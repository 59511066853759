<template>
    <tr class="menu-li menu-li-value menu-li-menuInputGroup-upDownBoxAndSelect" 
        :class="inputGroup.customCss"
        v-visible="isVisible()"
        data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menuInputGroup-upDownBoxAndSelect' }">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span>{{inputGroup.label}}</span>
                <div v-if="inputGroup.displayInfoTooltip === true && inputGroup.tooltipMessageBesideLabel === true" class="menu-tooltip-div">
                    <a data-placement="top"
                        @click="inputGroup.infoTooltip.showPopover"
                        :id="inputGroup.infoTooltip.id"
                        :data-content="inputGroup.infoTooltip.tooltipMessage"
                        data-bind="click: showPopover, attr: {id: id, 'data-content': tooltipMessage}">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a>
                </div>
            </div>
        </td>
        <td class="menu-value-td">
            <div class="flex-center menu-flex-end">
                <div v-if="inputGroup.displayInfoTooltip === true && inputGroup.tooltipMessageBesideLabel === false" class="increment-div info-tooltip-div">
                    <a data-placement="top" 
                        @click="inputGroup.infoTooltip.showPopover"
                        :id="inputGroup.infoTooltip.id"
                        :data-content="inputGroup.infoTooltip.tooltipMessage"
                        data-bind="click: showPopover, attr: {id: id, 'data-content': tooltipMessage}">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a>
                </div>
                
                <div v-if="inputGroup.displayInputTooltip() === true" 
                    class="increment-div input-tooltip-div" 
                    :class="{ 'increment-div-inactive': returnInputTooltipReadOnly() }"
                    data-bind="css: { 'increment-div-inactive': permissionObv().readOnly } ">
                    <a href="#" data-placement="top" 
                        @click="inputGroup.inputTooltip.showPopover"
                        :id="inputGroup.inputTooltip.id"
                        data-bind="click: showPopover, attr: {id: id}">
                        <i class="fa fa-book-open fa-lg"></i>
                    </a>
                </div>
                <div class="override-updownbox-div">
                    <div v-if="displayInfoTooltipOverride()" 
                        class="override-updownbox-checkbox"
                        @click="inputGroup.upDownBox.toggleOverride"
                        :id="inputGroup.upDownBox.id + '-div'"
                        :title="inputGroup.upDownBox.override.value === false ? inputGroup.upDownBox.overrideTooltipMessage: ''"
                        :class="{'override-false': inputGroup.upDownBox.override.value === false }"
                        data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }, attr: {id: id, title: override() === false ? overrideTooltipMessage: '' }"
                        data-toggle="tooltip">
                        <i class="far fa-lg"
                            :class="{ 'fa-check-square': inputGroup.upDownBox.override.value === true, 'fa-square': inputGroup.upDownBox.override.value === false }"
                            data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
                    </div>
                    <div @click="upDownBoxDivClick"
                        data-bind="click: function() { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false ) { toggleOverride()}  }, touchstart: function () { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false) {  toggleOverride() } }">
                        <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel"
                            v-model.lazy="inputGroup.upDownBox.value.value"
                            @click="upDownBoxClick"
                            @blur="inputGroup.upDownBox.revertErrorValueOnBlur"
                            @focus="upDownBoxFocus"
                            @keyup.enter="handleEnterKey"
                            :id="inputGroup.upDownBox.id"
                            :title="inputGroup.upDownBox.override.value === false ? inputGroup.upDownBox.overrideTooltipMessage : inputGroup.upDownBox.errorMessageObv.value"
                            :data-original-title="inputGroup.upDownBox.errorMessageObv.value"
                            :class="{'override-false': addOverrideFalseClass(), 'override-cursor': addOverrideCursorClass()}"
                            :readonly="isInputDisabled()"
                            data-bind="value: value, click: isMac === true ? selectAllText : null,disable: override() === false || permissionObv().readOnly === true, event: { focus: isMac === false ? selectAllText : null, blur: revertErrorValueOnBlur }, attr: {id: id, title: override() === false ? overrideTooltipMessage : errorMessageObv, 'data-original-title': errorMessageObv}, css: {'override-false': override() === false || permissionObv().readOnly === true, 'override-cursor' : override() === false && permissionObv().readOnly === false}"
                            data-toggle="tooltip" />
                    </div>
                    <div class="menu-select-div" data-bind="with: select">
                        <select :id="inputGroup.select.id" :title="inputGroup.select.errorMessageObv.value"
                            :data-original-title="inputGroup.select.errorMessageObv.value"
                            v-model="inputGroup.select.valueObv.value"
                            data-toggle="tooltip">
                            <option v-for="option in inputGroup.select.displayArray.value" :key="option" :value="option.id">
                                {{ option.description }}</option>
                        </select>
                    </div>                    
                </div>
                <template v-if="displayActiveIncrementAndDecrementButtons()">
                    <div class="increment-div decrement-div" 
                        @click="inputGroup.upDownBox.decrementValue"
                        :class="{ 'increment-div-active': inputGroup.upDownBox.override.value === true, 'increment-div-inactive': inputGroup.upDownBox.override.value === false }"
                        data-bind="click: decrementValue, touchstart: decrementValue, css: {
                        'increment-div-active':
                        override() === true, 'increment-div-inactive': override() === false }">
                        <i class="fa fa-minus-circle fa-lg"></i>
                    </div>
                    <div class="increment-div"
                        @click="inputGroup.upDownBox.incrementValue"
                        :class="{ 'increment-div-active': inputGroup.upDownBox.override.value === true, 'increment-div-inactive': inputGroup.upDownBox.override.value === false }"
                        data-bind="click: incrementValue, touchstart: incrementValue, css: { 'increment-div-active': override() === true, 'increment-div-inactive': override() === false }">
                        <i class="fa fa-plus-circle fa-lg"></i>
                    </div>
                </template>
                <template v-if="displayIncrementAndDecrementButtonPlaceholders()">
                    <div class="increment-div decrement-div"></div>
                    <div class="increment-div"></div>
                </template>
            </div>
        </td>
    </tr>
    <!-- <div>
        template-menuInputGroup-upDownBoxAndSelect
    </div> -->

     <!-- <script type="text/html" id="template-menu-item">
     ko if: type === $root.config.ITEM_TYPE.INPUT_GROUP 
     ko if: inputGroupType === $root.config.INPUT_GROUP_TYPES.UPDOWNBOX_AND_SELECTMENU 
    <tr class="menu-li menu-li-value menu-li-menuInputGroup-upDownBoxAndSelect" data-bind="css: customCss, visible:  permissionObv().visible, template: { name: 'template-menuInputGroup-upDownBoxAndSelect' }"></tr>
     /ko 
     /ko 
</script>  -->

<!-- <script type="text/html" id="template-menuInputGroup-upDownBoxAndSelect">
    <td>
        <div class="menu-label-div menu-label-upodownbox">
            <span data-bind="text: label"></span>
             ko if: displayInfoTooltip === true && tooltipMessageBesideLabel === true 
             ko with: infoTooltip 
            <div class="menu-tooltip-div">
                <a data-placement="top" data-bind="click: showPopover, attr: {id: id, 'data-content': tooltipMessage}"><i class="fa fa-info-circle fa-lg"></i></a>
            </div>
             /ko 
             /ko 
        </div>
    </td>
    <td class="menu-value-td">
        <div class="flex-center menu-flex-end">
             ko if: displayInfoTooltip === true && tooltipMessageBesideLabel === false 
             ko with: infoTooltip 
            <div class="increment-div info-tooltip-div">
                <a data-placement="top" data-bind="click: showPopover, attr: {id: id, 'data-content': tooltipMessage}"><i class="fa fa-info-circle fa-lg"></i></a>
            </div>
             /ko 
             /ko 
             ko if: displayInputTooltip() === true 
             ko with: inputTooltip 
            <div class="increment-div input-tooltip-div" data-bind="css: { 'increment-div-inactive': permissionObv().readOnly } ">
                <a href="#" data-placement="top" data-bind="click: showPopover, attr: {id: id}">
                    <i class="fa fa-book-open fa-lg"></i>
                </a>
            </div>
             /ko 
             /ko 
            <div class="override-updownbox-div">
                 ko with: upDownBox 
                 ko if: permissionObv().readOnly === false && permissionObv().override === true 
                <div class="override-updownbox-checkbox"
                     data-bind="click: toggleOverride, touchstart: toggleOverride, css: {'override-false': override() === false }, attr: {id: id, title: override() === false ? overrideTooltipMessage: '' }"
                     data-toggle="tooltip">
                    <i class="far fa-square fa-lg"
                       data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
                </div>
                 /ko 
                <div data-bind="click: function() { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false ) { toggleOverride()}  }, touchstart: function () { if(permissionObv().readOnly === false && permissionObv().override === true && override() === false) {  toggleOverride() } }">
                    <input class="form-control login-input menu-input menu-input-number default-width-tel" type="tel"
                           data-bind="value: value, click: isMac === true ? selectAllText : null,disable: override() === false || permissionObv().readOnly === true, event: { focus: isMac === false ? selectAllText : null, blur: revertErrorValueOnBlur }, attr: {id: id, title: override() === false ? overrideTooltipMessage : errorMessageObv, 'data-original-title': errorMessageObv}, css: {'override-false': override() === false || permissionObv().readOnly === true, 'override-cursor' : override() === false && permissionObv().readOnly === false}"
                           data-toggle="tooltip" />
                </div>
                 /ko 
                <div class="menu-select-div" data-bind="with: select">
                    <select data-bind="options: displayArray, optionsText: 'description', optionsValue: 'id', value: valueObv, attr: {id: id, title: errorMessageObv, 'data-original-title': errorMessageObv}"
                            data-toggle="tooltip"></select>
                </div>
            </div>
             ko with: upDownBox 
             ko if: permissionObv().readOnly === false && displayIncrementAndDecrementButtons === true 
            <div class="increment-div decrement-div" data-bind="click: decrementValue, touchstart: decrementValue, css: {
                'increment-div-active':
                override() === true, 'increment-div-inactive': override() === false }">
                <i class="fa fa-minus-circle fa-lg"></i>
            </div>
            <div class="increment-div"
                 data-bind="click: incrementValue, touchstart: incrementValue, css: { 'increment-div-active': override() === true, 'increment-div-inactive': override() === false }">
                <i class="fa fa-plus-circle fa-lg"></i>
            </div>
             /ko 
             ko if: permissionObv().readOnly === true || displayIncrementAndDecrementButtons === false 
            <div class="increment-div decrement-div"></div>
            <div class="increment-div"></div>
             /ko 
             /ko 
        </div>
    </td>
</script> -->
</template>

<script>
import { toRef, nextTick, onUpdated, isRef } from 'vue';

const $ = window.$;

export default {
    name: 'MenuContentItemInputGroupUpDownBoxAndSelectMenu',
    props: {
        inputGroupFromParent: Object
    },
    setup (props) {
        // const inputGroup = ref(props.inputGroupFromParent);
        const inputGroup = toRef(props, "inputGroupFromParent");

        function upDownBoxDivClick() {
            if(inputGroup.value.permissionObv.value.readOnly === false && inputGroup.value.permissionObv.value.override === true && inputGroup.value.upDownBox.override.value === false ) { 
                inputGroup.value.upDownBox.toggleOverride()
            }
        }

        function upDownBoxClick() {
            if(inputGroup.value.upDownBox.isMac === true) {
                inputGroup.value.upDownBox.selectAllText();
            }
        }

        function upDownBoxFocus() {
            if(inputGroup.value.upDownBox.isMac === false) {
                inputGroup.value.upDownBox.selectAllText();
            }
        }

        function handleEnterKey() {
            nextTick(function(){
                $('#' + inputGroup.value.upDownBox.id).val(inputGroup.value.upDownBox.value.value);
                $('#' + inputGroup.value.select.id + '-button').focus();
            });
        }

        function isInputDisabled() {
            if(isRef(inputGroup.value.upDownBox.permissionObv) && inputGroup.value.upDownBox.permissionObv.value) {
                return inputGroup.value.upDownBox.override.value === false || inputGroup.value.upDownBox.permissionObv.value.readOnly === true;
            }
            return false;
        }

        // onUpdated(() => {
        //     inputGroup.value.select.valueObv.value
        //     if($('#' + inputGroup.value.select.id).selectmenu('instance')) {
        //         $('#' + inputGroup.value.select.id).selectmenu('refresh');
        //     }
        // });

        function isVisible() {
            if(isRef(inputGroup.value.permissionObv) && inputGroup.value.permissionObv.value) {
                return inputGroup.value.permissionObv.value.visible;
            } else {
                return inputGroup.value.permissionObv.visible;
            }
        }

        function returnInputTooltipReadOnly() {
            if(isRef(inputGroup.value.inputTooltip.permissionObv)) {
                return inputGroup.value.inputTooltip.permissionObv.value.readOnly;
            } else {
                return inputGroup.value.inputTooltip.permissionObv.readOnly;
            }
        }

        function displayInfoTooltipOverride() {
            if(isRef(inputGroup.value.upDownBox.permissionObv) && inputGroup.value.upDownBox.permissionObv.value) {
                return inputGroup.value.upDownBox.permissionObv.value.readOnly === false && inputGroup.value.upDownBox.permissionObv.value.override === true;
            } else {
                return inputGroup.value.upDownBox.permissionObv.readOnly === false && inputGroup.value.upDownBox.permissionObv.override === true;
            }
        }

        function addOverrideFalseClass() {
            if(isRef(inputGroup.value.upDownBox.permissionObv) && inputGroup.value.upDownBox.permissionObv.value) {
                return inputGroup.value.upDownBox.override.value === false || inputGroup.value.upDownBox.permissionObv.value.readOnly === true;
            } else {
                return inputGroup.value.upDownBox.override.value === false || inputGroup.value.upDownBox.permissionObv.readOnly === true;
            }
        }

        function addOverrideCursorClass() {
            if(isRef(inputGroup.value.upDownBox.permissionObv)) {
                return inputGroup.value.upDownBox.override.value === false && inputGroup.value.upDownBox.permissionObv.value.readOnly === false;
            } else {
                return inputGroup.value.upDownBox.override.value === false && inputGroup.value.upDownBox.permissionObv.readOnly === false
            }
        }

        function displayActiveIncrementAndDecrementButtons() {
            if(isRef(inputGroup.value.upDownBox.permissionObv) && inputGroup.value.upDownBox.permissionObv.value) {
                return inputGroup.value.upDownBox.permissionObv.value.readOnly === false && inputGroup.value.upDownBox.displayIncrementAndDecrementButtons === true;
            } else {
                return inputGroup.value.upDownBox.permissionObv.readOnly === false && inputGroup.value.upDownBox.displayIncrementAndDecrementButtons === true;
            }
        }

        function displayIncrementAndDecrementButtonPlaceholders() {
            if(isRef(inputGroup.value.upDownBox.permissionObv) && inputGroup.value.upDownBox.permissionObv.value) {
                return inputGroup.value.upDownBox.permissionObv.value.readOnly === true || inputGroup.value.upDownBox.displayIncrementAndDecrementButtons === false;
            } else {
                inputGroup.value.upDownBox.permissionObv.readOnly === true || inputGroup.value.upDownBox.displayIncrementAndDecrementButtons === false;
            }
        }

        return {
            inputGroup,
            upDownBoxClick,
            upDownBoxDivClick,
            upDownBoxFocus,
            handleEnterKey,
            isInputDisabled,
            isVisible,
            returnInputTooltipReadOnly,
            displayInfoTooltipOverride,
            addOverrideFalseClass,
            addOverrideCursorClass,
            displayActiveIncrementAndDecrementButtons,
            displayIncrementAndDecrementButtonPlaceholders
        }
    }
}
</script>

<style lang="scss" scoped>

</style>