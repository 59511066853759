import config from './config';
import globals from './globals';
import utils from './utils';
import graphicsLayer from './graphicsLayer';
import Dimension from '../model/drawing/dimension';
import Line from '../model/drawing/line';
import Circle from '../model/drawing/circle';
import Arc from '../model/drawing/arc';
import Polygon from '../model/drawing/polygon';
import AxleLetter from '../model/drawing/axleLetter';
import COG from '../model/drawing/cog';
import Rect from '../model/drawing/rect';
import offerManager from './offerManager';
import utilLegislationAttributeObjectCode from './enum/utilLegislationAttributeObjectCode';
import COGDimension from '../model/drawing/cogDimension';
import HitArea from '../model/drawing/hitArea';
import DimensionConfig from '../model/drawing/dimensionConfig';
import Text from '../model/drawing/text';
import HitchLoad from '../model/drawing/hitchLoad';
import Ellipse from '../model/drawing/ellipse';
import ComplianceDashboardSpacer from '../model/drawing/complianceDashboard/complianceDashboardSpacer';
import ComplianceDashboardRow from '../model/drawing/complianceDashboard/complianceDashboardRow';
import ComplianceDashboardText from '../model/drawing/complianceDashboard/complianceDashboardText';
import ComplianceDashboardIcon from  '../model/drawing/complianceDashboard/complianceDashboardIcon';
import ComplianceDashboard from '../model/drawing/complianceDashboard/complianceDashboard';
import RigOps from './rigOps';
import * as PIXI from '../assets/scripts/pixi.min';
import { watch, nextTick } from 'vue';



var TScMessenger = window.TScMessenger;
        

var FUEL_TANK_INCLUSIONS_TYPES = {
    STANDARD: 'STANDARD',
    NO: 'NO',
    OPTIONAL:'OPTIONAL'
};





var CIRCLE_TYPE = {
    INNER: "2",
    OUTER: "1",
    OTHER: "0"
}

var layerNumbers;

var POS = {
    TOP: 'TOP',
    LEFT: 'LEFT',
    BOTTOM: 'BOTTOM',
    RIGHT: 'RIGHT'
}

var LAYER = {
    FIFTH_WHEEL: 'FIFTH_WHEEL',
    AC_CA_FIFTH_WHEEL: 'AC_CA_FIFTH_WHEEL',
    AXLES_AND_WHEELBASE: 'AXLES_AND_WHEELBASE',
    LEGISLATION_WHEELBASE: 'LEGISLATION_WHEELBASE',
    THEORETICAL_WHEELBASE: 'THEORETICAL_WHEELBASE',
    WHEELBASE_SPECIFIED: 'WHEELBASE_SPECIFIED',
    OVERALL_LENGTH: 'OVERALL_LENGTH',
    REAR_MOST_PROTRUDING_ITEM_BEHIND_CAB: 'REAR_MOST_PROTRUDING_ITEM_BEHIND_CAB',
    CAB_TO_REAR_AXLE: 'CAB_TO_REAR_AXLE',
    CAB_TO_CHASSIS_END: 'CAB_TO_CHASSIS_END',
    PAYLOAD_FROM_HEADBOARD_INSIDE: 'PAYLOAD_FROM_HEADBOARD_INSIDE',
    BODY_INTERNAL: 'BODY_INTERNAL',
    BODY_EXTERNAL: 'BODY_EXTERNAL',
    BODY_EXTERNAL_POPPED: 'BODY_EXTERNAL_POPPED',
    FUEL_TANK_1: 'FUEL_TANK_1',
    FUEL_TANK_2: 'FUEL_TANK_2',
    FUEL_TANK_3: 'FUEL_TANK_3',
    FUEL_TANK_4: 'FUEL_TANK_4',
    CREW_CAB: 'CREW_CAB',
    CRANE: 'CRANE',
    ROLLOVER: 'ROLLOVER',
    HEADBOARD: 'HEADBOARD',
    GROUND_CLEARANCE: 'GROUND_CLEARANCE',
    CAB_TILT_ARC: 'CAB_TILT_ARC',
    VERTICAL_EXHAUST_HEIGHT: 'VERTICAL_EXHAUST_HEIGHT',
    FIFTH_WHEEL_HEIGHT: 'FIFTH_WHEEL_HEIGHT',
    SUBFRAME_AND_MAX_BODY_EXTERNAL: 'SUBFRAME_AND_MAX_BODY_EXTERNAL',
    OVERALL_HEIGHT_AT_REAR: 'OVERALL_HEIGHT_AT_REAR',
    FIFTH_WHEEL_AND_TRAILER_HITCH: 'FIFTH_WHEEL_AND_TRAILER_HITCH',
    VEHICLE_THEORETICAL_WHEELBASE: 'VEHICLE_THEORETICAL_WHEELBASE',
    TRAILER_WHEELBASE_THEORETICAL: 'TRAILER_WHEELBASE_THEORETICAL',
    TRAILER_PAYLOAD_FROM_HEADBOARD_INSIDE: 'TRAILER_PAYLOAD_FROM_HEADBOARD_INSIDE',
    TRAILER_BODY_INTERNAL: 'TRAILER_BODY_INTERNAL',
    TRAILER_BODY_EXTERNAL: 'TRAILER_BODY_EXTERNAL',
    CAB_HEIGHT: 'CAB_HEIGHT',
    TRAILER_CHASSIS_AND_BODY_EXTERNAL: 'TRAILER_CHASSIS_AND_BODY_EXTERNAL',
    DRAWBAR_PROTRUSION: 'DRAWBAR_PROTRUSION',
    HITCH: 'HITCH',
    KINGPIN_TO_REAR_OF_TRAILER: 'KINGPIN_TO_REAR_OF_TRAILER',
    BRIDGE_GROUP_1: 'BRIDGE_GROUP_1',
    BRIDGE_GROUP_1_AND_GROUP_6: 'BRIDGE_GROUP_1_AND_GROUP_6',
    BRIDGE_GROUP_2: 'BRIDGE_GROUP_2',
    BRIDGE_GROUP_3: 'BRIDGE_GROUP_3',
    BRIDGE_GROUP_3_AND_4: 'BRIDGE_GROUP_3_AND_4',
    BRIDGE_GROUP_4: 'BRIDGE_GROUP_4',
    BRIDGE_GROUP_5: 'BRIDGE_GROUP_5',
    BRIDGE_GROUP_6: 'BRIDGE_GROUP_6',
    BRIDGE_GROUP_7: 'BRIDGE_GROUP_7',
    BRIDGE_GROUP_5_AND_6_AND_13: 'BRIDGE_GROUP_5_AND_6_AND_13',
    BRIDGE_GROUP_9_AND_8_AND_10_AND_11: 'BRIDGE_GROUP_9_AND_8_AND_10_AND_11',
    VEHICLE_CAB_WIDTH: 'VEHICLE_CAB_WIDTH',
    VEHICLE_REAR_WIDTH: 'VEHICLE_REAR_WIDTH',
    TRAILER1_AND_TRAILER2_PAYLOAD_TO_WHEELBASE: 'TRAILER1_AND_TRAILER2_PAYLOAD_TO_WHEELBASE',
    TRAILER1_AND_TRAILER2_PAYLOAD_FROM_HEADBOARD_INSIDE: 'TRAILER1_AND_TRAILER2_PAYLOAD_FROM_HEADBOARD_INSIDE',
    TRAILER1_AND_TRAILER2_BODY_INTERNAL: 'TRAILER1_AND_TRAILER2_BODY_INTERNAL',
    TRAILER1_AND_TRAILER2_BODY_EXTERNAL: 'TRAILER1_AND_TRAILER2_BODY_EXTERNAL',
    GAPS_BETWEEN_TRAILERS_AND_KINGPIN_TO_REAR_OF_TRAILER2: 'GAPS_BETWEEN_TRAILERS_AND_KINGPIN_TO_REAR_OF_TRAILER2',
    VEHICLE_AND_TRAILER_FIFTH_WHEEL: 'VEHICLE_AND_TRAILER_FIFTH_WHEEL',
    VEHICLE_AND_TRAILER2_WHEELBASE_THEORETICAL: 'VEHICLE_AND_TRAILER2_WHEELBASE_THEORETICAL',
    TRAILER1_WHEELBASE_THEORETICAL: 'TRAILER1_WHEELBASE_THEORETICAL',
    TRAILER1_BODY_INTERNAL: 'TRAILER1_BODY_INTERNAL',
    TRAILER1_BODY_EXTERNAL: 'TRAILER1_BODY_EXTERNAL',
    TRAILER2_BODY_INTERNAL: 'TRAILER2_BODY_INTERNAL',
    TRAILER2_BODY_EXTERNAL: 'TRAILER2_BODY_EXTERNAL',
    AXLE_1_FRONT_TO_START_OF_BODY: 'AXLE_1_FRONT_TO_START_OF_BODY',
    HOOKLIFT_LENGTH_1: 'HOOKLIFT_LENGTH_1',
    HOOKLIFT_LENGTH_2: 'HOOKLIFT_LENGTH_2',
    HOOKLIFT_LENGTH_3: 'HOOKLIFT_LENGTH_3',
    HOOKLIFT_LENGTH_4: 'HOOKLIFT_LENGTH_4',
    HOOKLIFT_HEIGHT_1: 'HOOKLIFT_HEIGHT_1',
    HOOKLIFT_HEIGHT_2: 'HOOKLIFT_HEIGHT_2',
    FIFTH_WHEEL_LENGTH_1: 'FIFTH_WHEEL_LENGTH_1',
    HITCH_LENGTH_1: 'HITCH_LENGTH_1',
    CRANE_LENGTH_1: 'CRANE_LENGTH_1',
    CRANE_LENGTH_2: 'CRANE_LENGTH_2',
    CRANE_LENGTH_3: 'CRANE_LENGTH_3',
    CRANE_HEIGHT_1: 'CRANE_HEIGHT_1',
    CRANE_HEIGHT_2: 'CRANE_HEIGHT_2',
    FRIDGE_LENGTH_1: 'FRIDGE_LENGTH_1',
    FRIDGE_LENGTH_2: 'FRIDGE_LENGTH_2',
    FRIDGE_HEIGHT_1: 'FRIDGE_HEIGHT_1',
    FRIDGE_HEIGHT_2: 'FRIDGE_HEIGHT_2',
    TAILLIFT_LENGTH_1: 'TAILLIFT_LENGTH_1',
    TAILLIFT_LENGTH_2: 'TAILLIFT_LENGTH_2',
    TAILLIFT_HEIGHT_1: 'TAILLIFT_HEIGHT_1',
    TAILLIFT_HEIGHT_2: 'TAILLIFT_HEIGHT_2',
    OTHER_LENGTH_1: 'OTHER_LENGTH_1',
    OTHER_LENGTH_2: 'OTHER_LENGTH_2',
    OTHER_LENGTH_3: 'OTHER_LENGTH_3',
    OTHER_LENGTH_4: 'OTHER_LENGTH_4',
    OTHER_HEIGHT_1: 'OTHER_HEIGHT_1',
    OTHER_HEIGHT_2: 'OTHER_HEIGHT_2',
    COMBINATION_OVERALL_WIDTH: 'COMBINATION_OVERALL_WIDTH',
    

}

var equipmentLayerIncrement = 60;

var initialiseCalled = false;

var drawingObjectsArray;

var dimensionConfigs = {}, cellConfigs = {};

var dimensionXOffset = 0;

var cabColourSubscription;
var axle2FrontToAxle1RearSubscription;

var curRenderMode;
var displayObjects = [];
var oW = window.innerWidth, oH = window.innerHeight, mW, mH;
var topDimensionLayerNum = 0, bottomDimensionLayerNum = 0, leftDimensionLayerNum = 0, rightDimensionLayerNum = 0, margin = config.generalDrawingMargin, generalBuffer = config.generalDrawingBufferSmall;
var cogLeftDimensionLayerNum = 0, cogRightDimensionLayerNum = 0, cogTopDimensionLayerNum = 0, cogBottomDimensionLayerNum = 0, cogTopLeftDimensionLayerNum = 0, cogTopRightDimensionLayerNum = 0, cogBottomLeftDimensionLayerNum = 0, cogBottomRightDimensionLayerNum = 0;
var drawingShowing = false;
var pdfGeneratedWhileDrawingShowing = false;
var numAxles = 0;
var curDisplayType = '';
var curViewDetail = '';
var curViewType = '';
var curElevation = '';
var curPreviewType = '';
var curFontName;
var curMeasurementSystem;
var cabColour;
var isPdfDrawing = false;
var curContainer, curVehicle, originalVehicle, curTrailer1 = null, curTrailer2 = null, curRenderItem = null, curOriginalRenderItem = null, curRenderItemSize = null, curOriginalRenderItemSize = null;
var topDimensionLayerHeight, bottomDimensionLayerHeight, tableLayerHeight, notesHeight, warningsHeight, topBufferHeight = 5, bottomBufferHeight = 2, emptyVerticalSpace, emptyHorizontalSpace, axleLettersHeight = 0, axleLetterTableConnectorLayerHeight = 10, belowTableBufferLayerHeight = margin/2;
var overallScalingHeight = 0;
var scalingWidth;
var notesWrapWidth = 0, warningsWrapWidth = 0;
var scalingFactor;
var dimensions;
var tableData;
var tableMetas;
var axleLetters;
var inDrawingDimensionLayerOffsetX, inDrawingDimensionLayerOffsetY;
var complianceDashboard;

var tableFontSize, dimensionFontSize, labelFontSize, axleLetterFontSize, notesAndWarningsFontSize, dashboardAndTableTitleFontSize;
var cogDimensionFontTextHeight;
var axleLetterFontSizeReduced = false;
var notes;
var warnings;

var innerDimensionOffset = 700;

var resizeTimeoutId;
var updatingDrawTrailWaterMarkInternally = false;

var vehicleHasGraphic = false;

var interactionState = {};

var curWizardAttributePath = null;

var updateQueue = [];
var d = new Date();
var timeAtLastUpdate = 0;

var vehicleTransformation = null;
var trailerTransformation = null;
var trailer2Transformation = null;
var turntableTransformation = null;
var vehicleFrontLeftTyresTransformation = null;
var vehicleFrontRightTyresTransformation = null;
var vehicleFrontLeftSecondTyresTransformation = null;
var vehicleFrontRightSecondTyresTransformation = null;

var vehicleRearAxle1TyreTransformations = null;
var vehicleRearAxle2TyreTransformations = null;
var vehicleRearAxle3TyreTransformations = null;
var vehicleRearAxle4TyreTransformations = null;

var preventDrawingStandardGraphicsInTurningCircleView = false;

var colLetters = {};
var groupLetters = {};
var letterColourAllocations = {};

var previewOriginOffsetX = 0, previewOriginOffsetY = 0, previewWindowBuffer = 40;
var initialItemGraphicAdjustment = null;
var initialItemNegativeGraphicAdjustment = null;
//let positiveOriginAdjustment = null;
var scalingDimensionPlane = null;
var itemScalingFactor = null;
var itemDrawingMeta = null;
var itemScalingDimensionChangedWithoutOverrideFlag = config.SCALING_DIMENSION_TYPES.NONE;

var drawFromScratch = false, needToRedrawInfoArea = false;

var drawingGroupMaxBounds = {};

let previousOpenMenuId = null, curOpenMenuId = null;

offerManager.registerChangeListener(notify);
offerManager.registerAddToLocalStorageListener(renderDrawing, getDrawingAsDataURL, detachOnResizeEvent);

watch(globals.drawTrialWatermark, function (newValue) {
    if (updatingDrawTrailWaterMarkInternally !== true) {
        notify();
    }
});

function getCurContainer() {
    return curContainer;
}

//function renderDrawing(container, width, height, rig, displayType, viewDetail, viewType, elevation, fontName, isPdf) {
function renderDrawing(container, width, height, gmConfig) {
    initialiseCalled = false;
    drawFromScratch = true;
    //interactionState = {};
    cleanUpInteractionState();
    vehicleHasGraphic = false;
    oW = width;
    oH = height;
    curContainer = container;
    curRenderMode = gmConfig.renderMode;
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        curVehicle = gmConfig.objectToRender.getVehicle();
        curTrailer1 = gmConfig.objectToRender.getTrailer1();
        curTrailer2 = gmConfig.objectToRender.getTrailer2();
        originalVehicle = offerManager.getOriginalOffer().getConfiguration();
        curDisplayType = gmConfig.displayType;
        curViewDetail = gmConfig.viewDetail;
        curViewType = gmConfig.viewType;
        cabColour = offerManager.activeCabColour.value;
        setupSubscriptions();
        setupDimensionConfigs();
        //if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
        //    setupBridgeCellConfigs();
        //}
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
        var breakHere = 0;
        curRenderItem = gmConfig.objectToRender;
        curPreviewType = gmConfig.previewType;
        //itemDrawingMeta = gmConfig.itemDrawingMeta || null;
        curOriginalRenderItem = offerManager.getOriginalConfigurationWizardItem();
        curRenderItemSize = offerManager.getActiveConfigurationWizardItemSize();
        curOriginalRenderItemSize = offerManager.getOriginalConfigurationWizardItemSize();
        initialItemGraphicAdjustment = gmConfig.initialGraphicAdjustment || null;
        initialItemNegativeGraphicAdjustment = gmConfig.initialNegativeGraphicAdjustment || null;
        //positiveOriginAdjustment = gmConfig.positiveOriginAdjustment || null;

    }
    
    curFontName = gmConfig.fontName;
    curElevation = gmConfig.elevation;
    curMeasurementSystem = offerManager.activeMeasurementSystem.value;
    
    
    updatingDrawTrailWaterMarkInternally = true;
    if (globals.user.isTrialUser() === true && globals.user.isVipTrialUser() === false && curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        globals.drawTrialWatermark.value = true;
    } else {
        globals.drawTrialWatermark.value = false;
    }
    updatingDrawTrailWaterMarkInternally = false;

    if (gmConfig.isPdf) {
        isPdfDrawing = gmConfig.isPdf;
    } else {
        isPdfDrawing = false;
    }
    if (isPdfDrawing === true) {
        dashboardAndTableTitleFontSize = config.summaryDashboardAndTableTitleFontSize;
        tableFontSize = config.summaryTableFontSize;
        dimensionFontSize = config.summaryDimensionFontSize;
        labelFontSize = config.summaryLabelFontSize;
        axleLetterFontSize = config.summaryAxleLetterFontSize;
        notesAndWarningsFontSize = config.summaryNotesAndWarningsFontSize;
    } else {
        dashboardAndTableTitleFontSize = config.configurationDashboardAndTableTitleFontSize;
        tableFontSize = config.configurationTableFontSize;
        dimensionFontSize = config.configurationDimensionFontSize;
        labelFontSize = config.configurationLabelFontSize;
        axleLetterFontSize = config.configurationAxleLetterFontSize;
        notesAndWarningsFontSize = config.configurationNotesAndWarningsFontSize;
    }
    clearDrawingObjects();
    setupOrRefreshDrawingComponents();
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        if (drawingShowing === false || pdfGeneratedWhileDrawingShowing === true) {
            //if (pdfGeneratedWhileDrawingShowing === true) {

            //graphicsLayer.destroy(true);
            //updateDrawing(true);
            //}
            pdfGeneratedWhileDrawingShowing = false;
            generateDisplayObjects();
            if (curViewType === config.VIEW_TYPE.OVERVIEW && curElevation === config.ELEVATION.SIDE) {
                generatePinnedEquipmentDimensions();
            }
            setupRendering();
            if (isPdfDrawing === false) {
                drawingShowing = true;
            } else {
                pdfGeneratedWhileDrawingShowing = true
            }
        } else if (isPdfDrawing === true && drawingShowing === true) {
            //generateDisplayObjects();
            //setupRendering();
            refreshDrawing();
            pdfGeneratedWhileDrawingShowing = true;
            drawingShowing === false;
        }
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {

        //if (gmConfig.initialGraphicAdjustment && (gmConfig.initialGraphicAdjustment.LeftMost !== 0 || gmConfig.initialGraphicAdjustment.BottomMost !== 0)) {
            
        //}

        updateDrawing(true);
        
        pdfGeneratedWhileDrawingShowing = false;
        generateDisplayObjects();
        setupRendering();
        drawingShowing = true;

        if (curRenderItemSize.getLength() > curRenderItemSize.getHeight()) {
            scalingDimensionPlane = config.SCALING_PLANE.HORIZONTAL;
        } else {
            scalingDimensionPlane = config.SCALING_PLANE.VERTICAL;
        }
    }
    //else if (pdfGeneratedWhileDrawingShowing === true) {
    //    pdfGeneratedWhileDrawingShowing = false;
    //    refreshDrawing();
    //    //updateDrawing(true);
    //}

    window.onresize = function (event) {
        resize();
    }
    //$(window).unbind('resize').bind("resize", function (event) {
        
    //    resize();
    //});
    return drawingShowing;
}

function setupRendering() {
    passDislplayObjectsToGraphicsLayer();
    graphicsLayer.beginRendering(curContainer, oW, oH);
    
}

function stopRendering() {
    graphicsLayer.freeCanvasAndCancelAnimationLoop();
}

function passDislplayObjectsToGraphicsLayer(optionalDrawingGroupNameToPulse) {
    
    var fontSizes = {
        dashboardAndTableTitleFontSize: dashboardAndTableTitleFontSize,
        tableFontSize: tableFontSize,
        dimensionFontSize: dimensionFontSize,
        labelFontSize: labelFontSize,
        axleLetterFontSize: axleLetterFontSize,
        notesAndWarningsFontSize: notesAndWarningsFontSize
    }
    
    if (drawFromScratch) {
        drawFromScratch = false;
        graphicsLayer.initialise(curRenderMode, displayObjects, dimensions, tableData, complianceDashboard, axleLetters, isPdfDrawing, globals.drawTrialWatermark.value, interactionState, fontSizes, optionalDrawingGroupNameToPulse);
        
        initialiseCalled = true;
    } else {
        graphicsLayer.update(curRenderMode, displayObjects, dimensions, tableData, complianceDashboard, axleLetters, isPdfDrawing, globals.drawTrialWatermark.value, interactionState, fontSizes, needToRedrawInfoArea, optionalDrawingGroupNameToPulse);
        
    }
    needToRedrawInfoArea = false;
    
    if (isPdfDrawing === true) {
        //if (warnings.length > 0) {
        //    addWarnings(unpackText(warnings));
        //}
        //if (notes.length > 0) {
        //    addNotes(unpackText(notes));
        //}
        //if (warnings.length > 0 || notes.length > 0) {
        //    addNotesText();
        //}
        addNotesText();
    }
}
function handleAxlesForStandards(axleCircles) {
    
    sortExtractedAxles(axleCircles);
    axleCircles = addOrUpdateAxles(axleCircles, config.CHASSIS_OBJECT_TYPES.VEHICLE);
    sortExtractedAxles(axleCircles);
    copyExistingAxleDetailsToAddedAxles(axleCircles);
    displayObjects = displayObjects.concat(axleCircles);

    return axleCircles;
}
function handleAxlesForStandardTrailers(axleCircles, chassisObjectType) {
    sortExtractedAxles(axleCircles);
    axleCircles = addOrUpdateAxles(axleCircles, chassisObjectType);
    sortExtractedAxles(axleCircles);
    //copyExistingAxleDetailsToAddedAxles(axleCircles); //#unfinished, will need this when adding axles to trailers
    displayObjects = displayObjects.concat(axleCircles);

    return axleCircles;
}
function clearDrawingObjects() {
    displayObjects = [];
    if (drawingObjectsArray && drawingObjectsArray.length > 0) {
        drawingObjectsArray.length = 0;
    } else {
        drawingObjectsArray = new Array();
    }
}
function generateDisplayObjects() {
    
    clearDrawingObjects();
    
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {


        axleLetters = [];

        if (curViewType === config.VIEW_TYPE.OVERVIEW || curViewType === config.VIEW_TYPE.COG) {
            displayObjects = getDisplayObjects();
            var axleCircles;
            if (curVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD && vehicleHasGraphic) {


                if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                    axleCircles = extractExistingAxles(displayObjects, config.CHASSIS_OBJECT_TYPES.VEHICLE);
                    axleCircles = handleAxlesForStandards(axleCircles);
                }
            }
            //var trailer1AxleCircles;
            //if (curTrailer1 !== null && curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            //    trailer1AxleCircles = extractExistingAxles(displayObjects, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            //    trailer1AxleCircles = handleAxlesForStandardTrailers(trailer1AxleCircles, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            //    var trailer2AxleCircles;
            //    if (curTrailer2 !== null && curTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            //        trailer2AxleCircles = extractExistingAxles(displayObjects, config.CHASSIS_OBJECT_TYPES.TRAILER2);
            //        trailer2AxleCircles = handleAxlesForStandardTrailers(trailer2AxleCircles, config.CHASSIS_OBJECT_TYPES.TRAILER2);
            //    }
            //}

            //sortExtractedAxles(displayObjects);
            //displayObjects.sort(sortDrawingObjects);
            //displayObjects.sort(sortDrawingObjects);
            //displayObjects = groupDrawingObjects(displayObjects);
            assignAxlePositionsAndAddGroundLines(displayObjects, curVehicle.getChassisHeightFront(), curVehicle.getChassisHeightRear());
            assignDimensionOffsets(displayObjects);

            if (axleCircles !== undefined) {
                setFlatspotOnAxles(axleCircles);
            }
            axleLetters = generateAxleLetters(displayObjects);

            addHitAreasForDrawingGroups(displayObjects, config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG);
            if (curDisplayType === config.DISPLAY_TYPE.DETAILED) {
                generateCOGElements();
                addHitAreasForCOGElements(displayObjects);
            }
            if (curElevation === config.ELEVATION.SIDE && curVehicle.getCabTilt() === true && curVehicle.getBody() !== null &&
                (curVehicle.getBody().getType() === config.BODY_TYPES.TIPPER || (curVehicle.getAccessoryHolder().getFridge() !== null ||
                curVehicle.getAccessoryHolder().getFairing() !== null))) {
                addCabTiltArc();
            }



            if (curViewType === config.VIEW_TYPE.COG) {
                addHitAreasForCOGDimensions(dimensions, displayObjects);
            }
            if (curViewType === config.VIEW_TYPE.OVERVIEW && curElevation === config.ELEVATION.SIDE) {
                generatePinnedEquipmentDimensions();
            }
            addHitAreasForOverviewDimensions(dimensions, displayObjects);
            addHitAreasForEquipmentDimensions(dimensions, displayObjects);
            if (curVehicle.getAccessoryHolder().getHitch() !== null && curTrailer1 !== null && curTrailer1.getType() === config.TRAILER_TYPES.PUP && curElevation === config.ELEVATION.SIDE) {
                generateHitchOffset(displayObjects);
            }


        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
            dimensions = [];
            //tableData = null;
            try {

                generateTurningCircleDisplayObjectsAndDimensions(tcVals);
                var tcVals = offerManager.getRigOps().getTurningCircleValues(curViewDetail);
                if ((tcVals.steeringAngleInnerInDegrees !== 0 || tcVals.turningRadiusCurbToCurb !== 0 || tcVals.turningRadiusWallToWall !== 0) && offerManager.getNumLinkableTurningCircleMissingValues() === 0) {
                    displayObjects = getDisplayObjects();
                }
                vehicleTransformation = null;
                trailerTransformation = null;
                trailer2Transformation = null;
                vehicleFrontLeftTyresTransformation = null;
                vehicleFrontRightTyresTransformation = null;
                vehicleRearAxle1TyreTransformations = null;
                vehicleRearAxle2TyreTransformations = null;
                vehicleRearAxle3TyreTransformations = null;
                vehicleRearAxle4TyreTransformations = null;
            } catch (drawingException) {
                var breakHere = 0;
            }

            displayObjects = drawingObjectsArray;
        }
    } else if(curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY){
        //need to get drawing objects for item
        dimensions = [];
        tableData = null;
        axleLetters = undefined;
        cogLeftDimensionLayerNum = 0;
        cogRightDimensionLayerNum = 0;
        cogTopDimensionLayerNum = 0;
        cogBottomDimensionLayerNum = 0;
        cogTopLeftDimensionLayerNum = 0;
        cogTopRightDimensionLayerNum = 0;
        cogBottomLeftDimensionLayerNum = 0;
        cogBottomRightDimensionLayerNum = 0;
        inDrawingDimensionLayerOffsetX = 0;
        inDrawingDimensionLayerOffsetY = 0;

        displayObjects = getDisplayObjects();
        if (curPreviewType !== config.PREVIEW_TYPE.STANDARD) {
        
            if (curPreviewType === config.PREVIEW_TYPE.ADJUST_ORIGIN) {
                addHitAreasForDrawingGroups(displayObjects, config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY);
            } else if (curPreviewType === config.PREVIEW_TYPE.ADJUST_COG) {
                generateItemOnlyCOGMarker();
            } else if (curPreviewType === config.PREVIEW_TYPE.REVIEW_AND_FINALISE) {
                generateItemOnlyCOGMarker();
            }
            if (curPreviewType !== config.PREVIEW_TYPE.ADJUST_ORIGIN) {
                generateScaleAspectRatioLines();
            }
            if (curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE && curWizardAttributePath !== null) {
                generateItemAttributeReferenceLines(curWizardAttributePath);
            }
            generateOriginLines();
        }
    }

}

function getTextBlockHeight(textArray, width, fontSize) {
    if (textArray === undefined || textArray === null || textArray.length === 0) {
        return 0;
    }

    var numLines = Math.ceil(getTotalTextLength(textArray) / width);
    var textHeight = getHeightOfText("M", curFontName, fontSize, width);

    return textHeight + Math.ceil(textHeight * numLines) - (5 * numLines);
}

function calculateScreenMetrics() {

    var leftDimensionLayerWidth = 0;
    var rightDimensionLayerWidth = 0;
    var tempScalingFactor;
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        warningsWrapWidth = oW - (margin * 2) - getWidthOfText(config.getTranslationText('2795'), curFontName, notesAndWarningsFontSize);
        notesWrapWidth = oW - (margin * 2) - getWidthOfText(config.getTranslationText('2796'), curFontName, notesAndWarningsFontSize);
    } else {
        warningsWrapWidth = 0;
        notesWrapWidth = 0;
    }

    overallScalingHeight = getMaxRealWorldSizeRequiredForDrawingVerticalAxis();//getMaxSizeRequiredForRigVerticalAxis();
    scalingWidth = getMaxRealWorldSizeRequiredForDrawingHorizontalAxis();//getMaxSizeRequiredForRigHorizontalAxis();
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        inDrawingDimensionLayerOffsetX = getTotalInDrawingFrontDimensionLayerThickness();
        inDrawingDimensionLayerOffsetY = getTotalInDrawingBottomDimensionLayerThickness();
    } else {
        inDrawingDimensionLayerOffsetX = 0;
        inDrawingDimensionLayerOffsetY = 0;
    }
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        if (curViewType === config.VIEW_TYPE.OVERVIEW || curViewType === config.VIEW_TYPE.COG) {

            if (curElevation === config.ELEVATION.SIDE) {
                topBufferHeight = 5;
                bottomBufferHeight = 2;
            } else {
                topBufferHeight = 5;
                bottomBufferHeight = 10;
            }

            

            var layerIncrmentToUse = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
            if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG && curViewType === config.VIEW_TYPE.OVERVIEW) {
                leftDimensionLayerWidth = ((layerNumbers[POS.LEFT].layerNum + 2) * layerIncrmentToUse)/* + (margin * 2)*/;
                rightDimensionLayerWidth = ((layerNumbers[POS.RIGHT].layerNum + 1) * layerIncrmentToUse) /*+ (margin * 3)*/;
            } else {
                leftDimensionLayerWidth = 0;
                rightDimensionLayerWidth = 0;
            }
        
            mW = oW - (leftDimensionLayerWidth + rightDimensionLayerWidth + getTotalInDrawingDimensionLayerThicknessXAxis());
            if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG && curViewType === config.VIEW_TYPE.OVERVIEW) {
                adjustMainWidthForHorizontalJumpDimensions(config.DIMENSION_STYLE.JUMP_RIGHT);
                adjustMainWidthForHorizontalJumpDimensions(config.DIMENSION_STYLE.JUMP_LEFT);
            } 
            tempScalingFactor = (mW / scalingWidth).toFixed(8);
            //wordWrapWidth = mW - (margin * 2);
        

            var nonMainDrawingAreaHeight;
            if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
                nonMainDrawingAreaHeight = getNonMainDrawingAreaHeight();
            } else {
                nonMainDrawingAreaHeight = 0;
            }
            mH = oH - (nonMainDrawingAreaHeight + getTotalInDrawingDimensionLayerThicknessYAxis());

            scalingFactor = getScalingFactor();
            if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG && curViewType === config.VIEW_TYPE.COG) {
                mW = mW + getTotalInDrawingDimensionLayerThicknessXAxis();
            }

            if (scalingFactor !== tempScalingFactor) {
                mW = Math.round(scalingWidth * scalingFactor) + getTotalInDrawingDimensionLayerThicknessXAxis();
                warningsWrapWidth = oW - (margin * 2) - getWidthOfText(config.getTranslationText('2795'), curFontName, notesAndWarningsFontSize);
                notesWrapWidth = oW - (margin * 2) - getWidthOfText(config.getTranslationText('2796'), curFontName, notesAndWarningsFontSize);
                nonMainDrawingAreaHeight = getNonMainDrawingAreaHeight();

                mH = oH - (nonMainDrawingAreaHeight);

                emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor) + getTotalInDrawingDimensionLayerThicknessYAxis());
            } else {
                //trim unused vertical space as a result of retricted scaling due to length
                //mH = oH - (nonMainDrawingAreaHeight);
                emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor));
                mH = Math.round(overallScalingHeight * scalingFactor) + getTotalInDrawingDimensionLayerThicknessYAxis();
                //emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor) + getTotalInDrawingDimensionLayerThicknessYAxis());
                //emptyVerticalSpace = oH - (mH + nonMainDrawingAreaHeight);
            }
        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {

            topBufferHeight = 15;
            bottomBufferHeight = 10;

            topDimensionLayerHeight = 0;
            bottomDimensionLayerHeight = 0;
            //tableLayerHeight = 0;
            emptyHorizontalSpace = 0;
            axleLettersHeight = 0;
            axleLetters = [];
            inDrawingDimensionLayerOffsetX = 0;
            inDrawingDimensionLayerOffsetY = 0;
            //axleLetterTableConnectorLayerHeight = 0;
            //belowTableBufferLayerHeight = 0;


            /*
            topDimensionLayerHeight = getLayerHeight(layerNumbers[POS.TOP].layerNum, 'TOP');
        bottomDimensionLayerHeight = getLayerHeight(layerNumbers[POS.BOTTOM].layerNum, 'BOTTOM');


        tableLayerHeight = Math.max(getTableLayerHeight(tableData), complianceDashboard.getHeight() + complianceDashboard.getBackgroundBuffer()*2);
            */

            tableLayerHeight = Math.max(getTableLayerHeight(tableData), complianceDashboard !== null ? complianceDashboard.getHeight() + complianceDashboard.getBackgroundBuffer() * 2 : 0);

            var turningCircleVals = offerManager.getRigOps().getTurningCircleValues(curViewDetail);//////;//offerManager.getRigOps().getTurningCircleValues(curViewDetail);
            var measurementToUseForScale;
            if (turningCircleVals.turningRadiusCurbToCurb === 0 && turningCircleVals.turningRadiusWallToWall === 0 && turningCircleVals.steeringAngleInnerInDegrees === 0) {
                measurementToUseForScale = oH - topBufferHeight - bottomBufferHeight - tableLayerHeight - belowTableBufferLayerHeight - axleLetterTableConnectorLayerHeight + 30;
            } else {
                if (curViewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION) {
                    if (offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.TurningRadiusCurbToCurbMax) > 0) {
                        measurementToUseForScale = offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.TurningRadiusCurbToCurbMax);
                    } else if (offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.TurningRadiusOuterMax) > 0) {
                        measurementToUseForScale = offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.TurningRadiusOuterMax);
                    } else {
                        measurementToUseForScale = oH - topBufferHeight - bottomBufferHeight - tableLayerHeight - belowTableBufferLayerHeight - axleLetterTableConnectorLayerHeight + 30;
                    }
                
                } else {
                    measurementToUseForScale = turningCircleVals.turningRadiusWallToWall;
                }
            }
        
        


            overallScalingHeight = measurementToUseForScale;//turningCircleVals.turningRadiusOuterMax;
            scalingWidth = measurementToUseForScale * 2;

            mW = oW - 60 ;

            tempScalingFactor = (mW / scalingWidth).toFixed(8);

            if (isPdfDrawing === true) {
                notesHeight = getTextBlockHeight(notes, notesWrapWidth, notesAndWarningsFontSize);
                warningsHeight = getTextBlockHeight(warnings, warningsWrapWidth, notesAndWarningsFontSize);
            } else {
                notesHeight = 0;
                warningsHeight = 0;
            }
        
            mH = oH - topBufferHeight - bottomBufferHeight - tableLayerHeight - belowTableBufferLayerHeight - axleLetterTableConnectorLayerHeight - notesHeight - warningsHeight;

            scalingFactor = getScalingFactor();
            if (scalingFactor !== tempScalingFactor) {
                mW = Math.round(scalingWidth * scalingFactor);
                warningsWrapWidth = oW - (margin * 2) - getWidthOfText(config.getTranslationText('2795'), curFontName, notesAndWarningsFontSize);
                notesWrapWidth = oW - (margin * 2) - getWidthOfText(config.getTranslationText('2796'), curFontName, notesAndWarningsFontSize);

                emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor));
            } else {
                emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor));
                mH = Math.round(overallScalingHeight * scalingFactor);
            }
        }
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) { 

        //previewWindowBuffer = oW * 0.02;//(oW / scalingFactor) * 0.1;
        
        topBufferHeight = previewWindowBuffer;

        //if(curPreviewType === config.PREVIEW_TYPE.ADJUST_ORIGIN) {
            
            //leftDimensionLayerWidth = 0;
            //rightDimensionLayerWidth = 0;
        
            var xAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
            var yAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
            
            //var xScalingWidthAdjustment = xAxisChange > 0 ? xAxisChange : Math.abs(xAxisChange) > scalingWidth ? Math.abs(xAxisChange) - scalingWidth : 0;
        //var yScalingHeightAdjustment = yAxisChange > 0 ? yAxisChange : Math.abs(yAxisChange) > overallScalingHeight ? Math.abs(yAxisChange) - overallScalingHeight : 0;
            var xScalingWidthAdjustment = xAxisChange < 0 ? Math.abs(xAxisChange) > scalingWidth ? Math.abs(xAxisChange) - scalingWidth : Math.abs(xAxisChange) : 0;
            var yScalingHeightAdjustment = yAxisChange < 0 ? Math.abs(yAxisChange) > overallScalingHeight ? Math.abs(yAxisChange) - overallScalingHeight : Math.abs(yAxisChange) : 0;

            scalingWidth = scalingWidth + xScalingWidthAdjustment;
            overallScalingHeight = overallScalingHeight + yScalingHeightAdjustment;


            mW = oW - (previewWindowBuffer * 2);
            //mW = oW - (previewWindowBuffer * 2) - xAxisChange;

            //var tempScalingFactor = (mW / scalingWidth).toFixed(8);
            
            mH = oH - (previewWindowBuffer * 2);
            //mH = oH - (previewWindowBuffer * 2) - yAxisChange;
            scalingFactor = getScalingFactor();
            
            //if (scalingFactor !== tempScalingFactor) {
            //    mW = Math.round(scalingWidth * scalingFactor);
                

            //    //emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor) + previewOriginBufferY + yAxisChange);
            //    emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor));
            //} else {
                
            //    emptyVerticalSpace = mH - (Math.round(overallScalingHeight * scalingFactor));
            //    //mH = Math.round(overallScalingHeight * scalingFactor) + previewOriginBufferY + yAxisChange;
            //    mH = Math.round(overallScalingHeight * scalingFactor);
                
            //}
        //}
    }

    function adjustMainWidthForHorizontalJumpDimensions(dimensionJumpStyle) {
        var largestJumpDimensionTextWidth = getLargestJumpDimensionTextWidth(dimensionJumpStyle);
        if (largestJumpDimensionTextWidth !== null) {
            if (dimensionJumpStyle === config.DIMENSION_STYLE.JUMP_RIGHT) {
                if (leftDimensionLayerWidth + mW + largestJumpDimensionTextWidth + 40 > oW) {
                    var changeRight = (largestJumpDimensionTextWidth + 40) - rightDimensionLayerWidth;
                    mW -= changeRight;
                    scalingWidth += changeRight;
                }
            } else {
                if (rightDimensionLayerWidth + mW + largestJumpDimensionTextWidth + 40 > oW) {
                    var changeLeft = (largestJumpDimensionTextWidth + 40) - leftDimensionLayerWidth;
                    mW -= changeLeft;
                    scalingWidth += changeLeft;

                }
            }
            
        }
    }
}

function getLargestJumpDimensionTextWidth(dimensionJumpStyle) {
    var largestJumpDimensionTextWidth = null;

    dimensions.forEach(function (dimension) {
        if (dimension.style === dimensionJumpStyle) {
            if (largestJumpDimensionTextWidth === null) {
                largestJumpDimensionTextWidth = getWidthOfText('  ' + dimension.displayLength, curFontName, dimensionFontSize);
            } else {
                var tempTextWidth = getWidthOfText('' + dimension.displayLength, curFontName, dimensionFontSize);
                if (largestJumpDimensionTextWidth < tempTextWidth) {
                    largestJumpDimensionTextWidth = tempTextWidth;
                }
            }
        }
    });

    return largestJumpDimensionTextWidth;
}

function getTotalInDrawingFrontDimensionLayerThickness() {
    if (curViewType === config.VIEW_TYPE.COG) {
        var layerIncrmentToUse = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
        return (cogLeftDimensionLayerNum + 1) * layerIncrmentToUse;
    }else {
        return 0;
    }
}

function getTotalInDrawingDimensionLayerThicknessXAxis() {
    if (curViewType === config.VIEW_TYPE.COG) {
        var layerIncrmentToUse = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
        return ((cogLeftDimensionLayerNum + 1) * layerIncrmentToUse) + ((cogRightDimensionLayerNum + 1) * layerIncrmentToUse);
    } else {
        return 0;
    }
    
}

function getTotalInDrawingBottomDimensionLayerThickness() {
    if (curViewType === config.VIEW_TYPE.COG) {
        var layerIncrmentToUse = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
        return (cogBottomDimensionLayerNum + 1) * layerIncrmentToUse;
    } else {
        return 0;
    }
}

function getTotalInDrawingDimensionLayerThicknessYAxis() {
    if (curViewType === config.VIEW_TYPE.COG) {
        var layerIncrmentToUse = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
        return ((cogBottomDimensionLayerNum + 1) * layerIncrmentToUse) + ((cogTopDimensionLayerNum + 1) * layerIncrmentToUse);
    } else {
        return 0;
    }

}

function getTotalInDrawingTopDimensionLayerThickness() {
    if (curViewType === config.VIEW_TYPE.COG) {
        var layerIncrmentToUse = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
        return (cogTopDimensionLayerNum + 1) * layerIncrmentToUse;
    } else {
        return 0;
    }
}

function getScalingFactor() {
    var tempScalingFactor;
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
        
        /*
        if (curRenderItem.getLength() > curRenderItem.getHeight() && !offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.CRANEHEIGHT).override()) {
        itemScalingFactor = (curRenderItem.getLength() / itemDrawingMeta.widthInDrawingUnits).toFixed(8);
    }else  if (curRenderItem.getHeight() > curRenderItem.getLength() && !offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.CRANELENGTH).override()) {
        itemScalingFactor = (curRenderItem.getHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
    } else if (scalingDimensionPlane === config.SCALING_PLANE.VERTICAL) {
        itemScalingFactor = (curRenderItem.getHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
    } else if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL) {
        itemScalingFactor = (curRenderItem.getLength() / itemDrawingMeta.widthInDrawingUnits).toFixed(8);
    }
        */

        var xAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
        var yAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
        
        var effectiveLength;
        if (xAxisChange < 0) {
            effectiveLength = Math.abs(xAxisChange) + curRenderItemSize.getLength();
        } else {
            effectiveLength = curRenderItemSize.getLength();
        }

        var effectiveHeight;
        if (yAxisChange < 0) {
            effectiveHeight = Math.abs(yAxisChange) + curRenderItemSize.getHeight();
        } else {
            effectiveHeight = curRenderItemSize.getHeight();
        }
        

        if (effectiveLength > effectiveHeight && !offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override.value) {
            
            tempScalingFactor = (mW / effectiveLength).toFixed(8);

            if (((effectiveHeight * tempScalingFactor).toFixed(8) > mH)) {
                tempScalingFactor = (mH / effectiveHeight).toFixed(8);
            }
            
        } else if (effectiveHeight > effectiveLength && !offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override.value) {
            tempScalingFactor = (mH / effectiveHeight).toFixed(8);
            if (((effectiveLength * tempScalingFactor).toFixed(8) > mH)) {
                tempScalingFactor = (mW / effectiveLength).toFixed(8);
            }
        } else if (offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override.value && offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override.value) {
            //tempScalingFactor = (mH / overallScalingHeight).toFixed(8);
            if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL) {
                if (curRenderItemSize.getLength() > curRenderItemSize.getHeight()) {
                    tempScalingFactor = (mW / scalingWidth).toFixed(8);

                    if (((overallScalingHeight * tempScalingFactor).toFixed(8) > mH)) {
                        tempScalingFactor = (mH / overallScalingHeight).toFixed(8);
                    }
                } else {
                    tempScalingFactor = (mW / getAspectScalingWidth()).toFixed(8);

                    if (((getAspectScalingHeight() * tempScalingFactor).toFixed(8) > mH)) {
                        tempScalingFactor = (mH / getAspectScalingHeight()).toFixed(8);
                    }
                }
                
            } else {
                if (curRenderItemSize.getLength() > curRenderItemSize.getHeight()) {
                    //tempScalingFactor = (mW / scalingWidth).toFixed(8);
                    //tempScalingFactor = (mW / overallScalingHeight).toFixed(8);
                    //tempScalingFactor = (mH / scalingWidth).toFixed(8);
                    tempScalingFactor = (mH / getAspectScalingHeight()).toFixed(8);
                    if (((getAspectScalingWidth() * tempScalingFactor).toFixed(8) > mH)) {
                        tempScalingFactor = (mW / getAspectScalingWidth()).toFixed(8);
                    }
                } else {
                    //tempScalingFactor = (mW / overallScalingHeight).toFixed(8);
                    //tempScalingFactor = (mW / scalingWidth).toFixed(8);
                    //tempScalingFactor = (mH / scalingWidth).toFixed(8);
                    tempScalingFactor = (mH / overallScalingHeight).toFixed(8);
                    if (((scalingWidth * tempScalingFactor).toFixed(8) > mH)) {
                        tempScalingFactor = (mW / scalingWidth).toFixed(8);
                    }
                }
                //tempScalingFactor = (mW / overallScalingHeight).toFixed(8);
            }
        } else if (scalingDimensionPlane === config.SCALING_PLANE.VERTICAL) {
            
            //tempScalingFactor = (mH / overallScalingHeight).toFixed(8);
            tempScalingFactor = (mH / effectiveHeight).toFixed(8);
            if (((effectiveLength * tempScalingFactor).toFixed(8) > mH)) {
                tempScalingFactor = (mW / effectiveLength).toFixed(8);
            }
        } else if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL) {
            //tempScalingFactor = (mW / scalingWidth).toFixed(8);
            tempScalingFactor = (mW / effectiveLength).toFixed(8);
            if (((effectiveHeight * tempScalingFactor).toFixed(8) > mH)) {
                tempScalingFactor = (mH / effectiveHeight).toFixed(8);
            }
        }
        //tempScalingFactor = (mW / scalingWidth).toFixed(8);

        ////scalingDimensionPlane = config.SCALING_PLANE.HORIZONTAL;
        ////check that the height fits using the scaling factor calculated from length
        //if (((overallScalingHeight * tempScalingFactor).toFixed(8) > mH)) {
        //    tempScalingFactor = (mH / overallScalingHeight).toFixed(8);
        //    //scalingDimensionPlane = config.SCALING_PLANE.VERTICAL;
        //}

    } else {
        tempScalingFactor = (mW / scalingWidth).toFixed(8);

        scalingDimensionPlane = config.SCALING_PLANE.HORIZONTAL;
        //check that the height fits using the scaling factor calculated from length
        if (((overallScalingHeight * tempScalingFactor).toFixed(8) > mH)) {
            tempScalingFactor = (mH / overallScalingHeight).toFixed(8);
            scalingDimensionPlane = config.SCALING_PLANE.VERTICAL;
        }

        
    }
    return tempScalingFactor;
}

//function getActualItemLength() {
//    if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {

//    }else {
//        return curRenderItem.get
//    }
    
//}

function getAspectScalingHeight() {
    return curOriginalRenderItemSize.getHeight() * (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength());
    
}

function getAspectScalingWidth() {
    return curOriginalRenderItemSize.getLength() * (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight());
}

function getItemScalingFactor(displayObjects) {

    var xAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var yAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
    
    //need to figure out at what point below or elsewhere in sizing/scaling stuff the negative portion of the drawing needs to be taken into account as it looks like the issue is arising when the positive length/height drops below the other and so scaling mode switches etc

    //var effectiveLength = Math.abs(xAxisChange) + curRenderItem.getLength();
    //var effectiveHeight = Math.abs(yAxisChange) + curRenderItem.getHeight();

    var maxBounds = getMaxBoundsForGroup('configurationModal' + '.' + curRenderItem.getAccessoryType(), displayObjects, config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY, true, true);

    var itemScalingFactor;

    //if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL) {
    if (curRenderItemSize.getLength() > curRenderItemSize.getHeight() && !offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override.value) {
        itemScalingFactor = (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength()).toFixed(8);
    } else if (curRenderItemSize.getHeight() > curRenderItemSize.getLength() && !offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override.value) {
        itemScalingFactor = (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight()).toFixed(8);
    } else if (offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override.value && offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override.value) {
        //if (curRenderItem.getLength() > curRenderItem.getHeight()) {
        //    itemScalingFactor = (curRenderItem.getLength() / itemDrawingMeta.widthInDrawingUnits).toFixed(8);
        //} else {
        //    itemScalingFactor = (curRenderItem.getHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
        //}
        if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL) {
            if (curRenderItemSize.getLength() > curRenderItemSize.getHeight()) {
                itemScalingFactor = (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength()).toFixed(8);
            } else {
                itemScalingFactor = (getAspectScalingWidth() / curOriginalRenderItemSize.getLength()).toFixed(8);
            }

        } else {
            if (curRenderItemSize.getLength() > curRenderItemSize.getHeight()) {
                //tempScalingFactor = (mW / scalingWidth).toFixed(8);
                //tempScalingFactor = (mW / overallScalingHeight).toFixed(8);
                //tempScalingFactor = (mH / scalingWidth).toFixed(8);
                itemScalingFactor = (getAspectScalingHeight() / curOriginalRenderItemSize.getHeight()).toFixed(8);
            } else {
                //tempScalingFactor = (mW / overallScalingHeight).toFixed(8);
                //tempScalingFactor = (mW / scalingWidth).toFixed(8);
                //tempScalingFactor = (mH / scalingWidth).toFixed(8);
                itemScalingFactor = (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight()).toFixed(8);
            }
            //tempScalingFactor = (mW / overallScalingHeight).toFixed(8);
        }
        //itemScalingFactor = (curRenderItem.getHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
    } else if (scalingDimensionPlane === config.SCALING_PLANE.VERTICAL) {

        //var effectiveHeight;
        //if (yAxisChange < 0) {
        //    effectiveHeight = Math.abs(yAxisChange) + curRenderItem.getHeight();
        //} else {
        //    effectiveHeight = curRenderItem.getHeight();
        //}


        itemScalingFactor = (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight()).toFixed(8);
        //itemScalingFactor = (effectiveHeight / 158).toFixed(8);
    } else if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL) {
        itemScalingFactor = (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength()).toFixed(8);
    }
    
    return itemScalingFactor;
}

function getNonMainDrawingAreaHeight() {
    if (isPdfDrawing === true) {
        //notesHeight = getTextBlockHeight(notes, notesWrapWidth, notesAndWarningsFontSize);
        notesHeight = getHeightOfText(notes[0], curFontName, notesAndWarningsFontSize);
        warningsHeight = getTextBlockHeight(warnings, warningsWrapWidth, notesAndWarningsFontSize);
    } else {
        notesHeight = 0;
        warningsHeight = 0;
    }
    if (curElevation === config.ELEVATION.TOP) {
        axleLettersHeight = getHeightOfText("ABCDEF", curFontName, axleLetterFontSize, warningsWrapWidth);
    } else {
        axleLettersHeight = 0;
    }
    

    topDimensionLayerHeight = getLayerHeight(layerNumbers[POS.TOP].layerNum, 'TOP');
    bottomDimensionLayerHeight = getLayerHeight(layerNumbers[POS.BOTTOM].layerNum, 'BOTTOM');


    tableLayerHeight = Math.max(getTableLayerHeight(tableData), complianceDashboard !== null ? complianceDashboard.getHeight() + complianceDashboard.getBackgroundBuffer()*2 : 0);//(Object.keys(tableData.cols.total.cellData).length * tableData.cellHeight) + tableData.cellHeight + (margin);

    

    return topDimensionLayerHeight + bottomDimensionLayerHeight + tableLayerHeight + notesHeight + warningsHeight + topBufferHeight + bottomBufferHeight + axleLettersHeight + axleLetterTableConnectorLayerHeight + belowTableBufferLayerHeight;

    
}

function getTableLayerHeight(tableData) {
    var tableLayerHeight = 0;
    
    Object.keys(tableData.cols[Object.keys(tableData.cols)[0]].cellData).forEach(function (key) {
        if (key.includes('underline')) {
            tableLayerHeight += 1;
        } else {
            tableLayerHeight += tableData.cellHeight;
        }
    });
    return tableLayerHeight + tableData.cellHeight + (margin);
}

function getMaxRealWorldSizeRequiredForDrawingVerticalAxis() {
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        return getMaxSizeRequiredForRigVerticalAxis();
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
        return getMaxSizeRequiredForItemVerticalAxis();
    }
}

function getMaxSizeRequiredForItemVerticalAxis() {
    // if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
    
    // } else if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {

    // } else {
        if (curElevation === config.ELEVATION.SIDE) {
            return curRenderItemSize.getHeight();
        } else {
            return curRenderItemSize.getWidth();
        }
        
    // }
}

function getMaxSizeRequiredForRigVerticalAxis() {
    
    var maxHeightRequiredForVehicle = 0;
    if (curElevation === config.ELEVATION.SIDE) {
        maxHeightRequiredForVehicle = Math.max(curVehicle.getCompleteOverallBodyAndEquipmentHeight(), curVehicle.getOverallHeight());
        maxHeightRequiredForVehicle = Math.max(maxHeightRequiredForVehicle, curVehicle.getOverallHeightMeasuredFromFront());

        if (curTrailer1 !== null) {
            maxHeightRequiredForVehicle = Math.max(maxHeightRequiredForVehicle, curTrailer1.getOverallHeightAtRear());
            if (curTrailer2 !== null) {
                maxHeightRequiredForVehicle = Math.max(maxHeightRequiredForVehicle, curTrailer2.getOverallHeightAtRear());

            }
        }
        //#unfinished, will come into play later on when payload objects that can exceed VAN height come into play, refer to silverlight for further definitions
        //if (curVehicle.hasAccessory(config.ACCESSORY_TYPES.BODY) === true) {
        //    maxHeight = Math.Max(maxHeight, BottomOfVehicleToPayloadDeckStart + _Accessories.Body.PayloadObjects.MaxHeightOfPayloadObjects)
        //}
    } else {
        //maxHeightRequiredForVehicle = offerManager.getRigOps().getOverallWidthInclAccessories();

        // var topViewCentreLine = offerManager.getRigOps().getTopViewCentreLine();
        // var leftOfBodyWidthExternalVehicle = 0;
        // let vehicleSidewallLeft = 0;
        // let tempVehicleBody = curVehicle.getAccessoryHolder().getBody();
        // if (tempVehicleBody !== null) {
        //     leftOfBodyWidthExternalVehicle = offerManager.getRigOps().getLeftOfBodyWidthExternal(curVehicle);
        //     vehicleSidewallLeft = tempVehicleBody.getSidewallLeft();
        // }
        
        // var leftOfVehicleRearWidth = offerManager.getRigOps().getLeftOfChassisRearWidth(curVehicle);

        // var distanceOfBottomMostPointFromCentreLine = 0, distanceOfTopMostPointFromCentreLine = 0;

        // distanceOfBottomMostPointFromCentreLine = Math.max(curVehicle.getCabWidth() / 2, curVehicle.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternalVehicle));
        // distanceOfBottomMostPointFromCentreLine = Math.max(curVehicle.getOverallWidth() / 2, distanceOfBottomMostPointFromCentreLine); 
        // distanceOfBottomMostPointFromCentreLine = Math.max(distanceOfBottomMostPointFromCentreLine, curVehicle.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalVehicle + vehicleSidewallLeft));
        
        // distanceOfTopMostPointFromCentreLine = Math.max(curVehicle.getCabWidth() / 2, curVehicle.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternalVehicle));
        // distanceOfTopMostPointFromCentreLine = Math.max(curVehicle.getOverallWidth() / 2, distanceOfTopMostPointFromCentreLine); 
        // distanceOfTopMostPointFromCentreLine = Math.max(distanceOfTopMostPointFromCentreLine, curVehicle.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalVehicle + vehicleSidewallLeft));

        // if (curTrailer1 !== null) {
        //     var leftOfBodyWidthExternalTrailer1 = 0;
        //     let trailer1SidewallLeft = 0;
        //     let tempTrailer1Body = curTrailer1.getAccessoryHolder().getBody();
        //     if (tempTrailer1Body !== null) {
        //         leftOfBodyWidthExternalTrailer1 = offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer1);
        //         trailer1SidewallLeft = tempTrailer1Body.getSidewallLeft();
        //     }

        //     var leftOfTrailerRearWidth = offerManager.getRigOps().getLeftOfChassisRearWidth(curTrailer1);

        //     distanceOfBottomMostPointFromCentreLine = Math.max(distanceOfBottomMostPointFromCentreLine, curTrailer1.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfTrailerRearWidth, leftOfBodyWidthExternalTrailer1));
        //     distanceOfBottomMostPointFromCentreLine = Math.max(curTrailer1.getOverallWidth() / 2, distanceOfBottomMostPointFromCentreLine);
        //     distanceOfBottomMostPointFromCentreLine = Math.max(distanceOfBottomMostPointFromCentreLine, curTrailer1.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalTrailer1 + trailer1SidewallLeft));

        //     distanceOfTopMostPointFromCentreLine = Math.max(distanceOfTopMostPointFromCentreLine, curTrailer1.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfTrailerRearWidth, leftOfBodyWidthExternalTrailer1));
        //     distanceOfTopMostPointFromCentreLine = Math.max(curTrailer1.getOverallWidth() / 2, distanceOfTopMostPointFromCentreLine);
        //     distanceOfTopMostPointFromCentreLine = Math.max(distanceOfTopMostPointFromCentreLine, curTrailer1.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalTrailer1 + trailer1SidewallLeft));

        //     if (curTrailer2 !== null) {
        //         var leftOfBodyWidthExternalTrailer2 = 0;
        //         let trailer2SidewallLeft = 0;
        //         let tempTrailer2Body = curTrailer2.getAccessoryHolder().getBody();
        //         if (tempTrailer2Body !== null) {
        //             leftOfBodyWidthExternalTrailer2 = offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer2);
        //             trailer2SidewallLeft = tempTrailer2Body.getSidewallLeft();
        //         }

        //         leftOfTrailerRearWidth = offerManager.getRigOps().getLeftOfChassisRearWidth(curTrailer2);

        //         distanceOfBottomMostPointFromCentreLine = Math.max(distanceOfBottomMostPointFromCentreLine, curTrailer2.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfTrailerRearWidth, leftOfBodyWidthExternalTrailer2));
        //         distanceOfBottomMostPointFromCentreLine = Math.max(curTrailer2.getOverallWidth() / 2, distanceOfBottomMostPointFromCentreLine);
        //         distanceOfBottomMostPointFromCentreLine = Math.max(distanceOfBottomMostPointFromCentreLine, curTrailer2.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalTrailer2 + trailer2SidewallLeft));

        //         distanceOfTopMostPointFromCentreLine = Math.max(distanceOfTopMostPointFromCentreLine, curTrailer2.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfTrailerRearWidth, leftOfBodyWidthExternalTrailer2));
        //         distanceOfTopMostPointFromCentreLine = Math.max(curTrailer2.getOverallWidth() / 2, distanceOfTopMostPointFromCentreLine);
        //         distanceOfTopMostPointFromCentreLine = Math.max(distanceOfTopMostPointFromCentreLine, curTrailer2.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalTrailer2 + trailer2SidewallLeft));
        //     }
        // }

        // maxHeightRequiredForVehicle = distanceOfBottomMostPointFromCentreLine + distanceOfTopMostPointFromCentreLine;
        maxHeightRequiredForVehicle = offerManager.getRigOps().getCombinationOverallWidth();
    }

    return maxHeightRequiredForVehicle;

    
}

function getMaxRealWorldSizeRequiredForDrawingHorizontalAxis() {
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        return getMaxSizeRequiredForRigHorizontalAxis();
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
        return getMaxSizeRequiredForItemHorizontalAxis();
    }
}

function getMaxSizeRequiredForItemHorizontalAxis() {
    if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
        return curRenderItemSize.getLength();
    } else if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.TRAILER) {
        //not supported yet
    } else {
        return curRenderItemSize.getLength();

    }
}

function getMaxSizeRequiredForRigHorizontalAxis() {
    var maxWidthRequiredForVehicle;
    

    if (curTrailer1 !== null) {

        //var fifthWheelOffset;
        //if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
        //    fifthWheelOffset = curVehicle.getFifthWheelOffset();
        //} else {
        //    fifthWheelOffset = curVehicle.getAccessoryHolder().getFifthWheel().getOffset();
        //}


        //maxWidthRequiredForVehicle = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curTrailer1.getOverallLengthInclAccessories() - (fifthWheelOffset  + curTrailer1.getPortionOfOverallLengthOverhangingFront() + curTrailer1.getFrontOverhang());
        maxWidthRequiredForVehicle = offerManager.getRigOps().getOverallLengthLegal();
        
    } else {
        if (curVehicle.getCompleteOverallLength() === 0) {
            maxWidthRequiredForVehicle = curVehicle.getOverallLength();
        } else {
            maxWidthRequiredForVehicle = curVehicle.getCompleteOverallLength();
        }
        //maxWidthRequiredForVehicle = Math.max(maxWidthRequiredForVehicle, curVehicle.getOverallLengthExclAccessories());
        //maxWidthRequiredForVehicle = Math.max(maxWidthRequiredForVehicle, curVehicle.getOverallLengthInclAccessories());
        maxWidthRequiredForVehicle = Math.max(maxWidthRequiredForVehicle, offerManager.getRigOps().getOverallLengthLegal());
    }

    return maxWidthRequiredForVehicle;
}

function getLayerHeight(layerNum, layerPosition) {
    
    if (layerNum === 0) {
        return 0;
    } else {
        var numHorizontalPopLayers = 0;
        dimensions.forEach(function (dimension) {
            if (dimension.style === config.DIMENSION_STYLE.POP_LAYER && (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_BOTTOM)) {
                numHorizontalPopLayers++;
            }
        });
        var layerIncrmentToUse = isPdfDrawing === true ? config.summaryDimensionLayerIncrement : config.configurationDimensionLayerIncrement;
        var layerHeight = (layerNum + numHorizontalPopLayers) * layerIncrmentToUse;
        if (numHorizontalPopLayers > 0) {
            return layerHeight;
        }
        if (layerPosition === 'TOP') {
            return layerHeight + margin;
        } else {
            return layerHeight + (margin/2);
        }
        
    }
    
}

function getLeftMostBottomMostPointForItemDrawing(item) {
    var itemXMLDoc = item.getDecompressedGraphicBlob();
    
    var itemDrawingObjects = populateDrawingObjectsForItemOnlyFromXML(itemXMLDoc, item, item.getId());

    var leftMost = 9999999, bottomMost = 9999999;
    

    for (var i = 0; i < itemDrawingObjects.length; i++) {

        //var metaObject = displayObjects[i].getMetaObject();
        var metaObject = itemDrawingObjects[i];
        if (metaObject.type === "poly") {
            for (var j = 0; j < metaObject.pointsArray.length; j++) {
                if (metaObject.pointsArray[j].x < leftMost) {
                    leftMost = metaObject.pointsArray[j].x;
                }
                if (metaObject.pointsArray[j].y < bottomMost) {
                    bottomMost = metaObject.pointsArray[j].y;
                }
            }
        } else if (metaObject.type === "circle") {
            if (metaObject.centre.x * scalingFactor < leftMost) {
                leftMost = metaObject.centre.x * scalingFactor;
            }
            if (metaObject.centre.y * scalingFactor < bottomMost) {
                bottomMost = metaObject.centre.y * scalingFactor;
            }
        } else if (metaObject.type === "ellipse") {
            if (metaObject.centre.x < leftMost) {
                leftMost = metaObject.centre.x;
            }
            if (metaObject.centre.y < bottomMost) {
                bottomMost = metaObject.centre.y;
            }
        } else if (metaObject.type === "arc") {
            if (metaObject.centre.x < leftMost) {
                leftMost = metaObject.centre.x;
            }
            if (metaObject.centre.y < bottomMost) {
                bottomMost = metaObject.centre.y;
            }
            

        } else if (metaObject.type === "line") {
            if (metaObject.startPoint.x < leftMost) {
                leftMost = metaObject.startPoint.x;
            }
            if (metaObject.startPoint.y < bottomMost) {
                bottomMost = metaObject.startPoint.y;
            }
            if (metaObject.endPoint.x < leftMost) {
                leftMost = metaObject.endPoint.x;
            }
            if (metaObject.endPoint.y < bottomMost) {
                bottomMost = metaObject.endPoint.y;
            }
        }
    }
    return { leftMost: leftMost, bottomMost: bottomMost };
}

function zeroLeftMostAndBottomMostOfDrawing(displayObjects, leftMost, bottomMost) {
    

    updateItemDrawingPoints(displayObjects, -leftMost, -bottomMost);

}

function zeroNegativeLeftMostAndBottomMostOfDrawing(displayObjects, leftMost, bottomMost) {


    updateItemDrawingPointsB(displayObjects, -leftMost, -bottomMost);

}

//function shiftByPositiveOffset(displayObjects, xOffset, yOffset) {
//    updateItemDrawingPoints(displayObjects, xOffset, yOffset);
//}

function setupSubscriptions() {
    setupCabColourSubscription();
    //setupAxle2FrontToAxle1RearSubscription();
}

function disposeSubscriptions() {
    if (cabColourSubscription !== undefined) {
        cabColourSubscription.value = undefined;
        cabColourSubscription = undefined;
    }
}

function setupCabColourSubscription() {
    if (cabColourSubscription === undefined) {
        cabColourSubscription = watch(offerManager.activeCabColour, function (newValue) {
            if (newValue !== '') {
                notify("cabColour", newValue);
            }
        });
    }
}

//function setupAxle2FrontToAxle1RearSubscription() {
//    axle2FrontToAxle1RearSubscription = offerManager.axle2FrontToAxle1Rear.subscribe(function (newValue) {
        
//            notify("axle2FrontToAxle1Rear", newValue);
        
//    });
//}

function setupOrRefreshDrawingComponents() {
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {

        //if (isPdfDrawing === false) {
        //    complianceDashboard = generateComplianceDashboard();
        //} else {
        //    complianceDashboard = null;
        //}
        complianceDashboard = generateComplianceDashboard();
        if (curViewType === config.VIEW_TYPE.OVERVIEW || curViewType === config.VIEW_TYPE.COG) {
            dimensionXOffset = curVehicle.getPortionOfOverallLengthOverhangingFront();
            dimensions = generateDimensions(curVehicle, curDisplayType, curViewType);

        }
        
        tableMetas = generateTableMetasForActiveLegislations();
        
        if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
            tableData = generateMergedBridgeTableMeta(tableMetas);
        } else {
            tableData = generateMergedTableMeta(tableMetas);//generateTableMeta(curVehicle, curDisplayType, curViewDetail, offerManager.getLesserOfActiveLegislationVals);
        }
        
        adjustDashboardAndTableToFitScreenSizeIfNecessary(tableData);


        if (isPdfDrawing === true) {
            notes = [config.getTranslationText('1067')];
            warnings = [];
        }
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) { 

        complianceDashboard = null;

        var itemDrawingHeightAOG, itemDrawingLengthAOG;
        if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
            var itemHeightAOG = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHEIGHT);
            var itemLengthAOG = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMLENGTH);

            itemDrawingHeightAOG = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
            itemDrawingLengthAOG = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);
            if (scalingDimensionPlane === config.SCALING_PLANE.VERTICAL && itemScalingDimensionChangedWithoutOverrideFlag === config.SCALING_DIMENSION_TYPES.HEIGHT && !(itemHeightAOG.override.value && itemLengthAOG.override.value)) {
                itemScalingDimensionChangedWithoutOverrideFlag = config.SCALING_DIMENSION_TYPES.NONE;
                ////var scaledProportionalHeight = curOriginalRenderItemSize.getHeight() * (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength());
                ////curRenderItemSize.setHeight(scaledProportionalHeight);
                ////itemDrawingHeightAOG.value(scaledProportionalHeight);
                ////if (curRenderItem.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                ////    var scaledProportionalHeightInternal = scaledProportionalHeight - curRenderItem.getRoofThickness() - curRenderItem.getSubstructureHeight() - curRenderItem.getSubframeHeight();
                ////    curRenderItem.setHeight(scaledProportionalHeightInternal);
                ////    itemHeightAOG.value(scaledProportionalHeightInternal);
                ////} else {
                ////    curRenderItem.setHeight(scaledProportionalHeight - curRenderItem.getSubframeHeight());
                ////    itemHeightAOG.value(scaledProportionalHeight - curRenderItem.getSubframeHeight());
                ////}
                //var scaledProportionalLength = curOriginalRenderItemSize.getLength() * (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight());
                //curRenderItemSize.setLength(scaledProportionalLength);
                //itemDrawingLengthAOG.value(scaledProportionalLength);
                //if (curRenderItem.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                //    var scaledProportionalLengthInternal = scaledProportionalLength - curRenderItem.getHeadboardThickness() - curRenderItem.getTailboardThickness();
                //    curRenderItem.setLength(scaledProportionalLengthInternal);
                //    itemLengthAOG.value(scaledProportionalLengthInternal);
                //} else {
                //    curRenderItem.setLength(scaledProportionalLength);
                //    itemLengthAOG.value(scaledProportionalLength);
                //}
                handleLengthScalingAdjustment();
            } else if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL && itemScalingDimensionChangedWithoutOverrideFlag === config.SCALING_DIMENSION_TYPES.LENGTH && !(itemHeightAOG.override.value && itemLengthAOG.override.value)) {
                itemScalingDimensionChangedWithoutOverrideFlag = config.SCALING_DIMENSION_TYPES.NONE;
                ////var scaledProportionalLength = curOriginalRenderItemSize.getLength() * (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight());
                ////curRenderItemSize.setLength(scaledProportionalLength);
                ////itemDrawingLengthAOG.value(scaledProportionalLength);
                ////if (curRenderItem.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                ////    var scaledProportionalLengthInternal = scaledProportionalLength - curRenderItem.getHeadboardThickness() - curRenderItem.getTailboardThickness();
                ////    curRenderItem.setLength(scaledProportionalLengthInternal);
                ////    itemLengthAOG.value(scaledProportionalLengthInternal);
                ////} else {
                ////    curRenderItem.setLength(scaledProportionalLength);
                ////    itemLengthAOG.value(scaledProportionalLength);
                ////}
                //var scaledProportionalHeight = curOriginalRenderItemSize.getHeight() * (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength());
                //curRenderItemSize.setHeight(scaledProportionalHeight);
                //itemDrawingHeightAOG.value(scaledProportionalHeight);
                //if (curRenderItem.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                //    var scaledProportionalHeightInternal = scaledProportionalHeight - curRenderItem.getRoofThickness() - curRenderItem.getSubstructureHeight() - curRenderItem.getSubframeHeight();
                //    curRenderItem.setHeight(scaledProportionalHeightInternal);
                //    itemHeightAOG.value(scaledProportionalHeightInternal);
                //} else {
                //    curRenderItem.setHeight(scaledProportionalHeight - curRenderItem.getSubframeHeight());
                //    itemHeightAOG.value(scaledProportionalHeight - curRenderItem.getSubframeHeight());
                //}
                handleHeightScalingAdjustment();
            } else if (itemLengthAOG.override.value && !itemHeightAOG.override.value) {
                
                handleHeightScalingAdjustment();
                
            } else if (itemHeightAOG.override.value && !itemLengthAOG.override.value) {
                
                handleLengthScalingAdjustment();
            } 
        } else {


            // itemDrawingHeightAOG = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT);
            // itemDrawingLengthAOG = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH);

            // if (itemDrawingLengthAOG.override.value && !itemDrawingHeightAOG.override.value) {
            //     //if (curRenderItem.getLength() > itemDrawingMeta.heightInDrawingUnits) {
            //     var scaledProportionalHeight = curOriginalRenderItemSize.getHeight() * (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength());
            //     curRenderItemSize.setHeight(scaledProportionalHeight);
            //     itemDrawingHeightAOG.value.value = scaledProportionalHeight;
            //     // nextTick(function(){
            //     //     curRenderItemSize.setHeight(scaledProportionalHeight);
            //     //     itemDrawingHeightAOG.value.value = scaledProportionalHeight;   
            //     // });
            //     //}
            // } else if (itemDrawingHeightAOG.override.value && !itemDrawingLengthAOG.override.value) {
            //     //var scaledProportionalLength = curRenderItem.getLength() / scalingFactor;
            //     //if (curRenderItem.getHeight() > itemDrawingMeta.widthInDrawingUnits) {
            //     var scaledProportionalLength = curOriginalRenderItemSize.getLength() * (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight());
            //     curRenderItemSize.setLength(scaledProportionalLength);
            //     itemDrawingLengthAOG.value.value = scaledProportionalLength;   
            //     // nextTick(function(){
            //     //     curRenderItemSize.setLength(scaledProportionalLength);
            //     //     itemDrawingLengthAOG.value.value = scaledProportionalLength;   
            //     // });
            //     //}
            // }
        }
        
        
    }
    calculateScreenMetrics();
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
    
        //if (curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE) {
            itemScalingFactor = getItemScalingFactor(displayObjects);

            var horizontalAxisChange, verticalAxisChange;
            var xAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
            var yAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
            var zAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ).value.value;

            horizontalAxisChange = xAxisChange;
            if(curElevation === config.ELEVATION.SIDE) {
                verticalAxisChange = yAxisChange;
            }else if(curElevation === config.ELEVATION.TOP) {
                verticalAxisChange = zAxisChange;
            }
            var drawingGroupsNamesForItemScaling = {};
            
            //if (itemOriginIsInsideItemBounds(displayObjects)) {
                
            drawingGroupsNamesForItemScaling['configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHORIZONTALCOG] = true;
            drawingGroupsNamesForItemScaling['configurationModal' + '.' + curRenderItem.getAccessoryType()] = true;
            if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                drawingGroupsNamesForItemScaling['configurationModal' + '.' + config.ACCESSORY_TYPES.PAYLOAD] = true;
                drawingGroupsNamesForItemScaling['configurationModal' + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + config.VALUE_TYPE.ITEMHORIZONTALCOG] = true;
            }
            //} else {
            //    drawingGroupsNamesForItemScaling['configurationModal' + '.' + curRenderItem.getAccessoryType()] = false;
                
            //}
            //drawingGroupsNamesForItemScaling['scaleAspectRatioLines'] = false;

            graphicsLayer.setItemScalingFactor(itemScalingFactor, { x: horizontalAxisChange, y: verticalAxisChange }, drawingGroupsNamesForItemScaling);
                //graphicsLayer.setItemScalingFactor(itemScalingFactor, { x: 0, y: 0 }, drawingGroupsNamesForItemScaling);
            //scaleItemDisplayObjects(displayObjects, 2)
        //}
    } else {
        itemScalingFactor = null;
        graphicsLayer.setItemScalingFactor(itemScalingFactor, null, null);
    }
    configureGraphicsLayerParameters();
    function handleHeightScalingAdjustment() {
        var scaledProportionalHeight = curOriginalRenderItemSize.getHeight() * (curRenderItemSize.getLength() / curOriginalRenderItemSize.getLength());
        curRenderItemSize.setHeight(scaledProportionalHeight);
        itemDrawingHeightAOG.value.value = scaledProportionalHeight;
        if (curRenderItem.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
            var scaledProportionalHeightInternal = scaledProportionalHeight - curRenderItem.getRoofThickness() - curRenderItem.getSubstructureHeight() - curRenderItem.getSubframeHeight();

            if (curRenderItem.getType() === config.BODY_TYPES.SERVICE) {
                curRenderItem.setHeight(scaledProportionalHeight);
                itemHeightAOG.value.value = scaledProportionalHeight;
            } else {
                curRenderItem.setHeight(scaledProportionalHeightInternal);
                itemHeightAOG.value.value = scaledProportionalHeightInternal;
            }

        } else {
            if (curRenderItem.getType() === config.BODY_TYPES.SERVICE) {
                curRenderItem.setHeight(scaledProportionalHeight);
                itemHeightAOG.value.value = scaledProportionalHeight;
            } else {
                curRenderItem.setHeight(scaledProportionalHeight - curRenderItem.getSubframeHeight());
                itemHeightAOG.value.value = scaledProportionalHeight - curRenderItem.getSubframeHeight();
            }
        }
    }
    function handleLengthScalingAdjustment() {
        var scaledProportionalLength = curOriginalRenderItemSize.getLength() * (curRenderItemSize.getHeight() / curOriginalRenderItemSize.getHeight());
        curRenderItemSize.setLength(scaledProportionalLength);
        itemDrawingLengthAOG.value.value = scaledProportionalLength;
        if (curRenderItem.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
            var scaledProportionalLengthInternal = scaledProportionalLength - curRenderItem.getHeadboardThickness() - curRenderItem.getTailboardThickness();
            curRenderItem.setLength(scaledProportionalLengthInternal);
            itemLengthAOG.value.value = scaledProportionalLengthInternal;
        } else {
            curRenderItem.setLength(scaledProportionalLength);
            itemLengthAOG.value.value = scaledProportionalLength;
        }
    }
}

function itemOriginIsInsideItemBounds(displayObjects) {
    var maxBounds = getMaxBoundsForGroup('configurationModal' + '.' + curRenderItem.getAccessoryType(), displayObjects, config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY, false, false);
    if (maxBounds !== null) {
        var originXOffset = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
        var originYOffset = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
        var originZOffset = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ).value.value;

        if ((-(originXOffset) > maxBounds.x && -(originXOffset) < maxBounds.x + maxBounds.width) && (-(originYOffset) > maxBounds.y && -(originYOffset) < maxBounds.y + maxBounds.height)) {
            return true;
        }
    }
    return false;
}

function configureGraphicsLayerParameters() {
    graphicsLayer.setScalingFactor(scalingFactor);
    graphicsLayer.setBounds(oW, oH, mW, mH, topDimensionLayerHeight, bottomDimensionLayerHeight, tableLayerHeight,
                            notesHeight, warningsHeight, emptyVerticalSpace, emptyHorizontalSpace, warningsWrapWidth,
                            topBufferHeight, bottomBufferHeight, axleLettersHeight, inDrawingDimensionLayerOffsetX, inDrawingDimensionLayerOffsetY, getTotalInDrawingTopDimensionLayerThickness(), axleLetterTableConnectorLayerHeight, belowTableBufferLayerHeight, cogDimensionFontTextHeight);
    graphicsLayer.setFontName(curFontName);
}

function adjustDashboardAndTableToFitScreenSizeIfNecessary(tableData) {
    var titlesReduced = false, overloadReduced = true;
    var minDashboardTableGap = 4, minLabelBuffer = 5, minDashboardWidth = complianceDashboard !== null ? complianceDashboard.getWidth() + complianceDashboard.getBackgroundBuffer() * 2 : 0;
    var dashboardTableGap = complianceDashboard !== null ? complianceDashboard.getDashboardTableGap() : 0;
    var maxCellWidth, idealCellWidth, spaceUsedByTableWithIdealCellWidth, fittedCellWidth, spaceUsedByTableWithFittedCellWidth, maxCellTitleText, spaceUsedByTableWithMaxCellTitleTextWidth;
    var tableLabelBuffer = tableData.labelBuffer;
    var dashboardWidth = complianceDashboard !== null ? complianceDashboard.getWidth() + complianceDashboard.getBackgroundBuffer() * 2 : 0;
    var maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
    var marginMultiplier = 8;
    

    var numCols = getNumCols();

    calcVals(tableData.labelBuffer);

    var bufferForDrawingArea = 0;
    if (isPdfDrawing === false) {
        bufferForDrawingArea = 20;
    }
    

    if (spaceUsedByTableWithIdealCellWidth >= maxAvailableWidth) {
        
        if (titlesReduced === false) {
            if (getLargestCellWidth(tableData) < getLargestTitleWidth(tableData)) {
                redeuceTableColTitles()
                titlesReduced = true;
                maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
                calcVals(tableLabelBuffer);
            } 

        }
        if (spaceUsedByTableWithIdealCellWidth > maxAvailableWidth) {
            complianceDashboard = null;
            dashboardWidth = 0;
            maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
            calcVals(tableLabelBuffer);
        }
        if (spaceUsedByTableWithIdealCellWidth > maxAvailableWidth - bufferForDrawingArea) {
            while (spaceUsedByTableWithIdealCellWidth >= maxAvailableWidth - bufferForDrawingArea) {
                if (tableLabelBuffer - 1 > minLabelBuffer) {
                    tableLabelBuffer -= 2;
                    tableData.labelBuffer = tableLabelBuffer;
                    maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
                    calcVals(tableLabelBuffer);
                } else {
                    break;
                }
            }
            if(!axleLetterFontSizeReduced) {
                axleLetterFontSize -= 3;
                axleLetterFontSizeReduced = true;
            }
        } else {
            resetAxleLetterFontSize();
        }
        if (spaceUsedByTableWithIdealCellWidth > maxAvailableWidth - bufferForDrawingArea) {
            if (spaceUsedByTableWithFittedCellWidth >= maxAvailableWidth) {
                //reduce gaps, buffers and compliance dashboardwidth
                
                while (spaceUsedByTableWithIdealCellWidth > maxAvailableWidth - bufferForDrawingArea) {

                    /* if (dashboardWidth - 4 > minDashboardWidth) {
                        if (dashboardWidth - 4 > minDashboardWidth) {
                            dashboardWidth -= 5;
                        }
                        maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
                        calcVals(tableLabelBuffer);
                    } else*/ if ( dashboardTableGap - 1 > minDashboardTableGap) {

                        
                        if (dashboardTableGap - 1 > minDashboardTableGap) {
                            dashboardTableGap -= 2;
                        }

                        maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
                        calcVals(tableLabelBuffer);
                    } else if (overloadReduced === false && titlesReduced === false) {
                        if (getLargestCellWidth(tableData) > getLargestTitleWidth(tableData)) {
                            //reduceTableOverloadCol();
                            overloadReduced = true;
                        } else {
                            redeuceTableColTitles()
                            titlesReduced = true;
                        }
                        
                        maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
                        calcVals(tableLabelBuffer);
                    } else if (titlesReduced === false ) {
                        if (getLargestCellWidth(tableData) < getLargestTitleWidth(tableData)) {
                            redeuceTableColTitles()
                            titlesReduced = true;
                            maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
                            calcVals(tableLabelBuffer);
                        } else {
                            break;
                        }
                        
                    } else if (overloadReduced === false) {
                        if (getLargestCellWidth(tableData) > getLargestTitleWidth(tableData)) {
                            //reduceTableOverloadCol();
                            overloadReduced = true;
                            maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
                            calcVals(tableLabelBuffer);
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                }
                if (spaceUsedByTableWithFittedCellWidth > maxAvailableWidth - bufferForDrawingArea) {
                    tableData.labelBuffer = tableLabelBuffer;
                    tableData.cellWidth = maxCellTitleText;
                    if (complianceDashboard !== null) {
                        complianceDashboard.setDashboardTableGap(dashboardTableGap);
                        //complianceDashboard.setWidth(dashboardWidth);
                    }
                } else if (spaceUsedByTableWithIdealCellWidth > maxAvailableWidth - bufferForDrawingArea) {
                    tableData.labelBuffer = tableLabelBuffer;
                    tableData.cellWidth = fittedCellWidth;
                    if (complianceDashboard !== null) {
                        complianceDashboard.setDashboardTableGap(dashboardTableGap);
                        //complianceDashboard.setWidth(dashboardWidth);
                    }
                } else {
                    tableData.labelBuffer = tableLabelBuffer;
                    tableData.cellWidth = idealCellWidth;
                    if (complianceDashboard !== null) {
                        complianceDashboard.setDashboardTableGap(dashboardTableGap);
                        //complianceDashboard.setWidth(dashboardWidth);
                    }
                }
            } else {
                tableData.labelBuffer = tableLabelBuffer;
                tableData.cellWidth = fittedCellWidth;
                if (complianceDashboard !== null) {
                    complianceDashboard.setDashboardTableGap(dashboardTableGap);
                    //complianceDashboard.setWidth(dashboardWidth);
                }
            }
        } else {
            tableData.labelBuffer = tableLabelBuffer;
            tableData.cellWidth = idealCellWidth;
            if (complianceDashboard !== null) {
                complianceDashboard.setDashboardTableGap(dashboardTableGap);
                //complianceDashboard.setWidth(dashboardWidth);
            }
        }
        
    } else {
        resetAxleLetterFontSize();
    }
    
    function resetAxleLetterFontSize() {
        axleLetterFontSizeReduced = false;
        if (isPdfDrawing === true) {
            //dashboardAndTableTitleFontSize = config.summaryDashboardAndTableTitleFontSize;
            //tableFontSize = config.summaryTableFontSize;
            //dimensionFontSize = config.summaryDimensionFontSize;
            //labelFontSize = config.summaryLabelFontSize;
            axleLetterFontSize = config.summaryAxleLetterFontSize;
            //notesAndWarningsFontSize = config.summaryNotesAndWarningsFontSize;
        } else {
            //dashboardAndTableTitleFontSize = config.configurationDashboardAndTableTitleFontSize;
            //tableFontSize = config.configurationTableFontSize;
            //dimensionFontSize = config.configurationDimensionFontSize;
            //labelFontSize = config.configurationLabelFontSize;
            axleLetterFontSize = config.configurationAxleLetterFontSize;
            //notesAndWarningsFontSize = config.configurationNotesAndWarningsFontSize;
        }
    }

    function getMaxAvailableWidth(complianceDashboardWidth, compliacenDashboardAndTableBuffer) {
        if (complianceDashboard !== null) {
            return oW - (complianceDashboardWidth + complianceDashboard.getBackgroundBuffer() * 2 + compliacenDashboardAndTableBuffer);
        } else {
            return oW;
        }
    }

    function calcVals(tableLabelBuffer) {
        var largestLabelWidth = getLargestLabelWidth(tableData);
        maxCellTitleText = Math.max(getLargestDataCellWidth(tableData), getLargestTitleWidth(tableData));//Math.max(getLargestCellWidth(tableData), getLargestTitleWidth(tableData));
        //idealCellWidth = maxCellTextWidth + (margin * 8);
        idealCellWidth = maxCellTitleText + tableLabelBuffer * 2;
        spaceUsedByTableWithIdealCellWidth = (idealCellWidth * (numCols - 1)) + Math.max(largestLabelWidth + tableLabelBuffer * 2, idealCellWidth) ;

        //fittedCellWidth = maxCellTextWidth + ((maxAvailableWidth - (Math.max((maxCellTextWidth, maxCellTitleText) * numCols) + (getLargestLabelWidth(tableData)) + tableLabelBuffer)) / numCols);
        fittedCellWidth = maxCellTitleText + ((maxAvailableWidth - ((maxCellTitleText * (numCols - 1)) + Math.max(largestLabelWidth, maxCellTitleText) + tableLabelBuffer)) / numCols-1);
        
        //if (fittedCellWidth < maxCellTextWidth || titlesReduced === true) {
        //    fittedCellWidth = maxCellTextWidth;
        //}
        spaceUsedByTableWithFittedCellWidth = (fittedCellWidth * (numCols - 1)) + Math.max(largestLabelWidth, fittedCellWidth) + tableLabelBuffer;
        spaceUsedByTableWithMaxCellTitleTextWidth = (maxCellTitleText * (numCols - 1)) + Math.max(largestLabelWidth, maxCellTitleText) + tableLabelBuffer;

    }
    function redeuceTableColTitles() {
        if (curViewDetail !== config.VIEW_DETAIL.BRIDGE_VIEW) {
            Object.keys(tableData.cols).forEach(function (key) {
                if (tableData.cols[key].title) {
                    tableData.cols[key].title = tableData.cols[key].title.replace(config.getTranslationText('532'), '')
                        .replace(config.getTranslationText('671') + ' 1', '')
                        .replace(config.getTranslationText('671') + ' 2', '')
                        .replace(config.getTranslationText('671') + ' ', '')
                        .replace(config.getTranslationText('153') + ' ', '')
                        .replace(config.getTranslationText('2778') + ' ', '')
                        .replace(config.getTranslationText('2776') + ' ', '')
                        .replace(config.getTranslationText('467') + ' ', '')
                        .replace('(', '')
                        .replace(')', '');
                }
            });
        } else {
            Object.keys(tableData.cols).forEach(function (key) {
                if (tableData.cols[key].title && key === config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED) {
                    tableData.cols[key].title = config.getTranslationText('1012');
                }
            });
        }
    }
    function reduceTableOverloadCol() {
        //Object.keys(tableData.cols).forEach(function (key) {
        //    if (key === "overload") {
        //        tableData.cols[key].value = tableData.cols[key].value.replace(tableData.cols[key].maskValue, '');
        //    }
        //});
        if (curViewDetail !== config.VIEW_DETAIL.BRIDGE_VIEW) {
            Object.keys(tableData.cols).forEach(function (key) {
                Object.keys(tableData.cols[key].cellData).forEach(function (innerKey) {
                    if (innerKey === "Overload" || innerKey === config.TABLE_COLUMNS.BRIDGE_OVERLOAD) {
                        tableData.cols[key].cellData[innerKey].value = tableData.cols[key].cellData[innerKey].value.replace(tableData.cols[key].cellData[innerKey].displayMask, '').replace('(', '').replace(')', '');
                    }


                });

            });
        } else {
            Object.keys(tableData.cols).forEach(function (key) {
                if (key === config.TABLE_COLUMNS.BRIDGE_OVERLOAD) {
                    Object.keys(tableData.cols[key].cellData).forEach(function (innerKey) {
                        //if (innerKey === "Overload" || innerKey === config.TABLE_COLUMNS.BRIDGE_OVERLOAD) {
                            tableData.cols[key].cellData[innerKey].value = tableData.cols[key].cellData[innerKey].value.replace(tableData.cols[key].cellData[innerKey].displayMask, '').replace('(', '').replace(')', '');
                        //}


                    });
                }

            });
        }
    }
    function getNumCols() {
        return Object.keys(tableData.cols).length;
    }
    

    function getLargestCellWidth(tableData) {
        var largestWidth = 0;
        //Object.keys(tableData.cols).forEach(function (key) {
        //    if (tableData.cols[key].title) {
        //        largestWidth = Math.max(getWidthOfText(tableData.cols[key].title, curFontName, tableFontSize, true), largestWidth);
        //    }
        //});

        //Object.keys(tableData.cols.total.cellData).forEach(function (key) {
        //    largestWidth = Math.max(getWidthOfText(tableData.cols.total.cellData[key].value, curFontName, tableFontSize), largestWidth);
        //});
        Object.keys(tableData.cols).forEach(function (key) {
            if (key !== 'Labels') {
                Object.keys(tableData.cols[key].cellData).forEach(function (innerKey) {
                
                    largestWidth = Math.max(getWidthOfText(tableData.cols[key].cellData[innerKey].value, curFontName, tableFontSize), largestWidth);
                
                });
            }
        });
        

        return largestWidth;
    }
    
    function getLargestLabelWidth(tableData) {
        var largestWidth = 0;
        
        var labelsCol = tableData.cols[Object.keys(tableData.cols)[0]]
        var cellDataToUse = labelsCol.cellData;

        Object.keys(cellDataToUse).forEach(function (key) {
            largestWidth = Math.max(getWidthOfText(cellDataToUse[key].label || cellDataToUse[key].value, curFontName, tableFontSize), largestWidth);
        });
        largestWidth = Math.max(getWidthOfText(labelsCol.title, curFontName, labelsCol.titleFontSize, true), largestWidth)
        return largestWidth;
    }
}

function getLargestDataCellWidth(tableData) {
    var largestWidth = 0;
    //Object.keys(tableData.cols).forEach(function (key) {
    //    if (tableData.cols[key].title) {
    //        largestWidth = Math.max(getWidthOfText(tableData.cols[key].title, curFontName, tableFontSize, true), largestWidth);
    //    }
    //});

    //Object.keys(tableData.cols.total.cellData).forEach(function (key) {
    //    largestWidth = Math.max(getWidthOfText(tableData.cols.total.cellData[key].value, curFontName, tableFontSize), largestWidth);
    //});
    Object.keys(tableData.cols).forEach(function (key) {
        if (key !== 'Labels') {
            Object.keys(tableData.cols[key].cellData).forEach(function (innerKey) {

                largestWidth = Math.max(getWidthOfText(tableData.cols[key].cellData[innerKey].value, curFontName, tableFontSize, true), largestWidth);

            });
        }
    });


    return largestWidth;
}
function getLargestTitleWidth(tableData) {
    var largestWidth = 0;
    Object.keys(tableData.cols).forEach(function (key) {
        if (key !== 'Labels') {
            if (tableData.cols[key].title) {
                largestWidth = Math.max(getWidthOfText(tableData.cols[key].title, curFontName, dashboardAndTableTitleFontSize), largestWidth);
            }
        }
    });

    return largestWidth;
}
//function adjustDashboardAndTableToFitScreenSizeIfNecessary(tableData) {
//    var titlesReduced = false;
//    var minDashboardTableGap = 10, minLabelBuffer = 5, minDashboardWidth = 150;
//    var dashboardTableGap = config.minimumComplianceDashboardAndTableBuffer;
//    var maxCellWidth, idealCellWidth, spaceUsedByTableWithIdealCellWidth, fittedCellWidth, spaceUsedByTableWithFittedCellWidth, maxCellTitleText, spaceUsedByTableWithMaxCellTitleTextWidth;
//    var tableLabelBuffer = tableData.labelBuffer;
//    var dashboardWidth = complianceDashboard !== null ? complianceDashboard.getLargestLabelWidth() : 0;
//    var maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);



//    var numCols = getNumCols();

//    calcVals(tableData.labelBuffer);




//    if (spaceUsedByTableWithIdealCellWidth >= maxAvailableWidth) {
//        if (spaceUsedByTableWithFittedCellWidth >= maxAvailableWidth) {
//            //reduce gaps, buffers and compliance dashboardwidth
//            while (spaceUsedByTableWithFittedCellWidth >= maxAvailableWidth - 40) {

//                if (dashboardWidth - 4 > minDashboardWidth) {
//                    if (dashboardWidth - 4 > minDashboardWidth) {
//                        dashboardWidth -= 5;
//                    }
//                    maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                    calcVals(tableLabelBuffer);
//                } else if (tableLabelBuffer - 1 > minLabelBuffer || dashboardTableGap - 1 > minDashboardTableGap) {

//                    if (tableLabelBuffer - 1 > minLabelBuffer) {
//                        tableLabelBuffer -= 2;
//                    }
//                    if (dashboardTableGap - 1 > minDashboardTableGap) {
//                        dashboardTableGap -= 2;
//                    }

//                    maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                    calcVals(tableLabelBuffer);
//                } else if (titlesReduced === false) {
//                    redeuceTableColTitles()
//                    titlesReduced = true;
//                    maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                    calcVals(tableLabelBuffer);
//                } else {
//                    break;
//                }
//            }
//            tableData.labelBuffer = tableLabelBuffer;
//            tableData.cellWidth = fittedCellWidth;
//            if (complianceDashboard !== null) {
//                complianceDashboard.setDashboardTableGap(dashboardTableGap);
//                complianceDashboard.setWidth(dashboardWidth);
//            }
//            if (fittedCellWidth < maxCellTitleText) {
//                redeuceTableColTitles();
//                titlesReduced = true;
//                maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                calcVals(tableLabelBuffer);
//                //if (fittedCellWidth < maxCellTitleText) {
//                if (spaceUsedByTableWithFittedCellWidth >= maxAvailableWidth) {
//                    while (spaceUsedByTableWithFittedCellWidth >= maxAvailableWidth) {

//                        if (dashboardWidth - 4 > minDashboardWidth) {
//                            if (dashboardWidth - 4 > minDashboardWidth) {
//                                dashboardWidth -= 5;
//                            }
//                            maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                            calcVals(tableLabelBuffer);
//                        } else if (tableLabelBuffer - 1 > minLabelBuffer || dashboardTableGap - 1 > minDashboardTableGap) {

//                            if (tableLabelBuffer - 1 > minLabelBuffer) {
//                                tableLabelBuffer -= 2;
//                            }
//                            if (dashboardTableGap - 1 > minDashboardTableGap) {
//                                dashboardTableGap -= 2;
//                            }

//                            maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                            calcVals(tableLabelBuffer);
//                        } else if (titlesReduced === false) {
//                            redeuceTableColTitles()
//                            titlesReduced = true;
//                            maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                            calcVals(tableLabelBuffer);
//                        } else {
//                            break;
//                        }
//                    }
//                    tableData.labelBuffer = tableLabelBuffer;
//                    tableData.cellWidth = fittedCellWidth;
//                    if (complianceDashboard !== null) {
//                        complianceDashboard.setDashboardTableGap(dashboardTableGap);
//                        complianceDashboard.setWidth(dashboardWidth);
//                    }
//                } /*else {
//                    tableData.labelBuffer = 10;
//                    tableData.cellWidth = fittedCellWidth;
//                    complianceDashboard.setDashboardTableGap(30);
//                    complianceDashboard.setWidth(dashboardWidth);
//                }*/
//            }
//        } else {
//            if (spaceUsedByTableWithMaxCellTitleTextWidth <= maxAvailableWidth) {
//                if (spaceUsedByTableWithMaxCellTitleTextWidth > maxAvailableWidth - 10) {
//                    tableData.labelBuffer = tableLabelBuffer - 10;
//                    if (complianceDashboard !== null) {
//                        complianceDashboard.setDashboardTableGap(dashboardTableGap - 20);
//                    }
//                    //complianceDashboard.setWidth(dashboardWidth);
//                } else if (spaceUsedByTableWithMaxCellTitleTextWidth > maxAvailableWidth - 20) {
//                    tableData.labelBuffer = tableLabelBuffer - 5;
//                    if (complianceDashboard !== null) {
//                        complianceDashboard.setDashboardTableGap(dashboardTableGap - 10);
//                    }
//                    //complianceDashboard.setWidth(dashboardWidth);
//                }
//                tableData.cellWidth = maxCellTitleText;
//            } else {
//                tableData.cellWidth = fittedCellWidth;
//                if (fittedCellWidth < maxCellTitleText) {
//                    redeuceTableColTitles();
//                    titlesReduced = true;
//                    maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                    calcVals(tableLabelBuffer);
//                    //if (fittedCellWidth < maxCellTitleText) {
//                    if (spaceUsedByTableWithFittedCellWidth >= maxAvailableWidth) {
//                        while (spaceUsedByTableWithFittedCellWidth >= maxAvailableWidth) {

//                            if (dashboardWidth - 4 > minDashboardWidth) {
//                                if (dashboardWidth - 4 > minDashboardWidth) {
//                                    dashboardWidth -= 5;
//                                }
//                                maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                                calcVals(tableLabelBuffer);
//                            } else if (tableLabelBuffer - 1 > minLabelBuffer || dashboardTableGap - 1 > minDashboardTableGap) {

//                                if (tableLabelBuffer - 1 > minLabelBuffer) {
//                                    tableLabelBuffer -= 2;
//                                }
//                                if (dashboardTableGap - 1 > minDashboardTableGap) {
//                                    dashboardTableGap -= 2;
//                                }

//                                maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                                calcVals(tableLabelBuffer);
//                            } else if (titlesReduced === false) {
//                                redeuceTableColTitles()
//                                titlesReduced = true;
//                                maxAvailableWidth = getMaxAvailableWidth(dashboardWidth, dashboardTableGap);
//                                calcVals(tableLabelBuffer);
//                            } else {
//                                break;
//                            }
//                        }
//                        tableData.labelBuffer = tableLabelBuffer;
//                        tableData.cellWidth = fittedCellWidth;
//                        if (complianceDashboard !== null) {
//                            complianceDashboard.setDashboardTableGap(dashboardTableGap);
//                            complianceDashboard.setWidth(dashboardWidth);
//                        }
//                    } else {
//                        tableData.labelBuffer = 10;
//                        tableData.cellWidth = fittedCellWidth;
//                        if (complianceDashboard !== null) {
//                            complianceDashboard.setDashboardTableGap(30);
//                            complianceDashboard.setWidth(dashboardWidth);
//                        }
//                    }

//                    //} else {
//                    //    if (spaceUsedByTableWithMaxCellTitleTextWidth > maxAvailableWidth - 10) {
//                    //        tableData.labelBuffer = tableLabelBuffer - 10;
//                    //        complianceDashboard.setDashboardTableGap(dashboardTableGap - 20);
//                    //        //complianceDashboard.setWidth(dashboardWidth);
//                    //    } else if (spaceUsedByTableWithMaxCellTitleTextWidth > maxAvailableWidth - 20) {
//                    //        tableData.labelBuffer = tableLabelBuffer - 5;
//                    //        complianceDashboard.setDashboardTableGap(dashboardTableGap - 10);
//                    //        //complianceDashboard.setWidth(dashboardWidth);
//                    //    }
//                    //    //tableData.cellWidth = fittedCellWidth;
//                    //}
//                }
//            }

//        }

//    }

//    function getMaxAvailableWidth(complianceDashboardWidth, compliacenDashboardAndTableBuffer) {
//        if (complianceDashboard !== null) {
//            return oW - (complianceDashboardWidth + complianceDashboard.getBackgroundBuffer() * 2 + compliacenDashboardAndTableBuffer) + 20;
//        } else {
//            return oW;
//        }
//    }

//    function calcVals(tableLabelBuffer) {

//        maxCellTextWidth = getWidthOfText(" 99,999.9 kg ", curFontName, tableFontSize);
//        maxCellTitleText = getLargestCellWidth(tableData);
//        idealCellWidth = maxCellTextWidth + (margin * 8);
//        spaceUsedByTableWithIdealCellWidth = idealCellWidth * numCols + (getLargestLabelWidth(tableData)) + tableLabelBuffer;

//        fittedCellWidth = maxCellTextWidth + ((maxAvailableWidth - (Math.max((maxCellTextWidth, maxCellTitleText) * numCols) + (getLargestLabelWidth(tableData)) + tableLabelBuffer)) / numCols);

//        if (fittedCellWidth < maxCellTextWidth || titlesReduced === true) {
//            fittedCellWidth = maxCellTextWidth;
//        }
//        spaceUsedByTableWithFittedCellWidth = fittedCellWidth * numCols + (getLargestLabelWidth(tableData)) + tableLabelBuffer;
//        spaceUsedByTableWithMaxCellTitleTextWidth = maxCellTitleText * numCols + (getLargestLabelWidth(tableData)) + tableLabelBuffer;

//    }
//    function redeuceTableColTitles() {
//        Object.keys(tableData.cols).forEach(function (key) {
//            if (tableData.cols[key].title) {
//                tableData.cols[key].title = tableData.cols[key].title.replace(config.getTranslationText('532'), '')
//                    .replace(config.getTranslationText('671') + ' ', '')
//                    .replace(config.getTranslationText('153') + ' ', '')
//                    .replace(config.getTranslationText('322') + ' ', '')
//                    .replace(config.getTranslationText('314') + ' ', '')
//                    .replace(config.getTranslationText('467') + ' ', '')
//                    .replace('(', '')
//                    .replace(')', '');
//            }
//        });
//    }
//    function getNumCols() {
//        return Object.keys(tableData.cols).length;
//    }
//    function getLargestCellWidth() {
//        var largestWidth = getWidthOfText(tableData.cols.total.title, curFontName, tableFontSize, true);
//        Object.keys(tableData.cols).forEach(function (key) {
//            if (tableData.cols[key].title) {
//                largestWidth = Math.max(getWidthOfText(tableData.cols[key].title, curFontName, tableFontSize, true), largestWidth);
//            }
//        });

//        Object.keys(tableData.cols.total.cellData).forEach(function (key) {
//            largestWidth = Math.max(getWidthOfText(tableData.cols.total.cellData[key].value, curFontName, tableFontSize), largestWidth);
//        });

//        return largestWidth;
//    }
//    function getLargestLabelWidth(tableData) {
//        var largestWidth = 0;

//        Object.keys(tableData.cols.total.cellData).forEach(function (key) {
//            largestWidth = Math.max(getWidthOfText(tableData.cols.total.cellData[key].label, curFontName, tableFontSize), largestWidth);
//        });
//        return largestWidth;
//    }
//}
function getDisplayObjects() {
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        if (curVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            if (curVehicle.getGraphicBlobByElevation(curElevation) !== null && curVehicle.getGraphicBlobByElevation(curElevation) !== undefined) {

                addVehicleXMLDrawingElements();

            } else {
                vehicleHasGraphic = false;
                populateDrawingObjectsForTemplateVehicle(curVehicle);
            }
        } else {

            populateDrawingObjectsForTemplateVehicle(curVehicle);

        }

        populateDrawingObjectsForChassisObjectEquipment(curVehicle);
        if (curVehicle.getPayloadHolder().getDetailedPayloadItems().length > 0 && curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
            populateDrawingObjectsForChassisObjectPayload(curVehicle);
        }

        addDrawingObjectsForTrailerChassisInclEquipmentAndPayload(config.CHASSIS_OBJECT_TYPES.TRAILER1);
        addDrawingObjectsForTrailerChassisInclEquipmentAndPayload(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
        populateDrawingObjectsForItem(curRenderItem);
        //drawingObjectsArray.push(new Ellipse(1055, 690, 624, 170)); //TEST_ELLIPSE
        //drawingObjectsArray.push(new Circle(1055, 690, 170, 0));
    }
    
    
    function addDrawingObjectsForTrailerChassisInclEquipmentAndPayload(path) {

        var trailer = offerManager.getRigOps().getChassisObjectFromPath(path);

        if (trailer !== null) {
            populateDrawingObjectsForChassisObjectEquipment(trailer);
            if (trailer.getPayloadHolder().getDetailedPayloadItems().length > 0 && trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                populateDrawingObjectsForChassisObjectPayload(trailer);
            }
            //if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
            //    populateDrawingObjectsForTemplateTrailer(trailer);
            //} else {
                if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                    var trailerXMLDoc;
                    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                        trailerXMLDoc = trailer.getDecompressedTopViewGraphicBlob();
                    } else {
                        trailerXMLDoc = trailer.getDecompressedGraphicBlobByElevation(curElevation);
                    }

                    if (trailerXMLDoc === null || (trailerXMLDoc.documentElement && trailerXMLDoc.documentElement.childElementCount <= 1) || (trailer.getType() === config.TRAILER_TYPES.DRAWBAR && curViewType === config.VIEW_TYPE.TURNING_CIRCLE)) {
                        populateDrawingObjectsForTemplateTrailer(trailer);
                    } else {
                        var trailerDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(trailerXMLDoc, trailer.getChassisObjectName(), config.ACCESSORY_TYPES.TRAILER);
                        var axleNumberCounter = trailer.getAxlesHolder().getStartingNumberForAxles()+1;
                        trailerDrawingObjects.forEach(function (drawingObject) {
                            drawingObject.setDrawingOrder(7000);
                            if (drawingObject.type === 'circle' && (drawingObject.circleType === CIRCLE_TYPE.INNER || drawingObject.circleType === CIRCLE_TYPE.OUTER || drawingObject.circleType === Number(CIRCLE_TYPE.INNER) || drawingObject.circleType === Number(CIRCLE_TYPE.OUTER))) {
                                var axleGroup = trailer.getAxlesHolder().getAxleGroupByAxleNumber(axleNumberCounter);
                                var axle = trailer.getAxlesHolder().getAxleByNumber(axleNumberCounter);
                                drawingObject.setDrawingGroupName(trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + axleGroup.getId() + '.' + axle.getNumber());
                                if (drawingObject.circleType === CIRCLE_TYPE.INNER || drawingObject.circleType === Number(CIRCLE_TYPE.INNER)) {
                                    axleNumberCounter++;
                                }
                            }
                        });

                        var trailerTransformation = getTransformationForTrailer(path);
                        if (trailerTransformation !== null) {
                            applyTransformationsToDrawingObjects(trailerDrawingObjects, trailerTransformation);
                        }
                        if (curElevation === config.ELEVATION.TOP || curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                            var drawingGroupName;
                            if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                                drawingGroupName = config.OBJECT_TYPES.TURNING_CIRCLE;
                            } else {
                                drawingGroupName = trailer.getChassisObjectName();
                            }
                            //#unfinished, this code will be needed if we come up with a solution for isolating turntable on standard drawbar trailer
                            //if (trailerTransformation !== null && curTrailer1.getType() === confgi.TRAILER_TYPES.DRAWBAR) {
                            //    setupTurntableTransformation();
                            //}
                            generateTrailerAxlesTopView(trailer, drawingGroupName, turntableTransformation);
                            //addToMainDrawingObjectsArray(axleDrawingObjects);
                        }


                        //drawingObjectsArray = drawingObjectsArray.concat(trailerDrawingObjects);
                        addToMainDrawingObjectsArray(trailerDrawingObjects);
                    }

                }
            //}

        }
    }
    //function addElementsToArray(arrayTo, arrayFrom) {
    //    arrayFrom.forEach(function (element) {
    //        arrayTo.push(element);
    //    });
    //}

    function addVehicleXMLDrawingElements() {
        //translate XML string to XML object
        if (preventDrawingStandardGraphicsInTurningCircleView === false) {
            var decompressedXmlDocToUse;
            if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                decompressedXmlDocToUse = curVehicle.getDecompressedVehicleTopViewDrawing();
            } else {
                decompressedXmlDocToUse = curVehicle.getDecompressedGraphicBlobByElevation(curElevation);
            }
            if (decompressedXmlDocToUse === null || (decompressedXmlDocToUse.documentElement && decompressedXmlDocToUse.documentElement.childElementCount <= 1)) {
                populateDrawingObjectsForTemplateVehicle(curVehicle);
                vehicleHasGraphic = false;
            } else {
                vehicleHasGraphic = true;
                var vehicleDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(decompressedXmlDocToUse, config.CHASSIS_OBJECT_TYPES.VEHICLE, config.CHASSIS_OBJECT_TYPES.VEHICLE);
                adjustDisplayObjectsIfNecessary(vehicleDrawingObjects);
                vehicleDrawingObjects.forEach(function (drawingObject) {
                    if (drawingObject.circleType && (drawingObject.circleType === 1 || drawingObject.circleType === '1') && curElevation === config.ELEVATION.SIDE) {
                        //drawingObject.setDrawingOrder(4000);
                        //drawingObject.setBringToFront(true);
                    } else {
                        drawingObject.setDrawingOrder(9000);
                    }
                    
                });
                if (vehicleTransformation !== null) {
                    applyTransformationsToDrawingObjects(vehicleDrawingObjects, vehicleTransformation);
                }
                if (curElevation === config.ELEVATION.TOP || curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    var drawingGroupName;
                    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                        drawingGroupName = config.OBJECT_TYPES.TURNING_CIRCLE;
                    } else {
                        drawingGroupName = config.CHASSIS_OBJECT_TYPES.VEHICLE;
                    }
                    var axleDrawingObjects = generateVehicleAxlesTopView(curVehicle.getPortionOfOverallLengthOverhangingFront(), drawingGroupName);
                    axleDrawingObjects.forEach(function (drawingObject) {
                        drawingObject.setBypassHitAreaSort(true);
                    });
                    axleDrawingObjects.sort(sortDrawingObjects);
                    axleDrawingObjects = groupDrawingObjects(axleDrawingObjects);
                    
                    axleDrawingObjects.forEach(function (drawingObject) {
                        drawingObject.setDrawingOrder(10000);
                    });
                    
                    addToMainDrawingObjectsArray(axleDrawingObjects);
                }

                //drawingObjectsArray = drawingObjectsArray.concat(vehicleDrawingObjects);
                addToMainDrawingObjectsArray(vehicleDrawingObjects);
                vehicleDrawingObjects.length = 0;
                vehicleDrawingObjects = null;
            }
        }
    }
    

    return drawingObjectsArray;
}

function getTransformationForTrailer(path) {
    if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
        return trailerTransformation;
    } else if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        return trailer2Transformation;
    }
    return null
}

function populateDrawingObjectsForChassisObjectEquipment(chassisObject) {

    var accessoryHolder = chassisObject.getAccessoryHolder();
    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.FRIDGE) === true) {
        //if (accessoryHolder.getFridge().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || accessoryHolder.getFridge().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || accessoryHolder.getFridge().getSourceDatabaseId().toString().indexOf('unknown') !== -1) {
        //    populateDrawingObjectsForTemplateFridge(accessoryHolder.getFridge(), chassisObject);
        //} else {
            //var bodyXMLDoc = translateGraphicToXmlDoc(accessoryHolder.getFridge().getGraphicBlobByElevation(curElevation));

            //if (bodyXMLDoc === null) {
            //    populateDrawingObjectsForTemplateFridge(accessoryHolder.getFridge(), chassisObject);
            //} else {
            //    drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(bodyXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FRIDGE));
            // }
            if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                var decompressedFridgeXMLDocToUse;
                if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    decompressedFridgeXMLDocToUse = accessoryHolder.getFridge().getDecompressedTopViewGraphicBlob();
                } else {
                    decompressedFridgeXMLDocToUse = accessoryHolder.getFridge().getDecompressedGraphicBlobByElevation(curElevation);
                }

                if (decompressedFridgeXMLDocToUse === null || (decompressedFridgeXMLDocToUse.documentElement && decompressedFridgeXMLDocToUse.documentElement.childElementCount <= 1)) {
                    populateDrawingObjectsForTemplateFridge(accessoryHolder.getFridge(), chassisObject);
                } else {
                    var fridgeDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(decompressedFridgeXMLDocToUse, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FRIDGE);
                    fridgeDrawingObjects.forEach(function (drawingObject) {
                        //drawingObject.setDrawingOrder(6000);
                    });
                    //if (vehicleTransformation !== null) {
                    //    applyTransformationsToDrawingObjects(fridgeDrawingObjects, vehicleTransformation);
                    //}

                    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
                        applyTransformationsToDrawingObjects(fridgeDrawingObjects, vehicleTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
                        applyTransformationsToDrawingObjects(fridgeDrawingObjects, trailerTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
                        applyTransformationsToDrawingObjects(fridgeDrawingObjects, trailer2Transformation);
                    }

                    drawingObjectsArray = drawingObjectsArray.concat(fridgeDrawingObjects);

                }
            }

        //}
    }
    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.TAILLIFT) === true) {
        //if (accessoryHolder.getTaillift().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || accessoryHolder.getTaillift().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || accessoryHolder.getTaillift().getSourceDatabaseId().toString().indexOf('unknown') !== -1) {
        //    populateDrawingObjectsForTemplateTaillift(accessoryHolder.getTaillift(), chassisObject);
        //} else {
            //var bodyXMLDoc = translateGraphicToXmlDoc(accessoryHolder.getTaillift().getGraphicBlobByElevation(curElevation));

            //if (bodyXMLDoc === null) {
            //    populateDrawingObjectsForTemplateTaillift(accessoryHolder.getTaillift(), chassisObject);
            //} else {
            //    drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(bodyXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.TAILLIFT));
            // }
            if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                var decompressedTailliftXMLDoc;

                if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    decompressedTailliftXMLDoc = accessoryHolder.getTaillift().getDecompressedTopViewGraphicBlob();
                } else {
                    decompressedTailliftXMLDoc = accessoryHolder.getTaillift().getDecompressedGraphicBlobByElevation(curElevation);
                }

                if (decompressedTailliftXMLDoc === null || (decompressedTailliftXMLDoc.documentElement && decompressedTailliftXMLDoc.documentElement.childElementCount <= 1)) {
                    populateDrawingObjectsForTemplateTaillift(accessoryHolder.getTaillift(), chassisObject);
                } else {
                    var tailliftDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(decompressedTailliftXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.TAILLIFT);
                    tailliftDrawingObjects.forEach(function (drawingObject) {
                        //drawingObject.setDrawingOrder(6000);
                    });
                    //if (vehicleTransformation !== null) {
                    //    applyTransformationsToDrawingObjects(tailliftDrawingObjects, vehicleTransformation);
                    //}

                    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
                        applyTransformationsToDrawingObjects(tailliftDrawingObjects, vehicleTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
                        applyTransformationsToDrawingObjects(tailliftDrawingObjects, trailerTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
                        applyTransformationsToDrawingObjects(tailliftDrawingObjects, trailer2Transformation);
                    }

                    drawingObjectsArray = drawingObjectsArray.concat(tailliftDrawingObjects);

                }
            }

        //}
    }
    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.BODY) === true) {

        //if (accessoryHolder.getBody().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || accessoryHolder.getBody().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || accessoryHolder.getBody().getSourceDatabaseId().toString().indexOf('unknown') !== -1) {
        //    //populateDrawingObjectsForTemplateUniformPayload(accessoryHolder.getBody(), chassisObject);
        //    populateDrawingObjectsForTemplateBody(accessoryHolder.getBody(), chassisObject);

        //} else {

            //var bodyXMLDoc = translateGraphicToXmlDoc(accessoryHolder.getBody().getGraphicBlobByElevation(curElevation));
            //if (bodyXMLDoc === null) {
            //    populateDrawingObjectsForTemplateBody(accessoryHolder.getBody(), chassisObject);
            //} else {
            //    drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(bodyXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.BODY));
            // }
            if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                var bodyXMLDoc;
                if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    bodyXMLDoc = accessoryHolder.getBody().getDecompressedTopViewGraphicBlob();
                } else {
                    bodyXMLDoc = accessoryHolder.getBody().getDecompressedGraphicBlobByElevation(curElevation);
                }

                if (bodyXMLDoc === null || (bodyXMLDoc.documentElement && bodyXMLDoc.documentElement.childElementCount <= 1)) {
                    populateDrawingObjectsForTemplateBody(accessoryHolder.getBody(), chassisObject);
                } else {
                    var bodyDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(bodyXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.BODY);
                    //if (curElevation === config.ELEVATION.TOP) {
                        bodyDrawingObjects.forEach(function (drawingObject) {
                            drawingObject.setDrawingOrder(5000);
                        });
                    //}
                    //if (vehicleTransformation !== null) {
                    //    applyTransformationsToDrawingObjects(bodyDrawingObjects, vehicleTransformation);
                    //}
                    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
                        applyTransformationsToDrawingObjects(bodyDrawingObjects, vehicleTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
                        applyTransformationsToDrawingObjects(bodyDrawingObjects, trailerTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
                        applyTransformationsToDrawingObjects(bodyDrawingObjects, trailer2Transformation);
                    }

                    drawingObjectsArray = drawingObjectsArray.concat(bodyDrawingObjects);

                }
            }
        //}


    }

    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.CRANE) === true) {

        //if (accessoryHolder.getCrane().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || accessoryHolder.getCrane().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || accessoryHolder.getCrane().getSourceDatabaseId().toString().indexOf('unknown') !== -1) {

        //    populateDrawingObjectsForTemplateCrane(accessoryHolder.getCrane(), chassisObject);

        //} else {

            //var bodyXMLDoc = translateGraphicToXmlDoc(accessoryHolder.getCrane().getGraphicBlobByElevation(curElevation));

            //if (bodyXMLDoc === null) {
            //    populateDrawingObjectsForTemplateCrane(accessoryHolder.getCrane(), chassisObject);
            //} else {
            //    drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(bodyXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.CRANE));
            //}
            if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                var craneXMLDoc;
                if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    craneXMLDoc = accessoryHolder.getCrane().getDecompressedTopViewGraphicBlob();
                } else {
                    craneXMLDoc = accessoryHolder.getCrane().getDecompressedGraphicBlobByElevation(curElevation);
                }

                if (craneXMLDoc === null || (craneXMLDoc.documentElement && craneXMLDoc.documentElement.childElementCount <= 1)) {
                    populateDrawingObjectsForTemplateCrane(accessoryHolder.getCrane(), chassisObject);
                } else {
                    var craneDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(craneXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.CRANE);

                    //if (vehicleTransformation !== null) {
                    //    applyTransformationsToDrawingObjects(craneDrawingObjects, vehicleTransformation);
                    //}

                    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
                        applyTransformationsToDrawingObjects(craneDrawingObjects, vehicleTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
                        applyTransformationsToDrawingObjects(craneDrawingObjects, trailerTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
                        applyTransformationsToDrawingObjects(craneDrawingObjects, trailer2Transformation);
                    }

                    drawingObjectsArray = drawingObjectsArray.concat(craneDrawingObjects);

                }
            }
        //}


    }
    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.OTHER) === true) {
        accessoryHolder.getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                
                //if (accessory.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || accessory.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || accessory.getSourceDatabaseId().toString().indexOf('unknown') !== -1) {

                //    populateDrawingObjectsForTemplateOther(accessory, chassisObject);

                //} else {

                    //var accessoryXMLDoc = translateGraphicToXmlDoc(accessory.getGraphicBlobByElevation(curElevation));

                    //if (accessoryXMLDoc === null) {
                    //    populateDrawingObjectsForTemplateOther(accessory, chassisObject);
                    //} else {
                    //    drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(accessoryXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.OTHER, accessory.getId()));
                    //}
                    if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                        var otherXMLDoc;

                        if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                            otherXMLDoc = accessory.getDecompressedTopViewGraphicBlob();
                        } else {
                            otherXMLDoc = accessory.getDecompressedGraphicBlobByElevation(curElevation);
                        }

                        if (otherXMLDoc === null || (otherXMLDoc.documentElement && otherXMLDoc.documentElement.childElementCount <= 1)) {
                            populateDrawingObjectsForTemplateOther(accessory, chassisObject);
                        } else {
                            var otherDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(otherXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.OTHER, accessory.getId());

                            //if (vehicleTransformation !== null) {
                            //    applyTransformationsToDrawingObjects(otherDrawingObjects, vehicleTransformation);
                            //}

                            if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
                                applyTransformationsToDrawingObjects(otherDrawingObjects, vehicleTransformation);
                            } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
                                applyTransformationsToDrawingObjects(otherDrawingObjects, trailerTransformation);
                            } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
                                applyTransformationsToDrawingObjects(otherDrawingObjects, trailer2Transformation);
                            }

                            drawingObjectsArray = drawingObjectsArray.concat(otherDrawingObjects);

                        }
                    }
                //}
            }
        });
    }
    
    
    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.FIFTH_WHEEL) === true) {
        //if (accessoryHolder.getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || accessoryHolder.getFifthWheel().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || accessoryHolder.getFifthWheel().getSourceDatabaseId().toString().indexOf('unknown') !== -1) {
        //    populateDrawingObjectsForTemplateFifthWheel(accessoryHolder.getFifthWheel(), chassisObject);
        //} else {
            
            //var bodyXMLDoc = translateGraphicToXmlDoc(accessoryHolder.getFifthWheel().getGraphicBlobByElevation(curElevation));
            
            //if (bodyXMLDoc === null) {
            //    populateDrawingObjectsForTemplateFifthWheel(accessoryHolder.getFifthWheel(), chassisObject);
            //} else {
            //    drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(bodyXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FIFTH_WHEEL));
            // }
            if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                var fifthWheelXMLDoc;
                if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    fifthWheelXMLDoc = accessoryHolder.getFifthWheel().getDecompressedTopViewGraphicBlob();
                } else {
                    fifthWheelXMLDoc = accessoryHolder.getFifthWheel().getDecompressedGraphicBlobByElevation(curElevation);
                }

                if (fifthWheelXMLDoc === null || (fifthWheelXMLDoc.documentElement && fifthWheelXMLDoc.documentElement.childElementCount <= 1)) {
                    populateDrawingObjectsForTemplateFifthWheel(accessoryHolder.getFifthWheel(), chassisObject);
                } else {
                    var fifthWheelDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(fifthWheelXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FIFTH_WHEEL);
                    //fifthWheelDrawingObjects.forEach(function (drawingObject) {
                    //    drawingObject.setDrawingOrder(8000);
                    //});
                    if (vehicleTransformation !== null) {
                        applyTransformationsToDrawingObjects(fifthWheelDrawingObjects, vehicleTransformation);
                    }
                    if (curTrailer1 !== null && (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                        fifthWheelDrawingObjects.forEach(function (drawingObject) {
                            drawingObject.setBypassHitAreaSort(true);
                        });
                        
                    }

                    drawingObjectsArray = drawingObjectsArray.concat(fifthWheelDrawingObjects);

                }
            }
            
        //}
    }
    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.HITCH) === true) {
        //if (accessoryHolder.getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || accessoryHolder.getHitch().getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || accessoryHolder.getHitch().getSourceDatabaseId().toString().indexOf('unknown') !== -1) {
        //    populateDrawingObjectsForTemplateHitch(accessoryHolder.getHitch(), chassisObject);
        //} else {

            //var hitchXMLDoc = translateGraphicToXmlDoc(accessoryHolder.getHitch().getGraphicBlobByElevation(curElevation));

            //if (hitchXMLDoc === null) {
            //    populateDrawingObjectsForTemplateHitch(accessoryHolder.getHitch(), chassisObject);
            //} else {
            //    drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(hitchXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.HITCH));
            //}
            if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                var hitchXMLDoc;
                if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    hitchXMLDoc = accessoryHolder.getHitch().getDecompressedTopViewGraphicBlob();
                } else {
                    hitchXMLDoc = accessoryHolder.getHitch().getDecompressedGraphicBlobByElevation(curElevation);
                }

                if (hitchXMLDoc === null || (hitchXMLDoc.documentElement && hitchXMLDoc.documentElement.childElementCount <= 1)) {
                    populateDrawingObjectsForTemplateHitch(accessoryHolder.getHitch(), chassisObject);
                } else {
                    var hitchDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(hitchXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.HITCH);
                    //hitchDrawingObjects.forEach(function (drawingObject) {
                    //    //drawingObject.setDrawingOrder(8000);
                    //});
                    if (vehicleTransformation !== null) {
                        applyTransformationsToDrawingObjects(hitchDrawingObjects, vehicleTransformation);
                    }

                    drawingObjectsArray = drawingObjectsArray.concat(hitchDrawingObjects);

                }
            }

        //}
    }
    if (accessoryHolder.hasAccessory(config.ACCESSORY_TYPES.HOOKLIFT) === true) {
        if (preventDrawingStandardGraphicsInTurningCircleView === false) {
            var hookliftXMLDoc;
            if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                hookliftXMLDoc = accessoryHolder.getHooklift().getDecompressedTopViewGraphicBlob();
            } else {
                hookliftXMLDoc = accessoryHolder.getHooklift().getDecompressedGraphicBlobByElevation(curElevation);
            }

            if (hookliftXMLDoc === null || (hookliftXMLDoc.documentElement && hookliftXMLDoc.documentElement.childElementCount <= 1)) {
                populateDrawingObjectsForTemplateHooklift(accessoryHolder.getHooklift(), chassisObject);
            } else {
                var hookliftDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(hookliftXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.HOOKLIFT);
                if (vehicleTransformation !== null) {
                    applyTransformationsToDrawingObjects(hookliftDrawingObjects, vehicleTransformation);
                }
                drawingObjectsArray = drawingObjectsArray.concat(hookliftDrawingObjects);
            }
        }
    }
}
function populateDrawingObjectsForChassisObjectPayload(chassisObject) {

    var payloadHolder = chassisObject.getPayloadHolder();

    payloadHolder.getDetailedPayloadItems().forEach(function (payload) {
        
        //if (payload.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || payload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || payload.getSourceDatabaseId().toString().indexOf('unknown') !== -1) {

        //    populateDrawingObjectsForTemplatePayload(payload, chassisObject);

        // } else {

        //    var payloadXMLDoc = translateGraphicToXmlDoc(payload.getGraphicBlobByElevation(curElevation));

        //    if (payloadXMLDoc === null) {
        //         populateDrawingObjectsForTemplatePayload(payload, chassisObject);
        //     } else {
        //        drawingObjectsArray = drawingObjectsArray.concat(populateDrawingObjectsFromXML(payloadXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.PAYLOAD, payload.getId()));
        //        //var payloadDeckStartPos = getPayloadDeckStartPosition(chassisObject);
        //        drawingObjectsArray.push(getPayloadLabel(payload, chassisObject));
        //     }


        // }
        //if (payload.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || payload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || payload.getSourceDatabaseId().toString().indexOf('unknown') !== -1) {

        //    populateDrawingObjectsForTemplatePayload(payload, chassisObject);

        //} else {
            if (preventDrawingStandardGraphicsInTurningCircleView === false) {
                var payloadXMLDoc;

                if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                    payloadXMLDoc = payload.getDecompressedTopViewGraphicBlob();
                } else {
                    payloadXMLDoc = payload.getDecompressedGraphicBlobByElevation(curElevation);
                }

                if (payloadXMLDoc === null || (payloadXMLDoc.documentElement && payloadXMLDoc.documentElement.childElementCount <= 1)) {
                    populateDrawingObjectsForTemplatePayload(payload, chassisObject);
                } else {
                    var payloadDrawingObjects = populateDrawingObjectsForFullRigItemFromXML(payloadXMLDoc, chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.PAYLOAD, payload.getId());

                    //if (vehicleTransformation !== null) {
                    //    applyTransformationsToDrawingObjects(payloadDrawingObjects, vehicleTransformation);
                    //}

                    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
                        applyTransformationsToDrawingObjects(payloadDrawingObjects, vehicleTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
                        applyTransformationsToDrawingObjects(payloadDrawingObjects, trailerTransformation);
                    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
                        applyTransformationsToDrawingObjects(payloadDrawingObjects, trailer2Transformation);
                    }

                    drawingObjectsArray = drawingObjectsArray.concat(payloadDrawingObjects);

                    var payloadTextObject = getPayloadLabel(payload, chassisObject);
                    drawingObjectsArray.push(payloadTextObject);
                }
            }

        //}
        
    });
    /*
    if (vehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
            vehicle.getPayloadHolder().getDetailedPayloadItems().forEach(function (payload) {

                if (payload.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || payload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || payload.getSourceDatabaseId().toString().indexOf('unknown') !== -1) {

                    populateDrawingObjectsForTemplatePayloadTopView(payload, vehicle, transformation);

                } else {

                    var payloadXMLDoc = translateGraphicToXmlDoc(payload.getGraphicBlobByElevation(curElevation));

                    if (payloadXMLDoc === null) {
                        populateDrawingObjectsForTemplatePayloadTopView(payload, vehicle, transformation);
                    } else {
                        var payloadDrawingObjects = populateDrawingObjectsFromXML(payloadXMLDoc, vehicle.getChassisObjectName(), config.ACCESSORY_TYPES.PAYLOAD, payload.getId());
                        
                        applyTransformationsToDrawingObjects(payloadDrawingObjects, tranformation);
                        drawingObjectsArray = drawingObjectsArray.concat(payloadDrawingObjects);
                        
                        var payloadTextObject = getPayloadLabel(payload, vehicle, transformation);
                        drawingObjectsArray.push(payloadTextObject);
                    }


                }
            });
        }
    */
}

function populateDrawingObjectsForItem(item) {
    var itemXMLDoc;

    //if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //    itemXMLDoc = utils.translateGraphicToXmlDoc(item.getTopViewGraphicBlob());
    //} else {
        itemXMLDoc = item.getDecompressedGraphicBlob(); 
    //}

    if (itemXMLDoc === null || (itemXMLDoc.documentElement && itemXMLDoc.documentElement.childElementCount <= 1)) {
        populateDrawingObjectsForTemplateItem(item);
    } else {
        var itemDrawingObjects = populateDrawingObjectsForItemOnlyFromXML(itemXMLDoc, item, item.getId());
        if (initialItemGraphicAdjustment) {
            zeroLeftMostAndBottomMostOfDrawing(itemDrawingObjects, initialItemGraphicAdjustment.leftMost, initialItemGraphicAdjustment.bottomMost);
            //initialItemGraphicAdjustment = null;
        }
        if (initialItemNegativeGraphicAdjustment) {
            zeroNegativeLeftMostAndBottomMostOfDrawing(itemDrawingObjects, initialItemNegativeGraphicAdjustment.leftMost, initialItemNegativeGraphicAdjustment.bottomMost);
            //initialItemGraphicAdjustment = null;
        }

        // if(positiveOriginAdjustment) {
        //     shiftByPositiveOffset(itemDrawingObjects, positiveOriginAdjustment.xOffset, positiveOriginAdjustment.yOffset);
        // }
        
        
        adjustItemDisplayObjectsIfNecessary(item, itemDrawingObjects);
        drawingObjectsArray = drawingObjectsArray.concat(itemDrawingObjects);

    }
}

function populateDrawingObjectsForTemplateItem(item) {
    switch (item.getAccessoryType()) {
        case config.ACCESSORY_TYPES.CRANE:
        populateDrawingObjectsForTemplateCrane(item);
            break;
    }
}

function getDrawingObjectByObjectSequenceNumber(drawingObjects, objectSequenceNum) {
    var foundObject = null;
    var foundObjectIndex = -1;
    for (var i = 0; i < drawingObjects.length; i++) {
        if (drawingObjects[i].objNumSeqNum === objectSequenceNum) {
            foundObject = drawingObjects[i];
            foundObjectIndex = i;
            break;
        }
    }
    if (foundObject !== null) {
        drawingObjects.splice(foundObjectIndex, 1);
    }
    //drawingObjects.forEach(function (drawingObject) {
    //    if (drawingObject.objNumSeqNum === objectSequenceNum) {
    //        foundObject = drawingObject;
    //    }
    //});
    return foundObject;

    //var indexOfElementToRemove = _accessories.map(function (item) { return item.getId(); }).indexOf(accessory.getId());
    // _accessories.splice(indexOfElementToRemove, 1);
}

function adjustItemDisplayObjectsIfNecessary(item, displayObjects) { 

    var xAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + item.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var yAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + item.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
    var zAxisChange = offerManager.getAttributeGroup('configurationModal' + '.' + item.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ).value.value;

    if(curElevation === config.ELEVATION.SIDE) {
        if(xAxisChange !== 0 || yAxisChange !==0) {
            updateItemDrawingPoints(displayObjects, xAxisChange, yAxisChange);
        }
    } else if (curElevation === config.ELEVATION.TOP) {
        if (xAxisChange !== 0 || zAxisChange !== 0) {
            updateItemDrawingPoints(displayObjects, xAxisChange, zAxisChange);
        }
    }
    
}

function curRenderItemDimensionsChanged() {
    var changed = false;
    if (curRenderItemSize.getHeight() !== curOriginalRenderItemSize.getHeight()) {
        changed = true;
    }
    if (curRenderItemSize.getLength() !== curOriginalRenderItemSize.getLength()) {
        changed = true;
    }
    return changed;
}

function scaleItemDisplayObjects(displayObjects, itemScalingFactor) {

    for (var i = 0; i < displayObjects.length; i++) {

        //var metaObject = displayObjects[i].getMetaObject();
        var metaObject = displayObjects[i];
        if (metaObject.type === "poly") {
            for (var j = 0; j < metaObject.pointsArray.length; j++) {
                metaObject.pointsArray[j].x = metaObject.pointsArray[j].x * itemScalingFactor;
                metaObject.pointsArray[j].y = metaObject.pointsArray[j].y * itemScalingFactor;
            }

        } else if (metaObject.type === "circle") {
            metaObject.centre.x = metaObject.centre.x * itemScalingFactor;
            metaObject.centre.y = metaObject.centre.y * itemScalingFactor;
            metaObject.radius = metaObject.radius * itemScalingFactor;
        } else if (metaObject.type === "arc") {
            var newX = metaObject.centre.x * itemScalingFactor;
            var newY = metaObject.centre.y * itemScalingFactor;

            metaObject.setCentre({ x: newX, y: newY })
            metaObject.radius = metaObject.radius * itemScalingFactor;
        } else if (metaObject.type === "line") {
            metaObject.startPoint.x = metaObject.startPoint.x * itemScalingFactor;
            metaObject.startPoint.y = metaObject.startPoint.y * itemScalingFactor;

            metaObject.endPoint.x = metaObject.endPoint.x * itemScalingFactor;
            metaObject.endPoint.y = metaObject.endPoint.y * itemScalingFactor;
        }
    }

}
function adjustDisplayObjectsIfNecessary(displayObjects) {


    var deltaVal;
    //var needToUpdateDrawing = false;
    var preventRedraw = true;
    var breakPoint;
    if (curVehicle.getAxle1FrontToAxle2Front() !== originalVehicle.getAxle1FrontToAxle2Front()) {
        deltaVal = curVehicle.getAxle1FrontToAxle2Front() - originalVehicle.getAxle1FrontToAxle2Front();
        updateDrawingDimension(displayObjects, config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT, deltaVal, curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() - deltaVal - 5, preventRedraw);// + curVehicle.getBreakPointForAxle1FrontToAxle2FrontFromAxle1Front(), preventRedraw);
    }

    if (curVehicle.getAxle2FrontToAxle1Rear() !== originalVehicle.getAxle2FrontToAxle1Rear()) {
        //needToUpdateDrawing = true;
        deltaVal = curVehicle.getAxle2FrontToAxle1Rear() - originalVehicle.getAxle2FrontToAxle1Rear();
        updateDrawingDimension(displayObjects, config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR, deltaVal, curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getBreakpointForWheelbaseFromRearMostAxleFront(), preventRedraw);
    }

    if (curVehicle.getAxle1RearToAxle2Rear() !== originalVehicle.getAxle1RearToAxle2Rear()) {
        deltaVal = curVehicle.getAxle1RearToAxle2Rear() - originalVehicle.getAxle1RearToAxle2Rear();
                
        if (curVehicle.getAxlesHolder().getAxle2Rear().isAdded()) {
            breakPoint = (curVehicle.getBumperToBackOfCab() + offerManager.getRigOps().getCabProtrusion() + 300);
        } else {
            breakPoint = (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getBreakPointForAxle1RearToAxle2RearFromAxle1Rear());
        }
        //var 
        

        updateDrawingDimension(displayObjects, config.VALUE_TYPE.AXLE1REARTOAXLE2REAR, deltaVal, breakPoint /*- offerManager.getRigOps().getBreakpointAdjustment(config.VALUE_TYPE.AXLE1REARTOAXLE2REAR)*/, preventRedraw);

        //var bpLine = new Line(breakPoint, 0, breakPoint, 700);
        //bpLine.setDrawingGroupName('BreakPoint');
        //bpLine.setColour(config.DRAWING_COLOURS.ORANGE);
        //displayObjects.push(bpLine);
    }
    
    if (curVehicle.getAxle2RearToAxle3Rear() !== originalVehicle.getAxle2RearToAxle3Rear()) {
        deltaVal = curVehicle.getAxle2RearToAxle3Rear() - originalVehicle.getAxle2RearToAxle3Rear();
        
        
        if (curVehicle.getAxlesHolder().getAxle2Rear().isAdded()) {
            breakPoint = (curVehicle.getBumperToBackOfCab() + offerManager.getRigOps().getCabProtrusion() + 300);
        } else {
            breakPoint = (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getBreakPointForAxle2RearToAxle3RearFromAxle2Rear())  /*- offerManager.getRigOps().getBreakpointAdjustment(config.VALUE_TYPE.AXLE2REARTOAXLE3REAR)*/;
        }

        //var 
        

        updateDrawingDimension(displayObjects, config.VALUE_TYPE.AXLE2REARTOAXLE3REAR, deltaVal, breakPoint, preventRedraw);
        //var bpLine = new Line(breakPoint, 0, breakPoint, 700);
        //bpLine.setDrawingGroupName('BreakPoint');
        //bpLine.setColour(config.DRAWING_COLOURS.RED);
        //displayObjects.push(bpLine);
    }

    if (curVehicle.getAxle3RearToAxle4Rear() !== originalVehicle.getAxle3RearToAxle4Rear()) {
        deltaVal = curVehicle.getAxle3RearToAxle4Rear() - originalVehicle.getAxle3RearToAxle4Rear();
        
        
        //if (curVehicle.getAxlesHolder().getAxle2Rear().isAdded()) {
            breakPoint = (curVehicle.getBumperToBackOfCab() + offerManager.getRigOps().getCabProtrusion() + 300);
        //} else {
        //    breakPoint = (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getBreakPointForAxle3RearToAxle4RearFromAxle3Rear())  /*- offerManager.getRigOps().getBreakpointAdjustment(config.VALUE_TYPE.AXLE3REARTOAXLE4REAR)*/;
        //}

        //var 
        

        updateDrawingDimension(displayObjects, config.VALUE_TYPE.AXLE3REARTOAXLE4REAR, deltaVal, breakPoint, preventRedraw);
        //var bpLine = new Line(breakPoint, 0, breakPoint, 700);
        //bpLine.setColour(config.DRAWING_COLOURS.BLUE);
        //bpLine.setDrawingGroupName('BreakPoint');
        //displayObjects.push(bpLine);
    }

    if (curVehicle.getRearOverhang() !== originalVehicle.getRearOverhang()) {
        //needToUpdateDrawing = true;
        deltaVal = curVehicle.getRearOverhang() - originalVehicle.getRearOverhang();

        

            updateDrawingDimension(displayObjects, config.VALUE_TYPE.REAROVERHANG, deltaVal, curVehicle.getFrontOverhang() +
                                    curVehicle.getAxle1FrontToAxle2Front() +
                                    curVehicle.getAxle2FrontToAxle1Rear() +
                                    curVehicle.getAxle1RearToAxle2Rear() +
                                    curVehicle.getAxle2RearToAxle3Rear() +
                                    curVehicle.getAxle3RearToAxle4Rear() +
                                    curVehicle.getBreakpointForRearOverhangFromRearMostAxle(), preventRedraw);
        //updateDrawingDimension(config.VALUE_TYPE.REAROVERHANG, deltaVal, curVehicle.getFrontOverhang() +
        //                        curVehicle.getAxle1FrontToAxle2Front() +
        //                        curVehicle.getAxle2FrontToAxle1Rear() +
        //                        curVehicle.getAxle1RearToAxle2Rear() +
        //                        curVehicle.getBreakpointForRearOverhangFromRearMostAxle(), preventRedraw);
    }

    //var deltaVal;
    ////var needToUpdateDrawing = false;
    //var preventRedraw = true;

    //var axle1FrontToAxle2FrontAdjusted = false, axle2FrontToAxle1RearAdjusted = false, axle1RearToAxle2RearAdjusted = false, axle2RearToAxle3RearAdjusted = false, axle3RearToAxle4RearAdjusted = false, rearOverhangAdjusted = false;

    //if (curVehicle.getAxle1FrontToAxle2Front() !== originalVehicle.getAxle1FrontToAxle2Front()) {
    //    deltaVal = curVehicle.getAxle1FrontToAxle2Front() - originalVehicle.getAxle1FrontToAxle2Front();
    //    updateDrawingDimension(config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT, deltaVal, originalVehicle.getFrontOverhang() + 1, preventRedraw);
    //    axle1FrontToAxle2FrontAdjusted = true;
    //}

    //if (curVehicle.getAxle2FrontToAxle1Rear() !== originalVehicle.getAxle2FrontToAxle1Rear()) {
    //    //needToUpdateDrawing = true;
    //    deltaVal = curVehicle.getAxle2FrontToAxle1Rear() - originalVehicle.getAxle2FrontToAxle1Rear();
    //    updateDrawingDimension(config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR, deltaVal, originalVehicle.getFrontOverhang() + getAxle1FrontToAxle2Front() + originalVehicle.getBreakpointForWheelbaseFromRearMostAxleFront(), preventRedraw);
    //    axle2FrontToAxle1RearAdjusted = true;
    //}

    //if (curVehicle.getAxle1RearToAxle2Rear() !== originalVehicle.getAxle1RearToAxle2Rear()) {
    //    deltaVal = curVehicle.getAxle1RearToAxle2Rear() - originalVehicle.getAxle1RearToAxle2Rear();
    //    if (deltaVal < 0) {
    //        updateDrawingDimension(config.VALUE_TYPE.AXLE1REARTOAXLE2REAR, -deltaVal, originalVehicle.getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear()  + 1, preventRedraw);
    //    } else {
    //        updateDrawingDimension(config.VALUE_TYPE.AXLE1REARTOAXLE2REAR, deltaVal, originalVehicle.getFrontOverhang() + getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + 1, preventRedraw);
    //    }
    //    axle1RearToAxle2RearAdjusted = true;
    //}

    //if (curVehicle.getAxle2RearToAxle3Rear() !== originalVehicle.getAxle2RearToAxle3Rear()) {
    //    deltaVal = curVehicle.getAxle2RearToAxle3Rear() - originalVehicle.getAxle2RearToAxle3Rear();
    //    if (deltaVal < 0) {
    //        updateDrawingDimension(config.VALUE_TYPE.AXLE2REARTOAXLE3REAR, -deltaVal, originalVehicle.getFrontOverhang() +
    //                            getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear()  + 1, preventRedraw);
    //    } else {
    //        updateDrawingDimension(config.VALUE_TYPE.AXLE2REARTOAXLE3REAR, deltaVal, originalVehicle.getFrontOverhang() +
    //                            getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + 1, preventRedraw);
    //    }
    //    axle2RearToAxle3RearAdjusted = true;
    //}

    //if (curVehicle.getAxle3RearToAxle4Rear() !== originalVehicle.getAxle3RearToAxle4Rear()) {
    //    deltaVal = curVehicle.getAxle3RearToAxle4Rear() - originalVehicle.getAxle3RearToAxle4Rear();
    //    if (deltaVal < 0) {
    //        updateDrawingDimension(config.VALUE_TYPE.AXLE3REARTOAXLE4REAR, -deltaVal, originalVehicle.getFrontOverhang() +
    //                            getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + 1, preventRedraw);
    //    } else {
    //        updateDrawingDimension(config.VALUE_TYPE.AXLE3REARTOAXLE4REAR, deltaVal, originalVehicle.getFrontOverhang() +
    //                            getAxle1FrontToAxle2Front() + getAxle2FrontToAxle1Rear() + getAxle1RearToAxle2Rear() + getAxle2RearToAxle3Rear() + 1, preventRedraw);
    //    }
    //    axle3RearToAxle4RearAdjusted = true;
    //}

    //if (curVehicle.getRearOverhang() !== originalVehicle.getRearOverhang()) {
    //    //needToUpdateDrawing = true;
    //    deltaVal = curVehicle.getRearOverhang() - originalVehicle.getRearOverhang();
    //    updateDrawingDimension(config.VALUE_TYPE.REAROVERHANG, deltaVal, originalVehicle.getFrontOverhang() +
    //                            getAxle1FrontToAxle2Front() +
    //                            getAxle2FrontToAxle1Rear() +
    //                            getAxle1RearToAxle2Rear() +
    //                            getAxle2RearToAxle3Rear() +
    //                            getAxle3RearToAxle4Rear() +
    //                            originalVehicle.getBreakpointForRearOverhangFromRearMostAxle(), preventRedraw);
    //}

    //function getAxle1FrontToAxle2Front() {
    //    if (axle1FrontToAxle2FrontAdjusted) {
    //        return curVehicle.getAxle1FrontToAxle2Front();
    //    } else {
    //        return originalVehicle.getAxle1FrontToAxle2Front()
    //    }
    //}

    //function getAxle2FrontToAxle1Rear() {
    //    if (axle2FrontToAxle1RearAdjusted) {
    //        return curVehicle.getAxle2FrontToAxle1Rear();
    //    } else {
    //        return originalVehicle.getAxle2FrontToAxle1Rear();
    //    }
    //}

    //function getAxle1RearToAxle2Rear() {
    //    if (axle1RearToAxle2RearAdjusted) {
    //        return curVehicle.getAxle1RearToAxle2Rear();
    //    } else {
    //        return originalVehicle.getAxle1RearToAxle2Rear()
    //    }
    //}

    //function getAxle2RearToAxle3Rear() {
    //    if (axle2RearToAxle3RearAdjusted) {
    //        return curVehicle.getAxle2RearToAxle3Rear();
    //    } else {
    //        return originalVehicle.getAxle2RearToAxle3Rear();
    //    }
    //}

    //function getAxle3RearToAxle4Rear() {
    //    if (axle3RearToAxle4RearAdjusted) {
    //        return curVehicle.getAxle3RearToAxle4Rear();
    //    } else {
    //        return originalVehicle.getAxle3RearToAxle4Rear();
    //    }
    //}


    //if (needToUpdateDrawing === true) {
    //    updateDrawing();
    //}
}

function refreshDrawing() {
    cleanUp();
    setupSubscriptions();
    setupOrRefreshDrawingComponents();

    window.onresize = function (event) {
        resize();
    }

    if (drawingShowing === false) {
        generateDisplayObjects();
        setupRendering();
        drawingShowing = true;
    }
    try {
        graphicsLayer.refreshDrawing();
    }catch(drawingRefreshEx) {
        TScMessenger.writeDebugMessage('GraphicsManager.js, refreshDrawing, error calling graphicsLayer.refreshDrawing');
    }
    
}

function refreshDrawingOnResize() {
    
    setupOrRefreshDrawingComponents();
    graphicsLayer.refreshDrawing();

}

function updateDrawing(regenerateDisplayObjects, optionalDrawingGroupNameToPulse) {
    
    //graphicsLayer.destroy(true);

    //setupOrRefreshDrawingComponents();

    //if (regenerateDisplayObjects && regenerateDisplayObjects === true) {
    //    generateDisplayObjects();
    //}

    //assignDimensionOffsets(displayObjects);
    //axleLetters = generateAxleLetters(displayObjects);
    ////setupRendering();
    //graphicsLayer.clearDrawingArea();
    //passDislplayObjectsToGraphicsLayer(optionalDrawingGroupNameToPulse);
    

    if (timeAtLastUpdate + 50 < d.getTime() && updateQueue.length === 0) {
        doUpdate(regenerateDisplayObjects, optionalDrawingGroupNameToPulse);
    } else if (updateQueue.length === 0 || optionalDrawingGroupNameToPulse !== undefined) {

        if (optionalDrawingGroupNameToPulse !== undefined) {
            updateQueue.shift();
        }   

        updateQueue.push(new UpdateTask(regenerateDisplayObjects, optionalDrawingGroupNameToPulse));
        setTimeout(function () {
            if (updateQueue.length > 0) {
                var updateTask = updateQueue.shift();
                updateTask.executeUpdate();
            }
        }, 50);
    }

    function UpdateTask(regenerateDisplayObjects, optionalDrawingGroupNameToPulse) {
        this.executeUpdate = function () {
            doUpdate(regenerateDisplayObjects, optionalDrawingGroupNameToPulse);
        }
    }

    
    function doUpdate(regenerateDisplayObjects, optionalDrawingGroupNameToPulse) {

        nextTick(function () {
            //graphicsLayer.destroy(true);

            //setupOrRefreshDrawingComponents();

            //if (regenerateDisplayObjects && regenerateDisplayObjects === true) {
            //    generateDisplayObjects();
            //}

            //assignDimensionOffsets(displayObjects);
            //if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG && (curViewType === config.VIEW_TYPE.OVERVIEW || curViewType === config.VIEW_TYPE.COG)) {
            //    axleLetters = generateAxleLetters(displayObjects);
            //}
            ////setupRendering();
            //graphicsLayer.clearDrawingArea();
            //passDislplayObjectsToGraphicsLayer(optionalDrawingGroupNameToPulse);
            //notifyListenersRenderingComplete();
            //timeAtLastUpdate = d.getTime();
            //graphicsLayer.destroy(true);

            setupOrRefreshDrawingComponents();

            if (regenerateDisplayObjects && regenerateDisplayObjects === true) {
                generateDisplayObjects();
            }

            assignDimensionOffsets(displayObjects);
            if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG && (curViewType === config.VIEW_TYPE.OVERVIEW || curViewType === config.VIEW_TYPE.COG)) {
                axleLetters = generateAxleLetters(displayObjects);
            }
            //setupRendering();
            //graphicsLayer.clearDrawingArea();
            passDislplayObjectsToGraphicsLayer(optionalDrawingGroupNameToPulse);
            notifyListenersRenderingComplete();
            timeAtLastUpdate = d.getTime();
        });

        
    }
    
    
}
//function updateDrawingAfterDimensionChange() {
    
//}

function resize() {
    if (drawingShowing === true) {
        if (resizeTimeoutId !== undefined) {
            clearTimeout(resizeTimeoutId);
        }
        resizeTimeoutId = setTimeout(respondToResize, 1000);
    }
    
}

function respondToResize() {
    oW = curContainer.clientWidth;
    oH = curContainer.clientHeight;
    graphicsLayer.resizeRenderer(oW, oH);
    
    //refreshDrawing();
    needToRedrawInfoArea = true;
    graphicsLayer.cleanUpDraggingVariableState();
    updateDrawing(true);
}

function addWarnings(warnings) {
    var textHeight = getHeightOfText("M", curFontName, notesAndWarningsFontSize, 30);
    graphicsLayer.displayText(warnings, "WARNING", textHeight, warningsWrapWidth);
}

function addNotes(notes) {
    var textHeight = getHeightOfText("M", curFontName, notesAndWarningsFontSize, 30);
    graphicsLayer.displayText(notes, "NOTE", textHeight, notesWrapWidth);
}

function addNotesText() {
    var textHeight = getHeightOfText("M", curFontName, notesAndWarningsFontSize, 30),
        xPos = 0;
    if(tableData.cols.Labels !== undefined){
        xPos = tableData.cols.Labels.position.x;
    } 

    graphicsLayer.displayText(config.getTranslationText('1067'), "NOTESTEXT", textHeight, notesWrapWidth, xPos);
}

function notifyWithoutUpdate(source, value, deltaVal) {
    notify(source, value, deltaVal, true);
}

function notify(source, value, deltaVal, bypassUpdate) {
    if(offerManager.getRigOps().getIsSetup() === true) {
        if (offerManager.getActiveOffer() !== undefined && offerManager.getActiveOffer() !== null && offerManager.getActiveOffer() !== '' && curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
            curTrailer1 = offerManager.getActiveOffer().getRig().getTrailer1();
            curTrailer2 = offerManager.getActiveOffer().getRig().getTrailer2();
            if ((curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW || value === config.VIEW_DETAIL.BRIDGE_VIEW) && source !== 'Menu_Opened' && source !== 'Menu_Closed') {
                //setupBridgeCellConfigs();
            }
        }


        if (drawingShowing === true) {
            if(source === 'Menu_Opened') {
                previousOpenMenuId = curOpenMenuId;
                curOpenMenuId = value;
            }


            TScMessenger.writeDebugMessage("GraphicsManager.js, notify, receiving event from: " + source);
            if (source === config.CONFIGURATION_MENU_SOURCE.DISPLAY) {
                if (curDisplayType !== value) {
                    curDisplayType = value;
                    needToRedrawInfoArea = true;
                    //refreshDrawing();
                    updateDrawing(true);
                }
            } else if (source === config.CONFIGURATION_MENU_SOURCE.VIEW_DETAIL) {
                if (curViewDetail !== value || curViewType !== offerManager.activeConfigurationViewType.value.itemId) {
                    curViewType = offerManager.activeConfigurationViewType.value.itemId;
                    curViewDetail = value;
                    needToRedrawInfoArea = true;
                    //refreshDrawing();
                    if (curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                        preventDrawingStandardGraphicsInTurningCircleView = false;
                    }
                    if (bypassUpdate !== true) {
                        updateDrawing(true);
                    }
                }
            } else if (source === config.CONFIGURATION_MENU_SOURCE.VIEW_TYPE) {
                if (curViewType !== value) {
                    curViewType = value;
                    curViewDetail = offerManager.activeConfigurationViewDetail.value.itemId;
                    //refreshDrawing();
                    if (curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                        preventDrawingStandardGraphicsInTurningCircleView = false;
                    }
                    updateDrawing(true);
                }
            } else if (source === config.CONFIGURATION_MENU_SOURCE.ELEVATION) {
                if (curElevation !== value) {
                    curElevation = value;
                    curViewType = offerManager.activeConfigurationViewType.value.itemId;
                    curViewDetail = offerManager.activeConfigurationViewDetail.value.itemId;
                    if (curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                        preventDrawingStandardGraphicsInTurningCircleView = false;
                    }
                    updateDrawing(true);
                }
            } else if (source === config.CONFIGURATION_MENU_SOURCE.CAB_COLOUR) {
                if (cabColour !== value) {
                    cabColour = value;
                    //refreshDrawing();
                    updateDrawing(true);
                }
            } else if (source === 'previewType') {
                if (curPreviewType !== value) {
                    curPreviewType = value;
                    updateDrawing(true);
                }
            } else if (source === config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR) {
                //if (curVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                //    updateDrawingDimension(source, deltaVal, curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getBreakpointForWheelbaseFromRearMostAxleFront());
                //} else {
                //    updateDrawing(true);
                //    //refreshDrawing();
                //}
                needToRedrawInfoArea = true;
                updateDrawing(true);
            } else if (source === config.VALUE_TYPE.REAROVERHANG) {
                //if (curVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                //    updateDrawingDimension(source, deltaVal, curVehicle.getFrontOverhang() +
                //                        curVehicle.getAxle1FrontToAxle2Front() +
                //                        curVehicle.getAxle2FrontToAxle1Rear() +
                //                        curVehicle.getAxle1RearToAxle2Rear() +
                //                        curVehicle.getAxle2RearToAxle3Rear() +
                //                        curVehicle.getAxle3RearToAxle4Rear() +
                //                        curVehicle.getBreakpointForRearOverhangFromRearMostAxle());
                //} else {
                //    updateDrawing(true);
                //    //refreshDrawing();
                //}
                needToRedrawInfoArea = true;
                updateDrawing(true);
            } else if (source === 'Menu_Closed') {
                //if (globals.eventFiredFromDrawingElement === false) {
                //removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
                //removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
                //removeKeysForEvent('ACTIVE', false);
                //removeKeysForEvent(config.INTERACTION_EVENT.CLICK, true);
                //graphicsLayer.setCanDrag(true);
                //graphicsLayer.setDragDelayPeriod(500);
                graphicsLayer.cleanUpDraggingVariableState();
                previousOpenMenuId = null;
                curOpenMenuId = null;
                setTimeout(function(){
                    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
                    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
                    removeKeysForEvent('ACTIVE', false);
                    removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
                    graphicsLayer.refreshDrawingGroup(value);
                }, 0);
                //}
            
                //graphicsLayer.refreshDrawingGroup();
            } else if (source === 'Menu_Opened') {
                //if (globals.eventFiredFromDrawingElement === false) {
                //removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
                //removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
                //removeKeysForEvent('ACTIVE', false);
                //removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
            
                //interactionState[value + '_' + config.INTERACTION_EVENT.CLICK] = value;
                //interactionState[value + '_' + 'ACTIVE'] = value;
                //    graphicsLayer.refreshDrawingGroup();
                //}
                //graphicsLayer.setCanDrag(false);
                //graphicsLayer.setDragDelayPeriod(2000);
                setTimeout(function () {
                    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
                    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
                    removeKeysForEvent('ACTIVE', false);
                    removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);

                    interactionState[value + '_' + config.INTERACTION_EVENT.CLICK] = value;
                    interactionState[value + '_' + 'ACTIVE'] = value;
                    if(curOpenMenuId) {
                        graphicsLayer.refreshDrawingGroup(curOpenMenuId);
                        if(previousOpenMenuId) {
                            graphicsLayer.refreshDrawingGroup(previousOpenMenuId);
                        }
                    }else {
                        graphicsLayer.refreshDrawingGroup();
                    }
                    
                }, 0);
            } else if (source === 'Clear_Interaction_State') {
                //interactionState = {};
                //graphicsLayer.refreshDrawingGroup();
                //graphicsLayer.refreshTable();
                removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
                removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
                removeKeysForEvent('ACTIVE', false);
                removeKeysForEvent(config.INTERACTION_EVENT.CLICK, true);
            } else if (source === config.OBJECT_TYPES.ACCESSORY) {
                if (deltaVal !== undefined && deltaVal.getAccessoryType !== undefined) {
                    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
                    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
                    removeKeysForEvent('ACTIVE', false);
                    removeKeysForEvent(config.INTERACTION_EVENT.CLICK, true);
                    setupDimensionConfigs();
                    if (deltaVal.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || deltaVal.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
                        needToRedrawInfoArea = true;
                        if (value.indexOf(deltaVal.getId()) === -1) {
                            updateDrawing(true, value + '.' + deltaVal.getId());
                        } else {
                            updateDrawing(true, value);
                        }
                        
                    } else {
                        needToRedrawInfoArea = true;
                        updateDrawing(true, value);
                    }
                } else {
                    needToRedrawInfoArea = true;
                    updateDrawing(true);
                }
            } else if (source === 'Refresh_Table_Data') {
                
                tableMetas = generateTableMetasForActiveLegislations();
                if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
                    tableData = generateMergedBridgeTableMeta(tableMetas);
                } else {
                    tableData = generateMergedTableMeta(tableMetas);//generateTableMeta(curVehicle, curDisplayType, curViewDetail, offerManager.getLesserOfActiveLegislationVals);
                }
                
                graphicsLayer.refreshInfoAreaData(tableData, generateComplianceDashboard());
            } else if(source === 'Clear_All_Interaction_State') {
                clearAllInteractionState();
            } else if (source === config.OBJECT_TYPES.AXLES || source === config.OBJECT_TYPES.UPRIGHTS) {
                cleanUpInteractionState();
                needToRedrawInfoArea = true;
                updateDrawing(true);
            } else if (source === 'preventDrawingStandardGraphicsInTurningCircleView') {
                preventDrawingStandardGraphicsInTurningCircleView = false;
            } else if (source === 'canvas_resized') {
                oW = value.width;
                oH = value.height;

            } else if (source === 'Save_Current_Item_Graphic') {
                return updateGraphicObjectsInXMLAndConvertBackToGraphic(curRenderItem, drawingObjectsArray, value);
            } else if (source === 'Item_X_Offset_Changed') {
                
                //if (!offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override() && !offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override()) {
                    curRenderItemSize.setLength(curRenderItemSize.getLength() + value);
                    offerManager.getOriginalConfigurationWizardItemSize().setLength(curRenderItemSize.getLength());
                //} else {
                //    //curRenderItemSize.setLength((curRenderItemSize.getLength() + value) / scalingFactor);
                //}
                
                //itemDrawingMeta.widthInDrawingUnits = curRenderItem.getLength();
            } else if (source === 'Item_Y_Offset_Changed') {
                
                //if (!offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGLENGTH).override() && !offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGHEIGHT).override()) {
                    curRenderItemSize.setHeight(curRenderItemSize.getHeight() + value);
                    offerManager.getOriginalConfigurationWizardItemSize().setHeight(curRenderItemSize.getHeight());
                //} else {
                //    //curRenderItemSize.setHeight((curRenderItemSize.getHeight() + value) / scalingFactor);
                //}
                //itemDrawingMeta.heightInDrawingUnits = curRenderItem.getHeight();
            } else if (source === 'Item_Z_Offset_Changed') {
                curRenderItemSize.setWidth(curRenderItemSize.getWidth() + value);
                offerManager.getOriginalConfigurationWizardItemSize().setWidth(curRenderItemSize.getWidth());

            } else if (source === 'Focus_Event') {
                if (value !== curWizardAttributePath) {
                    curWizardAttributePath = value;
                    updateDrawing(true);
                }
            } else if (source === 'Scaling_Dimension_Changed') {
                itemScalingDimensionChangedWithoutOverrideFlag = value;
            } else if (source === 'Combination_Changed') {
                var combinationType = offerManager.getRigOps().getCombinationType();
                if (combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR) {
                    graphicsLayer.notifyDimensionLayerChange(config.DIMENSION_LAYER_CHANGE.TOP_TO_BOTTOM);
                } else if (combinationType === config.COMBINATION_TYPES.VEHICLE) {
                    graphicsLayer.notifyDimensionLayerChange(config.DIMENSION_LAYER_CHANGE.BOTTOM_TO_TOP);
                }
                
            } else if (source === 'Reset_InitialiseCalled_Flag') {
                initialiseCalled = false;
            } else if (source === config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN) {
                
                complianceDashboard.updateActiveViewPath(value);
                
                needToRedrawInfoArea = true;
                graphicsLayer.cleanUpDraggingVariableState();
                updateDrawing(true);

                //console.time();
                //tableMetas = generateTableMetasForActiveLegislations();
                //if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
                //    tableData = generateMergedBridgeTableMeta(tableMetas);
                //} else {
                //    tableData = generateMergedTableMeta(tableMetas);//generateTableMeta(curVehicle, curDisplayType, curViewDetail, offerManager.getLesserOfActiveLegislationVals);
                //}
                //console.timeEnd();
                //graphicsLayer.refreshInfoAreaData(tableData, generateComplianceDashboard());
            } else if (source === config.INTERACTION_ID.COMPLIANCE_DASHBOARD_CRUMB) {
                var activeViewPath = complianceDashboard.getActiveViewPath();
                var viewPathParts = activeViewPath.split('.');
                var i = 0;
                var newActiveViewPath;
                while (viewPathParts[i] !== value) {
                    newActiveViewPath += viewPathParts[i];
                    if (i - 1 < viewPathParts.length) {
                        newActiveViewPath += '.';    
                    }
                    i++;
                }
                complianceDashboard.setActiveViewPath(newActiveViewPath || value);

                needToRedrawInfoArea = true;
                graphicsLayer.cleanUpDraggingVariableState();
                updateDrawing(true);

                //console.time();
                //tableMetas = generateTableMetasForActiveLegislations();
                //if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
                //    tableData = generateMergedBridgeTableMeta(tableMetas);
                //} else {
                //    tableData = generateMergedTableMeta(tableMetas);//generateTableMeta(curVehicle, curDisplayType, curViewDetail, offerManager.getLesserOfActiveLegislationVals);
                //}
                //console.timeEnd();
                //graphicsLayer.refreshInfoAreaData(tableData, generateComplianceDashboard());
            } else if (source === 'Legislations_Changed') {
                if (complianceDashboard !== null) {
                    if (complianceDashboard.getActiveViewPath().indexOf(config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE_INSTANCE) !== -1) {
                        complianceDashboard.moveUpOneLevelOfViewPath();
                    }
                }
            } else if (source === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
                removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
                removeKeysForEvent('ACTIVE', false);
                removeKeysForEvent(config.INTERACTION_EVENT.CLICK, true);

                needToRedrawInfoArea = true;
                //graphicsLayer.cleanUpDraggingVariableState();
                updateDrawing(true);
            }
            
            /*else if (source === 'ITEM_DELETED') {
                graphicsLayer.removeGraphicsObject(value);
                graphicsLayer.cleanUpDraggingVariableState();
                updateDrawing(true);
            }*/ 
            else {
                needToRedrawInfoArea = true;
                graphicsLayer.cleanUpDraggingVariableState();
                updateDrawing(true);
            }
        } else if (source === 'Clear_All_Interaction_State') {
            clearAllInteractionState();
        }
    } else if (source === 'Clear_All_Interaction_State') {
        clearAllInteractionState();
    }
}

function notifyListenersRenderingComplete() {
    var renderingCompleteListeners = offerManager.getRenderingCompleteCallbackListeners();
    if (renderingCompleteListeners.length > 0) {
        renderingCompleteListeners.forEach(function (listenerCallback) {
            try {
                listenerCallback();
            } catch (listenerCallbackEx) {
                TScMessenger.writeDebugMessage('GraphicsManager.js, notifyListenersRenderingComplete, error calling listenerCallback');
            }
        });
    }
}

function cleanUp(keepGraphicsLayer) {
    //interactionState = {};
    drawingShowing = false;
    preventDrawingStandardGraphicsInTurningCircleView = false;
    //if (initialiseCalled) {
        cleanUpInteractionState();
        disposeSubscriptions();
        cleanUpGraphicsManager();
        if (!keepGraphicsLayer) {
            graphicsLayer.destroy(true);
        }
        stopRendering();
        initialiseCalled = false;
        window.onresize = null;
    //}
    
    
}
function cleanUpDisplayObjects() {
    displayObjects.forEach(function (displayObject) {
        if (displayObject.type === 'line' || displayObject.type === 'poly' || displayObject.type === 'circle' || displayObject.type === 'hitArea') {
            utils.deleteObjectMembers(displayObject);
        } else {
            var breakHere = 0;
        }
    });
    utils.deleteObjectMembers(displayObjects);
    displayObjects = [];
}
function cleanUpDimensions() {
    dimensions.forEach(function (dimension) {
        utils.deleteObjectMembers(dimension);
    });
    utils.deleteObjectMembers(dimensions);
    dimensions = [];
}
function cleanUpTableDefinitionData() {
    utils.deleteObjectMembers(tableData);
}
function cleanUpGraphicsManager() {
    
    cleanUpDisplayObjects();
    cleanUpDimensions();
    cleanUpTableDefinitionData();

    utils.deleteObjectMembers(drawingObjectsArray);

    utils.deleteObjectMembers(dimensionConfigs);
    utils.deleteObjectMembers(cellConfigs);
    
    drawingShowing = false;
    pdfGeneratedWhileDrawingShowing = false;
    
    curDisplayType = '';
    curViewDetail = '';
    curViewType = '';
    curElevation = '';
    curPreviewType = '';

    curFontName = null;
    curMeasurementSystem = null;
    cabColour = null;
    isPdfDrawing = false;

    curContainer = null;
    curVehicle = null;
    originalVehicle = null;
    curTrailer1 = null;
    curTrailer2 = null;
    curRenderItem = null;
    curOriginalRenderItem = null;
    curRenderItemSize = null;
    curOriginalRenderItemSize = null;
    
    
    utils.deleteObjectMembers(axleLetters);
    if (globals.getNavigatingHome()) {
        utils.deleteObjectMembers(complianceDashboard);
        complianceDashboard = null;
    }

    notes = null;
    warnings = null;

    resizeTimeoutId = null;
    updatingDrawTrailWaterMarkInternally = false;

    vehicleHasGraphic = false;

    //utils.deleteObjectMembers(interactionState);

    curWizardAttributePath = null;

    updateQueue = [];
    
    vehicleTransformation = null;
    trailerTransformation = null;
    trailer2Transformation = null;
    turntableTransformation = null;
    vehicleFrontLeftTyresTransformation = null;
    vehicleFrontRightTyresTransformation = null;
    vehicleRearAxle1TyreTransformations = null;
    vehicleRearAxle2TyreTransformations = null;
    vehicleRearAxle3TyreTransformations = null;
    vehicleRearAxle4TyreTransformations = null;
    

    preventDrawingStandardGraphicsInTurningCircleView = false;

    utils.deleteObjectMembers(colLetters);
    utils.deleteObjectMembers(groupLetters);
    utils.deleteObjectMembers(letterColourAllocations);

    initialItemGraphicAdjustment = null;
    initialItemNegativeGraphicAdjustment = null;
    scalingDimensionPlane = null;
    itemScalingFactor = null;
    itemDrawingMeta = null;
    itemScalingDimensionChangedWithoutOverrideFlag = config.SCALING_DIMENSION_TYPES.NONE;
}
function attachOnResizeEvent() {
    window.onresize = function (event) {
        resize();
    };
}

function detachOnResizeEvent() {
    window.onresize = null;
}

function cleanUpInteractionState() {
    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OVER, false);
    removeKeysForEvent(config.INTERACTION_EVENT.MOUSE_OUT, false);
    removeKeysForEvent('ACTIVE', false);
    removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
}

function clearAllInteractionState() {
    interactionState = {};
}

function getCellPosition(cellId, returnTableTopYPosition) {
    return graphicsLayer.getCellPosition(cellId, returnTableTopYPosition);
}

function getAxleXYPosition(axlePosition, path) {

    var position = { x: 0, y: 0 };
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(path);

    
    
    
    if (curElevation === config.ELEVATION.SIDE) {
        var mainDrawingAreaTopLeftPos = graphicsLayer.getMainTopLeftCornerPos();
        position.x += mainDrawingAreaTopLeftPos.x + inDrawingDimensionLayerOffsetX;
        position.y += mainDrawingAreaTopLeftPos.y + inDrawingDimensionLayerOffsetY + (mH - (getAxleLetterCentreYByPath(path)*scalingFactor));
    } else {
        var topElevationAxleLetterRowTopLeftPos = graphicsLayer.getTopElevationAxleLetterRowTopLeftPos();
        position.x += topElevationAxleLetterRowTopLeftPos.x + inDrawingDimensionLayerOffsetX;
        position.y += topElevationAxleLetterRowTopLeftPos.y;
    }
    

    if(chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        switch (axlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_FRONT:
                position.x += curVehicle.getFrontOverhang() * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_2_FRONT:
                position.x += (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front()) * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                position.x += (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear()) * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                position.x += (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear()) * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                position.x += (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear()) * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                position.x += (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear()) * scalingFactor;
                break;
        }
    } else {

        position.x += offerManager.getRigOps().getTrailerFrontOverhangStart(curTrailer1.getChassisObjectName()) * scalingFactor;

        switch (axlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_FRONT:
                position.x += curTrailer1.getFrontOverhang() * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_2_FRONT:
                position.x += (curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front()) * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_3_FRONT:
                position.x += (curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front()) * scalingFactor;
                break;
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) { 
                    position.x += (curTrailer1.getHitchPointToAxle1Rear() - curTrailer1.getDrawbarLength()) * scalingFactor;
                } else {
                    position.x += (curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear()) * scalingFactor;
                }
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    position.x += (curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() - curTrailer1.getDrawbarLength()) * scalingFactor;
                } else {
                    position.x += (curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear()) * scalingFactor;
                }
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    position.x += (curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() - curTrailer1.getDrawbarLength()) * scalingFactor;
                } else {
                    position.x += (curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear()) * scalingFactor;
                }
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    position.x += (curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() + curTrailer1.getAxle3RearToAxle4Rear() - curTrailer1.getDrawbarLength()) * scalingFactor;
                } else {
                    position.x += (curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() + curTrailer1.getAxle3RearToAxle4Rear()) * scalingFactor;
                }
                break;
        }
    }
    

    return position;
}

function getAxleLetterCentreYByPath(path) {
    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        return axleLetters[0].centre.y;
    } else {
        return axleLetters[axleLetters.length-1].centre.y;
    }
}

function getComplianceDashboardPosition() {
    return graphicsLayer.getComplianceDashboardPosition();
}

function getLengthFromDrawing(item) {
    var itemXMLDoc = item.getDecompressedGraphicBlob();//utils.translateGraphicToXmlDoc(item.getGraphicBlob());

    if (itemXMLDoc === null || (itemXMLDoc.documentElement && itemXMLDoc.documentElement.childElementCount <= 1)) {
        return 0;
    } else {

        var drawingGroupName = "configurationModal" + '.' + item.getAccessoryType();
        var layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.NONE;

        
        var itemDrawingObjects = getDrawingObjectsFromXML(itemXMLDoc, 0, 0, undefined, drawingGroupName, layeringAdjustmentFlag, true);

        var maxBounds;
        if (item.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            maxBounds = getMaxBoundsForGroup('configurationModal' + '.' + item.getAccessoryType(), itemDrawingObjects, config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY, false, false);
        } else {
            maxBounds = getMaxBoundsForGroup('configurationModal' + '.' + item.getAccessoryType(), itemDrawingObjects, config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY, true, true);
        }
        return maxBounds.width;
    }
}

function getHeightFromDrawing(item) {
    var itemXMLDoc = item.getDecompressedGraphicBlob();

    if (itemXMLDoc === null || (itemXMLDoc.documentElement && itemXMLDoc.documentElement.childElementCount <= 1)) {
        return 0;
    } else {

        var drawingGroupName = "configurationModal" + '.' + item.getAccessoryType();
        var layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.NONE;


        var itemDrawingObjects = getDrawingObjectsFromXML(itemXMLDoc, 0, 0, undefined, drawingGroupName, layeringAdjustmentFlag, true);

        var maxBounds;
        if (item.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
            maxBounds = getMaxBoundsForGroup('configurationModal' + '.' + item.getAccessoryType(), itemDrawingObjects, config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY, false, false);
        } else {
            maxBounds = getMaxBoundsForGroup('configurationModal' + '.' + item.getAccessoryType(), itemDrawingObjects, config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY, true, true);
        }

        return maxBounds.height;
    }
}

var graphicsManager = {
    renderDrawing: renderDrawing,
    addWarnings: addWarnings,
    addNotes: addNotes,
    notify: notify,
    cleanUp: cleanUp,
    getDrawingAsDataURL: getDrawingAsDataURL,
    getCurContainer: getCurContainer,
    getCellPosition: getCellPosition,
    getComplianceDashboardPosition: getComplianceDashboardPosition,
    notifyWithoutUpdate: notifyWithoutUpdate,
    clearAllInteractionState: clearAllInteractionState,
    stopRendering: stopRendering,
    getAxleXYPosition: getAxleXYPosition,
    updateGraphicObjectsInXMLAndConvertBackToGraphic: updateGraphicObjectsInXMLAndConvertBackToGraphic,
    getLengthFromDrawing: getLengthFromDrawing,
    getHeightFromDrawing: getHeightFromDrawing,
    getLeftMostBottomMostPointForItemDrawing: getLeftMostBottomMostPointForItemDrawing,
    attachOnResizeEvent: attachOnResizeEvent,
    detachOnResizeEvent: detachOnResizeEvent,
    getNegativePortionOfDrawing: getNegativePortionOfDrawing,
    getOriginOffsetsFromDrawing: getOriginOffsetsFromDrawing
}


export default graphicsManager;


function getDrawingAsDataURL() {
    return graphicsLayer.getDrawingAsDataURL();
}

function getHeightOfText(txt, fontname, fontsize, width) {
    //// Create a dummy canvas (render invisible with css)
    //var span = document.createElement("span");
    //span.innerHTML = txt; //or whatever string you want.
    //span.style.fontFamily = fontname;
    //span.style.fontSize = fontsize;

    //return span.offsetHeight;

    var body = document.getElementsByTagName('body')[0];
    var dummy = document.createElement('div');
    //dummy.setAttribute("style","width:" + width + "px");
    var dummyText = document.createTextNode(txt);
    dummy.appendChild(dummyText);
    var fontStyle = "font-size:" + fontsize + "px;font-family:" + fontname;
    dummy.setAttribute('style', fontStyle + ';display:block; width:' + width + "px;");
    body.appendChild(dummy);
    var height = dummy.offsetHeight;
    body.removeChild(dummy);
    return height;
}

function unpackText(textArray) {
    var unpackedText = '';

    for (var i = 0; i < textArray.length; i++) {
        if (i >= textArray.length -1) {
            unpackedText += textArray[i];
        } else {
            unpackedText += textArray[i] + '; ';
        }
    }
    //textArray.forEach(function (textItem) {
    //    unpackedText += textItem + '; ';
    //});
    return unpackedText;
}

function getWidthOfText(txt, fontname, fontsize, isBold) {
    // Create a dummy canvas (render invisible with css)
    var c = document.createElement('canvas');
    // Get the context of the dummy canvas
    var ctx = c.getContext('2d');
    // Set the context.font to the font that you are using
    if (isBold) {
        ctx.font = 'bold ' + fontsize + 'px ' + fontname;
    } else {
        ctx.font = fontsize + 'px ' + fontname;
    }
    
    // Measure the string 
    // !!! <CRUCIAL>  !!!
    var length = ctx.measureText(txt).width;
    // !!! </CRUCIAL> !!!
    // Return width
    return length;
}

function getTotalTextLength(text) {
    var totalWidth = 0;
    for (var i = 0; i < text.length; i++) {
        totalWidth += getWidthOfText(text[i], curFontName, notesAndWarningsFontSize);
    }
    return totalWidth;
}

function updateItemDrawingPoints(displayObjects, incrementHorizontalAxis, incrementVerticalAxis) { 
    for (var i = 0; i < displayObjects.length; i++) {
        
        //var metaObject = displayObjects[i].getMetaObject();
        var metaObject = displayObjects[i];
        if (metaObject.type === "poly") {
            for (var j = 0; j < metaObject.pointsArray.length; j++) {
                metaObject.pointsArray[j].x += incrementHorizontalAxis;
                metaObject.pointsArray[j].y += incrementVerticalAxis;
            }

        } else if (metaObject.type === "circle") {
            metaObject.centre.x += incrementHorizontalAxis;
            metaObject.centre.y += incrementVerticalAxis;

        } else if (metaObject.type === "ellipse") {
            metaObject.centre.x += incrementHorizontalAxis;
            metaObject.centre.y += incrementVerticalAxis;

        } else if (metaObject.type === "arc") {
            var newX = metaObject.centre.x + incrementHorizontalAxis;
            var newY = metaObject.centre.y + incrementVerticalAxis;
                
            metaObject.setCentre({ x: newX, y: newY })

        } else if (metaObject.type === "line") {
            metaObject.startPoint.x += incrementHorizontalAxis;
            metaObject.startPoint.y += incrementVerticalAxis;

            metaObject.endPoint.x += incrementHorizontalAxis;
            metaObject.endPoint.y += incrementVerticalAxis;
        }
    }

}
function updateItemDrawingPointsB(displayObjects, incrementHorizontalAxis, incrementVerticalAxis) {
    for (var i = 0; i < displayObjects.length; i++) {

        //var metaObject = displayObjects[i].getMetaObject();
        var metaObject = displayObjects[i];
        if (metaObject.type === "poly") {
            for (var j = 0; j < metaObject.pointsArray.length; j++) {
                metaObject.pointsArray[j].x += incrementHorizontalAxis;
                metaObject.pointsArray[j].y += incrementVerticalAxis;
            }

        } else if (metaObject.type === "circle") {
            metaObject.centre.x += incrementHorizontalAxis;
            metaObject.centre.y += incrementVerticalAxis;

        } else if (metaObject.type === "ellipse") {
            metaObject.centre.x += incrementHorizontalAxis;
            metaObject.centre.y += incrementVerticalAxis;

        } else if (metaObject.type === "arc") {
            var newX = metaObject.centre.x + incrementHorizontalAxis;
            var newY = metaObject.centre.y + incrementVerticalAxis;

            metaObject.setCentre({ x: newX, y: newY })

        } else if (metaObject.type === "line") {
            metaObject.startPoint.x += incrementHorizontalAxis;
            metaObject.startPoint.y += incrementVerticalAxis;

            metaObject.endPoint.x += incrementHorizontalAxis;
            metaObject.endPoint.y += incrementVerticalAxis;
        }
    }

}



function updateDrawingDimension(displayObjects, source, increment, breakPoint, preventRedraw) {

var needToRedraw = false;
//var displayMetaObjects = [];
if (source === config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR || source === config.VALUE_TYPE.REAROVERHANG || source === config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT ||
    source === config.VALUE_TYPE.AXLE1REARTOAXLE2REAR || source === config.VALUE_TYPE.AXLE2REARTOAXLE3REAR || source === config.VALUE_TYPE.AXLE3REARTOAXLE4REAR) {
    for (var i = 0; i < displayObjects.length; i++) {
        
        //var metaObject = displayObjects[i].getMetaObject();
        var metaObject = displayObjects[i];
        if (metaObject.type === "poly" && metaObject.drawingGroupName === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            for (var j = 0; j < metaObject.pointsArray.length; j++) {
                if (metaObject.pointsArray[j].x - curVehicle.getPortionOfOverallLengthOverhangingFront() > breakPoint) {
                    if (needToRedraw === false) {
                        //overallVehicleLength += increment;
                        needToRedraw = true;
                    }
                    metaObject.pointsArray[j].x += increment;

                }
            }

        } else if (metaObject.type === "circle" && (metaObject.circleType === "0" || metaObject.circleType === 0) && metaObject.drawingGroupName === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            if (metaObject.centre.x - curVehicle.getPortionOfOverallLengthOverhangingFront() > breakPoint) {
                if (needToRedraw === false) {
                    //overallVehicleLength += increment;
                    needToRedraw = true;
                }

                metaObject.centre.x += increment;

            }

        } else if (metaObject.type === "arc" && metaObject.drawingGroupName === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            if (metaObject.centre.x - curVehicle.getPortionOfOverallLengthOverhangingFront() > breakPoint) {
                if (needToRedraw === false) {
                    //overallVehicleLength += increment;
                    needToRedraw = true;
                }
                var newX = metaObject.centre.x + increment;
                
                metaObject.setCentre({ x: newX, y: metaObject.centre.y })

            }

        } else if (metaObject.type === "line" && metaObject.drawingGroupName === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            if (metaObject.startPoint.x - curVehicle.getPortionOfOverallLengthOverhangingFront() > breakPoint) {
                if (needToRedraw === false) {
                    //overallVehicleLength += increment;
                    needToRedraw = true;
                }
                metaObject.startPoint.x += increment;

            }
            if (metaObject.endPoint.x - curVehicle.getPortionOfOverallLengthOverhangingFront() > breakPoint) {
                if (needToRedraw === false) {
                    //overallVehicleLength += increment;
                    needToRedraw = true;
                }
                metaObject.endPoint.x += increment;

            }
        }
        //displayMetaObjects.push(metaObject);
    }
}
if (preventRedraw === undefined || preventRedraw === false) {
    if (needToRedraw === true) {
        /*//drawingShowing = false;
        graphicsLayer.destroy(true);
        setupOrRefreshDrawingComponents();
        axleLetters = generateAxleLetters(displayObjects);
        //setupRendering();
        graphicsLayer.clearDrawingArea();
        passDislplayObjectsToGraphicsLayer();*/
        updateDrawing(true);
    }
}

}

function getFifthWheelOffset() {
var fifthWheelOffset = 0;
if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
    fifthWheelOffset = curVehicle.getFifthWheelOffset();
} else if (curVehicle.getAccessoryHolder().getFifthWheel() !== null) {
    fifthWheelOffset = curVehicle.getAccessoryHolder().getFifthWheel().getOffset();
}
return fifthWheelOffset;
}

function getFifthWheelDimensionStart() {
var fifthWheelOffset = getFifthWheelOffset();

if (fifthWheelOffset < 0) {
    return dimensionXOffset + (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical());
} else {
    return dimensionXOffset + (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical()) - fifthWheelOffset;
}
}

function getFifthWheelDimensionLength() {
var fifthWheelOffset = getFifthWheelOffset();
if (fifthWheelOffset < 0) {
    return -fifthWheelOffset;
} else {
    return fifthWheelOffset;
}
}

function getBodyCOGToRearAxlesStart(attrContext) {
    if(attrContext === undefined) {
        if (curVehicle.getBodyCOGToRearAxles() > 0) {
            return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                            curVehicle.getWheelbaseTheoretical() - curVehicle.getBodyCOGToRearAxles();
        } else {
    
            return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                            curVehicle.getWheelbaseTheoretical();
        }
    }   else {
        return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.BODY) + offerManager.getRigOps().getBodyFromPath(attrContext).getActualHorizontalCOG();
    }
    
}
function getBodyCOGToRearAxlesLength(attrContext) {
    
    if(attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return curVehicle.getBodyCOGToRearAxles();
    }  else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        let tempBody = offerManager.getRigOps().getBodyFromPath(attrContext);
        return  (curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + curTrailer1.getWheelbaseTheoretical()) - (tempBody.getHorizontalGap() + tempBody.getActualHorizontalCOG());
    } else {
        let tempBody = offerManager.getRigOps().getBodyFromPath(attrContext);
        return  (curTrailer2.getFrontOverhang() + curTrailer2.getAxle1FrontToWheelbaseTheoreticalStart() + curTrailer2.getWheelbaseTheoretical()) - (tempBody.getHorizontalGap() + tempBody.getActualHorizontalCOG());
    }
}

function getRearOverhangDimensionStart() {
//if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && curVehicle.getCompleteRearOverhang() > 0) {
//    return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear();
//} else {
    return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear(); 
//}
}

function getRearOverhangDimensionLength() {
if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && curVehicle.getCompleteRearOverhang() > 0) {
    return curVehicle.getCompleteRearOverhang();
} else {
    return curVehicle.getRearOverhang();
}
}

function cogFromWheelbaseTheoreticalAtRearStart() {
if (curVehicle.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    var tempBody = curVehicle.getBody();
    var hookliftOffset = 0;
    if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        var tempHooklift = curVehicle.getAccessoryHolder().getHooklift();
        hookliftOffset = tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
    }
    return dimensionXOffset + (curVehicle.getBumperToBackOfCab() + hookliftOffset + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness() + curVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG());
} else {
    return dimensionXOffset + (curVehicle.getFrontOfVehicleToPayloadDeckStart() + curVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG());
}
}

function cogFromWheelbaseTheoreticalAtRearLength() {
if (curVehicle.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    var tempBody = curVehicle.getBody();
    var hookliftOffset = 0;
    if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        var tempHooklift = curVehicle.getAccessoryHolder().getHooklift();
        hookliftOffset = tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
    }
    return (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical()) - (curVehicle.getBumperToBackOfCab() + hookliftOffset + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness() + curVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG());
} else {
    return (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical()) - (curVehicle.getFrontOfVehicleToPayloadDeckStart() + curVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG());
}
}

function getBodyLengthInternalStart(attrContext) { 
if (attrContext === undefined) {
    if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        return dimensionXOffset + curVehicle.getBumperToStartOfBodyInternal();
    } else {
        return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY) + curVehicle.getBody().getHeadboardThickness();
    }
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1 || attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
    var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.BODY) + trailer.getAccessoryHolder().getBody().getHeadboardThickness();
}

return 0;
}

function getBodyLengthInternalLength(attrContext) {
if (attrContext === undefined) {
    if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        return curVehicle.getBodyLengthInternal();
    } else {
        return curVehicle.getBody().getActualInternalLength();
    }
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getActualInternalLength();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getActualInternalLength();
}

}

function getBodyLengthExternalIncludingRearDoorsStart() {
if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    return dimensionXOffset + curVehicle.getBumperToBackOfCab() + curVehicle.getCabGapRecommendedMin();
} else {
    return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY);
}
}

function getBodyLengthExternalIncludingRearDoorsLength() {
if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    return curVehicle.getBodyLengthExternalInclRearDoors();
} else {
    return curVehicle.getBody().getActualLength();
}
}

function getRearOfBodyToRearOfVehicleStart() {
return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY) + curVehicle.getBody().getActualLength();
}


function getBodyHeightStart(attrContext) {
if (attrContext === undefined) {
    if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        return curVehicle.getFloorHeight();
    } else {
        var tempBody = curVehicle.getBody();
        if(tempBody.getType() === config.BODY_TYPES.SERVICE) {
            return offerManager.getRigOps().getItemStartFromGround(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY) + tempBody.getBottomOfBodyToTopOfChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight()
        }else {
            return offerManager.getRigOps().getItemStartFromGround(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY) + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
        }
        
    }
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return getBodySubstructureHeightStart(attrContext) + curTrailer1.getAccessoryHolder().getBody().getSubstructureHeight();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
    return getBodySubstructureHeightStart(attrContext) + curTrailer2.getAccessoryHolder().getBody().getSubstructureHeight();
}

} 

function getBodyHeightLength(attrContext) { 
if (attrContext === undefined) {
    if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        return curVehicle.getBodyHeightInternal();
    } else {
        return curVehicle.getBody().getActualInternalHeight();
    }
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getActualInternalHeight();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getActualInternalHeight();
}

}

function getBodySubframeHeightStart() { 
return offerManager.getRigOps().getItemStartFromGround(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY);
}

function getTrailerRearOverhangLegislationStart(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() + offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseLegislation);
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset() +
            curTrailer1.getDrawbarLength() + curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + curTrailer1.getWheelbaseTheoretical();
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset() + 
            curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() + curTrailer1.getAxle3RearToAxle4Rear() - curTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
    }
    return 0;
    
} else {
    //#unfinished, need to implement trailer 2 rear overhang legislation start
    if (curTrailer2.getType() === config.TRAILER_TYPES.SEMI || curTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() + curTrailer1.getWheelbaseTheoretical() - curTrailer1.getFifthWheelOffset() + offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer2WheelbaseLegislation);
    } else {
        return 0;//#unfinished, need to support more options here for road train
    }
}


}
function getTrailerRearOverhangLegislationLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    //return curTrailer1.getRearOverhang();
    return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangLegislation);
} else {
    //return curTrailer2.getRearOverhang();
    return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer2RearOverhangLegislation);
}
}
// function getTrailer2RearOverhangLegislationStart(attrContext) {

//     return curVehicle.getStartOfVehicle() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
//                                         curVehicle.getWheelbaseTheoretical() - offerManager.getRigOps().getFifthWheelOffset() +
//                                         curTrailer1.getWheelbaseTheoretical() - curTrailer1.getFifthWheelOffset() +
//                                         offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer2WheelbaseLegislation);
//     }
    
//     function getTrailer2RearOverhangLegislationLength(attrContext) {
//     return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer2RearOverhangLegislation);
//     }
function getStartOfTrailer(attrContext) {
//if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
//    if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
//        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() - curTrailer1.getFrontOverhang();
//    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
//        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset();
//    }
//    return 0;
//} else {
//    //need to implement for trailer 2 front overhang start
//}
return offerManager.getRigOps().getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(attrContext) - offerManager.getRigOps().getChassisObjectFromPath(attrContext).getDrawbarLength();
}
function getTrailerFrontOverhangStart(attrContext) {
//if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
//    if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
//        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() - curTrailer1.getFrontOverhang();
//    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
//        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset() + curTrailer1.getDrawbarLength();
//    }
//    return 0;
//} else {
//    //need to implement for trailer 2 front overhang start
//}
return offerManager.getRigOps().getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(attrContext);

}

function getTrailerFrontOverhangLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getFrontOverhang();
} else {
    return curTrailer2.getFrontOverhang();
}

}

function getTrailerWheelbaseTheoreticalStart(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset();
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart();
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset();
    }
    return 0;
} else {
    if (curTrailer2.getType() === config.TRAILER_TYPES.SEMI || curTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
        //return getTrailerFifthWheelDimensionStart(config.CHASSIS_OBJECT_TYPES.TRAILER1);
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + curTrailer1.getWheelbaseTheoretical() - curTrailer1.getFifthWheelOffset();
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        //#unfinished, will need to implement this when supporting roar train
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //#unfinished, will need to implement this when supporting roar train
    }
    return 0;
}
}

function getTrailerWheelbaseTheoreticalLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getWheelbaseTheoretical();
} else {
    return curTrailer2.getWheelbaseTheoretical();
}
}

function getTrailerWheelbaseTheoreticalEndToRearMostAxleStart(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return getTrailerWheelbaseTheoreticalStart(attrContext) + curTrailer1.getWheelbaseTheoretical();
} else {
    return getTrailerWheelbaseTheoreticalStart(attrContext) + curTrailer2.getWheelbaseTheoretical();
}
}
function getTrailerWheelbaseTheoreticalEndToRearMostAxleLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
} else {
    return curTrailer2.getWheelbaseTheoreticalEndToRearMostAxle();
}
}

function getTrailerRearOverhangTotalStart(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return getTrailerWheelbaseTheoreticalEndToRearMostAxleStart(attrContext) + curTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
} else {
    return getTrailerWheelbaseTheoreticalEndToRearMostAxleStart(attrContext) + curTrailer2.getWheelbaseTheoreticalEndToRearMostAxle();
}
}

function getTrailerRearOverhangTotalLength(attrContext) {
return offerManager.getRigOps().getTrailerRearOverhangTotal(attrContext);
}

function getTrailerPayloadHorizontalCOGFromHeadboardInsideStart(attrContext) {
//if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
//    if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
//        return getTrailerFrontOverhangStart(attrContext);
//    } else {
//        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getAccessoryHolder().getBody().getHorizontalGap() + curTrailer1.getAccessoryHolder().getBody().getHeadboardThickness();
//    }
    
//} else {
//    //#unfinished, nedd to implement trailer 2 version
//}
var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer))) {
    return getTrailerFrontOverhangStart(attrContext);
} else {
    return getTrailerFrontOverhangStart(attrContext) + offerManager.getRigOps().getBodyGap(trailer.getChassisObjectName()) + trailer.getAccessoryHolder().getBody().getHeadboardThickness();
}
}

function getTrailerPayloadHorizontalCOGFromHeadboardInsideLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG();

} else {
    return curTrailer2.getPayloadHolder().getAveragePayloadHorizontalCOG();
}
}

function getTrailerPayloadHorizontalCOGFromHeadBoardInsideDisplayText(attrContext, lengthVal) {
var percentageAppend;

var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);

if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer))) {
    var trailerType = trailer.getType();
    if (trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK) {
        percentageAppend = trailer.getPayloadHolder().getAveragePayloadHorizontalCOG() / trailer.getOverallLengthExclAccessories() * 100;
    } else if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.PUP) {
        percentageAppend = trailer.getPayloadHolder().getAveragePayloadHorizontalCOG() / trailer.getOverallLengthExclAccessoriesAndDrawbar() * 100;
    }

} else {
    percentageAppend = trailer.getPayloadHolder().getAveragePayloadHorizontalCOG() / trailer.getAccessoryHolder().getBody().getActualInternalLength() * 100;
}

return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + offerManager.getUIFormattedPercentageValue(percentageAppend) + ')';
}

function getWheelbaseSpecifiedDisplayText(attrContext, lengthVal) {

var comVehWheelbaseLegislation = offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehWheelbaseLegislation);
var descriptionText = '';

if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getVehicleWheelbaseSpecified()) === offerManager.getCurMeasurementSysRoundedLength(comVehWheelbaseLegislation)) {
    descriptionText = config.getTranslationText('1168');
} else {
    descriptionText = config.getTranslationText('1167');
}

return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + descriptionText + ')';
}

function getWheelbaseLegislationDisplayText(attrContext, lengthVal) {
return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + config.getTranslationText('1193') + ')';
}

function getFrontOverhangDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1217'), lengthVal);
}

function getBumperToBackOfCabDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1218'), lengthVal);
}

function getCabHeightDimensionDisplayText(attrContext, lengthVal) {
var displayCabHeightText = false;
if (curTrailer1 !== null || curTrailer2 !== null) {
    displayCabHeightText = true;
}
if (!displayCabHeightText) {
    var frontalAreaHeightCompareVal = Math.max(offerManager.getCurMeasurementSysRoundedLength(curVehicle.getCompleteOverallBodyAndEquipmentHeight()),
        offerManager.getCurMeasurementSysRoundedLength(curVehicle.getFrontalAreaHeight()));
    if (offerManager.getCurMeasurementSysRoundedLength(curVehicle.getCabHeight()) < frontalAreaHeightCompareVal) {
        displayCabHeightText = true;
    }
}
if (displayCabHeightText === true) {
    return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1219'), lengthVal);
} else {
    return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('2928'), lengthVal);
}

}

function getOverallHeightAtRearDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('2929'), lengthVal);
}

function getChassisHeightRearDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1221'), lengthVal);
}

function getCabWidthDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1222'), lengthVal);
}

function getCabToChassisEndDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1223'), lengthVal);
}

function getCombinationOverallWidthDimensionDisplayText(attrContext, lengthVal) {
    return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('4685'), lengthVal);
}

function getRearMostProtrudingItemBehindCabToRearAxlesDisplayText(attrContext, lengthVal) {
    return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('4536'), lengthVal);
}

function getCabToAxlesDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(offerManager.getConfiguration().getNumberOfRearDrivenAxles() > 1 ? config.getTranslationText('1224') : config.getTranslationText('1465'), lengthVal);
}

function getAfterFrameDimensionDisplayText(attrContext, lengthVal) {
    return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('4404'), lengthVal);
}

function getRearOverhangDimensionDisplayText(attrContext, lengthVal) {
    return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('4852'), lengthVal);
}

function getAxle1FrontToBackOfCabDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1427'), lengthVal);
}

function getAxle1RearToAxle2RearDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1225'), lengthVal);
}

function getAxle2RearToAxle3RearDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1226'), lengthVal);
}

function getAxle3RearToAxle4RearDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1227'), lengthVal);
}

function getOverallLengthDimensionDisplayText(attrContext, lengthVal) {
return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1228'), lengthVal);
}

function getBodyLengthInternalDimensionDisplayText(attrContext, lengthVal) {

if ((attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE || attrContext === undefined) && curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    if (curVehicle.getBodyLengthInternal() > 0) {
        return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1428'), lengthVal);
    } else {
        return getCustomDimensionTextIfPresentForCultureCode('', lengthVal);
    }
} else {
    var tempBody = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE).getBody();
    if (tempBody !== null && tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
        return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('1428'), lengthVal);
    } else {
        return getCustomDimensionTextIfPresentForCultureCode('', lengthVal);
    }
}

}

function getBodyLengthExternalDimensionDisplayText(attrContext, lengthVal) {
if ((attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE || attrContext === undefined) && curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
    if (curVehicle.getBodyLengthExternalInclRearDoors() > 0) {
        return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('2835'), lengthVal);
    } else {
        return getCustomDimensionTextIfPresentForCultureCode('', lengthVal);
    }
} else {
    var tempBody = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE).getBody();
    if (tempBody !== null && tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        return getCustomDimensionTextIfPresentForCultureCode(config.getTranslationText('2835'), lengthVal);
    } else {
        return getCustomDimensionTextIfPresentForCultureCode('', lengthVal);
    }
}
}

function getCustomDimensionTextIfPresentForCultureCode(text, lengthVal) {

if (text !== '') {
    return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + text + ')';
} else {
    return offerManager.getUIFormattedDimensionValue(lengthVal);
}
}

function getWheelbaseTheoreticalDisplayText(attrContext, lengthVal) {
return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + config.getTranslationText('1194') + ')';
}

function getBridgeXDimensionLabelDisplayText(attrContext, lengthVal) {
return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + config.getTranslationText('1269') + ')';
}

function getBridgeYDimensionLabelDisplayText(attrContext, lengthVal) {
return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + config.getTranslationText('1270') + ')';
}

function getTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRearStart(attrContext) {
/*
    If _Trailer1.Item(0).Source = SourceTypes.Standard Then
            If _Trailer1.Item(0).PayloadCOGToWheelbaseTheoreticalEnd > 0 Then
                dist = _Trailer1.Item(0).PayloadObjects.AveragePayloadHorizontalCOG
            Else
                dist = _Trailer1.Item(0).PayloadObjects.AveragePayloadHorizontalCOG + _Trailer1.Item(0).PayloadCOGToWheelbaseTheoreticalEnd
            End If

            
        Else
            If _Trailer1.Item(0).PayloadCOGToWheelbaseTheoreticalEnd > 0 Then
                dist = _Trailer1.Item(0).Accessories.Body.HorizontalGap + _Trailer1.Item(0).Accessories.Body.HeadboardThickness +
                        _Trailer1.Item(0).Accessories.Body.PayloadObjects.AveragePayloadHorizontalCOG
            Else
                dist = _Trailer1.Item(0).Accessories.Body.HorizontalGap + _Trailer1.Item(0).Accessories.Body.HeadboardThickness +
                        _Trailer1.Item(0).Accessories.Body.PayloadObjects.AveragePayloadHorizontalCOG + _Trailer1.Item(0).PayloadCOGToWheelbaseTheoreticalEnd
            End If

            
        End If
*/
//if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
//    var startAddition;
//    if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
//        if (curTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() > 0) {
//            startAddition = curTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG();
//        } else {
//            startAddition = curTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() + curTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd();
//        }
        
//    } else {
//        if (curTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() > 0) {
//            startAddition = curTrailer1.getAccessoryHolder().getBody().getHorizontalGap() + curTrailer1.getAccessoryHolder().getBody().getHeadboardThickness() + curTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG();
//        } else {
//            startAddition = curTrailer1.getAccessoryHolder().getBody().getHorizontalGap() + curTrailer1.getAccessoryHolder().getBody().getHeadboardThickness() +
//                curTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG() + curTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd();
//        }
//    }
//    return getTrailerFrontOverhangStart(attrContext) + startAddition;

//} else {
//    //#unfinished, need to implement trailer 2 part
//}
var startAddition;
var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer))) {
    if (trailer.getPayloadCOGToWheelbaseTheoreticalEnd() > 0) {
        startAddition = trailer.getPayloadHolder().getAveragePayloadHorizontalCOG();
    } else {
        startAddition = trailer.getPayloadHolder().getAveragePayloadHorizontalCOG() + trailer.getPayloadCOGToWheelbaseTheoreticalEnd();
    }

} else {
    if (trailer.getPayloadCOGToWheelbaseTheoreticalEnd() > 0) {
        startAddition = offerManager.getRigOps().getBodyGap(trailer.getChassisObjectName()) + trailer.getAccessoryHolder().getBody().getHeadboardThickness() + trailer.getPayloadHolder().getAveragePayloadHorizontalCOG();
    } else {
        startAddition = offerManager.getRigOps().getBodyGap(trailer.getChassisObjectName()) + trailer.getAccessoryHolder().getBody().getHeadboardThickness() +
            trailer.getPayloadHolder().getAveragePayloadHorizontalCOG() + trailer.getPayloadCOGToWheelbaseTheoreticalEnd();
    }
}
return getTrailerFrontOverhangStart(attrContext) + startAddition;
}

function getTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRearLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd();

} else {
    return curTrailer2.getPayloadCOGToWheelbaseTheoreticalEnd();
}
}

function getBodyHeadboardThicknessStart(attrContext) { 

return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY);
//if (attrContext === undefined) {
//    return dimensionXOffset + curVehicle.getBumperToBackOfCab() + curVehicle.getBody().getHorizontalGap();
//} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1 || attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
//    var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
//    return getTrailerFrontOverhangStart(attrContext) + trailer.getAccessoryHolder().getBody().getHorizontalGap();
//} 
//return 0;
}

function getBodyHeadboardThicknessLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getBody().getHeadboardThickness();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getHeadboardThickness();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getHeadboardThickness();
}

}

function getBodyTailboardThicknessStart(attrContext) {
var tempBody = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY) + tempBody.getActualLength() - tempBody.getTailboardThickness();
//if (attrContext === undefined) {
//    return dimensionXOffset + curVehicle.getBumperToBackOfCab() + curVehicle.getBody().getHorizontalGap() + curVehicle.getBody().getActualLength() - curVehicle.getBody().getTailboardThickness();
//} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1 || attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
//    var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
//    return getTrailerFrontOverhangStart(attrContext) + trailer.getAccessoryHolder().getBody().getHorizontalGap() + trailer.getAccessoryHolder().getBody().getActualLength() -
//        trailer.getAccessoryHolder().getBody().getTailboardThickness();
//} 

//return 0;
}

function getBodyTailboardThicknessLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getBody().getTailboardThickness();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getTailboardThickness();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getTailboardThickness();
}

}

function getTrailerOverallLengthStart(attrContext) {
return getTrailerFrontOverhangStart(attrContext);
}

function getTrailerOverallLengthLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
        return curTrailer1.getOverallLengthExclAccessories();
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        return curTrailer1.getOverallLengthInclAccessoriesAndPayload() - curTrailer1.getDrawbarLength();
    }
    return 0;
} else {
    
    if (curTrailer2.getType() === config.TRAILER_TYPES.SEMI || curTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
        return curTrailer2.getOverallLengthExclAccessories();
    } else if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer2.getType() === config.TRAILER_TYPES.PUP) {
        return curTrailer2.getOverallLengthInclAccessoriesAndPayload() - curTrailer2.getDrawbarLength();
    }
    return 0;
}
}

function getCabGapToStartOfBodyStart(attrContext) {
return getTrailerFrontOverhangStart(attrContext);
}

function getCabGapToStartOfBodyLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return offerManager.getRigOps().getBodyGap(curTrailer1.getChassisObjectName());
} else {
    return offerManager.getRigOps().getBodyGap(curTrailer2.getChassisObjectName());
}
}

function getKingpinToRearOfTrailerStart(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return getTrailerFrontOverhangStart(attrContext)  + curTrailer1.getFrontOverhang();
} else {
    return getTrailerFrontOverhangStart(attrContext) + curTrailer2.getFrontOverhang();
}
}

function getKingpinToRearOfTrailerLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getOverallLengthInclAccessories() - curTrailer1.getFrontOverhang();
} else {
    return curTrailer2.getOverallLengthInclAccessories() - curTrailer2.getFrontOverhang();
}
}

function getCombinationOverallLengthStart(attrContext) {
if (offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.BullbarIncludedInLength) === 1) {
    return 0;
} else {
    return curVehicle.getBullbarProtrusion();
}
}

function getCombinationOverallLengthLength(attrContext) {
return offerManager.getRigOps().getOverallLengthLegal();
}

function getCombinationOverallWidthStart(attrContext) {
    return 0;
}
    
function getCombinationOverallWidthLength(attrContext) {
    return offerManager.getRigOps().getCombinationOverallWidth();
}

function getTrailerLengthExternalStart(attrContext) {

var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);

if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer))) {
    return getTrailerFrontOverhangStart(attrContext);
} else { //we know body is not null at this point
    return getTrailerFrontOverhangStart(attrContext) + offerManager.getRigOps().getBodyGap(trailer.getChassisObjectName());
}
}

function getTrailerLengthExternalLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(curTrailer1))) {
        if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI ) {
            return curTrailer1.getOverallLengthExclAccessories();
        } else if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            return curTrailer1.getFrontToStartOfTaper();
        } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            return curTrailer1.getOverallLengthExclAccessoriesAndDrawbar();
        }
        
    } else {//we already know the body is not null at this point
        return curTrailer1.getAccessoryHolder().getBody().getActualLength();
    }
} else {
    if (curTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (curTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(curTrailer2))) {
        if (curTrailer2.getType() === config.TRAILER_TYPES.SEMI || curTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
            return curTrailer2.getOverallLengthExclAccessories();
        } else if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer2.getType() === config.TRAILER_TYPES.PUP) {
            return curTrailer2.getOverallLengthExclAccessoriesAndDrawbar();
        }
        
    } else {//we already know the body is not null at this point
        return curTrailer2.getAccessoryHolder().getBody().getActualLength();
    }
}
return 0
}

function getBackOfTrailerBodyToRearOfTrailerStart(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return getTrailerFrontOverhangStart(attrContext) + offerManager.getRigOps().getBodyGap(curTrailer1.getChassisObjectName()) + curTrailer1.getAccessoryHolder().getBody().getActualLength();
} else {
    return getTrailerFrontOverhangStart(attrContext) + offerManager.getRigOps().getBodyGap(curTrailer2.getChassisObjectName()) + curTrailer2.getAccessoryHolder().getBody().getActualLength();
}
}

function getBackOfTrailerBodyToRearOfTrailerLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
        return curTrailer1.getOverallLengthExclAccessories() - offerManager.getRigOps().getBodyGap(curTrailer1.getChassisObjectName()) - curTrailer1.getAccessoryHolder().getBody().getActualLength();
    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        return curTrailer1.getOverallLengthExclAccessoriesAndDrawbar() - offerManager.getRigOps().getBodyGap(curTrailer1.getChassisObjectName()) - curTrailer1.getAccessoryHolder().getBody().getActualLength();
    }
    return 0;
} else {
    if (curTrailer2.getType() === config.TRAILER_TYPES.SEMI || curTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
        return curTrailer2.getOverallLengthExclAccessories() - offerManager.getRigOps().getBodyGap(curTrailer2.getChassisObjectName()) - curTrailer2.getAccessoryHolder().getBody().getActualLength();
    } else if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer2.getType() === config.TRAILER_TYPES.PUP) {
        return curTrailer2.getOverallLengthExclAccessoriesAndDrawbar() - offerManager.getRigOps().getBodyGap(curTrailer2.getChassisObjectName()) - curTrailer2.getAccessoryHolder().getBody().getActualLength();
    }
    return 0;
    
}
}

function getBodySubstructureHeightStart(attrContext) {
if (attrContext === undefined) {
    //return curVehicle.getChassisHeightMax() + curVehicle.getBody().getSubframeHeight();
    return offerManager.getRigOps().getItemStartFromGround(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY) + curVehicle.getBody().getSubframeHeight();
    //return curVehicle.getChassisHeightRear() + curVehicle.getBody().getAboveBelowChassis() + curVehicle.getBody().getSubframeHeight();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return offerManager.getRigOps().getItemStartFromGround(attrContext, config.ACCESSORY_TYPES.BODY) + curTrailer1.getAccessoryHolder().getBody().getSubframeHeight();
    //return curTrailer1.getChassisHeight() + curTrailer1.getAccessoryHolder().getBody().getAboveBelowChassis() + curTrailer1.getAccessoryHolder().getBody().getSubframeHeight();
} else {
    return offerManager.getRigOps().getItemStartFromGround(attrContext, config.ACCESSORY_TYPES.BODY) + curTrailer2.getAccessoryHolder().getBody().getSubframeHeight();
    //return curTrailer2.getChassisHeight() + curTrailer2.getAccessoryHolder().getBody().getAboveBelowChassis() + curTrailer2.getAccessoryHolder().getBody().getSubframeHeight();
}
}

function getBodySubstructureHeightLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getBody().getSubstructureHeight();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getSubstructureHeight();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getSubstructureHeight();
}
}

function getCombinedBodySubframeAndSubstructureHeightStart(attrContext) {
// return offerManager.getRigOps().getItemStartFromGround(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY) - offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE).getBottomOfBodyToTopOfChassis();

    if (attrContext === undefined) {
        return curVehicle.getChassisHeightMax();
        
    // return curVehicle.getChassisHeightRear() + curVehicle.getBody().getAboveBelowChassis();
    } else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        return curTrailer1.getChassisHeight();
    } else {
        return curTrailer2.getChassisHeight();
    }
}

function getCombinedBodySubframeAndSubstructureHeightLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getBody().getSubframeHeight() + curVehicle.getBody().getSubstructureHeight();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getSubframeHeight() + curTrailer1.getAccessoryHolder().getBody().getSubstructureHeight();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getSubframeHeight() + curTrailer2.getAccessoryHolder().getBody().getSubstructureHeight();
}
}

function getBodyRoofThicknessStart(attrContext) {
    var tempBody;
    var bodyStartFromGround = offerManager.getRigOps().getItemStartFromGround(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY);
    if (attrContext === undefined) {
        tempBody = curVehicle.getBody();
        if (tempBody.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
            //return curVehicle.getChassisHeightMax() + tempBody.getSubframeHeight() + tempBody.getHeight() - tempBody.getRoofThickness();
            return bodyStartFromGround + tempBody.getSubframeHeight() + tempBody.getHeight() - tempBody.getRoofThickness();
        } else {
            //return curVehicle.getChassisHeightMax() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + curVehicle.getBody().getHeight();
            return bodyStartFromGround + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + curVehicle.getBody().getHeight();
        }
    } else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        tempBody = curTrailer1.getAccessoryHolder().getBody();
        if (tempBody.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
            return getBodySubstructureHeightStart(attrContext) + tempBody.getHeight() - tempBody.getRoofThickness();
        } else {
            return getBodySubstructureHeightStart(attrContext) + tempBody.getSubstructureHeight() + tempBody.getHeight();
        }
        
    } else {
        tempBody = curTrailer2.getAccessoryHolder().getBody();
        if (tempBody.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
            return getBodySubstructureHeightStart(attrContext) + tempBody.getHeight() - tempBody.getRoofThickness();
        } else {
            return getBodySubstructureHeightStart(attrContext) + tempBody.getSubstructureHeight() + tempBody.getHeight();
        }
        
    }
}

function getBodyRoofThicknessLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getBody().getRoofThickness();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getRoofThickness();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getRoofThickness();
}
}

function getTrailerChassisHeightStart(attrContext) {
//if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
//    return curTrailer1.getAccessoryHolder().getBody().getRoofThickness();
//} else {
//    return curTrailer2.getAccessoryHolder().getBody().getRoofThickness();
//}
return 0;
}

function getTrailerChassisHeightLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getChassisHeight();
} else {
    return curTrailer2.getChassisHeight();
}
}
    
function getBodyHeightExternalStart(attrContext) {
    var tempBody;
    var bodyStartFromGround = offerManager.getRigOps().getItemStartFromGround(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY);
    if (attrContext === undefined) {
        tempBody = curVehicle.getBody();
        if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            //return curVehicle.getChassisHeightMax() - tempBody.getBottomOfBodyToTopOfChassis();
            return curVehicle.getChassisHeightRear() - tempBody.getBottomOfBodyToTopOfChassis();
        } else {
            //return curVehicle.getChassisHeightMax() - Math.max(curVehicle.getFrontAddition(), curVehicle.getRearAddition())  + tempBody.getSubframeHeight();
            return bodyStartFromGround + tempBody.getSubframeHeight();
        }
        
    } else {
        tempBody = offerManager.getRigOps().getBodyFromPath(attrContext);
        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            return chassisObjectToUse.getChassisHeight() - tempBody.getBottomOfBodyToTopOfChassis();
        } else {
            return getBodySubstructureHeightStart(attrContext);
        }
        
    }
}

function getBodyHeightExternalLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getBody().getActualRearDimensionHeight();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getAccessoryHolder().getBody().getActualRearDimensionHeight();
} else {
    return curTrailer2.getAccessoryHolder().getBody().getActualRearDimensionHeight();
}
}

function getOverallHeightAtRearLength(attrContext) {

if (attrContext === undefined) {
    return Math.max(curVehicle.getCompleteOverallBodyAndEquipmentHeight(), offerManager.getRigOps().getOverallHeight());
    //return Math.max(curVehicle.getCompleteOverallBodyAndEquipmentHeight(), curVehicle.getFrontalAreaHeight()); 
}  else {
    return offerManager.getRigOps().getOverallHeight();
}

}

function getOverallVehicleLengthStart() {
if (offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.BullbarIncludedInLength) === 1) {
    return 0;
} else {
    return curVehicle.getBullbarProtrusion();
}

}

function getOverallVehicleLengthLength() {
return offerManager.getRigOps().getOverallVehicleLengthLegal();
}

function getFifthWheelHeightFromGroundLength() {
if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES && curVehicle.getFifthWheelHeightFromGround() > 0) {
    return curVehicle.getFifthWheelHeightFromGround();
} else if (curVehicle.getAccessoryHolder().getFifthWheel() !== null) {
    return curVehicle.getChassisHeightRear() + curVehicle.getAccessoryHolder().getFifthWheel().getHeight() + curVehicle.getAccessoryHolder().getFifthWheel().getSubframeHeight();
}
return 0;
}

function getRearOverhangLegislationPercentageDisplayText(attrContext, lengthVal) {
//var comVehWheelbaseLegislation = offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehWheelbaseLegislation);
//var comVehRearOverhangLegislation = offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhangLegislation);
var rearOverhangPercentageLegislation = offerManager.getRigOps().getRearOverhangLegislationPercent();


return offerManager.getUIFormattedDimensionValue(lengthVal) + ' (' + offerManager.getUIFormattedPercentageValue(rearOverhangPercentageLegislation, 1) + ')';
}



function getRearOverhangLegislationStart() {
//return dimensionXOffset + curVehicle.getFrontOverhang() +
//    offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) +
//    offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.ComVehWheelbaseLegislation);
return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear() + curVehicle.getRearOverhangTotalInclAccessoriesAndPayload() - getRearOverhangLegislationLength();
}

function getRearOverhangLegislationLength() {
return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhangLegislation);
}

function getHitchOffsetStart() {

if (curVehicle.getAccessoryHolder().getHitch().getOffset() > 0) {
    return dimensionXOffset + curVehicle.getWheelbaseTheoreticalEnd() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle();
} else {
    return dimensionXOffset + curVehicle.getWheelbaseTheoreticalEnd() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() - removeSign(curVehicle.getAccessoryHolder().getHitch().getOffset());
}

}

function getHitchOffsetLength() {
var hitchOffset = curVehicle.getAccessoryHolder().getHitch().getOffset();
//if (hitchOffset < 0) {
//    hitchOffset = -hitchOffset;
//}
return removeSign(hitchOffset);
}

function getDrawbarProtrusionStart() {
return getHitchOffsetStart() + curVehicle.getAccessoryHolder().getHitch().getOffset();
}

function getAxle1FrontToWheelbaseLegislationStartStart(attrContext) {

if (attrContext === undefined) {
    return dimensionXOffset + curVehicle.getFrontOverhang();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if(curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        return getDrawbarProtrusionStart() + curTrailer1.getDrawbarLength() + curTrailer1.getFrontOverhang();
    }
    return 0;
} else {
    if(curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
        //#unfinished, need to support trailer 2
    }
    return 0;
}


}


function getWheelbaseLegislationStart(attrContext) {
if (attrContext === undefined) {
    return dimensionXOffset + curVehicle.getFrontOverhang() + offerManager.getRigOps().getAppropriateAxle1FrontToAxle2FrontAddition();//offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart);
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if(curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        return getAxle1FrontToWheelbaseLegislationStartStart(attrContext) + offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle1FrontToWheelbaseLegislationStart);
    }
    return 0;
} else {
    if(curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
        //unfinished, need to support trailer 2
    }
    return 0;
}
}

function getAxle1FrontToWheelbaseTheoreticalStartStart(attrContext) {
if (attrContext === undefined) {
    return dimensionXOffset + curVehicle.getFrontOverhang();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang();
    }
    return 0;
} else {
    if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
        //unfinished, need to support trailer 2
    }
    return 0;
}
}

function getAxle1FrontToWheelbaseTheoreticalStartLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getAxle1FrontToWheelbaseTheoreticalStart();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        return curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart();
    }
    return 0;
} else {
    if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
        return curTrailer2.getAxle1FrontToWheelbaseTheoreticalStart();
    }
    return 0;
}
}

function getTurntableToRearOfTrailerStart(attrContext) {
if(attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(); 
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
    return getTrailerFrontOverhangStart(attrContext) + curTrailer2.getFrontOverhang() + curTrailer2.getAxle1FrontToWheelbaseTheoreticalStart();
}
return 0;
}

function getTurntableToRearOfTrailerLength(attrContext) {
if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    return curTrailer1.getOverallLengthInclAccessories() - curTrailer1.getDrawbarLength() - curTrailer1.getFrontOverhang() - curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart();
} else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
    return curTrailer2.getOverallLengthInclAccessories() - curTrailer2.getDrawbarLength() - curTrailer2.getFrontOverhang() - curTrailer2.getAxle1FrontToWheelbaseTheoreticalStart();
}
return 0;

/*
_Trailer1(0).OverallLengthInclAccessories - x.DrawbarLength - x.FrontOverhang - x.Axle1FrontToWheelbaseTheoreticalStart,
*/
}

function getGroup1BridgeDistanceStart(attrContext) {
return dimensionXOffset + curVehicle.getFrontOverhang();
}

function getGroup1BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getVehicleGroup1BridgeFormulaDistance();
}

function getGroup2BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return dimensionXOffset + curVehicle.getFrontOverhang();
}

}

function getGroup2BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup2BridgeFormulaDistance();
}

function getGroup3BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownDrivenAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return dimensionXOffset + curVehicle.getFrontOverhang();
}
return 0;
}

function getGroup3BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup3BridgeFormulaDistance();
}

function getGroup4BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE:
        return dimensionXOffset + curVehicle.getFrontOverhang();
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear();
}
return 0;
}

function getGroup4BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup4BridgeFormulaDistance();
}

function getGroup5BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownDrivenAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear();
}
return 0;
}

function getGroup5BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup5BridgeFormulaDistance();
}

function getGroup6BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return dimensionXOffset + curVehicle.getFrontOverhang();
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang();
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOverhang() + curTrailer1.getKingpinToAxle1Rear();
}
return 0;
}

function getGroup6BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup6BridgeFormulaDistance();
}

function getGroup7BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang() + curTrailer1.getKingpinToAxle1Rear() + curTrailer1.getDistanceFromAxle1RearToFirstDownAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return dimensionXOffset + offerManager.getRigOps().getTrailerHitchPointFromFrontOfRig(attrContext) + curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getDistanceFromAxle1RearToFirstDownAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownAxle();
}
return 0;
}

function getGroup7BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup7BridgeFormulaDistance();
}

function getGroup8BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownDrivenAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return dimensionXOffset + curVehicle.getDistanceToAxle1Rear() + curVehicle.getDistanceFromAxle1RearToFirstDownDrivenAxle();
}
return 0;
}

function getGroup8BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup8BridgeFormulaDistance();
}

function getGroup9BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        return dimensionXOffset + curVehicle.getFrontOverhang();
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return dimensionXOffset + curVehicle.getFrontOverhang();
}
return 0;
}

function getGroup9BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup9BridgeFormulaDistance();
}

function getGroup10BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang();
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang() + curTrailer1.getKingpinToAxle1Rear() + curTrailer1.getDistanceFromAxle1RearToFirstDownAxle();
}
return 0;
}

function getGroup10BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup10BridgeFormulaDistance();
}

function getGroup11BridgeDistanceStart(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        return getTrailerFrontOverhangStart(attrContext) + curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear() + curTrailer1.getDistanceFromAxle1RearToFirstDownAxle();
    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        return 0;
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        return getTrailerFrontOverhangStart(attrContext) + curTrailer2.getFrontOverhang() + curTrailer2.getKingpinToAxle1Rear() + curTrailer2.getDistanceFromAxle1RearToFirstDownAxle();
}
return 0;
}

function getGroup11BridgeDistanceLength(attrContext) {
return offerManager.getRigOps().getGroup11BridgeFormulaDistance();
}

function getWheelbaseSpecifiedStart(attrContext) {

switch (globals.user.getSpecifyWheelbaseAs()) {
    case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
    case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
    case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
        return dimensionXOffset + curVehicle.getFrontOverhang();
        
    case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
        return dimensionXOffset + curVehicle.getFrontOverhang() + (curVehicle.getAxle1FrontToAxle2Front()/2);
        
}
return 0;
}

function getVehicleCabWidthStart() {
return offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine() - curVehicle.getCabWidth() / 2;
}

function getVehicleRearWidthStart() {
return offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine() - curVehicle.getRearWidth() / 2;
}

function getBodySidewallLeftStart(attrContext) {

var centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine();

if (attrContext === undefined) {
    return centreLine - curVehicle.getAccessoryHolder().getBody().getActualWidth()/2;
} else {
    return centreLine - offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getActualWidth()/2;
}
}

function getBodySidewallLeftLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getAccessoryHolder().getBody().getSidewallLeft();
} else  {
    return offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getSidewallLeft();
}
}

function getBodyLeftCompartmentWidthStart(attrContext) {

//var centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine();

//if (attrContext === undefined) {
//    return centreLine - curVehicle.getAccessoryHolder().getBody().getActualWidth() / 2;
//} else {
//    return centreLine - offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getActualWidth() / 2;
//}
return getBodySidewallLeftStart(attrContext);
}

function getBodyLeftCompartmentWidthLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getAccessoryHolder().getBody().getLeftCompartmentWidth();
} else {
    return offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getLeftCompartmentWidth();
}
}

function getBodyFloorWidthStart(attrContext) {

//var centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine();

//if (attrContext === undefined) {
//    return centreLine - curVehicle.getAccessoryHolder().getBody().getActualWidth() / 2;
//} else {
//    return centreLine - offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getActualWidth() / 2;
//}
return getBodySidewallLeftStart(attrContext) + getBodyLeftCompartmentWidthLength(attrContext);
}

function getBodyFloorWidthLength(attrContext) {
    var tempBody
    if (attrContext === undefined) {
        tempBody = curVehicle.getAccessoryHolder().getBody();
        return tempBody.getActualWidth() - (tempBody.getLeftCompartmentWidth() + tempBody.getRightCompartmentWidth());
    } else {
        tempBody = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody();
        return tempBody.getActualWidth() - (tempBody.getLeftCompartmentWidth() + tempBody.getRightCompartmentWidth());
    }
}

function getBodyRightCompartmentWidthStart(attrContext) {

//var centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine();

//if (attrContext === undefined) {
//    return centreLine - curVehicle.getAccessoryHolder().getBody().getActualWidth() / 2;
//} else {
//    return centreLine - offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getActualWidth() / 2;
//}
return getBodySidewallLeftStart(attrContext) + getBodyLeftCompartmentWidthLength(attrContext) + getBodyFloorWidthLength(attrContext);
}

function getBodyRightCompartmentWidthLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getAccessoryHolder().getBody().getRightCompartmentWidth();
} else {
    return offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getRightCompartmentWidth();
}
}

function getBodySidewallRightStart(attrContext) {

var centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine();

if (attrContext === undefined) {
    return centreLine + curVehicle.getAccessoryHolder().getBody().getActualWidth() / 2 - curVehicle.getAccessoryHolder().getBody().getSidewallRight();
} else {
    return centreLine + offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getActualWidth() / 2 - offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getSidewallRight();
}
}

function getBodySidewallRightLength(attrContext) {
if (attrContext === undefined) {
    return curVehicle.getAccessoryHolder().getBody().getSidewallRight();
} else {
    return offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getBody().getSidewallRight();
}
}

function getBodyWidthInternalStart(attrContext) {
var pathRoot = attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE;

var tempBody = offerManager.getRigOps().getBodyFromPath(pathRoot);
var centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine();

return centreLine - tempBody.getActualInternalWidth() / 2;
}

function getBodyWidthInternalLength(attrContext) {
var pathRoot = attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE;

var tempBody = offerManager.getRigOps().getBodyFromPath(pathRoot);

return tempBody.getActualInternalWidth();
}

function getBodyWidthExternalStart(attrContext) {
var pathRoot = attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE;

var tempBody = offerManager.getRigOps().getBodyFromPath(pathRoot);
var centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getTopViewCentreLine();

return centreLine - tempBody.getActualWidth() / 2;
}

function getBodyWidthExternalLength(attrContext) {
var pathRoot = attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE;

var tempBody = offerManager.getRigOps().getBodyFromPath(pathRoot);

return tempBody.getActualWidth();
}
function getChassisHeightAtRearLength(attrContext) {

if (attrContext === undefined) {
    return curVehicle.getChassisHeightRear();
} else {
    return offerManager.getRigOps().getChassisObjectFromPath(attrContext).getChassisHeight();
}
}

function getGapBetweenTrailer1EndOfBodyAndTrailer2Start(attrContext) {

var gapBetweenTrailer1EndOfBodyAndTrailer2Start = 0;

if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(curTrailer1))) {
    var trailerLengthValToUse;
    if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
        trailerLengthValToUse = curTrailer1.getFrontToStartOfTaper();
    } else {
        trailerLengthValToUse = curTrailer1.getLength();
    }
    
    gapBetweenTrailer1EndOfBodyAndTrailer2Start = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.FIFTH_WHEEL) -
        curTrailer1.getFrontOverhang() + trailerLengthValToUse;
    
    
} else {
    gapBetweenTrailer1EndOfBodyAndTrailer2Start = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.FIFTH_WHEEL) -
        curTrailer1.getFrontOverhang() + offerManager.getRigOps().getBodyGap(curTrailer1.getChassisObjectName()) + curTrailer1.getAccessoryHolder().getBody().getActualLength();
}
if (curVehicle.getAccessoryHolder().getFifthWheel() !== null) {
    gapBetweenTrailer1EndOfBodyAndTrailer2Start += curVehicle.getAccessoryHolder().getFifthWheel().getFrontOverhang();
}
return gapBetweenTrailer1EndOfBodyAndTrailer2Start;

/*
_Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                                _Vehicle.WheelbaseTheoretical - _Vehicle.Accessories.FifthWheel.HorizontalGap -
                                myTrailer1.FrontOverhang + myTrailer1.Accessories.Body.HorizontalGap +
                                myTrailer1.Accessories.Body.ActualLength
*/
}

function getGapBetweenTrailer1EndOfBodyAndTrailer2Length(attrContext) {
switch (offerManager.getRigOps().getCombinationType()) {
    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        //if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(curTrailer1))) {
        //    //return offerManager.getRigOps().getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2();
        //    return (curTrailer1.getFrontOverhang() + curTrailer1.getWheelbaseTheoretical() - curTrailer1.getFifthWheelOffset() - curTrailer2.getFrontOverhang()) - curTrailer1.getFrontToStartOfTaper();
        //} else {
            return offerManager.getRigOps().getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2();
        //}
        
    default:
        return 0;
}
//#unfinished, need to support other combinations here when supporting road train
}

function getTrailerFifthWheelDimensionStart(attrContext) {

var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
var fifthWheelOffset = trailer.getFifthWheelOffset();

if (fifthWheelOffset < 0) {
    return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext) + trailer.getFrontOverhang() + trailer.getAxle1FrontToWheelbaseTheoreticalStart() + trailer.getWheelbaseTheoretical();
} else {
    return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext) + trailer.getFrontOverhang() + trailer.getAxle1FrontToWheelbaseTheoreticalStart() + trailer.getWheelbaseTheoretical() - trailer.getFifthWheelOffset()
}


}

function getTrailerFifthWheelDimensionLength(attrContext) {
var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
var fifthWheelOffset = trailer.getFifthWheelOffset();

if (fifthWheelOffset < 0) {
    return -fifthWheelOffset;
} else {
    return fifthWheelOffset;
}
}





function getBridgeXDimensionStart() {
return offerManager.getRigOps().getStartingPointVehicleAxle3Rear();
}

function getBridgeXDimensionLength() {
//return getStartingPointTrailer1Axle1Rear() - getStartingPointVehicleAxle3Rear();
return offerManager.getRigOps().getVehicleAndInterlinkAndSemiXDimension();
}

function getBridgeYDimensionStart() {
return offerManager.getRigOps().getStartingPointTrailer1Axle4Rear();
}

function getBridgeYDimensionLength() {
//return getStartingPointTrailer2Axle1Rear() - getStartingPointTrailer1Axle4Rear();
return offerManager.getRigOps().getVehicleAndInterlinkAndSemiYDimension();
}

function getAxle1FrontToBackOfCabStart() {
if (curVehicle.getFrontOverhang() > curVehicle.getBumperToBackOfCab()) {
    return dimensionXOffset + curVehicle.getBumperToBackOfCab();
} else {
    return dimensionXOffset + curVehicle.getFrontOverhang();
}
}

function getCabHeightStart() {
if (curVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
    return curVehicle.getRearAddition();
} else {
    return 0;
}
}

function getVerticalExhaustHeightStart() {
if (curVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
    return curVehicle.getRearAddition();
} else {
    return 0;
}
}
function getBullbarProtrusion() {
return Math.max(-curVehicle.getAccessoryHolder().getBullbar().getHorizontalGap() - curVehicle.getBumperToBackOfCab(), 0);
}
function getBullbarProtrusionDimensionStart() {
var tempBullbar = curVehicle.getAccessoryHolder().getBullbar();
//return curVehicle.getBumperToBackOfCab() + tempBullbar.getHorizontalGap();
//return 0 + dimensionXOffset - tempBullBar.getLength();
var protrusion = getBullbarProtrusion();
if(protrusion === dimensionXOffset) {
    return 0;
}else {
    return dimensionXOffset - protrusion;
}
}
function getBullbarProtrusionDimensionLength() {
//var tempBullbar = curVehicle.getAccessoryHolder().getBullbar();
//return tempBullbar.getLength();
return getBullbarProtrusion();
}
function getTotalFrontOverhangDimensionStart() {
//var tempBullbar = curVehicle.getAccessoryHolder().getBullbar();
//return getBullbarProtrusionDimensionStart(); 
//return 0 + dimensionXOffset;
//return 0 + dimensionXOffset - tempBullBar.getLength();
var protrusion = getBullbarProtrusion();
if(protrusion === dimensionXOffset) {
    return 0;
}else {
    return dimensionXOffset - protrusion;
}
}
function getTotalFrontOverhangDimensionLength() {
var tempBullbar = curVehicle.getAccessoryHolder().getBullbar();
if (-(tempBullbar.getHorizontalGap()) - curVehicle.getAxle1FrontToBackOfCab() < curVehicle.getFrontOverhang()) {
    return 0;
}
return -(tempBullbar.getHorizontalGap()) - curVehicle.getAxle1FrontToBackOfCab();
}

function getRearProtrusionDimensionStart() { 
return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear() + curVehicle.getRearOverhang(); 
}

function getRearProtrusionDimensionLength() { 
return Math.max(curVehicle.getRearOverhangTotalInclAccessoriesAndPayload() - curVehicle.getRearOverhang(), curVehicle.getRearProtrusion()); 
}

function getFridgeProtrusionStart(attrContext) {
    var bodyToUse = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) - chassisObjectToUse.getAccessoryHolder().getFridge().getProtrusion() - chassisObjectToUse.getAccessoryHolder().getFridge().getFromFrontOfBody();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext) + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) - chassisObjectToUse.getAccessoryHolder().getFridge().getProtrusion() - chassisObjectToUse.getAccessoryHolder().getFridge().getFromFrontOfBody();
    }

}

function getFridgeProtrusionLengthForEndPoint(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getFridge().getProtrusion() ;
}

function getFridgeProtrusionLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getFridge().getProtrusion();
}

function getFridgeMountingPositionStart(attrContext) {
    var bodyToUse = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightRear() + bodyToUse.getAboveBelowChassis() + bodyToUse.getActualExternalHeight() - chassisObjectToUse.getAccessoryHolder().getFridge().getMountingPosition();
    } else {

        return chassisObjectToUse.getChassisHeight() + bodyToUse.getAboveBelowChassis() + bodyToUse.getActualExternalHeight() - chassisObjectToUse.getAccessoryHolder().getFridge().getMountingPosition();
    }

}

function getFridgeMountingPositionLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getFridge().getMountingPosition();
}

function getFridgeFromFrontOfBodyStart(attrContext) {
    var bodyToUse = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) - chassisObjectToUse.getAccessoryHolder().getFridge().getFromFrontOfBody();
    } else {
        
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext) + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) - chassisObjectToUse.getAccessoryHolder().getFridge().getFromFrontOfBody();
    }
}

function getFridgeFromFrontOfBodyLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getFridge().getFromFrontOfBody();
}

function getFridgeHeightStart(attrContext) {
var bodyToUse = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
    return chassisObjectToUse.getChassisHeightRear() + bodyToUse.getAboveBelowChassis() + bodyToUse.getActualExternalHeight() - chassisObjectToUse.getAccessoryHolder().getFridge().getMountingPosition() - chassisObjectToUse.getAccessoryHolder().getFridge().getHeight();
} else {
    return chassisObjectToUse.getChassisHeight() + bodyToUse.getAboveBelowChassis() + bodyToUse.getActualExternalHeight() - chassisObjectToUse.getAccessoryHolder().getFridge().getMountingPosition() - chassisObjectToUse.getAccessoryHolder().getFridge().getHeight();
}

}

function getFridgeHeightLength(attrContext) {
var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
return chassisObjectToUse.getAccessoryHolder().getFridge().getHeight();
}

function getTailliftMechanismLengthStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return offerManager.getRigOps().getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.TAILLIFT) - chassisObjectToUse.getAccessoryHolder().getTaillift().getMechanismLength();
}

function getTailliftMechanismLengthLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getTaillift().getMechanismLength();
}

function getTailliftBodyGapStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return offerManager.getRigOps().getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.TAILLIFT) - chassisObjectToUse.getAccessoryHolder().getTaillift().getFromBackOfBody();
}

function getTailliftBodyGapLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getTaillift().getFromBackOfBody();
}

function getTailliftPlatformProtrusionStart(attrContext) {
    return offerManager.getRigOps().getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.TAILLIFT);
}

function getTailliftPlatformProtrusionLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getTaillift().getPlatformProtrusion();
}

function getTailliftAboveBelowFloorStart(attrContext) {
    var bodyToUse = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightMax() + bodyToUse.getAboveBelowChassis() + bodyToUse.getSubframeHeight() + bodyToUse.getSubstructureHeight();
    } else {
        return chassisObjectToUse.getChassisHeight() + bodyToUse.getAboveBelowChassis() + bodyToUse.getSubframeHeight() + bodyToUse.getSubstructureHeight();
    }

    
}

function getTailliftAboveBelowFloorLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getTaillift().getAboveOrBelowFloor();
}

function getTailliftMechanismHeightStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return getTailliftAboveBelowFloorStart(attrContext) + chassisObjectToUse.getAccessoryHolder().getTaillift().getAboveOrBelowFloor() - chassisObjectToUse.getAccessoryHolder().getTaillift().getMechanismHeight();
}

function getTailliftMechanismHeightLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getTaillift().getMechanismHeight();
}

function getTailliftPlatformHeightStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return getTailliftAboveBelowFloorStart(attrContext) + chassisObjectToUse.getAccessoryHolder().getTaillift().getAboveOrBelowFloor();
}

function getTailliftPlatformHeightLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    return chassisObjectToUse.getAccessoryHolder().getTaillift().getPlatformHeight();
}

function getOtherCabGapStart(attrContext, objectId) {
var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId); 
return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.OTHER, other.getId()) - other.getHorizontalGap();
}

function getOtherCabGapLength(attrContext, objectId) {
var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
return other.getHorizontalGap();
}

function getOtherLengthStart(attrContext, objectId) {
var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.OTHER, other.getId());
}

function getOtherLengthLength(attrContext, objectId) {
var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
return other.getLength();
}

function getOtherHeightStart(attrContext, objectId) {
    var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightMax() + other.getVerticalGap();
    } else {
        return chassisObjectToUse.getChassisHeight() + other.getVerticalGap();
    }
    
}

function getOtherHeightLength(attrContext, objectId) {
    var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
    return other.getHeight();
}

function getOtherAboveBelowChassisStart(attrContext, objectId) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightMax();
    } else {
        return chassisObjectToUse.getChassisHeight();
    }
}

function getOtherAboveBelowChassisLength(attrContext, objectId) {
    var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
    return other.getVerticalGap();
}

function getOtherEndOfEquipmentToEndOfChassisStart(attrContext, objectId) {
    var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
    return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.OTHER, other.getId()) + other.getLength();
}

function getOtherEndOfEquipmentToEndOfChassisLength(attrContext, objectId) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
    var gapToFromPoint = 0;
    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToFromPoint = chassisObjectToUse.getBumperToBackOfCab();
    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
        gapToFromPoint = chassisObjectToUse.getDrawbarLength();
    }

    return chassisObjectToUse.getAccessoryHolder().getEndOfEquipmentToEndOfChassis(other, chassisObjectToUse.getOverallLengthExclAccessories(), gapToFromPoint);
}

function getOtherFromAxle1FrontStart(attrContext, objectId) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getFrontOverhang();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
    }
}

function getOtherFromAxle1FrontLength(attrContext, objectId) {
    var other = offerManager.getRigOps().getAccessoryForChassisById(attrContext, objectId);
    return offerManager.getRigOps().calculateFromAxle1Front(other);
}

function getCraneFromAxle1FrontStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getFrontOverhang();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
    }
}

function getCraneFromAxle1FrontLength(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    return offerManager.getRigOps().calculateFromAxle1Front(crane);
}

function getCraneEndOfEquipmentToEndOfChassisStart(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.CRANE, crane.getId()) + crane.getLength();
}

function getCraneEndOfEquipmentToEndOfChassisLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    var gapToFromPoint = 0;
    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToFromPoint = chassisObjectToUse.getBumperToBackOfCab();
    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
        gapToFromPoint = chassisObjectToUse.getDrawbarLength();
    }

    return chassisObjectToUse.getAccessoryHolder().getEndOfEquipmentToEndOfChassis(crane, chassisObjectToUse.getOverallLengthExclAccessories(), gapToFromPoint);
}

function getCraneCabGapStart(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
    }
    
}

function getCraneCabGapLength(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    return crane.getHorizontalGap();
}

function getCraneLengthStart(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab() + crane.getHorizontalGap();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext) + crane.getHorizontalGap();
    }
    
}

function getCraneLengthLength(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    return crane.getLength();
}



// function getCraneSubframeHeightLength(attrContext) {
//     var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
//     return crane.getSubframeHeight();
// }

function getCraneAboveBelowChassisStart(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightRear();
    } else {
        return chassisObjectToUse.getChassisHeight();
    }

}

function getCraneAboveBelowChassisLength(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    return crane.getAboveBelowChassis();
}

function getCraneHeightStart(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightRear() + crane.getAboveBelowChassis() + crane.getSubframeHeight();
    } else {
        return chassisObjectToUse.getChassisHeight() + crane.getAboveBelowChassis() + crane.getSubframeHeight();
    }
    
}

function getCraneHeightLength(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    return crane.getHeight();
}

function getCraneSubframeHeightStart(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightRear() + crane.getAboveBelowChassis();
    } else {
        return chassisObjectToUse.getChassisHeight() + crane.getAboveBelowChassis();
    }
    
}

function getCraneSubframeHeightLength(attrContext) {
    var crane = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getCrane();
    return crane.getSubframeHeight();

}

function getHookliftCabGapStart(attrContext) {
    
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
    }

    
}

function getHookliftCabGapLength(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return hooklift.getHorizontalGap();
}

function getHookliftLengthStart(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab() + hooklift.getHorizontalGap();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext) + hooklift.getHorizontalGap();
    }
}

function getHookliftLengthLength(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return hooklift.getLength();
}

function getHookliftFromAxle1FrontStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getFrontOverhang();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
    }
}

function getHookliftFromAxle1FrontLength(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return offerManager.getRigOps().calculateFromAxle1Front(hooklift);
}

function getHookliftHookOffsetStart(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab() + hooklift.getHorizontalGap();
    } else {
        return dimensionXOffset + offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext) + hooklift.getHorizontalGap();
    }
}

function getHookliftHookOffsetLength(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return hooklift.getHookOffset();
}



function getHookliftEndOfEquipmentToEndOfChassisStart(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext, config.ACCESSORY_TYPES.HOOKLIFT, hooklift.getId()) + hooklift.getLength();
}

function getHookliftEndOfEquipmentToEndOfChassisLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    var gapToFromPoint = 0;
    if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToFromPoint = chassisObjectToUse.getBumperToBackOfCab();
    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
        gapToFromPoint = chassisObjectToUse.getDrawbarLength();
    }

    return chassisObjectToUse.getAccessoryHolder().getEndOfEquipmentToEndOfChassis(hooklift, chassisObjectToUse.getOverallLengthExclAccessories(), gapToFromPoint);
}

function getHookliftRearmostAxleToCentreOfRollerStart(attrContext) {
    

    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();


    var gapToRefPointStart = 0, rearProtrusion = 0, chassisOffset = 0;
    if (attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToRefPointStart = chassisObjectToUse.getBumperToBackOfCab();
        rearProtrusion = chassisObjectToUse.getRearProtrusion();
    } else {
        gapToRefPointStart = offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
        chassisOffset = gapToRefPointStart;
    }
    var rollerFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength() - hooklift.getCentreOfRoller();

    var rearmostAxleFromFrontOfChassis = chassisOffset + chassisObjectToUse.getOverallLengthExclAccessories() - rearProtrusion - chassisObjectToUse.getRearOverhang();

    return dimensionXOffset +  Math.min(rearmostAxleFromFrontOfChassis, rollerFromFrontOfChassis);
}

function getHookliftRearmostAxleToCentreOfRollerLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();


    var gapToRefPointStart = 0, rearProtrusion = 0, chassisOffset = 0, overallLengthExclAccessories = 0;
    if (attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToRefPointStart = chassisObjectToUse.getBumperToBackOfCab();
        rearProtrusion = chassisObjectToUse.getRearProtrusion();
        overallLengthExclAccessories = chassisObjectToUse.getOverallLengthExclAccessories();
    } else {
        gapToRefPointStart = 0;//offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
        chassisOffset = offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
        overallLengthExclAccessories = chassisObjectToUse.getOverallLengthExclAccessoriesAndDrawbar();
    }
    var rollerFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength() - hooklift.getCentreOfRoller();

    var rearmostAxleFromFrontOfChassis = overallLengthExclAccessories - rearProtrusion - chassisObjectToUse.getRearOverhang();

    return Math.max(rearmostAxleFromFrontOfChassis, rollerFromFrontOfChassis) - Math.min(rearmostAxleFromFrontOfChassis, rollerFromFrontOfChassis);
}

function getHookliftCentreOfRollerToEndOfHookliftStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();


    var gapToRefPointStart = 0;
    if (attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToRefPointStart = chassisObjectToUse.getBumperToBackOfCab();
    } else {
        gapToRefPointStart =  offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
    }
    var rollerFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength() - hooklift.getCentreOfRoller();

    var endOfHookliftFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength();

    return dimensionXOffset + Math.min(endOfHookliftFromFrontOfChassis, rollerFromFrontOfChassis);
}

function getHookliftCentreOfRollerToEndOfHookliftLength(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();


    var gapToRefPointStart = 0;
    if (attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToRefPointStart = chassisObjectToUse.getBumperToBackOfCab();
    } else {
        gapToRefPointStart = offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
    }
    var rollerFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength() - hooklift.getCentreOfRoller();

    var endOfHookliftFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength();

    return Math.max(endOfHookliftFromFrontOfChassis, rollerFromFrontOfChassis) - Math.min(endOfHookliftFromFrontOfChassis, rollerFromFrontOfChassis);
}

function getHookliftCentreOfRollerToEndStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();

    var gapToRefPointStart = 0;
    var rearProtrusionEndFromFrontOfRig = 0;
    if (attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        gapToRefPointStart = chassisObjectToUse.getBumperToBackOfCab();
        rearProtrusionEndFromFrontOfRig = chassisObjectToUse.getOverallLengthExclAccessories();
    } else {
        gapToRefPointStart = offerManager.getRigOps().getTrailerFrontOverhangStart(attrContext);
        rearProtrusionEndFromFrontOfRig = offerManager.getRigOps().getTrailerFrontOverhangStartExclDrawbar(attrContext) + chassisObjectToUse.getOverallLengthExclAccessories();
    }
    var rollerFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength() - hooklift.getCentreOfRoller();
    

    var rearBumperEndFromFrontOfChassis = 0;
    var rearBumper = chassisObjectToUse.getAccessoryHolder().getRearmostRearBumper();
    if (rearBumper !== null) {
        rearBumperEndFromFrontOfChassis = gapToRefPointStart + rearBumper.getHorizontalGap() + rearBumper.getLength();
    }

    return dimensionXOffset + Math.min(Math.max(rearProtrusionEndFromFrontOfRig, rearBumperEndFromFrontOfChassis), rollerFromFrontOfChassis);
}

function getHookliftCentreOfRollerToEndLength(attrContext) {
    return offerManager.getRigOps().getCentreOfHookliftRollerToEnd(attrContext);
}

function getHookliftPlatformHeightStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();

    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightRear() + hooklift.getVerticalGap();
    } else {
        return chassisObjectToUse.getChassisHeight() + hooklift.getVerticalGap();
    }
}

function getHookliftPlatformHeightLength(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return hooklift.getPlatformHeight();
}

function getHookliftHeightStart(attrContext) {
    return getHookliftPlatformHeightStart(attrContext);
}

function getHookliftHeightLength(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return hooklift.getHeight();
}

function getHookliftAboveBelowChassisStart(attrContext) {
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
    if (attrContext === undefined || attrContext === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        return chassisObjectToUse.getChassisHeightRear();
    } else {
        return chassisObjectToUse.getChassisHeight();
    }
}

function getHookliftAboveBelowChassisLength(attrContext) {
    var hooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext).getHooklift();
    return hooklift.getVerticalGap();
}

function getBodyHorizontalCOGStart(attrContext) {
    var body = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE).getBody();
    /*var bodyHorizontalCOGStart = dimensionXOffset + curVehicle.getBumperToBackOfCab() + curVehicle.getBody().getHorizontalGap();*/
    var bodyHorizontalCOGStart = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.BODY, body.getId());
    //if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
    //    var tempHooklift = offerManager.getRigOps().getAccessoryHolderFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE).getHooklift();
    //    bodyHorizontalCOGStart += tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
    //}
    return bodyHorizontalCOGStart;
}

function getBodyHorizontalCOGLength(attrContext) {
    return offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE).getActualHorizontalCOG();
}

function getCOGFromHeadboardInsideStart() { 
    var tempBody = curVehicle.getBody();
    var hookliftOffset = 0
    if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        var tempHooklift = curVehicle.getAccessoryHolder().getHooklift();
        hookliftOffset = tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
    }
    return dimensionXOffset + curVehicle.getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness() + hookliftOffset; 
}

function getBodyOverhangDimensionStart(attrContext) {
    var tempBody = curVehicle.getBody();
    var hookliftOffset = 0
    if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        var tempHooklift = curVehicle.getAccessoryHolder().getHooklift();
        hookliftOffset = tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
    }
    return dimensionXOffset + curVehicle.getBumperToBackOfCab() + hookliftOffset + tempBody.getHorizontalGap() + tempBody.getActualLength() - curVehicle.getBodyOverhang();
}

function getBodyOverhangDimensionLength(attrContext) {
    return curVehicle.getBodyOverhang();
}

function getAfterFrameDimensionStart(attrContext) {
    
    return getWheelbaseSpecifiedStart(attrContext) + curVehicle.getWheelbaseSpecified();
}

function getAfterFrameDimensionLength(attrContext) {
    return offerManager.getRigOps().getAfterframe();
}

function getRearProtrusionVehicleOnlyDimensionStart() {
    return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear() + curVehicle.getRearOverhang();
}

function getRearProtrusionVehicleOnlyDimensionLength() {
    //var rearBumper = curVehicle.getAccessoryHolder().getRearmostRearBumper();
    //var rearBumperProtrusion = 0;
    //if (rearBumper !== null) {
    //    var endOfChassis = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear() + curVehicle.getRearOverhang();
    //    var rearBumperStart = curVehicle.getBumperToBackOfCab() + rearBumper.getHorizontalGap();
    //    rearBumperProtrusion = rearBumper.getLength() + (rearBumperStart - endOfChassis);

    //}
    //return Math.max(curVehicle.getRearProtrusion(), rearBumperProtrusion /*((rearBumperLength + curVehicle.getBumperToBackOfCab() + curVehicle.getAxle1FrontToBackOfCab()) - (curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear() + curVehicle.getRearOverhang()))*/);
    return curVehicle.getRearProtrusionVehicleOnly();
}

function getHookliftGapToBodyFromHookOffsetStart() {
    var tempHooklift = curVehicle.getAccessoryHolder().getHooklift();
    
    return dimensionXOffset + curVehicle.getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
}

function setupDimensionConfigs() {
dimensionConfigs = {};
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.FIFTHWHEELOFFSET).setStart(getFifthWheelDimensionStart).setLength(getFifthWheelDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYFROMAXLE1FRONT).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang(); }).setLength(function () { return offerManager.getRigOps().getAxle1FrontToStartOfBody(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYSTARTTOWHEELBASESPECIFIEDEND).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + offerManager.getRigOps().getAxle1FrontToStartOfBody(); }).setLength(function () { return offerManager.getRigOps().getBodyStartToWheelbaseSpecifiedEnd(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYHORIZONTALCOG).setStart(getBodyHorizontalCOGStart).setLength(getBodyHorizontalCOGLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYCOGTOREARAXLES).setStart(getBodyCOGToRearAxlesStart).setLength(function (attrContext) { return Math.abs(getBodyCOGToRearAxlesLength(attrContext)); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.FRONTOVERHANG).setStart(function () { return dimensionXOffset + 0 }).setLength(function () { return curVehicle.getFrontOverhang(); }).addCustomDisplayTextFunction(getFrontOverhangDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang(); }).setLength(function () { return curVehicle.getAxle1FrontToAxle2Front(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front(); }).setLength(function () { return curVehicle.getAxle2FrontToAxle1Rear(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE1REARTOAXLE2REAR).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear(); }).setLength(function () { return curVehicle.getAxle1RearToAxle2Rear(); }).addCustomDisplayTextFunction(getAxle1RearToAxle2RearDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE2REARTOAXLE3REAR).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear(); }).setLength(function () { return curVehicle.getAxle2RearToAxle3Rear(); }).addCustomDisplayTextFunction(getAxle2RearToAxle3RearDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE3REARTOAXLE4REAR).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear(); }).setLength(function () { return curVehicle.getAxle3RearToAxle4Rear(); }).addCustomDisplayTextFunction(getAxle3RearToAxle4RearDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REAROVERHANG).setStart(getRearOverhangDimensionStart).setLength(getRearOverhangDimensionLength).addCustomDisplayTextFunction(getRearOverhangDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REARPROTRUSION).setStart(getRearProtrusionDimensionStart).setLength(getRearProtrusionDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE1FRONTTOWHEELBASELEGISLATIONSTART).setStart(getAxle1FrontToWheelbaseLegislationStartStart).setLength(function () { return offerManager.getRigOps().getAppropriateAxle1FrontToAxle2FrontAddition()/*offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart)*/; }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.WHEELBASELEGISLATION).setStart(getWheelbaseLegislationStart).setLength(function () { return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehWheelbaseLegislation) }).addCustomDisplayTextFunction(getWheelbaseLegislationDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REAROVERHANGLEGISLATION).setStart(getRearOverhangLegislationStart).setLength(getRearOverhangLegislationLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART).setStart(getAxle1FrontToWheelbaseTheoreticalStartStart).setLength(getAxle1FrontToWheelbaseTheoreticalStartLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.WHEELBASETHEORETICAL).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart(); }).setLength(function () { return curVehicle.getWheelbaseTheoretical(); }).addCustomDisplayTextFunction(getWheelbaseTheoreticalDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.WHEELBASETHEORETICALENDTOAXLE4REAR).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical(); }).setLength(function () { return curVehicle.getWheelbaseTheoreticalEndToAxle4Rear(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REAROVERHANGTOTAL).setStart(function () { return dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToAxle4Rear(); }).setLength(function () { return curVehicle.getRearOverhangTotalInclAccessoriesAndPayload(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.COMPLETEOVERALLLENGTH).setStart(function () { return dimensionXOffset + 0; }).setLength(function () { return curVehicle.getCompleteOverallLength(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.OVERALLVEHICLELENGTH).setStart(getOverallVehicleLengthStart).setLength(getOverallVehicleLengthLength).addCustomDisplayTextFunction(getOverallLengthDimensionDisplayText));
// addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES).setStart(function () { return dimensionXOffset + ((offerManager.getRigOps().getCabToAxles() + curVehicle.getBumperToBackOfCab()) - curVehicle.getRearMostProtrudingItemBehindCabToRearAxles()); }).setLength(function () { return curVehicle.getRearMostProtrudingItemBehindCabToRearAxles(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES).setStart(function () { return dimensionXOffset + ((offerManager.getRigOps().getCabToAxles() + curVehicle.getBumperToBackOfCab()) - curVehicle.getRearMostProtrudingItemBehindCabToRearAxles()); }).setLength(function () { return curVehicle.getRearMostProtrudingItemBehindCabToRearAxles(); }).addCustomDisplayTextFunction(getRearMostProtrudingItemBehindCabToRearAxlesDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.CABTOAXLES).setStart(function () { return dimensionXOffset + curVehicle.getBumperToBackOfCab(); }).setLength(function () { return offerManager.getRigOps().getCabToAxles(globals.wheelbaseSpecifiedOptionJustChanged); }).addCustomDisplayTextFunction(getCabToAxlesDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AXLE1FRONTTOBACKOFCAB).setStart(getAxle1FrontToBackOfCabStart).setLength(function () { return Math.abs(curVehicle.getBumperToBackOfCab() - curVehicle.getFrontOverhang()); }).addCustomDisplayTextFunction(getAxle1FrontToBackOfCabDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BUMPERTOBACKOFCAB).setStart(function () { return dimensionXOffset + 0; }).setLength(function () { return curVehicle.getBumperToBackOfCab(); }).addCustomDisplayTextFunction(getBumperToBackOfCabDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.CABTOCHASSISEND).setStart(function () { return dimensionXOffset + curVehicle.getBumperToBackOfCab(); }).setLength(function () { return curVehicle.getCabToChassisEnd(); }).addCustomDisplayTextFunction(getCabToChassisEndDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.COGFROMHEADBOARDINSIDE).setStart(getCOGFromHeadboardInsideStart).setLength(function () { return curVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.COGFROMWHEELBASETHEORETICALATREAR).setStart(cogFromWheelbaseTheoreticalAtRearStart).setLength(cogFromWheelbaseTheoreticalAtRearLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.COGFROMBUMPERTOSTARTOFBODYINTERNAL).setStart(function () { return dimensionXOffset + curVehicle.getBumperToStartOfBodyInternal(); }).setLength(function () { return curVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYHEADBOARDTHICKNESS).setStart(getBodyHeadboardThicknessStart).setLength(getBodyHeadboardThicknessLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYLENGTHINTERNAL).setStart(getBodyLengthInternalStart).setLength(getBodyLengthInternalLength).addCustomDisplayTextFunction(getBodyLengthInternalDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYTAILBOARDTHICKNESS).setStart(getBodyTailboardThicknessStart).setLength(getBodyTailboardThicknessLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BUMPERTOBACKOFCAB).setStart(function () { return dimensionXOffset + 0; }).setLength(function () { return curVehicle.getBumperToBackOfCab(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYCABGAP).setStart(function () { return dimensionXOffset + curVehicle.getBumperToBackOfCab(); }).setLength(function () { return curVehicle.getBody().getHorizontalGap(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTGAPTOBODYFROMHOOKOFFSET).setStart(getHookliftGapToBodyFromHookOffsetStart).setLength(function () { return curVehicle.getBody().getHorizontalGap(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYLENGTHEXTERNALINCLUDINGREARDOORS).setStart(getBodyLengthExternalIncludingRearDoorsStart).setLength(getBodyLengthExternalIncludingRearDoorsLength).addCustomDisplayTextFunction(getBodyLengthExternalDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.RAMPLENGTH).setStart(function () { return dimensionXOffset + curVehicle.getBumperToBackOfCab() + curVehicle.getBody().getHorizontalGap() + curVehicle.getBody().getActualLength(); }).setLength(function () { return curVehicle.getBody().getRampLength(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.RAMPPLATESPROTRUSION).setStart(function () { return dimensionXOffset + curVehicle.getBumperToBackOfCab() + curVehicle.getBody().getHorizontalGap() + curVehicle.getBody().getActualLength() + curVehicle.getBody().getRampLength(); }).setLength(function () { return curVehicle.getBody().getRampPlatesProtrusion(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REAROFBODYTOREAROFVEHICLE).setStart(getRearOfBodyToRearOfVehicleStart).setLength(function () { return curVehicle.getBackOfBodyToRearOfVehicle(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUNDCLEARANCEFRONT).setStart(function () { return 0; }).setLength(function () { return curVehicle.getGroundClearanceFront(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUNDCLEARANCEREAR).setStart(function () { return 0; }).setLength(function () { return curVehicle.getGroundClearanceRear(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.CABHEIGHT).setStart(getCabHeightStart).setLength(function () { return curVehicle.getCabHeight(); }).addCustomDisplayTextFunction(getCabHeightDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.VERTICALEXHAUSTHEIGHT).setStart(getVerticalExhaustHeightStart).setLength(function () { return curVehicle.getVerticalExhaustHeight(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.FIFTHWHEELHEIGHTFROMGROUND).setStart(function () { return 0; }).setLength(getFifthWheelHeightFromGroundLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYSUBSTRUCTUREHEIGHT).setStart(getBodySubstructureHeightStart).setLength(getBodySubstructureHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYCOMBINEDSUBFRAMEANDSUBSTRUCTUREHEIGHT).setStart(getCombinedBodySubframeAndSubstructureHeightStart).setLength(getCombinedBodySubframeAndSubstructureHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYFLOORHEIGHT).setStart(function () { return 0; }).setLength(function () { return curVehicle.getFloorHeight(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYHEIGHT).setStart(getBodyHeightStart).setLength(getBodyHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYROOFTHICKNESS).setStart(getBodyRoofThicknessStart).setLength(getBodyRoofThicknessLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.CHASSISHEIGHTREAR).setStart(function () { return 0; }).setLength(getChassisHeightAtRearLength).addCustomDisplayTextFunction(getChassisHeightRearDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYFLOORHEIGHT).setStart(function () { return 0; }).setLength(function () { return curVehicle.getFloorHeight(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYSUBFRAMEHEIGHT).setStart(getBodySubframeHeightStart).setLength(function () { return curVehicle.getBody().getSubframeHeight(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYHEIGHTEXTERNALFROMFLOOR).setStart(function () { return curVehicle.getFloorHeight(); }).setLength(function () { return curVehicle.getBodyHeightExternal(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYHEIGHTEXTERNAL).setStart(getBodyHeightExternalStart).setLength(getBodyHeightExternalLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.OVERALLHEIGHTATREAR).setStart(function () { return 0; }).setLength(getOverallHeightAtRearLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.OVERALLHEIGHTATREAR).setStart(function () { return 0; }).setLength(getOverallHeightAtRearLength).addCustomDisplayTextFunction(getOverallHeightAtRearDimensionDisplayText))
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERREAROVERHANGLEGISLATION).setStart(getTrailerRearOverhangLegislationStart).setLength(getTrailerRearOverhangLegislationLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERFRONTOVERHANG).setStart(getTrailerFrontOverhangStart).setLength(getTrailerFrontOverhangLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));


addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL).setStart(getTrailerWheelbaseTheoreticalStart).setLength(getTrailerWheelbaseTheoreticalLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICALENDTOREARMOSTAXLE).setStart(getTrailerWheelbaseTheoreticalEndToRearMostAxleStart).setLength(getTrailerWheelbaseTheoreticalEndToRearMostAxleLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERREAROVERHANGTOTAL).setStart(getTrailerRearOverhangTotalStart).setLength(getTrailerRearOverhangTotalLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERPAYLOADHORIZONTALCOGFROMHEADBOARDINSIDE).setStart(getTrailerPayloadHorizontalCOGFromHeadboardInsideStart).setLength(getTrailerPayloadHorizontalCOGFromHeadboardInsideLength).addCustomDisplayTextFunction(getTrailerPayloadHorizontalCOGFromHeadBoardInsideDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERPAYLOADHORIZONTALCOGFROMWHEELBASETHEORETICALATREAR).setStart(getTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRearStart).setLength(getTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRearLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BUMPERTOBACKOFREARMOSTEQUIPMENT).setStart(function () { return dimensionXOffset + 0 }).setLength(function () { return curVehicle.getBumperToBackOfRearMostEquipment(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.CABGAPTOTRAILER).setStart(function () { return dimensionXOffset + curVehicle.getBumperToBackOfRearMostEquipment(); }).setLength(function () { return offerManager.getRigOps().getVehicleAndSemiOrInterlinkGapBetweenVehicleAndTrailer(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERLENGTH).setStart(getTrailerOverallLengthStart).setLength(getTrailerOverallLengthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.CABGAPTOSTARTOFBODY).setStart(getCabGapToStartOfBodyStart).setLength(getCabGapToStartOfBodyLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.KINGPINTOREAROFTRAILER).setStart(getKingpinToRearOfTrailerStart).setLength(getKingpinToRearOfTrailerLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.COMBINATIONOVERALLLENGTH).setStart(getCombinationOverallLengthStart).setLength(getCombinationOverallLengthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH).setStart(getCombinationOverallWidthStart).setLength(getCombinationOverallWidthLength).addCustomDisplayTextFunction(getCombinationOverallWidthDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERLENGTHEXTERNAL).setStart(getTrailerLengthExternalStart).setLength(getTrailerLengthExternalLength).addCustomDisplayTextFunction(getBodyLengthExternalDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BACKOFTRAILERBODYTOREAROFTRAILER).setStart(getBackOfTrailerBodyToRearOfTrailerStart).setLength(getBackOfTrailerBodyToRearOfTrailerLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERCHASSISHEIGHT).setStart(getTrailerChassisHeightStart).setLength(getTrailerChassisHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

//addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.HITCHOFFSET).setStart(getHitchOffsetStart).setLength(getHitchOffsetLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERDRAWBARPROTRUSION).setStart(getDrawbarProtrusionStart).setLength(function () { return curTrailer1.getDrawbarLength(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERDRAWBARLENGTH).setStart(getDrawbarProtrusionStart).setLength(function () { return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1DrawbarLengthLegislation); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASELEGISLATIONSTART).setStart(getAxle1FrontToWheelbaseLegislationStartStart).setLength(function () { return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle1FrontToWheelbaseLegislationStart); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERWHEELBASELEGISLATION).setStart(getWheelbaseLegislationStart).setLength(function () { return offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseLegislation); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART).setStart(getAxle1FrontToWheelbaseTheoreticalStartStart).setLength(getAxle1FrontToWheelbaseTheoreticalStartLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER).setStart(function () { return curVehicle.getOverallLengthInclAccessories(); }).setLength(function () { return offerManager.getRigOps().getVehicleAndDrawbarGapBetweenVehicleAndTrailer(); }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TURNTABLETOREAROFTRAILER).setStart(getTurntableToRearOfTrailerStart).setLength(getTurntableToRearOfTrailerLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP1BRIDGEDISTANCE).setStart(getGroup1BridgeDistanceStart).setLength(getGroup1BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP2BRIDGEDISTANCE).setStart(getGroup2BridgeDistanceStart).setLength(getGroup2BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP3BRIDGEDISTANCE).setStart(getGroup3BridgeDistanceStart).setLength(getGroup3BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP4BRIDGEDISTANCE).setStart(getGroup4BridgeDistanceStart).setLength(getGroup4BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP5BRIDGEDISTANCE).setStart(getGroup5BridgeDistanceStart).setLength(getGroup5BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP6BRIDGEDISTANCE).setStart(getGroup6BridgeDistanceStart).setLength(getGroup6BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP7BRIDGEDISTANCE).setStart(getGroup7BridgeDistanceStart).setLength(getGroup7BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP8BRIDGEDISTANCE).setStart(getGroup8BridgeDistanceStart).setLength(getGroup8BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP9BRIDGEDISTANCE).setStart(getGroup9BridgeDistanceStart).setLength(getGroup9BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP10BRIDGEDISTANCE).setStart(getGroup10BridgeDistanceStart).setLength(getGroup10BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GROUP11BRIDGEDISTANCE).setStart(getGroup11BridgeDistanceStart).setLength(getGroup11BridgeDistanceLength).addStandardDisplayTextFunction(offerManager.getUIBridgeFormattedDimensionValue));


addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.WHEELBASESPECIFIED).setStart(getWheelbaseSpecifiedStart).setLength(function () { return offerManager.getRigOps().getVehicleWheelbaseSpecified() }).addCustomDisplayTextFunction(getWheelbaseSpecifiedDisplayText));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.CABWIDTH).setStart(getVehicleCabWidthStart).setLength(function () { return curVehicle.getCabWidth() }).addCustomDisplayTextFunction(getCabWidthDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYSIDEWALLLEFT).setStart(getBodySidewallLeftStart).setLength(getBodySidewallLeftLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYSIDEWALLRIGHT).setStart(getBodySidewallRightStart).setLength(getBodySidewallRightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYWIDTH).setStart(getBodyWidthInternalStart).setLength(getBodyWidthInternalLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYWIDTHEXTERNAL).setStart(getBodyWidthExternalStart).setLength(getBodyWidthExternalLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REARWIDTH).setStart(getVehicleRearWidthStart).setLength(function () { return curVehicle.getRearWidth() }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYLEFTCOMPARTMENTWIDTH).setStart(getBodyLeftCompartmentWidthStart).setLength(getBodyLeftCompartmentWidthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYFLOORWIDTH).setStart(getBodyFloorWidthStart).setLength(getBodyFloorWidthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYRIGHTCOMPARTMENTWIDTH).setStart(getBodyRightCompartmentWidthStart).setLength(getBodyRightCompartmentWidthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.GAPBETWEENTRAILERANDTRAILER).setStart(getGapBetweenTrailer1EndOfBodyAndTrailer2Start).setLength(getGapBetweenTrailer1EndOfBodyAndTrailer2Length).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERFIFTHWHEELOFFSET).setStart(getTrailerFifthWheelDimensionStart).setLength(getTrailerFifthWheelDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
// addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TRAILERREAROVERHANGLEGISLATION).setStart(getTrailer2RearOverhangLegislationStart).setLength(getTrailer2RearOverhangLegislationLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BRIDGEXDIMENSION).setStart(getBridgeXDimensionStart).setLength(getBridgeXDimensionLength).addCustomDisplayTextFunction(getBridgeXDimensionLabelDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BRIDGEYDIMENSION).setStart(getBridgeYDimensionStart).setLength(getBridgeYDimensionLength).addCustomDisplayTextFunction(getBridgeYDimensionLabelDisplayText));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BULLBARPROTRUSION).setStart(getBullbarProtrusionDimensionStart).setLength(getBullbarProtrusionDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.TOTALFRONTOVERHANG).setStart(getTotalFrontOverhangDimensionStart).setLength(getTotalFrontOverhangDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.BODYOVERHANG).setStart(getBodyOverhangDimensionStart).setLength(getBodyOverhangDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.AFTERFRAME).setStart(getAfterFrameDimensionStart).setLength(getAfterFrameDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).addCustomDisplayTextFunction(getAfterFrameDimensionDisplayText));
addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.REARPROTRUSIONVEHICLEONLY).setStart(getRearProtrusionVehicleOnlyDimensionStart).setLength(getRearProtrusionVehicleOnlyDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));

}
//addDimensionConfig(new DimensionConfig().setId().setStart(function () { }).setLength(function () { }));
//function DimensionConfig() {
//    var self = this;

//    var _id, _start = 0, _length, _attrContext = undefined, _customDisplayTextFunc;


//    function setId(id) {
//        _attrContext = undefined;
//        _id = id;
//        return self;
//    }
//    function getId() {
//        return _id;
//    }

//    function setStart(start) {
//        _start = start;
//        return self;
//    }
//    function getStart() {
//        if (typeof _start === 'function') {
//            return _start(_attrContext);
//        } else {
//            return _start;
//        }
//    }
//    function setLength(length) {
//        _length = length;
//        return self;
//    }
//    function getLength() {
//        if (typeof _length === 'function') {
//            return _length(_attrContext);
//        } else {
//            return _length;
//        }
//    }
//    function getDisplayText() {
//        if (_customDisplayTextFunc === undefined) {
//            return offerManager.getUIFormattedDimensionValue(getLength());
//        } else {
//            return _customDisplayTextFunc(_attrContext, getLength());
//        }
    
//    }
//    function addCustomDisplayTextFunction(customDisplayTextFunc) {
//        _customDisplayTextFunc = customDisplayTextFunc;
//        return self;
//    }
//    function setContext(attrContext) {
//        _attrContext = attrContext;
//    }

//    self.setId = setId;
//    self.setStart = setStart;
//    self.setLength = setLength;
//    self.getId = getId;
//    self.getStart = getStart;
//    self.getLength = getLength;
//    self.getDisplayText = getDisplayText;
//    self.setContext = setContext;
//    self.addCustomDisplayTextFunction = addCustomDisplayTextFunction;

//}

function addDimensionConfig(dimensionConfig) {
dimensionConfigs[dimensionConfig.getId()] = dimensionConfig;
}
function getDimensionConfig(id, attrRootForContext) {
var dc = dimensionConfigs[id];
//if (attrRootForContext !== undefined) {
    dc.setContext(attrRootForContext);
//}
return dc;
}







function generatePinnedEquipmentDimensions() {

    var layerIncrement = 14 / scalingFactor;

    var equipmentLayerInfos = {
        VEHICLE: {
            top: {
                layerNum: 0
            },
            left: {
                layerNum: 0
            },
            bottom: {
                layerNum: 0
            },
            right: {
                layerNum: 0
            }
        },
        TRAILER1: {
            top: {
                layerNum: 0
            },
            left: {
                layerNum: 0
            },
            bottom: {
                layerNum: 0
            },
            right: {
                layerNum: 0
            }
        },
        TRAILER2: {
            top: {
                layerNum: 0
            },
            left: {
                layerNum: 0
            },
            bottom: {
                layerNum: 0
            },
            right: {
                layerNum: 0
            }
        }
        
    }

    offerManager.getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {
        chassisObject.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY && accessory.getIsPinned()) {
                switch (accessory.getAccessoryType()) {
                    case config.ACCESSORY_TYPES.CRANE:
                        generateCraneDimensions(chassisObject, accessory);
                        break;
                    case config.ACCESSORY_TYPES.TAILLIFT:
                        generateTailliftDimensions(chassisObject, accessory);
                        break;
                    case config.ACCESSORY_TYPES.FRIDGE:
                        generateFridgeDimensions(chassisObject, accessory);
                        break;
                    case config.ACCESSORY_TYPES.OTHER:
                        generateOtherDimensions(chassisObject, accessory);
                        break;
                    case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                        generateFifthWheelDimensions(chassisObject, accessory);
                        break;
                    case config.ACCESSORY_TYPES.HITCH:
                        generateHitchDimensions(chassisObject, accessory);
                        break;
                    case config.ACCESSORY_TYPES.HOOKLIFT:
                        generateHookliftDimensions(chassisObject, accessory);
                        break;
                }
            }
        });
    });

    if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
        generateFifthWheelDimensions(curVehicle, null);
    }

    function getDrawingGroupMaxBounds(chassisType, accessory) {
        var maxBoundsKey = '';
        switch (accessory.getAccessoryType()) {
            case config.ACCESSORY_TYPES.CRANE:
                maxBoundsKey = chassisType + '.ACCESSORY.Crane';
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                maxBoundsKey = chassisType + '.ACCESSORY.Taillift';
                //maxBoundsKey = accessory.getDrawingGroupName();
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                maxBoundsKey = chassisType + '.ACCESSORY.Fridge';
                break;
            case config.ACCESSORY_TYPES.OTHER:
                maxBoundsKey = chassisType + '.ACCESSORY.Other.' + accessory.getId();
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                maxBoundsKey = chassisType + '.ACCESSORY.FifthWheel';
                break;
            case config.ACCESSORY_TYPES.HITCH:
                maxBoundsKey = chassisType + '.ACCESSORY.Hitch';
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                maxBoundsKey = chassisType + '.ACCESSORY.Hooklift';
                break;
        }
        return drawingGroupMaxBounds[maxBoundsKey];
    }

    function getEquipmentLayerInfo(chassisType, desiredPosition, layer, accessory) {

        
        var actualPosition;

        var maxBounds = getDrawingGroupMaxBounds(chassisType, accessory);

        var spaceTop, spaceBottom, spaceLeft, spaceRight;
        //var maxBounds = getDrawingGroupMaxBounds(taillift);
        spaceTop = mH - (maxBounds.y * scalingFactor + maxBounds.height * scalingFactor);
        spaceBottom = maxBounds.y * scalingFactor;
        spaceLeft = maxBounds.x * scalingFactor;
        spaceRight = mW - (maxBounds.x * scalingFactor + maxBounds.width * scalingFactor);

        var numLayersAllowed = {
            top: Math.floor(spaceTop / (layerIncrement * scalingFactor)),
            bottom: Math.floor(spaceBottom / (layerIncrement * scalingFactor)),
            left: Math.floor(spaceLeft / (layerIncrement * scalingFactor)),
            right: Math.floor(spaceRight / (layerIncrement * scalingFactor))
        }



        if (equipmentLayerInfos[chassisType][desiredPosition] && equipmentLayerInfos[chassisType][desiredPosition][layer] === undefined) {
            if (equipmentLayerInfos[chassisType][desiredPosition].layerNum < numLayersAllowed[desiredPosition]) {
                equipmentLayerInfos[chassisType][desiredPosition][layer] = {};
                equipmentLayerInfos[chassisType][desiredPosition].layerNum = equipmentLayerInfos[chassisType][desiredPosition].layerNum + 1;
                actualPosition = desiredPosition;
            } else {
                actualPosition = getOppositePosition(desiredPosition);
                if (equipmentLayerInfos[chassisType][actualPosition][layer] === undefined) {
                    if (equipmentLayerInfos[chassisType][actualPosition].layerNum < numLayersAllowed[actualPosition]) {
                        equipmentLayerInfos[chassisType][actualPosition][layer] = {};
                        equipmentLayerInfos[chassisType][actualPosition].layerNum = equipmentLayerInfos[chassisType][actualPosition].layerNum + 1;
                        equipmentLayerInfos[chassisType][desiredPosition][layer] = {};
                        equipmentLayerInfos[chassisType][desiredPosition][layer].switched = true;

                    } else {
                        //hide
                    }

                }
            }

        } else if (equipmentLayerInfos[chassisType][desiredPosition][layer].switched) {
            actualPosition = getOppositePosition(desiredPosition);
            if (equipmentLayerInfos[chassisType][actualPosition][layer] === undefined) {
                if (equipmentLayerInfos[chassisType][actualPosition].layerNum < numLayersAllowed[actualPosition]) {
                    equipmentLayerInfos[chassisType][actualPosition][layer] = {};
                    equipmentLayerInfos[chassisType][actualPosition].layerNum = equipmentLayerInfos[chassisType][actualPosition].layerNum + 1;

                } else {
                    equipmentLayerInfos[chassisType][desiredPosition][layer] = {};
                    equipmentLayerInfos[chassisType][desiredPosition].layerNum = 1;
                }

            }
        } else {
            actualPosition = desiredPosition;
        }


        var layerPoints = getLayerPoints(actualPosition, equipmentLayerInfos[chassisType][actualPosition].layerNum, maxBounds);

        return {
            layerNum: equipmentLayerInfos[chassisType][actualPosition].layerNum,
            /*switched: equipmentLayerInfos[pos][layer].switched || false,*/
            layerPoints: layerPoints
        };
    }

    function getOppositePosition(pos) {
        switch (pos) {
            case 'top':
                return 'bottom';
            case 'bottom':
                return 'top';
            case 'left':
                return 'right';
            case 'right':
                return 'left';
        }
        return 'unsupported case';
    }

    // function switchEquipmentLayerPosition(pos, layer) {
    //     if (equipmentLayerInfos[chassisType][pos][layer] === undefined) {
    //         equipmentLayerInfos[chassisType][pos][layer] = {};
    //         equipmentLayerInfos[chassisType][pos].layerNum = equipmentLayerInfos[chassisType][pos].layerNum + 1;
    //     }
    //     equipmentLayerInfos[chassisType][pos][layer].switched = true;
    // }

    function getLayerPoints(position, layerNumber, maxBounds) {
        if (position === 'top') {
            return { startPoint: { x: maxBounds.x, y: maxBounds.y + maxBounds.height + layerIncrement * layerNumber }, endPoint: { x: maxBounds.x + maxBounds.width, y: maxBounds.y + maxBounds.height + layerIncrement * layerNumber } };
        } else if (position === 'right') {
            return { startPoint: { x: maxBounds.x + maxBounds.width + layerIncrement * layerNumber, y: maxBounds.y }, endPoint: { x: maxBounds.x + maxBounds.width + layerIncrement * layerNumber, y: maxBounds.y + maxBounds.height } };
        } else if (position === 'left') {
            return { startPoint: { x: maxBounds.x - layerIncrement * layerNumber, y: maxBounds.y }, endPoint: { x: maxBounds.x - layerIncrement * layerNumber, y: maxBounds.y + maxBounds.height } };
        } else if (position === 'bottom') {
            return { startPoint: { x: maxBounds.x, y: maxBounds.y - layerIncrement * layerNumber }, endPoint: { x: maxBounds.x + maxBounds.width, y: maxBounds.y - layerIncrement * layerNumber } };
        }
    }

    function generateOtherDimensions(chassisObjectToUse, other) {

        var chassisType = chassisObjectToUse.getChassisObjectName();
        var otherObjectId = other.getId();

        var otherLength1LayerPosToUse, otherLength2LayerPosToUse, otherLength3LayerPosToUse, otherHeight1LayerPosToUse, otherHeight2LayerPosToUse; 

        switch (other.getAccessorySubType()) {
            case config.ACCESSORY_TYPES.BULLBAR:
            case config.ACCESSORY_TYPES.SNOWPLOW:
                otherLength1LayerPosToUse = 'top'; 
                otherLength2LayerPosToUse = 'top'; 
                otherLength3LayerPosToUse = 'bottom'; 
                otherHeight1LayerPosToUse = 'right';
                otherHeight2LayerPosToUse = 'right';
                break;
            case config.ACCESSORY_TYPES.AEROKIT:
            case config.ACCESSORY_TYPES.FAIRING:
                otherLength1LayerPosToUse = 'bottom';
                otherLength2LayerPosToUse = 'bottom';
                otherLength3LayerPosToUse = 'bottom';
                otherHeight1LayerPosToUse = 'left';
                otherHeight2LayerPosToUse = 'left';//not used
                break;
            case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
            case config.ACCESSORY_TYPES.TOOLBOX:
            case config.ACCESSORY_TYPES.OUTRIGGER:
                otherLength1LayerPosToUse = 'top';
                otherLength2LayerPosToUse = 'top';
                otherLength3LayerPosToUse = 'top';
                otherHeight1LayerPosToUse = 'right';
                otherHeight2LayerPosToUse = 'left';
                break;
            case config.ACCESSORY_TYPES.REAR_BUMPER:
                otherLength1LayerPosToUse = 'bottom';
                otherLength2LayerPosToUse = 'bottom';
                otherLength3LayerPosToUse = 'bottom';//not used
                otherHeight1LayerPosToUse = 'right';
                otherHeight2LayerPosToUse = 'left';
                break;
            default:
                otherLength1LayerPosToUse = 'top';
                otherLength2LayerPosToUse = 'top';
                otherLength3LayerPosToUse = 'top';
                otherHeight1LayerPosToUse = 'right';
                otherHeight2LayerPosToUse = 'left';


        }



        var otherLength1LayerInfo = getEquipmentLayerInfo(chassisType, otherLength1LayerPosToUse, LAYER.OTHER_LENGTH_1, other);
        var otherDimensionPointsHorizontalLayer1 = otherLength1LayerInfo.layerPoints;

        var otherLength2LayerInfo = getEquipmentLayerInfo(chassisType, otherLength2LayerPosToUse, LAYER.OTHER_LENGTH_2, other);
        var otherDimensionPointsHorizontalLayer2 = otherLength2LayerInfo.layerPoints;

        var otherLength3LayerInfo = getEquipmentLayerInfo(chassisType, otherLength3LayerPosToUse, LAYER.OTHER_LENGTH_3, other);
        var otherDimensionPointsHorizontalLayer3 = otherLength3LayerInfo.layerPoints;

        var otherHeight1LayerInfo = getEquipmentLayerInfo(chassisType, otherHeight1LayerPosToUse, LAYER.OTHER_HEIGHT_1, other);
        var otherDimensionPointsVerticalLayer1 = otherHeight1LayerInfo.layerPoints;

        var otherHeight2LayerInfo = getEquipmentLayerInfo(chassisType, otherHeight2LayerPosToUse, LAYER.OTHER_HEIGHT_2, other);
        var otherDimensionPointsVerticalLayer2 = otherHeight2LayerInfo.layerPoints;

        
        var cabGapLineendStyleToUse, lengthLineEndStyleToUse, heightLineEndStyleToUse, aboveBelowChassisLineEndStyleToUse, endOfEquipmentToEndOfChassisLineEndStyleToUse, fromAxle1FrontLineEndStyleToUse;
        var cabGapLayerInfoToUse, lengthLayerInfoToUse, heightLayerInfoToUse, aboveBelowChassisLayerInfoToUse, endOfEquipmentToEndOfChassisLayerInfoToUse, fromAxle1FrontLayerInfoToUse;
        var cabGapLayerPointsToUse, lengthLayerPointsToUse, heightLayerPointsToUse, aboveBelowChassisLayerPointsToUse, endOfEquipmentToEndOfChassisLayerPointsToUse, fromAxle1FrontLayerPointsToUse;
        var cabGapDimensionStyleToUse, lengthDimensionStyleToUse, heightDimensionStyleToUse, aboveBelowChassisDimensionStyleToUse, endOfEquipmentToEndOfChassisDimensionStyleToUse, fromAxle1FrontDimensionStyleToUse;
        
        var accessoryPathPartToUse, dimensionPathPartToUse;

        var showEndOfEquipmentToEndOfCHassisDimension = true;

        switch (other.getAccessorySubType()) {
            case config.ACCESSORY_TYPES.BULLBAR:
            case config.ACCESSORY_TYPES.SNOWPLOW:
                fromAxle1FrontLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                fromAxle1FrontLayerInfoToUse = otherLength1LayerInfo;
                fromAxle1FrontLayerPointsToUse = otherDimensionPointsHorizontalLayer1;
                fromAxle1FrontDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                cabGapLineendStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                cabGapLayerInfoToUse = otherLength2LayerInfo;
                cabGapLayerPointsToUse = otherDimensionPointsHorizontalLayer2;
                cabGapDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                lengthLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                lengthLayerInfoToUse = otherLength3LayerInfo;
                lengthLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                lengthDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                aboveBelowChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN;
                aboveBelowChassisLayerInfoToUse = otherHeight1LayerInfo;
                aboveBelowChassisLayerPointsToUse = otherDimensionPointsVerticalLayer1;
                aboveBelowChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_DOWN;

                heightLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP;
                heightLayerInfoToUse = otherHeight2LayerInfo;
                heightLayerPointsToUse = otherDimensionPointsVerticalLayer2;
                heightDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_UP;

                showEndOfEquipmentToEndOfCHassisDimension = false;
                break;
            case config.ACCESSORY_TYPES.AEROKIT:
                cabGapLineendStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                cabGapLayerInfoToUse = otherLength1LayerInfo;
                cabGapLayerPointsToUse = otherDimensionPointsHorizontalLayer1;
                cabGapDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                lengthLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                lengthLayerInfoToUse = otherLength2LayerInfo;
                lengthLayerPointsToUse = otherDimensionPointsHorizontalLayer2;
                lengthDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                fromAxle1FrontLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                fromAxle1FrontLayerInfoToUse = otherLength3LayerInfo;
                fromAxle1FrontLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                fromAxle1FrontDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                endOfEquipmentToEndOfChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                endOfEquipmentToEndOfChassisLayerInfoToUse = otherLength3LayerInfo;
                endOfEquipmentToEndOfChassisLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                endOfEquipmentToEndOfChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                heightLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP;
                heightLayerInfoToUse = otherHeight1LayerInfo;
                heightLayerPointsToUse = otherDimensionPointsVerticalLayer1;
                heightDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_UP;

                aboveBelowChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN;
                aboveBelowChassisLayerInfoToUse = otherHeight1LayerInfo;
                aboveBelowChassisLayerPointsToUse = otherDimensionPointsVerticalLayer1;
                aboveBelowChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_DOWN;
                break;
            case config.ACCESSORY_TYPES.AERIAL_PLATFORM:
            case config.ACCESSORY_TYPES.FAIRING:
                cabGapLineendStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                cabGapLayerInfoToUse = otherLength1LayerInfo;
                cabGapLayerPointsToUse = otherDimensionPointsHorizontalLayer1;
                cabGapDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                lengthLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                lengthLayerInfoToUse = otherLength2LayerInfo;
                lengthLayerPointsToUse = otherDimensionPointsHorizontalLayer2;
                lengthDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                fromAxle1FrontLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                fromAxle1FrontLayerInfoToUse = otherLength3LayerInfo;
                fromAxle1FrontLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                fromAxle1FrontDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                endOfEquipmentToEndOfChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                endOfEquipmentToEndOfChassisLayerInfoToUse = otherLength3LayerInfo;
                endOfEquipmentToEndOfChassisLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                endOfEquipmentToEndOfChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                heightLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP;
                heightLayerInfoToUse = otherHeight1LayerInfo;
                heightLayerPointsToUse = otherDimensionPointsVerticalLayer1;
                heightDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_UP;
                
                aboveBelowChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN;
                aboveBelowChassisLayerInfoToUse = otherHeight2LayerInfo;
                aboveBelowChassisLayerPointsToUse = otherDimensionPointsVerticalLayer2;
                aboveBelowChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_DOWN;
                break;
            case config.ACCESSORY_TYPES.TOOLBOX:
            case config.ACCESSORY_TYPES.OUTRIGGER:
                cabGapLineendStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                cabGapLayerInfoToUse = otherLength1LayerInfo;
                cabGapLayerPointsToUse = otherDimensionPointsHorizontalLayer1;
                cabGapDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                lengthLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                lengthLayerInfoToUse = otherLength2LayerInfo;
                lengthLayerPointsToUse = otherDimensionPointsHorizontalLayer2;
                lengthDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                fromAxle1FrontLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                fromAxle1FrontLayerInfoToUse = otherLength3LayerInfo;
                fromAxle1FrontLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                fromAxle1FrontDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                endOfEquipmentToEndOfChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                endOfEquipmentToEndOfChassisLayerInfoToUse = otherLength3LayerInfo;
                endOfEquipmentToEndOfChassisLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                endOfEquipmentToEndOfChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                heightLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP;
                heightLayerInfoToUse = otherHeight1LayerInfo;
                heightLayerPointsToUse = otherDimensionPointsVerticalLayer1;
                heightDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_UP;

                aboveBelowChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN;
                aboveBelowChassisLayerInfoToUse = otherHeight2LayerInfo;
                aboveBelowChassisLayerPointsToUse = otherDimensionPointsVerticalLayer2;
                aboveBelowChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_DOWN;
                break;
            case config.ACCESSORY_TYPES.REAR_BUMPER:
                cabGapLineendStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                cabGapLayerInfoToUse = otherLength1LayerInfo;
                cabGapLayerPointsToUse = otherDimensionPointsHorizontalLayer1;
                cabGapDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                lengthLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                lengthLayerInfoToUse = otherLength1LayerInfo;
                lengthLayerPointsToUse = otherDimensionPointsHorizontalLayer1;
                lengthDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                fromAxle1FrontLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                fromAxle1FrontLayerInfoToUse = otherLength2LayerInfo;
                fromAxle1FrontLayerPointsToUse = otherDimensionPointsHorizontalLayer2;
                fromAxle1FrontDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                endOfEquipmentToEndOfChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                endOfEquipmentToEndOfChassisLayerInfoToUse = otherLength2LayerInfo;
                endOfEquipmentToEndOfChassisLayerPointsToUse = otherDimensionPointsHorizontalLayer2;
                endOfEquipmentToEndOfChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                heightLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP;
                heightLayerInfoToUse = otherHeight1LayerInfo;
                heightLayerPointsToUse = otherDimensionPointsVerticalLayer1;
                heightDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_UP;

                aboveBelowChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN;
                aboveBelowChassisLayerInfoToUse = otherHeight2LayerInfo;
                aboveBelowChassisLayerPointsToUse = otherDimensionPointsVerticalLayer2;
                aboveBelowChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_DOWN;
                break;
                default:
                cabGapLineendStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                cabGapLayerInfoToUse = otherLength1LayerInfo;
                cabGapLayerPointsToUse = otherDimensionPointsHorizontalLayer1;
                cabGapDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                lengthLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                lengthLayerInfoToUse = otherLength2LayerInfo;
                lengthLayerPointsToUse = otherDimensionPointsHorizontalLayer2;
                lengthDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                fromAxle1FrontLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT;
                fromAxle1FrontLayerInfoToUse = otherLength3LayerInfo;
                fromAxle1FrontLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                fromAxle1FrontDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_LEFT;

                endOfEquipmentToEndOfChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT;
                endOfEquipmentToEndOfChassisLayerInfoToUse = otherLength3LayerInfo;
                endOfEquipmentToEndOfChassisLayerPointsToUse = otherDimensionPointsHorizontalLayer3;
                endOfEquipmentToEndOfChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_RIGHT;

                heightLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP;
                heightLayerInfoToUse = otherHeight1LayerInfo;
                heightLayerPointsToUse = otherDimensionPointsVerticalLayer1;
                heightDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_UP;

                aboveBelowChassisLineEndStyleToUse = config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN;
                aboveBelowChassisLayerInfoToUse = otherHeight2LayerInfo;
                aboveBelowChassisLayerPointsToUse = otherDimensionPointsVerticalLayer2;
                aboveBelowChassisDimensionStyleToUse = config.DIMENSION_STYLE.JUMP_DOWN;


        }

        var otherCabGapDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.OTHERCABGAP).setContext(chassisType).setObjectId(otherObjectId).setStartPoint(getOtherCabGapStart(chassisType, otherObjectId), cabGapLayerPointsToUse.startPoint.y).setEndPoint(getOtherCabGapStart(chassisType, otherObjectId) + getOtherCabGapLength(chassisType, otherObjectId), cabGapLayerPointsToUse.endPoint.y).setLength(getOtherCabGapLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(cabGapLineendStyleToUse).setDrawBG(true);
        var otherLengthDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.OTHERLENGTH).setContext(chassisType).setObjectId(otherObjectId).setStartPoint(getOtherLengthStart(chassisType, otherObjectId), lengthLayerPointsToUse.startPoint.y).setEndPoint(getOtherLengthStart(chassisType, otherObjectId) + getOtherLengthLength(chassisType, otherObjectId), lengthLayerPointsToUse.endPoint.y).setLength(getOtherLengthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(lengthLineEndStyleToUse).setDrawBG(true);
        var otherFromAxle1FrontDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.OTHERFROMAXLE1FRONT).setContext(chassisType).setObjectId(otherObjectId).setStartPoint(getOtherFromAxle1FrontStart(chassisType, otherObjectId), fromAxle1FrontLayerPointsToUse.startPoint.y).setEndPoint(getOtherFromAxle1FrontStart(chassisType, otherObjectId) + getOtherFromAxle1FrontLength(chassisType, otherObjectId), fromAxle1FrontLayerPointsToUse.endPoint.y).setLength(getOtherFromAxle1FrontLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(fromAxle1FrontLineEndStyleToUse).setDrawBG(true);
        var otherEndOfEquipmentToEndOfChassisDimensionConfig;
        if (showEndOfEquipmentToEndOfCHassisDimension) {
            otherEndOfEquipmentToEndOfChassisDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.OTHERENDOFEQUIPMENTTOENDOFCHASSIS).setContext(chassisType).setObjectId(otherObjectId).setStartPoint(getOtherEndOfEquipmentToEndOfChassisStart(chassisType, otherObjectId), endOfEquipmentToEndOfChassisLayerPointsToUse.startPoint.y).setEndPoint(getOtherEndOfEquipmentToEndOfChassisStart(chassisType, otherObjectId) + getOtherEndOfEquipmentToEndOfChassisLength(chassisType, otherObjectId), endOfEquipmentToEndOfChassisLayerPointsToUse.endPoint.y).setLength(getOtherEndOfEquipmentToEndOfChassisLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(endOfEquipmentToEndOfChassisLineEndStyleToUse).setDrawBG(true);
        }
        var otherHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.OTHERHEIGHT).setContext(chassisType).setObjectId(otherObjectId).setStartPoint(heightLayerPointsToUse.startPoint.x, getOtherHeightStart(chassisType, otherObjectId)).setEndPoint(heightLayerPointsToUse.endPoint.x, getOtherHeightStart(chassisType, otherObjectId) + getOtherHeightLength(chassisType, otherObjectId)).setLength(getOtherHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(heightLineEndStyleToUse).setDrawBG(true);
        var otherAboveBelowChassisDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.OTHERABOVEBELOWCHASSIS).setContext(chassisType).setObjectId(otherObjectId).setStartPoint(aboveBelowChassisLayerPointsToUse.startPoint.x, getOtherAboveBelowChassisStart(chassisType, otherObjectId)).setEndPoint(aboveBelowChassisLayerPointsToUse.endPoint.x, getOtherAboveBelowChassisStart(chassisType, otherObjectId) + getOtherAboveBelowChassisLength(chassisType, otherObjectId)).setLength(getOtherAboveBelowChassisLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(aboveBelowChassisLineEndStyleToUse).setDrawBG(true);

        var path;
        if (offerManager.getCurMeasurementSysRoundedLength(other.getHorizontalGap()) !== 0) {
            var otherCapGapLengthDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, cabGapLayerInfoToUse.layerNum, otherCabGapDimensionConfig, cabGapDimensionStyleToUse);
            otherCapGapLengthDim.setShowAsPinned(other.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + otherObjectId + '.' + config.VALUE_TYPE.OTHERCABGAP;
            if (offerManager.isAttributeGroupVisible(path)) {
                otherCapGapLengthDim.setDrawingGroupName(path);
                otherCapGapLengthDim.setShowAsHyperLink(true);
            }

            dimensions.push(otherCapGapLengthDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(other.getLength()) > 0) {
            var otherLengthDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, lengthLayerInfoToUse.layerNum, otherLengthDimensionConfig, lengthDimensionStyleToUse);
            otherLengthDim.setShowAsPinned(other.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + otherObjectId +'.' + config.VALUE_TYPE.OTHERLENGTH;
            if (offerManager.isAttributeGroupVisible(path)) {
                otherLengthDim.setDrawingGroupName(path);
                otherLengthDim.setShowAsHyperLink(true);
            }

            dimensions.push(otherLengthDim);
        }

        var otherFromAxle1Front = offerManager.getRigOps().calculateFromAxle1Front(other);
        if (offerManager.getCurMeasurementSysRoundedLength(otherFromAxle1Front) !== 0 && chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            var otherFromAxle1FrontDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, fromAxle1FrontLayerInfoToUse.layerNum, otherFromAxle1FrontDimensionConfig, fromAxle1FrontDimensionStyleToUse);
            otherFromAxle1FrontDim.setShowAsPinned(other.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + otherObjectId + '.' + config.VALUE_TYPE.OTHERFROMAXLE1FRONT;
            if (offerManager.isAttributeGroupVisible(path)) {
                otherFromAxle1FrontDim.setDrawingGroupName(path);
                otherFromAxle1FrontDim.setShowAsHyperLink(true);
            }

            dimensions.push(otherFromAxle1FrontDim);
        }


        if (showEndOfEquipmentToEndOfCHassisDimension) {
            var gapToFromPoint = 0;
            if (chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                gapToFromPoint = chassisObjectToUse.getBumperToBackOfCab();
            }
            var endOfEquipmentToEndOfChassis = chassisObjectToUse.getAccessoryHolder().getEndOfEquipmentToEndOfChassis(other, chassisObjectToUse.getOverallLengthExclAccessories(), gapToFromPoint);
            if (offerManager.getCurMeasurementSysRoundedLength(endOfEquipmentToEndOfChassis) !== 0) {
                var otherEndOfEquipmentToEndOfChassisDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, endOfEquipmentToEndOfChassisLayerInfoToUse.layerNum, otherEndOfEquipmentToEndOfChassisDimensionConfig, endOfEquipmentToEndOfChassisDimensionStyleToUse);
                otherEndOfEquipmentToEndOfChassisDim.setShowAsPinned(other.getIsPinned());
                path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + otherObjectId + '.' + config.VALUE_TYPE.OTHERENDOFEQUIPMENTTOENDOFCHASSIS;
                if (offerManager.isAttributeGroupVisible(path)) {
                    otherEndOfEquipmentToEndOfChassisDim.setDrawingGroupName(path);
                    otherEndOfEquipmentToEndOfChassisDim.setShowAsHyperLink(true);
                }

                dimensions.push(otherEndOfEquipmentToEndOfChassisDim);
            }
        }

        if (offerManager.getCurMeasurementSysRoundedLength(other.getHeight()) > 0) {
            var otherHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, heightLayerInfoToUse.layerNum, otherHeightDimensionConfig, heightDimensionStyleToUse);
            otherHeightDim.setShowAsPinned(other.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + otherObjectId + '.' + config.VALUE_TYPE.OTHERHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                otherHeightDim.setDrawingGroupName(path);
                otherHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(otherHeightDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(other.getVerticalGap()) !== 0) {
            var otherAboveBelowChassisDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, aboveBelowChassisLayerInfoToUse.layerNum, otherAboveBelowChassisDimensionConfig, aboveBelowChassisDimensionStyleToUse);
            otherAboveBelowChassisDim.setShowAsPinned(other.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + otherObjectId + '.' + config.VALUE_TYPE.OTHERABOVEBELOWCHASSIS;
            if (offerManager.isAttributeGroupVisible(path)) {
                otherAboveBelowChassisDim.setDrawingGroupName(path);
                otherAboveBelowChassisDim.setShowAsHyperLink(true);
            }

            dimensions.push(otherAboveBelowChassisDim);
        }

    }

    function generateHookliftDimensions(chassisObjectToUse, hooklift) {
        var chassisType = chassisObjectToUse.getChassisObjectName();

        var length1LayerInfo = getEquipmentLayerInfo(chassisType, 'top', LAYER.HOOKLIFT_LENGTH_1, hooklift);
        var dimensionHorizontalLayer1Points = length1LayerInfo.layerPoints;

        var length2LayerInfo = getEquipmentLayerInfo(chassisType, 'top', LAYER.HOOKLIFT_LENGTH_2, hooklift);
        var dimensionHorizontalLayer2Points = length2LayerInfo.layerPoints;

        var length3LayerInfo = getEquipmentLayerInfo(chassisType, 'bottom', LAYER.HOOKLIFT_LENGTH_3, hooklift);
        var dimensionHorizontalLayer3Points = length3LayerInfo.layerPoints;

        var length4LayerInfo = getEquipmentLayerInfo(chassisType, 'bottom', LAYER.HOOKLIFT_LENGTH_4, hooklift);
        var dimensionHorizontalLayer4Points = length4LayerInfo.layerPoints;

        var height1LayerInfo = getEquipmentLayerInfo(chassisType, 'right', LAYER.HOOKLIFT_HEIGHT_1, hooklift);
        var dimensionVerticalLayer1Points = height1LayerInfo.layerPoints;

        var height2LayerInfo = getEquipmentLayerInfo(chassisType, 'right', LAYER.HOOKLIFT_HEIGHT_2, hooklift);
        var dimensionVerticalLayer2Points = height2LayerInfo.layerPoints;

        var hookliftCabGapDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTCABGAP).setContext(chassisType).setStartPoint(getHookliftCabGapStart(chassisType), dimensionHorizontalLayer1Points.startPoint.y).setEndPoint(getHookliftCabGapStart(chassisType) + getHookliftCabGapLength(chassisType), dimensionHorizontalLayer1Points.endPoint.y).setLength(getHookliftCabGapLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
        var hookliftLengthDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTLENGTH).setContext(chassisType).setStartPoint(getHookliftLengthStart(chassisType), dimensionHorizontalLayer1Points.startPoint.y).setEndPoint(getHookliftLengthStart(chassisType) + getHookliftLengthLength(chassisType), dimensionHorizontalLayer1Points.endPoint.y).setLength(getHookliftLengthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);
        
        var hookliftFromAxle1FrontDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTFROMAXLE1FRONT).setContext(chassisType).setStartPoint(getHookliftFromAxle1FrontStart(chassisType), dimensionHorizontalLayer2Points.startPoint.y).setEndPoint(getHookliftFromAxle1FrontStart(chassisType) + getHookliftFromAxle1FrontLength(chassisType), dimensionHorizontalLayer2Points.endPoint.y).setLength(getHookliftFromAxle1FrontLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
        var hookliftHookOffsetDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTHOOKOFFSET).setContext(chassisType).setStartPoint(getHookliftHookOffsetStart(chassisType), dimensionHorizontalLayer2Points.startPoint.y).setEndPoint(getHookliftHookOffsetStart(chassisType) + getHookliftHookOffsetLength(chassisType), dimensionHorizontalLayer2Points.endPoint.y).setLength(getHookliftHookOffsetLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);
        var hookliftEndOfEquipmentToEndOfChassisDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTENDOFEQUIPMENTTOENDOFCHASSIS).setContext(chassisType).setStartPoint(getHookliftEndOfEquipmentToEndOfChassisStart(chassisType), dimensionHorizontalLayer2Points.startPoint.y).setEndPoint(getHookliftEndOfEquipmentToEndOfChassisStart(chassisType) + getHookliftEndOfEquipmentToEndOfChassisLength(chassisType), dimensionHorizontalLayer2Points.endPoint.y).setLength(getHookliftEndOfEquipmentToEndOfChassisLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);

        var hookliftRearmostAxleToCentreOfRollerDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTREARMOSTAXLETOCENTREOFROLLER).setContext(chassisType).setStartPoint(getHookliftRearmostAxleToCentreOfRollerStart(chassisType), dimensionHorizontalLayer3Points.startPoint.y).setEndPoint(getHookliftRearmostAxleToCentreOfRollerStart(chassisType) + getHookliftRearmostAxleToCentreOfRollerLength(chassisType), dimensionHorizontalLayer3Points.endPoint.y).setLength(getHookliftRearmostAxleToCentreOfRollerLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER).setDrawBG(true);
        var hookliftCentreOfRollerToEndOfHookliftDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTCENTREOFROLLERTOENDOFHOOKLIFT).setContext(chassisType).setStartPoint(getHookliftCentreOfRollerToEndOfHookliftStart(chassisType), dimensionHorizontalLayer3Points.startPoint.y).setEndPoint(getHookliftCentreOfRollerToEndOfHookliftStart(chassisType) + getHookliftCentreOfRollerToEndOfHookliftLength(chassisType), dimensionHorizontalLayer3Points.endPoint.y).setLength(getHookliftCentreOfRollerToEndOfHookliftLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);

        var hookliftCentreOfRollerToEndDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTCENTREOFROLLERTOEND).setContext(chassisType).setStartPoint(getHookliftCentreOfRollerToEndStart(chassisType), dimensionHorizontalLayer4Points.startPoint.y).setEndPoint(getHookliftCentreOfRollerToEndStart(chassisType) + getHookliftCentreOfRollerToEndLength(chassisType), dimensionHorizontalLayer4Points.endPoint.y).setLength(getHookliftCentreOfRollerToEndLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
        

        var hookliftPlatformHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTPLATFORMHEIGHT).setContext(chassisType).setStartPoint(dimensionVerticalLayer1Points.startPoint.x, getHookliftPlatformHeightStart(chassisType)).setEndPoint(dimensionVerticalLayer1Points.endPoint.x, getHookliftPlatformHeightStart(chassisType) + getHookliftPlatformHeightLength(chassisType)).setLength(getHookliftPlatformHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP).setDrawBG(true);
        
        var hookliftHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTHEIGHT).setContext(chassisType).setStartPoint(dimensionVerticalLayer2Points.startPoint.x, getHookliftHeightStart(chassisType)).setEndPoint(dimensionVerticalLayer2Points.endPoint.x, getHookliftHeightStart(chassisType) + getHookliftHeightLength(chassisType)).setLength(getHookliftHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP).setDrawBG(true);
        var hookliftAboveBelowChassisDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HOOKLIFTABOVEBELOWCHASSIS).setContext(chassisType).setStartPoint(dimensionVerticalLayer2Points.startPoint.x, getHookliftAboveBelowChassisStart(chassisType)).setEndPoint(dimensionVerticalLayer2Points.endPoint.x, getHookliftAboveBelowChassisStart(chassisType) + getHookliftAboveBelowChassisLength(chassisType)).setLength(getHookliftAboveBelowChassisLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN).setDrawBG(true);




        var path;
        if (offerManager.getCurMeasurementSysRoundedLength(hooklift.getHorizontalGap()) !== 0) {
            var hookliftCabGapDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length1LayerInfo.layerNum, hookliftCabGapDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
            hookliftCabGapDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTCABGAP;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftCabGapDim.setDrawingGroupName(path);
                hookliftCabGapDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftCabGapDim);
        }

        var hookliftLengthDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length1LayerInfo.layerNum, hookliftLengthDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
        hookliftLengthDim.setShowAsPinned(hooklift.getIsPinned());
        path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTLENGTH;
        if (offerManager.isAttributeGroupVisible(path)) {
            hookliftLengthDim.setDrawingGroupName(path);
            hookliftLengthDim.setShowAsHyperLink(true);
        }

        dimensions.push(hookliftLengthDim);

        var hookliftFromAxle1Front = offerManager.getRigOps().calculateFromAxle1Front(hooklift);
        if (offerManager.getCurMeasurementSysRoundedLength(hookliftFromAxle1Front) !== 0 && chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            var hookliftFromAxle1FrontDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length2LayerInfo.layerNum, hookliftFromAxle1FrontDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
            hookliftFromAxle1FrontDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTFROMAXLE1FRONT;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftFromAxle1FrontDim.setDrawingGroupName(path);
                hookliftFromAxle1FrontDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftFromAxle1FrontDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(hooklift.getHookOffset()) !== 0) {
            var hookliftHookOffsetDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length2LayerInfo.layerNum, hookliftHookOffsetDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
            hookliftHookOffsetDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTHOOKOFFSET;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftHookOffsetDim.setDrawingGroupName(path);
                hookliftHookOffsetDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftHookOffsetDim);
        }


        var gapToFromPoint = 0;
        if (chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            gapToFromPoint = chassisObjectToUse.getBumperToBackOfCab();
        }
        var endOfEquipmentToEndOfChassis = chassisObjectToUse.getAccessoryHolder().getEndOfEquipmentToEndOfChassis(hooklift, chassisObjectToUse.getOverallLengthExclAccessories(), gapToFromPoint);
        if (offerManager.getCurMeasurementSysRoundedLength(endOfEquipmentToEndOfChassis) !== 0) {
            var hookliftEndOfEquipmentToEndOfChassisDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length2LayerInfo.layerNum, hookliftEndOfEquipmentToEndOfChassisDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
            hookliftEndOfEquipmentToEndOfChassisDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTENDOFEQUIPMENTTOENDOFCHASSIS;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftEndOfEquipmentToEndOfChassisDim.setDrawingGroupName(path);
                hookliftEndOfEquipmentToEndOfChassisDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftEndOfEquipmentToEndOfChassisDim);
        }

        //RearmostAxleToCentreOfRoller
        if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getRearmostAxleToCentreOfHookliftRoller(chassisType)) !== 0) {
            var hookliftRearmostAxleToCentreOfRollerDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length3LayerInfo.layerNum, hookliftRearmostAxleToCentreOfRollerDimensionConfig);
            hookliftRearmostAxleToCentreOfRollerDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTREARMOSTAXLETOCENTREOFROLLER;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftRearmostAxleToCentreOfRollerDim.setDrawingGroupName(path);
                hookliftRearmostAxleToCentreOfRollerDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftRearmostAxleToCentreOfRollerDim);
        }

        //CentreOfRollerToEndOfHooklift
        if (offerManager.getCurMeasurementSysRoundedLength(hooklift.getCentreOfRoller()) !== 0) {
            var hookliftCentreOfRollerToEndOfHookliftDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length3LayerInfo.layerNum, hookliftCentreOfRollerToEndOfHookliftDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
            hookliftCentreOfRollerToEndOfHookliftDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTCENTREOFROLLERTOENDOFHOOKLIFT;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftCentreOfRollerToEndOfHookliftDim.setDrawingGroupName(path);
                hookliftCentreOfRollerToEndOfHookliftDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftCentreOfRollerToEndOfHookliftDim);
        }
        
        //CentreOfRollerToEnd
        if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getCentreOfHookliftRollerToEnd(chassisType)) !== 0) {
            var hookliftCentreOfRollerToEndDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, length4LayerInfo.layerNum, hookliftCentreOfRollerToEndDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
            hookliftCentreOfRollerToEndDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTCENTREOFROLLERTOEND;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftCentreOfRollerToEndDim.setDrawingGroupName(path);
                hookliftCentreOfRollerToEndDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftCentreOfRollerToEndDim);
        }
        
        if (offerManager.getCurMeasurementSysRoundedLength(hooklift.getPlatformHeight()) > 0) {
            var hookliftPlatformHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, height1LayerInfo.layerNum, hookliftPlatformHeightDimensionConfig, config.DIMENSION_STYLE.JUMP_UP);
            hookliftPlatformHeightDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTPLATFORMHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftPlatformHeightDim.setDrawingGroupName(path);
                hookliftPlatformHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftPlatformHeightDim);
        }
        
        if (offerManager.getCurMeasurementSysRoundedLength(hooklift.getHeight()) !== 0) {
            var hookliftHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, height2LayerInfo.layerNum, hookliftHeightDimensionConfig, config.DIMENSION_STYLE.JUMP_UP);
            hookliftHeightDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftHeightDim.setDrawingGroupName(path);
                hookliftHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftHeightDim);
        }
        
        if (offerManager.getCurMeasurementSysRoundedLength(hooklift.getVerticalGap()) !== 0) {
            var hookliftAboveBelowChassisDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, height2LayerInfo.layerNum, hookliftAboveBelowChassisDimensionConfig, config.DIMENSION_STYLE.JUMP_DOWN);
            hookliftAboveBelowChassisDim.setShowAsPinned(hooklift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTABOVEBELOWCHASSIS;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftAboveBelowChassisDim.setDrawingGroupName(path);
                hookliftAboveBelowChassisDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftAboveBelowChassisDim);
        }
    }

    function generateTailliftDimensions(chassisObjectToUse, taillift) {

        var chassisType = chassisObjectToUse.getChassisObjectName();

        var tailliftLength1LayerInfo = getEquipmentLayerInfo(chassisType, 'top', LAYER.TAILLIFT_LENGTH_1, taillift);
        var tailliftDimensionTopPointsLayer1 = tailliftLength1LayerInfo.layerPoints;
        
        var tailliftLength2LayerInfo = getEquipmentLayerInfo(chassisType, 'top', LAYER.TAILLIFT_LENGTH_2, taillift);
        var tailliftDimensionTopPointsLayer2 = tailliftLength2LayerInfo.layerPoints;

        var tailliftHeight1LayerInfo = getEquipmentLayerInfo(chassisType, 'left', LAYER.TAILLIFT_HEIGHT_1, taillift);
        var tailliftDimensionLeftPointsLayer1 = tailliftHeight1LayerInfo.layerPoints;

        var tailliftHeight2LayerInfo = getEquipmentLayerInfo(chassisType, 'left', LAYER.TAILLIFT_HEIGHT_2, taillift);
        var tailliftDimensionLeftPointsLayer2 = tailliftHeight2LayerInfo.layerPoints;

        //var tailliftDimensionLeftPoints = getDimensionPoints('left', 1, taillift);
        //var tailliftDimensionRightPoints = getDimensionPoints('right', 1, taillift);
        //addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.HITCHOFFSET).setStart(getHitchOffsetStart).setLength(getHitchOffsetLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
        var tailliftMechanismLengthDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.TAILLIFTMECHANISMLENGTH).setContext(chassisType).setStartPoint(getTailliftMechanismLengthStart(chassisType), tailliftDimensionTopPointsLayer1.startPoint.y).setEndPoint(getTailliftMechanismLengthStart(chassisType) + getTailliftMechanismLengthLength(chassisType), tailliftDimensionTopPointsLayer1.endPoint.y).setLength(getTailliftMechanismLengthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
        var tailliftBodyGapDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.TAILLIFTFROMBACKOFBODY).setContext(chassisType).setStartPoint(getTailliftBodyGapStart(chassisType), tailliftDimensionTopPointsLayer1.startPoint.y).setEndPoint(getTailliftBodyGapStart(chassisType) + getTailliftBodyGapLength(chassisType), tailliftDimensionTopPointsLayer1.endPoint.y).setLength(getTailliftBodyGapLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);
        var tailliftPlatformProtrusionDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.TAILLIFTPLATFORMPROTRUSION).setContext(chassisType).setStartPoint(getTailliftPlatformProtrusionStart(chassisType), tailliftDimensionTopPointsLayer2.startPoint.y).setEndPoint(getTailliftPlatformProtrusionStart(chassisType) + getTailliftPlatformProtrusionLength(chassisType), tailliftDimensionTopPointsLayer2.endPoint.y).setLength(getTailliftPlatformProtrusionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
        

        var tailliftAboveBelowChassisDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.TAILLIFTABOVEORBELOWFLOOR).setContext(chassisType).setStartPoint(tailliftDimensionLeftPointsLayer1.startPoint.x, getTailliftAboveBelowFloorStart(chassisType)).setEndPoint(tailliftDimensionLeftPointsLayer1.endPoint.x, getTailliftAboveBelowFloorStart(chassisType) + getTailliftAboveBelowFloorLength(chassisType)).setLength(getTailliftAboveBelowFloorLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP).setDrawBG(true);
        var tailliftMechanismHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.TAILLIFTMECHANISMHEIGHT).setContext(chassisType).setStartPoint(tailliftDimensionLeftPointsLayer2.startPoint.x, getTailliftMechanismHeightStart(chassisType)).setEndPoint(tailliftDimensionLeftPointsLayer2.endPoint.x, getTailliftMechanismHeightStart(chassisType) + getTailliftMechanismHeightLength(chassisType)).setLength(getTailliftMechanismHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN).setDrawBG(true);
        var tailliftPlatformHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.TAILLIFTPLATFORMHEIGHT).setContext(chassisType).setStartPoint(tailliftDimensionLeftPointsLayer2.startPoint.x, getTailliftPlatformHeightStart(chassisType)).setEndPoint(tailliftDimensionLeftPointsLayer2.endPoint.x, getTailliftPlatformHeightStart(chassisType) + getTailliftPlatformHeightLength(chassisType)).setLength(getTailliftPlatformHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP).setDrawBG(true);
        var path;
        if (taillift.getMechanismLength() > 0) {
            var tailliftMechanismLengthDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, tailliftDimensionTopPointsLayer1.layerNum, tailliftMechanismLengthDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
            tailliftMechanismLengthDim.setShowAsPinned(taillift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT + '.' + config.VALUE_TYPE.TAILLIFTMECHANISMLENGTH;
            if (offerManager.isAttributeGroupVisible(path)) {
                tailliftMechanismLengthDim.setDrawingGroupName(path);
                tailliftMechanismLengthDim.setShowAsHyperLink(true);
            }

            dimensions.push(tailliftMechanismLengthDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(taillift.getFromBackOfBody()) !== 0) {
            var tailliftBodyGapDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, tailliftDimensionTopPointsLayer1.layerNum, tailliftBodyGapDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
            tailliftBodyGapDim.setShowAsPinned(taillift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT + '.' + config.VALUE_TYPE.TAILLIFTFROMBACKOFBODY;
            if (offerManager.isAttributeGroupVisible(path)) {
                tailliftBodyGapDim.setDrawingGroupName(path);
                tailliftBodyGapDim.setShowAsHyperLink(true);
            }

            dimensions.push(tailliftBodyGapDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(taillift.getPlatformProtrusion()) > 0) {
            var tailliftPlatformProtrusionDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, tailliftDimensionTopPointsLayer2.layerNum, tailliftPlatformProtrusionDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
            tailliftPlatformProtrusionDim.setShowAsPinned(taillift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT + '.' + config.VALUE_TYPE.TAILLIFTPLATFORMPROTRUSION;
            if (offerManager.isAttributeGroupVisible(path)) {
                tailliftPlatformProtrusionDim.setDrawingGroupName(path);
                tailliftPlatformProtrusionDim.setShowAsHyperLink(true);
            }

            dimensions.push(tailliftPlatformProtrusionDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(taillift.getAboveOrBelowFloor()) !== 0) {
            var tailliftAboveBelowFloorDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, tailliftDimensionLeftPointsLayer1.layerNum, tailliftAboveBelowChassisDimensionConfig, config.DIMENSION_STYLE.JUMP_UP);
            tailliftAboveBelowFloorDim.setShowAsPinned(taillift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT + '.' + config.VALUE_TYPE.TAILLIFTABOVEORBELOWFLOOR;
            if (offerManager.isAttributeGroupVisible(path)) {
                tailliftAboveBelowFloorDim.setDrawingGroupName(path);
                tailliftAboveBelowFloorDim.setShowAsHyperLink(true);
            }

            dimensions.push(tailliftAboveBelowFloorDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(taillift.getMechanismHeight()) > 0) {
            var tailliftMechanismHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, tailliftDimensionLeftPointsLayer2.layerNum, tailliftMechanismHeightDimensionConfig, config.DIMENSION_STYLE.JUMP_DOWN);
            tailliftMechanismHeightDim.setShowAsPinned(taillift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT + '.' + config.VALUE_TYPE.TAILLIFTMECHANISMHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                tailliftMechanismHeightDim.setDrawingGroupName(path);
                tailliftMechanismHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(tailliftMechanismHeightDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(taillift.getPlatformHeight()) > 0) {
            var tailliftPlatformHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, tailliftDimensionLeftPointsLayer2.layerNum, tailliftPlatformHeightDimensionConfig, config.DIMENSION_STYLE.JUMP_UP);
            tailliftPlatformHeightDim.setShowAsPinned(taillift.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT + '.' + config.VALUE_TYPE.TAILLIFTPLATFORMHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                tailliftPlatformHeightDim.setDrawingGroupName(path);
                tailliftPlatformHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(tailliftPlatformHeightDim);
        }

    }

    function generateFridgeDimensions(chassisObjectToUse, fridge) {
        
        var chassisType = chassisObjectToUse.getChassisObjectName();

        var fridgeLength1LayerInfo = getEquipmentLayerInfo(chassisType, 'bottom', LAYER.FRIDGE_LENGTH_1, fridge);
        var fridgeDimensionBottomPoints = fridgeLength1LayerInfo.layerPoints;

        var fridgeLength2LayerInfo = getEquipmentLayerInfo(chassisType, 'bottom', LAYER.FRIDGE_LENGTH_2, fridge);
        var fridgeDimensionBottom2Points = fridgeLength2LayerInfo.layerPoints;

        var fridgeHeight1LayerInfo = getEquipmentLayerInfo(chassisType, 'left', LAYER.FRIDGE_HEIGHT_1, fridge);
        var fridgeDimensionLeftPoints = fridgeHeight1LayerInfo.layerPoints;

        var fridgeHeight2LayerInfo = getEquipmentLayerInfo(chassisType, 'right', LAYER.FRIDGE_HEIGHT_2, fridge);
        var fridgeDimensionRightPoints = fridgeHeight2LayerInfo.layerPoints;
        //addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.HITCHOFFSET).setStart(getHitchOffsetStart).setLength(getHitchOffsetLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
        var fridgeFromFrontOfBodyDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.FRIDGEFROMFRONTOFBODY).setContext(chassisType).setStartPoint(getFridgeFromFrontOfBodyStart(chassisType), fridgeDimensionBottomPoints.startPoint.y).setEndPoint(getFridgeFromFrontOfBodyStart(chassisType) + getFridgeFromFrontOfBodyLength(chassisType), fridgeDimensionBottomPoints.endPoint.y).setLength(getFridgeFromFrontOfBodyLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);
        var fridgeProtrusionDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.FRIDGEPROTRUSION).setContext(chassisType).setStartPoint(getFridgeProtrusionStart(chassisType), fridgeDimensionBottom2Points.startPoint.y).setEndPoint(getFridgeProtrusionStart(chassisType) + getFridgeProtrusionLengthForEndPoint(chassisType), fridgeDimensionBottom2Points.endPoint.y).setLength(getFridgeProtrusionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);
        var fridgeMountingPositionDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.FRIDGEMOUNTINGPOSITION).setContext(chassisType).setStartPoint(fridgeDimensionLeftPoints.startPoint.x, getFridgeMountingPositionStart(chassisType)).setEndPoint(fridgeDimensionLeftPoints.endPoint.x, getFridgeMountingPositionStart(chassisType) + getFridgeMountingPositionLength(chassisType)).setLength(getFridgeMountingPositionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN).setDrawBG(true);
        
        var fridgeHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.FRIDGEHEIGHT).setContext(chassisType).setStartPoint(fridgeDimensionRightPoints.startPoint.x, getFridgeHeightStart(chassisType)).setEndPoint(fridgeDimensionRightPoints.endPoint.x, getFridgeHeightStart(chassisType) + getFridgeHeightLength(chassisType)).setLength(getFridgeHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN).setDrawBG(true);
        
        var path;

        if (offerManager.getCurMeasurementSysRoundedLength(fridge.getFromFrontOfBody()) > 0 || offerManager.getCurMeasurementSysRoundedLength(fridge.getFromFrontOfBody()) < 0) {
            var fridgeFromFrontOfBodyDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, fridgeFromFrontOfBodyDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
            fridgeFromFrontOfBodyDim.setShowAsPinned(fridge.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE + '.' + config.VALUE_TYPE.FRIDGEFROMFRONTOFBODY;
            if (offerManager.isAttributeGroupVisible(path)) {
                fridgeFromFrontOfBodyDim.setDrawingGroupName(path);
                fridgeFromFrontOfBodyDim.setShowAsHyperLink(true);
            }

            dimensions.push(fridgeFromFrontOfBodyDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(fridge.getProtrusion()) > 0) {
            var fridgeProtrusionDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, fridgeProtrusionDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
            fridgeProtrusionDim.setShowAsPinned(fridge.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE + '.' + config.VALUE_TYPE.FRIDGEPROTRUSION;
            if (offerManager.isAttributeGroupVisible(path)) {
                fridgeProtrusionDim.setDrawingGroupName(path);
                fridgeProtrusionDim.setShowAsHyperLink(true);
            }

            dimensions.push(fridgeProtrusionDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(fridge.getMountingPosition()) > 0) {
            var fridgeMountingPositionDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, fridgeMountingPositionDimensionConfig, config.DIMENSION_STYLE.JUMP_DOWN);
            fridgeMountingPositionDim.setShowAsPinned(fridge.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE + '.' + config.VALUE_TYPE.FRIDGEMOUNTINGPOSITION;
            if (offerManager.isAttributeGroupVisible(path)) {
                fridgeMountingPositionDim.setDrawingGroupName(path);
                fridgeMountingPositionDim.setShowAsHyperLink(true);
            }

            dimensions.push(fridgeMountingPositionDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(fridge.getHeight()) > 0) {
            var fridgeHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, fridgeHeightDimensionConfig, config.DIMENSION_STYLE.JUMP_DOWN);
            fridgeHeightDim.setShowAsPinned(fridge.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE + '.' + config.VALUE_TYPE.FRIDGEHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                fridgeHeightDim.setDrawingGroupName(path);
                fridgeHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(fridgeHeightDim);
        }

    }

    function generateHitchDimensions(chassisObjectToUse, hitch) {
        
        var chassisType = chassisObjectToUse.getChassisObjectName();

        var hitchLength1LayerInfo = getEquipmentLayerInfo(chassisType, 'bottom', LAYER.HITCH_LENGTH_1, hitch);
        var hitchDimensionBottomPoints = hitchLength1LayerInfo.layerPoints;
        //addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.HITCHOFFSET).setStart(getHitchOffsetStart).setLength(getHitchOffsetLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
        var hitchOffsetDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.HITCHOFFSET).setContext(chassisType).setStartPoint(getHitchOffsetStart(chassisType), hitchDimensionBottomPoints.startPoint.y).setEndPoint(getHitchOffsetStart(chassisType) + getHitchOffsetLength(chassisType), hitchDimensionBottomPoints.endPoint.y).setLength(getHitchOffsetLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);

        if (offerManager.getCurMeasurementSysRoundedLength(hitch.getOffset()) !== 0) {
            var hitchOffsetDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, hitchOffsetDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);

            var path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH + '.' + config.VALUE_TYPE.HITCHOFFSET;
            if (offerManager.isAttributeGroupVisible(path)) {
                hitchOffsetDim.setDrawingGroupName(path);
                hitchOffsetDim.setShowAsHyperLink(true);
            }

            dimensions.push(hitchOffsetDim);
        }

    }

    function generateFifthWheelDimensions(chassisObjectToUse, fifthWheel) {
        var path
        var chassisType = chassisObjectToUse.getChassisObjectName();
        var fifthWheelOffsetDimensionConfig, fifthWheelOffsetDim;
        if (fifthWheel !== null) {

            var fifthWheelLength1LayerInfo = getEquipmentLayerInfo(chassisType, 'bottom', LAYER.FIFTH_WHEEL_LENGTH_1, fifthWheel);
            var fifthWheelDimensionBottomPoints = fifthWheelLength1LayerInfo.layerPoints;
            //addDimensionConfig(new DimensionConfig().setId(config.VALUE_TYPE.FIFTHWHEELOFFSET).setStart(getFifthWheelDimensionStart).setLength(getFifthWheelDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue));
            fifthWheelOffsetDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.FIFTHWHEELOFFSET).setContext(chassisType).setStartPoint(getFifthWheelDimensionStart(chassisType), fifthWheelDimensionBottomPoints.startPoint.y).setEndPoint(getFifthWheelDimensionStart(chassisType) + getFifthWheelDimensionLength(chassisType), fifthWheelDimensionBottomPoints.endPoint.y).setLength(getFifthWheelDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);

        

            if (offerManager.getCurMeasurementSysRoundedLength(fifthWheel.getOffset()) !== 0) {
                fifthWheelOffsetDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, fifthWheelOffsetDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);

                path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET;
                if (offerManager.isAttributeGroupVisible(path)) {
                    fifthWheelOffsetDim.setDrawingGroupName(path);
                    fifthWheelOffsetDim.setShowAsHyperLink(true);
                }

                dimensions.push(fifthWheelOffsetDim);
            }
        } else {
            if (offerManager.getCurMeasurementSysRoundedLength(curVehicle.getFifthWheelOffset()) !== 0) {
                fifthWheelOffsetDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.FIFTHWHEELOFFSET).setContext(chassisType).setStartPoint(getFifthWheelDimensionStart(chassisType), curVehicle.getChassisHeightMax() - layerIncrement).setEndPoint(getFifthWheelDimensionStart(chassisType) + getFifthWheelDimensionLength(chassisType), curVehicle.getChassisHeightMax() - layerIncrement).setLength(getFifthWheelDimensionLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
                fifthWheelOffsetDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, fifthWheelOffsetDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
                
                //if (vehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET;
                //} else {
                //    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET;
                //}
                if (offerManager.isAttributeGroupVisible(path)) {
                    fifthWheelOffsetDim.setDrawingGroupName(path);
                    fifthWheelOffsetDim.setShowAsHyperLink(true);
                }

                dimensions.push(fifthWheelOffsetDim);
            }
        }

    }

    function generateCraneDimensions(chassisObjectToUse, crane) {

        var chassisType = chassisObjectToUse.getChassisObjectName();

        var craneLength1LayerInfo = getEquipmentLayerInfo(chassisType, 'top', LAYER.CRANE_LENGTH_1, crane);
        var craneDimensionTopPoints = craneLength1LayerInfo.layerPoints;

        var craneLength2LayerInfo = getEquipmentLayerInfo(chassisType, 'top', LAYER.CRANE_LENGTH_2, crane);
        var craneDimensionTop2Points = craneLength2LayerInfo.layerPoints;

        var craneHeight1LayerInfo = getEquipmentLayerInfo(chassisType, 'right', LAYER.CRANE_HEIGHT_1, crane);
        var craneDimensionRightPoints = craneHeight1LayerInfo.layerPoints;

        var craneHeight2LayerInfo = getEquipmentLayerInfo(chassisType, 'left', LAYER.CRANE_HEIGHT_2, crane);
        var craneDimensionLeftPoints = craneHeight2LayerInfo.layerPoints;

        var craneCabGapDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.CRANECABGAP).setContext(chassisType).setStartPoint(getCraneCabGapStart(chassisType), craneDimensionTopPoints.startPoint.y).setEndPoint(getCraneCabGapStart(chassisType) + getCraneCabGapLength(chassisType), craneDimensionTopPoints.endPoint.y).setLength(getCraneCabGapLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
        var craneLengthDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.CRANELENGTH).setContext(chassisType).setStartPoint(getCraneLengthStart(chassisType), craneDimensionTopPoints.startPoint.y).setEndPoint(getCraneLengthStart(chassisType) + getCraneLengthLength(chassisType), craneDimensionTopPoints.endPoint.y).setLength(getCraneLengthLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);
        
        var craneFromAxle1FrontDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.CRANEFROMAXLE1FRONT).setContext(chassisType).setStartPoint(getCraneFromAxle1FrontStart(chassisType), craneDimensionTop2Points.startPoint.y).setEndPoint(getCraneFromAxle1FrontStart(chassisType) + getCraneFromAxle1FrontLength(chassisType), craneDimensionTop2Points.endPoint.y).setLength(getCraneFromAxle1FrontLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_LEFT).setDrawBG(true);
        var craneEndOfEquipmentToEndOfChassisDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.CRANEENDOFEQUIPMENTTOENDOFCHASSIS).setContext(chassisType).setStartPoint(getCraneEndOfEquipmentToEndOfChassisStart(chassisType), craneDimensionTop2Points.startPoint.y).setEndPoint(getCraneEndOfEquipmentToEndOfChassisStart(chassisType) + getCraneEndOfEquipmentToEndOfChassisLength(chassisType), craneDimensionTop2Points.endPoint.y).setLength(getCraneEndOfEquipmentToEndOfChassisLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_RIGHT).setDrawBG(true);
        
        var craneHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.CRANEHEIGHT).setContext(chassisType).setStartPoint(craneDimensionRightPoints.startPoint.x, getCraneHeightStart(chassisType)).setEndPoint(craneDimensionRightPoints.endPoint.x, getCraneHeightStart(chassisType) + getCraneHeightLength(chassisType)).setLength(getCraneHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_UP).setDrawBG(true);
        var craneSubframeHeightDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.CRANESUBFRAMEHEIGHT).setContext(chassisType).setStartPoint(craneDimensionRightPoints.startPoint.x, getCraneSubframeHeightStart(chassisType)).setEndPoint(craneDimensionRightPoints.endPoint.x, getCraneSubframeHeightStart(chassisType) + getCraneSubframeHeightLength(chassisType)).setLength(getCraneSubframeHeightLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN).setDrawBG(true);
        var craneAboveBelowChassisDimensionConfig = new DimensionConfig().setId(config.VALUE_TYPE.CRANEABOVEBELOWCHASSIS).setContext(chassisType).setStartPoint(craneDimensionLeftPoints.startPoint.x, getCraneAboveBelowChassisStart(chassisType)).setEndPoint(craneDimensionLeftPoints.endPoint.x, getCraneAboveBelowChassisStart(chassisType) + getCraneAboveBelowChassisLength(chassisType)).setLength(getCraneAboveBelowChassisLength).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue).setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_EXTERNAL_DIMENSION_WITH_DELIMITER_JUMP_DOWN).setDrawBG(true);


        
        var path;

        if (offerManager.getCurMeasurementSysRoundedLength(crane.getHorizontalGap()) !== 0) {
            var craneCabGapDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, craneLength1LayerInfo.layerNum, craneCabGapDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
            craneCabGapDim.setShowAsPinned(crane.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANECABGAP;
            if (offerManager.isAttributeGroupVisible(path)) {
                craneCabGapDim.setDrawingGroupName(path);
                craneCabGapDim.setShowAsHyperLink(true);
            }

            dimensions.push(craneCabGapDim);
        }

        var craneLengthDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, craneLength1LayerInfo.layerNum, craneLengthDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
        craneLengthDim.setShowAsPinned(crane.getIsPinned());
        path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANELENGTH;
        if (offerManager.isAttributeGroupVisible(path)) {
            craneLengthDim.setDrawingGroupName(path);
            craneLengthDim.setShowAsHyperLink(true);
        }

        dimensions.push(craneLengthDim);


        var craneFromAxle1Front = offerManager.getRigOps().calculateFromAxle1Front(crane);
        if (offerManager.getCurMeasurementSysRoundedLength(craneFromAxle1Front) !== 0 && chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            var craneFromAxle1FrontDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, craneLength2LayerInfo.layerNum, craneFromAxle1FrontDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);
            craneFromAxle1FrontDim.setShowAsPinned(crane.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANEFROMAXLE1FRONT;
            if (offerManager.isAttributeGroupVisible(path)) {
                craneFromAxle1FrontDim.setDrawingGroupName(path);
                craneFromAxle1FrontDim.setShowAsHyperLink(true);
            }

            dimensions.push(craneFromAxle1FrontDim);
        }

        
        var gapToFromPoint = 0;
        if (chassisType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            gapToFromPoint = chassisObjectToUse.getBumperToBackOfCab();
        }
        var endOfEquipmentToEndOfChassis = chassisObjectToUse.getAccessoryHolder().getEndOfEquipmentToEndOfChassis(crane, chassisObjectToUse.getOverallLengthExclAccessories(), gapToFromPoint);
        if (offerManager.getCurMeasurementSysRoundedLength(endOfEquipmentToEndOfChassis) !== 0) {
            var craneEndOfEquipmentToEndOfChassisDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, craneLength2LayerInfo.layerNum, craneEndOfEquipmentToEndOfChassisDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
            craneEndOfEquipmentToEndOfChassisDim.setShowAsPinned(crane.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANEENDOFEQUIPMENTTOENDOFCHASSIS;
            if (offerManager.isAttributeGroupVisible(path)) {
                craneEndOfEquipmentToEndOfChassisDim.setDrawingGroupName(path);
                craneEndOfEquipmentToEndOfChassisDim.setShowAsHyperLink(true);
            }

            dimensions.push(craneEndOfEquipmentToEndOfChassisDim);
        }
        

        if (offerManager.getCurMeasurementSysRoundedLength(crane.getHeight()) !== 0) {
            var craneHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, craneHeight1LayerInfo.layerNum, craneHeightDimensionConfig, config.DIMENSION_STYLE.JUMP_UP);
            craneHeightDim.setShowAsPinned(crane.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANEHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                craneHeightDim.setDrawingGroupName(path);
                craneHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(craneHeightDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(crane.getSubframeHeight()) !== 0) {
            var craneSubframeHeightDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, craneHeight1LayerInfo.layerNum, craneSubframeHeightDimensionConfig, config.DIMENSION_STYLE.JUMP_DOWN);
            craneSubframeHeightDim.setShowAsPinned(crane.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANESUBFRAMEHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                craneSubframeHeightDim.setDrawingGroupName(path);
                craneSubframeHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(craneSubframeHeightDim);
        }

        if (offerManager.getCurMeasurementSysRoundedLength(crane.getAboveBelowChassis()) !== 0) {
            var craneAboveBelowChassisDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, craneHeight2LayerInfo.layerNum, craneAboveBelowChassisDimensionConfig, config.DIMENSION_STYLE.JUMP_DOWN);
            craneAboveBelowChassisDim.setShowAsPinned(crane.getIsPinned());
            path = chassisType + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANEABOVEBELOWCHASSIS;
            if (offerManager.isAttributeGroupVisible(path)) {
                craneAboveBelowChassisDim.setDrawingGroupName(path);
                craneAboveBelowChassisDim.setShowAsHyperLink(true);
            }

            dimensions.push(craneAboveBelowChassisDim);
        }


        //CRANESUBFRAMEHEIGHT

        ////CRANEENDOFEQUIPMENTTOENDOFCHASSIS, CRANEAXLE1FRONTTOEQUIPMENT
        //if (crane.getHorizontalGap() > 0) {
        //    var craneCabGapDim = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, 1, craneCabGapDimensionConfig, config.DIMENSION_STYLE.JUMP_LEFT);

        //    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANECABGAP;
        //    if (offerManager.isAttributeGroupVisible(path)) {
        //        craneCabGapDim.setDrawingGroupName(path);
        //        craneCabGapDim.setShowAsHyperLink(true);
        //    }

        //    dimensions.push(craneCabGapDim);
        //}
    }

}



function generateDimensions(vehicle, displayType, viewType) {

    

    layerNumbers = {
        TOP: {
            layerNum: 0
        },
        LEFT: {
            layerNum: 1
        },
        BOTTOM: {
            layerNum: 0
        },
        RIGHT: {
            layerNum: 1
        },
        incrementLayerNum: function (pos) {
            this[pos].layerNum = this[pos].layerNum + 1;
        },
        popCurrentBottomLayer: function () {
            var self = this;
            dimensions.forEach(function (dim){
                if (dim.type === config.DIMENSION_TYPE.HORIZONTAL_BOTTOM) {
                    if (dim.layerNumber === self['BOTTOM'].layerNum) {
                        dim.layerNumber++;
                    }
                }
            });
            this['BOTTOM'].layerNum++;
        }
    }

    function getNextLayerNum(pos, layer) {
        if (layerNumbers[pos][layer] === undefined) {
            layerNumbers[pos][layer] = true;
            layerNumbers[pos].layerNum = layerNumbers[pos].layerNum + 1;
            return layerNumbers[pos].layerNum;
        } else {
            return layerNumbers[pos].layerNum;
        }
    }

    

    var isTrialUser = false;
    //if (globals.user.isTrialUser() === true) {
    //    isTrialUser = true;
    //}

    var dimensions = [], layerNumber;
    var start, length, displayLength;

    var hasBody = (vehicle.getBody() !== null);
    var needToIncrementLayerAfterPop = false;

    var curPos, curLayer;

    var curTrailer1Body = null;
    if (curTrailer1 !== null) {
        curTrailer1Body = curTrailer1.getAccessoryHolder().getBody();
    }
    var curVehicleBody = vehicle.getAccessoryHolder().getBody();

    bottomDimensionLayerNum = 0;
    topDimensionLayerNum = 0;
    leftDimensionLayerNum = 0;
    rightDimensionLayerNum = 0;

    cogBottomDimensionLayerNum = 0;
    cogLeftDimensionLayerNum = 0;
    cogTopDimensionLayerNum = 0;
    cogRightDimensionLayerNum = 0;
    cogTopLeftDimensionLayerNum = 0;
    cogTopRightDimensionLayerNum = 0;
    cogBottomLeftDimensionLayerNum = 0;
    cogBottomRightDimensionLayerNum = 0;

    var previousChassisBottomDimensionLayerNum = 0, previousChassisTopDimensionLayerNum = 0;

    if (viewType === config.VIEW_TYPE.OVERVIEW) {
        if (curViewDetail !== config.VIEW_DETAIL.BRIDGE_VIEW) {
            switch (offerManager.getRigOps().getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    generateVehicleOverviewViewDimensions();
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                    generateVehicleAndSemiOrInterlinkOverviewDimensions();
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    generateVehicleAndDrawbarOverviewDimensions();
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    generateVehicleAndPupOverviewDimensions();
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    generateVehicleAndInterlinkAndSemiOverviewDimensions();
                    break;
            }
            
        } else {
            generateBridgeViewDimensions();
        }
        
    } else if (viewType === config.VIEW_TYPE.COG) {
        generateCOGViewDimensions();
    }

    function addWarningBehaviourToDimension(dimension, warningId, path) {
        if (offerManager.getDistinctWarnings().getStringTrackerObjectById(warningId) !== null) {
            if (offerManager.getDistinctWarnings().getStringTrackerObjectById(warningId).getIsNew() === true) {
                dimension.setPulse(true);
            }
            dimension.setShowAsWarning(true);
            dimension.setDrawingGroupName(path);
        }else {
            let matchedContext = offerManager.getTriggeredRuleContexts().dim[dimension.getContextId().toUpperCase()];
            if(matchedContext) {
                if (offerManager.getDistinctWarnings().getStringTrackerObjectById(matchedContext.messageCode).getIsNew() === true) {
                    dimension.setPulse(true);
                }
                dimension.setShowAsWarning(true);
                dimension.setDrawingGroupName(path);
            }
        }
    }

    

    function generateBridgeViewDimensions() {

        var bridgeGroupsVisible = offerManager.getBridgeGroupsVisibleForActiveLegislations();
        var onlyCountDownAxles = true;
        var numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        var numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        switch (offerManager.getRigOps().getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                curPos = POS.BOTTOM;
                curLayer = LAYER.BRIDGE_GROUP_3_AND_4;
                if (bridgeGroupsVisible.showExtremeRearDrivenGroup) {
                    showGroup3Dimension(curPos, curLayer);
                }
                if (bridgeGroupsVisible.showExtremeFrontGroup) {
                    showGroup4Dimension(curPos, curLayer);
                }
                if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
                    curLayer = LAYER.BRIDGE_GROUP_2;
                    showGroup2Dimension(curPos, curLayer);
                }
                curLayer = LAYER.BRIDGE_GROUP_1;
                showGroup1Dimension(curPos, curLayer);
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                curPos = POS.BOTTOM;
                curLayer = LAYER.BRIDGE_GROUP_5_AND_6_AND_13;
                if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
                    showGroup6Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.VEHICLE);
                }
                if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
                    showGroup5Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.VEHICLE);
                }
                if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
                    showGroup7Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                }
                if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
                    curLayer = LAYER.BRIDGE_GROUP_4;
                    showGroup4Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.VEHICLE);
                }

                curLayer = LAYER.BRIDGE_GROUP_1;
                showGroup1Dimension(curPos, curLayer);
                curLayer = LAYER.BRIDGE_GROUP_3;
                showGroup3Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                curLayer = LAYER.BRIDGE_GROUP_2;
                showGroup2Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                curPos = POS.BOTTOM;
                curLayer = LAYER.BRIDGE_GROUP_9_AND_8_AND_10_AND_11;
                if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
                    showGroup9Dimension(curPos, curLayer);
                }
                if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
                    showGroup8Dimension(curPos, curLayer);
                }
                if (bridgeGroupsVisible.showTrailer1ExtremeFrontGroup) {
                    showGroup10Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                }
                if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
                    showGroup11Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                }
                curLayer = LAYER.BRIDGE_GROUP_7;
                if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
                    showGroup7Dimension(curPos, curLayer);
                }
                curLayer = LAYER.BRIDGE_GROUP_1_AND_GROUP_6;
                showGroup1Dimension(curPos, curLayer);
                showGroup6Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                curLayer = LAYER.BRIDGE_GROUP_2;
                showGroup2Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                curLayer = LAYER.BRIDGE_GROUP_4;
                showGroup4Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                curLayer = LAYER.BRIDGE_GROUP_5;
                showGroup5Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                curLayer = LAYER.BRIDGE_GROUP_3;
                showGroup3Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                curPos = POS.BOTTOM;
                curLayer = LAYER.BRIDGE_GROUP_9_AND_8_AND_10_AND_11;
                if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
                    showGroup9Dimension(curPos, curLayer);
                }
                if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
                    showGroup8Dimension(curPos, curLayer);
                }
                if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
                    showGroup10Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                }
                if (bridgeGroupsVisible.showTrailer2ExtremeRearGroup) {
                    showGroup11Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
                }
                if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
                    curLayer = LAYER.BRIDGE_GROUP_7;
                    showGroup7Dimension(curPos, curLayer);
                }
                curLayer = LAYER.BRIDGE_GROUP_1_AND_GROUP_6;
                showGroup1Dimension(curPos, curLayer);
                showGroup6Dimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
                curLayer = LAYER.BRIDGE_GROUP_2;
                showGroup2Dimension(curPos, curLayer);
                curLayer = LAYER.BRIDGE_GROUP_4;
                showGroup4Dimension(curPos, curLayer);
                curLayer = LAYER.BRIDGE_GROUP_5;
                showGroup5Dimension(curPos, curLayer);
                curLayer = LAYER.BRIDGE_GROUP_3;
                showGroup3Dimension(curPos, curLayer);
                curLayer = LAYER.BRIDGE_X_Y_DIMENSIONS;
                showBridgeXYDimensions(curPos, curLayer);
                break;
        }

        
        function showGroup1Dimension(curPos, curLayer) {

            var group1DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP1BRIDGEDISTANCE);

            group1DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP1));

            //var group1Dim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group1DimensionConfig);
            var group1Dim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group1DimensionConfig);
            

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASETHEORETICAL;
            if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW && offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE) {
                addWarningBehaviourToDimension(group1Dim, config.WARNING_ID.OVERALL_WHEELBASE, path);
            }

            dimensions.push(group1Dim);
            return true;
        } 

        function getBridgeGroupDimensionLabelDisplayText(bridgeGroup) {
                
            var groupLabel = offerManager.getRigOps().getBridgeGroupLabel(bridgeGroup);

            var customDimTextFunc = function (attrContext, lengthVal) { return offerManager.getUIBridgeFormattedDimensionValue(lengthVal) + ' (' + groupLabel + ')' };

            return customDimTextFunc;
        }

        function showGroup2Dimension(curPos, curLayer, attrContext) {
            var group2DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP2BRIDGEDISTANCE, attrContext);

            group2DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP2));

            var group2Dim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group2DimensionConfig);

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASETHEORETICAL;
            if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW && (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_PUP || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_SEMI)) {
                addWarningBehaviourToDimension(group2Dim, config.WARNING_ID.OVERALL_WHEELBASE, path);
            }

            dimensions.push(group2Dim);
            return true;
        }

        function showGroup3Dimension(curPos, curLayer, attrContext) {
            var group3DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP3BRIDGEDISTANCE, attrContext);

            group3DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP3));

            var group3Dim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group3DimensionConfig);

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASETHEORETICAL;
            if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW && (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI)) {
                addWarningBehaviourToDimension(group3Dim, config.WARNING_ID.OVERALL_WHEELBASE, path);
            }

            dimensions.push(group3Dim);
            return true;
        }

        function showGroup4Dimension(curPos, curLayer, attrContext) {
            var group4DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP4BRIDGEDISTANCE, attrContext);

            group4DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP4));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group4DimensionConfig));
            return true;
        }

        function showGroup5Dimension(curPos, curLayer, attrContext) {
            var group5DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP5BRIDGEDISTANCE, attrContext);

            group5DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP5));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group5DimensionConfig));
            return true;
        }

        function showGroup6Dimension(curPos, curLayer, attrContext) {
            var group6DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP6BRIDGEDISTANCE, attrContext);

            group6DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP6));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group6DimensionConfig));
            return true;
        }

        function showGroup7Dimension(curPos, curLayer, attrContext) {
            var group7DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP7BRIDGEDISTANCE, attrContext);

            group7DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP7));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group7DimensionConfig));
            return true;
        }

        function showGroup8Dimension(curPos, curLayer, attrContext) {
            var group8DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP7BRIDGEDISTANCE, attrContext);

            group8DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP8));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group8DimensionConfig));
            return true;
        }

        function showGroup9Dimension(curPos, curLayer, attrContext) {
            var group9DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP9BRIDGEDISTANCE, attrContext);

            group9DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP9));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group9DimensionConfig));
            return true;
        }

        function showGroup10Dimension(curPos, curLayer, attrContext) {
            var group10DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP10BRIDGEDISTANCE, attrContext);

            group10DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP10));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group10DimensionConfig));
            return true;
        }

        function showGroup11Dimension(curPos, curLayer, attrContext) {
            var group11DimensionConfig = getDimensionConfig(config.VALUE_TYPE.GROUP11BRIDGEDISTANCE, attrContext);

            group11DimensionConfig.addCustomDisplayTextFunction(getBridgeGroupDimensionLabelDisplayText(config.BRIDGE_GROUPS.GROUP11));

            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), group11DimensionConfig));
            return true;
        }

        function showBridgeXYDimensions(curPos, curLayer) {
            var path;
            if (offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiXYUsed) === 1) {
                var bridgeXDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BRIDGEXDIMENSION));
                path = config.OBJECT_TYPES.RIG + '.' + config.VALUE_TYPE.BRIDGEXDIMENSION;
                addWarningBehaviourToDimension(bridgeXDim, config.WARNING_ID.X_DIMENSION_EXCEEDED, path);
                dimensions.push(bridgeXDim);

                var bridgeYDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BRIDGEYDIMENSION))
                path = config.OBJECT_TYPES.RIG + '.' + config.VALUE_TYPE.BRIDGEYDIMENSION;
                addWarningBehaviourToDimension(bridgeYDim, config.WARNING_ID.Y_DIMENSION_EXCEEDED, path);
                dimensions.push(bridgeYDim);
            }
        }
    }

    function generateVehicleOverviewViewDimensions() {
        if (curElevation === config.ELEVATION.SIDE) {
            generateVehicleOverviewViewDimensionsSideView();
        } else {
            generateVehicleOverviewViewDimensionsTopView();
        }
    }

    function generateVehicleOverviewViewDimensionsTopView() {
        
        generateBottomVehicleOverviewViewDimensions();

        generateTopVehicleOverviewViewDimensions();

        generateLeftVehicleOverviewViewDimensionsTopView();

        generateRightVehicleOverviewViewDimensionsTopView();
    }

    function generateLeftVehicleOverviewViewDimensionsTopView() {
        curPos = POS.LEFT;
        curLayer = LAYER.VEHICLE_CAB_WIDTH;

        showVehicleCabWidthDimension(curPos, curLayer);

        curLayer = LAYER.COMBINATION_OVERALL_WIDTH;

        showCombinationOverallWidthDimension(curPos, curLayer);
    }

    function generateRightVehicleOverviewViewDimensionsTopView() {
        curPos = POS.RIGHT;

        curLayer = LAYER.VEHICLE_REAR_WIDTH;
        showVehicleRearWidthDimension(curPos, curLayer);

        curLayer = LAYER.BODY_INTERNAL;

        var tempBody = curVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            showCompartmentDepthLeftDimension(curPos, curLayer);
            showFloorWidthDimension(curPos, curLayer);
            showCompartmentDepthRightDimension(curPos, curLayer);
        } else {
            showSidewallThicknessLeftDimension(curPos, curLayer);
            showBodyWidthInternalDimension(curPos, curLayer);
            showSidewallThicknessRightDimension(curPos, curLayer);
        }

        

        curLayer = LAYER.BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer);
    }

    function generateBottomVehicleOverviewViewDimensions() {
        bottomDimensionLayerNum = 0;

        curPos = POS.BOTTOM;
        curLayer = LAYER.AC_CA_FIFTH_WHEEL;
        // AC, CA & Fifth Wheel
        showAxle1ToBackOfCabDimension(curPos, curLayer);
        showCabToRearAxlesDimension(curPos, curLayer);
        showAfterFrameDimension(curPos, curLayer);
        showRearProtrusionVehicleOnlyDimension(curPos, curLayer);
        showBodyOverhangDimension(curPos, curLayer);
        //showFifthWheelOffsetDimension(curPos, curLayer);
        //showHitchOffsetDimension(curPos, curLayer);
        if (needToIncrementLayerAfterPop === true) {
            //layerNumbers.incrementLayerNum(curPos);
            layerNumbers.popCurrentBottomLayer();
            needToIncrementLayerAfterPop = false;
        }

        //Axles & wheelbase
        curLayer = LAYER.AXLES_AND_WHEELBASE;
        showBullbarProtrusionDimension(curPos, curLayer);
        showVehicleFrontOverhangDimension(curPos, curLayer);
        var path;
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle1FrontToAxle2Front()) !== 0) {
            var axle1FrontToAxle2FrontDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT));

            path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT;
            addWarningBehaviourToDimension(axle1FrontToAxle2FrontDim, config.WARNING_ID.VEHICLE_AXLE_1_FRONT_TO_AXLE_2_FRONT, path);
            if (offerManager.isAttributeGroupVisible(path)) {
                axle1FrontToAxle2FrontDim.setDrawingGroupName(path);
                axle1FrontToAxle2FrontDim.setShowAsHyperLink(true);
            }
            dimensions.push(axle1FrontToAxle2FrontDim);
        }
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle2FrontToAxle1Rear()) !== 0) {
            var axle2FrontToAxle1RearDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR));

            ////if (vehicle.getAxle2FrontToAxle1Rear() > offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.VehicleWheelbaseTheoreticalAdminMax) + vehicle.getWheelbaseTheoreticalEndToAxle4Rear() - vehicle.getAxle1RearToAxle2Rear() - vehicle.getAxle2RearToAxle3Rear() - vehicle.getAxle3RearToAxle4Rear()) {
            path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR;
            addWarningBehaviourToDimension(axle2FrontToAxle1RearDim, config.WARNING_ID.VEHICLE_REARMOST_AXLE_FRONT_TO_AXLE_1_REAR, path);
            //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL) !== null) {
            //    axle2FrontToAxle1RearDim.setDrawingGroupName(path);
            //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL).getIsNew() === true) {
            //        axle2FrontToAxle1RearDim.setPulse(true);
            //    }
            //    axle2FrontToAxle1RearDim.setShowAsWarning(true);
            //}

            //if (offerManager.isAttributeGroupVisible(path)) {
            //    axle2FrontToAxle1RearDim.setDrawingGroupName(path);
            //    axle2FrontToAxle1RearDim.setShowAsHyperLink(true);
            //}

            dimensions.push(axle2FrontToAxle1RearDim);
        }
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle1RearToAxle2Rear()) !== 0) {
            var axle1RearToAxle2RearDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE1REARTOAXLE2REAR));

            path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1REARTOAXLE2REAR;
            addWarningBehaviourToDimension(axle1RearToAxle2RearDim, config.WARNING_ID.VEHICLE_AXLE_1_REAR_TO_AXLE_2_REAR, path);
            if (offerManager.isAttributeGroupVisible(path)) {
                axle1RearToAxle2RearDim.setDrawingGroupName(path);
                axle1RearToAxle2RearDim.setShowAsHyperLink(true);
            }

            dimensions.push(axle1RearToAxle2RearDim);
        }
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle2RearToAxle3Rear()) !== 0) {
            var axle2RearToAxle3RearDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE2REARTOAXLE3REAR));

            path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE2REARTOAXLE3REAR;
            addWarningBehaviourToDimension(axle2RearToAxle3RearDim, config.WARNING_ID.VEHICLE_AXLE_2_REAR_TO_AXLE_3_REAR, path);
            if (offerManager.isAttributeGroupVisible(path)) {
                axle2RearToAxle3RearDim.setDrawingGroupName(path);
                axle2RearToAxle3RearDim.setShowAsHyperLink(true);
            }

            dimensions.push(axle2RearToAxle3RearDim);
        }
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle3RearToAxle4Rear()) !== 0) {
            var axle3RearToAxle4RearDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE3REARTOAXLE4REAR));

            path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE3REARTOAXLE4REAR;
            addWarningBehaviourToDimension(axle3RearToAxle4RearDim, config.WARNING_ID.VEHICLE_AXLE_3_REAR_TO_AXLE_4_REAR, path);
            if (offerManager.isAttributeGroupVisible(path)) {
                axle3RearToAxle4RearDim.setDrawingGroupName(path);
                axle3RearToAxle4RearDim.setShowAsHyperLink(true);
            }

            dimensions.push(axle3RearToAxle4RearDim);
        }


        var comVehAxle1FrontToWheelbaseLegislationStart = offerManager.getRigOps().getAppropriateAxle1FrontToAxle2FrontAddition();//offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart);
        var comVehWheelbaseLegislation = offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehWheelbaseLegislation);
        var comVehRearOverhangLegislation = offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhangLegislation);


        var rearOverhangDimensionConfig = getDimensionConfig(config.VALUE_TYPE.REAROVERHANG);
        var rearOverhangLegislationDimensionConfig = getDimensionConfig(config.VALUE_TYPE.REAROVERHANGLEGISLATION);
        var rearOverhangTotalDimensionConfig = getDimensionConfig(config.VALUE_TYPE.REAROVERHANGTOTAL);

        var showAsRearOverhangeDimensionAsWarning = true;

        // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED) !== null) {
        //     showAsRearOverhangeDimensionAsWarning = true;
        // }

        var shownOnROLegislation = false, shownOnROTotal = false, shownOnRO = false;
        if (displayType === config.DISPLAY_TYPE.DETAILED) {

            //if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearOverhangTotalInclAccessories()) !== offerManager.getCurMeasurementSysRoundedLength(comVehRearOverhangLegislation)) {
            rearOverhangLegislationDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            // rearOverhangDimensionConfig.addCustomDisplayTextFunction(undefined);
            rearOverhangTotalDimensionConfig.addCustomDisplayTextFunction(undefined);
            shownOnROLegislation = true;
            //}else if (!(vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getCompleteRearOverhang() > 0)) {
            //    if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getOverallLengthInclAccessories() - vehicle.getStartOfVehicle() - vehicle.getOverallLengthExclAccessories() + vehicle.getRearOverhangTotal()) !== offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearOverhang())) {
            //        rearOverhangTotalDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //        rearOverhangLegislationDimensionConfig.addCustomDisplayTextFunction(undefined);
            //        rearOverhangDimensionConfig.addCustomDisplayTextFunction(undefined);
            //        shownOnROTotal = true;
            //    } 
            //}
            //if (shownOnROLegislation === false && shownOnROTotal === false && displayType === config.DISPLAY_TYPE.DETAILED) {
            //    rearOverhangDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //    shownOnRO = true;
            //}
            //if (comVehRearOverhangLegislation !== curVehicle.getRearOverhangTotal()) {
            //    if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearOverhangTotalInclAccessories()) !== offerManager.getCurMeasurementSysRoundedLength(comVehRearOverhangLegislation)) {
            //        rearOverhangLegislationDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //    } else {
            //        rearOverhangDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //    }
            //} else if (curVehicle.getRearOverhangTotal() !== curVehicle.getRearOverhang()) {
            //    if (!(vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getCompleteRearOverhang() > 0)) {
            //        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getOverallLengthInclAccessories() - vehicle.getStartOfVehicle() - vehicle.getOverallLengthExclAccessories() + vehicle.getRearOverhangTotal()) !== offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearOverhang())) {
            //            rearOverhangTotalDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //        } else {
            //            rearOverhangDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //        }
            //    } else {
            //        rearOverhangDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //    }

            //} else {
            //    rearOverhangDimensionConfig.addCustomDisplayTextFunction(getRearOverhangLegislationPercentageDisplayText);
            //}
        }



        var rearOverhangDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), rearOverhangDimensionConfig);
        path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANG;
        addWarningBehaviourToDimension(rearOverhangDim, config.WARNING_ID.REAR_OVERHANG_EXCEEDED, path);
        //if (showAsRearOverhangeDimensionAsWarning === true && shownOnRO === true ) {
        //    rearOverhangDim.setDrawingGroupName(path);
        //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED).getIsNew() === true) {
        //        rearOverhangDim.setPulse(true);
        //    }
        //    rearOverhangDim.setShowAsWarning(true);
        //}

        if (offerManager.isAttributeGroupVisible(path)) {
            rearOverhangDim.setDrawingGroupName(path);
            rearOverhangDim.setShowAsHyperLink(true);
        }

        dimensions.push(rearOverhangDim);

        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            if (!(vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getCompleteRearOverhang() > 0)) {
                if (offerManager.getCurMeasurementSysRoundedLength(Math.max(vehicle.getRearOverhangTotalInclAccessoriesAndPayload() - vehicle.getRearOverhang(), vehicle.getRearProtrusion())) !== 0) {
                    var rearProtrusionDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REARPROTRUSION), config.DIMENSION_STYLE.JUMP_RIGHT);

                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARPROTRUSION;
                    addWarningBehaviourToDimension(rearProtrusionDim, config.WARNING_ID.VEHICLE_REAR_PROTRUSION, path);
                    if (offerManager.isAttributeGroupVisible(path)) {
                        rearProtrusionDim.setDrawingGroupName(path);
                        rearProtrusionDim.setShowAsHyperLink(true);
                    }

                    dimensions.push(rearProtrusionDim);
                }
            }

        }

        //Wheelbase Specified
        curLayer = LAYER.WHEELBASE_SPECIFIED;
        showTotalFrontOverhangDimension(curPos, curLayer);
        //Axle 1 Front To Wheelbase Legislation Start 
        showAxle1FrontToWheelbaseLegislationStartDimension(curPos, curLayer, comVehAxle1FrontToWheelbaseLegislationStart);
        //Wheelbase Specified
        showWheelbaseSpecifiedDimension(curPos, curLayer, comVehWheelbaseLegislation);
        //RearOverhang legislation
        if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getVehicleWheelbaseSpecified()) === offerManager.getCurMeasurementSysRoundedLength(comVehWheelbaseLegislation)) {
            showVehicleRearOverhangLegislationDimension(curPos, curLayer, rearOverhangLegislationDimensionConfig, showAsRearOverhangeDimensionAsWarning, shownOnROLegislation);
        }

        // Legislation Wheelbase
        curLayer = LAYER.LEGISLATION_WHEELBASE;
        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            //Legislation Wheelbase


            var legislationWheelbaseLayerIncremented = false;
            //Axle 1 Front To Wheelbase Legislation Start 
            if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getVehicleWheelbaseSpecified()) !== offerManager.getCurMeasurementSysRoundedLength(comVehWheelbaseLegislation)) {
                showAxle1FrontToWheelbaseLegislationStartDimension(curPos, curLayer, comVehAxle1FrontToWheelbaseLegislationStart);
            }
            //Wheelbase legislation
            //if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle1FrontToWheelbaseTheoreticalStart()) !== offerManager.getCurMeasurementSysRoundedLength(comVehAxle1FrontToWheelbaseLegislationStart) ||
            //        offerManager.getCurMeasurementSysRoundedLength(vehicle.getWheelbaseTheoretical()) !== offerManager.getCurMeasurementSysRoundedLength(comVehWheelbaseLegislation)) {
            if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getVehicleWheelbaseSpecified()) !== offerManager.getCurMeasurementSysRoundedLength(comVehWheelbaseLegislation)) {
                dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.WHEELBASELEGISLATION)));
            }
            //}
            //Rear Overhang Legislation
            //if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearOverhangTotalInclAccessories()) !== offerManager.getCurMeasurementSysRoundedLength(comVehRearOverhangLegislation)) {
            if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getVehicleWheelbaseSpecified()) !== offerManager.getCurMeasurementSysRoundedLength(comVehWheelbaseLegislation)) {
                showVehicleRearOverhangLegislationDimension(curPos, curLayer, rearOverhangLegislationDimensionConfig, showAsRearOverhangeDimensionAsWarning, shownOnROLegislation);
            }
            //}

            //Theoretical Wheelbase
            curLayer = LAYER.THEORETICAL_WHEELBASE;

            showVehicleAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer);

            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getWheelbaseTheoretical()) !== offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle2FrontToAxle1Rear())) {
                showVehicleWheelbaseTheoreticalDimension(curPos, curLayer);
            }


            showVehicleWheelbaseTheoreticalToAxle4RearDimension(curPos, curLayer);

            if (!(vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getCompleteRearOverhang() > 0)) {
                if ((offerManager.getCurMeasurementSysRoundedLength(vehicle.getOverallLengthInclAccessories() - vehicle.getStartOfVehicle() - vehicle.getOverallLengthExclAccessories() + vehicle.getRearOverhangTotal()) !== offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearOverhang())) &&
                        (offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearOverhangTotalInclAccessoriesAndPayload()) !== offerManager.getCurMeasurementSysRoundedLength(comVehRearOverhangLegislation))) {

                    var rearOverhangTotalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), rearOverhangTotalDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);
                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANGTOTAL;
                    addWarningBehaviourToDimension(rearOverhangTotalDim, config.WARNING_ID.VEHICLE_REAR_OVERHANG_TOTAL, path);
                    //if (showAsRearOverhangeDimensionAsWarning === true && shownOnROTotal === true) {
                    //    var 
                    //    rearOverhangTotalDim.setDrawingGroupName(path);
                    //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED).getIsNew() === true) {
                    //        rearOverhangTotalDim.setPulse(true);
                    //    }
                    //    rearOverhangTotalDim.setShowAsWarning(true);
                    //}

                    dimensions.push(rearOverhangTotalDim);
                }
            }
        }

        
    }

    function generateTopVehicleOverviewViewDimensions() {
        curPos = POS.TOP;


        

        var path;
        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            //payload to wheelbase
            //topDimensionLayerNum++;
            //start = dimensionXOffset + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToWheelbaseTheoreticalStart();
            //length = offerManager.getValue(config.VALUE_TYPE.OVERALLHORIZONTALCOG);
            ////if (isTrialUser) {
            ////    displayLength = config.trailUserObfuscationText;
            ////} else {
            //displayLength = offerManager.getUIFormattedDimensionValue(length);
            ////}
            //var ohCOGDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, topDimensionLayerNum, config.VALUE_TYPE.OVERALLHORIZONTALCOG, start, length, displayLength);
            //if (isTrialUser) {
            //    ohCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.OVERALLHORIZONTALCOG));
            //}
            //dimensions.push(ohCOGDim);

            curLayer = LAYER.REAR_MOST_PROTRUDING_ITEM_BEHIND_CAB;
            //rear most protruding item behind cab
            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearMostProtrudingItemBehindCabToRearAxles()) !== 0 &&
                    offerManager.getCurMeasurementSysRoundedLength(vehicle.getRearMostProtrudingItemBehindCabToRearAxles()) !== offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getCabToAxles()) &&
                    offerManager.getCurMeasurementSysRoundedLength(vehicle.getBodyLengthInternal()) === 0 && vehicle.getAccessoryHolder().getAccessories().length === 0) {

                var rearmostProtrudingItemBehindCabToRearAxlesDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES));

                path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REARMOSTPROTRUDINGITEMBEHINDCABTOREARAXLES;
                if (offerManager.isAttributeGroupVisible(path)) {
                    rearmostProtrudingItemBehindCabToRearAxlesDim.setDrawingGroupName(path);
                    rearmostProtrudingItemBehindCabToRearAxlesDim.setShowAsHyperLink(true);
                }

                dimensions.push(rearmostProtrudingItemBehindCabToRearAxlesDim);
            }
            ////cab To Axles(cab to bogie/rear axle)
            //curLayer = LAYER.CAB_TO_REAR_AXLE;
            //if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getCabToAxles()) !== 0 && vehicle.getBody() === null && vehicle.getCompleteOverallLength() === 0 && vehicle.getBodyLengthInternal() === 0 && vehicle.getAccessoryHolder().getAccessories().length === 0) {
            //    dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABTOAXLES)));
            //}

            //cab to chassis end
            curLayer = LAYER.CAB_TO_CHASSIS_END;

            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getBumperToBackOfCab()) !== 0 && vehicle.getBody() === null && vehicle.getCompleteOverallLength() === 0 && vehicle.getBodyLengthInternal() === 0 && vehicle.getAccessoryHolder().getAccessories().length === 0) {

                setupBBCDim();
            }
            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getCabToChassisEnd()) !== 0 && vehicle.getBody() === null && vehicle.getCompleteOverallLength() === 0 && vehicle.getBodyLengthInternal() === 0 && vehicle.getAccessoryHolder().getAccessories().length === 0) {
                dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABTOCHASSISEND)));
            }

            if (vehicle.getBody() !== null) {
                // do nothing?
            } else if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                layerNumbers.incrementLayerNum(curPos);
            }

            //payload from headboard inside
            //curLayer = LAYER.PAYLOAD_FROM_HEADBOARD_INSIDE;
            curLayer = LAYER.AXLE_1_FRONT_TO_START_OF_BODY;
            if (curVehicleBody !== null && curVehicle.getAccessoryHolder().getFifthWheel() === null) {
                showAxle1FrontToStartOfBodyDimension(curPos, curLayer);
                showBodyStartToWheelbaseSpecifiedEndDimension(curPos, curLayer);
            }
            
            //if (vehicle.getBody() !== null && vehicle.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //    showVehiclePayloadCOGFromHeadboardInsideDimension(curPos, curLayer);
            //    showVehiclePayloadCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.DIMENSION_STYLE.JUMP_RIGHT);
            //} else if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //    showVehiclePayloadCOGFromBumperToStartOfBodyInternal(curPos, curLayer);
            //    showVehiclePayloadCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.DIMENSION_STYLE.JUMP_RIGHT);
            //}
            //function getPayloadTotalDimension() {
            //    //#unfinished, hardcoding assumed single uniform payload object on body payloadObjects, may need to be revisited as multipoint load added
            //    start = start + length;
            //    length = 500;
            //    var displayMass;
            //    if (isTrialUser) {
            //        displayMass = offerManager.getTrialUserObfuscationTextWithCurrentMassUnit();
            //    } else {
            //        displayMass = offerManager.getUIFormattedMassValue(vehicle.getPayloadHolder().getTotalMass());
            //    }
            //    var payloadMassDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), config.VALUE_TYPE.PAYLOADTOTAL, start, length, displayMass, config.DIMENSION_STYLE.COG);

            //    if (isTrialUser) {
            //        payloadMassDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.PAYLOADTOTAL));
            //    }
            //    dimensions.push(payloadMassDim);
            //}
        }

        //body internal
        curLayer = LAYER.BODY_INTERNAL;
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            if (displayType === config.DISPLAY_TYPE.DETAILED) {
                //if (hasBody === true && vehicle.getBody().getHeadboardThickness() > 0) {
                //    var bodyHeadboardThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYHEADBOARDTHICKNESS), config.DIMENSION_STYLE.JUMP_LEFT);

                //    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEADBOARDTHICKNESS;
                //    if (offerManager.isAttributeGroupVisible(path)) {
                //        bodyHeadboardThicknessDim.setDrawingGroupName(path);
                //        bodyHeadboardThicknessDim.setShowAsHyperLink(true);
                //    }

                //    dimensions.push(bodyHeadboardThicknessDim);
                //}
                showVehicleHeadboardThicknessDimension(curPos, curLayer);
            }
            //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //    if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getBodyLengthInternal()) !== 0) {

            //        var bodyLengthInternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHINTERNAL));

            //        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL;
            //        if(offerManager.isAttributeGroupVisible(path)) {
            //            bodyLengthInternalDim.setDrawingGroupName(path);
            //            bodyLengthInternalDim.setShowAsHyperLink(true);
            //        }

            //        dimensions.push(bodyLengthInternalDim);
            //    }
            //} else if (hasBody === true) {
            //    if (vehicle.getBody().getLength() > 0 && offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getActualInternalLength()) !== offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getActualLength())) {
            //        var bodyLengthInternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHINTERNAL));

            //        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL;
            //        if(offerManager.isAttributeGroupVisible(path)) {
            //            bodyLengthInternalDim.setDrawingGroupName(path);
            //            bodyLengthInternalDim.setShowAsHyperLink(true);
            //        }

            //        dimensions.push(bodyLengthInternalDim);
            //    }
            //}
            showVehicleBodyLengthInternalDimension(curPos, curLayer);
            if (displayType === config.DISPLAY_TYPE.DETAILED) {
                //if (hasBody === true && vehicle.getBody().getTailboardThickness() > 0) {

                //    var bodyTailboardThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYTAILBOARDTHICKNESS), config.DIMENSION_STYLE.JUMP_RIGHT);

                //    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYTAILBOARDTHICKNESS;
                //    if(offerManager.isAttributeGroupVisible(path)) {
                //        bodyTailboardThicknessDim.setDrawingGroupName(path);
                //        bodyTailboardThicknessDim.setShowAsHyperLink(true);
                //    }

                //    dimensions.push(bodyTailboardThicknessDim);
                //}
                showVehicleTailboardThicknessDimension(curPos, curLayer);
            }
        }
        //body external
        curLayer = LAYER.BODY_EXTERNAL;
        if (displayType === config.DISPLAY_TYPE.DETAILED) {

            //if (hasBody === true && vehicle.getBumperToBackOfCab() > 0) {

            //    var bumperToBackOfCabDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BUMPERTOBACKOFCAB));

            //    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BUMPERTOBACKOFCAB;
            //    if(offerManager.isAttributeGroupVisible(path)) {
            //        bumperToBackOfCabDim.setDrawingGroupName(path);
            //        bumperToBackOfCabDim.setShowAsHyperLink(true);
            //    }

            //    dimensions.push(bumperToBackOfCabDim);
            //}
            showBumperToBackOfCabDimension(curPos, curLayer);

            //if (hasBody === true && vehicle.getBody().getHorizontalGap() !== 0) {
            //    var cabGapRecommendedMinDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABGAPRECOMMENDEDMIN), config.DIMENSION_STYLE.POP_LAYER);

            //    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYCABGAP;
            //    if (offerManager.isAttributeGroupVisible(path)) {
            //        cabGapRecommendedMinDim.setDrawingGroupName(path);
            //        cabGapRecommendedMinDim.setShowAsHyperLink(true);
            //    }

            //    dimensions.push(cabGapRecommendedMinDim);
            //    needToIncrementLayerAfterPop = true;
            //}
            if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
                showCabGapToBodyDimension(curPos, curLayer);
                showHookliftGapToBodyFromHookOffsetDimension(curPos, curLayer);
            }
            
        }
        //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getBodyLengthExternalInclRearDoors()) !== 0) {
        //        var bodyLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHEXTERNALINCLUDINGREARDOORS));

        //        dimensions.push(bodyLengthDim);
        //    }
        //} else if (hasBody === true) {
        //    if (vehicle.getBody().getLength() > 0) {
        //        var bodyLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHEXTERNALINCLUDINGREARDOORS));

        //        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTH;
        //        if (offerManager.isAttributeGroupVisible(path)) {
        //            bodyLengthDim.setDrawingGroupName(path);
        //            bodyLengthDim.setShowAsHyperLink(true);
        //        }

        //        dimensions.push(bodyLengthDim);
        //        //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHEXTERNALINCLUDINGREARDOORS)));
        //    }
        //}
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            showVehicleBodyLengthExternalDimension(curPos, curLayer);
        }
        
        if (displayType === config.DISPLAY_TYPE.DETAILED && curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            if (hasBody === true && vehicle.getBody().getType() === config.BODY_TYPES.BEAVERTAIL) {
                if (vehicle.getBody().getRampLength() > 0) {

                    var rampLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.RAMPLENGTH));

                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.RAMPLENGTH;
                    if (offerManager.isAttributeGroupVisible(path)) {
                        rampLengthDim.setDrawingGroupName(path);
                        rampLengthDim.setShowAsHyperLink(true);
                    }

                    dimensions.push(rampLengthDim);
                }
                if (vehicle.getBody().getRampPlatesProtrusion() > 0) {

                    var rampplatesProtrusionDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.RAMPPLATESPROTRUSION), config.DIMENSION_STYLE.JUMP_RIGHT);

                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.RAMPPLATESPROTRUSION;
                    if (offerManager.isAttributeGroupVisible(path)) {
                        rampplatesProtrusionDim.setDrawingGroupName(path);
                        rampplatesProtrusionDim.setShowAsHyperLink(true);
                    }

                    dimensions.push(rampplatesProtrusionDim);
                }

            } else {
                showBackOfVehicleBodyToRearOfVehicleDimension(curPos, curLayer);
            } /*if (hasBody === true && offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getHorizontalGap()) + vehicle.getBody().getActualLength() < vehicle.getOverallLengthInclAccessories() - vehicle.getBumperToBackOfCab()) {
                dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REAROFBODYTOREAROFVEHICLE), config.DIMENSION_STYLE.JUMP_RIGHT));
            }*/
        }


        if (needToIncrementLayerAfterPop === true) {
            layerNumbers.incrementLayerNum(curPos);
            needToIncrementLayerAfterPop = false;
        }

        ////Crane
        //curLayer = LAYER.CRANE;
        //var crane = vehicle.getAccessoryHolder().getCrane();
        //if (displayType === config.DISPLAY_TYPE.DETAILED && crane !== null) {
        //    if (crane.getHorizontalGap() > 0) {
        //        var craneCabGapDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CRANECABGAP), config.DIMENSION_STYLE.JUMP_LEFT);

        //        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANECABGAP;
        //        if (offerManager.isAttributeGroupVisible(path)) {
        //            craneCabGapDim.setDrawingGroupName(path);
        //            craneCabGapDim.setShowAsHyperLink(true);
        //        }

        //        dimensions.push(craneCabGapDim);
        //    }

        //    var craneLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CRANELENGTH));

        //    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANELENGTH;
        //    if (offerManager.isAttributeGroupVisible(path)) {
        //        craneLengthDim.setDrawingGroupName(path);
        //        craneLengthDim.setShowAsHyperLink(true);
        //    }

        //    dimensions.push(craneLengthDim);
        //}
        
        //Overall Length
        curLayer = LAYER.OVERALL_LENGTH;
        if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && displayType === config.DISPLAY_TYPE.SIMPLIFIED) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.COMPLETEOVERALLLENGTH)));
        } else {
            showOverallVehicleLengthDimension(curPos, curLayer);
        }
    }

    function generateVehicleOverviewViewDimensionsSideView() {
        //#region bottom dimension layers

        generateBottomVehicleOverviewViewDimensions();
        
        //#endregion bottom dimension layers

        //#region top dimension layer
        generateTopVehicleOverviewViewDimensions();
        
        //#endregion top dimension layer


        //#region innner dimensions
        var offset;
        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getGroundClearanceFront()) !== 0) {
                offset = dimensionXOffset + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToAxle2Front() + innerDimensionOffset;
                dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.GROUNDCLEARANCEFRONT), config.DIMENSION_STYLE.JUMP_RIGHT, offset));
            }

            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getGroundClearanceRear()) !== 0) {
                offset = dimensionXOffset + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToAxle2Front() + vehicle.getAxle2FrontToAxle1Rear() - innerDimensionOffset;
                dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.GROUNDCLEARANCEREAR), config.DIMENSION_STYLE.JUMP_LEFT, offset));
            }
        }
        //#endregion inner dimensions


        //#region left dimension layers
        //**************************************************************************************************************
        // starting dimension layer at 1 instead of 0 as this provides a blank dimension layer as a buffer to prevent or 
        // minimise overlap of front protrusions(minus values in the x axis) over left dimensions
        //**************************************************************************************************************
        
        //if (displayType === config.DISPLAY_TYPE.DETAILED) {
        //    length = offerManager.getValue(config.VALUE_TYPE.OVERALLVERTICALCOG);//#unfinished, this is not displaying in many instances due to data error where vertical COG is 0
        //    if (length > 0) {
        //        leftDimensionLayerNum++;
        //        start = 0;
        //        if (isTrialUser) {
        //            displayLength = config.trailUserObfuscationText;
        //        } else {
        //            displayLength = offerManager.getUIFormattedDimensionValue(length);
        //        }

        //        var ovCOGDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_LEFT, leftDimensionLayerNum, config.VALUE_TYPE.OVERALLVERTICALCOG, start, length, displayLength);
        //        if (isTrialUser) {
        //            ovCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.OVERALLVERTICALCOG));
        //        }

        //        dimensions.push(ovCOGDim);
        //    }

        //}
        curPos = POS.LEFT;
        
        //Cab Height
        curLayer = LAYER.CAB_HEIGHT;
        showCabHeightDimension(curPos, curLayer);
        
        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            //Vertical Exhaust Height
            curLayer = LAYER.VERTICAL_EXHAUST_HEIGHT;
            showVerticalExhaustHeightDimension(curPos, curLayer);
        }

        //#region left dimension layers

        //#region right dimension layers
        //**************************************************************************************************************
        // starting dimension layer at 1 instead of 0 as this provides a blank dimension layer as a buffer to prevent or 
        // minimise overlap of rear protrusions(greater than drawing area width values in the x axis) over right dimensions
        //**************************************************************************************************************
        
        curPos = POS.RIGHT;
        
        // body internal
        curLayer = LAYER.BODY_INTERNAL;
        var tempBody;
        if (displayType === config.DISPLAY_TYPE.DETAILED) {

            if ((vehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES && vehicle.getFifthWheelHeightFromGround() > 0) || vehicle.getAccessoryHolder().getFifthWheel() !== null) {
                dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.FIFTHWHEELHEIGHTFROMGROUND)));
            }


            if (hasBody === true) {
                tempBody = vehicle.getBody();
                if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                    showChassisHeightRearDimension(curPos, curLayer);

                    showCombinedBodySubframeAndSubstructureDimension(curPos, curLayer);
                    needToIncrementLayerAfterPop = true;

                    showBodyHeightDimension(curPos, curLayer);
                } else if (tempBody.getSubstructureHeight() > 0) {
                    showBodyStructureHeightDimension(curPos, curLayer);
                }
            } 
        }

        var bodyFloorHeightDim, path;
        if (displayType === config.DISPLAY_TYPE.SIMPLIFIED) {
            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getFloorHeight()) !== 0) {

                bodyFloorHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYFLOORHEIGHT));

                path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYFLOORHEIGHT;
                if(offerManager.isAttributeGroupVisible(path)) {
                    bodyFloorHeightDim.setDrawingGroupName(path);
                    bodyFloorHeightDim.setShowAsHyperLink(true);
                }

                dimensions.push(bodyFloorHeightDim);
            }
        }

        if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            if (vehicle.getBodyHeightInternal() !== 0 && vehicle.getBodyHeightExternal() !== vehicle.getBodyHeightInternal()) {
                showBodyHeightDimension(curPos, curLayer);
            }
        } else if (hasBody === true) {
            tempBody = vehicle.getBody();
            if (tempBody.getActualInternalHeight() !== 0 && (tempBody.getType() !== config.BODY_TYPES.SERVICE /*&& tempBody.getType() !== config.BODY_TYPES.COMPACTOR && tempBody.getType() !== config.BODY_TYPES.BEAVERTAIL && tempBody.getType() !== config.BODY_TYPES.OTHER*/)) {
                showBodyHeightDimension(curPos, curLayer);
            }
        }

        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            if (hasBody === true && vehicle.getBody().getRoofThickness() > 0) {
                showBodyRoofThicknessDimension(curPos, curLayer);
            }
        }

        if (needToIncrementLayerAfterPop === true) {
            layerNumbers.incrementLayerNum(curPos);
            needToIncrementLayerAfterPop = false;
        }

        //Subframe and max body external
        curLayer = LAYER.SUBFRAME_AND_MAX_BODY_EXTERNAL;

        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            //if complete vehicle use body floor height else chassis height rear
            if (vehicle.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                tempBody = vehicle.getBody()
                if (vehicle.getChassisHeightRear() > 0 && (tempBody === null || (tempBody !== null && (tempBody.getType() !== config.BODY_TYPES.SERVICE /*&& tempBody.getType() !== config.BODY_TYPES.COMPACTOR && tempBody.getType() !== config.BODY_TYPES.BEAVERTAIL && tempBody.getType() !== config.BODY_TYPES.OTHER*/)))) {

                    showChassisHeightRearDimension(curPos, curLayer);
                }
            } else {
                if (vehicle.getFloorHeight() > 0) {

                    bodyFloorHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYFLOORHEIGHT));

                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYFLOORHEIGHT;
                    if(offerManager.isAttributeGroupVisible(path)) {
                        bodyFloorHeightDim.setDrawingGroupName(path);
                        bodyFloorHeightDim.setShowAsHyperLink(true);
                    }

                    dimensions.push(bodyFloorHeightDim);
                }
            }
            tempBody = vehicle.getBody();
            if (hasBody === true && tempBody.getSubframeHeight() > 0 && tempBody.getType() !== config.BODY_TYPES.SERVICE /*&& tempBody.getType() !== config.BODY_TYPES.COMPACTOR && tempBody.getType() !== config.BODY_TYPES.BEAVERTAIL && tempBody.getType() !== config.BODY_TYPES.OTHER*/) {

                if (vehicle.getBody().getAboveBelowChassis() >= 0) {
                    var bodySubframeHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYSUBFRAMEHEIGHT), config.DIMENSION_STYLE.POP_LAYER);

                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYSUBFRAMEHEIGHT;

                    if (offerManager.isAttributeGroupVisible(path)) {
                        bodySubframeHeightDim.setDrawingGroupName(path);
                        bodySubframeHeightDim.setShowAsHyperLink(true);
                    }

                    dimensions.push(bodySubframeHeightDim);

                    needToIncrementLayerAfterPop = true;
                }
            }

        }

        if (displayType === config.DISPLAY_TYPE.SIMPLIFIED) {
            if (vehicle.getBodyHeightExternal() !== 0) {

                dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYHEIGHTEXTERNALFROMFLOOR)));
            }
        }

        if (displayType === config.DISPLAY_TYPE.DETAILED) {
            if (hasBody === true && vehicle.getBody().getActualRearDimensionHeight() !== 0) {
                if (vehicle.getBody().getAboveBelowChassis() >= 0) {
                    showBodyHeightExternalDimension(curPos, curLayer);
                }
            }
        }

        if (needToIncrementLayerAfterPop === true) {
            layerNumbers.incrementLayerNum(curPos);
            needToIncrementLayerAfterPop = false;
        }


        //Overall Height At Rear
        curLayer = LAYER.OVERALL_HEIGHT_AT_REAR;
        var frontalAreaHeightCompareVal = Math.max(offerManager.getCurMeasurementSysRoundedLength(vehicle.getCompleteOverallBodyAndEquipmentHeight()),
            offerManager.getCurMeasurementSysRoundedLength(vehicle.getFrontalAreaHeight()));

        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getCabHeight()) < frontalAreaHeightCompareVal) {
            showOverallHeightAtRearDimension(curPos, curLayer);
        }


        //#endregion right dimension layers
    }

    function generateVehicleAndSemiOrInterlinkOverviewDimensions() {
        if (curElevation === config.ELEVATION.SIDE) {
            generateVehicleAndSemiOrInterlinkOverviewDimensionsSideView();
        } else {
            generateVehicleAndSemiOrInterlinkOverviewDimensionsTopView();
        }
    }

    function generateVehicleAndSemiOrInterlinkOverviewDimensionsTopView() {
        
        generateBottomVehicleAndSemiOrInterlinkOverviewDimensions();
        
        generateTopVehicleAndSemiOrInterlinkOverviewDimensions();
        
        generateLeftVehicleAndSemiOrInterlinkOverviewDimensions();
        
        generateRightVehicleAndSemiOrInterlinkOverviewDimensions();
        
    }
    
    function generateLeftVehicleAndSemiOrInterlinkOverviewDimensions() {
        curPos = POS.LEFT;
        curLayer = LAYER.VEHICLE_CAB_WIDTH;

        showVehicleCabWidthDimension(curPos, curLayer);

        curLayer = LAYER.COMBINATION_OVERALL_WIDTH;

        showCombinationOverallWidthDimension(curPos, curLayer);
    }

    function generateRightVehicleAndSemiOrInterlinkOverviewDimensions() {
        curPos = POS.RIGHT;
        curLayer = LAYER.BODY_INTERNAL;

        showSidewallThicknessLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showBodyWidthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showSidewallThicknessRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function generateBottomVehicleAndSemiOrInterlinkOverviewDimensions() {
        curPos = POS.BOTTOM;
        curLayer = LAYER.FIFTH_WHEEL_AND_TRAILER_HITCH;

        //Fifth Wheel & Trailer Hicth
        showAxle1ToBackOfCabDimension(curPos, curLayer);
        //showFifthWheelOffsetDimension(curPos, curLayer);

        //Vehicle Theoretical Wheelbase
        curLayer = LAYER.VEHICLE_THEORETICAL_WHEELBASE;
        showBullbarProtrusionDimension(curPos, curLayer);
        showVehicleFrontOverhangDimension(curPos, curLayer);
        showVehicleAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalToAxle4RearDimension(curPos, curLayer);
        showTrailerRearOverhangLegislationDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        //trailer Wheelbase Theoretical
        curLayer = LAYER.TRAILER_WHEELBASE_THEORETICAL;

        //var trailerFrontOverhangdim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERFRONTOVERHANG, config.CHASSIS_OBJECT_TYPES.TRAILER1), config.DIMENSION_STYLE.JUMP_LEFT);

        //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERFRONTOVERHANG;
        ////if (curTrailer1.getFrontOverhang() > offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.Trailer1FrontOverhangMax)) {
        //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null) {
        //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_FRONT_OVERHANG).getIsNew() === true) {
        //        trailerFrontOverhangdim.setPulse(true);
        //    }
        //    trailerFrontOverhangdim.setDrawingGroupName(path);
        //    trailerFrontOverhangdim.setShowAsWarning(true);
        //}


        //if(offerManager.isAttributeGroupVisible(path)) {
        //    trailerFrontOverhangdim.setDrawingGroupName(path);
        //    trailerFrontOverhangdim.setShowAsHyperLink(true);
        //}

        //dimensions.push(trailerFrontOverhangdim);
        showTotalFrontOverhangDimension(curPos, curLayer);
        showTrailerFrontOverhangDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        //var trailerWheelbaseTheoreticalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL, config.CHASSIS_OBJECT_TYPES.TRAILER1));

        //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL;
        ////if (curTrailer1.getWheelbaseTheoretical() > offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseTheoreticalMax)) {
        //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null) {
        //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL).getIsNew() === true) {

        //        trailerWheelbaseTheoreticalDim.setPulse(true);
        //    }
        //    trailerWheelbaseTheoreticalDim.setShowAsWarning(true);
        //    trailerWheelbaseTheoreticalDim.setDrawingGroupName(path);
        //}


        //if(offerManager.isAttributeGroupVisible(path)) {
        //    trailerWheelbaseTheoreticalDim.setDrawingGroupName(path);
        //    trailerWheelbaseTheoreticalDim.setShowAsHyperLink(true);
        //}

        //dimensions.push(trailerWheelbaseTheoreticalDim);
        showTrailerWheelbaseTheoreticalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        //if (curTrailer1.getWheelbaseTheoreticalEndToRearMostAxle() !== 0) {
        //    dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICALENDTOREARMOSTAXLE, config.CHASSIS_OBJECT_TYPES.TRAILER1)));
        //}
        showTrailerWheelbaseTheoreticalEndToRearMostAxleDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        ////if (offerManager.getRigOps().getTrailer1RearOverhangTotal() > curTrailer1.getRearOverhang()) {
        //    dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERREAROVERHANGTOTAL, config.CHASSIS_OBJECT_TYPES.TRAILER1)));
        ////}
        showTrailerRearOverhangTotalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function generateTopVehicleAndSemiOrInterlinkOverviewDimensions() {
        curPos = POS.TOP;

        //var trailer1Body = curTrailer1.getAccessoryHolder().getBody();
        //if (curTrailer1Body !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //    //Trailer payload from headboard inside
        //    curLayer = LAYER.TRAILER_PAYLOAD_FROM_HEADBOARD_INSIDE;

        //    showTrailerPayloadHorizontalCOGFromHeadboardInsideDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        //    showTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}

        if (curTrailer1Body !== null) {
            //Trailer Body Internal
            curLayer = LAYER.TRAILER_BODY_INTERNAL;
            var trailerBodyInternalLayerIncremented = false;
            //if (trailer1Body.getHeadboardThickness() > 0) {

            //    var bodyHeadboardThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYHEADBOARDTHICKNESS, config.CHASSIS_OBJECT_TYPES.TRAILER1), config.DIMENSION_STYLE.JUMP_LEFT);

            //    var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEADBOARDTHICKNESS;
            //    if(offerManager.isAttributeGroupVisible(path)) {
            //        bodyHeadboardThicknessDim.setDrawingGroupName(path);
            //        bodyHeadboardThicknessDim.setShowAsHyperLink(true);
            //    }

            //    dimensions.push(bodyHeadboardThicknessDim);
            //}

            //if (trailer1Body.getActualInternalLength() > 0 && trailer1Body.getActualInternalLength() !== trailer1Body.getActualLength()) {

            //    var bodyLengthInternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHINTERNAL, config.CHASSIS_OBJECT_TYPES.TRAILER1));

            //    //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL;
            //    //if(offerManager.isAttributeGroupVisible(path)) {
            //    //    bodyLengthInternalDim.setDrawingGroupName(path);
            //    //}

            //    dimensions.push(bodyLengthInternalDim);
            //}

            //if (trailer1Body.getTailboardThickness() > 0) {

            //    var bodyTailbaordThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYTAILBOARDTHICKNESS, config.CHASSIS_OBJECT_TYPES.TRAILER1), config.DIMENSION_STYLE.JUMP_RIGHT);

            //    var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYTAILBOARDTHICKNESS;
            //    if(offerManager.isAttributeGroupVisible(path)) {
            //        bodyTailbaordThicknessDim.setDrawingGroupName(path);
            //        bodyTailbaordThicknessDim.setShowAsHyperLink(true);
            //    }

            //    dimensions.push(bodyTailbaordThicknessDim);
            //}
            showTrailerHeadboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showTrailerBodyLengthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showTrailerTailboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);


        }

        //Trailer Body External
        curLayer = LAYER.TRAILER_BODY_EXTERNAL;

        if (curVehicle.getBumperToBackOfRearMostEquipment() > 0 && curVehicle.getAccessoryHolder().getBody() === null) {
            //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BUMPERTOBACKOFREARMOSTEQUIPMENT)));
            showVehicleBumperToBackOfRearmostEquipmentDimension(curPos, curLayer);
        }



        if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE && curTrailer1Body === null) {

            //var trailerOverallLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILEROVERALLLENGTH, config.CHASSIS_OBJECT_TYPES.TRAILER1));

            //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILEROVERALLLENGTH;
            //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_LENGTH) !== null) {
            //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_LENGTH).getIsNew() === true) {
            //        trailerOverallLengthDim.setPulse(true);
            //    }
            //    trailerOverallLengthDim.setShowAsWarning(true);
            //    trailerOverallLengthDim.setDrawingGroupName(path);
            //}

            //dimensions.push(trailerOverallLengthDim);
            showTrailerOverallLengthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.DIMENSION_TYPE.HORIZONTAL_TOP);
        }
        //if (curTrailer1Body !== null) {
        //    var cabGapToStartOfBodyDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABGAPTOSTARTOFBODY, config.CHASSIS_OBJECT_TYPES.TRAILER1), config.DIMENSION_STYLE.POP_LAYER);

        //    var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYCABGAP;
        //    if (offerManager.isAttributeGroupVisible(path)) {
        //        cabGapToStartOfBodyDim.setDrawingGroupName(path);
        //        cabGapToStartOfBodyDim.setShowAsHyperLink(true);
        //    }

        //    dimensions.push(cabGapToStartOfBodyDim);
        //}
        showTrailerGapToStartOfBodyDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);



        //if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || curTrailer1Body !== null) {
        //    var trailerLengthExternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERLENGTHEXTERNAL, config.CHASSIS_OBJECT_TYPES.TRAILER1));

        //    if (curTrailer1.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //        var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTH;
        //        if (offerManager.isAttributeGroupVisible(path)) {
        //            trailerLengthExternalDim.setDrawingGroupName(path);
        //            trailerLengthExternalDim.setShowAsHyperLink(true);
        //        }
        //    }

        //    dimensions.push(trailerLengthExternalDim);
        //}
        showTrailerBodyLengthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        //if (curTrailer1Body !== null) {
        //    dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BACKOFTRAILERBODYTOREAROFTRAILER, config.CHASSIS_OBJECT_TYPES.TRAILER1), config.DIMENSION_STYLE.JUMP_RIGHT));
        //}
        showTrailerBackOfBodyToRearOfTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        if (needToIncrementLayerAfterPop === true) {
            layerNumbers.incrementLayerNum(curPos);
            needToIncrementLayerAfterPop = false;
        }


        //KINGPIN_TO_REAR_OF_TRAILER
        curLayer = LAYER.KINGPIN_TO_REAR_OF_TRAILER;

        if (curVehicle.getAccessoryHolder().getBody() === null) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABGAPTOTRAILER)/*, config.DIMENSION_STYLE.POP_LAYER*/));
            //needToIncrementLayerAfterPop = true;
        }

        showKingpinToRearOfTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        //Overall Length
        curLayer = LAYER.OVERALL_LENGTH;

        //var combinationOverallLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.COMBINATIONOVERALLLENGTH));

        //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.COMBINATIONOVERALLLENGTH;
        //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH) !== null) {
        //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH).getIsNew() === true) {
        //        combinationOverallLengthDim.setPulse(true);
        //    }
        //    combinationOverallLengthDim.setShowAsWarning(true);
        //    combinationOverallLengthDim.setDrawingGroupName(path);
        //}

        //dimensions.push(combinationOverallLengthDim);
        showCombinationOverallLengthDimension(curPos, curLayer);
    }

    function generateVehicleAndSemiOrInterlinkOverviewDimensionsSideView() { 
        

        
        //#region bottom dimension layers
        generateBottomVehicleAndSemiOrInterlinkOverviewDimensions();
        //#endregion bottom dimension layers

        //#region top dimension layer
        generateTopVehicleAndSemiOrInterlinkOverviewDimensions();
        //#endregion top dimension layer



        //#region innner dimensions
            
        //#endregion inner dimensions


        //#region left dimension layers
        //**************************************************************************************************************
        // starting dimension layer at 1 instead of 0 as this provides a blank dimension layer as a buffer to prevent or 
        // minimise overlap of front protrusions(minus values in the x axis) over left dimensions
        //**************************************************************************************************************
        
        curPos = POS.LEFT;
        //Cab Height
        curLayer = LAYER.CAB_HEIGHT;
        showCabHeightDimension(curPos, curLayer);

        curLayer = LAYER.VERTICAL_EXHAUST_HEIGHT;
        showVerticalExhaustHeightDimension(curPos, curLayer);

        //#region left dimension layers

        //#region right dimension layers
        //**************************************************************************************************************
        // starting dimension layer at 1 instead of 0 as this provides a blank dimension layer as a buffer to prevent or 
        // minimise overlap of rear protrusions(greater than drawing area width values in the x axis) over right dimensions
        //**************************************************************************************************************
        
        curPos = POS.RIGHT;
        //Trailer Body Internal
        curLayer = LAYER.TRAILER_BODY_INTERNAL;
        //if (curTrailer1Body !== null) {
        //    if (curTrailer1Body.getSubstructureHeight() > 0) {
        //        showBodyStructureHeightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //    }

        //    if (curTrailer1Body.getHeight() > 0 && curTrailer1Body.getHeight() === curTrailer1Body.getActualExternalHeight()) {
        //        showBodyHeightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //    }

        //    if (curTrailer1Body.getRoofThickness() > 0) {
        //        showBodyRoofThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //    }
        //}
        showTrailerBodyInternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            
        curLayer = LAYER.TRAILER_CHASSIS_AND_BODY_EXTERNAL;
        //if (curTrailer1.getChassisHeight() > 0) {

        //    //var trailerChassisHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERCHASSISHEIGHT, config.CHASSIS_OBJECT_TYPES.TRAILER1));

        //    //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERCHASSISHEIGHT;
        //    //if(offerManager.isAttributeGroupVisible(path)) {
        //    //    trailerChassisHeightDim.setDrawingGroupName(path);
        //    //    trailerChassisHeightDim.setShowAsHyperLink(true);
        //    //}


        //    //dimensions.push(trailerChassisHeightDim);
        //    showTrailerChassisHeightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}
        //if (curTrailer1Body !== null && curTrailer1Body.getActualExternalHeight() > 0) {
        //    showBodyHeightExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}
        showTrailerChassisAndBodyExternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.OVERALL_HEIGHT_AT_REAR;
        showOverallHeightAtRearDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //#endregion right dimension layers
    }

    function generateVehicleAndDrawbarOverviewDimensions() {
        if (curElevation === config.ELEVATION.SIDE) {
            generateVehicleAndDrawbarOverviewDimensionsSideView();
        } else {
            generateVehicleAndDrawbarOverviewDimensionsTopView();
        }
    }

    function generateVehicleAndDrawbarOverviewDimensionsTopView() {

        generateBottomVehicleAndDrawbarOverviewDimensions();

        generateTopVehicleAndDrawbarOverviewDimensions();

        generateLeftVehicleAndDrawbarOverviewDimensionsTopView();

        generateRightVehicleAndDrawbarOverviewDimensionsTopView()
    }

    function generateLeftVehicleAndDrawbarOverviewDimensionsTopView() {
        curPos = POS.LEFT;
        curLayer = LAYER.VEHICLE_CAB_WIDTH;

        showVehicleCabWidthDimension(curPos, curLayer);

        curLayer = LAYER.COMBINATION_OVERALL_WIDTH;

        showCombinationOverallWidthDimension(curPos, curLayer);

        curLayer = LAYER.BODY_INTERNAL;

        var tempBody = curVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            showCompartmentDepthLeftDimension(curPos, curLayer);
            showFloorWidthDimension(curPos, curLayer);
            showCompartmentDepthRightDimension(curPos, curLayer);
        } else {
            showSidewallThicknessLeftDimension(curPos, curLayer);
            showBodyWidthInternalDimension(curPos, curLayer);
            showSidewallThicknessRightDimension(curPos, curLayer);
        }

        curLayer = LAYER.BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer);
    }

    function generateRightVehicleAndDrawbarOverviewDimensionsTopView() {
        curPos = POS.RIGHT;
        curLayer = LAYER.BODY_INTERNAL;

        showSidewallThicknessLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showBodyWidthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showSidewallThicknessRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function generateBottomVehicleAndDrawbarOverviewDimensions() {
        curPos = POS.BOTTOM;
        curLayer = LAYER.DRAWBAR_PROTRUSION;


        //DrawbarProtrusion
        showTotalFrontOverhangDimension(curPos, curLayer);
        showDrawbarProtrusionDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        ////Hitch
        curLayer = LAYER.HITCH;
        showAxle1ToBackOfCabDimension(curPos, curLayer);
        //showHitchOffsetDimension(curPos, curLayer);
        //if (curVehicleBody !== null) {
        //    showAxle1FrontToStartOfBodyDimension(curPos, curLayer);
        //}
        showDrawbarLengthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //showTrailerAxle1FrontToWheelbaseLegislationStartDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //showTrailerWheelbaseLegislationDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerRearOverhangLegislationDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);


        //Theoretical Wheelbase
        curLayer = LAYER.THEORETICAL_WHEELBASE;
        showBullbarProtrusionDimension(curPos, curLayer);
        showVehicleFrontOverhangDimension(curPos, curLayer);
        showVehicleAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalToAxle4RearDimension(curPos, curLayer);
        showVehicleRearOverhangTotal(curPos, curLayer);

        showTrailerFrontOverhangDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerWheelbaseTheoreticalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerWheelbaseTheoreticalEndToRearMostAxleDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerRearOverhangTotalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);


        curLayer = LAYER.OVERALL_LENGTH;
        showOverallVehicleLengthDimension(curPos, curLayer);
        showTrailerOverallLengthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.DIMENSION_TYPE.HORIZONTAL_BOTTOM);
    }

    function generateTopVehicleAndDrawbarOverviewDimensions() {
        curPos = POS.TOP;
        curLayer = LAYER.PAYLOAD_TO_WHEELBASE;

        //if (curVehicleBody !== null || curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    showVehiclePayloadCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.DIMENSION_STYLE.JUMP_LEFT);
        //}
        //if (curTrailer1Body !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //    showTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}


        curLayer = LAYER.PAYLOAD_FROM_HEADBOARD_INSIDE;
        //if (curVehicleBody !== null && vehicle.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    showVehiclePayloadCOGFromHeadboardInsideDimension(curPos, curLayer);
        //} else if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    showVehiclePayloadCOGFromBumperToStartOfBodyInternal(curPos, curLayer);
        //}

        //if (curTrailer1Body !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //    showTrailerPayloadHorizontalCOGFromHeadboardInsideDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}
        curLayer = LAYER.AXLE_1_FRONT_TO_START_OF_BODY;
        if (curVehicleBody !== null) {
            showAxle1FrontToStartOfBodyDimension(curPos, curLayer);
            showBodyStartToWheelbaseSpecifiedEndDimension(curPos, curLayer);
        }

        curLayer = LAYER.BODY_INTERNAL;

        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            showVehicleHeadboardThicknessDimension(curPos, curLayer);
            showVehicleBodyLengthInternalDimension(curPos, curLayer);
            showVehicleTailboardThicknessDimension(curPos, curLayer);
        }

        if (curTrailer1Body !== null) {
            showTrailerHeadboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showTrailerBodyLengthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showTrailerTailboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        }

        curLayer = LAYER.BODY_EXTERNAL;

        showBumperToBackOfCabDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            showVehicleBodyLengthExternalDimension(curPos, curLayer);
        }
        showGapBetweenVehicleAndTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerBodyLengthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerBackOfBodyToRearOfTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.BODY_EXTERNAL_POPPED;
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            showCabGapToBodyDimension(curPos, curLayer);
            showHookliftGapToBodyFromHookOffsetDimension(curPos, curLayer);
            showBackOfVehicleBodyToRearOfVehicleDimension(curPos, curLayer);
        }
        showTurntableToRearOfTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerGapToStartOfBodyDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        if (needToIncrementLayerAfterPop === true) {
            layerNumbers.incrementLayerNum(curPos);
            needToIncrementLayerAfterPop = false;
        }

        curLayer = LAYER.OVERALL_LENGTH;
        showCombinationOverallLengthDimension(curPos, curLayer);
    }

    function generateVehicleAndDrawbarOverviewDimensionsSideView() { 


        generateBottomVehicleAndDrawbarOverviewDimensions();
        
        generateTopVehicleAndDrawbarOverviewDimensions();

        curPos = POS.LEFT;

        curLayer = LAYER.CAB_HEIGHT;
        showCabHeightDimension(curPos, curLayer);

        curLayer = LAYER.VERTICAL_EXHAUST_HEIGHT;
        showVerticalExhaustHeightDimension(curPos, curLayer);

        curPos = POS.RIGHT;

        curLayer = LAYER.TRAILER_BODY_INTERNAL;
        showTrailerBodyInternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.TRAILER_CHASSIS_AND_BODY_EXTERNAL;
        showTrailerChassisAndBodyExternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.OVERALL_HEIGHT_AT_REAR;
        showOverallHeightAtRearDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        
    }

    function generateVehicleAndPupOverviewDimensions() {
        if (curElevation === config.ELEVATION.SIDE) {
            generateVehicleAndPupOverviewDimensionsSideView();
        } else {
            generateVehicleAndPupOverviewDimensionsTopView();
        }
    }

    function generateVehicleAndPupOverviewDimensionsTopView() {

        generateBottomVehicleAndPupOverviewDimensions();

        generateTopVehicleAndPupOverviewDimensions();

        generateLeftVehicleAndPupOverviewDimensionsTopView();

        generateRightVehicleAndPupOverviewDimensionsTopView()
    }

    function generateLeftVehicleAndPupOverviewDimensionsTopView() {
        curPos = POS.LEFT;
        curLayer = LAYER.VEHICLE_CAB_WIDTH;

        showVehicleCabWidthDimension(curPos, curLayer);

       curLayer = LAYER.COMBINATION_OVERALL_WIDTH;

        showCombinationOverallWidthDimension(curPos, curLayer);

        curLayer = LAYER.BODY_INTERNAL;

        var tempBody = curVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            showCompartmentDepthLeftDimension(curPos, curLayer);
            showFloorWidthDimension(curPos, curLayer);
            showCompartmentDepthRightDimension(curPos, curLayer);
        } else {
            showSidewallThicknessLeftDimension(curPos, curLayer);
            showBodyWidthInternalDimension(curPos, curLayer);
            showSidewallThicknessRightDimension(curPos, curLayer);
        }

        curLayer = LAYER.BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer);
    }

    function generateRightVehicleAndPupOverviewDimensionsTopView() {
        curPos = POS.RIGHT;
        curLayer = LAYER.BODY_INTERNAL;

        var tempBody = curTrailer1.getAccessoryHolder().getBody();
        if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            showCompartmentDepthLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showFloorWidthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showCompartmentDepthRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        } else {
            showSidewallThicknessLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showBodyWidthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showSidewallThicknessRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        }

        

        curLayer = LAYER.BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function generateBottomVehicleAndPupOverviewDimensions() {
        curPos = POS.BOTTOM;
        curLayer = LAYER.DRAWBAR_PROTRUSION;

        //DrawbarProtrusion
        showDrawbarProtrusionDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        ////Hitch
        curLayer = LAYER.HITCH;
        showAxle1ToBackOfCabDimension(curPos, curLayer);
        //showHitchOffsetDimension(curPos, curLayer);
        showDrawbarLengthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //showTrailerWheelbaseLegislationDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerRearOverhangLegislationDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);


        //Theoretical Wheelbase
        curLayer = LAYER.THEORETICAL_WHEELBASE;
        showBullbarProtrusionDimension(curPos, curLayer);
        showVehicleFrontOverhangDimension(curPos, curLayer);
        showVehicleAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalToAxle4RearDimension(curPos, curLayer);
        showVehicleRearOverhangTotal(curPos, curLayer);
        showTrailerOverallLengthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1, config.DIMENSION_TYPE.HORIZONTAL_BOTTOM);

        curLayer = LAYER.TRAILER_THEORETICAL_WHEELBASE;
        showTotalFrontOverhangDimension(curPos, curLayer);
        showTrailerWheelbaseTheoreticalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerWheelbaseTheoreticalEndToRearMostAxleDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerRearOverhangTotalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);


        //curLayer = LAYER.OVERALL_LENGTH;
        //showOverallVehicleLengthDimension(curPos, curLayer);
    }

    function generateTopVehicleAndPupOverviewDimensions() {
        curPos = POS.TOP;
        curLayer = LAYER.PAYLOAD_TO_WHEELBASE;

        //if (curVehicleBody !== null || curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    showVehiclePayloadCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.DIMENSION_STYLE.JUMP_LEFT);
        //}
        //if (curTrailer1Body !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //    showTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}


        //curLayer = LAYER.PAYLOAD_FROM_HEADBOARD_INSIDE;
        //if (curVehicleBody !== null && vehicle.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    showVehiclePayloadCOGFromHeadboardInsideDimension(curPos, curLayer);
        //} else if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    showVehiclePayloadCOGFromBumperToStartOfBodyInternal(curPos, curLayer);
        //}

        //if (curTrailer1Body !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //    showTrailerPayloadHorizontalCOGFromHeadboardInsideDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}
        curLayer = LAYER.AXLE_1_FRONT_TO_START_OF_BODY;
        if (curVehicleBody !== null) {
            showAxle1FrontToStartOfBodyDimension(curPos, curLayer);
            showBodyStartToWheelbaseSpecifiedEndDimension(curPos, curLayer);
        }

        curLayer = LAYER.BODY_INTERNAL;
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            showVehicleHeadboardThicknessDimension(curPos, curLayer);
            showVehicleBodyLengthInternalDimension(curPos, curLayer);
            showVehicleTailboardThicknessDimension(curPos, curLayer);
        }

        if (curTrailer1Body !== null) {
            showTrailerHeadboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showTrailerBodyLengthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showTrailerTailboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        }

        curLayer = LAYER.BODY_EXTERNAL;

        showBumperToBackOfCabDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            showVehicleBodyLengthExternalDimension(curPos, curLayer);
        }
        showGapBetweenVehicleAndTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerBodyLengthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerBackOfBodyToRearOfTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.BODY_EXTERNAL_POPPED;
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            showCabGapToBodyDimension(curPos, curLayer);
            showHookliftGapToBodyFromHookOffsetDimension(curPos, curLayer);
            showBackOfVehicleBodyToRearOfVehicleDimension(curPos, curLayer);
        }
        showTrailerGapToStartOfBodyDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        if (needToIncrementLayerAfterPop === true) {
            layerNumbers.incrementLayerNum(curPos);
            needToIncrementLayerAfterPop = false;
        }

        curLayer = LAYER.OVERALL_LENGTH;
        showCombinationOverallLengthDimension(curPos, curLayer);
    }

    function generateVehicleAndPupOverviewDimensionsSideView() {



        generateBottomVehicleAndPupOverviewDimensions();
        
        generateTopVehicleAndPupOverviewDimensions();

        curPos = POS.LEFT;

        curLayer = LAYER.CAB_HEIGHT;
        showCabHeightDimension(curPos, curLayer);

        curLayer = LAYER.VERTICAL_EXHAUST_HEIGHT;
        showVerticalExhaustHeightDimension(curPos, curLayer);

        curPos = POS.RIGHT;

        curLayer = LAYER.TRAILER_BODY_INTERNAL;
        showTrailerBodyInternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.TRAILER_CHASSIS_AND_BODY_EXTERNAL;
        showTrailerChassisAndBodyExternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

        curLayer = LAYER.OVERALL_HEIGHT_AT_REAR;
        showOverallHeightAtRearDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

    } 

    function generateVehicleAndInterlinkAndSemiOverviewDimensions() {
        if (curElevation === config.ELEVATION.SIDE) {
            generateVehicleAndInterlinkAndSemiOverviewDimensionsSideView();
        } else {
            generateVehicleAndInterlinkAndSemiOverviewDimensionsTopView();
        }
    }

    function generateVehicleAndInterlinkAndSemiOverviewDimensionsTopView() {

        generateBottomVehicleAndInterlinkAndSemiOverviewDimensions();

        generateTopVehicleAndInterlinkAndSemiOverviewDimensions();

        generateLeftVehicleAndInterlinkAndSemiOverviewDimensionsTopView();

        generateRightVehicleAndInterlinkAndSemiOverviewDimensionsTopView()
    }

    function generateVehicleAndInterlinkAndSemiOverviewDimensionsSideView() {



        generateBottomVehicleAndInterlinkAndSemiOverviewDimensions();

        generateTopVehicleAndInterlinkAndSemiOverviewDimensions();

        curPos = POS.LEFT;

        curLayer = LAYER.CAB_HEIGHT;
        showCabHeightDimension(curPos, curLayer);

        curLayer = LAYER.VERTICAL_EXHAUST_HEIGHT;
        showVerticalExhaustHeightDimension(curPos, curLayer);

        curPos = POS.RIGHT;

        curLayer = LAYER.TRAILER_BODY_INTERNAL;
        showTrailerBodyInternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.TRAILER_CHASSIS_AND_BODY_EXTERNAL;
        showTrailerChassisAndBodyExternalDimensions(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.OVERALL_HEIGHT_AT_REAR;
        showOverallHeightAtRearDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

    }

    function generateBottomVehicleAndInterlinkAndSemiOverviewDimensions() {

        curPos = POS.BOTTOM;

        curLayer = LAYER.VEHICLE_AND_TRAILER_FIFTH_WHEEL;
        showAxle1ToBackOfCabDimension(curPos, curLayer);
        //showFifthWheelOffsetDimension(curPos, curLayer);
        showTrailerFifthWheelOffsetDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //showTrailer2RearOverhangLegislationDimension(curPos, curLayer);
        showTrailerRearOverhangLegislationDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.VEHICLE_AND_TRAILER2_WHEELBASE_THEORETICAL;
        showBullbarProtrusionDimension(curPos, curLayer);
        showVehicleFrontOverhangDimension(curPos, curLayer);
        showVehicleAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalDimension(curPos, curLayer);
        showVehicleWheelbaseTheoreticalToAxle4RearDimension(curPos, curLayer);

        showTrailerFrontOverhangDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        showTrailerWheelbaseTheoreticalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        showTrailerWheelbaseTheoreticalEndToRearMostAxleDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        showTrailerRearOverhangTotalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.TRAILER1_WHEELBASE_THEORETICAL;

        showTotalFrontOverhangDimension(curPos, curLayer);
        showTrailerFrontOverhangDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerWheelbaseTheoreticalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerWheelbaseTheoreticalEndToRearMostAxleDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //showTrailerRearOverhangTotalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1); //hidden

    }

    function generateTopVehicleAndInterlinkAndSemiOverviewDimensions() {

        curPos = POS.TOP;
        curLayer = LAYER.TRAILER1_AND_TRAILER2_PAYLOAD_TO_WHEELBASE;

        //showTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //showTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.TRAILER1_AND_TRAILER2_PAYLOAD_FROM_HEADBOARD_INSIDE;

        //showTrailerPayloadHorizontalCOGFromHeadboardInsideDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //showTrailerPayloadHorizontalCOGFromHeadboardInsideDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.TRAILER1_AND_TRAILER2_BODY_INTERNAL;

        showTrailerHeadboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerBodyLengthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerTailboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerHeadboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        showTrailerBodyLengthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        showTrailerTailboardThicknessDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.TRAILER1_AND_TRAILER2_BODY_EXTERNAL;

        showVehicleBumperToBackOfRearmostEquipmentDimension(curPos, curLayer);
        showTrailerGapToStartOfBodyDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        showTrailerBodyLengthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        

        showTrailerGapToStartOfBodyDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        showTrailerBodyLengthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        showTrailerBackOfBodyToRearOfTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.GAPS_BETWEEN_TRAILERS_AND_KINGPIN_TO_REAR_OF_TRAILER2;

        //showGapBetweenVehicleAndTrailerDimension(curPos, curLayer);
        dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABGAPTOTRAILER)));
        showGapBetweenTrailer1EndOfBodyAndTrailer2(curPos, curLayer);
        showKingpinToRearOfTrailerDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);

        curLayer = LAYER.OVERALL_LENGTH;

        showCombinationOverallLengthDimension(curPos, curLayer);

    }

    function generateLeftVehicleAndInterlinkAndSemiOverviewDimensionsTopView() {
        curPos = POS.LEFT;
        curLayer = LAYER.VEHICLE_CAB_WIDTH;

        showVehicleCabWidthDimension(curPos, curLayer);

        curLayer = LAYER.COMBINATION_OVERALL_WIDTH;

        showCombinationOverallWidthDimension(curPos, curLayer);

        curLayer = LAYER.TRAILER1_BODY_INTERNAL;

        //var tempBody = curTrailer1.getAccessoryHolder().getBody();
        //if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
        //    showCompartmentDepthLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //    showFloorWidthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //    showCompartmentDepthRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //} else {
            showSidewallThicknessLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showBodyWidthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            showSidewallThicknessRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}



        curLayer = LAYER.TRAILER1_BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER1);

    }

    function generateRightVehicleAndInterlinkAndSemiOverviewDimensionsTopView() {

        curPos = POS.RIGHT;

        curLayer = LAYER.TRAILER2_BODY_INTERNAL;

        //var tempBody = curTrailer2.getAccessoryHolder().getBody();
        //if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
        //    showCompartmentDepthLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        //    showFloorWidthDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        //    showCompartmentDepthRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        //} else {
            showSidewallThicknessLeftDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
            showBodyWidthInternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
            showSidewallThicknessRightDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        //}



        curLayer = LAYER.TRAILER2_BODY_EXTERNAL;
        showBodyWidthExternalDimension(curPos, curLayer, config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }
    

    //#region common Dimensions functions

    function showKingpinToRearOfTrailerDimension(curPos, curLayer, attrContext) {
        if (offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.KingpinToRearOfTrailerUsed) === 1) {

            var kingpinToRearOfTrailerDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.KINGPINTOREAROFTRAILER, attrContext)/*, config.DIMENSION_STYLE.POP_LAYER*/);

            var path = attrContext + '.' + config.VALUE_TYPE.KINGPINTOREAROFTRAILER;
            addWarningBehaviourToDimension(kingpinToRearOfTrailerDim, attrContext + '.' + config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER, path);
            // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER) !== null) {
            //     if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER).getIsNew() === true) {
            //         kingpinToRearOfTrailerDim.setPulse(true);
            //     }
            //     kingpinToRearOfTrailerDim.setShowAsWarning(true);

            //     kingpinToRearOfTrailerDim.setDrawingGroupName(path);
            // }

            dimensions.push(kingpinToRearOfTrailerDim);
        }
    }

    function showGapBetweenTrailer1EndOfBodyAndTrailer2(curPos, curLayer) {

        var trailer1Body = offerManager.getRigOps().getBodyFromPath(config.CHASSIS_OBJECT_TYPES.TRAILER1);

        if (trailer1Body !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            var gapBetweenTrailer1EndOfBodyAndTrailer2Dim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.GAPBETWEENTRAILERANDTRAILER));

            var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.GAPBETWEENTRAILERANDTRAILER;
            addWarningBehaviourToDimension(gapBetweenTrailer1EndOfBodyAndTrailer2Dim, config.WARNING_ID.GAP_BETWEEN_TRAILER_AND_TRAILER, path);

            dimensions.push(gapBetweenTrailer1EndOfBodyAndTrailer2Dim);

            return true;
        }
        return false;
    }

    

    function showVehicleBumperToBackOfRearmostEquipmentDimension(curPos, curLayer) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BUMPERTOBACKOFREARMOSTEQUIPMENT)));
        return true;
    }

    function showChassisHeightRearDimension(curPos, curLayer, attrContext) {
        var chassisHeightRearDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CHASSISHEIGHTREAR, attrContext));

        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CHASSISHEIGHTREAR;
        if (offerManager.isAttributeGroupVisible(path)) {
            chassisHeightRearDim.setDrawingGroupName(path);
            chassisHeightRearDim.setShowAsHyperLink(true);
        }

        dimensions.push(chassisHeightRearDim);
    }

    function showVehicleCabWidthDimension(curPos, curLayer) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_LEFT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABWIDTH)));
        return true;
    }

    function showVehicleRearWidthDimension(curPos, curLayer) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REARWIDTH)));
        return true;
    }

    function showSidewallThicknessLeftDimension(curPos, curLayer, attrContext) {

        if (offerManager.getRigOps().chassisHasBody(attrContext) === true) {

            var dimensionType;
            if (curPos === POS.LEFT) {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_LEFT;
            } else {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_RIGHT;
            }

            //dimensions.push(new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYSIDEWALLLEFT, attrContext), config.DIMENSION_STYLE.JUMP_DOWN));
            var sidewallLeftDim = new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYSIDEWALLLEFT, attrContext), config.DIMENSION_STYLE.JUMP_DOWN);
            // Check combination type
            if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
                var chassisObjectToUse = typeof attrContext === 'string' ? attrContext : config.CHASSIS_OBJECT_TYPES.VEHICLE;
                var path = chassisObjectToUse + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYSIDEWALLLEFT;
                if (offerManager.isAttributeGroupVisible(path)) {
                    sidewallLeftDim.setDrawingGroupName(path);
                    sidewallLeftDim.setShowAsHyperLink(true);
                }
            }
            
            dimensions.push(sidewallLeftDim);

            return true;
        }
        return false;
    }

    function showSidewallThicknessRightDimension(curPos, curLayer, attrContext) {
        if (offerManager.getRigOps().chassisHasBody(attrContext) === true) {

            var dimensionType;
            if (curPos === POS.LEFT) {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_LEFT;
            } else {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_RIGHT;
            }

            //dimensions.push(new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYSIDEWALLRIGHT, attrContext), config.DIMENSION_STYLE.JUMP_UP));
            var sidewallRightDim = new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYSIDEWALLRIGHT, attrContext), config.DIMENSION_STYLE.JUMP_UP);
            if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
                var chassisObjectToUse = typeof attrContext === 'string' ? attrContext : config.CHASSIS_OBJECT_TYPES.VEHICLE;
                var path = chassisObjectToUse + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYSIDEWALLRIGHT;
                if (offerManager.isAttributeGroupVisible(path)) {
                    sidewallRightDim.setDrawingGroupName(path);
                    sidewallRightDim.setShowAsHyperLink(true);
                }
            }
            dimensions.push(sidewallRightDim);
            return true;
        }
        return false;
    }

    function showBodyWidthInternalDimension(curPos, curLayer, attrContext) {

        if (offerManager.getRigOps().chassisHasBody(attrContext) === true) {

            var dimensionType;
            if (curPos === POS.LEFT) {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_LEFT;
            } else {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_RIGHT;
            }

            //dimensions.push(new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYWIDTH, attrContext)));
            var bodyWidthInternalDim = new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYWIDTH, attrContext));
            if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
                var tempBody = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
                if (tempBody !== null && tempBody.getWidthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                    var chassisObjectToUse = typeof attrContext === 'string' ? attrContext : config.CHASSIS_OBJECT_TYPES.VEHICLE;
                    var path = chassisObjectToUse + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYWIDTH;
                    if (offerManager.isAttributeGroupVisible(path)) {
                        bodyWidthInternalDim.setDrawingGroupName(path);
                        bodyWidthInternalDim.setShowAsHyperLink(true);
                    }
                }
            }
            dimensions.push(bodyWidthInternalDim);

            return true;
        }
        return false;
    }

    function showCompartmentDepthLeftDimension(curPos, curLayer, attrContext) {

        if (offerManager.getRigOps().chassisHasBody(attrContext) === true) {

            var dimensionType;
            if (curPos === POS.LEFT) {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_LEFT;
            } else {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_RIGHT;
            }

            dimensions.push(new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLEFTCOMPARTMENTWIDTH, attrContext), config.DIMENSION_STYLE.JUMP_DOWN));
            return true;
        }
        return false;
    }

    function showCompartmentDepthRightDimension(curPos, curLayer, attrContext) {

        if (offerManager.getRigOps().chassisHasBody(attrContext) === true) {

            var dimensionType;
            if (curPos === POS.LEFT) {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_LEFT;
            } else {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_RIGHT;
            }

            dimensions.push(new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYRIGHTCOMPARTMENTWIDTH, attrContext), config.DIMENSION_STYLE.JUMP_UP));
            return true;
        }
        return false;
    }

    function showFloorWidthDimension(curPos, curLayer, attrContext) {

        if (offerManager.getRigOps().chassisHasBody(attrContext) === true) {

            var dimensionType;
            if (curPos === POS.LEFT) {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_LEFT;
            } else {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_RIGHT;
            }

            dimensions.push(new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYFLOORWIDTH, attrContext)));
            return true;
        }
        return false;
    }
    

    function showBodyWidthExternalDimension(curPos, curLayer, attrContext) {
        if (offerManager.getRigOps().chassisHasBody(attrContext) === true) {

            var dimensionType;
            if (curPos === POS.LEFT) {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_LEFT;
            } else {
                dimensionType = config.DIMENSION_TYPE.VERTICAL_RIGHT;
            }

            //dimensions.push(new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYWIDTHEXTERNAL, attrContext)));
            var bodyWidthExternalDim = new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYWIDTHEXTERNAL, attrContext));
            if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
                var tempBody = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
                if (tempBody !== null && tempBody.getWidthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    var chassisObjectToUse = typeof attrContext === 'string' ? attrContext : config.CHASSIS_OBJECT_TYPES.VEHICLE;
                    var path = chassisObjectToUse + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYWIDTH;
                    if (offerManager.isAttributeGroupVisible(path)) {
                        bodyWidthExternalDim.setDrawingGroupName(path);
                        bodyWidthExternalDim.setShowAsHyperLink(true);
                    }
                }
            }
            dimensions.push(bodyWidthExternalDim);
            return true;
        }
        return false;
    }

    function showAxle1ToBackOfCabDimension(curPos, curLayer) {
        if (offerManager.getCurMeasurementSysRoundedLength(curVehicle.getFrontOverhang()) < offerManager.getCurMeasurementSysRoundedLength(curVehicle.getBumperToBackOfCab())) {
            var axle1ToBackOfCabDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE1FRONTTOBACKOFCAB), config.DIMENSION_STYLE.JUMP_LEFT);

            if (globals.user.getSpecifyCabDimensionsAs() === config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.AC) {
                var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOBACKOFCAB;
                if (offerManager.isAttributeGroupVisible(path)) {
                    axle1ToBackOfCabDim.setDrawingGroupName(path);
                    axle1ToBackOfCabDim.setShowAsHyperLink(true);
                }
            }

            dimensions.push(axle1ToBackOfCabDim);
        }
    }

    function showCabToRearAxlesDimension(curPos, curLayer) {
        //cab To Axles(cab to bogie/rear axle)
        
        if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getCabToAxles()) !== 0 && 
            (vehicle.getAccessoryHolder().getHitch() === null || (vehicle.getAccessoryHolder().getHitch() !== null && (globals.user.getSpecifyWheelbaseAs() === config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE || globals.user.getSpecifyWheelbaseAs() === config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES)))) {
            var cabToAxlesDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABTOAXLES));

            if (globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA) {
                var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABTOAXLES;
                
                if (offerManager.isAttributeGroupVisible(path)) {
                    cabToAxlesDim.setDrawingGroupName(path);
                    cabToAxlesDim.setShowAsHyperLink(true);
                }
            }

            dimensions.push(cabToAxlesDim);
        }
    }

    function showFifthWheelOffsetDimension(curPos, curLayer) {
        if (vehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            
            var dimensionStyle;
            if (vehicle.getFifthWheelOffset() < 0) {
                dimensionStyle = config.DIMENSION_STYLE.JUMP_LEFT;
            } else {
                dimensionStyle = config.DIMENSION_STYLE.JUMP_RIGHT;
            }

            var fifthWheelOffsetDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.FIFTHWHEELOFFSET), dimensionStyle);
            var path;
            if (vehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET;
            } else {
                path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET;
            }
            if(offerManager.isAttributeGroupVisible(path)) {
                fifthWheelOffsetDim.setDrawingGroupName(path);
                fifthWheelOffsetDim.setShowAsHyperLink(true);
            }

            dimensions.push(fifthWheelOffsetDim);
            return true;
        }
        return false;
    }

    function showTrailerFifthWheelOffsetDimension(curPos, curLayer, attrContext) {
        
        var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
            var dimensionStyle;
            if (trailer.getFifthWheelOffset() < 0) {
                dimensionStyle = config.DIMENSION_STYLE.JUMP_LEFT;
            } else {
                dimensionStyle = config.DIMENSION_STYLE.JUMP_RIGHT;
            }

            var fifthWheelOffsetDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERFIFTHWHEELOFFSET, attrContext), dimensionStyle);
            
            var path = attrContext + '.' + config.VALUE_TYPE.TRAILERFIFTHWHEELOFFSET;

        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        if (offerManager.isAttributeGroupVisible(path) && chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && (chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(chassisObjectToUse))) {
                fifthWheelOffsetDim.setDrawingGroupName(path);
                fifthWheelOffsetDim.setShowAsHyperLink(true);
            }

            dimensions.push(fifthWheelOffsetDim);
            return true;
    }

    function showHitchOffsetDimension(curPos, curLayer) {
        var tempHitch = vehicle.getAccessoryHolder().getHitch();
        if (tempHitch !== null) {

            
            var dimensionStyle;
            if (tempHitch.getOffset() < 0) {
                dimensionStyle = config.DIMENSION_STYLE.JUMP_LEFT;
            } else {
                dimensionStyle = config.DIMENSION_STYLE.JUMP_RIGHT;
            }
            var hitchOffsetDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.HITCHOFFSET), dimensionStyle);
            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH + '.' + config.VALUE_TYPE.HITCHOFFSET;
            
            if (offerManager.isAttributeGroupVisible(path)) {
                hitchOffsetDim.setDrawingGroupName(path);
                hitchOffsetDim.setShowAsHyperLink(true);
            }

            dimensions.push(hitchOffsetDim);
            return true;
        }
        return false;
    }

    function showBullbarProtrusionDimension(curPos, curLayer) { 
        if (curVehicle.getAccessoryHolder().getBullbar() !== null) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BULLBARPROTRUSION), config.DIMENSION_STYLE.JUMP_LEFT));
            return true;
        }
        return false;
    }

    function showBodyOverhangDimension(curPos, curLayer) {
        if (curVehicle.getAccessoryHolder().getBody() !== null && offerManager.getCurMeasurementSysRoundedLength(curVehicle.getBodyOverhang()) > 0) {

            var bodyOverhangDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYOVERHANG));
            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BODYOVERHANG;
            addWarningBehaviourToDimension(bodyOverhangDim, config.WARNING_ID.HOOKLIFT_BODY_OVERHANG_EXCEEDED, path);
            // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.HOOKLIFT_BODY_OVERHANG_EXCEEDED) !== null) {
            //     if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.HOOKLIFT_BODY_OVERHANG_EXCEEDED).getIsNew() === true) {
            //         bodyOverhangDim.setPulse(true);
            //     }
            //     bodyOverhangDim.setShowAsWarning(true);
            //     bodyOverhangDim.setDrawingGroupName(path);
            // }

            dimensions.push(bodyOverhangDim);
            return true;
        }
        return false;
    }
    
    function showAfterFrameDimension(curPos, curLayer) {
        if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getAfterframe()) > 0) {
            var afterFrameDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AFTERFRAME));
            
            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AFTERFRAME;
            if (offerManager.isAttributeGroupVisible(path)) {
                afterFrameDim.setDrawingGroupName(path);
                afterFrameDim.setShowAsHyperLink(true);
            }

            dimensions.push(afterFrameDim);
            return true;
        }
        return false;
    }

    function showRearProtrusionVehicleOnlyDimension(curPos, curLayer) {
        if (offerManager.getCurMeasurementSysRoundedLength(getRearProtrusionVehicleOnlyDimensionLength()) > 0) {
            var rearProtrusionVehicleOnlyDim;
            if (offerManager.getCurMeasurementSysRoundedLength(getRearProtrusionVehicleOnlyDimensionLength()) < offerManager.getCurMeasurementSysRoundedLength(300)) {
                rearProtrusionVehicleOnlyDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REARPROTRUSIONVEHICLEONLY), config.DIMENSION_STYLE.POP_LAYER);
                needToIncrementLayerAfterPop = true;
            } else {
                rearProtrusionVehicleOnlyDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REARPROTRUSIONVEHICLEONLY));
            }
            
            //var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AFTERFRAME;


            dimensions.push(rearProtrusionVehicleOnlyDim);
            return true;
        }
        return false;
    }

    
    function showVehicleFrontOverhangDimension(curPos, curLayer) {

        var frontOVerhangDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.FRONTOVERHANG));

        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.FRONTOVERHANG;
        addWarningBehaviourToDimension(frontOVerhangDim, config.WARNING_ID.VEHICLE_FRONT_OVERHANG, path);
        if (offerManager.isAttributeGroupVisible(path)) {
            frontOVerhangDim.setDrawingGroupName(path);
            frontOVerhangDim.setShowAsHyperLink(true);
        }

        dimensions.push(frontOVerhangDim);
        return true;
    }

    function showVehicleAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer) {
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle1FrontToWheelbaseTheoreticalStart()) !== 0) {
            var axle1FrontToWheelbasetheoreticalStartDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE1FRONTTOWHEELBASETHEORETICALSTART));

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.AXLE1FRONTTOWHEELBASELEGISLATIONSTART;
            addWarningBehaviourToDimension(axle1FrontToWheelbasetheoreticalStartDim, config.WARNING_ID.VEHICLE_AXLE_1_FRONT_TO_WHEELBASE_THEORETICAL_START, path);
            if (offerManager.isAttributeGroupVisible(path)) {
                axle1FrontToWheelbasetheoreticalStartDim.setDrawingGroupName(path);
                axle1FrontToWheelbasetheoreticalStartDim.setShowAsHyperLink(true);
            }

            dimensions.push(axle1FrontToWheelbasetheoreticalStartDim);
            return true;
        }
        return false;
    }

    function showTotalFrontOverhangDimension(curPos, curLayer) {
        if (curVehicle.getAccessoryHolder().getBullbar() !== null) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TOTALFRONTOVERHANG)));
            return true;
        }
        return false;
    }

    function showAxle1FrontToWheelbaseLegislationStartDimension(curPos, curLayer, comVehAxle1FrontToWheelbaseLegislationStart) {
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getAxle1FrontToWheelbaseTheoreticalStart()) !== offerManager.getCurMeasurementSysRoundedLength(comVehAxle1FrontToWheelbaseLegislationStart) &&
                offerManager.getCurMeasurementSysRoundedLength(comVehAxle1FrontToWheelbaseLegislationStart) !== 0) {

            let vehicleAxle1FrontToWheelbaseTheoreticalStartDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.AXLE1FRONTTOWHEELBASELEGISLATIONSTART));
            
            dimensions.push(vehicleAxle1FrontToWheelbaseTheoreticalStartDim);
        }
    }

    function showWheelbaseSpecifiedDimension(curPos, curLayer, comVehWheelbaseLegislation) {

        var wheelbaseSpecifiedDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.WHEELBASESPECIFIED));

        if (globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.WHEELBASE) {
            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASESPECIFIED;
            addWarningBehaviourToDimension(wheelbaseSpecifiedDim, config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL, path);
            //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL) !== null) {
            //    axle2FrontToAxle1RearDim.setDrawingGroupName(path);
            //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL).getIsNew() === true) {
            //        axle2FrontToAxle1RearDim.setPulse(true);
            //    }
            //    axle2FrontToAxle1RearDim.setShowAsWarning(true);
            //}

            if (offerManager.isAttributeGroupVisible(path)) {
                wheelbaseSpecifiedDim.setDrawingGroupName(path);
                wheelbaseSpecifiedDim.setShowAsHyperLink(true);
            }
        }
        dimensions.push(wheelbaseSpecifiedDim);
    }

    function showVehicleRearOverhangLegislationDimension(curPos, curLayer, rearOverhangLegislationDimensionConfig, showAsRearOverhangeDimensionAsWarning, shownOnROLegislation) {
        var rearOverhangLegislationDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), rearOverhangLegislationDimensionConfig, config.DIMENSION_STYLE.JUMP_RIGHT);

        if (showAsRearOverhangeDimensionAsWarning === true && shownOnROLegislation === true) {
            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANGLEGISLATION;
            addWarningBehaviourToDimension(rearOverhangLegislationDim, config.WARNING_ID.REAR_OVERHANG_EXCEEDED, path);
            // rearOverhangLegislationDim.setDrawingGroupName(path);
            // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED).getIsNew() === true) {
            //     rearOverhangLegislationDim.setPulse(true);
            // }
            // rearOverhangLegislationDim.setShowAsWarning(true);
        }

        dimensions.push(rearOverhangLegislationDim);
    }

    function showVehicleWheelbaseTheoreticalDimension(curPos, curLayer) {
        var wheelbaseTheoreticalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.WHEELBASETHEORETICAL));

        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.WHEELBASETHEORETICAL;
        addWarningBehaviourToDimension(wheelbaseTheoreticalDim, config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL, path);
        //if (offerManager.isAttributeGroupVisible(path) && curViewType !== config.VIEW_TYPE.COG) {
        //    wheelbaseTheoreticalDim.setDrawingGroupName(path);
        //    wheelbaseTheoreticalDim.setShowAsHyperLink(true);
        //}

        dimensions.push(wheelbaseTheoreticalDim);
        return true;
    }

    function showVehicleWheelbaseTheoreticalToAxle4RearDimension(curPos, curLayer) {

        var wheelbaseTheoreticalEndtoAxle4Rear = vehicle.getWheelbaseTheoreticalEndToAxle4Rear();

        if (offerManager.getCurMeasurementSysRoundedLength(wheelbaseTheoreticalEndtoAxle4Rear) !== 0) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.WHEELBASETHEORETICALENDTOAXLE4REAR)));
            return true;
        }
        return false;
    }

    function showCabHeightDimension(curPos, curLayer) {

        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getCabHeight()) !== 0) {
            var cabHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_LEFT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABHEIGHT));

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CABHEIGHT;

            if (offerManager.isAttributeGroupVisible(path)) {
                cabHeightDim.setDrawingGroupName(path);
                cabHeightDim.setShowAsHyperLink(true);
            }

            dimensions.push(cabHeightDim);
            return true;
        }
        return false;
    }

    function showVerticalExhaustHeightDimension(curPos, curLayer) {
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getVerticalExhaustHeight()) !== 0 &&
                    offerManager.getCurMeasurementSysRoundedLength(vehicle.getVerticalExhaustHeight()) > offerManager.getCurMeasurementSysRoundedLength(vehicle.getCabHeight())) {

            dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_LEFT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.VERTICALEXHAUSTHEIGHT)));
            return true;
        }
        return false;
    }

    function showBodyStructureHeightDimension(curPos, curLayer, attrContext) {

        var bodyStructureHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYSUBSTRUCTUREHEIGHT, attrContext), config.DIMENSION_STYLE.JUMP_DOWN);

        var path = attrContext === undefined ? config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYSUBSTRUCTUREHEIGHT : attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYSUBSTRUCTUREHEIGHT;
        if(offerManager.isAttributeGroupVisible(path)) {
            bodyStructureHeightDim.setDrawingGroupName(path);
            bodyStructureHeightDim.setShowAsHyperLink(true);
        }

        dimensions.push(bodyStructureHeightDim);
        return true;
    }

    function showCombinedBodySubframeAndSubstructureDimension(curPos, curLayer, attrContext) {

        
        var bodyCombinedSubframeAndStructureHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYCOMBINEDSUBFRAMEANDSUBSTRUCTUREHEIGHT, attrContext), config.DIMENSION_STYLE.POP_LAYER);

        dimensions.push(bodyCombinedSubframeAndStructureHeightDim);
        
        return true;
    }

    function showBodyHeightDimension(curPos, curLayer, attrContext) {

        var bodyHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYHEIGHT, attrContext));
        var tempBody = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
        if (tempBody !== null && tempBody.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
            //var path = attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEIGHT;
            var path = attrContext === undefined ? config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEIGHT : attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEIGHT;
            if(offerManager.isAttributeGroupVisible(path)) {
                bodyHeightDim.setDrawingGroupName(path);
                bodyHeightDim.setShowAsHyperLink(true);
            }
        }
        

        dimensions.push(bodyHeightDim);
        return true;
    }

    function showBodyRoofThicknessDimension(curPos, curLayer, attrContext) {

        var bodyRoofThicknessDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYROOFTHICKNESS, attrContext), config.DIMENSION_STYLE.JUMP_UP);

        var path = attrContext === undefined ? config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYROOFTHICKNESS : attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYROOFTHICKNESS;
        if(offerManager.isAttributeGroupVisible(path)) {
            bodyRoofThicknessDim.setDrawingGroupName(path);
            bodyRoofThicknessDim.setShowAsHyperLink(true);
        }

        dimensions.push(bodyRoofThicknessDim);
        return true;
    }

    function showBodyHeightExternalDimension(curPos, curLayer, attrContext) {

        var bodyHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYHEIGHTEXTERNAL, attrContext));
        var tempBody = offerManager.getRigOps().getBodyFromPath(attrContext || config.CHASSIS_OBJECT_TYPES.VEHICLE);
        if (tempBody !== null && tempBody.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
            var path = attrContext === undefined ? config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEIGHT : attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEIGHT;
            if (offerManager.isAttributeGroupVisible(path)) {
                bodyHeightDim.setDrawingGroupName(path);
                bodyHeightDim.setShowAsHyperLink(true);
            }
        }
        dimensions.push(bodyHeightDim);
        return true;
    }

    function showOverallHeightAtRearDimension(curPos, curLayer, attrContext) {

        var overallHeightAtRearDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.OVERALLHEIGHTATREAR, attrContext));
        var pathRoot = attrContext === undefined ? config.CHASSIS_OBJECT_TYPES.VEHICLE : attrContext;
        var path = pathRoot + '.' + config.VALUE_TYPE.OVERALLHEIGHTATREAR;
        addWarningBehaviourToDimension(overallHeightAtRearDim, config.WARNING_ID.OVERALL_HEIGHT_EXCEEDED, path);

        dimensions.push(overallHeightAtRearDim);
        return true;
    }

    function showDrawbarProtrusionDimension(curPos, curLayer, attrContext) {
        

        var drawbarProtrusionDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERDRAWBARPROTRUSION, attrContext));

        var path = attrContext + '.' + config.VALUE_TYPE.TRAILERDRAWBARPROTRUSION;
        
        addWarningBehaviourToDimension(drawbarProtrusionDim, attrContext + '.' + config.WARNING_ID.TRAILER_DRAWBAR_LENGTH, path);

        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        if (offerManager.isAttributeGroupVisible(path) && chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && (chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(chassisObjectToUse))) {
            drawbarProtrusionDim.setDrawingGroupName(path);
            drawbarProtrusionDim.setShowAsHyperLink(true);
        }

        dimensions.push(drawbarProtrusionDim);
        return true;
        
    }

    function showAxle1FrontToStartOfBodyDimension(curPos, curLayer) {
        var dimensionStyle;
        //if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR) {
        //    dimensionStyle = config.DIMENSION_STYLE.JUMP_RIGHT;
        //} else {
            dimensionStyle = config.DIMENSION_STYLE.JUMP_LEFT;
        //}

        var axle1FrontToStartOfBodyDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYFROMAXLE1FRONT), dimensionStyle);

        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYFROMAXLE1FRONT;
        if (offerManager.isAttributeGroupVisible(path)) {
            axle1FrontToStartOfBodyDim.setDrawingGroupName(path);
            axle1FrontToStartOfBodyDim.setShowAsHyperLink(true);
        }

        dimensions.push(axle1FrontToStartOfBodyDim);
        return true;
    }

    function showBodyStartToWheelbaseSpecifiedEndDimension(curPos, curLayer) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYSTARTTOWHEELBASESPECIFIEDEND)));
        return true;
    }

    function showDrawbarLengthDimension(curPos, curLayer, attrContext) {

        var drawDimension = false;
        var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext); 
        if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
            // if (offerManager.getCurMeasurementSysRoundedLength(curTrailer1.getDrawbarLength()) !== offerManager.getCurMeasurementSysRoundedLength(offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1DrawbarLengthLegislation))) {
            if (offerManager.getTrailer1DrawbarLengthEqualToDrawbarLegislationLength()) {
                drawDimension = true;
            }
        } else if (trailer.getType() === config.TRAILER_TYPES.PUP) {
            if (offerManager.getTrailer1DrawbarLengthEqualToDrawbarLegislationLength() && offerManager.getCurMeasurementSysRoundedLength(offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1DrawbarLengthLegislation)) !== offerManager.getCurMeasurementSysRoundedLength(trailer.getWheelbaseTheoretical())) {
                drawDimension = true;
            }
        }

        if (drawDimension) {

            var drawbarLengthDimension = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERDRAWBARLENGTH, attrContext));

            var path = attrContext + '.' + config.VALUE_TYPE.TRAILERDRAWBARLENGTH;
            addWarningBehaviourToDimension(drawbarLengthDimension, attrContext + '.' + config.WARNING_ID.TRAILER_DRAWBAR_LENGTH, path);

            //if (offerManager.isAttributeGroupVisible(path)) {
            //    drawbarLengthDimension.setDrawingGroupName(path);
            //    drawbarLengthDimension.setShowAsHyperLink(true);
            //}


            dimensions.push(drawbarLengthDimension);
            return true;
        }
        return false;
    }

    function showTrailerAxle1FrontToWheelbaseLegislationStartDimension(curPos, curLayer, attrContext) {
        if (offerManager.getCurMeasurementSysRoundedLength(offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle1FrontToWheelbaseLegislationStart)) > 0) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASELEGISLATIONSTART, attrContext)));
            return true;
        }
        return false;
    }

    function showTrailerWheelbaseLegislationDimension(curPos, curLayer, attrContext) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERWHEELBASELEGISLATION, attrContext)));
        return true;
    }

    // function showTrailer2RearOverhangLegislationDimension(curPos, curLayer, attrContext) {
        
    //     dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERREAROVERLEGISLATION, attrContext)));
    //     return true;
    // }

    function showTrailerRearOverhangLegislationDimension(curPos, curLayer, attrContext) {

        var rearOverhangLegislation = 0, rearOverhangTotal = 0;

        if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            rearOverhangLegislation = offerManager.getCurMeasurementSysRoundedLength(offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangLegislation));
            
        } else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            
            rearOverhangLegislation = offerManager.getCurMeasurementSysRoundedLength(offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer2RearOverhangLegislation));
        }
        rearOverhangTotal = offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getTrailerRearOverhangTotal(attrContext));

        if (rearOverhangLegislation !== rearOverhangTotal) {
            var trailerRearOverhangLegislationDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERREAROVERHANGLEGISLATION, attrContext));

            var path = attrContext + '.' + config.VALUE_TYPE.TRAILERREAROVERHANGLEGISLATION;
            //if (offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangLegislation) > offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangLegislationMax)) {
            if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null) {
                if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION).getIsNew() === true) {
                    trailerRearOverhangLegislationDim.setPulse(true);
                }
                trailerRearOverhangLegislationDim.setShowAsWarning(true);
                trailerRearOverhangLegislationDim.setDrawingGroupName(path);
            }

            dimensions.push(trailerRearOverhangLegislationDim);
        }
    }

    function showVehicleRearOverhangTotal(curPos, curLayer) {
        let vehicleRearOverhangTotalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REAROVERHANGTOTAL));
        let path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.REAROVERHANGTOTAL;
        addWarningBehaviourToDimension(vehicleRearOverhangTotalDim, config.WARNING_ID.VEHICLE_REAR_OVERHANG_TOTAL, path);
        dimensions.push(vehicleRearOverhangTotalDim);
        return true;
    }

    function showTrailerFrontOverhangDimension(curPos, curLayer, attrContext) {

        var trailerFrontOverhangdim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERFRONTOVERHANG, attrContext), config.DIMENSION_STYLE.JUMP_LEFT);

        var path = attrContext + '.' + config.VALUE_TYPE.TRAILERFRONTOVERHANG;
        addWarningBehaviourToDimension(trailerFrontOverhangdim, attrContext + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG, path);
        // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null) {
        //     if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_FRONT_OVERHANG).getIsNew() === true) {
        //         trailerFrontOverhangdim.setPulse(true);
        //     }
        //     trailerFrontOverhangdim.setDrawingGroupName(path);
        //     trailerFrontOverhangdim.setShowAsWarning(true);
        // }
        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        if (offerManager.isAttributeGroupVisible(path) && chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && (chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(chassisObjectToUse))) {
            trailerFrontOverhangdim.setDrawingGroupName(path);
            trailerFrontOverhangdim.setShowAsHyperLink(true);
        }

        dimensions.push(trailerFrontOverhangdim);
        return true;
    }

    function showTrailerAxle1FrontToWheelbaseTheoreticalStartDimension(curPos, curLayer, attrContext) {
        var trailerAxle1FrontToWheelbaseTheoreticalStart;
        if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            trailerAxle1FrontToWheelbaseTheoreticalStart = curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart();
        } else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            trailerAxle1FrontToWheelbaseTheoreticalStart = curTrailer2.getAxle1FrontToWheelbaseTheoreticalStart();
        } else {
            trailerAxle1FrontToWheelbaseTheoreticalStart = 0;
        }

        if (offerManager.getCurMeasurementSysRoundedLength(trailerAxle1FrontToWheelbaseTheoreticalStart) !== 0) {
            let trailerAxle1FrontToWheelbaseTheoreticalStartDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART, attrContext));
            let path = attrContext + '.' + config.VALUE_TYPE.TRAILERAXLE1FRONTTOWHEELBASETHEORETICALSTART;
            addWarningBehaviourToDimension(trailerAxle1FrontToWheelbaseTheoreticalStartDim, undefined, path);
            dimensions.push(trailerAxle1FrontToWheelbaseTheoreticalStartDim);
            return true;
        }
        return false;
    }

    function showTrailerWheelbaseTheoreticalDimension(curPos, curLayer, attrContext) {

        var trailerWheelbaseTheoreticalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL, attrContext));

        var path = attrContext + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL;
        addWarningBehaviourToDimension(trailerWheelbaseTheoreticalDim, attrContext + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL, path);
        //if (curTrailer1.getWheelbaseTheoretical() > offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseTheoreticalMax)) {
        // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null) {
        //     if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL).getIsNew() === true) {

        //         trailerWheelbaseTheoreticalDim.setPulse(true);
        //     }
        //     trailerWheelbaseTheoreticalDim.setShowAsWarning(true);
        //     trailerWheelbaseTheoreticalDim.setDrawingGroupName(path);
        // }

        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        if (offerManager.isAttributeGroupVisible(path) && chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && (chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(chassisObjectToUse))) {
            trailerWheelbaseTheoreticalDim.setDrawingGroupName(path);
            if (curViewType !== config.VIEW_TYPE.COG) {
                trailerWheelbaseTheoreticalDim.setShowAsHyperLink(true);
            }
        }

        dimensions.push(trailerWheelbaseTheoreticalDim);
        return true;
    }

    function showTrailerWheelbaseTheoreticalEndToRearMostAxleDimension(curPos, curLayer, attrContext) {
        if (offerManager.getCurMeasurementSysRoundedLength(curTrailer1.getWheelbaseTheoreticalEndToRearMostAxle()) !== 0) {
            let trailerWheelbaseTheoreticalEndToRearMostAxleDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERWHEELBASETHEORETICALENDTOREARMOSTAXLE, attrContext));
            let path = attrContext + '.' + config.VALUE_TYPE.TRAILERWHEELBASETHEORETICALENDTOREARMOSTAXLE;
            addWarningBehaviourToDimension(trailerWheelbaseTheoreticalEndToRearMostAxleDim, attrContext + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL_END_TO_REARMOST_AXLE, path);
            dimensions.push(trailerWheelbaseTheoreticalEndToRearMostAxleDim);
            return true
        }
        return false;
    }

    function showTrailerRearOverhangTotalDimension(curPos, curLayer, attrContext) {

        var rearOverhangLegislation = 0, rearOverhangTotal = 0;

        if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            rearOverhangLegislation = offerManager.getCurMeasurementSysRoundedLength(offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangLegislation));
            
        } else if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            rearOverhangLegislation = offerManager.getCurMeasurementSysRoundedLength(offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.Trailer2RearOverhangLegislation));
        }
        rearOverhangTotal = offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getTrailerRearOverhangTotal(attrContext));


        var trailerRearOverhangTotalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_BOTTOM, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERREAROVERHANGTOTAL, attrContext));

        var path = attrContext + '.' + config.VALUE_TYPE.TRAILERREAROVERHANGTOTAL;
        addWarningBehaviourToDimension(trailerRearOverhangTotalDim, attrContext + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION, path);
        //if (offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangLegislation) > offerManager.evaluateLegislation(utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangLegislationMax)) {
        // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null && (rearOverhangLegislation === rearOverhangTotal)) {
        //     if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION).getIsNew() === true) {
        //         trailerRearOverhangTotalDim.setPulse(true);
        //     }
        //     trailerRearOverhangTotalDim.setShowAsWarning(true);
        //     trailerRearOverhangTotalDim.setDrawingGroupName(path);
        // }

        dimensions.push(trailerRearOverhangTotalDim);
        return true;
    }

    function showOverallVehicleLengthDimension(curPos, curLayer) {
        if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getOverallLength()) !== 0) {
            
            var dimensionType;
            switch (offerManager.getRigOps().getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    dimensionType = config.DIMENSION_TYPE.HORIZONTAL_TOP;
                    break;
                default:
                    dimensionType = config.DIMENSION_TYPE.HORIZONTAL_BOTTOM;
                    break;
            }

            var overallVehicleLengthDim = new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.OVERALLVEHICLELENGTH));


            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERALLVEHICLELENGTH;
            addWarningBehaviourToDimension(overallVehicleLengthDim, config.WARNING_ID.OVERALL_LENGTH_EXCEEDED, path);

            dimensions.push(overallVehicleLengthDim);
        }
    }

    function showTrailerOverallLengthDimension(curPos, curLayer, attrContext, dimensionType) {
        var trailerOverallLengthDim = new Dimension(dimensionType, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERLENGTH, attrContext));

        //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILEROVERALLLENGTH;
        var path = attrContext + '.' + config.VALUE_TYPE.TRAILERLENGTH;
        addWarningBehaviourToDimension(trailerOverallLengthDim, attrContext + '.' + config.WARNING_ID.TRAILER_LENGTH, path);
        // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_LENGTH) !== null) {
        //     if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TRAILER_LENGTH).getIsNew() === true) {
        //         trailerOverallLengthDim.setPulse(true);
        //     }
        //     trailerOverallLengthDim.setShowAsWarning(true);
        //     trailerOverallLengthDim.setDrawingGroupName(path);
        // }

        //if (offerManager.isAttributeGroupVisible(path)) {
        //    trailerOverallLengthDim.setDrawingGroupName(path);
        //    trailerOverallLengthDim.setShowAsHyperLink(true);
        //}

        dimensions.push(trailerOverallLengthDim);
        return true;
    }

    function showVehiclePayloadCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, dimensionStyle) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.COGFROMWHEELBASETHEORETICALATREAR), dimensionStyle));
        return true;
    }

    function showTrailerPayloadHorizontalCOGFromWheelbaseTheoreticalAtRear(curPos, curLayer, attrContext) {
        if (offerManager.getRigOps().getChassisObjectFromPath(attrContext).getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || offerManager.getRigOps().getBodyFromPath(attrContext) !== null) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERPAYLOADHORIZONTALCOGFROMWHEELBASETHEORETICALATREAR, attrContext), config.DIMENSION_STYLE.JUMP_LEFT));
            return true;
        }
        return false;
    }

    function showVehiclePayloadCOGFromHeadboardInsideDimension(curPos, curLayer) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.COGFROMHEADBOARDINSIDE)));
        return true;
    }

    function showVehiclePayloadCOGFromBumperToStartOfBodyInternal(curPos, curLayer) {
        dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.COGFROMBUMPERTOSTARTOFBODYINTERNAL)));
        return true;
    }

    function showTrailerPayloadHorizontalCOGFromHeadboardInsideDimension(curPos, curLayer, attrContext) {
        if (offerManager.getRigOps().getChassisObjectFromPath(attrContext).getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || offerManager.getRigOps().getBodyFromPath(attrContext) !== null) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERPAYLOADHORIZONTALCOGFROMHEADBOARDINSIDE, attrContext), config.DIMENSION_STYLE.JUMP_LEFT));
            return true
        }
        return false;
    }

    function showVehicleHeadboardThicknessDimension(curPos, curLayer) {
        if (hasBody === true && vehicle.getBody().getHeadboardThickness() > 0) {
            var bodyHeadboardThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYHEADBOARDTHICKNESS), config.DIMENSION_STYLE.JUMP_LEFT);

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEADBOARDTHICKNESS;
            if (offerManager.isAttributeGroupVisible(path)) {
                bodyHeadboardThicknessDim.setDrawingGroupName(path);
                bodyHeadboardThicknessDim.setShowAsHyperLink(true);
            }

            dimensions.push(bodyHeadboardThicknessDim);
            return true;
        }
        return false
    }

    function showVehicleBodyLengthInternalDimension(curPos, curLayer) {
        var bodyLengthInternalDim, path;
        if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getBodyLengthInternal()) !== 0) {

                bodyLengthInternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHINTERNAL));

                path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL;
                if (offerManager.isAttributeGroupVisible(path)) {
                    bodyLengthInternalDim.setDrawingGroupName(path);
                    bodyLengthInternalDim.setShowAsHyperLink(true);
                }

                dimensions.push(bodyLengthInternalDim);
                return true;
            }
        } else if (hasBody === true) {
            if (vehicle.getBody().getLength() > 0 && offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getActualInternalLength()) !== offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getActualLength())) {
                bodyLengthInternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHINTERNAL));
                if (vehicle.getBody().getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
                    path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTH;
                    if (offerManager.isAttributeGroupVisible(path)) {
                        bodyLengthInternalDim.setDrawingGroupName(path);
                        bodyLengthInternalDim.setShowAsHyperLink(true);
                    }
                }
                    

                dimensions.push(bodyLengthInternalDim);
                return true;
            }
        }
        return false;
    }

    function showVehicleTailboardThicknessDimension(curPos, curLayer) {
        if (hasBody === true && vehicle.getBody().getTailboardThickness() > 0) {

            var bodyTailboardThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYTAILBOARDTHICKNESS), config.DIMENSION_STYLE.JUMP_RIGHT);

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYTAILBOARDTHICKNESS;
            if (offerManager.isAttributeGroupVisible(path)) {
                bodyTailboardThicknessDim.setDrawingGroupName(path);
                bodyTailboardThicknessDim.setShowAsHyperLink(true);
            }

            dimensions.push(bodyTailboardThicknessDim);
            return true;
        }
        return false;
    }

    function showTrailerHeadboardThicknessDimension(curPos, curLayer, attrContext) {
        var trailerBody = offerManager.getRigOps().getBodyFromPath(attrContext);

        if (trailerBody !== null && trailerBody.getHeadboardThickness() > 0) {

            var bodyHeadboardThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYHEADBOARDTHICKNESS, attrContext), config.DIMENSION_STYLE.JUMP_LEFT);

            var path = attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYHEADBOARDTHICKNESS;
            if(offerManager.isAttributeGroupVisible(path)) {
                bodyHeadboardThicknessDim.setDrawingGroupName(path);
                bodyHeadboardThicknessDim.setShowAsHyperLink(true);
            }

            dimensions.push(bodyHeadboardThicknessDim);
            return true;
        }

        return false;
    }

    function showTrailerBodyLengthInternalDimension(curPos, curLayer, attrContext) {
        var trailerBody = offerManager.getRigOps().getBodyFromPath(attrContext);

        if (trailerBody !== null && offerManager.getCurMeasurementSysRoundedLength(trailerBody.getActualInternalLength()) > 0 && offerManager.getCurMeasurementSysRoundedLength(trailerBody.getActualInternalLength()) !== offerManager.getCurMeasurementSysRoundedLength(trailerBody.getActualLength())) {

            var bodyLengthInternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHINTERNAL, attrContext));

            //var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTHINTERNAL;
            //if(offerManager.isAttributeGroupVisible(path)) {
            //    bodyLengthInternalDim.setDrawingGroupName(path);
            //}

            dimensions.push(bodyLengthInternalDim);
            return true
        }

        return false;
    }

    function showTrailerTailboardThicknessDimension(curPos, curLayer, attrContext) {
        
        var trailerBody = offerManager.getRigOps().getBodyFromPath(attrContext);

        if (trailerBody !== null && offerManager.getCurMeasurementSysRoundedLength(trailerBody.getTailboardThickness()) > 0) {

            var bodyTailbaordThicknessDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYTAILBOARDTHICKNESS, attrContext), config.DIMENSION_STYLE.JUMP_RIGHT);

            var path = attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYTAILBOARDTHICKNESS;
            if(offerManager.isAttributeGroupVisible(path)) {
                bodyTailbaordThicknessDim.setDrawingGroupName(path);
                bodyTailbaordThicknessDim.setShowAsHyperLink(true);
            }

            dimensions.push(bodyTailbaordThicknessDim);
            return true;
        }
        return false;
    }

    function showBumperToBackOfCabDimension(curPos, curLayer, attrContext) {


        if(attrContext && attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) === -1){
            if (/*hasBody === true && */offerManager.getCurMeasurementSysRoundedLength(vehicle.getBumperToBackOfCab()) > 0) {

                setupBBCDim();
                return true;
            }
        } else {
            if (hasBody === true && offerManager.getCurMeasurementSysRoundedLength(vehicle.getBumperToBackOfCab()) > 0) {

                setupBBCDim();
                return true;
            }
        }
        return false;

    }

    function setupBBCDim() {
        var bumperToBackOfCabDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BUMPERTOBACKOFCAB));

        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.BUMPERTOBACKOFCAB;
        addWarningBehaviourToDimension(bumperToBackOfCabDim, config.WARNING_ID.VEHICLE_BUMPER_TO_BACK_OF_CAB, path);
        if (globals.user.getSpecifyCabDimensionsAs() === config.SPECIFY_CAB_DIMENSIONS_AS_OPTIONS.BBC) {
            if (offerManager.isAttributeGroupVisible(path)) {
                bumperToBackOfCabDim.setDrawingGroupName(path);
                bumperToBackOfCabDim.setShowAsHyperLink(true);
            }
        }

        dimensions.push(bumperToBackOfCabDim);
    }

    function showCabGapToBodyDimension(curPos, curLayer) {
        if (hasBody === true && offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getHorizontalGap()) !== 0 && curVehicle.getAccessoryHolder().getBody().getSubType() !== config.BODY_SUB_TYPES.BODY_ONLY) {
            var cabGapRecommendedMinDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYCABGAP)/*, config.DIMENSION_STYLE.POP_LAYER*/);

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYCABGAP;
            addWarningBehaviourToDimension(cabGapRecommendedMinDim, config.WARNING_ID.VEHICLE_BODY_CAB_GAP, path);
            if (offerManager.isAttributeGroupVisible(path)) {
                cabGapRecommendedMinDim.setDrawingGroupName(path);
                cabGapRecommendedMinDim.setShowAsHyperLink(true);
            }

            dimensions.push(cabGapRecommendedMinDim);
            //needToIncrementLayerAfterPop = true;
            return true;
            
        }
        return false;
    }

    function showHookliftGapToBodyFromHookOffsetDimension(curPos, curLayer) {
        if (hasBody === true && offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getHorizontalGap()) !== 0 && curVehicle.getAccessoryHolder().getBody().getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
            var hookliftGapToBodyFromHookOffsetDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.HOOKLIFTGAPTOBODYFROMHOOKOFFSET));

            var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYCABGAP;
            if (offerManager.isAttributeGroupVisible(path)) {
                hookliftGapToBodyFromHookOffsetDim.setDrawingGroupName(path);
                hookliftGapToBodyFromHookOffsetDim.setShowAsHyperLink(true);
            }

            dimensions.push(hookliftGapToBodyFromHookOffsetDim);
            return true;
        }
        return false;
    }

    function showVehicleBodyLengthExternalDimension(curPos, curLayer) {
        var bodyLengthDim;
        if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getBodyLengthExternalInclRearDoors()) !== 0) {
                bodyLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHEXTERNALINCLUDINGREARDOORS));

                dimensions.push(bodyLengthDim);
                return true;
            }
        } else if (hasBody === true) {
            if (offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getLength()) > 0) {
                bodyLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BODYLENGTHEXTERNALINCLUDINGREARDOORS));

                if (vehicle.getBody().getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTH;
                    if (offerManager.isAttributeGroupVisible(path)) {
                        bodyLengthDim.setDrawingGroupName(path);
                        bodyLengthDim.setShowAsHyperLink(true);
                    }
                }
                dimensions.push(bodyLengthDim);
                return true;
            }
        }
        return false;
    }

    function showBackOfVehicleBodyToRearOfVehicleDimension(curPos, curLayer) {

        

        if (hasBody === true && offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getBodyGap(vehicle.getChassisObjectName())) + offerManager.getCurMeasurementSysRoundedLength(vehicle.getBody().getActualLength()) < offerManager.getCurMeasurementSysRoundedLength(vehicle.getOverallLengthInclAccessories()) - (offerManager.getCurMeasurementSysRoundedLength(vehicle.getBumperToBackOfCab()) + offerManager.getCurMeasurementSysRoundedLength(Math.max(vehicle.getBullbarProtrusion(), vehicle.getPortionOfOverallLengthOverhangingFront(false))))) {
            var dimensionStyle;
            if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE) {
                dimensionStyle = config.DIMENSION_STYLE.JUMP_RIGHT;
            } else {
                //dimensionStyle = config.DIMENSION_STYLE.POP_LAYER;
                //needToIncrementLayerAfterPop = true;
            }
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.REAROFBODYTOREAROFVEHICLE), dimensionStyle));
            return true;
        }
        return false;
    }

    function showGapBetweenVehicleAndTrailerDimension(curPos, curLayer) {

        var gapBetweenVehicleAndTrailerDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER));

        var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.GAPBETWEENVEHICLEANDTRAILER;
        addWarningBehaviourToDimension(gapBetweenVehicleAndTrailerDim, config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER, path);

        dimensions.push(gapBetweenVehicleAndTrailerDim);

        return true;
    }

    function showTurntableToRearOfTrailerDimension(curPos, curLayer, attrContext) {

        if (offerManager.getLesserOfActiveLegislationVals(utilLegislationAttributeObjectCode.variableTypes.KingpinToRearOfTrailerUsed) === 1) {

            var turntableToRearOfTrailerDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TURNTABLETOREAROFTRAILER, attrContext)/*, config.DIMENSION_STYLE.POP_LAYER*/);

            var path = attrContext + '.' + config.VALUE_TYPE.TURNTABLETOREAROFTRAILER;
            //if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNTABLE_TO_REAR_OF_TRAILER) !== null) {
            //    if (offerManager.getWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNTABLE_TO_REAR_OF_TRAILER).getIsNew() === true) {
            //        turntableToRearOfTrailerDim.setPulse(true);
            //    }
            //    turntableToRearOfTrailerDim.setShowAsWarning(true);
            //    turntableToRearOfTrailerDim.setDrawingGroupName(path);
            //}
            addWarningBehaviourToDimension(turntableToRearOfTrailerDim, config.WARNING_ID.TURNTABLE_TO_REAR_OF_TRAILER, path);


            dimensions.push(turntableToRearOfTrailerDim);
            //needToIncrementLayerAfterPop = true;
            return true;
        }
        return false;
    }

    function showTrailerGapToStartOfBodyDimension(curPos, curLayer, attrContext) { 

        var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        var trailerBody = offerManager.getRigOps().getChassisObjectFromPath(attrContext);

        if (trailer !== null && offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getBodyGap(attrContext)) > 0) {
            var dimensionStyle;
            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                dimensionStyle = config.DIMENSION_STYLE.POP_LAYER;
                needToIncrementLayerAfterPop = true;
            }

            var cabGapToStartOfBodyDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.CABGAPTOSTARTOFBODY, attrContext));

            var path = attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYCABGAP;
            if (offerManager.isAttributeGroupVisible(path)) {
                cabGapToStartOfBodyDim.setDrawingGroupName(path);
                cabGapToStartOfBodyDim.setShowAsHyperLink(true);
            }

            dimensions.push(cabGapToStartOfBodyDim);
            
            return true;
        }
        return false;
    }

    function showTrailerBodyLengthExternalDimension(curPos, curLayer, attrContext) {

        var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        var trailerBody = offerManager.getRigOps().getBodyFromPath(attrContext);
        var isCustomTrailerWithGraphic = trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer);

        if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || trailerBody !== null || isCustomTrailerWithGraphic === true) {
            var trailerLengthExternalDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERLENGTHEXTERNAL, attrContext));

            if (trailer.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD) {
                var path = attrContext + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYLENGTH;
                if (offerManager.isAttributeGroupVisible(path)) {
                    trailerLengthExternalDim.setDrawingGroupName(path);
                    trailerLengthExternalDim.setShowAsHyperLink(true);
                }
            }

            dimensions.push(trailerLengthExternalDim);
            return true;
        }
        return false;
    }

    function showTrailerBackOfBodyToRearOfTrailerDimension(curPos, curLayer, attrContext) {

        var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        var trailerBody = offerManager.getRigOps().getBodyFromPath(attrContext);

        if (trailerBody !== null && (offerManager.getCurMeasurementSysRoundedLength(offerManager.getRigOps().getBodyGap(attrContext) + trailerBody.getActualLength()) < offerManager.getCurMeasurementSysRoundedLength(trailer.getOverallLengthExclAccessoriesAndDrawbar()))) {
            dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.BACKOFTRAILERBODYTOREAROFTRAILER, attrContext), config.DIMENSION_STYLE.JUMP_RIGHT));
            return true;
        }
        return false;
    }

    function showCombinationOverallLengthDimension(curPos, curLayer) {
        var combinationOverallLengthDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_TOP, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.COMBINATIONOVERALLLENGTH, config.CHASSIS_OBJECT_TYPES.TRAILER1));

        var path = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.COMBINATIONOVERALLLENGTH;
        addWarningBehaviourToDimension(combinationOverallLengthDim, config.WARNING_ID.OVERALL_LENGTH, path);
        // if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH) !== null) {
        //     if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH).getIsNew() === true) {
        //         combinationOverallLengthDim.setPulse(true);
        //     }
        //     combinationOverallLengthDim.setShowAsWarning(true);
        //     combinationOverallLengthDim.setDrawingGroupName(path);
        // }

        dimensions.push(combinationOverallLengthDim);
        return true;
    }

    function showCombinationOverallWidthDimension(curPos, curLayer) {
        var combinationOverallWidthDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_LEFT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.COMBINATIONOVERALLWIDTH));

        var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.COMBINATIONOVERALLWIDTH;
        addWarningBehaviourToDimension(combinationOverallWidthDim, config.WARNING_ID.OVERALL_WIDTH, path);
       

        dimensions.push(combinationOverallWidthDim);
        return true;
    }

    function showTrailerBodyInternalDimensions(curPos, curLayer, attrContext) {
        var trailerBody = offerManager.getRigOps().getBodyFromPath(attrContext);

        if (trailerBody !== null) {

            if (trailerBody.getType() === config.BODY_TYPES.SERVICE /*|| curTrailer1Body.getType() === config.BODY_TYPES.COMPACTOR || curTrailer1Body.getType() === config.BODY_TYPES.BEAVERTAIL || curTrailer1Body.getType() === config.BODY_TYPES.OTHER*/) {
                showChassisHeightRearDimension(curPos, curLayer, attrContext);

                showCombinedBodySubframeAndSubstructureDimension(curPos, curLayer, attrContext);
                needToIncrementLayerAfterPop = true;

                showBodyHeightDimension(curPos, curLayer, attrContext);
            } else {

                if (offerManager.getCurMeasurementSysRoundedLength(trailerBody.getSubstructureHeight()) > 0) {
                    showBodyStructureHeightDimension(curPos, curLayer, attrContext);
                } 

                if (offerManager.getCurMeasurementSysRoundedLength(trailerBody.getHeight()) > 0 /*&& offerManager.getCurMeasurementSysRoundedLength(trailerBody.getHeight()) === offerManager.getCurMeasurementSysRoundedLength(trailerBody.getActualExternalHeight())*/) {
                    showBodyHeightDimension(curPos, curLayer, attrContext);
                }

                if (offerManager.getCurMeasurementSysRoundedLength(trailerBody.getRoofThickness()) > 0) {
                    showBodyRoofThicknessDimension(curPos, curLayer, attrContext);
                }
            }
        }
        if (needToIncrementLayerAfterPop === true) {
            layerNumbers.incrementLayerNum(curPos);
            needToIncrementLayerAfterPop = false;
        }
    }

    function showTrailerChassisHeightDimension(curPos, curLayer, attrContext) {
        var trailerChassisHeightDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_RIGHT, getNextLayerNum(curPos, curLayer), getDimensionConfig(config.VALUE_TYPE.TRAILERCHASSISHEIGHT, attrContext));

        var path = attrContext + '.' + config.VALUE_TYPE.TRAILERCHASSISHEIGHT;

        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(attrContext);
        if (offerManager.isAttributeGroupVisible(path) && chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.STANDARD && (chassisObjectToUse.getSource() !== config.VEHICLE_SOURCE_TYPES.CUSTOM && !utils.itemHasGraphic(chassisObjectToUse))) {
            trailerChassisHeightDim.setDrawingGroupName(path);
            trailerChassisHeightDim.setShowAsHyperLink(true);
        }


        dimensions.push(trailerChassisHeightDim);
        return true;
    }

    function showTrailerChassisAndBodyExternalDimensions(curPos, curLayer, attrContext) {
        var trailerBody = offerManager.getRigOps().getBodyFromPath(attrContext);
        var trailer = offerManager.getRigOps().getChassisObjectFromPath(attrContext);

        if (offerManager.getCurMeasurementSysRoundedLength(trailer.getChassisHeight()) > 0 && (trailerBody === null || (trailerBody.getType() !== config.BODY_TYPES.SERVICE /*&& curTrailer1Body.getType() !== config.BODY_TYPES.COMPACTOR && curTrailer1Body.getType() !== config.BODY_TYPES.BEAVERTAIL && curTrailer1Body.getType() !== config.BODY_TYPES.OTHER*/))) {
            showTrailerChassisHeightDimension(curPos, curLayer, attrContext);
        }
        if (trailerBody !== null && offerManager.getCurMeasurementSysRoundedLength(trailerBody.getActualExternalHeight()) > 0) {
            showBodyHeightExternalDimension(curPos, curLayer, attrContext);
        }
    }
    //#

    //function generateCOGViewDimensions() {
    //    //if (curElevation === config.ELEVATION.SIDE) {
    //    //    generateCOGViewDimensionsSideView();
    //    //} else {
    //    //    generateCOGViewDimensionsTopView();
    //    //}
    //}

    function generateCOGViewDimensions() {

        cogDimensionFontTextHeight = isPdfDrawing ? 13 : 10;
        
        
        var cogDimensionMetaObjects = [];

        var distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, hCOG, vCOG;
        var wheelbaseTheoreticalStart = vehicle.getFrontOverhang() + vehicle.getAxle1FrontToWheelbaseTheoreticalStart();
        var wheelbaseTheoreticalEnd = wheelbaseTheoreticalStart + vehicle.getWheelbaseTheoretical();

        var trailerWheelbaseTheoreticalStart, trailerWheelbaseTheoreticalEnd, trailer2WheelbaseTheoreticalStart, trailer2WheelbaseTheoreticalEnd;
        if (curTrailer1 !== null) {
            if(curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                trailerWheelbaseTheoreticalStart = curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                
            }else {
                trailerWheelbaseTheoreticalStart = curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStart();
                
            }
            trailerWheelbaseTheoreticalEnd = trailerWheelbaseTheoreticalStart + curTrailer1.getWheelbaseTheoretical();
            
        }
        if (curTrailer2 !== null) {
            if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer2.getType() === config.TRAILER_TYPES.PUP) {
                trailer2WheelbaseTheoreticalStart = curTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();

            } else {
                trailer2WheelbaseTheoreticalStart = curTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStart();

            }
            trailer2WheelbaseTheoreticalEnd = trailer2WheelbaseTheoreticalStart + curTrailer2.getWheelbaseTheoretical();

        }

        var tempAccessoryHolder = vehicle.getAccessoryHolder();
        var trailer1TareFront, trailer1TareRear, trailer2TareFront, trailer2TareRear;

        //Vehicle COG
        var tareFront = offerManager.getValue(config.VALUE_TYPE.TAREFRONT);
        var tareRear = offerManager.getValue(config.VALUE_TYPE.TAREREAR);
        hCOG = vehicle.getFrontOverhang() + vehicle.getAxle1FrontToWheelbaseTheoreticalStart() + offerManager.getValue(config.VALUE_TYPE.HORIZONTALCOG);
        if (curElevation === config.ELEVATION.SIDE) {
            vCOG = offerManager.getValue(config.VALUE_TYPE.VERTICALCOG);
        }else {
            vCOG = offerManager.getValue(config.VALUE_TYPE.LATERALCOG);
        }
        distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
        distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));

        cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.VEHICLE_COG, tareFront + tareRear, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 11));
        if (curTrailer1 !== null) {
            //Trailer COG
            trailer1TareFront = offerManager.getRigOps().getTrailerTareFront(config.CHASSIS_OBJECT_TYPES.TRAILER1);
            trailer1TareRear = offerManager.getRigOps().getTrailerTareRear(config.CHASSIS_OBJECT_TYPES.TRAILER1);
            
            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar() + offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
            } else if(curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar() + offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
            } else {
                hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStart() + offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
            }
            
            if (curElevation === config.ELEVATION.SIDE) {
                vCOG = offerManager.getRigOps().getTrailerVerticalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
            } else {
                vCOG = offerManager.getRigOps().getTrailerLateralCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
            }
            distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1)/*curTrailer1.getDistanceFromWheelbaseTheoreticalStart(hCOG)*/);
            distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer1.getWheelbaseTheoretical() - offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1)/*curTrailer1.getDistanceFromWheelbaseTheoreticalEnd(hCOG)*/);

            cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.TRAILER_COG, trailer1TareFront + trailer1TareRear, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 11));

            if (curTrailer2 !== null) {
                //Trailer COG
                trailer2TareFront = offerManager.getRigOps().getTrailerTareFront(config.CHASSIS_OBJECT_TYPES.TRAILER2);
                trailer2TareRear = offerManager.getRigOps().getTrailerTareRear(config.CHASSIS_OBJECT_TYPES.TRAILER2);

                if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer2.getType() === config.TRAILER_TYPES.PUP) {
                    hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + curTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar() + offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
                } else {
                    hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + curTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStart() + offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
                }

                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = offerManager.getRigOps().getTrailerVerticalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
                } else {
                    vCOG = offerManager.getRigOps().getTrailerLateralCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2)/*curTrailer1.getDistanceFromWheelbaseTheoreticalStart(hCOG)*/);
                distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer2.getWheelbaseTheoretical() - offerManager.getRigOps().getTrailerHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2)/*curTrailer1.getDistanceFromWheelbaseTheoreticalEnd(hCOG)*/);

                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.TRAILER_COG, trailer2TareFront + trailer2TareRear, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER2, 11));
            }
        }

        //Fueltanks
        if(vehicle.getFuelType() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
            hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank1COG();
            if (curElevation === config.ELEVATION.SIDE) {
                vCOG = vehicle.getFuelTank1VerticalCOG();
            }else {
                vCOG = vehicle.getFuelTank1LateralCOG();
            }
            distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
            distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
            cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK1_COG, vehicle.getMassFuelTank1(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 10));
            
            if (vehicle.getFuelTank2IncludeInCalculation() === true) {
                hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank2COG();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = vehicle.getFuelTank2VerticalCOG();
                }else {
                    vCOG = vehicle.getFuelTank2LateralCOG();
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK2_COG, vehicle.getMassFuelTank2(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 9));
            }
            if (vehicle.getFuelTank3IncludeInCalculation() === true) {
                hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank3COG();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = vehicle.getFuelTank3VerticalCOG();
                }else {
                    vCOG = vehicle.getFuelTank3LateralCOG();
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK3_COG, vehicle.getMassFuelTank3(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 8));
            }
            if (vehicle.getFuelTank4IncludeInCalculation() === true) {
                hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank4COG();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = vehicle.getFuelTank4VerticalCOG();
                }else {
                    vCOG = vehicle.getFuelTank4LateralCOG();
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK4_COG, vehicle.getMassFuelTank4(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 7));
            }
            if (vehicle.getFuelTank5IncludeInCalculation() === true) {
                hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank5COG();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = vehicle.getFuelTank5VerticalCOG();
                }else {
                    vCOG = vehicle.getFuelTank5LateralCOG();
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK5_COG, vehicle.getMassFuelTank5(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 6));
            }
            if (vehicle.getFuelTank6IncludeInCalculation() === true) {
                hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank6COG();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = vehicle.getFuelTank6VerticalCOG();
                }else {
                    vCOG = vehicle.getFuelTank6LateralCOG();
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK6_COG, vehicle.getMassFuelTank6(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 5));
            }
            if (vehicle.getFuelTank7IncludeInCalculation() === true) {
                hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank7COG();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = vehicle.getFuelTank7VerticalCOG();
                }else {
                    vCOG = vehicle.getFuelTank7LateralCOG();
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK7_COG, vehicle.getMassFuelTank7(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 4));
            }
            if (vehicle.getFuelTank8IncludeInCalculation() === true) {
                hCOG = vehicle.getFrontOverhang() + vehicle.getFuelTank8COG();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = vehicle.getFuelTank8VerticalCOG();
                }else {
                    vCOG = vehicle.getFuelTank8LateralCOG();
                }
                distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FUELTANK8_COG, vehicle.getMassFuelTank8(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 3));
            }
        }
        //Crew
        hCOG = vehicle.getFrontOverhang() + vehicle.getCrewFirstRowCOG();
        if (curElevation === config.ELEVATION.SIDE) {
            vCOG = vehicle.getCrewFirstRowVerticalCOG();
        } else {
            vCOG = vehicle.getCrewFirstRowLateralCOG();
        }
        distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
        distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
        cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.CREWFIRSTROW_COG, vehicle.getMassCrewFirst(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 6));
        if (vehicle.getCrewSecondRow() === true) {
            hCOG = vehicle.getFrontOverhang() + vehicle.getCrewSecondRowCOG();
            if (curElevation === config.ELEVATION.SIDE) {
                vCOG = vehicle.getCrewSecondRowVerticalCOG();
            }else {
                vCOG = vehicle.getCrewSecondRowLateralCOG();
            }
            distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
            distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
            cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.CREWSECONDROW_COG, vehicle.getMassCrewSecond(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 5));
        }
        
        
        offerManager.getActiveOffer().getRig().getChassisObjects().forEach(function (chassisObject) {

            setupAccessoryCOGItem(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE, config.COG_ELEMENTS.CRANE_COG, 1);
            setupAccessoryCOGItem(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT, config.COG_ELEMENTS.TAILLIFT_COG, 1);
            setupAccessoryCOGItem(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE, config.COG_ELEMENTS.FRIDGE_COG, 1);
            setupAccessoryCOGItem(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT, config.COG_ELEMENTS.HOOKLIFT_COG, 1);

            if (chassisObject.getAccessoryHolder().hasAccessory(config.ACCESSORY_TYPES.OTHER)) {
                chassisObject.getAccessoryHolder().getAccessories().forEach(function (accessory) {
                    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                        setupAccessoryCOGItem(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + accessory.getId(), config.COG_ELEMENTS.OTHER_COG + '_' + accessory.getId(), accessory.getId(), 1);
                    }
                });
            }

            setupAccessoryCOGItem(chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.BODY, config.COG_ELEMENTS.BODY_COG, 12);
            setupPayloadCOGItem(chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD, config.COG_ELEMENTS.PAYLOAD_COG, 13);
        });

        if (curTrailer1 !== null && (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
            hCOG = offerManager.getRigOps().getItemStartFromFrontOfRig(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.FIFTH_WHEEL);
            
            if (curElevation === config.ELEVATION.SIDE) {
                if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                    vCOG = curVehicle.getFifthWheelHeightFromGround();
                } else {
                    vCOG = offerManager.getRigOps().getItemStartFromGround(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.FIFTH_WHEEL) + curVehicle.getAccessoryHolder().getFifthWheel().getHeight();
                }
                
            } else {
                if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                    vCOG = offerManager.getRigOps().getTopViewCentreLine();
                } else {
                    vCOG = offerManager.getRigOps().getItemStartFromLeftOfChassis(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.FIFTH_WHEEL);
                }
            }

            var massVal = offerManager.getRigOps().getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);

            if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                distanceFromWheelbaseTheoreticalStart = removeSign(curVehicle.getWheelbaseTheoretical() - curVehicle.getFifthWheelOffset());
                distanceFromWheelbaseTheoreticalEnd = removeSign(curVehicle.getFifthWheelOffset());
            } else {
                distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(curVehicle.getAccessoryHolder().getFifthWheel(), config.CHASSIS_OBJECT_TYPES.VEHICLE));
                distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(curVehicle.getAccessoryHolder().getFifthWheel(), config.CHASSIS_OBJECT_TYPES.VEHICLE));
            }
            
            cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.VEHICLE_KINGPIN_LOAD, massVal, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));


            if (curTrailer2 !== null && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                //hCOG = offerManager.getRigOps().getItemStartFromFrontOfRig(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.ACCESSORY_TYPES.FIFTH_WHEEL);
                hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStart() + curTrailer1.getWheelbaseTheoretical() - curTrailer1.getFifthWheelOffset();
                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = curTrailer1.getFifthWheelHeight();
                    //vCOG = trailer.getChassisHeight() + tempTrailerCrane.getAboveBelowChassis() + tempTrailerCrane.getActualVerticalCOG();
                } else {
                    vCOG = offerManager.getRigOps().getTopViewCentreLine();
                    //vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(trailer) + tempTrailerCrane.getFromLeftOfVehicleRearWidth() + tempTrailerCrane.getActualLateralCOG();
                }

                massVal = offerManager.getRigOps().getKingpinLoad(config.CHASSIS_OBJECT_TYPES.TRAILER1);

                distanceFromWheelbaseTheoreticalStart = removeSign(curTrailer1.getWheelbaseTheoretical() - curTrailer1.getFifthWheelOffset());
                distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer1.getFifthWheelOffset());
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.TRAILER1_KINGPIN_LOAD, massVal, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 1));
            }
        } else if (curTrailer1 !== null && (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP)) {
            hCOG = offerManager.getRigOps().getItemStartFromFrontOfRig(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.HITCH);

            if (curElevation === config.ELEVATION.SIDE) {
                vCOG = offerManager.getRigOps().getItemStartFromGround(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.HITCH) + curVehicle.getAccessoryHolder().getHitch().getHeight();

            } else {
                vCOG = offerManager.getRigOps().getItemStartFromLeftOfChassis(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.ACCESSORY_TYPES.HITCH);
            }

            massVal = offerManager.getRigOps().getHitchLoad();

            distanceFromWheelbaseTheoreticalStart = curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset();
            //distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(curVehicle.getAccessoryHolder().getHitch(), config.CHASSIS_OBJECT_TYPES.VEHICLE));
            distanceFromWheelbaseTheoreticalEnd = curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset();

            cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.VEHICLE_HITCH_LOAD, massVal, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));
        }

        
        //Equipment
        //Crane
        //var tempCrane = tempAccessoryHolder.getCrane();
        //if(tempCrane !== null) {
        //    hCOG = vehicle.getBumperToBackOfCab() + tempCrane.getHorizontalGap() + tempCrane.getActualHorizontalCOG();
        //    if (curElevation === config.ELEVATION.SIDE) {
        //        vCOG = vehicle.getChassisHeightMax() + tempCrane.getAboveBelowChassis() + tempCrane.getActualVerticalCOG();
        //    } else {
        //        vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(vehicle) + tempCrane.getFromLeftOfVehicleRearWidth() + tempCrane.getActualLateralCOG();
        //    }
            
        //    distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(tempCrane));
        //    distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(tempCrane));
        //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.CRANE_COG, tempCrane.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));
        //}
        //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE, config.COG_ELEMENTS.CRANE_COG, 1);
        //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE, config.COG_ELEMENTS.CRANE_COG, 1);
        //if (curTrailer1 !== null) {
            
        //    var tempTrailer1Crane = curTrailer1.getAccessoryHolder().getCrane();
        //    if (tempTrailer1Crane !== null) {
        //        hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempTrailer1Crane.getHorizontalGap() + tempTrailer1Crane.getActualHorizontalCOG();
        //        if (curElevation === config.ELEVATION.SIDE) {
        //            vCOG = curTrailer1.getChassisHeight() + tempTrailer1Crane.getAboveBelowChassis() + tempTrailer1Crane.getActualVerticalCOG();
        //        } else {
        //            vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(curTrailer1) + tempTrailer1Crane.getFromLeftOfVehicleRearWidth() + tempTrailer1Crane.getActualLateralCOG();
        //        }
                
        //        distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalStartForTrailer1(tempTrailer1Crane));
        //        distanceFromWheelbaseTheoreticalEnd = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalEndForTrailer1(tempTrailer1Crane));
        //        cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.CRANE_COG, tempTrailer1Crane.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 1));
        //    }

        //    //if (curTrailer2 !== null) {
        //    //    var tempTrailer2Crane = curTrailer2.getAccessoryHolder().getCrane();
        //    //    if (tempTrailer2Crane !== null) {
        //    //        hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + tempTrailer2Crane.getHorizontalGap() + tempTrailer2Crane.getActualHorizontalCOG();
        //    //        if (curElevation === config.ELEVATION.SIDE) {
        //    //            vCOG = curTrailer2.getChassisHeight() + tempTrailer2Crane.getAboveBelowChassis() + tempTrailer2Crane.getActualVerticalCOG();
        //    //        } else {
        //    //            vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(curTrailer2) + tempTrailer2Crane.getFromLeftOfVehicleRearWidth() + tempTrailer2Crane.getActualLateralCOG();
        //    //        }

        //    //        distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalStartForTrailer2(tempTrailer2Crane));
        //    //        distanceFromWheelbaseTheoreticalEnd = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalEndForTrailer2(tempTrailer2Crane));
        //    //        cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.CRANE_COG, tempTrailer2Crane.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER2, 1));
        //    //    }
        //    //}
        //}
        //setupCraneCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //setupCraneCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER2);
        
        function setupAccessoryCOGItem(path, cOGElementId, zOrder) {

            var chassisObject = offerManager.getRigOps().getChassisObjectFromPath(path);

            if (chassisObject !== null) {
                var accessory = offerManager.getRigOps().getAccessoryFromPath(path);
                if (accessory !== null) {
                    hCOG = offerManager.getRigOps().getItemStartFromFrontOfRig(path, accessory.getAccessoryType(), accessory.getId()) + accessory.getActualHorizontalCOG(); 
                    //hCOG = getTrailerFrontOverhangStart(pathRoot) + tempCrane.getHorizontalGap() + tempTrailerCrane.getActualHorizontalCOG();
                    if (curElevation === config.ELEVATION.SIDE) {
                        vCOG = offerManager.getRigOps().getItemStartFromGround(path, accessory.getAccessoryType(), accessory.getId()) + accessory.getActualVerticalCOG();
                        //vCOG = trailer.getChassisHeight() + tempTrailerCrane.getAboveBelowChassis() + tempTrailerCrane.getActualVerticalCOG();
                    } else {
                        vCOG = offerManager.getRigOps().getItemStartFromLeftOfChassis(path, accessory.getAccessoryType(), accessory.getId()) + accessory.getActualLateralCOG();
                        //vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(trailer) + tempTrailerCrane.getFromLeftOfVehicleRearWidth() + tempTrailerCrane.getActualLateralCOG();
                    }

                    var massVal = accessory.getActualMass !== undefined ? accessory.getActualMass() : accessory.getMass();

                    var distanceFromWBTStartFunc, distanceFromWBTEndFunc;
                    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                        distanceFromWBTStartFunc = getDistanceFromWheelbaseTheoreticalStartForVehicle;
                        distanceFromWBTEndFunc = getDistanceFromWheelbaseTheoreticalEndForVehicle;
                    } else {
                        distanceFromWBTStartFunc = offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalStartForTrailer;
                        distanceFromWBTEndFunc = offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalEndForTrailer;
                    }

                    distanceFromWheelbaseTheoreticalStart = removeSign(distanceFromWBTStartFunc(accessory, chassisObject.getChassisObjectName()));
                    distanceFromWheelbaseTheoreticalEnd = removeSign(distanceFromWBTEndFunc(accessory, chassisObject.getChassisObjectName()));
                    cogDimensionMetaObjects.push(new COGItem(cOGElementId, massVal, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, chassisObject.getChassisObjectName(), zOrder));
                }
            }
        }

        function setupPayloadCOGItem(path, cOGElementId, zOrder) { 
            var chassisObject = offerManager.getRigOps().getChassisObjectFromPath(path);

            // if (chassisObject !== null && (chassisObject.getAccessoryHolder().getBody() !== null || (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) || (chassisObject.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD))) {
            if (chassisObject !== null && (chassisObject.getAccessoryHolder().getBody() !== null || (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) || (chassisObject.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD)) || ((chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObject.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(chassisObject))) {

                hCOG = offerManager.getRigOps().getPayloadDeckStartPositionFrontFrontInclPortionOfVehicleOverhangingFront(chassisObject) + chassisObject.getPayloadHolder().getAveragePayloadHorizontalCOG();

                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = offerManager.getRigOps().getPayloadDeckStartPositionFromBottom(chassisObject) + chassisObject.getPayloadHolder().getAveragePayloadVerticalCOG();
                } else {
                    vCOG = offerManager.getRigOps().getPayloadDeckStartPositionFromLeftInclPortionOfChassisOverhangingLeft(chassisObject) + chassisObject.getPayloadHolder().getAveragePayloadLateralCOG();
                }

                var hCOGFromStartOfChassis = hCOG;
                var isFromFront;
                if (chassisObject.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    hCOGFromStartOfChassis = hCOG - getStartOfTrailer(path);
                } else {
                    isFromFront = true;
                }

                distanceFromWheelbaseTheoreticalStart = removeSign(chassisObject.getDistanceFromWheelbaseTheoreticalStart(hCOGFromStartOfChassis));
                distanceFromWheelbaseTheoreticalEnd = removeSign(chassisObject.getDistanceFromWheelbaseTheoreticalEnd(hCOGFromStartOfChassis, isFromFront));
                if (chassisObject.getChassisObjectName() !== config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    distanceFromWheelbaseTheoreticalEnd = Math.max(chassisObject.getDrawbarLength(), distanceFromWheelbaseTheoreticalEnd) - Math.min(chassisObject.getDrawbarLength(), distanceFromWheelbaseTheoreticalEnd);
                }
                cogDimensionMetaObjects.push(new COGItem(cOGElementId, chassisObject.getPayloadHolder().getTotalLoad(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, chassisObject.getChassisObjectName(), zOrder));

            }
        }

        //Taillift
        //var tempTaillift = tempAccessoryHolder.getTaillift();
        //if (tempTaillift !== null) {
        //    hCOG = vehicle.getBumperToBackOfCab() + tempAccessoryHolder.getBody().getHorizontalGap() + tempAccessoryHolder.getBody().getActualLength() + tempTaillift.getActualHorizontalCOG() + tempTaillift.getFromBackOfBody() - tempTaillift.getMechanismLength();
        //    if (curElevation === config.ELEVATION.SIDE) {
        //        if (tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //            vCOG = vehicle.getChassisHeightRear() + tempAccessoryHolder.getBody().getSubframeHeight() + tempAccessoryHolder.getBody().getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getActualVerticalCOG() - (tempTaillift.getMechanismHeight() - (offerManager.getOriginalOffer().getRig().getVehicle().getAccessoryHolder().getBody().getSubstructureHeight() + offerManager.getOriginalOffer().getRig().getVehicle().getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
        //        } else {
        //            vCOG = vehicle.getChassisHeightRear() + tempAccessoryHolder.getBody().getSubframeHeight() + tempAccessoryHolder.getBody().getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getActualVerticalCOG() - tempTaillift.getMechanismHeight();
        //        }
        //    } else {
        //        vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(vehicle) + tempTaillift.getFromLeftOfBodyWidthExternal() + tempTaillift.getActualLateralCOG();
        //    }

            
        //    distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(tempTaillift));
        //    distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(tempTaillift));
        //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.TAILLIFT_COG, tempTaillift.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));
        //}
        //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT, config.COG_ELEMENTS.TAILLIFT_COG, 1);
        //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT, config.COG_ELEMENTS.TAILLIFT_COG, 1);
        //if (curTrailer1 !== null) {
            
            //var tempTrailer1Taillift = curTrailer1.getAccessoryHolder().getTaillift();
            //if (tempTrailer1Taillift !== null) {
            //    var tempTrailer1Body = curTrailer1.getAccessoryHolder().getBody();
            //    hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempTrailer1Body.getHorizontalGap() + tempTrailer1Body.getActualLength() + tempTrailer1Taillift.getActualHorizontalCOG() + tempTrailer1Taillift.getFromBackOfBody() - tempTrailer1Taillift.getMechanismLength();
            //    if (curElevation === config.ELEVATION.SIDE) {
            //        if (tempTrailer1Taillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            //            vCOG = curTrailer1.getChassisHeight() + tempTrailer1Body.getSubframeHeight() + tempTrailer1Body.getSubstructureHeight() + tempTrailer1Taillift.getAboveOrBelowFloor() + tempTrailer1Taillift.getActualVerticalCOG() - (tempTrailer1Taillift.getMechanismHeight() - (offerManager.getOriginalOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getSubstructureHeight() + offerManager.getOriginalOffer().getRig().getTrailer1().getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
            //        } else {
            //            vCOG = curTrailer1.getChassisHeight() + tempTrailer1Body.getSubframeHeight() + tempTrailer1Body.getSubstructureHeight() + tempTrailer1Taillift.getAboveOrBelowFloor() + tempTrailer1Taillift.getActualVerticalCOG() - tempTrailer1Taillift.getMechanismHeight();
            //        }
            //    } else {
            //        vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer1) + tempTrailer1Taillift.getFromLeftOfBodyWidthExternal() + tempTrailer1Taillift.getActualLateralCOG();
            //    }

            //    distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalStartForTrailer1(tempTrailer1Taillift));
            //    distanceFromWheelbaseTheoreticalEnd = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalEndForTrailer1(tempTrailer1Taillift));
            //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.TAILLIFT_COG, tempTrailer1Taillift.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 1));
            //}
        //}

        //Fridge
        //var tempFridge = tempAccessoryHolder.getFridge();
        //if (tempFridge !== null) {
        //    hCOG = vehicle.getBumperToBackOfCab() + tempAccessoryHolder.getBody().getHorizontalGap() + tempFridge.getActualHorizontalCOG() - tempFridge.getProtrusion();
        //    if (curElevation === config.ELEVATION.SIDE) {
        //        vCOG = vehicle.getChassisHeightMax() + tempAccessoryHolder.getBody().getActualExternalHeight() - tempFridge.getMountingPosition() - tempFridge.getHeight() + tempFridge.getActualVerticalCOG();
        //    } else {
        //        vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(vehicle) + tempFridge.getFromLeftOfBodyWidthExternal() + tempFridge.getActualLateralCOG();
        //    }
        //    distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(tempFridge));
        //    distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(tempFridge));
        //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FRIDGE_COG, tempFridge.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));
        //}
        //if (curTrailer1 !== null) {
        //    var tempTrailer1Fridge = curTrailer1.getAccessoryHolder().getFridge();
        //    if (tempTrailer1Fridge !== null) {
        //        var tempTrailer1Body = curTrailer1.getAccessoryHolder().getBody();
        //        hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempTrailer1Body.getHorizontalGap() + tempTrailer1Fridge.getActualHorizontalCOG() - tempTrailer1Fridge.getProtrusion();
        //        if (curElevation === config.ELEVATION.SIDE) {
        //            vCOG = curTrailer1.getChassisHeight() + tempTrailer1Body.getActualExternalHeight() - tempTrailer1Fridge.getMountingPosition() - tempTrailer1Fridge.getHeight() + tempTrailer1Fridge.getActualVerticalCOG();
        //        } else {
        //            vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer1) + tempTrailer1Fridge.getFromLeftOfBodyWidthExternal() + tempTrailer1Fridge.getActualLateralCOG();
        //        }

        //        distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalStartForTrailer1(tempTrailer1Fridge));
        //        distanceFromWheelbaseTheoreticalEnd = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalEndForTrailer1(tempTrailer1Fridge));
        //        cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FRIDGE_COG, tempTrailer1Fridge.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 1));
        //    }
        //}
        //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE, config.COG_ELEMENTS.FRIDGE_COG, 1);
        //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE, config.COG_ELEMENTS.FRIDGE_COG, 1);

        //Fifth Wheel
        //var tempFifthWheel = tempAccessoryHolder.getFifthWheel();
        //if (tempFifthWheel !== null) {
        //    hCOG = (wheelbaseTheoreticalEnd - (tempFifthWheel.getOffset() + (tempFifthWheel.getLength() / 2))) + tempFifthWheel.getActualHorizontalCOG();
        //    if (curElevation === config.ELEVATION.SIDE) {
        //        vCOG = vehicle.getChassisHeightMax() + tempFifthWheel.getActualVerticalCOG();
        //    } else {
        //        vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(vehicle) + tempFifthWheel.getFromLeftOfVehicleRearWidth() + tempFifthWheel.getActualLateralCOG();
        //    }
        //    distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(tempFifthWheel));
        //    distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(tempFifthWheel));
        //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.FIFTH_WHEEL_COG, tempFifthWheel.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));
        //}
        setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL, config.COG_ELEMENTS.FIFTH_WHEEL_COG, 1);

        //Hitch
        //var tempHitch = tempAccessoryHolder.getHitch();
        //if (tempHitch !== null) {
        //    hCOG = wheelbaseTheoreticalEnd + (tempHitch.getOffset() - tempHitch.getCouplingOffset() - tempHitch.getMountingLength()) + tempHitch.getActualHorizontalCOG();
        //    if (curElevation === config.ELEVATION.SIDE) {
        //        vCOG = tempHitch.getHeightFromGround() - tempHitch.getCouplingHeight() + tempHitch.getActualVerticalCOG();
        //    } else {
        //        vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(vehicle) + tempHitch.getFromLeftOfVehicleRearWidth() + tempHitch.getActualLateralCOG();
        //    }
            
        //    distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(tempHitch));
        //    distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(tempHitch));
        //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.HITCH_COG, tempHitch.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));
        //}
        setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH, config.COG_ELEMENTS.HITCH_COG, 1);

        ////Other
        //if (tempAccessoryHolder.hasAccessory(config.ACCESSORY_TYPES.OTHER)) {
        //    tempAccessoryHolder.getAccessories().forEach(function (accessory) {
        //        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
        //            //hCOG = vehicle.getBumperToBackOfCab() + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG();
        //            //if (curElevation === config.ELEVATION.SIDE) {
        //            //    vCOG = vehicle.getChassisHeightMax() + accessory.getVerticalGap() + accessory.getActualVerticalCOG();
        //            //} else {
        //            //    vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(vehicle) + accessory.getFromLeftOfVehicleRearWidth() + accessory.getActualLateralCOG();
        //            //}
        //            //distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(accessory));
        //            //distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory));
        //            //cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.OTHER_COG + '_' + accessory.getId(), accessory.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 1));
        //            setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + accessory.getId(), config.COG_ELEMENTS.OTHER_COG + '_' + accessory.getId(), accessory.getId(), 1);
        //        }
        //    });
        //}
        //if (curTrailer1 !== null) {

        //    if (curTrailer1.getAccessoryHolder().hasAccessory(config.ACCESSORY_TYPES.OTHER)) {
        //        curTrailer1.getAccessoryHolder().getAccessories().forEach(function (accessory) {
        //            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
        //                //hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + accessory.getHorizontalGap() + accessory.getActualHorizontalCOG();
        //                //if (curElevation === config.ELEVATION.SIDE) {
        //                //    vCOG = curTrailer1.getChassisHeight() + accessory.getVerticalGap() + accessory.getActualVerticalCOG();
        //                //} else {
        //                //    vCOG = offerManager.getRigOps().getLeftOfChassisRearWidth(curTrailer1) + accessory.getFromLeftOfVehicleRearWidth() + accessory.getActualLateralCOG();
        //                //}

        //                //distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalStartForTrailer1(accessory));
        //                //distanceFromWheelbaseTheoreticalEnd = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalEndForTrailer1(accessory));
        //                //cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.OTHER_COG + '_' + accessory.getId(), accessory.getMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 1));
        //                setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + accessory.getId(), config.COG_ELEMENTS.OTHER_COG + '_' + accessory.getId(), accessory.getId(), 1);
        //            }
        //        });
        //    }

        //}

        //Body
        //if (hasBody === true) {
            //var tempBody = vehicle.getBody();
            //hCOG = vehicle.getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getActualHorizontalCOG();
            //if (curElevation === config.ELEVATION.SIDE) {
            //    if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            //        vCOG = vehicle.getChassisHeightMax() - tempBody.getBottomOfBodyToTopOfChassis() + tempBody.getActualVerticalCOG();
            //    } else {
            //        vCOG = vehicle.getChassisHeightMax() + tempBody.getActualVerticalCOG();
            //    }
                
            //} else {
            //    vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(vehicle) + tempBody.getActualLateralCOG();
            //}
            
            //distanceFromWheelbaseTheoreticalStart = removeSign(getDistanceFromWheelbaseTheoreticalStartForVehicle(tempBody));
            //distanceFromWheelbaseTheoreticalEnd = removeSign(getDistanceFromWheelbaseTheoreticalEndForVehicle(tempBody));
            //cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.BODY_COG, vehicle.getBody().getActualMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 12));

            //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY, config.COG_ELEMENTS.BODY_COG, 12);
            //setupPayloadCOGItem(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD, config.COG_ELEMENTS.PAYLOAD_COG, 13);
            //Payload COG
        //    hCOG = vehicle.getFrontOfVehicleToPayloadDeckStart() + vehicle.getPayloadHolder().getAveragePayloadHorizontalCOG();

        //    if (curElevation === config.ELEVATION.SIDE) {
        //        vCOG = vehicle.getBottomOfVehicleToPayloadDeckStart() + vehicle.getPayloadHolder().getAveragePayloadVerticalCOG();
        //    } else {
        //        vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(vehicle) + vehicle.getLeftOfVehicleToPayloadDeckStart() + vehicle.getPayloadHolder().getAveragePayloadLateralCOG();
        //    }

        //    distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
        //    distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
        //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.PAYLOAD_COG, vehicle.getPayloadHolder().getTotalLoad(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 13));
        //} else if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    //Payload COG
        //    hCOG = vehicle.getFrontOfVehicleToPayloadDeckStart() + vehicle.getPayloadHolder().getAveragePayloadHorizontalCOG();
        //    if (curElevation === config.ELEVATION.SIDE) {
        //        vCOG = vehicle.getBottomOfVehicleToPayloadDeckStart() + vehicle.getPayloadHolder().getAveragePayloadVerticalCOG();
        //    }else {
        //        vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(vehicle) + vehicle.getLeftOfVehicleToPayloadDeckStart() + vehicle.getPayloadHolder().getAveragePayloadLateralCOG();
        //    }
        //    distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
        //    distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
        //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.PAYLOAD_COG, vehicle.getPayloadHolder().getTotalLoad(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd,config.CHASSIS_OBJECT_TYPES.VEHICLE, 13));
        //}
        //if (curTrailer1 !== null) {

            //var tempTrailer1Body = curTrailer1.getAccessoryHolder().getBody();
            //if (tempTrailer1Body !== null) {
            //    hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempTrailer1Body.getHorizontalGap() + tempTrailer1Body.getActualHorizontalCOG();
            //    if (curElevation === config.ELEVATION.SIDE) {
            //        if (tempTrailer1Body.getType() === config.BODY_TYPES.SERVICE /*|| tempTrailer1Body.getType() === config.BODY_TYPES.COMPACTOR || tempTrailer1Body.getType() === config.BODY_TYPES.BEAVERTAIL || tempTrailer1Body.getType() === config.BODY_TYPES.OTHER*/) {
            //            vCOG = curTrailer1.getChassisHeight() - tempTrailer1Body.getBottomOfBodyToTopOfChassis() + tempTrailer1Body.getActualVerticalCOG();
            //        } else {
            //            vCOG = curTrailer1.getChassisHeight() + tempTrailer1Body.getActualVerticalCOG();
            //        }
            //    } else {
            //        vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer1) + tempTrailer1Body.getActualLateralCOG();
            //    }

            //    distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalStartForTrailer1(tempTrailer1Body));
            //    distanceFromWheelbaseTheoreticalEnd = removeSign(offerManager.getRigOps().getDistanceFromWheelbaseTheoreticalEndForTrailer1(tempTrailer1Body));
            //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.BODY_COG, tempTrailer1Body.getActualMass(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 12));
            //setupAccessoryCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.BODY, config.COG_ELEMENTS.BODY_COG, 12);
            //setupPayloadCOGItem(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.PAYLOAD, config.COG_ELEMENTS.PAYLOAD_COG, 13);
                ////Payload COG
                //hCOG = curTrailer1.getFrontOfTrailerToPayloadDeckStart() + curTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG();

                //if (curElevation === config.ELEVATION.SIDE) {
                //    vCOG = curTrailer1.getBottomOfTrailerToPayloadDeckStart() + curTrailer1.getPayloadHolder().getAveragePayloadVerticalCOG();
                //} else {
                //    vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer1) + curTrailer1.getLeftOfTrailerToPayloadDeckStart() + curTrailer1.getPayloadHolder().getAveragePayloadLateralCOG();
                //}

                //distanceFromWheelbaseTheoreticalStart = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalStart(hCOG));
                //distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalEnd(hCOG));
                //cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.PAYLOAD_COG, curTrailer1.getPayloadHolder().getTotalLoad(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 13));
            //} else if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            //    //Payload COG
            //    hCOG = curTrailer1.getFrontOfTrailerToPayloadDeckStart() + curTrailer1.getPayloadHolder().getAveragePayloadHorizontalCOG();

            //    if (curElevation === config.ELEVATION.SIDE) {
            //        vCOG = curTrailer1.getBottomOfTrailerToPayloadDeckStart() + curTrailer1.getPayloadHolder().getAveragePayloadVerticalCOG();
            //    } else {
            //        vCOG = offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer1) + curTrailer1.getLeftOfTrailerToPayloadDeckStart() + curTrailer1.getPayloadHolder().getAveragePayloadLateralCOG();
            //    }

            //    distanceFromWheelbaseTheoreticalStart = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalStart(hCOG));
            //    distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalEnd(hCOG));
            //    cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.PAYLOAD_COG, curTrailer1.getPayloadHolder().getTotalLoad(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 13));
            //}
            
        //}
        

        //Overall Vehicle COG
        hCOG = vehicle.getFrontOverhang() + vehicle.getAxle1FrontToWheelbaseTheoreticalStart() + offerManager.getValue(config.VALUE_TYPE.OVERALLHORIZONTALCOG); 
        if (curElevation === config.ELEVATION.SIDE) {
            vCOG = offerManager.getValue(config.VALUE_TYPE.OVERALLVERTICALCOG);
        } else {
            vCOG = offerManager.getValue(config.VALUE_TYPE.OVERALLLATERALCOG);
        }
        distanceFromWheelbaseTheoreticalStart = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalStart(hCOG));
        distanceFromWheelbaseTheoreticalEnd = removeSign(vehicle.getDistanceFromWheelbaseTheoreticalEnd(hCOG, true));
        cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.OVERALL_COG, vehicle.getGrossTotal(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.VEHICLE, 14));

        if (curTrailer1 !== null) {
            //Overall Trailer COG
            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar() + offerManager.getRigOps().getTrailer1OverallHorizontalCOG();
            } else {
                hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStart() + offerManager.getRigOps().getTrailer1OverallHorizontalCOG();
            }
            
            if (curElevation === config.ELEVATION.SIDE) {
                vCOG = offerManager.getRigOps().getTrailer1OverallVerticalCOG();
            } else {
                vCOG = offerManager.getRigOps().getTrailer1OverallLateralCOG();
            }
            //distanceFromWheelbaseTheoreticalStart = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalStart(curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar() + offerManager.getRigOps().getTrailer1OverallHorizontalCOG()));
            distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getTrailer1OverallHorizontalCOG());
            //distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalEnd(hCOG));
            distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer1.getWheelbaseTheoretical() - offerManager.getRigOps().getTrailer1OverallHorizontalCOG());
            cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.TRAILER_OVERALL_COG, curTrailer1.getGrossTotal(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER1, 14));


            if (curTrailer2 !== null) {
                //Overall Trailer COG
                if (curTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer2.getType() === config.TRAILER_TYPES.PUP) {
                    hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + curTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar() + offerManager.getRigOps().getTrailer2OverallHorizontalCOG();
                } else {
                    hCOG = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + curTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStart() + offerManager.getRigOps().getTrailer2OverallHorizontalCOG();
                }

                if (curElevation === config.ELEVATION.SIDE) {
                    vCOG = offerManager.getRigOps().getTrailer2OverallVerticalCOG();
                } else {
                    vCOG = offerManager.getRigOps().getTrailer2OverallLateralCOG();
                }
                //distanceFromWheelbaseTheoreticalStart = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalStart(curTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar() + offerManager.getRigOps().getTrailer1OverallHorizontalCOG()));
                distanceFromWheelbaseTheoreticalStart = removeSign(offerManager.getRigOps().getTrailer2OverallHorizontalCOG());
                //distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer1.getDistanceFromWheelbaseTheoreticalEnd(hCOG));
                distanceFromWheelbaseTheoreticalEnd = removeSign(curTrailer2.getWheelbaseTheoretical() - offerManager.getRigOps().getTrailer2OverallHorizontalCOG());
                cogDimensionMetaObjects.push(new COGItem(config.COG_ELEMENTS.TRAILER_OVERALL_COG, curTrailer2.getGrossTotal(), hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, config.CHASSIS_OBJECT_TYPES.TRAILER2, 14));
            }
        }

        function COGItem(id, mass, hCOG, vCOG, distanceFromWheelbaseTheoreticalStart, distanceFromWheelbaseTheoreticalEnd, parent, optionalZOrder) {
            this.id = id;
            this.mass = mass;
            this.hCOG = hCOG;
            this.vCOG = vCOG;
            this.topDimensionLayerNum = -1;
            this.leftDimensionLayerNum = -1;
            this.bottomDimensionLayerNum = -1;
            this.rightDimensionLayerNum = -1;
            this.distanceFromWheelbaseTheoreticalStart = distanceFromWheelbaseTheoreticalStart;
            this.distanceFromWheelbaseTheoreticalEnd = distanceFromWheelbaseTheoreticalEnd;
            this.horizontalReferenceX = 0;
            this.distanceFromType = 'NOT_SET';
            this.parent = parent;
            this.chassisStartOffset = 0;
            if (this.parent === config.CHASSIS_OBJECT_TYPES.TRAILER1 || this.parent === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                this.chassisStartOffset = getTrailerFrontOverhangStart(this.parent);
            }
            this.zOrder = optionalZOrder || 0;
        }

        

        cogDimensionMetaObjects = allocateVerticalAndHorizontalLayerNumbers(cogDimensionMetaObjects);

        function allocateVerticalAndHorizontalLayerNumbers(cogItems) {

            var combinationType = offerManager.getRigOps().getCombinationType();

            if (curTrailer1 === null) {
                cogItems = allocateVerticalAndHorizontalLayerNumbersVehicleOnly(cogItems);
            } else if (curTrailer1 !== null && curTrailer2 === null) {
                //cogItems = allocateVerticalAndHorizontalLayerNumbersVehicleAndTrailer1(cogItems);
                cogItems = allocateVerticalAndHorizontalLayerNumbersVehicleAndTrailer1AndTrailer2(cogItems);
            } else if (curTrailer1 !== null && curTrailer2 !== null) {
                cogItems = allocateVerticalAndHorizontalLayerNumbersVehicleAndTrailer1AndTrailer2(cogItems);
            }

            function allocateVerticalAndHorizontalLayerNumbersVehicleOnly(cogItems) {
                cogItems.sort(sortCOGItemsByWeightDescending);

                if (cogItems.length > 14) {
                    cogItems = cogItems.slice(0, 14);
                }

                cogItems.sort(sortCOGItemsByHCOGAscending);
                var numLeftLayers = Math.floor(cogItems.length / 2) + cogItems.length % 2;
                var numRightLayers = cogItems.length - numLeftLayers;

                var cogItemsLeftLayers = cogItems.slice(0, numLeftLayers);
                var cogItemsRightLayers = cogItems.slice(numLeftLayers, cogItems.length);

                //var numLeftCounter = 0, numRightCounter = 0;
                cogItemsLeftLayers.sort(sortCOGItemsByVCOGAscending);
                var i;
                for (i = 0; i < cogItemsLeftLayers.length; i++) {
                    //if (i < numLeftLayers) {
                        cogLeftDimensionLayerNum++;
                        cogItemsLeftLayers[i].leftDimensionLayerNum = cogLeftDimensionLayerNum;
                    //} else {
                    //    cogRightDimensionLayerNum++;
                    //    cogItems[i].rightDimensionLayerNum = cogRightDimensionLayerNum;
                    //}
                }

                cogItemsRightLayers.sort(sortCOGItemsByVCOGAscending);
                for (i = 0; i < cogItemsRightLayers.length; i++) {
                    //if (i < numLeftLayers) {
                    //cogLeftDimensionLayerNum++;
                    //cogItemsLeftLayers[i].leftDimensionLayerNum = cogLeftDimensionLayerNum;
                    //} else {
                        cogRightDimensionLayerNum++;
                        cogItemsRightLayers[i].rightDimensionLayerNum = cogRightDimensionLayerNum;
                    //}
                }


                cogItems.sort(sortCOGItemsByWeightDescending);

                cogItems.sort(sortCOGItemsByVCOGAscending);
                var numTopLayers = Math.floor(cogItems.length / 2) + cogItems.length % 2;
                var numBottomLayers = cogItems.length - numTopLayers;

                //var numLeftCounter = 0, numRightCounter = 0;
                var cogItemsBottomLayers = cogItems.slice(0, numBottomLayers);
                var cogItemsTopLayers = cogItems.slice(numBottomLayers, cogItems.length);

                var numWTBStart = Math.floor(cogItemsBottomLayers.length / 2) + cogItemsBottomLayers.length % 2;
                var numWTBEnd = cogItemsBottomLayers.length - numWTBStart;

                cogItemsBottomLayers.sort(sortCOGItemsByHCOGAscending);

                var cogItemsBottomLayersWBTStartGroup = cogItemsBottomLayers.slice(0, numWTBStart);
                var cogItemsBottomLayersWBTEndGroup = cogItemsBottomLayers.slice(numWTBStart, cogItemsBottomLayers.length);

                cogItemsBottomLayersWBTStartGroup.sort(sortByDistanceFromWheelbaseTheoreticalStartAscending);
                for (i = 0; i < cogItemsBottomLayersWBTStartGroup.length; i++) {
                    cogBottomLeftDimensionLayerNum++; 
                    cogItemsBottomLayersWBTStartGroup[i].bottomDimensionLayerNum = cogBottomLeftDimensionLayerNum;
                    cogItemsBottomLayersWBTStartGroup[i].horizontalReferenceX = wheelbaseTheoreticalStart;
                    cogItemsBottomLayersWBTStartGroup[i].distanceFromType = 'START';
                }

                cogItemsBottomLayersWBTEndGroup.sort(sortByDistanceFromWheelbaseTheoreticalEndAscending);
                for (i = 0; i < cogItemsBottomLayersWBTEndGroup.length; i++) {
                    cogBottomRightDimensionLayerNum++;
                    cogItemsBottomLayersWBTEndGroup[i].bottomDimensionLayerNum = cogBottomRightDimensionLayerNum;
                    cogItemsBottomLayersWBTEndGroup[i].horizontalReferenceX = wheelbaseTheoreticalEnd;
                    cogItemsBottomLayersWBTEndGroup[i].distanceFromType = 'END';
                }

                var numWBTStart = Math.floor(cogItemsTopLayers.length / 2) + cogItemsTopLayers.length % 2;
                var numWBTEnd = cogItemsTopLayers.length - numWBTStart;

                cogItemsTopLayers.sort(sortCOGItemsByHCOGAscending);

                var cogItemsTopLayersWBTStartGroup = cogItemsTopLayers.slice(0, numWBTStart);
                var cogItemsTopLayersWBTEndGroup = cogItemsTopLayers.slice(numWBTStart, cogItemsTopLayers.length);

                cogItemsTopLayersWBTStartGroup.sort(sortByDistanceFromWheelbaseTheoreticalStartAscending);
                for (i = 0; i < cogItemsTopLayersWBTStartGroup.length; i++) {
                    cogTopLeftDimensionLayerNum++;
                    cogItemsTopLayersWBTStartGroup[i].topDimensionLayerNum = cogTopLeftDimensionLayerNum;
                    cogItemsTopLayersWBTStartGroup[i].horizontalReferenceX = wheelbaseTheoreticalStart;
                    cogItemsTopLayersWBTStartGroup[i].distanceFromType = 'START';
                }

                cogItemsTopLayersWBTEndGroup.sort(sortByDistanceFromWheelbaseTheoreticalEndAscending);
                for (i = 0; i < cogItemsTopLayersWBTEndGroup.length; i++) {
                    cogTopRightDimensionLayerNum++;
                    cogItemsTopLayersWBTEndGroup[i].topDimensionLayerNum = cogTopRightDimensionLayerNum;
                    cogItemsTopLayersWBTEndGroup[i].horizontalReferenceX = wheelbaseTheoreticalEnd;
                    cogItemsTopLayersWBTEndGroup[i].distanceFromType = 'END';
                }

                cogBottomDimensionLayerNum = Math.max(cogBottomRightDimensionLayerNum, cogBottomLeftDimensionLayerNum);
                cogTopDimensionLayerNum = Math.max(cogTopRightDimensionLayerNum, cogTopLeftDimensionLayerNum);

                return cogItemsLeftLayers.concat(cogItemsRightLayers);

                function sortCOGItemsByWeightDescending(a, b) {
                    return b.mass - a.mass;
                }

                function sortCOGItemsByHCOGAscending(a, b) {
                    return a.hCOG - b.hCOG;
                }

                function sortCOGItemsByVCOGAscending(a, b) {
                    return a.vCOG - b.vCOG;
                }
                function sortByDistanceFromWheelbaseTheoreticalStartAscending(a, b) {
                    return a.distanceFromWheelbaseTheoreticalStart - b.distanceFromWheelbaseTheoreticalStart;
                }
                function sortByDistanceFromWheelbaseTheoreticalEndAscending(a, b) {
                    return a.distanceFromWheelbaseTheoreticalEnd - b.distanceFromWheelbaseTheoreticalEnd;
                }
            }

            function allocateVerticalAndHorizontalLayerNumbersVehicleAndTrailer1(cogItems) {

                var cogItemsLeftLayers = cogItems.filter(function (cogItem) {
                    return cogItem.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE;
                });
                var cogItemsRightLayers = cogItems.filter(function (cogItem) {
                    return cogItem.parent === config.CHASSIS_OBJECT_TYPES.TRAILER1;
                });


                cogItemsLeftLayers.sort(sortCOGItemsByWeightDescending);

                var unusedSlotsFromRight = 7 - cogItemsRightLayers.length;
                if (unusedSlotsFromRight < 0) {
                    unusedSlotsFromRight = 0;
                }

                if (cogItemsLeftLayers.length > 7 + unusedSlotsFromRight) {
                    cogItemsLeftLayers = cogItemsLeftLayers.slice(0, 7 + unusedSlotsFromRight);
                }

                cogItemsRightLayers.sort(sortCOGItemsByWeightDescending);

                if (cogItemsRightLayers.length > 7) {
                    cogItemsRightLayers = cogItemsRightLayers.slice(0, 7);
                }

                cogItemsLeftLayers.sort(sortCOGItemsByHCOGAscending);
                cogItemsRightLayers.sort(sortCOGItemsByHCOGAscending);
                

                

                //var numLeftCounter = 0, numRightCounter = 0;
                
                cogItemsLeftLayers.sort(sortCOGItemsByVCOGAscending);
                var i;
                for (i = 0; i < cogItemsLeftLayers.length; i++) {
                    //if (i < numLeftLayers) {
                    cogLeftDimensionLayerNum++;
                    cogItemsLeftLayers[i].leftDimensionLayerNum = cogLeftDimensionLayerNum;
                    //} else {
                    //    cogRightDimensionLayerNum++;
                    //    cogItems[i].rightDimensionLayerNum = cogRightDimensionLayerNum;
                    //}
                }

                cogItemsRightLayers.sort(sortCOGItemsByVCOGAscending);
                for (i = 0; i < cogItemsRightLayers.length; i++) {
                    //if (i < numLeftLayers) {
                    //cogLeftDimensionLayerNum++;
                    //cogItemsLeftLayers[i].leftDimensionLayerNum = cogLeftDimensionLayerNum;
                    //} else {
                    cogRightDimensionLayerNum++;
                    cogItemsRightLayers[i].rightDimensionLayerNum = cogRightDimensionLayerNum;
                    //}
                }

                //cogItems.sort(sortCOGItemsByWeightDescending);
                cogItemsLeftLayers.sort(sortCOGItemsByWeightDescending);
                cogItemsRightLayers.sort(sortCOGItemsByWeightDescending);

                //do vehicle and trailer laft and right cogs need to be handled in separate arrays or is it enough to have split them above and them carry on as normal i.e is just contrlling the left and right layer assignments the only change required and 
                //so i should reverse the stuff immediately above?

                allocateStartEndTopBottom(cogItemsLeftLayers);
                allocateStartEndTopBottom(cogItemsRightLayers);
                return cogItemsLeftLayers.concat(cogItemsRightLayers);
                function allocateStartEndTopBottom(cogItems) {

                    cogBottomRightDimensionLayerNum = 0;
                    cogBottomLeftDimensionLayerNum = 0;
                    cogTopRightDimensionLayerNum = 0;
                    cogTopLeftDimensionLayerNum = 0;

                    cogItems.sort(sortCOGItemsByVCOGAscending);
                    var numTopLayers = Math.floor(cogItems.length / 2) + cogItems.length % 2;
                    var numBottomLayers = cogItems.length - numTopLayers;

                    //var numLeftCounter = 0, numRightCounter = 0;
                    var cogItemsBottomLayers = cogItems.slice(0, numBottomLayers);
                    var cogItemsTopLayers = cogItems.slice(numBottomLayers, cogItems.length);

                    var numWTBStart = Math.floor(cogItemsBottomLayers.length / 2) + cogItemsBottomLayers.length % 2;
                    var numWTBEnd = cogItemsBottomLayers.length - numWTBStart;

                    cogItemsBottomLayers.sort(sortCOGItemsByHCOGAscending);

                    var cogItemsBottomLayersWBTStartGroup = cogItemsBottomLayers.slice(0, numWTBStart);
                    var cogItemsBottomLayersWBTEndGroup = cogItemsBottomLayers.slice(numWTBStart, cogItemsBottomLayers.length);

                    cogItemsBottomLayersWBTStartGroup.sort(sortByDistanceFromWheelbaseTheoreticalStartAscending);
                    var i;
                    for (i = 0; i < cogItemsBottomLayersWBTStartGroup.length; i++) {
                        cogBottomLeftDimensionLayerNum++;
                        cogItemsBottomLayersWBTStartGroup[i].bottomDimensionLayerNum = cogBottomLeftDimensionLayerNum;
                        if (cogItemsBottomLayersWBTStartGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            cogItemsBottomLayersWBTStartGroup[i].horizontalReferenceX = wheelbaseTheoreticalStart;
                        } else {
                            cogItemsBottomLayersWBTStartGroup[i].horizontalReferenceX = cogItemsBottomLayersWBTStartGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalStart;
                        }
                        
                        cogItemsBottomLayersWBTStartGroup[i].distanceFromType = 'START';
                    }

                    cogItemsBottomLayersWBTEndGroup.sort(sortByDistanceFromWheelbaseTheoreticalEndAscending);
                    for (i = 0; i < cogItemsBottomLayersWBTEndGroup.length; i++) {
                        cogBottomRightDimensionLayerNum++;
                        cogItemsBottomLayersWBTEndGroup[i].bottomDimensionLayerNum = cogBottomRightDimensionLayerNum;
                        if (cogItemsBottomLayersWBTEndGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            cogItemsBottomLayersWBTEndGroup[i].horizontalReferenceX = wheelbaseTheoreticalEnd;
                        } else {
                            cogItemsBottomLayersWBTEndGroup[i].horizontalReferenceX = cogItemsBottomLayersWBTEndGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalEnd;
                        }
                        cogItemsBottomLayersWBTEndGroup[i].distanceFromType = 'END';
                    }

                    var numWBTStart = Math.floor(cogItemsTopLayers.length / 2) + cogItemsTopLayers.length % 2;
                    var numWBTEnd = cogItemsTopLayers.length - numWBTStart;

                    cogItemsTopLayers.sort(sortCOGItemsByHCOGAscending);

                    var cogItemsTopLayersWBTStartGroup = cogItemsTopLayers.slice(0, numWBTStart);
                    var cogItemsTopLayersWBTEndGroup = cogItemsTopLayers.slice(numWBTStart, cogItemsTopLayers.length);

                    cogItemsTopLayersWBTStartGroup.sort(sortByDistanceFromWheelbaseTheoreticalStartAscending);
                    for (i = 0; i < cogItemsTopLayersWBTStartGroup.length; i++) {
                        cogTopLeftDimensionLayerNum++;
                        cogItemsTopLayersWBTStartGroup[i].topDimensionLayerNum = cogTopLeftDimensionLayerNum;
                        if (cogItemsTopLayersWBTStartGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            cogItemsTopLayersWBTStartGroup[i].horizontalReferenceX = wheelbaseTheoreticalStart;
                        } else {
                            cogItemsTopLayersWBTStartGroup[i].horizontalReferenceX = cogItemsTopLayersWBTStartGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalStart;
                        }
                        cogItemsTopLayersWBTStartGroup[i].distanceFromType = 'START';
                    }

                    cogItemsTopLayersWBTEndGroup.sort(sortByDistanceFromWheelbaseTheoreticalEndAscending);
                    for (i = 0; i < cogItemsTopLayersWBTEndGroup.length; i++) {
                        cogTopRightDimensionLayerNum++;
                        cogItemsTopLayersWBTEndGroup[i].topDimensionLayerNum = cogTopRightDimensionLayerNum;
                        if (cogItemsTopLayersWBTEndGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            cogItemsTopLayersWBTEndGroup[i].horizontalReferenceX = wheelbaseTheoreticalEnd;
                        } else {
                            cogItemsTopLayersWBTEndGroup[i].horizontalReferenceX = cogItemsTopLayersWBTEndGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalEnd;
                        }
                        cogItemsTopLayersWBTEndGroup[i].distanceFromType = 'END';
                    }
                }


                // cogBottomDimensionLayerNum = Math.max(cogBottomDimensionLayerNum, Math.max(cogBottomRightDimensionLayerNum, cogBottomLeftDimensionLayerNum));
                // cogTopDimensionLayerNum = Math.max(cogTopDimensionLayerNum, Math.max(cogTopRightDimensionLayerNum, cogTopLeftDimensionLayerNum));
            }

            function allocateVerticalAndHorizontalLayerNumbersVehicleAndTrailer1AndTrailer2(cogItems) {
                cogItems.sort(sortCOGItemsByWeightDescending);

                if (cogItems.length > 14) {
                    cogItems = cogItems.slice(0, 14);
                }

                cogItems.sort(sortCOGItemsByChassisTypeRightToLeft);

                //cogItems.sort(sortCOGItemsByHCOGAscending);
                var numLeftLayers = Math.floor(cogItems.length / 2) + cogItems.length % 2;
                var numRightLayers = cogItems.length - numLeftLayers;

                var cogItemsLeftLayers = cogItems.slice(0, numLeftLayers);
                var cogItemsRightLayers = cogItems.slice(numLeftLayers, cogItems.length);

                //var numLeftCounter = 0, numRightCounter = 0;
                cogItemsLeftLayers.sort(sortCOGItemsByVCOGAscending);
                var i;
                for (i = 0; i < cogItemsLeftLayers.length; i++) {
                    //if (i < numLeftLayers) {
                    cogLeftDimensionLayerNum++;
                    cogItemsLeftLayers[i].leftDimensionLayerNum = cogLeftDimensionLayerNum;
                    //} else {
                    //    cogRightDimensionLayerNum++;
                    //    cogItems[i].rightDimensionLayerNum = cogRightDimensionLayerNum;
                    //}
                }

                cogItemsRightLayers.sort(sortCOGItemsByVCOGAscending);
                for (i = 0; i < cogItemsRightLayers.length; i++) {
                    //if (i < numLeftLayers) {
                    //cogLeftDimensionLayerNum++;
                    //cogItemsLeftLayers[i].leftDimensionLayerNum = cogLeftDimensionLayerNum;
                    //} else {
                    cogRightDimensionLayerNum++;
                    cogItemsRightLayers[i].rightDimensionLayerNum = cogRightDimensionLayerNum;
                    //}
                }


                //cogItems.sort(sortCOGItemsByWeightDescending);

                var vehicleCOGItems = cogItems.filter(function (cogItem) {
                    return cogItem.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE;
                });

                var trailer1COGItems = cogItems.filter(function (cogItem) {
                    return cogItem.parent === config.CHASSIS_OBJECT_TYPES.TRAILER1;
                });

                var trailer2COGItems = cogItems.filter(function (cogItem) {
                    return cogItem.parent === config.CHASSIS_OBJECT_TYPES.TRAILER2;
                });
                vehicleCOGItems.sort(sortCOGItemsByHCOGAscending);
                allocateStartEndTopBottomForChassisGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleCOGItems);
                trailer1COGItems.sort(sortCOGItemsByHCOGAscending);
                allocateStartEndTopBottomForChassisGroup(config.CHASSIS_OBJECT_TYPES.TRAILER1, trailer1COGItems);
                trailer2COGItems.sort(sortCOGItemsByHCOGAscending);
                allocateStartEndTopBottomForChassisGroup(config.CHASSIS_OBJECT_TYPES.TRAILER2, trailer2COGItems);

                function allocateStartEndTopBottomForChassisGroup(chassisObjectType, cogItems) {
                    if (cogItems.length > 0) {
                        cogBottomRightDimensionLayerNum = 0;
                        cogBottomLeftDimensionLayerNum = 0;
                        cogTopRightDimensionLayerNum = 0;
                        cogTopLeftDimensionLayerNum = 0;

                        if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            if (wheelbaseTheoreticalEnd > trailerWheelbaseTheoreticalStart) {
                                cogTopLeftDimensionLayerNum = previousChassisTopDimensionLayerNum;
                                cogBottomLeftDimensionLayerNum = previousChassisBottomDimensionLayerNum;
                            }
                        } else if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            if (trailerWheelbaseTheoreticalEnd > trailer2WheelbaseTheoreticalStart) {
                                cogTopLeftDimensionLayerNum = previousChassisTopDimensionLayerNum;
                                cogBottomLeftDimensionLayerNum = previousChassisBottomDimensionLayerNum;
                            }
                        }

                        cogItems.sort(sortCOGItemsByVCOGAscending);
                        var numTopLayers = Math.floor(cogItems.length / 2) + cogItems.length % 2;
                        var numBottomLayers = cogItems.length - numTopLayers;

                        //var numLeftCounter = 0, numRightCounter = 0;
                        var cogItemsBottomLayers = cogItems.slice(0, numBottomLayers);
                        var cogItemsTopLayers = cogItems.slice(numBottomLayers, cogItems.length);

                        var numWTBStart = Math.floor(cogItemsBottomLayers.length / 2) + cogItemsBottomLayers.length % 2;
                        var numWTBEnd = cogItemsBottomLayers.length - numWTBStart;

                        cogItemsBottomLayers.sort(sortCOGItemsByHCOGAscending);

                        var cogItemsBottomLayersWBTStartGroup = cogItemsBottomLayers.slice(0, numWTBStart);
                        var cogItemsBottomLayersWBTEndGroup = cogItemsBottomLayers.slice(numWTBStart, cogItemsBottomLayers.length);

                        cogItemsBottomLayersWBTStartGroup.sort(sortByDistanceFromWheelbaseTheoreticalStartAscending);
                        var i;
                        for (i = 0; i < cogItemsBottomLayersWBTStartGroup.length; i++) {
                            cogBottomLeftDimensionLayerNum++;
                            cogItemsBottomLayersWBTStartGroup[i].bottomDimensionLayerNum = cogBottomLeftDimensionLayerNum;
                            if (cogItemsBottomLayersWBTStartGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                cogItemsBottomLayersWBTStartGroup[i].horizontalReferenceX = wheelbaseTheoreticalStart;
                            } else if (cogItemsBottomLayersWBTStartGroup[i].parent === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                                cogItemsBottomLayersWBTStartGroup[i].horizontalReferenceX = cogItemsBottomLayersWBTStartGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalStart;
                            } else {
                                cogItemsBottomLayersWBTStartGroup[i].horizontalReferenceX = cogItemsBottomLayersWBTStartGroup[i].chassisStartOffset + trailer2WheelbaseTheoreticalStart;
                            }
                            //cogItemsBottomLayersWBTStartGroup[i].horizontalReferenceX = wheelbaseTheoreticalStart;
                            cogItemsBottomLayersWBTStartGroup[i].distanceFromType = 'START';
                        }

                        cogItemsBottomLayersWBTEndGroup.sort(sortByDistanceFromWheelbaseTheoreticalEndAscending);
                        for (i = 0; i < cogItemsBottomLayersWBTEndGroup.length; i++) {
                            cogBottomRightDimensionLayerNum++;
                            cogItemsBottomLayersWBTEndGroup[i].bottomDimensionLayerNum = cogBottomRightDimensionLayerNum;
                            if (cogItemsBottomLayersWBTEndGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                cogItemsBottomLayersWBTEndGroup[i].horizontalReferenceX = wheelbaseTheoreticalEnd;
                            } else if (cogItemsBottomLayersWBTEndGroup[i].parent === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                                cogItemsBottomLayersWBTEndGroup[i].horizontalReferenceX = cogItemsBottomLayersWBTEndGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalEnd;
                            } else {
                                cogItemsBottomLayersWBTEndGroup[i].horizontalReferenceX = cogItemsBottomLayersWBTEndGroup[i].chassisStartOffset + trailer2WheelbaseTheoreticalEnd;
                            }
                            cogItemsBottomLayersWBTEndGroup[i].distanceFromType = 'END';
                        }

                        var numWBTStart = Math.floor(cogItemsTopLayers.length / 2) + cogItemsTopLayers.length % 2;
                        var numWBTEnd = cogItemsTopLayers.length - numWBTStart;

                        cogItemsTopLayers.sort(sortCOGItemsByHCOGAscending);

                        var cogItemsTopLayersWBTStartGroup = cogItemsTopLayers.slice(0, numWBTStart);
                        var cogItemsTopLayersWBTEndGroup = cogItemsTopLayers.slice(numWBTStart, cogItemsTopLayers.length);

                        cogItemsTopLayersWBTStartGroup.sort(sortByDistanceFromWheelbaseTheoreticalStartAscending);
                        for (i = 0; i < cogItemsTopLayersWBTStartGroup.length; i++) {
                            cogTopLeftDimensionLayerNum++;
                            cogItemsTopLayersWBTStartGroup[i].topDimensionLayerNum = cogTopLeftDimensionLayerNum;
                            if (cogItemsTopLayersWBTStartGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                cogItemsTopLayersWBTStartGroup[i].horizontalReferenceX = wheelbaseTheoreticalStart;
                            } else if (cogItemsTopLayersWBTStartGroup[i].parent === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                                cogItemsTopLayersWBTStartGroup[i].horizontalReferenceX = cogItemsTopLayersWBTStartGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalStart;
                            } else {
                                cogItemsTopLayersWBTStartGroup[i].horizontalReferenceX = cogItemsTopLayersWBTStartGroup[i].chassisStartOffset + trailer2WheelbaseTheoreticalStart;
                            }
                            cogItemsTopLayersWBTStartGroup[i].distanceFromType = 'START';
                        }

                        cogItemsTopLayersWBTEndGroup.sort(sortByDistanceFromWheelbaseTheoreticalEndAscending);
                        for (i = 0; i < cogItemsTopLayersWBTEndGroup.length; i++) {
                            cogTopRightDimensionLayerNum++;
                            cogItemsTopLayersWBTEndGroup[i].topDimensionLayerNum = cogTopRightDimensionLayerNum;
                            if (cogItemsTopLayersWBTEndGroup[i].parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                cogItemsTopLayersWBTEndGroup[i].horizontalReferenceX = wheelbaseTheoreticalEnd;
                            } else if (cogItemsTopLayersWBTEndGroup[i].parent === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                                cogItemsTopLayersWBTEndGroup[i].horizontalReferenceX = cogItemsTopLayersWBTEndGroup[i].chassisStartOffset + trailerWheelbaseTheoreticalEnd;
                            } else {
                                cogItemsTopLayersWBTEndGroup[i].horizontalReferenceX = cogItemsTopLayersWBTEndGroup[i].chassisStartOffset + trailer2WheelbaseTheoreticalEnd;
                            }
                            cogItemsTopLayersWBTEndGroup[i].distanceFromType = 'END';
                        }
                        previousChassisBottomDimensionLayerNum = cogBottomRightDimensionLayerNum;
                        previousChassisTopDimensionLayerNum = cogTopRightDimensionLayerNum;

                        cogBottomDimensionLayerNum = Math.max(cogBottomRightDimensionLayerNum, cogBottomLeftDimensionLayerNum);
                        cogTopDimensionLayerNum = Math.max(cogTopRightDimensionLayerNum, cogTopLeftDimensionLayerNum);
                    }
                }

                

                //cogItemsLeftLayers.sort(sortCOGItemsByVCOGDescending);
                //cogItemsRightLayers.sort(sortCOGItemsByVCOGAscending);

                //var mergedLayers = cogItemsLeftLayers.concat(cogItemsRightLayers);
                //mergedLayers.sort(sort)

                //return cogItemsLeftLayers.concat(cogItemsRightLayers);
                return cogItems;
            }

            return cogItems;

            function sortCOGItemsByWeightDescending(a, b) {
                return b.mass - a.mass;
            }

            function sortCOGItemsByHCOGAscending(a, b) {
                return a.hCOG - b.hCOG;
            }

            function sortCOGItemsByHCOGDescending(a, b) {
                return b.hCOG - a.hCOG;
            }

            function sortCOGItemsByVCOGAscending(a, b) {
                return a.vCOG - b.vCOG;
            }
            function sortCOGItemsByVCOGDescending(a, b) {
                return b.vCOG - a.vCOG;
            }
            function sortByDistanceFromWheelbaseTheoreticalStartAscending(a, b) {
                return a.distanceFromWheelbaseTheoreticalStart - b.distanceFromWheelbaseTheoreticalStart;
            }
            function sortByDistanceFromWheelbaseTheoreticalEndAscending(a, b) {
                return a.distanceFromWheelbaseTheoreticalEnd - b.distanceFromWheelbaseTheoreticalEnd;
            }

            function sortCOGItemsByChassisTypeRightToLeft(a, b) {
                if (a.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE && b.parent !== config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return -1;
                } else if (a.parent !== config.CHASSIS_OBJECT_TYPES.VEHICLE && b.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return 1;
                } else if (a.parent === config.CHASSIS_OBJECT_TYPES.TRAILER1 && b.parent === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    return -1;
                } else if (a.parent === config.CHASSIS_OBJECT_TYPES.TRAILER2 && b.parent !== config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    return 1;
                } else {
                    return 0;
                }
            }
        }

        
        var vehicleLengthInc = getMaxSizeRequiredForRigHorizontalAxis();
        var vehicleHeightInc = getMaxSizeRequiredForRigVerticalAxis();

        

        var hCOGOffset, vCOGOffset, horizontalReferenceX, displayLengthHorizontal, displayLengthVertical, verticalAxisOrigin;

        cogDimensionMetaObjects.forEach(function (cogDimensionMeta) {
            createCOGDimension(cogDimensionMeta);
        });

        dimensions.sort(applyZOrderPriorityToCOGElements);
        
        showVehicleWheelbaseTheoreticalDimension(POS.BOTTOM, LAYER.VEHICLE_AND_TRAILER2_WHEELBASE_THEORETICAL);
        if (curTrailer2 !== null) {
            showTrailerWheelbaseTheoreticalDimension(POS.BOTTOM, LAYER.VEHICLE_AND_TRAILER2_WHEELBASE_THEORETICAL, config.CHASSIS_OBJECT_TYPES.TRAILER2);
        }
        if (curTrailer1 !== null) {
            showTrailerWheelbaseTheoreticalDimension(POS.BOTTOM, LAYER.TRAILER1_WHEELBASE_THEORETICAL, config.CHASSIS_OBJECT_TYPES.TRAILER1);
            
        }

        function applyZOrderPriorityToCOGElements(a, b) {
            return a.zOrder - b.zOrder;
        }

        function getDistanceFromWheelbaseTheoreticalStartForVehicle(accessory) {
            
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalStartForVehicle(accessory, vehicle.getFrontOverhang(), vehicle.getBumperToBackOfCab(), 
                vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), vehicle.getWheelbaseTheoretical());
        }

        function getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory) {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory, vehicle.getFrontOverhang(), vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), 
                vehicle.getWheelbaseTheoretical(), vehicle.getBumperToBackOfCab(), tempAccessoryHolder.getBodyGap());
        }
        
        function getDisplayLengthHorizontal(cogMeta) {
            if (cogMeta.distanceFromType === 'START') {
                return offerManager.getUIFormattedDimensionValue(cogMeta.distanceFromWheelbaseTheoreticalStart);
            } else {
                return offerManager.getUIFormattedDimensionValue(cogMeta.distanceFromWheelbaseTheoreticalEnd);
            }
        }
        function createCOGDimension(cogDimensionMeta) {
            var horizontalLayerNum, verticalLayerNum, dimensionType;
            if (curElevation === config.ELEVATION.SIDE) {
                verticalAxisOrigin = 0;
            } else {
                verticalAxisOrigin = offerManager.getRigOps().getTopViewCentreLine();
            }
            
            var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(cogDimensionMeta.parent);

            if (cogDimensionMeta.topDimensionLayerNum !== -1) {
                horizontalLayerNum = cogDimensionMeta.topDimensionLayerNum;
                if (cogDimensionMeta.leftDimensionLayerNum !== -1) {
                    dimensionType = config.DIMENSION_TYPE.COG_TOP_LEFT;
                    verticalLayerNum = cogDimensionMeta.leftDimensionLayerNum;
                } else if (cogDimensionMeta.rightDimensionLayerNum !== -1) {
                    dimensionType = config.DIMENSION_TYPE.COG_TOP_RIGHT;
                    //verticalLayerNum = cogRightDimensionLayerNum - getSumLayersUpToLayerNum(cogDimensionMeta.rightDimensionLayerNum);
                    verticalLayerNum = cogDimensionMeta.rightDimensionLayerNum;
                }
            } else if (cogDimensionMeta.bottomDimensionLayerNum !== -1) {
                horizontalLayerNum = cogDimensionMeta.bottomDimensionLayerNum;
                if (cogDimensionMeta.leftDimensionLayerNum !== -1) {
                    dimensionType = config.DIMENSION_TYPE.COG_BOTTOM_LEFT;
                    verticalLayerNum = cogDimensionMeta.leftDimensionLayerNum;
                } else if (cogDimensionMeta.rightDimensionLayerNum !== -1) {
                    dimensionType = config.DIMENSION_TYPE.COG_BOTTOM_RIGHT;
                    //verticalLayerNum = cogRightDimensionLayerNum - getSumLayersUpToLayerNum(cogDimensionMeta.rightDimensionLayerNum);
                    verticalLayerNum = cogDimensionMeta.rightDimensionLayerNum;
                }
            }
            let numDieselTanks = offerManager.getActiveOffer().getRig().getVehicle().countFuelTankType(config.FUEL_TYPE_OPTIONS.DIESEL.CODE);
            let txtCode = '', tankNumber, fuelTank2, fuelTank3, fuelTank4, fuelTank5, fuelTank6, fuelTank7, fuelTank8;
            var cogLabelText;
            var portionOfOverallWidthOverhangingLeft = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft();
            var topViewExtraBuffer = 0;
            if (cogDimensionMeta.id.indexOf(config.COG_ELEMENTS.OTHER_COG) !== -1) {
                var otherId = cogDimensionMeta.id.split('_')[1];
                var other = offerManager.getRigOps().getAccessoryHolderFromPath(cogDimensionMeta.parent).getAccessoryById(Number(otherId));

                if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    hCOGOffset = dimensionXOffset + vehicle.getBumperToBackOfCab() + other.getHorizontalGap();
                } else {
                    hCOGOffset = cogDimensionMeta.chassisStartOffset + other.getHorizontalGap();
                }
                hCOG = other.getActualHorizontalCOG();

                if (curElevation === config.ELEVATION.SIDE) {
                    if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                        vCOGOffset = vehicle.getChassisHeightMax() + other.getVerticalGap();
                    } else {
                        vCOGOffset = curTrailer1.getChassisHeight() + other.getVerticalGap();
                    }
                    vCOG = other.getActualVerticalCOG();
                    topViewExtraBuffer = 0;
                }else {
                    topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                    vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + other.getFromLeftOfVehicleRearWidth()));
                    vCOG = other.getActualLateralCOG();
                }
                
                displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                
                if (isTrialUser) {
                    displayLengthVertical = config.trailUserObfuscationText;
                    cogLabelText = config.trailUserObfuscationText;
                } else {
                    displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                    cogLabelText = other.getMass();
                }

                var otherCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                    displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(other.getDescriptionForDrawing(), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer  + verticalAxisOrigin);

                otherCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + other.getId() + '.' + config.VALUE_TYPE.OTHERHORIZONTALCOG);

                //if (isTrialUser) {
                //    otherCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.OTHERVERTICALCOG + '_' + otherId));
                //}

                dimensions.push(otherCOGDim);
            } else {
                var fifthWheel, vehicleKingpinLoadCOGDim, hitch;
                switch (cogDimensionMeta.id) {
                    case config.COG_ELEMENTS.VEHICLE_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToWheelbaseTheoreticalStart();
                        hCOG = offerManager.getValue(config.VALUE_TYPE.HORIZONTALCOG);
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = offerManager.getValue(config.VALUE_TYPE.VERTICALCOG);
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfVehicleWidestWidth(chassisObjectToUse) + offerManager.getValue(config.VALUE_TYPE.LATERALCOG)));
                        }
                        
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = tareFront + tareRear;
                        }

                        var vehicleCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('659'), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        vehicleCOGDim.setDrawingGroupName(cogDimensionMeta.parent +  '.' + config.OBJECT_TYPES.AXLES_TOTAL +  '.' + config.VALUE_TYPE.HORIZONTALCOG);

                        //if (isTrialUser) {
                        //    vehicleCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.VERTICALCOG));
                        //}

                        dimensions.push(vehicleCOGDim);
                        break;
                    case config.COG_ELEMENTS.TRAILER_COG:

                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                            hCOGOffset = getTrailerFrontOverhangStart(chassisObjectToUse.getChassisObjectName()) + chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                        } else {
                            hCOGOffset = getTrailerFrontOverhangStart(chassisObjectToUse.getChassisObjectName()) + chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                        }

                        
                        hCOG = offerManager.getRigOps().getTrailerHorizontalCOG(chassisObjectToUse.getChassisObjectName());
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = offerManager.getRigOps().getTrailerVerticalCOG(chassisObjectToUse.getChassisObjectName());
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + offerManager.getRigOps().getTrailerLateralCOG(chassisObjectToUse.getChassisObjectName())));
                        }

                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);

                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                                cogLabelText = trailer1TareFront + trailer1TareRear;
                            } else {
                                cogLabelText = trailer2TareFront + trailer2TareRear;
                            }
                            
                        }

                        var trailerCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getTrailerChassisCOGLabelText(cogLabelText, cogDimensionMeta.parent), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        trailerCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.AXLES_TOTAL  + '.' + config.VALUE_TYPE.HORIZONTALCOG);

                        //if (isTrialUser) {
                        //    vehicleCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.VERTICALCOG));
                        //}

                        dimensions.push(trailerCOGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK1_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank1COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank1VerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank1LateralCOG()));//vehicle.getFuelTank1LateralCOG();
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank1();
                        }

                        //var fuelTank1COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                        //                hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                        //    displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('159') + ' 1', cogLabelText), null, null, isPdfDrawing, offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + verticalAxisOrigin);
                        var fuelTank1COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('159') + ' 1', cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        fuelTank1COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank1().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    fuelTank1COGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FUELTANKVERTICALCOG + '1'));
                        //}

                        dimensions.push(fuelTank1COGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK2_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank2COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank2VerticalCOG();
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank2LateralCOG()));//vehicle.getFuelTank2LateralCOG();
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank2();
                        }

                        fuelTank2 = vehicle.getFuelTank2();
                        
                        if(fuelTank2.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                            txtCode = '4662';
                            tankNumber = Number(fuelTank2.getNumber()) - numDieselTanks;
                        }else {
                            txtCode = '159';
                            tankNumber = fuelTank2.getNumber();
                        }

                        var fuelTank2COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText(txtCode) + ' ' + tankNumber, cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        fuelTank2COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank2().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    fuelTank2COGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FUELTANKVERTICALCOG + '2'));
                        //}

                        dimensions.push(fuelTank2COGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK3_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank3COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank3VerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank3LateralCOG()));//vehicle.getFuelTank3LateralCOG();
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);

                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank3();
                        }

                        fuelTank3 = vehicle.getFuelTank3();
                        
                        if(fuelTank3.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                            txtCode = '4662';
                            tankNumber = Number(fuelTank3.getNumber()) - numDieselTanks;
                        }else {
                            txtCode = '159';
                            tankNumber = fuelTank3.getNumber();
                        }

                        var fuelTank3COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText(txtCode) + ' ' + tankNumber, cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        fuelTank3COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank3().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        dimensions.push(fuelTank3COGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK4_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank4COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank4VerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank4LateralCOG()));
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank4();
                        }

                        fuelTank4 = vehicle.getFuelTank4();
                        
                        if(fuelTank4.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                            txtCode = '4662';
                            tankNumber = Number(fuelTank4.getNumber()) - numDieselTanks;
                        }else {
                            txtCode = '159';
                            tankNumber = fuelTank4.getNumber();
                        }

                        var fuelTank4COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText(txtCode) + ' ' + tankNumber, cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        fuelTank4COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank4().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    fuelTank4COGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FUELTANKVERTICALCOG + '4'));
                        //}

                        dimensions.push(fuelTank4COGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK5_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank5COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank5VerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank5LateralCOG()));
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank5();
                        }
                        

                        fuelTank5 = vehicle.getFuelTank5();
                        
                        if(fuelTank5.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                            txtCode = '4662';
                            tankNumber = Number(fuelTank5.getNumber()) - numDieselTanks;
                        }else {
                            txtCode = '159';
                            tankNumber = fuelTank5.getNumber();
                        }

                        var fuelTank5COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText(txtCode) + ' ' + tankNumber, cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                            fuelTank5COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank5().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        dimensions.push(fuelTank5COGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK6_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank6COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank6VerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank6LateralCOG()));
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank6();
                        }
                        
                        fuelTank6 = vehicle.getFuelTank6();
                        
                        if(fuelTank6.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                            txtCode = '4662';
                            tankNumber = Number(fuelTank6.getNumber()) - numDieselTanks;
                        }else {
                            txtCode = '159';
                            tankNumber = fuelTank6.getNumber();
                        }

                        var fuelTank6COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText(txtCode) + ' ' + tankNumber, cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                            fuelTank6COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank6().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        dimensions.push(fuelTank6COGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK7_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank7COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank7VerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank7LateralCOG()));
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank7();
                        }
                        
                        fuelTank7 = vehicle.getFuelTank7();
                        
                        if(fuelTank7.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                            txtCode = '4662';
                            tankNumber = Number(fuelTank7.getNumber()) - numDieselTanks;
                        }else {
                            txtCode = '159';
                            tankNumber = fuelTank7.getNumber();
                        }

                        var fuelTank7COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText(txtCode) + ' ' + tankNumber, cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                            fuelTank7COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank7().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        dimensions.push(fuelTank7COGDim);
                        break;
                    case config.COG_ELEMENTS.FUELTANK8_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getFuelTank8COG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getFuelTank8VerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + vehicle.getFuelTank8LateralCOG()));
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassFuelTank8();
                        }

                        fuelTank8 = vehicle.getFuelTank8();
                        
                        if(fuelTank8.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                            txtCode = '4662';
                            tankNumber = Number(fuelTank8.getNumber()) - numDieselTanks;
                        }else {
                            txtCode = '159';
                            tankNumber = fuelTank8.getNumber();
                        }
                        
                        var fuelTank8COGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText(txtCode) + ' ' + tankNumber, cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                            fuelTank8COGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + vehicle.getFuelTank8().getId() + '.' + config.VALUE_TYPE.FUELTANKHORIZONTALCOG);

                        dimensions.push(fuelTank8COGDim);
                        break;
                    case config.COG_ELEMENTS.CREWFIRSTROW_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getCrewFirstRowCOG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getCrewFirstRowVerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfVehicleCabWidth() + vehicle.getCrewFirstRowLateralCOG()));//vehicle.getCrewFirstRowLateralCOG();
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassCrewFirst();
                        }

                        var crewFirstRowCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('1243'), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        crewFirstRowCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.VALUE_TYPE.CREWFIRSTROWHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    crewFirstRowCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.CREWFIRSTROWVERTICALCOG));
                        //}

                        dimensions.push(crewFirstRowCOGDim);
                        break;
                    case config.COG_ELEMENTS.CREWSECONDROW_COG:
                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang();
                        hCOG = vehicle.getCrewSecondRowCOG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = vehicle.getCrewSecondRowVerticalCOG();
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            vCOG = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfVehicleCabWidth() + vehicle.getCrewSecondRowLateralCOG()));//vehicle.getCrewSecondRowLateralCOG();
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = vehicle.getMassCrewSecond();
                        }

                        //var crewSecondRowCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                        //                hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                        //    displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('98'), cogLabelText), null, null, isPdfDrawing, offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + verticalAxisOrigin);
                        var crewSecondRowCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('98'), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        crewSecondRowCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.VALUE_TYPE.CREWSECONDROWHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    crewSecondRowCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.CREWSECONDROWVERTICALCOG));
                        //}

                        dimensions.push(crewSecondRowCOGDim);
                        break;
                    
                    case config.COG_ELEMENTS.CRANE_COG:
                        //var crane = tempAccessoryHolder.getCrane();
                        var crane = offerManager.getRigOps().getCraneFromPath(cogDimensionMeta.parent);
                        
                        if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) { 
                            hCOGOffset = dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab() + crane.getHorizontalGap();
                        } else {
                            hCOGOffset = cogDimensionMeta.chassisStartOffset + crane.getHorizontalGap();
                        }
                        
                        hCOG = crane.getActualHorizontalCOG();
                        
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                vCOGOffset = chassisObjectToUse.getChassisHeightMax() + crane.getAboveBelowChassis();
                            } else {
                                vCOGOffset = chassisObjectToUse.getChassisHeight() + crane.getAboveBelowChassis();
                            }
                            vCOG = crane.getActualVerticalCOG();

                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            //if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            //    vCOGOffset = chassisObjectToUse.getChassisHeightMax();
                            //} else {
                            //    vCOGOffset = chassisObjectToUse.getChassisHeight();
                            //}
                            //vCOGOffset = 0;
                            vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - ( offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + crane.getFromLeftOfVehicleRearWidth()));
                            vCOG = crane.getActualLateralCOG();
                            
                        }
                        
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = crane.getMass();
                        }

                        var craneCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(crane.getDescriptionForDrawing(), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        craneCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE + '.' + config.VALUE_TYPE.CRANEHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    craneCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.CRANEVERTICALCOG));
                        //}

                        dimensions.push(craneCOGDim);
                        break;
                    case config.COG_ELEMENTS.TAILLIFT_COG:
                        //var taillift = tempAccessoryHolder.getTaillift();
                        var taillift = offerManager.getRigOps().getTailliftFromPath(cogDimensionMeta.parent);

                        if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                                hCOGOffset = dimensionXOffset + vehicle.getOverallLength() + taillift.getFromBackOfBody() - taillift.getMechanismLength();
                            }else {
                                hCOGOffset = dimensionXOffset + vehicle.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(cogDimensionMeta.parent) + tempAccessoryHolder.getBody().getActualLength() + taillift.getFromBackOfBody() - taillift.getMechanismLength();
                            }
                            
                        } else {
                            hCOGOffset = cogDimensionMeta.chassisStartOffset + offerManager.getRigOps().getBodyGap(cogDimensionMeta.parent) + chassisObjectToUse.getAccessoryHolder().getBody().getActualLength() + taillift.getFromBackOfBody() - taillift.getMechanismLength();
                        }

                        hCOG = taillift.getActualHorizontalCOG();

                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            var tempBody;
                            if (taillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                                if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                    if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                                        vCOGOffset = vehicle.getFloorHeight() + taillift.getAboveOrBelowFloor() - (taillift.getMechanismHeight() - (offerManager.getOriginalOffer().getRig().getVehicle().getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
                                    } else {
                                        tempBody = tempAccessoryHolder.getBody();
                                        vCOGOffset = vehicle.getChassisHeightRear() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - (taillift.getMechanismHeight() - (offerManager.getOriginalOffer().getRig().getVehicle().getAccessoryHolder().getBody().getSubstructureHeight() + offerManager.getOriginalOffer().getRig().getVehicle().getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
                                    }
                                    
                                } else {
                                    tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
                                    vCOGOffset = chassisObjectToUse.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - (taillift.getMechanismHeight() - (offerManager.getOriginalOffer().getRig().getTrailer1().getAccessoryHolder().getBody().getSubstructureHeight() + offerManager.getOriginalOffer().getRig().getTrailer1().getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
                                }
                            } else {
                                if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                    if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                                        vCOGOffset = vehicle.getFloorHeight() + taillift.getAboveOrBelowFloor() - taillift.getMechanismHeight();
                                    } else {
                                        tempBody = tempAccessoryHolder.getBody();
                                        vCOGOffset = vehicle.getChassisHeightRear() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - taillift.getMechanismHeight();
                                    }
                                    
                                } else {
                                    tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
                                    vCOGOffset = chassisObjectToUse.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - taillift.getMechanismHeight();
                                }
                            }
                            vCOG = taillift.getActualVerticalCOG();
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + taillift.getFromLeftOfBodyWidthExternal()));
                            vCOG = taillift.getActualLateralCOG();
                        }
                        
                        
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = taillift.getMass();
                        }

                        var tailliftCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(taillift.getDescriptionForDrawing(), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        tailliftCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT + '.' + config.VALUE_TYPE.TAILLIFTHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    tailliftCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.TAILLIFTVERTICALCOG));
                        //}

                        dimensions.push(tailliftCOGDim);
                        break;
                    case config.COG_ELEMENTS.FRIDGE_COG:
                        var fridge = offerManager.getRigOps().getFridgeFromPath(cogDimensionMeta.parent);

                        if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            hCOGOffset = dimensionXOffset + vehicle.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(cogDimensionMeta.parent) - fridge.getProtrusion() - fridge.getFromFrontOfBody();
                        } else {
                            hCOGOffset = cogDimensionMeta.chassisStartOffset + offerManager.getRigOps().getBodyGap(cogDimensionMeta.parent) - fridge.getProtrusion() - fridge.getFromFrontOfBody();
                        }

                        hCOG = fridge.getActualHorizontalCOG();

                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            
                            if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                tempBody = tempAccessoryHolder.getBody();
                                vCOGOffset = vehicle.getChassisHeightMax() + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - fridge.getMountingPosition() - fridge.getHeight();
                            } else {
                                tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
                                vCOGOffset = chassisObjectToUse.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - fridge.getMountingPosition() - fridge.getHeight();
                            }
                            vCOG = fridge.getActualVerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + fridge.getFromLeftOfBodyWidthExternal()));
                            vCOG = fridge.getActualLateralCOG();
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);

                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = fridge.getMass();
                        }
                        
                        var fridgeCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(fridge.getDescriptionForDrawing(), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        fridgeCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE + '.' + config.VALUE_TYPE.FRIDGEHORIZONTALCOG);


                        //if (isTrialUser) {
                        //    fridgeCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FRIDGEVERTICALCOG));
                        //}

                        dimensions.push(fridgeCOGDim);


                        break;
                    case config.COG_ELEMENTS.HOOKLIFT_COG:
                        //var crane = tempAccessoryHolder.getCrane();
                        var hooklift = offerManager.getRigOps().getHookliftFromPath(cogDimensionMeta.parent);

                        if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            hCOGOffset = dimensionXOffset + chassisObjectToUse.getBumperToBackOfCab() + hooklift.getHorizontalGap();
                        } else {
                            hCOGOffset = cogDimensionMeta.chassisStartOffset + hooklift.getHorizontalGap();
                        }

                        hCOG = hooklift.getActualHorizontalCOG();

                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                vCOGOffset = chassisObjectToUse.getChassisHeightMax() + hooklift.getVerticalGap();
                            } else {
                                vCOGOffset = chassisObjectToUse.getChassisHeight() + hooklift.getVerticalGap();
                            }
                            vCOG = hooklift.getActualVerticalCOG();

                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            //if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            //    vCOGOffset = chassisObjectToUse.getChassisHeightMax();
                            //} else {
                            //    vCOGOffset = chassisObjectToUse.getChassisHeight();
                            //}
                            //vCOGOffset = 0;
                            vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + hooklift.getFromLeftOfVehicleRearWidth()));
                            vCOG = hooklift.getActualLateralCOG();

                        }

                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);

                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = hooklift.getMass();
                        }

                        var hookliftCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(hooklift.getDescriptionForDrawing(), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        hookliftCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT + '.' + config.VALUE_TYPE.HOOKLIFTHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    craneCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.CRANEVERTICALCOG));
                        //}

                        dimensions.push(hookliftCOGDim);
                        break;
                    case config.COG_ELEMENTS.FIFTH_WHEEL_COG:
                        fifthWheel = tempAccessoryHolder.getFifthWheel();

                        //hCOGOffset = dimensionXOffset + wheelbaseTheoreticalEnd - (fifthWheel.getOffset() + (fifthWheel.getLength()/2));
                        hCOGOffset = dimensionXOffset + wheelbaseTheoreticalEnd - fifthWheel.getOffset() - fifthWheel.getFrontOverhang();
                        hCOG = fifthWheel.getActualHorizontalCOG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = vehicle.getChassisHeightMax();
                            vCOG = fifthWheel.getActualVerticalCOG();
                        }else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + fifthWheel.getFromLeftOfVehicleRearWidth() - fifthWheel.getWidth() / 2));
                            vCOG = fifthWheel.getActualLateralCOG();
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);


                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = fifthWheel.getMass();
                        }

                        var fifthWheelCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(fifthWheel.getDescriptionForDrawing(), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        fifthWheelCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL + '.' + config.VALUE_TYPE.FIFTHWHEELHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    fifthWheelCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FIFTHWHEELVERTICALCOG));
                        //}

                        dimensions.push(fifthWheelCOGDim);


                        break;
                    case config.COG_ELEMENTS.VEHICLE_KINGPIN_LOAD:
                        fifthWheel = tempAccessoryHolder.getFifthWheel();

                        hCOGOffset = dimensionXOffset + wheelbaseTheoreticalEnd - (offerManager.getRigOps().getFifthWheelOffset());
                        hCOG = 0;
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            if (vehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
                                vCOGOffset = vehicle.getFifthWheelHeightFromGround();
                            } else {
                                vCOGOffset = vehicle.getChassisHeightMax() + fifthWheel.getSubframeHeight() + fifthWheel.getHeight();
                            }
                            
                            vCOG = 0;
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            
                            vCOG = 0;
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);


                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = offerManager.getRigOps().getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                        }

                        vehicleKingpinLoadCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('1297'), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                            vehicleKingpinLoadCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL + '.' + config.VALUE_TYPE.VEHICLEKINGPINLOAD);

                        //if (isTrialUser) {
                        //    fifthWheelCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FIFTHWHEELVERTICALCOG));
                        //}

                        dimensions.push(vehicleKingpinLoadCOGDim);
                        break;
                    case config.COG_ELEMENTS.TRAILER1_KINGPIN_LOAD:
                        

                        hCOGOffset = cogDimensionMeta.chassisStartOffset + trailerWheelbaseTheoreticalEnd - (curTrailer1.getFifthWheelOffset());
                        hCOG = 0;
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = curTrailer1.getFifthWheelHeight();
                            vCOG = 0;
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            //vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + fifthWheel.getFromLeftOfVehicleRearWidth() - fifthWheel.getWidth() / 2));
                            vCOGOffset = 0;
                            vCOG = 0;
                        }
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);


                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = offerManager.getRigOps().getKingpinLoad(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                        }

                        vehicleKingpinLoadCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('1297'), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                            vehicleKingpinLoadCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL + '.' + config.VALUE_TYPE.VEHICLEKINGPINLOAD);

                        //if (isTrialUser) {
                        //    fifthWheelCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FIFTHWHEELVERTICALCOG));
                        //}

                        dimensions.push(vehicleKingpinLoadCOGDim);
                        break;
                    case config.COG_ELEMENTS.HITCH_COG:
                        hitch = tempAccessoryHolder.getHitch();
                        
                        hCOGOffset = dimensionXOffset + wheelbaseTheoreticalEnd + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + (hitch.getOffset() - hitch.getCouplingOffset() - hitch.getMountingLength());
                        hCOG = hitch.getActualHorizontalCOG();
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = hitch.getHeightFromGround() - hitch.getCouplingHeight();
                            vCOG = hitch.getActualVerticalCOG();
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + hitch.getFromLeftOfVehicleRearWidth() - hitch.getWidth() / 2));
                            vCOG = hitch.getActualLateralCOG();
                        }
                        
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);


                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = hitch.getMass();
                        }

                        var hitchCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                                        displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(hitch.getDescriptionForDrawing(), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        hitchCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH + '.' + config.VALUE_TYPE.HITCHHORIZONTALCOG);

                        //if (isTrialUser) {
                        //    fifthWheelCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FIFTHWHEELVERTICALCOG));
                        //}

                        dimensions.push(hitchCOGDim);


                        break;
                    case config.COG_ELEMENTS.VEHICLE_HITCH_LOAD:
                        hitch = tempAccessoryHolder.getHitch();

                        hCOGOffset = dimensionXOffset + wheelbaseTheoreticalEnd + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + (hitch.getOffset());
                        hCOG = 0;
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = hitch.getHeightFromGround();
                            vCOG = 0;
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = 0;
                            //vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + hitch.getFromLeftOfVehicleRearWidth() - hitch.getWidth() / 2));
                            vCOG = 0;
                        }

                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);


                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            cogLabelText = offerManager.getRigOps().getHitchLoad();
                        }

                        var vehicleHitchLoadCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getCOGLabelText(config.getTranslationText('1043'), cogLabelText), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        vehicleHitchLoadCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH + '.' + config.VALUE_TYPE.HITCHLOAD);

                        //if (isTrialUser) {
                        //    fifthWheelCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.FIFTHWHEELVERTICALCOG));
                        //}

                        dimensions.push(vehicleHitchLoadCOGDim);
                        break;
                    case config.COG_ELEMENTS.BODY_COG: 
                        var body = offerManager.getRigOps().getBodyFromPath(cogDimensionMeta.parent);//vehicle.getBody();

                        hCOGOffset = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(cogDimensionMeta.parent, body.getAccessoryType(), body.getId());
                        hCOG = body.getActualHorizontalCOG();


                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                if (body.getType() === config.BODY_TYPES.SERVICE /*|| body.getType() === config.BODY_TYPES.COMPACTOR || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.OTHER*/) {
                                    vCOGOffset = offerManager.getRigOps().getItemStartFromGround(cogDimensionMeta.parent, body.getAccessoryType(), body.getId());//vehicle.getChassisHeightMax() - body.getBottomOfBodyToTopOfChassis();
                                } else {
                                    vCOGOffset = offerManager.getRigOps().getItemStartFromGround(cogDimensionMeta.parent, body.getAccessoryType(), body.getId());//vehicle.getChassisHeightMax() + body.getAboveBelowChassis();
                                }
                            } else {
                                if (body.getType() === config.BODY_TYPES.SERVICE /*|| body.getType() === config.BODY_TYPES.COMPACTOR || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.OTHER*/) {
                                    vCOGOffset = offerManager.getRigOps().getItemStartFromGround(cogDimensionMeta.parent, body.getAccessoryType(), body.getId());//chassisObjectToUse.getChassisHeight() - body.getBottomOfBodyToTopOfChassis();
                                } else {
                                    vCOGOffset = offerManager.getRigOps().getItemStartFromGround(cogDimensionMeta.parent, body.getAccessoryType(), body.getId());//chassisObjectToUse.getChassisHeight() + body.getAboveBelowChassis();
                                }
                                
                            }
                            vCOG = body.getActualVerticalCOG();
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            vCOGOffset = -(body.getActualWidth() / 2);//-((offerManager.getRigOps().getTopViewCentreLine()) - (body.getActualWidth()/2));
                            vCOG = body.getActualLateralCOG();
                        }
                        
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                        }

                        var bodyCOGDIM = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                                        displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getBodyCOGLabelText(cogLabelText, cogDimensionMeta.parent), 8, config.COG_ELEMENT_STYLE.DRAW_RIGHT, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        bodyCOGDIM.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYVERTICALCOG);
                        
                        //if (isTrialUser) {
                        //    bodyCOGDIM.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.BODYVERTICALCOG));
                        //}

                        dimensions.push(bodyCOGDIM);
                        break;
                    case config.COG_ELEMENTS.PAYLOAD_COG:
                        if (chassisObjectToUse.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE || (chassisObjectToUse.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && chassisObjectToUse.getPayloadHolder().getDetailedPayloadItems().length > 0)) {

                            hCOGOffset = offerManager.getRigOps().getPayloadDeckStartPositionFrontFrontInclPortionOfVehicleOverhangingFront(chassisObjectToUse); 
                            hCOG = chassisObjectToUse.getPayloadHolder().getAveragePayloadHorizontalCOG();

                            if (curElevation === config.ELEVATION.SIDE) {
                                topViewExtraBuffer = 0;
                                if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                    vCOGOffset = offerManager.getRigOps().getPayloadDeckStartPositionFromBottom(chassisObjectToUse);//vehicle.getBottomOfVehicleToPayloadDeckStart();
                                } else {
                                    vCOGOffset = offerManager.getRigOps().getPayloadDeckStartPositionFromBottom(chassisObjectToUse);//chassisObjectToUse.getBottomOfTrailerToPayloadDeckStart();
                                }
                                vCOG = chassisObjectToUse.getPayloadHolder().getAveragePayloadVerticalCOG();
                            } else {
                                topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                                if (cogDimensionMeta.parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                                    vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + vehicle.getLeftOfVehicleToPayloadDeckStart()));
                                } else {
                                    vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + chassisObjectToUse.getLeftOfTrailerToPayloadDeckStart()));
                                }
                                
                                vCOG = chassisObjectToUse.getPayloadHolder().getAveragePayloadLateralCOG();
                            }
                            displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);

                            if (isTrialUser) {
                                displayLengthVertical = config.trailUserObfuscationText;
                                cogLabelText = config.trailUserObfuscationText;
                            } else {
                                displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                            }

                            var payloadCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                            hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                                            displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getPayloadCOGLabelText(cogLabelText, cogDimensionMeta.parent), null, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                            if (chassisObjectToUse.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
                                payloadCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + chassisObjectToUse.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS);
                            } else {
                                payloadCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + chassisObjectToUse.getPayloadHolder().getDetailedPayloadItems()[0].getId() + '.' + config.VALUE_TYPE.DETAILEDPAYLOADMASS);
                            }


                            //if (isTrialUser) {
                            //    payloadCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.SINGLEPOINTVERTICALCOG));
                            //}

                            dimensions.push(payloadCOGDim);
                        }
                        break;
                    case config.COG_ELEMENTS.OVERALL_COG:

                        /*
                            if (isTrialUser) {
        //            displayLength = config.trailUserObfuscationText;
        //        } else {
        //            displayLength = offerManager.getUIFormattedDimensionValue(length);
        //        }

        //        var ovCOGDim = new Dimension(config.DIMENSION_TYPE.VERTICAL_LEFT, leftDimensionLayerNum, config.VALUE_TYPE.OVERALLVERTICALCOG, start, length, displayLength);
        //        if (isTrialUser) {
        //            ovCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.OVERALLVERTICALCOG));
        //        }

        //        dimensions.push(ovCOGDim);
                        */


                        hCOGOffset = dimensionXOffset + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToWheelbaseTheoreticalStart();
                        hCOG = offerManager.getValue(config.VALUE_TYPE.OVERALLHORIZONTALCOG);
                        
                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = offerManager.getValue(config.VALUE_TYPE.OVERALLVERTICALCOG);
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            //vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth()));
                            
                            vCOGOffset = 0;
                            vCOG = offerManager.getValue(config.VALUE_TYPE.OVERALLLATERALCOG);
                        }
                        
                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);
                        
                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                        }
                        
                        var overallCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                                        displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getOverallCOGLabelText(cogLabelText), 10, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);

                        

                        if (isTrialUser) {
                            //overallCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.OVERALLVERTICALCOG));
                            overallCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.VALUE_TYPE.OVERALLHORIZONTALCOG);
                        }
                        dimensions.push(overallCOGDim);
                        break;
                    case config.COG_ELEMENTS.TRAILER_OVERALL_COG:
                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                            hCOGOffset = cogDimensionMeta.chassisStartOffset + chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                        } else {
                            hCOGOffset = cogDimensionMeta.chassisStartOffset + chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                        }
                        
                        hCOG = offerManager.getRigOps().getTrailerOverallHorizontalCOG(chassisObjectToUse.getChassisObjectName());

                        if (curElevation === config.ELEVATION.SIDE) {
                            topViewExtraBuffer = 0;
                            vCOGOffset = 0;
                            vCOG = offerManager.getRigOps().getTrailerOverallVerticalCOG(chassisObjectToUse.getChassisObjectName());
                        } else {
                            topViewExtraBuffer = portionOfOverallWidthOverhangingLeft;
                            //vCOGOffset = -((offerManager.getRigOps().getTopViewCentreLine()) - (offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth()));
                            
                            vCOGOffset = 0;
                            vCOG = offerManager.getRigOps().getTrailerOverallLateralCOG(chassisObjectToUse.getChassisObjectName());
                        }

                        displayLengthHorizontal = getDisplayLengthHorizontal(cogDimensionMeta);

                        if (isTrialUser) {
                            displayLengthVertical = config.trailUserObfuscationText;
                            cogLabelText = config.trailUserObfuscationText;
                        } else {
                            displayLengthVertical = offerManager.getUIFormattedDimensionValue(Math.abs(vCOGOffset + vCOG));
                        }

                        var trailerOverallCOGDim = new COGDimension(dimensionType, cogDimensionMeta.id, horizontalLayerNum, verticalLayerNum, config.DIMENSION_STYLE.STANDARD,
                                        hCOGOffset, hCOG, vCOGOffset, vCOG, dimensionXOffset + cogDimensionMeta.horizontalReferenceX, vehicleLengthInc, vehicleHeightInc,
                                        displayLengthHorizontal, displayLengthVertical, cogDimensionMeta.zOrder, getTrailerOverallCOGLabelText(cogLabelText, cogDimensionMeta.parent), 10, null, isPdfDrawing, topViewExtraBuffer + verticalAxisOrigin);



                        if (isTrialUser) {
                            //trailerOverallCOGDim.addInteraction(offerManager.getDimensionInteractionObject(config.VALUE_TYPE.OVERALLVERTICALCOG));
                            trailerOverallCOGDim.setDrawingGroupName(cogDimensionMeta.parent + '.' + config.VALUE_TYPE.OVERALLHORIZONTALCOG);
                        }
                        dimensions.push(trailerOverallCOGDim);
                        break;

                }
            }

            
        }
        if (curElevation === config.ELEVATION.TOP) {
            var topViewCentreLine = offerManager.getRigOps().getTopViewCentreLine();
            var lastLabelStyle;
            dimensions.forEach(function (dimension) {
                if (dimension.type.indexOf('cog') !== -1) {
                    //if (vCOGOffset + vCOG > topViewCentreLine) {
                    //    dimension.setLabelStyle(config.COG_ELEMENT_STYLE.DRAW_TOP);
                    //    lastLabelStyle = config.COG_ELEMENT_STYLE.DRAW_TOP;
                    //} else if (vCOGOffset + vCOG < topViewCentreLine) {
                    //    dimension.setLabelStyle(config.COG_ELEMENT_STYLE.DRAW_BOTTOM);
                    //    lastLabelStyle = config.COG_ELEMENT_STYLE.DRAW_BOTTOM;
                    //} else {
                    //    if (lastLabelStyle === undefined) {
                    //        dimension.setLabelStyle(config.COG_ELEMENT_STYLE.DRAW_TOP);
                    //        lastLabelStyle = config.COG_ELEMENT_STYLE.DRAW_TOP;
                    //    } else if (lastLabelStyle === config.COG_ELEMENT_STYLE.DRAW_TOP) {
                    //        dimension.setLabelStyle(config.COG_ELEMENT_STYLE.DRAW_BOTTOM);
                    //        lastLabelStyle = config.COG_ELEMENT_STYLE.DRAW_BOTTOM;
                    //    } else {
                    //        dimension.setLabelStyle(config.COG_ELEMENT_STYLE.DRAW_TOP);
                    //        lastLabelStyle = config.COG_ELEMENT_STYLE.DRAW_TOP;
                    //    }
                    //}
                    if (dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT || dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT) {
                        dimension.setLabelStyle(config.COG_ELEMENT_STYLE.DRAW_TOP);
                        lastLabelStyle = config.COG_ELEMENT_STYLE.DRAW_TOP;
                    } else {
                        dimension.setLabelStyle(config.COG_ELEMENT_STYLE.DRAW_BOTTOM);
                        lastLabelStyle = config.COG_ELEMENT_STYLE.DRAW_BOTTOM;
                    }
                }
            });
        }
        //if (hasBody === true) {
            
        //}
        
    }
    //dimensions.sort(function (a, b) {
    //    if (interactionState[a.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[a.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
    //        return 1;
    //    } else if (interactionState[b.drawingGroupName + '_' + config.INTERACTION_EVENT.MOUSE_OVER] !== undefined && interactionState[b.drawingGroupName + '_' + 'ACTIVE'] === undefined) {
    //        return 1;
    //    }
    //    return 0;
    //});
    return dimensions.filter(function (dimension) {
        if (dimension.getHideDimension) {
            return dimension.getHideDimension() === false;
        } else {
            return true;
        }
    });
}



function getCOGLabelText(descriptionText, valueText) {
    var textBetweenBrackets = valueText === config.trailUserObfuscationText ? valueText : massFormattingFuncNoDecimalPlaces(valueText);
    return descriptionText + ' (' + textBetweenBrackets + ')';
}

function removeSign(val) {

    if (isMinusValue(val)) {
        return -val;
    } else {
        return val;
    }

    function isMinusValue(valToCheck) {
        if (typeof valToCheck === 'string') {
            if (valToCheck.indexOf('-') !== -1) {
                return true;
            }
        } else {
            if (valToCheck < 0) {
                return true;
            }
        }
        return false;
    }
}

function getPayloadCOGLabelText(replacementTextForTrialUser, attrContext) {

    var tempPayloadHolder;
    if (attrContext !== undefined) {
        tempPayloadHolder = offerManager.getRigOps().getPayloadHolderFromPath(attrContext);
    } else {
        tempPayloadHolder = curVehicle.getPayloadHolder();
    }

    var textBetweenBrackets = replacementTextForTrialUser !== undefined ? replacementTextForTrialUser : massFormattingFuncNoDecimalPlaces(tempPayloadHolder.getTotalMass());
    //return config.getTranslationText('2869') + ' (' + textBetweenBrackets + ')';
    return config.getTranslationText('2869', [textBetweenBrackets]);
}

function getOverallCOGLabelText(replacementTextForTrialUser) {
    var textBetweenBrackets = replacementTextForTrialUser !== undefined ? replacementTextForTrialUser : massFormattingFuncNoDecimalPlaces(curVehicle.getGrossTotal() + offerManager.getRigOps().getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE));
    var textBeforeBrackets;
    if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE) {
        //textBeforeBrackets = config.getTranslationText('285');
        return config.getTranslationText('3063', [textBetweenBrackets]);
    } else {
        //textBeforeBrackets = config.getTranslationText('1027');
        return config.getTranslationText('1027', [textBetweenBrackets]);
    }
    //return textBeforeBrackets + ' (' + textBetweenBrackets + ')';
} 

function getTrailerOverallCOGLabelText(replacementTextForTrialUser, path) {
    var trailerToUse = offerManager.getRigOps().getChassisObjectFromPath(path);

    var textBetweenBrackets = replacementTextForTrialUser !== undefined ? replacementTextForTrialUser : massFormattingFuncNoDecimalPlaces(trailerToUse.getGrossTotal() + offerManager.getRigOps().getKingpinLoad(trailerToUse.getChassisObjectName()));
                
    var trailerPrefixText;
    if (trailerToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
        if (curTrailer2 === null) {
            trailerPrefixText = config.getTranslationText('1196');
        } else {
            trailerPrefixText = config.getTranslationText('1298');
        }
    } else {
        trailerPrefixText = config.getTranslationText('1300');
    }

    return trailerPrefixText + ' (' + textBetweenBrackets + ')';
}

function getTrailerChassisCOGLabelText(cogLabelText, path) {
    var trailerToUse = offerManager.getRigOps().getChassisObjectFromPath(path);

    var textBetweenBrackets = cogLabelText === config.trailUserObfuscationText ? cogLabelText : massFormattingFuncNoDecimalPlaces(cogLabelText);

    var trailerPrefixText;
    if (trailerToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
        if (curTrailer2 === null) {
            trailerPrefixText = config.getTranslationText('1197');
        } else {
            trailerPrefixText = config.getTranslationText('1299');
        }
    } else {
        trailerPrefixText = config.getTranslationText('1301');
    }

    return trailerPrefixText + ' (' + textBetweenBrackets + ')';
}

function getBodyCOGLabelText(replacementTextForTrialUser, attrContext) {
    var tempBody;
    if (attrContext !== undefined) {
        tempBody = offerManager.getRigOps().getBodyFromPath(attrContext);
    } else {
        tempBody = curVehicle.getBody();
    }
    
    var textBetweenBrackets = replacementTextForTrialUser !== undefined ? replacementTextForTrialUser : massFormattingFuncNoDecimalPlaces(tempBody.getActualMass());
    //return config.getTranslationText('39') + ' (' + textBetweenBrackets + ')';
    return config.getTranslationText('3064', [textBetweenBrackets]);
}

function generateComplianceDashboard() {

    if (globals.user.getShowComplianceScorecard() === false) {
        return null;
    }

    if (!complianceDashboard) {
        complianceDashboard = new ComplianceDashboard();
    } else {
        complianceDashboard.clearContents();
    }
    
    
    //tableFontSize = config.configurationTableFontSize;
    //dimensionFontSize = config.configurationDimensionFontSize;
    //labelFontSize = config.configurationLabelFontSize;
    //axleLetterFontSize = config.configurationAxleLetterFontSize;
    //notesAndWarningsFontSize = config.configurationNotesAndWarningsFontSize;

    var textStyle = labelFontSize + 'px ' + curFontName; 
    complianceDashboard.setWidthOfTextCallback(function (text, isBold) {
        if (isBold) {
            return getWidthOfText(text, curFontName, dashboardAndTableTitleFontSize, isBold);
        } else {
            return getWidthOfText(text, curFontName, tableFontSize, isBold);
        }
        
    });
    complianceDashboard.setDefaultTextColour(config.DRAWING_COLOURS.BLACK);
    complianceDashboard.setDefaultTextStyle(textStyle);
    complianceDashboard.setYOffset(0);
    complianceDashboard.setIconSize(22);
    complianceDashboard.setItemRowHeight(getCellHeight(getCellWidth()));
    //complianceDashboard.setBackgroundColour(config.DRAWING_COLOURS.TABLE_CELL);
    complianceDashboard.setBackgroundColour(config.DRAWING_COLOURS.DASHBOARD_CONTENT_BACKGROUND);
    complianceDashboard.setBackgroundBorderColour(config.DRAWING_COLOURS.COLUMN_TITLE);
    complianceDashboard.setBackgroundBuffer(10);
    
    var delimiterStr = '»';
    var navBarRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.NAVIGATION);
    

    var activePathParts = complianceDashboard.getActiveViewPath().split('.');
    var lastPathPart = activePathParts[activePathParts.length - 1];
    var drillDownLevel = lastPathPart === config.COMPLIANCE_DASHBOARD_VIEWS.MAIN ? null : lastPathPart;
    var drilldownLevelParts, legislationInstanceencodedId;
    var navBarTitleDrillDownTextItem, navDashboardTitleCrumbDelimiter, navBarTitleDrillDownTextItem2, navDashboardTitleCrumbDelimiter2;
    if (drillDownLevel && drillDownLevel === config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE) {
        navDashboardTitleCrumbDelimiter = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_DELIMITER + '.' + drillDownLevel).setText('   ' + delimiterStr).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE); 
        //navDashboardTitleCrumbDelimiter = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_DELIMITER + '.' + drillDownLevel).setIcon(config.FONT_AWESOME_UNICODE_ICONS.CHEVRON_RIGHT).setIconColour(config.DRAWING_COLOURS.COLUMN_TITLE); 
        navBarTitleDrillDownTextItem = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_TEXT + '.' + drillDownLevel).setText(config.getTranslationText('4287')).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE)/*.setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, drillDownLevel), addGraphicsManagerToInteractionCallbackChain)*/; 
    } else if (drillDownLevel && drillDownLevel.indexOf(config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE_INSTANCE) !== -1) {
        drilldownLevelParts = drillDownLevel.split('_');
        legislationInstanceencodedId = drilldownLevelParts[drilldownLevelParts.length - 1];
        var drilledDownLegislation = offerManager.getLegislations().filter(function (legislationDetail) {
            return legislationDetail.id === Number(legislationInstanceencodedId);
        })[0];
        navDashboardTitleCrumbDelimiter = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_DELIMITER + '.' + config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE).setText('  ' + delimiterStr).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE);
        navBarTitleDrillDownTextItem = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_TEXT + '.' + config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE).setText('...').setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE), addGraphicsManagerToInteractionCallbackChain);
        navDashboardTitleCrumbDelimiter2 = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_DELIMITER + '.' + drillDownLevel).setText('' + delimiterStr).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE);
        navBarTitleDrillDownTextItem2 = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_TEXT + '.' + drillDownLevel).setText(drilledDownLegislation.description).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE)/*.setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, drillDownLevel), addGraphicsManagerToInteractionCallbackChain)*/; 
    } else if (drillDownLevel && drillDownLevel === config.COMPLIANCE_DASHBOARD_VIEWS.INTERNAL_STANDARDS) {
        navDashboardTitleCrumbDelimiter = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_DELIMITER + '.' + drillDownLevel).setText('  ' + delimiterStr).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE);
        navBarTitleDrillDownTextItem = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_TEXT + '.' + drillDownLevel).setText(config.getTranslationText('4308')).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE)/*.setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, drillDownLevel), addGraphicsManagerToInteractionCallbackChain)*/;
    } else if (drillDownLevel && drillDownLevel === config.COMPLIANCE_DASHBOARD_VIEWS.MANUFACTURER_LIMITS) {
        navDashboardTitleCrumbDelimiter = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_DELIMITER + '.' + drillDownLevel).setText('  ' + delimiterStr).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE);
        navBarTitleDrillDownTextItem = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_TEXT + '.' + drillDownLevel).setText(config.getTranslationText('4686')).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE)/*.setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, drillDownLevel), addGraphicsManagerToInteractionCallbackChain)*/;
    }
    var navBarTitleTextItem = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.NAVIGATION_TITLE_TEXT).setText(config.getTranslationText('4302')).setIsHeading().setTextStyle('bold ' + dashboardAndTableTitleFontSize + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE);
    var navBarSettingsIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.NAVIGATION_SETTINGS_ICON).setIcon('\uf013').setIconColour(config.DRAWING_COLOURS.COLUMN_TITLE).setCustomClickableColour(config.DRAWING_COLOURS.COLUMN_TITLE).setIconSize(18).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_SETTINGS, config.COMPLIANCE_IDS.NAVIGATION_SETTINGS_ICON), addGraphicsManagerToInteractionCallbackChain); 
    //var navBarBackIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.NAVIGATION + '_' + globals.nextId()).setIcon('\uf053').setIconColour(config.DRAWING_COLOURS.COLUMN_TITLE).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD, config.COMPLIANCE_IDS.LEGISLATION), addGraphicsManagerToInteractionCallbackChain); 
    
    navBarRow.addItem(navBarTitleTextItem);
    if (navBarTitleDrillDownTextItem) {
        navBarRow.addItem(navDashboardTitleCrumbDelimiter);
        navBarRow.addItem(navBarTitleDrillDownTextItem);
        navBarRow.setTextPositionMethod(config.COMPLIANCE_ROW_TEXT_POSITION_METHOD.SEQUENTIAL_FROM_LEFT);
        navBarTitleTextItem.setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_CRUMB, config.COMPLIANCE_DASHBOARD_VIEWS.MAIN), addGraphicsManagerToInteractionCallbackChain);
        if (navBarTitleDrillDownTextItem2) {
            navBarRow.addItem(navDashboardTitleCrumbDelimiter2);
            navBarRow.addItem(navBarTitleDrillDownTextItem2);
        }
    }
    navBarRow.addItem(navBarSettingsIcon);
    //navBarRow.addItem(navBarBackIcon);
    complianceDashboard.addRow(navBarRow);

    var navSpacerRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.NAVIGATION_SPACER);
    var navSpacer = new ComplianceDashboardSpacer().setSpacerType(config.SPACER_TYPE.CUSTOM_VERTICAL).setHeight(2);
    navSpacerRow.addItem(navSpacer);
    complianceDashboard.addRow(navSpacerRow);
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setId(config.COMPLIANCE_IDS.APPLICATION).setText(offerManager.getActiveOffer().getApplication().description).setIsSubheading().setTextStyle(config.configurationTableFontSize - 1 + 'px ' + curFontName).setTextColour(config.DRAWING_COLOURS.COLUMN_TITLE).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD, config.COMPLIANCE_IDS.APPLICATION)));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.LARGE));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.LINE));
    ////complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.LARGE));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.LARGE));
    //complianceDashboard.addItem(new ComplianceDashboardItem().setSpacerType(config.SPACER_TYPE.SMALL));
    /*
        AXLES_OVERLOADED: 'AXLES_OVERLOADED',
    PERMISSIBLE_TOTAL_OVERLOADED: 'PERMISSIBLE_TOTAL_OVERLOADED',
    STEER_AXLE_MINIMUM: 'STEER_AXLE_MINIMUM',
    BRIDGE_FORMULA: 'BRIDGE_FORMULA',
    OVERALL_LENGTH: 'OVERALL_LENGTH',
    MISSING_VALUES: 'MISSING_VALUES',
    TURNING_RADIUS_INNER: 'TURNING_RADIUS_INNER',
    TURNING_RADIUS_OUTER: 'TURNING_RADIUS_OUTER',
    REAR_OVERHANG_EXCEEDED: 'REAR_OVERHANG_EXCEEDED',
    WHEELBASE_EXCEEDED: 'WHEELBASE_EXCEEDED',
    */

    var regulatoryComplianceInstances = generateLegislationRegulatoryComplianceInstances();
    
    var manufacturerWeightsIcon, manufacturerWeightsIconColour, manufacturerDimensionsIcon, manufacturerDimensionsIconColour;
    if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.MANUFACTURERS_WEIGHTS_AXLES_OVERLOADED) !== null || 
        offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.MANUFACTURERS_WEIGHTS_GROSS_COMBINATION_OVERLOADED) !== null || 
        offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.MANUFACTURERS_WEIGHTS_MAX_UVW_EXCEEDED) !== null) {
        manufacturerWeightsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
        manufacturerWeightsIconColour = config.DRAWING_COLOURS.RED;
    } else {
        manufacturerWeightsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
        manufacturerWeightsIconColour = config.DRAWING_COLOURS.GREEN;
    }
    if(offerManager.getActiveOffer().getRig().getVehicle().getBodyWidthManufacturerMax() > 0) {
        if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.MANUFACTURER_WIDTH_EXCEEDED) !== null) {
            manufacturerDimensionsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
            manufacturerDimensionsIconColour = config.DRAWING_COLOURS.RED;
        } else {
            manufacturerDimensionsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
            manufacturerDimensionsIconColour = config.DRAWING_COLOURS.GREEN;
        }
    } else {
        manufacturerDimensionsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
        manufacturerDimensionsIconColour = config.DRAWING_COLOURS.GREEN;
    }

    let manufacturerLimitsIcon, manufacturerLimitsIconColour;
    if(manufacturerWeightsIcon === config.COMPLIANCE_STATUS_ICON.PASS && manufacturerDimensionsIcon === config.COMPLIANCE_STATUS_ICON.PASS) {
        manufacturerLimitsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
        manufacturerLimitsIconColour = config.DRAWING_COLOURS.GREEN;
        globals.passesManufacturerLimits = "YES";
    }else {
        manufacturerLimitsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
        manufacturerLimitsIconColour = config.DRAWING_COLOURS.RED;
        globals.passesManufacturerLimits = "NO";
    }

    var regulatoryComplianceStatus = getLegislationRegulatoryCompliancePassFailStatus(getLegislationRegulatoryComplianceMergedInstance(regulatoryComplianceInstances));

        if(regulatoryComplianceStatus.regulatoryComplianceIcon ===  config.COMPLIANCE_STATUS_ICON.PASS) {
            globals.passesRegulatoryCompliance = "YES";
        } else {
            globals.passesRegulatoryCompliance = "NO";
        }

    if (!drillDownLevel) {
       
        

        var manufacturerRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.MANUFACTURER_LIMITS);
        var manufacturerLimitsComplianceText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.MANUFACTURER_WEIGHTS_TEXT).setText(config.getTranslationText('1200')).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, config.COMPLIANCE_DASHBOARD_VIEWS.MANUFACTURER_LIMITS), addGraphicsManagerToInteractionCallbackChain);
        var manufacturerLimitsComplianceIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.MANUFACTURER_WEIGHTS_ICON).setIcon(manufacturerLimitsIcon).setIconColour(manufacturerLimitsIconColour);
        manufacturerRow.addItem(manufacturerLimitsComplianceText);
        manufacturerRow.addItem(manufacturerLimitsComplianceIcon);
        complianceDashboard.addRow(manufacturerRow);


        
        
        var regulatoryComplianceRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.REGULATORY_COMPLIANCE);
        var regulatoryComplianceText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.REGULATORY_COMPLIANCE_TEXT).setText(config.getTranslationText('4287')).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE), addGraphicsManagerToInteractionCallbackChain);
        regulatoryComplianceRow.addItem(regulatoryComplianceText);
        if (regulatoryComplianceStatus.regulatoryComplianceIcon !== config.COMPLIANCE_STATUS_ICON.UNKNOWN) {
            var regulatoryComplianceIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.REGULATORY_COMPLIANCE_ICON).setIcon(regulatoryComplianceStatus.regulatoryComplianceIcon).setIconColour(regulatoryComplianceStatus.regulatoryComplianceIconColour);
            regulatoryComplianceRow.addItem(regulatoryComplianceIcon);
        } else {
            var regulatoryComplianceTextUnknwonValue = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.REGULATORY_COMPLIANCE_TEXT + '2').setText('-');
            regulatoryComplianceRow.addItem(regulatoryComplianceTextUnknwonValue);
        }
        complianceDashboard.addRow(regulatoryComplianceRow);


        if (globals.user.getDashboardConfiguration().hasActiveInternalStandards()) {
            var internalStandardsOverallPassFailStatus = getInternalStandardsOverallPassFailStatus(generateInternalStandardsPassFails());

            var internalStandardsRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.INTERNAL_STANDARDS);
            var internalStandardsText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.INTERNAL_STANDARDS_TEXT).setText(config.getTranslationText('4308')).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, config.COMPLIANCE_DASHBOARD_VIEWS.INTERNAL_STANDARDS), addGraphicsManagerToInteractionCallbackChain);
            var internalStandardsIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.INTERNAL_STANDARDS_ICON).setIcon(internalStandardsOverallPassFailStatus.internalStandardsIcon).setIconColour(internalStandardsOverallPassFailStatus.internalStandardsIconColour);
        
            internalStandardsRow.addItem(internalStandardsText);
            internalStandardsRow.addItem(internalStandardsIcon);
            complianceDashboard.addRow(internalStandardsRow);
        }
    } else if (drillDownLevel && drillDownLevel === config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE){
        offerManager.getLegislations().forEach(function (legislationDetails){
            complianceDashboard.addRow(generateLegislationRow(legislationDetails, regulatoryComplianceInstances[legislationDetails.id]))
        });
    } else if (drillDownLevel && drillDownLevel.indexOf(config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE_INSTANCE) !== -1) {

        drilldownLevelParts = drillDownLevel.split('_');
        legislationInstanceencodedId = drilldownLevelParts[drilldownLevelParts.length-1];
        var regulatoryComplianceInstance = regulatoryComplianceInstances[legislationInstanceencodedId];
        if (regulatoryComplianceInstance.permissibleWeightsIcon) {
            var permissibleWeightsComplianceRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.PERMISSIBLE_WEIGHTS);
            var permissibleWeightsComplianceText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.PERMISSIBLE_WEIGHTS_TEXT).setText(config.getTranslationText('1201'));
            permissibleWeightsComplianceRow.addItem(permissibleWeightsComplianceText);
            if (regulatoryComplianceInstance.permissibleWeightsIcon !== config.COMPLIANCE_STATUS_ICON.UNKNOWN) {
                var permissibleWeightsComplianceIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.PERMISSIBLE_WEIGHTS_ICON).setIcon(regulatoryComplianceInstance.permissibleWeightsIcon).setIconColour(regulatoryComplianceInstance.permissibleWeightsIconColour);
                permissibleWeightsComplianceRow.addItem(permissibleWeightsComplianceIcon);
            } else {
                var permissibleWeightsComplianceTextUnknownValue = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.PERMISSIBLE_WEIGHTS_TEXT + '2').setText('-');
                permissibleWeightsComplianceRow.addItem(permissibleWeightsComplianceTextUnknownValue);
            }
            
            
            complianceDashboard.addRow(permissibleWeightsComplianceRow);
        }

        if (regulatoryComplianceInstance.bridgeIcon) {
            var bridgeComplianceRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.BRIDGE_FORMULA);
            var bridgeComplianceText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.BRIDGE_FORMULA_TEXT).setText(config.getTranslationText('1014'));
            bridgeComplianceRow.addItem(bridgeComplianceText);
            if (regulatoryComplianceInstance.bridgeIcon !== config.COMPLIANCE_STATUS_ICON.UNKNOWN) {
                var bridgeComplianceIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.BRIDGE_FORMULA_ICON).setIcon(regulatoryComplianceInstance.bridgeIcon).setIconColour(regulatoryComplianceInstance.bridgeIconColour);
                bridgeComplianceRow.addItem(bridgeComplianceIcon);
            } else {
                var bridgeComplianceTextUnknownValue = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.BRIDGE_FORMULA_TEXT + '2').setText('-');
                bridgeComplianceRow.addItem(bridgeComplianceTextUnknownValue);
            }
            complianceDashboard.addRow(bridgeComplianceRow);
            
        }

        if (regulatoryComplianceInstance.dimensionIcon) {
            var dimensionComplianceRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.DIMENSIONS);
            var dimensionComplianceText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.DIMENSIONS_TEXT).setText(config.getTranslationText('868'));
            dimensionComplianceRow.addItem(dimensionComplianceText);
            if (regulatoryComplianceInstance.dimensionIcon !== config.COMPLIANCE_STATUS_ICON.UNKNOWN) {
                var dimensionsComplianceIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.DIMENSIONS_ICON).setIcon(regulatoryComplianceInstance.dimensionIcon).setIconColour(regulatoryComplianceInstance.dimensionIconColour);
                dimensionComplianceRow.addItem(dimensionsComplianceIcon);
            } else {
                var dimensionsComplianceTextUnknownValue = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.DIMENSIONS_TEXT + '2').setText('-');
                dimensionComplianceRow.addItem(dimensionsComplianceTextUnknownValue);
            }
            
            
            complianceDashboard.addRow(dimensionComplianceRow);
            
        }

        if (regulatoryComplianceInstance.turningCircleIcon) {
            var turningCircleComplianceRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.TURNING_CIRCLE);
            var turningCircleComplianceText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.TURNING_CIRCLE_TEXT).setText(config.getTranslationText('869'));
            turningCircleComplianceRow.addItem(turningCircleComplianceText);
            if (regulatoryComplianceInstance.turningCircleIcon !== config.COMPLIANCE_STATUS_ICON.UNKNOWN) {
                var turningCircleComplianceIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.TURNING_CIRCLE_ICON).setIcon(regulatoryComplianceInstance.turningCircleIcon).setIconColour(regulatoryComplianceInstance.turningCircleIconColour); 
                turningCircleComplianceRow.addItem(turningCircleComplianceIcon);
            } else {
                var turningCircleComplianceTextUnknownValue = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.TURNING_CIRCLE_TEXT + '2').setText('-'); 
                turningCircleComplianceRow.addItem(turningCircleComplianceTextUnknownValue);
            }
            
            
            complianceDashboard.addRow(turningCircleComplianceRow);
        }
    } else if (drillDownLevel && drillDownLevel === config.COMPLIANCE_DASHBOARD_VIEWS.INTERNAL_STANDARDS) {
        
        if (globals.user.getDashboardConfiguration().hasActiveInternalStandards()) {
            var internalStandardPassFailStatuses = [];
            globals.user.getDashboardConfiguration().getInternalStandards().forEach(function (internalStandard) {
                if(internalStandard.getIsActive()) {
                    var passFailStatus = getInternalStandardPassFailStatus(internalStandard)
                    internalStandardPassFailStatuses.push(passFailStatus);
                }
            
            
            });

            internalStandardPassFailStatuses.sort(sortAlphaByDesc);

            internalStandardPassFailStatuses.forEach(function (passFailStatus){
                complianceDashboard.addRow(generateInternalStandardRow(passFailStatus));
            });
        }
    }else if (drillDownLevel && drillDownLevel === config.COMPLIANCE_DASHBOARD_VIEWS.MANUFACTURER_LIMITS){
        
        var manufacturerWeightsRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.MANUFACTURER_WEIGHTS);
        var manufacturerWeightsComplianceText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.MANUFACTURER_WEIGHTS_TEXT).setText(config.getTranslationText('4680'));
        var manufacturerWeightsComplianceIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.MANUFACTURER_WEIGHTS_ICON).setIcon(manufacturerWeightsIcon).setIconColour(manufacturerWeightsIconColour);
        manufacturerWeightsRow.addItem(manufacturerWeightsComplianceText);
        manufacturerWeightsRow.addItem(manufacturerWeightsComplianceIcon);
        complianceDashboard.addRow(manufacturerWeightsRow);
        if(offerManager.getActiveOffer().getRig().getVehicle().getBodyWidthManufacturerMax() > 0) {
            var manufacturerDimensionsRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.DIMENSIONS);
            var manufacturerDimensionsRowText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.DIMENSIONS_TEXT).setText(config.getTranslationText('4681'));
            var manufacturerDimensionsRowIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.DIMENSIONS_ICON).setIcon(manufacturerDimensionsIcon).setIconColour(manufacturerDimensionsIconColour);
            manufacturerDimensionsRow.addItem(manufacturerDimensionsRowText);
            manufacturerDimensionsRow.addItem(manufacturerDimensionsRowIcon);
            complianceDashboard.addRow(manufacturerDimensionsRow);
        }
    }
    
    if (globals.user.getDashboardConfiguration().getDashboardDataPoints().length > 0) {
        
        var complianceSpacerRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.COMPLIANCE_SPACER_1);
        var complianceSpacer = new ComplianceDashboardSpacer().setSpacerType(config.SPACER_TYPE.CUSTOM_VERTICAL).setHeight(2);
        complianceSpacerRow.addItem(complianceSpacer);
        complianceDashboard.addRow(complianceSpacerRow);

        var complianceSpacerLineRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.COMPLIANCE_SEPARATOR_LINE);
        var complianceSpacerLine = new ComplianceDashboardSpacer().setSpacerType(config.SPACER_TYPE.LINE);
        complianceSpacerLineRow.addItem(complianceSpacerLine);
        complianceDashboard.addRow(complianceSpacerLineRow);

        var complianceSpacerRow2 = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.COMPLIANCE_SPACER_2);
        var complianceSpacer2 = new ComplianceDashboardSpacer().setSpacerType(config.SPACER_TYPE.CUSTOM_VERTICAL).setHeight(2);
        complianceSpacerRow2.addItem(complianceSpacer2);
        complianceDashboard.addRow(complianceSpacerRow2);

        var detailedDataPoints = [];

        globals.user.getDashboardConfiguration().getDashboardDataPoints().forEach(function (dashboardDataPoint){
            var dataPoint = globals.user.getDashboardDataPoints().filter(function (dataPoint) {
                return dataPoint.id === dashboardDataPoint.getDataPointId();
            })[0] || null;
            detailedDataPoints.push(dataPoint);
        });

        detailedDataPoints.sort(sortAlphaByDesc);

        detailedDataPoints.forEach(function (dataPoint) {
            generateDashboardDataPointRow(dataPoint)
        });
    }

    function sortAlphaByDesc(a, b) {
        var descriptionA = a.description.toUpperCase(); // ignore upper and lowercase
        var descriptionB = b.description.toUpperCase(); // ignore upper and lowercase
        if (descriptionA < descriptionB) {
            return -1; //descriptionA comes first
        }
        if (descriptionA > descriptionB) {
            return 1; // descriptionB comes first
        }
        return 0;
    }
    //var hitchLoadComplianceRow = new ComplianceDashboardRow().setId('hitchLoad');
    //var hitchLoadComplianceTextLabel = new ComplianceDashboardText().setId('hitchLoad' + '_text').setText('Hitch Load');
    //var hitchLoadComplianceTextVal = new ComplianceDashboardText().setId('hitchLoad' + '_icon').setText('550 lb');
    //hitchLoadComplianceRow.addItem(hitchLoadComplianceTextLabel);
    //hitchLoadComplianceRow.addItem(hitchLoadComplianceTextVal);
    //complianceDashboard.addRow(hitchLoadComplianceRow);

    //var staticRolloverComplianceRow = new ComplianceDashboardRow().setId('static_rollover');
    //var staticRolloverComplianceTextLabel = new ComplianceDashboardText().setId('static_rollover' + '_text').setText('Static Rollover');
    //var staticRolloverComplianceTextVal = new ComplianceDashboardText().setId('static_rollover' + '_icon').setText('30.5%');
    //staticRolloverComplianceRow.addItem(staticRolloverComplianceTextLabel);
    //staticRolloverComplianceRow.addItem(staticRolloverComplianceTextVal);
    //complianceDashboard.addRow(staticRolloverComplianceRow);

    //var verticalCGComplianceRow = new ComplianceDashboardRow().setId('vertical_cg');
    //var verticalCGComplianceTextLabel = new ComplianceDashboardText().setId('vertical_cg' + '_text').setText('Vertical CG');
    //var verticalCGComplianceTextVal = new ComplianceDashboardText().setId('vertical_cg' + '_icon').setText('82.7 in');
    //verticalCGComplianceRow.addItem(verticalCGComplianceTextLabel);
    //verticalCGComplianceRow.addItem(verticalCGComplianceTextVal);
    //complianceDashboard.addRow(verticalCGComplianceRow);

    //var bottomSpacerRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.SPACER + '_' + globals.nextId());
    //var bottomSpacer = new ComplianceDashboardSpacer().setSpacerType(config.SPACER_TYPE.CUSTOM_VERTICAL).setHeight(config.tableAndDashboardTitleToDataGap);
    //bottomSpacerRow.addItem(bottomSpacer);
    //complianceDashboard.addRow(bottomSpacerRow);

    return complianceDashboard;
}



function generateLegislationRow(legislationDetails, regulatoryComplianceStatusInstance) {
    var legislationRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.LEGISLATION + legislationDetails.id);
    var legislationText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.LEGISLATION_TEXT + legislationDetails.id).setText(legislationDetails.description).setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN, config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE_INSTANCE + '_' + legislationDetails.id), addGraphicsManagerToInteractionCallbackChain);
    legislationRow.addItem(legislationText);

    var passFailStatus = getLegislationRegulatoryCompliancePassFailStatus(regulatoryComplianceStatusInstance);
    if (passFailStatus.regulatoryComplianceIcon !== config.COMPLIANCE_STATUS_ICON.UNKNOWN) {
        var legislationIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.LEGISLATION_ICON + legislationDetails.id).setIcon(passFailStatus.regulatoryComplianceIcon).setIconColour(passFailStatus.regulatoryComplianceIconColour);
        //if (regulatoryComplianceStatusInstance.regulatoryComplianceUnknownIconSize) {
        //    legislationIcon.setIconSize(regulatoryComplianceStatusInstance.regulatoryComplianceUnknownIconSize);
        //}
        legislationRow.addItem(legislationIcon);
    } else {
        var legislationTextUnknownVal = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.LEGISLATION_TEXT + legislationDetails.id + '2').setText('-');
        legislationRow.addItem(legislationTextUnknownVal);
    }
    
    
    return legislationRow;
}

function generateInternalStandardRow(internalStatusPassFailStatus) {
    var internalStandardRow = new ComplianceDashboardRow().setId(config.COMPLIANCE_IDS.LEGISLATION + internalStatusPassFailStatus.dataPointId);
    var internalStandardText = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.LEGISLATION_TEXT + internalStatusPassFailStatus.dataPointId).setText(internalStatusPassFailStatus.description)/*.setInteraction(offerManager.getComplianceDashboardInteractionObject(config.INTERACTION_ID.COMPLIANCE_DASHBOARD, config.COMPLIANCE_DASHBOARD_VIEWS.REGULATORY_COMPLIANCE), addGraphicsManagerToInteractionCallbackChain);*/;
    
    internalStandardRow.addItem(internalStandardText);

    
    if (internalStatusPassFailStatus.internalStandardIcon !== config.COMPLIANCE_STATUS_ICON.UNKNOWN) {
        var internalStandardIcon = new ComplianceDashboardIcon().setId(config.COMPLIANCE_IDS.LEGISLATION_ICON + internalStatusPassFailStatus.dataPointId).setIcon(internalStatusPassFailStatus.internalStandardIcon).setIconColour(internalStatusPassFailStatus.internalStandardIconColour);
        //if (internalStatusPassFailStatus.unknownIconSize) {
        //    internalStandardIcon.setIconSize(internalStatusPassFailStatus.unknownIconSize);
        //}
        internalStandardRow.addItem(internalStandardIcon);
    } else {
        var internalStandardTextUnknownVal = new ComplianceDashboardText().setId(config.COMPLIANCE_IDS.LEGISLATION_TEXT + internalStatusPassFailStatus.dataPointId + '2').setText('-');
        internalStandardRow.addItem(internalStandardTextUnknownVal);
    }
    
    return internalStandardRow;
}



function generateDashboardDataPointRow(dashboardDataPoint) {
    
    var evaluateDataPoint = offerManager.needToEvaluateDataPointOrInternalStandard(dashboardDataPoint.name.toUpperCase());

    var dataPointVal;
    if (evaluateDataPoint) {
        offerManager.getInterpreterInstance().setLegislationObject(offerManager.getLegislations()[0]);
        offerManager.getRigOps().setLegislationEvaluationFunction(offerManager.getLesserOfActiveLegislationVals);
        offerManager.getInterpreterInstance().setRigOps(offerManager.getRigOps());
        dataPointVal = offerManager.getInterpreterInstance().loadVariable(undefined, dashboardDataPoint.name.toUpperCase(), true);
    } else {
        dataPointVal = '-';
    }
    

    var dashboardDataPointRow = new ComplianceDashboardRow().setId(dashboardDataPoint.id);
    var dashboardDataPointTextLabel = new ComplianceDashboardText().setId(dashboardDataPoint.id + '_text').setText(dashboardDataPoint.description);
    
    var displayVal;
    if (evaluateDataPoint) {
        var formatFuncToUse = offerManager.getInternalStandardFormatFunc(dashboardDataPoint.unitFormat);
        if (dashboardDataPoint.unitFormat === config.INCREMENT_TYPE.MASS.toUpperCase()) {
            displayVal = formatFuncToUse(dataPointVal, 0);
        } else {
            displayVal = formatFuncToUse(dataPointVal);
        }
        
    } else {
        displayVal = dataPointVal;
    }

    var dashboardDataPointTextVal = new ComplianceDashboardText().setId(dashboardDataPoint.id + '_icon').setText(displayVal);
    dashboardDataPointRow.addItem(dashboardDataPointTextLabel);
    dashboardDataPointRow.addItem(dashboardDataPointTextVal);
    complianceDashboard.addRow(dashboardDataPointRow);
}
function generateInternalStandardsPassFails() {
    var internalStandardsPassFails = {};

    globals.user.getDashboardConfiguration().getInternalStandards().forEach(function (internalStandard) {
        if (internalStandard.getIsActive()) {
            var dataPoint = globals.user.getDashboardInternalStandardsDataPoints().filter(function (dataPoint) {
                return dataPoint.id === internalStandard.getDataPointId();
            })[0] || null;
            internalStandardsPassFails[dataPoint.name] = checkInternalStandardPassFailStatus(internalStandard);
        }
    });

    return internalStandardsPassFails;
}

function getInternalStandardsOverallPassFailStatus(internalStandardsPassFails) {
    var internalStandardsOverallPassFailStatus = {};
    var internalStandardsOvreallPass = true;
    Object.keys(internalStandardsPassFails).forEach(function (key) {
        if (internalStandardsPassFails[key] === false) {
            internalStandardsOvreallPass = false;
        }
    });

    if (internalStandardsOvreallPass) {
        internalStandardsOverallPassFailStatus.internalStandardsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
        internalStandardsOverallPassFailStatus.internalStandardsIconColour = config.TABLE_HIGHLIGHT_COLOURS.OK;
    } else {
        internalStandardsOverallPassFailStatus.internalStandardsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
        internalStandardsOverallPassFailStatus.internalStandardsIconColour = config.TABLE_HIGHLIGHT_COLOURS.FAIL;
    }

    return internalStandardsOverallPassFailStatus;
}

function checkInternalStandardPassFailStatus(internalStandard) {

    var checkPassFailStatus = false;
    var dashboardDataPoint = globals.user.getDashboardInternalStandardsDataPoints().filter(function (dataPoint){
        return dataPoint.id === internalStandard.getDataPointId();
    })[0] || null;

    if(offerManager.needToEvaluateDataPointOrInternalStandard(dashboardDataPoint.name.toUpperCase())) {
        var dataPointVal = offerManager.getInterpreterInstance().loadVariable(undefined, dashboardDataPoint.name.toUpperCase(), true);
        switch (internalStandard.getRangeType()) {
            case config.RANGE_TYPES.BETWEEN:
                if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.INTERNAL_STANDARD + '_' + dashboardDataPoint.code) === null) {
                // if (dataPointVal >= internalStandard.getRangeMin() && dataPointVal <= internalStandard.getRangeMax()) {
                    checkPassFailStatus = true;
                }
                break;
            case config.RANGE_TYPES.GREATER:
                if (dataPointVal > internalStandard.getRangeMin()) {
                    checkPassFailStatus = true;
                }  
                break;
            case config.RANGE_TYPES.LESSER:
                if (dataPointVal < internalStandard.getRangeMax()) {
                    checkPassFailStatus = true;
                }
                break;
        }
    } else {
        checkPassFailStatus = true
    }
    return checkPassFailStatus;
}

function getInternalStandardPassFailStatus(internalStandard) {
    var internalStandardPassFailStatus = {};
    
    
    var dataPoint = globals.user.getDashboardInternalStandardsDataPoints().filter(function (dataPoint) {
        return dataPoint.id === internalStandard.getDataPointId();
    })[0] || null;

    //var internalStandardPass = checkInternalStandardPassFailStatus(internalStandard);
    if (offerManager.needToEvaluateDataPointOrInternalStandard(dataPoint.name.toUpperCase())) {
        if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.INTERNAL_STANDARD + '_' + dataPoint.code) === null) {
            internalStandardPassFailStatus.internalStandardIcon = config.COMPLIANCE_STATUS_ICON.PASS;
            internalStandardPassFailStatus.internalStandardIconColour = config.TABLE_HIGHLIGHT_COLOURS.OK;
        } else {
            internalStandardPassFailStatus.internalStandardIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
            internalStandardPassFailStatus.internalStandardIconColour = config.TABLE_HIGHLIGHT_COLOURS.FAIL;
        }
    } else {
        internalStandardPassFailStatus.internalStandardIcon = config.COMPLIANCE_STATUS_ICON.UNKNOWN;
        internalStandardPassFailStatus.internalStandardIconColour = config.DRAWING_COLOURS.COLUMN_TITLE;
        internalStandardPassFailStatus.unknownIconSize = 10;
    }
    internalStandardPassFailStatus.description = dataPoint.description;
    internalStandardPassFailStatus.dataPointId = dataPoint.id;
    internalStandardPassFailStatus.name = dataPoint.name;

    return internalStandardPassFailStatus;
}

function getLegislationRegulatoryCompliancePassFailStatus(regulatoryComplianceInstance) {
    

    var worstColourStatus = config.TABLE_HIGHLIGHT_COLOURS.OK;
    var worstIconStatus = config.COMPLIANCE_STATUS_ICON.PASS;
    //var unknowIconSize = undefined;
    Object.keys(regulatoryComplianceInstance).forEach(function (key){
        if (key.indexOf('Icon') !== -1 && key.indexOf('IconColour') === -1 && key.indexOf('IconSize') === -1) {
            worstIconStatus = offerManager.returnWorstIconStatus(regulatoryComplianceInstance[key], worstIconStatus);
        }else if (key.indexOf('IconColour') !== -1) {
            worstColourStatus = offerManager.returnWorstColourStatus(regulatoryComplianceInstance[key], worstColourStatus);
        } /*else if (key.indexOf('UnknownIconSize') !== -1) {
            unknowIconSize = regulatoryComplianceInstance[key];
        }*/
    });
    
    var returnObj = {
        regulatoryComplianceIcon: worstIconStatus,
        regulatoryComplianceIconColour: worstColourStatus
    } 
    //if (unknowIconSize) {
    //    returnObj.regulatoryComplianceUnknownIconSize = unknowIconSize;

    //}
    return returnObj;
}

function getLegislationRegulatoryComplianceMergedInstance(regulatoryComplianceInstances) {
    var regulatoryComplianceInstanceValueArrays = {};

    
    Object.keys(regulatoryComplianceInstances).forEach(function (instanceKey) {
        Object.keys(regulatoryComplianceInstances[instanceKey]).forEach(function(valKey){
            if (!regulatoryComplianceInstanceValueArrays[valKey]) {
                regulatoryComplianceInstanceValueArrays[valKey] = [];
            }
            regulatoryComplianceInstanceValueArrays[valKey].push(regulatoryComplianceInstances[instanceKey][valKey]);
            
        });
    });

    var mergedLegislationRegulatoryCompliancePassFailStatusValSet = {};
    
    Object.keys(regulatoryComplianceInstanceValueArrays).forEach(function (key) {

        if (regulatoryComplianceInstanceValueArrays[key].length === 1) {
            mergedLegislationRegulatoryCompliancePassFailStatusValSet[key] = regulatoryComplianceInstanceValueArrays[key][0];
        } else {
            var previousVal; 
            regulatoryComplianceInstanceValueArrays[key].forEach(function (val){
                if (!previousVal) {
                    previousVal = val;
                } else {
                    if (key.indexOf('Icon') !== -1 && key.indexOf('IconColour') === -1) {
                        mergedLegislationRegulatoryCompliancePassFailStatusValSet[key] = offerManager.returnWorstIconStatus(val, previousVal);
                        previousVal = mergedLegislationRegulatoryCompliancePassFailStatusValSet[key];
                    } else if(key.indexOf('IconColour') !== -1) {
                        mergedLegislationRegulatoryCompliancePassFailStatusValSet[key] = offerManager.returnWorstColourStatus(val, previousVal);
                        previousVal = mergedLegislationRegulatoryCompliancePassFailStatusValSet[key];
                    }
                }
            });
        }
    });
    return mergedLegislationRegulatoryCompliancePassFailStatusValSet;
}

function generateLegislationRegulatoryComplianceInstances() {

    var regulatoryComplianceInstances = {};
    var mergedPermissibleValues = offerManager.getMergedLegislationsCombinationPermissibleVals(offerManager.getActiveLegislationsCombinationPermissibleVals());
    var tempRigOpsForRegulatoryComplianceInstanceGeneration = new RigOps();
    //tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals(mergedPermissibleValues);
    offerManager.getLegislations().forEach(function (legislationDetails) {

        var regulatoryComplianceInstance = {};

        var evaluateLegislationWrapper = function (attributeName) {
            return offerManager.evaluateLegislation(attributeName, legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration)
        };
        // var baseOffer = undefined;
        tempRigOpsForRegulatoryComplianceInstanceGeneration.setup(offerManager.getActiveOffer(), offerManager.getOriginalOffer(), offerManager.getBaseOffer(), evaluateLegislationWrapper, utilLegislationAttributeObjectCode);
        tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals();
        //offerManager.getRigOps().setLegislationEvaluationFunction(evaluateLegislationWrapper);
        //offerManager.clearLegislationQuickLookup();
        //if (offerManager.needToMaximise(tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
        //    tempRigOpsForRegulatoryComplianceInstanceGeneration.maximisePayloads(mergedPermissibleValues);
        //}

        var legislationWarnings = offerManager.getWarningsByEncodedLegislationId(legislationDetails.id);

        // var curTableWorstOverloadingStatus = offerManager.getWorstOverloadingStatus(legislationDetails.id, evaluateLegislationWrapper);

        var showPermissibleWeights = false;
        var bridgeComplianceOnly = evaluateLegislationWrapper(utilLegislationAttributeObjectCode.variableTypes.BridgeComplianceOnly);
        if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
            bridgeComplianceOnly = 0;
        }
        
        if (bridgeComplianceOnly === 0) {
            showPermissibleWeights = true;
            if (offerManager.hasLegislationARegulationUnknown(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
                regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.UNKNOWN;
                regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.COLUMN_TITLE;
                regulatoryComplianceInstance.unknownIconSize = 10;
            } else if(offerManager.hasLegislationARegulationWarning(legislationDetails)) {
                regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
                regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.RED;
            }else if(offerManager.hasLegislationARegulationCaution(legislationDetails)) {
                regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.WARNING;//'\uf071';
                regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.ORANGE;
                regulatoryComplianceInstance.permissibleWeightsIconSize = 20;
            } else {
                regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
                regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.GREEN;
            }
            
        }
    
        var showBridge = false;
        if (tempRigOpsForRegulatoryComplianceInstanceGeneration.getCurrentCombinationBridgeFormulaUsed() === 1) {
            
            showBridge = true;
            if (offerManager.hasLegislationABridgeUnknown(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
                regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.UNKNOWN;
                regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.COLUMN_TITLE;
                regulatoryComplianceInstance.bridgeUnknownIconSize = 10;
            } else if (offerManager.hasLegislationABridgeWarning(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration, evaluateLegislationWrapper)) {
                regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
                regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.RED;
            } else if (offerManager.hasLegislationABridgeCaution(legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration, evaluateLegislationWrapper)) {
                regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.WARNING;//'\uf071';
                regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.ORANGE;
                regulatoryComplianceInstance.bridgeIconSize = 20;
            }  else {
                regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.PASS;
                regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.GREEN;
            }
        }


        
        if (offerManager.hasLegislationADimensionWarning(legislationDetails)) {
            regulatoryComplianceInstance.dimensionIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
            regulatoryComplianceInstance.dimensionIconColour = config.DRAWING_COLOURS.RED;
        } else {
            regulatoryComplianceInstance.dimensionIcon = config.COMPLIANCE_STATUS_ICON.PASS;
            regulatoryComplianceInstance.dimensionIconColour = config.DRAWING_COLOURS.GREEN;
        }

        if (offerManager.hasLegislationATurningCircleUnknown(legislationDetails)) {
            regulatoryComplianceInstance.turningCircleIcon = config.COMPLIANCE_STATUS_ICON.UNKNOWN;
            regulatoryComplianceInstance.turningCircleIconColour = config.DRAWING_COLOURS.COLUMN_TITLE;
            regulatoryComplianceInstance.turningCircleUnknownIconSize = 10;
        } else if (offerManager.hasLegislationATurningCircleWarning(legislationDetails)) {
            regulatoryComplianceInstance.turningCircleIcon = config.COMPLIANCE_STATUS_ICON.FAIL;//'\uf00d';
            regulatoryComplianceInstance.turningCircleIconColour = config.DRAWING_COLOURS.RED;
        } else {
            regulatoryComplianceInstance.turningCircleIcon = config.COMPLIANCE_STATUS_ICON.PASS;//'\uf00c';
            regulatoryComplianceInstance.turningCircleIconColour = config.DRAWING_COLOURS.GREEN;
        }
        
        regulatoryComplianceInstances[legislationDetails.id] = regulatoryComplianceInstance;
    });

    

    return regulatoryComplianceInstances;
}

// function generateLegislationRegulatoryComplianceInstances() {

//     var regulatoryComplianceInstances = {};
//     var mergedPermissibleValues = offerManager.getMergedLegislationsCombinationPermissibleVals(offerManager.getActiveLegislationsCombinationPermissibleVals());
//     var tempRigOpsForRegulatoryComplianceInstanceGeneration = new RigOps();
//     //tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals(mergedPermissibleValues);
//     offerManager.getLegislations().forEach(function (legislationDetails) {

//         var regulatoryComplianceInstance = {};

//         var evaluateLegislationWrapper = function (attributeName) {
//             return offerManager.evaluateLegislation(attributeName, legislationDetails, tempRigOpsForRegulatoryComplianceInstanceGeneration)
//         };
//         var baseOffer = undefined;
//         tempRigOpsForRegulatoryComplianceInstanceGeneration.setup(offerManager.getActiveOffer(), offerManager.getOriginalOffer(), baseOffer, evaluateLegislationWrapper, utilLegislationAttributeObjectCode);
//         tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals();
//         //offerManager.getRigOps().setLegislationEvaluationFunction(evaluateLegislationWrapper);
//         //offerManager.clearLegislationQuickLookup();
//         //if (offerManager.needToMaximise(tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
//         //    tempRigOpsForRegulatoryComplianceInstanceGeneration.maximisePayloads(mergedPermissibleValues);
//         //}

//         var legislationWarnings = offerManager.getWarningsByEncodedLegislationId(legislationDetails.id);

//         // var curTableWorstOverloadingStatus = offerManager.getWorstOverloadingStatus(legislationDetails.id, evaluateLegislationWrapper);

//         var showPermissibleWeights = false;
//         var bridgeComplianceOnly = evaluateLegislationWrapper(utilLegislationAttributeObjectCode.variableTypes.BridgeComplianceOnly);
//         if (bridgeComplianceOnly === undefined || bridgeComplianceOnly === null) {
//             bridgeComplianceOnly = 0;
//         }
        
//         if (bridgeComplianceOnly === 0) {
//             offerManager.clearBridgeFormulaPermissiblWorstHighlightColour();
//             offerManager.clearSteerAxleMinimumWorstHighlightColour();
//             // tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals(mergedPermissibleValues);
//             // if (offerManager.needToMaximise(tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
//             //     tempRigOpsForRegulatoryComplianceInstanceGeneration.maximisePayloads(mergedPermissibleValues);
//             // }
//             var bridgeFormulaPermissibleValWorstHighlightColour = offerManager.getWorstBridgeFormulaPermissibleOverloadColour(legislationDetails.id);
//             var steerAxleMinumumWorstHighlightColour = offerManager.getWorstSteerAxleMinimumOverloadColour(legislationDetails.id);
//             showPermissibleWeights = true;
//             var permissibleWeightsIcon, permissibleWeightsIconColour, permissibleWeightsIconSize, unknownIconSize;
//             if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.MISSING_VALUES_VEHICLE) !== null) {
//                 regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.UNKNOWN;
//                 regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.COLUMN_TITLE;
//                 regulatoryComplianceInstance.unknownIconSize = 10;
//             } else if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.PERMISSIBLE_TOTAL_OVERLOADED) !== null ||
//                 legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.AXLES_OVERLOADED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.PUP_HEAVIER_THAN_VEHICLE) !== null ||
//                 legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.DRAWBAR_HEAVIER_THAN_VEHICLE) !== null) {
//                 if (bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING || bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.RED;
//                 } else if(bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.WARNING;//'\uf071';
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.ORANGE;
//                     regulatoryComplianceInstance.permissibleWeightsIconSize = 20;
//                 } else {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.GREEN;
//                 }
//             } else if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.STEER_AXLE_MINIMUM) !== null) {
//                 if (steerAxleMinumumWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING || steerAxleMinumumWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.RED;
//                 } else if(steerAxleMinumumWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.WARNING;//'\uf071';
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.ORANGE;
//                     regulatoryComplianceInstance.permissibleWeightsIconSize = 20;
//                 } else {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.GREEN;
//                 }
//             } /*else if (bridgeFormulaPermissibleValWorstHighlightColour !== config.TABLE_HIGHLIGHT_COLOURS.OK) {
//                 if (bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING || bridgeFormulaPermissibleValWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.RED;
//                 } else {
//                     regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.WARNING;//'\uf071';
//                     regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.ORANGE;
//                     regulatoryComplianceInstance.permissibleWeightsIconSize = 20;
//                 }
//             }*/ else {
//                 regulatoryComplianceInstance.permissibleWeightsIcon = config.COMPLIANCE_STATUS_ICON.PASS;
//                 regulatoryComplianceInstance.permissibleWeightsIconColour = config.DRAWING_COLOURS.GREEN;
//             }
//         }
//         // if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//         //     return config.OVERLOADING_STATUS.OVERLOADED;
//         // } else if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.UNDER_LOADING) {
//         //     return config.OVERLOADING_STATUS.UNDERLOADED;
//         // } else if (grossFrontTableHighLightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//         //     return config.OVERLOADING_STATUS.OVERLOADED_WITHIN_TOLERANCES;
//         // }
//         var bridgeIcon, bridgeIconColour, bridgeIconSize, bridgeUnknownIconSize;

//         var showBridge = false;

//         if (tempRigOpsForRegulatoryComplianceInstanceGeneration.getCurrentCombinationBridgeFormulaUsed() === 1) {
//             offerManager.clearBridgeWorstHighlightColour();
//             tempRigOpsForRegulatoryComplianceInstanceGeneration.setCombinationPermissibleVals(mergedPermissibleValues);
//             if (offerManager.needToMaximise(tempRigOpsForRegulatoryComplianceInstanceGeneration)) {
//                 tempRigOpsForRegulatoryComplianceInstanceGeneration.maximisePayloads(mergedPermissibleValues);
//             }
//             var bridgeWorstHighlightColour = offerManager.getWorstBridgeFormulaOverloadColour(tempRigOpsForRegulatoryComplianceInstanceGeneration, legislationDetails.id, evaluateLegislationWrapper);
//             showBridge = true;
//             if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.X_DIMENSION_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.Y_DIMENSION_EXCEEDED) !== null ||
//                 legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_WHEELBASE) !== null) {
//                 regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
//                 regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.RED;
//             } else if (bridgeWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
//                 regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
//                 regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.RED;
//             } else if (bridgeWorstHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
//                 regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.WARNING;//'\uf071';
//                 regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.ORANGE;
//                 regulatoryComplianceInstance.bridgeIconSize = 20;
//             } else if (tempRigOpsForRegulatoryComplianceInstanceGeneration.hasMissingTareWeigthsForBridgeFormula() === true) {
//                 regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.UNKNOWN;
//                 regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.COLUMN_TITLE;
//                 regulatoryComplianceInstance.bridgeUnknownIconSize = 10;
//             } else {
//                 regulatoryComplianceInstance.bridgeIcon = config.COMPLIANCE_STATUS_ICON.PASS;
//                 regulatoryComplianceInstance.bridgeIconColour = config.DRAWING_COLOURS.GREEN;
//             }
//             //if (bridgeComplianceOnly === 1) {

//             //    if (offerManager.getRigOps().getPermissibleWeightsComplianceFailed()) {
//             //        bridgeIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
//             //        bridgeIconColour = config.DRAWING_COLOURS.RED;
//             //        bridgeUnknownIconSize = undefined;
//             //        bridgeIconSize = undefined;
//             //    }
//             //}

//         }


//         var dimensionIcon, dimensionIconColour;
//         if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.REAR_OVERHANG_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.VEHICLE_WHEELBASE_THEORETICAL) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_LENGTH) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TRAILER_DRAWBAR_LENGTH) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TURNTABLE_TO_REAR_OF_TRAILER) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_HEIGHT_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.GAP_BETWEEN_VEHICLE_AND_TRAILER) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_REAR_OVERHANG_LEGISLATION) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_LENGTH) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_LENGTH) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.KINGPIN_TO_REAR_OF_TRAILER) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_FRONT_OVERHANG) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null || legislationWarnings.getStringTrackerObjectById(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.WARNING_ID.TRAILER_WHEELBASE_THEORETICAL) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.GAP_BETWEEN_TRAILER_AND_TRAILER) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.VEHICLE_LENGTH) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.OVERALL_LENGTH_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.KINGPIN_TO_REARMOST_AXLE_SETTING_EXCEEDED) !== null ||
//             legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.CARGO_CARRYING_LENGTH_EXCEEDED) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.HOOKLIFT_BODY_OVERHANG_EXCEEDED) !== null) {
                



//             regulatoryComplianceInstance.dimensionIcon = config.COMPLIANCE_STATUS_ICON.FAIL;
//             regulatoryComplianceInstance.dimensionIconColour = config.DRAWING_COLOURS.RED;
//         } else {
//             regulatoryComplianceInstance.dimensionIcon = config.COMPLIANCE_STATUS_ICON.PASS;
//             regulatoryComplianceInstance.dimensionIconColour = config.DRAWING_COLOURS.GREEN;
//         }

//         var turningCircleIcon, turningCircleIconColour, turningCircleUNknownIconSize;
//         if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.MISSING_VALUES_TURNING_CIRCLE) !== null) {
//             regulatoryComplianceInstance.turningCircleIcon = config.COMPLIANCE_STATUS_ICON.UNKNOWN;
//             regulatoryComplianceInstance.turningCircleIconColour = config.DRAWING_COLOURS.COLUMN_TITLE;
//             regulatoryComplianceInstance.turningCircleUnknownIconSize = 10;
//         } else if (legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_OUTER) !== null || legislationWarnings.getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_INNER) !== null) {
//             regulatoryComplianceInstance.turningCircleIcon = config.COMPLIANCE_STATUS_ICON.FAIL;//'\uf00d';
//             regulatoryComplianceInstance.turningCircleIconColour = config.DRAWING_COLOURS.RED;
//         } else {
//             regulatoryComplianceInstance.turningCircleIcon = config.COMPLIANCE_STATUS_ICON.PASS;//'\uf00c';
//             regulatoryComplianceInstance.turningCircleIconColour = config.DRAWING_COLOURS.GREEN;
//         }
        
//         regulatoryComplianceInstances[legislationDetails.id] = regulatoryComplianceInstance;
        

//     });

    

//     return regulatoryComplianceInstances;
// }

function ComplianceDashboardNavigationBar() {
    var _id, _text, _textColour, _textStyle, _textIsBold = false, _backgroundColour;


}

function ComplianceDashboardContentArea() {

}









function getBridgeGroup(bridgeGroup) {
    //switch (offerManager.getRigOps().getCombinationType()) {
    //    case config.COMBINATION_TYPES.VEHICLE:
    //        return 
    //    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    //        setupBridgeViewVehicleAndDrawbarTableRows(tableMeta);
    //        break;
    //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    //        setupBridgeViewVehicleAndSemiTableRows(tableMeta);
    //        break;
    //}
    return offerManager.getRigOps().getBridgeGroupLabel(bridgeGroup);
}

function getGroup1BridgeDistanceValue() {
    return offerManager.getRigOps().getVehicleGroup1BridgeFormulaDistance();
}

function getGroup1BridgeDistanceRoundedValue() {
    return offerManager.getRigOps().getVehicleGroup1BridgeFormulaDistanceNearest();
}

function getGroup1BridgePermissibleValue() {
    return offerManager.getRigOps().getVehicleGroup1BridgeFormulaPermissible();
}

function getGroup1BridgeActualValue() {
    return offerManager.getRigOps().getVehicleGroup1BridgeFormulaActual();
}

function getGroup1BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup1BridgeFormulaPermissible() - offerManager.getRigOps().getGroup1BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup1BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }


    return offerManager.getUIFormattedMassValue(diffMass, 0) /*+ ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

//function getGroup2BridgeDistanceValue() {
//    return offerManager.getRigOps().getGroup2BridgeFormulaDistance();
//}

function getGroup2BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup2BridgeFormulaPermissible() - offerManager.getRigOps().getGroup2BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup2BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup3BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup3BridgeFormulaPermissible() - offerManager.getRigOps().getGroup3BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup3BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0) /*+ ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup4BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup4BridgeFormulaPermissible() - offerManager.getRigOps().getGroup4BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup4BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0) /*+ ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup5BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup5BridgeFormulaPermissible() - offerManager.getRigOps().getGroup5BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup5BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0) /*+ ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup6BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup6BridgeFormulaPermissible() - offerManager.getRigOps().getGroup6BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup6BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup7BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup7BridgeFormulaPermissible() - offerManager.getRigOps().getGroup7BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup7BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup8BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup8BridgeFormulaPermissible() - offerManager.getRigOps().getGroup8BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup8BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup9BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup9BridgeFormulaPermissible() - offerManager.getRigOps().getGroup9BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup9BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup10BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup10BridgeFormulaPermissible() - offerManager.getRigOps().getGroup10BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup10BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0) /*+ ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup11BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup11BridgeFormulaPermissible() - offerManager.getRigOps().getGroup11BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup11BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup12BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup12BridgeFormulaPermissible() - offerManager.getRigOps().getGroup12BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup12BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup13BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup13BridgeFormulaPermissible() - offerManager.getRigOps().getGroup13BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup13BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup14BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup14BridgeFormulaPermissible() - offerManager.getRigOps().getGroup14BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup14BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup15BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup15BridgeFormulaPermissible() - offerManager.getRigOps().getGroup15BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup15BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup16BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup16BridgeFormulaPermissible() - offerManager.getRigOps().getGroup16BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup16BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup17BridgeOverloadValue() {

    var diffMass = offerManager.getRigOps().getGroup17BridgeFormulaPermissible() - offerManager.getRigOps().getGroup17BridgeFormulaActual();
    var overloadPercentage = offerManager.getRigOps().getGroup17BridgeFormulaOverload();
    if (overloadPercentage < 0) {
        diffMass = -diffMass;
    } else if (diffMass < 0) {
        diffMass = -diffMass;
    }

    return offerManager.getUIFormattedMassValue(diffMass, 0)/* + ' (' + offerManager.getUIFormattedPercentageValue(overloadPercentage) + ')'*/;
}

function getGroup1BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getVehicleGroup1BridgeFormulaPermissible() - offerManager.getRigOps().getVehicleGroup1BridgeFormulaActual(), 0);
}

//function getGroup2BridgeDistanceValue() {
//    return offerManager.getRigOps().getGroup2BridgeFormulaDistance();
//}

function getGroup2BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup2BridgeFormulaPermissible() - offerManager.getRigOps().getGroup2BridgeFormulaActual(), 0);
}

function getGroup3BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup3BridgeFormulaPermissible() - offerManager.getRigOps().getGroup3BridgeFormulaActual(), 0);
}

function getGroup4BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup4BridgeFormulaPermissible() - offerManager.getRigOps().getGroup4BridgeFormulaActual(), 0);
}

function getGroup5BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup5BridgeFormulaPermissible() - offerManager.getRigOps().getGroup5BridgeFormulaActual(), 0);
}

function getGroup6BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup6BridgeFormulaPermissible() - offerManager.getRigOps().getGroup6BridgeFormulaActual(), 0);
}

function getGroup7BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup7BridgeFormulaPermissible() - offerManager.getRigOps().getGroup7BridgeFormulaActual(), 0);
}

function getGroup8BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup8BridgeFormulaPermissible() - offerManager.getRigOps().getGroup8BridgeFormulaActual(), 0);
}

function getGroup9BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup9BridgeFormulaPermissible() - offerManager.getRigOps().getGroup9BridgeFormulaActual(), 0);
}

function getGroup10BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup10BridgeFormulaPermissible() - offerManager.getRigOps().getGroup10BridgeFormulaActual(), 0);
}

function getGroup11BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup11BridgeFormulaPermissible() - offerManager.getRigOps().getGroup11BridgeFormulaActual(), 0);
}

function getGroup12BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup12BridgeFormulaPermissible() - offerManager.getRigOps().getGroup12BridgeFormulaActual(), 0);
}

function getGroup13BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup13BridgeFormulaPermissible() - offerManager.getRigOps().getGroup13BridgeFormulaActual(), 0);
}

function getGroup14BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup14BridgeFormulaPermissible() - offerManager.getRigOps().getGroup14BridgeFormulaActual(), 0);
}

function getGroup15BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup15BridgeFormulaPermissible() - offerManager.getRigOps().getGroup15BridgeFormulaActual(), 0);
}

function getGroup16BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup16BridgeFormulaPermissible() - offerManager.getRigOps().getGroup16BridgeFormulaActual(), 0);
}

function getGroup17BridgeOverloadDisplayMask() {
    return offerManager.getUIFormattedMassValue(offerManager.getRigOps().getGroup17BridgeFormulaPermissible() - offerManager.getRigOps().getGroup17BridgeFormulaActual(), 0);
}

function massFormattingFuncNoDecimalPlaces(massVal) {
    return offerManager.getUIFormattedMassValue(massVal, 0);
}

function setupBridgeCellConfigs(rigOpsToUse, usesDataDrivenRulesMethod) {
    cellConfigs = {};
    groupLetters = {};
    letterColourAllocations = {};
    
    //offerManager.clearLegislationQuickLookup();

    var bridgeGroupsVisible = rigOpsToUse.getBridgeGroupsVisible();

    setupGroup1CellConfigs();
    var onlyCountDownAxles, numDownFrontAxles, numDownRearAxles;
    if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE) { 
        if (bridgeGroupsVisible.showExtremeRearGroup) {
            setupGroup2CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup) {
            setupGroup3CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeFrontGroup) {
            setupGroup4CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher1Group) {
            setupGroup5CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            setupGroup6CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            setupGroup7CellConfigs();
        }
        if (bridgeGroupsVisible.showTag1Group) {
            setupGroup8CellConfigs();
        }
        if (bridgeGroupsVisible.showTag2Group) {
            setupGroup9CellConfigs();
        }
        if (bridgeGroupsVisible.showTag3Group) {
            setupGroup10CellConfigs();
        }

    } else if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_SEMI || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK || offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {

        setupGroup2CellConfigs();
        setupGroup3CellConfigs();
        onlyCountDownAxles = true;
        numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
            setupGroup4CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
            setupGroup5CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
            setupGroup6CellConfigs();
        }

        if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
            setupGroup7CellConfigs();
        }
        
        if (bridgeGroupsVisible.showPusher1Group) {
            setupGroup8CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            setupGroup9CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            setupGroup10CellConfigs();
        }
        if (bridgeGroupsVisible.showTag1Group) {
            setupGroup11CellConfigs();
        }
        if (bridgeGroupsVisible.showTag2Group) {
            setupGroup12CellConfigs();
        }
        if (bridgeGroupsVisible.showTag3Group) {
            setupGroup13CellConfigs();
        }
        
        
    } else if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR) {
        
        setupGroup2CellConfigs();
        setupGroup3CellConfigs();
        setupGroup4CellConfigs();
        setupGroup5CellConfigs();
        setupGroup6CellConfigs();
        onlyCountDownAxles = true;
        numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
            setupGroup7CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
            setupGroup8CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
            setupGroup9CellConfigs();
        }

        if (bridgeGroupsVisible.showTrailer1ExtremeFrontGroup) {
            setupGroup10CellConfigs();
        }

        if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
            setupGroup11CellConfigs();
        }

        if (bridgeGroupsVisible.showPusher1Group) {
            setupGroup12CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            setupGroup13CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            setupGroup14CellConfigs();
        }
        if (bridgeGroupsVisible.showTag1Group) {
            setupGroup15CellConfigs();
        }
        if (bridgeGroupsVisible.showTag2Group) {
            setupGroup16CellConfigs();
        }
        if (bridgeGroupsVisible.showTag3Group) {
            setupGroup17CellConfigs();
        }

        //setupGroup2CellConfigs();
        //setupGroup3CellConfigs();
        //setupGroup4CellConfigs();
        //setupGroup5CellConfigs();
        //setupGroup6CellConfigs();
    } else if (offerManager.getRigOps().getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI) {

        setupGroup2CellConfigs();
        setupGroup3CellConfigs();
        setupGroup4CellConfigs();
        setupGroup5CellConfigs();
        setupGroup6CellConfigs();
        onlyCountDownAxles = true;
        numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
            setupGroup7CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
            setupGroup8CellConfigs();
        }
        if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
            setupGroup9CellConfigs();
        }

        if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
            setupGroup10CellConfigs();
        }

        if (bridgeGroupsVisible.showTrailer2ExtremeRearGroup) {
            setupGroup11CellConfigs();
        }

        if (bridgeGroupsVisible.showPusher1Group) {
            setupGroup12CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            setupGroup13CellConfigs();
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            setupGroup14CellConfigs();
        }
        if (bridgeGroupsVisible.showTag1Group) {
            setupGroup15CellConfigs();
        }
        if (bridgeGroupsVisible.showTag2Group) {
            setupGroup16CellConfigs();
        }
        if (bridgeGroupsVisible.showTag3Group) {
            setupGroup17CellConfigs();
        }

        //setupGroup2CellConfigs();
        //setupGroup3CellConfigs();
        //setupGroup4CellConfigs();
        //setupGroup5CellConfigs();
        //setupGroup6CellConfigs();
    }

    function formatUtilisationColumnVal(val) {
        return applyRoundingForOverloadOverload(val) + '%';
    }

    function setupGroup1CellConfigs() {
        // var group1HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP1, offerManager.getCurMeasurementSysRoundedPercentage);
        var group1HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP1, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP1BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP1)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP1BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup1BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP1BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup1BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group1PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP1BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup1BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP1);
            if(elementId !== '') {
                group1PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group1PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group1PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group1PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP1BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup1BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group1HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP1BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup1BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group1HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group1HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP1BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup1BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group1HighlightColour));
    }

    function setupGroup2CellConfigs() {
        // var group2HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP2, offerManager.getCurMeasurementSysRoundedPercentage);
        var group2HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP2, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP2BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP2)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP2BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup2BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP2BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup2BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group2PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP2BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup2BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP2);
            if (elementId !== '') {
                group2PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group2PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group2PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group2PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP2BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup2BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group2HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP2BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup2BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group2HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group2HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP2BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup2BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group2HighlightColour));
    }

    function setupGroup3CellConfigs() {
        // var group3HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP3, offerManager.getCurMeasurementSysRoundedPercentage);
        var group3HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP3, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP3BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP3)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP3BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup3BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP3BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup3BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group3PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP3BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup3BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP3);
            if (elementId !== '') {
                group3PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group3PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group3PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group3PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP3BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup3BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group3HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP3BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup3BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group3HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group3HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP3BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup3BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group3HighlightColour));
    }

    function setupGroup4CellConfigs() {
        // var group4HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP4, offerManager.getCurMeasurementSysRoundedPercentage);
        var group4HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP4, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP4BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP4)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP4BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup4BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP4BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup4BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group4PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP4BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup4BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP4);
            if (elementId !== '') {
                group4PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group4PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group4PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group4PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP4BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup4BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group4HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP4BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup4BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group4HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group4HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP4BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup4BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group4HighlightColour));
    }

    function setupGroup5CellConfigs() {
        // var group5HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP5, offerManager.getCurMeasurementSysRoundedPercentage);
        var group5HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP5, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP5BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP5)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP5BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup5BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP5BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup5BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group5PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP5BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup5BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP5);
            if (elementId !== '') {
                group5PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group5PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group5PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group5PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP5BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup5BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group5HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP5BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup5BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group5HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group5HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP5BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup5BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group5HighlightColour));
    }

    function setupGroup6CellConfigs() {
        // var group6HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP6, offerManager.getCurMeasurementSysRoundedPercentage);
        var group6HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP6, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP6BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP6)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP6BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup6BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP6BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup6BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group6PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP6BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup6BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP6);
            if (elementId !== '') {
                group6PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group6PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group6PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group6PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP6BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup6BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group6HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP6BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup6BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group6HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group6HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP6BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup6BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group6HighlightColour));
    }

    function setupGroup7CellConfigs() {
        // var group7HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP7, offerManager.getCurMeasurementSysRoundedPercentage);
        var group7HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP7, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP7BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP7)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP7BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup7BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP7BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup7BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group7PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP7BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup7BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP7);
            if (elementId !== '') {
                group7PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group7PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group7PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group7PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP7BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup7BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group7HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP7BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup7BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group7HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group7HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP7BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup7BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group7HighlightColour));
    }

    function setupGroup8CellConfigs() {
        // var group8HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP8, offerManager.getCurMeasurementSysRoundedPercentage);
        var group8HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP8, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP8BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP8)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP8BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup8BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP8BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup8BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group8PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP8BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup8BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP8);
            if (elementId !== '') {
                group8PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group8PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group8PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group8PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP8BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup8BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group8HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP8BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup8BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group8HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group8HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP8BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup8BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group8HighlightColour));
    }

    function setupGroup9CellConfigs() {
        // var group9HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP9, offerManager.getCurMeasurementSysRoundedPercentage);
        var group9HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP9, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP9BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP9)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP9BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup9BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP9BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup9BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group9PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP9BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup9BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP9);
            if (elementId !== '') {
                group9PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group9PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group9PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group9PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP9BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup9BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group9HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP9BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup9BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group9HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group9HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP9BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup9BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group9HighlightColour));
    }

    function setupGroup10CellConfigs() {
        // var group10HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP10, offerManager.getCurMeasurementSysRoundedPercentage);
        var group10HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP10, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP10BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP10)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP10BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup10BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP10BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup10BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group10PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP10BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup10BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP10);
            if (elementId !== '') {
                group10PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group10PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group10PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group10PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP10BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup10BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group10HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP10BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup10BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group10HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group10HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP10BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup10BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group10HighlightColour));
    }

    function setupGroup11CellConfigs() {
        // var group11HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP11, offerManager.getCurMeasurementSysRoundedPercentage);
        var group11HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP11, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP11BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP11)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP11BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup11BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP11BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup11BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group11PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP11BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup11BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP11);
            if (elementId !== '') {
                group11PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group11PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group11PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group11PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP11BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup11BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group11HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP11BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup11BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group11HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group11HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP11BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup11BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group11HighlightColour));
    }

    function setupGroup12CellConfigs() {
        // var group12HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP12, offerManager.getCurMeasurementSysRoundedPercentage);
        var group12HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP12, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP12BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP12)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP12BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup12BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP12BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup12BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group12PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP12BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup12BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP12);
            if (elementId !== '') {
                group12PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group12PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group12PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group12PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP12BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup12BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group12HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP12BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup12BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group12HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group12HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP12BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup12BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group12HighlightColour));
    }

    function setupGroup13CellConfigs() {
        // var group13HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP13, offerManager.getCurMeasurementSysRoundedPercentage);
        var group13HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP13, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP13BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP13)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP13BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup13BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP13BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup13BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group13PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP13BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup13BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP13);
            if (elementId !== '') {
                group13PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group13PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group13PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group13PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP13BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup13BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group13HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP13BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup13BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group13HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group13HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP13BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup13BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group13HighlightColour));
    }

    function setupGroup14CellConfigs() {
        // var group14HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP14, offerManager.getCurMeasurementSysRoundedPercentage);
        var group14HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP14, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP14BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP14)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP14BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup14BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP14BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup14BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group14PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP14BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup14BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP14);
            if (elementId !== '') {
                group14PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group14PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group14PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group14PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP14BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup14BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group14HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP14BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup14BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group14HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group14HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP14BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup14BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group14HighlightColour));
    }

    function setupGroup15CellConfigs() {
        // var group15HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP15, offerManager.getCurMeasurementSysRoundedPercentage);
        var group15HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP15, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP15BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP15)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP15BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup15BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP15BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup15BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group15PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP15BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup15BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP15);
            if (elementId !== '') {
                group15PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group15PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group15PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group15PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP15BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup15BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group15HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP15BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup15BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group15HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group15HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP15BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup15BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group15HighlightColour));
    }

    function setupGroup16CellConfigs() {
        // var group16HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP16, offerManager.getCurMeasurementSysRoundedPercentage);
        var group16HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP16, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP16BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP16)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP16BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup16BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP16BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup16BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group16PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP16BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup16BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP16);
            if (elementId !== '') {
                group16PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group16PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group16PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group16PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP16BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup16BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group16HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP16BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup16BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group16HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group16HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP16BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup16BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group16HighlightColour));
    }

    function setupGroup17CellConfigs() {
        // var group17HighlightColour = rigOpsToUse.getGroupOverloadColour(config.BRIDGE_GROUPS.GROUP17, offerManager.getCurMeasurementSysRoundedPercentage);
        var group17HighlightColour = offerManager.getBridgeFormulaGroupOverloadColour(config.BRIDGE_GROUPS.GROUP17, rigOpsToUse, offerManager.getCurMeasurementSysRoundedPercentage, usesDataDrivenRulesMethod);
        
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP17BRIDGEGROUP, config.TABLE_COLUMNS.LABELS).setValue(getBridgeGroup(config.BRIDGE_GROUPS.GROUP17)).setColTitle(config.getTranslationText('1005')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP17BRIDGEDISTANCE, config.TABLE_COLUMNS.BRIDGE_DISTANCE).setValue(rigOpsToUse.getGroup17BridgeFormulaDistance()).setFormattingFunc(offerManager.getUIBridgeFormattedLengthValue).setColTitle(config.getTranslationText('1007')));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP17BRIDGEDISTANCEROUNDED, config.TABLE_COLUMNS.BRIDGE_DISTANCE_ROUNDED).setValue(rigOpsToUse.getGroup17BridgeFormulaDistanceNearest()).setFormattingFunc(offerManager.getUIBridgeNearestFormattedLengthValue).setColTitle(config.getTranslationText('1008')));
        var group17PermissibleCellConfig = new CellConfig(config.VALUE_TYPE.GROUP17BRIDGEPERMISSIBLE, config.TABLE_COLUMNS.BRIDGE_PERMISSIBLE).setValue(rigOpsToUse.getGroup17BridgeFormulaPermissible()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('1009'));
        if (rigOpsToUse.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            var elementId = rigOpsToUse.getBridgeGroupPermissibleHyperlinkElementId(config.BRIDGE_GROUPS.GROUP17);
            if (elementId !== '') {
                group17PermissibleCellConfig.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId));
            } else {
                group17PermissibleCellConfig.setInteraction(undefined);
            }
        } else {
            group17PermissibleCellConfig.setInteraction(undefined);
        }
        addCellConfig(group17PermissibleCellConfig);
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP17BRIDGEACTUAL, config.TABLE_COLUMNS.BRIDGE_ACTUAL).setValue(rigOpsToUse.getGroup17BridgeFormulaActual()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4318')).setColour(group17HighlightColour));
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP17BRIDGEUNUSEDCAPACITY, config.TABLE_COLUMNS.BRIDGE_UNUSED_CAPACITY).setValue(rigOpsToUse.getGroup17BridgeFormulaUnusedCapacity()).setFormattingFunc(massFormattingFuncNoDecimalPlaces).setColTitle(config.getTranslationText('4316')));
        if (group17HighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            group17HighlightColour = undefined;
        }
        addCellConfig(new CellConfig(config.VALUE_TYPE.GROUP17BRIDGEOVERLOAD, config.TABLE_COLUMNS.BRIDGE_OVERLOAD).setValue(100 + rigOpsToUse.getGroup17BridgeFormulaOverload()).setFormattingFunc(formatUtilisationColumnVal).setColTitle(config.getTranslationText('4317')).setColour(group17HighlightColour));
    }
    //}

}

function addCellConfig(cellConfig) {
    cellConfigs[cellConfig.getId()] = cellConfig;
}

function getCellConfig(id/*, attrRootForContext*/) {
    var cc = cellConfigs[id];
    //if (attrRootForContext !== undefined) {
    //cc.setContext(attrRootForContext);
    //}
    return cc;
}

function CellConfig(id, colName) {
    var self = this;

    var _id = id, _colName = colName;

    var _value, _formattingFunc, _colour, _isBold, _interaction, _colTitle, _displayMask;

    function getId() {
        return _id;
    }

    function setId(id) {
        _id = id;
    }

    function getColName() {
        return colName;
    }

    function getValue() {
        return _value;
    }

    function setValue(value) {
        _value = value;
        return self;
    }

    function getFormattingFunc() {
        return _formattingFunc;
    }

    function setFormattingFunc(formattingFunc) {
        _formattingFunc = formattingFunc;
        return self;
    }

    function getFormattedValue() {
        if (_formattingFunc && typeof _formattingFunc === 'function') {
            return _formattingFunc(_value);
        } else {
            return _value;
        }
    }

    function getColour() {
        return _colour;
    }

    function setColour(colour) {
        _colour = colour;
        return self;
    }

    function getInteraction() {
        return _interaction;
    }

    function setInteraction(interaction) {
        _interaction = interaction;
        return self;
    }

    function getColTitle() {
        return _colTitle;
    }

    function setColTitle(colTitle) {
        _colTitle = colTitle;
        return self;
    }

    function getDisplayMask() {
        return _displayMask;
    }

    function setDisplayMask(displayMask) {
        _displayMask = displayMask;
        return self;
    }

    self.getId = getId;
    self.setId = setId;
    self.getColName = getColName;
    self.getValue = getValue;
    self.setValue = setValue;
    self.getFormattingFunc = getFormattingFunc;
    self.setFormattingFunc = setFormattingFunc;
    self.getFormattedValue = getFormattedValue;
    self.getColour = getColour;
    self.setColour = setColour;
    
    self.getInteraction = getInteraction;
    self.setInteraction = setInteraction;
    self.getColTitle = getColTitle;
    self.setColTitle = setColTitle;
    self.getDisplayMask = getDisplayMask;
    self.setDisplayMask = setDisplayMask;


}

function TableRow(id) {

    var self = this;
    var _type = config.TABLE_ROW_TYPE.DATA;
    var _id = id;
    var _rowLabel, _isBold;
    var _cells = [];

    function getId() {
        return _id;
    }
    function getLabel() {
        if (_rowLabel !== undefined) {
            return _rowLabel;
        }
        return _id;
    }
    function setRowLabel(rowLabel) {
        _rowLabel = rowLabel;
    }
    function getIsBold() {
        return _isBold;
    }

    function setIsBold(newValue) {
        _isBold = newValue;
    }
    function addCell(cellConfig) {
        if (!cellConfig.getId()) {
            cellConfig.setId(_id + cellConfig.getColName());
        }
        _cells.push(cellConfig);
    }

    function getCells() {
        return _cells;
    }

    function getType() {
        return _type;
    }

    self.getType = getType;
    self.getId = getId;
    self.addCell = addCell;
    self.getCells = getCells;
    self.getLabel = getLabel;
    self.setRowLabel = setRowLabel;
    self.getIsBold = getIsBold;
    self.setIsBold = setIsBold;
    
}

function UnderlineRow(colour) {
    var self = this;
    var _type = config.TABLE_ROW_TYPE.UNDERLINE;
    var _colour =  colour;

    function getType() {
        return _type;
    }

    function getColour() {
        return _colour;
    }

    self.getType = getType;
    self.getColour = getColour;
    
}

function TableColumnConfig(colName) {
    var self = this;
    var _colName = colName;
    var _bgColour, _textColour, _title, _contentsAlign, _titleFontStyleAddition, _titleFontSize;


    function setBackgroundColour(colour) {
        _bgColour = colour;
        return self;
    }

    function getBackgroundColour() {
        return _bgColour;
    }

    function setTextColour(colour) {
        _textColour = colour;
        return self;
    }

    function getTextColour() {
        return _textColour;
    }

    function setTitle(title) {
        _title = title;
        return self;
    }
    
    function getTitle() {
        return _title;
    }

    function setContentsAlignment(contentsAlign) {
        _contentsAlign = contentsAlign;
        return self;
    }

    function getContentsAlignment() {
        return _contentsAlign;
    }

    function setTitleFontStyleAddition(titleFontStyleAddition) {
        _titleFontStyleAddition = titleFontStyleAddition;
        return self;
    }

    function getTitleFontStyleAddition() {
        return _titleFontStyleAddition;
    }

    function setTitleFontSize(titleFontSize) {
        _titleFontSize = titleFontSize;
        return self;
    }

    function getTitleFontSize() {
        return _titleFontSize;
    }

    function getName() {
        return _colName;
    }

    self.setBackgroundColour = setBackgroundColour;
    self.getBackgroundColour = getBackgroundColour;
    self.setTextColour = setTextColour;
    self.getTextColour = getTextColour;
    self.setTitle = setTitle;
    self.getTitle = getTitle;
    self.setContentsAlignment = setContentsAlignment;
    self.getContentsAlignment = getContentsAlignment;
    self.setTitleFontStyleAddition = setTitleFontStyleAddition;
    self.getTitleFontStyleAddition = getTitleFontStyleAddition;
    self.setTitleFontSize = setTitleFontSize;
    self.getTitleFontSize = getTitleFontSize;
    self.getName = getName;

}

function checkHasOverloadedCol(overloadingColColours) {
    var hasOverloadedCol = false;

    Object.keys(overloadingColColours).forEach(function (legislationKey) {
        Object.keys(overloadingColColours[legislationKey]).forEach(function (colKey) {
            if (overloadingColColours[legislationKey][colKey] === config.TABLE_HIGHLIGHT_COLOURS.FAIL || overloadingColColours[legislationKey][colKey] === config.DRAWING_COLOURS.LIGHT_RED) {
                hasOverloadedCol = true;
            }
        });
    });

    return hasOverloadedCol;
}

function generateTableMetasForActiveLegislations() {

    var legislationTableMetas = {};
   
    var mergedPermissibleValues = offerManager.getMergedLegislationsCombinationPermissibleVals(offerManager.getActiveLegislationsCombinationPermissibleVals());

    var tempRigOpsForTableMetaGeneration = new RigOps();
    tempRigOpsForTableMetaGeneration.setCombinationPermissibleVals(mergedPermissibleValues);
    
    var overloadingColColours = offerManager.getOverloadingColoursForLegislations();
    var hasOverloadedCol = checkHasOverloadedCol(overloadingColColours);

    offerManager.getLegislations().forEach(function (legislationDetails) {
        
        var evaluateLegislationWrapper = function (attributeName) {
            return offerManager.evaluateLegislation(attributeName, legislationDetails, tempRigOpsForTableMetaGeneration)
        };
        // var baseOffer = undefined;
        tempRigOpsForTableMetaGeneration.setup(offerManager.getActiveOffer(), offerManager.getOriginalOffer(), offerManager.getBaseOffer(), evaluateLegislationWrapper, utilLegislationAttributeObjectCode);
        if (offerManager.needToMaximise(tempRigOpsForTableMetaGeneration)) {
            tempRigOpsForTableMetaGeneration.maximisePayloads(mergedPermissibleValues);
        }
        //offerManager.getRigOps().setLegislationEvaluationFunction(evaluateLegislationWrapper);
        //offerManager.clearLegislationQuickLookup();
        //tempRigOpsForSwap.maximisePayloads();
        
        var dontGenerateBridgeViewForLegislation = false;

        //if (offerManager.needToMaximise(tempRigOpsForTableMetaGeneration)) {
        //    tempRigOpsForTableMetaGeneration.maximisePayloads(mergedPermissibleValues);
        //}
        if (curViewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
            
            

            if (evaluateLegislationWrapper(tempRigOpsForTableMetaGeneration.getCurrentCombinationBridgeFormulaUsedAttributeName()) === 1) {
                let usesDataDrivenRulesMethod = offerManager.getLegislationUsesDataDrivenRuleMethod(legislationDetails.id); //evaluateLegislationWrapper('USESDATADRIVENRULESMETHOD') === 1 ? true : false;
                setupBridgeCellConfigs(tempRigOpsForTableMetaGeneration, usesDataDrivenRulesMethod);
            } else {
                dontGenerateBridgeViewForLegislation = true;
            }
        } 
        if (!dontGenerateBridgeViewForLegislation) {
            legislationTableMetas[legislationDetails.id] = generateTableMeta(curVehicle, curDisplayType, curViewDetail, legislationDetails, tempRigOpsForTableMetaGeneration, hasOverloadedCol, mergedPermissibleValues);
        }
        
    });
    
    return legislationTableMetas;
}

function generateMergedTableMeta(legislationTableMetas) {
    
    if (Object.keys(legislationTableMetas).length === 1) {
        return legislationTableMetas[Object.keys(legislationTableMetas)[0]];
    }

    var mergedTableData = JSON.parse(JSON.stringify(legislationTableMetas[Object.keys(legislationTableMetas)[0]]));

    Object.keys(mergedTableData.cols).forEach(function (colKey, colKeyIndex) {
        
        Object.keys(mergedTableData.cols[colKey].cellData).forEach(function (rowKey, rowKeyIndex) {
            
            if (!isNaN(parseFloat(mergedTableData.cols[colKey].cellData[rowKey].value)) && colKey !== 'Labels') {
                var lowestVal = 999999999;
                var lowestValLegislatioKey = '';
                Object.keys(legislationTableMetas).forEach(function (legislationKey, innermostIndex) {
                    
                    var curLegislationCellVal = 0;
                    if (typeof legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value === 'string') {
                        curLegislationCellVal = parseFloat(legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value.replace(/[^.\d]/g, ""));
                    } else {
                        curLegislationCellVal = legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value;
                    }

                    if (curLegislationCellVal < lowestVal) {
                        lowestVal = curLegislationCellVal;
                        lowestValLegislatioKey = legislationKey;
                    }
                    if (mergedTableData.cols[colKey].cellData[rowKey].colour || legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].colour) {
                        if (legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].colour === config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD) {
                            mergedTableData.cols[colKey].cellData[rowKey].colour = legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].colour; 
                        } else {
                            mergedTableData.cols[colKey].cellData[rowKey].colour = offerManager.returnWorstColourStatus(mergedTableData.cols[colKey].cellData[rowKey].colour, legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].colour); 
                        }
                        
                    }
                    
                });
                mergedTableData.cols[colKey].cellData[rowKey].value = legislationTableMetas[lowestValLegislatioKey].cols[colKey].cellData[rowKey].value;
                if (mergedTableData.cols[colKey].cellData[rowKey].interaction) {
                    mergedTableData.cols[colKey].cellData[rowKey].interaction = legislationTableMetas[lowestValLegislatioKey].cols[colKey].cellData[rowKey].interaction;
                }
            }
            
        });
        
    });

    return mergedTableData;
    //for (var member in obj) {
    //    if (obj[member] && obj[member].cleanUp) {
    //        obj[member].cleanUp();
    //    }
    //    if (typeof obj[member] === 'object' && obj[member] !== null && shallowOnly !== true) {
    //        deleteObjectMembers(obj[member]);
    //    }
    //    delete obj[member];
    //};

}

function generateMergedBridgeTableMeta(legislationTableMetas) {

    if (Object.keys(legislationTableMetas).length === 1) {
        return legislationTableMetas[Object.keys(legislationTableMetas)[0]];
    }

    //var intermediateTableData = JSON.parse(JSON.stringify(legislationTableMetas[Object.keys(legislationTableMetas)[0]]));
    var mergedTableData = {};
    //Object.keys(legislationTableMetas).forEach(function (legislationKey, i) {
    //    console.time('outer most merge loop ' + i);
    //    if(i > 0) {
    //        Object.keys(legislationTableMetas[legislationKey].cols).forEach(function (colKey, colIndex) {
    //            console.time('middle merge loop ' + colIndex);
    //            if (!mergedTableData.cols) {
    //                mergedTableData.cols = {};
    //            }
                
    //            if (intermediateTableData.cols[colKey]) {
    //                mergedTableData.cols[colKey] = intermediateTableData.cols[colKey];
    //                shallowCopy(intermediateTableData.cols[colKey], mergedTableData.cols[colKey]);
    //            } else if (legislationTableMetas[legislationKey].cols[colKey]) {
    //                mergedTableData.cols[colKey] = legislationTableMetas[legislationKey].cols[colKey];
    //                shallowCopy(legislationTableMetas[legislationKey].cols[colKey], mergedTableData.cols[colKey]);
    //            }

                

    //            //if (!mergedTableData.cols[colKey]) {
    //            //    mergedTableData.cols[colKey] = legislationTableMetas[legislationKey].cols[colKey];
    //            //}

    //            Object.keys(legislationTableMetas[legislationKey].cols[colKey].cellData).forEach(function (rowKey, rowIndex) {
    //                console.time('merge innermost loop ' + rowIndex);
    //                console.time('merge innermost loop ' + rowIndex + ' conditional 1');
    //                if (!mergedTableData.cols[colKey].cellData) {
    //                    mergedTableData.cols[colKey].cellData = {};
    //                }
    //                console.timeEnd('merge innermost loop ' + rowIndex + ' conditional 1');
    //                console.time('merge innermost loop ' + rowIndex + ' conditional 2');
    //                if (intermediateTableData.cols[colKey] !== undefined) {
    //                    if (intermediateTableData.cols[colKey].cellData !== undefined) {
    //                        if (intermediateTableData.cols[colKey].cellData[rowKey] !== undefined) {
    //                            console.time('merge innermost loop ' + rowIndex + ' assignment');
    //                            mergedTableData.cols[colKey].cellData[rowKey] = intermediateTableData.cols[colKey].cellData[rowKey];
    //                            console.timeEnd('merge innermost loop ' + rowIndex + ' assignment');
    //                            console.time('merge innermost loop ' + rowIndex + ' copy');
    //                            shallowCopy(intermediateTableData.cols[colKey].cellData[rowKey], mergedTableData.cols[colKey].cellData[rowKey]);
    //                            console.timeEnd('merge innermost loop ' + rowIndex + ' copy');
    //                        }
    //                    }                                
                        
    //                } else if (legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey]) {
    //                    console.time('merge innermost loop ' + rowIndex + ' assignment');
    //                    mergedTableData.cols[colKey].cellData[rowKey] = legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey];
    //                    console.timeEnd('merge innermost loop ' + rowIndex + ' assignment');
    //                    console.time('merge innermost loop ' + rowIndex + ' copy');
    //                    shallowCopy(legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey], mergedTableData.cols[colKey].cellData[rowKey]);
    //                    console.timeEnd('merge innermost loop ' + rowIndex + ' copy');
    //                }
    //                console.timeEnd('merge innermost loop ' + rowIndex + ' conditional 2');
    //                //if (!mergedTableData.cols[colKey].cellData[rowKey]) {
    //                //    mergedTableData.cols[colKey].cellData[rowKey] = legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey];
    //                //}
                    
    //                //Object.keys(legislationTableMetas).forEach(function (legislationKey) {
    //                //    var curLegislationCellVal = 0;
    //                //    if (typeof legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value === 'string') {
    //                //        curLegislationCellVal = parseFloat(legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value.replace(/[^\.\d]/g, ""));
    //                //    } else {
    //                //        curLegislationCellVal = legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value;
    //                //    }

    //                //    if (curLegislationCellVal < lowestVal) {
    //                //        lowestVal = curLegislationCellVal;
    //                //        lowestValLegislatioKey = legislationKey;
    //                //    }
    //                //    if (mergedTableData.cols[colKey].cellData[rowKey].colour) {
    //                //        mergedTableData.cols[colKey].cellData[rowKey].colour = offerManager.returnWorstColour(mergedTableData.cols[colKey].cellData[rowKey].colour, legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].colour);
    //                //    }
    //                //});
    //                //mergedTableData.cols[colKey].cellData[rowKey].value = legislationTableMetas[lowestValLegislatioKey].cols[colKey].cellData[rowKey].value;
    //                //if (mergedTableData.cols[colKey].cellData[rowKey].interaction) {
    //                //    mergedTableData.cols[colKey].cellData[rowKey].interaction = legislationTableMetas[lowestValLegislatioKey].cols[colKey].cellData[rowKey].interaction;
    //                //}
    //                console.timeEnd('merge innermost loop ' + rowIndex);
    //            });
    //            console.timeEnd('middle merge loop ' + colIndex);
    //        }); 
    //        shallowCopy(legislationTableMetas[legislationKey], mergedTableData);
    //        intermediateTableData = mergedTableData;
    //        mergedTableData = {};
    //    }
    //    console.timeEnd('outer most merge loop ' + i);
    //});
    Object.keys(legislationTableMetas).forEach(function (legislationKey, i) {
        //mergedTableData = {
        //    ...mergedTableData,
        //    ...legislationTableMetas[legislationKey]
        //}
        mergedTableData = mergeObjects(mergedTableData, legislationTableMetas[legislationKey]);

        //mergedTableData.cols = {
        //    ...mergedTableData.cols,
        //    ...legislationTableMetas[legislationKey].cols
        //}
        mergedTableData.cols = mergeObjects(mergedTableData.cols, legislationTableMetas[legislationKey].cols);

        Object.keys(legislationTableMetas[legislationKey].cols).forEach(function (colKey, colIndex) {
            //mergedTableData.cols[colKey].cellData = {
            //    ...mergedTableData.cols[colKey].cellData,
            //    ...legislationTableMetas[legislationKey].cols[colKey].cellData
            //}
            mergedTableData.cols[colKey].cellData = mergeObjects(mergedTableData.cols[colKey].cellData, legislationTableMetas[legislationKey].cols[colKey].cellData);
            
            
            

            //if (intermediateTableData.cols[colKey]) {
            //    mergedTableData.cols[colKey] = intermediateTableData.cols[colKey];
            //    shallowCopy(intermediateTableData.cols[colKey], mergedTableData.cols[colKey]);
            //} else if (legislationTableMetas[legislationKey].cols[colKey]) {
            //    mergedTableData.cols[colKey] = legislationTableMetas[legislationKey].cols[colKey];
            //    shallowCopy(legislationTableMetas[legislationKey].cols[colKey], mergedTableData.cols[colKey]);
            //}



            //if (!mergedTableData.cols[colKey]) {
            //    mergedTableData.cols[colKey] = legislationTableMetas[legislationKey].cols[colKey];
            //}

            //Object.keys().forEach(function (rowKey, rowIndex) {
            //    console.time('merge innermost loop ' + rowIndex);
            //    console.time('merge innermost loop ' + rowIndex + ' conditional 1');
            //    if (!mergedTableData.cols[colKey].cellData) {
            //        mergedTableData.cols[colKey].cellData = {};
            //    }
            //    console.timeEnd('merge innermost loop ' + rowIndex + ' conditional 1');
            //    console.time('merge innermost loop ' + rowIndex + ' conditional 2');
            //    if (intermediateTableData.cols[colKey] !== undefined) {
            //        if (intermediateTableData.cols[colKey].cellData !== undefined) {
            //            if (intermediateTableData.cols[colKey].cellData[rowKey] !== undefined) {
            //                console.time('merge innermost loop ' + rowIndex + ' assignment');
            //                mergedTableData.cols[colKey].cellData[rowKey] = intermediateTableData.cols[colKey].cellData[rowKey];
            //                console.timeEnd('merge innermost loop ' + rowIndex + ' assignment');
            //                console.time('merge innermost loop ' + rowIndex + ' copy');
            //                shallowCopy(intermediateTableData.cols[colKey].cellData[rowKey], mergedTableData.cols[colKey].cellData[rowKey]);
            //                console.timeEnd('merge innermost loop ' + rowIndex + ' copy');
            //            }
            //        }

            //    } else if (legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey]) {
            //        console.time('merge innermost loop ' + rowIndex + ' assignment');
            //        mergedTableData.cols[colKey].cellData[rowKey] = legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey];
            //        console.timeEnd('merge innermost loop ' + rowIndex + ' assignment');
            //        console.time('merge innermost loop ' + rowIndex + ' copy');
            //        shallowCopy(legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey], mergedTableData.cols[colKey].cellData[rowKey]);
            //        console.timeEnd('merge innermost loop ' + rowIndex + ' copy');
            //    }
            //    console.timeEnd('merge innermost loop ' + rowIndex + ' conditional 2');
                    
            //    console.timeEnd('merge innermost loop ' + rowIndex);
            //});
            
        });
        //shallowCopy(legislationTableMetas[legislationKey], mergedTableData);
        //intermediateTableData = mergedTableData;
        
    });
    //mergedTableData = intermediateTableData;
    sortRowOrder(mergedTableData);
    Object.keys(mergedTableData.cols).forEach(function (colKey) {
        Object.keys(mergedTableData.cols[colKey].cellData).forEach(function (rowKey) {
            
            if (!isNaN(parseFloat(mergedTableData.cols[colKey].cellData[rowKey].value))) {
                var lowestVal = 999999999;
                var lowestValLegislatioKey = '';
                Object.keys(legislationTableMetas).forEach(function (legislationKey) {
                    if (mergedTableData.cols[colKey] && legislationTableMetas[legislationKey].cols[colKey] && mergedTableData.cols[colKey].cellData[rowKey] && legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey]) {
                        var curLegislationCellVal = 0;
                        if (typeof legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value === 'string') {
                            curLegislationCellVal = parseFloat(legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value.replace(/[^.\d]/g, ""));
                        } else {
                            curLegislationCellVal = legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].value;
                        }

                        if (curLegislationCellVal < lowestVal) {
                            lowestVal = curLegislationCellVal;
                            lowestValLegislatioKey = legislationKey;
                        }
                        if (mergedTableData.cols[colKey].cellData[rowKey].colour) {
                            mergedTableData.cols[colKey].cellData[rowKey].colour = offerManager.returnWorstColourStatus(mergedTableData.cols[colKey].cellData[rowKey].colour, legislationTableMetas[legislationKey].cols[colKey].cellData[rowKey].colour);
                        }
                    }
                });
                mergedTableData.cols[colKey].cellData[rowKey].value = legislationTableMetas[lowestValLegislatioKey].cols[colKey].cellData[rowKey].value;
                if (mergedTableData.cols[colKey].cellData[rowKey].interaction) {
                    mergedTableData.cols[colKey].cellData[rowKey].interaction = legislationTableMetas[lowestValLegislatioKey].cols[colKey].cellData[rowKey].interaction;
                }
            }
            
        });
    });

    return mergedTableData;

    function mergeObjects(objA, objB) {
        var combined = {};
        var key;
        for (key in objA) { 
            if (!combined[key]){
                combined[key] = objA[key]; 
            }
        }
        for (key in objB) { 
            if (!combined[key]) {
                combined[key] = objB[key]; 
            }
        }
        return combined;
    }

    function sortRowOrder(mergedTableData) {
        var colCells = [];
        var colCellKeys = {};
        Object.keys(mergedTableData.cols.Labels.cellData).forEach(function (rowKey) {
            colCells.push(mergedTableData.cols.Labels.cellData[rowKey].value);
            colCellKeys[mergedTableData.cols.Labels.cellData[rowKey].value] = rowKey;
        });
        colCells.sort(ascendingRange);

        Object.keys(mergedTableData.cols).forEach(function (colKey) {
            var orderedCells = {};
            colCells.forEach(function (rowKey) {
                orderedCells[colCellKeys[rowKey]] = mergedTableData.cols[colKey].cellData[colCellKeys[rowKey]];
                //colCells.push(mergedTableData.cols.Labels.cellData[rowKey]);
                //colCellKeys[mergedTableData.cols.Labels.cellData[rowKey].value] = rowKey;
            });
            mergedTableData.cols[colKey].cellData = orderedCells;
        });

    }
    function ascendingRange(a, b) {
        var aNums = a.split('-'), bNums = b.split('-');
        if (aNums.length > 1 && bNums.length > 1) {
            var aNum1 = aNums[0].trim(), aNum2 = aNums[1].trim(), bNum1 = bNums[0].trim(), bNum2 = bNums[1].trim(); 
            if (aNum2 === aNum1 && bNum1 === bNum2) {
                if (aNum2 < bNum2) {
                    return 1;
                } if (aNum2 < bNum2) {
                    return -1;
                } else {
                    return 0;
                }
            } if (aNum2 === aNum1 && bNum2 !== bNum1) {
                if (aNum2 < bNum2) {
                    return -1;
                } if (aNum2 > bNum2) {
                    return 1;
                } else {
                    return 0;
                }
            } if (aNum2 !== aNum1 && bNum2 === bNum1) {
                if (aNum2 > bNum2) {
                    return 1;
                } if (aNum2 < bNum2) {
                    return -1;
                } else {
                    return 0;
                }
            }else if (aNum2 - aNum1 < bNum2 - bNum1) {
                return -1;
            } else if (aNum2 - aNum1 === bNum2 - bNum1){
                if (aNum2 > bNum2) {
                    return 1;
                } if (aNum2 < bNum2) {
                    return -1;
                } if (aNum2 === bNum2) {
                    if (aNum1 < bNum1) {
                        return -1;
                    } else {
                        return 1;
                    }
                    
                } else {
                    return 0;
                }
            } else if (aNum2 - aNum1 > bNum2 - bNum1) {
                return 1;
            }
        }
        return 0;
    }
    function shallowCopy(obj1, obj2) {
        for (var member in obj1) {
            if (obj1[member] && typeof obj1[member] !== 'object' && !obj2[member]) {
                obj2[member] = obj1[member];
            }
            
        }
    }
    //function mergeToNewObject() {

    //    var newObject = {};

    //    function cop
    //    for (var member in obj) {
    //        if (obj[member] && obj[member].cleanUp) {
    //            obj[member].cleanUp();
    //        }
    //        if (typeof obj[member] === 'object' && obj[member] !== null && shallowOnly !== true) {
    //            deleteObjectMembers(obj[member]);
    //        }
    //        delete obj[member];
    //    };

    //    return nweObject;
    //}

}

function getCellHeight(cellWidth) {
    return getHeightOfText("99,999.9 kg", curFontName, tableFontSize, cellWidth) + (generalBuffer * 2);
}

function getCellWidth() {
    return getWidthOfText(" 99,999.9 kg ", curFontName, tableFontSize) + (margin * 8);
}

function generateTableMeta(vehicle, displayType, viewDetail, legislationDetails, rigOpsToUse, hasOverloadedCol, optionalPermissibleValues) {
    let encodedLegislationId = legislationDetails.id
    let hasLegislationARegulationUnknown = offerManager.hasLegislationARegulationUnknown(legislationDetails, rigOpsToUse);
    //offerManager.getRigOps().setLegislationEvaluationFunction(legislationEvaluationFunc);

    var alternateColColour = config.DRAWING_COLOURS.ALT_TABLE_CELL_GREY;
    var alternateCellTextColour = config.DRAWING_COLOURS.ALT_TABLE_CELL_TEXT;

    colLetters = {};
    

    var formattingPrecision = config.roundingPrecisionForTableAndWarnings;

    var underlineCounter = 0;
    var rigOps = rigOpsToUse;

    var isTrialUser = false;
    //if (globals.user.isTrialUser() === true) {
    //    isTrialUser = true;
    //}
    var hasPusherOrTagDown = curVehicle.getAxlesHolder().getHasPusherOrTagAxleDown();
    //var frontStartPosition = Math.abs(vehicle.getPortionOfOverallLengthOverhangingFront() + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToAxle2Front() / 2);
    //var rearStartPosition = Math.abs(vehicle.getPortionOfOverallLengthOverhangingFront() + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToAxle2Front() +
    //                         vehicle.getAxle2FrontToAxle1Rear() + (vehicle.getAxle1RearToAxle2Rear() +
    //                         vehicle.getAxle2RearToAxle3Rear() + vehicle.getAxle3RearToAxle4Rear()) / 2);
    //var totalStartPosition = Math.abs((frontStartPosition + rearStartPosition) / 2);

    var cellWidth = getCellWidth();
    var cellHeight = getCellHeight(cellWidth);

    var trialUserObfuscationText = offerManager.getTrialUserObfuscationTextWithCurrentMassUnit();
    var xXedOutValuesInteractionMetaObject;
    if (isTrialUser) {
        xXedOutValuesInteractionMetaObject = offerManager.getTrialUserXValuesInteractionMetaObject(trialUserObfuscationText);
    }
    
    
    function getRearAxlesTitleAppend(colName, objWithAxles, numPreviousAxles) {
        var numFrontAxles = objWithAxles.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        numFrontAxles += objWithAxles.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
        //var numRearAxles = curVehicle.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        var numRearAxles = objWithAxles.getAxlesHolder().getAxles().length - numFrontAxles;

        if (numRearAxles > 1) {
            //var firstRearAxleLetter = String.fromCharCode(65 + numPreviousAxles + (numFrontAxles));
            //var lastRearAxleLetter = String.fromCharCode(65 + numPreviousAxles + ((numFrontAxles + numRearAxles) - 1));
            var firstDownAxleNumber = objWithAxles.getAxlesHolder().getAxleByPosition(objWithAxles.getAxlesHolder().getFrontMostDownAxlePositionAtRear()).getNumber();
            //var firstRearAxleLetter = numPreviousAxles + numFrontAxles + 1;
            //var lastRearAxleLetter = numPreviousAxles + numFrontAxles + numRearAxles;
            var lastDownAxleNumber = objWithAxles.getAxlesHolder().getAxleByPosition(objWithAxles.getAxlesHolder().getRearMostDownAxlePositionAtRear()).getNumber();
            colLetters[colName] = {};
            colLetters[colName].letters = [firstDownAxleNumber, lastDownAxleNumber];
            if (firstDownAxleNumber === lastDownAxleNumber) {
                return '(' + lastDownAxleNumber + ')';
            } else {
                return '(' + firstDownAxleNumber + ' - ' + lastDownAxleNumber + ')';
            }
            
        } else {
            //var letter = String.fromCharCode(65 + numPreviousAxles + ((numFrontAxles + numRearAxles) - 1));
            var letter = numPreviousAxles + numFrontAxles + numRearAxles;
            colLetters[colName] = {};
            colLetters[colName].letters = [letter];
            return '(' + letter + ')';
        }

    }

    function getRearConnectedAxlesTitleAppend(colName) {
        var numRearAxles = curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        
        var rearAxleNumbers = [];
        curVehicle.getAxles().forEach(function (axle) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                rearAxleNumbers.push(axle.getNumber());
            }
        });

        //var numRearAxles = curVehicle.getAxles().length - numFrontAxles;

        if (numRearAxles > 1) {
            //var firstRearAxleLetter = String.fromCharCode(65 + (rearAxleNumbers[0]-1));
            //var lastRearAxleLetter = String.fromCharCode(65 + (rearAxleNumbers[rearAxleNumbers.length-1] - 1));
            var firstRearAxleLetter = rearAxleNumbers[0];
            var lastRearAxleLetter = rearAxleNumbers[rearAxleNumbers.length - 1];
            colLetters[colName] = {};
            colLetters[colName].letters = [firstRearAxleLetter, lastRearAxleLetter];

            return '(' + firstRearAxleLetter + ' - ' + lastRearAxleLetter + ')';
        } else {
            //var letter = String.fromCharCode(65 + (rearAxleNumbers[0] - 1));
            var letter = rearAxleNumbers[0];
            colLetters[colName] = {};
            colLetters[colName].letters = [letter];

            return '(' + letter + ')';
        }
    }

    function getFrontAxlesTitleAppend(colName, objWithAxles, numPrevAxles) {

        var numPreviousAxles = numPrevAxles || 0;

        var numFrontAxles = objWithAxles.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        numFrontAxles += objWithAxles.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);

        if (numFrontAxles > 1) {
            //var firstFrontAxleLetter = String.fromCharCode(65 + numPreviousAxles);
            //var lastFrontAxleLetter = String.fromCharCode(65 + numPreviousAxles + (numFrontAxles - 1));
            var firstFrontAxleLetter = numPreviousAxles + 1;
            var lastFrontAxleLetter = numPreviousAxles + 1 + (numFrontAxles - 1);

            colLetters[colName] = {};
            colLetters[colName].letters = [firstFrontAxleLetter, lastFrontAxleLetter];

            return '(' + firstFrontAxleLetter + ' - ' + lastFrontAxleLetter + ')';
        } else if (numFrontAxles === 0) {
            return '';
        } else {
            //var letter = String.fromCharCode(65 + numPreviousAxles);
            var letter = numPreviousAxles + 1;
            colLetters[colName] = {};
            colLetters[colName].letters = [letter];
            
            return '(' + letter + ')';
        }

    }

    function getFrontColPrefix() {
        return curTrailer1 === null ? '' : config.getTranslationText('532');
    }

    function getRearColPrefix() {
        //return curTrailer1 === null ? '' : viewDetail !== config.VIEW_DETAIL.TRAILER1 ? config.getTranslationText('532') : config.getTranslationText('671');
        return curTrailer1 === null ? '' : viewDetail !== config.VIEW_DETAIL.TRAILER1 ? config.getTranslationText('532') : config.getTranslationText('671');
    }

    function getTableTitle(viewDetailType) {
        if (viewDetailType === config.VIEW_DETAIL.SUMMARY) {
            return config.getTranslationText('4303');
        } else if (viewDetailType === config.VIEW_DETAIL.TRAILER1) {
            return config.getTranslationText('4304');
        } else if (viewDetailType === config.VIEW_DETAIL.TRAILER2) {
            return config.getTranslationText('4305');
        }
    }

    var tableMeta = getTableBasicSetup();

    //var overloadingColColours = offerManager.generateOverloadingColColours(legislationEvaluationFunc, 'GROSS');
    var overloadingColColours = offerManager.getOverloadingColoursForLegislation(encodedLegislationId);

    function getTableBasicSetup(forcedViewDetail) {

        

        var tableBasic = {};

        var viewDetailToUse = forcedViewDetail || viewDetail

        tableBasic.cellWidth = cellWidth;
        tableBasic.cellHeight = cellHeight;
        tableBasic.labelBuffer = 10;
        tableBasic.titleRowBackgroundColour = config.DRAWING_COLOURS.TABLE_TITLE_BACKGROUND;
        tableBasic.titleCellBorderColour = config.DRAWING_COLOURS.TABLE_TITLE_BACKGROUND;
        
        tableBasic.cols = {};

        if (viewDetailToUse !== config.VIEW_DETAIL.BRIDGE_VIEW && viewDetailToUse !== config.VIEW_DETAIL.CHASSIS && viewDetailToUse !== config.VIEW_DETAIL.VEHICLE) {
            
            tableBasic.cols.Labels = {
                title: getTableTitle(viewDetailToUse),
                titleFontStyleAddition: 'bold',
                titleFontSize: dashboardAndTableTitleFontSize,
                position: { x: 0/*frontStartPosition*/, y: 0 },
                cellData: {},
                colour: alternateColColour,
                contentsAlign: 'left'
            }


            if (viewDetailToUse !== config.VIEW_DETAIL.TRAILER1 && viewDetailToUse !== config.VIEW_DETAIL.TRAILER2) {
                //var frontColTitle = getFrontColPrefix() + ' ' + config.getTranslationText('153') + ' ' + getFrontAxlesTitleAppend('front', curVehicle);
                var frontColTitle;
                if (curTrailer1 === null) {
                    frontColTitle = config.getTranslationText('153') + ' ' + getFrontAxlesTitleAppend('front', curVehicle);
                } else {
                    frontColTitle = getFrontColPrefix() + ' ' + getFrontAxlesTitleAppend('front', curVehicle);
                }
                
                tableBasic.cols.front = {
                    title: frontColTitle,
                    titleFontStyleAddition: 'oblique',
                    position: { x: 0/*frontStartPosition*/, y: 0 },
                    cellData: {},
                    colour: alternateColColour
                }
            }

            if (hasPusherOrTagDown === true && (viewDetailToUse === config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST || viewDetailToUse === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION || viewDetailToUse === config.VIEW_DETAIL.SUMMARY || viewDetailToUse === config.VIEW_DETAIL.EXTRAS || viewDetailToUse === config.VIEW_DETAIL.CHASSIS)) {
                var pusherCounter = 1;
                var tagCounter = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR) - curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.TAG) + 1;//2; 
                var rearConnectedAdded = false;

                curVehicle.getAxles().forEach(function (axle) {
                    var colName = '', letter;
                    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                        if (!axle.getRaised()) {
                            colName = config.TABLE_COLUMNS.PUSHER + '_' + pusherCounter + '_' + axle.getNumber();
                            //var letter = String.fromCharCode(65 + (axle.getNumber() - 1));
                            letter = axle.getNumber();
                            colLetters[colName] = {};
                            colLetters[colName].letters = [letter];

                            tableBasic.cols[colName] = {};
                            //tableBasic.cols[colName].title = config.getTranslationText('314') + ' ' + '(' + letter + ')';
                            //tableBasic.cols[colName].title = config.getTranslationText('2777') + ' ' + '(' + letter + ')';
                            tableBasic.cols[colName].title = config.getTranslationText('2777', [letter]);
                            tableBasic.cols[colName].titleFontStyleAddition = 'oblique',
                            tableBasic.cols[colName].position = { x: 0, y: 0 };
                            tableBasic.cols[colName].cellData = {};
                            tableBasic.cols[colName].textColour = alternateCellTextColour;
                        }
                        pusherCounter++;
                    } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {

                        if (rearConnectedAdded === false) {
                            colName = config.TABLE_COLUMNS.REAR_CONNECTED;
                            tableBasic.cols[colName] = {};
                            //tableBasic.cols[colName].title = config.getTranslationText('322') + ' ' + getRearConnectedAxlesTitleAppend(config.TABLE_COLUMNS.REAR_CONNECTED);
                            //tableBasic.cols[colName].title = config.getTranslationText('2778') + ' ' + getRearConnectedAxlesTitleAppend(config.TABLE_COLUMNS.REAR_CONNECTED);
                            tableBasic.cols[colName].title = config.getTranslationText('2778') + ' ' + getRearConnectedAxlesTitleAppend(config.TABLE_COLUMNS.REAR_CONNECTED);
                            tableBasic.cols[colName].titleFontStyleAddition = 'oblique',
                            tableBasic.cols[colName].position = { x: 0, y: 0 };
                            tableBasic.cols[colName].cellData = {}
                            tableBasic.cols[colName].textColour = alternateCellTextColour;

                            rearConnectedAdded = true;
                        }

                    } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                        if (!axle.getRaised()) {
                            colName = config.TABLE_COLUMNS.TAG + '_' + tagCounter + '_' + axle.getNumber();
                            //var letter = String.fromCharCode(65 + (axle.getNumber() - 1));
                            letter = axle.getNumber();
                            colLetters[colName] = {};
                            colLetters[colName].letters = [letter];

                            tableBasic.cols[colName] = {};
                            //tableBasic.cols[colName].title = config.getTranslationText('467') + ' ' + '(' + letter + ')';
                            //tableBasic.cols[colName].title = config.getTranslationText('467') + ' ' + '(' + letter + ')';
                            tableBasic.cols[colName].title = config.getTranslationText('3065', [letter]);
                            tableBasic.cols[colName].titleFontStyleAddition = 'oblique',
                            tableBasic.cols[colName].position = { x: 0, y: 0 };
                            tableBasic.cols[colName].cellData = {}
                            tableBasic.cols[colName].textColour = alternateCellTextColour;
                        }
                        tagCounter++;
                    }
                });
            }
            var numPreviousAxles = curVehicle.getAxlesHolder().getAxles().length;
            if (viewDetailToUse !== config.VIEW_DETAIL.TRAILER1 && viewDetailToUse !== config.VIEW_DETAIL.TRAILER2) {
                //var rearColTitle = getRearColPrefix() + ' ' + (viewDetailToUse === config.VIEW_DETAIL.TRAILER1 ? config.getTranslationText('153') : config.getTranslationText('2778', [getRearAxlesTitleAppend('rear', curVehicle, 0)]));
                var rearColTitle;
                if (curTrailer1 === null) {
                    rearColTitle = config.getTranslationText('2778') + ' ' + getRearAxlesTitleAppend('rear', curVehicle, 0);
                } else {
                    rearColTitle = getRearColPrefix() + ' ' + getRearAxlesTitleAppend('rear', curVehicle, 0);
                }
                
                tableBasic.cols.rear = {
                    title: rearColTitle,
                    titleFontStyleAddition: 'oblique',
                    position: { x: 0/*rearStartPosition*/, y: 0 },
                    cellData: {},
                    colour: alternateColColour
                }
            }

            if (((curTrailer1 !== null && curTrailer2 === null && viewDetailToUse !== config.VIEW_DETAIL.EXTRAS && viewDetailToUse !== config.VIEW_DETAIL.CHASSIS) || (curTrailer1 !== null && curTrailer2 !== null && viewDetailToUse !== config.VIEW_DETAIL.EXTRAS && viewDetailToUse !== config.VIEW_DETAIL.CHASSIS) && (viewDetailToUse === config.VIEW_DETAIL.TRAILER1 || viewDetailToUse === config.VIEW_DETAIL.TRAILER2))/*(viewDetail === config.VIEW_DETAIL.SUMMARY || viewDetail === config.VIEW_DETAIL.TRAILER1)*/) {
                if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || viewDetailToUse === config.VIEW_DETAIL.TRAILER1 || viewDetailToUse === config.VIEW_DETAIL.TRAILER2) {
                    var colTitle, chassisObjectToUse;
                    if ((curTrailer2 === null && curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) || viewDetailToUse === config.VIEW_DETAIL.TRAILER1) {
                        chassisObjectToUse = curTrailer1;
                        
                        if (viewDetailToUse === config.VIEW_DETAIL.TRAILER1 && (curTrailer2 !== null || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK || curTrailer1.getType() === config.TRAILER_TYPES.SEMI)) {
                            //colTitle = config.getTranslationText('671') + ' 1 ' + config.getTranslationText('153') + ' ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                            // colTitle = config.getTranslationText('671') + ' 1 ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                            colTitle = config.getTranslationText('4612') + ' ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                        } else if (viewDetailToUse === config.VIEW_DETAIL.TRAILER1 && curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            colTitle = config.getTranslationText('4638');
                        } else {
                            //colTitle = config.getTranslationText('671') + ' ' + config.getTranslationText('153') + ' ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                            colTitle = config.getTranslationText('671') + ' ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                        }
                        
                    } else if(viewDetailToUse === config.VIEW_DETAIL.TRAILER2) {
                        chassisObjectToUse = curTrailer2;
                        numPreviousAxles = curVehicle.getAxlesHolder().getAxles().length + curTrailer1.getAxlesHolder().getAxles().length;
                        //colTitle = config.getTranslationText('671') + ' 2 ' + config.getTranslationText('153') + ' ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                        // colTitle = config.getTranslationText('671') + ' 2 ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                        colTitle = config.getTranslationText('4613') + ' ' + getFrontAxlesTitleAppend('trailerFront', chassisObjectToUse, numPreviousAxles);
                    }

                    tableBasic.cols.trailerFront = {
                        title: colTitle,
                        titleFontStyleAddition: 'oblique',
                        position: { x: 0, y: 0 },
                        cellData: {},
                        colour: alternateColColour
                    }
                }
                if (curTrailer2 === null) {
                    
                    //if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                        //tableBasic.cols.trailerRear = {
                        //    title: config.getTranslationText('671') + ' ' + config.getTranslationText('322') + ' ' + getRearAxlesTitleAppend('trailerRear', curTrailer1, curVehicle.getAxlesHolder().getAxles().length),
                        //    position: { x: 0, y: generalBuffer },
                        //    cellData: {}
                        //}
                    //colTitle = config.getTranslationText('671') + ' ' + config.getTranslationText('322') + ' ' + getRearAxlesTitleAppend('trailerRear', curTrailer1, curVehicle.getAxlesHolder().getAxles().length);
                    colTitle = config.getTranslationText('671') + ' ' + getRearAxlesTitleAppend('trailerRear', curTrailer1, curVehicle.getAxlesHolder().getAxles().length);
                    //}
                } else {
                    if (viewDetailToUse === config.VIEW_DETAIL.TRAILER1) {
                        //colTitle = config.getTranslationText('671') + ' 1 ' + config.getTranslationText('2778', [getRearAxlesTitleAppend('trailerRear', curTrailer1, curVehicle.getAxlesHolder().getAxles().length)]);
                        colTitle = config.getTranslationText('671') + ' 1 ' + getRearAxlesTitleAppend('trailerRear', curTrailer1, curVehicle.getAxlesHolder().getAxles().length);
                    } else {
                        //colTitle = config.getTranslationText('671') + ' 2 ' + config.getTranslationText('2778', [getRearAxlesTitleAppend('trailerRear', curTrailer2, curVehicle.getAxlesHolder().getAxles().length + curTrailer1.getAxlesHolder().getAxles().length)]);
                        colTitle = config.getTranslationText('671') + ' 2 ' + getRearAxlesTitleAppend('trailerRear', curTrailer2, curVehicle.getAxlesHolder().getAxles().length + curTrailer1.getAxlesHolder().getAxles().length);
                    }
                    
                }
                if (colTitle !== undefined) {
                    tableBasic.cols.trailerRear = {
                        title: colTitle,
                        titleFontStyleAddition: 'oblique',
                        position: { x: 0, y: 0 },
                        cellData: {},
                        colour: alternateColColour
                    }
                }
            } else if (curTrailer1 !== null && curTrailer2 !== null && viewDetailToUse !== config.VIEW_DETAIL.EXTRAS) {
                tableBasic.cols.trailer1 = {
                    title: config.getTranslationText('1264') + ' ' + getRearAxlesTitleAppend('trailer1', curTrailer1, curVehicle.getAxlesHolder().getAxles().length),
                    titleFontStyleAddition: 'oblique',
                    position: { x: 0, y: 0 },
                    cellData: {},
                    colour: alternateColColour
                }
                tableBasic.cols.trailer2 = {
                    title: config.getTranslationText('1265') + ' ' + getRearAxlesTitleAppend('trailer2', curTrailer2, curVehicle.getAxlesHolder().getAxles().length + curTrailer1.getAxlesHolder().getAxles().length),
                    titleFontStyleAddition: 'oblique',
                    position: { x: 0, y: 0 },
                    cellData: {},
                    colour: alternateColColour
                }
            }

            tableBasic.cols.total = {
                title: config.getTranslationText('2790'),
                titleFontStyleAddition: 'oblique',
                position: { x: 0/*totalStartPosition*/, y: 0 },
                cellData: {},
                colour: alternateColColour
            }
        } 
        return tableBasic;
    }


    var missingValuesObject;
    if (curViewType === config.VIEW_TYPE.OVERVIEW || curViewType === config.VIEW_TYPE.COG) {
        missingValuesObject = offerManager.getMissingValuesLookupObject();
    } else {
        missingValuesObject = offerManager.getTurningCircleMissingValuesLookupObject();
    }

    if (viewDetail === config.VIEW_DETAIL.SUMMARY || viewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION || viewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST) {

        if (viewDetail === config.VIEW_DETAIL.SUMMARY) { 
            //printHiddenValuesToTheConsole();

            // function printHiddenValuesToTheConsole() {

            //     var numPushers = curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
            //     var numTags = curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.TAG);

            //     //var rigOps = offerManager.getRigOps();
            //     var startRow = '**    ', endRow = '  **';
            //     var colGap = '  ';

            //     var formatFunc = offerManager.getUIFormattedMassValue;

            //     console.log('*****************************************************************');
            //     console.log('*****************************************************************');
            //     console.log(startRow + 'Wheelbase Theoretical Before Axles Added:                    ' + offerManager.getUIFormattedLengthValue(rigOps.getWheelbaseTheoreticalBeforeAnyAxlesAdded()));
            //     console.log(startRow + 'Original COG Total to original Wheelbase Theoretical Start:  ' + offerManager.getUIFormattedLengthValue(rigOps.getOriginalCOGTotalToOriginalWheelbaseTheoreticalStart()));
            //     console.log(startRow + 'Original COG Total to new Wheelbase Theoretical Start:       ' + offerManager.getUIFormattedLengthValue(rigOps.getOriginalCOGTotalToNewWheelbaseTheoreticalStart()));
            //     console.log('*****************************************************************');
            //     console.log(startRow + '               ' + pad('FRONT') + colGap + pad('REAR') + colGap + pad('TOTAL') + endRow)
            //     console.log(startRow + 'Tare Original  ' + pad(formatFunc(rigOps.getTareOriginalFrontAxles())) + colGap + pad(formatFunc(rigOps.getTareOriginalRearAxles())) + colGap + pad(formatFunc(rigOps.getTareOriginalTotalAxles())) + endRow);
            //     console.log(startRow + 'Tare Revised   ' + pad(formatFunc(rigOps.getTareRevisedFrontAxles())) + colGap + pad(formatFunc(rigOps.getTareRevisedRearAxles())) + colGap + pad(formatFunc(rigOps.getTareRevisedTotalAxles())) + endRow);
            //     if (numPushers >= 1) {
            //         console.log(startRow + 'Tare Pusher 1  ' + pad(formatFunc(rigOps.getPusher1FrontAxles())) + colGap + pad(formatFunc(rigOps.getPusher1RearAxles())) + colGap + pad(formatFunc(rigOps.getPusher1TotalAxles())) + endRow);
            //     }
            //     if (numPushers >= 2) {
            //         console.log(startRow + 'Tare Pusher 2  ' + pad(formatFunc(rigOps.getPusher2FrontAxles())) + colGap + pad(formatFunc(rigOps.getPusher2RearAxles())) + colGap + pad(formatFunc(rigOps.getPusher2TotalAxles())) + endRow);
            //     }
            //     if (numPushers === 3) {
            //         console.log(startRow + 'Tare Pusher 3  ' + pad(formatFunc(rigOps.getPusher3FrontAxles())) + colGap + pad(formatFunc(rigOps.getPusher3RearAxles())) + colGap + pad(formatFunc(rigOps.getPusher3TotalAxles())) + endRow);
            //     }
            //     if (numTags >= 1) {
            //         console.log(startRow + 'Tare Tag 1     ' + pad(formatFunc(rigOps.getTag1FrontAxles())) + colGap + pad(formatFunc(rigOps.getTag1RearAxles())) + colGap + pad(formatFunc(rigOps.getTag1TotalAxles())) + endRow);
            //     }
            //     if (numTags >= 2) {
            //         console.log(startRow + 'Tare Tag 2     ' + pad(formatFunc(rigOps.getTag2FrontAxles())) + colGap + pad(formatFunc(rigOps.getTag2RearAxles())) + colGap + pad(formatFunc(rigOps.getTag2TotalAxles())) + endRow);
            //     }
            //     if (numTags === 3) {
            //         console.log(startRow + 'Tare Tag 3     ' + pad(formatFunc(rigOps.getTag3FrontAxles())) + colGap + pad(formatFunc(rigOps.getTag3RearAxles())) + colGap + pad(formatFunc(rigOps.getTag3TotalAxles())) + endRow);
            //     }
            //     console.log('*****************************************************************');
            //     console.log('*****************************************************************');
            // }
            // function pad(value) {
            //     return value.padStart(12);
            // }
        }
        //addSpacerRow(tableMeta, config.tableAndDashboardTitleToDataGap);
        if (curTrailer1 === null) {
            if (displayType === config.DISPLAY_TYPE.DETAILED && (vehicle.getType() !== config.VEHICLE_TYPES.COMPLETE_VEHICLE || !(vehicle.getDBUnladenTotal() > 0))) {
                setupTareRow();
                setupExtrasRow();
                
                if (vehicle.getAccessoryHolder().getCount(true) === 0) {
                    addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
                }
            }

            if (displayType === config.DISPLAY_TYPE.DETAILED) {
                if (vehicle.getBody() !== null) {
                    setupBodyRow();
                }

                var accessory1 = vehicle.getAccessoryHolder().getAccessory1();
                if (accessory1 !== null) {

                    setupAccessoryRow(1, accessory1);
                }
                if (vehicle.getAccessoryHolder().getCount(false) === 2) {
                    var accessory2 = vehicle.getAccessoryHolder().getAccessory2();

                    if (accessory2 !== null) {
                        setupAccessoryRow(2, accessory2);
                    }
                }
                if (vehicle.getAccessoryHolder().getCount(false) > 2) {
                    setupOtherAccessoriesRow();
                }
                if (vehicle.getAccessoryHolder().getCount(true) > 0) {
                    addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
                }
            }
        }
        

        setupUnladenRow();
        if (curTrailer1 !== null && curTrailer2 === null) {
            setupUnladenTrailerRow();
            addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
            setupUnladenTotalRow();
        } else if (curTrailer1 !== null && curTrailer2 !== null) {
            setupUnladenTrailer1Row();
            setupUnladenTrailer2Row();
            addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
            setupUnladenTotalRow();
        }

        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        var combinationType = rigOps.getCombinationType();

        if (combinationType === config.COMBINATION_TYPES.VEHICLE || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
            setupPayloadVehicleOrVehicleAndSemiRow();
        } else {
            //setupVehiclePayloadRow();
            //setupTrailerPayloadRow();
            if (curTrailer2 === null) {
                setupPayloadVehicleAndDrawbarOrVehicleAndPupRow();
            } else {
                setupPayloadTwoTrailerTotalRow();
            }
            
        }
        
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);

        addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        if (curTrailer2 === null) {
            setupGrossRow();
        } else {
            setupTwoTrailerGrossRow();
        }
        
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);

        if (curTrailer2 === null) { 
            setupPermissibleRow(optionalPermissibleValues);
        } else {
            setupTwoTrailerPermissibleRow(optionalPermissibleValues);
        }
        
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //if (displayType === config.DISPLAY_TYPE.DETAILED) {
        if (curTrailer2 === null) {
            setupUnusedCapacityRow(optionalPermissibleValues);
            setupUtilisationRow();
            //setupOverloadRow();
        } else {
            setupTwoTrailerUnusedCapacityRow(optionalPermissibleValues);
            setupTwoTrailerUtilisationRow();
            //setupTwoTrailerOverloadRow();
        }
            
            //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
            //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
            //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
            //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
            //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //}
        //addSpacerRow(tableMeta, config.tableAndDashboardTitleToDataGap);
    } else if (viewDetail === config.VIEW_DETAIL.EXTRAS) {// extras
        let showFuelTanks = curVehicle.getFuelType() !== config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE;
        
        var fuelTank1 = vehicle.getFuelTank1(), fuelTank2 = vehicle.getFuelTank2(), fuelTank3 = vehicle.getFuelTank3(), fuelTank4 = vehicle.getFuelTank4(), fuelTank5 = vehicle.getFuelTank5(), fuelTank6 = vehicle.getFuelTank6(), fuelTank7 = vehicle.getFuelTank7(), fuelTank8 = vehicle.getFuelTank8();

        var crewFirstRowLabel = config.getTranslationText('97');
        var crewSecondRowLabel = config.getTranslationText('98');
        var totalRowLabel = config.getTranslationText('749');
        var frontSuffix = '.' + 'front';
        var rearSuffix = '.' + 'rear';
        var totalSuffix = '.' + 'total';
        var fuel1label = '', fuel2label = '', fuel3label = '', fuel4label = '', fuel5label = '', fuel6label = '', fuel7label = '', fuel8label = '';
        let formattingFunc = offerManager.getUIFormattedEnergySourceUnitOfMeasure;
        // if(offerManager.isFuelTypeWithExpressedASSupport()) {
        //     formattingFunc = offerManager.getUIFormattedFuelExpressedAsValue;
        // }
        if(showFuelTanks) {
            let txtCode = '';
            let numDieselTanks = offerManager.getActiveOffer().getRig().getVehicle().countFuelTankType(config.FUEL_TYPE_OPTIONS.DIESEL.CODE);
            let tankNumber = 0;
            //var fuel1label = config.getTranslationText('159') + ' 1' + '(' + offerManager.getUIFormattedVolumeValue(vehicle.getFuelTank1().getVolumeIncludedInExtras()) + ')';
            if(fuelTank1.getVolumeIncludedInExtras() > 0) {
                fuel1label = config.getTranslationText('159') + ' 1 ' + '(' + formattingFunc(fuelTank1.getVolumeIncludedInExtras()) + ')';
            }
            if (vehicle.getFuelTank2IncludeInCalculation() === true && fuelTank2.getVolumeIncludedInExtras() > 0) {
                if(fuelTank2.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    txtCode = '4662';
                    tankNumber = Number(fuelTank2.getNumber()) - numDieselTanks;
                }else {
                    txtCode = '159';
                    tankNumber = fuelTank2.getNumber();
                }
                fuel2label = config.getTranslationText(txtCode) + ' ' + tankNumber + ' ' + '(' + formattingFunc(fuelTank2.getVolumeIncludedInExtras()) + ')';
            }
            if (vehicle.getFuelTank3IncludeInCalculation() === true && fuelTank3.getVolumeIncludedInExtras() > 0) {
                if(fuelTank3.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    txtCode = '4662';
                    tankNumber = Number(fuelTank3.getNumber()) - numDieselTanks;
                }else {
                    txtCode = '159';
                    tankNumber = fuelTank3.getNumber();
                }
                fuel3label = config.getTranslationText(txtCode) + ' ' + tankNumber + ' ' + '(' + formattingFunc(fuelTank3.getVolumeIncludedInExtras()) + ')';
            }
            if (vehicle.getFuelTank4IncludeInCalculation() === true && fuelTank4.getVolumeIncludedInExtras() > 0) {
                if(fuelTank4.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    txtCode = '4662';
                    tankNumber = Number(fuelTank4.getNumber()) - numDieselTanks;
                }else {
                    txtCode = '159';
                    tankNumber = fuelTank4.getNumber();
                }
                fuel4label = config.getTranslationText(txtCode) + ' ' + tankNumber + ' ' + '(' + formattingFunc(fuelTank4.getVolumeIncludedInExtras()) + ')';
            }
            if (vehicle.getFuelTank5IncludeInCalculation() === true && fuelTank5.getVolumeIncludedInExtras() > 0) {
                if(fuelTank5.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    txtCode = '4662';
                    tankNumber = Number(fuelTank5.getNumber()) - numDieselTanks;
                }else {
                    txtCode = '159';
                    tankNumber = fuelTank5.getNumber();
                }
                fuel5label = config.getTranslationText(txtCode) + ' ' + tankNumber + ' ' + '(' + formattingFunc(fuelTank5.getVolumeIncludedInExtras()) + ')';
            }
            if (vehicle.getFuelTank6IncludeInCalculation() === true && fuelTank6.getVolumeIncludedInExtras() > 0) {
                if(fuelTank6.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    txtCode = '4662';
                    tankNumber = Number(fuelTank6.getNumber()) - numDieselTanks;
                }else {
                    txtCode = '159';
                    tankNumber = fuelTank6.getNumber();
                }
                fuel6label = config.getTranslationText(txtCode) + ' ' + tankNumber + ' ' + '(' + formattingFunc(fuelTank6.getVolumeIncludedInExtras()) + ')';
            }
            if (vehicle.getFuelTank7IncludeInCalculation() === true && fuelTank7.getVolumeIncludedInExtras() > 0) {
                if(fuelTank7.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    txtCode = '4662';
                    tankNumber = Number(fuelTank7.getNumber()) - numDieselTanks;
                }else {
                    txtCode = '159';
                    tankNumber = fuelTank7.getNumber();
                }
                fuel7label = config.getTranslationText(txtCode) + ' ' + tankNumber + ' ' + '(' + formattingFunc(fuelTank7.getVolumeIncludedInExtras()) + ')';
            }
            if (vehicle.getFuelTank8IncludeInCalculation() === true && fuelTank8.getVolumeIncludedInExtras() > 0) {
                if(fuelTank8.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    txtCode = '4662';
                    tankNumber = Number(fuelTank8.getNumber()) - numDieselTanks;
                }else {
                    txtCode = '159';
                    tankNumber = fuelTank8.getNumber();
                }
                fuel8label = config.getTranslationText(txtCode) + ' ' + tankNumber + ' ' + '(' + formattingFunc(fuelTank8.getVolumeIncludedInExtras()) + ')';
            }

            /*
            if(object.getContents() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE) {
                    return config.getTranslationText('4652') + ' ' + object.getNumber();
                }else if(object.getContents() === config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) {
                    return config.getTranslationText('4651') + ' ' + (Number(object.getNumber()) - offerManager.getActiveOffer().getRig().getVehicle().countFuelTankType(config.FUEL_TYPE_OPTIONS.DIESEL.CODE));
                }else {
                    return config.getTranslationText('2861') + ' ' + object.getNumber();
                }
            */

            var idFuelTank1 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + vehicle.getFuelTank1().getId();
            var idFuelTank2, idFuelTank3, idFuelTank4, idFuelTank5, idFuelTank6, idFuelTank7, idFuelTank8;
            if (fuelTank2) {
                idFuelTank2 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + fuelTank2.getId();
                if (fuelTank3) {
                    idFuelTank3 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + fuelTank3.getId();
                    if (fuelTank4) {
                        idFuelTank4 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + fuelTank4.getId();
                        if (fuelTank5) {
                            idFuelTank5 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + fuelTank5.getId();
                            if (fuelTank6) {
                                idFuelTank6 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + fuelTank6.getId();
                                if (fuelTank7) {
                                    idFuelTank7 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + fuelTank7.getId();
                                    if (fuelTank8) {
                                        idFuelTank8 = config.ACCESSORY_TYPES.FUEL_TANK + '.' + fuelTank8.getId();
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        var idCrewFirst = config.VALUE_TYPE.CREWFIRSTROW;
        var idCrewSecond = config.VALUE_TYPE.CREWSECONDROW;
        if(vehicle.getExtrasTotal() > 0) {
            tableMeta.cols.Labels.title = curVehicle.getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE || !offerManager.hasFuelInFuelAndCrew() ? config.getTranslationText('4659') : config.getTranslationText('742');
        }
        if(showFuelTanks) {
            if(fuelTank1.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel1 = { value: fuel1label, id: idFuelTank1 + '_label' };
            }
            if (vehicle.getFuelTank2IncludeInCalculation() === true  && fuelTank2.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel2 = { value: fuel2label, id: idFuelTank2 + '_label' };
            }
            if (vehicle.getFuelTank3IncludeInCalculation() === true && fuelTank3.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel3 = { value: fuel3label, id: idFuelTank3 + '_label' };
            }
            if (vehicle.getFuelTank4IncludeInCalculation() === true && fuelTank4.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel4 = { value: fuel4label, id: idFuelTank4 + '_label' };
            }
            if (vehicle.getFuelTank5IncludeInCalculation() === true && fuelTank5.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel5 = { value: fuel5label, id: idFuelTank5 + '_label' };
            }
            if (vehicle.getFuelTank6IncludeInCalculation() === true && fuelTank6.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel6 = { value: fuel6label, id: idFuelTank6 + '_label' };
            }
            if (vehicle.getFuelTank7IncludeInCalculation() === true && fuelTank7.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel7 = { value: fuel7label, id: idFuelTank7 + '_label' };
            }
            if (vehicle.getFuelTank8IncludeInCalculation() === true && fuelTank8.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.Labels.cellData.Fuel8 = { value: fuel8label, id: idFuelTank8 + '_label' };
            }
        }
        if(vehicle.getMassCrewFirst() > 0) {
            tableMeta.cols.Labels.cellData.Crew1st = { value: crewFirstRowLabel, id: idCrewFirst + '_label' };
        }
        
        if (vehicle.getCrewSecondRow() === true && vehicle.getMassCrewSecond() > 0) {
            tableMeta.cols.Labels.cellData.Crew2nd = { value: crewSecondRowLabel, id: idCrewSecond + '_label' };
        }

        

        if(showFuelTanks) {
            if(fuelTank1.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel1 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank1()), label: fuel1label };
                tableMeta.cols.front.cellData.Fuel1.id = idFuelTank1 + frontSuffix;
            }
            if (vehicle.getFuelTank2IncludeInCalculation() === true && fuelTank2.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel2 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank2()), label: fuel2label };
                tableMeta.cols.front.cellData.Fuel2.id = idFuelTank2 + frontSuffix;
            }
            if (vehicle.getFuelTank3IncludeInCalculation() === true && fuelTank3.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel3 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank3()), label: fuel3label };
                tableMeta.cols.front.cellData.Fuel3.id = idFuelTank3 + frontSuffix;
            }
            if (vehicle.getFuelTank4IncludeInCalculation() === true && fuelTank4.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel4 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank4()), label: fuel4label };
                tableMeta.cols.front.cellData.Fuel4.id = idFuelTank4 + frontSuffix;
            }
            if (vehicle.getFuelTank5IncludeInCalculation() === true && fuelTank5.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel5 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank5()), label: fuel5label };
                tableMeta.cols.front.cellData.Fuel5.id = idFuelTank5 + frontSuffix;
            }
            if (vehicle.getFuelTank6IncludeInCalculation() === true && fuelTank6.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel6 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank6()), label: fuel6label };
                tableMeta.cols.front.cellData.Fuel6.id = idFuelTank6 + frontSuffix;
            }
            if (vehicle.getFuelTank7IncludeInCalculation() === true && fuelTank7.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel7 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank7()), label: fuel7label };
                tableMeta.cols.front.cellData.Fuel7.id = idFuelTank7 + frontSuffix;
            }
            if (vehicle.getFuelTank8IncludeInCalculation() === true && fuelTank8.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.front.cellData.Fuel8 = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontFuelTank8()), label: fuel8label };
                tableMeta.cols.front.cellData.Fuel8.id = idFuelTank8 + frontSuffix;
            }
        }
        if(vehicle.getMassCrewFirst() > 0) {
            tableMeta.cols.front.cellData.Crew1st = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontCrewFirst()), label: crewFirstRowLabel };
            tableMeta.cols.front.cellData.Crew1st.id = idCrewFirst + frontSuffix;
        }
        if (vehicle.getCrewSecondRow() === true && vehicle.getMassCrewSecond() > 0) {
            tableMeta.cols.front.cellData.Crew2nd = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontCrewSecond()), label: crewSecondRowLabel };
            tableMeta.cols.front.cellData.Crew2nd.id = idCrewSecond + frontSuffix;
        }
        
        

        if(showFuelTanks) {
            if(fuelTank1.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel1 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank1()), label: fuel1label };
                
                tableMeta.cols.total.cellData.Fuel1.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank1.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel1.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel1.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel1.id = idFuelTank1 + totalSuffix;
            }
            if (vehicle.getFuelTank2IncludeInCalculation() === true && fuelTank2.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel2 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank2()), label: fuel2label };

                tableMeta.cols.total.cellData.Fuel2.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank2.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel2.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel2.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel2.id = idFuelTank2 + totalSuffix;
            }
            if (vehicle.getFuelTank3IncludeInCalculation() === true && fuelTank3.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel3 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank3()), label: fuel3label };

                tableMeta.cols.total.cellData.Fuel3.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank3.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel3.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel3.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel3.id = idFuelTank3 + totalSuffix;
            }
            if (vehicle.getFuelTank4IncludeInCalculation() === true && fuelTank4.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel4 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank4()), label: fuel4label };

                tableMeta.cols.total.cellData.Fuel4.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank4.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel4.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel4.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel4.id = idFuelTank4 + totalSuffix;
            }
            if (vehicle.getFuelTank5IncludeInCalculation() === true && fuelTank5.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel5 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank5()), label: fuel5label };

                tableMeta.cols.total.cellData.Fuel5.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank5.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel5.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel5.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel5.id = idFuelTank5 + totalSuffix;
            }
            if (vehicle.getFuelTank6IncludeInCalculation() === true && fuelTank6.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel6 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank6()), label: fuel6label };

                tableMeta.cols.total.cellData.Fuel6.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank6.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel6.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel6.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel6.id = idFuelTank6 + totalSuffix;
            }
            if (vehicle.getFuelTank7IncludeInCalculation() === true && fuelTank7.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel7 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank7()), label: fuel7label };

                tableMeta.cols.total.cellData.Fuel7.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank7.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel7.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel7.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel7.id = idFuelTank7 + totalSuffix;
            }
            if (vehicle.getFuelTank8IncludeInCalculation() === true && fuelTank8.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.total.cellData.Fuel8 = { value: offerManager.getUIFormattedMassValue(vehicle.getMassFuelTank8()), label: fuel8label };

                tableMeta.cols.total.cellData.Fuel8.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.FUEL_TANKS + '.' + fuelTank8.getId() + '.' + config.VALUE_TYPE.FUELTANKCAPACITY);
                tableMeta.cols.total.cellData.Fuel8.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Fuel8.interaction.callbacks);
                tableMeta.cols.total.cellData.Fuel8.id = idFuelTank8 + totalSuffix;
            }
        }
        if(vehicle.getMassCrewFirst() > 0) {
            tableMeta.cols.total.cellData.Crew1st = { value: offerManager.getUIFormattedMassValue(vehicle.getMassCrewFirst()), label: crewFirstRowLabel };

            tableMeta.cols.total.cellData.Crew1st.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWMASSPERPERSON);
            tableMeta.cols.total.cellData.Crew1st.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Crew1st.interaction.callbacks);
            tableMeta.cols.total.cellData.Crew1st.id = idCrewFirst + totalSuffix;
        }

        if (vehicle.getCrewSecondRow() === true && vehicle.getMassCrewSecond() > 0) {
            //tableMeta.cols.total.cellData.Crew2nd = { value: offerManager.getUIFormattedMassValue(vehicle.getMassCrewSecond()), label: config.getTranslationText('98') };
            tableMeta.cols.total.cellData.Crew2nd = { value: offerManager.getUIFormattedMassValue(vehicle.getMassCrewSecond()), label: crewSecondRowLabel };

            tableMeta.cols.total.cellData.Crew2nd.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.CREWMASSPERPERSON);
            tableMeta.cols.total.cellData.Crew2nd.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Crew2nd.interaction.callbacks);
            tableMeta.cols.total.cellData.Crew2nd.id = idCrewSecond + totalSuffix;
        }
        
        
        if(showFuelTanks) {
            if(fuelTank1.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel1 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank1()), label: fuel1label };
                tableMeta.cols.rear.cellData.Fuel1.id = idFuelTank1 + rearSuffix;
            }
            if (vehicle.getFuelTank2IncludeInCalculation() === true && fuelTank2.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel2 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank2()), label: fuel2label };
                tableMeta.cols.rear.cellData.Fuel2.id = idFuelTank2 + rearSuffix;
            }
            if (vehicle.getFuelTank3IncludeInCalculation() === true && fuelTank3.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel3 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank3()), label: fuel3label };
                tableMeta.cols.rear.cellData.Fuel3.id = idFuelTank3 + rearSuffix;
            }
            if (vehicle.getFuelTank4IncludeInCalculation() === true && fuelTank4.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel4 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank4()), label: fuel4label };
                tableMeta.cols.rear.cellData.Fuel4.id = idFuelTank4 + rearSuffix;
            }
            if (vehicle.getFuelTank5IncludeInCalculation() === true && fuelTank5.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel5 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank5()), label: fuel5label };
                tableMeta.cols.rear.cellData.Fuel5.id = idFuelTank5 + rearSuffix;
            }
            if (vehicle.getFuelTank6IncludeInCalculation() === true && fuelTank6.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel6 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank6()), label: fuel6label };
                tableMeta.cols.rear.cellData.Fuel6.id = idFuelTank6 + rearSuffix;
            }
            if (vehicle.getFuelTank7IncludeInCalculation() === true && fuelTank7.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel7 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank7()), label: fuel7label };
                tableMeta.cols.rear.cellData.Fuel7.id = idFuelTank7 + rearSuffix;
            }
            if (vehicle.getFuelTank8IncludeInCalculation() === true && fuelTank8.getVolumeIncludedInExtras() > 0) {
                tableMeta.cols.rear.cellData.Fuel8 = { value: offerManager.getUIFormattedMassValue(vehicle.getRearFuelTank8()), label: fuel8label };
                tableMeta.cols.rear.cellData.Fuel8.id = idFuelTank8 + rearSuffix;
            }
        }
        if(vehicle.getMassCrewFirst() > 0) {
            tableMeta.cols.rear.cellData.Crew1st = { value: offerManager.getUIFormattedMassValue(vehicle.getRearCrewFirst()), label: crewFirstRowLabel };
            tableMeta.cols.rear.cellData.Crew1st.id = idCrewFirst + rearSuffix;
        }
        if (vehicle.getCrewSecondRow() === true && vehicle.getMassCrewSecond() > 0) {
            //tableMeta.cols.rear.cellData.Crew2nd = { value: offerManager.getUIFormattedMassValue(vehicle.getRearCrewSecond()), label: config.getTranslationText('98') };
            tableMeta.cols.rear.cellData.Crew2nd = { value: offerManager.getUIFormattedMassValue(vehicle.getRearCrewSecond()), label: crewSecondRowLabel };
            tableMeta.cols.rear.cellData.Crew2nd.id = idCrewSecond + rearSuffix;
        }

        if(showFuelTanks) {
            if(fuelTank1.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel1', fuel1label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank1.getId());
            }
            if (vehicle.getFuelTank2IncludeInCalculation() === true && fuelTank2.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel2', fuel2label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank2.getId());
            }
            if (vehicle.getFuelTank3IncludeInCalculation() === true && fuelTank3.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel3', fuel3label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank3.getId());
            }
            if (vehicle.getFuelTank4IncludeInCalculation() === true && fuelTank4.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel4', fuel4label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank4.getId());
            }
            if (vehicle.getFuelTank5IncludeInCalculation() === true && fuelTank5.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel5', fuel5label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank5.getId());
            }
            if (vehicle.getFuelTank6IncludeInCalculation() === true && fuelTank6.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel6', fuel6label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank6.getId());
            }
            if (vehicle.getFuelTank7IncludeInCalculation() === true && fuelTank7.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel7', fuel7label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank7.getId());
            }
            if (vehicle.getFuelTank8IncludeInCalculation() === true && fuelTank8.getVolumeIncludedInExtras() > 0) {
                generatePopulatedPusherTagAndRearConnectedAccessoryCols('Fuel8', fuel8label, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.OBJECT_TYPES.FUEL_TANKS, fuelTank8.getId());
            }
        }
        if(vehicle.getMassCrewFirst() > 0) {
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('CrewFirst', crewFirstRowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.VALUE_TYPE.CREWFIRSTROW);
        }
        if (vehicle.getCrewSecondRow() === true && vehicle.getMassCrewSecond() > 0) {
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('CrewSecond', crewSecondRowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.VALUE_TYPE.CREWSECONDROW);
        }

        
        if(vehicle.getExtrasTotal() > 0) {
            addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
            tableMeta.cols.Labels.cellData.Total = { value: totalRowLabel, id: 'EXTRAS_TOTAL_label' };
            tableMeta.cols.front.cellData.Total = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontExtrasTotal()), label: totalRowLabel, id: 'FRONT_EXTRAS_TOTAL', bold: true };
            tableMeta.cols.rear.cellData.Total = { value: offerManager.getUIFormattedMassValue(vehicle.getRearExtrasTotal()), label: totalRowLabel, id: 'REAR_EXTRAS_TOTAL', bold: true };
            tableMeta.cols.total.cellData.Total = { value: offerManager.getUIFormattedMassValue(vehicle.getExtrasTotal()), label: totalRowLabel, id: 'TOTAL_EXTRAS_TOTAL', bold: true };

            generatePopulatedPusherTagAndRearConnectedAccessoryCols('Total', totalRowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, vehicle.getRearExtrasTotal());
        }

        
        
        
        //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);

    } else if (viewDetail === config.VIEW_DETAIL.TRAILER1 || viewDetail === config.VIEW_DETAIL.TRAILER2) {

        var curTrailerForView;
        if (viewDetail === config.VIEW_DETAIL.TRAILER1) {
            curTrailerForView = curTrailer1;
        } else if (viewDetail === config.VIEW_DETAIL.TRAILER2) {
            curTrailerForView = curTrailer2;
        }

        setupTrailerTareRow(curTrailerForView);

        var accessoryCounter = 1;
        var alreadyHandledAccessories = [];

        var tempBody = curTrailerForView.getAccessoryHolder().getBody();
        if (tempBody !== null) {
            setupTrailerAccessoryRow(accessoryCounter, tempBody, curTrailerForView);
            accessoryCounter++;
            alreadyHandledAccessories.push(tempBody)
        }

        var tempCrane = curTrailerForView.getAccessoryHolder().getCrane();
        if (tempCrane !== null) {
            setupTrailerAccessoryRow(accessoryCounter, tempCrane, curTrailerForView);
            accessoryCounter++;
            alreadyHandledAccessories.push(tempCrane);
        }

        var tempFridge = curTrailerForView.getAccessoryHolder().getFridge();
        if (tempFridge !== null) {
            setupTrailerAccessoryRow(accessoryCounter, tempFridge, curTrailerForView);
            accessoryCounter++;
            alreadyHandledAccessories.push(tempFridge);
        }

        var tempTaillift = curTrailerForView.getAccessoryHolder().getTaillift();
        if (tempTaillift !== null) {
            setupTrailerAccessoryRow(accessoryCounter, tempTaillift, curTrailerForView);
            accessoryCounter++;
            alreadyHandledAccessories.push(tempTaillift);
        }

        var tempHooklift = curTrailerForView.getAccessoryHolder().getHooklift();
        if (tempHooklift !== null) {
            setupTrailerAccessoryRow(accessoryCounter, tempHooklift, curTrailerForView);
            accessoryCounter++;
            alreadyHandledAccessories.push(tempHooklift);
        }

        curTrailerForView.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessoryCounter <= 5 && accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                setupTrailerAccessoryRow(accessoryCounter, accessory, curTrailerForView);
                accessoryCounter++;
                alreadyHandledAccessories.push(accessory);
            }
        });

        if (accessoryCounter > 5 && curTrailerForView.getAccessoryHolder().getAccessories().length > 5) {
            setupTrailerOtherAccessoriesOverflowRow(curTrailerForView, alreadyHandledAccessories);
        }
        addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
        setupTrailerTotalRow(curTrailerForView);
        //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.WHITE);
        //addUnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE);

    } else if (viewDetail === config.VIEW_DETAIL.CHASSIS) {
        setupChassisViewTable();
    } else if (viewDetail === config.VIEW_DETAIL.VEHICLE) {
        setupVehicleViewTable();
    } else if (viewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
        
        switch (rigOps.getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                setupBridgeViewVehicleTableRows(tableMeta); 
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                setupBridgeViewVehicleAndDrawbarTableRows(tableMeta);
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                setupBridgeViewVehicleAndSemiTableRows(tableMeta);
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                setupBridgeViewVehicleAndPupTableRows(tableMeta);
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                setupBridgeViewVehicleAndInterlinkAndSemiTableRows(tableMeta);
                break;
        }

    }

    ////format and add measurement system mass unit
    //Object.keys(tableMeta.front.cellData).forEach(function (key) {
    //    tableMeta.front.cellData[key].value = Globalize.format(tableMeta.front.cellData[key].value, "n0") + " " + curMeasurementSystem.unitMass;
    //});

    //Object.keys(tableMeta.total.cellData).forEach(function (key) {
    //    tableMeta.total.cellData[key].value = Globalize.format(tableMeta.total.cellData[key].value, "n0") + " " + curMeasurementSystem.unitMass;
    //});

    //Object.keys(tableMeta.rear.cellData).forEach(function (key) {
    //    tableMeta.rear.cellData[key].value = Globalize.format(tableMeta.rear.cellData[key].value, "n0") + " " + curMeasurementSystem.unitMass;
    //});

    tableMeta.tableHeight = getTableLayerHeight(tableMeta);
    tableMeta.tableWidth = Object.keys(tableMeta.cols).length * (tableMeta.cellWidth + config.configurationTableInterColGap);
    if (viewDetail === config.VIEW_DETAIL.SUMMARY || viewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION || viewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST) {
        markOverloadedCols(tableMeta);
    } else if (viewDetail === config.VIEW_DETAIL.BRIDGE_VIEW) {
        markOverloadedGroups(tableMeta);
    } else {
        if (curTrailer2 !== null) {
            markOverloadedCols(setupTwoTrailerGrossRow(true));
        } else {
            markOverloadedCols(setupGrossRow(true));
        }
        //markOverloadedCols(getGrossRowOnlyEmbeddedInTableStructure());
    }

    tableMeta.cellWidth = Math.max(getLargestDataCellWidth(tableMeta) + tableMeta.labelBuffer * 2, getLargestTitleWidth(tableMeta) + tableMeta.labelBuffer * 2);

    return tableMeta;
    
    
    //function getCellMenuHyperLink(rowName, colName) {
    //    switch(colName) {
    //        case config.TABLE_COLUMNS.FRONT:
    //            return;
    //        case config.TABLE_COLUMNS.REAR:
    //            return;
    //        case config.TABLE_COLUMNS.TOTAL:
    //            return;
    //        case config.TABLE_COLUMNS.PUSHER:
    //            return;
    //        case config.TABLE_COLUMNS.TAG:
    //            return;
    //        case config.TABLE_COLUMNS.REAR_CONNECTED:
    //            return;
    //        case config.TABLE_COLUMNS.TRAILER_FRONT:
    //            return;
    //        case config.TABLE_COLUMNS.TRAILER_REAR:
    //            return;
    //        case config.TABLE_COLUMNS.TRAILER_1:
    //            return;
    //        case config.TABLE_COLUMNS.TRAILER_2:
    //            return;
    //    }
    //}
    //getVehiclePusher3AxleInteractionTargetElementId
    function getVehicleTareFrontInteractionTargetElementId() {
        
        if (curVehicle.getAxlesHolder().hasAddedAxle() === true) {
            return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + config.VALUE_TYPE.TAREWEIGHTMODIFIED;
        } else {
            return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT;
        }
    }

    function getVehicleTareFrontOriginalInteractionTargetElementId() {

        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT;
    }

    function getVehicleTareRearInteractionTargetElementId() {
        if (curVehicle.getAxlesHolder().hasAddedPusherOrTag() === true) {
            //return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_REAR_TOTAL + '.' + config.VALUE_TYPE.TAREWEIGHTMODIFIED;
            return undefined;
        } else {
            return config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREARREAR;
        }
    }

    function getVehicleTareRearOriginalInteractionTargetElementId() {
        return config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREARREAR;
    }

    function getVehiclePusherOrTagInteractionTargetElementId(axleId) {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.TAREWEIGHTMODIFIED;
    }

    function getVehicleRearConnectedInteractionTargetElementId(axleGroupId) {
        if (curVehicle.getAxlesHolder().hasAddedAxle() === true) {
            return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + axleGroupId + '.' + config.VALUE_TYPE.TAREWEIGHTMODIFIED;
        } else {
            return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREARREAR;
        }
    }

    function setupVehicleViewTable() {
        //var rigOps = offerManager.getRigOps();
        var formatFunc = offerManager.getUIFormattedMassValue;

        var frontColTitle = getFrontColPrefix() + ' ' + config.getTranslationText('153') + ' ' + getFrontAxlesTitleAppend('front', curVehicle);
        var rearColTitle = getRearColPrefix() + ' ' + config.getTranslationText('2778') + ' ' + getRearAxlesTitleAppend('rear', curVehicle, 0);
        //var rearConnectedTitle = config.getTranslationText('2778', [getRearConnectedAxlesTitleAppend(config.TABLE_COLUMNS.REAR_CONNECTED)]);
        var totalColTitle = config.getTranslationText('2790');


        var tableColumnConfigs = [];
                            
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.LABELS).setBackgroundColour(alternateColColour).setTitle(config.getTranslationText('4000')).setContentsAlignment('left').setTitleFontStyleAddition('bold').setTitleFontSize(dashboardAndTableTitleFontSize));
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.FRONT).setBackgroundColour(alternateColColour));
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.REAR).setBackgroundColour(alternateColColour));
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.TOTAL).setBackgroundColour(alternateColColour));

        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColumnConfigs(tableColumnConfigs);
        }

        var viewRows = [];



        var vehicleTareRow = new TableRow(config.TABLE_ROWS.TARE);

        var frontTare = rigOps.getVehicleOnlyFrontTare();
        var rearTare = rigOps.getVehicleTareRearRearOnly();

        vehicleTareRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(config.getTranslationText('100')));
        vehicleTareRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(frontTare)).setColTitle(frontColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareFrontInteractionTargetElementId())));
        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColsForRow(vehicleTareRow, { type: 'distribute_value', massVal: rearTare, formatFunc: formatFunc, interaction: true });
            vehicleTareRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(rearTare)).setColTitle(rearColTitle));
        } else {
            vehicleTareRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(rearTare)).setColTitle(rearColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareRearInteractionTargetElementId())));
        }
        
        vehicleTareRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(frontTare + rearTare)).setColTitle(totalColTitle));

        vehicleTareRow.setRowLabel(config.getTranslationText('100'));
        viewRows.push(vehicleTareRow);


        if(curVehicle.getExtrasTotal() > 0) {
            var vehicleFuelAndCrewRow = new TableRow(config.TABLE_ROWS.FUEL_AND_CREW);
                        
            vehicleFuelAndCrewRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(curVehicle.getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE || !offerManager.hasFuelInFuelAndCrew() ? config.getTranslationText('4659') : config.getTranslationText('742')));
            vehicleFuelAndCrewRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(vehicle.getFrontExtrasTotal())).setColTitle(frontColTitle));
            if (hasPusherOrTagDown) {
                generatePusherTagAndRearConnectedColsForRow(vehicleFuelAndCrewRow, { type: 'distribute_value', massVal: vehicle.getRearExtrasTotal(), formatFunc: formatFunc });
            }
            vehicleFuelAndCrewRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(vehicle.getRearExtrasTotal())).setColTitle(rearColTitle));
            vehicleFuelAndCrewRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(vehicle.getExtrasTotal())).setColTitle(totalColTitle));

            vehicleFuelAndCrewRow.setRowLabel(curVehicle.getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE ? config.getTranslationText('4659') :config.getTranslationText('742'));
            viewRows.push(vehicleFuelAndCrewRow);
        }
        
        var remainingBodyAndEquipmentRows = 7;

        if(vehicle.getBody() !== null) {
            remainingBodyAndEquipmentRows--;
            generateAccessoryRow(viewRows, vehicle.getBody(), frontColTitle, rearColTitle, totalColTitle, formatFunc);
        }

        if (remainingBodyAndEquipmentRows >= vehicle.getAccessoryHolder().getCount(false)) {
            vehicle.getAccessoryHolder().getAccessories().forEach(function (accessory) {
                if (accessory.getAccessoryType() !== config.ACCESSORY_TYPES.BODY) {
                    generateAccessoryRow(viewRows, accessory, frontColTitle, rearColTitle, totalColTitle, formatFunc);
                }
            });
        } else {
            var previouslyCountedAccessories = {};
            for(var i = 0; i < remainingBodyAndEquipmentRows; i++) {
                generateAccessoryRow(viewRows, vehicle.getAccessoryHolder().getNextAccessory(previouslyCountedAccessories), frontColTitle, rearColTitle, totalColTitle, formatFunc);
            }
            generateCombinedOtherAccessoriesRow(viewRows, previouslyCountedAccessories, frontColTitle, rearColTitle, totalColTitle, formatFunc);
        }

        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));

        var vehicleUnladenTotalRow = new TableRow(config.TABLE_ROWS.VEHICLE_UNLADEN_TOTAL);

        vehicleUnladenTotalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(config.getTranslationText('4019')));
        vehicleUnladenTotalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(rigOps.getVehicleOnlyFrontUnladen())).setColTitle(frontColTitle));
        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColsForRow(vehicleUnladenTotalRow, { type: 'distribute_value', massVal: rigOps.getVehicleOnlyRearUnladen(), formatFunc: formatFunc });
        }
        vehicleUnladenTotalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(rigOps.getVehicleOnlyRearUnladen())).setColTitle(rearColTitle));
        vehicleUnladenTotalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(vehicle.getUnladenTotal())).setColTitle(totalColTitle));

        vehicleUnladenTotalRow.setRowLabel(config.getTranslationText('4019'));
        vehicleUnladenTotalRow.setIsBold(true);
        viewRows.push(vehicleUnladenTotalRow);

        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE));


        extractToTableMeta(viewRows, tableMeta, tableColumnConfigs);
    }

    function generateCombinedOtherAccessoriesRow(viewRows, alreadyListedAccessories, frontColTitle, rearColTitle, totalColTitle, formatFunc) {
        var accessoryFrontVal, accessoryRearVal, accessoryTotalVal;
        
        accessoryFrontVal = vehicle.getAccessoryHolder().getVehicleFrontAxleTotalOtherAccessoriesCombined(alreadyListedAccessories, vehicle.getFrontOverhang(), vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), vehicle.getWheelbaseTheoretical(), 
                                                                    vehicle.getBumperToBackOfCab(), vehicle.getAccessoryHolder().getBodyGap())
        accessoryRearVal = vehicle.getAccessoryHolder().getVehicleRearAxleTotalOtherAccessoriesCombined(alreadyListedAccessories, vehicle.getFrontOverhang(), vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), vehicle.getWheelbaseTheoretical(), 
                                                                    vehicle.getBumperToBackOfCab(), vehicle.getAccessoryHolder().getBodyGap())
        accessoryTotalVal = accessoryFrontVal + accessoryRearVal;

        //var targetElementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + getAccessoryPathAppend(accessory);

        var vehicleAccessoryRow = new TableRow(config.TABLE_ROWS.EQUIPMENT + viewRows.length);
                    
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(config.getTranslationText('688')));
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(accessoryFrontVal)).setColTitle(frontColTitle));
        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColsForRow(vehicleAccessoryRow, { type: 'distribute_value', massVal: accessoryRearVal, formatFunc: formatFunc });
        }
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(accessoryRearVal)).setColTitle(rearColTitle));
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(accessoryTotalVal)).setColTitle(totalColTitle));

        vehicleAccessoryRow.setRowLabel(config.getTranslationText('688'));
        viewRows.push(vehicleAccessoryRow);

    }

    function generateAccessoryRow(viewRows, accessory, frontColTitle, rearColTitle, totalColTitle, formatFunc) {
        var accessoryFrontVal, accessoryRearVal, accessoryTotalVal;

        var distanceFromWheelbaseTheoreticalEnd = vehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory,
                vehicle.getFrontOverhang(), vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), vehicle.getWheelbaseTheoretical(), vehicle.getBumperToBackOfCab(), vehicle.getAccessoryHolder().getBodyGap());
        var wheelbaseTheoretical = vehicle.getWheelbaseTheoretical();

                    
        accessoryFrontVal = accessory.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
        accessoryRearVal = accessory.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
        accessoryTotalVal = accessory.getTotalLoad();

        var targetElementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + getAccessoryPathAppend(accessory);

        var vehicleAccessoryRow = new TableRow(config.TABLE_ROWS.EQUIPMENT + viewRows.length);
                    
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(accessory.getDescriptionForDrawing()));
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(accessoryFrontVal)).setColTitle(frontColTitle));
        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColsForRow(vehicleAccessoryRow, { type: 'distribute_value', massVal: accessoryRearVal, formatFunc: formatFunc });
        }
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(accessoryRearVal)).setColTitle(rearColTitle));
        vehicleAccessoryRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(accessoryTotalVal)).setColTitle(totalColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, targetElementId)));

        vehicleAccessoryRow.setRowLabel(accessory.getDescriptionForDrawing());
        viewRows.push(vehicleAccessoryRow);

    }

    function setupChassisViewTable() {

        //var rigOps = offerManager.getRigOps();
        var formatFunc = offerManager.getUIFormattedMassValue;

        var frontColTitle = getFrontColPrefix() + ' ' + config.getTranslationText('153') + ' ' + getFrontAxlesTitleAppend('front', curVehicle);
        var rearColTitle = getRearColPrefix() + ' ' + config.getTranslationText('2778') + ' ' + getRearAxlesTitleAppend('rear', curVehicle, 0);
        //var rearConnectedTitle = config.getTranslationText('2778', [getRearConnectedAxlesTitleAppend(config.TABLE_COLUMNS.REAR_CONNECTED)]);
        var totalColTitle = config.getTranslationText('2790');


        var tableColumnConfigs = [];
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.LABELS).setBackgroundColour(alternateColColour).setTitle(config.getTranslationText('3098')).setContentsAlignment('left').setTitleFontStyleAddition('bold').setTitleFontSize(dashboardAndTableTitleFontSize));
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.FRONT).setBackgroundColour(alternateColColour)); 
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.REAR).setBackgroundColour(alternateColColour));
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.TOTAL).setBackgroundColour(alternateColColour));

        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColumnConfigs(tableColumnConfigs);
        }



        var viewRows = [];



        var chassisWeightOriginalRow = new TableRow(config.TABLE_ROWS.CHASSIS_WEIGHT_ORIGINAL);

        chassisWeightOriginalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(config.getTranslationText('4011')));
        chassisWeightOriginalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(rigOps.getTareOriginalFrontAxles())).setColTitle(frontColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareFrontOriginalInteractionTargetElementId())));
        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColsForRow(chassisWeightOriginalRow, { type: 'rear', massVal: rigOps.getTareOriginalRearAxles(), formatFunc: formatFunc, interaction: true, interactionTargetElementId: getVehicleTareRearOriginalInteractionTargetElementId() });
            chassisWeightOriginalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue('-').setColTitle(rearColTitle)/*.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareRearOriginalInteractionTargetElementId()))*/);
        } else {
            chassisWeightOriginalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(rigOps.getTareOriginalRearAxles())).setColTitle(rearColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareRearOriginalInteractionTargetElementId())));
        }
        
        chassisWeightOriginalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(rigOps.getTareOriginalTotalAxles())).setColTitle(totalColTitle));
        
        chassisWeightOriginalRow.setRowLabel(config.getTranslationText('4011'));
        chassisWeightOriginalRow.setIsBold(true);
        viewRows.push(chassisWeightOriginalRow);

        

        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));



        var chassisWeightOriginalRecalculatedRow = new TableRow(config.TABLE_ROWS.CHASSIS_WEIGHT_ORIGINAL_RECALCULATED);

        chassisWeightOriginalRecalculatedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(config.getTranslationText('4012')));
        chassisWeightOriginalRecalculatedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(rigOps.getTareRevisedFrontAxles())).setColTitle(frontColTitle));
        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColsForRow(chassisWeightOriginalRecalculatedRow, { type: 'distribute_value', massVal: rigOps.getTareRevisedRearAxles(), formatFunc: formatFunc });
        }
        chassisWeightOriginalRecalculatedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(rigOps.getTareRevisedRearAxles())).setColTitle(rearColTitle));
        chassisWeightOriginalRecalculatedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(rigOps.getTareRevisedTotalAxles())).setColTitle(totalColTitle));

        chassisWeightOriginalRecalculatedRow.setRowLabel(config.getTranslationText('4012'));
        viewRows.push(chassisWeightOriginalRecalculatedRow);

        generatePusherAndTagRows(viewRows, frontColTitle, rearColTitle, totalColTitle, formatFunc);



        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));


        
        var distributedTareFrontTotal = rigOps.getDistributedTareFrontTotal();
        var distributedTareRearTotal = rigOps.getDistributedTareRearTotal();
        var chassisWeightModifiedRow = new TableRow(config.TABLE_ROWS.CHASSIS_WEIGHT_MODIFIED);

        chassisWeightModifiedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(config.getTranslationText('4013')));
        chassisWeightModifiedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(distributedTareFrontTotal)).setColTitle(frontColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareFrontInteractionTargetElementId())));
        if (hasPusherOrTagDown) {
            generatePusherTagAndRearConnectedColsForRow(chassisWeightModifiedRow, { type: 'distribute_value', massVal: distributedTareRearTotal, formatFunc: formatFunc, interaction: true });
        }
        if (curVehicle.getAxlesHolder().hasAddedPusherOrTag() === true) {
            chassisWeightModifiedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(distributedTareRearTotal)).setColTitle(rearColTitle));
        } else {
            chassisWeightModifiedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(distributedTareRearTotal)).setColTitle(rearColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareRearInteractionTargetElementId())));
        }
        
        chassisWeightModifiedRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(distributedTareFrontTotal + distributedTareRearTotal)).setColTitle(totalColTitle));

        chassisWeightModifiedRow.setRowLabel(config.getTranslationText('4013'));
        chassisWeightModifiedRow.setIsBold(true);
        viewRows.push(chassisWeightModifiedRow);

        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.WHITE));
        //viewRows.push(new UnderlineRow(config.DRAWING_COLOURS.COLUMN_TITLE));


        extractToTableMeta(viewRows, tableMeta, tableColumnConfigs);
    }

    function generatePusherAndTagRows(viewRows, frontColTitle, rearColTitle, totalColTitle, formatFunc) {
        var pusherCounter = 1;
        var tagCounter = 1;
        

        curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

            var tableRow, rowLabel, frontColValToUse, rearColValToUse, totalColValToUse;
            
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {

                tableRow = config.TABLE_ROWS.PUSHER + pusherCounter;
                rowLabel;
                if(axle.getRaised()) {
                    rowLabel = config.getTranslationText('4014', [axle.getNumber()]);
                }else {
                    rowLabel = config.getTranslationText('4015', [axle.getNumber()]);
                }
                frontColValToUse = rigOps['getPusher' + pusherCounter + 'FrontAxles']();
                rearColValToUse = rigOps['getPusher' + pusherCounter + 'RearAxles']();
                totalColValToUse = rigOps['getPusher' + pusherCounter + 'TotalAxles']();
                
                addPusherOrTagRow(tableRow, rowLabel, frontColValToUse, rearColValToUse, totalColValToUse, formatFunc, axle.getId());
                
                pusherCounter++;
            } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {

                tableRow = config.TABLE_COLUMNS.TAG + tagCounter;
                rowLabel;
                if(axle.getRaised()) {
                    rowLabel = config.getTranslationText('4016', [axle.getNumber()]);
                }else {
                    rowLabel = config.getTranslationText('4017', [axle.getNumber()]);
                }
                frontColValToUse = rigOps['getTag' + tagCounter + 'FrontAxles']();
                rearColValToUse = rigOps['getTag' + tagCounter + 'RearAxles']();
                totalColValToUse = rigOps['getTag' + tagCounter + 'TotalAxles']();
                addPusherOrTagRow(tableRow, rowLabel, frontColValToUse, rearColValToUse, totalColValToUse, formatFunc, axle.getId());
                
                tagCounter++;
            }
            

        });
        function addPusherOrTagRow(tableRow, rowLabel, frontColVal, rearColVal, totalColVal, formatFunc, axleId) {
            var curPusherOrTagRowRow = new TableRow(tableRow);

            curPusherOrTagRowRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.LABELS).setValue(rowLabel));
            curPusherOrTagRowRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(frontColVal)).setColTitle(frontColTitle));
            if (hasPusherOrTagDown) {
                generatePusherTagAndRearConnectedColsForRow(curPusherOrTagRowRow, { type: 'distribute_value', massVal: rearColVal, formatFunc: formatFunc });
            }
            curPusherOrTagRowRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.REAR).setValue(formatFunc(rearColVal)).setColTitle(rearColTitle));
            //curPusherOrTagRowRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(totalColVal)).setColTitle(totalColTitle));
            curPusherOrTagRowRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.TOTAL).setValue(formatFunc(totalColVal)).setColTitle(totalColTitle)
                .setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + axleId + '.' + config.VALUE_TYPE.AXLEWEIGHT)));
            //chassisWeightOriginalRow.addCell(new CellConfig(null, config.TABLE_COLUMNS.FRONT).setValue(formatFunc(rigOps.getTareOriginalFrontAxles())).setColTitle(frontColTitle).setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareFrontOriginalInteractionTargetElementId())));

            curPusherOrTagRowRow.setRowLabel(rowLabel);
            viewRows.push(curPusherOrTagRowRow);
        }
    }

    function generatePusherTagAndRearConnectedColsForRow(row, params) {

        var rearConnectedTitle = config.getTranslationText('2778') + ' ' + getRearConnectedAxlesTitleAppend(config.TABLE_COLUMNS.REAR_CONNECTED);

        var pusherCounter = 1;
        var tagCounter = 1;
        var rearConnectedAdded = false;
        curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

            var colName = '', valToUse, newCell, letter;
            if (!axle.getRaised() && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {

                colName = config.TABLE_COLUMNS.PUSHER + '_' + pusherCounter + '_' + axle.getNumber();

                letter = axle.getNumber();
                colLetters[colName] = {};
                colLetters[colName].letters = [letter];

                valToUse = '';
                if (params.type === 'blank' || params.type === 'rear') {
                    valToUse = '-';
                } else if (params.type === 'distribute_value') {
                    valToUse = params.formatFunc(curVehicle.getAxlesHolder().getPusherOrTagRearAxleDistribution(axle.getNumber(), params.massVal));
                }
                newCell = new CellConfig(null, colName).setValue(valToUse).setColTitle(config.getTranslationText('2777', [letter]));
                if (params.interaction) {
                    if (params.type === 'distribute_value') {
                        newCell.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehiclePusherOrTagInteractionTargetElementId(axle.getId())));
                    } 
                }
                    
                row.addCell(newCell);
                pusherCounter++;
            } else if (!axle.getRaised() && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {

                valToUse = '';
                if (params.type === 'blank') {
                    valToUse = '-';
                } else if (params.type === 'distribute_value') {
                    valToUse = params.formatFunc(curVehicle.getAxlesHolder().getRearRearAxlesDistribution(params.massVal));
                } else if (params.type === 'rear') {
                    valToUse = params.formatFunc(params.massVal);
                }

                if (rearConnectedAdded === false) {
                    newCell = new CellConfig(null, config.TABLE_COLUMNS.REAR_CONNECTED).setValue(valToUse).setColTitle(rearConnectedTitle);
                    if (params.interaction) {
                        if (params.type === 'distribute_value') {
                            newCell.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleRearConnectedInteractionTargetElementId(curVehicle.getAxlesHolder().getAxleGroupByAxleNumber(axle.getNumber()).getId())));
                        } else if (params.type === 'rear') {
                            newCell.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, params.interactionTargetElementId));
                        }
                    }
                    row.addCell(newCell);

                    rearConnectedAdded = true;
                }

            } else if (!axle.getRaised() && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {

                colName = config.TABLE_COLUMNS.TAG + '_' + tagCounter + '_' + axle.getNumber();

                letter = axle.getNumber();
                colLetters[colName] = {};
                colLetters[colName].letters = [letter];

                valToUse = '';
                if (params.type === 'blank' || params.type === 'rear') {
                    valToUse = '-';
                } else if (params.type === 'distribute_value') {
                    valToUse = params.formatFunc(curVehicle.getAxlesHolder().getPusherOrTagRearAxleDistribution(axle.getNumber(), params.massVal));
                }
                newCell = new CellConfig(null, colName).setValue(valToUse).setColTitle(config.getTranslationText('3065', [letter]));
                if (params.interaction) {
                    if (params.type === 'distribute_value') {
                        newCell.setInteraction(offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehiclePusherOrTagInteractionTargetElementId(axle.getId())));
                    } 
                }

                row.addCell(newCell);
                tagCounter++;
            }

        });
    }

    function generatePusherTagAndRearConnectedColumnConfigs(colConfigs) {

        

        var pusherCounter = 1;
        var tagCounter = 1;
        var rearConnectedAdded = false;
        curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

            var colName = '';
            if (!axle.getRaised() && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {

                colName = config.TABLE_COLUMNS.PUSHER + '_' + pusherCounter + '_' + axle.getNumber();

                colConfigs.push(new TableColumnConfig(colName).setTextColour(alternateCellTextColour));
                
                pusherCounter++;
            } else if (!axle.getRaised() && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {

                

                if (rearConnectedAdded === false) {
                    colConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.REAR_CONNECTED).setTextColour(alternateCellTextColour));

                    rearConnectedAdded = true;
                }

            } else if (!axle.getRaised() && axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {

                colName = config.TABLE_COLUMNS.TAG + '_' + tagCounter + '_' + axle.getNumber();

                colConfigs.push(new TableColumnConfig(colName).setTextColour(alternateCellTextColour));

                tagCounter++;
            }

        });
    }

    function setupBridgeViewVehicleTableRows(tableMeta) {
        var viewRows = [];
        // var bridgeGroupsVisible = offerManager.getBridgeGroupsVisibleForActiveLegislations();
        let bridgeGroupsVisible = rigOps.getBridgeGroupsVisible();

        if (bridgeGroupsVisible.showExtremeFrontGroup) {
            viewRows.push(setupGroup4Row());//1
        }

        if (bridgeGroupsVisible.showPusher1Group) {
            viewRows.push(setupGroup5Row());//2
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            viewRows.push(setupGroup6Row());//3
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            viewRows.push(setupGroup7Row());//4
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup) {
            viewRows.push(setupGroup3Row());//5
        }
        if (bridgeGroupsVisible.showTag1Group) {
            viewRows.push(setupGroup8Row());//6
        }
        if (bridgeGroupsVisible.showTag2Group) {
            viewRows.push(setupGroup9Row());//7
        }
        if (bridgeGroupsVisible.showTag3Group) {
            viewRows.push(setupGroup10Row());//8
        }
        if (bridgeGroupsVisible.showExtremeRearGroup) {
            viewRows.push(setupGroup2Row());//9
        }
        viewRows.push(setupGroup1Row());//10

        var tableColumnConfigs = [];
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.LABELS).setTitle(config.getTranslationText('1005')).setContentsAlignment('left').setTitleFontStyleAddition('bold').setTitleFontSize(dashboardAndTableTitleFontSize));
        
        extractToTableMeta(viewRows, tableMeta, tableColumnConfigs);
    }

    function setupBridgeViewVehicleAndSemiTableRows() {
        var viewRows = [];
        // var bridgeGroupsVisible = offerManager.getBridgeGroupsVisibleForActiveLegislations();
        let bridgeGroupsVisible = rigOps.getBridgeGroupsVisible();

        var onlyCountDownAxles = true;
        var numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        var numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
            viewRows.push(setupGroup6Row());
        }
        if (bridgeGroupsVisible.showPusher1Group) {
            viewRows.push(setupGroup8Row());
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            viewRows.push(setupGroup9Row());
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            viewRows.push(setupGroup10Row());
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
            viewRows.push(setupGroup5Row());
        }
        if (bridgeGroupsVisible.showTag1Group) {
            viewRows.push(setupGroup11Row());
        }
        if (bridgeGroupsVisible.showTag2Group) {
            viewRows.push(setupGroup12Row());
        }
        if (bridgeGroupsVisible.showTag3Group) {
            viewRows.push(setupGroup13Row());
        }
        if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
            viewRows.push(setupGroup7Row());
        }
        if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
            viewRows.push(setupGroup4Row());
        }
        viewRows.push(setupGroup1Row());
        viewRows.push(setupGroup3Row());
        viewRows.push(setupGroup2Row());

        var tableColumnConfigs = [];
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.LABELS).setTitle(config.getTranslationText('1005')).setContentsAlignment('left').setTitleFontStyleAddition('bold').setTitleFontSize(dashboardAndTableTitleFontSize));

        extractToTableMeta(viewRows, tableMeta, tableColumnConfigs);
    }

    function setupBridgeViewVehicleAndPupTableRows() {
        var viewRows = [];
        // var bridgeGroupsVisible = offerManager.getBridgeGroupsVisibleForActiveLegislations();
        let bridgeGroupsVisible = rigOps.getBridgeGroupsVisible();

        var onlyCountDownAxles = true;
        var numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        var numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
            viewRows.push(setupGroup6Row());
        }
        if (bridgeGroupsVisible.showPusher1Group) {
            viewRows.push(setupGroup8Row());
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            viewRows.push(setupGroup9Row());
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            viewRows.push(setupGroup10Row());
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
            viewRows.push(setupGroup5Row());
        }
        if (bridgeGroupsVisible.showTag1Group) {
            viewRows.push(setupGroup11Row());
        }
        if (bridgeGroupsVisible.showTag2Group) {
            viewRows.push(setupGroup12Row());
        }
        if (bridgeGroupsVisible.showTag3Group) {
            viewRows.push(setupGroup13Row());
        }
        if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
            viewRows.push(setupGroup7Row());
        }
        if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
            viewRows.push(setupGroup4Row());
        }
        viewRows.push(setupGroup1Row());
        viewRows.push(setupGroup3Row());
        viewRows.push(setupGroup2Row());

        var tableColumnConfigs = [];
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.LABELS).setTitle(config.getTranslationText('1005')).setContentsAlignment('left').setTitleFontStyleAddition('bold').setTitleFontSize(dashboardAndTableTitleFontSize));

        extractToTableMeta(viewRows, tableMeta, tableColumnConfigs);
    } 

    function setupBridgeViewVehicleAndDrawbarTableRows() {
        var viewRows = [];

        // var bridgeGroupsVisible = offerManager.getBridgeGroupsVisibleForActiveLegislations();
        let bridgeGroupsVisible = rigOps.getBridgeGroupsVisible();

        var onlyCountDownAxles = true;
        var numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        var numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
            viewRows.push(setupGroup9Row());
        }
        if (bridgeGroupsVisible.showPusher1Group) {
            viewRows.push(setupGroup12Row());
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            viewRows.push(setupGroup13Row());
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            viewRows.push(setupGroup14Row());
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
            viewRows.push(setupGroup8Row());
        }
        if (bridgeGroupsVisible.showTag1Group) {
            viewRows.push(setupGroup15Row());
        }
        if (bridgeGroupsVisible.showTag2Group) {
            viewRows.push(setupGroup16Row());
        }
        if (bridgeGroupsVisible.showTag3Group) {
            viewRows.push(setupGroup17Row());
        }
        if (bridgeGroupsVisible.showTrailer1ExtremeFrontGroup) {
            viewRows.push(setupGroup10Row());
        }
        if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
            viewRows.push(setupGroup11Row());
        }
        if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
            viewRows.push(setupGroup7Row());
        }
        viewRows.push(setupGroup1Row());
        viewRows.push(setupGroup6Row());
        viewRows.push(setupGroup2Row());
        viewRows.push(setupGroup4Row());
        viewRows.push(setupGroup5Row());
        viewRows.push(setupGroup3Row());
        


        //viewRows.push(setupGroup1Row());
        //viewRows.push(setupGroup2Row());
        //viewRows.push(setupGroup4Row());
        //viewRows.push(setupGroup5Row());
        //viewRows.push(setupGroup6Row());
        //viewRows.push(setupGroup3Row());

        var tableColumnConfigs = [];
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.LABELS).setTitle(config.getTranslationText('1005')).setContentsAlignment('left').setTitleFontStyleAddition('bold').setTitleFontSize(dashboardAndTableTitleFontSize));

        extractToTableMeta(viewRows, tableMeta, tableColumnConfigs);
    }

    function setupBridgeViewVehicleAndInterlinkAndSemiTableRows() {
        var viewRows = [];

        // var bridgeGroupsVisible = offerManager.getBridgeGroupsVisibleForActiveLegislations();
        let bridgeGroupsVisible = rigOps.getBridgeGroupsVisible();

        var onlyCountDownAxles = true;
        var numDownFrontAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        var numDownRearAxles = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        if (bridgeGroupsVisible.showExtremeFrontGroup && numDownFrontAxles >= 1) {
            viewRows.push(setupGroup9Row());
        }
        if (bridgeGroupsVisible.showPusher1Group) {
            viewRows.push(setupGroup12Row());
        }
        if (bridgeGroupsVisible.showPusher2Group) {
            viewRows.push(setupGroup13Row());
        }
        if (bridgeGroupsVisible.showPusher3Group) {
            viewRows.push(setupGroup14Row());
        }
        if (bridgeGroupsVisible.showExtremeRearDrivenGroup && curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) >= 1) {
            viewRows.push(setupGroup8Row());
        }
        if (bridgeGroupsVisible.showTag1Group) {
            viewRows.push(setupGroup15Row());
        }
        if (bridgeGroupsVisible.showTag2Group) {
            viewRows.push(setupGroup16Row());
        }
        if (bridgeGroupsVisible.showTag3Group) {
            viewRows.push(setupGroup17Row());
        }
        if (bridgeGroupsVisible.showTrailer1ExtremeRearGroup) {
            viewRows.push(setupGroup10Row());
        }
        if (bridgeGroupsVisible.showTrailer2ExtremeRearGroup) {
            viewRows.push(setupGroup11Row());
        }
        if (bridgeGroupsVisible.showExtremeRearGroup && numDownRearAxles > 1) {
            viewRows.push(setupGroup7Row());
        }
        viewRows.push(setupGroup1Row());
        viewRows.push(setupGroup6Row());
        viewRows.push(setupGroup2Row());
        viewRows.push(setupGroup4Row());
        viewRows.push(setupGroup5Row());
        viewRows.push(setupGroup3Row());


        //viewRows.push(setupGroup1Row());
        //viewRows.push(setupGroup2Row());
        
        //viewRows.push(setupGroup4Row());
        //viewRows.push(setupGroup5Row());
        //viewRows.push(setupGroup6Row());
        //viewRows.push(setupGroup3Row());

        var tableColumnConfigs = [];
        tableColumnConfigs.push(new TableColumnConfig(config.TABLE_COLUMNS.LABELS).setTitle(config.getTranslationText('1005')).setContentsAlignment('left').setTitleFontStyleAddition('bold').setTitleFontSize(dashboardAndTableTitleFontSize));

        extractToTableMeta(viewRows, tableMeta, tableColumnConfigs);
    }

    function addSpacerRow(tableMeta, height) {
        Object.keys(tableMeta.cols).forEach(function (key) {
            var rowName = 'spacer_' + globals.nextId();
            tableMeta.cols[key].cellData[rowName] = {};
            tableMeta.cols[key].cellData[rowName].id = key + '_' + rowName;
            tableMeta.cols[key].cellData[rowName].height = height;
        });
    }

    function extractToTableMeta(rows, tableMeta, cols) {
        rows.forEach(function (row) {
            if (row.getType() === config.TABLE_ROW_TYPE.DATA) {


                row.getCells().forEach(function (cell) {
                    if (tableMeta.cols[cell.getColName()] === undefined) {
                        tableMeta.cols[cell.getColName()] = {
                            title: cell.getColTitle(),
                            position: { x: 0, y: 0 },
                            cellData: {}
                        }
                    }
                    //if (tableMeta.cols[cell.getColName()].cellData === undefined) {
                    //    tableMeta.cols[cell.getColName()].cellData = {};
                    //}
                    if (tableMeta.cols[cell.getColName()].cellData[row.getId()] === undefined) {
                        tableMeta.cols[cell.getColName()].cellData[row.getId()] = {};
                    }
                    tableMeta.cols[cell.getColName()].cellData[row.getId()].id = cell.getId();
                    tableMeta.cols[cell.getColName()].cellData[row.getId()].value = cell.getFormattedValue();
                    //tableMeta.cols[cell.getColName()].cellData[row.getId()].label = row.getLabel();
                    tableMeta.cols[cell.getColName()].cellData[row.getId()].colour = cell.getColour();
                    tableMeta.cols[cell.getColName()].cellData[row.getId()].bold = row.getIsBold();
                    tableMeta.cols[cell.getColName()].cellData[row.getId()].displayMask = cell.getDisplayMask();
                    if (cell.getInteraction() !== undefined) {
                        tableMeta.cols[cell.getColName()].cellData[row.getId()].interaction = cell.getInteraction();
                        tableMeta.cols[cell.getColName()].cellData[row.getId()].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols[cell.getColName()].cellData[row.getId()].interaction.callbacks);
                    }
                });
            } else if (row.getType() === config.TABLE_ROW_TYPE.UNDERLINE) {
                underlineCounter++;
                Object.keys(tableMeta.cols).forEach(function (key) {
                    tableMeta.cols[key].cellData['underline' + underlineCounter] = { underlineColour: row.getColour() };
                });
            }
        });
        if (cols) {
            cols.forEach(function (col) {
                if (col.getBackgroundColour()) {
                    tableMeta.cols[col.getName()].colour = col.getBackgroundColour();
                }
                if (col.getTextColour()) {
                    tableMeta.cols[col.getName()].textColour = col.getTextColour();
                }
                if (col.getTitle()) {
                    tableMeta.cols[col.getName()].title = col.getTitle();
                }
                if (col.getContentsAlignment()) {
                    tableMeta.cols[col.getName()].contentsAlign = col.getContentsAlignment();
                }
                if (col.getTitleFontStyleAddition()) {
                    tableMeta.cols[col.getName()].titleFontStyleAddition = col.getTitleFontStyleAddition();
                }
                if (col.getTitleFontSize()) {
                    tableMeta.cols[col.getName()].titleFontSize = col.getTitleFontSize();
                }
            });
        }
    }

    function setupGroup1Row() {
        var group1BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP1);

        group1BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP1BRIDGEGROUP));
        group1BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP1BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group1BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP1BRIDGEDISTANCEROUNDED));
        }
        group1BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP1BRIDGEPERMISSIBLE));
        group1BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP1BRIDGEACTUAL));
        group1BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP1BRIDGEUNUSEDCAPACITY));
        group1BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP1BRIDGEOVERLOAD));

        return group1BridgeRow;
    }

    function setupGroup2Row() {
        var group2BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP2);
        
        var cellConfigGroup2 = getCellConfig(config.VALUE_TYPE.GROUP2BRIDGEGROUP);
        group2BridgeRow.addCell(cellConfigGroup2);
        group2BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP2BRIDGEDISTANCE));
        
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group2BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP2BRIDGEDISTANCEROUNDED));
        }
        group2BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP2BRIDGEPERMISSIBLE));
        group2BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP2BRIDGEACTUAL));
        group2BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP2BRIDGEUNUSEDCAPACITY));
        group2BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP2BRIDGEOVERLOAD));

        return group2BridgeRow;
    }

    function setupGroup3Row() {
        var group3BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP3);

        group3BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP3BRIDGEGROUP));
        group3BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP3BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group3BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP3BRIDGEDISTANCEROUNDED));
        }
        group3BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP3BRIDGEPERMISSIBLE));
        group3BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP3BRIDGEACTUAL));
        group3BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP3BRIDGEUNUSEDCAPACITY));
        group3BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP3BRIDGEOVERLOAD));

        return group3BridgeRow;
    }

    function setupGroup4Row() {
        var group4BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP4);

        group4BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP4BRIDGEGROUP));
        group4BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP4BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group4BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP4BRIDGEDISTANCEROUNDED));
        }
        group4BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP4BRIDGEPERMISSIBLE));
        group4BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP4BRIDGEACTUAL));
        group4BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP4BRIDGEUNUSEDCAPACITY));
        group4BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP4BRIDGEOVERLOAD));

        return group4BridgeRow;
    }

    function setupGroup5Row() {
        var group5BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP5);

        group5BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP5BRIDGEGROUP));
        group5BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP5BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group5BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP5BRIDGEDISTANCEROUNDED));
        }
        group5BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP5BRIDGEPERMISSIBLE));
        group5BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP5BRIDGEACTUAL));
        group5BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP5BRIDGEUNUSEDCAPACITY));
        group5BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP5BRIDGEOVERLOAD));

        return group5BridgeRow;
    }

    function setupGroup6Row() {
        var group6BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP6);

        group6BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP6BRIDGEGROUP));
        group6BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP6BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group6BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP6BRIDGEDISTANCEROUNDED));
        }
        group6BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP6BRIDGEPERMISSIBLE));
        group6BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP6BRIDGEACTUAL));
        group6BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP6BRIDGEUNUSEDCAPACITY));
        group6BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP6BRIDGEOVERLOAD));

        return group6BridgeRow;
    }

    function setupGroup7Row() {
        var group7BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP7);

        group7BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP7BRIDGEGROUP));
        group7BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP7BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group7BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP7BRIDGEDISTANCEROUNDED));
        }
        group7BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP7BRIDGEPERMISSIBLE));
        group7BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP7BRIDGEACTUAL));
        group7BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP7BRIDGEUNUSEDCAPACITY));
        group7BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP7BRIDGEOVERLOAD));

        return group7BridgeRow;
    }

    function setupGroup8Row() {
        var group8BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP8);

        group8BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP8BRIDGEGROUP));
        group8BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP8BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group8BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP8BRIDGEDISTANCEROUNDED));
        }
        group8BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP8BRIDGEPERMISSIBLE));
        group8BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP8BRIDGEACTUAL));
        group8BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP8BRIDGEUNUSEDCAPACITY));
        group8BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP8BRIDGEOVERLOAD));

        return group8BridgeRow;
    }

    function setupGroup9Row() {
        var group9BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP9);

        group9BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP9BRIDGEGROUP));
        group9BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP9BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group9BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP9BRIDGEDISTANCEROUNDED));
        }
        group9BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP9BRIDGEPERMISSIBLE));
        group9BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP9BRIDGEACTUAL));
        group9BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP9BRIDGEUNUSEDCAPACITY));
        group9BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP9BRIDGEOVERLOAD));

        return group9BridgeRow;
    }

    function setupGroup10Row() {
        var group10BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP10);

        group10BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP10BRIDGEGROUP));
        group10BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP10BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group10BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP10BRIDGEDISTANCEROUNDED));
        }
        group10BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP10BRIDGEPERMISSIBLE));
        group10BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP10BRIDGEACTUAL));
        group10BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP10BRIDGEUNUSEDCAPACITY));
        group10BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP10BRIDGEOVERLOAD));

        return group10BridgeRow;
    }

    function setupGroup11Row() {
        var group11BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP11);

        group11BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP11BRIDGEGROUP));
        group11BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP11BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group11BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP11BRIDGEDISTANCEROUNDED));
        }
        group11BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP11BRIDGEPERMISSIBLE));
        group11BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP11BRIDGEACTUAL));
        group11BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP11BRIDGEUNUSEDCAPACITY));
        group11BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP11BRIDGEOVERLOAD));

        return group11BridgeRow;
    }

    function setupGroup12Row() {
        var group12BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP12);

        group12BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP12BRIDGEGROUP));
        group12BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP12BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group12BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP12BRIDGEDISTANCEROUNDED));
        }
        group12BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP12BRIDGEPERMISSIBLE));
        group12BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP12BRIDGEACTUAL));
        group12BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP12BRIDGEUNUSEDCAPACITY));
        group12BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP12BRIDGEOVERLOAD));

        return group12BridgeRow;
    }

    function setupGroup13Row() {
        var group13BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP13);

        group13BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP13BRIDGEGROUP));
        group13BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP13BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group13BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP13BRIDGEDISTANCEROUNDED));
        }
        group13BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP13BRIDGEPERMISSIBLE));
        group13BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP13BRIDGEACTUAL));
        group13BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP13BRIDGEUNUSEDCAPACITY));
        group13BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP13BRIDGEOVERLOAD));

        return group13BridgeRow;
    }

    function setupGroup14Row() {
        var group14BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP14);

        group14BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP14BRIDGEGROUP));
        group14BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP14BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group14BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP14BRIDGEDISTANCEROUNDED));
        }
        group14BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP14BRIDGEPERMISSIBLE));
        group14BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP14BRIDGEACTUAL));
        group14BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP14BRIDGEUNUSEDCAPACITY));
        group14BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP14BRIDGEOVERLOAD));

        return group14BridgeRow;
    }

    function setupGroup15Row() {
        var group15BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP15);

        group15BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP15BRIDGEGROUP));
        group15BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP15BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group15BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP15BRIDGEDISTANCEROUNDED));
        }
        group15BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP15BRIDGEPERMISSIBLE));
        group15BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP15BRIDGEACTUAL));
        group15BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP15BRIDGEUNUSEDCAPACITY));
        group15BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP15BRIDGEOVERLOAD));

        return group15BridgeRow;
    }

    function setupGroup16Row() {
        var group16BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP16);

        group16BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP16BRIDGEGROUP));
        group16BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP16BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group16BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP16BRIDGEDISTANCEROUNDED));
        }
        group16BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP16BRIDGEPERMISSIBLE));
        group16BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP16BRIDGEACTUAL));
        group16BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP16BRIDGEUNUSEDCAPACITY));
        group16BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP16BRIDGEOVERLOAD));

        return group16BridgeRow;
    }

    function setupGroup17Row() {
        var group17BridgeRow = new TableRow(config.BRIDGE_GROUPS.GROUP17);

        group17BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP17BRIDGEGROUP));
        group17BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP17BRIDGEDISTANCE));
        if (rigOps.evaluationLegislationFunction(utilLegislationAttributeObjectCode.variableTypes.BridgeDistanceRounded) === 1) {
            group17BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP17BRIDGEDISTANCEROUNDED));
        }
        group17BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP17BRIDGEPERMISSIBLE));
        group17BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP17BRIDGEACTUAL));
        group17BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP17BRIDGEUNUSEDCAPACITY));
        group17BridgeRow.addCell(getCellConfig(config.VALUE_TYPE.GROUP17BRIDGEOVERLOAD));

        return group17BridgeRow;
    }

    function addUnderlineRow(colour) {
        underlineCounter++;
        Object.keys(tableMeta.cols).forEach(function (key) {
            tableMeta.cols[key].cellData['underline' + underlineCounter] = { underlineColour: colour };
        });
    }

    function setupTrailerTareRow(trailer) {

        var rowLabel = config.getTranslationText('100');

        var tareTrailerFrontVal, tareTrailerRearVal, tareTrailerTotalVal;
        
        tareTrailerFrontVal = offerManager.getUIFormattedMassValue(trailer.getTareFront(), formattingPrecision);
        if (isTrialUser) {
            tareTrailerRearVal = trialUserObfuscationText;
            tareTrailerTotalVal = trialUserObfuscationText;
        } else {
            tareTrailerRearVal = offerManager.getUIFormattedMassValue(trailer.getTareRear(), formattingPrecision);
            tareTrailerTotalVal = offerManager.getUIFormattedMassValue(trailer.getTareFront() + trailer.getTareRear(), formattingPrecision);
        }

        tableMeta.cols.Labels.cellData.Tare = { value: rowLabel, id: 'TRAILER_DETAIL_FRONT_label' };

        tableMeta.cols.trailerFront.cellData.Tare = { value: tareTrailerFrontVal, label: rowLabel, id: 'TRAILER_DETAIL_FRONT' };
        if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            //tableMeta.cols.trailerFront.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLES_TRAILER_FRONT + '.' + config.VALUE_TYPE.TRAILERTAREFRONT);
            if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, trailer.getChassisObjectName() + '.' + config.VALUE_TYPE.TRAILERTAREFRONT);
            } else {
                tableMeta.cols.trailerFront.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLES_TRAILER_FRONT + '.' + config.VALUE_TYPE.TRAILERTAREFRONT);
            }
            
            tableMeta.cols.trailerFront.cellData.Tare.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.trailerFront.cellData.Tare.interaction.callbacks);
        }

        tableMeta.cols.trailerRear.cellData.Tare = { value: tareTrailerRearVal, label: rowLabel, id: 'TRAILER_DETAIL_REAR' };
        if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            tableMeta.cols.trailerRear.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, trailer.getChassisObjectName() + '.' + config.VALUE_TYPE.TRAILERTAREREAR);
            tableMeta.cols.trailerRear.cellData.Tare.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.trailerRear.cellData.Tare.interaction.callbacks);
        }

        tableMeta.cols.total.cellData.Tare = { value: tareTrailerTotalVal, label: rowLabel, id: 'TRAILER_DETAIL_TOTAL' };



        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailerRear.cellData.Tare.interaction = xXedOutValuesInteractionMetaObject;
            tableMeta.cols.total.cellData.Tare.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupTrailerAccessoryRow(accessoryNum, accessory, trailer) {

        var accessoryFrontVal, accessoryRearVal, accessoryTotalVal;



        //var accessoryLabel = getTableAccessoryLabel(accessory.getAccessoryType());
        var accessoryLabel = accessory.getDescriptionForDrawing();

        var distanceFromWheelbaseTheoreticalEnd = 0, wheelbaseTheoretical = 0;

        if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
            distanceFromWheelbaseTheoreticalEnd = trailer.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, trailer.getFrontOfTrailerToWheelbaseTheoreticalEnd(), trailer.getFrontOfTrailerToLastAxle());    
        } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
            distanceFromWheelbaseTheoreticalEnd = trailer.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, trailer.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), trailer.getFrontOfTrailerToLastAxleExclDrawbar());
        }

        wheelbaseTheoretical = trailer.getWheelbaseTheoretical();

        accessoryFrontVal = offerManager.getUIFormattedMassValue(accessory.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical), formattingPrecision);
        if (isTrialUser) {
            accessoryRearVal = trialUserObfuscationText;
            accessoryTotalVal = trialUserObfuscationText;
        } else {
            accessoryRearVal = offerManager.getUIFormattedMassValue(accessory.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical), formattingPrecision);
            accessoryTotalVal = offerManager.getUIFormattedMassValue(accessory.getTotalLoad(), formattingPrecision);
        }

        tableMeta.cols.Labels.cellData['Accessory' + accessoryNum] = { value: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_label' };

        tableMeta.cols.trailerFront.cellData['Accessory' + accessoryNum] = { value: accessoryFrontVal, label: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_front' };
        //if (hasPusherOrTagDown === true) {
        //    generateEmptyPusherTagAndRearConnectedRowCols('Accessory' + accessoryNum, accessoryLabel);
        //}
        tableMeta.cols.trailerRear.cellData['Accessory' + accessoryNum] = { value: accessoryRearVal, label: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_rear' };
        tableMeta.cols.total.cellData['Accessory' + accessoryNum] = { value: accessoryTotalVal, label: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_total' };

        //if (accessory.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            var pathAppend = getAccessoryPathAppend(accessory);

            tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, trailer.getChassisObjectName() + pathAppend);
            tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction.callbacks);
        //}

        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailerRear.cellData['Accessory' + accessoryNum].interaction = xXedOutValuesInteractionMetaObject;
            tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction = xXedOutValuesInteractionMetaObject;
        }

    }

    function getAccessoryPathAppend(accessory) {
        var pathAppend = null;
        switch (accessory.getAccessoryType()) {
            case config.ACCESSORY_TYPES.CRANE:
                pathAppend = '.' + config.OBJECT_TYPES.ACCESSORY + '.' + accessory.getAccessoryType() + '.' + config.VALUE_TYPE.CRANEMASS;
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                pathAppend = '.' + config.OBJECT_TYPES.ACCESSORY + '.' + accessory.getAccessoryType() + '.' + config.VALUE_TYPE.TAILLIFTMASS;
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                pathAppend = '.' + config.OBJECT_TYPES.ACCESSORY + '.' + accessory.getAccessoryType() + '.' + config.VALUE_TYPE.FRIDGEMASS;
                break;
            case config.ACCESSORY_TYPES.OTHER:
                pathAppend = '.' + config.OBJECT_TYPES.ACCESSORY + '.' + accessory.getAccessoryType() + '.' + accessory.getId() + '.' + config.VALUE_TYPE.OTHERMASS;
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                pathAppend = '.' + config.OBJECT_TYPES.ACCESSORY + '.' + accessory.getAccessoryType() + '.' + config.VALUE_TYPE.FIFTHWHEELMASS;
                break;
            case config.ACCESSORY_TYPES.HITCH:
                pathAppend = '.' + config.OBJECT_TYPES.ACCESSORY + '.' + accessory.getAccessoryType() + '.' + config.VALUE_TYPE.HITCHMASS;
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                pathAppend = '.' + config.OBJECT_TYPES.ACCESSORY + '.' + accessory.getAccessoryType() + '.' + config.VALUE_TYPE.HOOKLIFTMASS;
                break;
            case config.ACCESSORY_TYPES.BODY:
                pathAppend = '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYMASS;
                break;
        }
        return pathAppend;
    }

    function setupTrailerOtherAccessoriesOverflowRow(trailer, alreadyHandledAccessories) {

        var otherAccessoriesFrontVal, otherAccessoriesRearVal, otherAccessoriesTotalVal;

        
        var rowLabel = config.getTranslationText('2805');

        
        var wheelbaseTheoretical = trailer.getWheelbaseTheoretical();
        
        if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
            otherAccessoriesFrontVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerFrontAxleTotalRemainingAccessories(alreadyHandledAccessories, trailer.getFrontOfTrailerToWheelbaseTheoreticalEnd(), trailer.getFrontOfTrailerToLastAxle(), wheelbaseTheoretical), formattingPrecision);
        } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
            otherAccessoriesFrontVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerFrontAxleTotalRemainingAccessories(alreadyHandledAccessories, trailer.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), trailer.getFrontOfTrailerToLastAxleExclDrawbar(), wheelbaseTheoretical), formattingPrecision);
        }

        if (isTrialUser) {
            otherAccessoriesRearVal = trialUserObfuscationText;
            otherAccessoriesTotalVal = trialUserObfuscationText;
        } else {
            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                otherAccessoriesRearVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerRearAxleTotalRemainingAccessories(alreadyHandledAccessories, trailer.getFrontOfTrailerToWheelbaseTheoreticalEnd(), trailer.getFrontOfTrailerToLastAxle(), wheelbaseTheoretical), formattingPrecision);
            } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
                otherAccessoriesRearVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerRearAxleTotalRemainingAccessories(alreadyHandledAccessories, trailer.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), trailer.getFrontOfTrailerToLastAxleExclDrawbar(), wheelbaseTheoretical), formattingPrecision);
            }
            
            otherAccessoriesTotalVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerTotalTotalRemainingAccessories(alreadyHandledAccessories), formattingPrecision);
        }

        tableMeta.cols.Labels.cellData.TotolOther = { value: rowLabel, id: rowLabel + '_label' };

        tableMeta.cols.trailerFront.cellData.TotolOther = { value: otherAccessoriesFrontVal, label: rowLabel, id: rowLabel + '_front' };
        
        tableMeta.cols.trailerRear.cellData.TotalOther = { value: otherAccessoriesRearVal, label: rowLabel, id: rowLabel + '_rear' };
        tableMeta.cols.total.cellData.TotalOther = { value: otherAccessoriesTotalVal, label: rowLabel, id: rowLabel + '_total'};

        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailerRear.cellData.TotalOther.interaction = xXedOutValuesInteractionMetaObject;
            tableMeta.cols.total.cellData.TotalOther.interaction = xXedOutValuesInteractionMetaObject;
        }

    }
    
    function setupTrailerTotalRow(trailer) {

        var rowLabel;
        if (trailer.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            rowLabel = config.getTranslationText('4020');
        } else {
            rowLabel = config.getTranslationText('4021');
        }


        var totalTrailerFrontVal, totalTrailerRearVal, totalTrailerTotalVal;
        var trailerBody = trailer.getAccessoryHolder().getBody();
        var trailerBodyFront = 0, trailerBodyRear = 0, trailerBodyTotal = 0;
        if (trailerBody !== null) {
            var distanceFromWheelbaseTheoreticalEnd = 0;
            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                distanceFromWheelbaseTheoreticalEnd = trailer.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForTrailer(trailerBody, trailer.getFrontOfTrailerToWheelbaseTheoreticalEnd(), trailer.getFrontOfTrailerToLastAxle());
            } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
                distanceFromWheelbaseTheoreticalEnd = trailer.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForTrailer(trailerBody, trailer.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), trailer.getFrontOfTrailerToLastAxleExclDrawbar());
            }
            
            var wheelbaseTheoretical = trailer.getWheelbaseTheoretical();

            trailerBodyFront = trailerBody.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            trailerBodyRear = trailerBody.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            trailerBodyTotal = trailerBody.getTotalLoad();
        }
        
        
        if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
            totalTrailerFrontVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerFrontAxleTotalAccessories(trailer.getFrontOfTrailerToWheelbaseTheoreticalEnd(), trailer.getFrontOfTrailerToLastAxle(), trailer.getWheelbaseTheoretical()) + trailer.getTareFront() + trailerBodyFront, formattingPrecision);
        } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
            totalTrailerFrontVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerFrontAxleTotalAccessories(trailer.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), trailer.getFrontOfTrailerToLastAxleExclDrawbar(), trailer.getWheelbaseTheoretical()) + trailer.getTareFront() + trailerBodyFront, formattingPrecision);
        }
        if (isTrialUser) {
            totalTrailerRearVal = trialUserObfuscationText;
            totalTrailerTotalVal = trialUserObfuscationText;
        } else {
            
            if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                totalTrailerRearVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerRearAxleTotalAccessories(trailer.getFrontOfTrailerToWheelbaseTheoreticalEnd(), trailer.getFrontOfTrailerToLastAxle(), trailer.getWheelbaseTheoretical()) + trailer.getTareRear() + trailerBodyRear, formattingPrecision);
            } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
                totalTrailerRearVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTrailerRearAxleTotalAccessories(trailer.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), trailer.getFrontOfTrailerToLastAxleExclDrawbar(), trailer.getWheelbaseTheoretical()) + trailer.getTareRear() + trailerBodyRear, formattingPrecision);
            }
            totalTrailerTotalVal = offerManager.getUIFormattedMassValue(trailer.getAccessoryHolder().getTotalTotalAccessories() + trailer.getTareFront() + trailer.getTareRear() + trailerBodyTotal, formattingPrecision);
        }

        tableMeta.cols.Labels.cellData.Total = { value: rowLabel, id: config.VALUE_TYPE.TRAILERUNLADENFRONT + '_label'};
        tableMeta.cols.trailerFront.cellData.Total = { value: totalTrailerFrontVal, label: rowLabel, id: config.VALUE_TYPE.TRAILERUNLADENFRONT, bold: true };
        tableMeta.cols.trailerRear.cellData.Total = { value: totalTrailerRearVal, label: rowLabel, id: config.VALUE_TYPE.TRAILERUNLADENREAR, bold: true };
        tableMeta.cols.total.cellData.Total = { value: totalTrailerTotalVal, label: rowLabel, id: config.VALUE_TYPE.TRAILERUNLADENTOTAL, bold: true };

        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailerRear.cellData.Total.interaction = xXedOutValuesInteractionMetaObject;
            tableMeta.cols.total.cellData.Total.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupTareRow() {
        var pathToUse;
        var rowLabel = config.getTranslationText('100');

        var tareFrontVal, tareRearVal, tareTotalVal;

        
        //if (hasPusherOrTagDown === true) {
        //    tareRear = curVehicle.getTareRearRear();
        //} else {
        //    tareRear = offerManager.getValue(config.VALUE_TYPE.TAREREAR);
        //}

        //if (curVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) > 0 && curVehicle.getAxlesHolder().getAxle2Front().isAdded() === true) {
        if (curVehicle.getAxlesHolder().hasAddedPusherOrTagDown()) {
        
            //var altTareFrontVal = offerManager.getRigOps().getTareRevisedFrontAxles() + offerManager.getRigOps().getPusher1FrontAxles() + offerManager.getRigOps().getPusher2FrontAxles() + offerManager.getRigOps().getPusher3FrontAxles() +
            //                        offerManager.getRigOps().getTag1FrontAxles() + offerManager.getRigOps().getTag2FrontAxles() + offerManager.getRigOps().getTag3FrontAxles();
            var altTareFrontVal = rigOps.getDistributedTareFrontTotal();
            tareFrontVal = offerManager.getUIFormattedMassValue(altTareFrontVal, formattingPrecision);

            //var altTareRearVal = offerManager.getRigOps().getTareRevisedRearAxles() + offerManager.getRigOps().getPusher1RearAxles() + offerManager.getRigOps().getPusher2RearAxles() + offerManager.getRigOps().getPusher3RearAxles() +
            //                        offerManager.getRigOps().getTag1RearAxles() + offerManager.getRigOps().getTag2RearAxles() + offerManager.getRigOps().getTag3RearAxles();
            var altTareRearVal = rigOps.getDistributedTareRearTotal();
            tareRearVal = offerManager.getUIFormattedMassValue(altTareRearVal, formattingPrecision);
            tareTotalVal = offerManager.getUIFormattedMassValue(altTareFrontVal + altTareRearVal, formattingPrecision);
        } else {

            var tareFront = rigOps.getVehicleOnlyFrontTare();//offerManager.getValue(config.VALUE_TYPE.TAREFRONT);
            var tareRear;
            
            //if (curVehicle.getAxlesHolder().getAnyRearAxlesOverridden() || (curVehicle.getAxlesHolder().hasPreconfiguredPusherOrTag() && curVehicle.getTareRearOverride())) {
            //    tareRear = curVehicle.getAxlesHolder().getRearAxleWeight();
            //} else {
            //    tareRear = rigOps.getVehicleOnlyRearTare();
            //}
            tareRear = rigOps.getVehicleTareRearRearOnly();
            
            

            tareFrontVal = offerManager.getUIFormattedMassValue(tareFront, formattingPrecision);
            tareRearVal = offerManager.getUIFormattedMassValue(tareRear, formattingPrecision);
            tareTotalVal = offerManager.getUIFormattedMassValue(tareFront + tareRear, formattingPrecision);
        }
        
        tableMeta.cols.Labels.cellData.Tare = { value: rowLabel, id: config.VALUE_TYPE.TAREFRONT + '_label'};
        
        //tableMeta.cols.front.cellData.Tare = { value: tareFrontVal, label: config.getTranslationText('100') };
        tableMeta.cols.front.cellData.Tare = { value: tareFrontVal, label: rowLabel };
        tableMeta.cols.front.cellData.Tare.id = config.VALUE_TYPE.TAREFRONT;
        if (missingValuesObject[config.VALUE_TYPE.TAREFRONT] !== undefined) {
            tableMeta.cols.front.cellData.Tare.missingValMeta = missingValuesObject[config.VALUE_TYPE.TAREFRONT];
        } else {
            //if (curVehicle.getAxlesHolder().hasAddedAxle() === true) {
            //    pathToUse = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + config.VALUE_TYPE.TAREWEIGHTMODIFIED;
            //} else {
            //    pathToUse = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT;
            //}
            pathToUse = getVehicleTareFrontInteractionTargetElementId();

            if (globals.user.isLoadingAdministrator() === true) {
                //tableMeta.cols.front.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREFRONT);
                tableMeta.cols.front.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, pathToUse);
                tableMeta.cols.front.cellData.Tare.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.front.cellData.Tare.interaction.callbacks);
            }
        }

        if (hasPusherOrTagDown === true) {
            //generateEmptyPusherTagAndRearConnectedRowCols('Tare', config.getTranslationText('100'));
            //generateEmptyPusherTagAndRearConnectedRowCols('Tare', config.getTranslationText('100'));
            var tareRearValToDistribute;
            if (curVehicle.getAxlesHolder().hasAddedPusherOrTagDown()) {
                tareRearValToDistribute = rigOps.getDistributedTareRearTotal();
            } else {
                tareRearValToDistribute = curVehicle.getTareRear();
            }
            generatePopulatedPusherTagAndRearConnectedTareCols('Tare', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, tareRearValToDistribute);
        } 

        //tableMeta.cols.rear.cellData.Tare = { value: tareRearVal, label: config.getTranslationText('100') };
        tableMeta.cols.rear.cellData.Tare = { value: tareRearVal, label: rowLabel };
        if (hasPusherOrTagDown === true) {
            tableMeta.cols.rear.cellData.Tare.id = config.VALUE_TYPE.TAREREARREAR + '_pusherOrTagDown';
        } else {
            tableMeta.cols.rear.cellData.Tare.id = config.VALUE_TYPE.TAREREARREAR;
        }
        if (missingValuesObject[config.VALUE_TYPE.TAREREARREAR] !== undefined) {
            tableMeta.cols.rear.cellData.Tare.missingValMeta = missingValuesObject[config.VALUE_TYPE.TAREREARREAR];
        } else {
            if (globals.user.isLoadingAdministrator() === true) {
                if (hasPusherOrTagDown === true) {
                    //tableMeta.cols.rear.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareRearInteractionTargetElementId());
                    //tableMeta.cols.rear.cellData.Tare.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.rear.cellData.Tare.interaction.callbacks);
                } else {
                    tableMeta.cols.rear.cellData.Tare.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, getVehicleTareRearInteractionTargetElementId());
                    tableMeta.cols.rear.cellData.Tare.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.rear.cellData.Tare.interaction.callbacks);
                }
            }
        }

        //tableMeta.cols.total.cellData.Tare = { value: tareTotalVal, label: config.getTranslationText('100') };
        tableMeta.cols.total.cellData.Tare = { value: tareTotalVal, label: rowLabel };
        tableMeta.cols.total.cellData.Tare.id = config.VALUE_TYPE.TARETOTAL;
        if (missingValuesObject[config.VALUE_TYPE.TARETOTAL] !== undefined) {
            tableMeta.cols.total.cellData.Tare.missingValMeta = missingValuesObject[config.VALUE_TYPE.TARETOTAL];
        } 
    }

    function generateEmptyPusherTagAndRearConnectedRowCols(rowKey, rowLabel, optionalColour) {
        Object.keys(tableMeta.cols).forEach(function (key) {
            if(key.indexOf('Pusher') !== -1 || key.indexOf('Connected') !== -1 || key.indexOf('Tag') !== -1) {
                tableMeta.cols[key].cellData[rowKey] = { value: '-', label: rowLabel, colour: optionalColour };
                tableMeta.cols[key].cellData[rowKey].id = globals.nextId();
            }                    
        });
    }

    function generatePopulatedPusherTagAndRearConnectedTareCols(rowKey, rowLabel, tagOrPusherAxleTareFunc, rearOnlyTareFunc, massVal, forcedTableMeta) { 

        var tableMetaToUse = forcedTableMeta || tableMeta;
        var elementId;
        Object.keys(tableMetaToUse.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                var axleNumber, axleTypeNumber, valToUse = '';
                var highlightColourIfAny;
                
                
                var rowId;
                if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1) {
                    axleNumber = Number(key.split('_')[2]);
                    axleTypeNumber = key.split('_')[1];
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        if (curVehicle.getAxlesHolder().hasAddedPusherOrTagDown()) {
                            valToUse = tagOrPusherAxleTareFunc({ objectOrAccessoryType: config.CHASSIS_OBJECT_TYPES.VEHICLE, axleNumber: axleNumber, vehicleValSubCode: 'tare', rearMassVal: massVal });
                        } else {
                            var pusherOrTagAxle = curVehicle.getAxlesHolder().getAxleByNumber(axleNumber);
                            //if (pusherOrTagAxle.getAxleWeightOverride()) {
                                valToUse = pusherOrTagAxle.getAxleWeight();
                            //} else {
                            //    valToUse = tagOrPusherAxleTareFunc({ objectOrAccessoryType: config.CHASSIS_OBJECT_TYPES.VEHICLE, axleNumber: axleNumber, vehicleValSubCode: 'tare', rearMassVal: massVal });
                            //}
                        }
                        
                    }

                    var axleTypeNum = key.split('_')[1];
                    if (key.indexOf('Pusher') !== -1) {
                        rowId = config.VALUE_TYPE.AXLETAREPUSHER + axleTypeNumber;
                    } else {
                        rowId = config.VALUE_TYPE.AXLETARETAG + axleTypeNumber;
                    }                            
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + curVehicle.getAxlesHolder()['getAxle' + axleTypeNumber + 'Rear']().getId() + '.' + config.VALUE_TYPE.AXLEWEIGHT;
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + curVehicle.getAxlesHolder()['getAxle' + axleTypeNumber + 'Rear']().getId() + '.' + config.VALUE_TYPE.TAREWEIGHTMODIFIED; 
                    elementId = getVehiclePusherOrTagInteractionTargetElementId(curVehicle.getAxlesHolder()['getAxle' + axleTypeNumber + 'Rear']().getId());
                } else if (key.indexOf('Connected') !== -1) {
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        if (curVehicle.getAxlesHolder().hasAddedPusherOrTagDown()) {
                            valToUse = rearOnlyTareFunc({ objectOrAccessoryType: config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleValSubCode: 'tare', rearMassVal: massVal });
                        }else {
                            //if (curVehicle.getTareRearOverride()) {
                                valToUse = curVehicle.getTareRearRear();
                            //} else {
                            //    valToUse = rearOnlyTareFunc({ objectOrAccessoryType: config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleValSubCode: 'tare', rearMassVal: massVal });
                            //}
                        }
                        
                    }

                    
                    rowId = config.VALUE_TYPE.AXLETAREREARREAR;
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREARREAR;
                    //if (curVehicle.getAxlesHolder().hasAddedAxle() === true) {
                    //    elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + config.VALUE_TYPE.TAREWEIGHTMODIFIED;
                    //} else {
                    //    elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TAREREARREAR;
                    //} 
                    elementId = getVehicleRearConnectedInteractionTargetElementId(curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId());
                }
                
                valToUse = offerManager.getUIFormattedMassValue(valToUse, formattingPrecision);
                tableMetaToUse.cols[key].cellData[rowKey] = { value: valToUse, label: rowLabel, colour: highlightColourIfAny };
                
                if (globals.user.isLoadingAdministrator() === true) {
                    if (xXedOutValuesInteractionMetaObject !== undefined) {
                        tableMetaToUse.cols[key].cellData[rowKey].interaction = xXedOutValuesInteractionMetaObject;
                    } else {
                        tableMetaToUse.cols[key].cellData[rowKey].interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId);
                        tableMetaToUse.cols[key].cellData[rowKey].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMetaToUse.cols[key].cellData[rowKey].interaction.callbacks);
                    }
                }
                tableMetaToUse.cols[key].cellData[rowKey].id = rowId;
            }
        });
    }

    

    function setupExtrasRow() { 
        var rowLabel = curVehicle.getFuelType() === config.FUEL_TYPE_OPTIONS.BATTERY_ELECTRIC.CODE || !offerManager.hasFuelInFuelAndCrew() ? config.getTranslationText('4659') : config.getTranslationText('742');

        tableMeta.cols.Labels.cellData.Extras = { value: rowLabel, id: config.VALUE_TYPE.FRONTEXTRAS + '_label'};

        tableMeta.cols.front.cellData.Extras = { value: offerManager.getUIFormattedMassValue(vehicle.getFrontExtrasTotal(), formattingPrecision), label: rowLabel, id: config.VALUE_TYPE.FRONTEXTRAS };
        if (hasPusherOrTagDown === true) {
            //generateEmptyPusherTagAndRearConnectedRowCols('Extras', config.getTranslationText('742'));
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('Extras', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, vehicle.getRearExtrasTotal());

        }
        tableMeta.cols.rear.cellData.Extras = { value: offerManager.getUIFormattedMassValue(vehicle.getRearExtrasTotal(), formattingPrecision), label: rowLabel, id: config.VALUE_TYPE.REAREXTRAS };
        tableMeta.cols.total.cellData.Extras = { value: offerManager.getUIFormattedMassValue(vehicle.getExtrasTotal(), formattingPrecision), label: rowLabel, id: config.VALUE_TYPE.TOTALEXTRAS };
    }

    function setupBodyRow() {
        var rowLabel = vehicle.getBody().getDescriptionForDrawing();
        var bodyFrontVal, bodyRearVal, bodyTotalVal;

        bodyFrontVal = offerManager.getUIFormattedMassValue(vehicle.getBodyFrontAxle(), formattingPrecision);
        if (isTrialUser) {
            bodyRearVal = trialUserObfuscationText;
            bodyTotalVal = trialUserObfuscationText;
        } else {
            bodyRearVal = offerManager.getUIFormattedMassValue(vehicle.getBodyRearAxle(), formattingPrecision);
            bodyTotalVal = offerManager.getUIFormattedMassValue(vehicle.getBodyTotalAxle(), formattingPrecision);
        }

        tableMeta.cols.Labels.cellData.Body = { value: rowLabel, id: config.VALUE_TYPE.BODYMASSFRONT + '_label' };

        tableMeta.cols.front.cellData.Body = { value: bodyFrontVal, label: rowLabel, id: config.VALUE_TYPE.BODYMASSFRONT };
        if (hasPusherOrTagDown === true) {
            //generateEmptyPusherTagAndRearConnectedRowCols('Body', vehicle.getBody().getDescriptionForDrawing());
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('Body', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, config.ACCESSORY_TYPES.BODY);
        }
        tableMeta.cols.rear.cellData.Body = { value: bodyRearVal, label: rowLabel, id: config.VALUE_TYPE.BODYMASSREAR };
        tableMeta.cols.total.cellData.Body = { value: bodyTotalVal, label: rowLabel, id: config.VALUE_TYPE.BODYMASSTOTAL };

        //if (vehicle.getBody().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            tableMeta.cols.total.cellData.Body.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYMASS);
            tableMeta.cols.total.cellData.Body.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Body.interaction.callbacks);
        //}
        
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.Body.interaction = xXedOutValuesInteractionMetaObject;
            tableMeta.cols.total.cellData.Body.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function generatePopulatedPusherTagAndRearConnectedAccessoryCols(rowKey, rowLabel, tagOrPusherAxleAccessoryFunc, rearOnlyAccessoryFunc, rearMassVal, accessoryType, optionalObjectInternalId, forcedTableMeta) {

        var tableMetaToUse = forcedTableMeta || tableMeta;

        Object.keys(tableMetaToUse.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                var axleNumber, axleTypeNumber, valToUse = '';
                var highlightColourIfAny;

                var rowId;
                if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1) {
                    axleNumber = key.split('_')[2];
                    axleTypeNumber = key.split('_')[1];
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = tagOrPusherAxleAccessoryFunc({ objectOrAccessoryType: accessoryType, axleNumber: Number(axleNumber), objectInternalId: optionalObjectInternalId, rearMassVal: rearMassVal });
                    }

                    var axleTypeNum = key.split('_')[1];
                    if (key.indexOf('Pusher') !== -1) {

                        rowId = config.VALUE_TYPE.AXLEPUSHER + axleTypeNumber + '.' + accessoryType + '.' + rowLabel;
                    } else {

                        rowId = config.VALUE_TYPE.AXLETAG + axleTypeNumber + '.' + accessoryType + '.' + rowLabel;
                    }
                } else if (key.indexOf('Connected') !== -1) {
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = rearOnlyAccessoryFunc({ objectOrAccessoryType: accessoryType, objectInternalId: optionalObjectInternalId, rearMassVal: rearMassVal });
                    }


                    rowId = config.VALUE_TYPE.AXLEREARREAR + '.' + accessoryType + '.' + rowLabel;
                }

                valToUse = offerManager.getUIFormattedMassValue(valToUse, formattingPrecision);
                tableMetaToUse.cols[key].cellData[rowKey] = { value: valToUse, label: rowLabel, colour: highlightColourIfAny };
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMetaToUse.cols[key].cellData[rowKey].interaction = xXedOutValuesInteractionMetaObject;
                }
                tableMetaToUse.cols[key].cellData[rowKey].id = rowId;
            }
        });
    }

    function setupAccessoryRow(accessoryNum, accessory) {

        var accessoryFrontVal, accessoryRearVal, accessoryTotalVal;

        

        //var accessoryLabel = getTableAccessoryLabel(accessory.getAccessoryType());
        var accessoryLabel = accessory.getDescriptionForDrawing();

        var distanceFromWheelbaseTheoreticalEnd = vehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory,
                vehicle.getFrontOverhang(), vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), vehicle.getWheelbaseTheoretical(), vehicle.getBumperToBackOfCab(), vehicle.getAccessoryHolder().getBodyGap());
        var wheelbaseTheoretical = vehicle.getWheelbaseTheoretical();

        accessoryFrontVal = offerManager.getUIFormattedMassValue(accessory.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical), formattingPrecision);
        if (isTrialUser) {
            accessoryRearVal = trialUserObfuscationText;
            accessoryTotalVal = trialUserObfuscationText;
        } else {
            accessoryRearVal = offerManager.getUIFormattedMassValue(accessory.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical), formattingPrecision);
            accessoryTotalVal = offerManager.getUIFormattedMassValue(accessory.getTotalLoad(), formattingPrecision);
        }

        tableMeta.cols.Labels.cellData['Accessory' + accessoryNum] = { value: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_label' };

        tableMeta.cols.front.cellData['Accessory' + accessoryNum] = { value: accessoryFrontVal, label: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_front' };
        if (hasPusherOrTagDown === true) {
            //generateEmptyPusherTagAndRearConnectedRowCols('Accessory' + accessoryNum, accessoryLabel);
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('Accessory' + accessoryNum, accessoryLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, undefined, accessory.getAccessoryType(), accessory.getId());
        }
        tableMeta.cols.rear.cellData['Accessory' + accessoryNum] = { value: accessoryRearVal, label: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_rear' };
        tableMeta.cols.total.cellData['Accessory' + accessoryNum] = { value: accessoryTotalVal, label: accessoryLabel, id: accessory.getAccessoryType() + '_' + accessory.getId() + '_total' };

        //if (accessory.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
            var pathAppend = getAccessoryPathAppend(accessory);

            tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + pathAppend);
            tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction.callbacks);
        //}
        

        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData['Accessory' + accessoryNum].interaction = xXedOutValuesInteractionMetaObject;
            tableMeta.cols.total.cellData['Accessory' + accessoryNum].interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupOtherAccessoriesRow() {

        var otherAccessoriesFrontVal, otherAccessoriesRearDisplayVal, otherAccessoriesRearVal, otherAccessoriesTotalVal;
        var rowLabel = config.getTranslationText('688');

        otherAccessoriesFrontVal = offerManager.getUIFormattedMassValue(vehicle.getAccessoryHolder().getVehicleFrontAxleOtherAccessories(vehicle.getFrontOverhang(), 
                                                                    vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), vehicle.getWheelbaseTheoretical(), 
                                                                    vehicle.getBumperToBackOfCab(), vehicle.getAccessoryHolder().getBodyGap()), formattingPrecision);
        if (isTrialUser) {
            otherAccessoriesRearDisplayVal = trialUserObfuscationText;
            otherAccessoriesTotalVal = trialUserObfuscationText;
        } else {
            otherAccessoriesRearVal = vehicle.getAccessoryHolder().getVehicleRearAxleOtherAccessories(vehicle.getFrontOverhang(),
                                                                    vehicle.getAxle1FrontToWheelbaseTheoreticalStart(), vehicle.getWheelbaseTheoretical(), 
                                                                    vehicle.getBumperToBackOfCab(), vehicle.getAccessoryHolder().getBodyGap());
            otherAccessoriesRearDisplayVal = offerManager.getUIFormattedMassValue(otherAccessoriesRearVal, formattingPrecision);
            otherAccessoriesTotalVal = offerManager.getUIFormattedMassValue(vehicle.getAccessoryHolder().getVehicleTotalOtherAccessories(), formattingPrecision);
        }

        tableMeta.cols.Labels.cellData.OtherAccessories = { value: rowLabel, id: rowLabel + '_label' };

        tableMeta.cols.front.cellData.OtherAccessories = { value: otherAccessoriesFrontVal, label: rowLabel, id: rowLabel + '_front' };
        if (hasPusherOrTagDown === true) {
            //generateEmptyPusherTagAndRearConnectedRowCols('OtherAccessories', rowLabel);
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('OtherAccessories', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, otherAccessoriesRearVal, 'OtherAccessories');
        }
        tableMeta.cols.rear.cellData.OtherAccessories = { value: otherAccessoriesRearDisplayVal, label: rowLabel, id: rowLabel + '_rear' };
        tableMeta.cols.total.cellData.OtherAccessories = { value: otherAccessoriesTotalVal, label: rowLabel, id: rowLabel + '_total' };

        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.OtherAccessories.interaction = xXedOutValuesInteractionMetaObject;
            tableMeta.cols.total.cellData.OtherAccessories.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupUnladenRow() { 

        var frontUnladenVal, rearUnladenVal, totalUnladenVal;
        var rowLabel = curTrailer1 === null ? config.getTranslationText('705') : config.getTranslationText('517');

        
        if (isTrialUser) {
            rearUnladenVal = trialUserObfuscationText;
            totalUnladenVal = trialUserObfuscationText;
            frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleOnlyFrontUnladen(), formattingPrecision);
        } else {
            //if (curVehicle.getAxlesHolder().hasAddedPusherOrTag()) {
            //    frontUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getRevisedUnladenFront(offerManager.getRigOps().getDistributedTareFrontTotal()), formattingPrecision);
            //    rearUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getRevisedUnladenRear(offerManager.getRigOps().getDistributedTareRearTotal()), formattingPrecision);
            //    totalUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getRevisedUnladenTotal(offerManager.getRigOps().getDistributedTareTotalTotal()), formattingPrecision);
            //} else {
                frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleOnlyFrontUnladen(), formattingPrecision);
                rearUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleOnlyRearUnladen(), formattingPrecision);
                totalUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getUnladenTotal(), formattingPrecision);
            //}
                
        }

        tableMeta.cols.Labels.cellData.Unladen = { value: rowLabel, id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.UNLADENFRONT + '_label' };


        tableMeta.cols.front.cellData.Unladen = { value: frontUnladenVal, label: rowLabel};
        tableMeta.cols.front.cellData.Unladen.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.UNLADENFRONT;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENFRONT] !== undefined) { 
            tableMeta.cols.front.cellData.Unladen.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENFRONT];
        } 

        if (hasPusherOrTagDown === true) {
            //generateEmptyPusherTagAndRearConnectedRowCols('Unladen', rowLabel);
            var rearUnladenValToDistribute;
            //if (curVehicle.getAxlesHolder().hasAddedPusherOrTag()) {
            //    rearUnladenValToDistribute = vehicle.getRevisedUnladenRear(offerManager.getRigOps().getDistributedTareRearTotal());
            //} else {
            rearUnladenValToDistribute = rigOps.getVehicleOnlyRearUnladen();
            //}
            generatePopulatedPusherTagAndRearConnectedUnladenCols('Unladen', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, rearUnladenValToDistribute);
        }

        tableMeta.cols.rear.cellData.Unladen = { value: rearUnladenVal, label: rowLabel };
        tableMeta.cols.rear.cellData.Unladen.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.UNLADENREAR;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENREAR] !== undefined) {
            tableMeta.cols.rear.cellData.Unladen.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENREAR];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.Unladen.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1 !== null && curTrailer2 === null) {
            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.Unladen = { value: '-', label: rowLabel };
                tableMeta.cols.trailerFront.cellData.Unladen.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TRAILERUNLADENFRONT;
            }
            
            tableMeta.cols.trailerRear.cellData.Unladen = { value: '-', label: rowLabel };
            tableMeta.cols.trailerRear.cellData.Unladen.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.TRAILERUNLADENREAR;
        }else if (curTrailer1 !== null && curTrailer2 !== null) {
            
            tableMeta.cols.trailer1.cellData.Unladen = { value: '-', label: rowLabel };
            tableMeta.cols.trailer1.cellData.Unladen.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
            

            tableMeta.cols.trailer2.cellData.Unladen = { value: '-', label: rowLabel };
            tableMeta.cols.trailer2.cellData.Unladen.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        }

        tableMeta.cols.total.cellData.Unladen = { value: totalUnladenVal, label: rowLabel };
        tableMeta.cols.total.cellData.Unladen.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.UNLADENTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.Unladen.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.Unladen.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function generatePopulatedPusherTagAndRearConnectedUnladenCols(rowKey, rowLabel, tagOrPusherAxleTareFunc, rearOnlyTareFunc, rearMassVal, forcedTableMeta) {

        var tableMetaToUse = forcedTableMeta || tableMeta;

        Object.keys(tableMetaToUse.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                var axleNumber, axleTypeNumber, valToUse = '';
                var highlightColourIfAny;

                var rowId;
                if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1) {
                    axleNumber = key.split('_')[2];
                    axleTypeNumber = key.split('_')[1];
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = tagOrPusherAxleTareFunc({ objectOrAccessoryType: config.CHASSIS_OBJECT_TYPES.VEHICLE, axleNumber: Number(axleNumber), vehicleValSubCode: 'unladen', rearMassVal: rearMassVal });
                    }

                    var axleTypeNum = key.split('_')[1];
                    if (key.indexOf('Pusher') !== -1) {

                        rowId = config.VALUE_TYPE.AXLEUNLADENPUSHER + axleTypeNumber + rowKey;
                    } else {

                        rowId = config.VALUE_TYPE.AXLEUNLADENTAG + axleTypeNumber + rowKey;
                    }
                } else if (key.indexOf('Connected') !== -1) {
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = rearOnlyTareFunc({ objectOrAccessoryType: config.CHASSIS_OBJECT_TYPES.VEHICLE, vehicleValSubCode: 'unladen', rearMassVal: rearMassVal });
                    }


                    rowId = config.VALUE_TYPE.AXLEUNLADENREARREAR + rowKey;
                }

                valToUse = offerManager.getUIFormattedMassValue(valToUse, formattingPrecision);
                tableMetaToUse.cols[key].cellData[rowKey] = { value: valToUse, label: rowLabel, colour: highlightColourIfAny };
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMetaToUse.cols[key].cellData[rowKey].interaction = xXedOutValuesInteractionMetaObject;
                }
                tableMetaToUse.cols[key].cellData[rowKey].id = rowId;
            }
        });
    }

    function setupUnladenTrailerRow() { 

        var frontUnladenVal, rearUnladenDisplayVal, rearUnladenVal = 0, totalUnladenVal, trailerFrontUnladenVal, trailerRearUnladenVal;
        var rowLabel = config.getTranslationText('2791') + ' ' + config.getTranslationText('671');

        
        if (isTrialUser) {
            rearUnladenDisplayVal = trialUserObfuscationText;
            totalUnladenVal = trialUserObfuscationText;
            trailerFrontUnladenVal = trialUserObfuscationText;
            trailerRearUnladenVal = trialUserObfuscationText;
        } else {
            
            totalUnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenTotal(), formattingPrecision);

            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle();
                rearUnladenDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontUnladenVal = config.defaultTableBlankRowChar;
                rearUnladenDisplayVal = config.defaultTableBlankRowChar;
                trailerFrontUnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenFront(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle();
                rearUnladenDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
                
            }

            trailerRearUnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenRear(), formattingPrecision);
            
        }

        tableMeta.cols.Labels.cellData.UnladenTrailer = { value: rowLabel, id: config.VALUE_TYPE.VEHICLEUNLADENFRONT + '_label' };

        tableMeta.cols.front.cellData.UnladenTrailer = { value: frontUnladenVal, label: rowLabel };
        tableMeta.cols.front.cellData.UnladenTrailer.id = config.VALUE_TYPE.VEHICLEUNLADENFRONT;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENFRONT] !== undefined) {
            tableMeta.cols.front.cellData.UnladenTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENFRONT];
        }

        if (hasPusherOrTagDown === true) {
            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                generateEmptyPusherTagAndRearConnectedRowCols('UnladenTrailer', rowLabel);
            } else {
                generatePopulatedPusherTagAndRearConnectedUnladenCols('UnladenTrailer', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, rearUnladenVal);
            }
        }

        tableMeta.cols.rear.cellData.UnladenTrailer = { value: rearUnladenDisplayVal, label: rowLabel };
        tableMeta.cols.rear.cellData.UnladenTrailer.id = config.VALUE_TYPE.UNLADENREAR;
        //if (missingValuesObject[config.VALUE_TYPE.UNLADENREAR] !== undefined) {
        //    tableMeta.cols.rear.cellData.UnladenTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENREAR];
        //} else 
            if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.UnladenTrailer.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            tableMeta.cols.trailerFront.cellData.UnladenTrailer = { value: trailerFrontUnladenVal, label: rowLabel };
            tableMeta.cols.trailerFront.cellData.UnladenTrailer.id = config.VALUE_TYPE.TRAILERUNLADENFRONT;
            if (xXedOutValuesInteractionMetaObject !== undefined) {
                tableMeta.cols.trailerRear.cellData.UnladenTrailer.interaction = xXedOutValuesInteractionMetaObject;
            }
        }

        tableMeta.cols.trailerRear.cellData.UnladenTrailer = { value: trailerRearUnladenVal, label: rowLabel };
        tableMeta.cols.trailerRear.cellData.UnladenTrailer.id = config.VALUE_TYPE.TRAILERUNLADENREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailerRear.cellData.UnladenTrailer.interaction = xXedOutValuesInteractionMetaObject;
        }
        

        tableMeta.cols.total.cellData.UnladenTrailer = { value: totalUnladenVal, label: rowLabel };
        tableMeta.cols.total.cellData.UnladenTrailer.id = config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.UnladenTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.UnladenTrailer.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupUnladenTrailer1Row() {

        var frontUnladenVal, rearUnladenDisplayVal, rearUnladenVal, totalUnladenVal, trailer1UnladenVal, trailer2UnladenVal;
        var rowLabel = config.getTranslationText('2792') + ' ' + config.getTranslationText('671') + ' 1';


        if (isTrialUser) {
            rearUnladenDisplayVal = trialUserObfuscationText;
            totalUnladenVal = trialUserObfuscationText;
            trailer1UnladenVal = trialUserObfuscationText;
            trailer2UnladenVal = trialUserObfuscationText;
        } else {

            totalUnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenTotal(), formattingPrecision);

            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle();
                rearUnladenDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
                trailer1UnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenRear(), formattingPrecision);
                
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontUnladenVal = config.defaultTableBlankRowChar;
                rearUnladenDisplayVal = config.defaultTableBlankRowChar;
                trailer1UnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenTotal(), formattingPrecision);
                
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle();
                rearUnladenDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
                trailer1UnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenRear(), formattingPrecision);
            }
            trailer2UnladenVal = offerManager.getUIFormattedMassValue(0, formattingPrecision);
            

        }

        tableMeta.cols.Labels.cellData.UnladenTrailer1 = { value: rowLabel, id: config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.VEHICLEUNLADENFRONT + '_label' };

        tableMeta.cols.front.cellData.UnladenTrailer1 = { value: frontUnladenVal, label: rowLabel };
        tableMeta.cols.front.cellData.UnladenTrailer1.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.VEHICLEUNLADENFRONT;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENFRONT] !== undefined) {
            tableMeta.cols.front.cellData.UnladenTrailer1.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENFRONT];
        }

        if (hasPusherOrTagDown === true) {
            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                generateEmptyPusherTagAndRearConnectedRowCols('UnladenTrailer1', rowLabel);
            } else {
                generatePopulatedPusherTagAndRearConnectedUnladenCols('UnladenTrailer1', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, rearUnladenVal);
            }
            
        }

        tableMeta.cols.rear.cellData.UnladenTrailer1 = { value: rearUnladenDisplayVal, label: rowLabel };
        tableMeta.cols.rear.cellData.UnladenTrailer1.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.UNLADENREAR;
        //if (missingValuesObject[config.VALUE_TYPE.UNLADENREAR] !== undefined) {
        //    tableMeta.cols.rear.cellData.UnladenTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENREAR];
        //} else 
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.UnladenTrailer1.interaction = xXedOutValuesInteractionMetaObject;
        }
        
        tableMeta.cols.trailer1.cellData.UnladenTrailer1 = { value: trailer1UnladenVal, label: rowLabel };
        tableMeta.cols.trailer1.cellData.UnladenTrailer1.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer1.cellData.UnladenTrailer1.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.trailer2.cellData.UnladenTrailer1 = { value: '-', label: rowLabel };
        tableMeta.cols.trailer2.cellData.UnladenTrailer1.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer2.cellData.UnladenTrailer1.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.total.cellData.UnladenTrailer1 = { value: totalUnladenVal, label: rowLabel };
        tableMeta.cols.total.cellData.UnladenTrailer1.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.UnladenTrailer1.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.UnladenTrailer1.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupUnladenTrailer2Row() {

        var frontUnladenVal, rearUnladenDisplayVal, rearUnladenVal, totalUnladenVal, trailer1UnladenVal, trailer2UnladenVal;
        var rowLabel = config.getTranslationText('2793') + ' ' + config.getTranslationText('671') + ' 2';


        if (isTrialUser) {
            rearUnladenDisplayVal = trialUserObfuscationText;
            totalUnladenVal = trialUserObfuscationText;
            trailer1UnladenVal = trialUserObfuscationText;
            trailer2UnladenVal = trialUserObfuscationText;
        } else {

            totalUnladenVal = offerManager.getUIFormattedMassValue(curTrailer2.getUnladenTotal(), formattingPrecision);

            if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK || curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = rigOps.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle();
                rearUnladenDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
                trailer1UnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle(), formattingPrecision);
                trailer2UnladenVal = offerManager.getUIFormattedMassValue(curTrailer2.getUnladenRear(), formattingPrecision);
            } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontUnladenVal = config.defaultTableBlankRowChar;
                rearUnladenDisplayVal = config.defaultTableBlankRowChar;
                trailer1UnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenTotal(), formattingPrecision);

            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenDisplayVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle(), formattingPrecision);
                trailer1UnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenRear(), formattingPrecision);
            }*/ //#unfinished, other combinations of trailer1 and trailer2 will need to be supported here
            


        }

        tableMeta.cols.Labels.cellData.UnladenTrailer2 = { value: rowLabel, id: config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.VEHICLEUNLADENFRONT + '_label' };

        tableMeta.cols.front.cellData.UnladenTrailer2 = { value: frontUnladenVal, label: rowLabel };
        tableMeta.cols.front.cellData.UnladenTrailer2.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.VEHICLEUNLADENFRONT;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENFRONT] !== undefined) {
            tableMeta.cols.front.cellData.UnladenTrailer2.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENFRONT];
        }

        if (hasPusherOrTagDown === true) {
            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                generateEmptyPusherTagAndRearConnectedRowCols('UnladenTrailer2', rowLabel);
            } else {
                generatePopulatedPusherTagAndRearConnectedUnladenCols('UnladenTrailer2', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, rearUnladenVal);
            }
        }

        tableMeta.cols.rear.cellData.UnladenTrailer2 = { value: rearUnladenDisplayVal, label: rowLabel };
        tableMeta.cols.rear.cellData.UnladenTrailer2.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.UNLADENREAR;
        //if (missingValuesObject[config.VALUE_TYPE.UNLADENREAR] !== undefined) {
        //    tableMeta.cols.rear.cellData.UnladenTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENREAR];
        //} else 
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.UnladenTrailer2.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.trailer1.cellData.UnladenTrailer2 = { value: trailer1UnladenVal, label: rowLabel };
        tableMeta.cols.trailer1.cellData.UnladenTrailer2.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer1.cellData.UnladenTrailer2.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.trailer2.cellData.UnladenTrailer2 = { value: trailer2UnladenVal, label: rowLabel };
        tableMeta.cols.trailer2.cellData.UnladenTrailer2.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer2.cellData.UnladenTrailer2.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.total.cellData.UnladenTrailer2 = { value: totalUnladenVal, label: rowLabel };
        tableMeta.cols.total.cellData.UnladenTrailer2.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERUNLADENTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.UnladenTrailer2.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.UnladenTrailer2.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupUnladenTotalRow() {
        if (curTrailer1 !== null && curTrailer2 === null) {
            setupUnladenTotalRowOneTrailer();
        } else if (curTrailer1 !== null && curTrailer2 !== null) {
            setupUnladenTotalRowTwoTrailers();
        }
    }

    function setupUnladenTotalRowOneTrailer() {

        var frontUnladenVal, rearUnladenValDisplayVal, rearUnladenVal = 0, totalUnladenVal, trailerFrontUnladenVal, trailerRearUnladenVal;
        var rowLabel = config.getTranslationText('705');

        var vehicleFrontUnladenValToUse, vehicleRearUnladenValToUse;
        //if (hasPusherOrTagDown === true) {
        //    vehicleFrontUnladenValToUse = vehicle.getRevisedUnladenFront(offerManager.getRigOps().getDistributedTareFrontTotal());
        //    vehicleRearUnladenValToUse = vehicle.getRevisedUnladenRear(offerManager.getRigOps().getDistributedTareRearTotal());
        //} else {
            vehicleFrontUnladenValToUse = rigOps.getVehicleOnlyFrontUnladen();
            vehicleRearUnladenValToUse = rigOps.getVehicleOnlyRearUnladen();
        //}

        if (isTrialUser) {
            rearUnladenValDisplayVal = trialUserObfuscationText;
            totalUnladenVal = trialUserObfuscationText;
            trailerRearUnladenVal = trialUserObfuscationText;
        } else {

            totalUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getUnladenTotal() + curTrailer1.getUnladenTotal(), formattingPrecision);
            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(vehicleFrontUnladenValToUse + rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = vehicleRearUnladenValToUse + rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle();
                rearUnladenValDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(vehicleFrontUnladenValToUse, formattingPrecision);
                rearUnladenVal = vehicleRearUnladenValToUse;
                rearUnladenValDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
                trailerFrontUnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenFront(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(vehicleFrontUnladenValToUse + rigOps.getVehicleAndPupTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = vehicleRearUnladenValToUse + rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle();
                rearUnladenValDisplayVal = offerManager.getUIFormattedMassValue(rearUnladenVal, formattingPrecision);
            }
            trailerRearUnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenRear(), formattingPrecision);
        }

        tableMeta.cols.Labels.cellData.UnladenTotal = { value: rowLabel, id: config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENFRONT + '_label' };

        tableMeta.cols.front.cellData.UnladenTotal = { value: frontUnladenVal, label: rowLabel };
        tableMeta.cols.front.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENFRONT;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENFRONT] !== undefined) {
            tableMeta.cols.front.cellData.UnladenTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENFRONT];
        }

        if (hasPusherOrTagDown === true) {
            var trailerUnladenRearTransfer;
            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                
                trailerUnladenRearTransfer = rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle();
                
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                
                trailerUnladenRearTransfer = 0;
                
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                
                trailerUnladenRearTransfer = rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle();
                
            }
            //generateEmptyPusherTagAndRearConnectedRowCols('UnladenTotal', rowLabel);
            generatePopulatedPusherTagAndRearConnectedUnladenCols('UnladenTotal', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, vehicleRearUnladenValToUse + trailerUnladenRearTransfer);
        }

        tableMeta.cols.rear.cellData.UnladenTotal = { value: rearUnladenValDisplayVal, label: rowLabel };
        tableMeta.cols.rear.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENREAR;
        //if (missingValuesObject[config.VALUE_TYPE.UNLADENREAR] !== undefined) {
        //    tableMeta.cols.rear.cellData.UnladenTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENREAR];
        //} else
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            tableMeta.cols.trailerFront.cellData.UnladenTotal = { value: trailerFrontUnladenVal, label: rowLabel };
            tableMeta.cols.trailerFront.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERTRAILERUNLADENFRONT;
            if (xXedOutValuesInteractionMetaObject !== undefined) {
                tableMeta.cols.trailerFront.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
            }
        }

        tableMeta.cols.trailerRear.cellData.UnladenTotal = { value: trailerRearUnladenVal, label: rowLabel };
        tableMeta.cols.trailerRear.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERTRAILERUNLADENREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailerRear.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
        }


        tableMeta.cols.total.cellData.UnladenTotal = { value: totalUnladenVal, label: rowLabel };
        tableMeta.cols.total.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.UnladenTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupUnladenTotalRowTwoTrailers() {

        var frontUnladenVal, rearUnladenVal, totalUnladenVal, trailer1UnladenVal, trailer2UnladenVal;
        var rowLabel = config.getTranslationText('705');

        var vehicleFrontUnladenValToUse, vehicleRearUnladenValToUse;
        //if (hasPusherOrTagDown === true) {
        //    vehicleFrontUnladenValToUse = vehicle.getRevisedUnladenFront(offerManager.getRigOps().getDistributedTareFrontTotal());
        //    vehicleRearUnladenValToUse = vehicle.getRevisedUnladenRear(offerManager.getRigOps().getDistributedTareRearTotal());
        //} else {
            vehicleFrontUnladenValToUse = rigOps.getVehicleOnlyFrontUnladen();
            vehicleRearUnladenValToUse = rigOps.getVehicleOnlyRearUnladen();
        //}

        if (isTrialUser) {
            rearUnladenVal = trialUserObfuscationText;
            totalUnladenVal = trialUserObfuscationText;
            trailer1UnladenVal = trialUserObfuscationText;
            trailer2UnladenVal = trialUserObfuscationText;
        } else {

            totalUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getUnladenTotal() + curTrailer1.getUnladenTotal() + curTrailer2.getUnladenTotal(), formattingPrecision);
            if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK || curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(vehicleFrontUnladenValToUse + rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + rigOps.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = offerManager.getUIFormattedMassValue(vehicleRearUnladenValToUse + rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + rigOps.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle(), formattingPrecision);
                trailer1UnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenRear() + rigOps.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle(), formattingPrecision);
                trailer2UnladenVal = offerManager.getUIFormattedMassValue(curTrailer2.getUnladenRear(), formattingPrecision);
            } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getUnladenFront(), formattingPrecision);
                rearUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getUnladenRear(), formattingPrecision);
                trailerFrontUnladenVal = offerManager.getUIFormattedMassValue(curTrailer1.getUnladenFront(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getUnladenFront() + rigOps.getVehicleAndPupTareTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearUnladenVal = offerManager.getUIFormattedMassValue(vehicle.getUnladenRear() + rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle(), formattingPrecision);
            }*/ //#unfinished, need to support other two trailer combinations here
            
        }

        tableMeta.cols.Labels.cellData.UnladenTotal = { value: rowLabel, id: config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENFRONT + '_label' };

        tableMeta.cols.front.cellData.UnladenTotal = { value: frontUnladenVal, label: rowLabel };
        tableMeta.cols.front.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENFRONT;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENFRONT] !== undefined) {
            tableMeta.cols.front.cellData.UnladenTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENFRONT];
        }

        if (hasPusherOrTagDown === true) {
            var trailer1UnladenRearTransfer = 0, trailer2UnladenRearTransfer = 0;
            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {

                trailer1UnladenRearTransfer = rigOps.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle();
                trailer2UnladenRearTransfer = rigOps.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle();
            } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {

                trailer1UnladenRearTransfer = 0;

            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {

                trailer1UnladenRearTransfer = rigOps.getVehicleAndPupTareTransferOntoVehicleRearAxle();

            }*/
            //generateEmptyPusherTagAndRearConnectedRowCols('UnladenTotal', rowLabel);
            generatePopulatedPusherTagAndRearConnectedUnladenCols('UnladenTotal', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, vehicleRearUnladenValToUse + trailer1UnladenRearTransfer + trailer2UnladenRearTransfer);

            //generateEmptyPusherTagAndRearConnectedRowCols('UnladenTotal', rowLabel);
        }

        tableMeta.cols.rear.cellData.UnladenTotal = { value: rearUnladenVal, label: rowLabel };
        tableMeta.cols.rear.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENREAR;
        //if (missingValuesObject[config.VALUE_TYPE.UNLADENREAR] !== undefined) {
        //    tableMeta.cols.rear.cellData.UnladenTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENREAR];
        //} else
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.trailer1.cellData.UnladenTotal = { value: trailer1UnladenVal, label: rowLabel };
        tableMeta.cols.trailer1.cellData.UnladenTotal.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.VEHICLEANDTRAILERTRAILERUNLADENREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer1.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.trailer2.cellData.UnladenTotal = { value: trailer2UnladenVal, label: rowLabel };
        tableMeta.cols.trailer2.cellData.UnladenTotal.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.VEHICLEANDTRAILERTRAILERUNLADENREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer2.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
        }


        tableMeta.cols.total.cellData.UnladenTotal = { value: totalUnladenVal, label: rowLabel };
        tableMeta.cols.total.cellData.UnladenTotal.id = config.VALUE_TYPE.VEHICLEANDTRAILERUNLADENTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.UnladenTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.UNLADENTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.UnladenTotal.interaction = xXedOutValuesInteractionMetaObject;
        }
    }

    function setupPayloadVehicleOrVehicleAndSemiRow() {

        var frontPayloadVal, rearPayloadDisplayVal = 0, rearPayloadVal, totalPayloadVal, trailerFrontPayloadVal, trailerRearPayloadVal;
        var rowLabel = config.getTranslationText('2870');

        var payloadPath;

        if (curTrailer1 === null && curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            frontPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadFront(), formattingPrecision);
            payloadPath = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD;
            if (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE && curVehicle.getPayloadHolder().getSimplePayloadObject() !== null) {
                payloadPath += '.' + curVehicle.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS;
            } else if (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && curVehicle.getPayloadHolder().getDetailedPayloadItems().length > 0) {
                payloadPath += '.' + curVehicle.getPayloadHolder().getDetailedPayloadItems()[0].getId() + '.' + config.VALUE_TYPE.DETAILEDPAYLOADMASS;
            }
        } else if (curTrailer1 === null && curVehicle.getAccessoryHolder().getFifthWheel() !== null) {
            frontPayloadVal = offerManager.getUIFormattedMassValue(0, formattingPrecision);
        } else {
            payloadPath = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.PAYLOAD;
            if (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE && curTrailer1.getPayloadHolder().getSimplePayloadObject() !== null) {
                payloadPath += '.' + curTrailer1.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS;
            } else if (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && curTrailer1.getPayloadHolder().getDetailedPayloadItems().length > 0) {
                payloadPath += '.' + curTrailer1.getPayloadHolder().getDetailedPayloadItems()[0].getId() + '.' + config.VALUE_TYPE.DETAILEDPAYLOADMASS;
            }
            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                frontPayloadVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadFront(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontPayloadVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle(), formattingPrecision);
            }
            
        }
        var highlightPayload = false;
        if (isTrialUser) {
            rearPayloadDisplayVal = trialUserObfuscationText;
            totalPayloadVal = trialUserObfuscationText;
            trailerFrontPayloadVal = trialUserObfuscationText;
            trailerRearPayloadVal = trialUserObfuscationText;
            if (curTrailer1 === null) {
                if (curVehicle.getAccessoryHolder().getBody() !== null) {
                    highlightPayload = true;
                }
            } else {
                if (curTrailer1.getAccessoryHolder().getBody() !== null) {
                    highlightPayload = true;
                }
            }
            
        } else {
            if (curTrailer1 === null) {
                rearPayloadVal = vehicle.getPayloadRear();
                rearPayloadDisplayVal = offerManager.getUIFormattedMassValue(rearPayloadVal, formattingPrecision);
                var vehiclePayloadTotal = vehicle.getPayloadTotal();
                totalPayloadVal = offerManager.getUIFormattedMassValue(vehiclePayloadTotal, formattingPrecision);
                if (vehiclePayloadTotal > 0) {
                    highlightPayload = true;
                }
            } else {

                var trailer1PayloadTotal;

                if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                    rearPayloadVal = rigOps.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle();
                    rearPayloadDisplayVal = offerManager.getUIFormattedMassValue(rearPayloadVal, formattingPrecision);
                    trailer1PayloadTotal = curTrailer1.getPayloadTotal();
                    totalPayloadVal = offerManager.getUIFormattedMassValue(trailer1PayloadTotal, formattingPrecision);
                    if (trailer1PayloadTotal > 0) {
                        highlightPayload = true;
                    }
                } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    rearPayloadDisplayVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadRear(), formattingPrecision);
                    var combinationPayloadTotal = curTrailer1.getPayloadTotal() + vehicle.getPayloadTotal();
                    totalPayloadVal = offerManager.getUIFormattedMassValue(combinationPayloadTotal, formattingPrecision);
                    if (combinationPayloadTotal > 0) {
                        highlightPayload = true;
                    }
                    trailerFrontPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadFront(), formattingPrecision);
                } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    rearPayloadDisplayVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupPayloadTransferOntoVehicleRearAxle(), formattingPrecision);
                    var trailer1PayloadTotal = curTrailer1.getPayloadTotal();
                    totalPayloadVal = offerManager.getUIFormattedMassValue(trailer1PayloadTotal, formattingPrecision);
                    if (trailer1PayloadTotal > 0) {
                        highlightPayload = true;
                    }
                }*/

                
                
                trailerRearPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadRear(), formattingPrecision);
            }
            
        }

        tableMeta.cols.Labels.cellData.Payload = { value: rowLabel, id: config.VALUE_TYPE.PAYLOADFRONT + '_label' };

        tableMeta.cols.front.cellData.Payload = { value: frontPayloadVal, label: rowLabel/*, fontStyle: 'bold' */ };
        tableMeta.cols.front.cellData.Payload.id = config.VALUE_TYPE.PAYLOADFRONT;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT] !== undefined) {
            tableMeta.cols.front.cellData.Payload.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT];
        } 

        if (hasPusherOrTagDown === true) {
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('Payload', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, rearPayloadVal, config.ACCESSORY_TYPES.PAYLOAD);
        }
        
        tableMeta.cols.rear.cellData.Payload = { value: rearPayloadDisplayVal, label: config.getTranslationText('2871')/*, fontStyle: 'bold' */ };
        tableMeta.cols.rear.cellData.Payload.id = config.VALUE_TYPE.PAYLOADREAR;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADREAR] !== undefined) {
            tableMeta.cols.rear.cellData.Payload.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADREAR];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.Payload.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.Payload = { value: trailerFrontPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
                tableMeta.cols.trailerFront.cellData.Payload.id = config.VALUE_TYPE.TRAILERPAYLOADFRONT;
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMeta.cols.trailerFront.cellData.Payload.interaction = xXedOutValuesInteractionMetaObject;
                }
            }

            tableMeta.cols.trailerRear.cellData.Payload = { value: trailerRearPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
            tableMeta.cols.trailerRear.cellData.Payload.id = config.VALUE_TYPE.TRAILERPAYLOADREAR;
            if (xXedOutValuesInteractionMetaObject !== undefined) {
                tableMeta.cols.trailerRear.cellData.Payload.interaction = xXedOutValuesInteractionMetaObject;
            }
        }

        tableMeta.cols.total.cellData.Payload = { value: totalPayloadVal, label: rowLabel, colour: highlightPayload === true ? config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD : undefined/*, fontStyle: 'bold'*/ };
        tableMeta.cols.total.cellData.Payload.id = payloadPath;//'1' + config.VALUE_TYPE.PAYLOADTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.Payload.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.Payload.interaction = xXedOutValuesInteractionMetaObject;
        } else {
            tableMeta.cols.total.cellData.Payload.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, payloadPath);
            tableMeta.cols.total.cellData.Payload.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Payload.interaction.callbacks);
        }
    }

    function setupVehiclePayloadRow() {
        var frontPayloadVal, rearPayloadVal, totalPayloadVal, trailerFrontPayloadVal, trailerRearPayloadVal;
        var rowLabel = config.getTranslationText('1050');

        var payloadPath = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD;

        if (isTrialUser) {
            rearPayloadVal = trialUserObfuscationText;
            totalPayloadVal = trialUserObfuscationText;
            trailerFrontPayloadVal = trialUserObfuscationText;
            trailerRearPayloadVal = trialUserObfuscationText;

            //if (curVehicle.getAccessoryHolder().getBody() !== null && curTrailer1.getAccessoryHolder().getBody() !== null) {
            //    highlightPayload = true;
            //}

        } else {
            var trailer1PayloadTotal;

            frontPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadFront(), formattingPrecision);
            rearPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadRear(), formattingPrecision);
            totalPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadTotal(), formattingPrecision);
            trailerFrontPayloadVal = '-';
            trailerRearPayloadVal = '-';
            
            //if (vehicle.getPayloadTotal() > 0 && curTrailer1.getPayloadTotal() > 0) {
            //    highlightPayload = true;
            //}

        }

        tableMeta.cols.front.cellData.PayloadVehicle = { value: frontPayloadVal, label: rowLabel/*, fontStyle: 'bold' */ };
        tableMeta.cols.front.cellData.PayloadVehicle.id = config.VALUE_TYPE.PAYLOADFRONT;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT] !== undefined) {
            tableMeta.cols.front.cellData.PayloadVehicle.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT];
        }

        if (hasPusherOrTagDown === true) {
            generateEmptyPusherTagAndRearConnectedRowCols('Payload', rowLabel);
        }

        tableMeta.cols.rear.cellData.PayloadVehicle = { value: rearPayloadVal, label: config.getTranslationText('290')/*, fontStyle: 'bold' */ };
        tableMeta.cols.rear.cellData.PayloadVehicle.id = config.VALUE_TYPE.PAYLOADREAR;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADREAR] !== undefined) {
            tableMeta.cols.rear.cellData.PayloadVehicle.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADREAR];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.PayloadVehicle.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.PayloadVehicle = { value: trailerFrontPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
                tableMeta.cols.trailerFront.cellData.PayloadVehicle.id = config.VALUE_TYPE.TRAILERPAYLOADFRONT;
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMeta.cols.trailerFront.cellData.PayloadVehicle.interaction = xXedOutValuesInteractionMetaObject;
                }
            }

            tableMeta.cols.trailerRear.cellData.PayloadVehicle = { value: trailerRearPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
            tableMeta.cols.trailerRear.cellData.PayloadVehicle.id = config.VALUE_TYPE.TRAILERPAYLOADREAR;
            if (xXedOutValuesInteractionMetaObject !== undefined) {
                tableMeta.cols.trailerRear.cellData.PayloadVehicle.interaction = xXedOutValuesInteractionMetaObject;
            }
        }

        tableMeta.cols.total.cellData.PayloadVehicle = { value: totalPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
        tableMeta.cols.total.cellData.PayloadVehicle.id = payloadPath;//'2' + config.VALUE_TYPE.PAYLOADTOTAL;
        //tableMeta.cols.total.cellData.PayloadVehicle.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.PAYLOADTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.PayloadVehicle.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.PayloadVehicle.interaction = xXedOutValuesInteractionMetaObject;
        } else {
            tableMeta.cols.total.cellData.PayloadVehicle.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, payloadPath);
            tableMeta.cols.total.cellData.PayloadVehicle.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.PayloadVehicle.interaction.callbacks);
        }
    }

    function setupTrailerPayloadRow() {
        var frontPayloadVal, rearPayloadDisplayVal = 0, rearPayloadVal, totalPayloadVal, trailerFrontPayloadVal, trailerRearPayloadVal;
        var rowLabel = config.getTranslationText('1051');

        var payloadPath = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.PAYLOAD;
        
        // var highlightPayload;
        if (isTrialUser) {
            rearPayloadDisplayVal = trialUserObfuscationText;
            totalPayloadVal = trialUserObfuscationText;
            trailerFrontPayloadVal = trialUserObfuscationText;
            trailerRearPayloadVal = trialUserObfuscationText;
            //if (curTrailer1 === null) {
            //    if (curVehicle.getAccessoryHolder().getBody() !== null) {
            //        highlightPayload = true;
            //    }
            //} else {
            //    if (curTrailer1.getAccessoryHolder().getBody() !== null) {
            //        highlightPayload = true;
            //    }
            //}

        } else {
            var trailer1PayloadTotal;

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontPayloadVal = '-';
                rearPayloadDisplayVal = '-';
                trailerFrontPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadFront(), formattingPrecision);



            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontPayloadVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearPayloadVal = rigOps.getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
                rearPayloadDisplayVal = offerManager.getUIFormattedMassValue(rearPayloadVal, formattingPrecision);

            }


            totalPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadTotal(), formattingPrecision);
            if (curTrailer1.getPayloadTotal() > 0 && vehicle.getPayloadTotal() > 0) {
                // highlightPayload = true;
            }
            trailerRearPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadRear(), formattingPrecision);

        }

        tableMeta.cols.front.cellData.PayloadTrailer = { value: frontPayloadVal, label: rowLabel/*, fontStyle: 'bold' */ };
        tableMeta.cols.front.cellData.PayloadTrailer.id = config.VALUE_TYPE.PAYLOADFRONT;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT] !== undefined) {
            tableMeta.cols.front.cellData.PayloadTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT];
        }

        if (hasPusherOrTagDown === true) {
            generateEmptyPusherTagAndRearConnectedRowCols('Payload', rowLabel);
        }

        tableMeta.cols.rear.cellData.PayloadTrailer = { value: rearPayloadDisplayVal, label: rowLabel/*, fontStyle: 'bold' */ };
        tableMeta.cols.rear.cellData.PayloadTrailer.id = config.VALUE_TYPE.PAYLOADREAR;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADREAR] !== undefined) {
            tableMeta.cols.rear.cellData.PayloadTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADREAR];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.PayloadTrailer.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.PayloadTrailer = { value: trailerFrontPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
                tableMeta.cols.trailerFront.cellData.PayloadTrailer.id = config.VALUE_TYPE.TRAILERPAYLOADFRONT;
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMeta.cols.trailerFront.cellData.PayloadTrailer.interaction = xXedOutValuesInteractionMetaObject;
                }
            }

            tableMeta.cols.trailerRear.cellData.PayloadTrailer = { value: trailerRearPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
            tableMeta.cols.trailerRear.cellData.PayloadTrailer.id = config.VALUE_TYPE.TRAILERPAYLOADREAR;
            if (xXedOutValuesInteractionMetaObject !== undefined) {
                tableMeta.cols.trailerRear.cellData.PayloadTrailer.interaction = xXedOutValuesInteractionMetaObject;
            }
        }

        tableMeta.cols.total.cellData.PayloadTrailer = { value: totalPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
        tableMeta.cols.total.cellData.PayloadTrailer.id = payloadPath;//'3' + config.VALUE_TYPE.PAYLOADTOTAL;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.PayloadTrailer.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.PayloadTrailer.interaction = xXedOutValuesInteractionMetaObject;
        } else {
            tableMeta.cols.total.cellData.PayloadTrailer.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, payloadPath);
            tableMeta.cols.total.cellData.PayloadTrailer.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.PayloadTrailer.interaction.callbacks);
        }
    }

    function setupPayloadVehicleAndDrawbarOrVehicleAndPupRow() {
        var frontPayloadVal, rearPayloadDisplayVal = 0, rearPayloadVal, totalPayloadVal, trailerFrontPayloadVal, trailerRearPayloadVal;
        var rowLabel = config.getTranslationText('1052');

        var payloadPath;

        //if (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
        //    payloadPath = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS;
        //} else {
        //    payloadPath = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD;
        //}

        payloadPath = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.ACCESSORY_TYPES.PAYLOAD;
        if (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE && curTrailer1.getPayloadHolder().getSimplePayloadObject() !== null) {
            payloadPath += '.' + curTrailer1.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS;
        } else if (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && curTrailer1.getPayloadHolder().getDetailedPayloadItems().length > 0) {
            payloadPath += '.' + curTrailer1.getPayloadHolder().getDetailedPayloadItems()[0].getId() + '.' + config.VALUE_TYPE.DETAILEDPAYLOADMASS;
        }


        var highlightPayload = false;
        if (isTrialUser) {
            rearPayloadDisplayVal = trialUserObfuscationText;
            totalPayloadVal = trialUserObfuscationText;
            trailerFrontPayloadVal = trialUserObfuscationText;
            trailerRearPayloadVal = trialUserObfuscationText;
            
            if (curTrailer1.getAccessoryHolder().getBody() !== null && curVehicle.getAccessoryHolder().getBody() !== null) {
                highlightPayload = true;
            }

        } else {
            
            var trailer1PayloadTotal;

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadFront(), formattingPrecision);
                rearPayloadVal = vehicle.getPayloadRear();
                rearPayloadDisplayVal = offerManager.getUIFormattedMassValue(rearPayloadVal, formattingPrecision);
                
                trailerFrontPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadFront(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadFront() + rigOps.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle(), formattingPrecision);
                rearPayloadVal = vehicle.getPayloadRear() + rigOps.getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
                rearPayloadDisplayVal = offerManager.getUIFormattedMassValue(rearPayloadVal, formattingPrecision);
                
            }
            totalPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadTotal() + curTrailer1.getPayloadTotal(), formattingPrecision);
            if (vehicle.getPayloadTotal() > 0 && curTrailer1.getPayloadTotal() > 0) {
                highlightPayload = true;
            }
            trailerRearPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadRear(), formattingPrecision);
            

        }

        tableMeta.cols.Labels.cellData.PayloadTotal = { value: rowLabel, id: config.VALUE_TYPE.PAYLOADFRONT + '_label' };

        tableMeta.cols.front.cellData.PayloadTotal = { value: frontPayloadVal, label: rowLabel/*, fontStyle: 'bold' */ };
        tableMeta.cols.front.cellData.PayloadTotal.id = config.VALUE_TYPE.PAYLOADFRONT;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT] !== undefined) {
            tableMeta.cols.front.cellData.PayloadTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT];
        }

        if (hasPusherOrTagDown === true) {
            //generateEmptyPusherTagAndRearConnectedRowCols('Payload', rowLabel);
            generatePopulatedPusherTagAndRearConnectedAccessoryCols('Payload', rowLabel, curVehicle.getPusherOrTagRearAxleDistribution, curVehicle.getRearRearAxlesDistribution, rearPayloadVal, config.ACCESSORY_TYPES.PAYLOAD);
        }

        tableMeta.cols.rear.cellData.PayloadTotal = { value: rearPayloadDisplayVal, label: rowLabel/*, fontStyle: 'bold' */ };
        tableMeta.cols.rear.cellData.PayloadTotal.id = config.VALUE_TYPE.PAYLOADREAR;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADREAR] !== undefined) {
            tableMeta.cols.rear.cellData.PayloadTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADREAR];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.PayloadTotal = { value: trailerFrontPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
                tableMeta.cols.trailerFront.cellData.PayloadTotal.id = config.VALUE_TYPE.TRAILERPAYLOADFRONT;
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMeta.cols.trailerFront.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
                }
            }

            tableMeta.cols.trailerRear.cellData.PayloadTotal = { value: trailerRearPayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
            tableMeta.cols.trailerRear.cellData.PayloadTotal.id = config.VALUE_TYPE.TRAILERPAYLOADREAR;
            if (xXedOutValuesInteractionMetaObject !== undefined) {
                tableMeta.cols.trailerRear.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
            }
        }

        tableMeta.cols.total.cellData.PayloadTotal = { value: totalPayloadVal, label: rowLabel, colour: highlightPayload === true ? config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD : undefined/*, fontStyle: 'bold'*/ };
        tableMeta.cols.total.cellData.PayloadTotal.id = payloadPath;//config.VALUE_TYPE.PAYLOADTOTAL;
        /*if (missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.PayloadTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL];
        } else */if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
        } /*else {
            tableMeta.cols.total.cellData.PayloadTotal.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, payloadPath);
            tableMeta.cols.total.cellData.PayloadTotal.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.PayloadTotal.interaction.callbacks);
        }*/
    }

    function setupPayloadTwoTrailerTotalRow() {

        var frontPayloadVal, rearPayloadVal, totalPayloadVal, trailer1PayloadVal, trailer2PayloadVal;
        var rowLabel = config.getTranslationText('2872');

        //var payloadPath;

        //payloadPath = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.ACCESSORY_TYPES.PAYLOAD;
        if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK || curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
            frontPayloadVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() + rigOps.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle(), formattingPrecision);
        } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            frontPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadFront(), formattingPrecision);
        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            frontPayloadVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle(), formattingPrecision);
        }*/ //#unfinished, need to support other two trailer combinations here

        var highlightPayload = false;
        if (isTrialUser) {
            rearPayloadVal = trialUserObfuscationText;
            totalPayloadVal = trialUserObfuscationText;
            trailer1PayloadVal = trialUserObfuscationText;
            trailer2PayloadVal = trialUserObfuscationText;
            if (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer2.getAccessoryHolder().getBody() !== null) {
                highlightPayload = true;
            }

        } else {
            var trailer1And2PayloadTotal = 0;

            if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK || curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                rearPayloadVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() + rigOps.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle(), formattingPrecision);
                trailer1PayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadRear() + rigOps.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle(), formattingPrecision);
                trailer2PayloadVal = offerManager.getUIFormattedMassValue(curTrailer2.getPayloadRear(), formattingPrecision);
                trailer1And2PayloadTotal += curTrailer1.getPayloadTotal() + curTrailer2.getPayloadTotal();
                totalPayloadVal = offerManager.getUIFormattedMassValue(trailer1And2PayloadTotal, formattingPrecision);
                if (trailer1And2PayloadTotal > 0) {
                    highlightPayload = true;
                }
            }/* else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                rearPayloadVal = offerManager.getUIFormattedMassValue(vehicle.getPayloadRear(), formattingPrecision);
                var combinationPayloadTotal = curTrailer1.getPayloadTotal() + vehicle.getPayloadTotal();
                totalPayloadVal = offerManager.getUIFormattedMassValue(combinationPayloadTotal, formattingPrecision);
                if (combinationPayloadTotal > 0) {
                    highlightPayload = true;
                }
                trailerFrontPayloadVal = offerManager.getUIFormattedMassValue(curTrailer1.getPayloadFront(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                rearPayloadVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupPayloadTransferOntoVehicleRearAxle(), formattingPrecision);
                var trailer1PayloadTotal = curTrailer1.getPayloadTotal();
                totalPayloadVal = offerManager.getUIFormattedMassValue(trailer1PayloadTotal, formattingPrecision);
                if (trailer1PayloadTotal > 0) {
                    highlightPayload = true;
                }
            }*/ //#unfinished, need to support other two trailer combinations here 
        }

        tableMeta.cols.Labels.cellData.PayloadTotal = { value: rowLabel, id: config.VALUE_TYPE.PAYLOADFRONT + '_label' };

        tableMeta.cols.front.cellData.PayloadTotal = { value: frontPayloadVal, label: config.getTranslationText('2873')/*, fontStyle: 'bold' */ };
        tableMeta.cols.front.cellData.PayloadTotal.id = config.VALUE_TYPE.PAYLOADFRONT;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT] !== undefined) {
            tableMeta.cols.front.cellData.PayloadTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADFRONT];
        }

        if (hasPusherOrTagDown === true) {
            generateEmptyPusherTagAndRearConnectedRowCols('PayloadTotal', config.getTranslationText('2873'));
        }

        tableMeta.cols.rear.cellData.PayloadTotal = { value: rearPayloadVal, label: config.getTranslationText('2873')/*, fontStyle: 'bold' */ };
        tableMeta.cols.rear.cellData.PayloadTotal.id = config.VALUE_TYPE.PAYLOADREAR;
        if (missingValuesObject[config.VALUE_TYPE.PAYLOADREAR] !== undefined) {
            tableMeta.cols.rear.cellData.PayloadTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADREAR];
        } else if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.rear.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.trailer1.cellData.PayloadTotal = { value: trailer1PayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
        tableMeta.cols.trailer1.cellData.PayloadTotal.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERPAYLOADREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer1.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.trailer2.cellData.PayloadTotal = { value: trailer2PayloadVal, label: rowLabel/*, fontStyle: 'bold'*/ };
        tableMeta.cols.trailer2.cellData.PayloadTotal.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERPAYLOADREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.trailer2.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
        }

        tableMeta.cols.total.cellData.PayloadTotal = { value: totalPayloadVal, label: config.getTranslationText('2872'), colour: highlightPayload === true ? config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD : undefined/*, fontStyle: 'bold'*/ };
        tableMeta.cols.total.cellData.PayloadTotal.id = config.VALUE_TYPE.PAYLOADTOTAL;
        /*if (missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.PayloadTotal.missingValMeta = missingValuesObject[config.VALUE_TYPE.PAYLOADTOTAL];
        } else*/ if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMeta.cols.total.cellData.PayloadTotal.interaction = xXedOutValuesInteractionMetaObject;
        } /*else {
            tableMeta.cols.total.cellData.PayloadTotal.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, payloadPath);
            tableMeta.cols.total.cellData.PayloadTotal.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.PayloadTotal.interaction.callbacks);
        }*/
    }


    function applyRoundingForGrossOverload(overloadVal) {

        var valToUse = utils.returnValueOrZeroIfNanOrInfinity(overloadVal);

        var fixed = 2;
        //return overloadVal.toFixed(2);
        var re = new RegExp('^-?\\d+(?:.\\d{0,' + (fixed || -1) + '})?');
        return parseFloat(valToUse.toString().match(re)[0]);
    }

    function setupGrossRow(returnThisRowOnlyInOwnTableStructure) {

        

        var tableMetaToUse;

        if (returnThisRowOnlyInOwnTableStructure) {
            tableMetaToUse = getTableBasicSetup(config.VIEW_DETAIL.SUMMARY);
        } else {
            tableMetaToUse = tableMeta;
        }
        
        //var overloadingColColours = offerManager.generateOverloadingColColours('GROSS');

        var frontGrossVal, rearGrossVal, totalGrossVal, trailerFrontGrossVal, trailerRearGrossVal;
        var rowLabel = config.getTranslationText('171');
        
        var frontAxleOverload, totalOverload, rearAxleOverload, trailerRearOverload;
        var frontAxleCompareVal, frontAxleRefVal, totalCompareVal, totalRefVal, rearAxleCompareVal, rearAxleRefVal, trailerFrontCompareVal, trailerFrontRefVal, trailerRearCompareVal, trailerRearRefVal;

        if (curTrailer1 === null) { 
            //frontAxleOverload = rigOps.getVehicleFrontAxleOverload();//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
            frontAxleCompareVal = rigOps.getVehicleOnlyFrontGross();
            //frontAxleRefVal = combinationPermissibleValues.vehicleFrontPermissible;//rigOps.getVehicleFrontPermissible();
            //totalOverload = rigOps.getVehicleTotalOverload();//offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
            totalCompareVal = curVehicle.getGrossTotal();
            //totalRefVal = combinationPermissibleValues.combinationTotalPermissible;//rigOps.getVehicleTotalPermissible();
            //rearAxleOverload = rigOps.getVehicleRearAxleOverload();//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
            rearAxleCompareVal = rigOps.getVehicleOnlyRearGross();
            //rearAxleRefVal = combinationPermissibleValues.vehicleRearPermissible;//rigOps.getVehicleRearPermissible();

        } else {

            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                //frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload());
                frontAxleCompareVal = rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleGross();
                //frontAxleRefVal = combinationPermissibleValues.vehicleFrontPermissible;//rigOps.getVehicleFrontPermissible();
                //totalOverload = applyRoundingForGrossOverload(utils.returnValueOrZeroIfNanOrInfinity(rigOps.getVehicleAndSemiOrInterlinkTotalOverload()));
                totalCompareVal = rigOps.getVehicleAndTrailer1GrossTotal();
                //totalRefVal = combinationPermissibleValues.combinationTotalPermissible;//rigOps.getCombinationTotalPermissible();
                //rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload());
                rearAxleCompareVal = rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleGross();
                //rearAxleRefVal = combinationPermissibleValues.vehicleRearPermissible;//rigOps.getVehicleRearPermissible();
                //trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload());
                trailerRearCompareVal = rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleGross();
                //trailerRearRefVal = combinationPermissibleValues.trailer1RearPermissible;//rigOps.getTrailer1PermissibleRear();
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
                frontAxleCompareVal = rigOps.getVehicleOnlyFrontGross();
                //frontAxleRefVal = combinationPermissibleValues.vehicleFrontPermissible;//rigOps.getVehicleFrontPermissible();
                //totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTotalOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
                totalCompareVal = rigOps.getVehicleAndTrailer1GrossTotal();
                //totalRefVal = combinationPermissibleValues.combinationTotalPermissible;//rigOps.getCombinationTotalPermissible();
                //rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
                rearAxleCompareVal = rigOps.getVehicleOnlyRearGross();
                //rearAxleRefVal = combinationPermissibleValues.vehicleRearPermissible;//rigOps.getVehicleRearPermissible();
                //trailerFrontOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload());
                trailerFrontCompareVal = curTrailer1.getGrossTotal();
                //trailerFrontRefVal = combinationPermissibleValues.trailer1FrontPermissible;//rigOps.getTrailer1PermissibleFront();
                //trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTrailerRearAxleOverload());
                trailerRearCompareVal = curTrailer1.getUnladenRear() + curTrailer1.getPayloadRear();
                //trailerRearRefVal = combinationPermissibleValues.trailer1RearPermissible;//rigOps.getTrailer1PermissibleRear();

            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupVehicleFrontAxleOverload());
                totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupTotalOverload());
                rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupVehicleRearAxleOverload());
                trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupTrailerRearAxleOverload());
            }

            

        }



        frontGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getCombinationVehicleFrontGross());
        if (isTrialUser) {
            rearGrossVal = trialUserObfuscationText;
            totalGrossVal = trialUserObfuscationText;
            trailerFrontGrossVal = trialUserObfuscationText;
            trailerRearGrossVal = trialUserObfuscationText;
        } else {
            rearGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getCombinationVehicleRearGross());
            if (curTrailer1 === null) {
                totalGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(vehicle.getGrossTotal());
            } else {

                if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                    totalGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getVehicleAndSemiOrInterlinkTotalGross());
                } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    totalGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getVehicleAndDrawbarTotalGross());
                    trailerFrontGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getTrailer1FrontGross());
                } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    totalGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getVehicleAndPupTotalGross());
                }

                
                trailerRearGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getTrailer1RearGross());
            }
            
        }

        tableMetaToUse.cols.Labels.cellData.Gross = { value: rowLabel, id: config.VALUE_TYPE.GROSSFRONT + '_label' };

        var grossFrontTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossFrontTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossFrontTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleCompareVal, frontAxleRefVal, 'GROSS');
            grossFrontTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.FRONT];
        }
        tableMetaToUse.cols.front.cellData.Gross = { value: frontGrossVal, label: config.getTranslationText('704'), colour: grossFrontTableHighLightColour, bold: true };
        tableMetaToUse.cols.front.cellData.Gross.id = config.VALUE_TYPE.GROSSFRONT;
        /*if (missingValuesObject[config.VALUE_TYPE.GROSSFRONT] !== undefined) {
            tableMetaToUse.cols.front.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSFRONT];
        }*/

        if (hasPusherOrTagDown === true) {

            var grossFunctionToUse, rearRearAxlesGrossFunctionToUse; 
            grossFunctionToUse = function (axleNumber) {
                return curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() })
            };
            rearRearAxlesGrossFunctionToUse = function () {
                return curVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOps.getCombinationVehicleRearGross() })
            };

            var tableMetaToPass;

            if (returnThisRowOnlyInOwnTableStructure) {
                tableMetaToPass = tableMetaToUse;
            } else {
                tableMetaToPass = undefined;
            }

            generatePopulatedPusherTagAndRearConnectedGrossCols('Gross', config.getTranslationText('171'), grossFunctionToUse, rearRearAxlesGrossFunctionToUse, tableMetaToPass); 
        }
        
        var grossRearTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossRearTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossRearTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleCompareVal, rearAxleRefVal, 'GROSS');
            grossRearTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.REAR];
        }
        tableMetaToUse.cols.rear.cellData.Gross = { value: rearGrossVal, label: config.getTranslationText('171'), colour: grossRearTableHighLightColour, bold: true };
        tableMetaToUse.cols.rear.cellData.Gross.id = config.VALUE_TYPE.GROSSREAR;
        /*if (missingValuesObject[config.VALUE_TYPE.GROSSREAR] !== undefined) {
            tableMetaToUse.cols.rear.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSREAR];
        } else*/ if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMetaToUse.cols.rear.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                var grossTrailer1FrontTableHighLightColour;
                if (hasLegislationARegulationUnknown) {
                    grossTrailer1FrontTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
                } else {
                    //grossRearTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleCompareVal, rearAxleRefVal, 'GROSS');
                    grossTrailer1FrontTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_FRONT];
                }
                tableMetaToUse.cols.trailerFront.cellData.Gross = { value: trailerFrontGrossVal, label: rowLabel, colour: grossTrailer1FrontTableHighLightColour, bold: true };
                tableMetaToUse.cols.trailerFront.cellData.Gross.id = config.VALUE_TYPE.TRAILERGROSSFRONT;
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMetaToUse.cols.trailerFront.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
                }
            }

            var grossTrailer1RearTableHighLightColour;
            if (hasLegislationARegulationUnknown) {
                grossTrailer1RearTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
            } else {
                //grossRearTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleCompareVal, rearAxleRefVal, 'GROSS');
                grossTrailer1RearTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_REAR];
            }
            tableMetaToUse.cols.trailerRear.cellData.Gross = { value: trailerRearGrossVal, label: rowLabel, colour: grossTrailer1RearTableHighLightColour, bold: true };
            tableMetaToUse.cols.trailerRear.cellData.Gross.id = config.VALUE_TYPE.TRAILERGROSSREAR;
            if (xXedOutValuesInteractionMetaObject !== undefined) {
                tableMetaToUse.cols.trailerRear.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
            }
        }

        var grossTotalTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossTotalTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossTotalTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalCompareVal, totalRefVal, 'GROSS');
            grossTotalTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.TOTAL];
        }
        //tableMetaToUse.cols.total.cellData.Gross = { value: totalGrossVal, label: config.getTranslationText('704'), colour: grossTotalTableHighLightColour, bold: true };
        tableMetaToUse.cols.total.cellData.Gross = { value: totalGrossVal, label: config.getTranslationText('704'), colour: grossTotalTableHighLightColour, bold: true };
        tableMetaToUse.cols.total.cellData.Gross.id = config.VALUE_TYPE.GROSSTOTAL;
       /* if (missingValuesObject[config.VALUE_TYPE.GROSSTOTAL] !== undefined) {
            tableMetaToUse.cols.total.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSTOTAL];
        } else*/ if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMetaToUse.cols.total.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (returnThisRowOnlyInOwnTableStructure) {
            return tableMetaToUse;
        }
    }

    function setupTwoTrailerGrossRow(returnThisRowOnlyInOwnTableStructure) {

        var tableMetaToUse;

        if (returnThisRowOnlyInOwnTableStructure) {
            tableMetaToUse = getTableBasicSetup(config.VIEW_DETAIL.SUMMARY);
        } else {
            tableMetaToUse = tableMeta;
        }

        //var overloadingColColours = offerManager.generateOverloadingColColours('GROSS');

        var frontGrossVal, rearGrossVal, totalGrossVal, trailer1GrossVal, trailer2GrossVal;
        var rowLabel = config.getTranslationText('171');

        var frontAxleOverload, totalOverload, rearAxleOverload, trailer1Overload, trailer2Overload;

        if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
            frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload());
            totalOverload = applyRoundingForGrossOverload(utils.returnValueOrZeroIfNanOrInfinity(rigOps.getVehicleAndInterlinkAndSemiTotalOverload()));
            rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndInterlinkAndSemiVehicleRearAxleOverload());
            trailer1Overload = applyRoundingForGrossOverload(rigOps.getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload());
            trailer2Overload = applyRoundingForGrossOverload(rigOps.getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload());
        }/* else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
            totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTotalOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
            rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
            trailerFrontOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload());
            trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTrailerRearAxleOverload());

        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupVehicleFrontAxleOverload());
            totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupTotalOverload());
            rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupVehicleRearAxleOverload());
            trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupTrailerRearAxleOverload());
        }*/ //#unfinished, need to support other two trailer combinations here



        frontGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getCombinationVehicleFrontGross());
        if (isTrialUser) {
            rearGrossVal = trialUserObfuscationText;
            totalGrossVal = trialUserObfuscationText;
            trailer1GrossVal = trialUserObfuscationText;
            trailer2GrossVal = trialUserObfuscationText;
        } else {
            rearGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getCombinationVehicleRearGross());
            
            if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK || curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                totalGrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getVehicleAndInterlinkAndSemiTotalGross());
                trailer1GrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getTrailer1RearGross());
                trailer2GrossVal = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(rigOps.getTrailer2RearGross());
            } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndDrawbarTotalGross(), formattingPrecision);
                trailerFrontGrossVal = offerManager.getUIFormattedMassValue(rigOps.getTrailer1FrontGross(), formattingPrecision);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupTotalGross(), formattingPrecision);
            }*/ //#unfinished, need to support other two trailer combinations here


            

        }

        tableMetaToUse.cols.Labels.cellData.Gross = { value: rowLabel, id: config.VALUE_TYPE.GROSSFRONT + '_label'};

        var grossFrontTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossFrontTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossFrontTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleOverload, 'GROSS');
            grossFrontTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.FRONT];
            
        }
        //tableMetaToUse.cols.front.cellData.Gross = { value: frontGrossVal, label: config.getTranslationText('704'), colour: grossFrontTableHighLightColour, bold: true };
        tableMetaToUse.cols.front.cellData.Gross = { value: frontGrossVal, label: config.getTranslationText('704'), colour: grossFrontTableHighLightColour, bold: true };
        tableMetaToUse.cols.front.cellData.Gross.id = config.VALUE_TYPE.GROSSFRONT;
        // if (missingValuesObject[config.VALUE_TYPE.GROSSFRONT] !== undefined) {
        //     tableMetaToUse.cols.front.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSFRONT];
        // }

        if (hasPusherOrTagDown === true) {

            var grossFunctionToUse, rearRearAxlesGrossFunctionToUse;
            grossFunctionToUse = function (axleNumber) {
                return curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() })
            };
            rearRearAxlesGrossFunctionToUse = function () {
                return curVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOps.getCombinationVehicleRearGross() })
            };


            var tableMetaToPass;

            if (returnThisRowOnlyInOwnTableStructure) {
                tableMetaToPass = tableMetaToUse;
            } else {
                tableMetaToPass = undefined;
            }

            generatePopulatedPusherTagAndRearConnectedGrossCols('Gross', config.getTranslationText('171'), grossFunctionToUse, rearRearAxlesGrossFunctionToUse, tableMetaToPass);
        }

        var grossRearTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossRearTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossRearTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload, 'GROSS');
            grossRearTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.REAR];
        }
        tableMetaToUse.cols.rear.cellData.Gross = { value: rearGrossVal, label: config.getTranslationText('171'), colour: grossRearTableHighLightColour, bold: true };
        tableMetaToUse.cols.rear.cellData.Gross.id = config.VALUE_TYPE.GROSSREAR;
        /*if (missingValuesObject[config.VALUE_TYPE.GROSSREAR] !== undefined) {
            tableMetaToUse.cols.rear.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSREAR];
        } else*/ if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMetaToUse.cols.rear.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
        }

        var grossTrailer1RearTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossTrailer1RearTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossRearTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload, 'GROSS');
            grossTrailer1RearTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_1];
        }
        tableMetaToUse.cols.trailer1.cellData.Gross = { value: trailer1GrossVal, label: rowLabel, colour: grossTrailer1RearTableHighLightColour, bold: true };
        tableMetaToUse.cols.trailer1.cellData.Gross.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERGROSSREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMetaToUse.cols.trailer1.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
        }

        var grossTrailer2RearTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossTrailer2RearTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossRearTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload, 'GROSS');
            grossTrailer2RearTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_2];
        }
        tableMetaToUse.cols.trailer2.cellData.Gross = { value: trailer2GrossVal, label: rowLabel, colour: grossTrailer2RearTableHighLightColour, bold: true };
        tableMetaToUse.cols.trailer2.cellData.Gross.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERGROSSREAR;
        if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMetaToUse.cols.trailer2.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
        }

        var grossTotalTableHighLightColour;
        if (hasLegislationARegulationUnknown) {
            grossTotalTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        } else {
            //grossTotalTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalOverload, 'GROSS');
            grossTotalTableHighLightColour = overloadingColColours[config.TABLE_COLUMNS.TOTAL];
        }
        //tableMetaToUse.cols.total.cellData.Gross = { value: totalGrossVal, label: config.getTranslationText('704'), colour: grossTotalTableHighLightColour, bold: true };
        tableMetaToUse.cols.total.cellData.Gross = { value: totalGrossVal, label: config.getTranslationText('704'), colour: grossTotalTableHighLightColour, bold: true };
        tableMetaToUse.cols.total.cellData.Gross.id = config.VALUE_TYPE.GROSSTOTAL;
        /*if (missingValuesObject[config.VALUE_TYPE.GROSSTOTAL] !== undefined) {
            tableMetaToUse.cols.total.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSTOTAL];
        } else */if (xXedOutValuesInteractionMetaObject !== undefined) {
            tableMetaToUse.cols.total.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
        }

        if (returnThisRowOnlyInOwnTableStructure) {
            return tableMetaToUse;
        } 
    }

    function generatePopulatedPusherTagAndRearConnectedGrossCols(rowKey, rowLabel, tagOrPusherAxleGrossFunc, rearOnlyGrossFunc, forcedTableMeta) {

        var tableMetaToUse = forcedTableMeta || tableMeta;
        var numAxlesBeforeTags = curVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags();
        var numAxlesBeforePushers = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        Object.keys(tableMetaToUse.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                var axleNumber, axleTypeNumber, valToUse = '';
                var highlightColourIfAny;
                var overloadValToUse;
                var rowId;
                if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1) {
                    axleNumber = key.split('_')[2];
                    axleTypeNumber = key.split('_')[1];
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = tagOrPusherAxleGrossFunc(Number(axleNumber));
                    }
                    
                    var axleTypeNum = key.split('_')[1];
                    if (key.indexOf('Pusher') !== -1) {
                        overloadValToUse = offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADPUSHER, { axleNumber: Number(axleNumber), pusherNumber: (Number(axleNumber) - numAxlesBeforePushers) }); 
                        rowId = config.VALUE_TYPE.AXLEGROSSPUSHER + axleTypeNumber;
                    } else {

                        overloadValToUse = offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADTAG, { axleNumber: Number(axleNumber), tagNumber: (Number(axleNumber) - numAxlesBeforeTags) });
                        rowId = config.VALUE_TYPE.AXLEGROSSTAG + axleTypeNumber;
                    }
                } else if (key.indexOf('Connected') !== -1) {
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = rearOnlyGrossFunc();
                    }

                    overloadValToUse = offerManager.getValue(config.VALUE_TYPE.AXLESOVERLOADREARREAR);
                    rowId = config.VALUE_TYPE.AXLEGROSSREARREAR;
                }
                if (overloadValToUse !== null) {
                    applyRoundingForGrossOverload(overloadValToUse);
                }
                if (hasLegislationARegulationUnknown) {
                    highlightColourIfAny = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
                } else {
                // highlightColourIfAny = offerManager.getTableCellHighlightColour(key, overloadValToUse, 'GROSS');
                    highlightColourIfAny = overloadingColColours[key];
                }
                
                

                valToUse = hasLegislationARegulationUnknown ? config.defaultTableBlankRowChar : getUIMassFormattedValOrDash(valToUse);
                tableMetaToUse.cols[key].cellData[rowKey] = { value: valToUse, label: rowLabel, colour: highlightColourIfAny };
                if (xXedOutValuesInteractionMetaObject !== undefined) {
                    tableMetaToUse.cols[key].cellData[rowKey].interaction = xXedOutValuesInteractionMetaObject;
                }
                tableMetaToUse.cols[key].cellData[rowKey].id = rowId;
            }
        });
    }

    //function getGrossRowOnlyEmbeddedInTableStructure() {

    //    var tableMeta = getTableBasicSetup(config.VIEW_DETAIL.SUMMARY);


    //    var frontGrossVal, rearGrossVal, totalGrossVal, trailerFrontGrossVal, trailerRearGrossVal;
    //    var rowLabel = config.getTranslationText('171');

    //    var frontAxleOverload, totalOverload, rearAxleOverload, trailerRearOverload

    //    if (curTrailer1 === null) {
    //        frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
    //        totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleTotalOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
    //        rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);

    //    } else {

    //        if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
    //            frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload());
    //            totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndSemiOrInterlinkTotalOverload());
    //            rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload());
    //            trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload());
    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
    //            totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTotalOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
    //            rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
    //            trailerFrontOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload());
    //            trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndDrawbarTrailerRearAxleOverload());

    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //            frontAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupVehicleFrontAxleOverload());
    //            totalOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupTotalOverload());
    //            rearAxleOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupVehicleRearAxleOverload());
    //            trailerRearOverload = applyRoundingForGrossOverload(rigOps.getVehicleAndPupTrailerRearAxleOverload());
    //        }



    //    }



    //    frontGrossVal = offerManager.getUIFormattedMassValue(rigOps.getCombinationVehicleFrontGross(), formattingPrecision);
    //    if (isTrialUser) {
    //        rearGrossVal = trialUserObfuscationText;
    //        totalGrossVal = trialUserObfuscationText;
    //        trailerFrontGrossVal = trialUserObfuscationText;
    //        trailerRearGrossVal = trialUserObfuscationText;
    //    } else {
    //        rearGrossVal = offerManager.getUIFormattedMassValue(rigOps.getCombinationVehicleRearGross(), formattingPrecision);
    //        if (curTrailer1 === null) {
    //            totalGrossVal = offerManager.getUIFormattedMassValue(vehicle.getGrossTotal(), formattingPrecision);
    //        } else {

    //            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
    //                totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndSemiOrInterlinkTotalGross(), formattingPrecision);
    //            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //                totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndDrawbarTotalGross(), formattingPrecision);
    //                trailerFrontGrossVal = offerManager.getUIFormattedMassValue(rigOps.getTrailer1FrontGross(), formattingPrecision);
    //            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //                totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupTotalGross(), formattingPrecision);
    //            }


    //            trailerRearGrossVal = offerManager.getUIFormattedMassValue(rigOps.getTrailer1RearGross(), formattingPrecision);
    //        }

    //    }


    //    var grossFrontTableHighLightColour;
    //    if (missingValuesObject[config.VALUE_TYPE.GROSSFRONT] !== undefined) {
    //        grossFrontTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    } else {
    //        grossFrontTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleOverload, 'GROSS');
    //    }
    //    //tableMeta.cols.front.cellData.Gross = { value: frontGrossVal, label: config.getTranslationText('704'), colour: grossFrontTableHighLightColour, bold: true };
    //    tableMeta.cols.front.cellData.Gross = { value: frontGrossVal, label: config.getTranslationText('704'), colour: grossFrontTableHighLightColour, bold: true };
    //    tableMeta.cols.front.cellData.Gross.id = config.VALUE_TYPE.GROSSFRONT;
    //    if (missingValuesObject[config.VALUE_TYPE.GROSSFRONT] !== undefined) {
    //        tableMeta.cols.front.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSFRONT];
    //    }

    //    if (hasPusherOrTagDown === true) {

    //        var grossFunctionToUse, rearRearAxlesGrossFunctionToUse;
    //        grossFunctionToUse = function (axleNumber) {
    //            return curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() });
    //        };
    //        rearRearAxlesGrossFunctionToUse = function () {
    //            return curVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOps.getCombinationVehicleRearGross() });
    //        };
    //        generatePopulatedPusherTagAndRearConnectedGrossCols('Gross', config.getTranslationText('171'), grossFunctionToUse, rearRearAxlesGrossFunctionToUse, tableMeta);
    //    }

    //    var grossRearTableHighLightColour;
    //    if (missingValuesObject[config.VALUE_TYPE.GROSSREAR] !== undefined) {
    //        grossRearTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    } else {
    //        grossRearTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload, 'GROSS');
    //    }
    //    tableMeta.cols.rear.cellData.Gross = { value: rearGrossVal, label: config.getTranslationText('171'), colour: grossRearTableHighLightColour, bold: true };
    //    tableMeta.cols.rear.cellData.Gross.id = config.VALUE_TYPE.GROSSREAR;
    //    if (missingValuesObject[config.VALUE_TYPE.GROSSREAR] !== undefined) {
    //        tableMeta.cols.rear.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSREAR];
    //    } else if (xXedOutValuesInteractionMetaObject !== undefined) {
    //        tableMeta.cols.rear.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
    //    }

    //    if (curTrailer1 !== null) {

    //        if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            tableMeta.cols.trailerFront.cellData.Gross = { value: trailerFrontGrossVal, label: rowLabel, colour: offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_FRONT, trailerFrontOverload, 'GROSS'), bold: true };
    //            tableMeta.cols.trailerFront.cellData.Gross.id = config.VALUE_TYPE.TRAILERGROSSFRONT;
    //            if (xXedOutValuesInteractionMetaObject !== undefined) {
    //                tableMeta.cols.trailerFront.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
    //            }
    //        }

    //        tableMeta.cols.trailerRear.cellData.Gross = { value: trailerRearGrossVal, label: rowLabel, colour: offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_REAR, trailerRearOverload, 'GROSS'), bold: true };
    //        tableMeta.cols.trailerRear.cellData.Gross.id = config.VALUE_TYPE.TRAILERGROSSREAR;
    //        if (xXedOutValuesInteractionMetaObject !== undefined) {
    //            tableMeta.cols.trailerRear.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
    //        }
    //    }

    //    var grossTotalTableHighLightColour;
    //    if (missingValuesObject[config.VALUE_TYPE.GROSSTOTAL] !== undefined) {
    //        grossTotalTableHighLightColour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    } else {
    //        grossTotalTableHighLightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalOverload, 'GROSS');
    //    }
    //    tableMeta.cols.total.cellData.Gross = { value: totalGrossVal, label: config.getTranslationText('704'), colour: grossTotalTableHighLightColour, bold: true };
    //    tableMeta.cols.total.cellData.Gross.id = config.VALUE_TYPE.GROSSTOTAL;
    //    if (missingValuesObject[config.VALUE_TYPE.GROSSTOTAL] !== undefined) {
    //        tableMeta.cols.total.cellData.Gross.missingValMeta = missingValuesObject[config.VALUE_TYPE.GROSSTOTAL];
    //    } else if (xXedOutValuesInteractionMetaObject !== undefined) {
    //        tableMeta.cols.total.cellData.Gross.interaction = xXedOutValuesInteractionMetaObject;
    //    }

    //    return tableMeta;
    //}

    
    //getMergedLegislationsCombinationPermissibleVals
    function getUIMassFormattedValOrDash(value) {
        let permissibleVal = utils.returnValueOrDashIfNanOrZero(value);
        if(permissibleVal !== config.defaultTableBlankRowChar) {
            permissibleVal = offerManager.getUIFormattedMassValue(permissibleVal, formattingPrecision)
        }
        return permissibleVal;
    }
    function setupPermissibleRow(combinationPermissibleValues) {
                        
        if (!combinationPermissibleValues) {
            combinationPermissibleValues = rigOps.getCombinationPermissibleVals();
        }

        var rowLabel = config.getTranslationText('296');
        
        var valueType, elementId;
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
            valueType = config.VALUE_TYPE.AXLEPERMISSIBLEDETAILED;
        } else {
            valueType = config.VALUE_TYPE.AXLEPERMISSIBLESIMPLIFIED;
        }

        tableMeta.cols.Labels.cellData.Permissible = { value: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS + '_label' };

        //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleFront() > 0) {
        //    tableMeta.cols.front.cellData.Permissible = { value: offerManager.getUIFormattedMassValue(vehicle.getPermissibleFront(), formattingPrecision), label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS };
        //} else {
       
        tableMeta.cols.front.cellData.Permissible = { value: getUIMassFormattedValOrDash(combinationPermissibleValues.vehicleFrontPermissible), label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS };
            if (missingValuesObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS] !== undefined) {
                tableMeta.cols.front.cellData.Permissible.missingValMeta = missingValuesObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS];
            } else {
                if (globals.user.isLoadingAdministrator() === true) {
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + valueType;
                    tableMeta.cols.front.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, rigOps.getVehicleExtremeFrontAxlesInteractionTargetElementIdPermissible());
                    tableMeta.cols.front.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.front.cellData.Permissible.interaction.callbacks);
                }
            }
        //}


        if (hasPusherOrTagDown === true) {
            generatePopulatedPusherTagAndRearConnectedPermissibleCols('Permissible', rowLabel, combinationPermissibleValues);
        } 

        //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleRear() > 0) {
        //    tableMeta.cols.rear.cellData.Permissible = { value: offerManager.getUIFormattedMassValue(vehicle.getPermissibleRear(), formattingPrecision), label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS };
        //} else {

        //var rearVal;
        //if (hasPusherOrTagDown) {
        //    rearVal = offerManager.getValue(config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS);
        //} else {
        //    rearVal
        //}
        tableMeta.cols.rear.cellData.Permissible = { value: getUIMassFormattedValOrDash(combinationPermissibleValues.vehicleRearPermissible), label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS };
        if (missingValuesObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS] !== undefined) {
            tableMeta.cols.rear.cellData.Permissible.missingValMeta = missingValuesObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS];
        } else {
            if (hasPusherOrTagDown === true) {
                if (globals.user.isLoadingAdministrator() === true) {
                    tableMeta.cols.rear.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, rigOps.getVehicleExtremeRearAxlesInteractionTargetElementIdPermissible()/*config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_REAR_TOTAL + '.' + valueType*/);
                    tableMeta.cols.rear.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.rear.cellData.Permissible.interaction.callbacks);
                }
            } else {
                if (globals.user.isLoadingAdministrator() === true) {
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + valueType;
                    tableMeta.cols.rear.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, rigOps.getVehicleExtremeRearDrivenAxlesInteractionTargetElementIdPermissible());
                    tableMeta.cols.rear.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.rear.cellData.Permissible.interaction.callbacks);
                }
            }
        }
        //}

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.Permissible = { value: getUIMassFormattedValOrDash(combinationPermissibleValues.trailer1PermissibleFront), label: rowLabel };
                tableMeta.cols.trailerFront.cellData.Permissible.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSFRONTAXLESMASS;

                if (globals.user.isLoadingAdministrator() === true) {
                    elementId = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + valueType;
                    tableMeta.cols.trailerFront.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId);
                    tableMeta.cols.trailerFront.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.trailerFront.cellData.Permissible.interaction.callbacks);
                }
            }

            tableMeta.cols.trailerRear.cellData.Permissible = { value: getUIMassFormattedValOrDash(combinationPermissibleValues.trailer1PermissibleRear), label: rowLabel };
            tableMeta.cols.trailerRear.cellData.Permissible.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASS;

            if (globals.user.isLoadingAdministrator() === true) {
                elementId = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + valueType;
                tableMeta.cols.trailerRear.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId);
                tableMeta.cols.trailerRear.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.trailerRear.cellData.Permissible.interaction.callbacks);
            }
        }

        tableMeta.cols.total.cellData.Permissible = { value: getUIMassFormattedValOrDash(combinationPermissibleValues.combinationTotalPermissible), label: rowLabel };
        tableMeta.cols.total.cellData.Permissible.id = config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS;
        if (missingValuesObject[config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS] !== undefined) {
            tableMeta.cols.total.cellData.Permissible.missingValMeta = missingValuesObject[config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS];
        } else {
            if (globals.user.isLoadingAdministrator() === true) {
                //tableMeta.cols.total.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.PERMISSIBLETOTALAXLEMASS);
                tableMeta.cols.total.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, offerManager.getRigOps().getCombinationExtremeAxlesInteractionTargetElementIdPermissible());
                tableMeta.cols.total.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Permissible.interaction.callbacks);
            }
        }

    }

    function setupTwoTrailerPermissibleRow(combinationPermissibleValues) {

        if (!combinationPermissibleValues) {
            combinationPermissibleValues = rigOps.getCombinationPermissibleVals();
        }

        var rowLabel = config.getTranslationText('296');

        var valueType, elementId;
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
            valueType = config.VALUE_TYPE.AXLEPERMISSIBLEDETAILED;
        } else {
            valueType = config.VALUE_TYPE.AXLEPERMISSIBLESIMPLIFIED;
        }

        var frontPermisibleVal, rearPermissibleVal, trailer1PermissibleVal, trailer2PermissibleVal, totalPermissibleVal;
            
        if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
            //frontPermisibleVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleFrontPermissible(), formattingPrecision); 
            //rearPermissibleVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleRearPermissible(), formattingPrecision);
            //trailer1PermissibleVal = offerManager.getUIFormattedMassValue(rigOps.getTrailer1PermissibleRear(), formattingPrecision);
            //trailer2PermissibleVal = offerManager.getUIFormattedMassValue(rigOps.getTrailer2PermissibleRear(), formattingPrecision);
            //totalPermissibleVal = offerManager.getUIFormattedMassValue(rigOps.getCombinationTotalPermissible(), formattingPrecision);
            frontPermisibleVal = getUIMassFormattedValOrDash(combinationPermissibleValues.vehicleFrontPermissible);
            rearPermissibleVal = getUIMassFormattedValOrDash(combinationPermissibleValues.vehicleRearPermissible);
            trailer1PermissibleVal = getUIMassFormattedValOrDash(combinationPermissibleValues.trailer1PermissibleRear);
            trailer2PermissibleVal = getUIMassFormattedValOrDash(combinationPermissibleValues.trailer2PermissibleRear);
            totalPermissibleVal = getUIMassFormattedValOrDash(combinationPermissibleValues.combinationTotalPermissible);
        }


        tableMeta.cols.Labels.cellData.Permissible = { value: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS + '_label'};


        //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleFront() > 0) {
        //    tableMeta.cols.front.cellData.Permissible = { value: offerManager.getUIFormattedMassValue(vehicle.getPermissibleFront(), formattingPrecision), label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS };
        //} else {
        tableMeta.cols.front.cellData.Permissible = { value: frontPermisibleVal, label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS };
        if (missingValuesObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS] !== undefined) {
            tableMeta.cols.front.cellData.Permissible.missingValMeta = missingValuesObject[config.VALUE_TYPE.MANUFACTURERSFRONTAXLEMASS];
        } else {
            if (globals.user.isLoadingAdministrator() === true) {
                //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + valueType;
                tableMeta.cols.front.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, rigOps.getVehicleExtremeFrontAxlesInteractionTargetElementIdPermissible());
                tableMeta.cols.front.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.front.cellData.Permissible.interaction.callbacks);
            }
        }
        //}


        if (hasPusherOrTagDown === true) {
            generatePopulatedPusherTagAndRearConnectedPermissibleCols('Permissible', rowLabel, combinationPermissibleValues);
        }

        //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleRear() > 0) {
        //    tableMeta.cols.rear.cellData.Permissible = { value: offerManager.getUIFormattedMassValue(vehicle.getPermissibleRear(), formattingPrecision), label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS };
        //} else {
        tableMeta.cols.rear.cellData.Permissible = { value: rearPermissibleVal, label: rowLabel, id: config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS };
        if (missingValuesObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS] !== undefined) {
            tableMeta.cols.rear.cellData.Permissible.missingValMeta = missingValuesObject[config.VALUE_TYPE.MANUFACTURERSREARAXLEMASS];
        } /*else {
            tableMeta.cols.rear.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS);
            tableMeta.cols.rear.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.rear.cellData.Permissible.interaction.callbacks);
        }*/
        //}

        tableMeta.cols.trailer1.cellData.Permissible = { value: trailer1PermissibleVal, label: rowLabel };
        tableMeta.cols.trailer1.cellData.Permissible.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASS;

        if (globals.user.isLoadingAdministrator() === true) {
            elementId = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + valueType;
            tableMeta.cols.trailer1.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId);
            tableMeta.cols.trailer1.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.trailer1.cellData.Permissible.interaction.callbacks);
        }

        tableMeta.cols.trailer2.cellData.Permissible = { value: trailer2PermissibleVal, label: rowLabel };
        tableMeta.cols.trailer2.cellData.Permissible.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASS;

        if (globals.user.isLoadingAdministrator() === true) {
            elementId = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curTrailer2.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + valueType;
            tableMeta.cols.trailer2.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId);
            tableMeta.cols.trailer2.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.trailer2.cellData.Permissible.interaction.callbacks);
        }

        tableMeta.cols.total.cellData.Permissible = { value: totalPermissibleVal, label: rowLabel };
        tableMeta.cols.total.cellData.Permissible.id = config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS;
        if (missingValuesObject[config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS] !== undefined) {
            tableMeta.cols.total.cellData.Permissible.missingValMeta = missingValuesObject[config.VALUE_TYPE.MANUFACTURERSGROSSVEHICLEMASS];
        } else {
            if (globals.user.isLoadingAdministrator() === true) {
                //tableMeta.cols.total.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.PERMISSIBLETOTALAXLEMASS);
                tableMeta.cols.total.cellData.Permissible.interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, rigOps.getCombinationExtremeAxlesInteractionTargetElementIdPermissible()/*config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.COMBINATIONBRIDGEFORMULAPERMISSIBLE*/);
                tableMeta.cols.total.cellData.Permissible.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols.total.cellData.Permissible.interaction.callbacks);
            }
        }

    }

    function generatePopulatedPusherTagAndRearConnectedPermissibleCols(rowKey, rowLabel, combinationPermissibleValues) {
        var numAxlesBeforeTags = curVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags();
        var numAxlesBeforePushers = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        Object.keys(tableMeta.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                var valToUse = '';
                var highlightColourIfAny;
                var rowId;
                var elementId;
                var axleNumber;
                var valueType;
                if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
                    valueType = config.VALUE_TYPE.AXLEPERMISSIBLEDETAILED;
                } else {
                    valueType = config.VALUE_TYPE.AXLEPERMISSIBLESIMPLIFIED;
                }
                if (key.indexOf('Pusher') !== -1) {

                    var pusherNumber = key.split('_')[1];
                    axleNumber = key.split('_')[2];
                      
                    //valToUse = offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARPUSHER' + pusherNumber);
                    valToUse = combinationPermissibleValues['vehiclePusher' + (Number(axleNumber) - numAxlesBeforePushers)];//rigOps.getPusherOrTagPermissibleVal(Number(axleNumber), (Number(axleNumber) - numAxlesBeforePushers), config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);//['getVehiclePusher' + (Number(axleNumber) - numAxlesBeforePushers) + 'GroupBridgeFormulaPermissible']();
                    rowId = config.VALUE_TYPE.AXLEPERMISSIBLEPUSHER + pusherNumber;
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + curVehicle.getAxlesHolder()['getAxle' + pusherNumber + 'Rear']().getId() + '.' + valueType; 
                    elementId = rigOps['getVehiclePusher' + pusherNumber + 'AxleInteractionTargetElementIdPermissible']();
                } else if (key.indexOf('Connected') !== -1) {
                    //valToUse = offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARREARONLY');
                    valToUse = combinationPermissibleValues.vehicleRearRearOnly;//rigOps.getRearRearAxlePermissibleVal();//getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible();
                    rowId = config.VALUE_TYPE.AXLEPERMISSIBLEREARREAR;
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + valueType;
                    elementId = rigOps.getVehicleExtremeRearDrivenAxlesInteractionTargetElementIdPermissible();
                } else if (key.indexOf('Tag') !== -1) {

                    var tagNumber = key.split('_')[1];
                    axleNumber = key.split('_')[2];
                    //valToUse = offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARTAG' + tagNumber);
                    valToUse = combinationPermissibleValues['vehicleTag' + (Number(axleNumber) - numAxlesBeforeTags)];//rigOps.getPusherOrTagPermissibleVal(Number(axleNumber), (Number(axleNumber) - numAxlesBeforeTags), config.VEHICLE_AXLE_POSITION_TYPES.TAG);//['getVehicleTag' + (Number(axleNumber) - numAxlesBeforeTags) + 'GroupBridgeFormulaPermissible']();
                    rowId = config.VALUE_TYPE.AXLEPERMISSIBLETAG + tagNumber;
                    //elementId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + curVehicle.getAxlesHolder()['getAxle' + tagNumber + 'Rear']().getId() + '.' + valueType;
                    elementId = rigOps['getVehicleTag' + (Number(axleNumber) - numAxlesBeforeTags) + 'AxleInteractionTargetElementIdPermissible']();
                }
                valToUse = getUIMassFormattedValOrDash(valToUse);

                tableMeta.cols[key].cellData[rowKey] = { value: valToUse, label: rowLabel };
                tableMeta.cols[key].cellData[rowKey].id = rowId;

                if (globals.user.isLoadingAdministrator() === true) {
                    tableMeta.cols[key].cellData[rowKey].interaction = offerManager.getStandardTableCellInteractionObject(config.INTERACTION_ID.TABLE_CELL, elementId);
                    tableMeta.cols[key].cellData[rowKey].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(tableMeta.cols[key].cellData[rowKey].interaction.callbacks);
                }
            }
        });
    }

    

    function setupUnusedCapacityRow(combinationPermissibleValues) {
    
        if (!combinationPermissibleValues) {
            combinationPermissibleValues = rigOps.getCombinationPermissibleVals();
        }

        var frontGrossVal, rearGrossVal, totalGrossVal, trailerFrontGrossVal, trailerRearGrossVal, frontPermissibleVal, rearPermissibleVal, totalPermissibleVal, trailerFrontPermissibleVal, trailerRearPermissibleVal;

        frontGrossVal = rigOps.getCombinationVehicleFrontGross();
        frontPermissibleVal = combinationPermissibleValues.vehicleFrontPermissible;//offerManager.getValue(config.VALUE_TYPE.PERMISSIBLEFRONTAXLEMASS);
        rearGrossVal = rigOps.getCombinationVehicleRearGross();
        rearPermissibleVal = combinationPermissibleValues.vehicleRearPermissible;//offerManager.getValue(config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS);
        
        totalPermissibleVal = combinationPermissibleValues.combinationTotalPermissible;//offerManager.getValue(config.VALUE_TYPE.PERMISSIBLECOMBINATIONTOTAL);
        if (curTrailer1 === null) {
            totalGrossVal = vehicle.getGrossTotal();
        } else {
            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                totalGrossVal = rigOps.getVehicleAndSemiOrInterlinkTotalGross();
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                totalGrossVal = rigOps.getVehicleAndDrawbarTotalGross();
                trailerFrontGrossVal = rigOps.getTrailer1FrontGross();
                trailerFrontPermissibleVal = combinationPermissibleValues.trailer1PermissibleFront;//rigOps.getTrailer1PermissibleFront();
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                totalGrossVal = rigOps.getVehicleAndPupTotalGross();
            }
            trailerRearGrossVal = rigOps.getTrailer1RearGross();
            trailerRearPermissibleVal = combinationPermissibleValues.trailer1PermissibleRear;//rigOps.getTrailer1PermissibleRear();
        }

        var frontDiffDisplay, rearDiffDisplay, totalDiffDisplay, trailerFrontDiffDisplay, trailerRearDiffDisplay, frontDiff, rearDiff, trailerFrontDiff, trailerRearDiff, totalDiff;
        
        frontDiff = roundMassInCurrentMeasurementSystem(frontPermissibleVal) - roundMassInCurrentMeasurementSystem(frontGrossVal);
        frontDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(frontDiff, formattingPrecision);
        rearDiff = roundMassInCurrentMeasurementSystem(rearPermissibleVal) - roundMassInCurrentMeasurementSystem(rearGrossVal);
        rearDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(rearDiff, formattingPrecision);
        totalDiff = roundMassInCurrentMeasurementSystem(totalPermissibleVal) - roundMassInCurrentMeasurementSystem(totalGrossVal);
        totalDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(totalDiff, formattingPrecision);

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                trailerFrontDiff = roundMassInCurrentMeasurementSystem(trailerFrontPermissibleVal) - roundMassInCurrentMeasurementSystem(trailerFrontGrossVal);
                trailerFrontDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(trailerFrontDiff, formattingPrecision);
            }

            trailerRearDiff = roundMassInCurrentMeasurementSystem(trailerRearPermissibleVal) - roundMassInCurrentMeasurementSystem(trailerRearGrossVal);
            trailerRearDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(trailerRearDiff, formattingPrecision);
        }

        
        tableMeta.cols.Labels.cellData.UnusedCapacity = { value: config.getTranslationText('4309'), id: config.VALUE_TYPE.UNUSEDCAPACITYLABEL };
        tableMeta.cols.front.cellData.UnusedCapacity = { value: frontDiffDisplay, id: config.VALUE_TYPE.UNUSEDCAPACITYFRONT };
        if (hasPusherOrTagDown === true) {
            var grossFunctionToUse, rearRearAxlesGrossFunctionToUse;
            grossFunctionToUse = function (axleNumber) {
                return curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() });
            };
            rearRearAxlesGrossFunctionToUse = function () {
                return curVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOps.getCombinationVehicleRearGross() });
            };
            //generatePopulatedPusherTagAndRearConnectedOverloadCols('Overload', rowLabel, grossFunctionToUse, rearRearAxlesGrossFunctionToUse);
            generatePopulatedPusherTagAndRearConnectedUnusedCapacityCols('UnusedCapacity', grossFunctionToUse, rearRearAxlesGrossFunctionToUse, combinationPermissibleValues);
        }
        tableMeta.cols.rear.cellData.UnusedCapacity = { value: rearDiffDisplay, id: config.VALUE_TYPE.UNUSEDCAPACITYREAR };
        if (curTrailer1 !== null) {
            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                tableMeta.cols.trailerFront.cellData.UnusedCapacity = { value: trailerFrontDiffDisplay, id: config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERFRONTAXLESMASSUNUSEDCAPACITY };
            }
            tableMeta.cols.trailerRear.cellData.UnusedCapacity = { value: trailerRearDiffDisplay, id: config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERREARAXLESMASSUNUSEDCAPACITY };
        }
        tableMeta.cols.total.cellData.UnusedCapacity = { value: totalDiffDisplay, id: config.VALUE_TYPE.UNUSEDCAPACITYTOTAL };
    }

    function setupUtilisationRow() {


        //var overloadingColColours = offerManager.generateOverloadingColColours();

        var frontAxleOverload, totalOverload, rearAxleOverload, trailerFrontOverload, trailerRearOverload;

        var hasNonZeroPayload = false;
        if(curTrailer1 === null) {
            hasNonZeroPayload = (curVehicle.getAccessoryHolder().getBody() !== null && curVehicle.getPayloadHolder().getTotalMass() > 0);
        } else {
            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                hasNonZeroPayload = (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                hasNonZeroPayload = (curVehicle.getAccessoryHolder().getBody() !== null && curVehicle.getPayloadHolder().getTotalMass() > 0) && (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                hasNonZeroPayload = (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
            }


            //#unfinished, trailer 2 support needed here 
        }


        


        

        

        var frontAxleOverloadDisplay, totalOverloadDisplay, rearAxleOverloadDisplay, trailerFrontOverloadDisplay, trailerRearOverloadDisplay;
        
        var rowLabel = config.getTranslationText('4314');

        

        var highlightRearAxleAsPayloadLimitingAxle = false;
        var highlightFrontAxleAsPayloadLimitingAxle = false;
        var highlightTrailerRearAxleAsPayloadLimitingAxle = false;
        var highlightTrailerFrontAxleAsPayloadLimitingAxle = false;
        var highlightTotalAsPayloadLimitingFactor = false;

        if (curTrailer1 === null) {
            //frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
            //totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleTotalOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
            //rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);

            frontAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleFrontAxleOverload());
            totalOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleTotalOverload());
            rearAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleRearAxleOverload());

            frontAxleOverloadDisplay = frontAxleOverload + '%';
            totalOverloadDisplay = totalOverload + '%';
            rearAxleOverloadDisplay = rearAxleOverload + '%';
            

        } else {

            

            if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                frontAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload());
                totalOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndSemiOrInterlinkTotalOverload());
                rearAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload());
                trailerRearOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload());
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                frontAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
                totalOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndDrawbarTotalOverload());
                rearAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
                trailerFrontOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload());
                trailerRearOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndDrawbarTrailerRearAxleOverload());

                trailerFrontOverloadDisplay = trailerFrontOverload + '%';
            } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                frontAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndPupVehicleFrontAxleOverload());
                totalOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndPupTotalOverload());
                rearAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndPupVehicleRearAxleOverload());
                trailerRearOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndPupTrailerRearAxleOverload());
            }

            frontAxleOverloadDisplay = frontAxleOverload + '%';
            totalOverloadDisplay = totalOverload + '%';
            rearAxleOverloadDisplay = rearAxleOverload + '%';
            
            trailerRearOverloadDisplay = trailerRearOverload + '%';
            
        }

        if (!getHasAnyPusherTagOrRearConnectedColsOverload()) {
            //if (Number((frontAxleOverload).toFixed(2)) === 0) {
            //    highlightFrontAxleAsPayloadLimitingAxle = true;
            //}
            //if (Number((rearAxleOverload).toFixed(2)) === 0) {
            //    highlightRearAxleAsPayloadLimitingAxle = true;
            //}
            //if (curTrailer1 !== null) {

            //    if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            //        if (Number((trailerFrontOverload).toFixed(2)) === 0) {
            //            highlightTrailerFrontAxleAsPayloadLimitingAxle = true;
            //        }
            //    }

            //    if (Number((trailerRearOverload).toFixed(2)) === 0) {
            //        highlightTrailerRearAxleAsPayloadLimitingAxle = true;
            //    }
            //}

            //if (Number((totalOverload).toFixed(2)) === 0) {
            //    highlightTotalAsPayloadLimitingFactor = true;
            //}
            if (Number(frontAxleOverload) === 100) {
                highlightFrontAxleAsPayloadLimitingAxle = true;
            }
            if (Number(rearAxleOverload) === 100) {
                highlightRearAxleAsPayloadLimitingAxle = true;
            }
            if (curTrailer1 !== null) {

                if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    if (Number(trailerFrontOverload) === 100) {
                        highlightTrailerFrontAxleAsPayloadLimitingAxle = true;
                    }
                }

                if (Number(trailerRearOverload) === 100) {
                    highlightTrailerRearAxleAsPayloadLimitingAxle = true;
                }
            }

            if (Number(totalOverload) === 100) {
                highlightTotalAsPayloadLimitingFactor = true;
            }
        }
        tableMeta.cols.Labels.cellData.Overload = { value: rowLabel, id: config.VALUE_TYPE.OVERLOADFRONT + '_label' };
        var frontTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.FRONT];
        if (frontTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightFrontAxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                frontTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                frontTableHighlightColour = undefined;
            }
        }
        
        tableMeta.cols.front.cellData.Overload = { value: frontAxleOverloadDisplay, colour: frontTableHighlightColour, bold: true, id: config.VALUE_TYPE.OVERLOADFRONT };
        
        if (missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT] !== undefined) {
            tableMeta.cols.front.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT];
            tableMeta.cols.front.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        }

        if (hasPusherOrTagDown === true) {
            generatePopulatedPusherTagAndRearConnectedOverloadCols('Overload', rowLabel, hasNonZeroPayload, hasOverloadedCol);
        } 

        var rearTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.REAR];
        if (rearTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightRearAxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                rearTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                rearTableHighlightColour = undefined;
            }
        }
        tableMeta.cols.rear.cellData.Overload = { value: rearAxleOverloadDisplay, colour: rearTableHighlightColour, bold: true, id: config.VALUE_TYPE.OVERLOADREAR };
        
        if (missingValuesObject[config.VALUE_TYPE.OVERLOADREAR] !== undefined) {
            tableMeta.cols.rear.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADREAR];
            tableMeta.cols.rear.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        }

        if (curTrailer1 !== null) {

            if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                var trailer1FrontTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_FRONT];
                if (trailer1FrontTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
                    if (hasNonZeroPayload === true && highlightTrailerFrontAxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                        trailer1FrontTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
                    } else {
                        trailer1FrontTableHighlightColour = undefined;
                    }
                }
                tableMeta.cols.trailerFront.cellData.Overload = { value: trailerFrontOverloadDisplay, colour: trailer1FrontTableHighlightColour, bold: true, id: config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSFRONTAXLESMASSOVERLOAD };
            }

            var trailer1TableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_REAR];
            if (trailer1TableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
                if (hasNonZeroPayload === true && highlightTrailerRearAxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                    trailer1TableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
                } else {
                    trailer1TableHighlightColour = undefined;
                }
            }
            tableMeta.cols.trailerRear.cellData.Overload = { value: trailerRearOverloadDisplay, colour: trailer1TableHighlightColour, bold: true, id: config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASSOVERLOAD };
            
        }
        
        var totalTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TOTAL];
        if (totalTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightTotalAsPayloadLimitingFactor === true && !hasOverloadedCol) {
                totalTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                totalTableHighlightColour = undefined;
            }
        }

        tableMeta.cols.total.cellData.Overload = { value: totalOverloadDisplay, colour: totalTableHighlightColour, bold: true, id: config.VALUE_TYPE.OVERLOADTOTAL };
        if (missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL];
            tableMeta.cols.total.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        }
    }

    function roundMassInCurrentMeasurementSystem(valToRound) {
        valToRound = valToRound * globals.user.getActiveMeasurementSystem().massConversion;

        return parseFloat(valToRound.toFixed(config.roundingPrecisionForTableAndWarnings));
    }

    //function setupOverloadRow() {
        

    //    //var overloadingColColours = offerManager.generateOverloadingColColours();

    //    var frontGrossVal, rearGrossVal, totalGrossVal, trailerFrontGrossVal, trailerRearGrossVal, frontPermissibleVal, rearPermissibleVal, totalPermissibleVal, trailerFrontPermissibleVal, trailerRearPermissibleVal;
        
    //    var hasNonZeroPayload = false;
    //    if(curTrailer1 === null) {
    //        hasNonZeroPayload = (curVehicle.getAccessoryHolder().getBody() !== null && curVehicle.getPayloadHolder().getTotalMass() > 0);
    //    } else {
    //        if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
    //            hasNonZeroPayload = (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            hasNonZeroPayload = (curVehicle.getAccessoryHolder().getBody() !== null && curVehicle.getPayloadHolder().getTotalMass() > 0) && (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //            hasNonZeroPayload = (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
    //        }

            
    //        //#unfinished, trailer 2 support needed here 
    //    }
        

    //    frontGrossVal = rigOps.getCombinationVehicleFrontGross();
    //    //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleFront() > 0) {
    //    //    frontPermissibleVal = vehicle.getPermissibleFront();
    //    //} else {
    //        frontPermissibleVal = offerManager.getValue(config.VALUE_TYPE.PERMISSIBLEFRONTAXLEMASS);
    //    //}
        
    //        rearGrossVal = rigOps.getCombinationVehicleRearGross();
    //    //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleRear() > 0) {
    //    //    rearPermissibleVal = vehicle.getPermissibleRear();
    //    //} else {
    //        rearPermissibleVal = offerManager.getValue(config.VALUE_TYPE.PERMISSIBLEREARAXLEMASS);
    //    //}
            
    //    totalPermissibleVal = offerManager.getValue(config.VALUE_TYPE.PERMISSIBLECOMBINATIONTOTAL);
    //    if (curTrailer1 === null) {
    //        totalGrossVal = vehicle.getGrossTotal();
            
    //    } else {

    //        if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
    //            totalGrossVal = rigOps.getVehicleAndSemiOrInterlinkTotalGross();
    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            totalGrossVal = rigOps.getVehicleAndDrawbarTotalGross();
    //            trailerFrontGrossVal = rigOps.getTrailer1FrontGross();
    //            trailerFrontPermissibleVal = offerManager.getRigOps().getTrailer1PermissibleFront();
    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //            totalGrossVal = rigOps.getVehicleAndPupTotalGross();
    //        }
    //        trailerRearGrossVal = rigOps.getTrailer1RearGross();
    //        trailerRearPermissibleVal = offerManager.getRigOps().getTrailer1PermissibleRear();
            
    //    }

        
    //    var frontDiffDisplay, rearDiffDisplay, totalDiffDisplay, trailerFrontDiffDisplay, trailerRearDiffDisplay, frontDiff, rearDiff, trailerFrontDiff, trailerRearDiff, totalDiff;
    //    var frontDisplayMask, rearDisplayMask, totalDisplayMask, trailerFrontDisplayMask, trailerRearDisplayMask;
    //    frontDiff = roundMassInCurrentMeasurementSystem(frontPermissibleVal) - roundMassInCurrentMeasurementSystem(frontGrossVal);
    //    frontDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-frontDiff, formattingPrecision);
    //    rearDiff = roundMassInCurrentMeasurementSystem(rearPermissibleVal) - roundMassInCurrentMeasurementSystem(rearGrossVal);
    //    rearDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-rearDiff, formattingPrecision);
    //    totalDiff = roundMassInCurrentMeasurementSystem(totalPermissibleVal) - roundMassInCurrentMeasurementSystem(totalGrossVal);
    //    totalDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-totalDiff, formattingPrecision);

    //    if (curTrailer1 !== null) {

    //        if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            trailerFrontDiff = roundMassInCurrentMeasurementSystem(trailerFrontPermissibleVal) - roundMassInCurrentMeasurementSystem(trailerFrontGrossVal);
    //            trailerFrontDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-trailerFrontDiff, formattingPrecision);
    //        }

    //        trailerRearDiff = roundMassInCurrentMeasurementSystem(trailerRearPermissibleVal) - roundMassInCurrentMeasurementSystem(trailerRearGrossVal);
    //        trailerRearDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-trailerRearDiff, formattingPrecision);
    //    }


    //    var frontAxleOverload, totalOverload, rearAxleOverload, trailerFrontOverload, trailerRearOverload, frontAxleOverloadDisplay, totalOverloadDisplay, rearAxleOverloadDisplay, trailerFrontOverloadDisplay, trailerRearOverloadDisplay;

    //    var frontAxleCompareVal, frontAxleRefVal, totalCompareVal, totalRefVal, rearAxleCompareVal, rearAxleRefVal, trailerRearCompareVal, trailerRearRefVal;

    //    var rowLabel = config.getTranslationText('288');

    //    frontAxleOverloadDisplay = frontDiffDisplay;
    //    totalOverloadDisplay = totalDiffDisplay;
    //    rearAxleOverloadDisplay = rearDiffDisplay;
        
    //    var highlightRearAxleAsPayloadLimitingAxle = false;
    //    var highlightFrontAxleAsPayloadLimitingAxle = false;
    //    var highlightTrailerRearAxleAsPayloadLimitingAxle = false;
    //    var highlightTrailerFrontAxleAsPayloadLimitingAxle = false;
    //    var highlightTotalAsPayloadLimitingFactor = false;

    //    if (curTrailer1 === null) {
    //        //frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
    //        //totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleTotalOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLETOTALOVERLOAD);
    //        //rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
            
    //        frontAxleOverload = rigOps.getVehicleFrontAxleOverload();
    //        totalOverload = rigOps.getVehicleTotalOverload();
    //        rearAxleOverload = rigOps.getVehicleRearAxleOverload();
            

    //        frontDisplayMask = frontAxleOverloadDisplay;
    //        //if (frontAxleOverload > 0) {
    //        //frontAxleOverloadDisplay += ' (' + applyRoundingForOverloadOverload(frontAxleOverload) + '%)';
    //        ////}
    //        //    totalDisplayMask = totalOverloadDisplay;
    //        ////if (totalOverload > 0) {
    //        //totalOverloadDisplay += ' (' + applyRoundingForOverloadOverload(totalOverload) + '%)';
    //        ////}
    //        //    rearDisplayMask = rearAxleOverloadDisplay;
    //        ////if (rearAxleOverload > 0) {
    //        //rearAxleOverloadDisplay += ' (' + applyRoundingForOverloadOverload(rearAxleOverload) + '%)';
    //        ////}
            
    //    } else {

    //        trailerRearOverloadDisplay = trailerRearDiffDisplay;

    //        if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
    //            frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload());
    //            totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndSemiOrInterlinkTotalOverload());
    //            rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload());
    //            trailerRearOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload());
    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            trailerFrontOverloadDisplay = trailerFrontDiffDisplay;

    //            frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
    //            totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTotalOverload());
    //            rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
    //            trailerFrontOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload());
    //            trailerRearOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTrailerRearAxleOverload());
    //        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //            frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupVehicleFrontAxleOverload());
    //            totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupTotalOverload());
    //            rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupVehicleRearAxleOverload());
    //            trailerRearOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupTrailerRearAxleOverload());
    //        }
            
            


            
    //        frontDisplayMask = frontAxleOverloadDisplay;
    //        ////if (frontAxleOverload > 0) {
    //        //    frontAxleOverloadDisplay += ' (' + frontAxleOverload + '%)';
    //        ////}
    //        //    totalDisplayMask = totalOverloadDisplay;
    //        ////if (totalOverload > 0) {
    //        //    totalOverloadDisplay += ' (' + totalOverload + '%)';
    //        ////}
    //        //    rearDisplayMask = rearAxleOverloadDisplay;
    //        ////if (rearAxleOverload > 0) {
    //        //    rearAxleOverloadDisplay += ' (' + rearAxleOverload + '%)';
    //        ////}
    //        //    trailerFrontDisplayMask = trailerFrontOverloadDisplay;
    //        ////if (trailerFrontOverload > 0) {
    //        //    trailerFrontOverloadDisplay += ' (' + trailerFrontOverload + '%)';
    //        ////}
    //        //    trailerRearDisplayMask = trailerRearOverloadDisplay;
    //        ////if (trailerRearOverload > 0) {
    //        //    trailerRearOverloadDisplay += ' (' + trailerRearOverload + '%)';
    //        ////}
            
    //    }
        
    //    if (Number((frontDiff).toFixed(2)) === 0) {
    //        highlightFrontAxleAsPayloadLimitingAxle = true;
    //    }
    //    if (Number((rearDiff).toFixed(2)) === 0) {
    //        highlightRearAxleAsPayloadLimitingAxle = true;
    //    }
    //    if (curTrailer1 !== null) {

    //        if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            if (Number((trailerFrontDiff).toFixed(2)) === 0) {
    //                highlightTrailerFrontAxleAsPayloadLimitingAxle = true;
    //            }
    //        }

    //        if (Number((trailerRearDiff).toFixed(2)) === 0) {
    //            highlightTrailerRearAxleAsPayloadLimitingAxle = true;
    //        }
    //    }
        
    //    if (Number((totalDiff).toFixed(2)) === 0) {
    //        highlightTotalAsPayloadLimitingFactor = true;
    //    }
        
    //    tableMeta.cols.Labels.cellData.Overload = { value: rowLabel, id: config.VALUE_TYPE.OVERLOADFRONT + '_label' };

    //    //var frontTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleOverload);
    //    //var frontTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleCompareVal, frontAxleRefVal);
    //    var frontTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.FRONT];
    //    if (frontTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightFrontAxleAsPayloadLimitingAxle === true) {
    //        frontTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.front.cellData.Overload = { value: frontAxleOverloadDisplay, label: rowLabel, colour: frontTableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? frontTableHighlightColour : undefined*/, displayMask: frontDisplayMask, bold: true };
    //    tableMeta.cols.front.cellData.Overload.id = config.VALUE_TYPE.OVERLOADFRONT;
    //    if (missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT] !== undefined) {
    //        tableMeta.cols.front.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT];
    //        tableMeta.cols.front.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    }

    //    if (hasPusherOrTagDown === true) {
    //        var grossFunctionToUse, rearRearAxlesGrossFunctionToUse; 
    //        grossFunctionToUse = function (axleNumber) {
    //            return curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() });
    //        };
    //        rearRearAxlesGrossFunctionToUse = function () {
    //            return curVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOps.getCombinationVehicleRearGross() });
    //        };
    //        generatePopulatedPusherTagAndRearConnectedOverloadCols('Overload', rowLabel, grossFunctionToUse, rearRearAxlesGrossFunctionToUse);
    //    } 

    //    //var rearTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload);
    //    //var rearTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleCompareVal, rearAxleRefVal);
    //    var rearTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.REAR];
    //    if (rearTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightRearAxleAsPayloadLimitingAxle === true) {
    //        rearTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.rear.cellData.Overload = { value: rearAxleOverloadDisplay, label: rowLabel, colour: rearTableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? rearTableHighlightColour : undefined*/, displayMask: rearDisplayMask, bold: true };
    //    tableMeta.cols.rear.cellData.Overload.id = config.VALUE_TYPE.OVERLOADREAR;
    //    if (missingValuesObject[config.VALUE_TYPE.OVERLOADREAR] !== undefined) {
    //        tableMeta.cols.rear.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADREAR];
    //        tableMeta.cols.rear.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    }

    //    if (curTrailer1 !== null) {

    //        if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //            //var trailer1FrontTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_FRONT, trailerFrontOverload);
    //            var trailer1FrontTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_FRONT];
    //            if (trailer1FrontTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightTrailerFrontAxleAsPayloadLimitingAxle === true) {
    //                trailer1FrontTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //            }
    //            tableMeta.cols.trailerFront.cellData.Overload = { value: trailerFrontOverloadDisplay, label: rowLabel, colour: trailer1FrontTableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? trailer1FrontTableHighlightColour : undefined*/, displayMask: trailerFrontDisplayMask, bold: true };
    //            tableMeta.cols.trailerFront.cellData.Overload.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSFRONTAXLESMASSOVERLOAD;
    //        }

    //        //var trailer1TableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_REAR, trailerRearOverload);
    //        var trailer1TableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_REAR];
    //        if (trailer1TableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightTrailerRearAxleAsPayloadLimitingAxle === true) {
    //            trailer1TableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //        }
    //        tableMeta.cols.trailerRear.cellData.Overload = { value: trailerRearOverloadDisplay, label: rowLabel, colour: trailer1TableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? trailer1TableHighlightColour : undefined*/, displayMask: trailerRearDisplayMask, bold: true };
    //        tableMeta.cols.trailerRear.cellData.Overload.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASSOVERLOAD;
    //    }

    //    //var totalTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalOverload);
    //    //var totalTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalCompareVal, totalRefVal);
    //    var totalTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TOTAL];
    //    if (totalTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightTotalAsPayloadLimitingFactor === true) {
    //        totalTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.total.cellData.Overload = { value: totalOverloadDisplay, label: rowLabel, colour: totalTableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? totalTableHighlightColour : undefined*/, displayMask: totalDisplayMask, bold: true };
    //    tableMeta.cols.total.cellData.Overload.id = config.VALUE_TYPE.OVERLOADTOTAL;
    //    if (missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL] !== undefined) {
    //        tableMeta.cols.total.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL];
    //        tableMeta.cols.total.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    }
    //}

    //function roundMassInCurrentMeasurementSystem(valToRound) {
    //    valToRound = valToRound * globals.user.getActiveMeasurementSystem().massConversion;

    //    return parseFloat(valToRound.toFixed(config.roundingPrecisionForTableAndWarnings));
    //}

    function setupTwoTrailerUnusedCapacityRow(combinationPermissibleValues) {

        if (!combinationPermissibleValues) {
            combinationPermissibleValues = rigOps.getCombinationPermissibleVals();
        }

        var frontGrossVal, rearGrossVal, totalGrossVal, trailer1GrossVal, trailer2GrossVal, frontPermissibleVal, rearPermissibleVal, totalPermissibleVal, trailer1PermissibleVal, trailer2PermissibleVal;

        var hasNonZeroPayload = false;
        if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
            hasNonZeroPayload = (((curTrailer1.getAccessoryHolder().getBody() !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) && curTrailer1.getPayloadHolder().getTotalMass() > 0) &&
                ((curTrailer2.getAccessoryHolder().getBody() !== null || curTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) && curTrailer2.getPayloadHolder().getTotalMass() > 0));
        } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            hasNonZeroPayload = (curVehicle.getAccessoryHolder().getBody() !== null && curVehicle.getPayloadHolder().getTotalMass() > 0) && (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            hasNonZeroPayload = (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
        }*/ //#unfinished, need to support other two trailer combinations here


        frontGrossVal = rigOps.getCombinationVehicleFrontGross();
        frontPermissibleVal = combinationPermissibleValues.vehicleFrontPermissible;//rigOps.getVehicleFrontPermissible();
        rearGrossVal = rigOps.getCombinationVehicleRearGross();
        rearPermissibleVal = combinationPermissibleValues.vehicleRearPermissible;//rigOps.getVehicleRearPermissible();
        totalPermissibleVal = combinationPermissibleValues.combinationTotalPermissible;//rigOps.getCombinationTotalPermissible();
        if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
            totalGrossVal = rigOps.getVehicleAndInterlinkAndSemiTotalGross();
        } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            totalGrossVal = rigOps.getVehicleAndDrawbarTotalGross();
            trailerFrontGrossVal = rigOps.getTrailer1FrontGross();
            trailerFrontPermissibleVal = offerManager.getRigOps().getTrailer1FrontPermissible();
        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            totalGrossVal = rigOps.getVehicleAndPupTotalGross();
        }*/ //#unfinished, need to support other two trailer combinations here

        trailer1GrossVal = rigOps.getTrailer1RearGross();
        trailer1PermissibleVal = combinationPermissibleValues.trailer1PermissibleRear;//rigOps.getTrailer1PermissibleRear();
        trailer2GrossVal = rigOps.getTrailer2RearGross();
        trailer2PermissibleVal = combinationPermissibleValues.trailer2PermissibleRear;//rigOps.getTrailer2PermissibleRear();


        var frontDiffDisplay, rearDiffDisplay, totalDiffDisplay, trailer1DiffDisplay, trailer2DiffDisplay, frontDiff, rearDiff, trailer1Diff, trailer2Diff, totalDiff;
        
        frontDiff = roundMassInCurrentMeasurementSystem(frontPermissibleVal) - roundMassInCurrentMeasurementSystem(frontGrossVal);
        frontDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(frontDiff, formattingPrecision);
        rearDiff = roundMassInCurrentMeasurementSystem(rearPermissibleVal) - roundMassInCurrentMeasurementSystem(rearGrossVal);
        rearDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(rearDiff, formattingPrecision);
        totalDiff = roundMassInCurrentMeasurementSystem(totalPermissibleVal) - roundMassInCurrentMeasurementSystem(totalGrossVal);
        totalDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(totalDiff, formattingPrecision);

        trailer1Diff = roundMassInCurrentMeasurementSystem(trailer1PermissibleVal) - roundMassInCurrentMeasurementSystem(trailer1GrossVal);
        trailer1DiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(trailer1Diff, formattingPrecision);
        trailer2Diff = roundMassInCurrentMeasurementSystem(trailer2PermissibleVal) - roundMassInCurrentMeasurementSystem(trailer2GrossVal);
        trailer2DiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(trailer2Diff, formattingPrecision);

        tableMeta.cols.Labels.cellData.UnusedCapacity = { value: config.getTranslationText('4309'), id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.UNUSEDCAPACITYLABEL };
        tableMeta.cols.front.cellData.UnusedCapacity = { value: frontDiffDisplay, id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.UNUSEDCAPACITYFRONT};
        
        if (hasPusherOrTagDown === true) {
            var grossFunctionToUse, rearRearAxlesGrossFunctionToUse;
            grossFunctionToUse = function (axleNumber) {
                return curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() })
            };
            rearRearAxlesGrossFunctionToUse = function () {
                return curVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOps.getCombinationVehicleRearGross() })
            };
            generatePopulatedPusherTagAndRearConnectedUnusedCapacityCols('UnusedCapacity', grossFunctionToUse, rearRearAxlesGrossFunctionToUse, combinationPermissibleValues);
        }

        tableMeta.cols.rear.cellData.UnusedCapacity = { value: rearDiffDisplay, id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.UNUSEDCAPACITYREAR };
        tableMeta.cols.trailer1.cellData.UnusedCapacity = { value: trailer1DiffDisplay, id: config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERREARAXLESMASSUNUSEDCAPACITY };
        tableMeta.cols.trailer2.cellData.UnusedCapacity = { value: trailer2DiffDisplay, id: config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERREARAXLESMASSUNUSEDCAPACITY };
        tableMeta.cols.total.cellData.UnusedCapacity = { value: totalDiffDisplay, id: config.VALUE_TYPE.UNUSEDCAPACITYTOTAL };
        
    }

    function setupTwoTrailerUtilisationRow() {



        var frontGrossVal, rearGrossVal, totalGrossVal, trailer1GrossVal, trailer2GrossVal, frontPermissibleVal, rearPermissibleVal, totalPermissibleVal, trailer1PermissibleVal, trailer2PermissibleVal;

        var hasNonZeroPayload = false;
        if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
            hasNonZeroPayload = (((curTrailer1.getAccessoryHolder().getBody() !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) && curTrailer1.getPayloadHolder().getTotalMass() > 0) &&
                ((curTrailer2.getAccessoryHolder().getBody() !== null || curTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) && curTrailer2.getPayloadHolder().getTotalMass() > 0));
        } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            hasNonZeroPayload = (curVehicle.getAccessoryHolder().getBody() !== null && curVehicle.getPayloadHolder().getTotalMass() > 0) && (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            hasNonZeroPayload = (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
        }*/ //#unfinished, need to support other two trailer combinations here


        


        var frontAxleOverload, totalOverload, rearAxleOverload, trailer1Overload, trailer2Overload, frontAxleOverloadDisplay, totalOverloadDisplay, rearAxleOverloadDisplay, trailer1OverloadDisplay, trailer2OverloadDisplay;



        var rowLabel = config.getTranslationText('4314');

        


        var highlightRearAxleAsPayloadLimitingAxle = false;
        var highlightFrontAxleAsPayloadLimitingAxle = false;
        var highlightTrailer1AxleAsPayloadLimitingAxle = false;
        var highlightTrailer2AxleAsPayloadLimitingAxle = false;
        var highlightTotalAsPayloadLimitingFactor = false;



        if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
            frontAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload());
            totalOverload = applyRoundingForOverloadOverload(100 + utils.returnValueOrZeroIfNanOrInfinity(rigOps.getVehicleAndInterlinkAndSemiTotalOverload()));
            rearAxleOverload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndInterlinkAndSemiVehicleRearAxleOverload());
            trailer1Overload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload());
            trailer2Overload = applyRoundingForOverloadOverload(100 + rigOps.getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload());


        } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailerFrontOverloadDisplay = trailerFrontDiffDisplay;

            frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
            totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTotalOverload());
            rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
            trailerFrontOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload());
            trailerRearOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTrailerRearAxleOverload());
        } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupVehicleFrontAxleOverload());
            totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupTotalOverload());
            rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupVehicleRearAxleOverload());
            trailerRearOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupTrailerRearAxleOverload());
        }*/   //#unfinished, need to support other two trailer combinations here





        
        
        frontAxleOverloadDisplay = frontAxleOverload + '%';
        totalOverloadDisplay = totalOverload + '%';
        rearAxleOverloadDisplay = rearAxleOverload + '%';
        trailer1OverloadDisplay = trailer1Overload + '%';
        trailer2OverloadDisplay = trailer2Overload + '%';
        
        if (!getHasAnyPusherTagOrRearConnectedColsOverload()) {
            //if (Number((frontDiff).toFixed(2)) === 0) {
            //    highlightFrontAxleAsPayloadLimitingAxle = true;
            //}
            //if (Number((rearDiff).toFixed(2)) === 0) {
            //    highlightRearAxleAsPayloadLimitingAxle = true;
            //}
            //if (Number((trailer1Diff).toFixed(2)) === 0) {
            //    highlightTrailer1AxleAsPayloadLimitingAxle = true;
            //}

            //if (Number((trailer2Diff).toFixed(2)) === 0) {
            //    highlightTrailer2AxleAsPayloadLimitingAxle = true;
            //}

            //if (Number((totalDiff).toFixed(2)) === 0) {
            //    highlightTotalAsPayloadLimitingFactor = true;
            //}
            if (Number(frontAxleOverload) === 100) {
                highlightFrontAxleAsPayloadLimitingAxle = true;
            }
            if (Number(rearAxleOverload) === 100) {
                highlightRearAxleAsPayloadLimitingAxle = true;
            }
            if (Number(trailer1Overload) === 100) {
                highlightTrailer1AxleAsPayloadLimitingAxle = true;
            }

            if (Number(trailer2Overload) === 100) {
                highlightTrailer2AxleAsPayloadLimitingAxle = true;
            }

            if (Number(totalOverload) === 100) {
                highlightTotalAsPayloadLimitingFactor = true;
            }
        }
        

        tableMeta.cols.Labels.cellData.Overload = { value: rowLabel, id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERLOADFRONT + '_label' };

        var frontTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.FRONT];
        if (frontTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightFrontAxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                frontTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                frontTableHighlightColour = undefined;
            }
            
        }
        tableMeta.cols.front.cellData.Overload = { value: frontAxleOverloadDisplay, colour: frontTableHighlightColour, bold: true, id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERLOADFRONT };
        if (missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT] !== undefined) {
            tableMeta.cols.front.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT];
            tableMeta.cols.front.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        }

        if (hasPusherOrTagDown === true) {
            generatePopulatedPusherTagAndRearConnectedOverloadCols('Overload', rowLabel, hasNonZeroPayload, hasOverloadedCol);
        }

        //var rearTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload);
        var rearTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.REAR];
        if (rearTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightRearAxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                rearTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                rearTableHighlightColour = undefined;
            }
            
        }
        tableMeta.cols.rear.cellData.Overload = { value: rearAxleOverloadDisplay, colour: rearTableHighlightColour, id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERLOADREAR };
        if (missingValuesObject[config.VALUE_TYPE.OVERLOADREAR] !== undefined) {
            tableMeta.cols.rear.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADREAR];
            tableMeta.cols.rear.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        }


        
        var trailer1TableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_1];
        if (trailer1TableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightTrailer1AxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                trailer1TableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                trailer1TableHighlightColour = undefined;
            }
            
        }
        tableMeta.cols.trailer1.cellData.Overload = { value: trailer1OverloadDisplay, colour: trailer1TableHighlightColour, bold: true, id: config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASSOVERLOAD };
        
        var trailer2TableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_2];
        if (trailer2TableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightTrailer2AxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                trailer2TableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                trailer2TableHighlightColour = undefined;
            }
            
        }
        tableMeta.cols.trailer2.cellData.Overload = { value: trailer2OverloadDisplay, colour: trailer2TableHighlightColour, bold: true, id: config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASSOVERLOAD };
        
        var totalTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TOTAL];
        if (totalTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
            if (hasNonZeroPayload === true && highlightTotalAsPayloadLimitingFactor === true && !hasOverloadedCol) {
                totalTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
            } else {
                totalTableHighlightColour = undefined;
            }
            
        }
        tableMeta.cols.total.cellData.Overload = { value: totalOverloadDisplay, colour: totalTableHighlightColour, bold: true, id: config.VALUE_TYPE.OVERLOADTOTAL };
        if (missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL] !== undefined) {
            tableMeta.cols.total.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL];
            tableMeta.cols.total.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
        }
    }

    //function setupTwoTrailerOverloadRow() {

        

    //    var frontGrossVal, rearGrossVal, totalGrossVal, trailer1GrossVal, trailer2GrossVal, frontPermissibleVal, rearPermissibleVal, totalPermissibleVal, trailer1PermissibleVal, trailer2PermissibleVal;

    //    var hasNonZeroPayload = false;
    //    if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
    //        hasNonZeroPayload = (((curTrailer1.getAccessoryHolder().getBody() !== null || curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) && curTrailer1.getPayloadHolder().getTotalMass() > 0) &&
    //                            ((curTrailer2.getAccessoryHolder().getBody() !== null || curTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) && curTrailer2.getPayloadHolder().getTotalMass() > 0));
    //    } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //        hasNonZeroPayload = (curVehicle.getAccessoryHolder().getBody() !== null && curVehicle.getPayloadHolder().getTotalMass() > 0) && (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
    //    } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //        hasNonZeroPayload = (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getPayloadHolder().getTotalMass() > 0);
    //    }*/ //#unfinished, need to support other two trailer combinations here


    //    frontGrossVal = rigOps.getCombinationVehicleFrontGross();
    //    //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleFront() > 0) {
    //    //    frontPermissibleVal = vehicle.getPermissibleFront();
    //    //} else {
    //    frontPermissibleVal = rigOps.getVehicleFrontPermissible();
    //    //}

    //    rearGrossVal = rigOps.getCombinationVehicleRearGross();
    //    //if (vehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE && vehicle.getPermissibleRear() > 0) {
    //    //    rearPermissibleVal = vehicle.getPermissibleRear();
    //    //} else {
    //    rearPermissibleVal = rigOps.getVehicleRearPermissible();
    //    //}

    //    totalPermissibleVal = rigOps.getCombinationTotalPermissible();
    //    if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
    //        totalGrossVal = rigOps.getVehicleAndInterlinkAndSemiTotalGross();
    //    } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //        totalGrossVal = rigOps.getVehicleAndDrawbarTotalGross();
    //        trailerFrontGrossVal = rigOps.getTrailer1FrontGross();
    //        trailerFrontPermissibleVal = offerManager.getRigOps().getTrailer1FrontPermissible();
    //    } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //        totalGrossVal = rigOps.getVehicleAndPupTotalGross();
    //    }*/ //#unfinished, need to support other two trailer combinations here

    //    trailer1GrossVal = rigOps.getTrailer1RearGross();
    //    trailer1PermissibleVal = offerManager.getRigOps().getTrailer1PermissibleRear();
    //    trailer2GrossVal = rigOps.getTrailer2RearGross();
    //    trailer2PermissibleVal = offerManager.getRigOps().getTrailer2PermissibleRear();


    //    var frontDiffDisplay, rearDiffDisplay, totalDiffDisplay, trailer1DiffDisplay, trailer2DiffDisplay, frontDiff, rearDiff, trailer1Diff, trailer2Diff, totalDiff;
    //    var frontDisplayMask, rearDisplayMask, totalDisplayMask, trailer1DisplayMask, trailer2DisplayMask;
    //    frontDiff = roundMassInCurrentMeasurementSystem(frontPermissibleVal) - roundMassInCurrentMeasurementSystem(frontGrossVal);
    //    frontDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-frontDiff, formattingPrecision);
    //    rearDiff = roundMassInCurrentMeasurementSystem(rearPermissibleVal) - roundMassInCurrentMeasurementSystem(rearGrossVal);
    //    rearDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-rearDiff, formattingPrecision);
    //    totalDiff = roundMassInCurrentMeasurementSystem(totalPermissibleVal) - roundMassInCurrentMeasurementSystem(totalGrossVal);
    //    totalDiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-totalDiff, formattingPrecision);

    //    trailer1Diff = roundMassInCurrentMeasurementSystem(trailer1PermissibleVal) - roundMassInCurrentMeasurementSystem(trailer1GrossVal);
    //    trailer1DiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-trailer1Diff, formattingPrecision);
    //    trailer2Diff = roundMassInCurrentMeasurementSystem(trailer2PermissibleVal) - roundMassInCurrentMeasurementSystem(trailer2GrossVal);
    //    trailer2DiffDisplay = offerManager.getUIFormattedMassValueIgnoreMeasurementSystem(-trailer2Diff, formattingPrecision);


    //    var frontAxleOverload, totalOverload, rearAxleOverload, trailer1Overload, trailer2Overload, frontAxleOverloadDisplay, totalOverloadDisplay, rearAxleOverloadDisplay, trailer1OverloadDisplay, trailer2OverloadDisplay;



    //    var rowLabel = config.getTranslationText('2775');

    //    frontAxleOverloadDisplay = frontDiffDisplay;
    //    totalOverloadDisplay = totalDiffDisplay;
    //    rearAxleOverloadDisplay = rearDiffDisplay;
    //    trailer1OverloadDisplay = trailer1DiffDisplay;
    //    trailer2OverloadDisplay = trailer2DiffDisplay;


    //    var highlightRearAxleAsPayloadLimitingAxle = false;
    //    var highlightFrontAxleAsPayloadLimitingAxle = false;
    //    var highlightTrailer1AxleAsPayloadLimitingAxle = false;
    //    var highlightTrailer2AxleAsPayloadLimitingAxle = false;
    //    var highlightTotalAsPayloadLimitingFactor = false;

        

    //    if (curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && curTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
    //        frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload());
    //        totalOverload = applyRoundingForOverloadOverload(utils.returnValueOrZeroIfNanOrInfinity(rigOps.getVehicleAndInterlinkAndSemiTotalOverload()));
    //        rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndInterlinkAndSemiVehicleRearAxleOverload());
    //        trailer1Overload = applyRoundingForOverloadOverload(rigOps.getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload());
    //        trailer2Overload = applyRoundingForOverloadOverload(rigOps.getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload());


    //    } /*else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
    //        trailerFrontOverloadDisplay = trailerFrontDiffDisplay;

    //        frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleFrontAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEFRONTAXLEOVERLOAD);
    //        totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTotalOverload());
    //        rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleRearAxleOverload());//offerManager.getValue(config.VALUE_TYPE.VEHICLEREARAXLEOVERLOAD);
    //        trailerFrontOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTrailerFrontAxleOverload());
    //        trailerRearOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndDrawbarTrailerRearAxleOverload());
    //    } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
    //        frontAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupVehicleFrontAxleOverload());
    //        totalOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupTotalOverload());
    //        rearAxleOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupVehicleRearAxleOverload());
    //        trailerRearOverload = applyRoundingForOverloadOverload(rigOps.getVehicleAndPupTrailerRearAxleOverload());
    //    }*/   //#unfinished, need to support other two trailer combinations here





    //    frontDisplayMask = frontAxleOverloadDisplay;
    //    //if (frontAxleOverload > 0) {
    //    //frontAxleOverloadDisplay += ' (' + frontAxleOverload + '%)';
    //    ////}
    //    //totalDisplayMask = totalOverloadDisplay;
    //    ////if (totalOverload > 0) {
    //    //totalOverloadDisplay += ' (' + totalOverload + '%)';
    //    ////}
    //    //rearDisplayMask = rearAxleOverloadDisplay;
    //    ////if (rearAxleOverload > 0) {
    //    //rearAxleOverloadDisplay += ' (' + rearAxleOverload + '%)';
    //    ////}
    //    //trailer1DisplayMask = trailer1OverloadDisplay;
    //    ////if (trailerFrontOverload > 0) {
    //    //trailer1OverloadDisplay += ' (' + trailer1Overload + '%)';
    //    ////}
    //    //trailer2DisplayMask = trailer2OverloadDisplay;
    //    ////if (trailerRearOverload > 0) {
    //    //trailer2OverloadDisplay += ' (' + trailer2Overload + '%)';
    //    //}

    //    if (Number((frontDiff).toFixed(2)) === 0) {
    //        highlightFrontAxleAsPayloadLimitingAxle = true;
    //    }
    //    if (Number((rearDiff).toFixed(2)) === 0) {
    //        highlightRearAxleAsPayloadLimitingAxle = true;
    //    }
    //    if (Number((trailer1Diff).toFixed(2)) === 0) {
    //        highlightTrailer1AxleAsPayloadLimitingAxle = true;
    //    }

    //    if (Number((trailer2Diff).toFixed(2)) === 0) {
    //        highlightTrailer2AxleAsPayloadLimitingAxle = true;
    //    }

    //    if (Number((totalDiff).toFixed(2)) === 0) {
    //        highlightTotalAsPayloadLimitingFactor = true;
    //    }

    //    tableMeta.cols.Labels.cellData.Overload = { value: rowLabel, id: config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERLOADFRONT + '_label' };

    //    //var frontTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.FRONT, frontAxleOverload);
    //    var frontTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.FRONT];
    //    if (frontTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightFrontAxleAsPayloadLimitingAxle === true) {
    //        frontTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.front.cellData.Overload = { value: frontAxleOverloadDisplay, label: rowLabel, colour: frontTableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? frontTableHighlightColour : undefined*/, displayMask: frontDisplayMask, bold: true };
    //    tableMeta.cols.front.cellData.Overload.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERLOADFRONT;
    //    if (missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT] !== undefined) {
    //        tableMeta.cols.front.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADFRONT];
    //        tableMeta.cols.front.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    }

    //    if (hasPusherOrTagDown === true) {
    //        var grossFunctionToUse, rearRearAxlesGrossFunctionToUse;
    //        grossFunctionToUse = function (axleNumber) {
    //            return curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() })
    //        };
    //        rearRearAxlesGrossFunctionToUse = function () {
    //            return curVehicle.getRearRearAxlesDistribution({ rearMassVal: rigOps.getCombinationVehicleRearGross() })
    //        };
    //        generatePopulatedPusherTagAndRearConnectedOverloadCols('Overload', rowLabel, grossFunctionToUse, rearRearAxlesGrossFunctionToUse);
    //    }

    //    //var rearTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.REAR, rearAxleOverload);
    //    var rearTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.REAR];
    //    if (rearTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightRearAxleAsPayloadLimitingAxle === true) {
    //        rearTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.rear.cellData.Overload = { value: rearAxleOverloadDisplay, label: rowLabel, colour: rearTableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? rearTableHighlightColour : undefined*/, displayMask: rearDisplayMask, bold: true };
    //    tableMeta.cols.rear.cellData.Overload.id = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.VALUE_TYPE.OVERLOADREAR;
    //    if (missingValuesObject[config.VALUE_TYPE.OVERLOADREAR] !== undefined) {
    //        tableMeta.cols.rear.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADREAR];
    //        tableMeta.cols.rear.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    }


    //    //var trailer1TableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_1, trailer1Overload);
    //    var trailer1TableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_1];
    //    if (trailer1TableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightTrailer1AxleAsPayloadLimitingAxle === true) {
    //        trailer1TableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.trailer1.cellData.Overload = { value: trailer1OverloadDisplay, label: rowLabel, colour: trailer1TableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? trailer1TableHighlightColour : undefined*/, displayMask: trailer1DisplayMask, bold: true };
    //    tableMeta.cols.trailer1.cellData.Overload.id = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASSOVERLOAD;

    //    //var trailer2TableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TRAILER_2, trailer2Overload);
    //    var trailer2TableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TRAILER_2];
    //    if (trailer2TableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightTrailer2AxleAsPayloadLimitingAxle === true) {
    //        trailer2TableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.trailer2.cellData.Overload = { value: trailer2OverloadDisplay, label: rowLabel, colour: trailer2TableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? trailer1TableHighlightColour : undefined*/, displayMask: trailer2DisplayMask, bold: true };
    //    tableMeta.cols.trailer2.cellData.Overload.id = config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERMANUFACTURERSREARAXLESMASSOVERLOAD;

    //    //var totalTableHighlightColour = offerManager.getTableCellHighlightColour(config.TABLE_COLUMNS.TOTAL, totalOverload);
    //    var totalTableHighlightColour = overloadingColColours[config.TABLE_COLUMNS.TOTAL];
    //    if (totalTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK && hasNonZeroPayload === true && highlightTotalAsPayloadLimitingFactor === true) {
    //        totalTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
    //    }
    //    tableMeta.cols.total.cellData.Overload = { value: totalOverloadDisplay, label: rowLabel, colour: totalTableHighlightColour /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? totalTableHighlightColour : undefined*/, displayMask: totalDisplayMask, bold: true };
    //    tableMeta.cols.total.cellData.Overload.id = config.VALUE_TYPE.OVERLOADTOTAL;
    //    if (missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL] !== undefined) {
    //        tableMeta.cols.total.cellData.Overload.missingValMeta = missingValuesObject[config.VALUE_TYPE.OVERLOADTOTAL];
    //        tableMeta.cols.total.cellData.Overload.colour = config.TABLE_HIGHLIGHT_COLOURS.CANT_CALCULATE;
    //    }
    //}


    function generatePopulatedPusherTagAndRearConnectedUnusedCapacityCols(rowKey, tagOrPusherAxleGrossFunc, rearOnlyGrossFunc, combinationPermissibleValues) {

        var numAxlesBeforeTags = curVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags();
        var numAxlesBeforePushers = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

        Object.keys(tableMeta.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                var axleNumber, valToUse = '', permissibleValToUse, grossValToUse, diffDisplay;
                
                var rowId;
                if (key.indexOf('Pusher') !== -1) {

                    var pusherNumber = key.split('_')[1];
                    axleNumber = key.split('_')[2];
                    rowId = config.VALUE_TYPE.AXLEUNUSEDCAPACITYPUSHER + pusherNumber;
                    //valToUse = offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADPUSHER, { axleNumber: Number(axleNumber), pusherNumber: (Number(axleNumber) - numAxlesBeforePushers) });
                    permissibleValToUse = combinationPermissibleValues['vehiclePusher' + (Number(axleNumber) - numAxlesBeforePushers)];//rigOps.getPusherOrTagPermissibleVal(Number(axleNumber), (Number(axleNumber) - numAxlesBeforePushers), config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);//offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARPUSHER' + pusherNumber);
                    grossValToUse = tagOrPusherAxleGrossFunc(Number(axleNumber));

                } else if (key.indexOf('Connected') !== -1) {
                    rowId = config.VALUE_TYPE.AXLESUNUSEDCAPACITYREARREAR;
                    //valToUse = offerManager.getValue(config.VALUE_TYPE.AXLESOVERLOADREARREAR);
                    permissibleValToUse = combinationPermissibleValues.vehicleRearRearOnly;//rigOps.getRearRearAxlePermissibleVal();//offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARREARONLY');
                    grossValToUse = rearOnlyGrossFunc();
                } else if (key.indexOf('Tag') !== -1) {

                    var tagNumber = key.split('_')[1];
                    axleNumber = key.split('_')[2];
                    rowId = config.VALUE_TYPE.AXLEUNUSEDCAPACITYTAG + tagNumber;
                    //valToUse = offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADTAG, { axleNumber: Number(axleNumber), tagNumber: (Number(axleNumber) - numAxlesBeforeTags) });
                    permissibleValToUse = combinationPermissibleValues['vehicleTag' + (Number(axleNumber) - numAxlesBeforeTags)];//rigOps.getPusherOrTagPermissibleVal(Number(axleNumber), (Number(axleNumber) - numAxlesBeforeTags), config.VEHICLE_AXLE_POSITION_TYPES.TAG);//offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARTAG' + tagNumber);
                    grossValToUse = tagOrPusherAxleGrossFunc(Number(axleNumber));
                }
                //if (valToUse !== null) {
                //    valToUse = applyRoundingForOverloadOverload(valToUse);
                //}
                
                
                diffDisplay = offerManager.getUIFormattedMassValue((permissibleValToUse - grossValToUse), formattingPrecision);
                
                tableMeta.cols[key].cellData[rowKey] = { value: diffDisplay, id: rowId };
                
            }
        });
    }

    function getHasAnyPusherTagOrRearConnectedColsOverload() {
        var hasAnyPusherTagAndRearConnectedColsOverload = false;
        var highlightColour;
        Object.keys(tableMeta.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                highlightColour = overloadingColColours[key];
                if (highlightColour !== config.DRAWING_COLOURS.LIGHT_GREEN) {
                    hasAnyPusherTagAndRearConnectedColsOverload = true;
                }
            }
        });
        return hasAnyPusherTagAndRearConnectedColsOverload;
    }

    function generatePopulatedPusherTagAndRearConnectedOverloadCols(rowKey, rowLabel, hasNonZeroPayload, hasOverloadedCol) {

        var numAxlesBeforeTags = curVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags();
        var numAxlesBeforePushers = curVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

        Object.keys(tableMeta.cols).forEach(function (key) {
            if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1 || key.indexOf('Connected') !== -1) {
                var axleNumber, valToUse = '', permissibleValToUse, grossValToUse, diffDisplay;
                var highlightColourIfAny;
                var rowId;
                var highlightAsLimitingAxle = false;
                if (key.indexOf('Pusher') !== -1) {

                    var pusherNumber = key.split('_')[1];
                    axleNumber = key.split('_')[2];
                    rowId = config.VALUE_TYPE.AXLEOVERLOADPUSHER + pusherNumber; 
                    valToUse = 100 + offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADPUSHER, { axleNumber: Number(axleNumber), pusherNumber: (Number(axleNumber) - numAxlesBeforePushers) });
                    //permissibleValToUse = offerManager.getRigOps().getPusherOrTagPermissibleVal(Number(axleNumber), (Number(axleNumber) - numAxlesBeforePushers), config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);//offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARPUSHER' + pusherNumber);
                    //grossValToUse = tagOrPusherAxleGrossFunc(Number(axleNumber));
                    
                } else if (key.indexOf('Connected') !== -1) {
                    rowId = config.VALUE_TYPE.AXLESOVERLOADREARREAR;
                    valToUse = 100 + offerManager.getValue(config.VALUE_TYPE.AXLESOVERLOADREARREAR);
                    //permissibleValToUse = offerManager.getRigOps().getRearRearAxlePermissibleVal();//offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARREARONLY');
                    //grossValToUse = rearOnlyGrossFunc();
                } else if (key.indexOf('Tag') !== -1) {

                    var tagNumber = key.split('_')[1];
                    axleNumber = key.split('_')[2];
                    rowId = config.VALUE_TYPE.AXLEOVERLOADTAG + tagNumber;
                    valToUse = 100 + offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADTAG, { axleNumber: Number(axleNumber), tagNumber: (Number(axleNumber) - numAxlesBeforeTags) });
                    //permissibleValToUse = offerManager.getRigOps().getPusherOrTagPermissibleVal(Number(axleNumber), (Number(axleNumber) - numAxlesBeforeTags), config.VEHICLE_AXLE_POSITION_TYPES.TAG);//offerManager.evaluateLegislation('VEHICLEPERMISSIBLEREARTAG' + tagNumber);
                    //grossValToUse = tagOrPusherAxleGrossFunc(Number(axleNumber));
                }
                if (valToUse !== null) {
                    valToUse = applyRoundingForOverloadOverload(valToUse);
                    if (Number(valToUse) === 100) {
                        highlightAsLimitingAxle = true;
                    }
                }
                //highlightColourIfAny = offerManager.getTableCellHighlightColour(key, valToUse);
                
                /*
                 if (frontTableHighlightColour === config.TABLE_HIGHLIGHT_COLOURS.OK) {
                    if (hasNonZeroPayload === true && highlightFrontAxleAsPayloadLimitingAxle === true && !hasOverloadedCol) {
                        frontTableHighlightColour = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
                    } else {
                        frontTableHighlightColour = undefined;
                    }
                }
                 */

                if (overloadingColColours[key] !== config.DRAWING_COLOURS.LIGHT_GREEN) {
                    highlightColourIfAny = overloadingColColours[key];                        
                } else {
                    if (hasNonZeroPayload === true && highlightAsLimitingAxle === true && !hasOverloadedCol) {
                        highlightColourIfAny = config.TABLE_HIGHLIGHT_COLOURS.PAYLOAD;
                    }
                }
                //diffDisplay = offerManager.getUIFormattedMassValue((permissibleValToUse - grossValToUse), formattingPrecision);
                
                //if (valToUse > 0) {
                    //diffDisplay += ' (' + valToUse + ' %)'
                //}
                tableMeta.cols[key].cellData[rowKey] = { value: valToUse + '%', label: rowLabel, colour: highlightColourIfAny /*!== config.TABLE_HIGHLIGHT_COLOURS.OK ? highlightColourIfAny : undefined*/, bold: true };
                tableMeta.cols[key].cellData[rowKey].id = rowId;
            }
        });
    }

    /*
if (key.indexOf('Pusher') !== -1 || key.indexOf('Tag') !== -1) {
                    axleNumber = key.split('_')[2];
                    axleTypeNumber = key.split('_')[1];
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = tagOrPusherAxleGrossFunc(Number(axleNumber));
                    }
                    
                    var axleTypeNum = key.split('_')[1];
                    if (key.indexOf('Pusher') !== -1) {
                        overloadValToUse = offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADPUSHER, { axleNumber: Number(axleNumber), pusherNumber: axleTypeNum });
                        rowId = config.AXLEGROSSPUSHER + axleTypeNumber;
                    } else {
                        overloadValToUse = offerManager.getValue(config.VALUE_TYPE.AXLEOVERLOADTAG, { axleNumber: Number(axleNumber), tagNumber: axleTypeNum });
                        rowId = config.AXLEGROSSTAG + axleTypeNumber;
                    }
                } else if (key.indexOf('Connected') !== -1) {
                    if (isTrialUser) {
                        valToUse = trialUserObfuscationText;
                    } else {
                        valToUse = rearOnlyGrossFunc();
                    }

                    overloadValToUse = offerManager.getValue(config.VALUE_TYPE.AXLESOVERLOADREARREAR);
                    rowId = config.AXLEGROSSREARREAR;
                }
*/

    
}

/*

if (writeToLocalStorage === true) {
        stage.toDataURL({
            mimeType: "image/jpeg",
            quality: 1.0,
            callback: function (dataUrl) {
                //write to localStorage
                localStorage.setItem("configurationDrawing", dataUrl);
                dataManager.persist(OPERATION.ConfigDrawing, dataUrl, offer.vehicleId() + "_configurationDrawing")
                    .then(function () {
                        if (dfdImage !== undefined) {
                            dfdImage.resolve(dataUrl);
                        }
                    });
            }
        });
    }


*/
function applyRoundingForOverloadOverload(overloadVal) {

    var valToUse = utils.returnValueOrZeroIfNanOrInfinity(overloadVal);

    return valToUse.toFixed(1);
}

function generateOriginLines() {

    var isOriginStep = curPreviewType === config.PREVIEW_TYPE.ADJUST_ORIGIN; 

    var tailLength = (previewWindowBuffer/scalingFactor) * 0.5;

    var markingSize = 3 / scalingFactor;

    var drawingOriginHorizontalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var drawingOriginVerticalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType()  + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;

    var originAdjustmentHorizontalAxis = drawingOriginHorizontalOffset >= 0 ? 0 : drawingOriginHorizontalOffset;
    var originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? 0 : drawingOriginVerticalOffset;

    ////vertical axis line
    //var newLine = new Line(previewOriginBufferX - originAdjustmentHorizontalAxis, previewOriginBufferY - tailLength, previewOriginBufferX - originAdjustmentHorizontalAxis, previewOriginBufferY - tailLength + ((oH / scalingFactor) - tailLength * 2)).setDrawingGroupName('previewOriginLines');
    //drawingObjectsArray.push(newLine);

    ////horizontal axis line
    //newLine = new Line(previewOriginBufferX - tailLength, previewOriginBufferY - originAdjustmentVerticalAxis, previewOriginBufferX - tailLength + ((oW / scalingFactor) - tailLength * 2), previewOriginBufferY - originAdjustmentVerticalAxis).setDrawingGroupName('previewOriginLines');
    //drawingObjectsArray.push(newLine);

    //vertical axis line
    var newLine = new Line(-(originAdjustmentHorizontalAxis), -tailLength, -(originAdjustmentHorizontalAxis), (((oH-previewWindowBuffer*2)/scalingFactor) + tailLength)).setDrawingGroupName('previewOriginLines');
    if (isOriginStep) {
        newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    }
    drawingObjectsArray.push(newLine);

    var stepLength = 100;
    var verticalStepCounter = -(Math.abs(originAdjustmentVerticalAxis) / stepLength);
    

    var numPositiveMarkings = Math.floor((((oH - previewWindowBuffer * 2) / scalingFactor) + tailLength + originAdjustmentVerticalAxis) / stepLength);
    var i;
    for (i = 1; i <= numPositiveMarkings; i++) {
        newLine = new Line(-(originAdjustmentHorizontalAxis) - markingSize, -(originAdjustmentVerticalAxis) + (i * stepLength), -(originAdjustmentHorizontalAxis) + markingSize, -(originAdjustmentVerticalAxis) + (i * stepLength)).setDrawingGroupName('previewOriginLines');
        if (isOriginStep) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }

    var numNegativeMarkings = Math.floor((originAdjustmentVerticalAxis + previewWindowBuffer / scalingFactor) / stepLength);

    for (i = -1; i >= numNegativeMarkings; i--) {
        newLine = new Line(-(originAdjustmentHorizontalAxis) - markingSize, -(originAdjustmentVerticalAxis) + (i * stepLength), -(originAdjustmentHorizontalAxis) + markingSize, -(originAdjustmentVerticalAxis) + (i * stepLength)).setDrawingGroupName('previewOriginLines');
        if (isOriginStep) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }

    //horizontal axis line
    newLine = new Line(-tailLength, -(originAdjustmentVerticalAxis), (((oW-previewWindowBuffer*2)/scalingFactor) + tailLength), -(originAdjustmentVerticalAxis)).setDrawingGroupName('previewOriginLines');
    if (isOriginStep) {
        newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    }
    drawingObjectsArray.push(newLine);

    numPositiveMarkings = Math.floor((((oW - previewWindowBuffer * 2) / scalingFactor) + tailLength + originAdjustmentHorizontalAxis) / stepLength);

    for (i = 1; i <= numPositiveMarkings; i++) {
        newLine = new Line(-(originAdjustmentHorizontalAxis) + (i * stepLength), -(originAdjustmentVerticalAxis) - markingSize, -(originAdjustmentHorizontalAxis) + (i * stepLength), -(originAdjustmentVerticalAxis) + markingSize).setDrawingGroupName('previewOriginLines');
        if (isOriginStep) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }

    numNegativeMarkings = Math.floor((originAdjustmentHorizontalAxis + previewWindowBuffer / scalingFactor) / stepLength);

    for (i = -1; i >= numNegativeMarkings; i--) {
        newLine = new Line(-(originAdjustmentHorizontalAxis) + (i * stepLength), -(originAdjustmentVerticalAxis) - markingSize, -(originAdjustmentHorizontalAxis) + (i * stepLength), -(originAdjustmentVerticalAxis) + markingSize).setDrawingGroupName('previewOriginLines');
        if (isOriginStep) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }

    var textColour = isOriginStep ? config.DRAWING_COLOURS.ORANGE : undefined;

    var newText = new Text('x', -(tailLength + tailLength / 2), -(originAdjustmentVerticalAxis), undefined, textColour).setDrawingGroupName('previewOriginLines');
    drawingObjectsArray.push(newText);

    newText = new Text('y', -(originAdjustmentHorizontalAxis), -(tailLength + tailLength / 2) + 2, undefined, textColour).setDrawingGroupName('previewOriginLines');
    drawingObjectsArray.push(newText);

}

function generateScaleAspectRatioLines() { 

    var isScalingStep = curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE;

    var tailLength = (previewWindowBuffer / scalingFactor) * 0.5;

    var drawingOriginHorizontalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var drawingOriginVerticalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;

    //var originAdjustmentHorizontalAxis = drawingOriginHorizontalOffset >= 0 ? drawingOriginHorizontalOffset : 0;
    //var originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? drawingOriginVerticalOffset : 0;
    var originAdjustmentHorizontalAxis = drawingOriginHorizontalOffset >= 0 ?  0 : Math.abs(drawingOriginHorizontalOffset);
    var originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? 0 : Math.abs(drawingOriginVerticalOffset);

    //var scaleLineWidth, scaleLineHeight;


    //if (offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.CRANEHEIGHT).override() && offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.CRANELENGTH).override()) {
    //    //if (curRenderItem.getLength() > curRenderItem.getHeight()) {
    //    //    itemScalingFactor = (curRenderItem.getLength() / itemDrawingMeta.widthInDrawingUnits).toFixed(8);
    //    //} else {
    //    //    itemScalingFactor = (curRenderItem.getHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
    //    //}
    //    if (scalingDimensionPlane === config.SCALING_PLANE.HORIZONTAL) {
    //        if (curRenderItem.getLength() > curRenderItem.getHeight()) {
    //            //itemScalingFactor = (curRenderItem.getLength() / itemDrawingMeta.widthInDrawingUnits).toFixed(8);
    //            scaleLineWidth = curRenderItem.getLength();
    //            scaleLineHeight = curRenderItem.getHeight();
    //        } else {
    //            scaleLineWidth = itemScalingFactor * itemDrawingMeta.widthInDrawingUnits;
    //            //itemScalingFactor = (getAspectScalingWidth() / itemDrawingMeta.widthInDrawingUnits).toFixed(8);
    //            scaleLineHeight = curRenderItem.getHeight();
    //        }

    //    } else {
    //        if (curRenderItem.getLength() > curRenderItem.getHeight()) {
    //            scaleLineHeight = itemScalingFactor * itemDrawingMeta.heightInDrawingUnits;
    //            //itemScalingFactor = (getAspectScalingHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
    //            scaleLineWidth = curRenderItem.getLength();
    //        } else {
    //            //itemScalingFactor = (curRenderItem.getHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
    //            scaleLineHeight = curRenderItem.getHeight();
    //            scaleLineWidth = curRenderItem.getLength();
    //        }
    //        //tempScalingFactor = (mW / overallScalingHeight).toFixed(8);
    //    }
    //    //itemScalingFactor = (curRenderItem.getHeight() / itemDrawingMeta.heightInDrawingUnits).toFixed(8);
    //} else {
    //    scaleLineWidth = curRenderItem.getLength();
    //    scaleLineHeight = curRenderItem.getHeight();
    //}
    //need to either finish this part of the refactor above or reverse the scaleLineWidth and scaleLineHeight change

    var dash = Math.min(Math.max(originAdjustmentHorizontalAxis + curRenderItemSize.getLength(), originAdjustmentVerticalAxis + curRenderItemSize.getHeight()) / 15, 100);
    var gap = dash * 0.6;
    

    ////vertical axis line left
    //var newLine = new Line(originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis, originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis + curRenderItem.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    ////horizontal axis line bottom
    //newLine = new Line(originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis, originAdjustmentHorizontalAxis + curRenderItem.getLength(), originAdjustmentVerticalAxis).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    ////vertical axis line right
    //newLine = new Line(originAdjustmentHorizontalAxis + curRenderItem.getLength(), originAdjustmentVerticalAxis, originAdjustmentHorizontalAxis + curRenderItem.getLength(), originAdjustmentVerticalAxis + curRenderItem.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    ////horizontal axis line top
    //newLine = new Line(originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis + curRenderItem.getHeight(), originAdjustmentHorizontalAxis + curRenderItem.getLength(), originAdjustmentVerticalAxis + curRenderItem.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    //var textColour = curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE ? config.DRAWING_COLOURS.ORANGE : undefined;

    //var newText = new Text(curRenderItem.getHeight(), originAdjustmentHorizontalAxis + curRenderItem.getLength() + 15 / scalingFactor, originAdjustmentVerticalAxis + curRenderItem.getHeight() / 2, offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    //newText.setRotationAngle(270);
    //drawingObjectsArray.push(newText);

    //newText = new Text(curRenderItem.getLength(), originAdjustmentHorizontalAxis + curRenderItem.getLength() / 2, originAdjustmentVerticalAxis + curRenderItem.getHeight() + 15 / scalingFactor, offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    //drawingObjectsArray.push(newText);


    ////vertical axis line left
    //var newLine = new Line(0, 0, 0, originAdjustmentVerticalAxis + curRenderItem.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    ////horizontal axis line bottom
    //newLine = new Line(0, 0, originAdjustmentHorizontalAxis + curRenderItem.getLength(), 0).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    ////vertical axis line right
    //newLine = new Line(originAdjustmentHorizontalAxis + curRenderItem.getLength(), 0, originAdjustmentHorizontalAxis + curRenderItem.getLength(), originAdjustmentVerticalAxis + curRenderItem.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    ////horizontal axis line top
    //newLine = new Line(0, originAdjustmentVerticalAxis + curRenderItem.getHeight(), originAdjustmentHorizontalAxis + curRenderItem.getLength(), originAdjustmentVerticalAxis + curRenderItem.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    //if (isScalingStep) {
    //    newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    //}
    //newLine.setDashed(true);
    //newLine.setDashLengths(dash, gap);
    //drawingObjectsArray.push(newLine);

    //var textColour = curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE ? config.DRAWING_COLOURS.ORANGE : undefined;

    //var newText = new Text(originAdjustmentVerticalAxis + curRenderItem.getHeight(), originAdjustmentHorizontalAxis + curRenderItem.getLength() + 15 / scalingFactor, (originAdjustmentVerticalAxis + curRenderItem.getHeight()) / 2, offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    //newText.setRotationAngle(270);
    //drawingObjectsArray.push(newText);

    //newText = new Text(originAdjustmentHorizontalAxis + curRenderItem.getLength(), (originAdjustmentHorizontalAxis + curRenderItem.getLength()) / 2, (originAdjustmentVerticalAxis + curRenderItem.getHeight()) + 15 / scalingFactor, offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    //drawingObjectsArray.push(newText);

    //vertical axis line left
    var newLine = new Line(originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis, originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis + curRenderItemSize.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    if (isScalingStep) {
        newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    }
    newLine.setDashed(true);
    newLine.setDashLengths(dash, gap);
    drawingObjectsArray.push(newLine);

    //horizontal axis line bottom
    newLine = new Line(originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis, originAdjustmentHorizontalAxis + curRenderItemSize.getLength(), originAdjustmentVerticalAxis).setDrawingGroupName('scaleAspectRatioLines');
    if (isScalingStep) {
        newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    }
    newLine.setDashed(true);
    newLine.setDashLengths(dash, gap);
    drawingObjectsArray.push(newLine);

    //vertical axis line right
    newLine = new Line(originAdjustmentHorizontalAxis + curRenderItemSize.getLength(), originAdjustmentVerticalAxis, originAdjustmentHorizontalAxis + curRenderItemSize.getLength(), originAdjustmentVerticalAxis + curRenderItemSize.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    if (isScalingStep) {
        newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    }
    newLine.setDashed(true);
    newLine.setDashLengths(dash, gap);
    drawingObjectsArray.push(newLine);

    //horizontal axis line top
    newLine = new Line(originAdjustmentHorizontalAxis, originAdjustmentVerticalAxis + curRenderItemSize.getHeight(), originAdjustmentHorizontalAxis + curRenderItemSize.getLength(), originAdjustmentVerticalAxis + curRenderItemSize.getHeight()).setDrawingGroupName('scaleAspectRatioLines');
    if (isScalingStep) {
        newLine.setColour(config.DRAWING_COLOURS.ORANGE);
    }
    newLine.setDashed(true);
    newLine.setDashLengths(dash, gap);
    drawingObjectsArray.push(newLine);

    var textColour = curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE ? config.DRAWING_COLOURS.ORANGE : undefined;

    var newText = new Text(curRenderItemSize.getHeight(), (originAdjustmentHorizontalAxis + curRenderItemSize.getLength()) + 20 / scalingFactor, originAdjustmentVerticalAxis + (curRenderItemSize.getHeight() / 2), offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    newText.setRotationAngle(270);
    newText.setAnchorPoint({x: 0.5, y: 1});
    drawingObjectsArray.push(newText);

    newText = new Text(curRenderItemSize.getLength(), originAdjustmentHorizontalAxis + (curRenderItemSize.getLength() / 2), (originAdjustmentVerticalAxis + curRenderItemSize.getHeight()) + 15 / scalingFactor, offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    newText.setAnchorPoint({ x: 0.5, y: 0.5 });
    drawingObjectsArray.push(newText);

}

function generateItemOnlyCOGMarker() {
    
    
    var horizontalCOGVal, xOffset, verticalAxisCOGVal, yOffset;

    var xAxisChange = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var yAxisChange = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;

    var drawingOriginHorizontalOffset = xAxisChange < 0 ? Math.abs(xAxisChange) : 0;
    var drawingOriginVerticalOffset = yAxisChange < 0 ? Math.abs(yAxisChange) : 0;


    horizontalCOGVal = curRenderItem.getActualHorizontalCOG();
    
    xOffset = drawingOriginHorizontalOffset + horizontalCOGVal;

    if (curElevation === config.ELEVATION.SIDE) {
        verticalAxisCOGVal = curRenderItem.getActualVerticalCOG();
        yOffset = drawingOriginVerticalOffset + verticalAxisCOGVal;

    } else {
        //verticalAxisCOGVal = curRenderItem.getActualLateralCOG();
        //yOffset = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + verticalAxisCOGVal;
        //#unfinished, need to support top view here
    }

    var displayText = '';

    var textWidth = 0;
    var textHeight = 0;

    var curRenderItemCOGElement = new COG(xOffset, yOffset, 0, 0, displayText, config.COG_ELEMENT_STYLE.DRAW_RIGHT);
    
    curRenderItemCOGElement.setDrawingGroupName("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMHORIZONTALCOG);
    curRenderItemCOGElement.setDrawMarkerOnly(true);
    if (curPreviewType === config.PREVIEW_TYPE.ADJUST_COG) {
        curRenderItemCOGElement.setColour(config.DRAWING_COLOURS.ORANGE);
    }

    drawingObjectsArray.push(curRenderItemCOGElement);

    if (curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {

        horizontalCOGVal = curRenderItem.getActualPayloadHorizontalCOG();//offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMBODYPAYLOADHORIZONTALCOG).value.value;

        xOffset = drawingOriginHorizontalOffset + curRenderItem.getHeadboardThickness() + horizontalCOGVal;

        if (curElevation === config.ELEVATION.SIDE) {
            verticalAxisCOGVal = curRenderItem.getActualPayloadVerticalCOG();//offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMBODYPAYLOADVERTICALCOG).value.value;
            if (curRenderItem.getType() === config.BODY_TYPES.SERVICE) {
                yOffset = drawingOriginVerticalOffset + verticalAxisCOGVal;
            } else {
                yOffset = drawingOriginVerticalOffset + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight() + verticalAxisCOGVal;
            }
            

        } else {
            //verticalAxisCOGVal = curRenderItem.getActualLateralCOG();
            //yOffset = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + verticalAxisCOGVal;
            //#unfinished, need to support top view here
        }

        var curRenderItemPayloadCOGElement = new COG(xOffset, yOffset, 0, 0, displayText, config.COG_ELEMENT_STYLE.DRAW_RIGHT);

        curRenderItemPayloadCOGElement.setDrawingGroupName("configurationModal" + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + config.VALUE_TYPE.ITEMHORIZONTALCOG);
        curRenderItemPayloadCOGElement.setDrawMarkerOnly(true);
        if (curPreviewType === config.PREVIEW_TYPE.ADJUST_COG) {
            curRenderItemPayloadCOGElement.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(curRenderItemPayloadCOGElement);
    }
}

function generateItemAttributeReferenceLines(path) {
    var isScalingStep = curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE;

    var tailLength = (previewWindowBuffer / scalingFactor) * 0.5;

    var drawingOriginHorizontalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var drawingOriginVerticalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;

    //var originAdjustmentHorizontalAxis = drawingOriginHorizontalOffset >= 0 ? drawingOriginHorizontalOffset : 0;
    //var originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? drawingOriginVerticalOffset : 0;
    var originAdjustmentHorizontalAxis = drawingOriginHorizontalOffset >= 0 ? 0 : Math.abs(drawingOriginHorizontalOffset);
    var originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? 0 : Math.abs(drawingOriginVerticalOffset);
    //var originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? 0 : drawingOriginVerticalOffset;
    //var scaleLineWidth, scaleLineHeight;

    var pathParts = path.split('.');
    var attribute = pathParts[pathParts.length-1];
    
    var dash = Math.min(Math.max(originAdjustmentHorizontalAxis + curRenderItemSize.getLength(), originAdjustmentVerticalAxis + curRenderItemSize.getHeight()) / 15, 100);
    var gap = dash * 0.6;

    var drawHorizontalReferenceLines = false, drawVerticalReferenceLines = false;
    var leftVerticalRefLineX, rightVerticalRefLineX, bottomHorizontalRefLineY, topHorizontalRefLineY;

    if (attribute === config.VALUE_TYPE.ITEMLENGTH && curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY && curRenderItem.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis;

        //vertical axis line right
        rightVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItem.getActualLength();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMLENGTH && curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY && curRenderItem.getLengthType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItem.getHeadboardThickness();
        
        //vertical axis line right
        rightVerticalRefLineX = leftVerticalRefLineX + curRenderItem.getActualInternalLength();
        
        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMHEADBOARDTHICKNESS) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis;

        //vertical axis line right
        rightVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItem.getHeadboardThickness();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMTAILBOARDTHICKNESS) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItem.getHeadboardThickness() + curRenderItem.getActualInternalLength();

        //vertical axis line right
        rightVerticalRefLineX = leftVerticalRefLineX + curRenderItem.getTailboardThickness();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMSUBFRAMELENGTH) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis;

        //vertical axis line right
        rightVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItem.getSubframeLength();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMRAMPLENGTH) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItem.getHeadboardThickness() + curRenderItem.getActualInternalLength() + curRenderItem.getTailboardThickness();

        //vertical axis line right
        rightVerticalRefLineX = leftVerticalRefLineX + curRenderItem.getRampLength();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMRAMPPLATESPROTRUSION) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItem.getHeadboardThickness() + curRenderItem.getActualInternalLength() + curRenderItem.getTailboardThickness() + curRenderItem.getRampLength();

        //vertical axis line right
        rightVerticalRefLineX = leftVerticalRefLineX + curRenderItem.getRampPlatesProtrusion();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMAFRAME) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis;

        //vertical axis line right
        rightVerticalRefLineX = leftVerticalRefLineX + curRenderItem.getAFrame();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMHOOKOFFSET) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis;

        //vertical axis line right
        rightVerticalRefLineX = leftVerticalRefLineX + curRenderItem.getHookOffset();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMCENTREOFROLLER) {
        //vertical axis line left
        leftVerticalRefLineX = originAdjustmentHorizontalAxis + curRenderItemSize.getLength() - curRenderItem.getCentreOfRoller();

        //vertical axis line right
        rightVerticalRefLineX = leftVerticalRefLineX + curRenderItem.getCentreOfRoller();

        drawVerticalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMBOTTOMOFBODYTOTOPOFCHASSIS) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis;

        //horizontal axis line top
        topHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getBottomOfBodyToTopOfChassis();

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMSUBFRAMEHEIGHT) {
        if (curRenderItem.getType() === config.BODY_TYPES.SERVICE) {
            //horizontal axis line bottom
            bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getBottomOfBodyToTopOfChassis();

            //horizontal axis line top
            topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getSubframeHeight();
        } else {
            //horizontal axis line bottom
            bottomHorizontalRefLineY = originAdjustmentVerticalAxis;

            //horizontal axis line top
            topHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight();
        }
        
        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMSUBSTRUCTUREHEIGHT) {
        if (curRenderItem.getType() === config.BODY_TYPES.SERVICE) {
            //horizontal axis line bottom
            bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getBottomOfBodyToTopOfChassis() + curRenderItem.getSubframeHeight();

            //horizontal axis line top
            topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getSubstructureHeight();
        } else {
            //horizontal axis line bottom
            bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight();

            //horizontal axis line top
            topHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight();
        }
        

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMBODYDROPSIDEHEIGHT) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight();

        //horizontal axis line top
        topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getDropsideHeight();

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMHEIGHT && curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY && curRenderItem.getHeightType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight();

        //horizontal axis line top
        topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getHeight();

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMHEIGHT && curRenderItem.getAccessoryType() === config.ACCESSORY_TYPES.BODY && curRenderItem.getHeightType() === config.BODY_MEASUREMENT_TYPES.INTERNAL) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight();

        //horizontal axis line top
        if (curRenderItem.getType() === config.BODY_TYPES.TIMBER) {
            topHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight() + curRenderItem.getHeight();
        } else {
            topHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight() + curRenderItem.getActualInternalHeight();
        }

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMROOFTHICKNESS) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight() + curRenderItem.getActualInternalHeight();

        //horizontal axis line top
        topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getRoofThickness();

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMHEADBOARDHEIGHT) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight();

        //horizontal axis line top
        topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getHeadboardHeight();

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMTAILBOARDHEIGHT) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis + curRenderItem.getSubframeHeight() + curRenderItem.getSubstructureHeight();

        //horizontal axis line top
        topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getTailboardHeight();

        drawHorizontalReferenceLines = true;
    } else if (attribute === config.VALUE_TYPE.ITEMPLATFORMHEIGHT) {
        //horizontal axis line bottom
        bottomHorizontalRefLineY = originAdjustmentVerticalAxis;

        //horizontal axis line top
        topHorizontalRefLineY = bottomHorizontalRefLineY + curRenderItem.getPlatformHeight();

        drawHorizontalReferenceLines = true;
    }
    
    var newLine;
    if (drawVerticalReferenceLines) {
        //vertical axis line left
        newLine = new Line(leftVerticalRefLineX, originAdjustmentVerticalAxis - tailLength, leftVerticalRefLineX, originAdjustmentVerticalAxis + curRenderItemSize.getHeight() + tailLength).setDrawingGroupName('itemAttributeReferenceLines');
        //if (isScalingStep) {
        newLine.setColour(config.DRAWING_COLOURS.BLUE);
        //}
        newLine.setDashed(true);
        newLine.setDashLengths(dash, gap);
        newLine.setLineWidth(2);
        drawingObjectsArray.push(newLine);


        //vertical axis line right
        newLine = new Line(rightVerticalRefLineX, originAdjustmentVerticalAxis - tailLength, rightVerticalRefLineX, originAdjustmentVerticalAxis + curRenderItemSize.getHeight() + tailLength).setDrawingGroupName('itemAttributeReferenceLines');
        //if (isScalingStep) {
        newLine.setColour(config.DRAWING_COLOURS.BLUE);
        //}
        newLine.setDashed(true);
        newLine.setDashLengths(dash, gap);
        newLine.setLineWidth(2);
        drawingObjectsArray.push(newLine);
    } else if (drawHorizontalReferenceLines) {
        //horizontal axis line bottom
        newLine = new Line(originAdjustmentHorizontalAxis - tailLength, bottomHorizontalRefLineY, originAdjustmentHorizontalAxis + curRenderItemSize.getLength() + tailLength, bottomHorizontalRefLineY).setDrawingGroupName('scaleAspectRatioLines');
        
        newLine.setColour(config.DRAWING_COLOURS.BLUE);
        
        newLine.setDashed(true);
        newLine.setDashLengths(dash, gap);
        newLine.setLineWidth(2);
        drawingObjectsArray.push(newLine);



        //horizontal axis line top
        newLine = new Line(originAdjustmentHorizontalAxis - tailLength, topHorizontalRefLineY, originAdjustmentHorizontalAxis + curRenderItemSize.getLength() + tailLength, topHorizontalRefLineY).setDrawingGroupName('scaleAspectRatioLines');
        
        newLine.setColour(config.DRAWING_COLOURS.BLUE);
        
        newLine.setDashed(true);
        newLine.setDashLengths(dash, gap);
        newLine.setLineWidth(2);
        drawingObjectsArray.push(newLine);
    }

    

    



    

    //var textColour = curPreviewType === config.PREVIEW_TYPE.ADJUST_SCALE ? config.DRAWING_COLOURS.ORANGE : undefined;

    //var newText = new Text(curRenderItemSize.getHeight(), (originAdjustmentHorizontalAxis + curRenderItemSize.getLength()) + 20 / scalingFactor, originAdjustmentVerticalAxis + (curRenderItemSize.getHeight() / 2), offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    //newText.setRotationAngle(270);
    //newText.setAnchorPoint({ x: 0.5, y: 1 });
    //drawingObjectsArray.push(newText);

    ////newText = new Text(curRenderItemSize.getLength(), originAdjustmentHorizontalAxis + (curRenderItemSize.getLength() / 2), (originAdjustmentVerticalAxis + curRenderItemSize.getHeight()) + 15 / scalingFactor, offerManager.getUIFormattedDimensionValue, textColour).setDrawingGroupName('previewOriginLines');
    ////newText.setAnchorPoint({ x: 0.5, y: 0.5 });
    //drawingObjectsArray.push(newText);
}

function populateDrawingObjectsForFullRigItemFromXML(xmlDoc, parentType, blobType, optionalAccessoryId) {
    
    var tempDrawingObjects = [];
    var drawingGroupName;
    
    //var x = xmlDoc.getElementsByTagName("NewDataSet");
    //var xmlValues = x[0].childNodes;
    
    //var browser = globals.getBrowser();
    //var field1, field2, field3, field4, field5, field6, field7, field8, field9;

    var lineColour;

    var originX, originY, chassisObjectToUse;
    var topViewCentreLine = offerManager.getRigOps().getTopViewCentreLine();

    switch (parentType) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            originX = curVehicle.getPortionOfOverallLengthOverhangingFront();
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = 0;
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + (topViewCentreLine - curVehicle.getOverallWidth()/*InclAccessories(topViewCentreLine, offerManager.getRigOps().getLeftOfChassisRearWidth(curVehicle), offerManager.getRigOps().getLeftOfBodyWidthExternal(curVehicle))*/ / 2);
                //originY = (topViewCentreLine - curVehicle.getOverallWidth() / 2) + offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft(); 
                //originY = offerManager.getRigOps().getPayloadDeckStartPositionFromLeftInclPortionOfChassisOverhangingLeft(chassisObject)
            }
            
            chassisObjectToUse = curVehicle;
            drawingGroupName = config.CHASSIS_OBJECT_TYPES.VEHICLE;
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            //if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            //    var fifthWheelOffset;
            //    if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            //        fifthWheelOffset = curVehicle.getFifthWheelOffset();
            //    } else {
            //        fifthWheelOffset = curVehicle.getAccessoryHolder().getFifthWheel().getOffset();
            //    }

            //    originX = ((curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheelOffset)) - curTrailer1.getFrontOverhang();
            //} else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            //    originX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset();
            //} 
            chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(parentType);

            originX = offerManager.getRigOps().getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(parentType);
            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                originX -= chassisObjectToUse.getDrawbarLength();
            }
            
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = 0;
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + (topViewCentreLine - chassisObjectToUse.getOverallWidth()/*InclAccessories(topViewCentreLine, offerManager.getRigOps().getLeftOfChassisRearWidth(curTrailer1), offerManager.getRigOps().getLeftOfBodyWidthExternal(curTrailer1))*/ / 2);
            }

            
            drawingGroupName = parentType;
            break;

    }
    var chassisHeight = chassisObjectToUse.getChassisHeightMax !== undefined ? chassisObjectToUse.getChassisHeightMax() : chassisObjectToUse.getChassisHeight();
    var curObject = null;
    var tempBody, tempHooklift;
    switch (blobType) {
        case config.ACCESSORY_TYPES.BODY:

            tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    originX += chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName());
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                        originX += offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName());
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        originX += chassisObjectToUse.getDrawbarLength() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName());
                    }
                    break;
            }
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                    originY = chassisHeight - tempBody.getBottomOfBodyToTopOfChassis();
                } else {
                    originY = chassisHeight + tempBody.getAboveBelowChassis();
                }
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + (topViewCentreLine - tempBody.getActualWidth() / 2);
            }
            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                tempHooklift  = chassisObjectToUse.getAccessoryHolder().getHooklift();
                //originX += tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
                originY += tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight();
            }
            drawingGroupName += '.' + config.ACCESSORY_TYPES.BODY;
            curObject = tempBody;
            break;
        case config.ACCESSORY_TYPES.CRANE:

            var tempCrane = chassisObjectToUse.getAccessoryHolder().getCrane();

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    originX += chassisObjectToUse.getBumperToBackOfCab() + tempCrane.getHorizontalGap();
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                        originX += tempCrane.getHorizontalGap();
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        originX += chassisObjectToUse.getDrawbarLength() + tempCrane.getHorizontalGap();
                    }
                    
                    break;
            }
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = tempCrane.getVerticalGap() + tempCrane.getAboveBelowChassis();//curVehicle.getChassisHeightMax();
            }else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth();
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth();
            }
            
            drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE;
            curObject = tempCrane;
            break;
        case config.ACCESSORY_TYPES.FRIDGE: 
            var tempFridge = chassisObjectToUse.getAccessoryHolder().getFridge();

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    originX += chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) - tempFridge.getFromFrontOfBody() - tempFridge.getProtrusion();
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                        originX += offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) - tempFridge.getFromFrontOfBody() - tempFridge.getProtrusion();
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        originX += chassisObjectToUse.getDrawbarLength() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) - tempFridge.getFromFrontOfBody() - tempFridge.getProtrusion();
                    }
                    break;
            }
            
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = tempFridge.getVerticalGap() + chassisObjectToUse.getAccessoryHolder().getBody().getAboveBelowChassis() + chassisObjectToUse.getAccessoryHolder().getBody().getActualExternalHeight() - tempFridge.getMountingPosition() - tempFridge.getHeight();
            } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = offerManager.getRigOps().getLeftOfBodyWidthExternalForTurningCircle(chassisObjectToUse) + tempFridge.getFromLeftOfBodyWidthExternal();
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) +  tempFridge.getFromLeftOfBodyWidthExternal();
            }
            
            drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE;
            curObject = tempFridge;
            break;
        case config.ACCESSORY_TYPES.TAILLIFT:

            var tempTaillift = chassisObjectToUse.getAccessoryHolder().getTaillift();
            tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        originX += chassisObjectToUse.getOverallLength() + tempTaillift.getFromBackOfBody();
                    } else {
                        originX += chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualLength() + tempTaillift.getFromBackOfBody();
                    }
                    
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                        originX += offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualLength() + tempTaillift.getFromBackOfBody();
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        originX += chassisObjectToUse.getDrawbarLength() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualLength() + tempTaillift.getFromBackOfBody();
                    }
                    break;
            }
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    originY = chassisObjectToUse.getFloorHeight() + tempTaillift.getAboveOrBelowFloor();
                } else {
                    originY = chassisHeight + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor();
                }
                
            } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = offerManager.getRigOps().getLeftOfBodyWidthExternalForTurningCircle(chassisObjectToUse) + tempTaillift.getFromLeftOfBodyWidthExternal();
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + tempTaillift.getFromLeftOfBodyWidthExternal();
            }
            
            drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT;
            curObject = tempTaillift;
            break;
        case config.ACCESSORY_TYPES.FIFTH_WHEEL:

            var tempFifthWheel = chassisObjectToUse.getAccessoryHolder().getFifthWheel();

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    //originX += chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart() +
                    //            chassisObjectToUse.getWheelbaseTheoretical() - (tempFifthWheel.getOffset() + (tempFifthWheel.getLength() / 2));
                    originX += chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart() +
                                chassisObjectToUse.getWheelbaseTheoretical() - tempFifthWheel.getOffset() - tempFifthWheel.getFrontOverhang();
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    // currently not possible to add fifth wheel to trailer
                    originX = 0;
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    // currently not possible to add fifth wheel to trailer
                    originX = 0;
                    break;

            }
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = chassisHeight;
            } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempFifthWheel.getFromLeftOfVehicleRearWidth() - tempFifthWheel.getWidth() / 2;
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempFifthWheel.getFromLeftOfVehicleRearWidth() - tempFifthWheel.getWidth()/2;
            }
            
            drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL;
            curObject = tempFifthWheel;
            break;
        case config.ACCESSORY_TYPES.OTHER:

            var tempOther = chassisObjectToUse.getAccessoryHolder().getAccessoryById(optionalAccessoryId);

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    originX += chassisObjectToUse.getBumperToBackOfCab() + tempOther.getHorizontalGap();
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempOther.getHorizontalGap();
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + tempOther.getHorizontalGap();
                    break;
            }
            
            

            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = chassisHeight + tempOther.getVerticalGap();
            } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempOther.getFromLeftOfVehicleRearWidth();
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempOther.getFromLeftOfVehicleRearWidth();
            }
            
            drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + tempOther.getId();
            curObject = tempOther;
            break;
        case config.ACCESSORY_TYPES.HITCH:

            var tempHitch = chassisObjectToUse.getAccessoryHolder().getHitch();

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    //if (tempHitch.getType() === config.HITCH_TYPES.CONVENTIONAL) {
                        originX += (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset()) - (tempHitch.getCouplingOffset() + tempHitch.getMountingLength());
                    //} else {
                    //    originX = (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + tempHitch.getOffset()) - (tempHitch.getCouplingOffset() + tempHitch.getMountingLength());
                    //}
                    
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    // currently not possible to add hitch to trailer
                    originX = 0;
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    // currently not possible to add hitch to trailer
                    originX = 0;
                    break;

            }
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = tempHitch.getHeightFromGround() - tempHitch.getCouplingHeight();
            } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempHitch.getFromLeftOfVehicleRearWidth() - tempHitch.getWidth() / 2;
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempHitch.getFromLeftOfVehicleRearWidth() - tempHitch.getWidth()/2;
            }
            
            drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH;
            curObject = tempHitch;
            break;
        case config.ACCESSORY_TYPES.HOOKLIFT:

            tempHooklift = chassisObjectToUse.getAccessoryHolder().getHooklift();

            switch (chassisObjectToUse.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    originX += chassisObjectToUse.getBumperToBackOfCab() + tempHooklift.getHorizontalGap();
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                        originX += tempHooklift.getHorizontalGap();
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        originX += chassisObjectToUse.getDrawbarLength() + tempHooklift.getHorizontalGap();
                    }

                    break;
            }
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = chassisHeight + tempHooklift.getVerticalGap();
            } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
                originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempHooklift.getFromLeftOfVehicleRearWidth();
            } else {
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempHooklift.getFromLeftOfVehicleRearWidth();
            }

            drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT;
            curObject = tempHooklift;
            break;
        case config.ACCESSORY_TYPES.PAYLOAD:
            
            var tempPayload = chassisObjectToUse.getPayloadHolder().getPayloadById(optionalAccessoryId);
            tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

            lineColour = config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR;

            var payloadDeckStartPos = getPayloadDeckStartPosition(chassisObjectToUse);
            originX = payloadDeckStartPos.x + tempPayload.getStartX();
            originY = payloadDeckStartPos.y;
            if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
                originY += tempPayload.getStartY();
            } else {
                //originY += tempPayload.getStartZ();
                originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + payloadDeckStartPos.y + tempPayload.getStartZ();
            } 
            

            drawingGroupName += '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + tempPayload.getId();
            curObject = tempPayload;
            break;
    }


    
    

    //for (var i = 0; i < xmlValues.length; i++) {

        
    //    if (xmlValues[i].nodeName === 'Graphic' || xmlValues[i].nodeName === 'Graphic.xml') {

    //        if (xmlValues[i].childNodes[1].textContent === 'LINE') {
    //            var startX = 0, startY = 0, endX = 0, endY = 0, blockName = undefined, layerNumber = 0, objNumSeqNum = undefined, closedIndicator = undefined;
    //            startX = parseFloat(xmlValues[i].childNodes[3].textContent);        // startX, filed1
    //            startY = parseFloat(xmlValues[i].childNodes[5].textContent);        // startY, field2
    //            endX = parseFloat(xmlValues[i].childNodes[7].textContent);        // endX, field3
    //            endY = parseFloat(xmlValues[i].childNodes[9].textContent);        // endY, field4
    //            //field5 = xmlValues[i].childNodes[11].textContent; 
    //            if (xmlValues[i].childNodes[19] !== undefined) {
    //                layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
    //                blockName = xmlValues[i].childNodes[15].textContent;                   // blockName, field7
    //                objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
    //                closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9
    //            }
                
    //            //if (blobType !== config.ACCESSORY_TYPES.OTHER) {
    //                startX += originX;
    //                startY += originY;
    //                endX += originX;
    //                endY += originY;
    //            //}

    //            //switch (blobType) {
    //            //    case config.OBJECT_TYPES.VEHICLE:
    //            //        //startX, startY, radius, startAngle, sweep, antiClockwise, layerNumber, blockName, objNumSeqNum, closedIndicator
    //            //        //addXMLArc(field1, field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        startX += originX;
    //            //        startY += originY;
    //            //        endX += originX;
    //            //        endY += originY;
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.BODY:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        startX += originX;
    //            //        startY += originY;
    //            //        endX += originX;
    //            //        endY += originY;
    //            //        conceptualGroup = 'body';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.CRANE:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        startX += originX;
    //            //        startY += originY;
    //            //        endX += originX;
    //            //        endY += originY;
    //            //        conceptualGroup = 'crane';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.FRIDGE:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        startX += originX;
    //            //        startY += originY;
    //            //        endX += originX;
    //            //        endY += originY;
    //            //        conceptualGroup = 'fridge';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.TAILLIFT:
    //            //        startX += originX;
    //            //        startY += originY;
    //            //        endX += originX;
    //            //        endY += originY;
    //            //        conceptualGroup = 'taillift';
    //            //        break;
    //            //}

    //            //var DrawingObjectLine = function (startX, startY, endX, endY, layerNumber, blockName, objNumSeqNum, closedIndicator) {
    //                var tempLine = new Line(startX, startY, endX, endY, layerNumber, blockName, objNumSeqNum, closedIndicator);
    //                tempLine.setColour(lineColour);
    //            if (drawingGroupName !== undefined) {
    //                tempLine.setDrawingGroupName(drawingGroupName);
    //            }
    //            if (blobType === config.ACCESSORY_TYPES.BODY) {
    //                var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
    //                if (tempBody.getType() === config.BODY_TYPES.SERVICE || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL) {
    //                    tempLine.setBringToFront(true);
    //                    //newLine.setBypassHitAreaSort(true);
    //                }
    //            } else if (blobType !== config.CHASSIS_OBJECT_TYPES.VEHICLE && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER1 && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER2) {
    //                if (isItemObscured(chassisObjectToUse, curObject, originY, originX)) {
    //                    dashItemAndBringForward(tempLine);
    //                }
    //            }
    //            tempDrawingObjects.push(tempLine);
                
    //        }

    //        if (xmlValues[i].childNodes[1].textContent === 'CIRCLE') {
    //            var centreX = 0, centreY = 0, radius = 0, blockName = undefined, layerNumber = 0, circleType = undefined, objNumSeqNum = undefined, closedIndicator = undefined, fill = true;
    //            centreX = parseFloat(xmlValues[i].childNodes[3].textContent);        // centreX, field1
    //            centreY = parseFloat(xmlValues[i].childNodes[5].textContent);        // centreY, field2
    //            radius = parseFloat(xmlValues[i].childNodes[7].textContent);        // radius, field3
    //            //field4 = parseFloat(xmlValues[i].childNodes[9].textContent);        // 
    //            //field5 = parseFloat(xmlValues[i].childNodes[9].textContent);        // blockname??
                
    //            //field6 = parseInt(xmlValues[i].childNodes[11].textContent);         // layerNumber
                
    //            if (xmlValues[i].childNodes[17] !== undefined && xmlValues[i].childNodes[17].textContent.indexOf('_') > -1) {//MBV data
                    
    //                blockName = xmlValues[i].childNodes[11].textContent;                   // blockName, field5
    //                layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
    //                circleType = parseInt(xmlValues[i].childNodes[15].textContent);         // circleType, field7
    //                objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
    //                closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9

    //            } else {
    //                //blockName = xmlValues[i].childNodes[9].textContent;

    //                circleType = xmlValues[i].childNodes[15].textContent;// field 7

    //                if (circleType === '') {
    //                    circleType = xmlValues[i].childNodes[11].textContent; // circleType, field5
    //                }
    //            }

    //            //if (blobType !== config.ACCESSORY_TYPES.OTHER) {
    //                centreX += originX;
    //                centreY += originY;
    //            //}
                
    //            ////var DrawingObjectCircle = function (centreX, centreY, radius, fill, blockName, layerNumber, circleType, objNumSeqNum, closedIndicator) {
    //            //switch (blobType) {
    //            //    case config.OBJECT_TYPES.VEHICLE:
    //            //        //startX, startY, radius, startAngle, sweep, antiClockwise, layerNumber, blockName, objNumSeqNum, closedIndicator
    //            //        //addXMLArc(field1, field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        centreX += originX;
    //            //        centreY += originY;
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.BODY:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        centreX += originX;
    //            //        centreY += originY;
    //            //        conceptualGroup = 'body';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.CRANE:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        centreX += originX;
    //            //        centreY += originY;
    //            //        conceptualGroup = 'crane';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.FRIDGE:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        centreX += originX;
    //            //        centreY += originY;
    //            //        conceptualGroup = 'fridge';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.TAILLIFT:
    //            //        centreX += originX;
    //            //        centreY += originY;
    //            //        conceptualGroup = 'taillift';
    //            //        break;
    //            //}

    //            var c = new Circle(centreX, centreY, radius, circleType, fill, blockName, layerNumber, objNumSeqNum, closedIndicator);
    //            if (c.blockName !== undefined && globals.user.getColourDrawings() === true) {
    //                c.fillColour = utils.getColour(c.blockName, cabColour);
    //            } else {
    //                c.fillColour = config.DRAWING_COLOURS.WHITE;
    //            }
    //            if (drawingGroupName !== undefined) {
    //                c.setDrawingGroupName(drawingGroupName);
    //            }
    //            if (circleType === CIRCLE_TYPE.OUTER) {
    //                c.setDrawGroundline(true);
    //            }
    //            c.setColour(lineColour);
    //            if (blobType === config.ACCESSORY_TYPES.BODY) {
    //                var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
    //                if (tempBody.getType() === config.BODY_TYPES.SERVICE || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL) {
    //                    c.setBringToFront(true);
    //                    //newLine.setBypassHitAreaSort(true);
    //                }
    //            } else if (blobType !== config.CHASSIS_OBJECT_TYPES.VEHICLE && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER1 && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER2) {
    //                if (isItemObscured(chassisObjectToUse, curObject, originY, originX)) {
    //                    dashItemAndBringForward(c);
    //                }
    //            }
    //            tempDrawingObjects.push(c);
                
    //            //drawingObjectsArray.push(new Circle(centreX, centreY, redius, circleType, fill, blockName, layerNumber, objNumSeqNum, closedIndicator));
    //        }

    //        if (xmlValues[i].childNodes[1].textContent === 'ARC') {
    //            field1 = parseFloat(xmlValues[i].childNodes[3].textContent);        // centerX
    //            field2 = parseFloat(xmlValues[i].childNodes[5].textContent);        // centerY
    //            field3 = parseFloat(xmlValues[i].childNodes[7].textContent);        // radius
    //            field4 = parseFloat(xmlValues[i].childNodes[9].textContent);        // start angle
    //            field5 = parseFloat(xmlValues[i].childNodes[11].textContent);       // end angle
    //            field6 = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber
    //            field7 = xmlValues[i].childNodes[15].textContent;                   // blockName
    //            field8 = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum
    //            field9 = xmlValues[i].childNodes[18].textContent;                   // closedIndicator
    //            //var DrawingObjectArc = function (startX, startY, radius, startAngle, endAngle, antiClockwise, layerNumber, blockName, objNumSeqNum, closedIndicator) {
    //            //drawingObjectsArray.push(new DrawingObjectArc(field1, field2, field3, field4, field5, field6));
    //            //drawingObjectsArray.push(new Arc(field1, field2, field3, field4, field5, true, field6, field7, field8, field9));

    //            //if (blobType !== config.ACCESSORY_TYPES.OTHER) {
    //                field1 += originX;
    //                field2 += originY;
    //            //}

    //            //switch(blobType) {
    //            //    case config.OBJECT_TYPES.VEHICLE:
    //            //        //startX, startY, radius, startAngle, sweep, antiClockwise, layerNumber, blockName, objNumSeqNum, closedIndicator
    //            //        //addXMLArc(field1, field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        field1 += originX;
    //            //        field2 += originY;
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.BODY:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        field1 += originX;
    //            //        field2 += originY;
    //            //        conceptualGroup = 'body';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.CRANE:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        field1 += originX;
    //            //        field2 += originY;
    //            //        conceptualGroup = 'crane';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.FRIDGE:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        field1 += originX;
    //            //        field2 += originY;
    //            //        conceptualGroup = 'fridge';
    //            //        break;
    //            //    case config.ACCESSORY_TYPES.TAILLIFT:
    //            //        //addXMLArc(bodyOriginX + field1, bodyOriginY + field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            //        field1 += originX;
    //            //        field2 += originY;
    //            //        conceptualGroup = 'taillift';
    //            //        break;
    //            //}
    //            var tempArc = new Arc(field1, field2, field3, field4, field5, true, field6, field7, field8, field9);
    //            if (drawingGroupName !== undefined) {
    //                tempArc.setDrawingGroupName(drawingGroupName);
    //            }
    //            tempArc.setColour(lineColour);
    //            if (blobType === config.ACCESSORY_TYPES.BODY) {
    //                var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
    //                if (tempBody.getType() === config.BODY_TYPES.SERVICE || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL) {
    //                    tempArc.setBringToFront(true);
    //                    //newLine.setBypassHitAreaSort(true);
    //                }
    //            } else if (blobType !== config.CHASSIS_OBJECT_TYPES.VEHICLE && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER1 && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER2) {
    //                if (isItemObscured(chassisObjectToUse, curObject, originY, originX)) {
    //                    dashItemAndBringForward(tempArc);
    //                }
    //            }
    //            tempDrawingObjects.push(tempArc);
    //        }
    //    }
    //}
    var layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.NONE;
    tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
    if (blobType === config.ACCESSORY_TYPES.BODY) {
        
        if (tempBody.getType() === config.BODY_TYPES.SERVICE || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL) {
            //tempLine.setBringToFront(true);
            //newLine.setBypassHitAreaSort(true);
            layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.BRING_TO_FRONT;
        }
    } else if (blobType === config.ACCESSORY_TYPES.PAYLOAD || ((tempBody !== null && tempBody.getType() === config.BODY_TYPES.SERVICE) && blobType !== config.CHASSIS_OBJECT_TYPES.VEHICLE && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER1 && blobType !== config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
        if (isItemObscured(chassisObjectToUse, curObject, originY, originX)) {
            //dashItemAndBringForward(tempLine);
            layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.DASH_AND_BRING_TO_FRONT;
        }
    }

    //tempDrawingObjects = getDrawingObjectsFromXML(xmlDoc, originX, originY, lineColour, drawingGroupName, layeringAdjustmentFlag);

    //tempDrawingObjects.sort(sortDrawingObjects);

    //tempDrawingObjects = groupDrawingObjects(tempDrawingObjects);
    
    
    //return tempDrawingObjects;

    var preventBlockFill = false;
    if (curObject !== null && curObject.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
        if (curElevation === config.ELEVATION.SIDE && utils.itemHasValidSideViewGraphic(curObject)) {
            preventBlockFill = true;
        } else if (curElevation === config.ELEVATION.TOP && utils.itemHasValidTopViewGraphic(curObject)) {
            preventBlockFill = true;
        }
    }

    return getDrawingObjectsFromXML(xmlDoc, originX, originY, lineColour, drawingGroupName, layeringAdjustmentFlag, preventBlockFill);
    
}

function populateDrawingObjectsForItemOnlyFromXML(xmlDoc, item, optionalAccessoryId) {

    var blobType = item.getAccessoryType();

    var tempDrawingObjects = [];
    var drawingGroupName = "configurationModal" + '.' + blobType;

    var lineColour;

    //var originX = previewWindowBuffer / scalingFactor + 0;
    //var originY = previewWindowBuffer / scalingFactor + 0;

    var drawingOriginHorizontalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + item.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var drawingOriginVerticalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + item.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;

    var originAdjustmentHorizontalAxis = drawingOriginHorizontalOffset >= 0 ? 0 : drawingOriginHorizontalOffset;
    var originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? 0 : drawingOriginVerticalOffset;

    var originX = -originAdjustmentHorizontalAxis;
    var originY = -originAdjustmentVerticalAxis;
    
    var topViewCentreLine = offerManager.getRigOps().getTopViewCentreLine();

    if (item.getAccessoryType() === config.ACCESSORY_TYPES.PAYLOAD) {
        lineColour = config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR;
    }
    
    
    //var curObject = null;
    //switch (blobType) {
    //    case config.ACCESSORY_TYPES.BODY:

    //        var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    //        switch (chassisObjectToUse.getChassisObjectName()) {
    //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //                originX += chassisObjectToUse.getBumperToBackOfCab() + tempBody.getHorizontalGap();
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //                if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
    //                    originX += tempBody.getHorizontalGap();
    //                } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
    //                    originX += chassisObjectToUse.getDrawbarLength() + tempBody.getHorizontalGap();
    //                }
    //                break;
    //        }
    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
    //                originY = chassisHeight - tempBody.getBottomOfBodyToTopOfChassis();
    //            } else {
    //                originY = chassisHeight;
    //            }
    //        } else {
    //            originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + (topViewCentreLine - tempBody.getActualWidth() / 2);
    //        }
    //        drawingGroupName += '.' + config.ACCESSORY_TYPES.BODY;
    //        curObject = tempBody;
    //        break;
    //    case config.ACCESSORY_TYPES.CRANE:

    //        var tempCrane = chassisObjectToUse.getAccessoryHolder().getCrane();

    //        switch (chassisObjectToUse.getChassisObjectName()) {
    //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //                originX += chassisObjectToUse.getBumperToBackOfCab() + tempCrane.getHorizontalGap();
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //                if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
    //                    originX += tempCrane.getHorizontalGap();
    //                } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
    //                    originX += chassisObjectToUse.getDrawbarLength() + tempCrane.getHorizontalGap();
    //                }

    //                break;
    //        }
    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = tempCrane.getVerticalGap() + tempCrane.getAboveBelowChassis();//curVehicle.getChassisHeightMax();
    //        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth();
    //        } else {
    //            originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth();
    //        }

    //        drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE;
    //        curObject = tempCrane;
    //        break;
    //    case config.ACCESSORY_TYPES.FRIDGE:
    //        var tempFridge = chassisObjectToUse.getAccessoryHolder().getFridge();

    //        switch (chassisObjectToUse.getChassisObjectName()) {
    //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //                originX += chassisObjectToUse.getBumperToBackOfCab() + chassisObjectToUse.getAccessoryHolder().getBody().getHorizontalGap() - tempFridge.getProtrusion();
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //                if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
    //                    originX += chassisObjectToUse.getAccessoryHolder().getBody().getHorizontalGap() - tempFridge.getProtrusion();
    //                } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
    //                    originX += chassisObjectToUse.getDrawbarLength() + chassisObjectToUse.getAccessoryHolder().getBody().getHorizontalGap() - tempFridge.getProtrusion();
    //                }
    //                break;
    //        }

    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = tempFridge.getVerticalGap() + chassisObjectToUse.getAccessoryHolder().getBody().getActualExternalHeight() - tempFridge.getMountingPosition() - tempFridge.getHeight();
    //        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = offerManager.getRigOps().getLeftOfBodyWidthExternalForTurningCircle(chassisObjectToUse) + tempFridge.getFromLeftOfBodyWidthExternal();
    //        } else {
    //            originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + tempFridge.getFromLeftOfBodyWidthExternal();
    //        }

    //        drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE;
    //        curObject = tempFridge;
    //        break;
    //    case config.ACCESSORY_TYPES.TAILLIFT:

    //        var tempTaillift = chassisObjectToUse.getAccessoryHolder().getTaillift();
    //        var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    //        switch (chassisObjectToUse.getChassisObjectName()) {
    //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //                originX += chassisObjectToUse.getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getActualLength() + tempTaillift.getFromBackOfBody();
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //                if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
    //                    originX += tempBody.getHorizontalGap() + tempBody.getActualLength() + tempTaillift.getFromBackOfBody();
    //                } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
    //                    originX += chassisObjectToUse.getDrawbarLength() + tempBody.getHorizontalGap() + tempBody.getActualLength() + tempTaillift.getFromBackOfBody();
    //                }
    //                break;
    //        }
    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = chassisHeight + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor();
    //        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = offerManager.getRigOps().getLeftOfBodyWidthExternalForTurningCircle(chassisObjectToUse) + tempTaillift.getFromLeftOfBodyWidthExternal();
    //        } else {
    //            originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + tempTaillift.getFromLeftOfBodyWidthExternal();
    //        }

    //        drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT;
    //        curObject = tempTaillift;
    //        break;
    //    case config.ACCESSORY_TYPES.FIFTH_WHEEL:

    //        var tempFifthWheel = chassisObjectToUse.getAccessoryHolder().getFifthWheel();

    //        switch (chassisObjectToUse.getChassisObjectName()) {
    //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //                originX += chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart() +
    //                            chassisObjectToUse.getWheelbaseTheoretical() - (tempFifthWheel.getOffset() + (tempFifthWheel.getLength() / 2));
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //                // currently not possible to add fifth wheel to trailer
    //                originX = 0;
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //                // currently not possible to add fifth wheel to trailer
    //                originX = 0;
    //                break;

    //        }
    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = chassisHeight;
    //        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempFifthWheel.getFromLeftOfVehicleRearWidth() - tempFifthWheel.getWidth() / 2;
    //        } else {
    //            originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempFifthWheel.getFromLeftOfVehicleRearWidth() - tempFifthWheel.getWidth() / 2;
    //        }

    //        drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL;
    //        curObject = tempFifthWheel;
    //        break;
    //    case config.ACCESSORY_TYPES.OTHER:

    //        var tempOther = chassisObjectToUse.getAccessoryHolder().getAccessoryById(optionalAccessoryId);

    //        switch (chassisObjectToUse.getChassisObjectName()) {
    //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //                originX += chassisObjectToUse.getBumperToBackOfCab() + tempOther.getHorizontalGap();
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //                originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempOther.getHorizontalGap();
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //                originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + tempOther.getHorizontalGap();
    //                break;
    //        }



    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = chassisHeight + tempOther.getVerticalGap();
    //        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempOther.getFromLeftOfVehicleRearWidth();
    //        } else {
    //            originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempOther.getFromLeftOfVehicleRearWidth();
    //        }

    //        drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + tempOther.getId();
    //        curObject = tempOther;
    //        break;
    //    case config.ACCESSORY_TYPES.HITCH:

    //        var tempHitch = chassisObjectToUse.getAccessoryHolder().getHitch();

    //        switch (chassisObjectToUse.getChassisObjectName()) {
    //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //                if (tempHitch.getType() === config.HITCH_TYPES.CONVENTIONAL) {
    //                    originX = (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + tempHitch.getOffset()) - (tempHitch.getCouplingOffset() + tempHitch.getMountingLength());
    //                } else {
    //                    originX = (curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + tempHitch.getOffset()) - (tempHitch.getCouplingOffset() + tempHitch.getMountingLength());
    //                }

    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //                // currently not possible to add hitch to trailer
    //                originX = 0;
    //                break;
    //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //                // currently not possible to add hitch to trailer
    //                originX = 0;
    //                break;

    //        }
    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = tempHitch.getHeightFromGround() - tempHitch.getCouplingHeight();
    //        } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObjectToUse) + tempHitch.getFromLeftOfVehicleRearWidth() - tempHitch.getWidth() / 2;
    //        } else {
    //            originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + tempHitch.getFromLeftOfVehicleRearWidth() - tempHitch.getWidth() / 2;
    //        }

    //        drawingGroupName += '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH;
    //        curObject = tempHitch;
    //        break;
    //    case config.ACCESSORY_TYPES.PAYLOAD:

    //        var tempPayload = chassisObjectToUse.getPayloadHolder().getPayloadById(optionalAccessoryId);
    //        var tempBody = chassisObjectToUse.getAccessoryHolder().getBody();

    //        lineColour = config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR;

    //        var payloadDeckStartPos = getPayloadDeckStartPosition(chassisObjectToUse);
    //        originX = payloadDeckStartPos.x + tempPayload.getStartX();
    //        originY = payloadDeckStartPos.y;
    //        if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
    //            originY += tempPayload.getStartY();
    //        } else {
    //            originY += tempPayload.getStartZ();
    //        }


    //        drawingGroupName += '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + tempPayload.getId();
    //        curObject = tempPayload;
    //        break;
    //}

    
    var layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.NONE;
    
    return getDrawingObjectsFromXML(xmlDoc, originX, originY, lineColour, drawingGroupName, layeringAdjustmentFlag, true);


}

function getDrawingObjectsFromXML(xmlDoc, originX, originY, lineColour, drawingGroupName, layeringAdjustmentFlag, preventBlockFill) {
    var tempDrawingObjects = [];
    

    var x = xmlDoc.getElementsByTagName("NewDataSet");
    var xmlValues = x[0].childNodes;

    var browser = globals.getBrowser();
    var field1, field2, field3, field4, field5, field6, field7, field8, field9;

    var tempDrawingObject;
    var blockname;
    for (var i = 0; i < xmlValues.length; i++) {

        tempDrawingObject = undefined;

        if (xmlValues[i].nodeName === 'Graphic' || xmlValues[i].nodeName === 'Graphic.xml') {
            var blockName = undefined, layerNumber = 0, objNumSeqNum = undefined, closedIndicator = undefined;
            var centreX = 0, centreY = 0, circleType = undefined, fill = true;
            if (xmlValues[i].childNodes[1].textContent === 'LINE') {
                
                var startX = 0, startY = 0, endX = 0, endY = 0;
                startX = parseFloat(xmlValues[i].childNodes[3].textContent);        // startX, filed1
                startY = parseFloat(xmlValues[i].childNodes[5].textContent);        // startY, field2
                endX = parseFloat(xmlValues[i].childNodes[7].textContent);        // endX, field3
                endY = parseFloat(xmlValues[i].childNodes[9].textContent);        // endY, field4
                
                if (xmlValues[i].childNodes[19] !== undefined) {
                    layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
                    blockName = xmlValues[i].childNodes[15].textContent;                   // blockName, field7
                    objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
                    closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9
                }

                
                startX += originX;
                startY += originY;
                endX += originX;
                endY += originY;

                tempDrawingObject = new Line(startX, startY, endX, endY, layerNumber, blockName, objNumSeqNum, closedIndicator);
                tempDrawingObject.setColour(lineColour);
                if (drawingGroupName !== undefined) {
                    tempDrawingObject.setDrawingGroupName(drawingGroupName);
                }
                

            }

            if (xmlValues[i].childNodes[1].textContent === 'CIRCLE') {
                var radius = 0;
                
                centreX = parseFloat(xmlValues[i].childNodes[3].textContent);        // centreX, field1
                centreY = parseFloat(xmlValues[i].childNodes[5].textContent);        // centreY, field2
                radius = parseFloat(xmlValues[i].childNodes[7].textContent);        // radius, field3

                if (xmlValues[i].childNodes[17] !== undefined && xmlValues[i].childNodes[17].textContent.indexOf('_') > -1) {//MBV data

                    blockName = xmlValues[i].childNodes[11].textContent;                   // blockName, field5
                    layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
                    circleType = parseInt(xmlValues[i].childNodes[15].textContent);         // circleType, field7
                    objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
                    closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9

                } else {
                    circleType = xmlValues[i].childNodes[15].textContent;// field 7
                    if (circleType === '') {
                        circleType = xmlValues[i].childNodes[11].textContent; // circleType, field5
                    }
                }
                //if (isNaN(circleType)) {
                //    circleType = CIRCLE_TYPE.INNER;
                //}
                centreX += originX;
                centreY += originY;

                tempDrawingObject = new Circle(centreX, centreY, radius, circleType, fill, blockName, layerNumber, objNumSeqNum, closedIndicator);
                //if (!preventBlockFill) {
                    if (tempDrawingObject.blockName !== undefined && globals.user.getColourDrawings() === true) {
                        tempDrawingObject.fillColour = utils.getColour(tempDrawingObject.blockName, cabColour);
                    } else {
                        tempDrawingObject.fillColour = config.DRAWING_COLOURS.WHITE;
                    }
                //}
                if (drawingGroupName !== undefined) {
                    tempDrawingObject.setDrawingGroupName(drawingGroupName);
                }
                if (circleType === CIRCLE_TYPE.OUTER) {
                    tempDrawingObject.setDrawGroundline(true);
                }
                tempDrawingObject.setColour(lineColour);
                
            }

            if (xmlValues[i].childNodes[1].textContent === 'ARC') {
                field1 = parseFloat(xmlValues[i].childNodes[3].textContent);        // centerX
                field2 = parseFloat(xmlValues[i].childNodes[5].textContent);        // centerY
                field3 = parseFloat(xmlValues[i].childNodes[7].textContent);        // radius
                field4 = parseFloat(xmlValues[i].childNodes[9].textContent);        // start angle
                field5 = parseFloat(xmlValues[i].childNodes[11].textContent);       // end angle
                field6 = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber
                field7 = xmlValues[i].childNodes[15].textContent;                   // blockName
                field8 = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum
                field9 = xmlValues[i].childNodes[18].textContent;                   // closedIndicator
                
                field1 += originX;
                field2 += originY;
                

                
                tempDrawingObject = new Arc(field1, field2, field3, field4, field5, true, field6, field7, field8, field9);
                if (drawingGroupName !== undefined) {
                    tempDrawingObject.setDrawingGroupName(drawingGroupName);
                }
                tempDrawingObject.setColour(lineColour);
            }

            if (xmlValues[i].childNodes[1].textContent === 'ELLIPSE') {
                var halfWidth = 0, halfHeight = 0;
                centreX = parseFloat(xmlValues[i].childNodes[3].textContent);        // centreX, field1
                centreY = parseFloat(xmlValues[i].childNodes[5].textContent);        // centreY, field2
                halfWidth = parseFloat(xmlValues[i].childNodes[7].textContent);        // halfWidth, field3
                halfHeight = parseFloat(xmlValues[i].childNodes[9].textContent);        // halfHeight, field4

                if (xmlValues[i].childNodes[17] !== undefined && xmlValues[i].childNodes[17].textContent.indexOf('_') > -1) {//MBV data

                    blockName = xmlValues[i].childNodes[11].textContent;                   // blockName, field5
                    layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
                    //circleType = parseInt(xmlValues[i].childNodes[15].textContent);         // circleType, field7
                    objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
                    closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9

                } 

                centreX += originX;
                centreY += originY;

                tempDrawingObject = new Ellipse(centreX, centreY, halfWidth, halfHeight, fill, blockName, layerNumber, objNumSeqNum, closedIndicator);
                if (tempDrawingObject.blockName !== undefined && globals.user.getColourDrawings() === true) {
                    tempDrawingObject.fillColour = utils.getColour(tempDrawingObject.blockName, cabColour);
                } else {
                    tempDrawingObject.fillColour = config.DRAWING_COLOURS.WHITE;
                }
                if (drawingGroupName !== undefined) {
                    tempDrawingObject.setDrawingGroupName(drawingGroupName);
                }
                
                tempDrawingObject.setColour(lineColour);

            }

            if (tempDrawingObject !== undefined) {
                if (layeringAdjustmentFlag === config.LAYERING_ADJUSTMENT.BRING_TO_FRONT) {
                    tempDrawingObject.setBringToFront(true);
                }else if (layeringAdjustmentFlag === config.LAYERING_ADJUSTMENT.DASH_AND_BRING_TO_FRONT) {
                    dashItemAndBringForward(tempDrawingObject);
                }

                tempDrawingObjects.push(tempDrawingObject);
            }
        }
    }


    tempDrawingObjects.sort(sortDrawingObjects);

    tempDrawingObjects = groupDrawingObjects(tempDrawingObjects, preventBlockFill);


    return tempDrawingObjects;
}

async function updateGraphicObjectsInXMLAndConvertBackToGraphic(item, drawingObjectsOriginal, isEdit) {

    var precisionLimit = 4;

    var drawingObjects = JSON.parse(JSON.stringify(drawingObjectsOriginal));

    var xmlDoc = item.getDecompressedGraphicBlobByElevation(curElevation);
    //var graphicBack = utils.translateXmlDocToGraphic(xmlDoc);
    var tempDrawingObjects = [];

    convertPolygonsBackToLines(drawingObjects);

    var x = xmlDoc.getElementsByTagName("NewDataSet");
    var xmlValues = x[0].childNodes;

    var browser = globals.getBrowser();
    var field1, field2, field3, field4, field5, field6, field7, field8, field9;

    var tempDrawingObject;

    var originXOffset = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
    var originYOffset = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
    var originZOffset = offerManager.getAttributeGroup('configurationModal' + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ).value.value;

    var startingOffsetsFromDrawing = getNegativePortionOfDrawing(item.getPath(), item);
    //var startingOffsets = { x: 0, y: 0 };
    
    var finalOffsetX = 0, finalOffsetY = 0;
    
    if (startingOffsetsFromDrawing.x !== 0 || startingOffsetsFromDrawing.y !== 0) {
        if (originXOffset < 0) {
            finalOffsetX = originXOffset;
        } 
        if (originYOffset < 0){
            finalOffsetY = originYOffset;
        }
        
        // finalOffsetX = originXOffset;
        // finalOffsetY = originYOffset;
        
    } else if (isEdit && (startingOffsetsFromDrawing.poitiveX > 0 || startingOffsetsFromDrawing.positiveY > 0)) {
        // if (originXOffset < 0) {
        //     finalOffsetX = originXOffset;
        // } 
        // if (originYOffset < 0){
        //     finalOffsetY = originYOffset;
        // }
        
        // finalOffsetX = -startingOffsetsFromDrawing.positiveX;
        // finalOffsetY = -startingOffsetsFromDrawing.positiveY;
        
    } else {
        if ((!isEdit && originXOffset < 0) || originXOffset < 0) {
            finalOffsetX = originXOffset;
        }
        if ((!isEdit && originYOffset < 0) || originYOffset < 0) {
            finalOffsetY = originYOffset;
        }
    }
    
    
    getScalingFactor();
    for (var i = 0; i < xmlValues.length; i++) {

        tempDrawingObject = undefined;

        if (xmlValues[i].nodeName === 'Graphic' || xmlValues[i].nodeName === 'Graphic.xml') {
            var blockName = undefined, layerNumber = 0, objNumSeqNum = undefined, closedIndicator = undefined;
            var circleType = undefined, centreX = 0, centreY = 0, radius = 0, fill = true;
            var drawingObject;
            if (xmlValues[i].childNodes[1].textContent === 'LINE') {
                var startX = 0, startY = 0, endX = 0, endY = 0;
                //startX = parseFloat(xmlValues[i].childNodes[3].textContent);        // startX, filed1
                //startY = parseFloat(xmlValues[i].childNodes[5].textContent);        // startY, field2
                //endX = parseFloat(xmlValues[i].childNodes[7].textContent);        // endX, field3
                //endY = parseFloat(xmlValues[i].childNodes[9].textContent);        // endY, field4

                if (xmlValues[i].childNodes[19] !== undefined) {
                    //layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
                    //blockName = xmlValues[i].childNodes[15].textContent;                   // blockName, field7
                    objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
                    //closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9
                }

                drawingObject = getDrawingObjectByObjectSequenceNumber(drawingObjects, objNumSeqNum);
                if (drawingObject !== null) {
                    if (item.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                        xmlValues[i].childNodes[3].textContent = ((drawingObject.startPoint.x).toFixed(precisionLimit) * itemScalingFactor + originXOffset).toFixed(precisionLimit);
                        xmlValues[i].childNodes[5].textContent = (((drawingObject.startPoint.y).toFixed(precisionLimit)) * itemScalingFactor + originYOffset).toFixed(precisionLimit);
                        xmlValues[i].childNodes[7].textContent = ((drawingObject.endPoint.x).toFixed(precisionLimit) * itemScalingFactor + originXOffset).toFixed(precisionLimit);
                        xmlValues[i].childNodes[9].textContent = (((drawingObject.endPoint.y).toFixed(precisionLimit)) * itemScalingFactor + originYOffset).toFixed(precisionLimit);
                    } else {
                        //xmlValues[i].childNodes[3].textContent = (drawingObject.startPoint.x) / scalingFactor + originXOffset;
                        //xmlValues[i].childNodes[5].textContent = (mH - (drawingObject.startPoint.y)) / scalingFactor + originYOffset;
                        //xmlValues[i].childNodes[7].textContent = (drawingObject.endPoint.x) / scalingFactor + originXOffset;
                        //xmlValues[i].childNodes[9].textContent = (mH - (drawingObject.endPoint.y)) / scalingFactor + originYOffset;

                        //xmlValues[i].childNodes[3].textContent = (drawingObject.startPoint.x + originXOffset + startingOffsets.x) * itemScalingFactor;
                        //xmlValues[i].childNodes[5].textContent = ((drawingObject.startPoint.y) + originYOffset + startingOffsets.y) * itemScalingFactor;
                        //xmlValues[i].childNodes[7].textContent = (drawingObject.endPoint.x + originXOffset + startingOffsets.x) * itemScalingFactor;
                        //xmlValues[i].childNodes[9].textContent = ((drawingObject.endPoint.y) + originYOffset + startingOffsets.y) * itemScalingFactor;

                        xmlValues[i].childNodes[3].textContent = ((parseFloat((drawingObject.startPoint.x).toFixed(precisionLimit)) + finalOffsetX) * itemScalingFactor).toFixed(precisionLimit);
                        xmlValues[i].childNodes[5].textContent = ((parseFloat((drawingObject.startPoint.y).toFixed(precisionLimit)) + finalOffsetY) * itemScalingFactor).toFixed(precisionLimit);
                        xmlValues[i].childNodes[7].textContent = ((parseFloat((drawingObject.endPoint.x).toFixed(precisionLimit)) + finalOffsetX) * itemScalingFactor).toFixed(precisionLimit);
                        xmlValues[i].childNodes[9].textContent = ((parseFloat((drawingObject.endPoint.y).toFixed(precisionLimit)) + finalOffsetY) * itemScalingFactor).toFixed(precisionLimit);
                    }
                } else {
                    // need to handle null drawing object??
                }
                //startX += originX;
                //startY += originY;
                //endX += originX;
                //endY += originY;

                //tempDrawingObject = new Line(startX, startY, endX, endY, layerNumber, blockName, objNumSeqNum, closedIndicator);
                //tempDrawingObject.setColour(lineColour);
                //if (drawingGroupName !== undefined) {
                //    tempDrawingObject.setDrawingGroupName(drawingGroupName);
                //}


            }

            if (xmlValues[i].childNodes[1].textContent === 'CIRCLE') {
                
                //centreX = parseFloat(xmlValues[i].childNodes[3].textContent);        // centreX, field1
                //centreY = parseFloat(xmlValues[i].childNodes[5].textContent);        // centreY, field2
                //radius = parseFloat(xmlValues[i].childNodes[7].textContent);        // radius, field3

                if (xmlValues[i].childNodes[17] !== undefined && xmlValues[i].childNodes[17].textContent.indexOf('_') > -1) {//MBV data

                    //blockName = xmlValues[i].childNodes[11].textContent;                   // blockName, field5
                    //layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
                    //circleType = parseInt(xmlValues[i].childNodes[15].textContent);         // circleType, field7
                    objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
                    //closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9

                } 

                drawingObject = getDrawingObjectByObjectSequenceNumber(drawingObjects, objNumSeqNum);
                if (drawingObject !== null) {
                    //xmlValues[i].childNodes[3].textContent = drawingObject.centre.x / scalingFactor + originXOffset;
                    //xmlValues[i].childNodes[5].textContent = (mH - drawingObject.centre.y) / scalingFactor + originYOffset;
                    //xmlValues[i].childNodes[7].textContent = drawingObject.radius / scalingFactor;
                    if (item.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                        xmlValues[i].childNodes[3].textContent = (((drawingObject.centre.x).toFixed(precisionLimit) * itemScalingFactor) + originXOffset).toFixed(precisionLimit);// ;
                        xmlValues[i].childNodes[5].textContent = (((drawingObject.centre.y).toFixed(precisionLimit) * itemScalingFactor) + originYOffset).toFixed(precisionLimit);// ;
                        xmlValues[i].childNodes[7].textContent = (drawingObject.radius * itemScalingFactor).toFixed(8);
                    } else {
                        xmlValues[i].childNodes[3].textContent = ((parseFloat((drawingObject.centre.x).toFixed(precisionLimit)) + finalOffsetX) * itemScalingFactor).toFixed(precisionLimit);// ;
                        xmlValues[i].childNodes[5].textContent = ((parseFloat((drawingObject.centre.y).toFixed(precisionLimit)) + finalOffsetY) * itemScalingFactor).toFixed(precisionLimit);// ;
                        xmlValues[i].childNodes[7].textContent = (drawingObject.radius * itemScalingFactor).toFixed(8);
                    }
                    
                } else {
                    // need to handle null drawing object??
                }

                //centreX += originX;
                //centreY += originY;

                //tempDrawingObject = new Circle(centreX, centreY, radius, circleType, fill, blockName, layerNumber, objNumSeqNum, closedIndicator);
                //if (tempDrawingObject.blockName !== undefined && globals.user.getColourDrawings() === true) {
                //    tempDrawingObject.fillColour = utils.getColour(tempDrawingObject.blockName, cabColour);
                //} else {
                //    tempDrawingObject.fillColour = config.DRAWING_COLOURS.WHITE;
                //}
                //if (drawingGroupName !== undefined) {
                //    tempDrawingObject.setDrawingGroupName(drawingGroupName);
                //}
                //if (circleType === CIRCLE_TYPE.OUTER) {
                //    tempDrawingObject.setDrawGroundline(true);
                //}
                //tempDrawingObject.setColour(lineColour);

            }

            if (xmlValues[i].childNodes[1].textContent === 'ELLIPSE') {
                
                //centreX = parseFloat(xmlValues[i].childNodes[3].textContent);        // centreX, field1
                //centreY = parseFloat(xmlValues[i].childNodes[5].textContent);        // centreY, field2
                //radius = parseFloat(xmlValues[i].childNodes[7].textContent);        // radius, field3

                if (xmlValues[i].childNodes[17] !== undefined && xmlValues[i].childNodes[17].textContent.indexOf('_') > -1) {//MBV data

                    //blockName = xmlValues[i].childNodes[11].textContent;                   // blockName, field5
                    //layerNumber = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber, field6
                    //circleType = parseInt(xmlValues[i].childNodes[15].textContent);         // circleType, field7
                    objNumSeqNum = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum, field8
                    //closedIndicator = xmlValues[i].childNodes[19].textContent;                   // closedIndicator, field9

                }

                drawingObject = getDrawingObjectByObjectSequenceNumber(drawingObjects, objNumSeqNum);
                if (drawingObject !== null) {
                    //xmlValues[i].childNodes[3].textContent = drawingObject.centre.x / scalingFactor + originXOffset;
                    //xmlValues[i].childNodes[5].textContent = (mH - drawingObject.centre.y) / scalingFactor + originYOffset;
                    //xmlValues[i].childNodes[7].textContent = drawingObject.radius / scalingFactor;
                    if (item.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                        xmlValues[i].childNodes[3].textContent = (((drawingObject.centre.x).toFixed(precisionLimit) * itemScalingFactor) + originXOffset).toFixed(precisionLimit);// ;
                        xmlValues[i].childNodes[5].textContent = (((drawingObject.centre.y).toFixed(precisionLimit) * itemScalingFactor) + originYOffset).toFixed(precisionLimit);// ;
                    } else {
                        xmlValues[i].childNodes[3].textContent = ((parseFloat((drawingObject.centre.x).toFixed(precisionLimit)) + finalOffsetX) * itemScalingFactor).toFixed(precisionLimit);// ;
                        xmlValues[i].childNodes[5].textContent = ((parseFloat((drawingObject.centre.y).toFixed(precisionLimit)) + finalOffsetY) * itemScalingFactor).toFixed(precisionLimit);// ;
                        
                    }
                    xmlValues[i].childNodes[7].textContent = (drawingObject.halfWidth * itemScalingFactor).toFixed(precisionLimit);
                    xmlValues[i].childNodes[9].textContent = (drawingObject.halfHeight * itemScalingFactor).toFixed(precisionLimit);
                } else {
                    // need to handle null drawing object??
                }

                //centreX += originX;
                //centreY += originY;

                //tempDrawingObject = new Circle(centreX, centreY, radius, circleType, fill, blockName, layerNumber, objNumSeqNum, closedIndicator);
                //if (tempDrawingObject.blockName !== undefined && globals.user.getColourDrawings() === true) {
                //    tempDrawingObject.fillColour = utils.getColour(tempDrawingObject.blockName, cabColour);
                //} else {
                //    tempDrawingObject.fillColour = config.DRAWING_COLOURS.WHITE;
                //}
                //if (drawingGroupName !== undefined) {
                //    tempDrawingObject.setDrawingGroupName(drawingGroupName);
                //}
                //if (circleType === CIRCLE_TYPE.OUTER) {
                //    tempDrawingObject.setDrawGroundline(true);
                //}
                //tempDrawingObject.setColour(lineColour);

            }

            if (xmlValues[i].childNodes[1].textContent === 'ARC') {
                //field1 = parseFloat(xmlValues[i].childNodes[3].textContent);        // centerX
                //field2 = parseFloat(xmlValues[i].childNodes[5].textContent);        // centerY
                //field3 = parseFloat(xmlValues[i].childNodes[7].textContent);        // radius
                //field4 = parseFloat(xmlValues[i].childNodes[9].textContent);        // start angle
                //field5 = parseFloat(xmlValues[i].childNodes[11].textContent);       // end angle
                //field6 = parseInt(xmlValues[i].childNodes[13].textContent) || "";         // layerNumber
                //field7 = xmlValues[i].childNodes[15].textContent;                   // blockName
                field8 = xmlValues[i].childNodes[17].textContent;                   // objNumSeqNum
                //field9 = xmlValues[i].childNodes[18].textContent;                   // closedIndicator


                drawingObject = getDrawingObjectByObjectSequenceNumber(drawingObjects, field8);
                if (drawingObject !== null) {

                    if (item.getAccessoryType() === config.ACCESSORY_TYPES.TAILLIFT) {
                        xmlValues[i].childNodes[3].textContent = ((drawingObject.centre.x).toFixed(precisionLimit) * itemScalingFactor + originXOffset).toFixed(precisionLimit);
                        xmlValues[i].childNodes[5].textContent = ((drawingObject.centre.y).toFixed(precisionLimit) * itemScalingFactor + originYOffset).toFixed(precisionLimit);
                        xmlValues[i].childNodes[7].textContent = (drawingObject.radius * itemScalingFactor).toFixed(precisionLimit);
                    } else {


                        //xmlValues[i].childNodes[3].textContent = drawingObject.centre.x / scalingFactor + originXOffset;
                        //xmlValues[i].childNodes[5].textContent = (mH - drawingObject.centre.y) / scalingFactor + originYOffset;
                        //xmlValues[i].childNodes[7].textContent = drawingObject.radius / scalingFactor;
                        xmlValues[i].childNodes[3].textContent = ((parseFloat((drawingObject.centre.x).toFixed(precisionLimit)) + finalOffsetX) * itemScalingFactor).toFixed(precisionLimit);
                        xmlValues[i].childNodes[5].textContent = ((parseFloat((drawingObject.centre.y).toFixed(precisionLimit)) + finalOffsetY) * itemScalingFactor).toFixed(precisionLimit);
                        xmlValues[i].childNodes[7].textContent = (drawingObject.radius * itemScalingFactor).toFixed(precisionLimit);
                    }
                } else {
                    // need to handle null drawing object??
                }


                //    field1 += originX;
                //    field2 += originY;



                //    tempDrawingObject = new Arc(field1, field2, field3, field4, field5, true, field6, field7, field8, field9);
                //    if (drawingGroupName !== undefined) {
                //        tempDrawingObject.setDrawingGroupName(drawingGroupName);
                //    }
                //    tempDrawingObject.setColour(lineColour);
                //}
                //if (tempDrawingObject !== undefined) {
                //    if (layeringAdjustmentFlag === config.LAYERING_ADJUSTMENT.BRING_TO_FRONT) {
                //        tempDrawingObject.setBringToFront(true);
                //    } else if (layeringAdjustmentFlag === config.LAYERING_ADJUSTMENT.DASH_AND_BRING_TO_FRONT) {
                //        dashItemAndBringForward(tempDrawingObject);
                //    }

                //    tempDrawingObjects.push(tempDrawingObject);
                //}
            }
        }

    }
    return await utils.translateXmlDocToGraphic(xmlDoc).then(function (compressedGraphicBlob) {
        return item.setGraphicBlob(compressedGraphicBlob);
    });
    
    
}

function addHitAreasForCOGElements(drawingObjects) {
    var hitAreas = {};

    var isTrialUser = false;
    //if (globals.user.isTrialUser() === true) {
    //    isTrialUser = true;
    //}

    drawingObjects.forEach(function (drawingObject) {
        if (drawingObject.type === 'cog') {
            if (hitAreas[drawingObject.drawingGroupName] === undefined) {
                /*
                if (style === config.COG_ELEMENT_STYLE.DRAW_LEFT) {
        bgElementTopLeftCorner.x = cogPointCopy.x - radius - 15 - textWidth - (textBuffer * 2);
        //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
        textElementPoint.x = textBuffer;
    } else {
        bgElementTopLeftCorner.x = cogPointCopy.x + radius + 15;
        //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
        textElementPoint.x = textBuffer;
    }
    bgElementTopLeftCorner.y = cogPointCopy.y - textBuffer - (textHeight / 2);
    //textElementPoint.y = cogPointCopy.y;
    textElementPoint.y = textBuffer + (textHeight / 2);

    need to define the hitArea more accurately to include the full label and cog marker
                */
                hitAreas[drawingObject.drawingGroupName] = new HitArea(drawingObject.drawingGroupName, getCOGElementPoints(drawingObject), getCOGElementMaxBounds(drawingObject));
            }
        }
        
    });
    
    Object.keys(hitAreas).forEach(function (key) {
        if (isTrialUser) {
            //bodyCOGElement.addInteraction(offerManager.getCOGElementInteractionObject(config.VALUE_TYPE.BODYMASS));
            hitAreas[key].setInteraction(offerManager.getCOGElementInteractionObject(config.INTERACTION_ID.XXED_OUT_VALUE, key));
        } else {
            hitAreas[key].setInteraction(offerManager.getDrawingGroupInteractionObject(config.INTERACTION_ID.DRAWING_GROUP, key));
        }
        
        hitAreas[key].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(hitAreas[key].interaction.callbacks);
        drawingObjects.push(hitAreas[key]);
    });

    
}

function getCOGElementPoints(cogElement) {
    var points = [];

    var x = cogElement.style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? cogElement.position.x - cogElement.markerRadius - (15/scalingFactor) - cogElement.width - ((cogElement.textBuffer/scalingFactor) * 2) :
        cogElement.position.x - cogElement.markerRadius;
    var y = cogElement.position.y - (cogElement.textBuffer / scalingFactor) - (cogElement.height / 2);

    var width = cogElement.width + (15 / scalingFactor) + ((cogElement.textBuffer / scalingFactor) * 2) + (cogElement.markerRadius * 2);
    var height = cogElement.height + ((cogElement.textBuffer / scalingFactor) * 2);

    points.push({ x: x, y: y });
    points.push({ x: width, y: y });
    points.push({ x: width, y: height });
    points.push({ x: x, y: height });

    return points;
}

function getCOGElementMaxBounds(cogElement) {
    var x = cogElement.style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? cogElement.position.x - cogElement.markerRadius - (15/scalingFactor) - cogElement.width - ((cogElement.textBuffer/scalingFactor) * 2) :
        cogElement.position.x - cogElement.markerRadius;
    var y = cogElement.position.y - (cogElement.textBuffer / scalingFactor) - (cogElement.height / 2);

    var width = cogElement.width + (15 / scalingFactor) + ((cogElement.textBuffer / scalingFactor) * 2) + (cogElement.markerRadius * 2);
    var height = cogElement.height + ((cogElement.textBuffer / scalingFactor) * 2);

    return { x: x, y: y, width: width, height: height };
}

function getPoints(maxBounds) {
    var points = [];

    var x = maxBounds.x !== undefined ? maxBounds.x : maxBounds.position.x;
    var y = maxBounds.y !== undefined ? maxBounds.y : maxBounds.position.y;

    points.push({ x: x, y: y });
    points.push({ x: maxBounds.width, y: y });
    points.push({ x: maxBounds.width, y: maxBounds.height });
    points.push({ x: x, y: maxBounds.height });

    return points;
}

function addHitAreasForDrawingGroups(drawingObjects, renderMode) {
    var hitAreas = {};
    drawingGroupMaxBounds = {};

    drawingObjects.forEach(function (drawingObject) {
        var key = /*drawingObject.clickGroupName ||*/ drawingObject.drawingGroupName;
        if (/*drawingObject.drawingGroupName !== 'IGNORE' &&*/ hitAreas[key] === undefined) {
            // let isDXFImport = offerManager.isCustomItemWithDXFImportedGraphic(drawingObject.drawingGroupName);
            // let drawingGroupX = 0, drawingGroupY = 0;
            // if(isDXFImport) {
            //     // let drawingGroupObject = offerManager.getRigOps().getAccessoryFromPath(drawingObject.drawingGroupName); 
            //     let chassisObjectType = offerManager.getRigOps().getChassisTypeUsingPath(drawingObject.drawingGroupName);
            //     let accessoryType = offerManager.getRigOps().getAccessoryTypeFromPath(drawingObject.drawingGroupName);
            //     drawingGroupX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObjectType, accessoryType)
            //     drawingGroupY = offerManager.getRigOps().getItemStartFromGround(chassisObjectType, accessoryType);
            // }
            var maxBoundsForGroup = getMaxBoundsForGroup(drawingObject.drawingGroupName, drawingObjects, renderMode, false, true, /*isDXFImport, drawingGroupX, drawingGroupY*/);
            hitAreas[key] = new HitArea(key, getPoints(maxBoundsForGroup), maxBoundsForGroup);
            drawingGroupMaxBounds[key] = maxBoundsForGroup;
            if (drawingObject.drawingGroupName.includes(config.OBJECT_TYPES.AXLES) || drawingObject.drawingGroupName.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
                hitAreas[key].setDisableDrag(true);
            }
        }
    });

    if (hitAreas[config.CHASSIS_OBJECT_TYPES.VEHICLE] !== undefined) {
        hitAreas[config.CHASSIS_OBJECT_TYPES.VEHICLE].setDisableDrag(true);
        //if (hitAreas[config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT] !== undefined) {
        //    hitAreas[config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT].setDisableDrag(true);
        //}
    }
    //if (curElevation === config.ELEVATION.TOP) {
    //    curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

    //    });
    //} else {
    if (curVehicle !== null) {
        var frontAxleNumber = curVehicle.getAxlesHolder().getAxle1Front().getNumber();

        var hitAreaId = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + frontAxleNumber;
        if (hitAreas[hitAreaId] !== undefined) {
            hitAreas[hitAreaId].setDisableDrag(true);
        }
        //}
    }
    if (hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER1] !== undefined) {
        hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER1].setDisableDrag(true);
        //if (hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT] !== undefined) {
        //    hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT].setDisableDrag(true);
        //}

        if (hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER2] !== undefined) {
            hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER2].setDisableDrag(true);
            //if (hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT] !== undefined) {
            //    hitAreas[config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT].setDisableDrag(true);
            //}
        }
    }
    if (curTrailer1 !== null) {
        var trailer1Axle1Front = curTrailer1.getAxlesHolder().getAxle1Front();
        if (trailer1Axle1Front !== null) {
            var trailer1FrontAxleNumber = trailer1Axle1Front.getNumber();
            + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + trailer1FrontAxleNumber;
            hitAreaId = config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + curTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + trailer1FrontAxleNumber;
            if (hitAreas[hitAreaId] !== undefined) {
                hitAreas[hitAreaId].setDisableDrag(true);
            }
        }
    }


    Object.keys(hitAreas).forEach(function (key) {
        hitAreas[key].setInteraction(offerManager.getDrawingGroupInteractionObject(config.INTERACTION_ID.DRAWING_GROUP, key));
        hitAreas[key].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(hitAreas[key].interaction.callbacks);

        if (hitAreas[key].disableDrag === false) {
            if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
                hitAreas[key].setDraggable(offerManager.getDraggableObjectInteractionObject(config.INTERACTION_ID.DRAGGABLE_OBJECT, key, getDragabbleObjectAttributeInfoWrappers(key)));
            } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
                hitAreas[key].setDraggable(offerManager.getItemDraggableObjectInteractionObject(config.INTERACTION_ID.DRAGGABLE_OBJECT, key, getDragabbleObjectAttributeInfoWrappers(key)));
            }
            
            hitAreas[key].draggable.callbacks = addGraphicsManagerToInteractionCallbackChain(hitAreas[key].draggable.callbacks);
        }
        drawingObjects.push(hitAreas[key]);
    });

    
}

function getNegativePortionOfDrawing(groupName, item) {
    var x = 0, y = 0;
    

    if (item.getGraphicBlob() && item.getGraphicBlob() !== null && item.getDecompressedGraphicBlob() !== null) {
        var itemXMLDoc = item.getDecompressedGraphicBlob();

        var layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.NONE;


        var itemDrawingObjects = getDrawingObjectsFromXML(itemXMLDoc, 0, 0, undefined, groupName, layeringAdjustmentFlag, true);

        

        itemDrawingObjects.forEach(function (displayObject) {
            if (displayObject.drawingGroupName === groupName) {
                if (displayObject.type === 'poly') {
                    displayObject.pointsArray.forEach(function (point) {
                        updateNegativePoint(point);
                    });
                } else if (displayObject.type === 'circle') {
                    //var circleRadius = scaleValue(displayObject.radius);

                    //var scaledCopy = scalePointAsCopy(displayObject.centre);
                    //flipMainDrawingAreaAxisPoint(scaledCopy);
                    //updateMaxBounds(scaledCopy);
                    //updateMaxBounds({ x: scaledCopy.x + circleRadius, y: scaledCopy.y + circleRadius });
                    //updateMaxBounds({ x: scaledCopy.x - circleRadius, y: scaledCopy.y - circleRadius });

                    updateNegativePoint(displayObject.centre);
                    updateNegativePoint({ x: displayObject.centre.x + displayObject.radius, y: displayObject.centre.y + displayObject.radius });
                    updateNegativePoint({ x: displayObject.centre.x - displayObject.radius, y: displayObject.centre.y - displayObject.radius });
                } else if (displayObject.type === 'ellipse') {

                    updateNegativePoint(displayObject.centre);
                    updateNegativePoint({ x: displayObject.centre.x + displayObject.halfWidth, y: displayObject.centre.y + displayObject.halfHeight });
                    updateNegativePoint({ x: displayObject.centre.x - displayObject.halfWidth, y: displayObject.centre.y - displayObject.halfHeight });
                } else if (displayObject.type === 'arc') {
                    //updateMaxBounds(displayObject.centre, ignoreNegativeX, ignoreNegativeY);
                    var startPoint = displayObject.startPoint;
                    var endPoint = displayObject.endPoint;
                    updateNegativePoint({ x: startPoint.x, y: startPoint.y });
                    updateNegativePoint({ x: endPoint.x, y: endPoint.y });

                    var maxedOutLeft = false,
                        maxedOutRight = false,
                        maxedOutTop = false,
                        maxedOutBottom = false;


                    if (displayObject.startAngle >= 0 && displayObject.startAngle < 90) {
                        //bottom right

                        var startAnglePlusSweepAngle = (displayObject.startAngle + displayObject.sweepAngle) % 360;

                        if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                            //bottom right
                            // do nothing
                        } else if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                            //bottom left
                            maxedOutBottom = true;
                        } else if (displayObject.endAngle >= 180 && startAnglePlusSweepAngle < 270) {
                            //top left
                            maxedOutBottom = true;
                            maxedOutLeft = true;
                        } else {
                            //top right
                            maxedOutBottom = true;
                            maxedOutLeft = true;
                            maxedOutTop = true;
                        }
                    } else if (displayObject.startAngle >= 90 && displayObject.startAngle < 180) {
                        //bottom left

                        if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                            //bottom left
                            // do nothing
                        } else if (displayObject.endAngle >= 180 && displayObject.endAngle < 270) {
                            //top left
                            maxedOutLeft = true;
                        } else if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                            //top right
                            maxedOutLeft = true;
                            maxedOutTop = true;
                        } else {
                            //bottom right
                            maxedOutLeft = true;
                            maxedOutTop = true;
                            maxedOutBottom = true;
                        }

                    } else if (displayObject.startAngle >= 180 && displayObject.startAngle < 270) {
                        //top left

                        if (displayObject.endAngle >= 180 && displayObject.endAngle < 270) {
                            //top left
                            // do nothing
                        } else if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                            //top right
                            maxedOutTop = true;
                        } else if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                            //bottom right
                            maxedOutTop = true;
                            maxedOutRight = true;
                        } else {
                            //bottom left
                            maxedOutTop = true;
                            maxedOutRight = true;
                            maxedOutBottom = true;
                        }

                    } else if (displayObject.startAngle >= 270 && displayObject.startAngle <= 360) {
                        //top right

                        if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                            //top right
                            // do nothing
                        } else if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                            //bottom right
                            maxedOutRight = true;
                        } else if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                            //bottom left
                            maxedOutRight = true;
                            maxedOutBottom = true;
                        } else {
                            //top left
                            maxedOutRight = true;
                            maxedOutBottom = true;
                            maxedOutLeft = true;
                        }

                    }

                    if (maxedOutLeft) {
                        //tempLeftMost = Math.Min(tempLeftMost, row("field_1") - row("field_3"))
                        updateNegativePoint({ x: displayObject.centre.x - displayObject.radius, y: 0 });
                    }
                    if (maxedOutRight) {
                        //tempRightMost = Math.Max(tempRightMost, row("field_1") + row("field_3"))
                        updateNegativePoint({ x: displayObject.centre.x + displayObject.radius, y: 0 });
                    }
                    if (maxedOutTop) {
                        //tempTopMost = Math.Max(tempTopMost, row("field_2") + row("field_3"))
                        updateNegativePoint({ x: 0, y: displayObject.centre.y + displayObject.radius });
                    }
                    if (maxedOutBottom) {
                        //tempBottomMost = Math.Min(tempBottomMost, row("field_2") - row("field_3"))
                        updateNegativePoint({ x: 0, y: displayObject.centre.y - displayObject.radius });
                    }


                } else if (displayObject.type === 'line') {
                    updateNegativePoint(displayObject.startPoint);
                    updateNegativePoint(displayObject.endPoint);
                } else if (displayObject.type === 'cog') {
                    //#unfinished, need to implement for cog elements

                } else if (displayObject.type === 'rect') {
                    updateNegativePoint(displayObject.position);
                    updateNegativePoint({ x: displayObject.position.x + displayObject.width, y: displayObject.position.y + displayObject.height });
                }
            }

        });
    }
    function updateNegativePoint(point) {
        if (point.x < 0 && point.x < x) {
            x = point.x;
        }
        if (point.y < 0 && point.y < y) {
            y = point.y;
        }
        
    }
    
    return { x: x, y: y };
}

function getOriginOffsetsFromDrawing(groupName, item) {
    var x = 9999999999, y = 9999999999;
    var positiveX = 9999999999, positiveY = 9999999999;

    if (item.getGraphicBlob() && item.getGraphicBlob() !== null && item.getDecompressedGraphicBlob() !== null) {
        var itemXMLDoc = item.getDecompressedGraphicBlob();

        var layeringAdjustmentFlag = config.LAYERING_ADJUSTMENT.NONE;


        var itemDrawingObjects = getDrawingObjectsFromXML(itemXMLDoc, 0, 0, undefined, groupName, layeringAdjustmentFlag, true);

        

        itemDrawingObjects.forEach(function (displayObject) {
            if (displayObject.drawingGroupName === groupName) {
                if (displayObject.type === 'poly') {
                    displayObject.pointsArray.forEach(function (point) {
                        updateNegativePoint(point);
                    });
                } else if (displayObject.type === 'circle') {
                    //var circleRadius = scaleValue(displayObject.radius);

                    //var scaledCopy = scalePointAsCopy(displayObject.centre);
                    //flipMainDrawingAreaAxisPoint(scaledCopy);
                    //updateMaxBounds(scaledCopy);
                    //updateMaxBounds({ x: scaledCopy.x + circleRadius, y: scaledCopy.y + circleRadius });
                    //updateMaxBounds({ x: scaledCopy.x - circleRadius, y: scaledCopy.y - circleRadius });

                    updateNegativePoint(displayObject.centre);
                    updateNegativePoint({ x: displayObject.centre.x + displayObject.radius, y: displayObject.centre.y + displayObject.radius });
                    updateNegativePoint({ x: displayObject.centre.x - displayObject.radius, y: displayObject.centre.y - displayObject.radius });
                } else if (displayObject.type === 'ellipse') {

                    updateNegativePoint(displayObject.centre);
                    updateNegativePoint({ x: displayObject.centre.x + displayObject.halfWidth, y: displayObject.centre.y + displayObject.halfHeight });
                    updateNegativePoint({ x: displayObject.centre.x - displayObject.halfWidth, y: displayObject.centre.y - displayObject.halfHeight });
                } else if (displayObject.type === 'arc') {
                    //updateMaxBounds(displayObject.centre, ignoreNegativeX, ignoreNegativeY);
                    var startPoint = displayObject.startPoint;
                    var endPoint = displayObject.endPoint;
                    updateNegativePoint({ x: startPoint.x, y: startPoint.y });
                    updateNegativePoint({ x: endPoint.x, y: endPoint.y });

                    var maxedOutLeft = false,
                        maxedOutRight = false,
                        maxedOutTop = false,
                        maxedOutBottom = false;


                    if (displayObject.startAngle >= 0 && displayObject.startAngle < 90) {
                        //bottom right

                        var startAnglePlusSweepAngle = (displayObject.startAngle + displayObject.sweepAngle) % 360;

                        if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                            //bottom right
                            // do nothing
                        } else if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                            //bottom left
                            maxedOutBottom = true;
                        } else if (displayObject.endAngle >= 180 && startAnglePlusSweepAngle < 270) {
                            //top left
                            maxedOutBottom = true;
                            maxedOutLeft = true;
                        } else {
                            //top right
                            maxedOutBottom = true;
                            maxedOutLeft = true;
                            maxedOutTop = true;
                        }
                    } else if (displayObject.startAngle >= 90 && displayObject.startAngle < 180) {
                        //bottom left

                        if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                            //bottom left
                            // do nothing
                        } else if (displayObject.endAngle >= 180 && displayObject.endAngle < 270) {
                            //top left
                            maxedOutLeft = true;
                        } else if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                            //top right
                            maxedOutLeft = true;
                            maxedOutTop = true;
                        } else {
                            //bottom right
                            maxedOutLeft = true;
                            maxedOutTop = true;
                            maxedOutBottom = true;
                        }

                    } else if (displayObject.startAngle >= 180 && displayObject.startAngle < 270) {
                        //top left

                        if (displayObject.endAngle >= 180 && displayObject.endAngle < 270) {
                            //top left
                            // do nothing
                        } else if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                            //top right
                            maxedOutTop = true;
                        } else if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                            //bottom right
                            maxedOutTop = true;
                            maxedOutRight = true;
                        } else {
                            //bottom left
                            maxedOutTop = true;
                            maxedOutRight = true;
                            maxedOutBottom = true;
                        }

                    } else if (displayObject.startAngle >= 270 && displayObject.startAngle <= 360) {
                        //top right

                        if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                            //top right
                            // do nothing
                        } else if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                            //bottom right
                            maxedOutRight = true;
                        } else if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                            //bottom left
                            maxedOutRight = true;
                            maxedOutBottom = true;
                        } else {
                            //top left
                            maxedOutRight = true;
                            maxedOutBottom = true;
                            maxedOutLeft = true;
                        }

                    }

                    if (maxedOutLeft) {
                        //tempLeftMost = Math.Min(tempLeftMost, row("field_1") - row("field_3"))
                        updateNegativePoint({ x: displayObject.centre.x - displayObject.radius, y: 0 });
                    }
                    if (maxedOutRight) {
                        //tempRightMost = Math.Max(tempRightMost, row("field_1") + row("field_3"))
                        updateNegativePoint({ x: displayObject.centre.x + displayObject.radius, y: 0 });
                    }
                    if (maxedOutTop) {
                        //tempTopMost = Math.Max(tempTopMost, row("field_2") + row("field_3"))
                        updateNegativePoint({ x: 0, y: displayObject.centre.y + displayObject.radius });
                    }
                    if (maxedOutBottom) {
                        //tempBottomMost = Math.Min(tempBottomMost, row("field_2") - row("field_3"))
                        updateNegativePoint({ x: 0, y: displayObject.centre.y - displayObject.radius });
                    }


                } else if (displayObject.type === 'line') {
                    updateNegativePoint(displayObject.startPoint);
                    updateNegativePoint(displayObject.endPoint);
                } else if (displayObject.type === 'cog') {
                    //#unfinished, need to implement for cog elements

                } else if (displayObject.type === 'rect') {
                    updateNegativePoint(displayObject.position);
                    updateNegativePoint({ x: displayObject.position.x + displayObject.width, y: displayObject.position.y + displayObject.height });
                }
            }

        });
    }
    function updateNegativePoint(point) {
        if (point.x < x) {
            x = point.x;
        }
        if (point.y < y) {
            y = point.y;
        }
        // if (point.x < 0 && point.x < x) {
        //     x = point.x;
        // }
        // if (point.y < 0 && point.y < y) {
        //     y = point.y;
        // }
        // if(point.x > 0 && point.x < positiveX) {
        //     positiveX = point.x;
        // }
        // if(point.y > 0 && point.y < positiveY) {
        //     positiveY = point.y;
        // }
    }
    let positiveXToUse = 0, positiveYToUse = 0;
    if(positiveX !== 9999999999) {
        positiveXToUse = positiveX;
    }
    if(positiveY !== 9999999999) {
        positiveYToUse = positiveY;
    }
    let xToUse = 0, yToUse = 0;
    if(x !== 9999999999) {
        xToUse = x;
    }
    if(y !== 9999999999) {
        yToUse = y;
    }
    return { x: xToUse, y: yToUse, positiveX: positiveXToUse, positiveY: positiveYToUse };
}

function getMaxBoundsForGroup(groupName, displayObjs, renderMode, ignoreNegativeX, ignoreNegativeY/*, isDXFImport, drawingGroupX, drawingGroupY*/) {

    var x = 99999, y = 99999, width = 0, height = 0, lowestWidth = 99999, highestWidth = -99999, lowestHeight = 99999, highestHeight = -99999;
    var groupPresent = false;

    displayObjs.forEach(function (displayObject) {
        if (displayObject.drawingGroupName === groupName) {
            groupPresent = true;
            if (displayObject.type === 'poly') {
                displayObject.pointsArray.forEach(function (point) {
                    updateMaxBounds(point, ignoreNegativeX, ignoreNegativeY);
                });
            } else if (displayObject.type === 'circle') {
                //var circleRadius = scaleValue(displayObject.radius);

                //var scaledCopy = scalePointAsCopy(displayObject.centre);
                //flipMainDrawingAreaAxisPoint(scaledCopy);
                //updateMaxBounds(scaledCopy);
                //updateMaxBounds({ x: scaledCopy.x + circleRadius, y: scaledCopy.y + circleRadius });
                //updateMaxBounds({ x: scaledCopy.x - circleRadius, y: scaledCopy.y - circleRadius });

                updateMaxBounds(displayObject.centre, ignoreNegativeX, ignoreNegativeY);
                updateMaxBounds({ x: displayObject.centre.x + displayObject.radius, y: displayObject.centre.y + displayObject.radius }, ignoreNegativeX, ignoreNegativeY);
                updateMaxBounds({ x: displayObject.centre.x - displayObject.radius, y: displayObject.centre.y - displayObject.radius }, ignoreNegativeX, ignoreNegativeY);
            } else if (displayObject.type === 'ellipse') {
                
                updateMaxBounds(displayObject.centre, ignoreNegativeX, ignoreNegativeY);
                updateMaxBounds({ x: displayObject.centre.x + displayObject.halfWidth, y: displayObject.centre.y + displayObject.halfHeight }, ignoreNegativeX, ignoreNegativeY);
                updateMaxBounds({ x: displayObject.centre.x - displayObject.halfWidth, y: displayObject.centre.y - displayObject.halfHeight }, ignoreNegativeX, ignoreNegativeY);
            } else if (displayObject.type === 'arc') {
                //updateMaxBounds(displayObject.centre, ignoreNegativeX, ignoreNegativeY);
                var startPoint = displayObject.startPoint;
                var endPoint = displayObject.endPoint;
                updateMaxBounds({ x: startPoint.x, y: startPoint.y }, ignoreNegativeX, ignoreNegativeY);
                updateMaxBounds({ x: endPoint.x, y: endPoint.y }, ignoreNegativeX, ignoreNegativeY);

                var maxedOutLeft = false,
                    maxedOutRight = false,
                    maxedOutTop = false,
                    maxedOutBottom = false;


                if (displayObject.startAngle >= 0 && displayObject.startAngle < 90) {
                    //bottom right

                    var startAnglePlusSweepAngle = (displayObject.startAngle + displayObject.sweepAngle) % 360;

                    if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                        //bottom right
                        // do nothing
                    } else if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                        //bottom left
                        maxedOutBottom = true;
                    } else if (displayObject.endAngle >= 180 && startAnglePlusSweepAngle < 270) {
                        //top left
                        maxedOutBottom = true;
                        maxedOutLeft = true;
                    }else {
                        //top right
                        maxedOutBottom = true;
                        maxedOutLeft = true;
                        maxedOutTop = true;
                    }
                } else if (displayObject.startAngle >= 90 && displayObject.startAngle < 180) {
                    //bottom left
                    
                    if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                        //bottom left
                        // do nothing
                    } else if (displayObject.endAngle >= 180 && displayObject.endAngle < 270) {
                        //top left
                        maxedOutLeft = true;
                    } else if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                        //top right
                        maxedOutLeft = true;
                        maxedOutTop = true;
                    } else {
                        //bottom right
                        maxedOutLeft = true;
                        maxedOutTop = true;
                        maxedOutBottom = true;
                    }

            } else if (displayObject.startAngle >= 180 && displayObject.startAngle < 270) {
                //top left

                if (displayObject.endAngle >= 180 && displayObject.endAngle < 270) {
                    //top left
                    // do nothing
                } else if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                    //top right
                    maxedOutTop = true;
                } else if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                    //bottom right
                    maxedOutTop = true;
                    maxedOutRight = true;
                } else {
                    //bottom left
                    maxedOutTop = true;
                    maxedOutRight = true;
                    maxedOutBottom = true;
                }

            } else if (displayObject.startAngle >= 270 && displayObject.startAngle <= 360) {
                //top right

                if (displayObject.endAngle >= 270 && displayObject.endAngle < 360) {
                    //top right
                    // do nothing
                } else if (displayObject.endAngle >= 0 && displayObject.endAngle < 90) {
                    //bottom right
                    maxedOutRight = true;
                } else if (displayObject.endAngle >= 90 && displayObject.endAngle < 180) {
                    //bottom left
                    maxedOutRight = true;
                    maxedOutBottom = true;
                } else {
                    //top left
                    maxedOutRight = true;
                    maxedOutBottom = true;
                    maxedOutLeft = true;
                }

            }

            if (maxedOutLeft) {
                //tempLeftMost = Math.Min(tempLeftMost, row("field_1") - row("field_3"))
                updateMaxBounds({ x: displayObject.centre.x - displayObject.radius, y: 0 }, ignoreNegativeX, ignoreNegativeY);
            }
            if (maxedOutRight) {
                //tempRightMost = Math.Max(tempRightMost, row("field_1") + row("field_3"))
                updateMaxBounds({ x: displayObject.centre.x + displayObject.radius, y: 0 }, ignoreNegativeX, ignoreNegativeY);
            }
            if (maxedOutTop) {
                //tempTopMost = Math.Max(tempTopMost, row("field_2") + row("field_3"))
                updateMaxBounds({ x: 0, y: displayObject.centre.y + displayObject.radius }, ignoreNegativeX, ignoreNegativeY);
            }
            if (maxedOutBottom) {
                //tempBottomMost = Math.Min(tempBottomMost, row("field_2") - row("field_3"))
                updateMaxBounds({ x: 0, y: displayObject.centre.y - displayObject.radius }, ignoreNegativeX, ignoreNegativeY);
            }

                /*
                Dim startAngle, sweepAngle, startAnglePlusSweepAngle, startX, startY, endX, endY As Double
                Dim maxedOutLeft, maxedOutRight, maxedOutTop, maxedOutBottom As Boolean

                startAngle = row("field_4") Mod 360
                sweepAngle = row("field_5") Mod 360
                startAnglePlusSweepAngle = (startAngle + sweepAngle) Mod 360
                maxedOutLeft = False
                maxedOutRight = False
                maxedOutTop = False
                maxedOutBottom = False

                If startAngle >= 0 And startAngle < 90 Then
                    'bottom right

                    startX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAngle)))
                    startY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAngle)))

                    If startAnglePlusSweepAngle >= 0 And startAnglePlusSweepAngle < 90 Then
                        'bottom right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                    ElseIf startAnglePlusSweepAngle >= 90 And startAnglePlusSweepAngle < 180 Then
                        'bottom left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutBottom = True
                    ElseIf startAnglePlusSweepAngle >= 180 And startAnglePlusSweepAngle < 270 Then
                        'top left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutBottom = True
                        maxedOutLeft = True
                    Else
                        'top right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutBottom = True
                        maxedOutLeft = True
                        maxedOutTop = True
                    End If

                ElseIf startAngle >= 90 And startAngle < 180 Then
                    'bottom left

                    startX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAngle)))
                    startY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAngle)))

                    If startAnglePlusSweepAngle >= 90 And startAnglePlusSweepAngle < 180 Then
                        'bottom left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                    ElseIf startAnglePlusSweepAngle >= 180 And startAnglePlusSweepAngle < 270 Then
                        'top left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutLeft = True
                    ElseIf startAnglePlusSweepAngle >= 270 And startAnglePlusSweepAngle < 360 Then
                        'top right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutLeft = True
                        maxedOutTop = True
                    Else
                        'bottom right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutLeft = True
                        maxedOutTop = True
                        maxedOutBottom = True
                    End If

                ElseIf startAngle >= 180 And startAngle < 270 Then
                    'top left

                    startX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAngle)))
                    startY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAngle)))

                    If startAnglePlusSweepAngle >= 180 And startAnglePlusSweepAngle < 270 Then
                        'top left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                    ElseIf startAnglePlusSweepAngle >= 270 And startAnglePlusSweepAngle < 360 Then
                        'top right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutTop = True
                    ElseIf startAnglePlusSweepAngle >= 0 And startAnglePlusSweepAngle < 90 Then
                        'bottom right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutTop = True
                        maxedOutRight = True
                    Else
                        'bottom left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutTop = True
                        maxedOutRight = True
                        maxedOutBottom = True
                    End If

                ElseIf startAngle >= 270 And startAngle <= 360 Then
                    'top right

                    startX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAngle)))
                    startY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAngle)))

                    If startAnglePlusSweepAngle >= 270 And startAnglePlusSweepAngle < 360 Then
                        'top right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                    ElseIf startAnglePlusSweepAngle >= 0 And startAnglePlusSweepAngle < 90 Then
                        'bottom right
                        endX = row("field_1") + row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutRight = True
                    ElseIf startAnglePlusSweepAngle >= 90 And startAnglePlusSweepAngle < 180 Then
                        'bottom left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") - row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutRight = True
                        maxedOutBottom = True
                    Else
                        'top left
                        endX = row("field_1") - row("field_3") * Math.Abs(Math.Cos(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        endY = row("field_2") + row("field_3") * Math.Abs(Math.Sin(UtilGeneral.ConvertDegreesToRadians(startAnglePlusSweepAngle)))
                        maxedOutRight = True
                        maxedOutBottom = True
                        maxedOutLeft = True
                    End If

                End If

                tempLeftMost = Math.Min(startX, endX)
                tempRightMost = Math.Max(startX, endX)
                tempTopMost = Math.Max(startY, endY)
                tempBottomMost = Math.Min(startY, endY)

                If maxedOutLeft Then
                    tempLeftMost = Math.Min(tempLeftMost, row("field_1") - row("field_3"))
                End If
                If maxedOutRight Then
                    tempRightMost = Math.Max(tempRightMost, row("field_1") + row("field_3"))
                End If
                If maxedOutTop Then
                    tempTopMost = Math.Max(tempTopMost, row("field_2") + row("field_3"))
                End If
                If maxedOutBottom Then
                    tempBottomMost = Math.Min(tempBottomMost, row("field_2") - row("field_3"))
                End If
                */
            } else if (displayObject.type === 'line') {
                updateMaxBounds(displayObject.startPoint, ignoreNegativeX, ignoreNegativeY);
                updateMaxBounds(displayObject.endPoint, ignoreNegativeX, ignoreNegativeY);
            } else if (displayObject.type === 'cog') {
                //#unfinished, need to implement for cog elements

            } else if (displayObject.type === 'rect') {
                updateMaxBounds(displayObject.position, ignoreNegativeX, ignoreNegativeY);
                updateMaxBounds({ x: displayObject.position.x + displayObject.width, y: displayObject.position.y + displayObject.height }, ignoreNegativeX, ignoreNegativeY);
            }
        }

    });

    if (groupPresent === true) {
        var widthToUse, heightToUse;
        


        // return { x: Math.min(x,0), y: Math.min(y,0), width: Math.max(widthToUse, widthToUse + x), height: Math.max(heightToUse, heightToUse + y) };
        if(renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
            if (ignoreNegativeX) {
                widthToUse = width; 
            } else {
                widthToUse = Math.abs(highestWidth - lowestWidth);
            }
            if (ignoreNegativeY) {
                heightToUse = height;
            } else {
                heightToUse = Math.abs(highestHeight - lowestHeight);
            }    
            
            return { x: Math.min(x,0), y: Math.min(y,0), width: widthToUse, height: heightToUse };
        } /*else if(isDXFImport && renderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
            if (ignoreNegativeX) {
                widthToUse = width; 
            } else {
                widthToUse = highestWidth;
            }
            if (ignoreNegativeY) {
                heightToUse = height;
            } else {
                heightToUse = highestHeight;
            }    
            // return { x: Math.min(x,0), y: Math.min(y,0), width: widthToUse, height: heightToUse };
            return { x:  drawingGroupX, y: drawingGroupY, width: widthToUse - drawingGroupX, height: heightToUse - drawingGroupY };
        }*/ else {
            if (ignoreNegativeX) {
                widthToUse = width - x; 
            } else {
                widthToUse = Math.abs(highestWidth - lowestWidth);
            }
            if (ignoreNegativeY) {
                heightToUse = height - y;
            } else {
                heightToUse = Math.abs(highestHeight - lowestHeight);
            }

            return { x: x, y: y, width: widthToUse, height: heightToUse };
        }
        
    } else {
        return null;
    }


    function updateMaxBounds(point, ignoreNegativeX, ignoreNegativeY) {
        if (point.x < x) {
            //x = point.x;
            if (point.x < 0 && ignoreNegativeX === true) {
                x = 0;
            } else {
                x = point.x;
            }
        }
        if (point.y < y) {
            if (point.y < 0 && ignoreNegativeY === true) {
                y = 0;
            } else {
                y = point.y;
            }

        }
        if (point.x > width && ignoreNegativeX === true) {
            width = point.x;
        } else {
            if (point.x > highestWidth) {
                highestWidth = point.x;
            } else if (point.x < lowestWidth) {
                lowestWidth = point.x;
            }
        }
        if (point.y > height && ignoreNegativeY === true) {
            height = point.y;
        } else {
            if (point.y > highestHeight) {
                highestHeight = point.y;
            } else if (point.y < lowestHeight) {
                lowestHeight = point.y;
            }
        }
    }
}

function getDragabbleObjectAttributeInfoWrappers(elementId) {

    var attributeInfoWrappers = [], drawingOffset, tempBody, tempHooklift;
    var portionOverhangingFront, portionOverhangingLeft;
    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
        
        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(elementId);
        portionOverhangingFront = curVehicle.getPortionOfOverallLengthOverhangingFront();
        portionOverhangingLeft = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft();
        if (elementId.includes(config.ACCESSORY_TYPES.CRANE)) {
            var craneRefPointStartPoint = 0;
            var craneRefPointValueType = config.VALUE_TYPE.CRANECABGAP;
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                if (globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB) {
                    craneRefPointStartPoint = chassisObjectToUse.getBumperToBackOfCab();
                } else {
                    craneRefPointValueType = config.VALUE_TYPE.CRANEFROMAXLE1FRONT;
                    craneRefPointStartPoint = chassisObjectToUse.getFrontOverhang();
                }
                

            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                craneRefPointStartPoint = offerManager.getRigOps().getTrailer1FrontOverhangStart();
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                craneRefPointStartPoint = offerManager.getRigOps().getTrailer2FrontOverhangStart();
            }
            let negativeXOrZero = Math.abs(getNegativePortionOfDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x);
            let positiveXOffset = getOriginOffsetsFromDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x;
            if(positiveXOffset > 0) {
                drawingOffset = -positiveXOffset;
            }else {
                drawingOffset = negativeXOrZero;
            }
            // drawingOffset = Math.abs(getNegativePortionOfDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x);

            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + craneRefPointValueType, offerManager.getAttributeGroup(elementId + '.' + craneRefPointValueType).value.value, craneRefPointStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, undefined, drawingOffset));

            if (curElevation === config.ELEVATION.TOP) {
                // var craneFromLeftOfChassisRearWidthStartPoint = portionOverhangingLeft + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse);

                // attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.CRANEFROMLEFTOFVEHICLEREARWIDTH, chassisObjectToUse.getAccessoryHolder().getCrane().getFromLeftOfVehicleRearWidth(), craneFromLeftOfChassisRearWidthStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
                var craneFromVehicleCentreLineStartPoint = portionOverhangingLeft + offerManager.getRigOps().getTopViewCentreLine();

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.CRANEFROMCENTREOFVEHICLE, chassisObjectToUse.getAccessoryHolder().getCrane().getFromCentreOfVehicle(), craneFromVehicleCentreLineStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
            } else if (curElevation === config.ELEVATION.SIDE) {
                var craneAboveBelowChassisStartPoint = 0;

                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    craneAboveBelowChassisStartPoint = chassisObjectToUse.getChassisHeightMax();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    craneAboveBelowChassisStartPoint = offerManager.getActiveOffer().getRig().getTrailer1().getChassisHeight();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    craneAboveBelowChassisStartPoint = offerManager.getActiveOffer().getRig().getTrailer2().getChassisHeight();
                }

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.CRANEABOVEBELOWCHASSIS, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.CRANEABOVEBELOWCHASSIS).value.value, craneAboveBelowChassisStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
            }

        } else if (elementId.includes(config.ACCESSORY_TYPES.OTHER)) {
            var otherRefStartPoint = 0;
            var otherRefPointValueType = config.VALUE_TYPE.OTHERCABGAP;
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                if (globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB) {
                    otherRefStartPoint = chassisObjectToUse.getBumperToBackOfCab();
                } else {
                    otherRefPointValueType = config.VALUE_TYPE.OTHERFROMAXLE1FRONT;
                    otherRefStartPoint = chassisObjectToUse.getFrontOverhang();
                    //if (offerManager.getRigOps().getOtherFromPathById(elementId, globals.getIdOrNumberFromPath(elementId, 1)).getAccessorySubType() === config.ACCESSORY_TYPES.TOOLBOX) {
                    //    otherRefStartPoint += chassisObjectToUse.getAxle1FrontToBackOfCab() + 500;//offerManager.getRigOps().getOtherFromPathById(elementId, globals.getIdOrNumberFromPath(elementId, 1));
                    //}
                }
                
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                otherRefStartPoint = offerManager.getRigOps().getTrailer1FrontOverhangStart();
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                otherRefStartPoint = offerManager.getRigOps().getTrailer2FrontOverhangStart();//#unfinished, getTrailer2FrontOverhangStart not implemented
            }
            let negativeXOrZero = Math.abs(getNegativePortionOfDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x);
            let positiveXOffset = getOriginOffsetsFromDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x;
            if(positiveXOffset > 0) {
                drawingOffset = -positiveXOffset;
            }else {
                drawingOffset = negativeXOrZero;
            }
            // drawingOffset = Math.abs(getNegativePortionOfDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x);
            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + otherRefPointValueType, offerManager.getAttributeGroup(elementId + '.' + otherRefPointValueType).value.value, otherRefStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, undefined, drawingOffset));
            

            if (curElevation === config.ELEVATION.SIDE) {
                var otherAboveBelowChassisStartPoint = 0;

                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    otherAboveBelowChassisStartPoint = chassisObjectToUse.getChassisHeightMax();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    otherAboveBelowChassisStartPoint = offerManager.getActiveOffer().getRig().getTrailer1().getChassisHeight();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    otherAboveBelowChassisStartPoint = offerManager.getActiveOffer().getRig().getTrailer2().getChassisHeight();
                }

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.OTHERABOVEBELOWCHASSIS, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.OTHERABOVEBELOWCHASSIS).value.value, otherAboveBelowChassisStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
            } else {
                var otherFromVehicleCentreLineStartPoint = portionOverhangingLeft + offerManager.getRigOps().getTopViewCentreLine();
                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.OTHERFROMCENTREOFVEHICLE, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.OTHERFROMCENTREOFVEHICLE).value.value, otherFromVehicleCentreLineStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
            }


        } else if (elementId.includes(config.ACCESSORY_TYPES.FRIDGE)) {

            if (curElevation === config.ELEVATION.SIDE) {
                var fridgeMountingPositionStartPoint = 0;

                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    fridgeMountingPositionStartPoint = chassisObjectToUse.getChassisHeightMax() + chassisObjectToUse.getAccessoryHolder().getBody().getActualExternalHeight();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    fridgeMountingPositionStartPoint = offerManager.getActiveOffer().getRig().getTrailer1().getChassisHeight() + chassisObjectToUse.getAccessoryHolder().getBody().getActualExternalHeight();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    fridgeMountingPositionStartPoint = offerManager.getActiveOffer().getRig().getTrailer2().getChassisHeight() + chassisObjectToUse.getAccessoryHolder().getBody().getActualExternalHeight();
                }

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.FRIDGEMOUNTINGPOSITION, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.FRIDGEMOUNTINGPOSITION).value.value, fridgeMountingPositionStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, 0, 'minus'));
            } else {
                var fridgeFromVehicleCentreLineStartPoint = portionOverhangingLeft + offerManager.getRigOps().getTopViewCentreLine();

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.FRIDGEFROMCENTREOFVEHICLE, chassisObjectToUse.getAccessoryHolder().getFridge().getFromCentreOfVehicle(), fridgeFromVehicleCentreLineStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
            }
            var fridgeFromFrontOfBodyStartPosition = 0;
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                fridgeFromFrontOfBodyStartPosition = chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName());
                
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                fridgeFromFrontOfBodyStartPosition = offerManager.getRigOps().getTrailer1FrontOverhangStart() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName());
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                fridgeFromFrontOfBodyStartPosition = offerManager.getRigOps().getTrailer2FrontOverhangStart() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName());
            }

            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.FRIDGEFROMFRONTOFBODY, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.FRIDGEFROMFRONTOFBODY).value.value, fridgeFromFrontOfBodyStartPosition, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, 'minus'));
        } else if (elementId.includes(config.ACCESSORY_TYPES.FIFTH_WHEEL)) {

            var fifthWheelOffsetStartPoint = 0;

            //curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical()

            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                fifthWheelOffsetStartPoint = chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart() + chassisObjectToUse.getWheelbaseTheoretical();
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                fifthWheelOffsetStartPoint = 0; //#unfinished, need to implement trailer 1 fifth wheel ref point x axis calculation
            }

            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.FIFTHWHEELOFFSET).value.value, fifthWheelOffsetStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, 'minus'));

        } else if (elementId.includes(config.ACCESSORY_TYPES.HITCH)) {
            var tempHitch = chassisObjectToUse.getAccessoryHolder().getHitch();
            var hitchOffsetStartPoint = 0;

            //curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical()

            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                hitchOffsetStartPoint = chassisObjectToUse.getWheelbaseTheoreticalEnd() + chassisObjectToUse.getWheelbaseTheoreticalEndToRearMostDrivenAxle() /*- chassisObjectToUse.getAccessoryHolder().getHitch().getMountingLength() - chassisObjectToUse.getAccessoryHolder().getHitch().getCouplingOffset()*/;
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                hitchOffsetStartPoint = 0; //#unfinished, need to implement trailer ref point x axis calculation
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                hitchOffsetStartPoint = 0; //#unfinished, need to implement trailer ref point x axis calculation
            }
            
            drawingOffset = 0;
            if (curElevation === config.ELEVATION.SIDE) {
                let negativeXOrZero = Math.abs(getNegativePortionOfDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x);
                let positiveXOffset = getOriginOffsetsFromDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x;
                if(positiveXOffset > 0) {
                    drawingOffset = -positiveXOffset;
                }else {
                    drawingOffset = negativeXOrZero;
                }
                drawingOffset += tempHitch.getMountingLength() + tempHitch.getCouplingOffset();
            } else {
                if (tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                    drawingOffset = tempHitch.getMountingLength() + tempHitch.getCouplingOffset();
                } else {
                    var hitchRadius = tempHitch.getWidth() / 2;
                    if (hitchRadius === 0) {
                        hitchRadius = config.topViewHitchRadius;
                    }
                    drawingOffset = hitchRadius;
                }

            }

            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.HITCHOFFSET, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.HITCHOFFSET).value.value, hitchOffsetStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, 'plus', drawingOffset));


            if (curElevation === config.ELEVATION.SIDE) {
                var hitchHeightFromGroundStartPoint = 0;
                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.HITCHHEIGHTFROMGROUND, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.HITCHHEIGHTFROMGROUND).value.value, hitchHeightFromGroundStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
            }

        } else if (elementId.includes(config.ACCESSORY_TYPES.TAILLIFT)) {

            var tailliftFromBackOfBodyStartPoint = 0;

            // if (chassisObjectToUse.getAccessoryHolder().getTaillift().getType() === config.TAILLIFT_TYPES.CANTILEVER
            //     || chassisObjectToUse.getAccessoryHolder().getTaillift().getType() === config.TAILLIFT_TYPES.SLIDER
            //     || chassisObjectToUse.getAccessoryHolder().getTaillift().getType() === config.TAILLIFT_TYPES.TUCKAWAY
            // ) {

                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        tailliftFromBackOfBodyStartPoint = chassisObjectToUse.getOverallLength();
                    } else {
                        tailliftFromBackOfBodyStartPoint = chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + chassisObjectToUse.getAccessoryHolder().getBody().getActualLength();
                    }
                    
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    tailliftFromBackOfBodyStartPoint = offerManager.getRigOps().getTrailer1FrontOverhangStart() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + chassisObjectToUse.getAccessoryHolder().getBody().getActualLength();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    tailliftFromBackOfBodyStartPoint = offerManager.getRigOps().getTrailer2FrontOverhangStart() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + chassisObjectToUse.getAccessoryHolder().getBody().getActualLength();
                }

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.TAILLIFTFROMBACKOFBODY, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.TAILLIFTFROMBACKOFBODY).value.value, tailliftFromBackOfBodyStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, 'plus', chassisObjectToUse.getAccessoryHolder().getTaillift().getMechanismLength()));
            // }


            if (curElevation === config.ELEVATION.SIDE) {
                var tailliftAboveOrBelowFloorStartPoint = 0;
                tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        tailliftAboveOrBelowFloorStartPoint = chassisObjectToUse.getFloorHeight();
                    } else {
                        tailliftAboveOrBelowFloorStartPoint = chassisObjectToUse.getChassisHeightMax() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
                    }
                    
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    tailliftAboveOrBelowFloorStartPoint = chassisObjectToUse.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
                } 

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.TAILLIFTABOVEORBELOWFLOOR, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.TAILLIFTABOVEORBELOWFLOOR).value.value, tailliftAboveOrBelowFloorStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
            } else {
                var tailliftFromCentreLineOfVehicleStartPoint = portionOverhangingLeft + offerManager.getRigOps().getTopViewCentreLine();

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.TAILLIFTFROMCENTREOFVEHICLE, chassisObjectToUse.getAccessoryHolder().getTaillift().getFromCentreOfVehicle(), tailliftFromCentreLineOfVehicleStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
            }

        } else if (elementId.includes(config.ACCESSORY_TYPES.HOOKLIFT)) {
            var hookliftRefPointStartPoint = 0;
            var hookliftRefPointValueType = config.VALUE_TYPE.HOOKLIFTCABGAP;
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                if (globals.user.getSpecifyEquipmentPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB) {
                    hookliftRefPointStartPoint = chassisObjectToUse.getBumperToBackOfCab();
                    
                } else {
                    hookliftRefPointValueType = config.VALUE_TYPE.HOOKLIFTFROMAXLE1FRONT;
                    hookliftRefPointStartPoint = chassisObjectToUse.getFrontOverhang();
                }
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                hookliftRefPointStartPoint = offerManager.getRigOps().getTrailer1FrontOverhangStart();
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                hookliftRefPointStartPoint = offerManager.getRigOps().getTrailer2FrontOverhangStart();
            }
            let negativeXOrZero = Math.abs(getNegativePortionOfDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x);
            let positiveXOffset = getOriginOffsetsFromDrawing(elementId, offerManager.getRigOps().getAccessoryFromPath(elementId)).x;
            if(positiveXOffset > 0) {
                drawingOffset = -positiveXOffset;
            }else {
                drawingOffset = negativeXOrZero;
            }
            

            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + hookliftRefPointValueType, offerManager.getAttributeGroup(elementId + '.' + hookliftRefPointValueType).value.value, hookliftRefPointStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, undefined, drawingOffset));

            if (curElevation === config.ELEVATION.TOP) {
                var hookliftFromVehicleCentreLineStartPoint = portionOverhangingLeft + offerManager.getRigOps().getTopViewCentreLine();

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.HOOKLIFTFROMCENTREOFVEHICLE, chassisObjectToUse.getAccessoryHolder().getHooklift().getFromCentreOfVehicle(), hookliftFromVehicleCentreLineStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
            } else if (curElevation === config.ELEVATION.SIDE) {
                var hookliftAboveBelowChassisStartPoint = 0;

                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    hookliftAboveBelowChassisStartPoint = chassisObjectToUse.getChassisHeightMax();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    hookliftAboveBelowChassisStartPoint = offerManager.getActiveOffer().getRig().getTrailer1().getChassisHeight();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    hookliftAboveBelowChassisStartPoint = offerManager.getActiveOffer().getRig().getTrailer2().getChassisHeight();
                }

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.HOOKLIFTABOVEBELOWCHASSIS, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.HOOKLIFTABOVEBELOWCHASSIS).value.value, hookliftAboveBelowChassisStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
            }

        } else if (elementId.includes(config.ACCESSORY_TYPES.BODY)) {
            var bodyRefPointStartPoint = 0;
            var bodyRefPointValueType = config.VALUE_TYPE.BODYCABGAP;
            
            //curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical()
            tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                if (globals.user.getSpecifyBodyPositionAs() === config.SPECIFY_POSITION_AS_OPTIONS.FROM_BACK_OF_CAB || (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY)) {
                    bodyRefPointStartPoint = chassisObjectToUse.getBumperToBackOfCab();
                } else {
                    bodyRefPointValueType = config.VALUE_TYPE.BODYFROMAXLE1FRONT;
                    bodyRefPointStartPoint = chassisObjectToUse.getFrontOverhang();
                }
                
                

            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                bodyRefPointStartPoint = offerManager.getRigOps().getTrailer1FrontOverhangStart();
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                bodyRefPointStartPoint = offerManager.getRigOps().getTrailer2FrontOverhangStart();
            }

            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                tempHooklift = chassisObjectToUse.getAccessoryHolder().getHooklift();
                bodyRefPointStartPoint += tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
            } 

            drawingOffset = 0;
            if (curElevation === config.ELEVATION.SIDE && tempBody.getSubType() !== config.BODY_SUB_TYPES.WITH_CAB_PROTECTOR) {
                let negativeXOrZero = Math.abs(getNegativePortionOfDrawing(elementId, offerManager.getRigOps().getBodyFromPath(elementId)).x);
                let positiveXOffset = getOriginOffsetsFromDrawing(elementId, offerManager.getRigOps().getBodyFromPath(elementId)).x;
                if(positiveXOffset > 0) {
                    drawingOffset = -positiveXOffset;
                }else {
                    drawingOffset = negativeXOrZero;
                }
                
            }else if(curElevation === config.ELEVATION.SIDE && tempBody.getSubType() === config.BODY_SUB_TYPES.WITH_CAB_PROTECTOR) {
                // need to correct the drawing start for reference lines to account for the cab protector, the value is the same as tipperFrontProtrusion from tipper w/cab protector drawing code
                drawingOffset = 500;
            }/* else {
                if (tempHitch.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                    drawingOffset = tempHitch.getMountingLength() + tempHitch.getCouplingOffset();
                } else {
                    var hitchRadius = tempHitch.getWidth() / 2;
                    if (hitchRadius === 0) {
                        hitchRadius = config.topViewHitchRadius;
                    }
                    drawingOffset = hitchRadius;
                }

            }*/

            var bodyPathDragInfoObject = new PathValidationWrapper(elementId + '.' + bodyRefPointValueType, offerManager.getAttributeGroup(elementId + '.' + bodyRefPointValueType).value.value, bodyRefPointStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, 'plus', drawingOffset);

            //var accessoryHolder = offerManager.getRigOps().getAccessoryHolderFromPath(elementId);

            //if (accessoryHolder.getFridge() !== null) {
            //    var fridgeElementId = offerManager.getRigOps().getChassisTypeUsingPath(dragObjInfo.path) + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE;
            //    bodyPathDragInfoObject.addDependantDrawingGroup(fridgeElementId);
            //}
            //if (accessoryHolder.getTaillift() !== null) {
            //    var tailliftElementId = offerManager.getRigOps().getChassisTypeUsingPath(dragObjInfo.path) + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT;
            //    bodyPathDragInfoObject.addDependantDrawingGroup(tailliftElementId);
            //}

            attributeInfoWrappers.push(bodyPathDragInfoObject);

            if (curElevation === config.ELEVATION.SIDE && tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                var bodyBottomOfBodyToTopOfChassisStartPoint = 0;

                tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    bodyBottomOfBodyToTopOfChassisStartPoint = chassisObjectToUse.getChassisHeightRear();

                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    bodyBottomOfBodyToTopOfChassisStartPoint = chassisObjectToUse.getChassisHeight();
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    bodyBottomOfBodyToTopOfChassisStartPoint = chassisObjectToUse.getChassisHeight();
                }

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.BODYBOTTOMOFBODYTOTOPOFCHASSIS, tempBody.getBottomOfBodyToTopOfChassis(), bodyBottomOfBodyToTopOfChassisStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, 0, 'minus'));
            } else if (curElevation === config.ELEVATION.SIDE && tempBody.getType() !== config.BODY_TYPES.SERVICE) {
                var aboveBelowChassisStartPoint = 0;

                tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    aboveBelowChassisStartPoint = chassisObjectToUse.getChassisHeightRear();

                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    aboveBelowChassisStartPoint = chassisObjectToUse.getChassisHeight();
                } 
                if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                    tempHooklift = chassisObjectToUse.getAccessoryHolder().getHooklift();
                    aboveBelowChassisStartPoint += tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight();
                }
                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.BODYABOVEBELOWCHASSIS, tempBody.getAboveBelowChassis(), aboveBelowChassisStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
            }




        } else if (elementId.includes(config.ACCESSORY_TYPES.PAYLOAD)) {
            var payloadFromHeadboardStartPoint = 0;
            tempBody = chassisObjectToUse.getAccessoryHolder().getBody();
            if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                if (tempBody !== null) {
                    payloadFromHeadboardStartPoint = chassisObjectToUse.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getHeadboardThickness();
                } else {
                    payloadFromHeadboardStartPoint = chassisObjectToUse.getBumperToBackOfCab();
                }
            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                if (tempBody !== null) {
                    payloadFromHeadboardStartPoint = offerManager.getRigOps().getTrailer1FrontOverhangStart() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getHeadboardThickness();
                } else {
                    payloadFromHeadboardStartPoint = offerManager.getRigOps().getTrailer1FrontOverhangStart();
                }

            } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                if (tempBody !== null) {
                    payloadFromHeadboardStartPoint = offerManager.getRigOps().getTrailer2FrontOverhangStart() + offerManager.getRigOps().getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getHeadboardThickness();//#unfinished, getTrailer2FrontOverhangStart not implemented
                } else {
                    payloadFromHeadboardStartPoint = offerManager.getRigOps().getTrailer2FrontOverhangStart();//#unfinished, getTrailer2FrontOverhangStart not implemented
                }

            }
            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.DETAILEDPAYLOADFROMHEADBOARD, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.DETAILEDPAYLOADFROMHEADBOARD).value.value, payloadFromHeadboardStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront));

            if (curElevation === config.ELEVATION.SIDE) {
                var payloadAboveDeckStartPoint = 0;

                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    if (tempBody !== null) {
                        if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                            payloadAboveDeckStartPoint = chassisObjectToUse.getChassisHeightMax() - tempBody.getBottomOfBodyToTopOfChassis();
                        } else {
                            payloadAboveDeckStartPoint = chassisObjectToUse.getChassisHeightMax() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
                        }

                    } else {
                        payloadAboveDeckStartPoint = chassisObjectToUse.getChassisHeightMax();
                    }
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    if (tempBody !== null) {
                        if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                            payloadAboveDeckStartPoint = offerManager.getActiveOffer().getRig().getTrailer1().getChassisHeight() - tempBody.getBottomOfBodyToTopOfChassis();
                        } else {
                            payloadAboveDeckStartPoint = offerManager.getActiveOffer().getRig().getTrailer1().getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
                        }
                    } else {
                        payloadAboveDeckStartPoint = offerManager.getActiveOffer().getRig().getTrailer1().getChassisHeight();
                    }
                } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    if (tempBody !== null) {
                        if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                            payloadAboveDeckStartPoint = offerManager.getActiveOffer().getRig().getTrailer2().getChassisHeight() - tempBody.getBottomOfBodyToTopOfChassis();
                        } else {
                            payloadAboveDeckStartPoint = offerManager.getActiveOffer().getRig().getTrailer2().getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
                        }
                    } else {
                        payloadAboveDeckStartPoint = offerManager.getActiveOffer().getRig().getTrailer2().getChassisHeight();
                    }
                }

                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.DETAILEDPAYLOADABOVEDECK, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.DETAILEDPAYLOADABOVEDECK).value.value, payloadAboveDeckStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
            } else {
                var payloadFromLeftSidewallStartPoint;
                if (tempBody !== null) {
                    payloadFromLeftSidewallStartPoint = portionOverhangingLeft + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + offerManager.getRigOps().getLeftOfChassisToPayloadDeckStart(chassisObjectToUse);
                } else {
                    payloadFromLeftSidewallStartPoint = portionOverhangingLeft + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObjectToUse) + offerManager.getRigOps().getLeftOfChassisToPayloadDeckStart(chassisObjectToUse);
                }
                attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.DETAILEDPAYLOADFROMLEFTSIDEWALL, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.DETAILEDPAYLOADFROMLEFTSIDEWALL).value.value, payloadFromLeftSidewallStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
            }

            /*
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        if (tempBody !== null) {
                            originX = chassisObjectToUse.getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness() + tempPayload.getStartX();
                            originY = chassisObjectToUse.getChassisHeightMax() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempPayload.getStartY();
                        } else {
                            originX = chassisObjectToUse.getBumperToBackOfCab() + tempPayload.getStartX();
                            originY = chassisObjectToUse.getChassisHeightMax() + tempPayload.getStartY();
                        }
                        break;
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                        if (tempBody !== null) {
                            originX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() - curTrailer1.getFrontOverhang() + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness() + tempPayload.getStartX();
                            originY = chassisObjectToUse.getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempPayload.getStartY();
                        } else {
                            originX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() - curTrailer1.getFrontOverhang() + tempPayload.getStartX();
                            originY = chassisObjectToUse.getChassisHeight() + tempPayload.getStartY();
                        }
                        break;
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        //originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + tempOther.getHorizontalGap();
                        //#unfinished, need to implement Trailer 2 payload graphic origin offsets
                        break;
            */
        } else if (elementId.includes(config.OBJECT_TYPES.AXLES) || elementId.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
            var axleOffsetFromNeighbouringAxleStartPoint = getAxleOffsetFromNeighbouringAxle(elementId);


            var targetAxleSpacingAttribute = getTargetAxleSpacingAttribute(elementId);
            if(targetAxleSpacingAttribute !== undefined && targetAxleSpacingAttribute !== null) {
                var offsetActionFlag = 'plus';//getAxleOffsetActionFlag(elementId);
                var axle;
                if (elementId.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
                    var pathParts = elementId.split('.');
                    var axleNumber = pathParts[pathParts.length - 1];
                    axle = offerManager.getRigOps().getAxleHolderFromPath(elementId).getAxleByNumber(Number(axleNumber));
                }else {
                    axle = offerManager.getRigOps().getAxleFromPath(elementId);
                }
                var pathRoot = offerManager.getRigOps().getChassisTypeUsingPath(elementId);

                var horizontalVal = 0;
                var adjustmentVal = 0;
                if (targetAxleSpacingAttribute === config.VALUE_TYPE.WHEELBASESPECIFIED) {
                    horizontalVal = offerManager.getAttributeGroup(pathRoot + '.' + config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR).value.value;
                    adjustmentVal = offerManager.getAttributeGroup(pathRoot + '.' + targetAxleSpacingAttribute).value.value - horizontalVal;
                } else if (targetAxleSpacingAttribute === config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL) {
                    //horizontalVal = getAppropriateAmountOfWBT(offerManager.getAttributeGroup(pathRoot + '.' + targetAxleSpacingAttribute).value.value);
                    horizontalVal = offerManager.getRigOps().getChassisObjectFromPath(pathRoot).getRearMostFrontAxleToAxle1Rear();
                    adjustmentVal = offerManager.getAttributeGroup(pathRoot + '.' + targetAxleSpacingAttribute).value.value - horizontalVal;
                } else {
                    horizontalVal = offerManager.getAttributeGroup(pathRoot + '.' + targetAxleSpacingAttribute).value.value;
                }//AXLE2FRONTTOAXLE1REAR



                attributeInfoWrappers.push(new PathValidationWrapper(pathRoot + '.' + targetAxleSpacingAttribute, horizontalVal, axleOffsetFromNeighbouringAxleStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront, offsetActionFlag, axle.getTyreRadius(), true, undefined, undefined, adjustmentVal));
            }

            if (curElevation === config.ELEVATION.SIDE) {
                if (pathRoot === config.CHASSIS_OBJECT_TYPES.VEHICLE && axle.getType() !== config.VEHICLE_AXLE_POSITION_TYPES.FRONT && axle.getType() !== config.VEHICLE_AXLE_POSITION_TYPES.REAR && axle.getLifting()) {

                    var liftingOffset = 0;
                    var axleCentreYAxisStartPoint;
                    if (axle.getRaised()) {
                        liftingOffset = axle.getLiftingMaxTravel();
                        axleCentreYAxisStartPoint = axle.getTyreRadius() + liftingOffset;
                    } else {
                        axleCentreYAxisStartPoint = axle.getTyreRadius() + axle.getFlatspotOffset();
                    }

                    //var axleCentreYAxisStartPoint = axle.getTyreRadius() + liftingOffset + (axle.getFlatspotOffset() - liftingOffset);

                    if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
                        axleCentreYAxisStartPoint += curVehicle.getFrontAddition();
                    } else {//must be pusher or tag
                        axleCentreYAxisStartPoint += curVehicle.getRearAddition();
                    }

                    attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.AXLERAISED, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.AXLERAISED).value.value, axleCentreYAxisStartPoint, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, undefined, undefined, 0, true, 20, axle.getRaised()));
                }
            }
        }
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {

        portionOverhangingFront = 0;
        portionOverhangingLeft = 0;

        var drawingOriginHorizontalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value;
        
        

        var originAdjustmentHorizontalAxis = drawingOriginHorizontalOffset >= 0 ? 0 : drawingOriginHorizontalOffset;
        


        var itemStartPointX = -originAdjustmentHorizontalAxis;
        //need to be dealing with position object here otherwise changes will be lost everytime this gets regenerated
        attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETX).value.value, itemStartPointX, config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL, portionOverhangingFront));
        var drawingOriginVerticalOffset,  originAdjustmentVerticalAxis;
        if (curElevation === config.ELEVATION.TOP) {

            drawingOriginVerticalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ).value.value;
            originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? 0 : drawingOriginVerticalOffset;

            var itemStartPointZ = -originAdjustmentVerticalAxis;

            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETZ).value.value, itemStartPointZ, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL, portionOverhangingLeft));
        } else if (curElevation === config.ELEVATION.SIDE) {

            drawingOriginVerticalOffset = offerManager.getAttributeGroup("configurationModal" + '.' + curRenderItem.getAccessoryType() + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value;
            originAdjustmentVerticalAxis = drawingOriginVerticalOffset >= 0 ? 0 : drawingOriginVerticalOffset;

            var itemStartPointY =  -originAdjustmentVerticalAxis;

            attributeInfoWrappers.push(new PathValidationWrapper(elementId + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY, offerManager.getAttributeGroup(elementId + '.' + config.VALUE_TYPE.ITEMDRAWINGORIGINOFFSETY).value.value, itemStartPointY, config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL));
        }

    }

    

    return attributeInfoWrappers;
    function PathValidationWrapper(path, value, refPoint, dirMovement, portionOverhangingOffset, offsetAction, drawingOffset, lockPlane, switchThresholdInSteps, switchOn, valueAdjustment) {

        var movementIncrement, showDraggingReferenceLines;
        if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
            //if (globals.user.getActiveMeasurementSystem().id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
            //    movementIncrement = config.DRAG_INCREMENTS.IMPERIALUS;
            //} else if (globals.user.getActiveMeasurementSystem().id === config.MEASUREMENT_SYSTEM.IMPERIALUK) {
            //    movementIncrement = config.DRAG_INCREMENTS.IMPERIALUK;
            //} else {
            //    movementIncrement = config.DRAG_INCREMENTS.METRIC;
            //}
            movementIncrement = globals.user.getLengthIncrement();// / offerManager.activeMeasurementSystem().lengthConversion;
            showDraggingReferenceLines = true;
        } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
            if (globals.user.getActiveMeasurementSystem().id === config.MEASUREMENT_SYSTEM.IMPERIALUS) {
                movementIncrement = config.DRAG_INCREMENTS.IMPERIALUS * 0.1;
            } else if (globals.user.getActiveMeasurementSystem().id === config.MEASUREMENT_SYSTEM.IMPERIALUK) {
                movementIncrement = config.DRAG_INCREMENTS.IMPERIALUK * 0.1;
            } else {
                movementIncrement = config.DRAG_INCREMENTS.METRIC * 0.1;
            }
            showDraggingReferenceLines = false;
        }

        return {
            path: path,
            value: value,
            refPoint: refPoint,
            directionOfMovement: dirMovement,
            offsetAction: offsetAction || 'plus',
            dimensionObj: getNewEmptyDimension(dirMovement),
            unitConversion: globals.user.getActiveMeasurementSystem().lengthConversion,
            portionOverhangingFront: dirMovement === config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL && portionOverhangingOffset !== undefined ? portionOverhangingOffset : 0,
            portionOverhangingLeft: dirMovement === config.DRAWING_DIRECTION_OF_MOVEMENT.VERTICAL && portionOverhangingOffset !== undefined ? portionOverhangingOffset : 0,
            movementIncrement: movementIncrement,
            drawingOffset: drawingOffset || 0,
            lockPlane: lockPlane || false,
            switchThreshold: switchThresholdInSteps,
            switchOn: switchOn,
            valueAdjustment: valueAdjustment || 0,
            showDraggingReferenceLines: showDraggingReferenceLines
            //addDependantDrawingGroup: function(dependantDrawingGroup) {
        //}
        };
    }

    function getAxleOffsetFromNeighbouringAxle(path) {
        var axlePosition = offerManager.getRigOps().getAxlePositionFromPath(path);
        var axlesHolderToUse = offerManager.getRigOps().getAxleHolderFromPath(path);
        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(path);
        if (axlePosition !== config.AXLE_POSITIONS.AXLE_1_FRONT) {
            //var neighbouringAxle = offerManager.getRigOps().getNeighbouringAxleFromAxlePosition(axlePosition);

            if (axlePosition === config.AXLE_POSITIONS.AXLE_2_FRONT) {
                if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang();
                } else {
                    return chassisObjectToUse.getFrontOverhang(); 
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_3_FRONT) {
                if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front();
                } else {
                    return chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front();
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_1_REAR) {
                //if (axlesHolderToUse.countAxleLocation(config.AXLE_LOCATIONS.REAR) > 1) {
                //    if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI) {
                //        return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getKingpinToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                //    } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                //    } else {
                //        return chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                //    }
                    
                //} else {
                if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK)) {
                        return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang();
                    } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                        return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front();
                    } else if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) && chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        return offerManager.getRigOps().getTrailerHitchPointFromFrontOfRig(path);
                    } else {
                        return chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front();
                    }
                    
                //}
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_2_REAR) {

                if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                    if (chassisObjectToUse.getType() !== config.TRAILER_TYPES.PUP) {
                        return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear();
                    } else {
                        return offerManager.getRigOps().getTrailerHitchPointFromFrontOfRig(path) + chassisObjectToUse.getHitchPointToAxle1Rear();
                    }
                } else {
                    return chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear();
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_3_REAR) {
                if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                    if (chassisObjectToUse.getType() !== config.TRAILER_TYPES.PUP) {
                        return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                    } else {
                        return offerManager.getRigOps().getTrailerHitchPointFromFrontOfRig(path) + chassisObjectToUse.getHitchPointToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                    }
                    
                } else {
                    return chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_4_REAR) {
                if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
                    if (chassisObjectToUse.getType() !== config.TRAILER_TYPES.PUP) {
                        return offerManager.getRigOps().getTrailerFrontOverhangStart(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear();
                    } else {
                        return offerManager.getRigOps().getTrailerHitchPointFromFrontOfRig(path) + chassisObjectToUse.getHitchPointToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear();
                    }
                } else {
                    return chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear();
                }
                
            }
        }
        return 0;
    }

    function getAxleOffsetActionFlag(path) {
        var axlePosition = offerManager.getRigOps().getAxlePositionFromPath(path);
        var axlesHolderToUse = offerManager.getRigOps().getAxleHolderFromPath(path);
        if (axlePosition === config.AXLE_POSITIONS.AXLE_1_REAR) {
            if (axlesHolderToUse.countAxleLocation(config.AXLE_LOCATIONS.REAR) > 1) {
                return 'minus';
            } else {
                return 'plus';
            }
        }
        return 'plus';
    }

    function getTargetAxleSpacingAttribute(path) {
        var axlePosition = offerManager.getRigOps().getAxlePositionFromPath(path);
        var axlesHolderToUse = offerManager.getRigOps().getAxleHolderFromPath(path);

        var isVehicle = path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1;
        if (axlePosition !== config.AXLE_POSITIONS.AXLE_1_FRONT) {
            //var neighbouringAxle = offerManager.getRigOps().getNeighbouringAxleFromAxlePosition(axlePosition);

            if (axlePosition === config.AXLE_POSITIONS.AXLE_2_FRONT) {
                if (isVehicle) {
                    return config.VALUE_TYPE.AXLE1FRONTTOAXLE2FRONT;
                } else {
                    return config.VALUE_TYPE.TRAILERAXLE1FRONTTOAXLE2FRONT;
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_3_FRONT) {
                if (isVehicle) {
                    return config.VALUE_TYPE.AXLE2FRONTTOAXLE3FRONT;
                } else {
                    return config.VALUE_TYPE.TRAILERAXLE2FRONTTOAXLE3FRONT;
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_1_REAR) {
                //if (axlesHolderToUse.countAxleLocation(config.AXLE_LOCATIONS.REAR) > 1) {
                //    if (isVehicle) {
                //        return config.VALUE_TYPE.AXLE1REARTOAXLE2REAR;
                //    } else {
                //        return config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR;
                //    }
                    
                //} else {
                if (isVehicle) {
                    //if (globals.user.getSpecifyChassisLengthAs() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA) {
                    //    return config.VALUE_TYPE.CABTOAXLES;
                    //} else {
                        return config.VALUE_TYPE.WHEELBASESPECIFIED;
                    //}
                        
                        //return config.VALUE_TYPE.AXLE2FRONTTOAXLE1REAR;
                } else {
                    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(path);
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                        return config.VALUE_TYPE.TRAILERKINGPINTOAXLE1REAR;
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        return config.VALUE_TYPE.TRAILERWHEELBASETHEORETICAL;
                    } else {
                        return config.VALUE_TYPE.TRAILERREARMOSTAXLEFRONTTOAXLE1REAR;
                    }
                        
                }
                    
                //}
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_2_REAR) {
                if (isVehicle) {
                    return config.VALUE_TYPE.AXLE1REARTOAXLE2REAR;
                } else {
                    return config.VALUE_TYPE.TRAILERAXLE1REARTOAXLE2REAR;
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_3_REAR) {
                if (isVehicle) {
                    return config.VALUE_TYPE.AXLE2REARTOAXLE3REAR;
                } else {
                    return config.VALUE_TYPE.TRAILERAXLE2REARTOAXLE3REAR;
                }
                
            } else if (axlePosition === config.AXLE_POSITIONS.AXLE_4_REAR) {
                if (isVehicle) {
                    return config.VALUE_TYPE.AXLE3REARTOAXLE4REAR;
                } else {
                    return config.VALUE_TYPE.TRAILERAXLE3REARTOAXLE4REAR;
                }
                
            }
        }
    }
}

function getNewEmptyDimension(direction) {
    var dimensionConfig = new DimensionConfig().setId('').setStart(0).addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue);

    var type;
    if (direction === config.DRAWING_DIRECTION_OF_MOVEMENT.HORIZONTAL) {
        type = config.DIMENSION_TYPE.HORIZONTAL_INNER;
    } else {
        type = config.DIMENSION_TYPE.VERTICAL_INNER;
    }

    
    return new Dimension(type, 0, dimensionConfig, config.DIMENSION_STYLE.STANDARD);

    //var offset = dimensionXOffset + vehicle.getFrontOverhang() + vehicle.getAxle1FrontToAxle2Front() + innerDimensionOffset;
    //dimensions.push(new Dimension(config.DIMENSION_TYPE.VERTICAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.GROUNDCLEARANCEFRONT), config.DIMENSION_STYLE.JUMP_RIGHT, offset));
}

function addHitAreasForCOGDimensions(dimensions, drawingObjects) {
    var hitAreas = {};

    var isTrialUser = false;
    //if (globals.user.isTrialUser() === true) {
    //    isTrialUser = true;
    //}

    dimensions.forEach(function (dimension) {

        if ((dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
            dimension.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimension.type === config.DIMENSION_TYPE.COG_TOP_RIGHT)) {
            if (dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET') {
                dimension.setTotalFrontLayerThickness(inDrawingDimensionLayerOffsetX);
                dimension.setTotalBottomLayerThickness(inDrawingDimensionLayerOffsetY);
                dimension.setTotalTopLayerThickness(getTotalInDrawingTopDimensionLayerThickness());
                dimension.setMainHeight(mH);
                dimension.setupPoints();

                if (hitAreas[dimension.drawingGroupName] === undefined) {

                    hitAreas[dimension.drawingGroupName] = new HitArea(dimension.drawingGroupName, getCOGDimensionPoints(dimension), getCOGDimensionMaxBounds(dimension));
                    hitAreas[dimension.drawingGroupName].addHitMask(new HitArea(dimension.drawingGroupName + '_' + config.INTERACTION_ID.COG_DIMENSION + '_MARKER', getCOGDimensionMarkerPoints(dimension), getCOGDimensionMarkerMaxBounds(dimension), config.INTERACTION_BEHAVIOUR.PINNABLE));
                    hitAreas[dimension.drawingGroupName].addHitMask(new HitArea(dimension.drawingGroupName + '_' + config.INTERACTION_ID.COG_DIMENSION + '_LABEL', getCOGDimensionLabelPoints(dimension), getCOGDimensionLabelMaxBounds(dimension), config.INTERACTION_BEHAVIOUR.STANDARD));
                    //hitAreas[dimension.drawingGroupName + '_' + config.INTERACTION_ID.COG_DIMENSION] = new HitArea(dimension.drawingGroupName + '_' + config.INTERACTION_ID.COG_DIMENSION, getCOGDimensionMarkerPoints(dimension), getCOGDimensionMarkerMaxBounds(dimension));
                }
            } else {
                dimension.setTotalFrontLayerThickness(inDrawingDimensionLayerOffsetX);
                dimension.setTotalBottomLayerThickness(inDrawingDimensionLayerOffsetY);
                dimension.setTotalTopLayerThickness(getTotalInDrawingTopDimensionLayerThickness());
                dimension.setMainHeight(mH);
                dimension.setupPoints();
            }
        }
        
        
    });

    Object.keys(hitAreas).forEach(function (key) {

        if (isTrialUser) {
            //bodyCOGElement.addInteraction(offerManager.getCOGElementInteractionObject(config.VALUE_TYPE.BODYMASS));
            hitAreas[key].setInteraction(offerManager.getCOGElementInteractionObject(config.INTERACTION_ID.XXED_OUT_VALUE, key));
        } else {
            //if (key.indexOf(config.INTERACTION_ID.COG_DIMENSION) !== 1) {
            //hitAreas[key].setInteraction(offerManager.getCOGDimensionInteractionObject(config.INTERACTION_ID.COG_DIMENSION, key));
            //} else {
            hitAreas[key].setInteraction(offerManager.getDrawingGroupInteractionObject(config.INTERACTION_ID.DRAWING_GROUP, key));
            //}
            
        }

        
        hitAreas[key].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(hitAreas[key].interaction.callbacks);
        if (hitAreas[key].hitMasks !== undefined) {
            hitAreas[key].interaction.hitMasks = hitAreas[key].hitMasks;
        }

        drawingObjects.push(hitAreas[key]);
    });

    /*
        
    */
    
}

//function getCOGDimensionPoints(cogDimension) {
//    var points = [];

//    var textWidth = getWidthOfText("" + cogDimension.labelText, curFontName, dimensionFontSize);
//    var textHeight = 10;
//    var textBuffer = 5;

//    var x = cogDimension.labelStyle === config.COG_ELEMENT_STYLE.DRAW_LEFT ? cogDimension.cogPoint.x - ((cogDimension.markerRadius / scalingFactor)) - (15 / scalingFactor) - (textWidth / scalingFactor) - ((textBuffer / scalingFactor) * 2) :
//        cogDimension.cogPoint.x + (cogDimension.markerRadius / scalingFactor);
//    var y = cogDimension.cogPoint.y - ((textBuffer / scalingFactor) * 2) - ((textHeight / scalingFactor) / 2);

//    var width = (textWidth / scalingFactor) + (15 / scalingFactor) + ((textBuffer / scalingFactor) * 2);
//    var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2);

//    points.push({ x: x, y: y });
//    points.push({ x: width, y: y });
//    points.push({ x: width, y: height });
//    points.push({ x: x, y: height });

//    return points;
//}

//function getCOGDimensionMaxBounds(cogDimension) {

//    var textWidth = getWidthOfText("" + cogDimension.labelText, curFontName, dimensionFontSize);
//    var textHeight = 10;
//    var textBuffer = 5;

//    var x = cogDimension.labelStyle === config.COG_ELEMENT_STYLE.DRAW_LEFT ? cogDimension.cogPoint.x - ((cogDimension.markerRadius / scalingFactor)) - (15 / scalingFactor) - (textWidth / scalingFactor) - ((textBuffer / scalingFactor) * 2) :
//        cogDimension.cogPoint.x + (cogDimension.markerRadius / scalingFactor);
//    var y = cogDimension.cogPoint.y - ((textBuffer / scalingFactor) * 2) - ((textHeight / scalingFactor) / 2);

//    var width = (textWidth / scalingFactor) + (15 / scalingFactor) + ((textBuffer / scalingFactor) * 2);
//    var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2);

//    return { x: x, y: y, width: width, height: height };

//    /*
//    var textWidth = getWidthOfText("" + text, curFontName, dimensionFontSize);
//    var textHeight = 10;
//    var textBuffer = 5;
    
//    var cogPointCopy = JSON.parse(JSON.stringify(cogPoint));
//    flipMainDrawingAreaAxisPoint(cogPointCopy);

//    var bgElementTopLeftCorner = {}, textElementPoint = {};
//    if (style === config.COG_ELEMENT_STYLE.DRAW_LEFT) {
//        bgElementTopLeftCorner.x = cogPointCopy.x - radius - 15 - textWidth - (textBuffer * 2);
//        //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
//        textElementPoint.x = textBuffer;
//    } else {
//        bgElementTopLeftCorner.x = cogPointCopy.x + radius + 15;
//        //textElementPoint.x = bgElementTopLeftCorner.x + textBuffer;
//        textElementPoint.x = textBuffer;
//    }
//    bgElementTopLeftCorner.y = cogPointCopy.y - textBuffer - (textHeight / 2);
//    //textElementPoint.y = cogPointCopy.y;
//    textElementPoint.y = textBuffer + (textHeight / 2);
//    */
//}

function getCOGGroupDimensionPoints(dimension) {
    var points = [];

    var textWidth = (getWidthOfText("" + dimension.displayLength, curFontName, dimensionFontSize) / scalingFactor);
    var textHeight = (10 / scalingFactor);
    var textBuffer = (4 / scalingFactor);

    var pos = dimension.startPoint;
    
    var x = pos.x - dimension.length/2 - textWidth/2;
    var y = pos.y - textHeight/2 - textBuffer;

    var width = textWidth;
    var height = (textHeight ) + (textBuffer * 2) ;

    

    points.push({ x: x, y: y });
    points.push({ x: width, y: y });
    points.push({ x: width, y: height });
    points.push({ x: x, y: height });

    return points;
}

function getCOGGroupDimensionMaxBounds(dimension) {

    var textWidth = (getWidthOfText("" + dimension.displayLength, curFontName, dimensionFontSize) / scalingFactor);
    var textHeight = (10 / scalingFactor);
    var textBuffer = (4 / scalingFactor);

    var pos = dimension.startPoint;

    var x = pos.x - dimension.length / 2 - textWidth / 2;
    var y = pos.y - textHeight / 2 - textBuffer;


    //var width = textWidth + 5 / scalingFactor;
    //var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2) + 5 / scalingFactor;
    var width = textWidth;
    var height = (textHeight) + (textBuffer * 2);

    return { x: x, y: y, width: width, height: height };
}
function getCOGGroupDimensionMarkerPoints(cogElement) {
    var points = [];

    //var textHeight = cogDimension.position === undefined ? 10 : ((cogDimension.height - (cogDimension.textBuffer * 2)) * scalingFactor) * 2;
    //var textBuffer = 5;

    //var pos = cogDimension.cogPoint !== undefined ? cogDimension.cogPoint : cogDimension.position;

    //var scalingFactorToUseForMarker = scalingFactor;

    //if (cogDimension.position) {
    //    scalingFactorToUseForMarker = 1;
    //}

    //var x = pos.x - ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) - 5 / scalingFactor;
    //var y = pos.y - ((textBuffer / scalingFactor) * 2) - ((textHeight / scalingFactor) / 2) - 5 / scalingFactor;

    //var width = ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) + 5 / scalingFactor;
    //var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2) + 5 / scalingFactor;

    ////var x = cogDimension.cogPoint.x - ((cogDimension.markerRadius / scalingFactor) * 2);
    ////var y = cogDimension.cogPoint.y - ((cogDimension.markerRadius / scalingFactor) * 2);

    ////var width = ((cogDimension.markerRadius / scalingFactor) * 4);
    ////var height = ((cogDimension.markerRadius / scalingFactor) * 4);

    var maxBounds = getCOGGroupDimensionMarkerMaxBounds(cogElement)

    points.push({ x: maxBounds.x, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.height });
    points.push({ x: maxBounds.x, y: maxBounds.height });

    return points;
}

function getCOGGroupDimensionMarkerMaxBounds(cogElement) {

    var pos = cogElement.position;


    var x = pos.x -cogElement.markerRadius;
    var y = pos.y - cogElement.markerRadius;

    var width = (cogElement.markerRadius * 2);
    var height = (cogElement.markerRadius * 2);

    return { x: x, y: y, width: width, height: height };
}
function getCOGGroupDimensionLabelPoints(cogElement) {
    var points = [];

    var maxBounds = getCOGGroupDimensionLabelMaxBounds(cogElement);

    points.push({ x: maxBounds.x, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.height });
    points.push({ x: maxBounds.x, y: maxBounds.height });

    return points;
}

function getCOGGroupDimensionLabelMaxBounds(cogElement) {

    var text = cogElement.labelText;// !== undefined ? cogDimension.labelText : cogDimension.displayText;

    var textWidth = getWidthOfText("" + text, curFontName, dimensionFontSize);
    var textHeight = cogElement.height - cogElement.textBuffer * 2;
    var textBuffer = 4/scalingFactor;

    var pos = cogElement.position;
    var style = cogElement.style;

    
    //var x = style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? pos.x - (cogElement.markerRadius) - (15 / scalingFactor) - textWidth / scalingFactor - ((textBuffer) * 2) : pos.x + cogElement.markerRadius + (15 / scalingFactor) /*+ ((cogDimension.markerRadius / scalingFactor) * 2) + (5 / scalingFactor)*/;
    var x = style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? pos.x - (cogElement.markerRadius) - (15 / scalingFactor) - cogElement.width - (textBuffer * 2): pos.x + cogElement.markerRadius + (15 / scalingFactor);
    var y = pos.y - textBuffer - (textHeight / 2);

    var width = cogElement.width + ((textBuffer) * 2);//(textWidth / scalingFactor) + ((textBuffer) * 2);
    var height = textHeight + (textBuffer * 2);

    return { x: x, y: y, width: width, height: height };


}
function getCOGDimensionMarkerPoints(cogDimension) {
    var points = [];

    var textHeight = cogDimension.position === undefined ? 10 : ((cogDimension.height - (cogDimension.textBuffer * 2)) * scalingFactor) * 2;
    var textBuffer = 5;

    var pos = cogDimension.cogPoint !== undefined ? cogDimension.cogPoint : cogDimension.position;

    var scalingFactorToUseForMarker = scalingFactor;

    if (cogDimension.position) {
        scalingFactorToUseForMarker = 1;
    }

    var x = pos.x - ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) - 5 / scalingFactor;
    var y = pos.y - ((textBuffer / scalingFactor) * 2) - ((textHeight / scalingFactor) / 2) - 5 / scalingFactor;

    var width = ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) + 5 / scalingFactor;
    var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2) + 5 / scalingFactor;

    //var x = cogDimension.cogPoint.x - ((cogDimension.markerRadius / scalingFactor) * 2);
    //var y = cogDimension.cogPoint.y - ((cogDimension.markerRadius / scalingFactor) * 2);

    //var width = ((cogDimension.markerRadius / scalingFactor) * 4);
    //var height = ((cogDimension.markerRadius / scalingFactor) * 4);

    points.push({ x: x, y: y });
    points.push({ x: width, y: y });
    points.push({ x: width, y: height });
    points.push({ x: x, y: height });

    return points;
}

function getCOGDimensionMarkerMaxBounds(cogDimension) {

    //var textWidth = getWidthOfText("" + cogDimension.labelText, curFontName, dimensionFontSize);
    var textHeight = cogDimension.position === undefined ? 10 : ((cogDimension.height - (cogDimension.textBuffer * 2)) * scalingFactor) * 2;
    var textBuffer = 5;

    var pos = cogDimension.cogPoint !== undefined ? cogDimension.cogPoint : cogDimension.position;

    var scalingFactorToUseForMarker = scalingFactor;

    if (cogDimension.position) {
        scalingFactorToUseForMarker = 1;
    }

    var x = pos.x - ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) - 5 / scalingFactor;
    var y = pos.y - ((textBuffer / scalingFactor) * 2) - ((textHeight / scalingFactor) / 2) - 5 / scalingFactor;

    var width = ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) + 5 / scalingFactor;
    var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2) + 5/scalingFactor;

    //var x = cogDimension.cogPoint.x - ((cogDimension.markerRadius / scalingFactor) * 2);
    //var y = cogDimension.cogPoint.y - ((cogDimension.markerRadius / scalingFactor) * 2);

    //var width = ((cogDimension.markerRadius / scalingFactor) * 4);
    //var height = ((cogDimension.markerRadius / scalingFactor) * 4);

    return { x: x, y: y, width: width, height: height };
}

    function getCOGDimensionPoints(cogDimension) {
    var points = [];

    var text = cogDimension.labelText !== undefined ? cogDimension.labelText : cogDimension.displayText;

    var textWidth = getWidthOfText("" + text, curFontName, dimensionFontSize);
    var textHeight = cogDimension.position === undefined ? 10 : ((cogDimension.height - (cogDimension.textBuffer * 2)) * scalingFactor) * 2;
    var textBuffer = 5;
    
    var pos = cogDimension.cogPoint !== undefined ? cogDimension.cogPoint : cogDimension.position;
    var style = cogDimension.labelStyle !== undefined ? cogDimension.labelStyle : cogDimension.style;

    var x = style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? pos.x - ((cogDimension.markerRadius / scalingFactor) * 2) - (15 / scalingFactor) - (textWidth / scalingFactor) - ((textBuffer / scalingFactor) * 2) :
        pos.x - ((cogDimension.markerRadius/ scalingFactor)*2);
    var y = pos.y - ((textBuffer / scalingFactor) * 2) -((textHeight / scalingFactor) / 2);

    var width = (textWidth/ scalingFactor) + (15 / scalingFactor) + ((textBuffer / scalingFactor) * 2) + ((cogDimension.markerRadius/ scalingFactor) * 2);
    var height = (textHeight/ scalingFactor) + ((textBuffer / scalingFactor) * 2);

    points.push({ x: x, y: y });
    points.push({ x: width, y: y });
    points.push({ x: width, y: height });
    points.push({ x: x, y: height });

    return points;
}

function getCOGDimensionMaxBounds(cogDimension) {

    var text = cogDimension.labelText !== undefined ? cogDimension.labelText : cogDimension.displayText;

    var textWidth = getWidthOfText("" + text, curFontName, dimensionFontSize);
    var textHeight = cogDimension.position === undefined ? 10 : ((cogDimension.height - (cogDimension.textBuffer * 2)) * scalingFactor) * 2;
    var textBuffer = 5;

    var pos = cogDimension.cogPoint !== undefined ? cogDimension.cogPoint : cogDimension.position;
    var style = cogDimension.labelStyle !== undefined ? cogDimension.labelStyle : cogDimension.style;

    var scalingFactorToUseForMarker = scalingFactor;

    if (cogDimension.position) {
        scalingFactorToUseForMarker = 1;
    }

    var x = style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? pos.x - ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) - (15 / scalingFactor) - (textWidth / scalingFactor) - ((textBuffer / scalingFactor) * 2) :
        pos.x - ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2);
    var y = pos.y - ((textBuffer / scalingFactor)*2) - ((textHeight/ scalingFactor) / 2);

    var width = (textWidth / scalingFactor) + (15 / scalingFactor) + ((textBuffer / scalingFactor) * 2) + ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2);
    var height = (textHeight/ scalingFactor) + ((textBuffer / scalingFactor) * 2);

    return { x: x, y: y, width: width, height: height };

    
}

function getCOGDimensionLabelPoints(cogDimension) {
    var points = [];

    var text = cogDimension.labelText !== undefined ? cogDimension.labelText : cogDimension.displayText;

    var textWidth = getWidthOfText("" + text, curFontName, dimensionFontSize);
    var textHeight = cogDimension.position === undefined ? 10 : ((cogDimension.height - (cogDimension.textBuffer * 2)) * scalingFactor) * 2;
    var textBuffer = 5;

    var pos = cogDimension.cogPoint !== undefined ? cogDimension.cogPoint : cogDimension.position;
    var style = cogDimension.labelStyle !== undefined ? cogDimension.labelStyle : cogDimension.style;

    var scalingFactorToUseForMarker = scalingFactor;

    if (cogDimension.position) {
        scalingFactorToUseForMarker = 1;
    }

    var x = style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? pos.x - ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) - (15 / scalingFactor) - (textWidth / scalingFactor) - ((textBuffer / scalingFactor) * 2) - (5 / scalingFactor) :
        pos.x + ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) + (5 / scalingFactor);
    var y = pos.y - ((textBuffer / scalingFactor) * 2) - ((textHeight / scalingFactor) / 2);

    var width = (textWidth / scalingFactor) + (15 / scalingFactor) + ((textBuffer / scalingFactor) * 2) - (5 / scalingFactor);
    var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2);

    points.push({ x: x, y: y });
    points.push({ x: width, y: y });
    points.push({ x: width, y: height });
    points.push({ x: x, y: height });

    return points;
}

function getCOGDimensionLabelMaxBounds(cogDimension) {

    var text = cogDimension.labelText !== undefined ? cogDimension.labelText : cogDimension.displayText;

    var textWidth = getWidthOfText("" + text, curFontName, dimensionFontSize);
    var textHeight = cogDimension.position === undefined ? 10 : ((cogDimension.height - (cogDimension.textBuffer * 2)) * scalingFactor) * 2;
    var textBuffer = 5;

    var pos = cogDimension.cogPoint !== undefined ? cogDimension.cogPoint : cogDimension.position;
    var style = cogDimension.labelStyle !== undefined ? cogDimension.labelStyle : cogDimension.style;

    var scalingFactorToUseForMarker = scalingFactor;

    if (cogDimension.position) {
        scalingFactorToUseForMarker = 1;
    }

    var x = style === config.COG_ELEMENT_STYLE.DRAW_LEFT ? pos.x - ((cogDimension.markerRadius / scalingFactorToUseForMarker) * 2) - (15 / scalingFactor) - (textWidth / scalingFactor) - ((textBuffer / scalingFactor) * 2) - (5 / scalingFactor) :
        pos.x /*+ ((cogDimension.markerRadius / scalingFactor) * 2) + (5 / scalingFactor)*/;
    var y = pos.y - ((textBuffer / scalingFactor) * 2) - ((textHeight / scalingFactor) / 2);

    var width = (textWidth / scalingFactor) + (15 / scalingFactor) + ((textBuffer / scalingFactor) * 2) - (5 / scalingFactor);
    var height = (textHeight / scalingFactor) + ((textBuffer / scalingFactor) * 2);

    return { x: x, y: y, width: width, height: height };


}


function addHitAreasForOverviewDimensions(dimensions, drawingObjects) {
    var hitAreas = {};

    dimensions.forEach(function (dimension) {

        if (!(dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
            dimension.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimension.type === config.DIMENSION_TYPE.COG_TOP_RIGHT || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP || dimension.type === config.DIMENSION_TYPE.FREE_ANGLE) &&
            dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' && dimension.showAsHyperlink === true) {

            if (hitAreas[dimension.drawingGroupName] === undefined) {
                hitAreas[dimension.drawingGroupName] = new HitArea(dimension.drawingGroupName, getOverviewDimensionPoints(dimension), getOverviewDimensionMaxBounds(dimension));
                //if (dimension.linkedDrawingGroupName === undefined) {
                    hitAreas[dimension.drawingGroupName].setDontFlipYAxis(true);
                //} /*else {
                //    var breakHere = 0;
                //}*/
            }
        } else {
            var breakHere = 0;
        }
    });

    Object.keys(hitAreas).forEach(function (key) {
        hitAreas[key].setInteraction(offerManager.getDrawingGroupInteractionObject(config.INTERACTION_ID.DRAWING_GROUP, key));
        hitAreas[key].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(hitAreas[key].interaction.callbacks);
        drawingObjects.push(hitAreas[key]);
    });

    /*
        
    */

}



function getOverviewDimensionPoints(dimension) {
    var points = [];

    //var textWidth = getWidthOfText("" + overviewDimension.displayLength, curFontName, dimensionFontSize);
    //var textHeight = 10;
    //var textBuffer = 5;
    //var layerIncrement = config.configurationDimensionLayerIncrement;
    //var position ;

    //var x;
    //var y;

    //var width;
    //var height;

    //if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT || dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT || dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
    //    if (dimension.jumpingRight === true) {
    //        position = { x: dimension.startPoint.x + dimension.length + textWidth + 4, y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
    //    } else if (dimension.jumpingLeft === true) {
    //        position = { x: dimension.lineStart.x - (textWidth + 4), y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
    //    } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
    //        if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
    //            position = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: ((dimension.startPoint.y - (textHeight / 2)) - 1) - layerIncrement };
    //        } else {
    //            position = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: ((dimension.startPoint.y - (textHeight / 2)) - 1) + layerIncrement };
    //        }
    //    } else {
    //        position = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
    //    }

    //    x = position.x;
    //    y = position.y;

    //    width = textWidth + 8;
    //    height = textHeight + 2;
    //} else {
    //    if (dimension.style === config.DIMENSION_STYLE.STANDARD) {
    //        position = getStandardTextCentre();
    //    } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
    //        if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) + 15, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) - 15, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        }
    //    } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
    //        if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) + layerIncrement, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) - layerIncrement, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        }
    //    } else {
    //        if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
    //            if (dimension.jumpingDown && dimension.jumpingDown === true) {
    //                position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y + 20 + Math.abs(dimension.length)) - (textWidth / 2)) - 4) };
    //            } else {
    //                position = getStandardTextCentre();
    //            }
    //        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y - 30 - (Math.abs(dimension.length))) - (textWidth / 2)) - 4) };
    //        }
    //    }

    //    function getStandardTextCentre() {
    //        return { x: (dimension.lineStart.x - (textHeight / 2)) - 2, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //    }

    //    x = position.x;
    //    y = position.y;

    //    width = textHeight + 4;
    //    height = textWidth + 8;
    //}



    var maxBounds = getOverviewDimensionMaxBounds(dimension);

    

    points.push({ x: maxBounds.x, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.height });
    points.push({ x: maxBounds.x, y: maxBounds.height });

    return points;
}

function getOverviewDimensionMaxBounds(dimension) {

    var textWidth = (getWidthOfText("" + dimension.displayLength, curFontName, dimensionFontSize)/scalingFactor);
    var textHeight = (10 / scalingFactor);
    var textBuffer = (4/ scalingFactor);
    //var layerIncrement = (config.configurationDimensionLayerIncrement/scalingFactor);
    var layerIncrement = isPdfDrawing === true ? (config.summaryDimensionLayerIncrement / scalingFactor) : (config.configurationDimensionLayerIncrement / scalingFactor);
    var length = dimension.length;//(dimension.length/ scalingFactor)
    var position;

    var x;
    var y;

    var width;
    var height;
    var startPoint;

    if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT || dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT || dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {

        startPoint = JSON.parse(JSON.stringify(dimension.startPoint));

        if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
            startPoint.x = (((oW - mW) / 2) / scalingFactor) - (layerIncrement * dimension.layerNumber);
        } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
            //dimension.startPoint.x = (layerIncrement * dimension.layerNumber);
        } else {
            startPoint.x = (layerIncrement * dimension.layerNumber);
        }

        length = -length;

        if (dimension.type !== config.DIMENSION_TYPE.VERTICAL_INNER) {
            //if (dimension.style === config.DIMENSION_STYLE.JUMP_UP && textWidth > Math.abs(dimension.length) - 40) {
            //    dimension.jumpingUp = true;
            //} else if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN && textWidth > Math.abs(dimension.length) - 30) {
            //    dimension.jumpingDown = true;
            //}
            dimension.startPoint.y = (((mH / scalingFactor) + (topDimensionLayerHeight / scalingFactor) + (emptyVerticalSpace/scalingFactor) / 2 + (topBufferHeight/scalingFactor)) - (startPoint.y + (dimension.yDimensionOffset/scalingFactor)));
        } else {
            dimension.startPoint.y = (mH / scalingFactor) - (startPoint.y + (dimension.yDimensionOffset / scalingFactor));
        }

        

        if (dimension.style === config.DIMENSION_STYLE.STANDARD) {
            position = getStandardTextCentre(startPoint, textWidth, textHeight, textBuffer, length);
        } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
            if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
                position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) + (15 / scalingFactor), y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
            } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
                position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) - (15 / scalingFactor), y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
            }
        } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
            if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
                position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) + layerIncrement, y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
            } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
                position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) - layerIncrement, y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
            }
        } else {
            if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
                if (textWidth > Math.abs(length) - (30/scalingFactor)) {
                    position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)), y: (((startPoint.y + (20/scalingFactor) + Math.abs(length)) - (textWidth / 2)) - textBuffer) };
                } else {
                    position = getStandardTextCentre(startPoint, textWidth, textHeight, textBuffer, length);
                }
            } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
                position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)), y: (((startPoint.y - (30/scalingFactor) - (Math.abs(length))) - (textWidth / 2)) - textBuffer) };
            }
        }

        

        //if (dimension.style === config.DIMENSION_STYLE.STANDARD) {
        //    position = getStandardTextCentre();
        //} else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
        //    if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
        //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) + (15 / scalingFactor), y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
        //    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
        //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) - (15 / scalingFactor), y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
        //    }
        //} else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
        //    if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
        //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) + layerIncrement, y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
        //    } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
        //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) - layerIncrement, y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
        //    }
        //} else {
        //    if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
        //        if (dimension.jumpingDown && dimension.jumpingDown === true) {
        //            position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y + (20 / scalingFactor) + Math.abs(length)) - (textWidth / 2)) - textBuffer) };
        //        } else {
        //            position = getStandardTextCentre();
        //        }
        //    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
        //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y - (30 / scalingFactor) - (Math.abs(length))) - (textWidth / 2)) - textBuffer) };
        //    }
        //}

        //function getStandardTextCentre() {
        //    return { x: (((oW - mW)/2)/scalingFactor) - ((layerIncrement * dimension.layerNumber)+ ((textHeight + 4)/2)), y: ((dimension.startPoint.y + ((length - 2) / 2)) + (textWidth)) - textBuffer };
        //}

        x = position.x;
        y = position.y;

        width = textHeight + 4;
        height = textWidth + 8;
    } else {

        startPoint = JSON.parse(JSON.stringify(dimension.startPoint));

        if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
            startPoint.y = (topDimensionLayerHeight / scalingFactor) - (layerIncrement * dimension.layerNumber);
        } else if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_BOTTOM) {
            if (dimension.layerNumber === 1) {
                startPoint.y = layerIncrement;
            } else {
                startPoint.y = (layerIncrement * (dimension.layerNumber - 1)) + layerIncrement;
            }

        } else if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_INNER) {
            //#unfinished, need to cater for horizontal inner dimensions
        } else {
            startPoint.y = layerIncrement * dimension.layerNumber || 0;
        }
        
        if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT && textWidth > length - (40 / scalingFactor)) {
            position = { x: startPoint.x + length + textWidth + textBuffer, y: (startPoint.y - (textHeight / 2)) - (1 / scalingFactor) };
        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT && textWidth > length - (40 / scalingFactor)) {
            position = { x: startPoint.x - (20 / scalingFactor + textWidth + textBuffer), y: (startPoint.y - (textHeight / 2)) - (1 / scalingFactor) };
        } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
            if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
                position = { x: (startPoint.x + ((length - (2 / scalingFactor)) / 2) - (textWidth / 2)) - textBuffer, y: ((startPoint.y - (textHeight / 2)) - (1 / scalingFactor)) - layerIncrement };
            } else {
                position = { x: (startPoint.x + ((length - (2/scalingFactor)) / 2) - (textWidth / 2)) - textBuffer, y: ((startPoint.y - (textHeight / 2)) - (1/scalingFactor)) + layerIncrement };
            }
        } else {
            position = { x: (startPoint.x + ((length - (2 / scalingFactor)) / 2) - (textWidth / 2)) - textBuffer, y: (startPoint.y - (textHeight / 2)) - (1 / scalingFactor) };
        }
        
        
        //if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT && textWidth > length - (40/scalingFactor)) {
        //    position = { x: dimension.startPoint.x + length + textWidth + textBuffer, y: dimension.startPoint.y + (layerIncrement * (dimension.layerNumber - 1)) };
        //} else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT && textWidth > length - (40/scalingFactor)) {
        //    position = { x: dimension.startPoint.x - (20 / scalingFactor + textWidth + textBuffer), y: dimension.startPoint.y + (layerIncrement * (dimension.layerNumber - 1)) };
        //} else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
        //    if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
        //        position = { x: (dimension.startPoint.x + ((length - 2) / 2) - (textWidth / 2)) - textBuffer, y: dimension.startPoint.y - layerIncrement};
        //    } else {
        //        position = { x: (dimension.startPoint.x + ((length - 2) / 2) - (textWidth / 2)) - textBuffer, y: dimension.startPoint.y - layerIncrement };
        //    }
        //} else {
        //    position = { x: (dimension.startPoint.x + ((length - (2/scalingFactor)) / 2) - (textWidth / 2)) - textBuffer, y: dimension.startPoint.y + (layerIncrement * (dimension.layerNumber-1)) };
        //}
        //if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP && dimension.style !== config.DIMENSION_STYLE.POP_LAYER) {
        //    position.y = topDimensionLayerHeight + (dimension.startPoint.y + (layerIncrement * (dimension.layerNumber)));
        //}
        x = position.x;
        y = position.y;

        width = textWidth + 8;
        height = textHeight + 2;
    }

    return { x: x, y: y, width: width, height: height };

    function getStandardTextCentre(startPoint, textWidth, textHeight, textBuffer, length) {
        return { x: (startPoint.x - (textHeight / 2)) - (2 / scalingFactor), y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
    }
}


function addHitAreasForEquipmentDimensions(dimensions, drawingObjects) {
    var hitAreas = {};

    dimensions.forEach(function (dimension) {

        if (dimension.type === config.DIMENSION_TYPE.FREE_ANGLE && dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' && dimension.showAsHyperlink === true) {

            if (hitAreas[dimension.drawingGroupName] === undefined) {
                hitAreas[dimension.drawingGroupName] = new HitArea(dimension.drawingGroupName, getEquipmentDimensionPoints(dimension), getEquipmentDimensionMaxBounds(dimension));
                //if (dimension.linkedDrawingGroupName === undefined) {
                //hitAreas[dimension.drawingGroupName].setDontFlipYAxis(true);
                //} /*else {
                //    var breakHere = 0;
                //}*/
            }
        } else {
            var breakHere = 0;
        }
    });

    Object.keys(hitAreas).forEach(function (key) {
        hitAreas[key].setInteraction(offerManager.getDrawingGroupInteractionObject(config.INTERACTION_ID.DRAWING_GROUP, key));
        hitAreas[key].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(hitAreas[key].interaction.callbacks);
        drawingObjects.push(hitAreas[key]);
    });

    /*
        
    */

}

function getEquipmentDimensionPoints(dimension) {
    var points = [];

    //var textWidth = getWidthOfText("" + overviewDimension.displayLength, curFontName, dimensionFontSize);
    //var textHeight = 10;
    //var textBuffer = 5;
    //var layerIncrement = config.configurationDimensionLayerIncrement;
    //var position ;

    //var x;
    //var y;

    //var width;
    //var height;

    //if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT || dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT || dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
    //    if (dimension.jumpingRight === true) {
    //        position = { x: dimension.startPoint.x + dimension.length + textWidth + 4, y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
    //    } else if (dimension.jumpingLeft === true) {
    //        position = { x: dimension.lineStart.x - (textWidth + 4), y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
    //    } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
    //        if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
    //            position = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: ((dimension.startPoint.y - (textHeight / 2)) - 1) - layerIncrement };
    //        } else {
    //            position = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: ((dimension.startPoint.y - (textHeight / 2)) - 1) + layerIncrement };
    //        }
    //    } else {
    //        position = { x: (dimension.startPoint.x + ((dimension.length - 2) / 2) - (textWidth / 2)) - 4, y: (dimension.startPoint.y - (textHeight / 2)) - 1 };
    //    }

    //    x = position.x;
    //    y = position.y;

    //    width = textWidth + 8;
    //    height = textHeight + 2;
    //} else {
    //    if (dimension.style === config.DIMENSION_STYLE.STANDARD) {
    //        position = getStandardTextCentre();
    //    } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
    //        if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) + 15, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) - 15, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        }
    //    } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
    //        if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) + layerIncrement, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2) - layerIncrement, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //        }
    //    } else {
    //        if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
    //            if (dimension.jumpingDown && dimension.jumpingDown === true) {
    //                position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y + 20 + Math.abs(dimension.length)) - (textWidth / 2)) - 4) };
    //            } else {
    //                position = getStandardTextCentre();
    //            }
    //        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
    //            position = { x: ((dimension.lineStart.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y - 30 - (Math.abs(dimension.length))) - (textWidth / 2)) - 4) };
    //        }
    //    }

    //    function getStandardTextCentre() {
    //        return { x: (dimension.lineStart.x - (textHeight / 2)) - 2, y: ((dimension.lineStart.y + ((dimension.length - 2) / 2)) - (textWidth / 2)) - 4 };
    //    }

    //    x = position.x;
    //    y = position.y;

    //    width = textHeight + 4;
    //    height = textWidth + 8;
    //}



    var maxBounds = getEquipmentDimensionMaxBounds(dimension);



    points.push({ x: maxBounds.x, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.y });
    points.push({ x: maxBounds.width, y: maxBounds.height });
    points.push({ x: maxBounds.x, y: maxBounds.height });

    return points;
}

function getEquipmentDimensionMaxBounds(dimension) {

    var textWidth = (getWidthOfText("" + dimension.displayLength, curFontName, dimensionFontSize) / scalingFactor);
    var textHeight = (10 / scalingFactor);
    var textBuffer = (4 / scalingFactor);
    //var layerIncrement = (config.configurationDimensionLayerIncrement/scalingFactor);
    var layerIncrement = equipmentLayerIncrement;//isPdfDrawing === true ? (config.summaryDimensionLayerIncrement / scalingFactor) : (config.configurationDimensionLayerIncrement / scalingFactor);
    var length = dimension.length;//(dimension.length/ scalingFactor)
    var position;

    var x;// = dimension.startPoint.x;
    var y;// = mH / scalingFactor - dimension.startPoint.y;

    var width;
    var height;

    var lengthOfDimension = dimension.length;

    if (dimension.endPoint.x > dimension.startPoint.x) {
        if (dimension.style === config.DIMENSION_STYLE.STANDARD || (dimension.endPoint.x - dimension.startPoint.x) > 80/scalingFactor) {
            //x = dimension.endPoint.x + Math.cos((180) * Math.PI / 180) * lengthOfDimension / 2;
            //y = dimension.endPoint.y + Math.sin((180) * Math.PI / 180) * lengthOfDimension / 2;
            x = dimension.endPoint.x - lengthOfDimension / 2 - textWidth/2 - textBuffer;
            y = dimension.endPoint.y - textHeight / 2 - textBuffer;
            //dimension.bgWidth = lengthOfDimension + 4;
            //dimension.bgHeight = 16;
        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
            //x = dimension.startPoint.x + Math.cos((180) * Math.PI / 180) * 50;
            //y = dimension.startPoint.y + Math.sin((180) * Math.PI / 180) * 1;
            x = dimension.startPoint.x - 150 - textWidth - textBuffer * 2;
            y = dimension.startPoint.y - textHeight / 2 - textBuffer;
            //dimension.bgWidth = 50 + textWidth;
            //dimension.bgHeight = 16;
        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
            //x = dimension.endPoint.x + Math.cos((180) * Math.PI / 180) * (-50);
            //y = dimension.endPoint.y + Math.sin((180) * Math.PI / 180) * 1;
            x = dimension.endPoint.x + 150;
            y = dimension.endPoint.y - textHeight / 2 - textBuffer;
            //dimension.bgWidth = 50 + textWidth;
            //dimension.bgHeight = 16;
        }

    } else {
        if (dimension.style === config.DIMENSION_STYLE.STANDARD || (Math.abs(dimension.startPoint.y - dimension.endPoint.y)) > 80/scalingFactor) {
            //x = dimension.startPoint.x + Math.cos((270) * Math.PI / 180) * lengthOfDimension / 2;
            //y = dimension.startPoint.y + Math.sin((270) * Math.PI / 180) * lengthOfDimension / 2;
            x = dimension.startPoint.x - textHeight / 2 - textBuffer;
            y = dimension.startPoint.y + lengthOfDimension / 2 - textWidth / 2 - textBuffer;
            //dimension.bgWidth = 16;
            //dimension.bgHeight = lengthOfDimension + 4;
        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
            //x = dimension.endPoint.x + Math.cos((270) * Math.PI / 180) * 1;
            //y = dimension.endPoint.y + Math.sin((270) * Math.PI / 180) * 50;
            x = dimension.endPoint.x - textHeight / 2 - textBuffer;
            y = dimension.endPoint.y + 150;
            //dimension.bgWidth = 16;
            //dimension.bgHeight = 50 + textWidth;
        } else if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
            if (dimension.length < 0) {
                //x = dimension.startPoint.x + Math.cos((270) * Math.PI / 180) * 1;
                //y = dimension.startPoint.y + Math.sin((270) * Math.PI / 180) * (-50);
                x = dimension.startPoint.x - textHeight / 2 - textBuffer;
                y = dimension.endPoint.y - 150 - textWidth - textBuffer * 2;
                //dimension.bgWidth = 16;
                //dimension.bgHeight = 50 + textWidth;
            } else {
                //x = dimension.endPoint.x + Math.cos((270) * Math.PI / 180) * 1;
                //y = dimension.endPoint.y + Math.sin((270) * Math.PI / 180) * 50;
                x = dimension.endPoint.x - textHeight / 2 - textBuffer;
                y = dimension.endPoint.y - lengthOfDimension - 150 - textWidth - textBuffer * 2;
                //dimension.bgTopLeft.x = dimension.endPoint.x - 8 + Math.cos((angleOfLine) * Math.PI / 180) * 1;
                //dimension.bgTopLeft.y = dimension.endPoint.y + Math.sin((angleOfLine) * Math.PI / 180) * 50;
                //dimension.bgWidth = 16;
                //dimension.bgHeight = 50 + 4;
                
                //dimension.bgWidth = 16;
                //dimension.bgHeight = 50 + textWidth;
            }


        }
    }
    if (dimension.startPoint.x !== dimension.endPoint.x) {//horizontal
        //x = dimension.startPoint.x + dimension.length / 2;
        //y = dimension.startPoint.y - textHeight / 2;
        width = textWidth + textBuffer * 2;
        height = textHeight + textBuffer * 2;
    } else {//vertical
        //x = dimension.startPoint.x + textHeight / 2;
        //y = dimension.startPoint.y - dimension.length / 2;
        width = textHeight + textBuffer * 2;
        height = textWidth + textBuffer * 2;
    }

    ////if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT || dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT || dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {

    //    var startPoint = JSON.parse(JSON.stringify(dimension.startPoint));

    //    //if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
    //    //    startPoint.x = (((oW - mW) / 2) / scalingFactor) - (layerIncrement * dimension.layerNumber);
    //    //} else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
    //    //    //dimension.startPoint.x = (layerIncrement * dimension.layerNumber);
    //    //} else {
    //    //    startPoint.x = (layerIncrement * dimension.layerNumber);
    //    //}

    //    length = -length;

    //    //if (dimension.type !== config.DIMENSION_TYPE.VERTICAL_INNER) {
    //    //    //if (dimension.style === config.DIMENSION_STYLE.JUMP_UP && textWidth > Math.abs(dimension.length) - 40) {
    //    //    //    dimension.jumpingUp = true;
    //    //    //} else if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN && textWidth > Math.abs(dimension.length) - 30) {
    //    //    //    dimension.jumpingDown = true;
    //    //    //}
    //    //    dimension.startPoint.y = (((mH / scalingFactor) + (topDimensionLayerHeight / scalingFactor) + (emptyVerticalSpace / scalingFactor) / 2 + (topBufferHeight / scalingFactor)) - (startPoint.y + (dimension.yDimensionOffset / scalingFactor)));
    //    //} else {
    //        dimension.startPoint.y = (mH / scalingFactor) - (startPoint.y + (dimension.yDimensionOffset / scalingFactor));
    //    //}



    //    //if (dimension.style === config.DIMENSION_STYLE.STANDARD) {
    //        position = getStandardTextCentre();
    //    //} else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
    //    //    if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
    //    //        position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) + (15 / scalingFactor), y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
    //    //        position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) - (15 / scalingFactor), y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    }
    //    //} else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
    //    //    if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
    //    //        position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) + layerIncrement, y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
    //    //        position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)) - layerIncrement, y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    }
    //    //} else {
    //    //    if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
    //    //        if (textWidth > Math.abs(length) - (30 / scalingFactor)) {
    //    //            position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)), y: (((startPoint.y + (20 / scalingFactor) + Math.abs(length)) - (textWidth / 2)) - textBuffer) };
    //    //        } else {
    //    //            position = getStandardTextCentre();
    //    //        }
    //    //    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
    //    //        position = { x: ((startPoint.x - (textHeight / 2)) - (2 / scalingFactor)), y: (((startPoint.y - (30 / scalingFactor) - (Math.abs(length))) - (textWidth / 2)) - textBuffer) };
    //    //    }
    //    //}

    //    //function getStandardTextCentre() {
    //    //    return { x: (startPoint.x - (textHeight / 2)) - (2 / scalingFactor), y: ((startPoint.y + ((length - (2 / scalingFactor)) / 2)) - (textWidth / 2)) - textBuffer };
    //    //}

    //    //if (dimension.style === config.DIMENSION_STYLE.STANDARD) {
    //    //    position = getStandardTextCentre();
    //    //} else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
    //    //    if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT) {
    //    //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) + (15 / scalingFactor), y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT) {
    //    //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) - (15 / scalingFactor), y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    }
    //    //} else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
    //    //    if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
    //    //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) + layerIncrement, y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
    //    //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2) - layerIncrement, y: ((dimension.startPoint.y + ((length - 2) / 2)) - (textWidth / 2)) - textBuffer };
    //    //    }
    //    //} else {
    //    //    if (dimension.style === config.DIMENSION_STYLE.JUMP_DOWN) {
    //    //        if (dimension.jumpingDown && dimension.jumpingDown === true) {
    //    //            position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y + (20 / scalingFactor) + Math.abs(length)) - (textWidth / 2)) - textBuffer) };
    //    //        } else {
    //    //            position = getStandardTextCentre();
    //    //        }
    //    //    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_UP) {
    //    //        position = { x: ((dimension.startPoint.x - (textHeight / 2)) - 2), y: (((dimension.startPoint.y - (30 / scalingFactor) - (Math.abs(length))) - (textWidth / 2)) - textBuffer) };
    //    //    }
    //    //}

    //    function getStandardTextCentre() {
    //        return { x: (((oW - mW)/2)/scalingFactor) - ((layerIncrement * dimension.layerNumber)+ ((textHeight + 4)/2)), y: ((dimension.startPoint.y + ((length - 2) / 2)) + (textWidth)) - textBuffer };
    //    }

    //    x = position.x;
    //    y = position.y;

    //    width = textHeight + 4;
    //    height = textWidth + 8;
    ////} else {

    ////    var startPoint = JSON.parse(JSON.stringify(dimension.startPoint));

    ////    if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
    ////        startPoint.y = (topDimensionLayerHeight / scalingFactor) - (layerIncrement * dimension.layerNumber);
    ////    } else if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_BOTTOM) {
    ////        if (dimension.layerNumber === 1) {
    ////            startPoint.y = layerIncrement / 2;
    ////        } else {
    ////            startPoint.y = (layerIncrement * (dimension.layerNumber - 1)) + layerIncrement / 2;
    ////        }

    ////    } else if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_INNER) {
    ////        //#unfinished, need to cater for horizontal inner dimensions
    ////    } else {
    ////        startPoint.y = layerIncrement * dimension.layerNumber || 0;
    ////    }

    ////    if (dimension.style === config.DIMENSION_STYLE.JUMP_RIGHT && textWidth > length - (40 / scalingFactor)) {
    ////        position = { x: startPoint.x + length + textWidth + textBuffer, y: (startPoint.y - (textHeight / 2)) - (1 / scalingFactor) };
    ////    } else if (dimension.style === config.DIMENSION_STYLE.JUMP_LEFT && textWidth > length - (40 / scalingFactor)) {
    ////        position = { x: startPoint.x - (20 / scalingFactor + textWidth + textBuffer), y: (startPoint.y - (textHeight / 2)) - (1 / scalingFactor) };
    ////    } else if (dimension.style === config.DIMENSION_STYLE.POP_LAYER) {
    ////        if (dimension.type === config.DIMENSION_TYPE.HORIZONTAL_TOP) {
    ////            position = { x: (startPoint.x + ((length - (2 / scalingFactor)) / 2) - (textWidth / 2)) - textBuffer, y: ((startPoint.y - (textHeight / 2)) - (1 / scalingFactor)) - layerIncrement };
    ////        } else {
    ////            position = { x: (startPoint.x + ((length - (2 / scalingFactor)) / 2) - (textWidth / 2)) - textBuffer, y: ((startPoint.y - (textHeight / 2)) - (1 / scalingFactor)) + layerIncrement };
    ////        }
    ////    } else {
    ////        position = { x: (startPoint.x + ((length - (2 / scalingFactor)) / 2) - (textWidth / 2)) - textBuffer, y: (startPoint.y - (textHeight / 2)) - (1 / scalingFactor) };
    ////    }


        
    ////    x = position.x;
    ////    y = position.y;

    ////    width = textWidth + 8;
    ////    height = textHeight + 2;
    ////}
    //return { x: dimension.startPoint.x / scalingFactor, y: 100 / scalingFactor, width: 100/ scalingFactor, height: scalingFactor };
    /*return { x: dimension.startPoint.x * scalingFactor, y: dimension.startPoint.y * scalingFactor, width: 100, height: 100 };*/
    return { x: x, y: y, width: width, height: height };
    //return { x: 0, y: 0, width: 400, height: 400 };
}

function removeKeysForEvent(event, doRefresh) {
    var keysToRemove = [];
    Object.keys(interactionState).forEach(function (key) {
        if (key.includes(event)) {
            keysToRemove.push(key);
        }
    });
    if (keysToRemove.length > 0) {
        keysToRemove.forEach(function (key) {
            delete interactionState[key];
        });
        //updateDrawing(true);
        if (doRefresh === true) {
            graphicsLayer.refreshDrawingGroup();
            graphicsLayer.refreshTableInteractionHighlights();
        }
    }
}

function addGraphicsManagerToInteractionCallbackChain(callbacks) {
    
    return {
        click: callbacks.click !== undefined ? function (interactionObj, mousePos) {
            
            
            //globals.eventFiredFromDrawingElement = true;
            try {
                var dontPassEventOn = handleInteractionEvent(interactionObj, config.INTERACTION_EVENT.CLICK);
                if (dontPassEventOn === false) {
                    callbacks.click(mousePos, interactionObj.interactionBehaviour, interactionObj.hitMaskId);
                }
            } catch (eventWireEx) {
                TScMessenger.writeDebugMessage('GraphicsManager.js, addGraphicsManagerToInteractionCallbackChain, click, error processing click event');
            }

        } : undefined,
        mouseover: callbacks.mouseover !== undefined ? function (interactionObj, mousePos) {
            
            try {
                var dontPassEventOn = handleInteractionEvent(interactionObj, config.INTERACTION_EVENT.MOUSE_OVER);
                if (dontPassEventOn === false) {
                    callbacks.mouseover(mousePos);
                }
            } catch (eventWireEx) {
                TScMessenger.writeDebugMessage('GraphicsManager.js, addGraphicsManagerToInteractionCallbackChain, mouseover, error processing mouseover event');
            }

        } : undefined,
        mouseout: callbacks.mouseout !== undefined ? function (interactionObj, mousePos) {
            
            try {
                var dontPassEventOn = handleInteractionEvent(interactionObj, config.INTERACTION_EVENT.MOUSE_OUT);
                if (dontPassEventOn === false) {
                    callbacks.mouseout(mousePos);
                }
            } catch (eventWireEx) {
                TScMessenger.writeDebugMessage('GraphicsManager.js, addGraphicsManagerToInteractionCallbackChain, mouseout, error processing mouseout event');
            }

        } : undefined,
        mousemove: callbacks.mousemove !== undefined ? function (interactionObj, mousePos) {

            try {
                var dontPassEventOn = handleInteractionEvent(interactionObj, config.INTERACTION_EVENT.MOUSE_MOVE);
                if (dontPassEventOn === false) {
                    callbacks.mousemove(mousePos);
                }
            } catch (eventWireEx) {
                TScMessenger.writeDebugMessage('GraphicsManager.js, addGraphicsManagerToInteractionCallbackChain, mousemove, error processing mousemove event');
            }

        } : undefined,
        dragEnd: callbacks.dragEnd !== undefined ? function (interactionObj) {
            if (interactionObj) {
                callbacks.dragEnd(interactionObj.dragInfoObjects);
            } else {
                callbacks.dragEnd();
            }
            
        } : undefined,
        dragStart: callbacks.dragStart !== undefined ? function (interactionObj) {
            callbacks.dragStart(interactionObj.dragInfoObjects);
        } : undefined,
        dragMove: callbacks.dragMove !== undefined ? function (interactionObj) {
            callbacks.dragMove(interactionObj.dragInfoObjects);
        } : undefined
    }
    
}

function handleInteractionEvent(interactionObj, eventType) {
    switch (interactionObj.id) {
        case config.INTERACTION_ID.DRAWING_GROUP:
        case config.INTERACTION_ID.XXED_OUT_VALUE:
            return handleDrawingGroupInteraction(interactionObj, eventType);
        case config.INTERACTION_ID.TABLE_CELL:
            return handleNonMainDrawingAreaTextInteraction(interactionObj, eventType);
        case config.INTERACTION_ID.COMPLIANCE_DASHBOARD_SETTINGS:  
        case config.INTERACTION_ID.COMPLIANCE_DASHBOARD_CRUMB:
        case config.INTERACTION_ID.COMPLIANCE_DASHBOARD_DRILL_DOWN:
            return handleComplianceDashboardTextInteraction(interactionObj, eventType);
        //case config.INTERACTION_ID.COG_DIMENSION:
        //    return handleCOGDimensionMarkerInteraction(interactionObj, eventType);
    }

    return false;
}

//function handleCOGDimensionMarkerInteraction(interactionObj, eventType) {
//    switch(eventType) {
//        case config.INTERACTION_EVENT.CLICK:
//            if (interactionObj.hitMasks && interactionObj.hitMasks.length > 0) {
//                if (interactionObj.hitMaskId !== undefined) {
//                    if (interactionState[interactionObj.elementId + '_' + 'PINNED'] === undefined) {
//                        removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
//                        interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.CLICK] = interactionObj.elementId;
//                        interactionState[interactionObj.elementId + '_' + 'ACTIVE'] = interactionObj.elementId;
//                        interactionState[interactionObj.elementId + '_' + 'PINNED'] = interactionObj.elementId;
//                    } else {
//                        removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
//                        removeKeysForEvent('ACTIVE', false);
//                        //removeKeysForEvent('PINNED', false);
//                        delete interactionState[interactionObj.elementId + '_' + 'PINNED'];
//                    }
//                    //return true;
//                } else {
//                    doStandardClickHandling();
//                }
//            } else {
//                doStandardClickHandling();
//            }
//            function doStandardClickHandling() {
//                removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
//                interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.CLICK] = interactionObj.elementId;
//                interactionState[interactionObj.elementId + '_' + 'ACTIVE'] = interactionObj.elementId;   
//            }
//            //updateDrawing(true);
//            graphicsLayer.refreshDrawingGroup();
//            return false;
//        case config.INTERACTION_EVENT.MOUSE_OVER:
//            interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.MOUSE_OVER] = interactionObj.elementId;
//            delete interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.MOUSE_OUT];
//            graphicsLayer.refreshDrawingGroup(interactionObj.elementId);
//            //updateDrawing(true);
//            return true;
//        case config.INTERACTION_EVENT.MOUSE_MOVE:
//            interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.MOUSE_OVER] = interactionObj.elementId;
//            delete interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.MOUSE_OUT];
//            graphicsLayer.refreshDrawingGroup(interactionObj.elementId);
//            //updateDrawing(true);
//            return true;
//        case config.INTERACTION_EVENT.MOUSE_OUT:
//            //delete interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.CLICK];
//            interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.MOUSE_OUT] = interactionObj.elementId;
//            delete interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.MOUSE_OVER];
//            delete interactionState[interactionObj.elementId + '_' + 'ACTIVE'];
//            graphicsLayer.refreshDrawingGroup(interactionObj.elementId);
//            //updateDrawing(true);
//            return true;
//    }
//}

function handleDrawingGroupInteraction(interactionObj, eventType) {
    switch (eventType) {
        case config.INTERACTION_EVENT.CLICK:
            //removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
            //interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.CLICK] = interactionObj.elementId;
            //interactionState[interactionObj.elementId + '_' + 'ACTIVE'] = interactionObj.elementId;
            //graphicsLayer.refreshDrawingGroup();
            ////updateDrawing(true);
            //return false;
            if (interactionObj.hitMasks && interactionObj.hitMasks.length > 0) {
                if (interactionObj.hitMaskId !== undefined && interactionObj.interactionBehaviour === config.INTERACTION_BEHAVIOUR.PINNABLE) {
                    if (interactionState[interactionObj.elementId + '_' + 'PINNED'] === undefined) {
                        removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
                        interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.CLICK] = interactionObj.elementId;
                        interactionState[interactionObj.elementId + '_' + 'ACTIVE'] = interactionObj.elementId;
                        interactionState[interactionObj.elementId + '_' + 'PINNED'] = interactionObj.elementId;
                    } else {
                        removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
                        removeKeysForEvent('ACTIVE', false);
                        //removeKeysForEvent('PINNED', false);
                        delete interactionState[interactionObj.elementId + '_' + 'PINNED'];
                    }
                    //return true;
                } else {
                    //alert('doing standard click');
                    doStandardClickHandling();
                }
            } else {
                //alert('doing standard click');
                doStandardClickHandling();
            }
            
            //updateDrawing(true);
            graphicsLayer.refreshDrawingGroup(interactionObj.elementId);
            if(curOpenMenuId) {
                graphicsLayer.refreshDrawingGroup(curOpenMenuId);
                previousOpenMenuId = curOpenMenuId;
                curOpenMenuId = interactionObj.elementId;
            }
            return false;
        case config.INTERACTION_EVENT.MOUSE_OVER:
            interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] = interactionObj.elementId;
            delete interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OUT];
            graphicsLayer.refreshDrawingGroup(interactionObj.elementId);
            //updateDrawing(true);
            return true;
        case config.INTERACTION_EVENT.MOUSE_OUT:
            //delete interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.CLICK];
            interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OUT] = interactionObj.elementId;
            delete interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER];
            delete interactionState[interactionObj.elementId + '_' + 'ACTIVE'];
            graphicsLayer.refreshDrawingGroup(interactionObj.elementId);
            //updateDrawing(true);
            return true;
    }
    function doStandardClickHandling() {
        removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
        interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.CLICK] = interactionObj.elementId;
        interactionState[interactionObj.elementId + '_' + 'ACTIVE'] = interactionObj.elementId;
    }
}

function handleNonMainDrawingAreaTextInteraction(interactionObj, eventType) {
    switch (eventType) {
        case config.INTERACTION_EVENT.CLICK:
            removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
            interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.CLICK] = interactionObj.elementId;
            interactionState[interactionObj.elementId + '_' + 'ACTIVE'] = interactionObj.elementId;
            graphicsLayer.refreshTableInteractionHighlights();
            graphicsLayer.refreshComplianceDashboardInteractionHighlights();
            //updateDrawing(true);
            return false;
        case config.INTERACTION_EVENT.MOUSE_OVER:
            interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] = interactionObj.elementId;
            delete interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OUT];
            graphicsLayer.refreshTableInteractionHighlights();
            graphicsLayer.refreshComplianceDashboardInteractionHighlights();
            //updateDrawing(true);
            return true;
        case config.INTERACTION_EVENT.MOUSE_OUT:
            //delete interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.CLICK];
            interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OUT] = interactionObj.elementId;
            delete interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER];
            delete interactionState[interactionObj.elementId + '_' + 'ACTIVE'];
            graphicsLayer.refreshTableInteractionHighlights();
            graphicsLayer.refreshComplianceDashboardInteractionHighlights();
            //updateDrawing(true);
            return true;
    }
}

function handleComplianceDashboardTextInteraction(interactionObj, eventType) {
    switch (eventType) {
        case config.INTERACTION_EVENT.CLICK:
            removeKeysForEvent(config.INTERACTION_EVENT.CLICK, false);
            //interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.CLICK] = interactionObj.elementId;
            //interactionState[interactionObj.elementId + '_' + 'ACTIVE'] = interactionObj.elementId;
            graphicsLayer.refreshTableInteractionHighlights();
            graphicsLayer.refreshComplianceDashboardInteractionHighlights();
            //updateDrawing(true);
            return false;
        case config.INTERACTION_EVENT.MOUSE_OVER:
            interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER] = interactionObj.elementId;
            delete interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OUT];
            graphicsLayer.refreshTableInteractionHighlights();
            graphicsLayer.refreshComplianceDashboardInteractionHighlights();
            //updateDrawing(true);
            return true;
        case config.INTERACTION_EVENT.MOUSE_OUT:
            //delete interactionState[interactionObj.elementId + '_' + INTERACTION_EVENT.CLICK];
            interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OUT] = interactionObj.elementId;
            delete interactionState[interactionObj.elementId + '_' + config.INTERACTION_EVENT.MOUSE_OVER];
            delete interactionState[interactionObj.elementId + '_' + 'ACTIVE'];
            graphicsLayer.refreshTableInteractionHighlights();
            graphicsLayer.refreshComplianceDashboardInteractionHighlights();
            //updateDrawing(true);
            return true;
    }
}

function convertPolygonsBackToLines(drawingObjects) {
    var foundObjects = [];
    var foundObjectIndexes = [];
    for (var i = drawingObjects.length-1; i >= 0; i--) {
        if (drawingObjects[i].pointsArray) {
            foundObjects.push(drawingObjects[i]);
            drawingObjects.splice(i, 1);
        }
    }
    var explodedPolygonLines = [];
    foundObjects.forEach(function (drawingObject) {
        var pointIndex = 0;
        drawingObject.pointsArray.forEach(function (point) {
            var nextPointIndex;
            if(pointIndex === drawingObject.pointsArray.length-1) {
                nextPointIndex = 0;
            }else {
                nextPointIndex = pointIndex+1;
            }
            var nextPoint = drawingObject.pointsArray[nextPointIndex];
            //startX, startY, endX, endY, layerNumber, blockName, objNumSeqNum, closedIndicator
            explodedPolygonLines.push(new Line(point.x, point.y, nextPoint.x, nextPoint.y, undefined, undefined, point.objNumSeqNum));
            pointIndex++;
        });
    });

    explodedPolygonLines.forEach(function (drawingObject) {
        drawingObjects.push(drawingObject);
    });

    //return foundObject;
}

function groupDrawingObjects(displayObjects, preventFill) {
    var currentObjectNumber = 9999999;
    var currentObject;
    var objectGroup = [];
    var polygon, points = [];
    var groupedDisplayObjects = [];

    for (var j = 0; j < displayObjects.length; j++) {
    
        if (currentObjectNumber !== displayObjects[j].objectNumber || !(displayObjects[j].closedIndicator === 'TRUE')) {
        
            if (currentObjectNumber !== 9999999) {
                //draw what you have

                if (objectGroup.length > 1) {

                    //define polygon object
                    polygon = new Polygon();
                    points = [];

                    //assign x1/y1 points of each line object to polygon array
                    for (var i = 0; i < objectGroup.length; i++) {
                        points.push({ x: objectGroup[i].startPoint.x, y: objectGroup[i].startPoint.y, objNumSeqNum: objectGroup[i].objNumSeqNum});
                        //points.push(objectGroup[i].startPoint.y);
                    }
                    polygon.pointsArray = points;
                    //assign object name to polygon object
                    polygon.name = objectGroup[0].blockName;
                    if (objectGroup[0].bringToFront) {
                        polygon.setBringToFront(true);
                        polygon.setAlpha(0.5);
                    }
                    if (!preventFill) {
                        if (globals.user.getColourDrawings() === true) {
                            polygon.fillColour = utils.getColour(polygon.name, cabColour);
                        } else {
                            polygon.fillColour = config.DRAWING_COLOURS.WHITE;
                        }
                    }
                    

                    polygon.setDrawingGroupName(objectGroup[0].drawingGroupName);
                    if (objectGroup[0].drawingGroupName.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !== -1) {
                        polygon.setColour(config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR);
                        polygon.setFillPolygon(false);
                    }
                    //polygon.closedPath = true;
                    //assign polyugon to current object
                    currentObject = polygon;
                } else {
                    currentObject = objectGroup[0]
                }

                if (currentObject !== undefined) {
                    //currentObject.draw(myOrigin, group, cabColour);
                    groupedDisplayObjects.push(currentObject);
                }
            }

            objectGroup = [];
            currentObjectNumber = displayObjects[j].objectNumber;
        }

        //add currentObject to objectGroup
        objectGroup.push(displayObjects[j]);
    }

    //complete the last drawing object
    if (objectGroup !== undefined) {

        if (objectGroup.length > 1) {

            //define polygon object
            polygon = new Polygon();
            points = [];

            //assign x1/y1 points of each line object to polygon array
            for (i = 0; i < objectGroup.length; i++) {
                points.push({ x: objectGroup[i].startPoint.x, y: objectGroup[i].startPoint.y, objNumSeqNum: objectGroup[i].objNumSeqNum });
                //points.push(objectGroup[i].startPoint.y);
            }
            polygon.pointsArray = points;

            //assign object name to polygon object
            polygon.name = objectGroup[0].blockName;
            if (objectGroup[0].bringToFront) {
                polygon.setBringToFront(true);
                polygon.setAlpha(0.5);
            }

            if (globals.user.getColourDrawings() === true) {
                polygon.fillColour = utils.getColour(polygon.name, cabColour);
            } else {
                polygon.fillColour = config.DRAWING_COLOURS.WHITE;
            }


            polygon.setDrawingGroupName(objectGroup[0].drawingGroupName);
            if (objectGroup[0].drawingGroupName.indexOf(config.ACCESSORY_TYPES.PAYLOAD) !== -1) {
                polygon.setColour(config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR);
            }

            polygon.fillColour = utils.getColour(polygon.name, cabColour);
            //polygon.closedPath = true;
            //assign polyugon to current object
            currentObject = polygon;
        } else {
            currentObject = objectGroup[0]
        }

        if (currentObject !== undefined) {
            //currentObject.draw(myOrigin, group);
            groupedDisplayObjects.push(currentObject);
        }
    }
    return groupedDisplayObjects;
}



function sortDrawingObjects(a, b) {
    //if (a.layerNumber === "" && b.layerNumber === "") {
    //    return 0;
    //}
    //if (a.layerNumber === "") {
    //    return -1;
    //}
    //if (a.type === "circle" /*&& a.circleType !== "0"*/) {
    if ((a.type === "circle" && (a.circleType === "2" || a.circleType === 2 || a.circleType === "1" || a.circleType === 1)) && b.type !== "circle") {
            return 1;
    } else if (a.type !== "circle" && (b.type === "circle" && (b.circleType === "1" || b.circleType === 1 || b.circleType === "2" || b.circleType === 2))) {
            return -1;
    } else if ((a.type === "circle" && (a.circleType === "2" || a.circleType === 2 || a.circleType === "1" || a.circleType === 1)) && (b.type === "circle" && (b.circleType === "1" || b.circleType === 1 || b.circleType === "2" || b.circleType === 2))) {
            /*if ((a.circleType === "0" || a.circleType === 0) && (b.circleType !== "0" || b.circleType !== 0)) {
                return 1;
            } else if ((a.circleType !== "0" || a.circleType !== 0) && (b.circleType === "0" || b.circleType === 0)) {
                return -1;
            } else*/ if ((a.circleType === "2" || a.circleType === 2) && (b.circleType === "1" || b.circleType === 1)) {
                return 1;
            } else if ((a.circleType === "1" || a.circleType === 1) && (b.circleType === "2" || b.circleType === 2)) {
                return -1;
            } else if ((a.circleType === "2" || a.circleType === 2) && (b.circleType === "2" || b.circleType === 2)) {
                if(a.centre.x < b.centre.x) {
                    return -1;
                } else if (a.centre.x > b.centre.x) {
                    return 1;
                } 
            } else if (a.circleType === "1" && b.circleType === "1") {
                if (a.centre.x < b.centre.x) {
                    return -1;
                } else if (a.centre.x > b.centre.x) {
                    return 1;
                } 
            }/*else if (a.circleType < b.circleType) {
                return -1;
            } else if (a.circleType > b.circleType) {
                return 1;
            }*/ /*else if (a.circleType === "0" &&  b.circleType === "0") {
                if (a.layerNumber < b.layerNumber) {
                    return -1;
                }
                if (a.layerNumber > b.layerNumber) {
                    return 1;
                }
                if (a.layerNumber === b.layerNumber) {
                    if (a.objectNumber < b.objectNumber) {
                        return -1;
                    }
                    if (a.objectNumber > b.objectNumber) {
                        return 1;
                    }
                    if (a.objectNumber === b.objectNumber) {
                        if (a.sequenceNumber < b.sequenceNumber) {
                            return -1;
                        }
                        if (a.sequenceNumber > b.sequenceNumber) {
                            return 1;
                        }
                    }
                }
            }*/
        }
    //} else {
        if (a.layerNumber < b.layerNumber) {
            return -1;
        }
        if (a.layerNumber > b.layerNumber) {
            return 1;
        }
        if (a.layerNumber === b.layerNumber) {
            if (a.objectNumber < b.objectNumber) {
                return -1;
            }
            if (a.objectNumber > b.objectNumber) {
                return 1;
            }
            if (a.objectNumber === b.objectNumber) {
                if (a.sequenceNumber < b.sequenceNumber) {
                    return -1;
                }
                if (a.sequenceNumber > b.sequenceNumber) {
                    return 1;
                }
            }
        }
    //}
    

    return 0;
}

//function setCircleColours(displayObjects) {
//    for (var i = 0; i < drawingObjects.length; i++) {
//        if (drawingObjects[i].type === "circle") {
//            if (drawingObjects[i].circleType === "2" || drawingObjects[i].circleType === 2) {
//                drawingObjects[i].fillColour = 
//            } else if (drawingObjects[i].circleType === "1" || drawingObjects[i].circleType === 1) {
            
//            } else if (drawingObjects[i].circleType === "0" || drawingObjects[i].circleType === 0) {

//            }
//        }
//    }
//}

function extractExistingAxles(drawingObjects, chassisObjectType) {
    var extractedAxles = [];
    for (var i = drawingObjects.length - 1; i >= 0; i--) {
        if (drawingObjects[i].type === "circle" && drawingObjects[i].drawingGroupName === chassisObjectType) {
            if (drawingObjects[i].circleType === "2" || drawingObjects[i].circleType === 2 || drawingObjects[i].circleType === "1" || drawingObjects[i].circleType === 1) {
                extractedAxles.push(drawingObjects[i]);
                drawingObjects.splice(i, 1);
            } 
        }
    }
    return extractedAxles;
}

function sortExtractedAxles(axles) {

    axles.sort(function (a, b) {
        if ((a.type === "circle" && (a.circleType === "2" || a.circleType === 2 || a.circleType === "1" || a.circleType === 1)) && b.type !== "circle") {
            return 1;
        } else if (a.type !== "circle" && (b.type === "circle" && (b.circleType === "1" || b.circleType === 1 || b.circleType === "2" || b.circleType === 2))) {
            return -1;
        } else if ((a.type === "circle" && (a.circleType === "2" || a.circleType === 2 || a.circleType === "1" || a.circleType === 1)) && (b.type === "circle" && (b.circleType === "1" || b.circleType === 1 || b.circleType === "2" || b.circleType === 2))) {
            if ((a.circleType === "2" || a.circleType === 2) && (b.circleType === "1" || b.circleType === 1)) {
                return 1;
            } else if ((a.circleType === "1" || a.circleType === 1) && (b.circleType === "2" || b.circleType === 2)) {
                return -1;
            } else if ((a.circleType === "2" || a.circleType === 2) && (b.circleType === "2" || b.circleType === 2)) {
                if (a.centre.x < b.centre.x) {
                    return -1;
                } else if (a.centre.x > b.centre.x) {
                    return 1;
                }
            } else if ((a.circleType === "1" || a.circleType === 1) && (b.circleType === "1" || b.circleType === 1)) {
                if (a.centre.x < b.centre.x) {
                    return -1;
                } else if (a.centre.x > b.centre.x) {
                    return 1;
                }
            }
        }
        return 0;
    });

    
}

function setFlatspotOnAxles(axles) {
    var curAxleNum = 0;
    
    var frontAxleCircle, rearAxleCircle;

    setFrontAndRearAxleCircles(axles);
    var numAxles = curVehicle.getAxlesHolder().getAxles().length;

    curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

        var axlePosition = curVehicle.getAxlesHolder().getAxlePosition(axle);

        if (axle.isAdded()) {
            var liftingOffset = 0;
            if (axle.getRaised()) {
                liftingOffset = axle.getLiftingMaxTravel();
            }
            switch (axlePosition) {
                case config.AXLE_POSITIONS.AXLE_1_FRONT:
                case config.AXLE_POSITIONS.AXLE_2_FRONT:
                    var frontRadiusDiff = frontAxleCircle.radius - axle.getTyreRadius();

                    axle.setFlatspotOffset(frontAxleCircle.centre.y - frontRadiusDiff - axle.getTyreRadius());
                    axles[curAxleNum].centre.y = frontAxleCircle.centre.y - frontRadiusDiff + liftingOffset;
                    axles[curAxleNum].radius = axle.getTyreRadius();
                    axles[curAxleNum + numAxles].centre.y = frontAxleCircle.centre.y - frontRadiusDiff + liftingOffset;

                    //axle.setFlatspotOffset(frontAxleCircle.centre.y - frontAxleCircle.radius);
                    //axle.setTyreRadius(frontAxleCircle.radius);
                    //axles[curAxleNum].centre.y = frontAxleCircle.centre.y + liftingOffset;
                    //axles[curAxleNum].radius = frontAxleCircle.radius;
                    //axles[curAxleNum + numAxles].centre.y = frontAxleCircle.centre.y + liftingOffset;
                    break;
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    var radiusDiff = rearAxleCircle.radius - axle.getTyreRadius();
                    
                    axle.setFlatspotOffset(rearAxleCircle.centre.y - radiusDiff - axle.getTyreRadius());
                    axles[curAxleNum].centre.y = rearAxleCircle.centre.y - radiusDiff + liftingOffset;
                    axles[curAxleNum].radius = axle.getTyreRadius();
                    axles[curAxleNum + numAxles].centre.y = rearAxleCircle.centre.y - radiusDiff + liftingOffset;
                    //axle.setFlatspotOffset(rearAxleCircle.centre.y - rearAxleCircle.radius);
                    //axle.setTyreRadius(rearAxleCircle.radius);
                    //axles[curAxleNum].centre.y = rearAxleCircle.centre.y + liftingOffset;
                    //axles[curAxleNum].radius = rearAxleCircle.radius;
                    //axles[curAxleNum + numAxles].centre.y = rearAxleCircle.centre.y + liftingOffset;
                    break;
            }

        } else {
            var existingCircleOuter = axles[curAxleNum];
            
            axle.setFlatspotOffset(existingCircleOuter.centre.y - existingCircleOuter.radius);
            axle.setTyreRadius(existingCircleOuter.radius)
            //if (drawingObjects[i].centre.y > drawingObjects[i].radius) {
            //    //groundLine above 0
            //    groundLineY = Math.abs(chassisHeightRear - chassisHeightFront);
            //    //groundLineY = Math.abs(drawingObjects[i].radius - drawingObjects[i].centre.y);
            //} else if (drawingObjects[i].centre.y < drawingObjects[i].radius) {
            //    //groundLine below meaning tyre flatspot covered by being outside drawing area
            //    groundLineY = 0;
            //    //groundLineY = 0;
            //} else {
            //    groundLineY = drawingObjects[i].centre.y - drawingObjects[i].radius;


            //}
            
        }
        curAxleNum++;
    });

    function setFrontAndRearAxleCircles(axleCircles) {
        curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

            var axlePosition = curVehicle.getAxlesHolder().getAxlePosition(axle);

            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT) {

                if (frontAxleCircle === undefined) {
                    frontAxleCircle = axleCircles[axle.getNumber() - 1];
                }
                

            } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                if (rearAxleCircle === undefined) {
                    rearAxleCircle = axleCircles[axle.getNumber() - 1];
                }
            }
        });
    }
}

function addOrUpdateAxles(axles, chassisObjectType) {

    var curExistingAxleIndex = 0, curAxleNum = 0;

    var frontAddition = 0, rearAddition = 0;

    if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
        frontAddition = curVehicle.getFrontAddition();
        rearAddition = curVehicle.getRearAddition();
    }

    var addedAxles = [];
    var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(chassisObjectType);

    
    var addTrailerFrontOverhangStartOffset = chassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2;
    var trailerFrontOverhangStartOffset = 0;
    if(addTrailerFrontOverhangStartOffset) {
        trailerFrontOverhangStartOffset = getTrailerFrontOverhangStart(chassisObjectType);
    }
    
    chassisObjectToUse.getAxlesHolder().getAxles().forEach(function (axle) {

        var pathForClick, uniquePathFoDrawingGroup = null;
        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECOND_STEER) {
            pathForClick = chassisObjectToUse.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + chassisObjectToUse.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + axle.getNumber();
            //uniquePathFoDrawingGroup = pathForClick + '.' + axle.getNumber();
        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
            pathForClick = chassisObjectToUse.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + chassisObjectToUse.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + axle.getNumber();
            //uniquePathFoDrawingGroup = pathForClick + '.' + axle.getNumber();
        } else {
            pathForClick = chassisObjectToUse.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId();
            uniquePathFoDrawingGroup = null;
        }

        var axlePosition = chassisObjectToUse.getAxlesHolder().getAxlePosition(axle);

        var liftingOffset = 0;
        if (axle.getRaised()) {
            liftingOffset = axle.getLiftingMaxTravel();
        }

        if (axle.isAdded()) {
            
            switch(axlePosition) {
                case config.AXLE_POSITIONS.AXLE_1_FRONT:
                    addAxleDrawingObjects(pathForClick, uniquePathFoDrawingGroup, chassisObjectToUse.getFrontOverhang(), liftingOffset + axle.getTyreRadius() + frontAddition, axle.getTyreRadius(), !axle.getRaised());
                    break;
                case config.AXLE_POSITIONS.AXLE_2_FRONT:
                    addAxleDrawingObjects(pathForClick, uniquePathFoDrawingGroup, chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front(), liftingOffset + axle.getTyreRadius() + frontAddition, axle.getTyreRadius(), !axle.getRaised());
                    break;
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                    addAxleDrawingObjects(pathForClick, uniquePathFoDrawingGroup, chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, axle.getTyreRadius(), !axle.getRaised());
                    break;
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                    addAxleDrawingObjects(pathForClick, uniquePathFoDrawingGroup, chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, axle.getTyreRadius(), !axle.getRaised());
                    break;
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                    addAxleDrawingObjects(pathForClick, uniquePathFoDrawingGroup, chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, axle.getTyreRadius(), !axle.getRaised());
                    break;
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    addAxleDrawingObjects(pathForClick, uniquePathFoDrawingGroup, chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear() + chassisObjectToUse.getAxle3RearToAxle4Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, axle.getTyreRadius(), !axle.getRaised());
                    break;
            }
            
        } else {
            var existingCircleOuter = axles[curExistingAxleIndex];
            var existingCircleInner = axles[curExistingAxleIndex + axles.length / 2];
            if (!axle.getRaised()) {
                existingCircleOuter.setDrawGroundline(true);
            }
            switch (axlePosition) {
                case config.AXLE_POSITIONS.AXLE_1_FRONT:
                    //addAxleDrawingObjects(chassisObjectToUse.getFrontOverhang(), liftingOffset + axle.getTyreRadius() + frontAddition, radius);
                    var axle1FrontPosition = 0;
                    if (addTrailerFrontOverhangStartOffset) {
                        axle1FrontPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang();
                    }else {
                        axle1FrontPosition = chassisObjectToUse.getPortionOfOverallLengthOverhangingFront() + chassisObjectToUse.getFrontOverhang();
                    }
                    existingCircleOuter.centre.x = axle1FrontPosition;
                    existingCircleInner.centre.x = axle1FrontPosition;
                    break;
                case config.AXLE_POSITIONS.AXLE_2_FRONT:
                    //addAxleDrawingObjects(chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front(), liftingOffset + axle.getTyreRadius() + frontAddition, radius);
                    var axle2FrontPosition = 0;
                    if (addTrailerFrontOverhangStartOffset) {
                        axle2FrontPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front();
                    }else {
                        axle2FrontPosition = chassisObjectToUse.getPortionOfOverallLengthOverhangingFront() + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front();
                    }
                    
                    existingCircleOuter.centre.x = axle2FrontPosition;
                    existingCircleInner.centre.x = axle2FrontPosition;
                    break;
                case config.AXLE_POSITIONS.AXLE_3_FRONT:
                    //addAxleDrawingObjects(chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front(), liftingOffset + axle.getTyreRadius() + frontAddition, radius);

                    var axle3FrontPosition = 0;
                    if (addTrailerFrontOverhangStartOffset) {
                        axle3FrontPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front();
                    }else {
                        axle3FrontPosition = chassisObjectToUse.getPortionOfOverallLengthOverhangingFront() + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front();
                    }


                    existingCircleOuter.centre.x = axle3FrontPosition;
                    existingCircleInner.centre.x = axle3FrontPosition;
                    break;
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                    //addAxleDrawingObjects(chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, radius);

                    var axle1RearPosition = 0;
                    if (addTrailerFrontOverhangStartOffset) {
                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                            axle1RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getKingpinToAxle1Rear();
                        }else if(chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            axle1RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle3Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear();
                        }
                    }else {
                        axle1RearPosition = chassisObjectToUse.getPortionOfOverallLengthOverhangingFront() + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear();
                    }

                    existingCircleOuter.centre.x = axle1RearPosition;
                    existingCircleInner.centre.x = axle1RearPosition;
                    break;
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                    //addAxleDrawingObjects(chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, radius);

                    var axle2RearPosition = 0;
                    if (addTrailerFrontOverhangStartOffset) {
                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                            axle2RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getKingpinToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                        } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            axle2RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                        }
                    } else {
                        axle2RearPosition = chassisObjectToUse.getPortionOfOverallLengthOverhangingFront() + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear();
                    }

                    existingCircleOuter.centre.x = axle2RearPosition;
                    existingCircleInner.centre.x = axle2RearPosition;
                    break;
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                    //addAxleDrawingObjects(chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, radius);

                    var axle3RearPosition = 0;
                    if (addTrailerFrontOverhangStartOffset) {
                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                            axle3RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getKingpinToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear();
                        } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            axle3RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear();
                        }
                    } else {
                        axle3RearPosition = chassisObjectToUse.getPortionOfOverallLengthOverhangingFront() + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear();
                    }


                    existingCircleOuter.centre.x = axle3RearPosition;
                    existingCircleInner.centre.x = axle3RearPosition;
                    break;
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    //addAxleDrawingObjects(chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear() + chassisObjectToUse.getAxle3RearToAxle4Rear(), liftingOffset + axle.getTyreRadius() + rearAddition, radius);

                    var axle4RearPosition = 0;
                    if (addTrailerFrontOverhangStartOffset) {
                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                            axle4RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getKingpinToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear() + chassisObjectToUse.getAxle3RearToAxle4Rear();
                        } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            axle4RearPosition = trailerFrontOverhangStartOffset + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getRearMostFrontAxleToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear() + chassisObjectToUse.getAxle3RearToAxle4Rear();
                        }
                    } else {
                        axle4RearPosition = chassisObjectToUse.getPortionOfOverallLengthOverhangingFront() + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToAxle2Front() + chassisObjectToUse.getAxle2FrontToAxle1Rear() + chassisObjectToUse.getAxle1RearToAxle2Rear() + chassisObjectToUse.getAxle2RearToAxle3Rear() + chassisObjectToUse.getAxle3RearToAxle4Rear();
                    }


                    existingCircleOuter.centre.x = axle4RearPosition;
                    existingCircleInner.centre.x = axle4RearPosition;
                    break;
            }
            existingCircleOuter.centre.y = existingCircleOuter.centre.y + liftingOffset;
            existingCircleInner.centre.y = existingCircleInner.centre.y + liftingOffset;
            
            //existingCircleOuter.setDrawingGroupName(path);
            //existingCircleInner.setDrawingGroupName(path);
            if (uniquePathFoDrawingGroup) {
                existingCircleOuter.setDrawingGroupName(uniquePathFoDrawingGroup);
                existingCircleInner.setDrawingGroupName(uniquePathFoDrawingGroup);
                existingCircleOuter.clickGroupName = pathForClick;
                existingCircleInner.clickGroupName = pathForClick;

            } else {
                existingCircleOuter.setDrawingGroupName(pathForClick);
                existingCircleInner.setDrawingGroupName(pathForClick);
            }
            existingCircleOuter.setDrawingOrder(4000);
            existingCircleInner.setDrawingOrder(4000);
            curExistingAxleIndex++;
        }
        curAxleNum++;
    });

    return axles.concat(addedAxles);

    function addAxleDrawingObjects(pathForClick, pathForDrawingGroup, x, y, radius, drawGroundline) {
        var outerAxleCircle = new Circle(curVehicle.getPortionOfOverallLengthOverhangingFront() + x, y, radius, CIRCLE_TYPE.OUTER, true);
        if (drawGroundline === true) {
            outerAxleCircle.setDrawGroundline(true);
        }
        //outerAxleCircle.setDrawingGroupName(config.CHASSIS_OBJECT_TYPES.VEHICLE);
        
        addedAxles.push(outerAxleCircle);

        var innerAxleCricle = new Circle(curVehicle.getPortionOfOverallLengthOverhangingFront() + x, y, radius * 0.5, CIRCLE_TYPE.INNER, true);
        innerAxleCricle.setDrawingGroupName(config.CHASSIS_OBJECT_TYPES.VEHICLE);

        if (curElevation === config.ELEVATION.SIDE) {
            innerAxleCricle.setDrawingOrder(4000);
            outerAxleCircle.setDrawingOrder(4000);
        } else {
            innerAxleCricle.setDrawingOrder(10000);
            outerAxleCircle.setDrawingOrder(10000);
        }
        
        addedAxles.push(innerAxleCricle);
        if (pathForDrawingGroup) {
            outerAxleCircle.setDrawingGroupName(pathForDrawingGroup);
            innerAxleCricle.setDrawingGroupName(pathForDrawingGroup);
            outerAxleCircle.clickGroupName = pathForClick;
            innerAxleCricle.clickGroupName = pathForClick;
        } else {
            outerAxleCircle.setDrawingGroupName(pathForClick);
            innerAxleCricle.setDrawingGroupName(pathForClick);
        }
    }

}

function copyExistingAxleDetailsToAddedAxles(axleCircles) {
    var existingAxleOuter, existingAxleInner; 
    
    getExistingAxleCircles();

    axleCircles.forEach(function (axleCircle) {
        if (axleCircle.fillColour !== config.DRAWING_COLOURS.WHITE) {
            if (axleCircle.circleType === "1" || axleCircle.circleType === 1) {
                axleCircle.fill = existingAxleOuter.fill;
                axleCircle.fillColour = existingAxleOuter.fillColour;
            } else {
                axleCircle.fill = existingAxleInner.fill;
                axleCircle.fillColour = existingAxleInner.fillColour;
            }

        }
    });

    function getExistingAxleCircles() {

        for (var i = 0; i < axleCircles.length; i++) {
            if (axleCircles[i].blockName !== undefined && (axleCircles[i].circleType === "1" || axleCircles[i].circleType === 1)) {
                existingAxleOuter = axleCircles[i];
            } else if (axleCircles[i].blockName !== undefined && (axleCircles[i].circleType === "2" || axleCircles[i].circleType === 2)) {
                existingAxleInner = axleCircles[i];
            }
        }
    }
}

function assignAxlePositionsAndAddGroundLines(drawingObjects, chassisHeightFront, chassisHeightRear) { 
    numAxles = 0;
    var numOuterAxles = 0;
    for (var i = 0; i < drawingObjects.length; i++) {
        if (drawingObjects[i].type === "circle") {
            if (drawingObjects[i].circleType === "2" || drawingObjects[i].circleType === 2) {
                //inner so assign axle pos for later conversion to letter
                drawingObjects[i].axlePosition = numAxles++;
                //var axleHighlightColour = getPositionHighlightColour(drawingObjects[i].axlePosition);
                //if (axleHighlightColour !== null) {
                //    drawingObjects[i].setPulseColour(axleHighlightColour);
                //    drawingObjects[i].setPulse(true);
                //} else {
                //    drawingObjects[i].setPulseColour(undefined);
                //    drawingObjects[i].setPulse(undefined);

                //}
            } else if (drawingObjects[i].circleType === "1" || drawingObjects[i].circleType === 1) {

                //var axleHighlightColour = getPositionHighlightColour(numOuterAxles);
                //if (axleHighlightColour !== null) {
                //    drawingObjects[i].setPulseColour(axleHighlightColour);
                //    drawingObjects[i].setPulse(true);
                //} else {
                //    drawingObjects[i].setPulseColour(undefined);
                //    drawingObjects[i].setPulse(undefined);
                //}
                numOuterAxles++;


                //outer so needs ground line
                var groundLineY;
                if (drawingObjects[i].centre.y > drawingObjects[i].radius) {
                    //groundLine above 0
                    groundLineY = Math.abs(chassisHeightRear - chassisHeightFront);
                    //groundLineY = Math.abs(drawingObjects[i].radius - drawingObjects[i].centre.y);
                } else if (drawingObjects[i].centre.y < drawingObjects[i].radius) {
                    //groundLine below meaning tyre flatspot covered by being outside drawing area
                    groundLineY = 0;
                    //groundLineY = 0;
                } else {
                    groundLineY = drawingObjects[i].centre.y - drawingObjects[i].radius;
                    
                
                }
                drawingObjects[i].groundLineY = groundLineY;
            }
        }
    }
}

function assignDimensionOffsets(drawingObjects) {
    var GROUND_DIFFERENCE_LOCATION = {
        NONE: 'none',
        FRONT: 'front',
        REAR: 'rear'
    };
    var groundDifferenceLocation = GROUND_DIFFERENCE_LOCATION.NONE;
    var yGroundOffset = 0;
    var curAxle = 0;
    drawingObjects.forEach(function (obj) {
        if (obj.type === "circle") {
            if (obj.circleType === "1" || obj.circleType === 1) {//outer
                
                if (obj.groundLineY > 0) {
                    if (curAxle === 0) {
                        groundDifferenceLocation = GROUND_DIFFERENCE_LOCATION.FRONT;
                    } else if (curAxle === 1) {
                        if (curVehicle.getAxlesHolder().getAxle2Front() !== null) {
                            groundDifferenceLocation = GROUND_DIFFERENCE_LOCATION.FRONT;
                        } else {
                            groundDifferenceLocation = GROUND_DIFFERENCE_LOCATION.REAR;
                        }
                    } else {
                        groundDifferenceLocation = GROUND_DIFFERENCE_LOCATION.REAR;
                    }
                    yGroundOffset = obj.groundLineY;
                }
                curAxle++;
            }
        }
    });
    if(groundDifferenceLocation !== GROUND_DIFFERENCE_LOCATION.NONE) {
        dimensions.forEach(function (dimension) {
            if(groundDifferenceLocation === GROUND_DIFFERENCE_LOCATION.FRONT) {
                if (dimension.type === config.DIMENSION_TYPE.VERTICAL_LEFT) {
                    dimension.yDimensionOffset = yGroundOffset;
                } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
                    if (dimension.name === config.VALUE_TYPE.GROUNDCLEARANCEFRONT) {
                        dimension.yDimensionOffset = yGroundOffset;
                    }
                }
            } else if (groundDifferenceLocation === GROUND_DIFFERENCE_LOCATION.REAR) {
                if (dimension.type === config.DIMENSION_TYPE.VERTICAL_RIGHT) {
                    dimension.yDimensionOffset = yGroundOffset;
                } else if (dimension.type === config.DIMENSION_TYPE.VERTICAL_INNER) {
                    if (dimension.name === config.VALUE_TYPE.GROUNDCLEARANCEREAR) {
                        //if (yGroundOffset < 0) {
                        //    dimension.yDimensionOffset = -(yGroundOffset);
                        //} else {
                        //    dimension.yDimensionOffset = yGroundOffset;
                        //}
                        dimension.yDimensionOffset = yGroundOffset;
                    }
                }
            }
            
        });
    }
    
}

function addCabTiltArc() {
    /*
    If Cab Hinge Point X > 0 and Cab Hinge Point Y > 0
        Arc Centre Point = Cab Hinge Point X, Cab Hinge Point Y
    Else
        Arc Centre Point = 150, Chassis Height Front + 150
    Endif

    If Cab Tilt Radius > 0
        Cab Tilt Radius = Cab Tilt Radius
    Else
        Cab Tilt Radius = calculated to point where bumper to back of cab and cab height intercept
    Endif

    Arc Swept Radius = at least 15 degrees forward or to top of body measured at front otherwise no more than 35 degrees forward
    */

    /*
    specialDimension = New UtilDrawingDimensionObjectArc(_Vehicle.CalculatedCabTiltHingePoint,
                                                                _Vehicle.CalculatedCabTiltStartPoint,
                                                                _Vehicle.CalculatedCabTiltRadius,
                                                                _Vehicle.CalculatedCabTiltSweepAngle)
    */
    var arcCentrePoint = {}, arcRadius = 0, arcSweepAngle, arcStartPoint, arcStartAngle;
    //startAngle = myUtil.RadiansToDegrees(Math.Acos((_StartPoint.X - _Centrepoint.X) / _Radius))
    arcCentrePoint = curVehicle.getCalculatedCabTiltHingePoint();
    arcRadius = curVehicle.getCalculatedCabTiltRadius();
    arcSweepAngle = curVehicle.getCalculatedCabTiltSweepAngle();
    arcStartPoint = curVehicle.getCalculatedCabTiltStartPoint();
    arcStartAngle = 360 - utils.convertRadiansToDegrees(Math.acos((arcStartPoint.x - arcCentrePoint.x) / arcRadius)) - arcSweepAngle;
    //arcStartAngle = (arcStartAngle + 253.5);
    
    var tiltArc = new Arc(arcCentrePoint.x, arcCentrePoint.y, arcRadius, arcStartAngle, arcSweepAngle, true);
    tiltArc.setDashed(true);
    tiltArc.setDrawingGroupName('cabtilt');
    displayObjects.push(tiltArc);
}

function generateCOGElements() {

    var isTrialUser = false;
    //if (globals.user.isTrialUser() === true) {
    //    isTrialUser = true;
    //}

    if (curViewType === config.VIEW_TYPE.OVERVIEW) {
        //generateOverviewViewOverallCOGElement();
        
        if (curVehicle.getBody() !== null && curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            generateOverviewViewBodyCOGElement(curVehicle.getChassisObjectName(), true); 
        }
        generateTrailerCOGElements(curTrailer1);
        generateTrailerCOGElements(curTrailer2);
        //if (curTrailer1 !== null) {
        //    if (curTrailer1.getAccessoryHolder().getBody() !== null) {
        //        //generateOverviewViewTrailer1BodyCOGElement();
        //        generateOverviewViewTrailerBodyCOGElement(curTrailer1.getChassisObjectName());
        //        if (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE || (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && curTrailer1.getPayloadHolder().getDetailedPayloadItems().length > 0)) {
        //            generateOverviewViewTrailer1PayloadCOGElement();
        //        }
        //    } else if (curTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        //        //generateOverviewViewTrailer1StandardCOGElement();
        //        if (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE || (curTrailer1.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && curTrailer1.getPayloadHolder().getDetailedPayloadItems().length > 0)) {
        //            generateOverviewViewTrailer1PayloadCOGElement();
        //        }
        //    }
        //}
        if (curVehicle.getAccessoryHolder().getFifthWheel() === null) {
            if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                if (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE || (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && curVehicle.getPayloadHolder().getDetailedPayloadItems().length > 0)) {
                    generateOverviewViewCompleteVehiclePayloadCOGElement();
                }
            } else {
                if (curVehicle.getBody() !== null) {
                    if (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE || (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && curVehicle.getPayloadHolder().getDetailedPayloadItems().length > 0)) {
                        generateOverviewViewPayloadCOGElement(curVehicle.getChassisObjectName(), true);
                    }
                }
            }
        }
    } else if (curViewType === config.VIEW_TYPE.COG) {
        //??
    }

    function generateTrailerCOGElements(trailer) {
        if (trailer !== null) {
            if (trailer.getAccessoryHolder().getBody() !== null) {
                //generateOverviewViewTrailer1BodyCOGElement();
                generateOverviewViewBodyCOGElement(trailer.getChassisObjectName(), true);
                if (trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE || (trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && trailer.getPayloadHolder().getDetailedPayloadItems().length > 0)) {
                    generateOverviewViewPayloadCOGElement(trailer.getChassisObjectName(), true);
                }
            } else if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer))) {
                //generateOverviewViewTrailer1StandardCOGElement();
                if (trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE || (trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED && trailer.getPayloadHolder().getDetailedPayloadItems().length > 0)) {
                    generateOverviewViewPayloadCOGElement(trailer.getChassisObjectName(), true);
                }
            }
        }
    }

    function generateOverviewViewOverallCOGElement() {


        var horizontalCOGVal, xOffset, verticalAxisCOGVal, yOffset;

        //overall
        horizontalCOGVal = offerManager.getValue(config.VALUE_TYPE.OVERALLHORIZONTALCOG);
        xOffset = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + horizontalCOGVal;
        if (curElevation === config.ELEVATION.SIDE) {
            verticalAxisCOGVal = offerManager.getValue(config.VALUE_TYPE.OVERALLVERTICALCOG);
            yOffset = verticalAxisCOGVal + curVehicle.getFrontAddition();
        } else {
            verticalAxisCOGVal = offerManager.getValue(config.VALUE_TYPE.OVERALLLATERALCOG);
            yOffset = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + verticalAxisCOGVal;
        }
        
        

        var horizontalCOGValDisplayText, verticalCOGValDisplayText;

        if (isTrialUser) {
            //horizontalCOGValDisplayText = config.trailUserObfuscationText;
            verticalCOGValDisplayText = config.trailUserObfuscationText;
        } else {
            verticalCOGValDisplayText = offerManager.getUIFormattedDimensionValue(verticalAxisCOGVal);
        }
        horizontalCOGValDisplayText = offerManager.getUIFormattedDimensionValue(horizontalCOGVal);

        var displayText = config.getTranslationText('285') + ' (' + horizontalCOGValDisplayText + ', ' + verticalCOGValDisplayText + ')';
        
        var textWidth = getWidthOfText(displayText, curFontName, dimensionFontSize);
        var textHeight = getHeightOfText("M", curFontName, dimensionFontSize, textWidth);

        var oCOGElement = new COG(xOffset, yOffset, textWidth / scalingFactor, (textHeight / 2) / scalingFactor, displayText);
        //if (isTrialUser) {
        //    oCOGElement.addInteraction(offerManager.getCOGElementInteractionObject(config.VALUE_TYPE.OVERALLHORIZONTALCOG));
        //} else {
            
        //}
        oCOGElement.setDrawingGroupName(config.COG_ELEMENTS.OVERALL_COG);
        

        displayObjects.push(oCOGElement);
    }
    
    function generateOverviewViewBodyCOGElement(path, drawDimensions) {
        var tempBody = offerManager.getRigOps().getBodyFromPath(path); 
        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(path);
        var horizontalCOGVal, xOffset, verticalAxisCOGVal, yOffset;

        horizontalCOGVal = tempBody.getActualHorizontalCOG();
        //xOffset = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempBody.getHorizontalGap() + horizontalCOGVal;
        xOffset = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(path, config.ACCESSORY_TYPES.BODY) + horizontalCOGVal;

        if (curElevation === config.ELEVATION.SIDE) {
            verticalAxisCOGVal = tempBody.getActualVerticalCOG();
            var chassisHeight = offerManager.getRigOps().getChassisHeightFromPath(path);
            //if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
            //    yOffset = chassisHeight - tempBody.getBottomOfBodyToTopOfChassis() + verticalAxisCOGVal;
            //} else {
            //    yOffset = chassisHeight + tempBody.getAboveBelowChassis() + verticalAxisCOGVal;
            //}
            yOffset = offerManager.getRigOps().getItemStartFromGround(path, tempBody.getAccessoryType(), tempBody.getId()) + verticalAxisCOGVal;
            
        } else {
            verticalAxisCOGVal = tempBody.getActualLateralCOG();
            yOffset = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + verticalAxisCOGVal;
        }
        
        var trailUserText;
        if (isTrialUser) {
            trailUserText = config.trailUserObfuscationText;
        }
        var displayText = getBodyCOGLabelText(trailUserText, chassisObjectToUse.getChassisObjectName());

        var textWidth = getWidthOfText(displayText, curFontName, dimensionFontSize);
        var textHeight = getHeightOfText("M", curFontName, dimensionFontSize, textWidth);

        var bodyCOGElement = new COG(xOffset, yOffset, textWidth / scalingFactor, (textHeight / 2) / scalingFactor, displayText, config.COG_ELEMENT_STYLE.DRAW_RIGHT);
        
        //if (isTrialUser) {
        //    bodyCOGElement.addInteraction(offerManager.getCOGElementInteractionObject(config.VALUE_TYPE.BODYMASS));
        //} else {
            
        //}
        bodyCOGElement.setDrawingGroupName(chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.BODY + '.' + config.VALUE_TYPE.BODYMASS);
        

        displayObjects.push(bodyCOGElement);

        if (drawDimensions) {
            addInternalCOGToFromDimensions(bodyCOGElement, path, xOffset, yOffset, config.ACCESSORY_TYPES.BODY);
        }
    }

    function generateOverviewViewCompleteVehiclePayloadCOGElement() {

        var horizontalCOGVal, xOffset, verticalAxisCOGVal, yOffset;

        horizontalCOGVal = curVehicle.getPayloadHolder().getAveragePayloadHorizontalCOG();
        xOffset = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOfVehicleToPayloadDeckStart() + horizontalCOGVal;

        if (curElevation === config.ELEVATION.SIDE) {
            verticalAxisCOGVal = curVehicle.getPayloadHolder().getAveragePayloadVerticalCOG();
            yOffset = curVehicle.getBottomOfVehicleToPayloadDeckStart() + verticalAxisCOGVal;
        } else {
            verticalAxisCOGVal = curVehicle.getPayloadHolder().getAveragePayloadLateralCOG();
            yOffset = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(curVehicle) + curVehicle.getLeftOfVehicleToPayloadDeckStart() + verticalAxisCOGVal;
        }

        var trailUserText;
        if (isTrialUser) {
            trailUserText = config.trailUserObfuscationText;
        }
        var displayText = getPayloadCOGLabelText(trailUserText, config.CHASSIS_OBJECT_TYPES.VEHICLE);

        var textWidth = getWidthOfText(displayText, curFontName, dimensionFontSize);
        var textHeight = getHeightOfText("M", curFontName, dimensionFontSize, textWidth);

        var completeVehiclePayloadCOGElement = new COG(xOffset, yOffset, textWidth / scalingFactor, (textHeight / 2) / scalingFactor, displayText);
        //if (isTrialUser) {
        //    completeVehiclePayloadCOGElement.addInteraction(offerManager.getCOGElementInteractionObject(config.VALUE_TYPE.SINGLEPOINTPAYLOAD));
        //} else {
            
        //}
        if (curVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
            completeVehiclePayloadCOGElement.setDrawingGroupName(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + curVehicle.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS);
        } else {
            //completeVehiclePayloadCOGElement.setDrawingGroupName(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + curVehicle.getPayloadHolder().getDetailedPayloadItems()[0].getId() + '.' + config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD);
            completeVehiclePayloadCOGElement.setDrawingGroupName(config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.ACCESSORY_TYPES.PAYLOAD);
        }
        
        

        displayObjects.push(completeVehiclePayloadCOGElement);

        addInternalCOGToFromDimensions(completeVehiclePayloadCOGElement, curVehicle.getChassisObjectName(), xOffset, yOffset, config.ACCESSORY_TYPES.PAYLOAD);
    }

    function addInternalCOGToFromDimensions(cogElement, path, xOffset, yOffset, accessoryType) {
        cogElement.changeTypeToCOGGroup();
        ////var offset = 40;
        //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.COGFROMHEADBOARDINSIDE), config.DIMENSION_STYLE.STANDARD, yOffset));

        //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.COGFROMWHEELBASETHEORETICALATREAR), config.DIMENSION_STYLE.STANDARD, yOffset));
        var cogFromFrontValueType, cogFromRearAxlesValueType;
        
        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(path);
        var cOGFromFrontAttrPath;

        var offset = 20 / scalingFactor;
        if (accessoryType === config.ACCESSORY_TYPES.BODY) {
            offset = -offset;
            if (path === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                cogFromFrontValueType = config.VALUE_TYPE.BODYHORIZONTALCOG;
                cogFromRearAxlesValueType = config.VALUE_TYPE.BODYCOGTOREARAXLES;
                
            } else {
                //not showing body hcog from dimensions for trailer but this could change in future in which case the code for the correct value tyeps to setup would go here
                cogFromFrontValueType = config.VALUE_TYPE.BODYHORIZONTALCOG;
                cogFromRearAxlesValueType = config.VALUE_TYPE.BODYCOGTOREARAXLES;
            }
            cOGFromFrontAttrPath = cogElement.drawingGroupName.replace(config.VALUE_TYPE.BODYMASS, cogFromFrontValueType);
        } else if(accessoryType === config.ACCESSORY_TYPES.PAYLOAD) {
            if (path === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    cogFromFrontValueType = config.VALUE_TYPE.COGFROMBUMPERTOSTARTOFBODYINTERNAL;
                    cogFromRearAxlesValueType = config.VALUE_TYPE.COGFROMWHEELBASETHEORETICALATREAR;
                } else {
                    cogFromFrontValueType = config.VALUE_TYPE.COGFROMHEADBOARDINSIDE;
                    cogFromRearAxlesValueType = config.VALUE_TYPE.COGFROMWHEELBASETHEORETICALATREAR;
                }

            } else {
                cogFromFrontValueType = config.VALUE_TYPE.TRAILERPAYLOADHORIZONTALCOGFROMHEADBOARDINSIDE;
                cogFromRearAxlesValueType = config.VALUE_TYPE.TRAILERPAYLOADHORIZONTALCOGFROMWHEELBASETHEORETICALATREAR
            }
            cOGFromFrontAttrPath = cogElement.drawingGroupName.replace(config.VALUE_TYPE.SIMPLEPAYLOADMASS, config.VALUE_TYPE.SIMPLEPAYLOADHORIZONTALCOG);
        }
        

        var horizontalCOGFromFrontConfig = getDimensionConfig(cogFromFrontValueType, path);
        //bodyHorizontalCOGConfig.setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER)
        //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_INNER, 0, bodyHorizontalCOGConfig, config.DIMENSION_STYLE.STANDARD, yOffset - offset));

        //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.BODYCOGTOREARAXLES), config.DIMENSION_STYLE.STANDARD, yOffset - offset));




        var dimensionConfig = new DimensionConfig()
                                        .setId(cOGFromFrontAttrPath)
                                        .setLength(horizontalCOGFromFrontConfig.getLength())
                                        .setStartPoint(horizontalCOGFromFrontConfig.getStart() + horizontalCOGFromFrontConfig.getLength(), yOffset + offset)
                                        .setEndPoint(horizontalCOGFromFrontConfig.getStart(), yOffset + offset)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITH_DELIMITER);

        var cOGFromFrontDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP, undefined, dimensionConfig);
        
        if (offerManager.isAttributeGroupVisible(cOGFromFrontAttrPath)) {
            cOGFromFrontDim.setShowAsHyperLink(true);
            //cOGFromFrontDim.linkedDrawingGroupName = cogElement.drawingGroupName;
            //cOGFromFrontDim.setDrawingGroupName(cOGFromFrontAttrPath);
        } /*else {
            cOGFromFrontDim.setDrawingGroupName(cogElement.drawingGroupName);
        }*/
        cOGFromFrontDim.linkedDrawingGroupName = cogElement.drawingGroupName;
        cOGFromFrontDim.setDrawingGroupName(cOGFromFrontAttrPath);

        dimensions.push(cOGFromFrontDim);

        var horizontalCOGFromRearConfig = getDimensionConfig(cogFromRearAxlesValueType, path);
        if (path !== config.CHASSIS_OBJECT_TYPES.TRAILER1 || (path === config.CHASSIS_OBJECT_TYPES.TRAILER1 && chassisObjectToUse.getType() !== config.TRAILER_TYPES.INTERLINK)) {
            if (horizontalCOGFromRearConfig.getStart() < offerManager.getRigOps().getFrontOfChassis(path) + chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart() + chassisObjectToUse.getWheelbaseTheoretical()) {

                var startX = horizontalCOGFromRearConfig.getStart() + horizontalCOGFromRearConfig.getLength(), endX = horizontalCOGFromRearConfig.getStart(), lineEndStyle = config.LINE_END_STYLE.ARROW_START_ONLY_WITH_DELIMITER, length = horizontalCOGFromRearConfig.getLength();
                if (startX < endX) {
                    //var tempStartX = startX;
                    //startX = endX;
                    //endX = tempStartX;
                    startX = horizontalCOGFromRearConfig.getStart();
                    endX = horizontalCOGFromRearConfig.getStart() - horizontalCOGFromRearConfig.getLength();
                    //lineEndStyle = config.LINE_END_STYLE.ARROW_END_ONLY_WITH_DELIMITER;
                    length = Math.abs(horizontalCOGFromRearConfig.getLength());
                }

                dimensionConfig = new DimensionConfig()
                                                .setId(cogElement.drawingGroupName)
                                                .setLength(length)
                                                .setStartPoint(startX, yOffset + offset)
                                                .setEndPoint(endX, yOffset + offset)
                                                .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                                .setLineEndStyle(lineEndStyle);

                var cOGFromRearDim = new Dimension(config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP, undefined, dimensionConfig);
                //cOGFromRearDim.setDrawingGroupName(cogElement.drawingGroupName);
                cOGFromRearDim.linkedDrawingGroupName = cogElement.drawingGroupName;
                cOGFromRearDim.setDrawingGroupName(cOGFromFrontAttrPath);
                dimensions.push(cOGFromRearDim);
            }
        }

        var newLine = new Line(xOffset, yOffset, xOffset, yOffset + offset);
        newLine.setDrawingGroupName(cogElement.drawingGroupName);
        displayObjects.push(newLine); //draw the connnecting line from COG to dimensions

        var hitArea = new HitArea(cogElement.drawingGroupName, getCOGDimensionPoints(cogElement), getCOGDimensionMaxBounds(cogElement));
        hitArea.addHitMask(new HitArea(cogElement.drawingGroupName + '_' + config.INTERACTION_ID.COG_DIMENSION + '_MARKER', getCOGGroupDimensionMarkerPoints(cogElement), getCOGGroupDimensionMarkerMaxBounds(cogElement), config.INTERACTION_BEHAVIOUR.PINNABLE));
        hitArea.addHitMask(new HitArea(cogElement.drawingGroupName + '_' + config.INTERACTION_ID.COG_DIMENSION + '_LABEL', getCOGGroupDimensionLabelPoints(cogElement), getCOGGroupDimensionLabelMaxBounds(cogElement), config.INTERACTION_BEHAVIOUR.STANDARD));
        if (offerManager.isAttributeGroupVisible(cOGFromFrontAttrPath)) {
            hitArea.addHitMask(new HitArea(cOGFromFrontAttrPath, getCOGGroupDimensionPoints(cOGFromFrontDim), getCOGGroupDimensionMaxBounds(cOGFromFrontDim), config.INTERACTION_BEHAVIOUR.STANDARD));
        }
        /*
        dimensions.forEach(function (dimension) {

        if (!(dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_LEFT || dimension.type === config.DIMENSION_TYPE.COG_BOTTOM_RIGHT ||
            dimension.type === config.DIMENSION_TYPE.COG_TOP_LEFT || dimension.type === config.DIMENSION_TYPE.COG_TOP_RIGHT || dimension.type === config.DIMENSION_TYPE.HORIZONTAL_COG_GROUP) &&
            dimension.drawingGroupName !== undefined && dimension.drawingGroupName !== 'NOT_SET' && dimension.showAsHyperlink === true) {

            if (hitAreas[dimension.drawingGroupName] === undefined) {
                hitAreas[dimension.drawingGroupName] = new HitArea(dimension.drawingGroupName, getOverviewDimensionPoints(dimension), getOverviewDimensionMaxBounds(dimension));
                if (dimension.linkedDrawingGroupName === undefined) {
                    hitAreas[dimension.drawingGroupName].setDontFlipYAxis(true);
                } 
            }
        } else {
            var breakHere = 0;
        }
    });

    Object.keys(hitAreas).forEach(function (key) {
        hitAreas[key].setInteraction(offerManager.getDrawingGroupInteractionObject(config.INTERACTION_ID.DRAWING_GROUP, key));
        hitAreas[key].interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(hitAreas[key].interaction.callbacks);
        drawingObjects.push(hitAreas[key]);
    });
        */

        hitArea.setInteraction(offerManager.getDrawingGroupInteractionObject(config.INTERACTION_ID.DRAWING_GROUP, cogElement.drawingGroupName));


        hitArea.interaction.callbacks = addGraphicsManagerToInteractionCallbackChain(hitArea.interaction.callbacks);
        if (hitArea.hitMasks !== undefined) {
            hitArea.interaction.hitMasks = hitArea.hitMasks;
        }

        displayObjects.push(hitArea);
    }

    function generateOverviewViewPayloadCOGElement(path, drawDimensions) {

        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(path);

        var horizontalCOGVal, xOffset, verticalAxisCOGVal, yOffset;

        horizontalCOGVal = chassisObjectToUse.getPayloadHolder().getAveragePayloadHorizontalCOG();
        xOffset = offerManager.getRigOps().getPayloadDeckStartPositionFrontFrontInclPortionOfVehicleOverhangingFront(chassisObjectToUse) + horizontalCOGVal; //chassisObjectToUse.getFrontToPayloadDeckStart() + horizontalCOGVal;
        //if ((chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) && (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP)) {
        //    xOffset -= chassisObjectToUse.getDrawbarLength();
        //}
        if (curElevation === config.ELEVATION.SIDE) {
            verticalAxisCOGVal = chassisObjectToUse.getPayloadHolder().getAveragePayloadVerticalCOG();
            yOffset = offerManager.getRigOps().getPayloadDeckStartPositionFromBottom(chassisObjectToUse) + verticalAxisCOGVal;//chassisObjectToUse.getBottomToPayloadDeckStart() + verticalAxisCOGVal;
        } else {
            verticalAxisCOGVal = chassisObjectToUse.getPayloadHolder().getAveragePayloadLateralCOG();
            yOffset = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + chassisObjectToUse.getLeftToPayloadDeckStart() + verticalAxisCOGVal;
        }

        var trailUserText;
        if (isTrialUser) {
            trailUserText = config.trailUserObfuscationText;
        }
        var displayText = getPayloadCOGLabelText(trailUserText, chassisObjectToUse.getChassisObjectName());

        var textWidth = getWidthOfText(displayText, curFontName, dimensionFontSize);
        var textHeight = getHeightOfText("M", curFontName, dimensionFontSize, textWidth);

        var payloadCOGElement = new COG(xOffset, yOffset, textWidth / scalingFactor, (textHeight / 2) / scalingFactor, displayText);
        //if (isTrialUser) {
        //    payloadCOGElement.addInteraction(offerManager.getCOGElementInteractionObject(config.VALUE_TYPE.SINGLEPOINTPAYLOAD));
        //} else {

        //}
        if (chassisObjectToUse.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
            payloadCOGElement.setDrawingGroupName(chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + chassisObjectToUse.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS);
        } else {
            //payloadCOGElement.setDrawingGroupName(chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + chassisObjectToUse.getPayloadHolder().getDetailedPayloadItems()[0].getId() + '.' + config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD);
            payloadCOGElement.setDrawingGroupName(chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
        }



        displayObjects.push(payloadCOGElement);

        if (drawDimensions) {
            addInternalCOGToFromDimensions(payloadCOGElement, path, xOffset, yOffset, config.ACCESSORY_TYPES.PAYLOAD);
        }

        //var offset = 40/scalingFactor;
        //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.COGFROMHEADBOARDINSIDE), config.DIMENSION_STYLE.STANDARD, yOffset + offset));

        //dimensions.push(new Dimension(config.DIMENSION_TYPE.HORIZONTAL_INNER, 0, getDimensionConfig(config.VALUE_TYPE.COGFROMWHEELBASETHEORETICALATREAR), config.DIMENSION_STYLE.STANDARD, yOffset + offset));
    }

    function generateOverviewViewTrailerPayloadCOGElement(path) {

        var chassisObjectToUse = offerManager.getRigOps().getChassisObjectFromPath(path);

        var horizontalCOGVal, xOffset, verticalAxisCOGVal, yOffset;

        horizontalCOGVal = chassisObjectToUse.getPayloadHolder().getAveragePayloadHorizontalCOG();
        xOffset = offerManager.getRigOps().getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(path) + chassisObjectToUse.getFrontOfTrailerToPayloadDeckStart() + horizontalCOGVal;
        if (curElevation === config.ELEVATION.SIDE) {
            verticalAxisCOGVal = chassisObjectToUse.getPayloadHolder().getAveragePayloadVerticalCOG();
            yOffset = chassisObjectToUse.getBottomOfTrailerToPayloadDeckStart() + verticalAxisCOGVal;
        } else {
            verticalAxisCOGVal = chassisObjectToUse.getPayloadHolder().getAveragePayloadLateralCOG();
            yOffset = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObjectToUse) + chassisObjectToUse.getLeftOfTrailerToPayloadDeckStart() + verticalAxisCOGVal;
        }

        var trailUserText;
        if (isTrialUser) {
            trailUserText = config.trailUserObfuscationText;
        }
        var displayText = getPayloadCOGLabelText(trailUserText, chassisObjectToUse.getChassisObjectName());

        var textWidth = getWidthOfText(displayText, curFontName, dimensionFontSize);
        var textHeight = getHeightOfText("M", curFontName, dimensionFontSize, textWidth);

        var payloadCOGElement = new COG(xOffset, yOffset, textWidth / scalingFactor, (textHeight / 2) / scalingFactor, displayText);
        //if (isTrialUser) {
        //    payloadCOGElement.addInteraction(offerManager.getCOGElementInteractionObject(config.VALUE_TYPE.SINGLEPOINTPAYLOAD));
        //} else {

        //}
        if (chassisObjectToUse.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE) {
            payloadCOGElement.setDrawingGroupName(chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + chassisObjectToUse.getPayloadHolder().getSimplePayloadObject().getId() + '.' + config.VALUE_TYPE.SIMPLEPAYLOADMASS);
        } else {
            //payloadCOGElement.setDrawingGroupName(chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + chassisObjectToUse.getPayloadHolder().getDetailedPayloadItems()[0].getId() + '.' + config.VALUE_TYPE.DETAILEDPAYLOADPAYLOAD);
            payloadCOGElement.setDrawingGroupName(chassisObjectToUse.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
        }



        displayObjects.push(payloadCOGElement);
    }

    

}

function markOverloadedCols(tableMeta) {
    Object.keys(tableMeta.cols).forEach(function (key) {
        if (tableMeta.cols[key].cellData.Gross.colour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
            if (colLetters[key]) {
                colLetters[key].colour = config.TABLE_HIGHLIGHT_COLOURS.FAIL;
            }
        } else if (tableMeta.cols[key].cellData.Gross.colour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
            if (colLetters[key]) {
                colLetters[key].colour = config.TABLE_HIGHLIGHT_COLOURS.WARNING;
            }
        }
    });
}

function markOverloadedGroups(tableMeta) {
    Object.keys(tableMeta.cols.BridgeOverload.cellData).forEach(function (key) {
        groupLetters[key] = {};
        groupLetters[key].colour = tableMeta.cols.BridgeOverload.cellData[key].colour;
    });
    Object.keys(tableMeta.cols.Labels.cellData).forEach(function (key) {
        groupLetters[key].letters = getLetters(tableMeta.cols.Labels.cellData[key].value);
    });

    function getLetters(groupLabel) {
        var labelParts = groupLabel.split('-');
        var firstLetter = labelParts[0].replace(/ /g, '');
        var lastLetter = labelParts[1].replace(/ /g, '');

        var firstLetterCode = firstLetter.charCodeAt();
        var lastLetterCode = lastLetter.charCodeAt();
        
        var letters = [];
        letters.push(Number(firstLetter));

        if (firstLetterCode + 1 !== lastLetterCode) {
            var nextLetterCode = firstLetterCode + 1;
            while (nextLetterCode < lastLetterCode) {
                letters.push(Number(String.fromCharCode(nextLetterCode)));
                //letters.push(nextLetterCode);
                nextLetterCode++;
            }
        }

        letters.push(Number(lastLetter));

        return letters;
    }
}

function getPositionHighlightColour(axleNum) {

    var positionHighlightColour = null;
    if (curViewDetail !== config.VIEW_DETAIL.BRIDGE_VIEW) {
        Object.keys(colLetters).forEach(function (key) {
            if (contains(colLetters[key].letters, axleNum)) {
                if (colLetters[key].colour) {
                    positionHighlightColour = colLetters[key].colour
                }
            }
        });
    } else {

        

        Object.keys(groupLetters).forEach(function (key) {
            if (contains(groupLetters[key].letters, axleNum)) {
                if (groupLetters[key].colour) {
                    groupLetters[key].letters.forEach(function (innerKey) {
                        if (letterColourAllocations[innerKey] === undefined) {
                            letterColourAllocations[innerKey] = groupLetters[key].colour;
                        } else {
                            if (groupLetters[key].colour === config.TABLE_HIGHLIGHT_COLOURS.FAIL) {
                                letterColourAllocations[innerKey] = groupLetters[key].colour;
                            } else if (letterColourAllocations[innerKey] === config.TABLE_HIGHLIGHT_COLOURS.OK && groupLetters[key].colour === config.TABLE_HIGHLIGHT_COLOURS.WARNING) {
                                letterColourAllocations[innerKey] = groupLetters[key].colour;
                            }
                        }
                    });

                    
                    //positionHighlightColour = letterColourAllocations[String.fromCharCode(axleNum + 65)];
                    positionHighlightColour = letterColourAllocations[axleNum + 1];
                }
            }
        });
    }
    

    return positionHighlightColour;

    function contains(lettersArr, axleNum) {
        if (lettersArr.length > 1) {
            var startLetter = lettersArr[0];
            var endLetter = lettersArr[lettersArr.length - 1];

            if (typeof lettersArr[0] === 'string') {
                return axleNum + 65 >= startLetter.charCodeAt() && axleNum + 65 <= endLetter.charCodeAt();
            }
            return axleNum + 1 >= startLetter && axleNum + 1 <= endLetter;
        } else {
            if (typeof lettersArr[0] === 'string') {
                return lettersArr[0].charCodeAt() === 65 + axleNum;
            }
            return lettersArr[0] === axleNum + 1;
        }
    }
}

function generateAxleLetters(drawingObjects) {
    var missingValuesObject;
    if (curViewType === config.VIEW_TYPE.OVERVIEW || curViewType === config.VIEW_TYPE.COG) {
        missingValuesObject = offerManager.getMissingValuesLookupObject();
    } else {
        missingValuesObject = offerManager.getTurningCircleMissingValuesLookupObject();
    }
    var letters = [];
    drawingObjects.forEach(function (obj) {
        if (obj.type === "circle") {
            if (obj.drawingGroupName.indexOf(config.OBJECT_TYPES.ACCESSORY) === -1 && obj.drawingGroupName.indexOf(config.ACCESSORY_TYPES.BODY) === -1 && (obj.circleType === "2" || obj.circleType === 2)) {//inner 
                //var newAxleLetter = new AxleLetter(obj.centre.x, obj.centre.y, String.fromCharCode(65 + obj.axlePosition), obj.axlePosition);
                var newAxleLetter = new AxleLetter(obj.centre.x, obj.centre.y, obj.axlePosition + 1, obj.axlePosition);

                //if (obj.drawingGroupName.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                    newAxleLetter.setDrawingGroupName(obj.drawingGroupName);
                //}

                letters.push(newAxleLetter);
            } 
        }
    });
    letters.sort(function (a, b) {
        if (a.centre.x < b.centre.x) {
            return -1;
        } else if (a.centre.x > b.centre.x) {
            return 1;
        }
        return 0;
    });
    var axlePos = 0
    var vehAxleNum = 1, trailerAxleNum = 1;
    letters.forEach(function (axleLetter) {
        axleLetter.axlePosition = axlePos;
        //axleLetter.letter = String.fromCharCode(65 + axleLetter.axlePosition);
        axleLetter.letter = axleLetter.axlePosition + 1;
        if (axleLetter.drawingGroupName.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            if ((missingValuesObject[config.VALUE_TYPE.TAREFRONT] !== undefined && curVehicle.getAxlesHolder().getAxlePosition(curVehicle.getAxlesHolder().getAxleByNumber(axlePos + 1)).indexOf('FRONT') !== -1) || (missingValuesObject[config.VALUE_TYPE.TAREREAR] !== undefined && curVehicle.getAxlesHolder().getAxlePosition(curVehicle.getAxlesHolder().getAxleByNumber(axlePos + 1)).indexOf('REAR') !== -1)) {
                axleLetter.setColour(config.DRAWING_COLOURS.DARK_GREY);
            } else {
                allocateAxleLetterHighlightColour(axleLetter.axlePosition, axleLetter);
            }
        } else {
            allocateAxleLetterHighlightColour(axleLetter.axlePosition, axleLetter);
        }
        
        
        axlePos++;
        //if(axleLetter.drawingGroupName.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
        //    if (curVehicle.getAxlesHolder().getAxleOverloaded(vehAxleNum)) {
        //        axleLetter.setShowAsWarning(true);
        //    }
        //    vehAxleNum++;
        //} else if (axleLetter.drawingGroupName.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
        //    if (curTrailer1.getAxlesHolder().getAxleOverloaded(trailerAxleNum)) {
        //        axleLetter.setShowAsWarning(true);
        //    }
        //    trailerAxleNum++;
        //}
        
    });
    if (letters.length === 0) {
        var axlePosNum = 0;
        curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

            var axlePosition = curVehicle.getAxlesHolder().getAxlePosition(axle);

            //var liftingOffset = 0;
            //if (axle.getRaised()) {
            //    liftingOffset = axle.getLiftingMaxTravel();
            //}
            

            switch (axlePosition) {
                case config.AXLE_POSITIONS.AXLE_1_FRONT:
                    //letters.push(new AxleLetter(curVehicle.getFrontOverhang(), 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(curVehicle.getFrontOverhang(), 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_2_FRONT:
                    //letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front(), 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front(), 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                    //letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear(), 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear(), 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                    //letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear(), 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear(), 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                    //letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear(), 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear(), 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    //letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear(), 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear(), 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
            }

        });
        
        if (curTrailer1 !== null) {

            generateAxleLettersForTrailer(curTrailer1);

            if (curTrailer2 !== null) {

                generateAxleLettersForTrailer(curTrailer2);

            }
        }

        
        //if (curTrailer1 !== null) {

        //    var startOrTrailerChassis = offerManager.getRigOps().getTrailerFrontOverhangStart(curTrailer1.getChassisObjectName());
        //    var axleOffset = 0;
        //    curTrailer1.getAxlesHolder().getAxles().forEach(function (axle) {

        //        var axlePosition = curTrailer1.getAxlesHolder().getAxlePosition(axle);

        //        //var liftingOffset = 0;
        //        //if (axle.getRaised()) {
        //        //    liftingOffset = axle.getLiftingMaxTravel();
        //        //}
        //        axleOffset = startOrTrailerChassis;

        //        switch (axlePosition) {
        //            case config.AXLE_POSITIONS.AXLE_1_FRONT:
        //                axleOffset += curTrailer1.getFrontOverhang();
        //                letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
        //                axlePosNum++;
        //                break;
        //            case config.AXLE_POSITIONS.AXLE_2_FRONT:
        //                axleOffset += curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front();
        //                letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
        //                axlePosNum++;
        //                break;
        //            case config.AXLE_POSITIONS.AXLE_3_FRONT:
        //                axleOffset += curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front();
        //                letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
        //                axlePosNum++;
        //                break;
        //            case config.AXLE_POSITIONS.AXLE_1_REAR:
        //                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //                    axleOffset += curTrailer1.getHitchPointToAxle1Rear() - curTrailer1.getDrawbarLength();
        //                } else {
        //                    axleOffset += curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear();
        //                }
                        
        //                letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
        //                axlePosNum++;
        //                break;
        //            case config.AXLE_POSITIONS.AXLE_2_REAR:
        //                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //                    axleOffset += curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() - curTrailer1.getDrawbarLength();
        //                } else {
        //                    axleOffset += curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear();
        //                }
                        
        //                letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
        //                axlePosNum++;
        //                break;
        //            case config.AXLE_POSITIONS.AXLE_3_REAR:
        //                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //                    axleOffset += curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() - curTrailer1.getDrawbarLength();
        //                } else {
        //                    axleOffset += curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear();
        //                }
                        
        //                letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
        //                axlePosNum++;
        //                break;
        //            case config.AXLE_POSITIONS.AXLE_4_REAR:
        //                if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //                    axleOffset += curTrailer1.getHitchPointToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() + curTrailer1.getAxle3RearToAxle4Rear() - curTrailer1.getDrawbarLength();
        //                } else {
        //                    axleOffset += curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToAxle2Front() + curTrailer1.getAxle2FrontToAxle3Front() + curTrailer1.getRearMostFrontAxleToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() + curTrailer1.getAxle3RearToAxle4Rear();
        //                }
                        
        //                letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
        //                axlePosNum++;
        //                break;
        //        }

        //    });
        //}

        letters.forEach(function (axleLetter) {
            //allocateAxleLetterHighlightColour(axleLetter.axlePosition, axleLetter);
            if (axleLetter.drawingGroupName.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                if ((missingValuesObject[config.VALUE_TYPE.TAREFRONT] !== undefined && curVehicle.getAxlesHolder().getAxlePosition(curVehicle.getAxlesHolder().getAxleByNumber(axlePos + 1)).indexOf('FRONT') !== -1) || (missingValuesObject[config.VALUE_TYPE.TAREREAR] !== undefined && curVehicle.getAxlesHolder().getAxlePosition(curVehicle.getAxlesHolder().getAxleByNumber(axlePos + 1)).indexOf('REAR') !== -1)) {
                    axleLetter.setColour(config.DRAWING_COLOURS.DARK_GREY);
                } else {
                    allocateAxleLetterHighlightColour(axleLetter.axlePosition, axleLetter);
                }
            } else {
                allocateAxleLetterHighlightColour(axleLetter.axlePosition, axleLetter);
            }
            
        });
    }
    return letters;

    function allocateAxleLetterHighlightColour(axlePos, axleLetter) {
        var axleLetterHighlightColour = getPositionHighlightColour(axlePos);
        if (axleLetterHighlightColour !== null) {
            axleLetter.setColour(axleLetterHighlightColour);
        }
    }

    function generateAxleLettersForTrailer(trailer) {
        var startOrTrailerChassis = offerManager.getRigOps().getTrailerFrontOverhangStart(trailer.getChassisObjectName());
        var axleOffset = 0;
        trailer.getAxlesHolder().getAxles().forEach(function (axle) {

            var axlePosition = trailer.getAxlesHolder().getAxlePosition(axle);

            //var liftingOffset = 0;
            //if (axle.getRaised()) {
            //    liftingOffset = axle.getLiftingMaxTravel();
            //}
            axleOffset = startOrTrailerChassis;

            switch (axlePosition) {
                case config.AXLE_POSITIONS.AXLE_1_FRONT:
                    axleOffset += trailer.getFrontOverhang();
                    //letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(axleOffset, 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_2_FRONT:
                    axleOffset += trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front();
                    //letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(axleOffset, 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_3_FRONT:
                    axleOffset += trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front();
                    //letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(axleOffset, 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                    if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                        axleOffset += trailer.getHitchPointToAxle1Rear() - trailer.getDrawbarLength();
                    } else {
                        axleOffset += trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear();
                    }

                    //letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(axleOffset, 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                    if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                        axleOffset += trailer.getHitchPointToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() - trailer.getDrawbarLength();
                    } else {
                        axleOffset += trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear() + trailer.getAxle1RearToAxle2Rear();
                    }

                    //letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(axleOffset, 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                    if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                        axleOffset += trailer.getHitchPointToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear() - trailer.getDrawbarLength();
                    } else {
                        axleOffset += trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear();
                    }

                    //letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(axleOffset, 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                        axleOffset += trailer.getHitchPointToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear() + trailer.getAxle3RearToAxle4Rear() - trailer.getDrawbarLength();
                    } else {
                        axleOffset += trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear() + trailer.getAxle3RearToAxle4Rear();
                    }

                    //letters.push(new AxleLetter(axleOffset, 0, String.fromCharCode(65 + axlePosNum), axlePosNum));
                    letters.push(new AxleLetter(axleOffset, 0, axlePosNum + 1, axlePosNum));
                    axlePosNum++;
                    break;
            }

        });
    }
}

function generateHitchOffset(displayObjects) {
    var tempHitch = curVehicle.getAccessoryHolder().getHitch();
    var offsetX = 0, offsetY = 0;
    offsetX = dimensionXOffset + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset();
    offsetY = tempHitch.getHeightFromGround();
    //var hitchLoadObj = new HitchLoad(offsetX, offsetY, config.getTranslationText('1043') + ' (' + massFormattingFuncNoDecimalPlaces(offerManager.getRigOps().getHitchLoad()) + ')');
    var hitchLoadObj = new HitchLoad(offsetX, offsetY, config.getTranslationText('1043') + ' (' + massFormattingFuncNoDecimalPlaces(offerManager.getRigOps().getHitchLoad()) + ', ' + offerManager.getUIFormattedPercentageValue(offerManager.getRigOps().getHitchLoadAsPercentageOfTrailerGross(), 1) + ')');
    hitchLoadObj.setDrawingGroupName(config.VALUE_TYPE.HITCHLOAD);
    displayObjects.push(hitchLoadObj);
}



function rotate(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
}

function generateTurningCircleDisplayObjectsAndDimensions(tcVals) {

    var isTrialUser = false; 
    //if (globals.user.isTrialUser() === true) {
    //    isTrialUser = true;
    //}


    /*
    Private Const _CanvasFontSize As Integer = 10
Private Const _PdfFontSize As Double = 8
Private Const _FifthWheelRadius As Integer = 200
Private Const _TurnTableRadius As Integer = 200
Private Const _HitchRadius As Integer = 100
Private Const _LengthOfDashedLineInPixels As Integer = 80
Private Const _LengthOfAngleLineInPixels As Integer = 60
Private Const _CanvasDimensionOffsetInPixels As Integer = 20
Private Const _PDFDimensionOffsetInPixels As Integer = 15
    */
    var lengthOfDashedLineInPixels = 100,
        lengthOfAngleLineInPixels = 80,
        tcDimensionOffset = 20,
        turntableRadius = 200;


        
    /*
    toCentreOfRearAxle,
        turningRadiusInner,
        turningRadiusWallToWall,
        turningRadiusCurbToCurb,
        steeringAngleOuterInDegrees,
        steeringAngleInnerInDegrees,
        steeringAngleInnerMaxInDegrees,
        secondSteeringAngleOuterInDegrees,
        secondSteeringAngleInnerInDegrees,
        wheelbaseTurningMax,
        toCentreOfVehicleRearAxle, 
        toCentreOfTrailer1FrontAxle, 
        toCentreOfTrailer1RearAxle, 
        toCentreOfHitch, 
        toCentreOfFifthWheel, 
        vehicleTurningRadiusInner, 
        trailer1TurningRadiusInner;
    */
    /*
    ByVal turningRadiusWallToWall As Double, _
            ByVal turningRadiusCurbToCurb As Double, _
            ByVal vehicleTurningRadiusInner As Double, _
            ByVal trailer1TurningRadiusInner As Double, _
            ByVal toCentreOfVehicleRearAxle As Double, _
            ByVal toCentreOfTrailer1FrontAxle As Double, _
            ByVal toCentreOfTrailer1RearAxle As Double, _
            ByVal toCentreOfHitch As Double, _
            ByVal toCentreOfFifthWheel As Double, _
            ByVal steeringAngleInner As Double, _
            ByVal steeringAngleOuter As Double, _
            ByVal secondSteeringAngleInner As Double, _
            ByVal secondSteeringAngleOuter As Double, _
            ByVal turningRadiusOuterMax As Double, _
            ByVal turningRadiusInnerMin As Double, _
            ByVal turningRadiusCurbToCurbMax As Double, _
            
            ByVal swingClearanceWarning As Boolean, _
            ByVal vehicle As FileLoadingVehicle, _
            ByVal trailer1 As FileLoadingTrailer, _
            ByVal scale As UtilDrawingScaling, _
            ByVal view As FileLoading.ViewType
    */
    tcVals = offerManager.getRigOps().getTurningCircleValues(curViewDetail);////;//offerManager.getRigOps().getTurningCircleValues(curViewDetail);
    var turningRadiusWallToWall = tcVals.turningRadiusWallToWall,//curVehicle.getCabWidth(),
        turningRadiusCurbToCurb = tcVals.turningRadiusCurbToCurb,
        vehicleTurningRadiusInner = tcVals.vehicleTurningRadiusInner,
        trailer1TurningRadiusInner = tcVals.trailer1TurningRadiusInner,
        trailer2TurningRadiusInner = tcVals.trailer2TurningRadiusInner,
        toCentreOfVehicleRearAxle = tcVals.toCentreOfVehicleRearAxle,
        toCentreOfTrailer1FrontAxle = tcVals.toCentreOfTrailer1FrontAxle,
        toCentreOfTrailer1RearAxle = tcVals.toCentreOfTrailer1RearAxle,
        toCentreOfTrailer2RearAxle = tcVals.toCentreOfTrailer2RearAxle,
        toCentreOfHitch = tcVals.toCentreOfHitch,
        toCentreOfFifthWheel = tcVals.toCentreOfFifthWheel,
        toCentreOfTrailer1FifthWheel = tcVals.toCentreOfTrailer1FifthWheel,
        steeringAngleInner = tcVals.steeringAngleInnerInDegrees,
        steeringAngleOuter = tcVals.steeringAngleOuterInDegrees,
        secondSteeringAngleInner = tcVals.secondSteeringAngleInnerInDegrees,
        secondSteeringAngleOuter = tcVals.secondSteeringAngleOuterInDegrees,
        steeringAngleAxle1RearOuter = tcVals.steeringAngleAxle1RearOuter,
        steeringAngleAxle1RearInner = tcVals.steeringAngleAxle1RearInner,
        steeringAngleAxle2RearOuter = tcVals.steeringAngleAxle2RearOuter,
        steeringAngleAxle2RearInner = tcVals.steeringAngleAxle2RearInner,
        steeringAngleAxle3RearOuter = tcVals.steeringAngleAxle3RearOuter,
        steeringAngleAxle3RearInner = tcVals.steeringAngleAxle3RearInner,
        steeringAngleAxle4RearOuter = tcVals.steeringAngleAxle4RearOuter,
        steeringAngleAxle4RearInner = tcVals.steeringAngleAxle4RearInner,
        turningRadiusInnerMin = tcVals.turningRadiusInnerMin,
        turningRadiusCurbToCurbMax = tcVals.turningRadiusCurbToCurbMax,
        turningRadiusOuterMax = tcVals.turningRadiusOuterMax;


    var swingClearanceWarning = offerManager.getRigOps().isSwingClearanceWarning();
    var vehicle = curVehicle;
    var trailer1 = curTrailer1;
    var trailer2 = curTrailer2;


    /*
    Dim highlightRadius As Boolean = False
Dim newArc As UtilDrawingObjectArc
Dim newCircle As UtilDrawingObjectCircle
Dim newLine As UtilDrawingObjectLine
Dim newDimension As UtilDrawingObjectDimension
Dim newText As UtilDrawingObjectString
Dim centreOfRadius, cabFrontRightCornerOnWallToWall, turningRadiusInnerMeetsOverallWidth As Point
Dim bodyBackLeftCorner, vehicleBackLeftCorner, bodyBackRightCorner, vehicleBackRightCorner, bodyFrontRightCorner, vehicleFrontRightCorner, bodyFrontLeftCorner, vehicleFrontLeftCorner As Point
Dim vehicleRearBackLeft, vehicleRearBackRight, vehicleRearFrontRight, vehicleRearFrontLeft As Point
Dim cabFrontLeft, cabBackLeft, cabBackRight, curbToCurbMax, wallToWallMax, tyreFrontRightCurbToCurb, innerMin, startDimension, endDimension As Point
Dim angleBetweenZeroAndLegalAnchorPoint, angleBetweenWallToWallAnchorPointAndCentreOfRearAxle, angleBetweenZeroAndCentreOfRearAxle As Double
Dim angleBetweenCentreOfRearAxleAndEndOfVehicle, angleBetweenCentreOfRearAxleAndEndOfBody, toBodyBackLeftCorner As Double
Dim bodyPresent, isSelected As Boolean
*/
    var centreOfCircleX = (mW / 2)/scalingFactor;
    //var centreOfCircleY = -((oH - (mH + bottomBufferHeight)) / scalingFactor);//(Math.max(turningRadiusWallToWall, turningRadiusOuterMax));//Return Math.Max(_TurningRadiusWallToWall, _TurningRadiusOuterMax)
    var centreOfCircleY = 0;//(mH + bottomBufferHeight) / scalingFactor;
    var highlightRadius = false;
    var newArc, newCircle, newLine, newText;
    var centreOfRadiusX, centreOfRadiusY, cabFrontRightCornerWallToWallX, cabFrontRightCornerWallToWallY, turningRadiusInnerMeetsOverallWidthX, turningRadiusInnerMeetsOverallWidthY;
    var bodyBackLeftCornerX, bodyBackLeftCornerY, vehicleBackLeftCornerX, vehicleBackLeftCornerY, bodyBackRightCornerX, bodyBackRightCornerY, vehicleBackRightCornerX, vehicleBackRightCornerY,
        bodyFrontRightCornerX, bodyFrontRightCornerY, vehicleFrontRightCornerX, vehicleFrontRightCornerY, bodyFrontLeftCornerX, bodyFrontLeftCornerY, vehicleFrontLeftCornerX, vehicleFrontLeftCornerY;
    var vehicleRearBackLeftX, vehicleRearBackLeftY, vehicleRearBackRightX, vehicleRearBackRightY, vehicleRearFrontRightX, vehicleRearFrontRightY, vehicleRearFrontLeftX, vehicleRearFrontLeftY;
    var cabFrontLeftX, cabFrontLeftY, cabBackLeftX, cabBackLeftY, cabBackRightX, cabBackRightY, curbToCurbMaxX, curbToCurbMaxY, wallToWallMaxX, wallToWallMaxY, tyreFrontRightCurbToCurbX, tyreFrontRightCurbToCurbY,
        innerMinX, innerMinY, startDimensionX, startDimensionY, endDimensionX, endDimensionY;
    var angleBetweenZeroAndLegalAnchorPoint, angleBetweenWallToWallAnchorPointAndCentreOfRearAxle, angleBetweenZeroAndCentreOfRearAxle;
    var angleBetweenCentreOfRearAxleAndEndOfVehicle, angleBetweenCentreOfRearAxleAndEndOfBody, toBodyBackLeftCorner;
    var bodyPresent, isSelected;
    var dimensionConfig, newDimension, rearDimensionOffset;
    var chassisCoords = {};
    preventDrawingStandardGraphicsInTurningCircleView = false;
    if ((steeringAngleInner === 0 && turningRadiusCurbToCurb === 0 && turningRadiusWallToWall === 0) || offerManager.getNumLinkableTurningCircleMissingValues() > 0) {
        newArc = new Arc(centreOfCircleX, centreOfCircleY, 400/scalingFactor, 180, 180).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newArc);

        newArc = new Arc(centreOfCircleX, centreOfCircleY, (oH - topBufferHeight - bottomBufferHeight - tableLayerHeight - belowTableBufferLayerHeight - axleLetterTableConnectorLayerHeight)/scalingFactor, 180, 180).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newArc);
        //if (turningRadiusCurbToCurbMax > 0) {
        //    newArc = new Arc(centreOfCircleX, centreOfCircleY, oH - topBufferHeight - bottomBufferHeight, 180, 180).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //    drawingObjectsArray.push(newArc);
        //} else if (turningRadiusOuterMax > 0) {
        //    newArc = new Arc(centreOfCircleX, centreOfCircleY, oH - topBufferHeight - bottomBufferHeight, 180, 180).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //    drawingObjectsArray.push(newArc);
        //}
        
        preventDrawingStandardGraphicsInTurningCircleView = true;
    } else {

        /*
    _PixelStartX = scale.VerticalGraphStart
    _PixelEndX = scale.VerticalGraphEnd
    _PixelStartY = scale.HorizontalGraphEnd
    _PixelEndY = scale.HorizontalGraphStart

    _TurningRadiusWallToWall = turningRadiusWallToWall
    _TurningRadiusCurbToCurb = turningRadiusCurbToCurb
    _TurningRadiusInnerMin = turningRadiusInnerMin
    _TurningRadiusOuterMax = turningRadiusOuterMax
    _TurningRadiusCurbToCurbMax = turningRadiusCurbToCurbMax
    */

        angleBetweenZeroAndLegalAnchorPoint = 15;
        angleBetweenWallToWallAnchorPointAndCentreOfRearAxle = Math.atan((vehicle.getWheelbaseTurning() + vehicle.getFrontOverhang()) / (toCentreOfVehicleRearAxle + vehicle.getCabWidth() / 2)) * 180 / Math.PI;
        angleBetweenZeroAndCentreOfRearAxle = angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle;
        centreOfRadiusX = centreOfCircleX;
        centreOfRadiusY = centreOfCircleY;

        //angleBetweenZeroAndLegalAnchorPoint = 90;
        //angleBetweenWallToWallAnchorPointAndCentreOfRearAxle = 0;
        //angleBetweenZeroAndCentreOfRearAxle = angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle;

        /*
    angleBetweenZeroAndLegalAnchorPoint = 15
    angleBetweenWallToWallAnchorPointAndCentreOfRearAxle = Math.Atan((vehicle.WheelbaseTurning + vehicle.FrontOverhang) / (toCentreOfVehicleRearAxle + vehicle.CabWidth / 2)) * 180 / Math.PI
    angleBetweenZeroAndCentreOfRearAxle = angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle
    centreOfRadius.X = CentreOfCircle.X
    centreOfRadius.Y = CentreOfCircle.Y
    */


        //if (Math.round(vehicleTurningRadiusInner, 0) < turningRadiusInnerMin) {
        //    isSelected = true;
        //} else {
        //    isSelected = false;
        //}
        /*
    'turning radius inner
    If Math.Round(vehicleTurningRadiusInner, 0) < _TurningRadiusInnerMin Then
        isSelected = True
    Else
        isSelected = False
    End If

    */

            
        turningRadiusInnerMeetsOverallWidthX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicleTurningRadiusInner;
        turningRadiusInnerMeetsOverallWidthY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicleTurningRadiusInner;

        //newLine = new Line(centreOfRadiusX, centreOfRadiusY, turningRadiusInnerMeetsOverallWidthX, -turningRadiusInnerMeetsOverallWidthY).setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

        //drawingObjectsArray.push(newLine);
        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.VEHICLETURNINGRADIUSINNER)
                                        .setLength(vehicleTurningRadiusInner)
                                        .setStartPoint(centreOfRadiusX, centreOfRadiusY)
                                        .setEndPoint(turningRadiusInnerMeetsOverallWidthX, -turningRadiusInnerMeetsOverallWidthY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

        if (isTrialUser) {
            dimensionConfig.setTrialText(config.trailUserObfuscationText);
        }

        newDimension = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId());

        if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_INNER) !== null) {
            newDimension.setShowAsWarning(true);
        }

        dimensions.push(newDimension);

        /*
    turningRadiusInnerMeetsOverallWidth.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicleTurningRadiusInner * PixelsPerScaleUnit
    turningRadiusInnerMeetsOverallWidth.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicleTurningRadiusInner * PixelsPerScaleUnit
    newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                    UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                    centreOfRadius.X, _
                                                    centreOfRadius.Y, _
                                                    turningRadiusInnerMeetsOverallWidth.X, _
                                                    turningRadiusInnerMeetsOverallWidth.Y, _
                                                    Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(vehicleTurningRadiusInner), ApplicationGlobals.LengthFormatWithoutUnit), _
                                                    isSelected)
    DrawingObjects.Add(newDimension)
    */

        if (turningRadiusCurbToCurbMax > 0) {


            newArc = new Arc(centreOfCircleX, centreOfCircleY, turningRadiusCurbToCurbMax, 180, 180).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            drawingObjectsArray.push(newArc);

            /*
            'turning radius curb to curb max
            newArc = New UtilDrawingObjectArc(CentreOfCircle.X, CentreOfCircle.Y, _TurningRadiusCurbToCurbMax * PixelsPerScaleUnit, 180, 180, UtilDrawingSurface.StrokeThicknessTypes.Bold)
            DrawingObjects.Add(newArc)
            */

            if (turningRadiusCurbToCurbMax !== turningRadiusCurbToCurb) {
                //turning radius curb to curb max dimension
                curbToCurbMaxX = centreOfRadiusX + Math.cos((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * turningRadiusCurbToCurbMax;
                curbToCurbMaxY = centreOfRadiusY + Math.sin((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * turningRadiusCurbToCurbMax;

                //newLine = new Line(centreOfRadiusX, centreOfRadiusY, curbToCurbMaxX, -curbToCurbMaxY);

                //drawingObjectsArray.push(newLine);

                dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TURNINGRADIUSCURBTOCURBMAX)
                                        .setLength(turningRadiusCurbToCurbMax)
                                        .setStartPoint(centreOfRadiusX, centreOfRadiusY)
                                        .setEndPoint(curbToCurbMaxX, -curbToCurbMaxY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

                if (isTrialUser) {
                    dimensionConfig.setTrialText(config.trailUserObfuscationText);
                }

                dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));
            }

            /*
            If _TurningRadiusCurbToCurbMax <> _TurningRadiusCurbToCurb Then
            'need to draw a separate dimension for the legal limit

            'turning radius curb to curb max dimension
            curbToCurbMax.X = centreOfRadius.X + Math.Cos((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * _TurningRadiusCurbToCurbMax * PixelsPerScaleUnit
            curbToCurbMax.Y = centreOfRadius.Y + Math.Sin((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * _TurningRadiusCurbToCurbMax * PixelsPerScaleUnit

            newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                            UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
            centreOfRadius.X, _
            centreOfRadius.Y, _
            curbToCurbMax.X, _
            curbToCurbMax.Y, _
            Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(_TurningRadiusCurbToCurbMax), ApplicationGlobals.LengthFormatWithoutUnit))
            DrawingObjects.Add(newDimension)

            End If
            */

        } else if (turningRadiusOuterMax > 0) {

            newArc = new Arc(centreOfCircleX, centreOfCircleY, turningRadiusOuterMax, 180, 180).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            drawingObjectsArray.push(newArc);
            /*
            'turning radius outer max
        newArc = New UtilDrawingObjectArc(CentreOfCircle.X, CentreOfCircle.Y, _TurningRadiusOuterMax * PixelsPerScaleUnit, 180, 180, UtilDrawingSurface.StrokeThicknessTypes.Bold)
        DrawingObjects.Add(newArc)
        */
            if (turningRadiusOuterMax !== turningRadiusWallToWall) {
                //turning radius outer max dimension
                wallToWallMaxX = centreOfRadiusX + Math.cos((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * turningRadiusOuterMax;
                wallToWallMaxY = centreOfRadiusY + Math.sin((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * turningRadiusOuterMax;

                //newLine = new Line(centreOfRadiusX, centreOfRadiusY, wallToWallMaxX, -wallToWallMaxY);

                //drawingObjectsArray.push(newLine);

                dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TURNINGRADIUSOUTERMAX)
                                        .setLength(turningRadiusOuterMax)
                                        .setStartPoint(centreOfRadiusX, centreOfRadiusY)
                                        .setEndPoint(wallToWallMaxX, -wallToWallMaxY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

                if (isTrialUser) {
                    dimensionConfig.setTrialText(config.trailUserObfuscationText);
                }

                dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));
            }

            /*
        If _TurningRadiusOuterMax <> _TurningRadiusWallToWall Then

            'turning radius outer max dimension
            wallToWallMax.X = centreOfRadius.X + Math.Cos((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * _TurningRadiusOuterMax * PixelsPerScaleUnit
            wallToWallMax.Y = centreOfRadius.Y + Math.Sin((360 - angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * _TurningRadiusOuterMax * PixelsPerScaleUnit

            newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                            UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                            centreOfRadius.X, _
                                                            centreOfRadius.Y, _
                                                            wallToWallMax.X, _
                                                            wallToWallMax.Y, _
                                                            Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(_TurningRadiusOuterMax), ApplicationGlobals.LengthFormatWithoutUnit))
            DrawingObjects.Add(newDimension)

        End If
            */
        }

        /*
    If _TurningRadiusCurbToCurbMax > 0 Then

        

    ElseIf _TurningRadiusOuterMax > 0 Then

        

    End If
    */
        if (turningRadiusInnerMin > 0) {
            var innerRadiusDimensionAngle;
            if (trailer1 !== null && trailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                innerRadiusDimensionAngle = 270
            } else {
                innerRadiusDimensionAngle = 360 - 2 * angleBetweenZeroAndLegalAnchorPoint;
            }

            //turning radius inner min
            newArc = new Arc(centreOfCircleX, centreOfCircleY, turningRadiusInnerMin, 180, 180).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            drawingObjectsArray.push(newArc);

            //turning radius inner min dimension
            innerMinX = centreOfRadiusX + Math.cos(innerRadiusDimensionAngle * Math.PI / 180) * turningRadiusInnerMin;
            innerMinY = centreOfRadiusY + Math.sin(innerRadiusDimensionAngle * Math.PI / 180) * turningRadiusInnerMin;

            //newLine = new Line(centreOfRadiusX, centreOfRadiusY, innerMinX, -innerMinY);

            //drawingObjectsArray.push(newLine);

            dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TURNINGRADIUSINNERMIN)
                                        .setLength(turningRadiusInnerMin)
                                        .setStartPoint(centreOfRadiusX, centreOfRadiusY)
                                        .setEndPoint(innerMinX, -innerMinY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

            dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));
        }
        /*
    If _TurningRadiusInnerMin > 0 Then

        Dim innerRadiusDimensionAngle As Double

        If trailer1 IsNot Nothing AndAlso TypeOf (trailer1) Is FileLoadingTrailerDrawbar Then
            innerRadiusDimensionAngle = 270
        Else
            innerRadiusDimensionAngle = 360 - 2 * angleBetweenZeroAndLegalAnchorPoint
        End If

        'turning radius inner min
        newArc = New UtilDrawingObjectArc(CentreOfCircle.X, CentreOfCircle.Y, _TurningRadiusInnerMin * PixelsPerScaleUnit, 180, 180, UtilDrawingSurface.StrokeThicknessTypes.Bold)
        DrawingObjects.Add(newArc)

        'turning radius inner min dimension
        innerMin.X = centreOfRadius.X + Math.Cos(innerRadiusDimensionAngle * Math.PI / 180) * _TurningRadiusInnerMin * PixelsPerScaleUnit
        innerMin.Y = centreOfRadius.Y + Math.Sin(innerRadiusDimensionAngle * Math.PI / 180) * _TurningRadiusInnerMin * PixelsPerScaleUnit

        newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                        UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                        centreOfRadius.X, _
                                                        centreOfRadius.Y, _
                                                        innerMin.X, _
                                                        innerMin.Y, _
                                                        Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(_TurningRadiusInnerMin), ApplicationGlobals.LengthFormatWithoutUnit))
        DrawingObjects.Add(newDimension)

    End If
    */
        //calculate the point where the cab meets the turning radius wall to wall
        var cabFrontRightCornerOnWallToWallX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * turningRadiusWallToWall;
        var cabFrontRightCornerOnWallToWallY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * turningRadiusWallToWall;
        //calculate the point where the outer front tyre meets the curb to curb radius
        tyreFrontRightCurbToCurbX = cabFrontRightCornerOnWallToWallX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getFrontOverhang();
        tyreFrontRightCurbToCurbY = cabFrontRightCornerOnWallToWallY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getFrontOverhang();
        tyreFrontRightCurbToCurbX = tyreFrontRightCurbToCurbX + Math.cos((angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getCabWidth() - vehicle.getTrackWidthFront()) / 2;
        tyreFrontRightCurbToCurbY = tyreFrontRightCurbToCurbY + Math.sin((angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getCabWidth() - vehicle.getTrackWidthFront()) / 2;
        tyreFrontRightCurbToCurbX = tyreFrontRightCurbToCurbX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter) * Math.PI / 180) * vehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
        tyreFrontRightCurbToCurbY = tyreFrontRightCurbToCurbY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter) * Math.PI / 180) * vehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;

        //cabFrontRightCornerOnWallToWallX = (Math.max(turningRadiusWallToWall, turningRadiusOuterMax)) - cabFrontRightCornerOnWallToWallX;
        //cabFrontRightCornerOnWallToWallY = (Math.max(turningRadiusWallToWall, turningRadiusOuterMax)) - cabFrontRightCornerOnWallToWallY;

        /*
    'calculate the point where the cab meets the turning radius wall to wall
    cabFrontRightCornerOnWallToWall.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * _TurningRadiusWallToWall * PixelsPerScaleUnit
    cabFrontRightCornerOnWallToWall.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndLegalAnchorPoint) * Math.PI / 180) * _TurningRadiusWallToWall * PixelsPerScaleUnit
    'calculate the point where the outer front tyre meets the curb to curb radius
    tyreFrontRightCurbToCurb.X = cabFrontRightCornerOnWallToWall.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.FrontOverhang * PixelsPerScaleUnit
    tyreFrontRightCurbToCurb.Y = cabFrontRightCornerOnWallToWall.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.FrontOverhang * PixelsPerScaleUnit
    tyreFrontRightCurbToCurb.X = tyreFrontRightCurbToCurb.X + Math.Cos((angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.CabWidth - vehicle.TrackWidthFront) / 2 * PixelsPerScaleUnit
    tyreFrontRightCurbToCurb.Y = tyreFrontRightCurbToCurb.Y + Math.Sin((angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.CabWidth - vehicle.TrackWidthFront) / 2 * PixelsPerScaleUnit
    tyreFrontRightCurbToCurb.X = tyreFrontRightCurbToCurb.X + Math.Cos((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter) * Math.PI / 180) * vehicle.Axles.Axle1FrontTyreWidth / 2 * PixelsPerScaleUnit
    tyreFrontRightCurbToCurb.Y = tyreFrontRightCurbToCurb.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter) * Math.PI / 180) * vehicle.Axles.Axle1FrontTyreWidth / 2 * PixelsPerScaleUnit
    */
        //turning radius wall to wall
        if (curViewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST) {
            newArc = new Arc(centreOfCircleX, centreOfCircleY, turningRadiusWallToWall, 180, 180, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            drawingObjectsArray.push(newArc);
        }

        /*
    'turning radius wall to wall
    If view = FileLoading.ViewType.TurningCircleSmallest Then
        newArc = New UtilDrawingObjectArc(CentreOfCircle.X, CentreOfCircle.Y, _TurningRadiusWallToWall * PixelsPerScaleUnit, 180, 180)
        DrawingObjects.Add(newArc)
    End If
    */
        //if (Math.round(turningRadiusWallToWall, 0) > turningRadiusOuterMax && turningRadiusOuterMax > 0) {
        //    isSelected = true;
        //} else {
        //    isSelected = false;
        //}

        //newLine = new Line(centreOfRadiusX, centreOfRadiusY, cabFrontRightCornerOnWallToWallX, -cabFrontRightCornerOnWallToWallY);

        //drawingObjectsArray.push(newLine);

        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TURNINGRADIUSWALLTOWALL)
                                        .setLength(turningRadiusWallToWall)
                                        .setStartPoint(centreOfRadiusX, centreOfRadiusY)
                                        .setEndPoint(cabFrontRightCornerOnWallToWallX, -cabFrontRightCornerOnWallToWallY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

        if (isTrialUser) {
            dimensionConfig.setTrialText(config.trailUserObfuscationText);
        }

        newDimension = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId());

        if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_OUTER) !== null && turningRadiusOuterMax > 0) {
            newDimension.setShowAsWarning(true);
        }

        dimensions.push(newDimension);

        /*
    If Math.Round(_TurningRadiusWallToWall, 0) > _TurningRadiusOuterMax And _TurningRadiusOuterMax > 0 Then
        isSelected = True
    Else
        isSelected = False
    End If
    newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                    UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                    centreOfRadius.X, _
                                                    centreOfRadius.Y, _
                                                    cabFrontRightCornerOnWallToWall.X, _
                                                    cabFrontRightCornerOnWallToWall.Y, _
                                                    Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(_TurningRadiusWallToWall), ApplicationGlobals.LengthFormatWithoutUnit), _
                                                    isSelected)
    DrawingObjects.Add(newDimension)
    */
        //turning radius curb to curb
        if (curViewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST) {
            newArc = new Arc(centreOfCircleX, centreOfCircleY, turningRadiusCurbToCurb, 180, 180, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            drawingObjectsArray.push(newArc);
        }
        /*
    'turning radius curb to curb
    If view = FileLoading.ViewType.TurningCircleSmallest Then
        newArc = New UtilDrawingObjectArc(CentreOfCircle.X, CentreOfCircle.Y, _TurningRadiusCurbToCurb * PixelsPerScaleUnit, 180, 180)
        DrawingObjects.Add(newArc)
    End If
    */

        //if (Math.round(turningRadiusCurbToCurb, 0) > turningRadiusCurbToCurbMax && turningRadiusCurbToCurbMax > 0) {
        //    isSelected = true
        //} else {
        //    isSelected = false
        //}
        //newLine = new Line(centreOfRadiusX, centreOfRadiusY, tyreFrontRightCurbToCurbX, -tyreFrontRightCurbToCurbY);

        //drawingObjectsArray.push(newLine);

        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TURNINGRADIUSCURBTOCURB)
                                        .setLength(turningRadiusCurbToCurb)
                                        .setStartPoint(centreOfRadiusX, centreOfRadiusY)
                                        .setEndPoint(tyreFrontRightCurbToCurbX, -tyreFrontRightCurbToCurbY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

        if (isTrialUser) {
            dimensionConfig.setTrialText(config.trailUserObfuscationText);
        }

        newDimension = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId());

        if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_OUTER) !== null && turningRadiusCurbToCurbMax > 0) {
            newDimension.setShowAsWarning(true);
        }

        dimensions.push(newDimension);

        /*
    If Math.Round(_TurningRadiusCurbToCurb, 0) > _TurningRadiusCurbToCurbMax And _TurningRadiusCurbToCurbMax > 0 Then
        isSelected = True
    Else
        isSelected = False
    End If
    newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                    UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                    centreOfRadius.X, _
                                                    centreOfRadius.Y, _
                                                    tyreFrontRightCurbToCurb.X, _
                                                    tyreFrontRightCurbToCurb.Y, _
                                                    Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(_TurningRadiusCurbToCurb), ApplicationGlobals.LengthFormatWithoutUnit), _
                                                    isSelected)
    DrawingObjects.Add(newDimension)
    */
            
        //get the points for the cab
        cabFrontLeftX = cabFrontRightCornerOnWallToWallX - Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getCabWidth();
        cabFrontLeftY = cabFrontRightCornerOnWallToWallY - Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getCabWidth();
        cabBackLeftX = cabFrontLeftX + Math.cos((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getBumperToBackOfCab();
        cabBackLeftY = cabFrontLeftY + Math.sin((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getBumperToBackOfCab();
        cabBackRightX = cabBackLeftX + Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getCabWidth();
        cabBackRightY = cabBackLeftY + Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getCabWidth();



        /*
    'get the points for the cab
    cabFrontLeft.X = cabFrontRightCornerOnWallToWall.X - Math.Cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.CabWidth * PixelsPerScaleUnit
    cabFrontLeft.Y = cabFrontRightCornerOnWallToWall.Y - Math.Sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.CabWidth * PixelsPerScaleUnit
    cabBackLeft.X = cabFrontLeft.X + Math.Cos((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.BumperToBackOfCab * PixelsPerScaleUnit
    cabBackLeft.Y = cabFrontLeft.Y + Math.Sin((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.BumperToBackOfCab * PixelsPerScaleUnit
    cabBackRight.X = cabBackLeft.X + Math.Cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.CabWidth * PixelsPerScaleUnit
    cabBackRight.Y = cabBackLeft.Y + Math.Sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.CabWidth * PixelsPerScaleUnit
    */
        ////draw the cab
        //newLine = new Line(cabBackLeftX, -cabBackLeftY, cabBackRightX, -cabBackRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(cabBackRightX, -cabBackRightY, cabFrontRightCornerOnWallToWallX, -cabFrontRightCornerOnWallToWallY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(cabFrontRightCornerOnWallToWallX, -cabFrontRightCornerOnWallToWallY, cabFrontLeftX, -cabFrontLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(cabFrontLeftX, -cabFrontLeftY, cabBackLeftX, -cabBackLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);

        /*
    'draw the cab
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, cabBackLeft.X, cabBackLeft.Y, cabBackRight.X, cabBackRight.Y, False, True)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, cabBackRight.X, cabBackRight.Y, cabFrontRightCornerOnWallToWall.X, cabFrontRightCornerOnWallToWall.Y, False, True)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, cabFrontRightCornerOnWallToWall.X, cabFrontRightCornerOnWallToWall.Y, cabFrontLeft.X, cabFrontLeft.Y, False, True)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, cabFrontLeft.X, cabFrontLeft.Y, cabBackLeft.X, cabBackLeft.Y, False, True)
    DrawingObjects.Add(newLine)
    */

        

        if (vehicle.getRearWidthOfVehicleForTurningCircleCalculation() > vehicle.getRearWidthOfBodyForTurningCircleCalculation()) {

            angleBetweenCentreOfRearAxleAndEndOfVehicle = Math.atan(vehicle.getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation() / vehicleTurningRadiusInner) * 180 / Math.PI;
            angleBetweenCentreOfRearAxleAndEndOfBody = Math.atan(vehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation()
                                                                    / (vehicleTurningRadiusInner + (vehicle.getRearWidthOfVehicleForTurningCircleCalculation() -
                                                                                            vehicle.getRearWidthOfBodyForTurningCircleCalculation()) / 2)) * 180 / Math.PI;

        } else {

            angleBetweenCentreOfRearAxleAndEndOfVehicle = Math.atan(vehicle.getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation()
                                                                    / (vehicleTurningRadiusInner + (vehicle.getRearWidthOfBodyForTurningCircleCalculation() -
                                                                                                vehicle.getRearWidthOfVehicleForTurningCircleCalculation()) / 2)) * 180 / Math.PI;
            angleBetweenCentreOfRearAxleAndEndOfBody = Math.atan(vehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation() / vehicleTurningRadiusInner) * 180 / Math.PI;

        }

        /*
    If vehicle.RearWidthOfVehicleForTurningCircleCalculation > vehicle.RearWidthOfBodyForTurningCircleCalculation Then

        angleBetweenCentreOfRearAxleAndEndOfVehicle = Math.Atan(vehicle.WheelbaseTurningToRearOfVehicleForTurningCircleCalculation / vehicleTurningRadiusInner) * 180 / Math.PI
        angleBetweenCentreOfRearAxleAndEndOfBody = Math.Atan(vehicle.WheelbaseTurningToRearOfBodyForTurningCircleCalculation _
                                                                / (vehicleTurningRadiusInner + (vehicle.RearWidthOfVehicleForTurningCircleCalculation - _
                                                                                        vehicle.RearWidthOfBodyForTurningCircleCalculation) / 2)) * 180 / Math.PI

    Else

        angleBetweenCentreOfRearAxleAndEndOfVehicle = Math.Atan(vehicle.WheelbaseTurningToRearOfVehicleForTurningCircleCalculation _
                                                                / (vehicleTurningRadiusInner + (vehicle.RearWidthOfBodyForTurningCircleCalculation - _
                                                                                            vehicle.RearWidthOfVehicleForTurningCircleCalculation) / 2)) * 180 / Math.PI
        angleBetweenCentreOfRearAxleAndEndOfBody = Math.Atan(vehicle.WheelbaseTurningToRearOfBodyForTurningCircleCalculation / vehicleTurningRadiusInner) * 180 / Math.PI

    End If
    */
        

        ////draw the body
        if (vehicle.getAccessoryHolder().getBody() !== null) {

            //left back corner of the body
            toBodyBackLeftCorner = vehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation() / Math.sin(angleBetweenCentreOfRearAxleAndEndOfBody * Math.PI / 180);
            bodyBackLeftCornerX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle + angleBetweenCentreOfRearAxleAndEndOfBody) * Math.PI / 180) * toBodyBackLeftCorner;
            bodyBackLeftCornerY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle + angleBetweenCentreOfRearAxleAndEndOfBody) * Math.PI / 180) * toBodyBackLeftCorner;
            //right back corner of the body
            bodyBackRightCornerX = bodyBackLeftCornerX + Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearWidthOfBodyForTurningCircleCalculation();
            bodyBackRightCornerY = bodyBackLeftCornerY + Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearWidthOfBodyForTurningCircleCalculation();
            //right front corner of the body
            bodyFrontRightCornerX = bodyBackRightCornerX - Math.cos((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getBodyLengthForTurningCircleCalculation();
            bodyFrontRightCornerY = bodyBackRightCornerY - Math.sin((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getBodyLengthForTurningCircleCalculation();
            //left front corner of the body
            bodyFrontLeftCornerX = bodyFrontRightCornerX - Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearWidthOfBodyForTurningCircleCalculation();
            bodyFrontLeftCornerY = bodyFrontRightCornerY - Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearWidthOfBodyForTurningCircleCalculation();

            ////draw the rear of the body
            //newLine = new Line(bodyBackLeftCornerX, -bodyBackLeftCornerY, bodyBackRightCornerX, -bodyBackRightCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);
            //newLine = new Line(bodyBackRightCornerX, -bodyBackRightCornerY, bodyFrontRightCornerX, -bodyFrontRightCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);
            //newLine = new Line(bodyFrontRightCornerX, -bodyFrontRightCornerY, bodyFrontLeftCornerX, -bodyFrontLeftCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);
            //newLine = new Line(bodyFrontLeftCornerX, -bodyFrontLeftCornerY, bodyBackLeftCornerX, -bodyBackLeftCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);

            //bodyPresent = true;

        }
        /*
    'draw the body
    If vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

        'left back corner of the body
        toBodyBackLeftCorner = vehicle.WheelbaseTurningToRearOfBodyForTurningCircleCalculation / Math.Sin(angleBetweenCentreOfRearAxleAndEndOfBody * Math.PI / 180)
        bodyBackLeftCorner.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle + angleBetweenCentreOfRearAxleAndEndOfBody) * Math.PI / 180) * toBodyBackLeftCorner * PixelsPerScaleUnit
        bodyBackLeftCorner.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle + angleBetweenCentreOfRearAxleAndEndOfBody) * Math.PI / 180) * toBodyBackLeftCorner * PixelsPerScaleUnit
        'right back corner of the body
        bodyBackRightCorner.X = bodyBackLeftCorner.X + Math.Cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearWidthOfBodyForTurningCircleCalculation * PixelsPerScaleUnit
        bodyBackRightCorner.Y = bodyBackLeftCorner.Y + Math.Sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearWidthOfBodyForTurningCircleCalculation * PixelsPerScaleUnit
        'right front corner of the body
        bodyFrontRightCorner.X = bodyBackRightCorner.X - Math.Cos((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.BodyLengthForTurningCircleCalculation * PixelsPerScaleUnit
        bodyFrontRightCorner.Y = bodyBackRightCorner.Y - Math.Sin((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.BodyLengthForTurningCircleCalculation * PixelsPerScaleUnit
        'left front corner of the body
        bodyFrontLeftCorner.X = bodyFrontRightCorner.X - Math.Cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearWidthOfBodyForTurningCircleCalculation * PixelsPerScaleUnit
        bodyFrontLeftCorner.Y = bodyFrontRightCorner.Y - Math.Sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearWidthOfBodyForTurningCircleCalculation * PixelsPerScaleUnit

        'draw the rear of the body
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bodyBackLeftCorner.X, bodyBackLeftCorner.Y, bodyBackRightCorner.X, bodyBackRightCorner.Y, False, True)
        DrawingObjects.Add(newLine)
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bodyBackRightCorner.X, bodyBackRightCorner.Y, bodyFrontRightCorner.X, bodyFrontRightCorner.Y, False, True)
        DrawingObjects.Add(newLine)
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bodyFrontRightCorner.X, bodyFrontRightCorner.Y, bodyFrontLeftCorner.X, bodyFrontLeftCorner.Y, False, True)
        DrawingObjects.Add(newLine)
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bodyFrontLeftCorner.X, bodyFrontLeftCorner.Y, bodyBackLeftCorner.X, bodyBackLeftCorner.Y, False, True)
        DrawingObjects.Add(newLine)

        bodyPresent = True

    End If
    */

        //front right corner of the chassis
        vehicleFrontRightCornerX = cabBackRightX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * ((vehicle.getCabWidth() - vehicle.getChassisWidth()) / 2);
        vehicleFrontRightCornerY = cabBackRightY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * ((vehicle.getCabWidth() - vehicle.getChassisWidth()) / 2);
        //front left corner of the chassis
        vehicleFrontLeftCornerX = vehicleFrontRightCornerX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getChassisWidth();
        vehicleFrontLeftCornerY = vehicleFrontRightCornerY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getChassisWidth();
        //left back corner of the chassis
        vehicleBackLeftCornerX = vehicleFrontLeftCornerX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getOverallLengthExclAccessories() - vehicle.getBumperToBackOfCab() - vehicle.getRearProtrusion());
        vehicleBackLeftCornerY = vehicleFrontLeftCornerY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getOverallLengthExclAccessories() - vehicle.getBumperToBackOfCab() - vehicle.getRearProtrusion());
        //right back corner of the chassis
        vehicleBackRightCornerX = vehicleBackLeftCornerX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getChassisWidth();
        vehicleBackRightCornerY = vehicleBackLeftCornerY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getChassisWidth();

        ////draw the chassis
        //newLine = new Line(vehicleFrontRightCornerX, -vehicleFrontRightCornerY, vehicleFrontLeftCornerX, -vehicleFrontLeftCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(vehicleFrontLeftCornerX, -vehicleFrontLeftCornerY, vehicleBackLeftCornerX, -vehicleBackLeftCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(vehicleBackLeftCornerX, -vehicleBackLeftCornerY, vehicleBackRightCornerX, -vehicleBackRightCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(vehicleBackRightCornerX, -vehicleBackRightCornerY, vehicleFrontRightCornerX, -vehicleFrontRightCornerY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);

        /*
    'front right corner of the chassis
    vehicleFrontRightCorner.X = cabBackRight.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * ((vehicle.CabWidth - vehicle.ChassisWidth) / 2) * PixelsPerScaleUnit
    vehicleFrontRightCorner.Y = cabBackRight.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * ((vehicle.CabWidth - vehicle.ChassisWidth) / 2) * PixelsPerScaleUnit
    'front left corner of the chassis
    vehicleFrontLeftCorner.X = vehicleFrontRightCorner.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.ChassisWidth * PixelsPerScaleUnit
    vehicleFrontLeftCorner.Y = vehicleFrontRightCorner.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.ChassisWidth * PixelsPerScaleUnit
    'left back corner of the chassis
    vehicleBackLeftCorner.X = vehicleFrontLeftCorner.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.OverallLengthExclAccessories - vehicle.BumperToBackOfCab - vehicle.RearProtrusion) * PixelsPerScaleUnit
    vehicleBackLeftCorner.Y = vehicleFrontLeftCorner.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.OverallLengthExclAccessories - vehicle.BumperToBackOfCab - vehicle.RearProtrusion) * PixelsPerScaleUnit
    'right back corner of the chassis
    vehicleBackRightCorner.X = vehicleBackLeftCorner.X + Math.Cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.ChassisWidth * PixelsPerScaleUnit
    vehicleBackRightCorner.Y = vehicleBackLeftCorner.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.ChassisWidth * PixelsPerScaleUnit

    'draw the chassis
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleFrontRightCorner.X, vehicleFrontRightCorner.Y, vehicleFrontLeftCorner.X, vehicleFrontLeftCorner.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleFrontLeftCorner.X, vehicleFrontLeftCorner.Y, vehicleBackLeftCorner.X, vehicleBackLeftCorner.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleBackLeftCorner.X, vehicleBackLeftCorner.Y, vehicleBackRightCorner.X, vehicleBackRightCorner.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleBackRightCorner.X, vehicleBackRightCorner.Y, vehicleFrontRightCorner.X, vehicleFrontRightCorner.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    */

        //front left of vehicle rear
        vehicleRearFrontLeftX = vehicleBackLeftCornerX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.getRearWidthOfVehicleForTurningCircleCalculation() - vehicle.getChassisWidth()) / 2;
        vehicleRearFrontLeftY = vehicleBackLeftCornerY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.getRearWidthOfVehicleForTurningCircleCalculation() - vehicle.getChassisWidth()) / 2;
        //front right of vehicle rear
        vehicleRearFrontRightX = vehicleRearFrontLeftX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearWidthOfVehicleForTurningCircleCalculation();
        vehicleRearFrontRightY = vehicleRearFrontLeftY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearWidthOfVehicleForTurningCircleCalculation();
        //back right of vehicle rear
        vehicleRearBackRightX = vehicleRearFrontRightX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearProtrusion();
        vehicleRearBackRightY = vehicleRearFrontRightY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getRearProtrusion();
        //back left of vehicle rear
        vehicleRearBackLeftX = vehicleRearBackRightX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getRearWidthOfVehicleForTurningCircleCalculation();
        vehicleRearBackLeftY = vehicleRearBackRightY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getRearWidthOfVehicleForTurningCircleCalculation();

        ////draw the rear of the vehicle
        //newLine = new Line(vehicleRearFrontRightX, -vehicleRearFrontRightY, vehicleRearFrontLeftX, -vehicleRearFrontLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(vehicleRearFrontLeftX, -vehicleRearFrontLeftY, vehicleRearBackLeftX, -vehicleRearBackLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(vehicleRearBackLeftX, -vehicleRearBackLeftY, vehicleRearBackRightX, -vehicleRearBackRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //newLine = new Line(vehicleRearBackRightX, -vehicleRearBackRightY, vehicleRearFrontRightX, -vehicleRearFrontRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);

        /*
    'front left of vehicle rear
    vehicleRearFrontLeft.X = vehicleBackLeftCorner.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.RearWidthOfVehicleForTurningCircleCalculation - vehicle.ChassisWidth) / 2 * PixelsPerScaleUnit
    vehicleRearFrontLeft.Y = vehicleBackLeftCorner.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.RearWidthOfVehicleForTurningCircleCalculation - vehicle.ChassisWidth) / 2 * PixelsPerScaleUnit
    'front right of vehicle rear
    vehicleRearFrontRight.X = vehicleRearFrontLeft.X + Math.Cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearWidthOfVehicleForTurningCircleCalculation * PixelsPerScaleUnit
    vehicleRearFrontRight.Y = vehicleRearFrontLeft.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearWidthOfVehicleForTurningCircleCalculation * PixelsPerScaleUnit
    'back right of vehicle rear
    vehicleRearBackRight.X = vehicleRearFrontRight.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearProtrusion * PixelsPerScaleUnit
    vehicleRearBackRight.Y = vehicleRearFrontRight.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.RearProtrusion * PixelsPerScaleUnit
    'back left of vehicle rear
    vehicleRearBackLeft.X = vehicleRearBackRight.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.RearWidthOfVehicleForTurningCircleCalculation * PixelsPerScaleUnit
    vehicleRearBackLeft.Y = vehicleRearBackRight.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.RearWidthOfVehicleForTurningCircleCalculation * PixelsPerScaleUnit

    'draw the rear of the vehicle
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleRearFrontRight.X, vehicleRearFrontRight.Y, vehicleRearFrontLeft.X, vehicleRearFrontLeft.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleRearFrontLeft.X, vehicleRearFrontLeft.Y, vehicleRearBackLeft.X, vehicleRearBackLeft.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleRearBackLeft.X, vehicleRearBackLeft.Y, vehicleRearBackRight.X, vehicleRearBackRight.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, vehicleRearBackRight.X, vehicleRearBackRight.Y, vehicleRearFrontRight.X, vehicleRearFrontRight.Y, False, Not bodyPresent)
    DrawingObjects.Add(newLine)
    */


        cabFrontLeftX = cabFrontRightCornerOnWallToWallX - Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getCabWidth();
        cabFrontLeftY = cabFrontRightCornerOnWallToWallY - Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getCabWidth();
        cabBackLeftX = cabFrontLeftX + Math.cos((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getBumperToBackOfCab();
        cabBackLeftY = cabFrontLeftY + Math.sin((270 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getBumperToBackOfCab();
        cabBackRightX = cabBackLeftX + Math.cos((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getCabWidth();
        cabBackRightY = cabBackLeftY + Math.sin((180 + angleBetweenZeroAndLegalAnchorPoint + angleBetweenWallToWallAnchorPointAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getCabWidth();

        //front right corner of the chassis
        vehicleFrontRightCornerX = cabBackRightX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * ((curVehicle.getCabWidth() - curVehicle.getChassisWidth()) / 2);
        vehicleFrontRightCornerY = cabBackRightY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * ((curVehicle.getCabWidth() - curVehicle.getChassisWidth()) / 2);
        //front left corner of the chassis
        vehicleFrontLeftCornerX = vehicleFrontRightCornerX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * curVehicle.getChassisWidth();
        vehicleFrontLeftCornerY = vehicleFrontRightCornerY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * curVehicle.getChassisWidth();

        //left back corner of the chassis
        vehicleBackLeftCornerX = vehicleFrontLeftCornerX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (curVehicle.getOverallLengthExclAccessories() - curVehicle.getBumperToBackOfCab() - curVehicle.getRearProtrusion());
        vehicleBackLeftCornerY = vehicleFrontLeftCornerY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (curVehicle.getOverallLengthExclAccessories() - curVehicle.getBumperToBackOfCab() - curVehicle.getRearProtrusion());
        //right back corner of the chassis
        vehicleBackRightCornerX = vehicleBackLeftCornerX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getChassisWidth();
        vehicleBackRightCornerY = vehicleBackLeftCornerY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getChassisWidth();

        //front left of vehicle rear
        vehicleRearFrontLeftX = vehicleBackLeftCornerX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (curVehicle.getRearWidthOfVehicleForTurningCircleCalculation() - curVehicle.getChassisWidth()) / 2;
        vehicleRearFrontLeftY = vehicleBackLeftCornerY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (curVehicle.getRearWidthOfVehicleForTurningCircleCalculation() - curVehicle.getChassisWidth()) / 2;
        //front right of vehicle rear
        vehicleRearFrontRightX = vehicleRearFrontLeftX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getRearWidthOfVehicleForTurningCircleCalculation();
        vehicleRearFrontRightY = vehicleRearFrontLeftY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getRearWidthOfVehicleForTurningCircleCalculation();
        //back right of vehicle rear
        vehicleRearBackRightX = vehicleRearFrontRightX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getRearProtrusion();
        vehicleRearBackRightY = vehicleRearFrontRightY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * curVehicle.getRearProtrusion();
        //back left of vehicle rear
        vehicleRearBackLeftX = vehicleRearBackRightX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * curVehicle.getRearWidthOfVehicleForTurningCircleCalculation();
        vehicleRearBackLeftY = vehicleRearBackRightY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * curVehicle.getRearWidthOfVehicleForTurningCircleCalculation();


        vehicleTransformation = {};
        vehicleTransformation.originX = centreOfRadiusX;
        vehicleTransformation.originY = centreOfRadiusY;
        vehicleTransformation.anchorX = vehicle.getPortionOfOverallLengthOverhangingFront() + vehicle.getFrontOverhang() + vehicle.getWheelbaseTurning();
        vehicleTransformation.anchorY = 0;
        vehicleTransformation.angle = -(90 - angleBetweenZeroAndCentreOfRearAxle);
        vehicleTransformation.referencePointX = turningRadiusInnerMeetsOverallWidthX;
        vehicleTransformation.referencePointY = turningRadiusInnerMeetsOverallWidthY;
        vehicleTransformation.steeringAngleInner = steeringAngleInner;
        vehicleTransformation.steeringAngleOuter = steeringAngleOuter;
        vehicleTransformation.secondSteeringAngleInner = secondSteeringAngleInner;
        vehicleTransformation.secondSteeringAngleOuter = secondSteeringAngleOuter;
        vehicleTransformation.steeringAngleAxle1RearOuter = steeringAngleAxle1RearOuter;
        vehicleTransformation.steeringAngleAxle1RearInner = steeringAngleAxle1RearInner;
        vehicleTransformation.steeringAngleAxle2RearOuter = steeringAngleAxle2RearOuter;
        vehicleTransformation.steeringAngleAxle2RearInner = steeringAngleAxle2RearInner;
        vehicleTransformation.steeringAngleAxle3RearOuter = steeringAngleAxle3RearOuter;
        vehicleTransformation.steeringAngleAxle3RearInner = steeringAngleAxle3RearInner;
        vehicleTransformation.steeringAngleAxle4RearOuter = steeringAngleAxle4RearOuter;
        vehicleTransformation.steeringAngleAxle4RearInner = steeringAngleAxle4RearInner;


        
        //drawVehicle(chassisCoords, centreOfRadiusX, centreOfRadiusY, angleBetweenZeroAndLegalAnchorPoint, angleBetweenWallToWallAnchorPointAndCentreOfRearAxle, angleBetweenZeroAndCentreOfRearAxle, config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawVehicleTopView(config.OBJECT_TYPES.TURNING_CIRCLE, transformation);
        //var bodyCoords = {};
        //drawBody(bodyCoords, centreOfRadiusX, centreOfRadiusY, angleBetweenZeroAndLegalAnchorPoint, angleBetweenWallToWallAnchorPointAndCentreOfRearAxle, vehicleTurningRadiusInner, config.OBJECT_TYPES.TURNING_CIRCLE);

        

        //vehicle.getAccessoryHolder().getAccessories().forEach(function (accessory) {

        //    if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                

        //        populateDrawingObjectsForTemplateOtherTopView(accessory, vehicle, transformation);
        //    }
        //});

        //if (vehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
        //    vehicle.getPayloadHolder().getDetailedPayloadItems().forEach(function (payload) {

        //        if (payload.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || payload.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM || payload.getSourceDatabaseId().toString().indexOf('unknown') !== -1) {

        //            populateDrawingObjectsForTemplatePayloadTopView(payload, vehicle, transformation);

        //        } else {

        //            var payloadXMLDoc = translateGraphicToXmlDoc(payload.getGraphicBlobByElevation(curElevation));

        //            if (payloadXMLDoc === null) {
        //                populateDrawingObjectsForTemplatePayloadTopView(payload, vehicle, transformation);
        //            } else {
        //                var payloadDrawingObjects = populateDrawingObjectsFromXML(payloadXMLDoc, vehicle.getChassisObjectName(), config.ACCESSORY_TYPES.PAYLOAD, payload.getId());
                        
        //                applyTransformationsToDrawingObjects(payloadDrawingObjects, tranformation);
        //                drawingObjectsArray = drawingObjectsArray.concat(payloadDrawingObjects);
                        
        //                var payloadTextObject = getPayloadLabel(payload, vehicle, transformation);
        //                drawingObjectsArray.push(payloadTextObject);
        //            }


        //        }
        //    });
        //}
        


        startDimensionX = cabFrontRightCornerOnWallToWallX + Math.cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * (tcDimensionOffset / scalingFactor);
        startDimensionY = cabFrontRightCornerOnWallToWallY + Math.sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * (tcDimensionOffset / scalingFactor);
        endDimensionX = cabFrontLeftX + Math.cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * (tcDimensionOffset / scalingFactor);
        endDimensionY = cabFrontLeftY + Math.sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * (tcDimensionOffset / scalingFactor);

        //newLine = new Line(startDimensionX, -startDimensionY, endDimensionX, -endDimensionY);

        //drawingObjectsArray.push(newLine);

        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.CABWIDTH) 
                                        .setLength(vehicle.getCabWidth())
                                        .setStartPoint(startDimensionX, -startDimensionY)
                                        .setEndPoint(endDimensionX, -endDimensionY)
                                        .addCustomDisplayTextFunction(getCabWidthDimensionDisplayText)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

        dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));

        /*
    'add the dimension
    startDimension.X = cabFrontRightCornerOnWallToWall.X + Math.Cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * DimensionOffset
    startDimension.Y = cabFrontRightCornerOnWallToWall.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * DimensionOffset
    endDimension.X = cabFrontLeft.X + Math.Cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * DimensionOffset
    endDimension.Y = cabFrontLeft.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * 2 * DimensionOffset
    newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                    UtilDrawingObjectDimension.DimensionEnds.ArrowStartAndEndWithDelimiter, _
                                                    startDimension.X, _
                                                    startDimension.Y, _
                                                    endDimension.X, _
                                                    endDimension.Y, _
                                                    Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(vehicle.CabWidth), ApplicationGlobals.LengthFormatWithoutUnit))
    DrawingObjects.Add(newDimension)
    */
        

        if (vehicle.getAccessoryHolder().getBody() !== null) {
            if (vehicle.getRearWidthOfVehicleForTurningCircleCalculation() > vehicle.getRearWidthOfBodyForTurningCircleCalculation()) {
                startDimensionX = vehicleRearBackLeftX;
                startDimensionY = vehicleRearBackLeftY;
                endDimensionX = vehicleRearBackRightX;
                endDimensionY = vehicleRearBackRightY;
                if (vehicle.getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation() >= vehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation()) {
                    rearDimensionOffset = (tcDimensionOffset / scalingFactor);
                } else {
                    rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (vehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation() - vehicle.getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation());
                }
            } else {
                startDimensionX = bodyBackLeftCornerX;
                startDimensionY = bodyBackLeftCornerY;
                endDimensionX = bodyBackRightCornerX;
                endDimensionY = bodyBackRightCornerY;
                if (vehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation() >= vehicle.getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation()) {
                    rearDimensionOffset = (tcDimensionOffset / scalingFactor);
                } else {
                    rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (vehicle.getWheelbaseTurningToRearOfVehicleForTurningCircleCalculation() - vehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation());
                }
            }
        } else {
            startDimensionX = vehicleRearBackLeftX;
            startDimensionY = vehicleRearBackLeftY;
            endDimensionX = vehicleRearBackRightX;
            endDimensionY = vehicleRearBackRightY;
            rearDimensionOffset = tcDimensionOffset / scalingFactor;
        }

        startDimensionX = startDimensionX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset;
        startDimensionY = startDimensionY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset;
        endDimensionX = endDimensionX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset;
        endDimensionY = endDimensionY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset;

        //newLine = new Line(startDimensionX, -startDimensionY, endDimensionX, -endDimensionY);

        //drawingObjectsArray.push(newLine);

        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.REARWIDTH)
                                        .setLength(vehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation())
                                        .setStartPoint(startDimensionX, -startDimensionY)
                                        .setEndPoint(endDimensionX, -endDimensionY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

        dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));


        /*
    Dim rearDimensionOffset As Double

    If vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        If vehicle.RearWidthOfVehicleForTurningCircleCalculation > vehicle.RearWidthOfBodyForTurningCircleCalculation Then
            startDimension = vehicleRearBackLeft
            endDimension = vehicleRearBackRight
            If vehicle.WheelbaseTurningToRearOfVehicleForTurningCircleCalculation >= vehicle.WheelbaseTurningToRearOfBodyForTurningCircleCalculation Then
                rearDimensionOffset = DimensionOffset
            Else
                rearDimensionOffset = DimensionOffset + (vehicle.WheelbaseTurningToRearOfBodyForTurningCircleCalculation - vehicle.WheelbaseTurningToRearOfVehicleForTurningCircleCalculation) * PixelsPerScaleUnit
            End If
        Else
            startDimension = bodyBackLeftCorner
            endDimension = bodyBackRightCorner
            If vehicle.WheelbaseTurningToRearOfBodyForTurningCircleCalculation >= vehicle.WheelbaseTurningToRearOfVehicleForTurningCircleCalculation Then
                rearDimensionOffset = DimensionOffset
            Else
                rearDimensionOffset = DimensionOffset + (vehicle.WheelbaseTurningToRearOfVehicleForTurningCircleCalculation - vehicle.WheelbaseTurningToRearOfBodyForTurningCircleCalculation) * PixelsPerScaleUnit
            End If
        End If
    Else
        startDimension = vehicleRearBackLeft
        endDimension = vehicleRearBackRight
        rearDimensionOffset = DimensionOffset
    End If

    'add the rear width dimension
    startDimension.X = startDimension.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset
    startDimension.Y = startDimension.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset
    endDimension.X = endDimension.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset
    endDimension.Y = endDimension.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * rearDimensionOffset

    newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                    UtilDrawingObjectDimension.DimensionEnds.ArrowStartAndEndWithDelimiter, _
                                                    startDimension.X, _
                                                    startDimension.Y, _
                                                    endDimension.X, _
                                                    endDimension.Y, _
                                                    Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(vehicle.RearWidthOfVehicleOrBodyForTurningCircleCalculation), ApplicationGlobals.LengthFormatWithoutUnit))
    DrawingObjects.Add(newDimension)
    */

        //draw the axles
        var startAxleX, startAxleY, endAxleX, endAxleY, endDashX, endDashY, textPositionX, textPositionY;

        //axle 1 front
        startAxleX = cabFrontRightCornerOnWallToWallX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getFrontOverhang();
        startAxleY = cabFrontRightCornerOnWallToWallY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getFrontOverhang();
        startAxleX = startAxleX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.getCabWidth() - vehicle.getTrackWidthFront()) / 2;
        startAxleY = startAxleY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.getCabWidth() - vehicle.getTrackWidthFront()) / 2;
        endAxleX = startAxleX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getTrackWidthFront();
        endAxleY = startAxleY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getTrackWidthFront();
        //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //drawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxleX, startAxleY, steeringAngleOuter, endAxleX, endAxleY, steeringAngleInner, vehicle.getAxlesHolder().getAxle1Front().getNoOfTyresOnAxle(),
        //    vehicle.getAxlesHolder().getAxle1Front().getTyreWidth(), vehicle.getAxlesHolder().getAxle1Front().getTyreRadius());

        /*
    'draw the axles
    Dim startAxle, endAxle, endDash, textPosition As Point

    'axle 1 front
    startAxle.X = cabFrontRightCornerOnWallToWall.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.FrontOverhang * PixelsPerScaleUnit
    startAxle.Y = cabFrontRightCornerOnWallToWall.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.FrontOverhang * PixelsPerScaleUnit
    startAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.CabWidth - vehicle.TrackWidthFront) / 2 * PixelsPerScaleUnit
    startAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (vehicle.CabWidth - vehicle.TrackWidthFront) / 2 * PixelsPerScaleUnit
    endAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.TrackWidthFront * PixelsPerScaleUnit
    endAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.TrackWidthFront * PixelsPerScaleUnit
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
    DrawingObjects.Add(newLine)
    DrawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxle, steeringAngleOuter, endAxle, steeringAngleInner, vehicle.Axles.Axle1FrontNoOfTyres, vehicle.Axles.Axle1FrontTyreWidth, vehicle.Axles.Axle1FrontTyreRadius)
    
    */
        

        startDimensionX = startAxleX + Math.cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getFrontOverhang() + (tcDimensionOffset / scalingFactor));
        startDimensionY = startAxleY + Math.sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getFrontOverhang() + (tcDimensionOffset / scalingFactor));
        endDimensionX = endAxleX + Math.cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getFrontOverhang() + (tcDimensionOffset / scalingFactor));
        endDimensionY = endAxleY + Math.sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getFrontOverhang() + (tcDimensionOffset / scalingFactor));

        //newLine = new Line(startDimensionX, -startDimensionY, endDimensionX, -endDimensionY);

        //drawingObjectsArray.push(newLine);


        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TRACKWIDTHFRONT)
                                        .setLength(vehicle.getTrackWidthFront())
                                        .setStartPoint(startDimensionX, -startDimensionY)
                                        .setEndPoint(endDimensionX, -endDimensionY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

        dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));

        /*
    'add the dimension
    startDimension.X = startAxle.X + Math.Cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.FrontOverhang * PixelsPerScaleUnit + DimensionOffset)
    startDimension.Y = startAxle.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.FrontOverhang * PixelsPerScaleUnit + DimensionOffset)
    endDimension.X = endAxle.X + Math.Cos((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.FrontOverhang * PixelsPerScaleUnit + DimensionOffset)
    endDimension.Y = endAxle.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.FrontOverhang * PixelsPerScaleUnit + DimensionOffset)

    newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                    UtilDrawingObjectDimension.DimensionEnds.ArrowStartAndEndWithDelimiter, _
                                                    startDimension.X, _
                                                    startDimension.Y, _
                                                    endDimension.X, _
                                                    endDimension.Y, _
                                                    Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(vehicle.TrackWidthFront), ApplicationGlobals.LengthFormatWithoutUnit))
    DrawingObjects.Add(newDimension)
    */

        /*
    'steering angle outer
    endDash.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * _LengthOfDashedLineInPixels
    endDash.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * _LengthOfDashedLineInPixels
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endDash.X, endDash.Y, True)
    DrawingObjects.Add(newLine)
    endDash.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter) * Math.PI / 180) * _LengthOfDashedLineInPixels
    endDash.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter) * Math.PI / 180) * _LengthOfDashedLineInPixels
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endDash.X, endDash.Y, True)
    DrawingObjects.Add(newLine)
    textPosition.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter / 2) * Math.PI / 180) * _LengthOfAngleLineInPixels
    textPosition.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfRearAxle - steeringAngleOuter / 2) * Math.PI / 180) * _LengthOfAngleLineInPixels
    newText = New UtilDrawingObjectString(textPosition.X, textPosition.Y, Format(steeringAngleOuter, ApplicationGlobals.WholeNumberFormat) & Convert.ToChar(176))
    DrawingObjects.Add(newText)
    */

        //steering angle inner
        endDashX = endAxleX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (lengthOfDashedLineInPixels / scalingFactor);
        endDashY = endAxleY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * (lengthOfDashedLineInPixels / scalingFactor);
        newLine = new Line(endAxleX, -endAxleY, endDashX, -endDashY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        newLine.setDashed(true);
        drawingObjectsArray.push(newLine);
        endDashX = endAxleX + Math.cos((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner) * Math.PI / 180) * (lengthOfDashedLineInPixels / scalingFactor);
        endDashY = endAxleY + Math.sin((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner) * Math.PI / 180) * (lengthOfDashedLineInPixels / scalingFactor);
        newLine = new Line(endAxleX, -endAxleY, endDashX, -endDashY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        newLine.setDashed(true);
        drawingObjectsArray.push(newLine);
        textPositionX = endAxleX + Math.cos((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner / 2) * Math.PI / 180) * (lengthOfAngleLineInPixels / scalingFactor);
        textPositionY = endAxleY + Math.sin((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner / 2) * Math.PI / 180) * (lengthOfAngleLineInPixels / scalingFactor);

        var steeringAngleInnerDisplayText, textFormattingFunc;
        if (isTrialUser) {
            steeringAngleInnerDisplayText = config.trailUserObfuscationShortText + ' °';
        } else {
            steeringAngleInnerDisplayText = offerManager.getCurMeasurementSysRoundedPercentage(steeringAngleInner);
            textFormattingFunc = offerManager.getUIFormattedDegreeValue;
        }

        newText = new Text(steeringAngleInnerDisplayText, textPositionX, -textPositionY, textFormattingFunc).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newText);


        /*
    'steering angle inner
    endDash.X = endAxle.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * _LengthOfDashedLineInPixels
    endDash.Y = endAxle.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * _LengthOfDashedLineInPixels
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, endAxle.X, endAxle.Y, endDash.X, endDash.Y, True)
    DrawingObjects.Add(newLine)
    endDash.X = endAxle.X + Math.Cos((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner) * Math.PI / 180) * _LengthOfDashedLineInPixels
    endDash.Y = endAxle.Y + Math.Sin((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner) * Math.PI / 180) * _LengthOfDashedLineInPixels
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, endAxle.X, endAxle.Y, endDash.X, endDash.Y, True)
    DrawingObjects.Add(newLine)
    textPosition.X = endAxle.X + Math.Cos((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner / 2) * Math.PI / 180) * _LengthOfAngleLineInPixels
    textPosition.Y = endAxle.Y + Math.Sin((angleBetweenZeroAndCentreOfRearAxle - steeringAngleInner / 2) * Math.PI / 180) * _LengthOfAngleLineInPixels
    newText = New UtilDrawingObjectString(textPosition.X, textPosition.Y, Format(steeringAngleInner, ApplicationGlobals.WholeNumberFormat) & Convert.ToChar(176))
    DrawingObjects.Add(newText)
    */

        //axle 2 front
        if (vehicle.getAxle1FrontToAxle2Front() > 0) {

            startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1FrontToAxle2Front();
            startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1FrontToAxle2Front();
            endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1FrontToAxle2Front();
            endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1FrontToAxle2Front();
            //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);
            //drawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxleX, startAxleY, secondSteeringAngleOuter, endAxleX, endAxleY, secondSteeringAngleInner,
            //                    vehicle.getAxlesHolder().getAxle2Front().getNoOfTyresOnAxle(), vehicle.getAxlesHolder().getAxle2Front().getTyreWidth(), vehicle.getAxlesHolder().getAxle2Front().getTyreRadius());

        }

        /*
    'axle 2 front
    If vehicle.Axle1FrontToAxle2Front > 0 Then

        startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1FrontToAxle2Front * PixelsPerScaleUnit
        startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1FrontToAxle2Front * PixelsPerScaleUnit
        endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1FrontToAxle2Front * PixelsPerScaleUnit
        endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1FrontToAxle2Front * PixelsPerScaleUnit
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
        DrawingObjects.Add(newLine)
        DrawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxle, secondSteeringAngleOuter, endAxle, secondSteeringAngleInner, vehicle.Axles.Axle2FrontNoOfTyres, vehicle.Axles.Axle2FrontTyreWidth, vehicle.Axles.Axle2FrontTyreRadius)

    End If
    */

        //axle 1 rear
        startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle2FrontToAxle1Rear();
        startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle2FrontToAxle1Rear();
        if (vehicle.getTrackWidthFront() !== vehicle.getTrackWidthRear()) {
            startAxleX = startAxleX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getTrackWidthRear() - vehicle.getTrackWidthFront()) / 2;
            startAxleY = startAxleY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.getTrackWidthRear() - vehicle.getTrackWidthFront()) / 2;
        }
        endAxleX = startAxleX + Math.cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getTrackWidthRear();
        endAxleY = startAxleY + Math.sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.getTrackWidthRear();
        //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        //drawingObjectsArray.push(newLine);
        //drawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, vehicle.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle(),
        //        vehicle.getAxlesHolder().getAxle1Rear().getTyreWidth(), vehicle.getAxlesHolder().getAxle1Rear().getTyreRadius());

        /*
    'axle 1 rear
    startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle2FrontToAxle1Rear * PixelsPerScaleUnit
    startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle2FrontToAxle1Rear * PixelsPerScaleUnit
    If vehicle.TrackWidthFront <> vehicle.TrackWidthRear Then
        startAxle.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.TrackWidthRear - vehicle.TrackWidthFront) / 2 * PixelsPerScaleUnit
        startAxle.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (vehicle.TrackWidthRear - vehicle.TrackWidthFront) / 2 * PixelsPerScaleUnit
    End If
    endAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.TrackWidthRear * PixelsPerScaleUnit
    endAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndCentreOfRearAxle * Math.PI / 180) * vehicle.TrackWidthRear * PixelsPerScaleUnit
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
    DrawingObjects.Add(newLine)
    DrawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxle, 0, endAxle, 0, vehicle.Axles.Axle1RearNoOfTyres, vehicle.Axles.Axle1RearTyreWidth, vehicle.Axles.Axle1RearTyreRadius)
    */

        //axle 2 rear
        if (vehicle.getAxle1RearToAxle2Rear() > 0) {
            startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1RearToAxle2Rear();
            startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1RearToAxle2Rear();
            endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1RearToAxle2Rear();
            endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle1RearToAxle2Rear();
            //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);
            //drawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, vehicle.getAxlesHolder().getAxle2Rear().getNoOfTyresOnAxle(),
            //        vehicle.getAxlesHolder().getAxle2Rear().getTyreWidth(), vehicle.getAxlesHolder().getAxle2Rear().getTyreRadius());
        }

        /*
    'axle 2 rear
    If vehicle.Axle1RearToAxle2Rear > 0 Then
        startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1RearToAxle2Rear * PixelsPerScaleUnit
        startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1RearToAxle2Rear * PixelsPerScaleUnit
        endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1RearToAxle2Rear * PixelsPerScaleUnit
        endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle1RearToAxle2Rear * PixelsPerScaleUnit
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
        DrawingObjects.Add(newLine)
        DrawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxle, 0, endAxle, 0, vehicle.Axles.Axle2RearNoOfTyres, vehicle.Axles.Axle2RearTyreWidth, vehicle.Axles.Axle2RearTyreRadius)
    End If
    */

        //axle 3 rear
        if (vehicle.getAxle2RearToAxle3Rear() > 0) {
            startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle2RearToAxle3Rear();
            startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle2RearToAxle3Rear();
            endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle2RearToAxle3Rear();
            endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle2RearToAxle3Rear();
            //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);
            //drawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, vehicle.getAxlesHolder().getAxle3Rear().getNoOfTyresOnAxle(),
            //        vehicle.getAxlesHolder().getAxle3Rear().getTyreWidth(), vehicle.getAxlesHolder().getAxle3Rear().getTyreRadius());
        }

        /*
    'axle 3 rear
    If vehicle.Axle2RearToAxle3Rear > 0 Then
        startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle2RearToAxle3Rear * PixelsPerScaleUnit
        startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle2RearToAxle3Rear * PixelsPerScaleUnit
        endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle2RearToAxle3Rear * PixelsPerScaleUnit
        endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle2RearToAxle3Rear * PixelsPerScaleUnit
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
        DrawingObjects.Add(newLine)
        DrawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxle, 0, endAxle, 0, vehicle.Axles.Axle3RearNoOfTyres, vehicle.Axles.Axle3RearTyreWidth, vehicle.Axles.Axle3RearTyreRadius)
    End If
    */

        //axle 4 rear
        if (vehicle.getAxle3RearToAxle4Rear() > 0) {
            startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle3RearToAxle4Rear();
            startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle3RearToAxle4Rear();
            endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle3RearToAxle4Rear();
            endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.getAxle3RearToAxle4Rear();
            //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //drawingObjectsArray.push(newLine);
            //drawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, vehicle.getAxlesHolder().getAxle4Rear().getNoOfTyresOnAxle(),
            //        vehicle.getAxlesHolder().getAxle4Rear().getTyreWidth(), vehicle.getAxlesHolder().getAxle4Rear().getTyreRadius());
        }

        /*
    'axle 4 rear
    If vehicle.Axle3RearToAxle4Rear > 0 Then
        startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle3RearToAxle4Rear * PixelsPerScaleUnit
        startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle3RearToAxle4Rear * PixelsPerScaleUnit
        endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle3RearToAxle4Rear * PixelsPerScaleUnit
        endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * vehicle.Axle3RearToAxle4Rear * PixelsPerScaleUnit
        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
        DrawingObjects.Add(newLine)
        DrawTyres(angleBetweenZeroAndCentreOfRearAxle, startAxle, 0, endAxle, 0, vehicle.Axles.Axle4RearNoOfTyres, vehicle.Axles.Axle4RearTyreWidth, vehicle.Axles.Axle4RearTyreRadius)
    End If
    */

        startDimensionX = cabFrontRightCornerOnWallToWallX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (tcDimensionOffset / scalingFactor);
        startDimensionY = cabFrontRightCornerOnWallToWallY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (tcDimensionOffset / scalingFactor);

        var frontOverhangRightSideX, frontOverhangRightSideY;

        frontOverhangRightSideX = cabFrontRightCornerOnWallToWallX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle + 90) * Math.PI / 180) * vehicle.getFrontOverhang();
        frontOverhangRightSideY = cabFrontRightCornerOnWallToWallY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle + 90) * Math.PI / 180) * vehicle.getFrontOverhang();

        endDimensionX = frontOverhangRightSideX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (tcDimensionOffset / scalingFactor);
        endDimensionY = frontOverhangRightSideY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (tcDimensionOffset / scalingFactor);

        //newLine = new Line(startDimensionX, -startDimensionY, endDimensionX, -endDimensionY);

        //drawingObjectsArray.push(newLine);

        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.FRONTOVERHANG) 
                                        .setLength(vehicle.getFrontOverhang())
                                        .setStartPoint(startDimensionX, -startDimensionY)
                                        .setEndPoint(endDimensionX, -endDimensionY)
                                        .addCustomDisplayTextFunction(getFrontOverhangDimensionDisplayText)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

        dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));


        startDimensionX = endDimensionX;
        startDimensionY = endDimensionY;

        var wheelbaseTurningEndRightSideX, wheelbaseTurningEndRightSideY;
        wheelbaseTurningEndRightSideX = frontOverhangRightSideX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle + 90) * Math.PI / 180) * vehicle.getWheelbaseTurning();
        wheelbaseTurningEndRightSideY = frontOverhangRightSideY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle + 90) * Math.PI / 180) * vehicle.getWheelbaseTurning();

        endDimensionX = wheelbaseTurningEndRightSideX + Math.cos((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (tcDimensionOffset / scalingFactor);
        endDimensionY = wheelbaseTurningEndRightSideY + Math.sin((180 + angleBetweenZeroAndCentreOfRearAxle) * Math.PI / 180) * (tcDimensionOffset / scalingFactor);

        dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.WHEELBASETURNING)
                                        .setLength(vehicle.getWheelbaseTurning())
                                        .setStartPoint(startDimensionX, -startDimensionY)
                                        .setEndPoint(endDimensionX, -endDimensionY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

        dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));


        var angleBetweenVehicleRearAxleAndHitch, angleBetweenHitchAndTrailerRearAxle;
        var angleBetweenZeroAndHitch, angleBetweenZeroAndTrailerRearAxle;
        var hitchX, hitchY, trailerLeftRearX, trailerLeftRearY, trailerRightRearX, trailerRightRearY, trailerLeftFrontX, trailerLeftFrontY, trailerRightFrontX, trailerRightFrontY;
        var trailerLeftFrontForSwingClearanceX, trailerLeftFrontForSwingClearanceY;
        var trailerBodyLeftRearX, trailerBodyLeftRearY, trailerBodyRightRearX, trailerBodyRightRearY, trailerBodyLeftFrontX, trailerBodyLeftFrontY, trailerBodyRightFrontX, trailerBodyRightFrontY;
        var trailerFifthWheelX, trailerFifthWheelY;
        var trailerBodyWidth, trailerBodyLength;
        var frontOfTrailerToAxle1Rear;
        var angleBetweenCentreOfVehicleRearAxleAndFifthWheel, angleBetweenFifthWheelAndCentreOfTrailerRearAxle;
        var angleBetweenZeroAndCentreOfTrailerRearAxle, fifthWheelOffset;
        if (trailer1 !== null) {
            
            var trailerCoords = {};
            if (trailer1.getType() === config.TRAILER_TYPES.SEMI || trailer1.getType() === config.TRAILER_TYPES.INTERLINK) {

                
                
                

                /*
                Dim angleBetweenCentreOfVehicleRearAxleAndFifthWheel, angleBetweenFifthWheelAndCentreOfTrailerRearAxle As Double
            Dim angleBetweenZeroAndCentreOfTrailerRearAxle, fifthWheelOffset As Double
            Dim trailerLeftRear, trailerRightRear, trailerLeftFront, trailerRightFront, trailerLeftFrontForSwingClearance As Point
            Dim trailerBodyLeftRear, trailerBodyRightRear, trailerBodyLeftFront, trailerBodyRightFront As Point
            Dim trailerFifthWheel As Point
            */
                
                fifthWheelOffset = offerManager.getRigOps().getFifthWheelOffset();

                /*
            If vehicle.FifthWheelIncludedInTare = Web.VehicleInclusionTypes.Yes Then
                fifthWheelOffset = vehicle.FifthWheelOffset
            Else
                fifthWheelOffset = vehicle.Accessories.FifthWheel.HorizontalGap
            End If
            */

                angleBetweenCentreOfVehicleRearAxleAndFifthWheel = Math.asin(Math.abs(fifthWheelOffset) / toCentreOfFifthWheel) * 180 / Math.PI;
                angleBetweenFifthWheelAndCentreOfTrailerRearAxle = Math.asin(trailer1.getWheelbaseTurning() / toCentreOfFifthWheel) * 180 / Math.PI;

                if (fifthWheelOffset < 0) {
                    angleBetweenZeroAndCentreOfTrailerRearAxle = angleBetweenZeroAndCentreOfRearAxle + angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle
                }else{
                    angleBetweenZeroAndCentreOfTrailerRearAxle = angleBetweenZeroAndCentreOfRearAxle - angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle
                }

                /*
            angleBetweenCentreOfVehicleRearAxleAndFifthWheel = Math.Asin(Math.Abs(fifthWheelOffset) / toCentreOfFifthWheel) * 180 / Math.PI
            angleBetweenFifthWheelAndCentreOfTrailerRearAxle = Math.Asin(trailer1.WheelbaseTurning / toCentreOfFifthWheel) * 180 / Math.PI

            If fifthWheelOffset < 0 Then
                angleBetweenZeroAndCentreOfTrailerRearAxle = angleBetweenZeroAndCentreOfRearAxle + angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle
            Else
                angleBetweenZeroAndCentreOfTrailerRearAxle = angleBetweenZeroAndCentreOfRearAxle - angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle
            End If
                */

                turningRadiusInnerMeetsOverallWidthX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner; 
                turningRadiusInnerMeetsOverallWidthY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner;


                trailerTransformation = {};
                trailerTransformation.originX = centreOfRadiusX;
                trailerTransformation.originY = centreOfRadiusY;
                trailerTransformation.anchorX = ((curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheelOffset)) - trailer1.getFrontOverhang() + trailer1.getLength() - trailer1.getWheelbaseTurningToRearOfTrailer();
                trailerTransformation.anchorY = 0;
                trailerTransformation.angle = -(90 - angleBetweenZeroAndCentreOfTrailerRearAxle);
                trailerTransformation.referencePointX = turningRadiusInnerMeetsOverallWidthX;
                trailerTransformation.referencePointY = turningRadiusInnerMeetsOverallWidthY;

                /*
            turningRadiusInnerMeetsOverallWidth.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
            turningRadiusInnerMeetsOverallWidth.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
            */

                if (trailer1.getAccessoryHolder().getBody() !== null || trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer1))) {

                    

                    if (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer1))) {
                        trailerBodyWidth = trailer1.getOverallWidth();
                        trailerBodyLength = trailer1.getOverallLengthInclAccessories();
                    }else{
                        trailerBodyWidth = trailer1.getAccessoryHolder().getBody().getActualWidth();
                        trailerBodyLength = trailer1.getAccessoryHolder().getBody().getActualLength();
                    }
                    /*
                    Dim trailerBodyWidth, trailerBodyLength As Double

                    If trailer1.Source = Web.SourceTypes.Standard Then
                    trailerBodyWidth = trailer1.OverallWidth
                    trailerBodyLength = trailer1.OverallLengthInclAccessories
                    Else
                    trailerBodyWidth = trailer1.Accessories.Body.ActualWidth
                    trailerBodyLength = trailer1.Accessories.Body.ActualLength
                    End If
                    */

                    //left back corner of the body
                    trailerBodyLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getWheelbaseTurningToRearOfBody();
                    trailerBodyLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getWheelbaseTurningToRearOfBody();
                    
                    if (trailer1.getAccessoryHolder().getBody() !== null && trailer1.getOverallWidth() > trailer1.getAccessoryHolder().getBody().getActualWidth()) {
                        trailerBodyLeftRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getAccessoryHolder().getBody().getActualWidth()) / 2;
                        trailerBodyLeftRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getAccessoryHolder().getBody().getActualWidth()) / 2;
                    }
                    //right back corner of the body
                    trailerBodyRightRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    trailerBodyRightRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    //right front corner of the body
                    trailerBodyRightFrontX = trailerBodyRightRearX + Math.cos((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyLength;
                    trailerBodyRightFrontY = trailerBodyRightRearY + Math.sin((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyLength;
                    //left front corner of the body
                    trailerBodyLeftFrontX = trailerBodyRightFrontX + Math.cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    trailerBodyLeftFrontY = trailerBodyRightFrontY + Math.sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;

                    /*
                    'left back corner of the body
                    trailerBodyLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                    trailerBodyLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                    If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                    trailerBodyLeftRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                    trailerBodyLeftRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                    End If
                    'right back corner of the body
                    trailerBodyRightRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    trailerBodyRightRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    'right front corner of the body
                    trailerBodyRightFront.X = trailerBodyRightRear.X + Math.Cos((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                    trailerBodyRightFront.Y = trailerBodyRightRear.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                    'left front corner of the body
                    trailerBodyLeftFront.X = trailerBodyRightFront.X + Math.Cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    trailerBodyLeftFront.Y = trailerBodyRightFront.Y + Math.Sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    */

                    ////draw the body
                    //newLine = new Line(trailerBodyLeftRearX, -trailerBodyLeftRearY, trailerBodyRightRearX, -trailerBodyRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyRightRearX, -trailerBodyRightRearY, trailerBodyRightFrontX, -trailerBodyRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyRightFrontX, -trailerBodyRightFrontY, trailerBodyLeftFrontX, -trailerBodyLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyLeftFrontX, -trailerBodyLeftFrontY, trailerBodyLeftRearX, -trailerBodyLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    bodyPresent = true

                    /*
                    'draw the body
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, trailerBodyRightRear.X, trailerBodyRightRear.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightRear.X, trailerBodyRightRear.Y, trailerBodyRightFront.X, trailerBodyRightFront.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightFront.X, trailerBodyRightFront.Y, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, False, True)
                    DrawingObjects.Add(newLine)

                    bodyPresent = True
                        
                        */
                }

                /*
            If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or trailer1.Source = Web.SourceTypes.Standard Then

                

            Else
                
            End If
            */
                trailerLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getWheelbaseTheoreticalEndToRearMostAxle() + trailer1.getRearOverhang());
                trailerLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getWheelbaseTheoreticalEndToRearMostAxle() + trailer1.getRearOverhang());
                trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;
                trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;
                if(trailer1.getAccessoryHolder().getBody() !== null && trailer1.getOverallWidth() < trailer1.getAccessoryHolder().getBody().getActualWidth()) {
                    trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
                    trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
                }
                trailerRightRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerRightRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerRightFrontX = trailerRightRearX + Math.cos((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getOverallLengthInclAccessories();
                trailerRightFrontY = trailerRightRearY + Math.sin((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getOverallLengthInclAccessories();
                trailerLeftFrontX = trailerRightFrontX + Math.cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerLeftFrontY = trailerRightFrontY + Math.sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerLeftFrontForSwingClearanceX = trailerLeftFrontX + Math.cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;
                trailerLeftFrontForSwingClearanceY = trailerLeftFrontY + Math.sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;

                /*
            trailerLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
            trailerLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
            trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
            trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
            If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth < trailer1.Accessories.Body.ActualWidth Then
                trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
                trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
            End If
            trailerRightRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            trailerRightRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            trailerRightFront.X = trailerRightRear.X + Math.Cos((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.OverallLengthInclAccessories * PixelsPerScaleUnit
            trailerRightFront.Y = trailerRightRear.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.OverallLengthInclAccessories * PixelsPerScaleUnit
            trailerLeftFront.X = trailerRightFront.X + Math.Cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            trailerLeftFront.Y = trailerRightFront.Y + Math.Sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            trailerLeftFrontForSwingClearance.X = trailerLeftFront.X + Math.Cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
            trailerLeftFrontForSwingClearance.Y = trailerLeftFront.Y + Math.Sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
            */


                
                //newLine = new Line(trailerLeftRearX, -trailerLeftRearY, trailerRightRearX, -trailerRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerRightRearX, -trailerRightRearY, trailerRightFrontX, -trailerRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerRightFrontX, -trailerRightFrontY, trailerLeftFrontX, -trailerLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerLeftFrontX, -trailerLeftFrontY, trailerLeftRearX, -trailerLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);


                /*
            'draw the chassis
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftRear.X, trailerLeftRear.Y, trailerRightRear.X, trailerRightRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightRear.X, trailerRightRear.Y, trailerRightFront.X, trailerRightFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightFront.X, trailerRightFront.Y, trailerLeftFront.X, trailerLeftFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftFront.X, trailerLeftFront.Y, trailerLeftRear.X, trailerLeftRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            */

                //drawTrailer(config.OBJECT_TYPES.TURNING_CIRCLE);


                //draw the rear dimension
                if( trailer1.getAccessoryHolder().getBody() !== null){
                    if(trailer1.getOverallWidth() > trailer1.getAccessoryHolder().getBody().getActualWidth() ) {
                        startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        if(trailer1.getWheelbaseTurningToRearOfTrailer() >= trailer1.getWheelbaseTurningToRearOfBody()) {
                            rearDimensionOffset = tcDimensionOffset/scalingFactor;
                        }else{
                            rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer1.getWheelbaseTurningToRearOfBody() - trailer1.getWheelbaseTurningToRearOfTrailer());
                        }
                    }else{
                        startDimensionX = trailerBodyLeftRearX;
                        startDimensionY = trailerBodyLeftRearY;
                        endDimensionX = trailerBodyRightRearX;
                        endDimensionY = trailerBodyRightRearY;
                        if (trailer1.getWheelbaseTurningToRearOfBody() >= trailer1.getWheelbaseTurningToRearOfTrailer()) {
                            rearDimensionOffset = tcDimensionOffset / scalingFactor;
                        } else {
                            rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer1.getWheelbaseTurningToRearOfTrailer() - trailer1.getWheelbaseTurningToRearOfBody());
                        }
                    }
                }else{
                    startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    rearDimensionOffset = tcDimensionOffset / scalingFactor;
                }


                /*
            'draw the rear dimension
            If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                If trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                    startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    If trailer1.WheelbaseTurningToRearOfTrailer >= trailer1.WheelbaseTurningToRearOfBody Then
                        rearDimensionOffset = DimensionOffset
                    Else
                        rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfBody - trailer1.WheelbaseTurningToRearOfTrailer) * PixelsPerScaleUnit
                    End If
                Else
                    startDimension = trailerBodyLeftRear
                    endDimension = trailerBodyRightRear
                    If trailer1.WheelbaseTurningToRearOfBody >= trailer1.WheelbaseTurningToRearOfTrailer Then
                        rearDimensionOffset = DimensionOffset
                    Else
                        rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfTrailer - trailer1.WheelbaseTurningToRearOfBody) * PixelsPerScaleUnit
                    End If
                End If
            Else
                startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                rearDimensionOffset = DimensionOffset
            End If
            */

                //add the rear width dimension
                startDimensionX = startDimensionX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                startDimensionY = startDimensionY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                endDimensionX = endDimensionX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                endDimensionY = endDimensionY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;

                dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TRAILEROVERALLWIDTHINCLUDINGACCESSORIES)
                                        .setLength(trailer1.getOverallWidth())
                                        .setStartPoint(startDimensionX, -startDimensionY)
                                        .setEndPoint(endDimensionX, -endDimensionY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

                dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));
                /*
            'add the rear width dimension
            startDimension.X = startDimension.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            startDimension.Y = startDimension.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            endDimension.X = endDimension.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            endDimension.Y = endDimension.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset

            newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                            UtilDrawingObjectDimension.DimensionEnds.ArrowStartAndEndWithDelimiter, _
                                                            startDimension.X, _
                                                            startDimension.Y, _
                                                            endDimension.X, _
                                                            endDimension.Y, _
                                                            Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1.OverallWidthInclAccessories), ApplicationGlobals.LengthFormatWithoutUnit))
            DrawingObjects.Add(newDimension)
            */

                
                
                if(trailer1.getType() === config.TRAILER_TYPES.SEMI || trailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                    frontOfTrailerToAxle1Rear = trailer1.getFrontOverhang() + trailer1.getKingpinToAxle1Rear();
                    frontOverhang = trailer1.getFrontOverhang();
                }
                
                /*
            Dim frontOfTrailerToAxle1Rear, frontOverhang As Double

            If TypeOf (trailer1) Is FileLoadingTrailerSemi Then
                With DirectCast(trailer1, FileLoadingTrailerSemi)
                    frontOfTrailerToAxle1Rear = .FrontOverhang + .KingpinToAxle1Rear
                    frontOverhang = .FrontOverhang
                End With
            ElseIf TypeOf (trailer1) Is FileLoadingTrailerInterlink Then
                With DirectCast(trailer1, FileLoadingTrailerInterlink)
                    frontOfTrailerToAxle1Rear = .FrontOverhang + .KingpinToAxle1Rear
                    frontOverhang = .FrontOverhang
                End With
            End If
            */
                    
                //draw the fifth wheel
                //trailerFifthWheelX = trailerLeftFrontX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth() / 2;
                //trailerFifthWheelY = trailerLeftFrontY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth() / 2;
                //trailerFifthWheelX = trailerFifthWheelX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang;
                //trailerFifthWheelY = trailerFifthWheelY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang;
                
                //newCircle = new Circle(trailerFifthWheelX, -trailerFifthWheelY, fifthWheelRadius, CIRCLE_TYPE.OTHER, true);
                //drawingObjectsArray.push(newCircle);

                /*

            'draw the fifth wheel
            trailerFifthWheel.X = trailerLeftFront.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth / 2 * PixelsPerScaleUnit
            trailerFifthWheel.Y = trailerLeftFront.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth / 2 * PixelsPerScaleUnit
            trailerFifthWheel.X = trailerFifthWheel.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang * PixelsPerScaleUnit
            trailerFifthWheel.Y = trailerFifthWheel.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang * PixelsPerScaleUnit
            newCircle = New UtilDrawingObjectCircle(trailerFifthWheel.X, trailerFifthWheel.Y, _FifthWheelRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other)
            DrawingObjects.Add(newCircle)
            */
                //newCircle = new Circle(trailerFifthWheelX, -trailerFifthWheelY, trailer1.getFrontSwingClearanceRadius(), CIRCLE_TYPE.OTHER, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //newCircle.setDashed(true);
                //newCircle.setDashLength(10);
                //newCircle.setDrawingOrder(1000);
                //if (offerManager.getNotes().getStringTrackerObjectById(config.NOTE_ID.SWING_CLEARANCE) !== null) {
                //    newCircle.setShowAsWarning(true);
                //}
                //drawingObjectsArray.push(newCircle);
                /*

            'draw the swing clearance arc
            newCircle = New UtilDrawingObjectCircle(trailerFifthWheel.X, trailerFifthWheel.Y, trailer1.FrontSwingClearanceRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other, True, swingClearanceWarning)
            DrawingObjects.Add(newCircle)
            */


                //axle 1 rear
                startAxleX = trailerRightFrontX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                startAxleY = trailerRightFrontY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                endAxleX = startAxleX + Math.cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * (trailer1.getChassisWidth() + (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2);
                endAxleY = startAxleY + Math.sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * (trailer1.getChassisWidth() + (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2);
                startAxleX = startAxleX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2;
                startAxleY = startAxleY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2;
                
                //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                
                //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle(),
                //    trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle1Rear().getTyreRadius());
                /*
            'axle 1 rear
            startAxle.X = trailerRightFront.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
            startAxle.Y = trailerRightFront.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
            endAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            endAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            startAxle.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            startAxle.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
            DrawingObjects.Add(newLine)
            DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle1RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
                */

                //axle 2 rear
                if(trailer1.getAxle1RearToAxle2Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();

                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle2Rear().getNoOfTyresOnAxle(),
                    //        trailer1.getAxlesHolder().getAxle2Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle2Rear().getTyreRadius());
                }

                /*
            'axle 2 rear
            If trailer1.Axle1RearToAxle2Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle2RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
            */
                
                //axle 3 rear
                if(trailer1.getAxle2RearToAxle3Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();

                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle3Rear().getNoOfTyresOnAxle(),
                    //        trailer1.getAxlesHolder().getAxle3Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle3Rear().getTyreRadius());
                }

                /*
                'axle 3 rear
            If trailer1.Axle2RearToAxle3Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle3RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
            */

                //axle 4 rear
                if(trailer1.getAxle3RearToAxle4Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle4Rear().getNoOfTyresOnAxle(),
                    //        trailer1.getAxlesHolder().getAxle4Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle4Rear().getTyreRadius());
                    
                }

                /*
            'axle 4 rear
            If trailer1.Axle3RearToAxle4Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle4RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
            */

                if(trailer1TurningRadiusInner > 0) {

                    //trailer turning radius inner
                    dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TRAILERTURNINGRADIUSINNER)
                                        .setLength(trailer1TurningRadiusInner)
                                        .setStartPoint(centreOfRadiusX, -centreOfRadiusY)
                                        .setEndPoint(turningRadiusInnerMeetsOverallWidthX, -turningRadiusInnerMeetsOverallWidthY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

                    newDimension = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId());

                    if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_INNER) !== null) {
                        newDimension.setShowAsWarning(true);
                    }

                    dimensions.push(newDimension);

                }

                /*
            If trailer1TurningRadiusInner > 0 Then

                'trailer turning radius inner
                If Math.Round(trailer1TurningRadiusInner, 0) < _TurningRadiusInnerMin Then
                    isSelected = True
                Else
                    isSelected = False
                End If

                newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                                UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                                centreOfRadius.X, _
                                                                centreOfRadius.Y, _
                                                                turningRadiusInnerMeetsOverallWidth.X, _
                                                                turningRadiusInnerMeetsOverallWidth.Y, _
                                                                Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1TurningRadiusInner), ApplicationGlobals.LengthFormatWithoutUnit), _
                                                                isSelected)
                DrawingObjects.Add(newDimension)

            End If
            */
            } else if (trailer1.getType() === config.TRAILER_TYPES.DRAWBAR) { 
                /*
        ElseIf TypeOf (trailer1) Is FileLoadingTrailerDrawbar Then
*/
                

                var angleBetweenHitchAndTrailerFrontAxle, angleBetweenTrailerFrontAxleAndTrailerRearAxle;
                var angleBetweenZeroAndTrailerFrontAxle;
                var turnTableCentreX, turnTableCentreY, turnTableLeftFrontX, turnTableLeftFrontY, turnTableLeftRearX, turnTableLeftRearY, turnTableRightRearX, turnTableRightRearY, turnTableRightFrontX, turnTableRightFrontY;
                // var trailerLeftRearX, trailerLeftRearY, trailerRightRearX, trailerRightRearY, trailerLeftFrontX, trailerLeftFrontY, trailerRightFrontX, trailerRightFrontY;
                // var trailerBodyLeftRearX, trailerBodyLeftRearY, trailerBodyRightRearX, trailerBodyRightRearY, trailerBodyLeftFrontX, trailerBodyLeftFrontY, trailerBodyRightFrontX, trailerBodyRightFrontY;

                angleBetweenVehicleRearAxleAndHitch = Math.acos(toCentreOfVehicleRearAxle / toCentreOfHitch) * 180 / Math.PI;
                angleBetweenHitchAndTrailerFrontAxle = Math.acos(toCentreOfTrailer1FrontAxle / toCentreOfHitch) * 180 / Math.PI;
                angleBetweenTrailerFrontAxleAndTrailerRearAxle = Math.acos(toCentreOfTrailer1RearAxle / toCentreOfTrailer1FrontAxle) * 180 / Math.PI;
                angleBetweenZeroAndHitch = angleBetweenZeroAndCentreOfRearAxle + angleBetweenVehicleRearAxleAndHitch;
                angleBetweenZeroAndTrailerFrontAxle = angleBetweenZeroAndCentreOfRearAxle + angleBetweenVehicleRearAxleAndHitch + angleBetweenHitchAndTrailerFrontAxle;
                angleBetweenZeroAndTrailerRearAxle = angleBetweenZeroAndTrailerFrontAxle + angleBetweenTrailerFrontAxleAndTrailerRearAxle;


                /*
            Dim myTrailer As FileLoadingTrailerDrawbar = trailer1
            Dim angleBetweenVehicleRearAxleAndHitch, angleBetweenHitchAndTrailerFrontAxle, angleBetweenTrailerFrontAxleAndTrailerRearAxle As Double
            Dim angleBetweenZeroAndHitch, angleBetweenZeroAndTrailerFrontAxle, angleBetweenZeroAndTrailerRearAxle As Double
            Dim turnTableCentre, hitch, turnTableLeftFront, turnTableLeftRear, turnTableRightRear, turnTableRightFront As Point
            Dim trailerLeftRear, trailerRightRear, trailerLeftFront, trailerRightFront As Point
            Dim trailerBodyLeftRear, trailerBodyRightRear, trailerBodyLeftFront, trailerBodyRightFront As Point

            angleBetweenVehicleRearAxleAndHitch = Math.Acos(toCentreOfVehicleRearAxle / toCentreOfHitch) * 180 / Math.PI
            angleBetweenHitchAndTrailerFrontAxle = Math.Acos(toCentreOfTrailer1FrontAxle / toCentreOfHitch) * 180 / Math.PI
            angleBetweenTrailerFrontAxleAndTrailerRearAxle = Math.Acos(toCentreOfTrailer1RearAxle / toCentreOfTrailer1FrontAxle) * 180 / Math.PI
            angleBetweenZeroAndHitch = angleBetweenZeroAndCentreOfRearAxle + angleBetweenVehicleRearAxleAndHitch
            angleBetweenZeroAndTrailerFrontAxle = angleBetweenZeroAndCentreOfRearAxle + angleBetweenVehicleRearAxleAndHitch + angleBetweenHitchAndTrailerFrontAxle
            angleBetweenZeroAndTrailerRearAxle = angleBetweenZeroAndTrailerFrontAxle + angleBetweenTrailerFrontAxleAndTrailerRearAxle
*/

                //'draw hitch
                hitchX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch;
                hitchY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch;
                //newCircle = new Circle(hitchX, -hitchY, hitchRadius, CIRCLE_TYPE.OTHER, true);
                //drawingObjectsArray.push(newCircle);

                /*
            'draw hitch
            hitch.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch * PixelsPerScaleUnit
            hitch.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch * PixelsPerScaleUnit
            newCircle = New UtilDrawingObjectCircle(hitch.X, hitch.Y, _HitchRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other)
            DrawingObjects.Add(newCircle)
*/
                //draw the swing clearance arc
                newCircle = new Circle(hitchX, -hitchY, vehicle.getRearSwingClearanceRadius(), CIRCLE_TYPE.OTHER, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                newCircle.setDashed(true);
                newCircle.setDashLength(10);
                newCircle.setDrawingOrder(1000);
                if (offerManager.getNotes().getStringTrackerObjectById(config.NOTE_ID.SWING_CLEARANCE) !== null) {
                    newCircle.setShowAsWarning(true);
                }
                drawingObjectsArray.push(newCircle);

                /*
            'draw the swing clearance arc
            newCircle = New UtilDrawingObjectCircle(hitch.X, hitch.Y, vehicle.RearSwingClearanceRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other, True, swingClearanceWarning)
            DrawingObjects.Add(newCircle)
*/

                //'draw turntable
                turnTableCentreX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * toCentreOfTrailer1FrontAxle;
                turnTableCentreY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * toCentreOfTrailer1FrontAxle;
                //'turntable left front
                turnTableLeftFrontX = turnTableCentreX + Math.cos((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (trailer1.getFrontOverhang() + trailer1.getAxle1FrontToWheelbaseTheoreticalStart());
                turnTableLeftFrontY = turnTableCentreY + Math.sin((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (trailer1.getFrontOverhang() + trailer1.getAxle1FrontToWheelbaseTheoreticalStart());
                turnTableLeftFrontX = turnTableLeftFrontX + Math.cos(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * trailer1.getChassisWidth() / 2;
                turnTableLeftFrontY = turnTableLeftFrontY + Math.sin(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * trailer1.getChassisWidth() / 2;
                //'turntable left rear
                turnTableLeftRearX = turnTableLeftFrontX + Math.cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getTurntableLength();
                turnTableLeftRearY = turnTableLeftFrontY + Math.sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getTurntableLength();
                //'turntable right rear
                turnTableRightRearX = turnTableLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                turnTableRightRearY = turnTableLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                //'turntable right front
                turnTableRightFrontX = turnTableRightRearX + Math.cos((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getTurntableLength();
                turnTableRightFrontY = turnTableRightRearY + Math.sin((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getTurntableLength();

                /*
            'draw turntable
            turnTableCentre.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * toCentreOfTrailer1FrontAxle * PixelsPerScaleUnit
            turnTableCentre.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * toCentreOfTrailer1FrontAxle * PixelsPerScaleUnit
            'turntable left front
            turnTableLeftFront.X = turnTableCentre.X + Math.Cos((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (myTrailer.FrontOverhang + myTrailer.Axle1FrontToWheelbaseTheoreticalStart) * PixelsPerScaleUnit
            turnTableLeftFront.Y = turnTableCentre.Y + Math.Sin((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (myTrailer.FrontOverhang + myTrailer.Axle1FrontToWheelbaseTheoreticalStart) * PixelsPerScaleUnit
            turnTableLeftFront.X = turnTableLeftFront.X + Math.Cos(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * trailer1.ChassisWidth / 2 * PixelsPerScaleUnit
            turnTableLeftFront.Y = turnTableLeftFront.Y + Math.Sin(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * trailer1.ChassisWidth / 2 * PixelsPerScaleUnit
            'turntable left rear
            turnTableLeftRear.X = turnTableLeftFront.X + Math.Cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.TurnTableLength * PixelsPerScaleUnit
            turnTableLeftRear.Y = turnTableLeftFront.Y + Math.Sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.TurnTableLength * PixelsPerScaleUnit
            'turntable right rear
            turnTableRightRear.X = turnTableLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            turnTableRightRear.Y = turnTableLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            'turntable right front
            turnTableRightFront.X = turnTableRightRear.X + Math.Cos((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.TurnTableLength * PixelsPerScaleUnit
            turnTableRightFront.Y = turnTableRightRear.Y + Math.Sin((90 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.TurnTableLength * PixelsPerScaleUnit
*/
                ////draw the turntable
                //newLine = new Line(turnTableLeftFrontX, -turnTableLeftFrontY, turnTableLeftRearX, -turnTableLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(turnTableLeftRearX, -turnTableLeftRearY, turnTableRightRearX, -turnTableRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(turnTableRightRearX, -turnTableRightRearY, turnTableRightFrontX, -turnTableRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(turnTableRightFrontX, -turnTableRightFrontY, turnTableLeftFrontX, -turnTableLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newCircle = new Circle(turnTableCentreX, -turnTableCentreY, turntableRadius, CIRCLE_TYPE.OTHER, true);
                //drawingObjectsArray.push(newCircle);

                /*
            'draw the turntable
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, turnTableLeftFront.X, turnTableLeftFront.Y, turnTableLeftRear.X, turnTableLeftRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, turnTableLeftRear.X, turnTableLeftRear.Y, turnTableRightRear.X, turnTableRightRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, turnTableRightRear.X, turnTableRightRear.Y, turnTableRightFront.X, turnTableRightFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, turnTableRightFront.X, turnTableRightFront.Y, turnTableLeftFront.X, turnTableLeftFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newCircle = New UtilDrawingObjectCircle(turnTableCentre.X, turnTableCentre.Y, _TurnTableRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other)
            DrawingObjects.Add(newCircle)
*/

                newCircle = new Circle(turnTableCentreX, -turnTableCentreY, trailer1.getFrontSwingClearanceRadius(), CIRCLE_TYPE.OTHER, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                newCircle.setDashed(true);
                newCircle.setDashLength(10);
                newCircle.setDrawingOrder(1000);
                if (offerManager.getNotes().getStringTrackerObjectById(config.NOTE_ID.SWING_CLEARANCE) !== null) {
                    newCircle.setShowAsWarning(true);
                }
                drawingObjectsArray.push(newCircle);
                
                /*
            'draw the swing clearance arc
            newCircle = New UtilDrawingObjectCircle(turnTableCentre.X, turnTableCentre.Y, trailer1.FrontSwingClearanceRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other, True, swingClearanceWarning)
            DrawingObjects.Add(newCircle)
*/

                ////'draw the drawbar protrusion
                //newLine = new Line(turnTableLeftFrontX, -turnTableLeftFrontY, hitchX, -hitchY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(turnTableRightFrontX, -turnTableRightFrontY, hitchX, -hitchY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);

                /*
            'draw the drawbar protrusion
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, turnTableLeftFront.X, turnTableLeftFront.Y, hitch.X, hitch.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, turnTableRightFront.X, turnTableRightFront.Y, hitch.X, hitch.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
*/

                //'axle 1 front
                startAxleX = turnTableLeftFrontX + Math.cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getFrontOverhang();
                startAxleY = turnTableLeftFrontY + Math.sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getFrontOverhang();
                endAxleX = startAxleX + Math.cos((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (trailer1.getChassisWidth() + (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2);
                endAxleY = startAxleY + Math.sin((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (trailer1.getChassisWidth() + (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2);
                startAxleX = startAxleX + Math.cos(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2;
                startAxleY = startAxleY + Math.sin(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2;
                
                
                //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);

                //drawTyres(angleBetweenZeroAndTrailerFrontAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle1Front().getNoOfTyresOnAxle(),
                //        trailer1.getAxlesHolder().getAxle1Front().getTyreWidth(), trailer1.getAxlesHolder().getAxle1Front().getTyreRadius());

                /*
            'axle 1 front
            startAxle.X = turnTableLeftFront.X + Math.Cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.FrontOverhang * PixelsPerScaleUnit
            startAxle.Y = turnTableLeftFront.Y + Math.Sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.FrontOverhang * PixelsPerScaleUnit
            endAxle.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            endAxle.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            startAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            startAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndTrailerFrontAxle * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
            DrawingObjects.Add(newLine)
            DrawTyres(angleBetweenZeroAndTrailerFrontAxle, startAxle, 0, endAxle, 0, myTrailer.Axle1FrontNoOfTyres, myTrailer.TyreWidth, myTrailer.TyreRadius)
*/

                //'axle 2 front
                if (trailer1.getAxle1FrontToAxle2Front() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getAxle1FrontToAxle2Front();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getAxle1FrontToAxle2Front();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getAxle1FrontToAxle2Front();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * trailer1.getAxle1FrontToAxle2Front();
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndTrailerFrontAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle2Front().getNoOfTyresOnAxle(),
                    //        trailer1.getAxlesHolder().getAxle2Front().getTyreWidth(), trailer1.getAxlesHolder().getAxle2Front().getTyreRadius());
                }

                /*
            'axle 2 front
            If myTrailer.Axle1FrontToAxle2Front > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.Axle1FrontToAxle2Front * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.Axle1FrontToAxle2Front * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.Axle1FrontToAxle2Front * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerFrontAxle) * Math.PI / 180) * myTrailer.Axle1FrontToAxle2Front * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndTrailerFrontAxle, startAxle, 0, endAxle, 0, myTrailer.Axle2FrontNoOfTyres, myTrailer.TyreWidth, myTrailer.TyreRadius)
            End If
*/

                


                turningRadiusInnerMeetsOverallWidthX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner;
                turningRadiusInnerMeetsOverallWidthY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner;

                trailerTransformation = {};
                trailerTransformation.originX = centreOfRadiusX;
                trailerTransformation.originY = centreOfRadiusY;
                trailerTransformation.anchorX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset()) + trailer1.getDrawbarLength() + trailer1.getLength() - trailer1.getWheelbaseTurningToRearOfTrailer();
                trailerTransformation.anchorY = 0;
                trailerTransformation.angle = -(90 - angleBetweenZeroAndTrailerRearAxle);
                trailerTransformation.referencePointX = turningRadiusInnerMeetsOverallWidthX;
                trailerTransformation.referencePointY = turningRadiusInnerMeetsOverallWidthY;



                

                /*
            turningRadiusInnerMeetsOverallWidth.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
            turningRadiusInnerMeetsOverallWidth.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
*/



                /*
            If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or trailer1.Source = Web.SourceTypes.Standard Then
*/
                if (trailer1.getAccessoryHolder().getBody() !== null || trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer1))) {

                    // var trailerBodyWidth, trailerBodyLength;
                    /*
                    Dim trailerBodyWidth, trailerBodyLength As Double
*/
                    if (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer1))) {
                        trailerBodyWidth = trailer1.getOverallWidth();
                        //trailerBodyLength = trailer1.getOverallLengthInclAccessories() - trailer1.getDrawbarLength();
                        trailerBodyLength = trailer1.getOverallLengthExclAccessoriesAndDrawbar();
                    } else {
                        trailerBodyWidth = trailer1.getAccessoryHolder().getBody().getActualWidth();
                        trailerBodyLength = trailer1.getAccessoryHolder().getBody().getActualLength();
                    }
                    /*
                    If trailer1.Source = Web.SourceTypes.Standard Then
                        trailerBodyWidth = trailer1.OverallWidth
                        trailerBodyLength = trailer1.OverallLengthInclAccessories
                    Else
                        trailerBodyWidth = trailer1.Accessories.Body.ActualWidth
                        trailerBodyLength = trailer1.Accessories.Body.ActualLength
                    End If
*/

                    //'left back corner of the body
                    trailerBodyLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getWheelbaseTurningToRearOfBody();
                    trailerBodyLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getWheelbaseTurningToRearOfBody();
                    if( trailer1.getAccessoryHolder().getBody() !== null && trailer1.getOverallWidth() > trailer1.getAccessoryHolder().getBody().getActualWidth()) {
                        trailerBodyLeftRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getAccessoryHolder().getBody().getActualWidth()) / 2;
                        trailerBodyLeftRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getAccessoryHolder().getBody().getActualWidth()) / 2;
                    }
                    //'right back corner of the body
                    trailerBodyRightRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    trailerBodyRightRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    //'right front corner of the body
                    trailerBodyRightFrontX = trailerBodyRightRearX + Math.cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength;
                    trailerBodyRightFrontY = trailerBodyRightRearY + Math.sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength;
                    //'left front corner of the body
                    trailerBodyLeftFrontX = trailerBodyRightFrontX + Math.cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    trailerBodyLeftFrontY = trailerBodyRightFrontY + Math.sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;

                    /*
                    'left back corner of the body
                    trailerBodyLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                    trailerBodyLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                    If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                        trailerBodyLeftRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                        trailerBodyLeftRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                    End If
                    'right back corner of the body
                    trailerBodyRightRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    trailerBodyRightRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    'right front corner of the body
                    trailerBodyRightFront.X = trailerBodyRightRear.X + Math.Cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                    trailerBodyRightFront.Y = trailerBodyRightRear.Y + Math.Sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                    'left front corner of the body
                    trailerBodyLeftFront.X = trailerBodyRightFront.X + Math.Cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    trailerBodyLeftFront.Y = trailerBodyRightFront.Y + Math.Sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
*/

                    ////'draw the body
                    //newLine = new Line(trailerBodyLeftRearX, -trailerBodyLeftRearY, trailerBodyRightRearX, -trailerBodyRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyRightRearX, -trailerBodyRightRearY, trailerBodyRightFrontX, -trailerBodyRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyRightFrontX, -trailerBodyRightFrontY, trailerBodyLeftFrontX, -trailerBodyLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyLeftFrontX, -trailerBodyLeftFrontY, trailerBodyLeftRearX, -trailerBodyLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //bodyPresent = true;


                    /*
                    'draw the body
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, trailerBodyRightRear.X, trailerBodyRightRear.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightRear.X, trailerBodyRightRear.Y, trailerBodyRightFront.X, trailerBodyRightFront.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightFront.X, trailerBodyRightFront.Y, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, False, True)
                    DrawingObjects.Add(newLine)

                    bodyPresent = True
                    */
                }
                /*
            End If
*/

                trailerLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getWheelbaseTheoreticalEndToRearMostAxle() + trailer1.getRearOverhang());
                trailerLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getWheelbaseTheoreticalEndToRearMostAxle() + trailer1.getRearOverhang());
                trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;
                trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;
                if (trailer1.getAccessoryHolder().getBody() !== null && trailer1.getOverallWidth() < trailer1.getAccessoryHolder().getBody().getActualWidth()) {
                    trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
                    trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
                }
                trailerRightRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerRightRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerRightFrontX = trailerRightRearX + Math.cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getOverallLengthExclAccessoriesAndDrawbar();
                trailerRightFrontY = trailerRightRearY + Math.sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getOverallLengthExclAccessoriesAndDrawbar();
                trailerLeftFrontX = trailerRightFrontX + Math.cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerLeftFrontY = trailerRightFrontY + Math.sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();

                /*
            trailerLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
            trailerLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
            trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
            trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
            If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth < trailer1.Accessories.Body.ActualWidth Then
                trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
                trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
            End If
            trailerRightRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            trailerRightRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            trailerRightFront.X = trailerRightRear.X + Math.Cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * myTrailer.OverallLengthExclAccessoriesAndDrawbar * PixelsPerScaleUnit
            trailerRightFront.Y = trailerRightRear.Y + Math.Sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * myTrailer.OverallLengthExclAccessoriesAndDrawbar * PixelsPerScaleUnit
            trailerLeftFront.X = trailerRightFront.X + Math.Cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
            trailerLeftFront.Y = trailerRightFront.Y + Math.Sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
*/


                ////'draw the chassis
                //newLine = new Line(trailerLeftRearX, -trailerLeftRearY, trailerRightRearX, -trailerRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerRightRearX, -trailerRightRearY, trailerRightFrontX, -trailerRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);    
                //newLine = new Line(trailerRightFrontX, -trailerRightFrontY, trailerLeftFrontX, -trailerLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerLeftFrontX, -trailerLeftFrontY, trailerLeftRearX, -trailerLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);

                /*
            'draw the chassis
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftRear.X, trailerLeftRear.Y, trailerRightRear.X, trailerRightRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightRear.X, trailerRightRear.Y, trailerRightFront.X, trailerRightFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightFront.X, trailerRightFront.Y, trailerLeftFront.X, trailerLeftFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftFront.X, trailerLeftFront.Y, trailerLeftRear.X, trailerLeftRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
*/
                //drawTrailer(trailerCoords, angleBetweenZeroAndTrailerRearAxle);
                //'draw the rear dimension
                if (trailer1.getAccessoryHolder().getBody() !== null) {
                    if (trailer1.getOverallWidth() > trailer1.getAccessoryHolder().getBody().getActualWidth()) {
                        startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        if (trailer1.getWheelbaseTurningToRearOfTrailer() >= trailer1.getWheelbaseTurningToRearOfBody()) {
                            rearDimensionOffset = tcDimensionOffset / scalingFactor;
                        } else {
                            rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer1.getWheelbaseTurningToRearOfBody() - trailer1.getWheelbaseTurningToRearOfTrailer());
                        }
                    }else{   
                        startDimensionX = trailerBodyLeftRearX;
                        startDimensionY = trailerBodyLeftRearY;
                        endDimensionX = trailerBodyRightRearX;
                        endDimensionY = trailerBodyRightRearY;
                        if (trailer1.getWheelbaseTurningToRearOfBody() >= trailer1.getWheelbaseTurningToRearOfTrailer()) {
                            rearDimensionOffset = tcDimensionOffset / scalingFactor;
                        } else {
                            rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer1.getWheelbaseTurningToRearOfTrailer() - trailer1.getWheelbaseTurningToRearOfBody());
                        }
                    }
                }else {
                    startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    rearDimensionOffset = tcDimensionOffset / scalingFactor;
                }

                /*
            'draw the rear dimension
            If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                If trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                    startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    If trailer1.WheelbaseTurningToRearOfTrailer >= trailer1.WheelbaseTurningToRearOfBody Then
                        rearDimensionOffset = DimensionOffset
                    Else
                        rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfBody - trailer1.WheelbaseTurningToRearOfTrailer) * PixelsPerScaleUnit
                    End If
                Else
                    startDimension = trailerBodyLeftRear
                    endDimension = trailerBodyRightRear
                    If trailer1.WheelbaseTurningToRearOfBody >= trailer1.WheelbaseTurningToRearOfTrailer Then
                        rearDimensionOffset = DimensionOffset
                    Else
                        rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfTrailer - trailer1.WheelbaseTurningToRearOfBody) * PixelsPerScaleUnit
                    End If
                End If
            Else
                startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                rearDimensionOffset = DimensionOffset
            End If
*/
                frontOfTrailerToAxle1Rear = trailer1.getFrontOverhang() + trailer1.getAxle1FrontToWheelbaseTheoreticalStart() + trailer1.getWheelbaseTheoretical() +
                    trailer1.getWheelbaseTheoreticalEndToRearMostAxle() - (trailer1.getAxle3RearToAxle4Rear() + trailer1.getAxle2RearToAxle3Rear() + trailer1.getAxle1RearToAxle2Rear());

                /*
            Dim frontOfTrailerToAxle1Rear As Double = myTrailer.FrontOverhang + myTrailer.Axle1FrontToWheelbaseTheoreticalStart + myTrailer.WheelbaseTheoretical + _
                                                        myTrailer.WheelbaseTheoreticalEndToRearMostAxle - (myTrailer.Axle3RearToAxle4Rear + myTrailer.Axle2RearToAxle3Rear + myTrailer.Axle1RearToAxle2Rear)
*/

                //'axle 1 rear
                startAxleX = trailerRightFrontX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                startAxleY = trailerRightFrontY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                endAxleX = startAxleX + Math.cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.getChassisWidth() + (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2);
                endAxleY = startAxleY + Math.sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.getChassisWidth() + (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2);
                startAxleX = startAxleX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2;
                startAxleY = startAxleY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (config.gapBetweenTyresAndChassis + trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth() / 2 + (trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.gapBetweenTyresInMm / 2) / 2;
                
                //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);

                //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle(),
                //        trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle1Rear().getTyreRadius());


                /*
            'axle 1 rear
            startAxle.X = trailerRightFront.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
            startAxle.Y = trailerRightFront.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
            endAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            endAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            startAxle.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            startAxle.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
            DrawingObjects.Add(newLine)
            DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle1RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
*/


                //'axle 2 rear
                if( trailer1.getAxle1RearToAxle2Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle2Rear().getNoOfTyresOnAxle(),
                    //        trailer1.getAxlesHolder().getAxle2Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle2Rear().getTyreRadius());
                }

                /*
            'axle 2 rear
            If trailer1.Axle1RearToAxle2Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle2RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
*/

                //'axle 3 rear
                if (trailer1.getAxle2RearToAxle3Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle3Rear().getNoOfTyresOnAxle(),
                    //        trailer1.getAxlesHolder().getAxle3Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle3Rear().getTyreRadius());
                }
                
                /*
            'axle 3 rear
            If trailer1.Axle2RearToAxle3Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle3RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
*/


                //'axle 4 rear
                if( trailer1.getAxle3RearToAxle4Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle4Rear().getNoOfTyresOnAxle(),
                    //        trailer1.getAxlesHolder().getAxle4Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle4Rear().getTyreRadius());
                }

                /*
            'axle 4 rear
            If trailer1.Axle3RearToAxle4Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle4RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
*/
                //'add the rear width dimension
                startDimensionX = startDimensionX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                startDimensionY = startDimensionY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                endDimensionX = endDimensionX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                endDimensionY = endDimensionY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;

                dimensionConfig = new DimensionConfig()
                                            .setId(config.VALUE_TYPE.TRAILEROVERALLWIDTHINCLUDINGACCESSORIES)
                                            .setLength(trailer1.getOverallWidth())
                                            .setStartPoint(startDimensionX, -startDimensionY)
                                            .setEndPoint(endDimensionX, -endDimensionY)
                                            .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                            .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

                dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));


                /*
            'add the rear width dimension
            startDimension.X = startDimension.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            startDimension.Y = startDimension.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            endDimension.X = endDimension.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            endDimension.Y = endDimension.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset

            newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                            UtilDrawingObjectDimension.DimensionEnds.ArrowStartAndEndWithDelimiter, _
                                                            startDimension.X, _
                                                            startDimension.Y, _
                                                            endDimension.X, _
                                                            endDimension.Y, _
                                                            Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1.OverallWidthInclAccessories), ApplicationGlobals.LengthFormatWithoutUnit))
            DrawingObjects.Add(newDimension)
*/

                if (trailer1TurningRadiusInner > 0) {

                    //'trailer turning radius inner
                    dimensionConfig = new DimensionConfig()
                                        .setId(config.VALUE_TYPE.TRAILERTURNINGRADIUSINNER)
                                        .setLength(trailer1TurningRadiusInner)
                                        .setStartPoint(centreOfRadiusX, -centreOfRadiusY)
                                        .setEndPoint(turningRadiusInnerMeetsOverallWidthX, -turningRadiusInnerMeetsOverallWidthY)
                                        .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                        .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

                    newDimension = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId());

                    if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_INNER) !== null) {
                        newDimension.setShowAsWarning(true);
                    }

                    dimensions.push(newDimension);

                }

                /*
            If trailer1TurningRadiusInner > 0 Then

                'trailer turning radius inner
                If Math.Round(trailer1TurningRadiusInner, 0) < _TurningRadiusInnerMin Then
                    isSelected = True
                Else
                    isSelected = False
                End If

                newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                                UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                                centreOfRadius.X, _
                                                                centreOfRadius.Y, _
                                                                turningRadiusInnerMeetsOverallWidth.X, _
                                                                turningRadiusInnerMeetsOverallWidth.Y, _
                                                                Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1TurningRadiusInner), ApplicationGlobals.LengthFormatWithoutUnit), _
                                                                isSelected)
                DrawingObjects.Add(newDimension)

            End If
                */
            } else if (trailer1.getType() === config.TRAILER_TYPES.PUP) {  

                
                // var trailerBodyLeftRearX, trailerBodyLeftRearY, trailerBodyRightRearX, trailerBodyRightRearY, trailerBodyLeftFrontX, trailerBodyLeftFrontY, trailerBodyRightFrontX, trailerBodyRightFrontY;

                /*
                Dim myTrailer As FileLoadingTrailerPup = trailer1
            Dim angleBetweenVehicleRearAxleAndHitch, angleBetweenHitchAndTrailerRearAxle As Double
            Dim angleBetweenZeroAndHitch, angleBetweenZeroAndTrailerRearAxle As Double
            Dim hitch, trailerLeftRear, trailerRightRear, trailerLeftFront, trailerRightFront As Point
            Dim trailerBodyLeftRear, trailerBodyRightRear, trailerBodyLeftFront, trailerBodyRightFront As Point
*/
                angleBetweenVehicleRearAxleAndHitch = Math.acos(toCentreOfVehicleRearAxle / toCentreOfHitch) * 180 / Math.PI;
                angleBetweenHitchAndTrailerRearAxle = Math.acos(toCentreOfTrailer1RearAxle / toCentreOfHitch) * 180 / Math.PI;
                angleBetweenZeroAndHitch = angleBetweenZeroAndCentreOfRearAxle + angleBetweenVehicleRearAxleAndHitch;
                angleBetweenZeroAndTrailerRearAxle = angleBetweenZeroAndHitch + angleBetweenHitchAndTrailerRearAxle;
                /*
            angleBetweenVehicleRearAxleAndHitch = Math.Acos(toCentreOfVehicleRearAxle / toCentreOfHitch) * 180 / Math.PI
            angleBetweenHitchAndTrailerRearAxle = Math.Acos(toCentreOfTrailer1RearAxle / toCentreOfHitch) * 180 / Math.PI
            angleBetweenZeroAndHitch = angleBetweenZeroAndCentreOfRearAxle + angleBetweenVehicleRearAxleAndHitch
            angleBetweenZeroAndTrailerRearAxle = angleBetweenZeroAndHitch + angleBetweenHitchAndTrailerRearAxle
*/

                //'draw hitch
                hitchX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch;
                hitchY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch;
                //newCircle = new Circle(hitchX, -hitchY, hitchRadius, CIRCLE_TYPE.OTHER, true);
                //drawingObjectsArray.push(newCircle);


                /*
            'draw hitch
            hitch.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch * PixelsPerScaleUnit
            hitch.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndHitch) * Math.PI / 180) * toCentreOfHitch * PixelsPerScaleUnit
            newCircle = New UtilDrawingObjectCircle(hitch.X, hitch.Y, _HitchRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other)
            DrawingObjects.Add(newCircle)
*/

                //'draw the swing clearance arc
                newCircle = new Circle(hitchX, -hitchY, vehicle.getRearSwingClearanceRadius(), CIRCLE_TYPE.OTHER, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                newCircle.setDashed(true);
                newCircle.setDashLength(10);
                newCircle.setDrawingOrder(1000);
                if (offerManager.getNotes().getStringTrackerObjectById(config.NOTE_ID.SWING_CLEARANCE) !== null) {
                    newCircle.setShowAsWarning(true);
                }
                drawingObjectsArray.push(newCircle);


                /*
            'draw the swing clearance arc
            newCircle = New UtilDrawingObjectCircle(hitch.X, hitch.Y, vehicle.RearSwingClearanceRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other, True, swingClearanceWarning)
            DrawingObjects.Add(newCircle)
*/

                turningRadiusInnerMeetsOverallWidthX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner;
                turningRadiusInnerMeetsOverallWidthY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner;


                trailerTransformation = {};
                trailerTransformation.originX = centreOfRadiusX;
                trailerTransformation.originY = centreOfRadiusY;
                trailerTransformation.anchorX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset()) + trailer1.getDrawbarLength() + trailer1.getLength() - trailer1.getWheelbaseTurningToRearOfTrailer();
                trailerTransformation.anchorY = 0;
                trailerTransformation.angle = -(90 - angleBetweenZeroAndTrailerRearAxle);
                trailerTransformation.referencePointX = turningRadiusInnerMeetsOverallWidthX;
                trailerTransformation.referencePointY = turningRadiusInnerMeetsOverallWidthY;


                /*
            turningRadiusInnerMeetsOverallWidth.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
            turningRadiusInnerMeetsOverallWidth.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
*/
                if (trailer1.getAccessoryHolder().getBody() !== null || trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer1))) {
                
                    

                    if (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer1))) {
                        trailerBodyWidth = trailer1.getOverallWidth();
                        trailerBodyLength = trailer1.getOverallLengthInclAccessories();
                    } else {
                        trailerBodyWidth = trailer1.getAccessoryHolder().getBody().getActualWidth();
                        trailerBodyLength = trailer1.getAccessoryHolder().getBody().getActualLength();
                    }

                    /*

                    Dim trailerBodyWidth, trailerBodyLength As Double

                    If trailer1.Source = Web.SourceTypes.Standard Then
                    trailerBodyWidth = trailer1.OverallWidth
                    trailerBodyLength = trailer1.OverallLengthInclAccessories
                    Else
                    trailerBodyWidth = trailer1.Accessories.Body.ActualWidth
                    trailerBodyLength = trailer1.Accessories.Body.ActualLength
                    End If
*/
                    //'left back corner of the body
                    trailerBodyLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getWheelbaseTurningToRearOfBody();
                    trailerBodyLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getWheelbaseTurningToRearOfBody();
                    if (trailer1.getAccessoryHolder().getBody() !== null && trailer1.getOverallWidth() > trailer1.getAccessoryHolder().getBody().getActualWidth())  {
                        trailerBodyLeftRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getAccessoryHolder().getBody().getActualWidth()) / 2;
                        trailerBodyLeftRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getAccessoryHolder().getBody().getActualWidth()) / 2;
                    }
                    //'right back corner of the body
                    trailerBodyRightRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    trailerBodyRightRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    //'right front corner of the body
                    trailerBodyRightFrontX = trailerBodyRightRearX + Math.cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength;
                    trailerBodyRightFrontY = trailerBodyRightRearY + Math.sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength;
                    //'left front corner of the body
                    trailerBodyLeftFrontX = trailerBodyRightFrontX + Math.cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;
                    trailerBodyLeftFrontY = trailerBodyRightFrontY + Math.sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth;

                    /*
                    'left back corner of the body
                    trailerBodyLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                    trailerBodyLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                    If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                    trailerBodyLeftRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                    trailerBodyLeftRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                    End If
                    'right back corner of the body
                    trailerBodyRightRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    trailerBodyRightRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    'right front corner of the body
                    trailerBodyRightFront.X = trailerBodyRightRear.X + Math.Cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                    trailerBodyRightFront.Y = trailerBodyRightRear.Y + Math.Sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                    'left front corner of the body
                    trailerBodyLeftFront.X = trailerBodyRightFront.X + Math.Cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                    trailerBodyLeftFront.Y = trailerBodyRightFront.Y + Math.Sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
*/

                    ////'draw the body
                    //newLine = new Line(trailerBodyLeftRearX, -trailerBodyLeftRearY, trailerBodyRightRearX, -trailerBodyRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyRightRearX, -trailerBodyRightRearY, trailerBodyRightFrontX, -trailerBodyRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyRightFrontX, -trailerBodyRightFrontY, trailerBodyLeftFrontX, -trailerBodyLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerBodyLeftFrontX, -trailerBodyLeftFrontY, trailerBodyLeftRearX, -trailerBodyLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    /*
                    'draw the body
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, trailerBodyRightRear.X, trailerBodyRightRear.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightRear.X, trailerBodyRightRear.Y, trailerBodyRightFront.X, trailerBodyRightFront.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightFront.X, trailerBodyRightFront.Y, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, False, True)
                    DrawingObjects.Add(newLine)
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, False, True)
                    DrawingObjects.Add(newLine)
*/
                    bodyPresent = true;

                }
                

                trailerLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getWheelbaseTheoreticalEndToRearMostAxle() + trailer1.getRearOverhang());
                trailerLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getWheelbaseTheoreticalEndToRearMostAxle() + trailer1.getRearOverhang());
                trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;
                trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2;
                if (trailer1.getAccessoryHolder().getBody() !== null && trailer1.getOverallWidth() < trailer1.getAccessoryHolder().getBody().getActualWidth()) {
                    trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
                    trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
                }
                trailerRightRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerRightRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerRightFrontX = trailerRightRearX + Math.cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getOverallLengthExclAccessoriesAndDrawbar();
                trailerRightFrontY = trailerRightRearY + Math.sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getOverallLengthExclAccessoriesAndDrawbar();
                trailerLeftFrontX = trailerRightFrontX + Math.cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
                trailerLeftFrontY = trailerRightFrontY + Math.sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth();
            
                /*
                                trailerLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
                                trailerLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
                                trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
                                trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
                                If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth < trailer1.Accessories.Body.ActualWidth Then
                                    trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
                                    trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
                                End If
                                trailerRightRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                                trailerRightRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                                trailerRightFront.X = trailerRightRear.X + Math.Cos((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * myTrailer.OverallLengthExclAccessoriesAndDrawbar * PixelsPerScaleUnit
                                trailerRightFront.Y = trailerRightRear.Y + Math.Sin((90 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * myTrailer.OverallLengthExclAccessoriesAndDrawbar * PixelsPerScaleUnit
                                trailerLeftFront.X = trailerRightFront.X + Math.Cos((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                                trailerLeftFront.Y = trailerRightFront.Y + Math.Sin((angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                */

                ////'draw the chassis
                //newLine = new Line(trailerLeftRearX, -trailerLeftRearY, trailerRightRearX, -trailerRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerRightRearX, -trailerRightRearY, trailerRightFrontX, -trailerRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerRightFrontX, -trailerRightFrontY, trailerLeftFrontX, -trailerLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerLeftFrontX, -trailerLeftFrontY, trailerLeftRearX, -trailerLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                

                /*
            'draw the chassis
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftRear.X, trailerLeftRear.Y, trailerRightRear.X, trailerRightRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightRear.X, trailerRightRear.Y, trailerRightFront.X, trailerRightFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightFront.X, trailerRightFront.Y, trailerLeftFront.X, trailerLeftFront.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftFront.X, trailerLeftFront.Y, trailerLeftRear.X, trailerLeftRear.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
*/

                ////'draw the drawbar protrusion
                //newLine = new Line(trailerLeftFrontX, -trailerLeftFrontY, hitchX, -hitchY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                //newLine = new Line(trailerRightFrontX, -trailerRightFrontY, hitchX, -hitchY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);

                /*
            'draw the drawbar protrusion
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftFront.X, trailerLeftFront.Y, hitch.X, hitch.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightFront.X, trailerRightFront.Y, hitch.X, hitch.Y, False, Not bodyPresent)
            DrawingObjects.Add(newLine)
*/

                //'draw the rear dimension
                if (trailer1.getAccessoryHolder().getBody() !== null) {
                    if (trailer1.getOverallWidth() > trailer1.getAccessoryHolder().getBody().getActualWidth()) {
                        startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                        if (trailer1.getWheelbaseTurningToRearOfTrailer() >= trailer1.getWheelbaseTurningToRearOfBody()) {
                            rearDimensionOffset = tcDimensionOffset / scalingFactor;
                        }else {
                            rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer1.getWheelbaseTurningToRearOfBody() - trailer1.getWheelbaseTurningToRearOfTrailer());
                        }
                    }else {
                        startDimensionX = trailerBodyLeftRearX;
                        startDimensionY = trailerBodyLeftRearY;
                        endDimensionX = trailerBodyRightRearX;
                        endDimensionY = trailerBodyRightRearY;
                        if (trailer1.getWheelbaseTurningToRearOfBody() >= trailer1.getWheelbaseTurningToRearOfTrailer()) {
                            rearDimensionOffset = tcDimensionOffset / scalingFactor;
                        } else {
                            rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer1.getWheelbaseTurningToRearOfTrailer() - trailer1.getWheelbaseTurningToRearOfBody());
                        }
                    }
                } else {
                    startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.getOverallWidth() - trailer1.getChassisWidth()) / 2);
                    rearDimensionOffset = tcDimensionOffset / scalingFactor;
                }

                /*
            'draw the rear dimension
            If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                If trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                    startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    If trailer1.WheelbaseTurningToRearOfTrailer >= trailer1.WheelbaseTurningToRearOfBody Then
                        rearDimensionOffset = DimensionOffset
                    Else
                        rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfBody - trailer1.WheelbaseTurningToRearOfTrailer) * PixelsPerScaleUnit
                    End If
                Else
                    startDimension = trailerBodyLeftRear
                    endDimension = trailerBodyRightRear
                    If trailer1.WheelbaseTurningToRearOfBody >= trailer1.WheelbaseTurningToRearOfTrailer Then
                        rearDimensionOffset = DimensionOffset
                    Else
                        rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfTrailer - trailer1.WheelbaseTurningToRearOfBody) * PixelsPerScaleUnit
                    End If
                End If
            Else
                startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                rearDimensionOffset = DimensionOffset
            End If
*/

                frontOfTrailerToAxle1Rear = trailer1.getWheelbaseTheoretical() - trailer1.getDrawbarLength() + trailer1.getWheelbaseTheoreticalEndToRearMostAxle() - (trailer1.getAxle3RearToAxle4Rear() + trailer1.getAxle2RearToAxle3Rear() + trailer1.getAxle1RearToAxle2Rear());

                /*
            Dim frontOfTrailerToAxle1Rear As Double = myTrailer.WheelbaseTheoretical - myTrailer.DrawbarLength + _
                                                        myTrailer.WheelbaseTheoreticalEndToRearMostAxle - (myTrailer.Axle3RearToAxle4Rear + myTrailer.Axle2RearToAxle3Rear + myTrailer.Axle1RearToAxle2Rear)
*/


                //'axle 1 rear
                var axle1RearNumberOfTyres = trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle();

                startAxleX = trailerRightFrontX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                startAxleY = trailerRightFrontY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                endAxleX = startAxleX + Math.cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.getChassisWidth() + (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + axle1RearNumberOfTyres * trailer1.getTyreWidth() / 2 + (axle1RearNumberOfTyres - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2);
                endAxleY = startAxleY + Math.sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.getChassisWidth() + (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + axle1RearNumberOfTyres * trailer1.getTyreWidth() / 2 + (axle1RearNumberOfTyres - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2);
                startAxleX = startAxleX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + axle1RearNumberOfTyres * trailer1.getTyreWidth() / 2 + (axle1RearNumberOfTyres - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2;
                startAxleY = startAxleY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + axle1RearNumberOfTyres * trailer1.getTyreWidth() / 2 + (axle1RearNumberOfTyres - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2;
                //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                //drawingObjectsArray.push(newLine);
                
                //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, axle1RearNumberOfTyres,
                //            trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle1Rear().getTyreRadius());


                /*
            'axle 1 rear
            startAxle.X = trailerRightFront.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
            startAxle.Y = trailerRightFront.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
            endAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            endAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
            startAxle.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            startAxle.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
            newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
            DrawingObjects.Add(newLine)
            DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle1RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
*/

                //'axle 2 rear
                if (trailer1.getAxle1RearToAxle2Rear() > 0) {

                    var axle2Rear = trailer1.getAxlesHolder().getAxle2Rear();

                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle1RearToAxle2Rear();
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                
                    //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, axle2Rear.getNoOfTyresOnAxle(),
                    //            axle2Rear.getTyreWidth(), axle2Rear.getTyreRadius());
                    
                    
                }

                /*
            'axle 2 rear
            If trailer1.Axle1RearToAxle2Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle2RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
*/

                //'axle 3 rear
                if (trailer1.getAxle2RearToAxle3Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle2RearToAxle3Rear();
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                
                    //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle3Rear().getNoOfTyresOnAxle(),
                    //            trailer1.getAxlesHolder().getAxle3Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle3Rear().getTyreRadius());
                    
                }

                /*
            'axle 3 rear
            If trailer1.Axle2RearToAxle3Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle3RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
*/

                //'axle 4 rear
                if (trailer1.getAxle3RearToAxle4Rear() > 0) {
                    startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.getAxle3RearToAxle4Rear();
                    
                    
                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                
                    //drawTyres(angleBetweenZeroAndTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle4Rear().getNoOfTyresOnAxle(),
                    //            trailer1.getAxlesHolder().getAxle4Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle4Rear().getTyreRadius());
                    
                }


                /*
            'axle 4 rear
            If trailer1.Axle3RearToAxle4Rear > 0 Then
                startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle4RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
            End If
*/


                //'add the rear width dimension
                startDimensionX = startDimensionX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                startDimensionY = startDimensionY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                endDimensionX = endDimensionX + Math.cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;
                endDimensionY = endDimensionY + Math.sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset;

                
                dimensionConfig = new DimensionConfig()
                                            .setId(config.VALUE_TYPE.TRAILEROVERALLWIDTHINCLUDINGACCESSORIES)
                                            .setLength(trailer1.getOverallWidth())
                                            .setStartPoint(startDimensionX, -startDimensionY)
                                            .setEndPoint(endDimensionX, -endDimensionY)
                                            .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                            .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

                dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));

                /*
            'add the rear width dimension
            startDimension.X = startDimension.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            startDimension.Y = startDimension.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            endDimension.X = endDimension.X + Math.Cos((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
            endDimension.Y = endDimension.Y + Math.Sin((270 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset

            newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                            UtilDrawingObjectDimension.DimensionEnds.ArrowStartAndEndWithDelimiter, _
                                                            startDimension.X, _
                                                            startDimension.Y, _
                                                            endDimension.X, _
                                                            endDimension.Y, _
                                                            Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1.OverallWidthInclAccessories), ApplicationGlobals.LengthFormatWithoutUnit))
            DrawingObjects.Add(newDimension)
*/
                if (trailer1TurningRadiusInner > 0) {

                    //'trailer turning radius inner
                    dimensionConfig = new DimensionConfig()
                                            .setId(config.VALUE_TYPE.TRAILERTURNINGRADIUSINNER)
                                            .setLength(trailer1TurningRadiusInner)
                                            .setStartPoint(centreOfRadiusX, -centreOfRadiusY)
                                            .setEndPoint(turningRadiusInnerMeetsOverallWidthX, -turningRadiusInnerMeetsOverallWidthY)
                                            .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                            .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

                    newDimension = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId());

                    if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_INNER) !== null) {
                        newDimension.setShowAsWarning(true);
                    }

                    dimensions.push(newDimension);

                }

                /*
            If trailer1TurningRadiusInner > 0 Then

                'trailer turning radius inner
                If Math.Round(trailer1TurningRadiusInner, 0) < _TurningRadiusInnerMin Then
                    isSelected = True
                Else
                    isSelected = False
                End If

                newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                                UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                                centreOfRadius.X, _
                                                                centreOfRadius.Y, _
                                                                turningRadiusInnerMeetsOverallWidth.X, _
                                                                turningRadiusInnerMeetsOverallWidth.Y, _
                                                                Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1TurningRadiusInner), ApplicationGlobals.LengthFormatWithoutUnit), _
                                                                isSelected)
                DrawingObjects.Add(newDimension)

            End If
                */
            }

            if (trailer2 !== null) {
                if (trailer2.getType() === config.TRAILER_TYPES.SEMI) {
                    var angleBetweenCentreOfTrailer1RearAxleAndTrailer1FifthWheel;
                    var angleBetweenZeroAndCentreOfTrailer2RearAxle;
                    // var trailerLeftRearX, trailerLeftRearY, trailerRightRearX, trailerRightRearY, trailerLeftFrontX, trailerLeftFrontY, trailerRightFrontX, trailerRightFrontY, trailerLeftFrontForSwingClearanceX, trailerLeftFrontForSwingClearanceY;
                    // var trailerBodyLeftRearX, trailerBodyLeftRearY, trailerBodyRightRearX, trailerBodyRightRearY, trailerBodyLeftFrontX, trailerBodyLeftFrontY, trailerBodyRightFrontX, trailerBodyRightFrontY;
                    

                    /*
                    Dim angleBetweenCentreOfVehicleRearAxleAndFifthWheel, angleBetweenFifthWheelAndCentreOfTrailerRearAxle As Double
                Dim angleBetweenZeroAndCentreOfTrailerRearAxle, fifthWheelOffset As Double
                Dim trailerLeftRear, trailerRightRear, trailerLeftFront, trailerRightFront, trailerLeftFrontForSwingClearance As Point
                Dim trailerBodyLeftRear, trailerBodyRightRear, trailerBodyLeftFront, trailerBodyRightFront As Point
                Dim trailerFifthWheel As Point
                */

                    fifthWheelOffset = offerManager.getRigOps().getFifthWheelOffset();

                    /*
                If vehicle.FifthWheelIncludedInTare = Web.VehicleInclusionTypes.Yes Then
                    fifthWheelOffset = vehicle.FifthWheelOffset
                Else
                    fifthWheelOffset = vehicle.Accessories.FifthWheel.HorizontalGap
                End If
                */
                    angleBetweenCentreOfVehicleRearAxleAndFifthWheel = Math.asin(Math.abs(fifthWheelOffset) / toCentreOfFifthWheel) * 180 / Math.PI;
                    angleBetweenFifthWheelAndCentreOfTrailerRearAxle = Math.asin(trailer1.getWheelbaseTurning() / toCentreOfFifthWheel) * 180 / Math.PI;
                    angleBetweenCentreOfTrailer1RearAxleAndTrailer1FifthWheel = Math.asin(Math.abs(trailer1.getFifthWheelOffset()) / toCentreOfTrailer1FifthWheel) * 180 / Math.PI;
                    var angleBetweenTrailer1FifthWheelAndCentreOfTrailer2RearAxle = Math.asin(Math.min(trailer2.getWheelbaseTurning(), toCentreOfTrailer1FifthWheel) / Math.max(toCentreOfTrailer1FifthWheel, trailer2.getWheelbaseTurning())) * 180 / Math.PI;

                    if (trailer1.getFifthWheelOffset() < 0) {
                        angleBetweenZeroAndCentreOfTrailer2RearAxle = angleBetweenZeroAndCentreOfRearAxle + angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle + angleBetweenCentreOfTrailer1RearAxleAndTrailer1FifthWheel + angleBetweenTrailer1FifthWheelAndCentreOfTrailer2RearAxle
                    } else {
                        angleBetweenZeroAndCentreOfTrailer2RearAxle = angleBetweenZeroAndCentreOfRearAxle - angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle - angleBetweenCentreOfTrailer1RearAxleAndTrailer1FifthWheel + angleBetweenTrailer1FifthWheelAndCentreOfTrailer2RearAxle
                    }

                    /*
                angleBetweenCentreOfVehicleRearAxleAndFifthWheel = Math.Asin(Math.Abs(fifthWheelOffset) / toCentreOfFifthWheel) * 180 / Math.PI
                angleBetweenFifthWheelAndCentreOfTrailerRearAxle = Math.Asin(trailer1.WheelbaseTurning / toCentreOfFifthWheel) * 180 / Math.PI

                If fifthWheelOffset < 0 Then
                    angleBetweenZeroAndCentreOfTrailerRearAxle = angleBetweenZeroAndCentreOfRearAxle + angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle
                Else
                    angleBetweenZeroAndCentreOfTrailerRearAxle = angleBetweenZeroAndCentreOfRearAxle - angleBetweenCentreOfVehicleRearAxleAndFifthWheel + angleBetweenFifthWheelAndCentreOfTrailerRearAxle
                End If
                    */

                    turningRadiusInnerMeetsOverallWidthX = centreOfRadiusX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2TurningRadiusInner;
                    turningRadiusInnerMeetsOverallWidthY = centreOfRadiusY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2TurningRadiusInner;


                    trailer2Transformation = {};
                    trailer2Transformation.originX = centreOfRadiusX;
                    trailer2Transformation.originY = centreOfRadiusY;
                    //trailer2Transformation.anchorX = ((curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheelOffset)) - trailer1.getFrontOverhang() + trailer1.getLength() - trailer1.getWheelbaseTurningToRearOfTrailer();
                    trailer2Transformation.anchorX = ((curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheelOffset)) + trailer1.getWheelbaseTheoretical() - trailer1.getFifthWheelOffset() - trailer2.getFrontOverhang() + trailer2.getLength() - trailer2.getWheelbaseTurningToRearOfTrailer();
                    trailer2Transformation.anchorY = 0;
                    trailer2Transformation.angle = -(90 - angleBetweenZeroAndCentreOfTrailer2RearAxle);
                    trailer2Transformation.referencePointX = turningRadiusInnerMeetsOverallWidthX;
                    trailer2Transformation.referencePointY = turningRadiusInnerMeetsOverallWidthY;

                    /*
                turningRadiusInnerMeetsOverallWidth.X = centreOfRadius.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
                turningRadiusInnerMeetsOverallWidth.Y = centreOfRadius.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1TurningRadiusInner * PixelsPerScaleUnit
                */

                    if (trailer2.getAccessoryHolder().getBody() !== null || trailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer2))) {

                        

                        if (trailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(trailer2))) {
                            trailerBodyWidth = trailer2.getOverallWidth();
                            trailerBodyLength = trailer2.getOverallLengthInclAccessories();
                        } else {
                            trailerBodyWidth = trailer2.getAccessoryHolder().getBody().getActualWidth();
                            trailerBodyLength = trailer2.getAccessoryHolder().getBody().getActualLength();
                        }
                        /*
                        Dim trailerBodyWidth, trailerBodyLength As Double

                        If trailer1.Source = Web.SourceTypes.Standard Then
                        trailerBodyWidth = trailer1.OverallWidth
                        trailerBodyLength = trailer1.OverallLengthInclAccessories
                        Else
                        trailerBodyWidth = trailer1.Accessories.Body.ActualWidth
                        trailerBodyLength = trailer1.Accessories.Body.ActualLength
                        End If
                        */

                        //left back corner of the body
                        trailerBodyLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getWheelbaseTurningToRearOfBody();
                        trailerBodyLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getWheelbaseTurningToRearOfBody();

                        if (trailer2.getAccessoryHolder().getBody() !== null && trailer2.getOverallWidth() > trailer2.getAccessoryHolder().getBody().getActualWidth()) {
                            trailerBodyLeftRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getOverallWidth() - trailer2.getAccessoryHolder().getBody().getActualWidth()) / 2;
                            trailerBodyLeftRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getOverallWidth() - trailer2.getAccessoryHolder().getBody().getActualWidth()) / 2;
                        }
                        //right back corner of the body
                        trailerBodyRightRearX = trailerBodyLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailerBodyWidth;
                        trailerBodyRightRearY = trailerBodyLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailerBodyWidth;
                        //right front corner of the body
                        trailerBodyRightFrontX = trailerBodyRightRearX + Math.cos((90 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailerBodyLength;
                        trailerBodyRightFrontY = trailerBodyRightRearY + Math.sin((90 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailerBodyLength;
                        //left front corner of the body
                        trailerBodyLeftFrontX = trailerBodyRightFrontX + Math.cos((angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailerBodyWidth;
                        trailerBodyLeftFrontY = trailerBodyRightFrontY + Math.sin((angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailerBodyWidth;

                        /*
                        'left back corner of the body
                        trailerBodyLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                        trailerBodyLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.WheelbaseTurningToRearOfBody * PixelsPerScaleUnit
                        If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                        trailerBodyLeftRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                        trailerBodyLeftRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.Accessories.Body.ActualWidth) / 2 * PixelsPerScaleUnit
                        End If
                        'right back corner of the body
                        trailerBodyRightRear.X = trailerBodyLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                        trailerBodyRightRear.Y = trailerBodyLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                        'right front corner of the body
                        trailerBodyRightFront.X = trailerBodyRightRear.X + Math.Cos((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                        trailerBodyRightFront.Y = trailerBodyRightRear.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyLength * PixelsPerScaleUnit
                        'left front corner of the body
                        trailerBodyLeftFront.X = trailerBodyRightFront.X + Math.Cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                        trailerBodyLeftFront.Y = trailerBodyRightFront.Y + Math.Sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailerBodyWidth * PixelsPerScaleUnit
                        */

                        ////draw the body
                        //newLine = new Line(trailerBodyLeftRearX, -trailerBodyLeftRearY, trailerBodyRightRearX, -trailerBodyRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                        //drawingObjectsArray.push(newLine);
                        //newLine = new Line(trailerBodyRightRearX, -trailerBodyRightRearY, trailerBodyRightFrontX, -trailerBodyRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                        //drawingObjectsArray.push(newLine);
                        //newLine = new Line(trailerBodyRightFrontX, -trailerBodyRightFrontY, trailerBodyLeftFrontX, -trailerBodyLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                        //drawingObjectsArray.push(newLine);
                        //newLine = new Line(trailerBodyLeftFrontX, -trailerBodyLeftFrontY, trailerBodyLeftRearX, -trailerBodyLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                        //drawingObjectsArray.push(newLine);

                        bodyPresent = true

                        /*
                        'draw the body
                        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, trailerBodyRightRear.X, trailerBodyRightRear.Y, False, True)
                        DrawingObjects.Add(newLine)
                        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightRear.X, trailerBodyRightRear.Y, trailerBodyRightFront.X, trailerBodyRightFront.Y, False, True)
                        DrawingObjects.Add(newLine)
                        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyRightFront.X, trailerBodyRightFront.Y, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, False, True)
                        DrawingObjects.Add(newLine)
                        newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerBodyLeftFront.X, trailerBodyLeftFront.Y, trailerBodyLeftRear.X, trailerBodyLeftRear.Y, False, True)
                        DrawingObjects.Add(newLine)

                        bodyPresent = True
                            
                            */
                    }

                    /*
                If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or trailer1.Source = Web.SourceTypes.Standard Then

                    

                Else
                    
                End If
                */
                    trailerLeftRearX = turningRadiusInnerMeetsOverallWidthX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getWheelbaseTheoreticalEndToRearMostAxle() + trailer2.getRearOverhang());
                    trailerLeftRearY = turningRadiusInnerMeetsOverallWidthY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getWheelbaseTheoreticalEndToRearMostAxle() + trailer2.getRearOverhang());
                    trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2;
                    trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2;
                    if (trailer2.getAccessoryHolder().getBody() !== null && trailer2.getOverallWidth() < trailer2.getAccessoryHolder().getBody().getActualWidth()) {
                        trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getAccessoryHolder().getBody().getActualWidth() - trailer2.getOverallWidth()) / 2;
                        trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getAccessoryHolder().getBody().getActualWidth() - trailer2.getOverallWidth()) / 2;
                    }
                    trailerRightRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getChassisWidth();
                    trailerRightRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getChassisWidth();
                    trailerRightFrontX = trailerRightRearX + Math.cos((90 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getOverallLengthInclAccessories();
                    trailerRightFrontY = trailerRightRearY + Math.sin((90 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getOverallLengthInclAccessories();
                    trailerLeftFrontX = trailerRightFrontX + Math.cos((angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getChassisWidth();
                    trailerLeftFrontY = trailerRightFrontY + Math.sin((angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getChassisWidth();
                    trailerLeftFrontForSwingClearanceX = trailerLeftFrontX + Math.cos((angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2;
                    trailerLeftFrontForSwingClearanceY = trailerLeftFrontY + Math.sin((angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2;

                    /*
                trailerLeftRear.X = turningRadiusInnerMeetsOverallWidth.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
                trailerLeftRear.Y = turningRadiusInnerMeetsOverallWidth.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.WheelbaseTheoreticalEndToRearMostAxle + trailer1.RearOverhang) * PixelsPerScaleUnit
                trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
                trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
                If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 AndAlso trailer1.OverallWidth < trailer1.Accessories.Body.ActualWidth Then
                    trailerLeftRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
                    trailerLeftRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.Accessories.Body.ActualWidth - trailer1.OverallWidth) / 2 * PixelsPerScaleUnit
                End If
                trailerRightRear.X = trailerLeftRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                trailerRightRear.Y = trailerLeftRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                trailerRightFront.X = trailerRightRear.X + Math.Cos((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.OverallLengthInclAccessories * PixelsPerScaleUnit
                trailerRightFront.Y = trailerRightRear.Y + Math.Sin((90 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.OverallLengthInclAccessories * PixelsPerScaleUnit
                trailerLeftFront.X = trailerRightFront.X + Math.Cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                trailerLeftFront.Y = trailerRightFront.Y + Math.Sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth * PixelsPerScaleUnit
                trailerLeftFrontForSwingClearance.X = trailerLeftFront.X + Math.Cos((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
                trailerLeftFrontForSwingClearance.Y = trailerLeftFront.Y + Math.Sin((angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (trailer1.OverallWidth - trailer1.ChassisWidth) / 2 * PixelsPerScaleUnit
                */



                    //newLine = new Line(trailerLeftRearX, -trailerLeftRearY, trailerRightRearX, -trailerRightRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerRightRearX, -trailerRightRearY, trailerRightFrontX, -trailerRightFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerRightFrontX, -trailerRightFrontY, trailerLeftFrontX, -trailerLeftFrontY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);
                    //newLine = new Line(trailerLeftFrontX, -trailerLeftFrontY, trailerLeftRearX, -trailerLeftRearY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);


                    /*
                'draw the chassis
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftRear.X, trailerLeftRear.Y, trailerRightRear.X, trailerRightRear.Y, False, Not bodyPresent)
                DrawingObjects.Add(newLine)
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightRear.X, trailerRightRear.Y, trailerRightFront.X, trailerRightFront.Y, False, Not bodyPresent)
                DrawingObjects.Add(newLine)
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerRightFront.X, trailerRightFront.Y, trailerLeftFront.X, trailerLeftFront.Y, False, Not bodyPresent)
                DrawingObjects.Add(newLine)
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, trailerLeftFront.X, trailerLeftFront.Y, trailerLeftRear.X, trailerLeftRear.Y, False, Not bodyPresent)
                DrawingObjects.Add(newLine)
                */

                    //drawTrailer(config.OBJECT_TYPES.TURNING_CIRCLE);


                    //draw the rear dimension
                    if (trailer2.getAccessoryHolder().getBody() !== null) {
                        if (trailer2.getOverallWidth() > trailer2.getAccessoryHolder().getBody().getActualWidth()) {
                            startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndCentreOfTrailer2RearAxle * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                            startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndCentreOfTrailer2RearAxle * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                            endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                            endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                            if (trailer2.getWheelbaseTurningToRearOfTrailer() >= trailer2.getWheelbaseTurningToRearOfBody()) {
                                rearDimensionOffset = tcDimensionOffset / scalingFactor;
                            } else {
                                rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer2.getWheelbaseTurningToRearOfBody() - trailer2.getWheelbaseTurningToRearOfTrailer());
                            }
                        } else {
                            startDimensionX = trailerBodyLeftRearX;
                            startDimensionY = trailerBodyLeftRearY;
                            endDimensionX = trailerBodyRightRearX;
                            endDimensionY = trailerBodyRightRearY;
                            if (trailer2.getWheelbaseTurningToRearOfBody() >= trailer2.getWheelbaseTurningToRearOfTrailer()) {
                                rearDimensionOffset = tcDimensionOffset / scalingFactor;
                            } else {
                                rearDimensionOffset = (tcDimensionOffset / scalingFactor) + (trailer2.getWheelbaseTurningToRearOfTrailer() - trailer2.getWheelbaseTurningToRearOfBody());
                            }
                        }
                    } else {
                        startDimensionX = trailerLeftRearX + Math.cos(angleBetweenZeroAndCentreOfTrailer2RearAxle * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                        startDimensionY = trailerLeftRearY + Math.sin(angleBetweenZeroAndCentreOfTrailer2RearAxle * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                        endDimensionX = trailerRightRearX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                        endDimensionY = trailerRightRearY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * ((trailer2.getOverallWidth() - trailer2.getChassisWidth()) / 2);
                        rearDimensionOffset = tcDimensionOffset / scalingFactor;
                    }


                    /*
                'draw the rear dimension
                If trailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                    If trailer1.OverallWidth > trailer1.Accessories.Body.ActualWidth Then
                        startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                        startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                        endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                        endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                        If trailer1.WheelbaseTurningToRearOfTrailer >= trailer1.WheelbaseTurningToRearOfBody Then
                            rearDimensionOffset = DimensionOffset
                        Else
                            rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfBody - trailer1.WheelbaseTurningToRearOfTrailer) * PixelsPerScaleUnit
                        End If
                    Else
                        startDimension = trailerBodyLeftRear
                        endDimension = trailerBodyRightRear
                        If trailer1.WheelbaseTurningToRearOfBody >= trailer1.WheelbaseTurningToRearOfTrailer Then
                            rearDimensionOffset = DimensionOffset
                        Else
                            rearDimensionOffset = DimensionOffset + (trailer1.WheelbaseTurningToRearOfTrailer - trailer1.WheelbaseTurningToRearOfBody) * PixelsPerScaleUnit
                        End If
                    End If
                Else
                    startDimension.X = trailerLeftRear.X + Math.Cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    startDimension.Y = trailerLeftRear.Y + Math.Sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.X = trailerRightRear.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    endDimension.Y = trailerRightRear.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * ((trailer1.OverallWidth - trailer1.ChassisWidth) / 2) * PixelsPerScaleUnit
                    rearDimensionOffset = DimensionOffset
                End If
                */

                    //add the rear width dimension
                    startDimensionX = startDimensionX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * rearDimensionOffset;
                    startDimensionY = startDimensionY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * rearDimensionOffset;
                    endDimensionX = endDimensionX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * rearDimensionOffset;
                    endDimensionY = endDimensionY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * rearDimensionOffset;

                    dimensionConfig = new DimensionConfig()
                                            .setId(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILEROVERALLWIDTHINCLUDINGACCESSORIES)
                                            .setLength(trailer2.getOverallWidth())
                                            .setStartPoint(startDimensionX, -startDimensionY)
                                            .setEndPoint(endDimensionX, -endDimensionY)
                                            .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                            .setLineEndStyle(config.LINE_END_STYLE.ARROW_START_AND_END_WITH_DELIMITER);

                    dimensions.push(new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId()));
                    /*
                'add the rear width dimension
                startDimension.X = startDimension.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
                startDimension.Y = startDimension.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
                endDimension.X = endDimension.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset
                endDimension.Y = endDimension.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * rearDimensionOffset

                newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                                UtilDrawingObjectDimension.DimensionEnds.ArrowStartAndEndWithDelimiter, _
                                                                startDimension.X, _
                                                                startDimension.Y, _
                                                                endDimension.X, _
                                                                endDimension.Y, _
                                                                Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1.OverallWidthInclAccessories), ApplicationGlobals.LengthFormatWithoutUnit))
                DrawingObjects.Add(newDimension)
                */

                    var frontOverhang;

                    if (trailer2.getType() === config.TRAILER_TYPES.SEMI || trailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
                        frontOfTrailerToAxle1Rear = trailer2.getFrontOverhang() + trailer2.getKingpinToAxle1Rear();
                        frontOverhang = trailer2.getFrontOverhang();
                    }

                    /*
                Dim frontOfTrailerToAxle1Rear, frontOverhang As Double

                If TypeOf (trailer1) Is FileLoadingTrailerSemi Then
                    With DirectCast(trailer1, FileLoadingTrailerSemi)
                        frontOfTrailerToAxle1Rear = .FrontOverhang + .KingpinToAxle1Rear
                        frontOverhang = .FrontOverhang
                    End With
                ElseIf TypeOf (trailer1) Is FileLoadingTrailerInterlink Then
                    With DirectCast(trailer1, FileLoadingTrailerInterlink)
                        frontOfTrailerToAxle1Rear = .FrontOverhang + .KingpinToAxle1Rear
                        frontOverhang = .FrontOverhang
                    End With
                End If
                */

                    //draw the fifth wheel
                    //trailerFifthWheelX = trailerLeftFrontX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth() / 2;
                    //trailerFifthWheelY = trailerLeftFrontY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.getChassisWidth() / 2;
                    //trailerFifthWheelX = trailerFifthWheelX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang;
                    //trailerFifthWheelY = trailerFifthWheelY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang;

                    //newCircle = new Circle(trailerFifthWheelX, -trailerFifthWheelY, fifthWheelRadius, CIRCLE_TYPE.OTHER, true);
                    //drawingObjectsArray.push(newCircle);

                    /*

                'draw the fifth wheel
                trailerFifthWheel.X = trailerLeftFront.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth / 2 * PixelsPerScaleUnit
                trailerFifthWheel.Y = trailerLeftFront.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.ChassisWidth / 2 * PixelsPerScaleUnit
                trailerFifthWheel.X = trailerFifthWheel.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang * PixelsPerScaleUnit
                trailerFifthWheel.Y = trailerFifthWheel.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOverhang * PixelsPerScaleUnit
                newCircle = New UtilDrawingObjectCircle(trailerFifthWheel.X, trailerFifthWheel.Y, _FifthWheelRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other)
                DrawingObjects.Add(newCircle)
                */
                    //newCircle = new Circle(trailerFifthWheelX, -trailerFifthWheelY, trailer1.getFrontSwingClearanceRadius(), CIRCLE_TYPE.OTHER, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //newCircle.setDashed(true);
                    //newCircle.setDashLength(10);
                    //newCircle.setDrawingOrder(1000);
                    //if (offerManager.getNotes().getStringTrackerObjectById(config.NOTE_ID.SWING_CLEARANCE) !== null) {
                    //    newCircle.setShowAsWarning(true);
                    //}
                    //drawingObjectsArray.push(newCircle);
                    /*

                'draw the swing clearance arc
                newCircle = New UtilDrawingObjectCircle(trailerFifthWheel.X, trailerFifthWheel.Y, trailer1.FrontSwingClearanceRadius * PixelsPerScaleUnit, UtilDrawingObjectCircle.CircleTypes.Other, True, swingClearanceWarning)
                DrawingObjects.Add(newCircle)
                */


                    //axle 1 rear
                    startAxleX = trailerRightFrontX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                    startAxleY = trailerRightFrontY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear;
                    endAxleX = startAxleX + Math.cos(angleBetweenZeroAndCentreOfTrailer2RearAxle * Math.PI / 180) * (trailer2.getChassisWidth() + (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer2.getTyreWidth() / 2 + (trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2);
                    endAxleY = startAxleY + Math.sin(angleBetweenZeroAndCentreOfTrailer2RearAxle * Math.PI / 180) * (trailer2.getChassisWidth() + (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer2.getTyreWidth() / 2 + (trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2);
                    startAxleX = startAxleX + Math.cos((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer2.getTyreWidth() / 2 + (trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2;
                    startAxleY = startAxleY + Math.sin((180 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * (config.TYRE_GAPS.GAP_BETWEEN_TYRES_AND_CHASSIS + trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() * trailer2.getTyreWidth() / 2 + (trailer2.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle() - 2) * config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM / 2) / 2;

                    //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                    //drawingObjectsArray.push(newLine);

                    //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle1Rear().getNoOfTyresOnAxle(),
                    //    trailer1.getAxlesHolder().getAxle1Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle1Rear().getTyreRadius());
                    /*
                'axle 1 rear
                startAxle.X = trailerRightFront.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
                startAxle.Y = trailerRightFront.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * frontOfTrailerToAxle1Rear * PixelsPerScaleUnit
                endAxle.X = startAxle.X + Math.Cos(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
                endAxle.Y = startAxle.Y + Math.Sin(angleBetweenZeroAndCentreOfTrailerRearAxle * Math.PI / 180) * (trailer1.ChassisWidth + (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2) * PixelsPerScaleUnit
                startAxle.X = startAxle.X + Math.Cos((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
                startAxle.Y = startAxle.Y + Math.Sin((180 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * (ApplicationGlobals.GapBetweenTyresAndChassis + trailer1.Axle1RearNoOfTyres * trailer1.TyreWidth / 2 + (trailer1.Axle1RearNoOfTyres - 2) * ApplicationGlobals.GapBetweenTyresInMm / 2) / 2 * PixelsPerScaleUnit
                newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                DrawingObjects.Add(newLine)
                DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle1RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
                    */

                    //axle 2 rear
                    if (trailer2.getAxle1RearToAxle2Rear() > 0) {
                        startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle1RearToAxle2Rear();
                        startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle1RearToAxle2Rear();
                        endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle1RearToAxle2Rear();
                        endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle1RearToAxle2Rear();

                        //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                        //drawingObjectsArray.push(newLine);

                        //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle2Rear().getNoOfTyresOnAxle(),
                        //        trailer1.getAxlesHolder().getAxle2Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle2Rear().getTyreRadius());
                    }

                    /*
                'axle 2 rear
                If trailer1.Axle1RearToAxle2Rear > 0 Then
                    startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                    startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                    endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                    endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle1RearToAxle2Rear * PixelsPerScaleUnit
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                    DrawingObjects.Add(newLine)
                    DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle2RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
                End If
                */

                    //axle 3 rear
                    if (trailer2.getAxle2RearToAxle3Rear() > 0) {
                        startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle2RearToAxle3Rear();
                        startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle2RearToAxle3Rear();
                        endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle2RearToAxle3Rear();
                        endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle2RearToAxle3Rear();

                        //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                        //drawingObjectsArray.push(newLine);

                        //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle3Rear().getNoOfTyresOnAxle(),
                        //        trailer1.getAxlesHolder().getAxle3Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle3Rear().getTyreRadius());
                    }

                    /*
                    'axle 3 rear
                If trailer1.Axle2RearToAxle3Rear > 0 Then
                    startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                    startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                    endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                    endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle2RearToAxle3Rear * PixelsPerScaleUnit
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                    DrawingObjects.Add(newLine)
                    DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle3RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
                End If
                */

                    //axle 4 rear
                    if (trailer2.getAxle3RearToAxle4Rear() > 0) {
                        startAxleX = startAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle3RearToAxle4Rear();
                        startAxleY = startAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle3RearToAxle4Rear();
                        endAxleX = endAxleX + Math.cos((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle3RearToAxle4Rear();
                        endAxleY = endAxleY + Math.sin((270 + angleBetweenZeroAndCentreOfTrailer2RearAxle) * Math.PI / 180) * trailer2.getAxle3RearToAxle4Rear();

                        //newLine = new Line(startAxleX, -startAxleY, endAxleX, -endAxleY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
                        //drawingObjectsArray.push(newLine);

                        //drawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxleX, startAxleY, 0, endAxleX, endAxleY, 0, trailer1.getAxlesHolder().getAxle4Rear().getNoOfTyresOnAxle(),
                        //        trailer1.getAxlesHolder().getAxle4Rear().getTyreWidth(), trailer1.getAxlesHolder().getAxle4Rear().getTyreRadius());

                    }

                    /*
                'axle 4 rear
                If trailer1.Axle3RearToAxle4Rear > 0 Then
                    startAxle.X = startAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                    startAxle.Y = startAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                    endAxle.X = endAxle.X + Math.Cos((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                    endAxle.Y = endAxle.Y + Math.Sin((270 + angleBetweenZeroAndCentreOfTrailerRearAxle) * Math.PI / 180) * trailer1.Axle3RearToAxle4Rear * PixelsPerScaleUnit
                    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, startAxle.X, startAxle.Y, endAxle.X, endAxle.Y, False, True)
                    DrawingObjects.Add(newLine)
                    DrawTyres(angleBetweenZeroAndCentreOfTrailerRearAxle, startAxle, 0, endAxle, 0, trailer1.Axle4RearNoOfTyres, trailer1.TyreWidth, trailer1.TyreRadius)
                End If
                */

                    if (trailer2TurningRadiusInner > 0) {

                        //trailer turning radius inner
                        dimensionConfig = new DimensionConfig()
                                            .setId(config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.VALUE_TYPE.TRAILERTURNINGRADIUSINNER)
                                            .setLength(trailer2TurningRadiusInner)
                                            .setStartPoint(centreOfRadiusX, -centreOfRadiusY)
                                            .setEndPoint(turningRadiusInnerMeetsOverallWidthX, -turningRadiusInnerMeetsOverallWidthY)
                                            .addStandardDisplayTextFunction(offerManager.getUIFormattedDimensionValue)
                                            .setLineEndStyle(config.LINE_END_STYLE.ARROW_END_ONLY_WITHOUT_DELIMITER);

                        newDimension = new Dimension(config.DIMENSION_TYPE.FREE_ANGLE, undefined, dimensionConfig).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE + '.' + dimensionConfig.getId());

                        if (offerManager.getDistinctWarnings().getStringTrackerObjectById(config.WARNING_ID.TURNING_RADIUS_INNER) !== null) {
                            newDimension.setShowAsWarning(true);
                        }

                        dimensions.push(newDimension);

                    }

                    /*
                If trailer1TurningRadiusInner > 0 Then

                    'trailer turning radius inner
                    If Math.Round(trailer1TurningRadiusInner, 0) < _TurningRadiusInnerMin Then
                        isSelected = True
                    Else
                        isSelected = False
                    End If

                    newDimension = New UtilDrawingObjectDimension(UtilDrawingObjectDimension.DimensionTypes.Standard, _
                                                                    UtilDrawingObjectDimension.DimensionEnds.ArrowEndWithoutDelimiter, _
                                                                    centreOfRadius.X, _
                                                                    centreOfRadius.Y, _
                                                                    turningRadiusInnerMeetsOverallWidth.X, _
                                                                    turningRadiusInnerMeetsOverallWidth.Y, _
                                                                    Format(ApplicationGlobals.GetLengthInCurrentMeasurementSystem(trailer1TurningRadiusInner), ApplicationGlobals.LengthFormatWithoutUnit), _
                                                                    isSelected)
                    DrawingObjects.Add(newDimension)

                End If
                */
                }
            }
            
        }

        
    }
    return drawingObjectsArray;
}

function drawVehicleTopView(drawingGroupName) {

    var vehicleDrawingObjects = [];

    var rigOps = offerManager.getRigOps();

    var topViewVerticalCentre;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        topViewVerticalCentre = offerManager.getRigOps().getTopViewCentreLineForTurningCircle(curVehicle);//curVehicle.getOverallWidthInclAccessories() / 2 - ((Math.max(curVehicle.getCabWidth(), curVehicle.getRearWidth()) - curVehicle.getRearWidth())/2);
    } else {
        topViewVerticalCentre = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + rigOps.getOverallWidth() / 2;
    }
    
    var vehicleLayerNum = 10, objectNum = 300000, blockName = 'CHASSIS1', sequenceNum = 1;

    var chassisRailThickness = 80;
    var cabWidth = curVehicle.getCabWidth(),
        chassisWidth = curVehicle.getChassisWidth();

    var vehicleOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront();
                            
    var newLine;
    var cabFrontLeftX, cabFrontLeftY, cabBackLeftX, cabBackLeftY, cabBackRightX, cabBackRightY, cabFrontRightX, cabFrontRightY;
    if (curVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        cabFrontLeftX = vehicleOriginX;
        cabFrontLeftY = topViewVerticalCentre - cabWidth / 2;
        cabBackLeftX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment();
        cabBackLeftY = topViewVerticalCentre - cabWidth / 2;
        cabBackRightX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment();
        cabBackRightY = topViewVerticalCentre + cabWidth / 2;
        cabFrontRightX = vehicleOriginX;
        cabFrontRightY = topViewVerticalCentre + cabWidth / 2;

        //draw the cab
        newLine = new Line(cabBackLeftX, cabBackLeftY, cabBackRightX, cabBackRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(cabBackRightX, cabBackRightY, cabFrontRightX, cabFrontRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(cabFrontRightX, cabFrontRightY, cabFrontLeftX, cabFrontLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(cabFrontLeftX, cabFrontLeftY, cabBackLeftX, cabBackLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);

        /*
        _TopViewDrawingObjects.Add(New UtilDrawingObjectRectangle(0,
                                                        CabWidth + vehicleCabOffset,
                                                        BumperToStartOfBodyInternal,
                                                        CabWidth,
                                                        Colors.Black,
                                                        Colors.White))

                                                        */
        drawRestOfVehicle();

        objectNum++;
        sequenceNum = 1;


        var externalDeckFrontLeftX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment();
        var externalDeckFrontLeftY = topViewVerticalCentre - curVehicle.getRearWidth() / 2;
        var externalDeckBackLeftX = vehicleOriginX + curVehicle.getOverallLength() - curVehicle.getRearProtrusion();
        var externalDeckBackLeftY = topViewVerticalCentre - curVehicle.getRearWidth() / 2;
        var externalDeckBackRightX = vehicleOriginX + curVehicle.getOverallLength() - curVehicle.getRearProtrusion();
        var externalDeckBackRightY = topViewVerticalCentre + curVehicle.getRearWidth() / 2;
        var externalDeckFrontRightX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment();
        var externalDeckFrontRightY = topViewVerticalCentre + curVehicle.getRearWidth() / 2;

        //draw external deck
        newLine = new Line(externalDeckBackLeftX, externalDeckBackLeftY, externalDeckBackRightX, externalDeckBackRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(externalDeckBackRightX, externalDeckBackRightY, externalDeckFrontRightX, externalDeckFrontRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(externalDeckFrontRightX, externalDeckFrontRightY, externalDeckFrontLeftX, externalDeckFrontLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(externalDeckFrontLeftX, externalDeckFrontLeftY, externalDeckBackLeftX, externalDeckBackLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);

        objectNum++;
        sequenceNum = 1;


        var loadCompartmentFrontLeftX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment();
        var loadCompartmentFrontLeftY = topViewVerticalCentre - curVehicle.getLoadCompartmentWidth() / 2;
        var loadCompartmentBackLeftX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment() + curVehicle.getBodyLengthInternal();
        var loadCompartmentBackLeftY = topViewVerticalCentre - curVehicle.getLoadCompartmentWidth() / 2;
        var loadCompartmentBackRightX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment() + curVehicle.getBodyLengthInternal();
        var loadCompartmentBackRightY = topViewVerticalCentre + curVehicle.getLoadCompartmentWidth() / 2;
        var loadCompartmentFrontRightX = vehicleOriginX + curVehicle.getBumperToStartOfLoadCompartment();
        var loadCompartmentFrontRightY = topViewVerticalCentre + curVehicle.getLoadCompartmentWidth() / 2;

        //draw the load compartment
        newLine = new Line(loadCompartmentBackLeftX, loadCompartmentBackLeftY, loadCompartmentBackRightX, loadCompartmentBackRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(loadCompartmentBackRightX, loadCompartmentBackRightY, loadCompartmentFrontRightX, loadCompartmentFrontRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(loadCompartmentFrontRightX, loadCompartmentFrontRightY, loadCompartmentFrontLeftX, loadCompartmentFrontLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(loadCompartmentFrontLeftX, loadCompartmentFrontLeftY, loadCompartmentBackLeftX, loadCompartmentBackLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);

        /*
    'payload area
    _TopViewDrawingObjects.Add(New UtilDrawingObjectRectangle(BumperToStartOfBodyInternal,
                                                        OverallWidth + bodyWidthOffset,
                                                        BodyLengthInternal,
                                                        OverallWidth,
                                                        Colors.Black,
                                                        Colors.White))
        */
    } else {
        cabFrontLeftX = vehicleOriginX;
        cabFrontLeftY = topViewVerticalCentre - cabWidth / 2;
        cabBackLeftX = vehicleOriginX + curVehicle.getBumperToBackOfCab();
        cabBackLeftY = topViewVerticalCentre - cabWidth / 2;
        cabBackRightX = vehicleOriginX + curVehicle.getBumperToBackOfCab();
        cabBackRightY = topViewVerticalCentre + cabWidth / 2;
        cabFrontRightX = vehicleOriginX;
        cabFrontRightY = topViewVerticalCentre + cabWidth / 2;

        //draw the cab
        newLine = new Line(cabBackLeftX, cabBackLeftY, cabBackRightX, cabBackRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(cabBackRightX, cabBackRightY, cabFrontRightX, cabFrontRightY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(cabFrontRightX, cabFrontRightY, cabFrontLeftX, cabFrontLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(cabFrontLeftX, cabFrontLeftY, cabBackLeftX, cabBackLeftY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);

        drawRestOfVehicle();
    }
    function drawRestOfVehicle() {
        objectNum++;
        sequenceNum = 1;

        //front right corner of the chassis
        var vehicleFrontRightCornerX = vehicleOriginX + curVehicle.getBumperToBackOfCab();
        var vehicleFrontRightCornerY = topViewVerticalCentre + chassisWidth / 2;
        //front left corner of the chassis
        var vehicleFrontLeftCornerX = vehicleOriginX + curVehicle.getBumperToBackOfCab();
        var vehicleFrontLeftCornerY = topViewVerticalCentre - chassisWidth / 2;
        //left back corner of the chassis
        var vehicleBackLeftCornerX = vehicleOriginX + curVehicle.getOverallLengthExclAccessories() - curVehicle.getRearProtrusion();
        var vehicleBackLeftCornerY = topViewVerticalCentre - chassisWidth / 2;
        //right back corner of the chassis
        var vehicleBackRightCornerX = vehicleOriginX + curVehicle.getOverallLengthExclAccessories() - curVehicle.getRearProtrusion();
        var vehicleBackRightCornerY = topViewVerticalCentre + chassisWidth / 2;



        //draw the chassis
        newLine = new Line(vehicleFrontRightCornerX, vehicleFrontRightCornerY, vehicleFrontRightCornerX, vehicleFrontRightCornerY - chassisRailThickness, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleFrontRightCornerX, vehicleFrontRightCornerY - chassisRailThickness, vehicleBackRightCornerX, vehicleBackRightCornerY - chassisRailThickness, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleBackRightCornerX, vehicleBackRightCornerY - chassisRailThickness, vehicleBackRightCornerX, vehicleBackRightCornerY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleBackRightCornerX, vehicleBackRightCornerY, vehicleFrontRightCornerX, vehicleFrontRightCornerY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);

        objectNum++;
        sequenceNum = 1;

        newLine = new Line(vehicleFrontLeftCornerX, vehicleFrontLeftCornerY, vehicleFrontLeftCornerX, vehicleFrontLeftCornerY + chassisRailThickness, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleFrontLeftCornerX, vehicleFrontLeftCornerY + chassisRailThickness, vehicleBackLeftCornerX, vehicleBackLeftCornerY + chassisRailThickness, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleBackLeftCornerX, vehicleBackLeftCornerY + chassisRailThickness, vehicleBackLeftCornerX, vehicleBackLeftCornerY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleBackLeftCornerX, vehicleBackLeftCornerY, vehicleFrontLeftCornerX, vehicleFrontLeftCornerY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);

        //newLine = new Line(vehicleFrontLeftCornerX, vehicleFrontLeftCornerY, vehicleBackLeftCornerX, vehicleBackLeftCornerY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        //vehicleDrawingObjects.push(newLine);
        //newLine = new Line(vehicleBackLeftCornerX, vehicleBackLeftCornerY, vehicleBackRightCornerX, vehicleBackRightCornerY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        //vehicleDrawingObjects.push(newLine);
        //newLine = new Line(vehicleBackRightCornerX, vehicleBackRightCornerY, vehicleFrontRightCornerX, vehicleFrontRightCornerY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        //vehicleDrawingObjects.push(newLine);

        objectNum++;
        sequenceNum = 1;

        //front left of vehicle rear
        var vehicleRearFrontLeftX = vehicleOriginX + curVehicle.getOverallLengthExclAccessories() - curVehicle.getRearProtrusion();
        var vehicleRearFrontLeftY = topViewVerticalCentre - curVehicle.getRearWidthOfVehicleForTurningCircleCalculation() / 2;
        //front right of vehicle rear
        var vehicleRearFrontRightX = vehicleOriginX + curVehicle.getOverallLengthExclAccessories() - curVehicle.getRearProtrusion();
        var vehicleRearFrontRightY = topViewVerticalCentre + curVehicle.getRearWidthOfVehicleForTurningCircleCalculation() / 2;

        //back right of vehicle rear
        var vehicleRearBackRightX = vehicleOriginX + curVehicle.getOverallLengthExclAccessories();
        var vehicleRearBackRightY = topViewVerticalCentre + curVehicle.getRearWidthOfVehicleForTurningCircleCalculation() / 2;

        //back left of vehicle rear
        var vehicleRearBackLeftX = vehicleOriginX + curVehicle.getOverallLengthExclAccessories();
        var vehicleRearBackLeftY = topViewVerticalCentre - curVehicle.getRearWidthOfVehicleForTurningCircleCalculation() / 2;


        //draw the rear of the vehicle
        newLine = new Line(vehicleRearFrontRightX, vehicleRearFrontRightY, vehicleRearFrontLeftX, vehicleRearFrontLeftY, vehicleLayerNum, "REAR_PROTRUSION", objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleRearFrontLeftX, vehicleRearFrontLeftY, vehicleRearBackLeftX, vehicleRearBackLeftY, vehicleLayerNum, "REAR_PROTRUSION", objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleRearBackLeftX, vehicleRearBackLeftY, vehicleRearBackRightX, vehicleRearBackRightY, vehicleLayerNum, "REAR_PROTRUSION", objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
        newLine = new Line(vehicleRearBackRightX, vehicleRearBackRightY, vehicleRearFrontRightX, vehicleRearFrontRightY, vehicleLayerNum, "REAR_PROTRUSION", objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        vehicleDrawingObjects.push(newLine);
    }
    
    vehicleDrawingObjects.sort(sortDrawingObjects);
    vehicleDrawingObjects = groupDrawingObjects(vehicleDrawingObjects);

    vehicleDrawingObjects.forEach(function (drawingObject) {
        
        drawingObject.setDrawingOrder(9000);
        
    });

    if (vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(vehicleDrawingObjects, vehicleTransformation);
    }
    var combinedAxleAndVehicleObjects = generateVehicleAxlesTopView(vehicleOriginX, drawingGroupName);
    

    
    //defaultLayerNumber(vehicleDrawingObjects, 4);
    
    combinedAxleAndVehicleObjects.sort(sortDrawingObjects);
    combinedAxleAndVehicleObjects = groupDrawingObjects(combinedAxleAndVehicleObjects);

    combinedAxleAndVehicleObjects.forEach(function (drawingObject) {
        if (drawingObject.drawingGroupName.indexOf(config.OBJECT_TYPES.AXLES) !== -1) {
            //drawingObject.setBypassHitAreaSort(true);
            drawingObject.setDrawingOrder(10000);
        }
    });
    combinedAxleAndVehicleObjects = combinedAxleAndVehicleObjects.concat(vehicleDrawingObjects);
    addToMainDrawingObjectsArray(combinedAxleAndVehicleObjects);
    
}
function generateVehicleAxlesTopView(vehicleOriginX, drawingGroupName) {

    var axleDrawingObjects = [];

    var currentFrontAxleNo, currentRearAxleNo, lastObjectNo;
    var overallWidth, bumperToAxle;
    // if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        overallWidth = curVehicle.getOverallWidth();
    // } else {
    //     overallWidth = offerManager.getRigOps().getOverallWidth();
    // }


    currentFrontAxleNo = 1;
    currentRearAxleNo = 1;
    var lastObjectNumberRef = {
        lastObjectNumber: 100000
    };



    curVehicle.getAxlesHolder().getAxles().forEach(function (axle) {

        var trackWidth

        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            trackWidth = curVehicle.getTrackWidthFront();
            if (currentFrontAxleNo === 1) {
                bumperToAxle = vehicleOriginX + curVehicle.getFrontOverhang();
            } else {
                bumperToAxle = vehicleOriginX + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front();
            }
            currentFrontAxleNo += 1
        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            trackWidth = curVehicle.getTrackWidthRear();
            if (currentRearAxleNo === 1) {
                bumperToAxle = vehicleOriginX + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear();
            } else if (currentRearAxleNo === 2) {
                bumperToAxle = vehicleOriginX + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear();
            } else if (currentRearAxleNo === 3) {
                bumperToAxle = vehicleOriginX + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear();
            } else {
                bumperToAxle = vehicleOriginX + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear();
            }
            currentRearAxleNo += 1;
        }



        var axleObjects = drawAxle(axle, config.CHASSIS_OBJECT_TYPES.VEHICLE, bumperToAxle, overallWidth, trackWidth, axle.getTyreWidth(), axle.getTyreRadius(), axle.getNoOfTyresOnAxle() === 2, axle.getDrivenAxle(), lastObjectNumberRef, drawingGroupName + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId());

        //axleObjects = groupDrawingObjects(axleObjects);
        
        if (vehicleTransformation !== null) {
            applyTransformationsToDrawingObjects(axleObjects, vehicleTransformation);
        }
        
        axleDrawingObjects = axleDrawingObjects.concat(axleObjects);
        //addToMainDrawingObjectsArray(axleObjects);
    });
    return axleDrawingObjects;
}
function defaultLayerNumber(arrayOfDrawingObjects, layerNumberToUse) {
    var objectNumber = 300000;
    arrayOfDrawingObjects.forEach(function (drawingObject) {
        if(drawingObject.type) {
            switch (drawingObject.type) {
                case 'arc':
                case 'circle':
                case 'line':
                case 'poly':
                    drawingObject.layerNumber = layerNumberToUse;
                    drawingObject.objectNumber = objectNumber++;
                    drawingObject.sequenceNumber = 1;
                    break;
            }
        }
        
    });
}

function setupTurntableTransformation() {
    turntableTransformation = {};

    
    var trailerOriginX;

    var hitchX;
    var hitchYTrailer, hitchYVehicle;
    trailerOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + curVehicle.getAccessoryHolder().getHitch().getOffset();
    hitchX = trailerOriginX;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //    trailerOriginX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset();
    //    hitchX = trailerOriginX;
        hitchYTrailer = curTrailer1.getOverallWidth() / 2; 
        //hitchY = offerManager.getRigOps().getTopViewCentreLineForTurningCircle(curTrailer1);
        hitchYVehicle = curVehicle.getRearWidth()/2;//offerManager.getRigOps().getOverallWidth() / 2;
    } else {
        //trailerOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset();
        //hitchX = trailerOriginX;
        hitchYTrailer = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getOverallWidth() / 2;
        hitchYVehicle = hitchYTrailer;
    }
    

    var turnTableCentreX = trailerOriginX + curTrailer1.getDrawbarLength() + curTrailer1.getFrontOverhang() + curTrailer1.getAxle1FrontToWheelbaseTheoreticalStart();
    var turnTableCentreY;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        turnTableCentreY = hitchYTrailer;//curTrailer1.getOverallWidth() / 2;
        //turnTableCentreY = offerManager.getRigOps().getTopViewCentreLineForTurningCircle(curTrailer1);;
        //turnTableCentreY = offerManager.getRigOps().getOverallWidth()/2;
    } else {
        turnTableCentreY = hitchYTrailer//offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getOverallWidth() / 2;
    }

    var endPoint = { x: hitchX, y: hitchYVehicle };
    applyTransformationToPoint(endPoint, vehicleTransformation);

    var startPoint = { x: hitchX, y: hitchYTrailer };
    applyTransformationToPoint(startPoint, trailerTransformation);

    var turntableCentrePoint = { x: turnTableCentreX, y: turnTableCentreY }
    applyTransformationToPoint(turntableCentrePoint, trailerTransformation);


    translatePoint(startPoint, turntableCentrePoint.x, turntableCentrePoint.y, 0, 0);
    translatePoint(endPoint, turntableCentrePoint.x, turntableCentrePoint.y, 0, 0);
    var angle = calculateTurntableRotationDegrees(endPoint.x, endPoint.y, startPoint.x, startPoint.y);

    turntableTransformation.angle = angle + 359;//-(90 - angle);
    turntableTransformation.centreX = turntableCentrePoint.x;
    turntableTransformation.centreY = turntableCentrePoint.y;

    //if (trailerTransformation !== null) {
    //    turntableTransformation = {};

    //    var endPoint = { x: hitchX, y: hitchY };
    //    applyTransformationToPoint(endPoint, vehicleTransformation);

    //    var startPoint = { x: hitchX, y: hitchY };
    //    applyTransformationToPoint(startPoint, trailerTransformation);

    //    var turntableCentrePoint = { x: turnTableCentreX, y: turnTableCentreY }
    //    applyTransformationToPoint(turntableCentrePoint, trailerTransformation);


    //    translatePoint(startPoint, turntableCentrePoint.x, turntableCentrePoint.y, 0, 0);
    //    translatePoint(endPoint, turntableCentrePoint.x, turntableCentrePoint.y, 0, 0);
    //    var angle = calculateTurntableRotationDegrees(endPoint.x, endPoint.y, startPoint.x, startPoint.y);

    //    turntableTransformation.angle = angle + 359;//-(90 - angle);
    //    turntableTransformation.centreX = turntableCentrePoint.x;
    //    turntableTransformation.centreY = turntableCentrePoint.y;


    //    //newCircle = new Circle(turntableTransformation.centreX, turntableTransformation.centreY, 50, CIRCLE_TYPE.OTHER, true);
    //    //drawingObjectsArray.push(newCircle);
    //}
}

function setupFrontTyreTransformations() {
    var axleFrontLeftX, axleFrontLeftY;

    axleFrontLeftX = curVehicle.getFrontOverhang();
    axleFrontLeftY = (curVehicle.getOverallWidth() - curVehicle.getTrackWidthFront()) / 2;

    var axlefrontLeftPoint = { x: curVehicle.getPortionOfOverallLengthOverhangingFront() + axleFrontLeftX, y: axleFrontLeftY };

    //translatePoint(axlefrontLeftPoint, vehicleTransformation.anchorX, vehicleTransformation.anchorY, vehicleTransformation.originX, vehicleTransformation.originY);


    vehicleFrontLeftTyresTransformation = {};

    vehicleFrontLeftTyresTransformation.centreX = axlefrontLeftPoint.x;
    vehicleFrontLeftTyresTransformation.centreY = axlefrontLeftPoint.y;
    vehicleFrontLeftTyresTransformation.angle = -vehicleTransformation.steeringAngleInner;

    vehicleFrontRightTyresTransformation = {};

    vehicleFrontRightTyresTransformation.centreX = axlefrontLeftPoint.x;
    vehicleFrontRightTyresTransformation.centreY = axlefrontLeftPoint.y + curVehicle.getTrackWidthFront();
    vehicleFrontRightTyresTransformation.angle = -vehicleTransformation.steeringAngleOuter;

    var axle2Front = curVehicle.getAxlesHolder().getAxle2Front();
    if (axle2Front !== null) {
        vehicleFrontLeftSecondTyresTransformation = {};

        vehicleFrontLeftSecondTyresTransformation.centreX = axlefrontLeftPoint.x + curVehicle.getAxle1FrontToAxle2Front();
        vehicleFrontLeftSecondTyresTransformation.centreY = axlefrontLeftPoint.y;
        vehicleFrontLeftSecondTyresTransformation.angle = -vehicleTransformation.secondSteeringAngleInner;

        vehicleFrontRightSecondTyresTransformation = {};

        vehicleFrontRightSecondTyresTransformation.centreX = axlefrontLeftPoint.x + curVehicle.getAxle1FrontToAxle2Front();
        vehicleFrontRightSecondTyresTransformation.centreY = axlefrontLeftPoint.y + curVehicle.getTrackWidthFront();
        vehicleFrontRightSecondTyresTransformation.angle = -vehicleTransformation.secondSteeringAngleOuter;
    }
}

function setupRearAxle1Transformations() {
    var axleLeftX, axleLeftY;

    axleLeftX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear();
    axleLeftY = (curVehicle.getOverallWidth() - curVehicle.getTrackWidthRear()) / 2;

    var axletLeftPoint = { x: curVehicle.getPortionOfOverallLengthOverhangingFront() + axleLeftX, y: axleLeftY };

    //translatePoint(axlefrontLeftPoint, vehicleTransformation.anchorX, vehicleTransformation.anchorY, vehicleTransformation.originX, vehicleTransformation.originY);

    vehicleRearAxle1TyreTransformations = {};
    vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation = {};
    // vehicleFrontLeftTyresTransformation = {};

    vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation.centreY = axletLeftPoint.y;
    vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle1RearInner;

    // vehicleFrontRightTyresTransformation = {};
    vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation = {};

    vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation.centreY = axletLeftPoint.y + curVehicle.getTrackWidthRear();
    vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle1RearOuter;

    
}

function setupRearAxle2Transformations() {
    var axleLeftX, axleLeftY;

    axleLeftX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear();
    axleLeftY = (curVehicle.getOverallWidth() - curVehicle.getTrackWidthRear()) / 2;

    var axletLeftPoint = { x: curVehicle.getPortionOfOverallLengthOverhangingFront() + axleLeftX, y: axleLeftY };

    //translatePoint(axlefrontLeftPoint, vehicleTransformation.anchorX, vehicleTransformation.anchorY, vehicleTransformation.originX, vehicleTransformation.originY);

    vehicleRearAxle2TyreTransformations = {};
    vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation = {};
    // vehicleFrontLeftTyresTransformation = {};

    vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation.centreY = axletLeftPoint.y;
    vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle2RearInner;

    // vehicleFrontRightTyresTransformation = {};
    vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation = {};

    vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation.centreY = axletLeftPoint.y + curVehicle.getTrackWidthRear();
    vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle2RearOuter;

    
}

function setupRearAxle3Transformations() {
    var axleLeftX, axleLeftY;

    axleLeftX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear();
    axleLeftY = (curVehicle.getOverallWidth() - curVehicle.getTrackWidthRear()) / 2;

    var axletLeftPoint = { x: curVehicle.getPortionOfOverallLengthOverhangingFront() + axleLeftX, y: axleLeftY };

    //translatePoint(axlefrontLeftPoint, vehicleTransformation.anchorX, vehicleTransformation.anchorY, vehicleTransformation.originX, vehicleTransformation.originY);

    vehicleRearAxle3TyreTransformations = {};
    vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation = {};
    // vehicleFrontLeftTyresTransformation = {};

    vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation.centreY = axletLeftPoint.y;
    vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle3RearInner;

    // vehicleFrontRightTyresTransformation = {};
    vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation = {};

    vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation.centreY = axletLeftPoint.y + curVehicle.getTrackWidthRear();
    vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle3RearOuter;

    
}

function setupRearAxle4Transformations() {
    var axleLeftX, axleLeftY;

    axleLeftX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear();
    axleLeftY = (curVehicle.getOverallWidth() - curVehicle.getTrackWidthRear()) / 2;

    var axletLeftPoint = { x: curVehicle.getPortionOfOverallLengthOverhangingFront() + axleLeftX, y: axleLeftY };

    //translatePoint(axlefrontLeftPoint, vehicleTransformation.anchorX, vehicleTransformation.anchorY, vehicleTransformation.originX, vehicleTransformation.originY);

    vehicleRearAxle4TyreTransformations = {};
    vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation = {};
    // vehicleFrontLeftTyresTransformation = {};

    vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation.centreY = axletLeftPoint.y;
    vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle4RearInner;

    // vehicleFrontRightTyresTransformation = {};
    vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation = {};

    vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation.centreX = axletLeftPoint.x;
    vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation.centreY = axletLeftPoint.y + curVehicle.getTrackWidthRear();
    vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation.angle = -vehicleTransformation.steeringAngleAxle4RearOuter;

    
}

function drawTrailerTopView(trailer, drawingGroupName) {

    //var trailer = offerManager.getRigOps().getChassisObjectFromPath(drawingGroupName);

    var vehicleLayerNum = 10, objectNum = 400000, blockName = drawingGroupName, sequenceNum = 1;

    var trailerDrawingObjects = [], drawbarTurntableDrawingObjects = [];

    var rigOps = offerManager.getRigOps();

    var topViewVerticalCentre;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        topViewVerticalCentre = trailer.getOverallWidth() / 2;
        //topViewVerticalCentre = offerManager.getRigOps().getTopViewCentreLineForTurningCircle(curTrailer1);
        //topViewVerticalCentre = rigOps.getOverallWidth() / 2;
    } else {
        topViewVerticalCentre = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + rigOps.getOverallWidth() / 2;
    }
    var trailerOriginX = rigOps.getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(trailer.getChassisObjectName()) - trailer.getDrawbarLength();

    //var turntableTransformation = null;
    var newLine, newCircle;
    var tempHitch, hitchX, hitchY;
    var trailerLeftRearX, trailerLeftRearY, trailerRightRearX, trailerRightRearY, trailerRightFrontX, trailerRightFrontY, trailerLeftFrontX, trailerLeftFrontY;
    if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
        var fifthWheelRadius = 200;
        //trailerOriginX = ((curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - rigOps.getFifthWheelOffset())) - curTrailer1.getFrontOverhang();
        //trailerOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront() + offerManager.getRigOps().getTrailerFrontOverhangStart(drawingGroupName);
        

        trailerLeftRearX = trailerOriginX + trailer.getOverallLengthExclAccessories();
        trailerLeftRearY = topViewVerticalCentre - trailer.getChassisWidth() / 2;
        
        //if (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getOverallWidth() < curTrailer1.getAccessoryHolder().getBody().getActualWidth()) {
        //    trailerCoords.trailerLeftRearX = trailerCoords.trailerLeftRearX + Math.cos((180 + angle) * Math.PI / 180) * (curTrailer1.getAccessoryHolder().getBody().getActualWidth() - curTrailer1.getOverallWidth()) / 2;
        //    trailerCoords.trailerLeftRearY = trailerCoords.trailerLeftRearY + Math.sin((180 + angle) * Math.PI / 180) * (curTrailer1.getAccessoryHolder.getBody().getActualWidth() - curTrailer1.getOverallWidth()) / 2;
        //}
        trailerRightRearX = trailerOriginX + trailer.getOverallLengthExclAccessories();
        trailerRightRearY = topViewVerticalCentre + trailer.getChassisWidth() / 2;
        trailerRightFrontX = trailerOriginX;
        trailerRightFrontY = topViewVerticalCentre + trailer.getChassisWidth() / 2;
        trailerLeftFrontX = trailerOriginX;
        trailerLeftFrontY = topViewVerticalCentre - trailer.getChassisWidth() / 2;

        //if (trailerTransformation !== null) {
        //    trailerCoords.trailerLeftFrontForSwingClearanceX = trailerCoords.trailerLeftFrontX + Math.cos((angle) * Math.PI / 180) * (curTrailer1.getOverallWidth() - curTrailer1.getChassisWidth()) / 2;
        //    trailerCoords.trailerLeftFrontForSwingClearanceY = trailerCoords.trailerLeftFrontY + Math.sin((angle) * Math.PI / 180) * (curTrailer1.getOverallWidth() - curTrailer1.getChassisWidth()) / 2;
        //}
        

        newLine = new Line(trailerLeftRearX, trailerLeftRearY, trailerRightRearX, trailerRightRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerRightRearX, trailerRightRearY, trailerRightFrontX, trailerRightFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerRightFrontX, trailerRightFrontY, trailerLeftFrontX, trailerLeftFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerLeftFrontX, trailerLeftFrontY, trailerLeftRearX, trailerLeftRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);


        if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
            ////draw the fifth wheel
            var trailerFifthWheelX = trailerOriginX + trailer.getFrontOverhang();
            var trailerFifthWheelY = topViewVerticalCentre;

            objectNum++;
            sequenceNum = 1;

            newCircle = new Circle(trailerFifthWheelX, trailerFifthWheelY, trailer.getFrontSwingClearanceRadius(), CIRCLE_TYPE.OTHER, true, blockName, vehicleLayerNum, objectNum + '_' + sequenceNum++).setDrawingGroupName(drawingGroupName);
            newCircle.setDashed(true);
            newCircle.setDashLength(10);
            newCircle.setDrawingOrder(1000);
            trailerDrawingObjects.push(newCircle);

            //newCircle = new Circle(trailerFifthWheelX, -trailerFifthWheelY, trailer1.getFrontSwingClearanceRadius(), CIRCLE_TYPE.OTHER, true).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
            //newCircle.setDashed(true);
            //newCircle.setDashLength(10);
            //newCircle.setDrawingOrder(1000);
            //if (offerManager.getNotes().getStringTrackerObjectById(config.NOTE_ID.SWING_CLEARANCE) !== null) {
            //    newCircle.setShowAsWarning(true);
            //}
            //drawingObjectsArray.push(newCircle);
        }

        if (trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
            
            
            

            var interlinkFifthWheelOffsetX = trailerOriginX + trailer.getFrontOverhang() + trailer.getWheelbaseTheoretical() - trailer.getFifthWheelOffset();
            var interlinkFifthWheelOffsetY = topViewVerticalCentre;

            objectNum++;
            sequenceNum = 1;

            newCircle = new Circle(interlinkFifthWheelOffsetX, interlinkFifthWheelOffsetY, config.topViewFifthWheelRadius, CIRCLE_TYPE.OTHER, true, blockName, vehicleLayerNum, objectNum + '_' + sequenceNum++).setDrawingGroupName(drawingGroupName);
            //newCircle.setDashed(true);
            //newCircle.setDashLength(10);
            //newCircle.setDrawingOrder(1000);
            trailerDrawingObjects.push(newCircle);

        }
    } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {

        tempHitch = curVehicle.getAccessoryHolder().getHitch();

        var turntableRadius = 200;
        //trailerOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront() + offerManager.getRigOps().getTrailerFrontOverhangStart(drawingGroupName);
        
        
        
        //var hitchX;
        //if (tempHitch.getType() === config.HITCH_TYPES.CONVENTIONAL) {
        //    //originX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getOverallLengthExclAccessories() - curVehicle.getRearProtrusion() - mountingLength);
        //    hitchX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + tempHitch.getOffset() - (tempHitch.getCouplingOffset() + tempHitch.getMountingLength());
        //} else {
        //    hitchX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + tempHitch.getOffset()) - (tempHitch.getCouplingOffset() + tempHitch.getMountingLength());
        //}
        
        //if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        //    hitchX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + tempHitch.getOffset();
        //}else {
        hitchX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset();
        //}
        
        hitchY = topViewVerticalCentre;
        //newCircle = new Circle(hitchX, -hitchY, hitchRadius, CIRCLE_TYPE.OTHER, true);
        //drawingObjectsArray.push(newCircle);

        //'draw turntable
        var turnTableCentreX = trailerOriginX + trailer.getDrawbarLength() + trailer.getFrontOverhang() + trailer.getAxle1FrontToWheelbaseTheoreticalStart();
        var turnTableCentreY = topViewVerticalCentre;
        //'turntable left front
        var turnTableLeftFrontX = trailerOriginX + trailer.getDrawbarLength();
        var turnTableLeftFrontY = topViewVerticalCentre - trailer.getChassisWidth() / 2;
        
        //'turntable left rear
        var turnTableLeftRearX = trailerOriginX + trailer.getDrawbarLength() + trailer.getTurntableLength();
        var turnTableLeftRearY = topViewVerticalCentre - trailer.getChassisWidth() / 2;
        //'turntable right rear
        var turnTableRightRearX = trailerOriginX + trailer.getDrawbarLength() + trailer.getTurntableLength();
        var turnTableRightRearY = topViewVerticalCentre + trailer.getChassisWidth() / 2;
        //'turntable right front
        var turnTableRightFrontX = trailerOriginX + trailer.getDrawbarLength();
        var turnTableRightFrontY = topViewVerticalCentre + trailer.getChassisWidth() / 2;


        //draw the turntable
        newLine = new Line(turnTableLeftFrontX, turnTableLeftFrontY, turnTableLeftRearX, turnTableLeftRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        drawbarTurntableDrawingObjects.push(newLine);
        newLine = new Line(turnTableLeftRearX, turnTableLeftRearY, turnTableRightRearX, turnTableRightRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        drawbarTurntableDrawingObjects.push(newLine);
        newLine = new Line(turnTableRightRearX, turnTableRightRearY, turnTableRightFrontX, turnTableRightFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        drawbarTurntableDrawingObjects.push(newLine);
        newLine = new Line(turnTableRightFrontX, turnTableRightFrontY, turnTableLeftFrontX, turnTableLeftFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        drawbarTurntableDrawingObjects.push(newLine);

        objectNum++;
        sequenceNum = 1;

        newCircle = new Circle(turnTableCentreX, turnTableCentreY, turntableRadius, CIRCLE_TYPE.OTHER, true, blockName, vehicleLayerNum, objectNum + '_' + sequenceNum++).setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newCircle);

        objectNum++;
        sequenceNum = 1;
        //'draw the drawbar protrusion
        newLine = new Line(turnTableLeftFrontX, turnTableLeftFrontY, hitchX, hitchY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        drawbarTurntableDrawingObjects.push(newLine);
        newLine = new Line(hitchX, hitchY, turnTableRightFrontX, turnTableRightFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        drawbarTurntableDrawingObjects.push(newLine);
        newLine = new Line(turnTableRightFrontX, turnTableRightFrontY, turnTableLeftFrontX, turnTableLeftFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        drawbarTurntableDrawingObjects.push(newLine);

        trailerLeftRearX = trailerOriginX + trailer.getOverallLengthExclAccessories();
        trailerLeftRearY = topViewVerticalCentre - trailer.getChassisWidth()/2;
        
        //if (curTrailer1.getAccessoryHolder().getBody() !== null && curTrailer1.getOverallWidth() < curTrailer1.getAccessoryHolder().getBody().getActualWidth()) {
        //    trailerCoords.trailerLeftRearX = trailerCoords.trailerLeftRearX + Math.cos((180 + angle) * Math.PI / 180) * (curTrailer1.getAccessoryHolder().getBody().getActualWidth() - curTrailer1.getOverallWidth()) / 2;
        //    trailerCoords.trailerLeftRearY = trailerCoords.trailerLeftRearY + Math.sin((180 + angle) * Math.PI / 180) * (curTrailer1.getAccessoryHolder().getBody().getActualWidth() - curTrailer1.getOverallWidth()) / 2;
        //}
        trailerRightRearX = trailerOriginX + trailer.getOverallLengthExclAccessories();
        trailerRightRearY = topViewVerticalCentre + trailer.getChassisWidth() / 2;
        trailerRightFrontX = trailerOriginX + trailer.getDrawbarLength();
        trailerRightFrontY = topViewVerticalCentre + trailer.getChassisWidth() / 2;
        trailerLeftFrontX = trailerOriginX + trailer.getDrawbarLength();
        trailerLeftFrontY = topViewVerticalCentre - trailer.getChassisWidth() / 2;

        objectNum++;
        sequenceNum = 1;

        //'draw the chassis
        newLine = new Line(trailerLeftRearX, trailerLeftRearY, trailerRightRearX, trailerRightRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerRightRearX, trailerRightRearY, trailerRightFrontX, trailerRightFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerRightFrontX, trailerRightFrontY, trailerLeftFrontX, trailerLeftFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerLeftFrontX, trailerLeftFrontY, trailerLeftRearX, trailerLeftRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);

        if (trailerTransformation !== null) {
            setupTurntableTransformation();
            //turntableTransformation = {};
            
            //var endPoint = { x: hitchX, y: hitchY };
            //applyTransformationToPoint(endPoint, vehicleTransformation);

            //var startPoint = { x: hitchX, y: hitchY };
            //applyTransformationToPoint(startPoint, trailerTransformation);

            //var turntableCentrePoint = { x: turnTableCentreX, y: turnTableCentreY }
            //applyTransformationToPoint(turntableCentrePoint, trailerTransformation);
            
            
            //translatePoint(startPoint, turntableCentrePoint.x, turntableCentrePoint.y, 0, 0);
            //translatePoint(endPoint, turntableCentrePoint.x, turntableCentrePoint.y, 0, 0);
            //var angle = calculateTurntableRotationDegrees(endPoint.x, endPoint.y, startPoint.x, startPoint.y);

            //turntableTransformation.angle = angle + 359;//-(90 - angle);
            //turntableTransformation.centreX = turntableCentrePoint.x;
            //turntableTransformation.centreY = turntableCentrePoint.y;


        //    //newCircle = new Circle(turntableTransformation.centreX, turntableTransformation.centreY, 50, CIRCLE_TYPE.OTHER, true);
        //    //drawingObjectsArray.push(newCircle);
        }
        

        /*
        function rotate(cx, cy, x, y, angle) {
            var radians = (Math.PI / 180) * angle,
            cos = Math.cos(radians),
            sin = Math.sin(radians),
            nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
            ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
            return [nx, ny];
        }
        */

    } else if (trailer.getType() === config.TRAILER_TYPES.PUP) {
        tempHitch = curVehicle.getAccessoryHolder().getHitch();
        //trailerOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + tempHitch.getOffset();
        

        
        hitchX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset();

        hitchY = topViewVerticalCentre;

        trailerLeftRearX = trailerOriginX + trailer.getOverallLengthExclAccessories();
        trailerLeftRearY = topViewVerticalCentre - trailer.getChassisWidth() / 2;
        
        //if (trailer1.getAccessoryHolder().getBody() !== null && trailer1.getOverallWidth() < trailer1.getAccessoryHolder().getBody().getActualWidth()) {
        //    trailerLeftRearX = trailerLeftRearX + Math.cos((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
        //    trailerLeftRearY = trailerLeftRearY + Math.sin((180 + angleBetweenZeroAndTrailerRearAxle) * Math.PI / 180) * (trailer1.getAccessoryHolder().getBody().getActualWidth() - trailer1.getOverallWidth()) / 2;
        //}
        trailerRightRearX = trailerOriginX + trailer.getOverallLengthExclAccessories();
        trailerRightRearY = topViewVerticalCentre + trailer.getChassisWidth() / 2;
        trailerRightFrontX = trailerOriginX + trailer.getDrawbarLength();
        trailerRightFrontY = topViewVerticalCentre + trailer.getChassisWidth() / 2;
        trailerLeftFrontX = trailerOriginX + trailer.getDrawbarLength();
        trailerLeftFrontY = topViewVerticalCentre - trailer.getChassisWidth() / 2;

        //'draw the chassis
        newLine = new Line(trailerLeftRearX, trailerLeftRearY, trailerRightRearX, trailerRightRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerRightRearX, trailerRightRearY, trailerRightFrontX, trailerRightFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerRightFrontX, trailerRightFrontY, trailerLeftFrontX, trailerLeftFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerLeftFrontX, trailerLeftFrontY, trailerLeftRearX, trailerLeftRearY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);

        //'draw the drawbar protrusion
        newLine = new Line(trailerRightFrontX, trailerRightFrontY, hitchX, hitchY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(hitchX, hitchY, trailerLeftFrontX, trailerLeftFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(trailerLeftFrontX, trailerLeftFrontY, trailerRightFrontX, trailerRightFrontY, vehicleLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
    }

    if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && trailer.getBodyType() !== '' && trailer.getBodyType() !== 'NONE')) {
        //if we hit this code it means we're drawing a template trailer top view as there is no top view graphics for a standard triler so we need to draw the body on there
        var bodyOriginX = trailerOriginX;

        if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR || trailer.getType() === config.TRAILER_TYPES.PUP) {
            bodyOriginX += trailer.getDrawbarLength();
        }

        objectNum++;
        sequenceNum = 1;

        //left back corner of the body
        var trailerLengthForBodyPurposes = 0;
        if (trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
            trailerLengthForBodyPurposes = trailer.getFrontToStartOfTaper();
        } else {
            trailerLengthForBodyPurposes = trailer.getLength()
        }

        var bodyBackLeftCornerX = bodyOriginX + trailerLengthForBodyPurposes;
        var bodyBackLeftCornerY = topViewVerticalCentre - trailer.getOverallWidth() / 2;
        //right back corner of the body
        var bodyBackRightCornerX = bodyOriginX + trailerLengthForBodyPurposes;
        var bodyBackRightCornerY = topViewVerticalCentre + trailer.getOverallWidth() / 2;
        //right front corner of the body
        var bodyFrontRightCornerX = bodyOriginX;
        var bodyFrontRightCornerY = topViewVerticalCentre + trailer.getOverallWidth() / 2;
        //left front corner of the body
        var bodyFrontLeftCornerX = bodyOriginX;
        var bodyFrontLeftCornerY = topViewVerticalCentre - trailer.getOverallWidth() / 2;

        //draw the rear of the body
        newLine = new Line(bodyBackLeftCornerX, bodyBackLeftCornerY, bodyBackRightCornerX, bodyBackRightCornerY, vehicleLayerNum + 1, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(bodyBackRightCornerX, bodyBackRightCornerY, bodyFrontRightCornerX, bodyFrontRightCornerY, vehicleLayerNum + 1, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(bodyFrontRightCornerX, bodyFrontRightCornerY, bodyFrontLeftCornerX, bodyFrontLeftCornerY, vehicleLayerNum + 1, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
        newLine = new Line(bodyFrontLeftCornerX, bodyFrontLeftCornerY, bodyBackLeftCornerX, bodyBackLeftCornerY, vehicleLayerNum + 1, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        trailerDrawingObjects.push(newLine);
    }

    if (getTransformationForTrailer(trailer.getChassisObjectName()) !== null) {
        applyTransformationsToDrawingObjects(trailerDrawingObjects, getTransformationForTrailer(trailer.getChassisObjectName()));
        if (turntableTransformation !== null) {
            applyTransformationsToDrawingObjects(drawbarTurntableDrawingObjects, trailerTransformation);
            applyRotationToDrawingObjects(drawbarTurntableDrawingObjects, turntableTransformation);
            
        }
    }

    if (drawbarTurntableDrawingObjects.length > 0) {
        drawbarTurntableDrawingObjects.sort(sortDrawingObjects);
        drawbarTurntableDrawingObjects = groupDrawingObjects(drawbarTurntableDrawingObjects);
        if (trailer.getAccessoryHolder().getBody() !== null) {
            drawbarTurntableDrawingObjects.forEach(function (drawingObject) {
                //if (drawingObject.drawingGroupName.indexOf(config.OBJECT_TYPES.AXLES) !== -1) {
                //drawingObject.setBypassHitAreaSort(true);
                drawingObject.setDrawingOrder(8000);
                //}
            });
        }
        addToMainDrawingObjectsArray(drawbarTurntableDrawingObjects);
    }

    trailerDrawingObjects.sort(sortDrawingObjects);
    trailerDrawingObjects = groupDrawingObjects(trailerDrawingObjects);
    if (trailer.getAccessoryHolder().getBody() !== null) {
        trailerDrawingObjects.forEach(function (drawingObject) {
            //if (drawingObject.drawingGroupName.indexOf(config.OBJECT_TYPES.AXLES) !== -1) {
            //drawingObject.setBypassHitAreaSort(true);
            drawingObject.setDrawingOrder(7000);
            //}
        });
    }

    addToMainDrawingObjectsArray(trailerDrawingObjects);
    
    generateTrailerAxlesTopView(trailer, drawingGroupName, turntableTransformation);

    
    turntableTransformation = null;
}

function generateTrailerAxlesTopView(trailer, drawingGroupName, turntableTransformation) {

    //var trailer = offerManager.getRigOps().getChassisObjectFromPath(drawingGroupName);

    var currentFrontAxleNo, currentRearAxleNo, lastObjectNo;
    var overallWidth, frontOfTrailerToAxle = 0;

    //if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    overallWidth = trailer.getOverallWidth();
    //} else {
    //    overallWidth = offerManager.getRigOps().getOverallWidth();
    //}

    currentFrontAxleNo = 1;
    currentRearAxleNo = 1;
    var lastObjectNumberRef = {
        lastObjectNumber: 200000
    };

    trailer.getAxlesHolder().getAxles().forEach(function (axle) {

        var trackWidth;

        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT) {
            trackWidth = 0;
            if (currentFrontAxleNo === 1) {
                if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    frontOfTrailerToAxle = trailer.getDrawbarLength() + trailer.getFrontOverhang();
                }
            } else if (currentFrontAxleNo === 2) {
                if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    frontOfTrailerToAxle = trailer.getDrawbarLength() + trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front();
                }
            } else {
                if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    frontOfTrailerToAxle = trailer.getDrawbarLength() + trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front();
                }
            }
            currentFrontAxleNo++;
        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            trackWidth = 0;
            if (currentRearAxleNo === 1) {
                if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                    frontOfTrailerToAxle = trailer.getFrontOverhang() + trailer.getKingpinToAxle1Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    frontOfTrailerToAxle = trailer.getDrawbarLength() + trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                    frontOfTrailerToAxle = trailer.getHitchPointToAxle1Rear();
                }
            } else if (currentRearAxleNo === 2) {
                if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                    frontOfTrailerToAxle = trailer.getFrontOverhang() + trailer.getKingpinToAxle1Rear() + trailer.getAxle1RearToAxle2Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    frontOfTrailerToAxle = trailer.getDrawbarLength() + trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear() + trailer.getAxle1RearToAxle2Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                    frontOfTrailerToAxle = trailer.getHitchPointToAxle1Rear() + trailer.getAxle1RearToAxle2Rear();
                }
            } else if (currentRearAxleNo === 3) {
                if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                    frontOfTrailerToAxle = trailer.getFrontOverhang() + trailer.getKingpinToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    frontOfTrailerToAxle = trailer.getDrawbarLength() + trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                    frontOfTrailerToAxle = trailer.getHitchPointToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear();
                }
            } else {
                if (trailer.getType() === config.TRAILER_TYPES.SEMI || trailer.getType() === config.TRAILER_TYPES.INTERLINK) {
                    frontOfTrailerToAxle = trailer.getFrontOverhang() + trailer.getKingpinToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear() + trailer.getAxle3RearToAxle4Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    frontOfTrailerToAxle = trailer.getDrawbarLength() + trailer.getFrontOverhang() + trailer.getAxle1FrontToAxle2Front() + trailer.getAxle2FrontToAxle3Front() + trailer.getRearMostFrontAxleToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear() + trailer.getAxle3RearToAxle4Rear();
                } else if (trailer.getType() === config.TRAILER_TYPES.PUP) {
                    frontOfTrailerToAxle = trailer.getHitchPointToAxle1Rear() + trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear() + trailer.getAxle3RearToAxle4Rear();
                }
            }
            currentRearAxleNo++;
        }

        frontOfTrailerToAxle = frontOfTrailerToAxle + getStartOfTrailer(trailer.getChassisObjectName());
        //frontOfTrailerToAxle = frontOfTrailerToAxle + 500;
        var axleObjects = drawAxle(axle, trailer.getChassisObjectName(), frontOfTrailerToAxle, overallWidth, trackWidth, axle.getTyreWidth(), axle.getTyreRadius(), axle.getNoOfTyresOnAxle() === 2, axle.getDrivenAxle(), lastObjectNumberRef, drawingGroupName + '.' + config.OBJECT_TYPES.AXLES + '.' + axle.getId());

        axleObjects = groupDrawingObjects(axleObjects);

        if (getTransformationForTrailer(trailer.getChassisObjectName()) !== null) {
            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT && trailer.getType() === config.TRAILER_TYPES.DRAWBAR && turntableTransformation !== null) {
                applyTransformationsToDrawingObjects(axleObjects, trailerTransformation);
                applyRotationToDrawingObjects(axleObjects, turntableTransformation);

            } else {
                applyTransformationsToDrawingObjects(axleObjects, getTransformationForTrailer(trailer.getChassisObjectName()));
            }

        }
        axleObjects.sort(sortDrawingObjects);
        axleObjects = groupDrawingObjects(axleObjects);
        axleObjects.forEach(function (drawingObject) {
            if (drawingObject.drawingGroupName.indexOf(config.OBJECT_TYPES.AXLES) !== -1) {
                //drawingObject.setBypassHitAreaSort(true);
                drawingObject.setDrawingOrder(10000);
            }
        });
        addToMainDrawingObjectsArray(axleObjects);
    });
}

function calculateTurntableRotationDegrees(startX, startY, endX, endY) {
    //var deltaX = startX - endX;
    //var deltaY = startY - endY;
    //return Math.atan2(deltaY, deltaX) * 180 / Math.PI;
    var angleRadian = Math.atan2(endY, endX) - Math.atan2(startY, startX);
    if (angleRadian < 0) angleRadian += 2 * Math.PI;

    return angleRadian * 180 / Math.PI;
}

function drawBodyTopView(drawingGroupName) {

    var bodyLayerNum = 11, objectNum = 500000, blockName = 'BODY1', sequenceNum = 1; 

    var rigOps = offerManager.getRigOps();

    //draw the body
    var tempBody = rigOps.getBodyFromPath(drawingGroupName);
    var chassisObject = rigOps.getChassisObjectFromPath(drawingGroupName);
    var newLine;
    if (tempBody !== null) {
        
        var bodyDrawingObjects = [];

        
        var topViewVerticalCentre;
        if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
            topViewVerticalCentre = offerManager.getRigOps().getTopViewCentreLineForTurningCircle(rigOps.getChassisObjectFromPath(drawingGroupName));
            //rigOps.getChassisObjectFromPath(drawingGroupName).getOverallWidthInclAccessories() / 2; 
        } else {
            topViewVerticalCentre = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + rigOps.getOverallWidth() / 2;
        }
        

        var bodyOriginX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.BODY);

        //switch (rigOps.getChassisTypeUsingPath(drawingGroupName)) {
        //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
        //        bodyOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getBumperToBackOfCab() + tempBody.getHorizontalGap();
                
        //        break;
        //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
        //        bodyOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempBody.getHorizontalGap();
                
        //        break;
        //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
        //        bodyOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + tempBody.getHorizontalGap();
                
        //        break;
        //}

        //left back corner of the body
        
        var bodyBackLeftCornerX = bodyOriginX + tempBody.getActualLength();
        var bodyBackLeftCornerY = topViewVerticalCentre - tempBody.getActualWidth()/2;
        //right back corner of the body
        var bodyBackRightCornerX = bodyOriginX + tempBody.getActualLength();
        var bodyBackRightCornerY = topViewVerticalCentre + tempBody.getActualWidth() / 2;
        //right front corner of the body
        var bodyFrontRightCornerX = bodyOriginX;
        var bodyFrontRightCornerY = topViewVerticalCentre + tempBody.getActualWidth() / 2;
        //left front corner of the body
        var bodyFrontLeftCornerX = bodyOriginX;
        var bodyFrontLeftCornerY = topViewVerticalCentre - tempBody.getActualWidth() / 2;

        //draw the rear of the body
        newLine = new Line(bodyBackLeftCornerX, bodyBackLeftCornerY, bodyBackRightCornerX, bodyBackRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);
        newLine = new Line(bodyBackRightCornerX, bodyBackRightCornerY, bodyFrontRightCornerX, bodyFrontRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);
        newLine = new Line(bodyFrontRightCornerX, bodyFrontRightCornerY, bodyFrontLeftCornerX, bodyFrontLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);
        newLine = new Line(bodyFrontLeftCornerX, bodyFrontLeftCornerY, bodyBackLeftCornerX, bodyBackLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);

        var headboardThickness = tempBody.getHeadboardThickness(), tailboardThickness = tempBody.getTailboardThickness(), leftSidewallWidth = tempBody.getSidewallLeft(), rightSidewallWidth = tempBody.getSidewallRight();

        objectNum++;
        sequenceNum = 1;

        newLine = new Line(bodyBackLeftCornerX - tailboardThickness, bodyBackLeftCornerY + leftSidewallWidth, bodyBackRightCornerX - tailboardThickness, bodyBackRightCornerY - rightSidewallWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);
        newLine = new Line(bodyBackRightCornerX - tailboardThickness, bodyBackRightCornerY - rightSidewallWidth, bodyFrontRightCornerX + headboardThickness, bodyFrontRightCornerY - rightSidewallWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);
        newLine = new Line(bodyFrontRightCornerX + headboardThickness, bodyFrontRightCornerY - rightSidewallWidth, bodyFrontLeftCornerX + headboardThickness, bodyFrontLeftCornerY + leftSidewallWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);
        newLine = new Line(bodyFrontLeftCornerX + headboardThickness, bodyFrontLeftCornerY + leftSidewallWidth, bodyBackLeftCornerX - tailboardThickness, bodyBackLeftCornerY + leftSidewallWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
        bodyDrawingObjects.push(newLine);

        //draw beaver tail ramp
        if (tempBody.getType() === config.BODY_TYPES.BEAVERTAIL) {
            
            var rampLength = tempBody.getRampLength(), rampPlatesProtrusion = tempBody.getRampPlatesProtrusion();

            objectNum++;
            sequenceNum = 1;

            newLine = new Line(bodyBackLeftCornerX, bodyBackLeftCornerY, bodyBackRightCornerX, bodyBackRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackRightCornerX, bodyBackRightCornerY, bodyBackRightCornerX + rampLength, bodyBackRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackRightCornerX + rampLength, bodyBackRightCornerY, bodyBackLeftCornerX + rampLength, bodyBackLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackLeftCornerX + rampLength, bodyBackLeftCornerY, bodyBackLeftCornerX, bodyBackLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);

            objectNum++;
            sequenceNum = 1;

            newLine = new Line(bodyBackLeftCornerX + rampLength, bodyBackLeftCornerY, bodyBackRightCornerX + rampLength, bodyBackRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackRightCornerX + rampLength, bodyBackRightCornerY, bodyBackRightCornerX + rampLength + rampPlatesProtrusion, bodyBackRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackRightCornerX + rampLength + rampPlatesProtrusion, bodyBackRightCornerY, bodyBackLeftCornerX + rampLength + rampPlatesProtrusion, bodyBackLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackLeftCornerX + rampLength + rampPlatesProtrusion, bodyBackLeftCornerY, bodyBackLeftCornerX + rampLength, bodyBackLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);

        } else if (tempBody.getType() === config.BODY_TYPES.SERVICE) {
            objectNum++;
            sequenceNum = 1;

            var leftCompartmentWidth = tempBody.getLeftCompartmentWidth(), rightCompartmentWidth = tempBody.getRightCompartmentWidth();

            newLine = new Line(bodyBackLeftCornerX, bodyBackLeftCornerY + leftCompartmentWidth, bodyFrontLeftCornerX, bodyBackLeftCornerY + leftCompartmentWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyFrontLeftCornerX, bodyBackLeftCornerY + leftCompartmentWidth, bodyFrontLeftCornerX, bodyBackLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyFrontLeftCornerX, bodyBackLeftCornerY, bodyBackLeftCornerX, bodyBackLeftCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackLeftCornerX, bodyBackLeftCornerY, bodyBackLeftCornerX, bodyBackLeftCornerY + leftCompartmentWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);

            objectNum++;
            sequenceNum = 1;
            
            newLine = new Line(bodyBackRightCornerX, bodyBackRightCornerY - rightCompartmentWidth, bodyFrontRightCornerX, bodyBackRightCornerY - rightCompartmentWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyFrontRightCornerX, bodyBackRightCornerY - rightCompartmentWidth, bodyFrontRightCornerX, bodyBackRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyFrontRightCornerX, bodyBackRightCornerY, bodyBackRightCornerX, bodyBackRightCornerY, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
            newLine = new Line(bodyBackRightCornerX, bodyBackRightCornerY, bodyBackRightCornerX, bodyBackRightCornerY - rightCompartmentWidth, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE").setDrawingGroupName(drawingGroupName);
            bodyDrawingObjects.push(newLine);
        }



        if (rigOps.getChassisTypeUsingPath(drawingGroupName) === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
            applyTransformationsToDrawingObjects(bodyDrawingObjects, vehicleTransformation);
        } else if (rigOps.getChassisTypeUsingPath(drawingGroupName) === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
            applyTransformationsToDrawingObjects(bodyDrawingObjects, trailerTransformation);
        } else if (rigOps.getChassisTypeUsingPath(drawingGroupName) === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
            applyTransformationsToDrawingObjects(bodyDrawingObjects, trailer2Transformation);
        }
        bodyDrawingObjects.sort(sortDrawingObjects);
        bodyDrawingObjects = groupDrawingObjects(bodyDrawingObjects);
        bodyDrawingObjects.forEach(function (drawingObject) {
            drawingObject.setDrawingOrder(5000);
        });
        addToMainDrawingObjectsArray(bodyDrawingObjects);

    }
}

function drawAxle(axle, parent, bumperToAxle, overallWidth, trackWidth, tyreWidth, tyreRadius, singleTyres, driven, lastObjectNumberRef, drawingGroupName) {
    
    var rigOps = offerManager.getRigOps();

    var topViewVerticalCentre;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        topViewVerticalCentre = offerManager.getRigOps().getTopViewCentreLineForTurningCircle(rigOps.getChassisObjectFromPath(parent));//rigOps.getChassisObjectFromPath(parent).getOverallWidthInclAccessories() / 2;
        //topViewVerticalCentre = rigOps.getOverallWidth() / 2;
    } else {
        topViewVerticalCentre = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + rigOps.getOverallWidth() / 2;
    }
        
    //if (drawingGroupName !== config.OBJECT_TYPES.TURNING_CIRCLE) {
    //    drawingGroupName = 'IGNORE';
    //}

    
    var axleThickness = 50;
    var startingLayerNo = 9;
    var drivenShaftWidth = 100;
    var drivenShaftHeight = 100;
    var drivenHousingRadius = 225;
    var drivenHousingLength = 340;
    var dualTyreSpacer = 30;


    var  axleObjects = [];
    var sequenceNumber;
    var newLine;
    var newarc;
    var centreLine;

    var blockNameChassisDetailDarkGrey, blockNameChassisDetailLightGrey;
    if (rigOps.getChassisObjectFromPath(parent).getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        blockNameChassisDetailDarkGrey = 'CHASSIS_DETAIL_DARKGREY';
        blockNameChassisDetailLightGrey = 'CHASSIS_DETAIL_LIGHTGREY';
    } else {
        blockNameChassisDetailDarkGrey = 'CHASSIS_DETAIL_DARKGREY1';
        blockNameChassisDetailLightGrey = 'CHASSIS_DETAIL_LIGHTGREY1';
    }

    if (trackWidth === 0) {
        //'no track width has been supplied, so we need to calculate it to fit in with the overall width
        if (singleTyres === true) {
            trackWidth = overallWidth - tyreWidth;
        } else {
            trackWidth = overallWidth - (2 * tyreWidth + dualTyreSpacer);
        }
    }


    
    //if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
    //    centreLine = overallWidth / 2;
    //} else {
    //    centreLine = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + overallWidth / 2;
    //}
    centreLine = topViewVerticalCentre;

    //'add the axle shaft
    lastObjectNumberRef.lastObjectNumber += 1;
    sequenceNumber = 0;
    sequenceNumber += 1;
    /*
    
newLine = New UtilDrawingLine With {.StartX = bumperToAxle - axleThickness,
                                    .StartY = (overallWidth - trackWidth) / 2,
                                    .EndX = .StartX,
                                    .EndY = .StartY + trackWidth,
                                    .PartOfClosedObject = True,
                                    .Layer = startingLayerNo,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = lastObjectNumber,
                                    .SequenceNumber = sequenceNumber}
                                    axleObjects.Add(newLine)
    */
    //, layerNumber, blockName, objNumSeqNum, closedIndicator
    newLine = new Line(bumperToAxle - axleThickness, topViewVerticalCentre - trackWidth / 2, bumperToAxle - axleThickness, topViewVerticalCentre + trackWidth / 2, startingLayerNo, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    axleObjects.push(newLine);

    sequenceNumber += 1;
    /*
    
newLine = New UtilDrawingLine With {.StartX = bumperToAxle - axleThickness,
                                    .StartY = (overallWidth - trackWidth) / 2 + trackWidth,
                                    .EndX = bumperToAxle + axleThickness,
                                    .EndY = .StartY,
                                    .PartOfClosedObject = True,
                                    .Layer = startingLayerNo,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = lastObjectNumber,
                                    .SequenceNumber = sequenceNumber}
axleObjects.Add(newLine)
*/
    newLine = new Line(bumperToAxle - axleThickness, topViewVerticalCentre + trackWidth / 2, bumperToAxle + axleThickness, topViewVerticalCentre + trackWidth / 2, startingLayerNo, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    axleObjects.push(newLine);

    sequenceNumber += 1;
    /*

newLine = New UtilDrawingLine With {.StartX = bumperToAxle + axleThickness,
                                    .StartY = (overallWidth - trackWidth) / 2 + trackWidth,
                                    .EndX = .StartX,
                                    .EndY = (overallWidth - trackWidth) / 2,
                                    .PartOfClosedObject = True,
                                    .Layer = startingLayerNo,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = lastObjectNumber,
                                    .SequenceNumber = sequenceNumber}
axleObjects.Add(newLine)
*/
    newLine = new Line(bumperToAxle + axleThickness, topViewVerticalCentre + trackWidth / 2, bumperToAxle + axleThickness, topViewVerticalCentre - trackWidth / 2, startingLayerNo, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    axleObjects.push(newLine);

    sequenceNumber += 1;
    /*

newLine = New UtilDrawingLine With {.StartX = bumperToAxle + axleThickness,
                                    .StartY = (overallWidth - trackWidth) / 2,
                                    .EndX = bumperToAxle - axleThickness,
                                    .EndY = .StartY,
                                    .PartOfClosedObject = True,
                                    .Layer = startingLayerNo,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = lastObjectNumber,
                                    .SequenceNumber = sequenceNumber}
axleObjects.Add(newLine)
*/
    newLine = new Line(bumperToAxle + axleThickness, topViewVerticalCentre - trackWidth / 2, bumperToAxle - axleThickness, topViewVerticalCentre - trackWidth / 2, startingLayerNo, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    axleObjects.push(newLine);

    if (vehicleTransformation !== null) {
        setupFrontTyreTransformations();
    }

    //'now add tyres
    if (singleTyres === true) {
        //'draw top tyre
        var frontRightTyreObjects = drawTyre(axle, parent, bumperToAxle, topViewVerticalCentre + trackWidth / 2 - tyreWidth / 2, tyreWidth, tyreRadius, startingLayerNo, lastObjectNumberRef, drawingGroupName);
        if (vehicleTransformation !== null) {

            if (axle.getSteering()) {
                if (axle.getNumber() === 1) {
                    if(vehicleFrontRightTyresTransformation !== null) {
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleFrontRightTyresTransformation);
                    }
                } else if (axle.getNumber() === 2) {
                    if(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER && vehicleFrontRightSecondTyresTransformation !== null) {
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleFrontRightSecondTyresTransformation);
                    } else if(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && axle.canCalculateSteeringAngleForPusher()) {
                        setupRearAxle1Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                    }
                } else if (axle.getNumber() === 3) {
                    if(axle.canCalculateSteeringAngleForPusher() && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle1Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        setupRearAxle2Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                    }
                } else if (axle.getNumber() === 4) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        setupRearAxle3Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle2Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                    }
                } else if (axle.getNumber() === 5) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        setupRearAxle4Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle3Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                    }
                } else if (axle.getNumber() === 6) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle4Transformations();
                        applyRotationToDrawingObjects(frontRightTyreObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                    }
                }
            }
        }
        axleObjects = axleObjects.concat(frontRightTyreObjects);
        
        

        //'draw bottom tyre
        var frontLeftTyreObjects = drawTyre(axle, parent, bumperToAxle, topViewVerticalCentre - trackWidth / 2 - tyreWidth / 2, tyreWidth, tyreRadius, startingLayerNo, lastObjectNumberRef, drawingGroupName);
        if (vehicleTransformation !== null) {

            if (axle.getSteering()) {
                
                if (axle.getNumber() === 1) {
                    if(vehicleFrontLeftTyresTransformation !== null) {
                        // setupFrontTyreTransformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleFrontLeftTyresTransformation);
                    }
                } else if (axle.getNumber() === 2) {
                    if(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER && vehicleFrontLeftSecondTyresTransformation !== null) {
                        // setupFrontTyreTransformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleFrontLeftSecondTyresTransformation);
                    } else if(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && axle.canCalculateSteeringAngleForPusher()) {
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                    }
                } else if (axle.getNumber() === 3) {
                    if(axle.canCalculateSteeringAngleForPusher() && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        // setupRearAxle1Transformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        // setupRearAxle2Transformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                    }
                }else if (axle.getNumber() === 4) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        // setupRearAxle3Transformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        // setupRearAxle2Transformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                    }
                } else if (axle.getNumber() === 5) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        // setupRearAxle4Transformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        // setupRearAxle3Transformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                    }
                } else if (axle.getNumber() === 6) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        // setupRearAxle4Transformations();
                        applyRotationToDrawingObjects(frontLeftTyreObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                    }
                }
            }


            
        }
        axleObjects = axleObjects.concat(frontLeftTyreObjects);
    } else {
        //'draw outer top tyre
        var frontRightTyreOuterObjects = drawTyre(axle, parent, bumperToAxle, topViewVerticalCentre + trackWidth / 2 + dualTyreSpacer / 2, tyreWidth, tyreRadius, startingLayerNo, lastObjectNumberRef, drawingGroupName);
        //'draw top tyre spacer
        var frontRightTyreSpacerObjects = drawTyreSpacer(parent, bumperToAxle, topViewVerticalCentre + trackWidth / 2 - dualTyreSpacer / 2, dualTyreSpacer, startingLayerNo, lastObjectNumberRef, drawingGroupName);
        //'draw inner top tyre
        var frontRightTyreInnerObjects = drawTyre(axle, parent, bumperToAxle, topViewVerticalCentre + trackWidth / 2 - dualTyreSpacer / 2 - tyreWidth, tyreWidth, tyreRadius, startingLayerNo, lastObjectNumberRef, drawingGroupName);
        //'draw outer bottom tyre
        var frontLeftTyreOuterObjects = drawTyre(axle, parent, bumperToAxle, topViewVerticalCentre - trackWidth / 2 - dualTyreSpacer / 2 - tyreWidth, tyreWidth, tyreRadius, startingLayerNo, lastObjectNumberRef, drawingGroupName);
        //'draw bottom tyre spacer
        var frontLeftTyreSpacerObjects = drawTyreSpacer(parent, bumperToAxle, topViewVerticalCentre - trackWidth / 2 - dualTyreSpacer / 2, dualTyreSpacer, startingLayerNo, lastObjectNumberRef, drawingGroupName);
        //'draw inner bottom tyre
        var frontLeftTyreInnerObjects = drawTyre(axle, parent, bumperToAxle, topViewVerticalCentre - trackWidth / 2 + dualTyreSpacer / 2, tyreWidth, tyreRadius, startingLayerNo, lastObjectNumberRef, drawingGroupName);

        if (vehicleTransformation !== null) {

            if (axle.getSteering()) {
                if (axle.getNumber() === 1) {
                    if(vehicleFrontRightTyresTransformation !== null && vehicleFrontLeftTyresTransformation !== null) {
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleFrontRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleFrontRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleFrontRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleFrontLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleFrontLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleFrontLeftTyresTransformation);
                    }
                } else if (axle.getNumber() === 2) {
                    if(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER && vehicleFrontRightSecondTyresTransformation !== null && vehicleFrontLeftSecondTyresTransformation !== null) {
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleFrontRightSecondTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleFrontRightSecondTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleFrontRightSecondTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleFrontLeftSecondTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleFrontLeftSecondTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleFrontLeftSecondTyresTransformation);
                    }else if(axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && axle.canCalculateSteeringAngleForPusher()) {
                        setupRearAxle1Transformations();
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                    }
                }else if (axle.getNumber() === 3) {
                    if(axle.canCalculateSteeringAngleForPusher() && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle1Transformations();
                        
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle1TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle1TyreTransformations.vehicleLeftTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        setupRearAxle2Transformations();
                        
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                    }
                }else if (axle.getNumber() === 4) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        setupRearAxle3Transformations();
                        
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle2Transformations();
                        
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle2TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle2TyreTransformations.vehicleLeftTyresTransformation);
                    }
                } else if (axle.getNumber() === 5) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() === 0) {
                        setupRearAxle4Transformations();
                        
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                    } else if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle3Transformations();
                        
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle3TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle3TyreTransformations.vehicleLeftTyresTransformation);
                    }
                } else if (axle.getNumber() === 6) {
                    if((axle.canCalculateSteeringAngleForPusher() || axle.canCalculateSteeringAngleForTag()) && curVehicle.getAxle1FrontToAxle2Front() > 0) {
                        setupRearAxle4Transformations();
                        
                        applyRotationToDrawingObjects(frontRightTyreOuterObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleRearAxle4TyreTransformations.vehicleRightTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                        applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleRearAxle4TyreTransformations.vehicleLeftTyresTransformation);
                    }
                }
            }
        }

        axleObjects = axleObjects.concat(frontRightTyreOuterObjects);
        axleObjects = axleObjects.concat(frontRightTyreSpacerObjects);
        axleObjects = axleObjects.concat(frontRightTyreInnerObjects);
        axleObjects = axleObjects.concat(frontLeftTyreOuterObjects);
        axleObjects = axleObjects.concat(frontLeftTyreSpacerObjects);
        axleObjects = axleObjects.concat(frontLeftTyreInnerObjects);
        

        // if (vehicleTransformation !== null) {

        //     if (axle.getSteering()) {
        //         if (axle.getNumber() === 1) {
        //             if(vehicleFrontRightTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleFrontRightTyresTransformation);
        //             }
        //         } else if (axle.getNumber() === 2) {
        //             if(vehicleFrontRightSecondTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontRightTyreSpacerObjects, vehicleFrontRightSecondTyresTransformation);
        //             }
        //         }
        //     }
        // }

        

        

        // if (vehicleTransformation !== null) {

        //     if (axle.getSteering()) {
        //         if (axle.getNumber() === 1) {
        //             if(vehicleFrontRightTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleFrontRightTyresTransformation);
        //             }
        //         } else if (axle.getNumber() === 2) {
        //             if(vehicleFrontRightSecondTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontRightTyreInnerObjects, vehicleFrontRightSecondTyresTransformation);
        //             }
        //         }
        //     }
        // }


        

        

        // if (vehicleTransformation !== null) {

        //     if (axle.getSteering()) {
        //         if (axle.getNumber() === 1) {
        //             if(vehicleFrontLeftTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleFrontLeftTyresTransformation);
        //             }
        //         } else if (axle.getNumber() === 2) {
        //             if(vehicleFrontLeftSecondTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontLeftTyreOuterObjects, vehicleFrontLeftSecondTyresTransformation);
        //             }
        //         }
        //     }
        // }

        

        

        // if (vehicleTransformation !== null) {

        //     if (axle.getSteering()) {
        //         if (axle.getNumber() === 1) {
        //             if(vehicleFrontLeftTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleFrontLeftTyresTransformation);
        //             }
        //         } else if (axle.getNumber() === 2) {
        //             if(vehicleFrontLeftSecondTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontLeftTyreSpacerObjects, vehicleFrontLeftSecondTyresTransformation);
        //             }
        //         }
        //     }
        // }

        

        
        // if (vehicleTransformation !== null) {

        //     if (axle.getSteering()) {
        //         if (axle.getNumber() === 1) {
        //             if(vehicleFrontLeftTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleFrontLeftTyresTransformation);
        //             }
        //         } else if (axle.getNumber() === 2) {
        //             if(vehicleFrontLeftSecondTyresTransformation !== null) {
        //                 applyRotationToDrawingObjects(frontLeftTyreInnerObjects, vehicleFrontLeftSecondTyresTransformation);
        //             }
        //         }
        //     }
        // }

        
    }

    if (driven === true) {
        //'add the driven housing 
        lastObjectNumberRef.lastObjectNumber += 1;
        sequenceNumber = 0;

        sequenceNumber += 1;
        /*
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle - drivenHousingLength,
                                            .StartY = centreLine - drivenShaftWidth / 2,
                                            .EndX = bumperToAxle - drivenHousingLength,
                                            .EndY = centreLine + drivenShaftWidth / 2,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newLine)
        */
        newLine = new Line(bumperToAxle - drivenHousingLength, centreLine - drivenShaftWidth / 2, bumperToAxle - drivenHousingLength, centreLine + drivenShaftWidth / 2, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);


        sequenceNumber += 1;
        /*
        
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle - drivenHousingLength,
                                            .StartY = centreLine + drivenShaftWidth / 2,
                                            .EndX = bumperToAxle,
                                            .EndY = centreLine + drivenHousingRadius,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newLine)
        */
        newLine = new Line(bumperToAxle - drivenHousingLength, centreLine + drivenShaftWidth / 2, bumperToAxle, centreLine + drivenHousingRadius, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);


        sequenceNumber += 1;
        /*
        
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle,
                                            .StartY = centreLine + drivenHousingRadius,
                                            .EndX = bumperToAxle,
                                            .EndY = centreLine - drivenHousingRadius,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber,
                                            .Pen = Pens.Transparent}
        axleObjects.Add(newLine)
        */
        newLine = new Line(bumperToAxle, centreLine + drivenHousingRadius, bumperToAxle, centreLine - drivenHousingRadius, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);


        sequenceNumber += 1;
        /*
        
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle,
                                            .StartY = centreLine - drivenHousingRadius,
                                            .EndX = bumperToAxle - drivenHousingLength,
                                            .EndY = centreLine - drivenShaftWidth / 2,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newLine)
        */
        newLine = new Line(bumperToAxle, centreLine - drivenHousingRadius, bumperToAxle - drivenHousingLength, centreLine - drivenShaftWidth / 2, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);


        //'add the driven housing round bit
        lastObjectNumberRef.lastObjectNumber += 1;
        sequenceNumber = 0;

        sequenceNumber += 1;
        /*
        
        newarc = New UtilDrawingArc With {.X = bumperToAxle,
                                            .Y = centreLine,
                                            .Radius = drivenHousingRadius,
                                            .StartAngle = 270,
                                            .SweepAngle = 180,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newarc)
        */
        var arcFillColour;
        if (globals.user.getColourDrawings() === true) {
            arcFillColour = utils.getColour(blockNameChassisDetailLightGrey, cabColour);
        } else {
            arcFillColour = config.DRAWING_COLOURS.WHITE;
        }

        var newArc = new Arc(bumperToAxle, centreLine, drivenHousingRadius, 270, 180, true, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber).setDrawingGroupName(drawingGroupName).setFillColour(arcFillColour);
        axleObjects.push(newArc);

        //'add the square on top of the driven housing round bit
        lastObjectNumberRef.lastObjectNumber += 1;
        sequenceNumber = 0;

        sequenceNumber += 1;
        /*
        
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle + drivenHousingRadius - 5,
                                            .StartY = centreLine + drivenShaftWidth / 2,
                                            .EndX = bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight,
                                            .EndY = centreLine + drivenShaftWidth / 2,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newLine)
        */
        newLine = new Line(bumperToAxle + drivenHousingRadius - 5, centreLine + drivenShaftWidth / 2, bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight, centreLine + drivenShaftWidth / 2, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);



        sequenceNumber += 1;
        /*
        
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight,
                                            .StartY = centreLine + drivenShaftWidth / 2,
                                            .EndX = bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight,
                                            .EndY = centreLine - drivenShaftWidth / 2,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newLine)
        */

        newLine = new Line(bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight, centreLine + drivenShaftWidth / 2, bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight, centreLine - drivenShaftWidth / 2, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);

        sequenceNumber += 1;
        /*
        
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight,
                                            .StartY = centreLine - drivenShaftWidth / 2,
                                            .EndX = bumperToAxle + drivenHousingRadius - 5,
                                            .EndY = centreLine - drivenShaftWidth / 2,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newLine)
        */
        newLine = new Line(bumperToAxle + drivenHousingRadius - 5 + drivenShaftHeight, centreLine - drivenShaftWidth / 2, bumperToAxle + drivenHousingRadius - 5, centreLine - drivenShaftWidth / 2, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);

        sequenceNumber += 1;
        /*
        
        newLine = New UtilDrawingLine With {.StartX = bumperToAxle + drivenHousingRadius - 5,
                                            .StartY = centreLine - drivenShaftWidth / 2,
                                            .EndX = bumperToAxle + drivenHousingRadius - 5,
                                            .EndY = centreLine + drivenShaftWidth / 2,
                                            .PartOfClosedObject = True,
                                            .Layer = startingLayerNo + 1,
                                            .ObjectName = "CHASSIS_DETAIL_LIGHTGREY",
                                            .ObjectNumber = lastObjectNumber,
                                            .SequenceNumber = sequenceNumber}
        axleObjects.Add(newLine)
        */
        newLine = new Line(bumperToAxle + drivenHousingRadius - 5, centreLine - drivenShaftWidth / 2, bumperToAxle + drivenHousingRadius - 5, centreLine + drivenShaftWidth / 2, startingLayerNo + 1, blockNameChassisDetailLightGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
        axleObjects.push(newLine);

    }
    
    

    //addToMainDrawingObjectsArray(axleObjects);
    return axleObjects;
    /*



Return axleObjects

End Function
    */
}

function drawTyre(axle, parent, centreOfAxleX, centreOfAxleY, tyreWidth, tyreRadius, layerNumber, lastObjectNumberRef, drawingGroupName) {

    var horizontalTyreNib = 80;
    var verticalTyreNib = 40;
    var treadDepth = 20;
    
    var tyreObjects = [];
    
    var newLine;
    var sequenceNumber = 0;


    var blockNameChassisDetailDarkGrey, blockNameChassisDetailLightGrey, blockNameChassis;
    if (offerManager.getRigOps().getChassisObjectFromPath(parent).getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        blockNameChassis = 'CHASSIS';
    } else {
        blockNameChassis = 'CHASSIS1';
    }
        
    //'add the tyre to be filled
    lastObjectNumberRef.lastObjectNumber += 1;

    sequenceNumber += 1;
    /*

newLine = New UtilDrawingLine With {.StartX = centreOfAxleX,
                                    .StartY = centreOfAxleY,
                                    .EndX = centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib,
                                    .EndY = centreOfAxleY,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX, centreOfAxleY, centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib, centreOfAxleY, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib,
                                    .StartY = centreOfAxleY,
                                    .EndX = centreOfAxleX - tyreRadius + treadDepth,
                                    .EndY = centreOfAxleY + verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib, centreOfAxleY, centreOfAxleX - tyreRadius + treadDepth, centreOfAxleY + verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius + treadDepth,
                                    .StartY = centreOfAxleY + verticalTyreNib,
                                    .EndX = centreOfAxleX - tyreRadius,
                                    .EndY = centreOfAxleY + verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius + treadDepth, centreOfAxleY + verticalTyreNib, centreOfAxleX - tyreRadius, centreOfAxleY + verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius,
                                    .StartY = centreOfAxleY + verticalTyreNib,
                                    .EndX = centreOfAxleX - tyreRadius,
                                    .EndY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius, centreOfAxleY + verticalTyreNib, centreOfAxleX - tyreRadius, centreOfAxleY + tyreWidth - verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius,
                                    .StartY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .EndX = centreOfAxleX - tyreRadius + treadDepth,
                                    .EndY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius, centreOfAxleY + tyreWidth - verticalTyreNib, centreOfAxleX - tyreRadius + treadDepth, centreOfAxleY + tyreWidth - verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius + treadDepth,
                                    .StartY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .EndX = centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib,
                                    .EndY = centreOfAxleY + tyreWidth,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius + treadDepth, centreOfAxleY + tyreWidth - verticalTyreNib, centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib, centreOfAxleY + tyreWidth, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib,
                                    .StartY = centreOfAxleY + tyreWidth,
                                    .EndX = centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib,
                                    .EndY = centreOfAxleY + tyreWidth,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius + treadDepth + horizontalTyreNib, centreOfAxleY + tyreWidth, centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib, centreOfAxleY + tyreWidth, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib,
                                    .StartY = centreOfAxleY + tyreWidth,
                                    .EndX = centreOfAxleX + tyreRadius - treadDepth,
                                    .EndY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib, centreOfAxleY + tyreWidth, centreOfAxleX + tyreRadius - treadDepth, centreOfAxleY + tyreWidth - verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreRadius - treadDepth,
                                    .StartY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .EndX = centreOfAxleX + tyreRadius,
                                    .EndY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreRadius - treadDepth, centreOfAxleY + tyreWidth - verticalTyreNib, centreOfAxleX + tyreRadius, centreOfAxleY + tyreWidth - verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreRadius,
                                    .StartY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .EndX = centreOfAxleX + tyreRadius,
                                    .EndY = centreOfAxleY + verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreRadius, centreOfAxleY + tyreWidth - verticalTyreNib, centreOfAxleX + tyreRadius, centreOfAxleY + verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreRadius,
                                    .StartY = centreOfAxleY + verticalTyreNib,
                                    .EndX = centreOfAxleX + tyreRadius - treadDepth,
                                    .EndY = centreOfAxleY + verticalTyreNib,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreRadius, centreOfAxleY + verticalTyreNib, centreOfAxleX + tyreRadius - treadDepth, centreOfAxleY + verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreRadius - treadDepth,
                                    .StartY = centreOfAxleY + verticalTyreNib,
                                    .EndX = centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib,
                                    .EndY = centreOfAxleY,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreRadius - treadDepth, centreOfAxleY + verticalTyreNib, centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib, centreOfAxleY, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib,
                                    .StartY = centreOfAxleY,
                                    .EndX = centreOfAxleX,
                                    .EndY = centreOfAxleY,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreRadius - treadDepth - horizontalTyreNib, centreOfAxleY, centreOfAxleX, centreOfAxleY, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

//'add the inner and out lines on the tyre 
    lastObjectNumberRef.lastObjectNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius + treadDepth,
                                    .StartY = centreOfAxleY + verticalTyreNib,
                                    .EndX = centreOfAxleX + tyreRadius - treadDepth,
                                    .EndY = centreOfAxleY + verticalTyreNib,
                                    .PartOfClosedObject = False,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = 1}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius + treadDepth, centreOfAxleY + verticalTyreNib, centreOfAxleX + tyreRadius - treadDepth, centreOfAxleY + verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    lastObjectNumberRef.lastObjectNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreRadius + treadDepth,
                                    .StartY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .EndX = centreOfAxleX + tyreRadius - treadDepth,
                                    .EndY = centreOfAxleY + tyreWidth - verticalTyreNib,
                                    .PartOfClosedObject = False,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = 1}
tyreObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreRadius + treadDepth, centreOfAxleY + tyreWidth - verticalTyreNib, centreOfAxleX + tyreRadius - treadDepth, centreOfAxleY + tyreWidth - verticalTyreNib, layerNumber, blockNameChassis, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    tyreObjects.push(newLine);

    

    return tyreObjects;
    /*
Return tyreObjects


    */
}

function drawTyreSpacer(parent, centreOfAxleX, centreOfAxleY, tyreSpacerWidth, layerNumber, lastObjectNumberRef, drawingGroupName) {

    var tyreSpacerHeight = 400;

    var spacerObjects = [];
    var newLine;
    var sequenceNumber = 0;

    lastObjectNumberRef.lastObjectNumber += 1;

    sequenceNumber += 1;

    var blockNameChassisDetailDarkGrey;
    if (offerManager.getRigOps().getChassisObjectFromPath(parent).getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
        blockNameChassisDetailDarkGrey = 'CHASSIS_DETAIL_DARKGREY';
    } else {
        blockNameChassisDetailDarkGrey = 'CHASSIS_DETAIL_DARKGREY1';
    }

    /*
            
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX,
                                    .StartY = centreOfAxleY,
                                    .EndX = centreOfAxleX - tyreSpacerHeight / 2,
                                    .EndY = centreOfAxleY,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
spacerObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX, centreOfAxleY, centreOfAxleX - tyreSpacerHeight / 2, centreOfAxleY, layerNumber, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    spacerObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreSpacerHeight / 2,
                                    .StartY = centreOfAxleY,
                                    .EndX = centreOfAxleX - tyreSpacerHeight / 2,
                                    .EndY = centreOfAxleY + tyreSpacerWidth,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
spacerObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreSpacerHeight / 2, centreOfAxleY, centreOfAxleX - tyreSpacerHeight / 2, centreOfAxleY + tyreSpacerWidth, layerNumber, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    spacerObjects.push(newLine);


    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX - tyreSpacerHeight / 2,
                                    .StartY = centreOfAxleY + tyreSpacerWidth,
                                    .EndX = centreOfAxleX + tyreSpacerHeight / 2,
                                    .EndY = centreOfAxleY + tyreSpacerWidth,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
spacerObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX - tyreSpacerHeight / 2, centreOfAxleY + tyreSpacerWidth, centreOfAxleX + tyreSpacerHeight / 2, centreOfAxleY + tyreSpacerWidth, layerNumber, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    spacerObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreSpacerHeight / 2,
                                    .StartY = centreOfAxleY + tyreSpacerWidth,
                                    .EndX = centreOfAxleX + tyreSpacerHeight / 2,
                                    .EndY = centreOfAxleY,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
spacerObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreSpacerHeight / 2, centreOfAxleY + tyreSpacerWidth, centreOfAxleX + tyreSpacerHeight / 2, centreOfAxleY, layerNumber, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    spacerObjects.push(newLine);

    sequenceNumber += 1;
    /*
newLine = New UtilDrawingLine With {.StartX = centreOfAxleX + tyreSpacerHeight / 2,
                                    .StartY = centreOfAxleY,
                                    .EndX = centreOfAxleX,
                                    .EndY = centreOfAxleY,
                                    .PartOfClosedObject = True,
                                    .Layer = layerNumber,
                                    .ObjectName = "CHASSIS_DETAIL_DARKGREY",
                                    .ObjectNumber = objectNumber,
                                    .SequenceNumber = sequenceNumber}
spacerObjects.Add(newLine)
*/
    newLine = new Line(centreOfAxleX + tyreSpacerHeight / 2, centreOfAxleY, centreOfAxleX, centreOfAxleY, layerNumber, blockNameChassisDetailDarkGrey, lastObjectNumberRef.lastObjectNumber + '_' + sequenceNumber, "TRUE").setDrawingGroupName(drawingGroupName);
    spacerObjects.push(newLine);
    /*
Return spacerObjects
*/
    return spacerObjects;

}

function drawTyres(axleAngle, axleStartPointX, axleStartPointY, tyreStartAngle, axleEndPointX, axleEndPointY, tyreEndAngle, noOfTyres,
        tyreWidth, tyreRadius) {

    var newLine;
    var topLeftX, topLeftY, bottomLeftX, bottomLeftY, bottomRightX, bottomRightY, topRightX, topRightY;

    //draw the outer tyre(s)
    //determine the point in the middle outer part of the tyre
    topLeftX = axleStartPointX + Math.cos((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2;
    topLeftY = axleStartPointY + Math.sin((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2;
    //now determine the rest from there
    topLeftX = topLeftX + Math.cos((270 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreRadius;
    topLeftY = topLeftY + Math.sin((270 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreRadius;
    topRightX = topLeftX + Math.cos((axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth;
    topRightY = topLeftY + Math.sin((axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth;
    bottomRightX = topRightX + Math.cos((90 + axleAngle - tyreStartAngle) * Math.PI / 180) * 2 * tyreRadius;
    bottomRightY = topRightY + Math.sin((90 + axleAngle - tyreStartAngle) * Math.PI / 180) * 2 * tyreRadius;
    bottomLeftX = bottomRightX + Math.cos((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth;
    bottomLeftY = bottomRightY + Math.sin((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth;


    newLine = new Line(topLeftX, -topLeftY, topRightX, -topRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);
    newLine = new Line(topRightX, -topRightY, bottomRightX, -bottomRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);
    newLine = new Line(bottomRightX, -bottomRightY, bottomLeftX, -bottomLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);
    newLine = new Line(bottomLeftX, -bottomLeftY, topLeftX, -topLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);

    if (noOfTyres === 4) { 

        topLeftX = topLeftX + Math.cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        topLeftY = topLeftY + Math.sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        topRightX = topRightX + Math.cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        topRightY = topRightY + Math.sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomRightX = bottomRightX + Math.cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomRightY = bottomRightY + Math.sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomLeftX = bottomLeftX + Math.cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomLeftY = bottomLeftY + Math.sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);

        newLine = new Line(topLeftX, -topLeftY, topRightX, -topRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);
        newLine = new Line(topRightX, -topRightY, bottomRightX, -bottomRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);
        newLine = new Line(bottomRightX, -bottomRightY, bottomLeftX, -bottomLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);
        newLine = new Line(bottomLeftX, -bottomLeftY, topLeftX, -topLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);

    }
    /*
    Dim newLine As UtilDrawingObjectLine
Dim topLeft, bottomLeft, bottomRight, topRight As Point

'draw the outer tyre(s)
'determine the point in the middle outer part of the tyre
topLeft.X = axleStartPoint.X + Math.Cos((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2 * PixelsPerScaleUnit
topLeft.Y = axleStartPoint.Y + Math.Sin((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2 * PixelsPerScaleUnit
'now determine the rest from there
topLeft.X = topLeft.X + Math.Cos((270 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreRadius * PixelsPerScaleUnit
topLeft.Y = topLeft.Y + Math.Sin((270 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreRadius * PixelsPerScaleUnit
topRight.X = topLeft.X + Math.Cos((axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth * PixelsPerScaleUnit
topRight.Y = topLeft.Y + Math.Sin((axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth * PixelsPerScaleUnit
bottomRight.X = topRight.X + Math.Cos((90 + axleAngle - tyreStartAngle) * Math.PI / 180) * 2 * tyreRadius * PixelsPerScaleUnit
bottomRight.Y = topRight.Y + Math.Sin((90 + axleAngle - tyreStartAngle) * Math.PI / 180) * 2 * tyreRadius * PixelsPerScaleUnit
bottomLeft.X = bottomRight.X + Math.Cos((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth * PixelsPerScaleUnit
bottomLeft.Y = bottomRight.Y + Math.Sin((180 + axleAngle - tyreStartAngle) * Math.PI / 180) * tyreWidth * PixelsPerScaleUnit

newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topLeft.X, topLeft.Y, topRight.X, topRight.Y)
DrawingObjects.Add(newLine)
newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topRight.X, topRight.Y, bottomRight.X, bottomRight.Y)
DrawingObjects.Add(newLine)
newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomRight.X, bottomRight.Y, bottomLeft.X, bottomLeft.Y)
DrawingObjects.Add(newLine)
newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomLeft.X, bottomLeft.Y, topLeft.X, topLeft.Y)
DrawingObjects.Add(newLine)

If noOfTyres = 4 Then

    topLeft.X = topLeft.X + Math.Cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    topLeft.Y = topLeft.Y + Math.Sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    topRight.X = topRight.X + Math.Cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    topRight.Y = topRight.Y + Math.Sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomRight.X = bottomRight.X + Math.Cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomRight.Y = bottomRight.Y + Math.Sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomLeft.X = bottomLeft.X + Math.Cos((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomLeft.Y = bottomLeft.Y + Math.Sin((axleAngle - tyreStartAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit

    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topLeft.X, topLeft.Y, topRight.X, topRight.Y)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topRight.X, topRight.Y, bottomRight.X, bottomRight.Y)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomRight.X, bottomRight.Y, bottomLeft.X, bottomLeft.Y)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomLeft.X, bottomLeft.Y, topLeft.X, topLeft.Y)
    DrawingObjects.Add(newLine)

End If
*/

    //draw the inner tyre(s)
    //determine the point in the middle outer part of the tyre
    topRightX = axleEndPointX + Math.cos((axleAngle - tyreEndAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2;
    topRightY = axleEndPointY + Math.sin((axleAngle - tyreEndAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2;
    //now determine the rest from there
    topRightX = topRightX + Math.cos((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreRadius;
    topRightY = topRightY + Math.sin((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreRadius;
    bottomRightX = topRightX + Math.cos((90 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius;
    bottomRightY = topRightY + Math.sin((90 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius;
    bottomLeftX = bottomRightX + Math.cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreWidth;
    bottomLeftY = bottomRightY + Math.sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreWidth;
    topLeftX = bottomLeftX + Math.cos((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius;
    topLeftY = bottomLeftY + Math.sin((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius;

    newLine = new Line(topLeftX, -topLeftY, topRightX, -topRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);
    newLine = new Line(topRightX, -topRightY, bottomRightX, -bottomRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);
    newLine = new Line(bottomRightX, -bottomRightY, bottomLeftX, -bottomLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);
    newLine = new Line(bottomLeftX, -bottomLeftY, topLeftX, -topLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
    drawingObjectsArray.push(newLine);

    if (noOfTyres === 4) {

        topLeftX = topLeftX + Math.cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        topLeftY = topLeftY + Math.sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        topRightX = topRightX + Math.cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        topRightY = topRightY + Math.sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomLeftX = bottomLeftX + Math.cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomLeftY = bottomLeftY + Math.sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomRightX = bottomRightX + Math.cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);
        bottomRightY = bottomRightY + Math.sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + config.gapBetweenTyresInMm);

        newLine = new Line(topLeftX, -topLeftY, topRightX, -topRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);
        newLine = new Line(topRightX, -topRightY, bottomRightX, -bottomRightY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);
        newLine = new Line(bottomRightX, -bottomRightY, bottomLeftX, -bottomLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);
        newLine = new Line(bottomLeftX, -bottomLeftY, topLeftX, -topLeftY).setDrawingGroupName(config.OBJECT_TYPES.TURNING_CIRCLE);
        drawingObjectsArray.push(newLine);

    }

    /*
'draw the inner tyre(s)
'determine the point in the middle outer part of the tyre
topRight.X = axleEndPoint.X + Math.Cos((axleAngle - tyreEndAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2 * PixelsPerScaleUnit
topRight.Y = axleEndPoint.Y + Math.Sin((axleAngle - tyreEndAngle) * Math.PI / 180) * noOfTyres / 2 * tyreWidth / 2 * PixelsPerScaleUnit
'now determine the rest from there
topRight.X = topRight.X + Math.Cos((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreRadius * PixelsPerScaleUnit
topRight.Y = topRight.Y + Math.Sin((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreRadius * PixelsPerScaleUnit
bottomRight.X = topRight.X + Math.Cos((90 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius * PixelsPerScaleUnit
bottomRight.Y = topRight.Y + Math.Sin((90 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius * PixelsPerScaleUnit
bottomLeft.X = bottomRight.X + Math.Cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreWidth * PixelsPerScaleUnit
bottomLeft.Y = bottomRight.Y + Math.Sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * tyreWidth * PixelsPerScaleUnit
topLeft.X = bottomLeft.X + Math.Cos((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius * PixelsPerScaleUnit
topLeft.Y = bottomLeft.Y + Math.Sin((270 + axleAngle - tyreEndAngle) * Math.PI / 180) * 2 * tyreRadius * PixelsPerScaleUnit

newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topLeft.X, topLeft.Y, topRight.X, topRight.Y)
DrawingObjects.Add(newLine)
newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topRight.X, topRight.Y, bottomRight.X, bottomRight.Y)
DrawingObjects.Add(newLine)
newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomRight.X, bottomRight.Y, bottomLeft.X, bottomLeft.Y)
DrawingObjects.Add(newLine)
newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomLeft.X, bottomLeft.Y, topLeft.X, topLeft.Y)
DrawingObjects.Add(newLine)

If noOfTyres = 4 Then

    topLeft.X = topLeft.X + Math.Cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    topLeft.Y = topLeft.Y + Math.Sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    topRight.X = topRight.X + Math.Cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    topRight.Y = topRight.Y + Math.Sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomLeft.X = bottomLeft.X + Math.Cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomLeft.Y = bottomLeft.Y + Math.Sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomRight.X = bottomRight.X + Math.Cos((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit
    bottomRight.Y = bottomRight.Y + Math.Sin((180 + axleAngle - tyreEndAngle) * Math.PI / 180) * (tyreWidth + ApplicationGlobals.GapBetweenTyresInMm) * PixelsPerScaleUnit

    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topLeft.X, topLeft.Y, topRight.X, topRight.Y)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, topRight.X, topRight.Y, bottomRight.X, bottomRight.Y)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomRight.X, bottomRight.Y, bottomLeft.X, bottomLeft.Y)
    DrawingObjects.Add(newLine)
    newLine = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, bottomLeft.X, bottomLeft.Y, topLeft.X, topLeft.Y)
    DrawingObjects.Add(newLine)

End If
    */
}

function addArc(centreX, centreY, radius, startAngle, sweep) {
    var newArc = new Arc(curVehicle.getPortionOfOverallLengthOverhangingFront() + centreX, centreY, radius, startAngle, sweep, true);
    newArc.setDrawingOrder(9000);
    drawingObjectsArray.push(newArc);
}

function addXMLArc(startX, startY, radius, startAngle, sweep, antiClockwise, layerNumber, blockName, objNumSeqNum, closedIndicator) {
    drawingObjectsArray.push(new Arc(startX, startY, radius, startAngle, sweep, antiClockwise, layerNumber, blockName, objNumSeqNum, closedIndicator));
}

function addLine(startX, startY, endX, endY) {
    var newLine = new Line(curVehicle.getPortionOfOverallLengthOverhangingFront() + startX, startY, curVehicle.getPortionOfOverallLengthOverhangingFront() + endX, endY);
    newLine.setDrawingOrder(9000);
    drawingObjectsArray.push(newLine);
}

function addCircle(centreX, centreY, radius, circleType, drawGroundline, drawingGroupName) {
    var newCircle = new Circle(curVehicle.getPortionOfOverallLengthOverhangingFront() + centreX, centreY, radius, circleType, true);
    if (drawGroundline === true) {
        newCircle.setDrawGroundline(true);
    }
    if (drawingGroupName !== undefined) {
        newCircle.setDrawingGroupName(drawingGroupName);
    }
    if (drawingGroupName && drawingGroupName.indexOf(config.OBJECT_TYPES.AXLES) !== -1 || drawingGroupName && drawingGroupName.indexOf(config.OBJECT_TYPES.AXLE_GROUPS) !== -1) {
        newCircle.setDrawingOrder(4000);
    } else {
        newCircle.setDrawingOrder(9000);
    }
    
    drawingObjectsArray.push(newCircle);
}

function addPayloadRect(chassisObject, x, y, width, height, bodyType, optionalFill, optionalAlpha) {
    var newRect = new Rect(x, y, width, height, optionalFill, optionalAlpha);
    if (bodyType === config.BODY_TYPES.HOOKLIFT) {
        //for hooklift we want to draw the rect border the same as the fill colour
        newRect.setLineColour(optionalFill);
    }
    newRect.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD);
    drawingObjectsArray.push(newRect);
}

function populateDrawingObjectsForTemplateVehicle(vehicle) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) { 
        populateDrawingObjectsForTemplateVehicleSideView(vehicle);
    } else {
        populateDrawingObjectsForTemplateVehicleTopView(vehicle);
    }
}

function populateDrawingObjectsForTemplateVehicleTopView(vehicle) {
    var chassisCoords = {};

    //chassisCoords.cabFrontRightCornerOnWallToWallX = Math.cos((180 + 90) * Math.PI / 180) * vehicle.getCabWidth();
    //chassisCoords.cabFrontRightCornerOnWallToWallY = Math.sin((180 + 90) * Math.PI / 180) * vehicle.getCabWidth();

    //chassisCoords.cabFrontRightCornerOnWallToWallX = 0;
    //chassisCoords.cabFrontRightCornerOnWallToWallY = 0;
    
    //drawVehicle(chassisCoords, 0, 0, 90, 0, 90, config.CHASSIS_OBJECT_TYPES.VEHICLE);

    var drawingGroupName = config.CHASSIS_OBJECT_TYPES.VEHICLE;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        drawingGroupName = config.OBJECT_TYPES.TURNING_CIRCLE;
    }

    drawVehicleTopView(drawingGroupName);
    
    
}

function populateDrawingObjectsForTemplateVehicleSideView(vehicle) {


    var bumperToBackOfCab = vehicle.getBumperToBackOfCab(),
        cabHeight = vehicle.getCabHeight(),
        frontOverhang = vehicle.getFrontOverhang(),
        rearOverhang = vehicle.getRearOverhang(),
        wheelbaseTheoretical = vehicle.getWheelbaseTheoretical(),
        bumperToStartOfBodyInternal = vehicle.getBumperToStartOfBodyInternal(),
        bodyLengthInternal = vehicle.getBodyLengthInternal(),
        rearProtrusion = vehicle.getRearProtrusion(),
        axle1FrontToWheelbaseTheoreticalStart = vehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
        fifthWheelOffset = vehicle.getFifthWheelOffset(),
        fifthWheelHeightFromGround = vehicle.getFifthWheelHeightFromGround(),
        axle1FrontToAxle2Front = vehicle.getAxle1FrontToAxle2Front(),
        axle2FrontToAxle1Rear = vehicle.getAxle2FrontToAxle1Rear(),
        axle1RearToAxle2Rear = vehicle.getAxle1RearToAxle2Rear(),
        axle2RearToAxle3Rear = vehicle.getAxle2RearToAxle3Rear(),
        axle3RearToAxle4Rear = vehicle.getAxle3RearToAxle4Rear(),
        control = vehicle.getControl(),
        rearMostProtrudingItemBehindCabToRearAxles = vehicle.getRearMostProtrudingItemBehindCabToRearAxles(),
        tareFifthWheelIncluded = vehicle.getTareFifthWheelIncluded(),
        chassisHeightFront = vehicle.getChassisHeightFront(),
        chassisHeightRear = vehicle.getChassisHeightRear(),
        axles = vehicle.getAxles(),
        overallLengthExclAccessories = vehicle.getOverallLengthExclAccessories(),
        overallLengthInclAccessories = vehicle.getOverallLengthInclAccessories(),
        type = vehicle.getType(),
        cabToAxles = offerManager.getRigOps().getCabToAxles();

    var axleNumber = 0, frontAddition, rearAddition, axle1Front, axle2Front, axle1Rear, axle2Rear, axle3Rear, axle4Rear;

    axle1Front = vehicle.getAxlesHolder().getAxle1Front();
    axle2Front = vehicle.getAxlesHolder().getAxle2Front();
    axle1Rear = vehicle.getAxlesHolder().getAxle1Rear();
    axle2Rear = vehicle.getAxlesHolder().getAxle2Rear();
    axle3Rear = vehicle.getAxlesHolder().getAxle3Rear();
    axle4Rear = vehicle.getAxlesHolder().getAxle4Rear();

    

    frontAddition = vehicle.getFrontAddition();
    rearAddition = vehicle.getRearAddition();

    //if (chassisHeightFront > chassisHeightRear) {
    //    frontAddition = 0;
    //    rearAddition = chassisHeightFront - chassisHeightRear;
    //} else {
    //    frontAddition = chassisHeightRear - chassisHeightFront;
    //    rearAddition = 0;
    //}

    var medArcRadius = 50;
    var smallArcRadius = 25;
    //addLine(0, axle1Front.TyreRadius + frontAddition + smallArcRadius, 0, vehicle.cabHeight * 0.6 - 200 + frontAddition - smallArcRadius);
    //addArc(smallArcRadius, vehicle.cabHeight * 0.6 - 200 + frontAddition - smallArcRadius, smallArcRadius, 180, 90);
    //addLine(smallArcRadius, axle1Front.TyreRadius + frontAddition, smallArcRadius, vehicle.cabHeight * 0.6 - 200 + frontAddition);
    //addLine(smallArcRadius, vehicle.cabHeight * 0.6 - 200 + frontAddition, vehicle.bumperToBackOfCab * 0.45, vehicle.cabHeight * 0.6 - 200 + frontAddition);
    switch (control) {
        case config.CONTROL_TYPES.NORMAL://bonnet
            /*
        
            'grill bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = smallArcRadius
            w.Y = axle1Front.TyreRadius + frontAddition + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 90
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            //grill bottom arc
            addArc(smallArcRadius, axle1Front.getTyreRadius() + frontAddition + smallArcRadius, smallArcRadius, 90, 90);
            /*
            'front
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = 0
            x.StartY = axle1Front.TyreRadius + frontAddition + smallArcRadius
            x.EndX = 0
            x.EndY = CabHeight * 0.6 - 200 + frontAddition - smallArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(0, axle1Front.getTyreRadius() + frontAddition + smallArcRadius, 0, cabHeight * 0.6 - 200 + frontAddition - smallArcRadius);
            /*
            'grill top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = smallArcRadius
            w.Y = CabHeight * 0.6 - 200 + frontAddition - smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(smallArcRadius, cabHeight * 0.6 - 200 + frontAddition - smallArcRadius, smallArcRadius, 180, 90);
            /*
            'grill inner
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = axle1Front.TyreRadius + frontAddition
            x.EndX = smallArcRadius
            x.EndY = CabHeight * 0.6 - 200 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, axle1Front.getTyreRadius() + frontAddition, smallArcRadius, cabHeight * 0.6 - 200 + frontAddition);
            /*
            'top of bonnet
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = CabHeight * 0.6 - 200 + frontAddition
            x.EndX = BumperToBackOfCab * 0.45
            x.EndY = CabHeight * 0.66 - 200 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, cabHeight * 0.6 - 200 + frontAddition, bumperToBackOfCab * 0.45, cabHeight * 0.66 - 200 + frontAddition);
            /*
                'windscreen
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.45
            x.StartY = CabHeight * 0.66 - 200 + frontAddition
            x.EndX = BumperToBackOfCab * 0.6
            x.EndY = CabHeight * 0.98 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.45, cabHeight * 0.66 - 200 + frontAddition, bumperToBackOfCab * 0.6, cabHeight * 0.98 + frontAddition);
            /*
                'windscreen bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.45
            x.StartY = CabHeight * 0.66 - 200 + frontAddition
            x.EndX = BumperToBackOfCab * 0.46 + medArcRadius
            x.EndY = CabHeight * 0.66 - 200 + frontAddition + medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.45, cabHeight * 0.66 - 200 + frontAddition, bumperToBackOfCab * 0.46 + medArcRadius, cabHeight * 0.66 - 200 + frontAddition + medArcRadius);
            /*
                'windscreen inner
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.46 + medArcRadius
            x.StartY = CabHeight * 0.66 - 200 + frontAddition + medArcRadius
            x.EndX = BumperToBackOfCab * 0.6 + medArcRadius
            x.EndY = CabHeight * 0.98 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.46 + medArcRadius, cabHeight * 0.66 - 200 + frontAddition + medArcRadius, bumperToBackOfCab * 0.6 + medArcRadius, cabHeight * 0.98 + frontAddition);
            /*
            'top line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.6
            x.StartY = CabHeight * 0.98 + frontAddition
            x.EndX = BumperToBackOfCab * 0.98
            x.EndY = CabHeight * 0.98 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.6, cabHeight * 0.98 + frontAddition, bumperToBackOfCab * 0.98, cabHeight * 0.98 + frontAddition);
            /*
        
            'rear slant
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.98
            x.StartY = CabHeight * 0.98 + frontAddition
            x.EndX = BumperToBackOfCab
            x.EndY = CabHeight * 0.92 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.98, cabHeight * 0.98 + frontAddition, bumperToBackOfCab, cabHeight * 0.92 + frontAddition);
            /*
                'back of cab
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab
            x.StartY = CabHeight * 0.92 + frontAddition
            x.EndX = BumperToBackOfCab
            x.EndY = axle1Front.TyreRadius * 2 + frontAddition + medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab, cabHeight * 0.92 + frontAddition, bumperToBackOfCab, axle1Front.getTyreRadius() * 2 + frontAddition + medArcRadius);
            /*
            'back of cab bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab - medArcRadius
            w.Y = axle1Front.TyreRadius * 2 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab - medArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition + medArcRadius, medArcRadius, 0, 90);
            /*
            'back of cab bottom rear
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab - medArcRadius
            x.StartY = axle1Front.TyreRadius * 2 + frontAddition
            x.EndX = FrontOverhang + axle1Front.TyreRadius
            x.EndY = axle1Front.TyreRadius * 2 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab - medArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition, frontOverhang + axle1Front.getTyreRadius(), axle1Front.getTyreRadius() * 2 + frontAddition);
            /*
            'cab hood front arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.6 + CabHeight * 0.02
            w.Y = CabHeight * 0.98 + frontAddition
            w.Radius = CabHeight * 0.02
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.6 + cabHeight * 0.02, cabHeight * 0.98 + frontAddition, cabHeight * 0.02, 180, 90);
            /*
            'cab hood top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.62
            x.StartY = CabHeight + frontAddition
            x.EndX = BumperToBackOfCab * 0.96
            x.EndY = CabHeight + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.62, cabHeight + frontAddition, bumperToBackOfCab * 0.96, cabHeight + frontAddition);
            /*
            'cab hood rear arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.98 - CabHeight * 0.02
            w.Y = CabHeight * 0.98 + frontAddition
            w.Radius = CabHeight * 0.02
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.98 - cabHeight * 0.02, cabHeight * 0.98 + frontAddition, cabHeight * 0.02, 270, 90);
            /*
            'cab front bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = axle1Front.TyreRadius + frontAddition
            x.EndX = FrontOverhang - axle1Front.TyreRadius - 50 - smallArcRadius
            x.EndY = axle1Front.TyreRadius + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, axle1Front.getTyreRadius() + frontAddition, frontOverhang - axle1Front.getTyreRadius() - 50 - smallArcRadius, axle1Front.getTyreRadius() + frontAddition);
            /*
            'wheel arch front arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang - axle1Front.TyreRadius - 50 - smallArcRadius
            w.Y = axle1Front.TyreRadius + frontAddition + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang - axle1Front.getTyreRadius() - 50 - smallArcRadius, axle1Front.getTyreRadius() + frontAddition + smallArcRadius, smallArcRadius, 0, 90);
            /*
            'wheel arch
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang
            w.Y = axle1Front.TyreRadius + frontAddition
            w.Radius = axle1Front.TyreRadius + 50
            w.StartAngle = 182
            w.SweepAngle = 180
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang, axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius() + 50, 182, 180);
            /*
            'wheel arch rear arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang
            w.Y = axle1Front.TyreRadius + frontAddition
            w.Radius = axle1Front.TyreRadius + 150
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang, axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius() + 150, 270, 90);
            /*
                'wheel arch botom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang + axle1Front.TyreRadius + 100
            w.Y = axle1Front.TyreRadius + frontAddition
            w.Radius = 50
            w.StartAngle = 0
            w.SweepAngle = 180
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang + axle1Front.getTyreRadius() + 100, axle1Front.getTyreRadius() + frontAddition, 50, 0, 180);
            /*
            'wheel arch top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang
            x.StartY = axle1Front.TyreRadius * 2 + 150 + frontAddition
            x.EndX = smallArcRadius
            x.EndY = axle1Front.TyreRadius * 2 + 150 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang, axle1Front.getTyreRadius() * 2 + 150 + frontAddition, smallArcRadius, axle1Front.getTyreRadius() * 2 + 150 + frontAddition);
            /*
            'wheel arch front
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = axle1Front.TyreRadius * 2 + 150 + frontAddition
            x.EndX = smallArcRadius
            x.EndY = axle1Front.TyreRadius + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, axle1Front.getTyreRadius() * 2 + 150 + frontAddition, smallArcRadius, axle1Front.getTyreRadius() + frontAddition);
            /*
            'fuel tank rear top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab - medArcRadius
            w.Y = axle1Front.TyreRadius * 2 + frontAddition - 50 - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab - medArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition - 50 - medArcRadius, medArcRadius, 270, 90);
            /*
            'fuel tank rear
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab
            x.StartY = axle1Front.TyreRadius * 2 + frontAddition - 50 - medArcRadius
            x.EndX = BumperToBackOfCab
            x.EndY = axle1Front.TyreRadius + frontAddition + medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab, axle1Front.getTyreRadius() * 2 + frontAddition - 50 - medArcRadius, bumperToBackOfCab, axle1Front.getTyreRadius() + frontAddition + medArcRadius);
            /*
            'fuel tank rear bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab - medArcRadius
            w.Y = axle1Front.TyreRadius + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab - medArcRadius, axle1Front.getTyreRadius() + frontAddition + medArcRadius, medArcRadius, 0, 90);
            /*
            'fuel tank bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab - medArcRadius
            x.StartY = axle1Front.TyreRadius + frontAddition
            x.EndX = FrontOverhang + axle1Front.TyreRadius + 200 + medArcRadius
            x.EndY = axle1Front.TyreRadius + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab - medArcRadius, axle1Front.getTyreRadius() + frontAddition, frontOverhang + axle1Front.getTyreRadius() + 200 + medArcRadius, axle1Front.getTyreRadius() + frontAddition);
            /*
            'fuel tank front bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang + axle1Front.TyreRadius + 200 + medArcRadius
            w.Y = axle1Front.TyreRadius + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 90
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang + axle1Front.getTyreRadius() + 200 + medArcRadius, axle1Front.getTyreRadius() + frontAddition + medArcRadius, medArcRadius, 90, 90);
            /*
            'fuel tank front
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang + axle1Front.TyreRadius + 200
            x.StartY = axle1Front.TyreRadius + frontAddition + medArcRadius
            x.EndX = FrontOverhang + axle1Front.TyreRadius + 200
            x.EndY = axle1Front.TyreRadius * 2 + frontAddition - 50 - medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang + axle1Front.getTyreRadius() + 200, axle1Front.getTyreRadius() + frontAddition + medArcRadius, frontOverhang + axle1Front.getTyreRadius() + 200, axle1Front.getTyreRadius() * 2 + frontAddition - 50 - medArcRadius);
            /*
            'fuel tank front top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang + axle1Front.TyreRadius + 200 + medArcRadius
            w.Y = axle1Front.TyreRadius * 2 + frontAddition - 50 - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang + axle1Front.getTyreRadius() + 200 + medArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition - 50 - medArcRadius, medArcRadius, 180, 90);
            /*
            'fuel tank top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang + axle1Front.TyreRadius + 200 + medArcRadius
            x.StartY = axle1Front.TyreRadius * 2 + frontAddition - 50
            x.EndX = BumperToBackOfCab - medArcRadius
            x.EndY = axle1Front.TyreRadius * 2 + frontAddition - 50
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang + axle1Front.getTyreRadius() + 200 + medArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition - 50, bumperToBackOfCab - medArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition - 50);
            /*
                'light
            'bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = axle1Front.TyreRadius + 200 + frontAddition
            x.EndX = 150
            x.EndY = axle1Front.TyreRadius + 200 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, axle1Front.getTyreRadius() + 200 + frontAddition, 150, axle1Front.getTyreRadius() + 200 + frontAddition);
            /*
            'bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = 150
            w.Y = axle1Front.TyreRadius + 200 + frontAddition + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(150, axle1Front.getTyreRadius() + 200 + frontAddition + smallArcRadius, smallArcRadius, 0, 90);
            /*
                'middle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius + 150
            x.StartY = axle1Front.TyreRadius + frontAddition + 200 + smallArcRadius
            x.EndX = smallArcRadius + 150
            x.EndY = axle1Front.TyreRadius + frontAddition + 450 - smallArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius + 150, axle1Front.getTyreRadius() + frontAddition + 200 + smallArcRadius, smallArcRadius + 150, axle1Front.getTyreRadius() + frontAddition + 450 - smallArcRadius);
            /*
            'top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = 150
            w.Y = axle1Front.TyreRadius + frontAddition + 450 - smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(150, axle1Front.getTyreRadius() + frontAddition + 450 - smallArcRadius, smallArcRadius, 270, 90);
            /*
                'top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = 150
            x.StartY = axle1Front.TyreRadius + frontAddition + 450
            x.EndX = smallArcRadius
            x.EndY = axle1Front.TyreRadius + frontAddition + 450
            _SideViewDrawingObjects.Add(x)
            */
            addLine(150, axle1Front.getTyreRadius() + frontAddition + 450, smallArcRadius, axle1Front.getTyreRadius() + frontAddition + 450);
            /*
            'front bottom of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.5
            x.StartY = CabHeight * 0.4 + frontAddition + medArcRadius
            x.EndX = BumperToBackOfCab * 0.5
            x.EndY = CabHeight * 0.55 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.5, cabHeight * 0.4 + frontAddition + medArcRadius, bumperToBackOfCab * 0.5, cabHeight * 0.55 + frontAddition);
            /*
            'front top of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.5
            x.StartY = CabHeight * 0.55 + frontAddition
            x.EndX = BumperToBackOfCab * 0.65
            x.EndY = CabHeight * 0.94 + frontAddition - medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.5, cabHeight * 0.55 + frontAddition, bumperToBackOfCab * 0.65, cabHeight * 0.94 + frontAddition - medArcRadius);
            /*
                'front top door arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.65 + medArcRadius
            w.Y = CabHeight * 0.94 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.65 + medArcRadius, cabHeight * 0.94 + frontAddition - medArcRadius, medArcRadius, 180, 90);
            /*
                'top of door
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.65 + medArcRadius
            x.StartY = CabHeight * 0.94 + frontAddition
            x.EndX = BumperToBackOfCab * 0.95 - medArcRadius
            x.EndY = CabHeight * 0.94 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.65 + medArcRadius, cabHeight * 0.94 + frontAddition, bumperToBackOfCab * 0.95 - medArcRadius, cabHeight * 0.94 + frontAddition);
            /*
                'rear top door arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.95 - medArcRadius
            w.Y = CabHeight * 0.94 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.95 - medArcRadius, cabHeight * 0.94 + frontAddition - medArcRadius, medArcRadius, 270, 90);
            /*
            'rear of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.95
            x.StartY = CabHeight * 0.94 + frontAddition - medArcRadius
            x.EndX = BumperToBackOfCab * 0.95
            x.EndY = CabHeight * 0.4 + frontAddition + medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.95, cabHeight * 0.94 + frontAddition - medArcRadius, bumperToBackOfCab * 0.95, cabHeight * 0.4 + frontAddition + medArcRadius);
            /*
            'rear bottom door arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.95 - medArcRadius
            w.Y = CabHeight * 0.4 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.95 - medArcRadius, cabHeight * 0.4 + frontAddition + medArcRadius, medArcRadius, 0, 90);
            /*
            'bottom of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.95 - medArcRadius
            x.StartY = CabHeight * 0.4 + frontAddition
            x.EndX = BumperToBackOfCab * 0.5 + medArcRadius
            x.EndY = CabHeight * 0.4 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.95 - medArcRadius, cabHeight * 0.4 + frontAddition, bumperToBackOfCab * 0.5 + medArcRadius, cabHeight * 0.4 + frontAddition);
            /*
                'front bottom door arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.5 + medArcRadius
            w.Y = CabHeight * 0.4 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 90
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.5 + medArcRadius, cabHeight * 0.4 + frontAddition + medArcRadius, medArcRadius, 90, 90);
            /*
            'rear of door handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.9
            x.StartY = CabHeight * 0.4 + 120 + frontAddition
            x.EndX = BumperToBackOfCab * 0.9
            x.EndY = CabHeight * 0.4 + 50 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.9, cabHeight * 0.4 + 120 + frontAddition, bumperToBackOfCab * 0.9, cabHeight * 0.4 + 50 + frontAddition);
            /*
            'bottom handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.9
            x.StartY = CabHeight * 0.4 + 50 + frontAddition
            x.EndX = BumperToBackOfCab * 0.9 - 200
            x.EndY = CabHeight * 0.4 + 50 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.9, cabHeight * 0.4 + 50 + frontAddition, bumperToBackOfCab * 0.9 - 200, cabHeight * 0.4 + 50 + frontAddition);
            /*
            'front of door handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.9 - 200
            x.StartY = CabHeight * 0.4 + 120 + frontAddition
            x.EndX = BumperToBackOfCab * 0.9 - 200
            x.EndY = CabHeight * 0.4 + 50 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.9 - 200, cabHeight * 0.4 + 120 + frontAddition, bumperToBackOfCab * 0.9 - 200, cabHeight * 0.4 + 50 + frontAddition);
            /*
            'top of  handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.9
            x.StartY = CabHeight * 0.4 + 120 + frontAddition
            x.EndX = BumperToBackOfCab * 0.9 - 200
            x.EndY = CabHeight * 0.4 + 120 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.9, cabHeight * 0.4 + 120 + frontAddition, bumperToBackOfCab * 0.9 - 200, cabHeight * 0.4 + 120 + frontAddition);
            /*
                'front bottom window arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.6 + medArcRadius
            w.Y = CabHeight * 0.63 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 90
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.6 + medArcRadius, cabHeight * 0.63 + frontAddition + medArcRadius, medArcRadius, 90, 90);
            /*
            'front bottom of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.6
            x.StartY = CabHeight * 0.63 + frontAddition + medArcRadius
            x.EndX = BumperToBackOfCab * 0.6
            x.EndY = CabHeight * 0.7 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.6, cabHeight * 0.63 + frontAddition + medArcRadius, bumperToBackOfCab * 0.6, cabHeight * 0.7 + frontAddition);
            /*
            'front top of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.6
            x.StartY = CabHeight * 0.7 + frontAddition
            x.EndX = BumperToBackOfCab * 0.67
            x.EndY = CabHeight * 0.9 + frontAddition - medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.6, cabHeight * 0.7 + frontAddition, bumperToBackOfCab * 0.67, cabHeight * 0.9 + frontAddition - medArcRadius);
            /*
            'front top window arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.67 + medArcRadius
            w.Y = CabHeight * 0.9 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.67 + medArcRadius, cabHeight * 0.9 + frontAddition - medArcRadius, medArcRadius, 180, 90);
            /*
            'top of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.67 + medArcRadius
            x.StartY = CabHeight * 0.9 + frontAddition
            x.EndX = BumperToBackOfCab * 0.91 - medArcRadius
            x.EndY = CabHeight * 0.9 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.67 + medArcRadius, cabHeight * 0.9 + frontAddition, bumperToBackOfCab * 0.91 - medArcRadius, cabHeight * 0.9 + frontAddition);
            /*
            'rear top window arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.91 - medArcRadius
            w.Y = CabHeight * 0.9 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.91 - medArcRadius, cabHeight * 0.9 + frontAddition - medArcRadius, medArcRadius, 270, 90);
            /*
            'rear of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.91
            x.StartY = CabHeight * 0.9 + frontAddition - medArcRadius
            x.EndX = BumperToBackOfCab * 0.91
            x.EndY = CabHeight * 0.67 + frontAddition + medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.91, cabHeight * 0.9 + frontAddition - medArcRadius, bumperToBackOfCab * 0.91, cabHeight * 0.67 + frontAddition + medArcRadius);
            /*
                'rear bottom window arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.91 - medArcRadius
            w.Y = CabHeight * 0.67 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.91 - medArcRadius, cabHeight * 0.67 + frontAddition + medArcRadius, medArcRadius, 0, 90);
            /*
                'bottom of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.91 - medArcRadius
            x.StartY = CabHeight * 0.67 + frontAddition
            x.EndX = BumperToBackOfCab * 0.6 + medArcRadius
            x.EndY = CabHeight * 0.63 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.91 - medArcRadius, cabHeight * 0.67 + frontAddition, bumperToBackOfCab * 0.6 + medArcRadius, cabHeight * 0.63 + frontAddition);
            /*
                'draw chassis
            'top line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab
            x.StartY = ChassisHeightFront + frontAddition
            x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.EndY = ChassisHeightRear + rearAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab, chassisHeightFront + frontAddition, frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear + rearAddition);
            /*
            'rear line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.StartY = ChassisHeightRear + rearAddition
            x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.EndY = ChassisHeightRear - 200 + rearAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear + rearAddition, frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear - 200 + rearAddition);
            /*
            'rear line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.StartY = ChassisHeightRear - 200 + rearAddition
            x.EndX = BumperToBackOfCab
            x.EndY = ChassisHeightFront - 200 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear - 200 + rearAddition, bumperToBackOfCab, chassisHeightFront - 200 + rearAddition);
            break;

        case config.CONTROL_TYPES.FORWARD://fc

            /*
            'bottom bumper arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = smallArcRadius
            w.Y = 200 + frontAddition + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 90
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(smallArcRadius, 200 + frontAddition + smallArcRadius, smallArcRadius, 90, 90);
            /*
            'front of bumper
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = 0
            x.StartY = 200 + frontAddition + smallArcRadius
            x.EndX = 0
            x.EndY = 400 + frontAddition - smallArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(0, 200 + frontAddition + smallArcRadius, 0, 400 + frontAddition - smallArcRadius);
            /*
            'top bumper arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = smallArcRadius
            w.Y = 400 + frontAddition - smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(smallArcRadius, 400 + frontAddition - smallArcRadius, smallArcRadius, 180, 90);
            /*
            'front
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = 400 + frontAddition
            x.EndX = smallArcRadius
            x.EndY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, 400 + frontAddition, smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition);
            /*
            'windscreen
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
            x.EndX = BumperToBackOfCab * 0.2
            x.EndY = CabHeight * 0.96 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition, bumperToBackOfCab * 0.2, cabHeight * 0.96 + frontAddition);
            /*
            'windscreen bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
            x.EndX = 50
            x.EndY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition, 50, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition);
            /*
                'windscreen bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = 50
            w.Y = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(50, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius, smallArcRadius, 0, 90);
            /*
                'windscreen inner
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius + 50
            x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius
            x.EndX = BumperToBackOfCab * 0.2 + 50
            x.EndY = CabHeight * 0.96 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius + 50, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius, bumperToBackOfCab * 0.2 + 50, cabHeight * 0.96 + frontAddition);
            /*
            'windscreen top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.2
            x.StartY = CabHeight * 0.96 + frontAddition
            x.EndX = BumperToBackOfCab * 0.93 + CabHeight * 0.04
            x.EndY = CabHeight * 0.96 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.2, cabHeight * 0.96 + frontAddition, bumperToBackOfCab * 0.93 + cabHeight * 0.04, cabHeight * 0.96 + frontAddition);
            /*
                'cab top front windscreen
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.2 + CabHeight * 0.04
            w.Y = CabHeight * 0.96 + frontAddition
            w.Radius = CabHeight * 0.04
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.2 + cabHeight * 0.04, cabHeight * 0.96 + frontAddition, cabHeight * 0.04, 180, 90);
            /*
            'cab hood top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.2 + CabHeight * 0.04
            x.StartY = CabHeight + frontAddition
            x.EndX = BumperToBackOfCab * 0.93
            x.EndY = CabHeight + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.2 + cabHeight * 0.04, cabHeight + frontAddition, bumperToBackOfCab * 0.93, cabHeight + frontAddition);
            /*
                'cab hood rear arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.93
            w.Y = CabHeight * 0.96 + frontAddition
            w.Radius = CabHeight * 0.04
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.93, cabHeight * 0.96 + frontAddition, cabHeight * 0.04, 270, 90);
            /*
            'rear slant
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.93 + CabHeight * 0.04
            x.StartY = CabHeight * 0.96 + frontAddition
            x.EndX = BumperToBackOfCab
            x.EndY = CabHeight * 0.92 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.93 + cabHeight * 0.04, cabHeight * 0.96 + frontAddition, bumperToBackOfCab, cabHeight * 0.92 + frontAddition);
            /*
            'back of cab
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab
            x.StartY = CabHeight * 0.92 + frontAddition
            x.EndX = BumperToBackOfCab
            x.EndY = axle1Front.TyreRadius * 2 + frontAddition + 50 + smallArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab, cabHeight * 0.92 + frontAddition, bumperToBackOfCab, axle1Front.getTyreRadius() * 2 + frontAddition + 50 + smallArcRadius);
            /*
                'back of cab back arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab - smallArcRadius
            w.Y = axle1Front.TyreRadius * 2 + frontAddition + 50 + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab - smallArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition + 50 + smallArcRadius, smallArcRadius, 0, 90);
            /*
            'back of wheel arch
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab - smallArcRadius
            x.StartY = axle1Front.TyreRadius * 2 + frontAddition + 50
            x.EndX = FrontOverhang
            x.EndY = axle1Front.TyreRadius * 2 + frontAddition + 50
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab - smallArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition + 50, frontOverhang, axle1Front.getTyreRadius() * 2 + frontAddition + 50);
            /*
            'wheel arch
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang
            w.Y = axle1Front.TyreRadius + frontAddition
            w.Radius = axle1Front.TyreRadius + 50
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang, axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius() + 50, 180, 90);
            /*
                'bottom of wheel arch
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang - axle1Front.TyreRadius - 50
            x.StartY = axle1Front.TyreRadius + frontAddition
            x.EndX = FrontOverhang - axle1Front.TyreRadius - 100
            x.EndY = 200 + frontAddition + smallArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang - axle1Front.getTyreRadius() - 50, axle1Front.getTyreRadius() + frontAddition, frontOverhang - axle1Front.getTyreRadius() - 100, 200 + frontAddition + smallArcRadius);
            /*
            'cab bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang - axle1Front.TyreRadius - 100 - smallArcRadius
            w.Y = 200 + frontAddition + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 200 + frontAddition + smallArcRadius, smallArcRadius, 0, 90);
            /*
            'cab bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang - axle1Front.TyreRadius - 100 - smallArcRadius
            x.StartY = 200 + frontAddition
            x.EndX = smallArcRadius
            x.EndY = 200 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 200 + frontAddition, smallArcRadius, 200 + frontAddition);
            /*
                'bumper bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang - axle1Front.getgetTyreRadius()() - 100 - smallArcRadius
            x.StartY = 400 + frontAddition
            x.EndX = smallArcRadius
            x.EndY = 400 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 400 + frontAddition, smallArcRadius, 400 + frontAddition);
            /*
                'bumper arch
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = FrontOverhang
            w.Y = axle1Front.getgetTyreRadius()() + frontAddition
            w.Radius = axle1Front.getgetTyreRadius()() + 100
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(frontOverhang, axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius() + 100, 180, 90);
            /*
            'bumper rear
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang - axle1Front.getgetTyreRadius()() - 100 - smallArcRadius
            x.StartY = 400 + frontAddition
            x.EndX = FrontOverhang - axle1Front.getTyreRadius() - 100
            x.EndY = axle1Front.getTyreRadius() + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 400 + frontAddition, frontOverhang - axle1Front.getTyreRadius() - 100, axle1Front.getTyreRadius() + frontAddition);
            /*
            'bumper rear top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab
            x.StartY = axle1Front.getTyreRadius() * 2 + frontAddition + 100
            x.EndX = FrontOverhang
            x.EndY = axle1Front.getTyreRadius() * 2 + frontAddition + 100
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab, axle1Front.getTyreRadius() * 2 + frontAddition + 100, frontOverhang, axle1Front.getTyreRadius() * 2 + frontAddition + 100);
            /*
            'front bottom of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = 200
            x.StartY = 700 + frontAddition + medArcRadius
            x.EndX = 200
            x.EndY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(200, 700 + frontAddition + medArcRadius, 200, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition);
            /*
            'door front bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = 200 + medArcRadius
            w.Y = 700 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 90
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(200 + medArcRadius, 700 + frontAddition + medArcRadius, medArcRadius, 90, 90);
            /*
            'front top of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = 200
            x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
            x.EndX = BumperToBackOfCab * 0.27
            x.EndY = CabHeight * 0.94 + frontAddition - medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(200, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition, bumperToBackOfCab * 0.27, cabHeight * 0.94 + frontAddition - medArcRadius);
            /*
            'door front top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.27 + medArcRadius
            w.Y = CabHeight * 0.94 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.27 + medArcRadius, cabHeight * 0.94 + frontAddition - medArcRadius, medArcRadius, 180, 90);
            /*
            'top of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.27 + medArcRadius
            x.StartY = CabHeight * 0.94 + frontAddition
            x.EndX = BumperToBackOfCab * 0.75 - medArcRadius
            x.EndY = CabHeight * 0.94 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.27 + medArcRadius, cabHeight * 0.94 + frontAddition, bumperToBackOfCab * 0.75 - medArcRadius, cabHeight * 0.94 + frontAddition);
            /*
            'door rear top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.75 - medArcRadius
            w.Y = CabHeight * 0.94 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.75 - medArcRadius, cabHeight * 0.94 + frontAddition - medArcRadius, medArcRadius, 270, 90);
            /*
            'rear of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.75
            x.StartY = CabHeight * 0.94 + frontAddition - medArcRadius
            x.EndX = BumperToBackOfCab * 0.75
            x.EndY = axle1Front.getTyreRadius() * 2 + frontAddition + 75
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.75, cabHeight * 0.94 + frontAddition - medArcRadius, bumperToBackOfCab * 0.75, axle1Front.getTyreRadius() * 2 + frontAddition + 75);
            /*
            'bottom of door  
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang - axle1Front.getTyreRadius() - 75
            x.StartY = 700 + frontAddition
            x.EndX = 200 + medArcRadius
            x.EndY = 700 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang - axle1Front.getTyreRadius() - 75, 700 + frontAddition, 200 + medArcRadius, 700 + frontAddition);
            /*
            'rear of door handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.71
            x.StartY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
            x.EndX = BumperToBackOfCab * 0.71
            x.EndY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 120 + frontAddition, bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 50 + frontAddition);
            /*
            'bottom handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.71
            x.StartY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
            x.EndX = BumperToBackOfCab * 0.71 - 200
            x.EndY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 50 + frontAddition, bumperToBackOfCab * 0.71 - 200, chassisHeightFront * 1.1 + 150 + 50 + frontAddition);
            /*
            'front of door handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.71 - 200
            x.StartY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
            x.EndX = BumperToBackOfCab * 0.71 - 200
            x.EndY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.71 - 200, chassisHeightFront * 1.1 + 150 + 120 + frontAddition, bumperToBackOfCab * 0.71 - 200, chassisHeightFront * 1.1 + 150 + 50 + frontAddition);
            /*
            'top of  handle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.71
            x.StartY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
            x.EndX = BumperToBackOfCab * 0.71 - 200
            x.EndY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 120 + frontAddition, bumperToBackOfCab * 0.71 - 200, chassisHeightFront * 1.1 + 150 + 120 + frontAddition);
            /*
            'front of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.23
            x.StartY = CabHeight * 0.6 + frontAddition + medArcRadius
            x.EndX = BumperToBackOfCab * 0.31
            x.EndY = CabHeight * 0.9 + frontAddition - medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.23, cabHeight * 0.6 + frontAddition + medArcRadius, bumperToBackOfCab * 0.31, cabHeight * 0.9 + frontAddition - medArcRadius);
            /*
            'window front top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.31 + medArcRadius
            w.Y = CabHeight * 0.9 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 180
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.31 + medArcRadius, cabHeight * 0.9 + frontAddition - medArcRadius, medArcRadius, 180, 90);
            /*
            'top of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.31 + medArcRadius
            x.StartY = CabHeight * 0.9 + frontAddition
            x.EndX = BumperToBackOfCab * 0.7 - medArcRadius
            x.EndY = CabHeight * 0.9 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.31 + medArcRadius, cabHeight * 0.9 + frontAddition, bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.9 + frontAddition);
            /*
            'window rear top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.7 - medArcRadius
            w.Y = CabHeight * 0.9 + frontAddition - medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.9 + frontAddition - medArcRadius, medArcRadius, 270, 90);
            /*
            'rear of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.7
            x.StartY = CabHeight * 0.9 + frontAddition - medArcRadius
            x.EndX = BumperToBackOfCab * 0.7
            x.EndY = CabHeight * 0.67 + frontAddition + medArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.7, cabHeight * 0.9 + frontAddition - medArcRadius, bumperToBackOfCab * 0.7, cabHeight * 0.67 + frontAddition + medArcRadius);
            /*
            'window rear bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.7 - medArcRadius
            w.Y = CabHeight * 0.67 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.67 + frontAddition + medArcRadius, medArcRadius, 0, 90);
            /*
            'bottom of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.7 - medArcRadius
            x.StartY = CabHeight * 0.67 + frontAddition
            x.EndX = BumperToBackOfCab * 0.5
            x.EndY = CabHeight * 0.67 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.67 + frontAddition, bumperToBackOfCab * 0.5, cabHeight * 0.67 + frontAddition);
            /*
            'bottom slant of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.5
            x.StartY = CabHeight * 0.67 + frontAddition
            x.EndX = BumperToBackOfCab * 0.4
            x.EndY = CabHeight * 0.6 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.5, cabHeight * 0.67 + frontAddition, bumperToBackOfCab * 0.4, cabHeight * 0.6 + frontAddition);
            /*
            'bottom of window
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab * 0.4
            x.StartY = CabHeight * 0.6 + frontAddition
            x.EndX = BumperToBackOfCab * 0.23 + medArcRadius
            x.EndY = CabHeight * 0.6 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab * 0.4, cabHeight * 0.6 + frontAddition, bumperToBackOfCab * 0.23 + medArcRadius, cabHeight * 0.6 + frontAddition);
            /*
            'window front bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = BumperToBackOfCab * 0.23 + medArcRadius
            w.Y = CabHeight * 0.6 + frontAddition + medArcRadius
            w.Radius = medArcRadius
            w.StartAngle = 90
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(bumperToBackOfCab * 0.23 + medArcRadius, cabHeight * 0.6 + frontAddition + medArcRadius, medArcRadius, 90, 90);
            /*
            'light
            'bottom
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = smallArcRadius
            x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition - 200
            x.EndX = 150 - smallArcRadius
            x.EndY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition - 200
            _SideViewDrawingObjects.Add(x)
            */
            addLine(smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition - 200, 150 - smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition - 200);
            /*
            'bottom arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = 150 - smallArcRadius
            w.Y = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 0
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(150 - smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius, smallArcRadius, 0, 90);
            /*
            'middle
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = 150
            x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius
            x.EndX = 150
            x.EndY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition - smallArcRadius
            _SideViewDrawingObjects.Add(x)
            */
            addLine(150, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius, 150, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition - smallArcRadius);
            /*
            'top arc
            w = New UtilDrawingObjectArc
            w.Pen = Colors.Black
            w.X = 150 - smallArcRadius
            w.Y = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition - smallArcRadius
            w.Radius = smallArcRadius
            w.StartAngle = 270
            w.SweepAngle = 90
            _SideViewDrawingObjects.Add(w)
            */
            addArc(150 - smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition - smallArcRadius, smallArcRadius, 270, 90);
            /*
            'top
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = 150 - smallArcRadius
            x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition
            x.EndX = smallArcRadius
            x.EndY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(150 - smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition, smallArcRadius, cabHeight * 0.1 + chassisHeightFront * 1.1 + frontAddition);
            /*
            'draw chassis
            'top line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang
            x.StartY = ChassisHeightFront + frontAddition
            x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.EndY = ChassisHeightRear + rearAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang, chassisHeightFront + frontAddition, frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear + rearAddition);
            /*
            'rear line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.StartY = ChassisHeightRear + rearAddition
            x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.EndY = ChassisHeightRear - 200 + rearAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear + rearAddition, frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear - 200 + rearAddition);
            /*
                'bottom line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                        Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
            x.StartY = ChassisHeightRear - 200 + rearAddition
            x.EndX = FrontOverhang
            x.EndY = ChassisHeightFront - 200 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear - 200 + rearAddition, frontOverhang, chassisHeightFront - 200 + frontAddition);
            break;

        case config.CONTROL_TYPES.SEMI_FORWARD:

            var tailGate = 150;

            if (type === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {//panel van
                /*
                'front bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = medArcRadius
                w.Y = axle1Front.getTyreRadius() + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 90
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(medArcRadius, axle1Front.getTyreRadius() + medArcRadius, medArcRadius, 90, 90);
                /*
                'front
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 0
                x.StartY = axle1Front.getTyreRadius() + 50
                x.EndX = 0
                x.EndY = CabHeight * 0.4 - medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(0, axle1Front.getTyreRadius() + 50, 0, cabHeight * 0.4 - medArcRadius);
                /*
                'front top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = medArcRadius
                w.Y = CabHeight * 0.4 - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 180
                w.SweepAngle = 45
                _SideViewDrawingObjects.Add(w)
                */
                addArc(medArcRadius, cabHeight * 0.4 - medArcRadius, medArcRadius, 180, 45);
                /*
                'bonnet
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = medArcRadius / 3.5
                x.StartY = CabHeight * 0.4 - medArcRadius / 3
                x.EndX = OverallLengthExclAccessories * 0.1
                x.EndY = CabHeight * 0.65
                _SideViewDrawingObjects.Add(x)
                */
                addLine(medArcRadius / 3.5, cabHeight * 0.4 - medArcRadius / 3, overallLengthExclAccessories * 0.1, cabHeight * 0.65);
                /*
                'windscreen
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthExclAccessories * 0.1
                x.StartY = CabHeight * 0.65
                x.EndX = _BumperToStartOfBodyInternal * 0.75
                x.EndY = CabHeight
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthExclAccessories * 0.1, cabHeight * 0.65, bumperToStartOfBodyInternal * 0.75, cabHeight);
                /*
                'windscreen top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.65
                x.StartY = CabHeight * 0.95 - 50
                x.EndX = _BumperToStartOfBodyInternal * 0.65 + 150
                x.EndY = CabHeight * 0.95 - 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.65, cabHeight * 0.95 - 50, bumperToStartOfBodyInternal * 0.65 + 150, cabHeight * 0.95 - 50);
                /*
                'windscreen inner
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.65 + 150
                x.StartY = CabHeight * 0.95 - 50
                x.EndX = OverallLengthExclAccessories * 0.1 + 200
                x.EndY = CabHeight * 0.65
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.65 + 150, cabHeight * 0.95 - 50, overallLengthExclAccessories * 0.1 + 200, cabHeight * 0.65);
                /*
                'windscreen bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthExclAccessories * 0.1
                x.StartY = CabHeight * 0.65
                x.EndX = OverallLengthExclAccessories * 0.1 + 200
                x.EndY = CabHeight * 0.65
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthExclAccessories * 0.1, cabHeight * 0.65, overallLengthExclAccessories * 0.1 + 200, cabHeight * 0.65);
                /*
                'front light top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 0
                x.StartY = CabHeight * 0.4 - medArcRadius
                x.EndX = 200 - smallArcRadius
                x.EndY = CabHeight * 0.4 - medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(0, cabHeight * 0.4 - medArcRadius, 200 - smallArcRadius, cabHeight * 0.4 - medArcRadius);
                /*
                'front light top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = 200 - smallArcRadius
                w.Y = CabHeight * 0.4 - medArcRadius - smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(200 - smallArcRadius, cabHeight * 0.4 - medArcRadius - smallArcRadius, smallArcRadius, 270, 90);
                /*
                'front light rear
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 200
                x.StartY = CabHeight * 0.4 - medArcRadius - smallArcRadius
                x.EndX = 200
                x.EndY = CabHeight * 0.4 - medArcRadius - 150 + smallArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(200, cabHeight * 0.4 - medArcRadius - smallArcRadius, 200, cabHeight * 0.4 - medArcRadius - 150 + smallArcRadius);
                /*
                'front light bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = 200 - smallArcRadius
                w.Y = CabHeight * 0.4 - medArcRadius - 150 + smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(200 - smallArcRadius, cabHeight * 0.4 - medArcRadius - 150 + smallArcRadius, smallArcRadius, 0, 90);
                /*
                'front light bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 200 - smallArcRadius
                x.StartY = CabHeight * 0.4 - medArcRadius - 150
                x.EndX = 0
                x.EndY = CabHeight * 0.4 - medArcRadius - 150
                _SideViewDrawingObjects.Add(x)
                */
                addLine(200 - smallArcRadius, cabHeight * 0.4 - medArcRadius - 150, 0, cabHeight * 0.4 - medArcRadius - 150);
                /*
                'bumper
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 0
                x.StartY = axle1Front.getTyreRadius() + 200
                x.EndX = _FrontOverhang - axle1Front.getTyreRadius() - 25
                x.EndY = axle1Front.getTyreRadius() + 200
                _SideViewDrawingObjects.Add(x)
                */
                addLine(0, axle1Front.getTyreRadius() + 200, frontOverhang - axle1Front.getTyreRadius() - 25, axle1Front.getTyreRadius() + 200);
                /*
                'roof top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.75
                x.StartY = CabHeight
                x.EndX = OverallLengthExclAccessories - medArcRadius
                x.EndY = CabHeight
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.75, cabHeight, overallLengthExclAccessories - medArcRadius, cabHeight);
                /*
                'roof bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.69
                x.StartY = CabHeight * 0.95
                x.EndX = OverallLengthExclAccessories
                x.EndY = CabHeight * 0.95
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.69, cabHeight * 0.95, overallLengthExclAccessories, cabHeight * 0.95);
                /*
                'rear roof arch
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = OverallLengthExclAccessories - medArcRadius
                w.Y = CabHeight - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(overallLengthExclAccessories - medArcRadius, cabHeight - medArcRadius, medArcRadius, 270, 90);
                /*
                'back
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthExclAccessories
                x.StartY = CabHeight - medArcRadius
                x.EndX = OverallLengthExclAccessories
                x.EndY = axle1Front.getTyreRadius() + medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthExclAccessories, cabHeight - medArcRadius, overallLengthExclAccessories, axle1Front.getTyreRadius() + medArcRadius);
                /*
                'back bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = OverallLengthExclAccessories - medArcRadius
                w.Y = axle1Front.getTyreRadius() + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(overallLengthExclAccessories - medArcRadius, axle1Front.getTyreRadius() + medArcRadius, medArcRadius, 0, 90);
                /*
                'rear bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthExclAccessories - medArcRadius
                x.StartY = axle1Rear.getTyreRadius()
                x.EndX = OverallLengthExclAccessories - RearOverhang + axle1Rear.getTyreRadius() + 50
                x.EndY = axle1Rear.getTyreRadius()
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthExclAccessories - medArcRadius, axle1Rear.getTyreRadius(), overallLengthExclAccessories - rearOverhang + axle1Rear.getTyreRadius() + 50, axle1Rear.getTyreRadius());
                /*
                'rear arch
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = OverallLengthExclAccessories - RearOverhang
                w.Y = axle1Rear.getTyreRadius()
                w.Radius = axle1Rear.getTyreRadius() + 50
                w.StartAngle = 180
                w.SweepAngle = 180
                _SideViewDrawingObjects.Add(w)
                */
                addArc(overallLengthExclAccessories - rearOverhang, axle1Rear.getTyreRadius(), axle1Rear.getTyreRadius() + 50, 180, 180);
                /*
                'middle bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthExclAccessories - RearOverhang - axle1Rear.getTyreRadius() - 50
                x.StartY = axle1Rear.getTyreRadius()
                x.EndX = FrontOverhang + axle1Front.getTyreRadius() + 50
                x.EndY = axle1Front.getTyreRadius()
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthExclAccessories - rearOverhang - axle1Rear.getTyreRadius() - 50, axle1Rear.getTyreRadius(), frontOverhang + axle1Front.getTyreRadius() + 50, axle1Front.getTyreRadius());
                /*
                'front arch
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = FrontOverhang
                w.Y = axle1Front.getTyreRadius()
                w.Radius = axle1Front.getTyreRadius() + 50
                w.StartAngle = 180
                w.SweepAngle = 180
                _SideViewDrawingObjects.Add(w)
                */
                addArc(frontOverhang, axle1Front.getTyreRadius(), axle1Front.getTyreRadius() + 50, 180, 180);
                /*
                'front bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang - axle1Front.getTyreRadius() - 50
                x.StartY = axle1Front.getTyreRadius()
                x.EndX = medArcRadius
                x.EndY = axle1Front.getTyreRadius()
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang - axle1Front.getTyreRadius() - 50, axle1Front.getTyreRadius(), medArcRadius, axle1Front.getTyreRadius());
                /*
                'front door
                'front of door
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _FrontOverhang
                x.StartY = CabHeight * 0.6
                x.EndX = _BumperToStartOfBodyInternal * 0.8
                x.EndY = CabHeight * 0.925
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang, cabHeight * 0.6, bumperToStartOfBodyInternal * 0.8, cabHeight * 0.925);
                /*
                'top of door
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.8
                x.StartY = CabHeight * 0.925
                x.EndX = _BumperToStartOfBodyInternal * 0.97
                x.EndY = CabHeight * 0.925
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.8, cabHeight * 0.925, bumperToStartOfBodyInternal * 0.97, cabHeight * 0.925);
                /*
                'rear of door
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97
                x.StartY = CabHeight * 0.925
                x.EndX = _BumperToStartOfBodyInternal * 0.97
                x.EndY = axle1Front.getTyreRadius() * 1.5
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97, cabHeight * 0.925, bumperToStartOfBodyInternal * 0.97, axle1Front.getTyreRadius() * 1.5);
                /*
                'front bottom of door
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _FrontOverhang
                x.StartY = axle1Front.getTyreRadius() * 2 + 200
                x.EndX = _FrontOverhang
                x.EndY = CabHeight * 0.6
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang, axle1Front.getTyreRadius() * 2 + 200, frontOverhang, cabHeight * 0.6);
                /*
                'bottom arc for front door
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = FrontOverhang
                w.Y = axle1Front.getTyreRadius()
                w.Radius = axle1Front.getTyreRadius() + 200
                w.StartAngle = 270
                w.SweepAngle = 71.5
                _SideViewDrawingObjects.Add(w)
                */
                addArc(frontOverhang, axle1Front.getTyreRadius(), axle1Front.getTyreRadius() + 200, 270, 71.5);
                /*
                'bottom of door
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97
                x.StartY = axle1Front.getTyreRadius() * 1.5
                x.EndX = _FrontOverhang + axle1Front.getTyreRadius() + 170
                x.EndY = axle1Front.getTyreRadius() * 1.5
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97, axle1Front.getTyreRadius() * 1.5, frontOverhang + axle1Front.getTyreRadius() + 170, axle1Front.getTyreRadius() * 1.5);
                /*
                'door window
                'top of door window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.8
                x.StartY = CabHeight * 0.9
                x.EndX = _BumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius
                x.EndY = CabHeight * 0.9
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.8, cabHeight * 0.9, bumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius, cabHeight * 0.9);
                /*
                'top of door window arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius
                w.Y = CabHeight * 0.9 - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius, cabHeight * 0.9 - medArcRadius, medArcRadius, 270, 90);
                /*
                'rear of door window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97 - 50
                x.StartY = CabHeight * 0.9 - medArcRadius
                x.EndX = _BumperToStartOfBodyInternal * 0.97 - 50
                x.EndY = CabHeight * 0.6 + medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97 - 50, cabHeight * 0.9 - medArcRadius, bumperToStartOfBodyInternal * 0.97 - 50, cabHeight * 0.6 + medArcRadius);
                /*
                'bottom of door window arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius
                w.Y = CabHeight * 0.6 + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius, cabHeight * 0.6 + medArcRadius, medArcRadius, 0, 90);
                /*
                'bottom of door window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius
                x.StartY = CabHeight * 0.6
                x.EndX = _FrontOverhang + 50
                x.EndY = CabHeight * 0.6
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97 - 50 - medArcRadius, cabHeight * 0.6, frontOverhang + 50, cabHeight * 0.6);
                /*
                'front of door window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _FrontOverhang + 50
                x.StartY = CabHeight * 0.6
                x.EndX = _BumperToStartOfBodyInternal * 0.8
                x.EndY = CabHeight * 0.9
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang + 50, cabHeight * 0.6, bumperToStartOfBodyInternal * 0.8, cabHeight * 0.9);
                /*
                'front door handle
                'rear of front door handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97 - 50
                x.StartY = CabHeight * 0.6 - 50
                x.EndX = _BumperToStartOfBodyInternal * 0.97 - 50
                x.EndY = CabHeight * 0.6 - 120
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97 - 50, cabHeight * 0.6 - 50, bumperToStartOfBodyInternal * 0.97 - 50, cabHeight * 0.6 - 120);
                /*
                'bottom of front handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97 - 50
                x.StartY = CabHeight * 0.6 - 120
                x.EndX = _BumperToStartOfBodyInternal * 0.97 - 250
                x.EndY = CabHeight * 0.6 - 120
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97 - 50, cabHeight * 0.6 - 120, bumperToStartOfBodyInternal * 0.97 - 250, cabHeight * 0.6 - 120);
                /*
                'front of front door handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97 - 250
                x.StartY = CabHeight * 0.6 - 120
                x.EndX = _BumperToStartOfBodyInternal * 0.97 - 250
                x.EndY = CabHeight * 0.6 - 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97 - 250, cabHeight * 0.6 - 120, bumperToStartOfBodyInternal * 0.97 - 250, cabHeight * 0.6 - 50);
                /*
                'top of front door handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal * 0.97 - 250
                x.StartY = CabHeight * 0.6 - 50
                x.EndX = _BumperToStartOfBodyInternal * 0.97 - 50
                x.EndY = CabHeight * 0.6 - 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal * 0.97 - 250, cabHeight * 0.6 - 50, bumperToStartOfBodyInternal * 0.97 - 50, cabHeight * 0.6 - 50);
                /*
                ' front sliding door line   
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal
                x.StartY = CabHeight * 0.95
                x.EndX = _BumperToStartOfBodyInternal
                x.EndY = axle1Front.getTyreRadius()
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal, cabHeight * 0.95, bumperToStartOfBodyInternal, axle1Front.getTyreRadius());
                /*
                ' rear sliding door line   
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45
                x.StartY = CabHeight * 0.95
                x.EndX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45
                x.EndY = axle1Rear.getTyreRadius()
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45, cabHeight * 0.95, bumperToStartOfBodyInternal + bodyLengthInternal * 0.45, axle1Rear.getTyreRadius());
                /*
                ' rear tailgate door line   
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthExclAccessories - tailGate
                x.StartY = CabHeight * 0.95
                x.EndX = OverallLengthExclAccessories - tailGate
                x.EndY = axle1Rear.getTyreRadius()
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthExclAccessories - tailGate, cabHeight * 0.95, overallLengthExclAccessories - tailGate, axle1Rear.getTyreRadius());
                /*
                'middle window
                'front of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + 50
                x.StartY = CabHeight * 0.9 - medArcRadius
                x.EndX = _BumperToStartOfBodyInternal + 50
                x.EndY = CabHeight * 0.6 + medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + 50, cabHeight * 0.9 - medArcRadius, bumperToStartOfBodyInternal + 50, cabHeight * 0.6 + medArcRadius);
                /*
                'top of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + 50 + medArcRadius
                x.StartY = CabHeight * 0.9
                x.EndX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 - 50 - medArcRadius
                x.EndY = CabHeight * 0.9
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + 50 + medArcRadius, cabHeight * 0.9, bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 - 50 - medArcRadius,
                            cabHeight * 0.9);
                /*
                'rear of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 - 50
                x.StartY = CabHeight * 0.9 - medArcRadius
                x.EndX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 - 50
                x.EndY = CabHeight * 0.6 + medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 - 50, cabHeight * 0.9 - medArcRadius,
                            bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 - 50, cabHeight * 0.6 + medArcRadius);
                /*
                'bottom of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 - 50 - medArcRadius
                x.StartY = CabHeight * 0.6
                x.EndX = _BumperToStartOfBodyInternal + 50 + medArcRadius
                x.EndY = CabHeight * 0.6
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 - 50 - medArcRadius, cabHeight * 0.6,
                            bumperToStartOfBodyInternal + 50 + medArcRadius, cabHeight * 0.6);
                /*
                'top left arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal + 50 + medArcRadius
                w.Y = CabHeight * 0.9 - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal + 50 + medArcRadius, cabHeight * 0.9 - medArcRadius, medArcRadius, 180, 90);
                /*
                'top right arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 - 50 - medArcRadius
                w.Y = CabHeight * 0.9 - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 - 50 - medArcRadius, cabHeight * 0.9 - medArcRadius, medArcRadius, 270, 90);
                /*
                'bottom right arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 - 50 - medArcRadius
                w.Y = CabHeight * 0.6 + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 - 50 - medArcRadius, cabHeight * 0.6 + medArcRadius, medArcRadius, 0, 90);
                /*
                'bottom left arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal + 50 + medArcRadius
                w.Y = CabHeight * 0.6 + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 90
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal + 50 + medArcRadius, cabHeight * 0.6 + medArcRadius, medArcRadius, 90, 90);
                /*
                'rear of door handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + 250
                x.StartY = CabHeight * 0.6 - 50
                x.EndX = _BumperToStartOfBodyInternal + 250
                x.EndY = CabHeight * 0.6 - 120
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + 250, cabHeight * 0.6 - 50, bumperToStartOfBodyInternal + 250, cabHeight * 0.6 - 120);
                /*
                'bottom handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + 250
                x.StartY = CabHeight * 0.6 - 120
                x.EndX = _BumperToStartOfBodyInternal + 50
                x.EndY = CabHeight * 0.6 - 120
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + 250, cabHeight * 0.6 - 120, bumperToStartOfBodyInternal + 50, cabHeight * 0.6 - 120);
                /*
                'front of door handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + 50
                x.StartY = CabHeight * 0.6 - 120
                x.EndX = _BumperToStartOfBodyInternal + 50
                x.EndY = CabHeight * 0.6 - 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + 50, cabHeight * 0.6 - 120, bumperToStartOfBodyInternal + 50, cabHeight * 0.6 - 50);
                /*
                'top of  handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + 50
                x.StartY = CabHeight * 0.6 - 50
                x.EndX = _BumperToStartOfBodyInternal + 250
                x.EndY = CabHeight * 0.6 - 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + 50, cabHeight * 0.6 - 50, bumperToStartOfBodyInternal + 250, cabHeight * 0.6 - 50);
                /*
                'rear window
                'front of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 + 50
                x.StartY = CabHeight * 0.9 - medArcRadius
                x.EndX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 + 50
                x.EndY = CabHeight * 0.6 + medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 + 50, cabHeight * 0.9 - medArcRadius, bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 + 50,
                            cabHeight * 0.6 + medArcRadius);
                /*
                'top of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 + 50 + medArcRadius
                x.StartY = CabHeight * 0.9
                x.EndX = OverallLengthInclAccessories - tailGate - 50 - medArcRadius
                x.EndY = CabHeight * 0.9
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 + 50 + medArcRadius, cabHeight * 0.9, overallLengthInclAccessories - tailGate - 50 - medArcRadius,
                            cabHeight * 0.9);
                /*
                'rear of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - tailGate - 50
                x.StartY = CabHeight * 0.9 - medArcRadius
                x.EndX = OverallLengthInclAccessories - tailGate - 50
                x.EndY = CabHeight * 0.6 + medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - tailGate - 50, cabHeight * 0.9 - medArcRadius, overallLengthInclAccessories - tailGate - 50, cabHeight * 0.6 + medArcRadius);
                /*
                'bottom of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - tailGate - 50 - medArcRadius
                x.StartY = CabHeight * 0.6
                x.EndX = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 + 50 + medArcRadius
                x.EndY = CabHeight * 0.6
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - tailGate - 50 - medArcRadius, cabHeight * 0.6, bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 + 50 + medArcRadius,
                            cabHeight * 0.6);
                /*
                'top left arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 + 50 + medArcRadius
                w.Y = CabHeight * 0.9 - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 + 50 + medArcRadius, cabHeight * 0.9 - medArcRadius, medArcRadius, 180, 90);
                /*
                'top right arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = OverallLengthInclAccessories - tailGate - 50 - medArcRadius
                w.Y = CabHeight * 0.9 - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(overallLengthInclAccessories - tailGate - 50 - medArcRadius, cabHeight * 0.9 - medArcRadius, medArcRadius, 270, 90);
                /*
                'bottom right arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = OverallLengthInclAccessories - tailGate - 50 - medArcRadius
                w.Y = CabHeight * 0.6 + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(overallLengthInclAccessories - tailGate - 50 - medArcRadius, cabHeight * 0.6 + medArcRadius, medArcRadius, 0, 90);
                /*
                'bottom left arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = _BumperToStartOfBodyInternal + _BodyLengthInternal * 0.45 + 50 + medArcRadius
                w.Y = CabHeight * 0.6 + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 90
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToStartOfBodyInternal + bodyLengthInternal * 0.45 + 50 + medArcRadius, cabHeight * 0.6 + medArcRadius, medArcRadius, 90, 90);
                /*
                'rear light
                'top of light
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories
                x.StartY = CabHeight * 0.4 + 300
                x.EndX = OverallLengthInclAccessories - tailGate / 2 + smallArcRadius
                x.EndY = CabHeight * 0.4 + 300
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories, cabHeight * 0.4 + 300, overallLengthInclAccessories - tailGate / 2 + smallArcRadius, cabHeight * 0.4 + 300);
                /*
                'rear of light
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - tailGate / 2
                x.StartY = CabHeight * 0.4 + 300 - smallArcRadius
                x.EndX = OverallLengthInclAccessories - tailGate / 2
                x.EndY = CabHeight * 0.4 + smallArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - tailGate / 2, cabHeight * 0.4 + 300 - smallArcRadius, overallLengthInclAccessories - tailGate / 2, cabHeight * 0.4 + smallArcRadius);
                /*
                'bottom of light
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories
                x.StartY = CabHeight * 0.4
                x.EndX = OverallLengthInclAccessories - tailGate / 2 + smallArcRadius
                x.EndY = CabHeight * 0.4
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories, cabHeight * 0.4, overallLengthInclAccessories - tailGate / 2 + smallArcRadius, cabHeight * 0.4);
                /*
                'middle of light
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories
                x.StartY = CabHeight * 0.4 + 150
                x.EndX = OverallLengthInclAccessories - tailGate / 2
                x.EndY = CabHeight * 0.4 + 150
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories, cabHeight * 0.4 + 150, overallLengthInclAccessories - tailGate / 2, cabHeight * 0.4 + 150);
                /*
                'light top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = OverallLengthInclAccessories - tailGate / 2 + smallArcRadius
                w.Y = CabHeight * 0.4 + 300 - smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(overallLengthInclAccessories - tailGate / 2 + smallArcRadius, cabHeight * 0.4 + 300 - smallArcRadius, smallArcRadius, 180, 90);
                /*
                'light bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = OverallLengthInclAccessories - tailGate / 2 + smallArcRadius
                w.Y = CabHeight * 0.4 + smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 90
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(overallLengthInclAccessories - tailGate / 2 + smallArcRadius, cabHeight * 0.4 + smallArcRadius, smallArcRadius, 90, 90);
                /*
                'rear stripe
                'rear of rear stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - tailGate - 50
                x.StartY = axle1Rear.getTyreRadius() * 2 + 50
                x.EndX = OverallLengthInclAccessories - tailGate - 50
                x.EndY = axle1Rear.getTyreRadius() * 2
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - tailGate - 50, axle1Rear.getTyreRadius() * 2 + 50, overallLengthInclAccessories - tailGate - 50, axle1Rear.getTyreRadius() * 2);
                /*
                'bottom of rear stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - tailGate - 50
                x.StartY = axle1Rear.getTyreRadius() * 2
                x.EndX = OverallLengthInclAccessories - RearOverhang + axle1Rear.getTyreRadius()
                x.EndY = axle1Rear.getTyreRadius() * 2
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - tailGate - 50, axle1Rear.getTyreRadius() * 2, overallLengthInclAccessories - rearOverhang + axle1Rear.getTyreRadius(), axle1Rear.getTyreRadius() * 2);
                /*
                'front of rear stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - RearOverhang + axle1Rear.getTyreRadius()
                x.StartY = axle1Rear.getTyreRadius() * 2
                x.EndX = OverallLengthInclAccessories - RearOverhang + axle1Rear.getTyreRadius()
                x.EndY = axle1Rear.getTyreRadius() * 2 + 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - rearOverhang + axle1Rear.getTyreRadius(), axle1Rear.getTyreRadius() * 2, overallLengthInclAccessories - rearOverhang + axle1Rear.getTyreRadius(),
                            axle1Rear.getTyreRadius() * 2 + 50);
                /*
                'top of rear stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - RearOverhang + axle1Rear.getTyreRadius()
                x.StartY = axle1Rear.getTyreRadius() * 2 + 50
                x.EndX = OverallLengthInclAccessories - tailGate - 50
                x.EndY = axle1Rear.getTyreRadius() * 2 + 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - rearOverhang + axle1Rear.getTyreRadius(), axle1Rear.getTyreRadius() * 2 + 50, overallLengthInclAccessories - tailGate - 50, axle1Rear.getTyreRadius() * 2 + 50);
                /*
                'middle stripe
                'rear of middle stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = OverallLengthInclAccessories - RearOverhang - axle1Rear.getTyreRadius()
                x.StartY = axle1Rear.getTyreRadius() * 2 + 50
                x.EndX = OverallLengthInclAccessories - RearOverhang - axle1Rear.getTyreRadius()
                x.EndY = axle1Rear.getTyreRadius() * 2
                _SideViewDrawingObjects.Add(x)
                */
                addLine(overallLengthInclAccessories - rearOverhang - axle1Rear.getTyreRadius(), axle1Rear.getTyreRadius() * 2 + 50, overallLengthInclAccessories - rearOverhang - axle1Rear.getTyreRadius(),
                            axle1Rear.getTyreRadius() * 2);
                /*
                'bottom of middle stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang + axle1Front.getTyreRadius()
                x.StartY = axle1Rear.getTyreRadius() * 2
                x.EndX = OverallLengthInclAccessories - RearOverhang - axle1Rear.getTyreRadius()
                x.EndY = axle1Rear.getTyreRadius() * 2
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang + axle1Front.getTyreRadius(), axle1Rear.getTyreRadius() * 2, overallLengthInclAccessories - rearOverhang - axle1Rear.getTyreRadius(), axle1Rear.getTyreRadius() * 2);
                /*
                'front of middle stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang + axle1Front.getTyreRadius()
                x.StartY = axle1Rear.getTyreRadius() * 2
                x.EndX = FrontOverhang + axle1Front.getTyreRadius()
                x.EndY = axle1Rear.getTyreRadius() * 2 + 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang + axle1Front.getTyreRadius(), axle1Rear.getTyreRadius() * 2, frontOverhang + axle1Front.getTyreRadius(), axle1Rear.getTyreRadius() * 2 + 50);
                /*
                'top of middle stripe
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang + axle1Front.getTyreRadius()
                x.StartY = axle1Rear.getTyreRadius() * 2 + 50
                x.EndX = OverallLengthInclAccessories - RearOverhang - axle1Rear.getTyreRadius()
                x.EndY = axle1Rear.getTyreRadius() * 2 + 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang + axle1Front.getTyreRadius(), axle1Rear.getTyreRadius() * 2 + 50, overallLengthInclAccessories - rearOverhang - axle1Rear.getTyreRadius(), axle1Rear.getTyreRadius() * 2 + 50);
            } else {//fc semi-forward
                /*
                'bottom bumper arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = smallArcRadius
                w.Y = 200 + frontAddition + smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 90
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(smallArcRadius, 200 + frontAddition + smallArcRadius, smallArcRadius, 90, 90);
                /*
                'front of bumper
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 0
                x.StartY = 200 + frontAddition + smallArcRadius
                x.EndX = 0
                x.EndY = 400 + frontAddition - smallArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(0, 200 + frontAddition + smallArcRadius, 0, 400 + frontAddition - smallArcRadius);
                /*
                'top bumper arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = smallArcRadius
                w.Y = 400 + frontAddition - smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(smallArcRadius, 400 + frontAddition - smallArcRadius, smallArcRadius, 180, 90);
                /*
                'front
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = smallArcRadius
                x.StartY = 400 + frontAddition
                x.EndX = smallArcRadius
                x.EndY = CabHeight * 0.05 + ChassisHeightFront * 1.1 + 150 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(smallArcRadius, 400 + frontAddition, smallArcRadius, cabHeight * 0.05 + chassisHeightFront * 1.1 + 150 + frontAddition);
                /*
                'front top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = smallArcRadius
                x.StartY = CabHeight * 0.05 + ChassisHeightFront * 1.1 + 150 + frontAddition
                x.EndX = smallArcRadius + 50
                x.EndY = CabHeight * 0.17 + ChassisHeightFront * 1.1 + 150 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(smallArcRadius, cabHeight * 0.05 + chassisHeightFront * 1.1 + 150 + frontAddition, smallArcRadius + 50,
                            cabHeight * 0.17 + chassisHeightFront * 1.1 + 150 + frontAddition);
                /*
                'windscreen
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = smallArcRadius + 50
                x.StartY = CabHeight * 0.17 + ChassisHeightFront * 1.1 + 150 + frontAddition
                x.EndX = BumperToBackOfCab * 0.3
                x.EndY = CabHeight * 0.96 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(smallArcRadius + 50, cabHeight * 0.17 + chassisHeightFront * 1.1 + 150 + frontAddition, bumperToBackOfCab * 0.3, cabHeight * 0.96 + frontAddition);
                /*
                'windscreen bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = smallArcRadius + 50
                x.StartY = CabHeight * 0.17 + ChassisHeightFront * 1.1 + 150 + frontAddition
                x.EndX = 50 + 50
                x.EndY = CabHeight * 0.17 + ChassisHeightFront * 1.1 + 150 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(smallArcRadius + 50, cabHeight * 0.17 + chassisHeightFront * 1.1 + 150 + frontAddition, 50 + 50,
                            cabHeight * 0.17 + chassisHeightFront * 1.1 + 150 + frontAddition);
                /*
                'windscreen bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = 50 + 50
                w.Y = CabHeight * 0.17 + ChassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(50 + 50, cabHeight * 0.17 + chassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius, smallArcRadius, 0, 90);
                /*
                'windscreen inner
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = smallArcRadius + 50 + 50
                x.StartY = CabHeight * 0.17 + ChassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius
                x.EndX = BumperToBackOfCab * 0.3 + 50
                x.EndY = CabHeight * 0.96 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(smallArcRadius + 50 + 50, cabHeight * 0.17 + chassisHeightFront * 1.1 + 150 + frontAddition + smallArcRadius, bumperToBackOfCab * 0.3 + 50,
                            cabHeight * 0.96 + frontAddition);
                /*
                'windscreen top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.3
                x.StartY = CabHeight * 0.96 + frontAddition
                x.EndX = BumperToBackOfCab * 0.93 + CabHeight * 0.04
                x.EndY = CabHeight * 0.96 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.3, cabHeight * 0.96 + frontAddition, bumperToBackOfCab * 0.93 + cabHeight * 0.04,
                            cabHeight * 0.96 + frontAddition);
                /*
                'cab top front windscreen
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.3 + CabHeight * 0.04
                w.Y = CabHeight * 0.96 + frontAddition
                w.Radius = CabHeight * 0.04
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.3 + cabHeight * 0.04, cabHeight * 0.96 + frontAddition, cabHeight * 0.04, 180, 90);
                /*
                'cab hood top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.3 + CabHeight * 0.04
                x.StartY = CabHeight + frontAddition
                x.EndX = BumperToBackOfCab * 0.93
                x.EndY = CabHeight + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.3 + cabHeight * 0.04, cabHeight + frontAddition, bumperToBackOfCab * 0.93, cabHeight + frontAddition);
                /*
                'cab hood rear arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.93
                w.Y = CabHeight * 0.96 + frontAddition
                w.Radius = CabHeight * 0.04
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.93, cabHeight * 0.96 + frontAddition, cabHeight * 0.04, 270, 90);
                /*
                'rear slant
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.93 + CabHeight * 0.04
                x.StartY = CabHeight * 0.96 + frontAddition
                x.EndX = BumperToBackOfCab
                x.EndY = CabHeight * 0.92 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.93 + cabHeight * 0.04, cabHeight * 0.96 + frontAddition, bumperToBackOfCab, cabHeight * 0.92 + frontAddition);
                /*
                'back of cab
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab
                x.StartY = CabHeight * 0.92 + frontAddition
                x.EndX = BumperToBackOfCab
                x.EndY = axle1Front.getTyreRadius() * 2 + frontAddition + 50 + smallArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab, cabHeight * 0.92 + frontAddition, bumperToBackOfCab, axle1Front.getTyreRadius() * 2 + frontAddition + 50 + smallArcRadius);
                /*
                'back of cab back arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab - smallArcRadius
                w.Y = axle1Front.getTyreRadius() * 2 + frontAddition + 50 + smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab - smallArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition + 50 + smallArcRadius, smallArcRadius, 0, 90);
                /*
                'back of wheel arch
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab - smallArcRadius
                x.StartY = axle1Front.getTyreRadius() * 2 + frontAddition + 50
                x.EndX = FrontOverhang
                x.EndY = axle1Front.getTyreRadius() * 2 + frontAddition + 50
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab - smallArcRadius, axle1Front.getTyreRadius() * 2 + frontAddition + 50, frontOverhang, axle1Front.getTyreRadius() * 2 + frontAddition + 50);
                /*
                'wheel arch
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = FrontOverhang
                w.Y = axle1Front.getTyreRadius() + frontAddition
                w.Radius = axle1Front.getTyreRadius() + 50
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(frontOverhang, axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius() + 50, 180, 90);
                /*
                'bottom of wheel arch
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang - axle1Front.getTyreRadius() - 50
                x.StartY = axle1Front.getTyreRadius() + frontAddition
                x.EndX = FrontOverhang - axle1Front.getTyreRadius() - 100
                x.EndY = 200 + frontAddition + smallArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang - axle1Front.getTyreRadius() - 50, axle1Front.getTyreRadius() + frontAddition, frontOverhang - axle1Front.getTyreRadius() - 100, 200 + frontAddition + smallArcRadius);
                /*
                'cab bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = FrontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius
                w.Y = 200 + frontAddition + smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 200 + frontAddition + smallArcRadius, smallArcRadius, 0, 90);
                /*
                'cab bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius
                x.StartY = 200 + frontAddition
                x.EndX = smallArcRadius
                x.EndY = 200 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 200 + frontAddition, smallArcRadius, 200 + frontAddition);
                /*
                'bumper bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius
                x.StartY = 400 + frontAddition
                x.EndX = smallArcRadius
                x.EndY = 400 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 400 + frontAddition, smallArcRadius, 400 + frontAddition);
                /*
                'bumper arch
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = FrontOverhang
                w.Y = axle1Front.getTyreRadius() + frontAddition
                w.Radius = axle1Front.getTyreRadius() + 100
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(frontOverhang, axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius() + 100, 180, 90);
                /*
                'bumper rear
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius
                x.StartY = 400 + frontAddition
                x.EndX = FrontOverhang - axle1Front.getTyreRadius() - 100
                x.EndY = axle1Front.getTyreRadius() + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang - axle1Front.getTyreRadius() - 100 - smallArcRadius, 400 + frontAddition, frontOverhang - axle1Front.getTyreRadius() - 100, axle1Front.getTyreRadius() + frontAddition);
                /*
                'bumper rear top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab
                x.StartY = axle1Front.getTyreRadius() * 2 + frontAddition + 100
                x.EndX = FrontOverhang
                x.EndY = axle1Front.getTyreRadius() * 2 + frontAddition + 100
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab, axle1Front.getTyreRadius() * 2 + frontAddition + 100, frontOverhang, axle1Front.getTyreRadius() * 2 + frontAddition + 100);
                /*
                'front bottom of door  
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 200
                x.StartY = 700 + frontAddition + medArcRadius
                x.EndX = 200
                x.EndY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(200, 700 + frontAddition + medArcRadius, 200, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition);
                /*
                'door front bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = 200 + medArcRadius
                w.Y = 700 + frontAddition + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 90
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(200 + medArcRadius, 700 + frontAddition + medArcRadius, medArcRadius, 90, 90);
                /*
                'front top of door  
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 200
                x.StartY = CabHeight * 0.1 + ChassisHeightFront * 1.1 + 150 + frontAddition
                x.EndX = BumperToBackOfCab * 0.37
                x.EndY = CabHeight * 0.94 + frontAddition - medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(200, cabHeight * 0.1 + chassisHeightFront * 1.1 + 150 + frontAddition, bumperToBackOfCab * 0.37,
                            cabHeight * 0.94 + frontAddition - medArcRadius);
                /*
                'door front top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.37 + medArcRadius
                w.Y = CabHeight * 0.94 + frontAddition - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.37 + medArcRadius, cabHeight * 0.94 + frontAddition - medArcRadius, medArcRadius, 180, 90);
                /*
                'top of door  
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.37 + medArcRadius
                x.StartY = CabHeight * 0.94 + frontAddition
                x.EndX = BumperToBackOfCab * 0.75 - medArcRadius
                x.EndY = CabHeight * 0.94 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.37 + medArcRadius, cabHeight * 0.94 + frontAddition, bumperToBackOfCab * 0.75 - medArcRadius,
                            cabHeight * 0.94 + frontAddition);
                /*
                'door rear top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.75 - medArcRadius
                w.Y = CabHeight * 0.94 + frontAddition - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.75 - medArcRadius, cabHeight * 0.94 + frontAddition - medArcRadius, medArcRadius, 270, 90);
                /*
                'rear of door  
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.75
                x.StartY = CabHeight * 0.94 + frontAddition - medArcRadius
                x.EndX = BumperToBackOfCab * 0.75
                x.EndY = axle1Front.getTyreRadius() * 2 + frontAddition + 75
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.75, cabHeight * 0.94 + frontAddition - medArcRadius, bumperToBackOfCab * 0.75, axle1Front.getTyreRadius() * 2 + frontAddition + 75);
                /*
                'bottom of door  
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang - axle1Front.getTyreRadius() - 75
                x.StartY = 700 + frontAddition
                x.EndX = 200 + medArcRadius
                x.EndY = 700 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang - axle1Front.getTyreRadius() - 75, 700 + frontAddition, 200 + medArcRadius, 700 + frontAddition);
                /*
                'rear of door handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.71
                x.StartY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
                x.EndX = BumperToBackOfCab * 0.71
                x.EndY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 120 + frontAddition, bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 50 + frontAddition);
                /*
                'bottom handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.71
                x.StartY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
                x.EndX = BumperToBackOfCab * 0.71 - 200
                x.EndY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 50 + frontAddition, bumperToBackOfCab * 0.71 - 200, chassisHeightFront * 1.1 + 150 + 50 + frontAddition);
                /*
                'front of door handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.71 - 200
                x.StartY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
                x.EndX = BumperToBackOfCab * 0.71 - 200
                x.EndY = ChassisHeightFront * 1.1 + 150 + 50 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.71 - 200, chassisHeightFront * 1.1 + 150 + 120 + frontAddition, bumperToBackOfCab * 0.71 - 200,
                            chassisHeightFront * 1.1 + 150 + 50 + frontAddition);
                /*
                'top of  handle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.71
                x.StartY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
                x.EndX = BumperToBackOfCab * 0.71 - 200
                x.EndY = ChassisHeightFront * 1.1 + 150 + 120 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.71, chassisHeightFront * 1.1 + 150 + 120 + frontAddition, bumperToBackOfCab * 0.71 - 200,
                            chassisHeightFront * 1.1 + 150 + 120 + frontAddition);
                /*
                'front of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.23
                x.StartY = CabHeight * 0.6 + frontAddition + medArcRadius
                x.EndX = BumperToBackOfCab * 0.39
                x.EndY = CabHeight * 0.9 + frontAddition - medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.23, cabHeight * 0.6 + frontAddition + medArcRadius, bumperToBackOfCab * 0.39, cabHeight * 0.9 + frontAddition - medArcRadius);
                /*
                'window front top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.39 + medArcRadius
                w.Y = CabHeight * 0.9 + frontAddition - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 180
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.39 + medArcRadius, cabHeight * 0.9 + frontAddition - medArcRadius, medArcRadius, 180, 90);
                /*
                'top of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.39 + medArcRadius
                x.StartY = CabHeight * 0.9 + frontAddition
                x.EndX = BumperToBackOfCab * 0.7 - medArcRadius
                x.EndY = CabHeight * 0.9 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.39 + medArcRadius, cabHeight * 0.9 + frontAddition, bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.9 + frontAddition);
                /*
                'window rear top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.7 - medArcRadius
                w.Y = CabHeight * 0.9 + frontAddition - medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.9 + frontAddition - medArcRadius, medArcRadius, 270, 90);
                /*
                'rear of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.7
                x.StartY = CabHeight * 0.9 + frontAddition - medArcRadius
                x.EndX = BumperToBackOfCab * 0.7
                x.EndY = CabHeight * 0.67 + frontAddition + medArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.7, cabHeight * 0.9 + frontAddition - medArcRadius, bumperToBackOfCab * 0.7, cabHeight * 0.67 + frontAddition + medArcRadius);
                /*
                'window rear bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.7 - medArcRadius
                w.Y = CabHeight * 0.67 + frontAddition + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.67 + frontAddition + medArcRadius, medArcRadius, 0, 90);
                /*
                'bottom of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.7 - medArcRadius
                x.StartY = CabHeight * 0.67 + frontAddition
                x.EndX = BumperToBackOfCab * 0.5
                x.EndY = CabHeight * 0.67 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.7 - medArcRadius, cabHeight * 0.67 + frontAddition, bumperToBackOfCab * 0.5, cabHeight * 0.67 + frontAddition);
                /*
                'bottom slant of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.5
                x.StartY = CabHeight * 0.67 + frontAddition
                x.EndX = BumperToBackOfCab * 0.4
                x.EndY = CabHeight * 0.6 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.5, cabHeight * 0.67 + frontAddition, bumperToBackOfCab * 0.4, cabHeight * 0.6 + frontAddition);
                /*
                'bottom of window
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab * 0.4
                x.StartY = CabHeight * 0.6 + frontAddition
                x.EndX = BumperToBackOfCab * 0.23 + medArcRadius
                x.EndY = CabHeight * 0.6 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(bumperToBackOfCab * 0.4, cabHeight * 0.6 + frontAddition, bumperToBackOfCab * 0.23 + medArcRadius, cabHeight * 0.6 + frontAddition);
                /*
                'window front bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = BumperToBackOfCab * 0.23 + medArcRadius
                w.Y = CabHeight * 0.6 + frontAddition + medArcRadius
                w.Radius = medArcRadius
                w.StartAngle = 90
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(bumperToBackOfCab * 0.23 + medArcRadius, cabHeight * 0.6 + frontAddition + medArcRadius, medArcRadius, 90, 90);
                /*
                'light
                'bottom
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = smallArcRadius
                x.StartY = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition - 200
                x.EndX = 150 - smallArcRadius
                x.EndY = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition - 200
                _SideViewDrawingObjects.Add(x)
                */
                addLine(smallArcRadius, cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition - 200, 150 - smallArcRadius,
                            cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition - 200);
                /*
                'bottom arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = 150 - smallArcRadius
                w.Y = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 0
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(150 - smallArcRadius, cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius, smallArcRadius, 0, 90);
                /*
                'middle
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 150
                x.StartY = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius
                x.EndX = 150
                x.EndY = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition - smallArcRadius
                _SideViewDrawingObjects.Add(x)
                */
                addLine(150, cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition - 200 + smallArcRadius, 150,
                            cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition - smallArcRadius);
                /*
                'top arc
                w = New UtilDrawingObjectArc
                w.Pen = Colors.Black
                w.X = 150 - smallArcRadius
                w.Y = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition - smallArcRadius
                w.Radius = smallArcRadius
                w.StartAngle = 270
                w.SweepAngle = 90
                _SideViewDrawingObjects.Add(w)
                */
                addArc(150 - smallArcRadius, cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition - smallArcRadius, smallArcRadius, 270, 90);
                /*
                'top
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = 150 - smallArcRadius
                x.StartY = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition
                x.EndX = smallArcRadius
                x.EndY = CabHeight * 0.07 + ChassisHeightFront * 1.1 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(150 - smallArcRadius, cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition, smallArcRadius, cabHeight * 0.07 + chassisHeightFront * 1.1 + frontAddition);
                /*
                'draw chassis
                'top line
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang
                x.StartY = ChassisHeightFront + frontAddition
                x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                            Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
                x.EndY = ChassisHeightRear + rearAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang, chassisHeightFront + frontAddition, frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear + rearAddition);
                /*
                'rear line
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                            Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
                x.StartY = ChassisHeightRear + rearAddition
                x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                            Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
                x.EndY = ChassisHeightRear - 200 + rearAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear + rearAddition, frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear - 200 + rearAddition);
                /*
                'bottom line
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                            Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
                x.StartY = ChassisHeightRear - 200 + rearAddition
                x.EndX = FrontOverhang
                x.EndY = ChassisHeightFront - 200 + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
                addLine(frontOverhang + axle1FrontToAxle2Front +
                            axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear +
                            rearOverhang, chassisHeightRear - 200 + rearAddition, frontOverhang, chassisHeightFront - 200 + frontAddition);
            }
            break;
    }

    if (type !== config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        if (cabToAxles !== rearMostProtrudingItemBehindCabToRearAxles && control !== config.CONTROL_TYPES.SEMI_FORWARD) { 
            /*
                'top line
                x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
                x.Pen = Colors.Black
                x.StartX = BumperToBackOfCab
                x.StartY = (CabHeight - ChassisHeightFront) * 0.5 + ChassisHeightFront + frontAddition
                x.EndX = BumperToBackOfCab + CabToAxles - RearMostProtrudingItemBehindCabToAxles
                x.EndY = (CabHeight - ChassisHeightFront) * 0.5 + ChassisHeightFront + frontAddition
                _SideViewDrawingObjects.Add(x)
                */
            addLine(bumperToBackOfCab, (cabHeight - chassisHeightFront) * 0.5 + chassisHeightFront + frontAddition,
                        bumperToBackOfCab + cabToAxles - rearMostProtrudingItemBehindCabToRearAxles,
                        (cabHeight - chassisHeightFront) * 0.5 + chassisHeightFront + frontAddition);
            /*
            'rear line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab + CabToAxles - RearMostProtrudingItemBehindCabToAxles
            x.StartY = (CabHeight - ChassisHeightFront) * 0.5 + ChassisHeightFront + frontAddition
            x.EndX = BumperToBackOfCab + CabToAxles - RearMostProtrudingItemBehindCabToAxles
            x.EndY = ChassisHeightFront * 1.1 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab + cabToAxles - rearMostProtrudingItemBehindCabToRearAxles,
                        (cabHeight - chassisHeightFront) * 0.5 + chassisHeightFront + frontAddition,
                        bumperToBackOfCab + cabToAxles - rearMostProtrudingItemBehindCabToRearAxles,
                        chassisHeightFront * 1.1 + frontAddition);
            /*
            'bottom line
            x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
            x.Pen = Colors.Black
            x.StartX = BumperToBackOfCab + CabToAxles - RearMostProtrudingItemBehindCabToAxles
            x.StartY = ChassisHeightFront * 1.1 + frontAddition
            x.EndX = BumperToBackOfCab
            x.EndY = ChassisHeightFront * 1.1 + frontAddition
            _SideViewDrawingObjects.Add(x)
            */
            addLine(bumperToBackOfCab + cabToAxles - rearMostProtrudingItemBehindCabToRearAxles,
                        chassisHeightFront * 1.1 + frontAddition,
                        bumperToBackOfCab,
                        chassisHeightFront * 1.1 + frontAddition);
        }
    }

    if (rearProtrusion > 0) {
        /*
        'top line
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + _Axle3RearToAxle4Rear + RearOverhang
        x.StartY = ChassisHeightRear - 200 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang + RearProtrusion
        x.EndY = ChassisHeightRear - 200 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear +
                    axle3RearToAxle4Rear + rearOverhang, chassisHeightRear - 200 + rearAddition,
                        overallLengthExclAccessories,
                        chassisHeightRear - 200 + rearAddition);
        /*
        'rear line
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang + RearProtrusion
        x.StartY = ChassisHeightRear - 200 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang + RearProtrusion
        x.EndY = ChassisHeightRear - 300 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(overallLengthExclAccessories, chassisHeightRear - 200 + rearAddition,
                        overallLengthExclAccessories,
                        chassisHeightRear - 300 + rearAddition);
        /*
        'bottom line
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang + RearProtrusion
        x.StartY = ChassisHeightRear - 300 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + +RearOverhang
        x.EndY = ChassisHeightRear - 300 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(overallLengthExclAccessories, chassisHeightRear - 300 + rearAddition, frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear +
                    axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear + rearOverhang,
                        chassisHeightRear - 300 + rearAddition);
        /*
        'front line
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
        x.StartY = ChassisHeightRear - 300 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToAxle2Front + Axle2FrontToAxle1Rear + Axle1RearToAxle2Rear +
                    Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + RearOverhang
        x.EndY = ChassisHeightRear - 200 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear +
                    axle3RearToAxle4Rear + rearOverhang, chassisHeightRear - 300 + rearAddition, frontOverhang + axle1FrontToAxle2Front +
                    axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear + rearOverhang,
                        chassisHeightRear - 200 + rearAddition);
    }

    if (tareFifthWheelIncluded === config.VEHICLE_INCLUSION_TYPES.YES) {
        /*
        'front of base
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 400
        x.StartY = ChassisHeightRear + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 350
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.47 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 400, chassisHeightRear + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 350,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.47 + rearAddition);
        /*
        'top of base
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 350
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.47 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 350
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.47 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 350,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.47 + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 350,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.47 + rearAddition);
        /*
        'rear of base
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 350
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.47 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 400
        x.EndY = ChassisHeightRear + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 350,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.47 + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 400,
                        chassisHeightRear + rearAddition);
        /*
        'middle front
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 150
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.47 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 150
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.8 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 150,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.47 + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 150,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.8 + rearAddition);
        /*
        'middle rear
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 150
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.47 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 150
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.8 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 150,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.47 + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 150,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.8 + rearAddition);
        /*
        'front of fw
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 350
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.8 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 350
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 350,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.8 + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 350,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) + rearAddition);
        /*
        'top of fw
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 350
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 200
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 350,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 200,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) + rearAddition);
        /*
        'rear of fw
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 200
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 350
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.8 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 200,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 350,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.8 + rearAddition);
        /*
        'bottom of fw
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset + 350
        x.StartY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.8 + rearAddition
        x.EndX = FrontOverhang + Axle1FrontToWheelbaseTheoreticalStart + WheelbaseTheoretical - FifthWheelOffset - 350
        x.EndY = ChassisHeightRear + (FifthWheelHeightFromGround - ChassisHeightRear) * 0.8 + rearAddition
        _SideViewDrawingObjects.Add(x)
        */
        addLine(frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset + 350,
                    chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.8 + rearAddition,
                    frontOverhang + axle1FrontToWheelbaseTheoreticalStart + wheelbaseTheoretical - fifthWheelOffset - 350,
                        chassisHeightRear + (fifthWheelHeightFromGround - chassisHeightRear) * 0.8 + rearAddition);
    }

    var frontAxleGroup = curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
    var rearAxleGroup = curVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);

    var path = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES;
    var groupPath = config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS;
    var drawGroupName;
    var maxLiftingTravel = 0;

    if (axle1Front.getRaised()) {
        maxLiftingTravel = axle1Front.getLiftingMaxTravel();
    } else {
        maxLiftingTravel = 0;
    }

    /*
    If axle1Front.Raised Then
        maxLiftingTravel = axle1Front.MaxLiftingTravel
    Else
        maxLiftingTravel = 0
    End If
    */

    /*
    'draw wheels
    'draw 1st front wheel
    'outer
    y = New UtilDrawingObjectCircle
    y.Pen = Colors.Black
    y.X = FrontOverhang
    y.Y = maxLiftingTravel + axle1Front.getTyreRadius() + frontAddition
    y.Radius = axle1Front.getTyreRadius()
    _SideViewDrawingObjects.Add(y)
    */
    addCircle(frontOverhang, maxLiftingTravel + axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius(), CIRCLE_TYPE.OUTER, !axle1Front.getRaised(), groupPath + '.' + frontAxleGroup.getId() + '.' + axle1Front.getNumber());
    /*
    'inner
    y = New UtilDrawingObjectCircle
    y.Pen = Colors.Black
    y.X = FrontOverhang
    y.Y = maxLiftingTravel + axle1Front.getTyreRadius() + frontAddition
    y.Radius = axle1Front.getTyreRadius() * 0.5
    _SideViewDrawingObjects.Add(y)
    */
    addCircle(frontOverhang, maxLiftingTravel + axle1Front.getTyreRadius() + frontAddition, axle1Front.getTyreRadius() * 0.5, CIRCLE_TYPE.INNER, undefined, groupPath + '.' + frontAxleGroup.getId() + '.' + axle1Front.getNumber());

    ////ground
    //if (maxLiftingTravel === 0) {
    //    /*
    //    'only need to draw the underline if the axle is down
    //    x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
    //    x.Pen = Colors.Black
    //    x.StartX = FrontOverhang - axle1Front.getTyreRadius()
    //    x.StartY = frontAddition
    //    x.EndX = FrontOverhang + axle1Front.getTyreRadius()
    //    x.EndY = frontAddition
    //    _SideViewDrawingObjects.Add(x)
    //    */
    //    addLine(frontOverhang - axle1Front.getTyreRadius(), frontAddition, frontOverhang - axle1Front.getTyreRadius(), frontAddition);
    //}

    /*
    'letter
    z = New UtilDrawingObjectString
    z.Pen = Colors.Black
    z.StartX = FrontOverhang
    z.StartY = maxLiftingTravel + axle1Front.getTyreRadius() + frontAddition
    z.Letter = Convert.ToChar(65 + axleNumber)
    _SideViewDrawingObjects.Add(z)
    */
    //drawTString(frontOverhang, maxLiftingTravel + axle1Front.getTyreRadius() + frontAddition, String.fromCharCode(65 + axleNumber));
    //axleNumber += 1

    if ((axle2Front !== null && axle2Front !== undefined) && axle1FrontToAxle2Front !== 0) {

        if (axle2Front.getRaised()) {
            maxLiftingTravel = axle2Front.getLiftingMaxTravel();
        } else {
            maxLiftingTravel = 0;
        }
        /*
        If axle2Front.Raised Then
        maxLiftingTravel = axle2Front.MaxLiftingTravel
        Else
        maxLiftingTravel = 0
        End If
        */

        /*
        'draw 2nd front wheel
        'outer
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + _Axle1FrontToAxle2Front
        y.Y = maxLiftingTravel + axle2Front.getTyreRadius() + frontAddition
        y.Radius = axle2Front.getTyreRadius()
        _SideViewDrawingObjects.Add(y)
        */
        //if (axle2Front.getLifting()) {
            drawGroupName = groupPath + '.' + frontAxleGroup.getId() + '.' + axle2Front.getNumber();
        //}else {
        //    drawGroupName = undefined;
        //}

        addCircle(frontOverhang + axle1FrontToAxle2Front, maxLiftingTravel + axle2Front.getTyreRadius() + frontAddition, axle2Front.getTyreRadius(), CIRCLE_TYPE.OUTER, !axle2Front.getRaised(), drawGroupName);
        /*
        'inner
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + _Axle1FrontToAxle2Front
        y.Y = maxLiftingTravel + axle2Front.getTyreRadius() + frontAddition
        y.Radius = axle2Front.getTyreRadius() * 0.5
        _SideViewDrawingObjects.Add(y)
        */
        addCircle(frontOverhang + axle1FrontToAxle2Front, maxLiftingTravel + axle2Front.getTyreRadius() + frontAddition, axle2Front.getTyreRadius() * 0.5, CIRCLE_TYPE.INNER, undefined, drawGroupName);
        /*
        'ground
        If maxLiftingTravel = 0 Then
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang - axle2Front.getTyreRadius() + _Axle1FrontToAxle2Front
        x.StartY = frontAddition
        x.EndX = FrontOverhang + axle2Front.getTyreRadius() + _Axle1FrontToAxle2Front
        x.EndY = frontAddition
        _SideViewDrawingObjects.Add(x)
        End If
        */
        //addLine(frontOverhang - axle2Front.getTyreRadius() + axle1FrontToAxle2Front, frontAddition, frontOverhang - axle2Front.getTyreRadius() + axle1FrontToAxle2Front, frontAddition);
        /*
        'letter
        z = New UtilDrawingObjectString
        z.Pen = Colors.Black
        z.StartX = FrontOverhang + _Axle1FrontToAxle2Front
        z.StartY = maxLiftingTravel + axle2Front.getTyreRadius() + frontAddition
        z.Letter = Convert.ToChar(65 + axleNumber)
        _SideViewDrawingObjects.Add(z)
        */
        //drawTString(frontOverhang + axle1FrontToAxle2Front, maxLiftingTravel + axle2Front.getTyreRadius() + frontAddition, String.fromCharCode(65 + axleNumber));
        //axleNumber += 1

    }

    if (axle1Rear.getRaised()) {
        maxLiftingTravel = axle1Rear.getLiftingMaxTravel();
    } else {
        maxLiftingTravel = 0;
    }

    if (axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
        drawGroupName = path + '.' + axle1Rear.getId();
    } else {
        drawGroupName = groupPath + '.' + rearAxleGroup.getId() + '.' + axle1Rear.getNumber();
    }
        
    
    /*
    If axle1Rear.Raised Then
        maxLiftingTravel = axle1Rear.MaxLiftingTravel
    Else
        maxLiftingTravel = 0
    End If
    */


    /*
    'draw 1st rear wheel
    'outer
    y = New UtilDrawingObjectCircle
    y.Pen = Colors.Black
    y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear
    y.Y = maxLiftingTravel + axle1Rear.getTyreRadius() + rearAddition
    y.Radius = axle1Rear.getTyreRadius()
    _SideViewDrawingObjects.Add(y)
    */
    addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear, maxLiftingTravel + axle1Rear.getTyreRadius() + rearAddition, axle1Rear.getTyreRadius(), CIRCLE_TYPE.OUTER, !axle1Rear.getRaised(), drawGroupName);
    /*
    'inner
    y = New UtilDrawingObjectCircle
    y.Pen = Colors.Black
    y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear
    y.Y = maxLiftingTravel + axle1Rear.getTyreRadius() + rearAddition
    y.Radius = axle1Rear.getTyreRadius() * 0.5
    _SideViewDrawingObjects.Add(y)
    */
    addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear, maxLiftingTravel + axle1Rear.getTyreRadius() + rearAddition, axle1Rear.getTyreRadius() * 0.5, CIRCLE_TYPE.INNER, undefined, drawGroupName);

    ////ground
    //if (maxLiftingTravel = 0) {
    //    /*
    //    x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
    //    x.Pen = Colors.Black
    //    x.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear - axle1Rear.getTyreRadius()
    //    x.StartY = rearAddition
    //    x.EndX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + axle1Rear.getTyreRadius()
    //    x.EndY = rearAddition
    //    _SideViewDrawingObjects.Add(x)
    //    */
    //    addLine(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear - axle1Rear.getTyreRadius(), rearAddition,
    //                frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1Rear.getTyreRadius(), rearAddition);
    //}
    /*
    'letter
    z = New UtilDrawingObjectString
    z.Pen = Colors.Black
    z.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear
    z.StartY = maxLiftingTravel + axle1Rear.getTyreRadius() + rearAddition
    z.Letter = Convert.ToChar(65 + axleNumber)
    _SideViewDrawingObjects.Add(z)
    */
    //drawTString(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear, maxLiftingTravel + axle1Rear.getTyreRadius() + rearAddition, String.fromCharCode(65 + axleNumber));
    //axleNumber += 1


    if ((axle2Rear !== null && axle2Rear !== undefined) && axle1RearToAxle2Rear !== 0) {

        if (axle2Rear.getRaised()) {
            maxLiftingTravel = axle2Rear.getLiftingMaxTravel();
        } else {
            maxLiftingTravel = 0;
        }

        if (axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            drawGroupName = path + '.' + axle2Rear.getId();
        } else {
            drawGroupName = groupPath + '.' + rearAxleGroup.getId() + '.' + axle2Rear.getNumber();
        }
        /*
        If axle2Rear.Raised Then
        maxLiftingTravel = axle2Rear.MaxLiftingTravel
        Else
        maxLiftingTravel = 0
        End If
        */

        /*
        'draw 2nd rear wheel
        'outer
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear
        y.Y = maxLiftingTravel + axle2Rear.getTyreRadius() + rearAddition
        y.Radius = axle2Rear.getTyreRadius()
        _SideViewDrawingObjects.Add(y)
        */
        addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear, maxLiftingTravel + axle2Rear.getTyreRadius() + rearAddition, axle2Rear.getTyreRadius(), CIRCLE_TYPE.OUTER, !axle2Rear.getRaised(), drawGroupName);
        /*
        'inner
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear
        y.Y = maxLiftingTravel + axle2Rear.getTyreRadius() + rearAddition
        y.Radius = axle2Rear.getTyreRadius() * 0.5
        _SideViewDrawingObjects.Add(y)
        */
        addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear, maxLiftingTravel + axle2Rear.getTyreRadius() + rearAddition, axle2Rear.getTyreRadius() * 0.5, CIRCLE_TYPE.INNER, undefined, drawGroupName);
        /*
        'ground
        If maxLiftingTravel = 0 Then
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear - axle2Rear.getTyreRadius()
        x.StartY = rearAddition
        x.EndX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + axle2Rear.getTyreRadius()
        x.EndY = rearAddition
        _SideViewDrawingObjects.Add(x)
        End If
        */
        //addLine(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear - axle2Rear.getTyreRadius(), rearAddition,
        //            frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2Rear.getTyreRadius(), rearAddition);
        /*
        'letter
        z = New UtilDrawingObjectString
        z.Pen = Colors.Black
        z.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear
        z.StartY = maxLiftingTravel + axle2Rear.getTyreRadius() + rearAddition
        z.Letter = Convert.ToChar(65 + axleNumber)
        _SideViewDrawingObjects.Add(z)
        */
        //drawTString(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear, maxLiftingTravel + axle2Rear.getTyreRadius() + rearAddition, String.fromCharCode(65 + axleNumber));
        //axleNumber += 1

    }

    if ((axle3Rear !== null && axle3Rear !== undefined) && axle2RearToAxle3Rear !== 0) {

        if (axle3Rear.getRaised()) {
            maxLiftingTravel = axle3Rear.getLiftingMaxTravel();
        } else {
            maxLiftingTravel = 0;
        }

        if (axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            drawGroupName = path + '.' + axle3Rear.getId();
        } else {
            drawGroupName = groupPath + '.' + rearAxleGroup.getId() + '.' + axle3Rear.getNumber();
        }
        /*
        If axle3Rear.Raised Then
        maxLiftingTravel = axle3Rear.MaxLiftingTravel
        Else
        maxLiftingTravel = 0
        End If
        */

        /*
        'draw 3nd rear wheel
        'outer
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear
        y.Y = maxLiftingTravel + axle3Rear.getTyreRadius() + rearAddition
        y.Radius = axle3Rear.getTyreRadius()
        _SideViewDrawingObjects.Add(y)
        */
        addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear,
                    maxLiftingTravel + axle3Rear.getTyreRadius() + rearAddition, axle3Rear.getTyreRadius(), CIRCLE_TYPE.OUTER, !axle3Rear.getRaised(), drawGroupName);
        /*
        'inner
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear
        y.Y = maxLiftingTravel + axle3Rear.getTyreRadius() + rearAddition
        y.Radius = axle3Rear.getTyreRadius() * 0.5
        _SideViewDrawingObjects.Add(y)
        */
        addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear,
                    maxLiftingTravel + axle3Rear.getTyreRadius() + rearAddition, axle3Rear.getTyreRadius() * 0.5, CIRCLE_TYPE.INNER, undefined, drawGroupName);
        /*
        'ground
        If maxLiftingTravel = 0 Then
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear - axle3Rear.getTyreRadius()
        x.StartY = rearAddition
        x.EndX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear + axle3Rear.getTyreRadius()
        x.EndY = rearAddition
        _SideViewDrawingObjects.Add(x)
        End If
        */
        //addLine(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear - axle3Rear.getTyreRadius(), rearAddition,
        //            frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3Rear.getTyreRadius(), rearAddition);
        /*
        'letter
        z = New UtilDrawingObjectString
        z.Pen = Colors.Black
        z.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear
        z.StartY = maxLiftingTravel + axle3Rear.getTyreRadius() + rearAddition
        z.Letter = Convert.ToChar(65 + axleNumber)
        _SideViewDrawingObjects.Add(z)
        */
        //drawTString(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, maxLiftingTravel + axle3Rear.getTyreRadius() + rearAddition,
        //            String.fromCharCode(65 + axleNumber));
        //axleNumber += 1

    }

    if ((axle4Rear !== null && axle4Rear !== undefined) && axle3RearToAxle4Rear !== 0) {

        if (axle4Rear.getRaised()) {
            maxLiftingTravel = axle4Rear.getLiftingMaxTravel();
        } else {
            maxLiftingTravel = 0;
        }


        if (axle4Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            drawGroupName = path + '.' + axle4Rear.getId();
        } else {
            drawGroupName = groupPath + '.' + rearAxleGroup.getId() + '.' + axle4Rear.getNumber();
        }
        /*
        If axle4Rear.Raised Then
        maxLiftingTravel = axle4Rear.MaxLiftingTravel
        Else
        maxLiftingTravel = 0
        End If
        */

        /*
        'draw 4th rear wheel
        'outer
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear + _Axle3RearToAxle4Rear
        y.Y = maxLiftingTravel + axle4Rear.getTyreRadius() + rearAddition
        y.Radius = axle4Rear.getTyreRadius()
        _SideViewDrawingObjects.Add(y)
        */
        addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear,
                    maxLiftingTravel + axle4Rear.getTyreRadius() + rearAddition, axle4Rear.getTyreRadius(), CIRCLE_TYPE.OUTER, !axle4Rear.getRaised(), drawGroupName);
        /*
        'inner
        y = New UtilDrawingObjectCircle
        y.Pen = Colors.Black
        y.X = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear + _Axle3RearToAxle4Rear
        y.Y = maxLiftingTravel + axle4Rear.getTyreRadius() + rearAddition
        y.Radius = axle4Rear.getTyreRadius() * 0.5
        _SideViewDrawingObjects.Add(y)
        */
        addCircle(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear,
                    maxLiftingTravel + axle4Rear.getTyreRadius() + rearAddition, axle4Rear.getTyreRadius() * 0.5, CIRCLE_TYPE.INNER, undefined, drawGroupName);
        /*
        'ground
        If maxLiftingTravel = 0 Then
        x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
        x.Pen = Colors.Black
        x.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear + Axle3RearToAxle4Rear - axle4Rear.getTyreRadius()
        x.StartY = rearAddition
        x.EndX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear + Axle3RearToAxle4Rear + axle4Rear.getTyreRadius()
        x.EndY = rearAddition
        _SideViewDrawingObjects.Add(x)
        End If
        */
        //addLine(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear +
        //            axle3RearToAxle4Rear - axle4Rear.getTyreRadius(), rearAddition, frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear +
        //            axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear + axle4Rear.getTyreRadius(), rearAddition);
        /*
        'letter
        z = New UtilDrawingObjectString
        z.Pen = Colors.Black
        z.StartX = FrontOverhang + Axle1FrontToAxle2Front + _Axle2FrontToAxle1Rear + _Axle1RearToAxle2Rear + _Axle2RearToAxle3Rear + _Axle3RearToAxle4Rear
        z.StartY = maxLiftingTravel + axle4Rear.getTyreRadius() + rearAddition
        z.Letter = Convert.ToChar(65 + axleNumber)
        _SideViewDrawingObjects.Add(z)
        */
        //drawTString(frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, maxLiftingTravel + axle4Rear.getTyreRadius() + rearAddition,
        //            String.fromCharCode(65 + axleNumber));
        //axleNumber += 1

    }


    //return drawingObjectsArray;



    
}

function getPayloadLabel(payload, chassisObject) {

    var payloadDeckStartPos = getPayloadDeckStartPosition(chassisObject);
    var varticalScreenDimensionToUse, payloadVerticalScreenOffsetToUse;
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        varticalScreenDimensionToUse = payload.getHeight();
        payloadVerticalScreenOffsetToUse = payload.getStartY();
    } else {
        varticalScreenDimensionToUse = payload.getWidth();
        if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
            payloadVerticalScreenOffsetToUse = payload.getStartZ();
        } else {
            payloadVerticalScreenOffsetToUse = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + payload.getStartZ();
        }
        
    }
    var payloadLabelPos;
    if ((chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2)&& curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        //payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + payload.getLength() / 2 + payload.getLength() / 4 + payload.getLength() / 8, y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse / 2 + varticalScreenDimensionToUse / 4 };
        //payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + payload.getLength(), y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse };
        if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            if (trailerTransformation && trailerTransformation.angle > 0) {
                payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + payload.getLength() / 2 + ((25 / scalingFactor) / 2), y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse / 2 + (25 / scalingFactor) };
            } else {
                payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + payload.getLength() / 2, y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse / 2 };
            }
            
        } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
            if (trailer2Transformation && trailer2Transformation.angle > 0) {
                payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + payload.getLength() / 2 + ((25 / scalingFactor) / 2), y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse / 2 + (25 / scalingFactor) };
            } else {
                payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + payload.getLength() / 2, y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse / 2 };
            }
            
            //payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + ((payload.getLength() - (25 / scalingFactor)) / 2), y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse - ((varticalScreenDimensionToUse - (25 / scalingFactor)) / 2) };
        }
    } else {
        payloadLabelPos = { x: payloadDeckStartPos.x + payload.getStartX() + payload.getLength() / 2, y: payloadDeckStartPos.y + payloadVerticalScreenOffsetToUse + varticalScreenDimensionToUse / 2 };
    }
    
    

    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        applyTransformationToPoint(payloadLabelPos, vehicleTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        applyTransformationToPoint(payloadLabelPos, trailerTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        applyTransformationToPoint(payloadLabelPos, trailer2Transformation);
    }
    //var payloadTextObject = getPayloadLabel(payload, payloadLabelPos.x, payloadLabelPos.y, chassisObject);

    

    var newText = new Text(payload.getNumber(), payloadLabelPos.x, payloadLabelPos.y, undefined, config.DRAWING_COLOURS.WHITE, labelFontSize + 'px ' + curFontName);
    newText.setBackgroundColour(config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR);
    newText.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + payload.getId());
    newText.setBackgroundWidth(25);
    newText.setBackgroundHeight(25);

    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        newText.setRotationAngle(vehicleTransformation.angle);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        newText.setRotationAngle(trailerTransformation.angle);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        newText.setRotationAngle(trailer2Transformation.angle);
    }

    return newText;
}

function getPayloadDeckStartPosition(chassisObject) {

    var posX = 0, posY = 0;
    posX = offerManager.getRigOps().getPayloadDeckStartPositionFrontFrontInclPortionOfVehicleOverhangingFront(chassisObject);
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        posY = offerManager.getRigOps().getPayloadDeckStartPositionFromBottom(chassisObject);
    } else if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        posY = offerManager.getRigOps().getPayloadDeckStartPositionFromLeftForTurningCircle(chassisObject);
    } else {
        posY = offerManager.getRigOps().getPayloadDeckStartPositionFromLeftInclPortionOfChassisOverhangingLeft(chassisObject);
    }

    return { x: posX, y: posY };
}

function populateDrawingObjectsForTemplatePayload(payload, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplatePayloadSideView(payload, chassisObject);
    } else {
        populateDrawingObjectsForTemplatePayloadTopView(payload, chassisObject);
    }
}

function populateDrawingObjectsForTemplatePayloadTopView(payload, chassisObject) { 
    var payloadDrawingObjects = [];


    var payloadLayerNum = 12, objectNum = 1000000, blockName = 'PAYLOAD1', sequenceNum = 1;

    var payloadOriginX,
        payloadOriginY;
    var drawingGroupName = chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + payload.getId();
    
    var payloadDeckStartPos = getPayloadDeckStartPosition(chassisObject);

    payloadOriginX = payloadDeckStartPos.x + payload.getStartX();
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        payloadOriginY = payloadDeckStartPos.y + payload.getStartZ();
    } else {
        payloadOriginY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + payloadDeckStartPos.y + payload.getStartZ();
    }
    

    var payloadLength = payload.getLength(),
        payloadWidth = payload.getWidth();



    //var newRect = new Rect(payloadOriginX, payloadOriginY, payloadWidth, payloadHeight);

    //newRect.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.PAYLOAD);
    //drawingObjectsArray.push(newRect);
    var topLeftCorner = { x: 0, y: 0 };
    var topRightCorner = { x: payloadLength, y: 0 };
    var bottomRightCorner = { x: payloadLength, y: payloadWidth };
    var bottomLeftCorner = { x: 0, y: payloadWidth };

    addPayloadLine(topLeftCorner.x, topLeftCorner.y, topRightCorner.x, topRightCorner.y, payloadLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    addPayloadLine(topRightCorner.x, topRightCorner.y, bottomRightCorner.x, bottomRightCorner.y, payloadLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    addPayloadLine(bottomRightCorner.x, bottomRightCorner.y, bottomLeftCorner.x, bottomLeftCorner.y, payloadLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    addPayloadLine(bottomLeftCorner.x, bottomLeftCorner.y, topLeftCorner.x, topLeftCorner.y, payloadLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

    var newText = getPayloadLabel(payload, chassisObject);
    payloadDrawingObjects.push(newText);

    payloadDrawingObjects.push(getCornerFill(topLeftCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.TOP_LEFT, drawingGroupName));
    payloadDrawingObjects.push(getCornerFill(topRightCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.TOP_RIGHT, drawingGroupName));
    payloadDrawingObjects.push(getCornerFill(bottomLeftCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.BOTTOM_LEFT, drawingGroupName));
    payloadDrawingObjects.push(getCornerFill(bottomRightCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.BOTTOM_RIGHT, drawingGroupName));

    //if (vehicleTransformation !== null) {
    //    applyTransformationsToDrawingObjects(payloadDrawingObjects, vehicleTransformation);
    //}

    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(payloadDrawingObjects, vehicleTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        applyTransformationsToDrawingObjects(payloadDrawingObjects, trailerTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        applyTransformationsToDrawingObjects(payloadDrawingObjects, trailer2Transformation);
    }
    payloadDrawingObjects.sort(sortDrawingObjects);
    payloadDrawingObjects = groupDrawingObjects(payloadDrawingObjects);
    addToMainDrawingObjectsArray(payloadDrawingObjects);

    function addPayloadLine(startX, startY, endX, endY, layerNum, blockName, objectNumSequenceNum, closedIndicator) {
        var newLine = new Line(payloadOriginX + startX, payloadOriginY + startY, payloadOriginX + endX, payloadOriginY + endY, layerNum, blockName, objectNumSequenceNum, closedIndicator);
        newLine.setDrawingGroupName(drawingGroupName);
        newLine.setColour(config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR);
        payloadDrawingObjects.push(newLine);
    }

    
}

function getCornerFill(position, payloadOriginX, payloadOriginY, cornerType, drawingGroupName) {
    var polygon = new Polygon();
    var points = [];
    var fillEdgeLength = 12 / scalingFactor;

    points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y });
    switch (cornerType) {
        case config.CORNER_TYPES.TOP_LEFT:
            points.push({ x: payloadOriginX + position.x + fillEdgeLength, y: payloadOriginY + position.y });
            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y + fillEdgeLength });
            break;
        case config.CORNER_TYPES.TOP_RIGHT:
            points.push({ x: payloadOriginX + position.x - fillEdgeLength, y: payloadOriginY + position.y });
            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y + fillEdgeLength });
            break;
        case config.CORNER_TYPES.BOTTOM_LEFT:
            points.push({ x: payloadOriginX + position.x + fillEdgeLength, y: payloadOriginY + position.y });
            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y - fillEdgeLength });
            break;
        case config.CORNER_TYPES.BOTTOM_RIGHT:
            points.push({ x: payloadOriginX + position.x - fillEdgeLength, y: payloadOriginY + position.y });
            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y - fillEdgeLength });
            break;
    }

    polygon.pointsArray = points;


    polygon.fillColour = config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR;



    polygon.setDrawingGroupName(drawingGroupName);
    polygon.setColour(config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR);
    polygon.setFillPolygon(true);
    return polygon;
}

function populateDrawingObjectsForTemplatePayloadSideView(payload, chassisObject) {
    var payloadOriginX,
        payloadOriginY;
    var drawingGroupName = chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.PAYLOAD + '.' + payload.getId();
    //var tempBody = chassisObject.getAccessoryHolder().getBody();

    //switch (chassisObject.getChassisObjectName()) {
    //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //        if (tempBody !== null) {
    //            deckStartX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab() + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness();
    //            deckStartY = chassisObject.getChassisHeightMax() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
    //        } else {
    //            deckStartX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab();
    //            deckStartY = chassisObject.getChassisHeightMax();
    //        }
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //        if (tempBody !== null) {
    //            deckStartX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness();
    //            deckStartY = chassisObject.getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
    //        } else {
    //            deckStartX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    //            deckStartY = chassisObject.getChassisHeight();
    //        }
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //        if (tempBody !== null) {
    //            deckStartX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + tempBody.getHorizontalGap() + tempBody.getHeadboardThickness();
    //            deckStartY = chassisObject.getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
    //        } else {
    //            deckStartX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    //            deckStartY = chassisObject.getChassisHeight();
    //        }
    //        break;
    //}
    var payloadDeckStartPos = getPayloadDeckStartPosition(chassisObject);

    payloadOriginX = payloadDeckStartPos.x + payload.getStartX();
    payloadOriginY = payloadDeckStartPos.y + payload.getStartY();

    var payloadWidth = payload.getLength(),
        payloadHeight = payload.getHeight();

    

    //var newRect = new Rect(payloadOriginX, payloadOriginY, payloadWidth, payloadHeight);
    
    //newRect.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.PAYLOAD);
    //drawingObjectsArray.push(newRect);
    var topLeftCorner = { x: 0, y: 0 };
    var topRightCorner = { x: payloadWidth, y: 0 };
    var bottomRightCorner = { x: payloadWidth, y: payloadHeight };
    var bottomLeftCorner = { x: 0, y: payloadHeight };

    addPayloadLine(topLeftCorner.x, topLeftCorner.y, topRightCorner.x, topRightCorner.y);
    addPayloadLine(topRightCorner.x, topRightCorner.y, bottomRightCorner.x, bottomRightCorner.y);
    addPayloadLine(bottomRightCorner.x, bottomRightCorner.y, bottomLeftCorner.x, bottomLeftCorner.y);
    addPayloadLine(bottomLeftCorner.x, bottomLeftCorner.y, topLeftCorner.x, topLeftCorner.y);

    var newText = getPayloadLabel(payload, chassisObject);
    drawingObjectsArray.push(newText);

    drawingObjectsArray.push(getCornerFill(topLeftCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.TOP_LEFT, drawingGroupName));
    drawingObjectsArray.push(getCornerFill(topRightCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.TOP_RIGHT, drawingGroupName));
    drawingObjectsArray.push(getCornerFill(bottomLeftCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.BOTTOM_LEFT, drawingGroupName));
    drawingObjectsArray.push(getCornerFill(bottomRightCorner, payloadOriginX, payloadOriginY, config.CORNER_TYPES.BOTTOM_RIGHT, drawingGroupName));

    function addPayloadLine(startX, startY, endX, endY) {
        var newLine = new Line(payloadOriginX + startX, payloadOriginY + startY, payloadOriginX + endX, payloadOriginY + endY);
        newLine.setDrawingGroupName(drawingGroupName);
        newLine.setColour(config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR);

        if (isItemObscured(chassisObject, payload, payloadOriginY, payloadOriginX)) {
            dashItemAndBringForward(newLine);
        }
        
        drawingObjectsArray.push(newLine);
    }

    //function getCornerFill(position, cornerType, drawingGroupName) {
    //    var polygon = new Polygon();
    //    var points = [];
    //    var fillEdgeLength = 12/scalingFactor;

    //    points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y });
    //    switch (cornerType) {
    //        case config.CORNER_TYPES.TOP_LEFT:
    //            points.push({ x: payloadOriginX + position.x + fillEdgeLength, y: payloadOriginY + position.y });
    //            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y + fillEdgeLength });
    //            break;
    //        case config.CORNER_TYPES.TOP_RIGHT:
    //            points.push({ x: payloadOriginX + position.x - fillEdgeLength, y: payloadOriginY + position.y });
    //            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y + fillEdgeLength });
    //            break;
    //        case config.CORNER_TYPES.BOTTOM_LEFT:
    //            points.push({ x: payloadOriginX + position.x + fillEdgeLength, y: payloadOriginY + position.y });
    //            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y - fillEdgeLength });
    //            break;
    //        case config.CORNER_TYPES.BOTTOM_RIGHT:
    //            points.push({ x: payloadOriginX + position.x - fillEdgeLength, y: payloadOriginY + position.y });
    //            points.push({ x: payloadOriginX + position.x, y: payloadOriginY + position.y - fillEdgeLength });
    //            break;
    //    }
                        
    //    polygon.pointsArray = points;
        
        
    //    polygon.fillColour = config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR;
        


    //    polygon.setDrawingGroupName(drawingGroupName);
    //    polygon.setColour(config.DRAWING_COLOURS.PAYLOAD_LINE_COLOUR);

    //    return polygon;
    //}
}

function dashItemAndBringForward(drawingObject) {
    drawingObject.setDashed(true);
    if (drawingObject.setDashLengths) {
        drawingObject.setDashLengths(40, 40);
    } else if (drawingObject.setDashLength) {
        drawingObject.setDashLength(1/scalingFactor);
    }
    drawingObject.setLineWidth(1);
    drawingObject.setBringToFront(true);
}

function isItemObscured(chassisObject, object, objectOriginY, objectOriginX) {

    var tempBody = chassisObject.getAccessoryHolder().getBody();
    var itemObscured = false;
    if (tempBody !== null) {
        if (tempBody.getType() === config.BODY_TYPES.SERVICE || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.DROPSIDE) {
            var bodySideHeightLine = 0, bodyLeftLine = 0, bodyRightLine = 0, bodyBottomLine = 0;
            if (tempBody.getType() === config.BODY_TYPES.SERVICE) {
                bodySideHeightLine = offerManager.getRigOps().getChassisHeightFromPath(chassisObject.getChassisObjectName())- tempBody.getBottomOfBodyToTopOfChassis() + tempBody.getActualExternalHeight();
            } else {
                bodySideHeightLine = offerManager.getRigOps().getChassisHeightFromPath(chassisObject.getChassisObjectName()) + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + Math.max(tempBody.getHeadboardHeight(), tempBody.getTailboardHeight(), tempBody.getHeight(), tempBody.getRampPlatesHeight());
            }
            bodyLeftLine = offerManager.getRigOps().getOffsetToBodyRefPointForChassis(chassisObject.getChassisObjectName()) + offerManager.getRigOps().getBodyGap(chassisObject.getChassisObjectName());
            bodyRightLine = bodyLeftLine + tempBody.getActualLength();
            if (tempBody.getType() === config.BODY_TYPES.BEAVERTAIL) {
                bodyRightLine += tempBody.getRampLength() + tempBody.getRampPlatesProtrusion();
            }
            bodyBottomLine = bodySideHeightLine - tempBody.getActualExternalHeight();
            if (tempBody.getType() === config.BODY_TYPES.BEAVERTAIL) {
                //bodyBottomLine -= (tempBody.getRampBelowDeckProtrusion() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight());
                bodyBottomLine = offerManager.getRigOps().getChassisHeightFromPath(chassisObject.getChassisObjectName()) - ((tempBody.getRampBelowDeckProtrusion() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight()));
            }
            

            if (object.getLength && bodySideHeightLine >= objectOriginY + object.getHeight() && bodyBottomLine <= objectOriginY && bodyLeftLine <= objectOriginX && bodyRightLine >= objectOriginX + object.getLength()) {
                //newDrawingObject.setDashed(true);
                //if (newDrawingObject.setDashLengths) {
                //    newDrawingObject.setDashLengths(40, 40);
                //} else if (newDrawingObject.setDashLength) {
                //    newDrawingObject.setDashLength(40);
                //}
                //newDrawingObject.setLineWidth(1);
                //newDrawingObject.setBringToFront(true);
                itemObscured = true;
            }
        }
    } /*else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        if (bodySideHeightLine > objectOriginY + object.getHeight()) {
            newDrawingObject.setDashed(true);
            newDrawingObject.setDashLengths(40, 40);
            newDrawingObject.setLineWidth(1);
            newDrawingObject.setBringToFront(true);
        }
    }*/
    return itemObscured;
}

function populateDrawingObjectsForTemplateUniformPayload(body, chassisObject) {
    //var bodyOriginX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getBumperToBackOfCab() + body.getHorizontalGap(),
    //    bodyOriginY = curVehicle.getChassisHeightMax(),
    //    payloadOriginX = bodyOriginX + body.getHeadboardThickness(),
    //    payloadOriginY = bodyOriginY + body.getSubframeHeight() + body.getSubstructureHeight();


    var bodyOriginX,
        bodyOriginY,
        payloadOriginX,
        payloadOriginY;

    switch (chassisObject.getChassisObjectName()) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            bodyOriginX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(chassisObject.getChassisObjectName());
            bodyOriginY = chassisObject.getChassisHeightMax();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            bodyOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + offerManager.getRigOps().getBodyGap(chassisObject.getChassisObjectName());
            bodyOriginY = chassisObject.getChassisHeight();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            bodyOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + offerManager.getRigOps().getBodyGap(chassisObject.getChassisObjectName());
            bodyOriginY = chassisObject.getChassisHeight();
            break;
    }

    if (body.getType() === config.BODY_TYPES.SERVICE /*|| body.getType() === config.BODY_TYPES.COMPACTOR || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.OTHER*/) {
        bodyOriginY = bodyOriginY - body.getBottomOfBodyToTopOfChassis();
    } 

    payloadOriginX = bodyOriginX + body.getHeadboardThickness();
    if (body.getType() === config.BODY_TYPES.SERVICE /*|| body.getType() === config.BODY_TYPES.COMPACTOR || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.OTHER*/) {
        payloadOriginY = bodyOriginY;
    } else {
        payloadOriginY = bodyOriginY + body.getSubframeHeight() + body.getSubstructureHeight();
    }


    var actualLength = body.getActualLength();

    //if (actualLength === 0) {
    //    actualLength = curVehicle.getCabToChassisEnd() - body.getHorizontalGap();
    //}

    var payloadWidth = actualLength - body.getHeadboardThickness() - body.getTailboardThickness(),
        payloadHeight = 0;//body.getActualExternalHeight() - body.getSubframeHeight() - body.getSubstructureHeight() - body.getRoofThickness();

    var tempBodyType = body.getType();
    switch (tempBodyType) {
        case config.BODY_TYPES.FLATDECK:
        case config.BODY_TYPES.VAN:
        case config.BODY_TYPES.TAUTLINER:
        case config.BODY_TYPES.TANKER:
        case config.BODY_TYPES.PIGCARRIER:
        case config.BODY_TYPES.SHEEPCARRIER:
        case config.BODY_TYPES.CATTLECARRIER:
        case config.BODY_TYPES.COMPACTOR:
        case config.BODY_TYPES.ROLLBACK:
        case config.BODY_TYPES.HOOKLIFT:
        case config.BODY_TYPES.OTHER:
            payloadHeight = body.getActualExternalHeight() - body.getSubframeHeight() - body.getSubstructureHeight() - body.getRoofThickness();
            break;
        case config.BODY_TYPES.SERVICE:
            payloadHeight = body.getActualExternalHeight();
            break
        case config.BODY_TYPES.DROPSIDE:
        case config.BODY_TYPES.TIPPER:
            payloadHeight = body.getActualRearDimensionHeight() - body.getSubstructureHeight();
            break;
        case config.BODY_TYPES.TIMBER:
            payloadHeight = Math.max(body.getHeadboardHeight(), body.getTailboardHeight());
            break;    
    }

    addPayloadRect(chassisObject, payloadOriginX, payloadOriginY, payloadWidth, payloadHeight, tempBodyType, 0xD3D3D3);
    
}

function populateDrawingObjectsForTemplateBody(body, chassisObject) {

    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateBodySideView(body, chassisObject);
    } else {
        populateDrawingObjectsForTemplateBodyTopView(body, chassisObject);
    }
    
}

function populateDrawingObjectsForTemplateBodyTopView(body, chassisObject) {
    //var tcVals = offerManager.getRigOps().getTurningCircleValues(curViewDetail);
    //if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
    //    drawBody({}, curVehicle.getOverallLengthInclAccessories() - curVehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation(), tcVals.vehicleTurningRadiusInner, 0, 90, tcVals.vehicleTurningRadiusInner, chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.BODY);
    //} else {
    //    drawBody({}, curVehicle.getOverallLengthInclAccessories() - curVehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation(), tcVals.trailer1TurningRadiusInner, 0, 90, tcVals.trailer1TurningRadiusInner, chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.BODY);
    //}
    //drawBody(bodyCoords, centreOfRadiusX + curVehicle.getOverallLengthInclAccessories() - curVehicle.getWheelbaseTurningToRearOfBodyForTurningCircleCalculation(), centreOfRadiusY + vehicleTurningRadiusInner, angleBetweenZeroAndLegalAnchorPoint, angleBetweenWallToWallAnchorPointAndCentreOfRearAxle, vehicleTurningRadiusInner, config.OBJECT_TYPES.TURNING_CIRCLE);
    drawBodyTopView(chassisObject.getChassisObjectName() + '.' + config.ACCESSORY_TYPES.BODY);
}

function populateDrawingObjectsForTemplateBodySideView(body, chassisObject) { 
    
    var bodyLayerNum = 11, objectNum = 500000, blockName = 'BODY1', sequenceNum = 1;
    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
        objectNum = 510000;
        blockName = 'BODY2';
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
        objectNum = 520000;
        blockName = 'BODY3';
    }
    var bodyDrawingObjects = [];
    var useBodyDrawingObjectsArray = false;

    //constants
    var bodyRibSpacer = 200,
        bodyRibHeight = 50,
        compactorRibWidth = 150,
        compactorRibHeightOffset = 50,
        dropsideBoxOffset = 50,
        dropsidePillarWidth = 150,
        tankerRibWidth = 50,
        tankerRibBase = 150,
        tautlinerShackleSpacer = 720,
        tautlinerShackleHeight = 800,
        tautlinerShackleWidth = 80,
        tautlinerShackleBuckleHeight = 50,
        tautlinerShackleBuckleEdge = 10,
        tipperHingePoint = 15,
        tipperHingePointExtention = 115,
        tipperFrontThickness = body.getHeadboardThickness(),
        tipperFrontProtrusion = 0,
        tipperFrontOffset = 0,
        tipperFrontHeight = 0,
        tipperBodyRibHeight = 0,
        tipperBodyTopThickness = 0,
        tipperRearThickness = body.getTailboardThickness(),
        tipperRearHeightExtension = 0,
        tipperRearTopTailgate = 0,
        livestockPillarWidth = 55,
        rampPlateTaperHeight = 350,
        hookbeamTaperAngle = 35;


    var numberOfBodyRibs = 0,  
        compactorRibSpacer = 0,
        tankerRibSpacer = 0,
        numberOfShackles = 0,
        shackleDrawingStartX = 0,
        numberOfLivestockCages = 4;
    

    var bodyOriginX,
        bodyOriginY;

    bodyOriginX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.BODY);

    switch (chassisObject.getChassisObjectName()) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            //bodyOriginX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab() + body.getHorizontalGap();
            bodyOriginY = chassisObject.getChassisHeightMax();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            //bodyOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + body.getHorizontalGap();
            bodyOriginY = chassisObject.getChassisHeight();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            //bodyOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + body.getHorizontalGap();
            bodyOriginY = chassisObject.getChassisHeight();
            break;
    }
    if (body.getType() !== config.BODY_TYPES.SERVICE) { 
        bodyOriginY += body.getAboveBelowChassis();
        if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
            var tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
            if (tempHooklift !== null) {
                //bodyOriginX += chassisObject.getAccessoryHolder().getHooklift().getHorizontalGap() + chassisObject.getAccessoryHolder().getHooklift().getHookOffset();
                bodyOriginY += chassisObject.getAccessoryHolder().getHooklift().getVerticalGap() + chassisObject.getAccessoryHolder().getHooklift().getPlatformHeight();
            }
        }
    }
        
    
    //if (body.getType() === config.BODY_TYPES.COMPACTOR || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.OTHER) {
    //    bodyOriginY -= body.getBottomOfBodyToTopOfChassis();
    //}

    var subframeHeight = body.getSubframeHeight(),
        actualExternalHeight = body.getActualExternalHeight(),
        actualInternalHeight = body.getActualInternalHeight(),
        actualInternalLength = body.getActualInternalLength(),
        roofThickness = body.getRoofThickness(),
        actualLength = body.getActualLength(),
        actualRearDimensionHeight = body.getActualRearDimensionHeight(),
        substructureHeight = body.getSubstructureHeight(),
        headboardThickness = body.getHeadboardThickness(),
        tailboardThickness = body.getTailboardThickness(),
        headboardHeight = body.getHeadboardHeight(),
        tailboardHeight = body.getTailboardHeight(),
        hingePoint = body.getHingePoint(),
        rampLength = body.getRampLength(),
        rampAngle = body.getRampAngle(),
        rampPlatesProtrusion = body.getRampPlatesProtrusion(),
        rampPlatesHeight = body.getRampPlatesHeight(),
        subframeLength = body.getSubframeLength(),
        hookarmLength = headboardThickness,
        binLength = actualInternalLength,
        hookbeamHeight = substructureHeight,
        hookarmHeight = headboardHeight,
        binHeight = actualInternalHeight,
        bottomOfBodyToTopOfChassis = body.getBottomOfBodyToTopOfChassis(),
        leftCompartmentWidth = body.getLeftCompartmentWidth(),
        rightCompartmentWidth = body.getRightCompartmentWidth()
    ;

    //if (actualLength === 0) {
    //    actualLength = curVehicle.getCabToChassisEnd() - body.getHorizontalGap();
    //}

    var tempBodyType = body.getType();
    var i;
    switch(tempBodyType) {
    //switch ("VAN") {
        case config.BODY_TYPES.VAN:
            drawVanBody();
            break;

        case config.BODY_TYPES.DROPSIDE:
        case config.BODY_TYPES.BEAVERTAIL:
            
            useBodyDrawingObjectsArray = true;
            /*
                ' Add subframe.
        AddSubframeForBody(ActualLength)
        */
            if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                addSubframeForBody(actualLength);
                objectNum++;
                sequenceNum = 1;
            }
            /*
        ' Add substructure.
        AddSubStructureForBody(ActualLength)
        */
            addSubstructureForBody(actualLength);
            objectNum++;
            sequenceNum = 1;


            /*
        ' Draw outer bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight))

                                                        */
            bodyDrawingObjects.push(addBodyLine(headboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            /*
        ' Draw outer rear height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight + ActualRearDimensionHeight))
                                                        */
            bodyDrawingObjects.push(addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, subframeHeight + actualRearDimensionHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            /*
        ' Draw outer roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight + ActualRearDimensionHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + ActualRearDimensionHeight))
                                                        */
            bodyDrawingObjects.push(addBodyLine(actualLength - tailboardThickness, subframeHeight + actualRearDimensionHeight, headboardThickness, subframeHeight + actualRearDimensionHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            /*
        ' Draw outer front height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight + ActualRearDimensionHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            bodyDrawingObjects.push(addBodyLine(headboardThickness, subframeHeight + actualRearDimensionHeight, headboardThickness, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));

            objectNum++;
            sequenceNum = 1;
            /*
        ' Draw left middle line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                (ActualLength - dropsidePillarWidth) / 2,
                                                        _SubframeHeight + ActualRearDimensionHeight,
                                                        (ActualLength - dropsidePillarWidth) / 2,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            bodyDrawingObjects.push(addBodyLine((actualLength - dropsidePillarWidth) / 2, subframeHeight + actualRearDimensionHeight, (actualLength - dropsidePillarWidth) / 2, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            bodyDrawingObjects.push(addBodyLine((actualLength - dropsidePillarWidth) / 2, subframeHeight + substructureHeight, (actualLength + dropsidePillarWidth) / 2, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            /*
        ' Draw right middle line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                (ActualLength + dropsidePillarWidth) / 2,
                                                        _SubframeHeight + ActualRearDimensionHeight,
                                                        (ActualLength + dropsidePillarWidth) / 2,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            bodyDrawingObjects.push(addBodyLine((actualLength + dropsidePillarWidth) / 2, subframeHeight + substructureHeight, (actualLength + dropsidePillarWidth) / 2, subframeHeight + actualRearDimensionHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            bodyDrawingObjects.push(addBodyLine((actualLength + dropsidePillarWidth) / 2, subframeHeight + actualRearDimensionHeight, (actualLength - dropsidePillarWidth) / 2, subframeHeight + actualRearDimensionHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));

            objectNum++;
            sequenceNum = 1;
            //addBodyLine((actualLength + dropsidePillarWidth) / 2, subframeHeight + actualRearDimensionHeight, (actualLength + dropsidePillarWidth) / 2, subframeHeight + substructureHeight);
            /*
        If _HeadboardThickness > 0 Then
        */
            if (headboardThickness > 0) {


                // Draw headboard front line.
                bodyDrawingObjects.push(addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight + headboardHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //' Draw headboard top line.
                bodyDrawingObjects.push(addBodyLine(0, subframeHeight + substructureHeight + headboardHeight, 0 + headboardThickness, subframeHeight + substructureHeight + headboardHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //' Draw headboard rear line.
                bodyDrawingObjects.push(addBodyLine(0 + headboardThickness, subframeHeight + substructureHeight + headboardHeight, 0 + headboardThickness, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //' Draw headboard bottom line
                bodyDrawingObjects.push(addBodyLine(0 + headboardThickness, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                objectNum++;
                sequenceNum = 1;
                /*
            End If
            */
            }
            /*
        If _TailboardThickness > 0 Then
        */
            if (tailboardThickness > 0) {
                bodyDrawingObjects.push(addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, subframeHeight + actualRearDimensionHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(actualLength - tailboardThickness, subframeHeight + actualRearDimensionHeight, actualLength, subframeHeight + actualRearDimensionHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(actualLength, subframeHeight + actualRearDimensionHeight, actualLength, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(actualLength, subframeHeight + substructureHeight, actualLength - tailboardThickness, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                objectNum++;
                sequenceNum = 1;
            }

            if (actualInternalHeight > 0 ) {
                /*
            ' Draw inner boxes
            ' Box 1
            ' Draw bottom line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    _HeadboardThickness + dropsideBoxOffset,
                                                            _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                            (ActualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset,
                                                            _SubframeHeight + _SubstructureHeight + dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine(headboardThickness + dropsideBoxOffset, subframeHeight + substructureHeight + dropsideBoxOffset, (actualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset, subframeHeight + substructureHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                /*
            ' Draw rear line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    (ActualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset,
                                                            _SubframeHeight + SubstructureHeight + dropsideBoxOffset,
                                                            (ActualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine((actualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset, subframeHeight + substructureHeight + dropsideBoxOffset, (actualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                /*
            ' Draw top line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    (ActualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset,
                                                            _HeadboardThickness + dropsideBoxOffset,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine((actualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset, headboardThickness + dropsideBoxOffset, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                /*
            ' Draw front line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    _HeadboardThickness + dropsideBoxOffset,
                                                            _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                            _HeadboardThickness + dropsideBoxOffset,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine(headboardThickness + dropsideBoxOffset, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset, headboardThickness + dropsideBoxOffset, subframeHeight + substructureHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                objectNum++;
                sequenceNum = 1;
                /*
            ' Draw top rib line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    (ActualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + _SubframeHeight + dropsideBoxOffset,
                                                            _HeadboardThickness + dropsideBoxOffset,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + _SubframeHeight + dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine((actualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + subframeHeight + dropsideBoxOffset,
                    headboardThickness + dropsideBoxOffset, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + subframeHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "FALSE"));
                objectNum++;
                sequenceNum = 1;
                /*
            ' Draw bottom rib line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    (ActualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + _SubframeHeight + dropsideBoxOffset,
                                                            _HeadboardThickness + dropsideBoxOffset,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + _SubframeHeight + dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine((actualLength - dropsidePillarWidth) / 2 - dropsideBoxOffset, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + subframeHeight + dropsideBoxOffset,
                    headboardThickness + dropsideBoxOffset, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + subframeHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "FALSE"));
                objectNum++;
                sequenceNum = 1;

                /*
            ' Box 2
            ' Draw bottom line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    dropsideBoxOffset + (ActualLength + dropsidePillarWidth) / 2,
                                                            _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                            ActualLength / 2 - dropsideBoxOffset + (ActualLength / 2) - _TailboardThickness,
                                                            _SubframeHeight + _SubstructureHeight + dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine(dropsideBoxOffset + (actualLength + dropsidePillarWidth) / 2, subframeHeight + substructureHeight + dropsideBoxOffset,
                    actualLength / 2 - dropsideBoxOffset + (actualLength / 2) - tailboardThickness, subframeHeight + substructureHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                /*
            ' Draw rear line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength / 2 - dropsideBoxOffset + (ActualLength / 2) - _TailboardThickness,
                                                            _SubframeHeight + SubstructureHeight + dropsideBoxOffset,
                                                            ActualLength / 2 - dropsideBoxOffset + (ActualLength / 2) - _TailboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine(actualLength / 2 - dropsideBoxOffset + (actualLength / 2) - tailboardThickness, subframeHeight + substructureHeight + dropsideBoxOffset,
                    actualLength / 2 - dropsideBoxOffset + (actualLength / 2) - tailboardThickness, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                /*
            ' Draw top line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength / 2 - dropsideBoxOffset + (ActualLength / 2) - _TailboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset,
                                                            dropsideBoxOffset + (ActualLength + dropsidePillarWidth) / 2,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine(actualLength / 2 - dropsideBoxOffset + (actualLength / 2) - tailboardThickness, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset,
                    dropsideBoxOffset + (actualLength + dropsidePillarWidth) / 2, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                /*
            ' Draw front line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    dropsideBoxOffset + (ActualLength + dropsidePillarWidth) / 2,
                                                            _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                            dropsideBoxOffset + (ActualLength + dropsidePillarWidth) / 2,
                                                            _SubframeHeight + ActualRearDimensionHeight - dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine(dropsideBoxOffset + (actualLength + dropsidePillarWidth) / 2, subframeHeight + actualRearDimensionHeight - dropsideBoxOffset,
                    dropsideBoxOffset + (actualLength + dropsidePillarWidth) / 2, subframeHeight + substructureHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                objectNum++;
                sequenceNum = 1;
                /*
            ' Draw top rib line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength / 2 - dropsideBoxOffset + (ActualLength / 2) - _TailboardThickness,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + _SubframeHeight + dropsideBoxOffset,
                                                            dropsideBoxOffset + (ActualLength + dropsidePillarWidth) / 2,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + _SubframeHeight + dropsideBoxOffset))
                                                            */
                bodyDrawingObjects.push(addBodyLine(actualLength / 2 - dropsideBoxOffset + (actualLength / 2) - tailboardThickness, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + subframeHeight + dropsideBoxOffset,
                    dropsideBoxOffset + (actualLength + dropsidePillarWidth) / 2, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.66 + subframeHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "FALSE"));
                objectNum++;
                sequenceNum = 1;
                /*
            ' Draw bottom rib line
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength / 2 - dropsideBoxOffset + (ActualLength / 2) - _TailboardThickness,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + _SubframeHeight + dropsideBoxOffset,
                                                            dropsideBoxOffset + (ActualLength + dropsidePillarWidth) / 2,
                                                            (ActualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + _SubframeHeight + dropsideBoxOffset))
                
                */
                bodyDrawingObjects.push(addBodyLine(actualLength / 2 - dropsideBoxOffset + (actualLength / 2) - tailboardThickness, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + subframeHeight + dropsideBoxOffset,
                    dropsideBoxOffset + (actualLength + dropsidePillarWidth) / 2, (actualRearDimensionHeight - dropsideBoxOffset - dropsideBoxOffset) * 0.33 + subframeHeight + dropsideBoxOffset, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "FALSE"));
                objectNum++;
                sequenceNum = 1;

            }
            //draw beaver tail ramp
            if (body.getType() === config.BODY_TYPES.BEAVERTAIL) {
                var rampBelowDeckFloor = ((Math.tan(rampAngle / (180 / Math.PI)) * rampLength));
                var rampHingeRadius = rampPlatesProtrusion / 2;

                //// ramp part
                // top line ramp substructure
                bodyDrawingObjects.push(addBodyLine(actualLength, subframeHeight + substructureHeight, actualLength + rampLength, (subframeHeight + substructureHeight) - rampBelowDeckFloor, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // back line ramp substructure
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength, (subframeHeight + substructureHeight) - rampBelowDeckFloor, actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor + substructureHeight), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // bottom line ramp substructure
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor + substructureHeight), actualLength, subframeHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // front line ramp substructure 
                bodyDrawingObjects.push(addBodyLine(actualLength, subframeHeight, actualLength, (subframeHeight + substructureHeight), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                objectNum++;
                sequenceNum = 1;

                //top line ramp subframe
                bodyDrawingObjects.push(addBodyLine(actualLength, subframeHeight, actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor + substructureHeight), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // back line ramp subframe 
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor + substructureHeight), actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor + substructureHeight + subframeHeight), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // bottom line ramp subframe
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor + substructureHeight + subframeHeight), actualLength, 0, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // front line ramp subrfame
                bodyDrawingObjects.push(addBodyLine(actualLength, 0, actualLength, subframeHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                objectNum++;
                sequenceNum = 1;

                ////ramp plates part
                // ramp hinge
                bodyDrawingObjects.push(addBodyCircle(actualLength + rampLength + rampHingeRadius, (subframeHeight + substructureHeight) - (rampBelowDeckFloor + rampHingeRadius), rampHingeRadius, CIRCLE_TYPE.OTHER, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "FALSE"));
                objectNum++;
                sequenceNum = 1;
                // ramp plate front line
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor),
                    actualLength + rampLength, ((subframeHeight + substructureHeight) - (rampBelowDeckFloor)) + (rampPlatesHeight - rampHingeRadius), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // ramp plate point taper line
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength, ((subframeHeight + substructureHeight) - (rampBelowDeckFloor)) + (rampPlatesHeight - rampHingeRadius),
                    actualLength + rampLength + rampPlatesProtrusion, ((subframeHeight + substructureHeight) - (rampBelowDeckFloor)) + (rampPlatesHeight - (rampHingeRadius + rampPlateTaperHeight)), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                // ramp plate back line
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength + rampPlatesProtrusion, ((subframeHeight + substructureHeight) - (rampBelowDeckFloor)) + (rampPlatesHeight - (rampHingeRadius + rampPlateTaperHeight)),
                    actualLength + rampLength + rampPlatesProtrusion, (subframeHeight + substructureHeight) - (rampBelowDeckFloor), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(actualLength + rampLength + rampPlatesProtrusion, (subframeHeight + substructureHeight) - (rampBelowDeckFloor),
                    actualLength + rampLength, (subframeHeight + substructureHeight) - (rampBelowDeckFloor), false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                objectNum++;
                sequenceNum = 1;

            }
            if (bodyDrawingObjects.length > 0 && useBodyDrawingObjectsArray) {
                bodyDrawingObjects.sort(sortDrawingObjects);
                bodyDrawingObjects = groupDrawingObjects(bodyDrawingObjects);
                //bodyDrawingObjects.forEach(function (drawingObject) {
                //    drawingObject.setDrawingOrder(5000);
                //});
                addToMainDrawingObjectsArray(bodyDrawingObjects);
            }
            break;
        case config.BODY_TYPES.TIPPER:

            if (body.getSubType() === config.BODY_SUB_TYPES.WITH_CAB_PROTECTOR) {


                tipperHingePoint = 15,
                tipperHingePointExtention = 115,
                tipperFrontThickness = 300,
                tipperFrontProtrusion = 500,
                tipperFrontOffset = 100,
                tipperFrontHeight = 150,
                tipperBodyRibHeight = 75,
                tipperBodyTopThickness = 50,
                tipperRearThickness = 250,
                tipperRearHeightExtension = 200,
                tipperRearTopTailgate = 100,


                /*
            ' Draw tipper subframe.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                    _SubframeHeight,
                                                    ActualLength,
                                                    _SubframeHeight))
            */
                addBodyLine(0, subframeHeight, actualLength, subframeHeight);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            0,
                                                            0,
                                                            _SubframeHeight))
                                                            */
                addBodyLine(0, 0, 0, subframeHeight);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _HingePoint + tipperHingePointExtention,
                                                            0,
                                                            ActualLength - _HingePoint + tipperHingePoint,
                                                            _SubframeHeight))
                                                            */
                addBodyLine(actualLength - hingePoint + tipperHingePointExtention, 0, actualLength - hingePoint + tipperHingePoint, subframeHeight);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _HingePoint + tipperHingePointExtention,
                                                            0,
                                                            0,
                                                            0))
                                                            */
                addBodyLine(actualLength - hingePoint + tipperHingePointExtention, 0, 0, 0);
                /*
            ' Hinge point.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    (ActualLength - _HingePoint) - tipperHingePoint,
                                                            _SubframeHeight / 2,
                                                            (ActualLength - _HingePoint) + tipperHingePoint,
                                                            _SubframeHeight / 2))
                                                            */
                addBodyLine((actualLength - hingePoint) - tipperHingePoint, subframeHeight / 2, (actualLength - hingePoint) + tipperHingePoint, subframeHeight / 2);
                /*
            ' Hinge point.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _HingePoint,
                                                            _SubframeHeight / 2 - tipperHingePoint,
                                                            ActualLength - _HingePoint,
                                                            _SubframeHeight / 2 + tipperHingePoint))
                                                            */
                addBodyLine(actualLength - hingePoint, subframeHeight / 2 - tipperHingePoint, actualLength - hingePoint, subframeHeight / 2 + tipperHingePoint);
                /*
            ' Add substructure.
            AddSubStructureForBody(ActualLength)
            */
                addSubstructureForBody(actualLength);
                /*
            ' Add inner line for substructure
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + _SubstructureHeight * 0.66,
                                                            ActualLength,
                                                            _SubframeHeight + _SubstructureHeight * 0.66))
                                                            */
                addBodyLine(0, subframeHeight + substructureHeight * 0.66, actualLength, subframeHeight + substructureHeight * 0.66);
                /*
            ' Front body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + _SubstructureHeight,
                                                            0,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight + actualRearDimensionHeight);
                /*
            ' Headboard line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    _HeadboardThickness,
                                                            _SubframeHeight + _SubstructureHeight,
                                                            _HeadboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(headboardThickness, subframeHeight + substructureHeight, headboardThickness, subframeHeight + actualRearDimensionHeight);
                /*
            ' Top body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(0, subframeHeight + actualRearDimensionHeight, actualLength - tipperRearThickness, subframeHeight + actualRearDimensionHeight);
                /*
            ' Rear body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + _SubstructureHeight))
                                                            */
                addBodyLine(actualLength - tipperRearThickness, subframeHeight + actualRearDimensionHeight, actualLength - tipperRearThickness, subframeHeight + substructureHeight);
                /*
            ' Inner Front body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + _SubstructureHeight,
                                                            tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(tipperFrontThickness, subframeHeight + substructureHeight, tipperFrontThickness, subframeHeight + actualRearDimensionHeight);
                /*
            ' Top body thickness line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness))
                                                            */
                addBodyLine(tipperFrontThickness, subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness, actualLength - tipperRearThickness, subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness);
                /*
            ' Body rib line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 - tipperBodyRibHeight / 2,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 - tipperBodyRibHeight / 2))
                                                            */
                addBodyLine(tipperFrontThickness,
                    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 - tipperBodyRibHeight / 2,
                    actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 - tipperBodyRibHeight / 2);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 + tipperBodyRibHeight / 2,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 + tipperBodyRibHeight / 2))
                                                            */
                addBodyLine(tipperFrontThickness,
                    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 + tipperBodyRibHeight / 2,
                    actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 + tipperBodyRibHeight / 2);
                /*
            ' Front tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            0 - tipperFrontOffset,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 1.5))
                                                            */
                addBodyLine(0,
                    subframeHeight + actualRearDimensionHeight,
                    0 - tipperFrontOffset,
                    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight * 1.5);
                /*
            ' Front tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0 - tipperFrontOffset,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 1.5,
                                                            0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight))
                                                            */
                addBodyLine(0 - tipperFrontOffset,
                    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight * 1.5,
                    0 - tipperFrontProtrusion,
                    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight);
                /*
            ' Front tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight,
                                                            0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                            */
                addBodyLine(0 - tipperFrontProtrusion,
                    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight,
                    0 - tipperFrontProtrusion,
                    subframeHeight + substructureHeight + headboardHeight);
                /*
            ' Top tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                            tipperFrontThickness / 3,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 0.8))
                                                            */
                addBodyLine(0 - tipperFrontProtrusion,
                    subframeHeight + substructureHeight + headboardHeight,
                    tipperFrontThickness / 3,
                    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight * 0.8);
                /*
            ' Rear tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness / 3,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 0.8,
                                                            tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(tipperFrontThickness / 3,
                    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight * 0.8,
                    tipperFrontThickness,
                    subframeHeight + actualRearDimensionHeight);
                /*
            ' Tailgate rear front line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension))
                                                            */
                addBodyLine(actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight,
                    actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension);
                /*
            ' Tailgate rear top line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension,
                                                            ActualLength,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension))
                                                            */
                addBodyLine(actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension,
                    actualLength,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension);
                /*
            ' Tailgate rear front line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension,
                                                            ActualLength,
                                                            _SubframeHeight + _SubstructureHeight))
                                                            */
                addBodyLine(actualLength,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension,
                    actualLength,
                    subframeHeight + substructureHeight);
                /*
            ' Tailboard rear top line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                                                            ActualLength - _TailboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate))
                                                            */
                addBodyLine(actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                    actualLength - tailboardThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate);
                /*
            ' Tailgate rear line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _TailboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                                                            ActualLength - _TailboardThickness,
                                                            _SubframeHeight + _SubstructureHeight))
                */
                addBodyLine(actualLength - tailboardThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                    actualLength - tailboardThickness,
                    subframeHeight + substructureHeight);

            } else {
                /*
                ' Draw tipper subframe.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                        _SubframeHeight,
                                                        ActualLength,
                                                        _SubframeHeight))
                */
                addBodyLine(0, subframeHeight, actualLength, subframeHeight);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            0,
                                                            0,
                                                            _SubframeHeight))
                                                            */
                addBodyLine(0, 0, 0, subframeHeight);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _HingePoint + tipperHingePointExtention,
                                                            0,
                                                            ActualLength - _HingePoint + tipperHingePoint,
                                                            _SubframeHeight))
                                                            */
                addBodyLine(actualLength - hingePoint + tipperHingePointExtention, 0, actualLength - hingePoint + tipperHingePoint, subframeHeight);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _HingePoint + tipperHingePointExtention,
                                                            0,
                                                            0,
                                                            0))
                                                            */
                addBodyLine(actualLength - hingePoint + tipperHingePointExtention, 0, 0, 0);
                /*
            ' Hinge point.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    (ActualLength - _HingePoint) - tipperHingePoint,
                                                            _SubframeHeight / 2,
                                                            (ActualLength - _HingePoint) + tipperHingePoint,
                                                            _SubframeHeight / 2))
                                                            */
                addBodyLine((actualLength - hingePoint) - tipperHingePoint, subframeHeight / 2, (actualLength - hingePoint) + tipperHingePoint, subframeHeight / 2);
                /*
            ' Hinge point.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _HingePoint,
                                                            _SubframeHeight / 2 - tipperHingePoint,
                                                            ActualLength - _HingePoint,
                                                            _SubframeHeight / 2 + tipperHingePoint))
                                                            */
                addBodyLine(actualLength - hingePoint, subframeHeight / 2 - tipperHingePoint, actualLength - hingePoint, subframeHeight / 2 + tipperHingePoint);
                /*
            ' Add substructure.
            AddSubStructureForBody(ActualLength)
            */
                addSubstructureForBody(actualLength);
                /*
            ' Add inner line for substructure
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + _SubstructureHeight * 0.66,
                                                            ActualLength,
                                                            _SubframeHeight + _SubstructureHeight * 0.66))
                                                            */
                //addBodyLine(0, subframeHeight + substructureHeight * 0.66, actualLength, subframeHeight + substructureHeight * 0.66);
                /*
            ' Front body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + _SubstructureHeight,
                                                            0,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight + headboardHeight);
                /*
            ' Headboard line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    _HeadboardThickness,
                                                            _SubframeHeight + _SubstructureHeight,
                                                            _HeadboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(headboardThickness, subframeHeight + substructureHeight, headboardThickness, subframeHeight + actualRearDimensionHeight);
                /*
            ' Top body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(0 + headboardThickness, subframeHeight + actualRearDimensionHeight, actualLength - tipperRearThickness, subframeHeight + actualRearDimensionHeight);
                /*
            ' Rear body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + _SubstructureHeight))
                                                            */
                addBodyLine(actualLength - tipperRearThickness, subframeHeight + actualRearDimensionHeight, actualLength - tipperRearThickness, subframeHeight + substructureHeight);
                /*
            ' Inner Front body line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + _SubstructureHeight,
                                                            tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(tipperFrontThickness, subframeHeight + substructureHeight, tipperFrontThickness, subframeHeight + actualRearDimensionHeight);
                /*
            ' Top body thickness line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness))
                                                            */
                //addBodyLine(tipperFrontThickness, subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness, actualLength - tipperRearThickness, subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness);
                /*
            ' Body rib line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 - tipperBodyRibHeight / 2,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 - tipperBodyRibHeight / 2))
                                                            */
                //addBodyLine(tipperFrontThickness,
                //    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 - tipperBodyRibHeight / 2,
                //    actualLength - tipperRearThickness,
                //    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 - tipperBodyRibHeight / 2);
                /*
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 + tipperBodyRibHeight / 2,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight - tipperBodyTopThickness - (ActualRearDimensionHeight - tipperBodyTopThickness - _SubframeHeight - _RoofThickness) / 2 + tipperBodyRibHeight / 2))
                                                            */
                //addBodyLine(tipperFrontThickness,
                //    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 + tipperBodyRibHeight / 2,
                //    actualLength - tipperRearThickness,
                //    subframeHeight + actualRearDimensionHeight - tipperBodyTopThickness - (actualRearDimensionHeight - tipperBodyTopThickness - subframeHeight - roofThickness) / 2 + tipperBodyRibHeight / 2);
                /*
            ' Front tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            0 - tipperFrontOffset,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 1.5))
                                                            */
                //addBodyLine(0,
                //    subframeHeight + actualRearDimensionHeight,
                //    0 - tipperFrontOffset,
                //    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight * 1.5);
                /*
            ' Front tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0 - tipperFrontOffset,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 1.5,
                                                            0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight))
                                                            */
                //addBodyLine(0 - tipperFrontOffset,
                //    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight * 1.5,
                //    0 - tipperFrontProtrusion,
                //    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight);
                /*
            ' Front tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight,
                                                            0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                            */
                //addBodyLine(0 - tipperFrontProtrusion,
                //    subframeHeight + substructureHeight + headboardHeight - tipperFrontHeight,
                //    0 - tipperFrontProtrusion,
                //    subframeHeight + substructureHeight + headboardHeight);
                /*
            ' Top tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    0 - tipperFrontProtrution,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                            tipperFrontThickness / 3,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 0.8))
                                                            */
                addBodyLine(0 - tipperFrontProtrusion,
                    subframeHeight + substructureHeight + headboardHeight,
                    tipperFrontThickness,
                    subframeHeight + substructureHeight + headboardHeight);
                /*
            ' Rear tipper line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    tipperFrontThickness / 3,
                                                            _SubframeHeight + _SubstructureHeight + _HeadboardHeight - tipperFrontHeight * 0.8,
                                                            tipperFrontThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight))
                                                            */
                addBodyLine(tipperFrontThickness,
                    subframeHeight + substructureHeight + headboardHeight,
                    tipperFrontThickness,
                    subframeHeight + actualRearDimensionHeight);
                /*
            ' Tailgate rear front line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight,
                                                            ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension))
                                                            */
                addBodyLine(actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight,
                    actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension);
                /*
            ' Tailgate rear top line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension,
                                                            ActualLength,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension))
                                                            */
                addBodyLine(actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension,
                    actualLength,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension);
                /*
            ' Tailgate rear front line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension,
                                                            ActualLength,
                                                            _SubframeHeight + _SubstructureHeight))
                                                            */
                addBodyLine(actualLength,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension,
                    actualLength,
                    subframeHeight + substructureHeight);
                /*
            ' Tailboard rear top line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - tipperRearThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                                                            ActualLength - _TailboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate))
                                                            */
                addBodyLine(actualLength - tipperRearThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                    actualLength - tailboardThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate);
                /*
            ' Tailgate rear line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength - _TailboardThickness,
                                                            _SubframeHeight + ActualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                                                            ActualLength - _TailboardThickness,
                                                            _SubframeHeight + _SubstructureHeight))
                */
                addBodyLine(actualLength - tailboardThickness,
                    subframeHeight + actualRearDimensionHeight + tipperRearHeightExtension - tipperRearTopTailgate,
                    actualLength - tailboardThickness,
                    subframeHeight + substructureHeight);
            }

            

            break;
        case config.BODY_TYPES.TAUTLINER:

            /*
            numberOfShackles = Math.Floor(ActualLength / (tautlinerShackleSpacer + tautlinerShackleWidth))
            */
            numberOfShackles = Math.floor(actualLength / (tautlinerShackleSpacer + tautlinerShackleWidth))
            /*
        ' Add subframe.
        AddSubframeForBody(ActualLength)
        */
            addSubframeForBody(actualLength);
            /*
        ' Add substructure.
        AddSubStructureForBody(ActualLength)
        */
            addSubstructureForBody(actualLength);
            /*
        ' Draw outer bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        _SubframeHeight,
                                                        ActualLength,
                                                        _SubframeHeight))
                                                        */
            addBodyLine(0, subframeHeight, actualLength, subframeHeight);
            /*
        ' Draw outer rear height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength, subframeHeight + substructureHeight, actualLength, actualExternalHeight);
            /*
        ' Draw outer roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        ActualExternalHeight,
                                                        0,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength, actualExternalHeight, 0, actualExternalHeight);
            /*
        ' Draw outer front height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        ActualExternalHeight,
                                                        0,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            addBodyLine(0, actualExternalHeight, 0, subframeHeight + substructureHeight);
            /*
        ' Draw line across top.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        ActualExternalHeight - _RoofThickness,
                                                        _HeadboardThickness,
                                                        ActualExternalHeight - _RoofThickness))
                                                        */
            addBodyLine(actualLength - tailboardThickness, actualExternalHeight - roofThickness, headboardThickness, actualExternalHeight - roofThickness);
            /*
        ' Draw headboard line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        _HeadboardThickness,
                                                        ActualExternalHeight - _RoofThickness))
                                                        */
            addBodyLine(headboardThickness, subframeHeight + substructureHeight, headboardThickness, actualExternalHeight - roofThickness);
            /*
        ' Draw tailboard line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength - _TailboardThickness,
                                                        ActualExternalHeight - _RoofThickness))
                                                        */
            addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, actualExternalHeight - roofThickness);
            /*
        ' Draw shackles
        */
            /*
        ' Draw shackle top.
        If tautlinerShackleHeight < (ActualExternalHeight - _SubframeHeight + _SubstructureHeight) Then
        */
            if (tautlinerShackleHeight < (actualExternalHeight - subframeHeight + substructureHeight)) {
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    ActualLength,
                                                                _SubframeHeight + tautlinerShackleHeight,
                                                                0,
                                                                _SubframeHeight + tautlinerShackleHeight))
                                                                */
                addBodyLine(actualLength, subframeHeight + tautlinerShackleHeight, 0, subframeHeight + tautlinerShackleHeight);
                /*
                For i As Integer = 1 To numberOfShackles
                */
                for (i = 1; i <= numberOfShackles; i++) {
                    /*
                        shackleDrawingStartX = tautlinerShackleSpacer * i + tautlinerShackleWidth * (i - 1)
                        */
                    shackleDrawingStartX = tautlinerShackleSpacer * i + tautlinerShackleWidth * (i - 1)
                    /*
                        ' left lower shackle.
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX,
                                                                        _SubframeHeight,
                                                                        shackleDrawingStartX,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2))
                                                                        */
                    addBodyLine(shackleDrawingStartX, subframeHeight, shackleDrawingStartX, subframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2);
                    /*
                        ' right lower shackle.
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX + tautlinerShackleWidth,
                                                                        _SubframeHeight,
                                                                        shackleDrawingStartX + tautlinerShackleWidth,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2))
                                                                        */
                    addBodyLine(shackleDrawingStartX + tautlinerShackleWidth,
                        subframeHeight,
                        shackleDrawingStartX + tautlinerShackleWidth,
                        subframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2);
                    /*
                        ' left upper shackle.
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2,
                                                                        shackleDrawingStartX,
                                                                        _SubframeHeight + tautlinerShackleHeight))
                                                                        */
                    addBodyLine(shackleDrawingStartX + tautlinerShackleWidth,
                        subframeHeight,
                        shackleDrawingStartX + tautlinerShackleWidth,
                        subframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2);
                    /*
                        ' right upper shackle.
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX + tautlinerShackleWidth,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2,
                                                                        shackleDrawingStartX + tautlinerShackleWidth,
                                                                        _SubframeHeight + tautlinerShackleHeight))
                                                                        */
                    addBodyLine(shackleDrawingStartX + tautlinerShackleWidth,
                        subframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2,
                        shackleDrawingStartX + tautlinerShackleWidth,
                        subframeHeight + tautlinerShackleHeight);
                    /*
                        ' upper buckle
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX - tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2,
                                                                        shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2))
                                                                        */
                    addBodyLine(shackleDrawingStartX - tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2,
                        shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2);
                    /*
                        ' lower buckle.
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX - tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2,
                                                                        shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2))
                                                                        */
                    addBodyLine(shackleDrawingStartX - tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2,
                        shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2);
                    /*
                        ' left buckle
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX - tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2,
                                                                        shackleDrawingStartX - tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2))
                                                                        */
                    addBodyLine(shackleDrawingStartX - tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2,
                        shackleDrawingStartX - tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2);
                    /*
                        ' right buckle
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2,
                                                                        shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2))
                                                                        */
                    addBodyLine(shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 - tautlinerShackleBuckleHeight / 2,
                        shackleDrawingStartX + tautlinerShackleWidth + tautlinerShackleBuckleEdge,
                        subframeHeight + tautlinerShackleHeight * 0.66 + tautlinerShackleBuckleHeight / 2);
                    /*
                        ' end of excess belt.
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        shackleDrawingStartX,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.33,
                                                                        shackleDrawingStartX + tautlinerShackleWidth,
                                                                        _SubframeHeight + tautlinerShackleHeight * 0.33))
                                                                        */
                    addBodyLine(shackleDrawingStartX,
                        subframeHeight + tautlinerShackleHeight * 0.33,
                        shackleDrawingStartX + tautlinerShackleWidth,
                        subframeHeight + tautlinerShackleHeight * 0.33);
                    /*
                    Next
                    */
                }
                /*
            End If
                */
            }
            
            break;
        case config.BODY_TYPES.FLATDECK:

            /*
                ' Add subframe.
        AddSubframeForBody(ActualLength)
        */
            addSubframeForBody(actualLength);
            /*
        ' Add substructure.
        AddSubStructureForBody(ActualLength)
        */
            addSubstructureForBody(actualLength);
            /*
        If _HeadboardThickness > 0 Then
        */
            if (headboardThickness > 0) {
                /*
                ' Draw headboard front line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        0,
                                                                _SubframeHeight + _SubstructureHeight,
                                                                0,
                                                                _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                                */
                addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight + headboardHeight);
                /*
                ' Draw headboard top line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        0,
                                                                _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                                _HeadboardThickness,
                                                                _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                                */
                addBodyLine(0, subframeHeight + substructureHeight + headboardHeight, headboardThickness, subframeHeight + substructureHeight + headboardHeight);
                /*
                ' Draw headboard rear line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        _HeadboardThickness,
                                                                _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                                _HeadboardThickness,
                                                                _SubframeHeight + _SubstructureHeight))
                                                                */
                addBodyLine(headboardThickness, subframeHeight + substructureHeight + headboardHeight, headboardThickness, subframeHeight + substructureHeight);
                /*
            End If
            */
            }
            /*
        If _TailboardThickness > 0 Then
        */
            if (tailboardThickness > 0) {
                /*
                ' Draw tailboard outer height line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        ActualLength - _TailboardThickness,
                                                                _SubframeHeight + _SubstructureHeight,
                                                                ActualLength - _TailboardThickness,
                                                                _SubframeHeight + _SubstructureHeight + _TailboardHeight))
                                                                */
                addBodyLine(actualLength - tailboardThickness,
                    subframeHeight + substructureHeight,
                    actualLength - tailboardThickness,
                    subframeHeight + substructureHeight + tailboardHeight);
                /*
                ' Draw tailboard outer top line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        ActualLength - _TailboardThickness,
                                                                _SubframeHeight + _SubstructureHeight + _TailboardHeight,
                                                                ActualLength,
                                                                _SubframeHeight + _SubstructureHeight + _TailboardHeight))
                                                                */
                addBodyLine(actualLength - tailboardThickness,
                    subframeHeight + substructureHeight + tailboardHeight,
                    actualLength,
                    subframeHeight + substructureHeight + tailboardHeight);
                /*
                ' Draw tailboard outer rear line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        ActualLength,
                                                                _SubframeHeight + _SubstructureHeight,
                                                                ActualLength,
                                                                _SubframeHeight + _SubstructureHeight + _TailboardHeight))
                                                                */
                addBodyLine(actualLength,
                    subframeHeight + substructureHeight,
                    actualLength,
                    subframeHeight + substructureHeight + tailboardHeight);
                /*
            End If
                */
            }
            break;
        case config.BODY_TYPES.TANKER:

            /*
            tankerRibSpacer = ((ActualLength - _HeadboardThickness - TailboardThickness) - (tankerRibWidth * 6)) / 7
            */
            tankerRibSpacer = ((actualLength - headboardThickness - tailboardThickness) - (tankerRibWidth * 6)) / 7;
            /*
        ' Add subframe (different from normal subframe).
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        0,
                                                        ActualLength - _TailboardThickness,
                                                        0))
                                                        */
            addBodyLine(headboardThickness, 0, actualLength - tailboardThickness, 0);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        0,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight))
                                                        */
            addBodyLine(actualLength - tailboardThickness, 0, actualLength - tailboardThickness, subframeHeight);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight))
                                                        */
            addBodyLine(actualLength - tailboardThickness, subframeHeight, headboardThickness, subframeHeight);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight,
                                                        _HeadboardThickness,
                                                        0))
                                                        */
            addBodyLine(headboardThickness, subframeHeight, headboardThickness, 0);
            /*
        ' Draw substructure line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, headboardThickness, subframeHeight + substructureHeight);
            /*
        ' Draw outer bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight))
                                                        */
            addBodyLine(headboardThickness, subframeHeight, actualLength - tailboardThickness, subframeHeight);
            /*
        ' Draw outer rear height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight)))
                                                        */
            //addBodyLine(actualLength - tailboardThickness, subframeHeight, actualLength - tailboardThickness, subframeHeight + (actualExternalHeight - subframeHeight));
            addBodyLine(actualLength - tailboardThickness, subframeHeight, actualLength - tailboardThickness, subframeHeight + substructureHeight);
            /*
        ' Draw outer roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight),
                                                        _HeadboardThickness,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight)))
                                                        */
            addBodyLine(actualLength - tailboardThickness,
                subframeHeight + (actualExternalHeight - subframeHeight),
                headboardThickness,
                subframeHeight + (actualExternalHeight - subframeHeight));
            /*
        ' Draw outer front height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight),
                                                        _HeadboardThickness,
                                                        _SubframeHeight))
                                                        */
            //addBodyLine(headboardThickness,
            //    subframeHeight + (actualExternalHeight - subframeHeight),
            //    headboardThickness,
            //    subframeHeight);
            addBodyLine(headboardThickness,
                subframeHeight + substructureHeight,
                headboardThickness,
                subframeHeight);
            /*
        ' Draw front convex.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        ActualExternalHeight,
                                                        _HeadboardThickness - (_HeadboardThickness * 0.7),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.8))
                                                        */
            addBodyLine(headboardThickness,
                actualExternalHeight,
                headboardThickness - (headboardThickness *0.7),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.8);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness - (_HeadboardThickness * 0.7),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.8,
                                                        0,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.6))
                                                        */
            addBodyLine(headboardThickness - (headboardThickness * 0.7),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.8,
                0,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.6);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.6,
                                                        0,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.4))
                                                        */
            addBodyLine(0,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.6,
                0,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.4);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.4,
                                                        _HeadboardThickness - (_HeadboardThickness * 0.7),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.2))
                                                        */
            addBodyLine(0,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.4,
                headboardThickness - (headboardThickness * 0.7),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.2);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness - (_HeadboardThickness * 0.7),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.2,
                                                        _HeadboardThickness,
                                                        _SubframeHeight))
                                                        */
            addBodyLine(headboardThickness - (headboardThickness * 0.7),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.2,
                headboardThickness,
                subframeHeight);
            /*
        ' Draw rear convex.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        ActualExternalHeight,
                                                        ActualLength - _TailboardThickness + (_TailboardThickness * 0.7),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.8))
                                                        */
            addBodyLine(actualLength - tailboardThickness,
                actualExternalHeight,
                actualLength - tailboardThickness + (tailboardThickness * 0.7),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.8);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness + (_TailboardThickness * 0.7),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.8,
                                                        ActualLength,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.6))
                                                        */
            addBodyLine(actualLength - tailboardThickness + (tailboardThickness * 0.7),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.8,
                actualLength,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.6);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.6,
                                                        ActualLength,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.4))
                                                        */
            addBodyLine(actualLength,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.6,
                actualLength,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.4);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.4,
                                                        ActualLength - (_TailboardThickness * 0.3),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.2))
                                                        */
            addBodyLine(actualLength,
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.4,
                actualLength - (tailboardThickness * 0.3),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.2);
            /*
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - (_TailboardThickness * 0.3),
                                                        _SubframeHeight + (ActualExternalHeight - _SubframeHeight) * 0.2,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight))
                                                        */
            addBodyLine(actualLength - (tailboardThickness * 0.3),
                subframeHeight + (actualExternalHeight - subframeHeight) * 0.2,
                actualLength - tailboardThickness,
                subframeHeight);
        //    /*
        //' Draw ribs
        //For i As Integer = 1 To 6
        //*/
        //    for (var i = 1; i <= 6; i++) {
        //        /*
        //        'left
        //        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
        //                                           _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1),
        //                                                      ActualExternalHeight,
        //                                                      _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1),
        //                                                      _SubframeHeight + _SubstructureHeight))
        //                                                      */
        //        addBodyLine(headboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1),
        //            actualExternalHeight,
        //            headboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1),
        //            subframeHeight + substructureHeight);
        //        /*
        //        'right
        //        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
        //                                           _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * i,
        //                                                      ActualExternalHeight,
        //                                                      _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * i,
        //                                                      _SubframeHeight + _SubstructureHeight))
        //                                                      */
        //        addBodyLine(headboardThickness + tankerRibSpacer * i + tankerRibWidth * i,
        //            actualExternalHeight,
        //            headboardThickness + tankerRibSpacer * i + tankerRibWidth * i,
        //            subframeHeight + substructureHeight);
        //        /*
        //        'bottom left
        //        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
        //                                           _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1),
        //                                                      _SubframeHeight + _SubstructureHeight + tankerRibBase,
        //                                                      _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1) - tankerRibBase,
        //                                                      _SubframeHeight + _SubstructureHeight))
        //                                                      */
        //        addBodyLine(headboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1),
        //            subframeHeight + substructureHeight + tankerRibBase,
        //            headboardThickness + tankerRibSpacer * i + tankerRibWidth * (i - 1) - tankerRibBase,
        //            subframeHeight + substructureHeight);
        //        /*
        //        'bottom right
        //        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
        //                                           _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * i,
        //                                                      _SubframeHeight + _SubstructureHeight + tankerRibBase,
        //                                                      _HeadboardThickness + tankerRibSpacer * i + tankerRibWidth * i + tankerRibBase,
        //                                                      _SubframeHeight + _SubstructureHeight))
        //                                                      */
        //        addBodyLine(headboardThickness + tankerRibSpacer * i + tankerRibWidth * i,
        //            subframeHeight + substructureHeight + tankerRibBase,
        //            headboardThickness + tankerRibSpacer * i + tankerRibWidth * i + tankerRibBase,
        //            subframeHeight + substructureHeight);
        //        /*
        //    Next
        //        */
        //    }

            break;

        case config.BODY_TYPES.PIGCARRIER:
        case config.BODY_TYPES.SHEEPCARRIER:
        case config.BODY_TYPES.CATTLECARRIER:


            /*
                If ActualLength > 6000 Then
            numberOfLivestockCages = 6
        ElseIf ActualLength > 4500 Then
            numberOfLivestockCages = 4
        Else
            numberOfLivestockCages = 3
        End If
        */
            if (actualLength > 6000) {
                numberOfLivestockCages = 6;
            } else if (actualLength > 4500) {
                numberOfLivestockCages = 4;
            } else {
                numberOfLivestockCages = 3;
            }
            /*
        ' Add subframe.
        AddSubframeForBody(ActualLength)
        */
            addSubframeForBody(actualLength);
            /*
        ' Add substructure.
        AddSubStructureForBody(ActualLength)
        */
            addSubstructureForBody(actualLength);
            /*
        ' Draw outer bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            addBodyLine(headboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, subframeHeight + substructureHeight);
            /*
        ' Draw outer rear height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength - _TailboardThickness,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, actualExternalHeight);
            /*
        ' Draw outer roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        ActualExternalHeight,
                                                        _HeadboardThickness,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength - tailboardThickness, actualExternalHeight, headboardThickness, actualExternalHeight);
            /*
        ' Draw outer front height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        ActualExternalHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            addBodyLine(headboardThickness, actualExternalHeight, headboardThickness, subframeHeight + substructureHeight);
            /*
        ' Draw headboard front line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        0,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(0, subframeHeight + substructureHeight, 0, actualExternalHeight);
            /*
        ' Draw headboard top line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        ActualExternalHeight,
                                                        0 + _HeadboardThickness,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(0, actualExternalHeight, 0 + headboardThickness, actualExternalHeight);
            /*
        ' Draw headboard rear line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        ActualExternalHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            addBodyLine(headboardThickness, actualExternalHeight, headboardThickness, subframeHeight + substructureHeight);
            /*
        ' Draw tailboard outer height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength - _TailboardThickness,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, actualExternalHeight);
            /*
        ' Draw tailboard outer top line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength - _TailboardThickness,
                                                        ActualExternalHeight,
                                                        ActualLength,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength - tailboardThickness, actualExternalHeight, actualLength, actualExternalHeight);
            /*
        ' Draw tailboard outer rear line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength, subframeHeight + substructureHeight, actualLength, actualExternalHeight);
            /*
        For i As Integer = 1 To numberOfLivestockCages - 1
        */
            
            for (i = 1; i < numberOfLivestockCages; i++) {
                /*
                ' Draw left upright line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - livestockPillarWidth,
                                                                ActualExternalHeight,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - livestockPillarWidth,
                                                                _SubframeHeight + _SubstructureHeight))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * i - livestockPillarWidth,
                    actualExternalHeight,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * i - livestockPillarWidth,
                    subframeHeight + substructureHeight);
                /*
                ' Draw right upright line.
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i + livestockPillarWidth,
                                                                ActualExternalHeight,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i + livestockPillarWidth,
                                                                _SubframeHeight + _SubstructureHeight))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * i + livestockPillarWidth,
                    actualExternalHeight,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * i + livestockPillarWidth,
                    subframeHeight + substructureHeight);
                /*
            Next
            */
            }
            /*
        For i As Integer = 1 To numberOfLivestockCages
        */
            for (i = 1; i <= numberOfLivestockCages; i++) {
                /*
                Dim firstPillarWidth As Integer
                Dim lastPillarWidth As Integer
                */
                var firstPillarWidth, lastPillarWidth;
                /*
                If i = 1 Then
                    firstPillarWidth = 0
                Else
                    firstPillarWidth = livestockPillarWidth
                End If
                If i = numberOfLivestockCages Then
                    lastPillarWidth = 0
                Else
                    lastPillarWidth = livestockPillarWidth
                End If
                */
                if (i === 1) {
                    firstPillarWidth = 0;
                } else {
                    firstPillarWidth = livestockPillarWidth;
                }

                if (i === numberOfLivestockCages) {
                    lastPillarWidth = 0;
                } else {
                    lastPillarWidth = livestockPillarWidth;
                }
                /*
                ' Draw box top line
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                    subframeHeight + substructureHeight + actualInternalHeight * 0.2,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                    subframeHeight + substructureHeight + actualInternalHeight * 0.2);
                /*
                ' Draw bottom line
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                                                                _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                                                                _SubframeHeight + _SubstructureHeight + dropsideBoxOffset))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                    subframeHeight + substructureHeight + dropsideBoxOffset,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                    subframeHeight + substructureHeight + dropsideBoxOffset);
                /*
                ' Draw rear line
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                                                                _SubframeHeight + SubstructureHeight + dropsideBoxOffset,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                    subframeHeight + substructureHeight + dropsideBoxOffset,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                    subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset);
                /*
                ' Draw top line
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                    subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                    subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset);
                /*
                ' Draw front line
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                                                                _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                    subframeHeight + substructureHeight + dropsideBoxOffset,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                    subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset);
                /*
                ' Draw top rib line
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                                                                (_SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - _SubframeHeight - _SubstructureHeight) * 0.66 + _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                                                                (_SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - _SubframeHeight - _SubstructureHeight) * 0.66 + _SubframeHeight + _SubstructureHeight + dropsideBoxOffset))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                    (subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - subframeHeight - substructureHeight) * 0.66 + subframeHeight + substructureHeight + dropsideBoxOffset,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                    (subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - subframeHeight - substructureHeight) * 0.66 + subframeHeight + substructureHeight + dropsideBoxOffset);
                /*
                ' Draw bottom rib line
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                                                                (_SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - _SubframeHeight - _SubstructureHeight) * 0.33 + _SubframeHeight + _SubstructureHeight + dropsideBoxOffset,
                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                                                                (_SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - _SubframeHeight - _SubstructureHeight) * 0.33 + _SubframeHeight + _SubstructureHeight + dropsideBoxOffset))
                                                                */
                addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth - dropsideBoxOffset,
                    (subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - subframeHeight - substructureHeight) * 0.33 + subframeHeight + substructureHeight + dropsideBoxOffset,
                    headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + dropsideBoxOffset,
                    (subframeHeight + substructureHeight + actualInternalHeight * 0.2 - dropsideBoxOffset - dropsideBoxOffset - subframeHeight - substructureHeight) * 0.33 + subframeHeight + substructureHeight + dropsideBoxOffset);


                var j;
                /*
                If _BodyType = BodyTemplateTypes.CattleCarrier Then
                */
                if (body.getType() === config.BODY_TYPES.CATTLECARRIER) {
                    /*
                        For j As Integer = 1 To Math.Floor(ActualInternalHeight * 0.8 / (150 + livestockPillarWidth))
                        */
                    
                    for (j = 1; j <= Math.floor(actualInternalHeight * 0.8 / (150 + livestockPillarWidth)) ; j++) {
                        /*
                                ' Draw cross member bottom line
                                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                            HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + 150 * j + livestockPillarWidth * (j - 1),
                                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + 150 * j + livestockPillarWidth * (j - 1)))
                                                                                */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + 150 * j + livestockPillarWidth * (j - 1),
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + 150 * j + livestockPillarWidth * (j - 1));
                        /*
                                ' Draw cross member top line
                                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                            HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + (150 + livestockPillarWidth) * j,
                                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + (150 + livestockPillarWidth) * j))
                                                                                */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + (150 + livestockPillarWidth) * j,
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + (150 + livestockPillarWidth) * j);
                        /*
                            Next
                            */
                    }
                    /*
                    
                Else ' Make double decker for pig and sheep carriers
                */
                } else {
                    /*
                    ' Draw double top decker line
                    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                    _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.7,
                                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                    _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.7))
                                                                    */
                    addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.7,
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.7);
                    /*
                        For j As Integer = 1 To Math.Floor(ActualInternalHeight * 0.2 / 50)
                        */
                    for (j = 1; j < Math.floor(actualInternalHeight * 0.2 / 50) ; j++) {
                        /*
                        ' Draw double middle decker line
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                        _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.5 + 50 * j,
                                                                        HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                        _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.5 + 50 * j))
                                                                        */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.5 + 50 * j,
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.5 + 50 *j);
                        /*
                            Next
                            */
                    }
                    /*
                        ' Draw double bottom decker line
                        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                        _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.5,
                                                                        HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                        _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.5))
                                                                        */
                    addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                        subframeHeight + substructureHeight + actualInternalHeight * 0.5,
                        headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                        subframeHeight + substructureHeight + actualInternalHeight * 0.5);
                    /*
                        ' Draw double decker vertical line
                        For j As Integer = 1 To Math.Floor(ActualInternalLength / numberOfLivestockCages / 50) - 1
                        */
                    for (j = 1; j < Math.floor(actualInternalLength / numberOfLivestockCages / 50) ; j++) {
                        /*
                                ' Draw double decker line
                                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                            HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + 50 * (j - 1),
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.5,
                                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + 50 * (j - 1),
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.7))
                                                                                */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + 50 * (j - 1),
                            subframeHeight + substructureHeight + actualInternalHeight * 0.5,
                            headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth + 50 * (j - 1),
                            subframeHeight + substructureHeight + actualInternalHeight * 0.7);
                        /*
                            Next
                            */
                    }
                    /*
                        ' Fill in cross members for double decker
                        For j As Integer = 1 To Math.Floor(ActualInternalHeight * 0.3 / (100 + livestockPillarWidth))
                        */
                    for (j = 1; j <= Math.floor(actualInternalHeight * 0.3 / (100 + livestockPillarWidth)) ; j++) {
                        /*
                            ' Bottom deck
                            ' Draw cross member bottom line
                            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                            _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + 100 * j + livestockPillarWidth * (j - 1),
                                                                            HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                            _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + 100 * j + livestockPillarWidth * (j - 1)))
                                                                            */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + 100 * j + livestockPillarWidth * (j - 1),
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + 100 * j + livestockPillarWidth * (j - 1));
                        /*
                                ' Draw cross member top line
                                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                            HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + (100 + livestockPillarWidth) * j,
                                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.2 + (100 + livestockPillarWidth) * j))
                                                                                */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + (100 + livestockPillarWidth) * j,
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.2 + (100 + livestockPillarWidth) * j);
                        /*
                                ' Top deck
                                ' Draw cross member bottom line
                                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                            HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.7 + 100 * j + livestockPillarWidth * (j - 1),
                                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.7 + 100 * j + livestockPillarWidth * (j - 1)))
                                                                                */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.7 + 100 * j + livestockPillarWidth * (j - 1),
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.7 + 100 * j + livestockPillarWidth * (j - 1));
                        /*
                                ' Draw cross member top line
                                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                            HeadboardThickness + ActualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.7 + (100 + livestockPillarWidth) * j,
                                                                                HeadboardThickness + ActualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                                                                                _SubframeHeight + SubstructureHeight + ActualInternalHeight * 0.7 + (100 + livestockPillarWidth) * j))
                                                                                */
                        addBodyLine(headboardThickness + actualInternalLength / numberOfLivestockCages * (i - 1) + firstPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.7 + (100 + livestockPillarWidth) * j,
                            headboardThickness + actualInternalLength / numberOfLivestockCages * i - lastPillarWidth,
                            subframeHeight + substructureHeight + actualInternalHeight * 0.7 + (100 + livestockPillarWidth) * j);
                        /*
                            Next
                            */
                    }
                    /*
                    End If
                    */
                }
                /*
            Next
                */
            }
            break;
        case config.BODY_TYPES.COMPACTOR:


            /*
            compactorRibSpacer = ((ActualLength * 0.85) - (compactorRibWidth * 4)) / 5
            */
            compactorRibSpacer = ((actualLength * 0.85) - (compactorRibWidth * 4)) / 5;
            /*
        ' Add subframe.
        AddSubframeForBody(ActualLength * 0.85)
        */
            addSubframeForBody(actualLength * 0.85);
            /*
        ' Add substructure
        AddSubStructureForBody(ActualLength * 0.85)
        */
            addSubstructureForBody(actualLength * 0.85)
            /*
        ' Draw outer front height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                ActualExternalHeight - compactorRibHeightOffset,
                                                0,
                                                _SubframeHeight + _SubstructureHeight))
                                                */
            addBodyLine(0, actualExternalHeight - compactorRibHeightOffset, 0, subframeHeight + substructureHeight);
            /*
        ' Draw outer bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                _SubframeHeight + _SubstructureHeight,
                                                ActualLength * 0.85,
                                                _SubframeHeight + _SubstructureHeight))
                                                */
            addBodyLine(0, subframeHeight + substructureHeight, actualLength * 0.85, subframeHeight + substructureHeight);
            /*
        ' Draw outer bin bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.85,
                                                        compactorRibWidth * -3,
                                                        ActualLength - compactorRibWidth,
                                                        compactorRibWidth * -3))
                                                        */
            addBodyLine(actualLength * 0.85, compactorRibWidth * -3, actualLength - compactorRibWidth, compactorRibWidth * -3);
            /*
        ' Draw outer rear height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        compactorRibWidth * -2,
                                                        ActualLength,
                                                        _SubframeHeight + _SubstructureHeight + ((ActualExternalHeight - _SubframeHeight - _SubstructureHeight - _RoofThickness) / 2)))
                                                        */
            addBodyLine(actualLength, compactorRibWidth * -2, actualLength, subframeHeight + substructureHeight + ((actualExternalHeight - subframeHeight - substructureHeight - roofThickness) / 2));
            /*
        ' Draw outer tappered roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        _SubframeHeight + _SubstructureHeight + ((ActualExternalHeight - _SubframeHeight - _SubstructureHeight - _RoofThickness) / 2),
                                                        ActualLength * 0.85 + compactorRibWidth,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength, subframeHeight + substructureHeight + ((actualExternalHeight - subframeHeight - substructureHeight - roofThickness) / 2), actualLength * 0.85 + compactorRibWidth, actualExternalHeight);
            /*
        ' Draw outer roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.85,
                                                        ActualExternalHeight,
                                                        ActualLength * 0.85 + compactorRibWidth,
                                                        ActualExternalHeight))
                                                        */
            addBodyLine(actualLength * 0.85, actualExternalHeight, actualLength * 0.85 + compactorRibWidth, actualExternalHeight);
            /*
        ' Draw front bin line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.85 + compactorRibWidth,
                                                        ActualExternalHeight,
                                                        ActualLength * 0.85 + compactorRibWidth,
                                                        compactorRibWidth * -3))
                                                        */
            addBodyLine(actualLength * 0.85 + compactorRibWidth, actualExternalHeight, actualLength * 0.85 + compactorRibWidth, compactorRibWidth * -3);
            /*
        ' Draw top middle bin line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.85 + compactorRibWidth,
                                                        ActualExternalHeight,
                                                        ActualLength,
                                                        compactorRibWidth * -2))
                                                        */
            addBodyLine(actualLength * 0.85 + compactorRibWidth, actualExternalHeight, actualLength, compactorRibWidth * -2);
            /*
        ' Draw bottom middle bin line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.85 + compactorRibWidth,
                                                        ActualExternalHeight - (compactorRibWidth * 2),
                                                        ActualLength - compactorRibWidth,
                                                        compactorRibWidth * -3))
                                                        */
            addBodyLine(actualLength * 0.85 + compactorRibWidth, actualExternalHeight - (compactorRibWidth * 2), actualLength - compactorRibWidth, compactorRibWidth * -3);
            /*
        ' Draw bottom corner line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength,
                                                        compactorRibWidth * -2,
                                                        ActualLength - compactorRibWidth,
                                                        compactorRibWidth * -3))
                                                        */
            addBodyLine(actualLength, compactorRibWidth * -2, actualLength - compactorRibWidth, compactorRibWidth * -3);
            /*
        ' Draw outer mid rear height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.85,
                                                        ActualExternalHeight,
                                                        ActualLength * 0.85,
                                                        compactorRibWidth * -3))
                                                        */
            addBodyLine(actualLength * 0.85, actualExternalHeight, actualLength * 0.85, compactorRibWidth * -3);
            /*
        ' Draw outer roof lines
        For i As Integer = 1 To 5
        */
            
            for (i = 1; i <= 5; i++) {
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        compactorRibSpacer * (i - 1) + compactorRibWidth * (i - 1),
                                                                ActualExternalHeight - compactorRibHeightOffset,
                                                                compactorRibSpacer * i + compactorRibWidth * (i - 1),
                                                                ActualExternalHeight - compactorRibHeightOffset))
                                                                */
                addBodyLine(compactorRibSpacer * (i - 1) + compactorRibWidth * (i - 1),
                    actualExternalHeight - compactorRibHeightOffset,
                    compactorRibSpacer * i + compactorRibWidth * (i - 1),
                    actualExternalHeight - compactorRibHeightOffset);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        compactorRibSpacer * (i - 1) + compactorRibWidth * (i - 1),
                                                                (ActualExternalHeight - _SubframeHeight - _SubstructureHeight) / 2 + compactorRibHeightOffset + _SubframeHeight + _SubstructureHeight,
                                                                compactorRibSpacer * i + compactorRibWidth * (i - 1),
                                                                (ActualExternalHeight - _SubframeHeight - _SubstructureHeight) / 2 + compactorRibHeightOffset + _SubframeHeight + _SubstructureHeight))
                                                                */
                addBodyLine(compactorRibSpacer * (i - 1) + compactorRibWidth * (i - 1),
                    (actualExternalHeight - subframeHeight - substructureHeight) / 2 + compactorRibHeightOffset + subframeHeight + substructureHeight,
                    compactorRibSpacer * i + compactorRibWidth * (i - 1),
                    (actualExternalHeight - subframeHeight - substructureHeight) / 2 + compactorRibHeightOffset + subframeHeight + substructureHeight);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        compactorRibSpacer * (i - 1) + compactorRibWidth * (i - 1),
                                                                (ActualExternalHeight - _SubframeHeight - _SubstructureHeight) / 2 - compactorRibHeightOffset + _SubframeHeight + _SubstructureHeight,
                                                                compactorRibSpacer * i + compactorRibWidth * (i - 1),
                                                                (ActualExternalHeight - _SubframeHeight - _SubstructureHeight) / 2 - compactorRibHeightOffset + _SubframeHeight + _SubstructureHeight))
                                                                */
                addBodyLine(compactorRibSpacer * (i - 1) + compactorRibWidth * (i - 1),
                    (actualExternalHeight - subframeHeight - substructureHeight) / 2 - compactorRibHeightOffset + subframeHeight + substructureHeight,
                    compactorRibSpacer * i + compactorRibWidth * (i - 1),
                    (actualExternalHeight - subframeHeight - substructureHeight) / 2 - compactorRibHeightOffset + subframeHeight + substructureHeight);
                /*
            Next
            */
            }
            /*
        ' Draw ribs
        For i As Integer = 1 To 4
        */
            for (i = 1; i <= 4; i++) {
                /*
                'left
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        compactorRibSpacer * i + compactorRibWidth * (i - 1),
                                                                ActualExternalHeight,
                                                                compactorRibSpacer * i + compactorRibWidth * (i - 1),
                                                                _SubframeHeight + _SubstructureHeight))
                                                                */
                addBodyLine(compactorRibSpacer * i + compactorRibWidth * (i - 1),
                    actualExternalHeight,
                    compactorRibSpacer * i + compactorRibWidth * (i - 1),
                    subframeHeight + substructureHeight);
                /*
                'right
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        compactorRibSpacer * i + compactorRibWidth * i,
                                                                ActualExternalHeight,
                                                                compactorRibSpacer * i + compactorRibWidth * i,
                                                                _SubframeHeight + _SubstructureHeight))
                                                                */
                addBodyLine(compactorRibSpacer * i + compactorRibWidth * i,
                    actualExternalHeight,
                    compactorRibSpacer * i + compactorRibWidth * i,
                    subframeHeight + substructureHeight);
                /*
                'top
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        compactorRibSpacer * i + compactorRibWidth * (i - 1),
                                                                ActualExternalHeight,
                                                                compactorRibSpacer * i + compactorRibWidth * i,
                                                                ActualExternalHeight))
                                                                */
                addBodyLine(compactorRibSpacer * i + compactorRibWidth * (i - 1),
                    actualExternalHeight,
                    compactorRibSpacer * i + compactorRibWidth * i,
                    actualExternalHeight);
            }
            /*
        Next
            
            */

            break;
        case config.BODY_TYPES.ROLLBACK:

            /*
                ' Add subframe.
        With DrawingObjects
            .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, 0, 0, ActualLength - 1000, 0))
            */
            addBodyLine(0, 0, actualLength - 1000, 0);
            /*
            .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, ActualLength - 1000, 0, ActualLength - 500, _SubframeHeight))
            */
            addBodyLine(actualLength - 1000, 0, actualLength - 500, subframeHeight);
            /*
            .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, ActualLength - 500, _SubframeHeight, 0, _SubframeHeight))
            */
            addBodyLine(actualLength - 500, subframeHeight, 0, subframeHeight);
            /*
            .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, 0, _SubframeHeight, 0, 0))
        End With
        */
            addBodyLine(0, subframeHeight, 0, 0);
            /*

        ' Add substructure.
        With DrawingObjects
            If _SubstructureHeight <> 0 Then
            */
            if (substructureHeight !== 0) {
                /*
                    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, ActualLength - 500, _SubframeHeight, ActualLength, _SubframeHeight + _SubstructureHeight))
                    */
                addBodyLine(actualLength - 500, subframeHeight, actualLength, subframeHeight + substructureHeight);
                /*
                    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, ActualLength, _SubframeHeight + _SubstructureHeight, 0, _SubframeHeight + _SubstructureHeight))
                    */
                addBodyLine(actualLength, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight);
                /*
                    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, 0, _SubframeHeight + _SubstructureHeight, 0, _SubframeHeight))
                    */
                addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight);
                /*
                End If
            End With
            */
            }
            /*
        ' Draw headboard front line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        0,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                        */
            addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight + headboardHeight);
            /*
        ' Draw headboard top line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                0,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                        */
            addBodyLine(0, subframeHeight + substructureHeight + headboardHeight, headboardThickness, subframeHeight + substructureHeight + headboardHeight);
            /*
        ' Draw headboard rear line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            addBodyLine(headboardThickness, subframeHeight + substructureHeight + headboardHeight, headboardThickness, subframeHeight + substructureHeight);
            /*
        ' Draw front structure top line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                        _HeadboardThickness + 250,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                        */
            addBodyLine(headboardThickness, subframeHeight + substructureHeight + headboardHeight, headboardThickness + 250, subframeHeight + substructureHeight + headboardHeight);
            /*
        ' Draw front structure rear line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness + 250,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                        _HeadboardThickness + 500,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
            addBodyLine(headboardThickness + 250, subframeHeight + substructureHeight + headboardHeight, headboardThickness + 500, subframeHeight + substructureHeight);
            /*
        ' Draw front inner structure front line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness + 75,
                                                        _SubframeHeight + _SubstructureHeight + 300,
                                                        _HeadboardThickness + 75,
                                                        _SubframeHeight + _SubstructureHeight + 75))
                                                        */
            addBodyLine(headboardThickness + 75, subframeHeight + substructureHeight + 300, headboardThickness + 75, subframeHeight + substructureHeight + 75);
            /*
        ' Draw front inner structure top line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness + 75,
                                                        _SubframeHeight + _SubstructureHeight + 300,
                                                        _HeadboardThickness + 364,
                                                        _SubframeHeight + _SubstructureHeight + 300))
                                                        */
            addBodyLine(headboardThickness + 75, subframeHeight + substructureHeight + 300, headboardThickness + 364, subframeHeight + substructureHeight + 300);
            /*
        ' Draw front inner structure rear line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness + 364,
                                                        _SubframeHeight + _SubstructureHeight + 300,
                                                        _HeadboardThickness + 410,
                                                        _SubframeHeight + _SubstructureHeight + 75))
                                                        */
            addBodyLine(headboardThickness + 364, subframeHeight + substructureHeight + 300, headboardThickness + 410, subframeHeight + substructureHeight + 75);
            /*
        ' Draw front inner structure bpttom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness + 75,
                                                        _SubframeHeight + _SubstructureHeight + 75,
                                                        _HeadboardThickness + 410,
                                                        _SubframeHeight + _SubstructureHeight + 75))
                                                        */
            addBodyLine(headboardThickness + 75, subframeHeight + substructureHeight + 75, headboardThickness + 410, subframeHeight + substructureHeight + 75);
            /*
        'outer roller
        DrawingObjects.Add(New UtilDrawingObjectArc(_HeadboardThickness + 75 + 50,
                                                        _SubframeHeight + _SubstructureHeight + 75 + 50,
                                                        75,
                                                        230,
                                                        170))
                                                        */
            addBodyArc(headboardThickness + 75 + 50, subframeHeight + substructureHeight + 75 + 50, 75, 230, 170);
            /*
        'inner roller part 1
        DrawingObjects.Add(New UtilDrawingObjectArc(_HeadboardThickness + 75 + 50,
                                                        _SubframeHeight + _SubstructureHeight + 75 + 50,
                                                        50,
                                                        200,
                                                        230))
                                                        */
            addBodyArc(headboardThickness + 75 + 50, subframeHeight + substructureHeight + 75 + 50, 50, 200, 230);
            /*
        'inner roller part 2
        DrawingObjects.Add(New UtilDrawingObjectArc(_HeadboardThickness + 75 + 50,
                                                        _SubframeHeight + _SubstructureHeight + 75 + 50,
                                                        50,
                                                        100,
                                                        90))
                                                        */
            addBodyArc(headboardThickness + 75 + 50, subframeHeight + substructureHeight + 75 + 50, 50, 100, 90);
            /*
        'chain
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                _HeadboardThickness + 500,
                                                        _SubframeHeight + _SubstructureHeight + 40,
                                                        ActualLength * 0.9,
                                                        _SubframeHeight + _SubstructureHeight + 40))
                                                        */
            addBodyLine(headboardThickness + 500, subframeHeight + substructureHeight + 40, actualLength * 0.9, subframeHeight + substructureHeight + 40);
            /*
        'chain end front
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.9,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength * 0.9,
                                                        _SubframeHeight + _SubstructureHeight + 50))
                                                        */
            addBodyLine(actualLength * 0.9, subframeHeight + substructureHeight, actualLength * 0.9, subframeHeight + substructureHeight + 50);
            /*
        'chain end top
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.9,
                                                        _SubframeHeight + _SubstructureHeight + 50,
                                                        ActualLength * 0.9 + 150,
                                                        _SubframeHeight + _SubstructureHeight + 50))
                                                        */
            addBodyLine(actualLength * 0.9, subframeHeight + substructureHeight + 50, actualLength * 0.9 + 150, subframeHeight + substructureHeight + 50);
            /*
        'chain end rear
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                ActualLength * 0.9 + 150,
                                                        _SubframeHeight + _SubstructureHeight + 50,
                                                        ActualLength * 0.9 + 150,
                                                        _SubframeHeight + _SubstructureHeight))
            */
            addBodyLine(actualLength * 0.9 + 150, subframeHeight + substructureHeight + 50, actualLength * 0.9 + 150, subframeHeight + substructureHeight);
            break;

        case config.BODY_TYPES.TIMBER:
            
            /*
                ' Add subframe.
        AddSubframeForBody(ActualLength)
        */
            addSubframeForBody(actualLength);
            /*
        ' Add substructure.
        AddSubStructureForBody(ActualLength)
        */
            addSubstructureForBody(actualLength);
            /*
        If _HeadboardThickness > 0 Then
        */
            if (headboardThickness > 0) {
                /*
                'headboard
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        0,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        0.3 * _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                        */
                addBodyLine(0, subframeHeight + substructureHeight, 0.3 * headboardThickness, subframeHeight + substructureHeight + headboardHeight);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        0.3 * _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                        */
                addBodyLine(0.3 * headboardThickness, subframeHeight + substructureHeight + headboardHeight, headboardThickness, subframeHeight + substructureHeight + headboardHeight);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        _HeadboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _HeadboardHeight))
                                                        */
                addBodyLine(headboardThickness, subframeHeight + substructureHeight, headboardThickness, subframeHeight + substructureHeight + headboardHeight);
            }
            /*
        End If
        */

            /*
        If _TailboardThickness > 0 Then
        */
            if (tailboardThickness > 0) {
                /*
                'tailboard
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _TailboardHeight))
                                                        */
                addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, subframeHeight + substructureHeight + tailboardHeight);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        ActualLength - _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _TailboardHeight,
                                                        ActualLength - 0.3 * _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _TailboardHeight))
                                                        */
                addBodyLine(actualLength - tailboardThickness,
                    subframeHeight + substructureHeight + tailboardHeight,
                    actualLength - 0.3 * tailboardThickness,
                    subframeHeight + substructureHeight + tailboardHeight);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        ActualLength - 0.3 * _TailboardThickness,
                                                        _SubframeHeight + _SubstructureHeight + _TailboardHeight,
                                                        ActualLength,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
                addBodyLine(actualLength - 0.3 * tailboardThickness,
                    subframeHeight + substructureHeight + tailboardHeight,
                    actualLength,
                    subframeHeight + substructureHeight);
            }
            /*
        End If
        */

            /*
        For Each upright As FileLoadingAccessoryBodyUpright In _Uprights
        */
            body.getBodyUprightHolder().getBodyUprights().forEach(function (upright) {

                var insideFrontToUpright = upright.getInsideFrontToUpright(),
                    uprightWidth = upright.getWidth(),
                    uprightHeight = upright.getHeight();
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                    _HeadboardThickness + upright.InsideFrontToUpright,
                                                    _SubframeHeight + _SubstructureHeight,
                                                    _HeadboardThickness + upright.InsideFrontToUpright + 0.35 * upright.Width,
                                                    _SubframeHeight + _SubstructureHeight + upright.Height))
                */
                addBodyLine(headboardThickness + insideFrontToUpright,
                    subframeHeight + substructureHeight,
                    headboardThickness + insideFrontToUpright + 0.35 * uprightWidth,
                    subframeHeight + substructureHeight + uprightHeight);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        _HeadboardThickness + upright.InsideFrontToUpright + 0.35 * upright.Width,
                                                        _SubframeHeight + _SubstructureHeight + upright.Height,
                                                        _HeadboardThickness + upright.InsideFrontToUpright + 0.65 * upright.Width,
                                                        _SubframeHeight + _SubstructureHeight + upright.Height))
                                                        */
                addBodyLine(headboardThickness + insideFrontToUpright + 0.35 * uprightWidth,
                    subframeHeight + substructureHeight + uprightHeight,
                    headboardThickness + insideFrontToUpright + 0.65 * uprightWidth,
                    subframeHeight + substructureHeight + uprightHeight);
                /*
                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        _HeadboardThickness + upright.InsideFrontToUpright + 0.65 * upright.Width,
                                                        _SubframeHeight + _SubstructureHeight + upright.Height,
                                                        _HeadboardThickness + upright.InsideFrontToUpright + upright.Width,
                                                        _SubframeHeight + _SubstructureHeight))
                                                        */
                addBodyLine(headboardThickness + insideFrontToUpright + 0.65 * uprightWidth,
                    subframeHeight + substructureHeight + uprightHeight,
                    headboardThickness + insideFrontToUpright + uprightWidth,
                    subframeHeight + substructureHeight);
            });
            
            

            /*
        Next
            */

            break;
        case config.BODY_TYPES.HOOKLIFT:
            
            
            
            if (body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                drawVanBody();
            
            }else {
                
                //' Draw bin bottom line.
                addBodyLine(hookarmLength, subframeHeight + hookbeamHeight, actualLength, subframeHeight + hookbeamHeight, true);

                ///*
                //' Draw bin rear height line.
                addBodyLine(actualLength, subframeHeight + hookbeamHeight, actualLength, subframeHeight + hookbeamHeight + binHeight, true);

                ///*
                //' Draw bin top line.
                addBodyLine(actualLength, subframeHeight + hookbeamHeight + binHeight, hookarmLength, subframeHeight + hookbeamHeight + binHeight, true);

                ///*
                // ' Draw bin front height line.
                addBodyLine(hookarmLength, subframeHeight + hookbeamHeight, hookarmLength, subframeHeight + hookbeamHeight + binHeight, true);
                


                //' Add subframe.
                //AddSubframeForBody(ActualLength)
                addSubframeForBody(subframeLength);

                //' Add substructure.
                //AddSubStructureForBody(ActualLength)
                addSubstructureForBody(subframeLength);

                ////draw hookarm and hook
                if (hookarmLength > 0) {

                    var hookHeight = 220, hookProtrusion = 30, hookLength = 115;

                    //draw hookarm lower front line 
                    addBodyLine(0, subframeHeight + hookbeamHeight, 0, subframeHeight + hookbeamHeight + ((hookarmHeight - hookHeight) / 2));
                    //draw hookarm upper front line
                    addBodyLine(0, subframeHeight + hookbeamHeight + ((hookarmHeight - hookHeight) / 2), hookarmLength / 2, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight));
                    //draw hookarm top line
                    addBodyLine(hookarmLength / 2, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight), hookarmLength, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight));
                    //draw hookarm back line
                    addBodyLine(hookarmLength, subframeHeight + hookbeamHeight, hookarmLength, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight));

                    //draw hook bottom slant of front protrusion
                    addBodyLine(hookarmLength - hookLength, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight), (hookarmLength - hookLength) - hookProtrusion, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + hookProtrusion);
                    //hook top slant of front protrusion
                    addBodyLine((hookarmLength - hookLength) - hookProtrusion, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + hookProtrusion, hookarmLength - hookLength, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2));
                    //hook bottom arc
                    addBodyArc(hookarmLength - hookLength, (subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + (hookLength / 3), (hookLength / 3), 0, 90);
                    //addBodyArc(hookarmLength / 2, (subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + ((hookarmLength / 2) / 3), ((hookarmLength / 2) / 3) + (hookProtrusion * 2), 0, 30);

                    ////hook mid section
                    var hookMidSectionHeight = hookHeight - (((hookProtrusion * 2) + ((hookLength) / 3)) * 2);
                    //hook mid section front
                    addBodyLine((hookarmLength - hookLength) + (hookLength / 3), subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + ((hookProtrusion * 2) + (hookLength / 3)),
                        (hookarmLength - hookLength) + (hookLength / 3), subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + ((hookProtrusion * 2) + (hookLength / 3)) + hookMidSectionHeight);
                    ////hook mid section back
                    addBodyLine(hookarmLength - 9, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight),
                        hookarmLength - 9, subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + ((hookProtrusion * 2) + (hookLength / 3)) + hookMidSectionHeight);

                    //hook top arc
                    addBodyArc(hookarmLength - hookLength, (subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + (hookLength / 3) + hookMidSectionHeight, (hookLength / 3), 225, 135);
                    addBodyArc(hookarmLength - hookLength, (subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + (hookLength / 3) + hookMidSectionHeight, (hookLength / 3) + (hookProtrusion * 2), 270, 90);
                    addBodyLine((hookarmLength - hookLength) - hookProtrusion, subframeHeight + hookbeamHeight + hookarmHeight - hookProtrusion, hookarmLength - hookLength, subframeHeight + hookbeamHeight + hookarmHeight);
                    addBodyLine((hookarmLength - hookLength) - hookProtrusion, subframeHeight + hookbeamHeight + hookarmHeight - hookProtrusion, (hookarmLength - hookLength) - hookProtrusion, subframeHeight + hookbeamHeight + hookarmHeight - (hookProtrusion * 2));
                }



                ////draw beam roller circles
                var topHookbeamCutBack = ((Math.tan(hookbeamTaperAngle / (180 / Math.PI)) * hookbeamHeight));
                //draw outer roller circle
                addBodyCircle(subframeLength - topHookbeamCutBack - 40, (subframeHeight + hookbeamHeight) - 50, 125, CIRCLE_TYPE.OTHER);
                //draw inner roller circle
                addBodyCircle(subframeLength - topHookbeamCutBack - 40, (subframeHeight + hookbeamHeight) - 50, 50, CIRCLE_TYPE.OTHER);

                ////draw bin roller circle
                //draw outer cricle
                addBodyCircle(actualLength - 125, (subframeHeight + hookbeamHeight) + 50, 125, CIRCLE_TYPE.OTHER, true);
                //draw inner circle
                addBodyCircle(actualLength - 125, (subframeHeight + hookbeamHeight) + 50, 50, CIRCLE_TYPE.OTHER, true);



            ///*
            //' Draw outer bottom line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   0,
            //                                          _SubframeHeight + _SubstructureHeight,
            //                                          ActualLength,
            //                                          _SubframeHeight + _SubstructureHeight))

            //*/
            //addBodyLine(0, subframeHeight + substructureHeight, actualLength, subframeHeight + substructureHeight);

            ///*
            //' Draw outer rear height line.
            //  DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   ActualLength,
            //                                          _SubframeHeight + _SubstructureHeight,
            //                                          ActualLength,
            //                                          ActualExternalHeight))

            //*/
            //addBodyLine(actualLength, subframeHeight + substructureHeight, actualLength, actualExternalHeight);


            ///*
            //' Draw outer roof line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                    ActualLength,
            //                            ActualExternalHeight,
            //                            0,
            //                            ActualExternalHeight))    
            //*/
            //addBodyLine(actualLength, actualExternalHeight, 0, actualExternalHeight);

            ///*
            //' Draw outer front height line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   0,
            //                                          ActualExternalHeight,
            //                                          0,
            //                                          _SubframeHeight + SubstructureHeight))

            //*/
            //addBodyLine(0, actualExternalHeight, 0, subframeHeight + substructureHeight);

            ///*

            //' Draw inner bottom line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   _HeadboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness,
            //                                          ActualLength - _TailboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness))

            //*/
            ////addBodyLine(headboardThickness, subframeHeight + substructureHeight + roofThickness, actualLength - tailboardThickness, subframeHeight + substructureHeight + roofThickness);

            ///*
            //' Draw inner rear height line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   ActualLength - _TailboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness,
            //                                          ActualLength - _TailboardThickness,
            //                                          ActualExternalHeight - _RoofThickness))    
            //*/
            //addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, actualExternalHeight - roofThickness);

            ///*
            //' Draw inner roof line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   ActualLength - _TailboardThickness,
            //                                          ActualExternalHeight - _RoofThickness,
            //                                          _HeadboardThickness,
            //                                          ActualExternalHeight - _RoofThickness))        
            //*/
            //addBodyLine(actualLength - tailboardThickness, actualExternalHeight - roofThickness, headboardThickness, actualExternalHeight - roofThickness);

            ///*
            // ' Draw inner front height line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   _HeadboardThickness,
            //                                          ActualExternalHeight - _RoofThickness,
            //                                          _HeadboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness))       
            //*/
            //addBodyLine(headboardThickness, actualExternalHeight - roofThickness, headboardThickness, subframeHeight + substructureHeight);

            ///*

            //   ' Removed until SP1
            //   '' Draw body ribs.
            //   'For i As Integer = 1 To numberOfBodyRibs - 1
            //   */

            ///*
            //   '    DrawingObjects.Add(New FileLoadingDrawingLine(_HeadboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i, _
            //   '                                                  ActualLength - TailboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i))

            //   '    DrawingObjects.Add(New FileLoadingDrawingLine(_HeadboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i + bodyRibHeight, _
            //   '                                                  ActualLength - TailboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i + bodyRibHeight))

            //   'Next
            //   */
            } 
            

            

            break;
        case config.BODY_TYPES.SERVICE:

            
            useBodyDrawingObjectsArray = true;

            var archOuterStartX, archOuterStartY, archOuterEndX, archOuterEndY, archInnerStartX,  archInnerY, archInnerEndX;
            var firstAxleCentreFromBodyOriginX, axleCentreFromBodyOriginY, lastAxleCentreFromBodyOriginX;
            var tyreRadius, tyreArchCornerRadius, tyreArchAddon, tyreArchAddonMultiplier = 2.5;

            var drawLeftArch = false, drawRightArch = false;

            var axle1Rear = null;
            var axle2Rear = null;
            var axle3Rear = null;
            var axle4Rear = null;

            var axle1RearTyreRadius = 0, axle2RearTyreRadius = 0, axle3RearTyreRadius = 0, axle4RearTyreRadius = 0;

            var firstAxle = null, lastAxle = null;

            var archSeedVal = 0;
            

            switch (chassisObject.getChassisObjectName()) {
                case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                    axle1Rear = curVehicle.getAxlesHolder().getAxle1Rear();
                    axle2Rear = curVehicle.getAxlesHolder().getAxle2Rear();
                    axle3Rear = curVehicle.getAxlesHolder().getAxle3Rear();
                    axle4Rear = curVehicle.getAxlesHolder().getAxle4Rear();

                    if (axle1Rear) {
                        axle1RearTyreRadius = axle1Rear.getTyreRadius();
                    }
                    if (axle2Rear) {
                        axle2RearTyreRadius = axle2Rear.getTyreRadius();
                    }
                    if (axle3Rear) {
                        axle3RearTyreRadius = axle3Rear.getTyreRadius();
                    }
                    if (axle4Rear) {
                        axle4RearTyreRadius = axle4Rear.getTyreRadius();
                    }

                    firstAxle = axle1Rear;

                    tyreRadius = Math.max(axle1RearTyreRadius, axle2RearTyreRadius, axle3RearTyreRadius, axle4RearTyreRadius);//firstAxle.getTyreRadius();

                    firstAxleCentreFromBodyOriginX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() - (curVehicle.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(curVehicle.getChassisObjectName()));
                    archSeedVal = Math.max(tyreRadius, 265);
                    archSeedVal = Math.min(archSeedVal, (curVehicle.getChassisHeightRear() + actualExternalHeight - body.getBottomOfBodyToTopOfChassis())/2.2);
                    if (axle4Rear !== null) {
                        lastAxleCentreFromBodyOriginX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() + curVehicle.getAxle3RearToAxle4Rear() - (curVehicle.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(curVehicle.getChassisObjectName()));
                        lastAxle = axle4Rear;
                    } else if (axle3Rear !== null) {
                        lastAxleCentreFromBodyOriginX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() + curVehicle.getAxle2RearToAxle3Rear() - (curVehicle.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(curVehicle.getChassisObjectName()));
                        lastAxle = axle3Rear;
                    } else if (axle2Rear !== null) {
                        lastAxleCentreFromBodyOriginX = curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToAxle2Front() + curVehicle.getAxle2FrontToAxle1Rear() + curVehicle.getAxle1RearToAxle2Rear() - (curVehicle.getBumperToBackOfCab() + offerManager.getRigOps().getBodyGap(curVehicle.getChassisObjectName()));
                        lastAxle = axle2Rear;
                    }
                    axleCentreFromBodyOriginY = -(curVehicle.getChassisHeightRear() - archSeedVal);

                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    axle1Rear = curTrailer1.getAxlesHolder().getAxle1Rear();
                    axle2Rear = curTrailer1.getAxlesHolder().getAxle2Rear();
                    axle3Rear = curTrailer1.getAxlesHolder().getAxle3Rear();
                    axle4Rear = curTrailer1.getAxlesHolder().getAxle4Rear();

                    if (axle1Rear) {
                        axle1RearTyreRadius = axle1Rear.getTyreRadius();
                    }
                    if (axle2Rear) {
                        axle2RearTyreRadius = axle2Rear.getTyreRadius();
                    }
                    if (axle3Rear) {
                        axle3RearTyreRadius = axle3Rear.getTyreRadius();
                    }
                    if (axle4Rear) {
                        axle4RearTyreRadius = axle4Rear.getTyreRadius();
                    }

                    firstAxle = axle1Rear;

                    tyreRadius = Math.max(axle1RearTyreRadius, axle2RearTyreRadius, axle3RearTyreRadius, axle4RearTyreRadius); //firstAxle.getTyreRadius();

                    //var chassisStartOffset = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1);//curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset()

                    firstAxleCentreFromBodyOriginX = (curTrailer1.getHitchPointToAxle1Rear() - curTrailer1.getDrawbarLength() - offerManager.getRigOps().getBodyGap(curTrailer1.getChassisObjectName()));
                    archSeedVal = Math.max(tyreRadius, 500);
                    archSeedVal = Math.min(archSeedVal, (curTrailer1.getChassisHeight() + actualExternalHeight - body.getBottomOfBodyToTopOfChassis()) / 2.2);
                    if (axle4Rear !== null) {
                        lastAxleCentreFromBodyOriginX = firstAxleCentreFromBodyOriginX + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() + curTrailer1.getAxle3RearToAxle4Rear();
                        lastAxle = axle4Rear;
                    } else if (axle3Rear !== null) {
                        lastAxleCentreFromBodyOriginX = firstAxleCentreFromBodyOriginX + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear();
                        lastAxle = axle3Rear;
                    } else if (axle2Rear !== null) {
                        lastAxleCentreFromBodyOriginX = firstAxleCentreFromBodyOriginX + curTrailer1.getAxle1RearToAxle2Rear();
                        lastAxle = axle2Rear;
                    }
                    axleCentreFromBodyOriginY = -(curTrailer1.getChassisHeight() - archSeedVal);
                    break;
                case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                    axle1Rear = curTrailer2.getAxlesHolder().getAxle1Rear();
                    axle2Rear = curTrailer2.getAxlesHolder().getAxle2Rear();
                    axle3Rear = curTrailer2.getAxlesHolder().getAxle3Rear();
                    axle4Rear = curTrailer2.getAxlesHolder().getAxle4Rear();

                    if (axle1Rear) {
                        axle1RearTyreRadius = axle1Rear.getTyreRadius();
                    }
                    if (axle2Rear) {
                        axle2RearTyreRadius = axle2Rear.getTyreRadius();
                    }
                    if (axle3Rear) {
                        axle3RearTyreRadius = axle3Rear.getTyreRadius();
                    }
                    if (axle4Rear) {
                        axle4RearTyreRadius = axle4Rear.getTyreRadius();
                    }


                    firstAxle = axle1Rear;

                    tyreRadius = Math.max(axle1RearTyreRadius, axle2RearTyreRadius, axle3RearTyreRadius, axle4RearTyreRadius); //firstAxle.getTyreRadius();

                    firstAxleCentreFromBodyOriginX = curTrailer2.getHitchPointToAxle1Rear() - curTrailer2.getDrawbarLength();
                    archSeedVal = Math.max(tyreRadius, 500);
                    archSeedVal = Math.min(archSeedVal, (curTrailer2.getChassisHeight() + actualExternalHeight - body.getBottomOfBodyToTopOfChassis()) / 2.2);
                    if (axle4Rear !== null) {
                        lastAxleCentreFromBodyOriginX = curTrailer2.getFrontOverhang() + curTrailer2.getAxle1RearToAxle2Rear() + curTrailer2.getAxle2RearToAxle3Rear() + curTrailer2.getAxle3RearToAxle4Rear();
                        lastAxle = axle4Rear;
                    } else if (axle3Rear !== null) {
                        lastAxleCentreFromBodyOriginX = curTrailer2.getFrontOverhang() + curTrailer2.getAxle1RearToAxle2Rear() + curTrailer2.getAxle2RearToAxle3Rear();
                        lastAxle = axle3Rear;
                    } else if (axle2Rear !== null) {
                        lastAxleCentreFromBodyOriginX = curTrailer2.getFrontOverhang() + curTrailer2.getAxle1RearToAxle2Rear();
                        lastAxle = axle2Rear;
                    }
                    axleCentreFromBodyOriginY = -(curTrailer2.getChassisHeight() - archSeedVal);

                    break;
            }

            

            

            
            tyreArchAddon = archSeedVal * 0.2;
            if (tyreArchAddon > 80) {
                tyreArchAddon = 80;
            }
            archOuterStartX = firstAxleCentreFromBodyOriginX - archSeedVal - tyreArchAddon;
            if (archOuterStartX >= tyreArchAddon * tyreArchAddonMultiplier) {
                drawLeftArch = true;
            }
            if (lastAxle === null) {
                archOuterEndX = firstAxleCentreFromBodyOriginX + archSeedVal + tyreArchAddon;
            } else {
                archOuterEndX = lastAxleCentreFromBodyOriginX + archSeedVal + tyreArchAddon;
            }
            if (archOuterEndX <= actualLength - tyreArchAddon * tyreArchAddonMultiplier) {
                drawRightArch = true;
            }
            archInnerStartX = archOuterStartX + tyreArchAddon * tyreArchAddonMultiplier;
            archInnerEndX = archOuterEndX - tyreArchAddon * tyreArchAddonMultiplier;
            //archInnerY = axleCentreFromBodyOriginY + tyreRadius + tyreArchAddon;
            archInnerY = axleCentreFromBodyOriginY + archSeedVal + tyreArchAddon;
            
            //if (drawLeftArch) {
            //    //front of body to left arch start bottom line
            //    bodyDrawingObjects.push(addBodyLine(0, -bottomOfBodyToTopOfChassis, archOuterStartX, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(0, -bottomOfBodyToTopOfChassis, archOuterStartX, -bottomOfBodyToTopOfChassis);
            //    //left arch vertical line
            //    //bodyDrawingObjects.push(addBodyLine(archOuterStartX, -bottomOfBodyToTopOfChassis, archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    bodyDrawingObjects.push(addBodyLine(archOuterStartX, -bottomOfBodyToTopOfChassis, archOuterStartX, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    bodyDrawingObjects.push(addBodyLine(archOuterStartX, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(archOuterStartX, -bottomOfBodyToTopOfChassis, archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier);
            //    //body front left vertical line
            //    bodyDrawingObjects.push(addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, -bottomOfBodyToTopOfChassis);
            //    //bodyDrawingObjects.push(addBodyArc(archInnerStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 180, 90, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyArc(archInnerStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 180, 90);
            //    //objectNum++;
            //    //sequenceNum = 1;

            //    //bodyDrawingObjects.push(addBodyLine(archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, archOuterStartX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //bodyDrawingObjects.push(addBodyLine(archOuterStartX, archInnerY, archInnerStartX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    objectNum++;
            //    sequenceNum = 1;
            //} else {


            //    //bodyDrawingObjects.push(addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //objectNum++;
            //    //sequenceNum = 1;
            //    //addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, archInnerY);
            //}

            
            //if (drawRightArch) {
            //    //right arch end to back of body bottom line
            //    bodyDrawingObjects.push(addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, actualLength, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    bodyDrawingObjects.push(addBodyLine(actualLength, -bottomOfBodyToTopOfChassis, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    bodyDrawingObjects.push(addBodyLine(actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, archOuterEndX, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, actualLength, -bottomOfBodyToTopOfChassis);
            //    //right arch vertical line
            //    bodyDrawingObjects.push(addBodyLine(archOuterEndX, actualExternalHeight - bottomOfBodyToTopOfChassis, archOuterEndX, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier);
            //    //body back right vertical line
                
            //    //addBodyLine(actualLength, -bottomOfBodyToTopOfChassis, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis);
            //    //bodyDrawingObjects.push(addBodyArc(archInnerEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 270, 90, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyArc(archInnerEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 270, 90);
            //    //objectNum++;
            //    //sequenceNum = 1;
            //    //bodyDrawingObjects.push(addBodyLine(archInnerEndX, archInnerY, archOuterEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //bodyDrawingObjects.push(addBodyLine(archOuterEndX, archInnerY, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    objectNum++;
            //    sequenceNum = 1;
            //} else {
            //    //bodyDrawingObjects.push(addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //objectNum++;
            //    //sequenceNum = 1;
            //    //addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis);
            //}

            //if (drawLeftArch && drawRightArch) {
            //    bodyDrawingObjects.push(addBodyLine(archOuterStartX, archInnerY, archOuterEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    bodyDrawingObjects.push(addBodyLine(archOuterEndX, archInnerY, archOuterEndX, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    bodyDrawingObjects.push(addBodyLine(archOuterEndX, actualExternalHeight - bottomOfBodyToTopOfChassis, archOuterStartX, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    bodyDrawingObjects.push(addBodyLine(archOuterStartX, actualExternalHeight - bottomOfBodyToTopOfChassis, archOuterStartX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    objectNum++;
            //    sequenceNum = 1;
            //    //addBodyLine(archInnerStartX, archInnerY, archInnerEndX, archInnerY);
            //} else if (drawLeftArch) {
            //    bodyDrawingObjects.push(addBodyLine(archInnerStartX, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(archInnerStartX, archInnerY, actualLength, archInnerY);
            //} else if (drawRightArch) {
            //    bodyDrawingObjects.push(addBodyLine(0, archInnerY, archInnerEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(0, archInnerY, archInnerEndX, archInnerY);
            //} else if (!drawLeftArch && !drawRightArch) {
            //    bodyDrawingObjects.push(addBodyLine(0, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(0, archInnerY, actualLength, archInnerY);
            //}
            
            //bodyDrawingObjects.push(addBodyLine(actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //addBodyLine(actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, actualExternalHeight - bottomOfBodyToTopOfChassis);

            if (drawLeftArch) {
                //body front left vertical line
                bodyDrawingObjects.push(addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //front of body to left arch start bottom line
                bodyDrawingObjects.push(addBodyLine(0, -bottomOfBodyToTopOfChassis, archOuterStartX, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(0, -bottomOfBodyToTopOfChassis, archOuterStartX, -bottomOfBodyToTopOfChassis);
                //left arch vertical line
                bodyDrawingObjects.push(addBodyLine(archOuterStartX, -bottomOfBodyToTopOfChassis, archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //bodyDrawingObjects.push(addBodyLine(archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, archOuterStartX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //bodyDrawingObjects.push(addBodyLine(archOuterStartX, archInnerY, archInnerStartX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyArc(archInnerStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 180, 90, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                

                if (drawLeftArch && drawRightArch) {
                    bodyDrawingObjects.push(addBodyLine(archInnerStartX, archInnerY, archInnerEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                    //addBodyLine(archInnerStartX, archInnerY, archInnerEndX, archInnerY);
                    // archCentreForDoubleDrawn = true;
                } else {
                    bodyDrawingObjects.push(addBodyLine(archInnerStartX, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                    bodyDrawingObjects.push(addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                }
                
            } else {
                bodyDrawingObjects.push(addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(0, archInnerY, archInnerEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                
                if (!drawRightArch) {
                    bodyDrawingObjects.push(addBodyLine(archInnerEndX, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                    bodyDrawingObjects.push(addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                }
            }


            if (drawRightArch) {
                //contunation of top arch line
                //bodyDrawingObjects.push(addBodyLine(archInnerEndX, archInnerY, archOuterEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //bodyDrawingObjects.push(addBodyLine(archOuterEndX, archInnerY, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyArc(archInnerEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 270, 90, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, archOuterEndX, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //right arch end to back of body bottom line
                bodyDrawingObjects.push(addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, actualLength, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, actualLength, -bottomOfBodyToTopOfChassis);
                //right arch vertical line
                
                //addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier);
                //body back right vertical line
                bodyDrawingObjects.push(addBodyLine(actualLength, -bottomOfBodyToTopOfChassis, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(actualLength, -bottomOfBodyToTopOfChassis, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis);
                //bodyDrawingObjects.push(addBodyArc(archInnerEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 270, 90, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyArc(archInnerEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 270, 90);
                //objectNum++;
                //sequenceNum = 1;
                //bodyDrawingObjects.push(addBodyLine(archInnerEndX, archInnerY, archOuterEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //bodyDrawingObjects.push(addBodyLine(archOuterEndX, archInnerY, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            }/* else {
                bodyDrawingObjects.push(addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //objectNum++;
                //sequenceNum = 1;
                //addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis);
            }*/

            //if (drawLeftArch && drawRightArch) {
            //    bodyDrawingObjects.push(addBodyLine(archInnerStartX, archInnerY, archInnerEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(archInnerStartX, archInnerY, archInnerEndX, archInnerY);
            //} else if (drawLeftArch) {
            //    bodyDrawingObjects.push(addBodyLine(archInnerStartX, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(archInnerStartX, archInnerY, actualLength, archInnerY);
            //} else if (drawRightArch) {
            //    bodyDrawingObjects.push(addBodyLine(0, archInnerY, archInnerEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(0, archInnerY, archInnerEndX, archInnerY);
            //} else if (!drawLeftArch && !drawRightArch) {
            //    bodyDrawingObjects.push(addBodyLine(0, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //    //addBodyLine(0, archInnerY, actualLength, archInnerY);
            //}

            bodyDrawingObjects.push(addBodyLine(actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //addBodyLine(actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, actualExternalHeight - bottomOfBodyToTopOfChassis);

            objectNum++;
            sequenceNum = 1;
            bodyDrawingObjects.push(addBodyLine(0, substructureHeight + subframeHeight, actualLength, substructureHeight + subframeHeight, true, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "FALSE"));

            if (bodyDrawingObjects.length > 0 && useBodyDrawingObjectsArray) {
                bodyDrawingObjects.sort(sortDrawingObjects);
                bodyDrawingObjects = groupDrawingObjects(bodyDrawingObjects);
                //bodyDrawingObjects.forEach(function (drawingObject) {
                //    drawingObject.setDrawingOrder(5000);
                //});
                addToMainDrawingObjectsArray(bodyDrawingObjects);
            }
            /*
            if (drawLeftArch) {
                //front of body to left arch start bottom line
                bodyDrawingObjects.push(addBodyLine(0, -bottomOfBodyToTopOfChassis, archOuterStartX, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(0, -bottomOfBodyToTopOfChassis, archOuterStartX, -bottomOfBodyToTopOfChassis);
                //left arch vertical line
                bodyDrawingObjects.push(addBodyLine(archOuterStartX, -bottomOfBodyToTopOfChassis, archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(archOuterStartX, -bottomOfBodyToTopOfChassis, archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier);
                //body front left vertical line
                bodyDrawingObjects.push(addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, -bottomOfBodyToTopOfChassis);
                //bodyDrawingObjects.push(addBodyArc(archInnerStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 180, 90, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyArc(archInnerStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 180, 90);
                //objectNum++;
                //sequenceNum = 1;

                bodyDrawingObjects.push(addBodyLine(archOuterStartX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, archOuterStartX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(archOuterStartX, archInnerY, archInnerStartX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            } else {
                bodyDrawingObjects.push(addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //objectNum++;
                //sequenceNum = 1;
                //addBodyLine(0, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, archInnerY);
            }

            
            if (drawRightArch) {
                //right arch end to back of body bottom line
                bodyDrawingObjects.push(addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, actualLength, -bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, actualLength, -bottomOfBodyToTopOfChassis);
                //right arch vertical line
                bodyDrawingObjects.push(addBodyLine(archOuterEndX, - bottomOfBodyToTopOfChassis, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(archOuterEndX, -bottomOfBodyToTopOfChassis, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier);
                //body back right vertical line
                bodyDrawingObjects.push(addBodyLine(actualLength, -bottomOfBodyToTopOfChassis, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(actualLength, -bottomOfBodyToTopOfChassis, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis);
                //bodyDrawingObjects.push(addBodyArc(archInnerEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 270, 90, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyArc(archInnerEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, tyreArchAddon * tyreArchAddonMultiplier, 270, 90);
                //objectNum++;
                //sequenceNum = 1;
                bodyDrawingObjects.push(addBodyLine(archInnerEndX, archInnerY, archOuterEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                bodyDrawingObjects.push(addBodyLine(archOuterEndX, archInnerY, archOuterEndX, archInnerY - tyreArchAddon * tyreArchAddonMultiplier, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            } else {
                bodyDrawingObjects.push(addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //objectNum++;
                //sequenceNum = 1;
                //addBodyLine(actualLength, archInnerY, actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis);
            }

            if (drawLeftArch && drawRightArch) {
                bodyDrawingObjects.push(addBodyLine(archInnerStartX, archInnerY, archInnerEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(archInnerStartX, archInnerY, archInnerEndX, archInnerY);
            } else if (drawLeftArch) {
                bodyDrawingObjects.push(addBodyLine(archInnerStartX, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(archInnerStartX, archInnerY, actualLength, archInnerY);
            } else if (drawRightArch) {
                bodyDrawingObjects.push(addBodyLine(0, archInnerY, archInnerEndX, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(0, archInnerY, archInnerEndX, archInnerY);
            } else if (!drawLeftArch && !drawRightArch) {
                bodyDrawingObjects.push(addBodyLine(0, archInnerY, actualLength, archInnerY, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
                //addBodyLine(0, archInnerY, actualLength, archInnerY);
            }
            
            bodyDrawingObjects.push(addBodyLine(actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, actualExternalHeight - bottomOfBodyToTopOfChassis, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //addBodyLine(actualLength, actualExternalHeight - bottomOfBodyToTopOfChassis, 0, actualExternalHeight - bottomOfBodyToTopOfChassis);
            */
            break;

    }

    function addBodyLine(startX, startY, endX, endY, dashed,  layerNum, blockName, objectNumSequenceNum, closedIndicator) {
        var newLine = new Line(bodyOriginX + startX, bodyOriginY + startY, bodyOriginX + endX, bodyOriginY + endY, layerNum, blockName, objectNumSequenceNum, closedIndicator);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName()  + '.' + config.ACCESSORY_TYPES.BODY );
        if (dashed !== undefined && dashed === true) {
            newLine.setDashed(true);
            if (body.getType() === config.BODY_TYPES.HOOKLIFT) {
                newLine.setDashLengths(100, 60);
                newLine.setLineWidth(2);
            } else if (body.getType() === config.BODY_TYPES.SERVICE) {
                newLine.setDashLengths(40, 40);
                newLine.setLineWidth(1); 
            }
            
        }
        if (body.getType() === config.BODY_TYPES.SERVICE || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.DROPSIDE) {
            newLine.setBringToFront(true);
            //newLine.setBypassHitAreaSort(true);
        }
        if (useBodyDrawingObjectsArray) {
            return newLine;
        } else {
            newLine.setDrawingOrder(5000);
            drawingObjectsArray.push(newLine);
        }
        
        //addLine(bodyOriginX + startX, bodyOriginY + startY, bodyOriginX + endX, bodyOriginY + endY);
    }

    function addBodyArc(centreX, centreY, radius, startAngle, sweep, layerNum, blockName, objectNumSequenceNum, closedIndicator) {
        var newArc = new Arc(bodyOriginX + centreX, bodyOriginY + centreY, radius, startAngle, sweep, true, layerNum, blockName, objectNumSequenceNum, closedIndicator);
        newArc.setDrawingGroupName(chassisObject.getChassisObjectName()  + '.' + config.ACCESSORY_TYPES.BODY );
        if (body.getType() === config.BODY_TYPES.SERVICE || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.DROPSIDE) {
            newArc.setBringToFront(true);
            //newLine.setBypassHitAreaSort(true);
        }
        
        if (useBodyDrawingObjectsArray) {
            return newArc;
        } else {
            newArc.setDrawingOrder(5000);
            drawingObjectsArray.push(newArc);
        }
    }

    function addBodyCircle(centreX, centreY, radius, circleType, dashed) {
        var newCircle = new Circle(bodyOriginX + centreX, bodyOriginY + centreY, radius, circleType);
        newCircle.setDrawingGroupName(chassisObject.getChassisObjectName()  + '.' + config.ACCESSORY_TYPES.BODY );
        if (dashed !== undefined && dashed === true) {
            newCircle.setDashed(true);
            newCircle.setDashLength(5);
        }
        if (body.getType() === config.BODY_TYPES.SERVICE || body.getType() === config.BODY_TYPES.BEAVERTAIL || body.getType() === config.BODY_TYPES.DROPSIDE) {
            newCircle.setBringToFront(true);
            //newLine.setBypassHitAreaSort(true);
        }
        if (useBodyDrawingObjectsArray) {
            return newCircle;
        } else {
            newCircle.setDrawingOrder(5000);
            drawingObjectsArray.push(newCircle);
        }
    }

    function addSubframeForBody(length) {
        /*.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, 0, 0, length, 0))
    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, length, 0, length, _SubframeHeight))
    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, length, _SubframeHeight, 0, _SubframeHeight))
    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, 0, _SubframeHeight, 0, 0))
    */
        if (body.getType() !== config.BODY_TYPES.BEAVERTAIL && body.getType() !== config.BODY_TYPES.DROPSIDE) {
            addBodyLine(0, 0, length, 0);
            //if (body.getType() !== config.BODY_TYPES.BEAVERTAIL) {
            addBodyLine(length, 0, length, subframeHeight);
            //}
            addBodyLine(length, subframeHeight, 0, subframeHeight);
            addBodyLine(0, subframeHeight, 0, 0);
        } else {
            bodyDrawingObjects.push(addBodyLine(0, 0, length, 0, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //if (body.getType() !== config.BODY_TYPES.BEAVERTAIL) {
            bodyDrawingObjects.push(addBodyLine(length, 0, length, subframeHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            //}
            bodyDrawingObjects.push(addBodyLine(length, subframeHeight, 0, subframeHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            bodyDrawingObjects.push(addBodyLine(0, subframeHeight, 0, 0, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
        }
    }

    function addSubstructureForBody(length) {
        /*
        .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, length, _SubframeHeight, length, _SubframeHeight + _SubstructureHeight))
    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, length, _SubframeHeight + _SubstructureHeight, 0, _SubframeHeight + _SubstructureHeight))
    .Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, 0, _SubframeHeight + _SubstructureHeight, 0, _SubframeHeight))
        */
        if (body.getType() !== config.BODY_TYPES.BEAVERTAIL && body.getType() !== config.BODY_TYPES.DROPSIDE) {
            //var topHookbeamCutBack = 0;
            //if (body.getType() === config.BODY_TYPES.HOOKLIFT) {
            //    topHookbeamCutBack = ((Math.tan(hookbeamTaperAngle / (180 / Math.PI)) * hookbeamHeight));
                
            //}
            addBodyLine(length, subframeHeight, length, subframeHeight + substructureHeight);
            addBodyLine(length, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight);
            addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight);
            addBodyLine(0, subframeHeight, length, subframeHeight);
            //lse if (body.getType() !== config.BODY_TYPES.BEAVERTAIL) {
            //addBodyLine(length, subframeHeight, length, subframeHeight + substructureHeight);
            //}

            
            
        } else {
            bodyDrawingObjects.push(addBodyLine(length, subframeHeight, length, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            bodyDrawingObjects.push(addBodyLine(length, subframeHeight + substructureHeight, 0, subframeHeight + substructureHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            bodyDrawingObjects.push(addBodyLine(0, subframeHeight + substructureHeight, 0, subframeHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
            bodyDrawingObjects.push(addBodyLine(0, subframeHeight, length, subframeHeight, false, bodyLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE"));
        }

    }

    function drawVanBody() {
        //numberOfBodyRibs = ((ActualExternalHeight - _SubframeHeight) - _RoofThickness * 2) / bodyRibSpacer
        numberOfBodyRibs = ((actualExternalHeight - subframeHeight) - roofThickness * 2) / bodyRibSpacer;


        //' Add subframe.
        //AddSubframeForBody(ActualLength)
        addSubframeForBody(actualLength);

        //' Add substructure.
        //AddSubStructureForBody(ActualLength)
        addSubstructureForBody(actualLength);

        /*
        ' Draw outer bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                            0,
                                                    _SubframeHeight + _SubstructureHeight,
                                                    ActualLength,
                                                    _SubframeHeight + _SubstructureHeight))
        
        */
        addBodyLine(0, subframeHeight + substructureHeight, actualLength, subframeHeight + substructureHeight);

        /*
        ' Draw outer rear height line.
            DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                            ActualLength,
                                                    _SubframeHeight + _SubstructureHeight,
                                                    ActualLength,
                                                    ActualExternalHeight))
        
        */
        addBodyLine(actualLength, subframeHeight + substructureHeight, actualLength, actualExternalHeight);


        /*
        ' Draw outer roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                            ActualLength,
                                    ActualExternalHeight,
                                    0,
                                    ActualExternalHeight))    
        */
        addBodyLine(actualLength, actualExternalHeight, 0, actualExternalHeight);

        /*
        ' Draw outer front height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                            0,
                                                    ActualExternalHeight,
                                                    0,
                                                    _SubframeHeight + SubstructureHeight))
        
        */
        addBodyLine(0, actualExternalHeight, 0, subframeHeight + substructureHeight);

        /*
        
        ' Draw inner bottom line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                            _HeadboardThickness,
                                                    _SubframeHeight + _SubstructureHeight + _RoofThickness,
                                                    ActualLength - _TailboardThickness,
                                                    _SubframeHeight + _SubstructureHeight + _RoofThickness))
        
        */
        //addBodyLine(headboardThickness, subframeHeight + substructureHeight + roofThickness, actualLength - tailboardThickness, subframeHeight + substructureHeight + roofThickness);

        /*
        ' Draw inner rear height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                            ActualLength - _TailboardThickness,
                                                    _SubframeHeight + _SubstructureHeight + _RoofThickness,
                                                    ActualLength - _TailboardThickness,
                                                    ActualExternalHeight - _RoofThickness))    
        */
        addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, actualExternalHeight - roofThickness);

        /*
        ' Draw inner roof line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                            ActualLength - _TailboardThickness,
                                                    ActualExternalHeight - _RoofThickness,
                                                    _HeadboardThickness,
                                                    ActualExternalHeight - _RoofThickness))        
        */
        addBodyLine(actualLength - tailboardThickness, actualExternalHeight - roofThickness, headboardThickness, actualExternalHeight - roofThickness);

        /*
            ' Draw inner front height line.
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                            _HeadboardThickness,
                                                    ActualExternalHeight - _RoofThickness,
                                                    _HeadboardThickness,
                                                    _SubframeHeight + _SubstructureHeight + _RoofThickness))       
        */
        addBodyLine(headboardThickness, actualExternalHeight - roofThickness, headboardThickness, subframeHeight + substructureHeight);

        /*

            ' Removed until SP1
            '' Draw body ribs.
            'For i As Integer = 1 To numberOfBodyRibs - 1
            */

        /*
            '    DrawingObjects.Add(New FileLoadingDrawingLine(_HeadboardThickness, _
            '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i, _
            '                                                  ActualLength - TailboardThickness, _
            '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i))

            '    DrawingObjects.Add(New FileLoadingDrawingLine(_HeadboardThickness, _
            '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i + bodyRibHeight, _
            '                                                  ActualLength - TailboardThickness, _
            '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i + bodyRibHeight))

            'Next
            */

        /*
            ' Draw side door if applicable.
            If _SideDoor Then
            */
        if (body.getSideDoors() === true) {
            var sideDoorFrontToDoor = body.getSideDoorFrontToDoor(),
                sideDoorWidthPerDoor = body.getSideDoorWidthPerDoor(),
                sideDoorHeight = body.getSideDoorHeight();
            /*
                    ' Draw front line.
                    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                        _SideDoorFrontToDoor,
                                                                    _SubframeHeight + SubstructureHeight,
                                                                    _SideDoorFrontToDoor,
                                                                    _SubframeHeight + SubstructureHeight + _SideDoorHeight))
                                                                    */
            addBodyLine(sideDoorFrontToDoor, subframeHeight + substructureHeight, sideDoorFrontToDoor, subframeHeight + substructureHeight + sideDoorHeight);
            /*
                    Select Case _SideDoorType
                    */
            switch (body.getSideDoorType()) {
                /*
                            Case SideDoorTypes.SingleDoor
                            */
                case config.SIDE_DOOR_TYPES.SINGLE:

                    /*
                                ' Draw top line.
                                DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                            _SideDoorFrontToDoor,
                                                                                _SubframeHeight + SubstructureHeight + _SideDoorHeight,
                                                                                _SideDoorFrontToDoor + _SideDoorWidthPerDoor,
                                                                                _SubframeHeight + SubstructureHeight + _SideDoorHeight))
                                                                                */
                    addBodyLine(sideDoorFrontToDoor,
                        subframeHeight + substructureHeight + sideDoorHeight,
                        sideDoorFrontToDoor + sideDoorWidthPerDoor,
                        subframeHeight + substructureHeight + sideDoorHeight);
                    /*
                                    ' Draw rear line.
                                    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _SideDoorFrontToDoor + _SideDoorWidthPerDoor,
                                                                                    _SubframeHeight + SubstructureHeight + _SideDoorHeight,
                                                                                    _SideDoorFrontToDoor + _SideDoorWidthPerDoor,
                                                                                    _SubframeHeight + SubstructureHeight))
                                                                                    */
                    addBodyLine(sideDoorFrontToDoor + sideDoorWidthPerDoor,
                        subframeHeight + substructureHeight + sideDoorHeight,
                        sideDoorFrontToDoor + sideDoorWidthPerDoor,
                        subframeHeight + substructureHeight);
                    break;


                /*
                            Case SideDoorTypes.DoubleDoor
                            */
                case config.SIDE_DOOR_TYPES.DOUBLE:
                    /*
                                    ' Draw top line.
                                    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _SideDoorFrontToDoor,
                                                                                    _SubframeHeight + SubstructureHeight + _SideDoorHeight,
                                                                                    _SideDoorFrontToDoor + (_SideDoorWidthPerDoor * 2),
                                                                                    _SubframeHeight + SubstructureHeight + _SideDoorHeight))
                                                                                    */
                    addBodyLine(sideDoorFrontToDoor,
                        subframeHeight + substructureHeight + sideDoorHeight,
                        sideDoorFrontToDoor + (sideDoorWidthPerDoor * 2),
                        subframeHeight + substructureHeight + sideDoorHeight);
                    /*
                                    ' Draw middle line.
                                    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _SideDoorFrontToDoor + _SideDoorWidthPerDoor,
                                                                                    _SubframeHeight + SubstructureHeight + _SideDoorHeight,
                                                                                    _SideDoorFrontToDoor + _SideDoorWidthPerDoor,
                                                                                    _SubframeHeight + SubstructureHeight))
                                                                                    */
                    addBodyLine(sideDoorFrontToDoor + sideDoorWidthPerDoor,
                        subframeHeight + substructureHeight + sideDoorHeight,
                        sideDoorFrontToDoor + sideDoorWidthPerDoor,
                        subframeHeight + substructureHeight);
                    /*
                                    ' Draw rear line.
                                    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _SideDoorFrontToDoor + (_SideDoorWidthPerDoor * 2),
                                                                                    _SubframeHeight + SubstructureHeight + _SideDoorHeight,
                                                                                    _SideDoorFrontToDoor + (_SideDoorWidthPerDoor * 2),
                                                                                    _SubframeHeight + SubstructureHeight))
                                                                                    */
                    addBodyLine(sideDoorFrontToDoor + (sideDoorWidthPerDoor * 2),
                        subframeHeight + substructureHeight + sideDoorHeight,
                        sideDoorFrontToDoor + (sideDoorWidthPerDoor * 2),
                        subframeHeight + substructureHeight);
                    break;
            }
            /*
                    End Select
                    */
        }
        /*
                End If
    
            */
    }
}

function populateDrawingObjectsForTemplateCrane(crane, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateCraneSideView(crane, chassisObject);
    } else {
        populateDrawingObjectsForTemplateCraneTopView(crane, chassisObject);
    }
}

function populateDrawingObjectsForTemplateCraneTopView(crane, chassisObject) {
    var craneDrawingObjectArray = [];

    var craneLayerNum = 12, objectNum = 600000, blockName = 'CRANE1', sequenceNum = 1;

    var originX, originY;

    var craneBoarder = 150, cornerNib = 5/scalingFactor;

    
    //switch (chassisObject.getChassisObjectName()) {
    //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //        originX = chassisObject.getBumperToBackOfCab() + crane.getHorizontalGap() + chassisObject.getPortionOfOverallLengthOverhangingFront();
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + crane.getHorizontalGap();
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + crane.getHorizontalGap();                    
    //        break;
    //}
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.CRANE);
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObject) +crane.getFromLeftOfVehicleRearWidth();
        
    } else {
        originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObject) + crane.getFromLeftOfVehicleRearWidth();
    }
    
    
    addCraneLine(0, 0, 0, crane.getWidth(), craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addCraneLine(0, crane.getWidth(), crane.getLength(), crane.getWidth(), craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addCraneLine(crane.getLength(), crane.getWidth(), crane.getLength(), 0, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addCraneLine(crane.getLength(), 0, 0, 0, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

    objectNum++;
    sequenceNum = 1;
    if (crane.getWidth() > 300 && crane.getLength() > 300) {
        addCraneLine(0 + craneBoarder, 0 + craneBoarder + cornerNib, 0 + craneBoarder, crane.getWidth() - (craneBoarder + cornerNib), craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
        addCraneLine(0 + craneBoarder, crane.getWidth() - (craneBoarder + cornerNib), 0 + craneBoarder + cornerNib, crane.getWidth() - craneBoarder, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

        addCraneLine(0 + craneBoarder + cornerNib, crane.getWidth() - craneBoarder, crane.getLength() - (craneBoarder + cornerNib), crane.getWidth() - craneBoarder, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
        addCraneLine(crane.getLength() - (craneBoarder + cornerNib), crane.getWidth() - craneBoarder, crane.getLength() - craneBoarder, crane.getWidth() - (craneBoarder + cornerNib), craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

        addCraneLine(crane.getLength() - craneBoarder, crane.getWidth() - (craneBoarder + cornerNib), crane.getLength() - craneBoarder, 0 + craneBoarder + cornerNib, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
        addCraneLine(crane.getLength() - craneBoarder, 0 + craneBoarder + cornerNib, crane.getLength() - (craneBoarder + cornerNib), 0 + craneBoarder, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

        addCraneLine(crane.getLength() - (craneBoarder + cornerNib), 0 + craneBoarder, 0 + craneBoarder + cornerNib, 0 + craneBoarder, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
        addCraneLine(0 + craneBoarder + cornerNib, 0 + craneBoarder, 0 + craneBoarder, 0 + craneBoarder + cornerNib, craneLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    }
    //if (vehicleTransformation !== null) {
    //    applyTransformationsToDrawingObjects(craneDrawingObjectArray, vehicleTransformation);
    //}
    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(craneDrawingObjectArray, vehicleTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        applyTransformationsToDrawingObjects(craneDrawingObjectArray, trailerTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        applyTransformationsToDrawingObjects(craneDrawingObjectArray, trailer2Transformation);
    }
    craneDrawingObjectArray.sort(sortDrawingObjects);
    craneDrawingObjectArray = groupDrawingObjects(craneDrawingObjectArray);
    addToMainDrawingObjectsArray(craneDrawingObjectArray);

    function addCraneLine(startX, startY, endX, endY,  layerNum, blockName, objectNumSequenceNum, closedIndicator) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY, layerNum, blockName, objectNumSequenceNum, closedIndicator);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE);
        craneDrawingObjectArray.push(newLine);
    }
}

function getCraneOrigin(crane, chassisObject) {

    var craneOriginX,
        craneOriginY;

    if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {

        craneOriginX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.CRANE);

        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                //craneOriginX = chassisObject.getPortionOfOverallLengthOverhangingFront() + curVehicle.getBumperToBackOfCab() + crane.getHorizontalGap();
                craneOriginY = chassisObject.getChassisHeightMax();
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                //craneOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + crane.getHorizontalGap();
                craneOriginY = chassisObject.getChassisHeight();
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                //craneOriginX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + crane.getHorizontalGap();
                craneOriginY = chassisObject.getChassisHeight();
                break;
        }
        craneOriginY += crane.getAboveBelowChassis();
    } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
        craneOriginX = 0;
        craneOriginY = 0;
    }

    return { x: craneOriginX, y: craneOriginY };
}

function populateDrawingObjectsForTemplateCraneSideView(crane, chassisObject) {
    /*
    Dim craneBaseHeight As Single = _Height * 0.1
Dim craneHeightBottom As Single = _Height * 0.25
Dim craneWidthBottom As Single = _Length * 0.4
Dim craneWidthTop As Single = _Length * 0.25
Dim craneStartpoint As Single = _Length * 0.2
Dim craneStabliserHeight As Single = 700
Dim craneStabliserWidth As Single = 120
Dim craneStabliserFootHeight As Single = 100
Dim craneBoxWidth As Single = 100
Dim craneBoxHeight As Single = 500
*/
    var height = crane.getHeight(), 
        length = crane.getLength(),
        subframeHeight = crane.getSubframeHeight();

    var craneBaseHeight = height * 0.1,
        craneHeightBottom = height * 0.25,
        craneWidthBottom = length * 0.4,
        craneWidthTop = length * 0.25,
        craneStartPoint = length * 0.2,
        craneStabliserHeight = 700,
        craneStabliserWidth = 120,
        craneStabliserFootHeight = 100,
        craneBoxWidth = 100,
        craneBoxHeight = 500;

        var craneOrigin = getCraneOrigin(crane, chassisObject);
    var craneOriginX = craneOrigin.x,
        craneOriginY = craneOrigin.y;


    
    /*
'clear out any existing objects before drawing the template
DrawingObjects.Clear(clearScreenOnly)

'*****************************************************************************
*/


    /*
'subframe

'subframe left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                0, _
                                                0, _
                                                _SubframeHeight))
                                                */
    addCraneLine(0, 0, 0, subframeHeight);
    /*
'top of subframe
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                _SubframeHeight, _
                                                _Length, _
                                                _SubframeHeight))
                                                */
    addCraneLine(0, subframeHeight, length, subframeHeight);
    /*
'bottom of subframe
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                0, _
                                                _Length, _
                                                0))
                                                */
    addCraneLine(0, 0, length, 0);
    /*
'subframe right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        _Length, _
                                                _SubframeHeight, _
                                                _Length, _
                                                0))
                                                */
    addCraneLine(length, subframeHeight, length, 0);
    /*
'*****************************************************************************

'*****************************************************************************
'crane - base section

'crane base left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                _SubframeHeight, _
                                                0, _
                                                _SubframeHeight + craneBaseHeight))
                                                */
    addCraneLine(0, subframeHeight, 0, subframeHeight + craneBaseHeight);
    /*
'top of crane base
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                _SubframeHeight + craneBaseHeight, _
                                                _Length - (craneBaseHeight / 2), _
                                                _SubframeHeight + craneBaseHeight))
                                                */
    addCraneLine(0, subframeHeight + craneBaseHeight, length - (craneBaseHeight / 2), subframeHeight + craneBaseHeight);
    /*
'crane base right vertical
'DrawingObjects.Add(New FileLoadingDrawingLine(_Length, _
'                                              _SubframeHeight + craneBaseHeight, _
'                                              _Length, _
'                                              _SubframeHeight))
*/
    
    /*
'tank outer
DrawingObjects.Add(New UtilDrawingObjectCircle(_Length - (craneBaseHeight / 2), _
                                            _SubframeHeight + (craneBaseHeight / 2), _
                                            craneBaseHeight / 2, _
                                            UtilDrawingObjectCircle.CircleTypes.Outer))
                                            */
    addCraneCircle(length - (craneBaseHeight / 2), subframeHeight + (craneBaseHeight / 2), craneBaseHeight / 2, CIRCLE_TYPE.OTHER);
    /*
'tank inner
DrawingObjects.Add(New UtilDrawingObjectCircle(_Length - (craneBaseHeight / 2), _
                                            _SubframeHeight + (craneBaseHeight / 2), _
                                            craneBaseHeight / 2 * 0.6, _
                                            UtilDrawingObjectCircle.CircleTypes.Inner))
                                            */
    addCraneCircle(length - (craneBaseHeight / 2), subframeHeight + (craneBaseHeight / 2), craneBaseHeight / 2 * 0.6, CIRCLE_TYPE.OTHER);
    /*
'*****************************************************************************

'*****************************************************************************
'crane - mid section

'crane left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStartpoint, _
                                                _SubframeHeight + craneBaseHeight, _
                                                craneStartpoint, _
                                                _SubframeHeight + craneBaseHeight + craneHeightBottom))
                                                */
    addCraneLine(craneStartPoint, subframeHeight + craneBaseHeight, craneStartPoint, subframeHeight + craneBaseHeight + craneHeightBottom);
    /*
'top of crane
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStartpoint, _
                                                _SubframeHeight + craneBaseHeight + craneHeightBottom, _
                                                craneStartpoint + craneWidthBottom, _
                                                _SubframeHeight + craneBaseHeight + craneHeightBottom))
                                                */
    addCraneLine(craneStartPoint, subframeHeight + craneBaseHeight + craneHeightBottom, craneStartPoint + craneWidthBottom, subframeHeight + craneBaseHeight + craneHeightBottom);
    /*
'crane right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStartpoint + craneWidthBottom, _
                                                _SubframeHeight + craneBaseHeight + craneHeightBottom, _
                                                craneStartpoint + craneWidthBottom, _
                                                _SubframeHeight + craneBaseHeight))
                                                */
    addCraneLine(craneStartPoint + craneWidthBottom, subframeHeight + craneBaseHeight + craneHeightBottom, craneStartPoint + craneWidthBottom, subframeHeight + craneBaseHeight);
    /*
'*****************************************************************************

'*****************************************************************************
'crane - top section

'crane left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStartpoint, _
                                                _SubframeHeight + craneBaseHeight + craneHeightBottom, _
                                                craneStartpoint + ((craneWidthBottom - craneWidthTop) / 2), _
                                                _SubframeHeight + _Height))
                                                */
    addCraneLine(craneStartPoint, subframeHeight + craneBaseHeight + craneHeightBottom, craneStartPoint + ((craneWidthBottom - craneWidthTop) / 2), subframeHeight + height);
    /*
'top of crane
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStartpoint + ((craneWidthBottom - craneWidthTop) / 2), _
                                                _SubframeHeight + _Height, _
                                                craneStartpoint + craneWidthBottom - ((craneWidthBottom - craneWidthTop) / 2), _
                                                _SubframeHeight + _Height))
                                                */
    addCraneLine(craneStartPoint + ((craneWidthBottom - craneWidthTop) / 2), subframeHeight + height, craneStartPoint + craneWidthBottom - ((craneWidthBottom - craneWidthTop) / 2), subframeHeight + height);
    /*
'crane right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStartpoint + craneWidthBottom - ((craneWidthBottom - craneWidthTop) / 2), _
                                                _SubframeHeight + _Height, _
                                                craneStartpoint + craneWidthBottom, _
                                                _SubframeHeight + craneBaseHeight + craneHeightBottom))
                                                */
    addCraneLine(craneStartPoint + craneWidthBottom - ((craneWidthBottom - craneWidthTop) / 2), subframeHeight + height, craneStartPoint + craneWidthBottom, subframeHeight + craneBaseHeight + craneHeightBottom);
    /*
'*****************************************************************************

'*****************************************************************************
'crane - stabliser

'crane stabliser left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                _SubframeHeight + craneBaseHeight, _
                                                0, _
                                                _SubframeHeight + craneBaseHeight - craneStabliserHeight))
                                                */
    addCraneLine(0, subframeHeight + craneBaseHeight, 0, subframeHeight + craneBaseHeight - craneStabliserHeight);
    /*
'crane stabliser base
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                _SubframeHeight + craneBaseHeight - craneStabliserHeight, _
                                                craneStabliserWidth, _
                                                _SubframeHeight + craneBaseHeight - craneStabliserHeight))
                                                */
    addCraneLine(0, subframeHeight + craneBaseHeight - craneStabliserHeight, craneStabliserWidth, subframeHeight + craneBaseHeight - craneStabliserHeight);
    /*
'crane stabliser right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStabliserWidth, _
                                                _SubframeHeight + craneBaseHeight - craneStabliserHeight, _
                                                craneStabliserWidth, _
                                                _SubframeHeight + craneBaseHeight))
                                                */
    addCraneLine(craneStabliserWidth, subframeHeight + craneBaseHeight - craneStabliserHeight, craneStabliserWidth, subframeHeight + craneBaseHeight);
    /*
'crane stabliser foot left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStabliserWidth * 0.15, _
                                                _SubframeHeight + craneBaseHeight - craneStabliserHeight, _
                                                craneStabliserWidth * 0.15, _
                                                _SubframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight)))
                                                */
    addCraneLine(craneStabliserWidth * 0.15, subframeHeight + craneBaseHeight - craneStabliserHeight, craneStabliserWidth * 0.15, subframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight));
    /*
'crane stabliser foot base
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        0, _
                                                _SubframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight), _
                                                craneStabliserWidth, _
                                                _SubframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight)))
                                                */
    addCraneLine(0, subframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight), craneStabliserWidth, subframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight));
    /*
'crane stabliser foot right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        craneStabliserWidth * 0.85, _
                                                _SubframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight), _
                                                craneStabliserWidth * 0.85, _
                                                _SubframeHeight + craneBaseHeight - craneStabliserHeight))
                                                */
    addCraneLine(craneStabliserWidth * 0.85, subframeHeight + craneBaseHeight - (craneStabliserHeight + craneStabliserFootHeight), craneStabliserWidth * 0.85, subframeHeight + craneBaseHeight - craneStabliserHeight);
    /*
'*****************************************************************************

'*****************************************************************************
'crane - stabliser

'crane box left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        _Length - craneBoxWidth - (craneBaseHeight / 2), _
                                                _SubframeHeight + craneBaseHeight, _
                                                _Length - craneBoxWidth - (craneBaseHeight / 2), _
                                                _SubframeHeight + craneBaseHeight + craneBoxHeight))
                                                */
    addCraneLine(length - craneBoxWidth - (craneBaseHeight / 2), subframeHeight + craneBaseHeight, length - craneBoxWidth - (craneBaseHeight / 2), subframeHeight + craneBaseHeight + craneBoxHeight);
    /*
'crane box top
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        _Length - craneBoxWidth - (craneBaseHeight / 2), _
                                                _SubframeHeight + craneBaseHeight + craneBoxHeight, _
                                                _Length - (craneBaseHeight / 2), _
                                                _SubframeHeight + craneBaseHeight + craneBoxHeight))
                                                */
    addCraneLine(length - craneBoxWidth - (craneBaseHeight / 2), subframeHeight + craneBaseHeight + craneBoxHeight, length - (craneBaseHeight / 2), subframeHeight + craneBaseHeight + craneBoxHeight);
    /*
'crane box right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                        _Length - (craneBaseHeight / 2), _
                                                _SubframeHeight + craneBaseHeight + craneBoxHeight, _
                                                _Length - (craneBaseHeight / 2), _
                                                _SubframeHeight + craneBaseHeight))
                                                */
    addCraneLine(length - (craneBaseHeight / 2), subframeHeight + craneBaseHeight + craneBoxHeight, length - (craneBaseHeight / 2), subframeHeight + craneBaseHeight);
    /*
'*****************************************************************************
    */

    

    function addCraneLine(startX, startY, endX, endY) {
        var newLine = new Line(craneOriginX + startX, craneOriginY + startY, craneOriginX + endX, craneOriginY + endY);
        if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
            newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE);
            if (curVehicle.hasServiceBody()) {
                if (isItemObscured(chassisObject, crane, craneOriginY, craneOriginX)) {
                    dashItemAndBringForward(newLine);
                }
            }
        } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
            newLine.setDrawingGroupName('configurationModal' + '.' + config.ACCESSORY_TYPES.CRANE);
        }

        if (crane.getIsPinned()) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }

        drawingObjectsArray.push(newLine);
    }

    function addCraneCircle(centreX, centreY, radius, circleType) {
        var newCircle = new Circle(craneOriginX + centreX, craneOriginY + centreY, radius, circleType);
        
        if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.FULL_RIG) {
            newCircle.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.CRANE);
            if (curVehicle.hasServiceBody()) {
                if (isItemObscured(chassisObject, crane, craneOriginY, craneOriginX)) {
                    dashItemAndBringForward(newCircle);
                }
            }
        } else if (curRenderMode === config.GRAPHICS_ENGINE_RENDER_MODE.ITEM_ONLY) {
            newCircle.setDrawingGroupName('configurationModal' + '.' + config.ACCESSORY_TYPES.CRANE);
        }
        if (crane.getIsPinned()) {
            newCircle.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        
        drawingObjectsArray.push(newCircle);
    } 
}

function populateDrawingObjectsForTemplateOther(other, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateOtherSideView(other, chassisObject);
    } else {
        populateDrawingObjectsForTemplateOtherTopView(other, chassisObject);
    }
}

function populateDrawingObjectsForTemplateOtherTopView(other, chassisObject) {
    var otherDrawingObjectArray = [];

    var otherLayerNum = 12, objectNum = 900000, blockName = 'OTHER1', sequenceNum = 1;

    var originX, originY;

    

    //switch (chassisObject.getChassisObjectName()) {
    //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //        originX = chassisObject.getBumperToBackOfCab() + other.getHorizontalGap() + chassisObject.getPortionOfOverallLengthOverhangingFront();
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + other.getHorizontalGap();
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + other.getHorizontalGap();
    //        break;
    //}
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.OTHER, other.getId());
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObject) + other.getFromLeftOfVehicleRearWidth();
    } else {
        originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObject) + other.getFromLeftOfVehicleRearWidth();
    }
    
    /*
        'left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                0, _
                                                0, _
                                                _Height))
                                                */
    addOtherLine(0, 0, 0, other.getWidth(), otherLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    /*
'top
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                _Height, _
                                                _Length, _
                                                _Height))
                                                */
    addOtherLine(0, other.getWidth(), other.getLength(), other.getWidth(), otherLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    /*
'right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _Length, _
                                                _Height, _
                                                _Length, _
                                                0))
                                                */
    addOtherLine(other.getLength(), other.getWidth(), other.getLength(), 0, otherLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    /*
'bottom
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _Length, _
                                                0, _
                                                0, _
                                                0))
    
    */
    addOtherLine(other.getLength(), 0, 0, 0, otherLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

    //if (vehicleTransformation !== null) {
    //    applyTransformationsToDrawingObjects(otherDrawingObjectArray, vehicleTransformation);
    //}

    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(otherDrawingObjectArray, vehicleTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        applyTransformationsToDrawingObjects(otherDrawingObjectArray, trailerTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        applyTransformationsToDrawingObjects(otherDrawingObjectArray, trailer2Transformation);
    }
    otherDrawingObjectArray.sort(sortDrawingObjects);
    otherDrawingObjectArray = groupDrawingObjects(otherDrawingObjectArray);
    addToMainDrawingObjectsArray(otherDrawingObjectArray);

    function addOtherLine(startX, startY, endX, endY, layerNum, blockName, objectNumSequenceNum, cloasedIndicator) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY, layerNum, blockName, objectNumSequenceNum, cloasedIndicator);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + other.getId());

        otherDrawingObjectArray.push(newLine);
    }
}

function addToMainDrawingObjectsArray(arrayToAdd) {
    arrayToAdd.forEach(function (drawingObject) {
        drawingObjectsArray.push(drawingObject);
    });
}

function applyTransformationsToDrawingObjects(drawingObjectsArray, transformation) {
    drawingObjectsArray.forEach(function (drawingObject) {
        switch (drawingObject.type) {
            case 'line':
                applyTransformationToPoint(drawingObject.startPoint, transformation);
                applyTransformationToPoint(drawingObject.endPoint, transformation);

                break;
            case 'poly':
                drawingObject.pointsArray.forEach(function (point) {
                    applyTransformationToPoint(point, transformation);
                });
                break;
            case 'circle':
                applyTransformationToPoint(drawingObject.centre, transformation);
                break;
            case 'arc':
                applyTransformationToPoint(drawingObject.centre, transformation);
                drawingObject.startAngle = (drawingObject.startAngle * 180 / Math.PI) + transformation.angle;
                drawingObject.refresh();
                break;
        }
    });
}

function applyRotationToDrawingObjects(drawingObjectsArray, transformation) {
    drawingObjectsArray.forEach(function (drawingObject) {
        switch (drawingObject.type) {
            case 'line':
                applyRotationToPoint(drawingObject.startPoint, transformation);
                applyRotationToPoint(drawingObject.endPoint, transformation);

                break;
            case 'poly':
                drawingObject.pointsArray.forEach(function (point) {
                    applyRotationToPoint(point, transformation);
                });
                break;
            case 'circle':
                applyRotationToPoint(drawingObject.centre, transformation);
                break;
            case 'arc':
                applyRotationToPoint(drawingObject.centre, transformation);
                //drawingObject.startAngle = (drawingObject.startAngle * 180 / Math.PI) + transformation.angle;
                //drawingObject.refresh();
                break;
        }
    });
}

function applyTransformationToPoint(point, transformation) {
    translatePoint(point, transformation.anchorX, transformation.anchorY, transformation.originX, transformation.originY);
    translatePoint(point, transformation.originX, transformation.originY, transformation.referencePointX, -transformation.referencePointY);

    var rotatedPointPointArr = rotate(transformation.referencePointX, -transformation.referencePointY, point.x, point.y, transformation.angle);
    

    point.x = rotatedPointPointArr[0];
    point.y = rotatedPointPointArr[1];

}

function applyRotationToPoint(point, transformation) {
    
    var rotatedPointPointArr = rotate(transformation.centreX, transformation.centreY, point.x, point.y, transformation.angle);


    point.x = rotatedPointPointArr[0];
    point.y = rotatedPointPointArr[1];

}

function translatePoint(point, originX, originY, targetX, targetY) {
    point.x += (targetX - originX);
    point.y += (targetY - originY);
}

function translateLine(line, originX, originY, targetX, targetY) {
    line.startPoint.x += (targetX - originX);
    line.startPoint.y += (targetY - originY);
    line.endPoint.x += (targetX - originX);
    line.endPoint.y += (targetY - originY);
}
/*
function rotate(cx, cy, x, y, angle) {
    var radians = (Math.PI / 180) * angle,
        cos = Math.cos(radians),
        sin = Math.sin(radians),
        nx = (cos * (x - cx)) + (sin * (y - cy)) + cx,
        ny = (cos * (y - cy)) - (sin * (x - cx)) + cy;
    return [nx, ny];
}
*/
function populateDrawingObjectsForTemplateOtherSideView(other, chassisObject) {

    var originX, originY;
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.OTHER, other.getId());
    switch (chassisObject.getChassisObjectName()) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            //originX = chassisObject.getBumperToBackOfCab() + other.getHorizontalGap() + chassisObject.getPortionOfOverallLengthOverhangingFront();
            originY = chassisObject.getChassisHeightMax() + other.getVerticalGap();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            //originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + other.getHorizontalGap();
            originY = chassisObject.getChassisHeight() + other.getVerticalGap();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            //originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + other.getHorizontalGap();
            originY = chassisObject.getChassisHeight() + other.getVerticalGap();
            break;
    }

    /*
        'left vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                0, _
                                                0, _
                                                _Height))
                                                */
    addOtherLine(0, 0, 0, other.getHeight());
    /*
'top
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                _Height, _
                                                _Length, _
                                                _Height))
                                                */
    addOtherLine(0, other.getHeight(), other.getLength(), other.getHeight());
    /*
'right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _Length, _
                                                _Height, _
                                                _Length, _
                                                0))
                                                */
    addOtherLine(other.getLength(), other.getHeight(), other.getLength(), 0);
    /*
'bottom
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _Length, _
                                                0, _
                                                0, _
                                                0))
    
    */
    addOtherLine(other.getLength(), 0, 0, 0);

    if(other.getAccessorySubType() === config.ACCESSORY_TYPES.TOOLBOX) {
        var innerBuffer = 75;
        var widthBuffer = innerBuffer,
            heightBuffer = innerBuffer,
            lockRadius = 30,
            lockTopBuffer = 60,
            lockSideBuffer = 150,
            handleLineLengthOutsideCircle = 10;
        // left line
        addOtherLine(0 + widthBuffer, 0 + heightBuffer, 0 + widthBuffer, other.getHeight() - heightBuffer);
        // top line
        addOtherLine(0 + widthBuffer, other.getHeight() - heightBuffer, other.getLength() - widthBuffer, other.getHeight() - heightBuffer);
        // right line
        addOtherLine(other.getLength() - widthBuffer, other.getHeight() - heightBuffer, other.getLength() - widthBuffer, 0 + heightBuffer);
        // bottom line
        addOtherLine(other.getLength() - widthBuffer, 0 + heightBuffer, 0 + widthBuffer, 0 + heightBuffer);
        
        // Add left lock
        addTooboxLock(0 + widthBuffer + lockSideBuffer, other.getHeight() - heightBuffer - lockTopBuffer, lockRadius, handleLineLengthOutsideCircle);

        // Add right lock
        addTooboxLock(other.getLength() - widthBuffer - lockSideBuffer, other.getHeight() - heightBuffer - lockTopBuffer, lockRadius, handleLineLengthOutsideCircle);
    }

    /**
     * Add line to drawing objects array linked to other object
     * @param {number} startX - X coordinate for the start of the line
     * @param {number} startY - Y coordinate for the start of the line
     * @param {number} endX - X coordinate for the end of the line
     * @param {number} endY - Y coordinate for the end of the line
     */
    function addOtherLine(startX, startY, endX, endY) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + other.getId());
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, other, originY, originX)) {
                dashItemAndBringForward(newLine);
            }
        }
        if (other.getIsPinned()) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }

    /**
     * Add circle to drawing objects array linked to other object
     * @param {number} centreX - X coordinate for the centre of the circle
     * @param {number} centreY - Y coordinate for the centre of the circle
     * @param {number} radius - Radius of the circle
     * @param {string} circleType - Circle type from CIRCLE_TYPE enum
     */
    function addOtherCircle(centreX, centreY, radius, circleType) {
        var newCircle = new Circle(originX + centreX, originY + centreY, radius, circleType);
        newCircle.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.OTHER + '.' + other.getId());
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, other, originY, originX)) {
                dashItemAndBringForward(newCircle);
            }
            // newCircle.setDrawingOrder(6000);
        }
        if (other.getIsPinned()) {
            newCircle.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newCircle);
    }

    function addTooboxLock(centreX, centreY, radius, offsetBuffer) {
        var offset = (radius * 2) + offsetBuffer;
        addOtherCircle(centreX, centreY, radius, CIRCLE_TYPE.OTHER);
        addOtherLine(centreX - offset, centreY, centreX + offset, centreY);
    }
}

function populateDrawingObjectsForTemplateFridge(fridge, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateFridgeSideView(fridge, chassisObject);
    } else {
        populateDrawingObjectsForTemplateFridgeTopView(fridge, chassisObject);
    }
}

function populateDrawingObjectsForTemplateFridgeTopView(fridge, chassisObject) {
    var fridgeDrawingObjectArray = [];

    var fridgeLayerNum = 12, objectNum = 800000, blockName = 'FRIDGE1', sequenceNum = 1;

    var protrusion = fridge.getProtrusion();
    var originX, originY;

    var fridgeBoarder = 150, fridgeGrillSpacing = 5 / scalingFactor;

    //switch (chassisObject.getChassisObjectName()) {
    //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //        originX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab() + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - protrusion;
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - protrusion;
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - protrusion;
    //        break;
    //}
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FRIDGE);
    //originY = offerManager.getRigOps().getOverallWidthInclAccessories() / 2 - fridge.getWidth() / 2;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        originY = offerManager.getRigOps().getLeftOfBodyWidthExternalForTurningCircle(chassisObject) + fridge.getFromLeftOfBodyWidthExternal();
    } else {
        originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObject) + fridge.getFromLeftOfBodyWidthExternal();
    }
    
    
    addFridgeLine(0, 0, 0, fridge.getWidth(), fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    addFridgeLine(0, fridge.getWidth(), protrusion, fridge.getWidth(), fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    addFridgeLine(protrusion, fridge.getWidth(), protrusion, 0, fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    addFridgeLine(protrusion, 0, 0, 0, fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

    if (fridge.getWidth() > 300 && protrusion > 300) {
        
        objectNum++;
        sequenceNum = 1;
        addFridgeLine(0 + fridgeBoarder, 0 + fridgeBoarder, 0 + fridgeBoarder, fridge.getWidth() - fridgeBoarder, fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
        addFridgeLine(0 + fridgeBoarder, fridge.getWidth() - fridgeBoarder, protrusion - fridgeBoarder, fridge.getWidth() - fridgeBoarder, fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
        addFridgeLine(protrusion - fridgeBoarder, fridge.getWidth() - fridgeBoarder, protrusion - fridgeBoarder, 0 + fridgeBoarder, fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
        addFridgeLine(protrusion - fridgeBoarder, 0 + fridgeBoarder, 0 + fridgeBoarder, 0 + fridgeBoarder, fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

        objectNum++;
        sequenceNum = 1;


        var x, y;
        for (x = fridgeBoarder; x <= protrusion - fridgeBoarder; x += fridgeGrillSpacing) {
            addFridgeLine(x, fridgeBoarder, x, fridge.getWidth() - fridgeBoarder, fridgeLayerNum+1, blockName, objectNum + '_' + sequenceNum++);
        }
        for (y = fridgeBoarder; y <= fridge.getWidth() - fridgeBoarder; y += fridgeGrillSpacing) {
            addFridgeLine(fridgeBoarder, y, protrusion - fridgeBoarder, y, fridgeLayerNum + 1, blockName, objectNum + '_' + sequenceNum++);
        }
        //for (y = fridgeBoarder; y <= fridge.getWidth() - fridgeBoarder ; y += fridgeGrillSpacing) {
        //    addFridgeLine(x, 0, 0 + fridgeBoarder, fridge.getWidth() - fridgeBoarder, fridgeLayerNum, blockName, objectNum + '_' + sequenceNum++);
        //    ctx.moveTo(x, 0);
        //    ctx.lineTo(x, h);
        //    ctx.stroke();
        //    ctx.moveTo(0, y);
        //    ctx.lineTo(w, y);
        //    ctx.stroke();
        //}
    }
    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(fridgeDrawingObjectArray, vehicleTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        applyTransformationsToDrawingObjects(fridgeDrawingObjectArray, trailerTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        applyTransformationsToDrawingObjects(fridgeDrawingObjectArray, trailer2Transformation);
    }
    fridgeDrawingObjectArray.sort(sortDrawingObjects);
    fridgeDrawingObjectArray = groupDrawingObjects(fridgeDrawingObjectArray);
    fridgeDrawingObjectArray.forEach(function (drawingObject) {
        drawingObject.setDrawingOrder(6000);
    });
    addToMainDrawingObjectsArray(fridgeDrawingObjectArray);

    function addFridgeLine(startX, startY, endX, endY, fridgeLayerNum, blockName, objectNumSequenceNum, closedIndicator) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY, fridgeLayerNum, blockName, objectNumSequenceNum, closedIndicator);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE);
        fridgeDrawingObjectArray.push(newLine);
    }
}


function populateDrawingObjectsForTemplateFridgeSideView(fridge, chassisObject) {

    var protrusion = fridge.getProtrusion(),
        height = fridge.getHeight(),
        mountingPosition = fridge.getMountingPosition();


    var originX, originY;

    //switch (chassisObject.getChassisObjectName()) {
    //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //        originX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab() + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - protrusion;
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - protrusion;
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - protrusion;
    //        break;
    //}
    var tempBody = chassisObject.getAccessoryHolder().getBody();
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FRIDGE);
    originY = fridge.getVerticalGap() + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - mountingPosition - height;

    /*
    'top horizontal
    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
    _Protrusion, _
    _Height, _
    0, _
    _Height))
    */
    addFridgeLine(protrusion, height, 0, height);
    /*
    'left vertical
    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
    0, _
    _Height, _
    0, _
    0.4 * _Height))
    */
    addFridgeLine(0, height, 0, 0.4 * height);
    /*
    'diagonal
    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
    0, _
    0.4 * _Height, _
    0.5 * _Protrusion, _
    0))
    */
    addFridgeLine(0, 0.4 * height, 0.5 * protrusion, 0);
    /*
    'bottom horizontal
    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
    0.5 * _Protrusion, _
    0, _
    _Protrusion, _
    0))
    */
    addFridgeLine(0.5 * protrusion, 0, protrusion, 0);
    /*
    'right vertical
    DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
    _Protrusion, _
    0, _
    _Protrusion, _
    _Height))
    */
    addFridgeLine(protrusion, 0, protrusion, height);


    function addFridgeLine(startX, startY, endX, endY) {

        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FRIDGE);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, fridge, originY, originX)) {
                dashItemAndBringForward(newLine);
            }
            newLine.setDrawingOrder(6000);
        }
        if (fridge.getIsPinned()) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }
}

function populateDrawingObjectsForTemplateTaillift(taillift, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateTailliftSideView(taillift, chassisObject);
    } else {
        populateDrawingObjectsForTemplateTailliftTopView(taillift, chassisObject);
    }
}

function populateDrawingObjectsForTemplateTailliftTopView(taillift, chassisObject) {

    var tailliftDrawingObjectArray = [];

    var tailliftLayerNum = 12, objectNum = 1100000, blockName = 'TAILLIFT1', sequenceNum = 1;

    var rearClearance = taillift.getFromBackOfBody();
    var rearProtrusion = taillift.getPlatformProtrusion();

    var originX, originY;

    //switch (chassisObject.getChassisObjectName()) {
    //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
    //        originX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab() + chassisObject.getBody().getHorizontalGap() + chassisObject.getAccessoryHolder().getBody().getActualLength() + rearClearance;
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() + chassisObject.getAccessoryHolder().getBody().getActualLength() + rearClearance;
    //        break;
    //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
    //        originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() + chassisObject.getAccessoryHolder().getBody().getActualLength() + rearClearance;
    //        break;
    //}
    //originY = offerManager.getRigOps().getOverallWidthInclAccessories() / 2 - taillift.getWidth() / 2;
    originX = offerManager.getRigOps().getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.TAILLIFT);
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        originY = offerManager.getRigOps().getLeftOfBodyWidthExternalForTurningCircle(chassisObject) + taillift.getFromLeftOfBodyWidthExternal();
    } else {
        originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfBodyWidthExternal(chassisObject) + taillift.getFromLeftOfBodyWidthExternal();
    }
    

    addTailliftLine(0, 0, 0, taillift.getWidth(), tailliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    /*
'top
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                _Height, _
                                                _Length, _
                                                _Height))
                                                */
    addTailliftLine(0, taillift.getWidth(), rearProtrusion, taillift.getWidth(), tailliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    /*
'right vertical
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _Length, _
                                                _Height, _
                                                _Length, _
                                                0))
                                                */
    addTailliftLine(rearProtrusion, taillift.getWidth(), rearProtrusion, 0, tailliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    /*
'bottom
DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _Length, _
                                                0, _
                                                0, _
                                                0))
    
    */
    addTailliftLine(rearProtrusion, 0, 0, 0, tailliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

    //if (vehicleTransformation !== null) {
    //    applyTransformationsToDrawingObjects(tailliftDrawingObjectArray, vehicleTransformation);
    //}

    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(tailliftDrawingObjectArray, vehicleTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        applyTransformationsToDrawingObjects(tailliftDrawingObjectArray, trailerTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        applyTransformationsToDrawingObjects(tailliftDrawingObjectArray, trailer2Transformation);
    }
    tailliftDrawingObjectArray.sort(sortDrawingObjects);
    tailliftDrawingObjectArray = groupDrawingObjects(tailliftDrawingObjectArray);
    tailliftDrawingObjectArray.forEach(function (drawingObject) {
        drawingObject.setDrawingOrder(6000);
    });
    addToMainDrawingObjectsArray(tailliftDrawingObjectArray);

    function addTailliftLine(startX, startY, endX, endY, layerNum, blockName, objectNumSequenceNum, closedIndicator) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY, layerNum, blockName, objectNumSequenceNum, closedIndicator);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT);
        tailliftDrawingObjectArray.push(newLine);
    }
}

function populateDrawingObjectsForTemplateTailliftSideView(taillift, chassisObject) {
    /*
        If _Type = TailliftTypes.Cantilever Then
        */
    var aboveOrBelowFloor = taillift.getAboveOrBelowFloor(),
        fromBackOfBody = taillift.getFromBackOfBody();
    var originX, originY, tempBody;

    originX = offerManager.getRigOps().getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.TAILLIFT);
    switch (chassisObject.getChassisObjectName()) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            //originX = chassisObject.getPortionOfOverallLengthOverhangingFront() + chassisObject.getBumperToBackOfCab() + chassisObject.getBody().getHorizontalGap() + chassisObject.getAccessoryHolder().getBody().getActualLength() + fromBackOfBody;
            if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                originY = chassisObject.getFloorHeight() + aboveOrBelowFloor;
            } else {
                tempBody = chassisObject.getAccessoryHolder().getBody();
                originY = chassisObject.getChassisHeightMax() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + aboveOrBelowFloor;
            }
            
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            tempBody = chassisObject.getAccessoryHolder().getBody();
            //originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() + chassisObject.getAccessoryHolder().getBody().getActualLength() + fromBackOfBody;
            originY = chassisObject.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + aboveOrBelowFloor;
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            //originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() + chassisObject.getAccessoryHolder().getBody().getActualLength() + fromBackOfBody;
            originY = chassisObject.getChassisHeight() + tempBody.getAboveBelowChassis() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + aboveOrBelowFloor;
            break;
    }

    var boxHeight;
    
    var height = taillift.getHeight(),
        platformHeight = taillift.getPlatformHeight(),
        mechanismHeight = taillift.getMechanismHeight(),
        rearProtrusion = taillift.getPlatformProtrusion(),
        internalMountingLength = taillift.getMechanismLength(),
        rearClearance = taillift.getFromBackOfBody(),
        type = taillift.getType();

    if (type === config.TAILLIFT_TYPES.CANTILEVER) {
        /*
        Dim TailliftRadiusArc As Single = _RearProtrusion / 3
        Dim Hypotenuse As Single = Math.Sqrt(_Height * _Height + _RearProtrusion * _RearProtrusion)
        Dim BottomAngle As Single = Math.Acos(_RearProtrusion / Hypotenuse) * 180 / Math.PI 'Math input and output is radians, so just convert to degrees.
        Dim NewOpposite As Single = Math.Tan(BottomAngle * (Math.PI / 180)) * (_RearProtrusion / 3 * 2)
        Dim BoxWidth As Integer = 300
        Dim BoxHeight As Integer = MyBase.DrawingStartY / 2
        */
        

        var tailliftRadiusArc = rearProtrusion / 3,
            hypotenuse = Math.sqrt(platformHeight * platformHeight + rearProtrusion * rearProtrusion),
            bottomAngle = Math.acos(rearProtrusion / hypotenuse) * 180 / Math.PI,
            newOpposite = Math.tan(bottomAngle * (Math.PI / 180)) * (rearProtrusion / 3 * 2),
            boxWidth = 300;

        boxHeight = mechanismHeight; //curVehicle.getChassisHeightMax() / 2;
        /*
        If _InternalMountingLength < BoxWidth Then
            BoxWidth = _InternalMountingLength / 3
        End If
        */
        if (internalMountingLength < boxWidth) {
            boxWidth = internalMountingLength / 3
        }
        /*
        'bottom horizontal
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                        0, _
                                                        _RearProtrusion / 3 * 2, _
                                                        0))
                                                        */
        addTailliftLine(0, 0, 0 + (rearProtrusion / 3 * 2), 0);
        /*
        'bottom arc
        DrawingObjects.Add(New UtilDrawingObjectArc(_RearProtrusion / 3 * 2, _
                                                        TailliftRadiusArc, _
                                                        TailliftRadiusArc, _
                                                        360, _
                                                        90))
                                                        */
        addTailliftArc( (rearProtrusion / 3 * 2), tailliftRadiusArc,  tailliftRadiusArc, 360, 90);
        /*
        'bottom vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion, _
                                                        TailliftRadiusArc, _
                                                        _RearProtrusion, _
                                                        TailliftRadiusArc * 2))
                                                        */
        addTailliftLine(rearProtrusion, tailliftRadiusArc, rearProtrusion, tailliftRadiusArc * 2);
        /*
        'rear vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion, _
                                                        TailliftRadiusArc * 2, _
                                                        _RearClearance, _
                                                        _Height))
                                                        */
        addTailliftLine(rearProtrusion, tailliftRadiusArc * 2, 0, platformHeight);
        /*
        'middle vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                (_RearProtrusion - _RearClearance) / 3 + _RearClearance, _
                                                        0, _
                                                        (_RearProtrusion - _RearClearance) / 3 + _RearClearance, _
                                                        NewOpposite))
                                                        */
        addTailliftLine(0 + (rearProtrusion / 3 * 2), 0, (rearProtrusion) / 3, newOpposite);
        /*
        'front vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearClearance, _
                                                        0, _
                                                        _RearClearance, _
                                                        _Height))
                                                        */
        addTailliftLine(0, 0, 0, platformHeight);
        /*
        'bolt
        DrawingObjects.Add(New UtilDrawingObjectCircle((_RearProtrusion - _RearClearance) / 3 + _RearClearance, _
                                                        TailliftRadiusArc, _
                                                        TailliftRadiusArc / 2, _
                                                        UtilDrawingObjectCircle.CircleTypes.Other))
                                                        */
        addTailliftCircle((rearProtrusion) / 3, tailliftRadiusArc, tailliftRadiusArc / 2, CIRCLE_TYPE.OTHER);
        /*
        'bottom arm
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                TailliftRadiusArc * 1.5, _
                                                        0, _
                                                        _InternalMountingLength * -1 + BoxWidth, _
                                                        BoxHeight * -1))
                                                        */
        addTailliftLine((tailliftRadiusArc * 1.5), 0, internalMountingLength * -1 + boxWidth, boxHeight * -1);
        /*
        'bottom arm
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                TailliftRadiusArc / 2, _
                                                        0, _
                                                        _InternalMountingLength * -1 + BoxWidth, _
                                                        BoxHeight * -1 + TailliftRadiusArc))
                                                        */
        addTailliftLine((tailliftRadiusArc / 2), 0, internalMountingLength * -1 + boxWidth, boxHeight * -1 + tailliftRadiusArc);
        /*
        'bottom of bottom box
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _InternalMountingLength * -1 + BoxWidth, _
                                                        BoxHeight * -1, _
                                                        _InternalMountingLength * -1, _
                                                        BoxHeight * -1))
                                                        */
        addTailliftLine(internalMountingLength * -1 + boxWidth, boxHeight * -1, internalMountingLength * -1, boxHeight * -1);
        /*
        'front of bottom box
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _InternalMountingLength * -1, _
                                                        BoxHeight * -1, _
                                                        _InternalMountingLength * -1, _
                                                        0))
                                                        */
        addTailliftLine(internalMountingLength * -1, boxHeight * -1, internalMountingLength * -1, 0);
        /*
        'rear of bottom box
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _InternalMountingLength * -1 + BoxWidth, _
                                                        BoxHeight * -1, _
                                                        _InternalMountingLength * -1 + BoxWidth, _
                                                        0))
                                                        */
        addTailliftLine(internalMountingLength * -1 + boxWidth, boxHeight * -1, internalMountingLength * -1 + boxWidth, 0);
        /*
        'top of bottom box
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _InternalMountingLength * -1 + BoxWidth, _
                                                        0, _
                                                        _InternalMountingLength * -1, _
                                                        0))
                                                        */
        addTailliftLine(internalMountingLength * -1 + boxWidth, 0, internalMountingLength * -1, 0);
    } else {
        /*

    Else
    */

        /*
        Dim boxHeight As Integer
        Dim railBottom As Integer = MyBase.DrawingStartY / 2
        Dim armWidth As Integer = 100
        */
        var railBottom = mechanismHeight,//curVehicle.getChassisHeightMax() / 2,
            armWidth = 100;
        /*
        If _Type = TailliftTypes.ColumnLowerBox Then
            boxHeight = _Height * 0.9
        Else
            boxHeight = _Height * 0.6
        End If
        */
        if (type === config.TAILLIFT_TYPES.COLUMN_LOWER_BOX) {
            boxHeight = platformHeight * 0.9;
        } else {
            boxHeight = platformHeight * 0.6;
        }
        //boxHeight = mechanismHeight;
        /*
        'taillift box
        'bottom horizontal
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion / 2, _
                                                        0, _
                                                        _RearProtrusion, _
                                                        0))
                                                        */
        addTailliftLine(rearProtrusion / 2, 0, rearProtrusion, 0);
        /*
        'right vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion, _
                                                        0, _
                                                        _RearProtrusion, _
                                                        boxHeight))
                                                        */
        addTailliftLine(rearProtrusion, 0, rearProtrusion, boxHeight);
        /*
        'top horizontal
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion, _
                                                        boxHeight, _
                                                        _RearProtrusion / 2, _
                                                        boxHeight))
                                                        */
        addTailliftLine(rearProtrusion, boxHeight, rearProtrusion / 2, boxHeight);
        /*
        'left vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion / 2, _
                                                        boxHeight, _
                                                        _RearProtrusion / 2, _
                                                        0))
                                                        */
        addTailliftLine(rearProtrusion / 2, boxHeight, rearProtrusion / 2, 0);
        /*
        'taillift rail
        'bottom horizontal
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion / 2, _
                                                        railBottom * -1, _
                                                        0, _
                                                        railBottom * -1))
                                                        */
        addTailliftLine(rearProtrusion / 2, railBottom * -1, 0, railBottom * -1);
        /*
        'right vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion / 2, _
                                                        railBottom * -1, _
                                                        _RearProtrusion / 2, _
                                                        _Height))
                                                        */
        addTailliftLine(rearProtrusion / 2, railBottom * -1, rearProtrusion / 2, platformHeight);
        /*
        'top horizontal
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                _RearProtrusion / 2, _
                                                        _Height, _
                                                        0, _
                                                        _Height))
                                                        */
        addTailliftLine(rearProtrusion / 2, platformHeight, 0, platformHeight);
        /*
        'left vertical
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                        _Height, _
                                                        0, _
                                                        railBottom * -1))
                                                        */
        addTailliftLine(0, platformHeight, 0, railBottom * -1);
        /*
        'bottom arm
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                        railBottom * -1 + armWidth, _
                                                        _InternalMountingLength * -1 + armWidth, _
                                                        0))
                                                        */
        addTailliftLine(0, railBottom * -1 + armWidth, internalMountingLength * -1 + armWidth, 0);
        /*
        'bottom arm
        DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                0, _
                                                        railBottom * -1, _
                                                        _InternalMountingLength * -1, _
                                                        0))
                                                        */
        addTailliftLine(0, railBottom * -1, internalMountingLength * -1, 0);
        /*
        'bolt
        DrawingObjects.Add(New UtilDrawingObjectCircle(_RearProtrusion * 0.75, _
                                                        50, _
                                                        25, _
                                                        UtilDrawingObjectCircle.CircleTypes.Other))
                                                        */
        addTailliftCircle(rearProtrusion * 0.75, 50, 25, CIRCLE_TYPE.OTHER);
    }
    /*
End If
    */
    function addTailliftArc(centreX, centreY, radius, startAngle, sweep) {
        var newArc = new Arc(originX + centreX, originY + centreY, radius, startAngle, sweep, true);
        newArc.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, taillift, originY, originX)) {
                dashItemAndBringForward(newArc);
            }
            newArc.setDrawingOrder(6000);
        }
        if (taillift.getIsPinned()) {
            newArc.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newArc);
    }

    function addTailliftLine(startX, startY, endX, endY) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, taillift, originY, originX)) {
                dashItemAndBringForward(newLine);
            }
        }
        if (taillift.getIsPinned()) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }

    function addTailliftCircle(centreX, centreY, radius, circleType) {
        var newCircle = new Circle(originX + centreX, originY + centreY, radius, circleType);
        newCircle.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.TAILLIFT);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, taillift, originY, originX)) {
                dashItemAndBringForward(newCircle);
            }
            newCircle.setDrawingOrder(6000);
        }
        if (taillift.getIsPinned()) {
            newCircle.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newCircle);
    }

}

function populateDrawingObjectsForTemplateFifthWheel(fifthWheel, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateFifthWheelSideView(fifthWheel, chassisObject)
    } else {
        populateDrawingObjectsForTemplateFifthWheelTopView(fifthWheel, chassisObject)
    }
}

function populateDrawingObjectsForTemplateFifthWheelTopView(fifthWheel, chassisObject) {

    var fifthWheelDrawingObjectsArray = [];

    var fifthWheelLayerNum = 10, objectNum = 700000, blockName = 'FIFTHWHEEL1', sequenceNum = 1;

    var fifthWheelRadius = fifthWheel.getWidth() / 2;
    if (fifthWheelRadius === 0) {
        fifthWheelRadius = config.topViewFifthWheelRadius;
    }

    var originX, originY;

    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FIFTH_WHEEL);
    
    //originY = offerManager.getRigOps().getOverallWidthInclAccessories() / 2;

    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObject) + fifthWheel.getFromLeftOfVehicleRearWidth();
    } else {
        originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObject) + fifthWheel.getFromLeftOfVehicleRearWidth();
    }
    


    addFifthWheelCircle(0, 0, fifthWheelRadius, CIRCLE_TYPE.OTHER, fifthWheelLayerNum, blockName, objectNum + '_' + sequenceNum++);

    if (vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(fifthWheelDrawingObjectsArray, vehicleTransformation);
    }

    addToMainDrawingObjectsArray(fifthWheelDrawingObjectsArray);

    function addFifthWheelCircle(centreX, centreY, radius, circleType, blockName, fifthWheelLayerNum, objectNumequenceNum) {
        var newCircle = new Circle(originX + centreX, originY + centreY, radius, circleType, blockName, fifthWheelLayerNum, objectNumequenceNum);
        newCircle.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL);
        //if (curTrailer1 !== null && curTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
        //    newCircle.setBypassHitAreaSort(true);
        //}
        newCircle.setDrawingOrder(8000);
        fifthWheelDrawingObjectsArray.push(newCircle);
    }
}

function populateDrawingObjectsForTemplateFifthWheelSideView(fifthWheel, chassisObject) {

    var pinRadius = 35,
        mountLengthTop = 150,
        mountLengthBottom = 300,
        mountBaseLength = 450,
        mountBaseHeight = 20,
        mountHeight,
        headHeight = 70,
        headLeftTaperLength = 150,
        subframeBaseLength = config.fifthWheelSubframeBaseLength,//1740
        subframeTopLength = 1340,
        centrePoint = subframeBaseLength/2;

    

    var length = fifthWheel.getLength(),
        subframeHeight = fifthWheel.getSubframeHeight(),
        frontOverhang = fifthWheel.getFrontOverhang(),
        height = fifthWheel.getHeight();

    mountHeight = height - mountBaseHeight - headHeight;
    var originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.FIFTH_WHEEL) + frontOverhang - (subframeBaseLength / 2);
    //var originX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheel.getOffset()) - (subframeBaseLength/2),
    var originY = curVehicle.getChassisHeightMax();
    
    //subframe base line
    addFifthWheelLine(0, 0, subframeBaseLength, 0);
    
    //subFrameTopLine
    addFifthWheelLine(centrePoint - (subframeTopLength/2), subframeHeight, centrePoint + (subframeTopLength/2), subframeHeight);

    //subframe taper left
    addFifthWheelLine(0, 0, centrePoint - (subframeTopLength/2), subframeHeight);

    //subframe taper right
    addFifthWheelLine(centrePoint + (subframeTopLength/2), subframeHeight, subframeBaseLength, 0);

    //mount base bottom line
    addFifthWheelLine(centrePoint - (mountBaseLength / 2), subframeHeight, centrePoint + (mountBaseLength / 2), subframeHeight);

    //mount base side right line
    addFifthWheelLine(centrePoint + (mountBaseLength / 2), subframeHeight, centrePoint + (mountBaseLength / 2), subframeHeight + mountBaseHeight);

    //mount base top right line
    addFifthWheelLine(centrePoint + (mountBaseLength / 2), subframeHeight + mountBaseHeight, centrePoint + (mountLengthBottom / 2), subframeHeight + mountBaseHeight);

    //mount base top left line
    addFifthWheelLine(centrePoint - (mountBaseLength / 2), subframeHeight + mountBaseHeight, centrePoint - (mountLengthBottom / 2), subframeHeight + mountBaseHeight);

    //mount base side left line
    addFifthWheelLine(centrePoint - (mountBaseLength / 2), subframeHeight + mountBaseHeight, centrePoint - (mountBaseLength / 2), subframeHeight);

    //mount left taper line
    addFifthWheelLine(centrePoint - (mountLengthBottom / 2), subframeHeight + mountBaseHeight, centrePoint - (mountLengthTop / 2), subframeHeight + mountBaseHeight + mountHeight);

    //mount top line
    addFifthWheelLine(centrePoint - (mountLengthTop / 2), subframeHeight + mountBaseHeight + mountHeight, centrePoint + (mountLengthTop / 2), subframeHeight + mountBaseHeight + mountHeight);

    //mount right taper line
    addFifthWheelLine(centrePoint + (mountLengthBottom / 2), subframeHeight + mountBaseHeight, centrePoint + (mountLengthTop / 2), subframeHeight + mountBaseHeight + mountHeight);

    //head side line right
    addFifthWheelLine(centrePoint - frontOverhang, subframeHeight + mountBaseHeight + mountHeight, centrePoint - frontOverhang, subframeHeight + mountBaseHeight + mountHeight + headHeight);

    //head top line
    addFifthWheelLine(centrePoint - frontOverhang, subframeHeight + mountBaseHeight + mountHeight + headHeight, centrePoint - frontOverhang + length - headLeftTaperLength, subframeHeight + mountBaseHeight + mountHeight + headHeight);

    //head right point top taper line
    addFifthWheelLine(centrePoint - frontOverhang + length - headLeftTaperLength, 
                        subframeHeight + mountBaseHeight + mountHeight + headHeight, 
                        centrePoint - frontOverhang + length, 
                        subframeHeight + mountBaseHeight + mountHeight + (headHeight/2));

    //head right point bottom taper line
    addFifthWheelLine(centrePoint - frontOverhang + length, 
                        subframeHeight + mountBaseHeight + mountHeight + (headHeight/2), 
                        centrePoint - frontOverhang + length -headLeftTaperLength, 
                        subframeHeight + mountBaseHeight + mountHeight);
    
    //head bottom line
    addFifthWheelLine(centrePoint - frontOverhang + length - headLeftTaperLength, subframeHeight + mountBaseHeight + mountHeight, centrePoint - frontOverhang, subframeHeight + mountBaseHeight + mountHeight);

    //head pin
    addFifthWheelCircle(centrePoint, subframeHeight + mountBaseHeight + mountHeight, pinRadius, CIRCLE_TYPE.OTHER);

    function addFifthWheelLine(startX, startY, endX, endY) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, fifthWheel, originY, originX)) {
                dashItemAndBringForward(newLine);
            }
        }
        drawingObjectsArray.push(newLine);
    }

    function addFifthWheelCircle(centreX, centreY, radius, circleType) {
        var newCircle = new Circle(originX + centreX, originY + centreY, radius, circleType);
        newCircle.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.FIFTH_WHEEL);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, fifthWheel, originY, originX)) {
                dashItemAndBringForward(newCircle);
            }
        }

        drawingObjectsArray.push(newCircle);
    }
}

function populateDrawingObjectsForTemplateHitch(hitch, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateHitchSideView(hitch, chassisObject)
    } else {
        populateDrawingObjectsForTemplateHitchTopView(hitch, chassisObject)
    }
}

function populateDrawingObjectsForTemplateHitchTopView(hitch, chassisObject) {

    var hitchDrawingObjectsArray = [];

    var hitchLayerNum = 10, objectNum = 700000, blockName = 'HITCH1', sequenceNum = 1;

    var mountingLength = hitch.getMountingLength(),
        couplingOffset = hitch.getCouplingOffset(),
        hitchOffset = hitch.getOffset(),
        hitchRadius = hitch.getWidth() / 2;

    if (hitchRadius === 0) {
        hitchRadius = config.topViewHitchRadius;
    }

    var originX, originY;

    //originX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + hitchOffset - mountingLength;//#unfinished, will need to be updated when adding hitch to trailer 1

    //originX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + hitchOffset);
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.HITCH);
    //originY = offerManager.getRigOps().getOverallWidthInclAccessories()/2 - hitch.getWidth()/2;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObject) + hitch.getFromLeftOfVehicleRearWidth();
    } else {
        originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObject) + hitch.getFromLeftOfVehicleRearWidth();
    }
    
    
    addHitchCircle(0, 0, hitchRadius, CIRCLE_TYPE.OTHER, hitchLayerNum, blockName, objectNum + '_' + sequenceNum++);

    if (vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(hitchDrawingObjectsArray, vehicleTransformation);//#unfinished, will need to support trailer when hitch added to trailer1
    }

    addToMainDrawingObjectsArray(hitchDrawingObjectsArray);

    function addHitchCircle(centreX, centreY, radius, circleType, layerNum, blockName, objectNumSequenceNum) {
        var newCircle = new Circle(originX + centreX, originY + centreY, radius, circleType, layerNum, blockName, objectNumSequenceNum);
        newCircle.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH);
        newCircle.setDrawingOrder(8000);
        hitchDrawingObjectsArray.push(newCircle);
    }
}

function populateDrawingObjectsForTemplateHitchSideView(hitch, chassisObject) {
    var couplingPinHeadLength = 100,
        mountInnerHeight = 100,
        mountOuterHeight = 150,
        mountPlateLength = 25,
        couplingFacePlateLength = 50,
        funnelNarrowSideHeight = 50,
        funnelWallThickness = 10;

    var mountingLength = hitch.getMountingLength(),
        couplingOffset = hitch.getCouplingOffset(),
        funnelLength = hitch.getFunnelLength(),
        height = hitch.getHeight(),
        couplingHeight = hitch.getCouplingHeight(),
        heightFromGround = hitch.getHeightFromGround(),
        hitchOffset = hitch.getOffset();

    var originX, originY;

    //if (hitch.getType() === config.HITCH_TYPES.CONVENTIONAL) {
    //    //originX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getOverallLengthExclAccessories() - curVehicle.getRearProtrusion() - mountingLength);
    //    originX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + hitchOffset - (couplingOffset + mountingLength);
    //} else {
    //    originX = (curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + hitchOffset) - (couplingOffset + mountingLength);
    //}
    //originX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + hitchOffset - (couplingOffset + mountingLength);
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.HITCH) - (couplingOffset + mountingLength);
    originY = heightFromGround - couplingHeight;

    //var hasServiceBody = curVehicle.getBody() !== null && curVehicle.getBody().getType() === config.BODY_TYPES.SERVICE; 
    ////draw mount
    //mount bottom
    addHitchLine(0, couplingHeight - mountInnerHeight / 2, mountingLength, couplingHeight - mountInnerHeight / 2);
    //mount right side
    addHitchLine(mountingLength, couplingHeight - mountInnerHeight / 2, mountingLength, couplingHeight + mountInnerHeight / 2);
    //mount top
    addHitchLine(0, couplingHeight + mountInnerHeight / 2, mountingLength, couplingHeight + mountInnerHeight / 2);
    //mount left side
    addHitchLine(0, couplingHeight - mountInnerHeight / 2, 0, couplingHeight + mountInnerHeight / 2);
    //mountPlate bottom
    addHitchLine(mountingLength - mountPlateLength, couplingHeight - mountOuterHeight / 2, mountingLength, couplingHeight - mountOuterHeight / 2);
    //mountPlate right side
    addHitchLine(mountingLength, couplingHeight - mountOuterHeight / 2, mountingLength, couplingHeight + mountOuterHeight / 2);
    //mountPlate top
    addHitchLine(mountingLength, couplingHeight + mountOuterHeight / 2, mountingLength - mountPlateLength, couplingHeight + mountOuterHeight / 2);
    //mountPlate left side
    addHitchLine(mountingLength - mountPlateLength, couplingHeight + mountOuterHeight / 2, mountingLength - mountPlateLength, couplingHeight - mountOuterHeight / 2);

    ////draw coupler
    //coupler bottom
    addHitchLine(mountingLength, couplingHeight - mountInnerHeight / 2, mountingLength + couplingOffset - (couplingFacePlateLength / 2), couplingHeight - mountInnerHeight / 2);
    //coupler right side
    addHitchLine(mountingLength + couplingOffset - (couplingFacePlateLength / 2), couplingHeight - mountInnerHeight / 2, mountingLength + couplingOffset - (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2);
    //coupler top
    addHitchLine(mountingLength + couplingOffset - (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2, mountingLength, couplingHeight + mountInnerHeight / 2);
    //coupler left side
    addHitchLine(mountingLength, couplingHeight + mountInnerHeight / 2, mountingLength, couplingHeight - mountInnerHeight / 2);
    //coupler face plate bottom arc
    addHitchArc(mountingLength + couplingOffset, couplingHeight - mountInnerHeight / 2, couplingFacePlateLength / 2, 0, 180);
    //coupler face plate right side
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight - mountInnerHeight / 2, mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2);
    //coupler face plate top
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2, mountingLength + couplingOffset - (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2);
    //coupler face plate left side
    addHitchLine(mountingLength + couplingOffset - (couplingFacePlateLength / 2), couplingHeight - mountInnerHeight / 2, mountingLength + couplingOffset - (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2);
    //coupler pin head bottom
    addHitchLine((mountingLength + couplingOffset + (couplingFacePlateLength / 2)) - couplingPinHeadLength, couplingHeight + mountInnerHeight / 2, mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2);
    //coupler pin head right side
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight + mountInnerHeight / 2, mountingLength + couplingOffset + (couplingFacePlateLength / 2), height);
    //coupler pin head top
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), height, (mountingLength + couplingOffset + (couplingFacePlateLength / 2)) - couplingPinHeadLength, height);
    //coupler pin head left side
    addHitchLine((mountingLength + couplingOffset + (couplingFacePlateLength / 2)) - couplingPinHeadLength, height, (mountingLength + couplingOffset + (couplingFacePlateLength / 2)) - couplingPinHeadLength, couplingHeight + mountInnerHeight / 2);

    ////draw funnel
    //funnel upper top
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight + (funnelNarrowSideHeight / 2) + funnelWallThickness, mountingLength + couplingOffset + funnelLength, (couplingHeight * 2) + funnelWallThickness);
    //funnel upper right
    addHitchLine(mountingLength + couplingOffset + funnelLength, (couplingHeight * 2) + funnelWallThickness, mountingLength + couplingOffset + funnelLength, couplingHeight * 2);
    //funnel upper bottom
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight + (funnelNarrowSideHeight / 2), mountingLength + couplingOffset + funnelLength, couplingHeight * 2);
    //funnel lower top
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight - (funnelNarrowSideHeight / 2), mountingLength + couplingOffset + funnelLength, 0);
    //funnel lower right
    addHitchLine(mountingLength + couplingOffset + funnelLength, 0, mountingLength + couplingOffset + funnelLength, 0 - funnelWallThickness);
    //funnel lower bottom
    addHitchLine(mountingLength + couplingOffset + (couplingFacePlateLength / 2), couplingHeight - (funnelNarrowSideHeight / 2) - funnelWallThickness, mountingLength + couplingOffset + funnelLength, 0 - funnelWallThickness);

    function addHitchLine(startX, startY, endX, endY) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, hitch, originY, originX)) {
                dashItemAndBringForward(newLine);
            }
            //newLine.setDrawingOrder(8000);
        }
        //if (hasServiceBody) {
        //    newLine.setDrawingOrder(8000);
        //} else {
        //    newLine.setDrawingOrder(5000);
        //}
        
        drawingObjectsArray.push(newLine);
    }

    function addHitchArc(centreX, centreY, radius, startAngle, sweep) {
        var newArc = new Arc(originX + centreX, originY + centreY, radius, startAngle, sweep, true);
        newArc.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HITCH);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, hitch, originY, originX)) {
                dashItemAndBringForward(newArc);
            }
            //newArc.setDrawingOrder(8000);
        }
        //if (hasServiceBody) {
        //    newArc.setDrawingOrder(8000);
        //} else {
        //    newArc.setDrawingOrder(5000);
        //}
        
        drawingObjectsArray.push(newArc);
    }

}


function populateDrawingObjectsForTemplateHooklift(hooklift, chassisObject) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateHookliftSideView(hooklift, chassisObject)
    } else {
        populateDrawingObjectsForTemplateHookliftTopView(hooklift, chassisObject)
    }
}

function populateDrawingObjectsForTemplateHookliftTopView(hooklift, chassisObject) {

    var drawingGroupName = chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT;
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        drawingGroupName = config.OBJECT_TYPES.TURNING_CIRCLE;
    }

    var hookliftDrawingObjectArray = [];

    var hookliftLayerNum = 12, objectNum = 900000, blockName = 'HOOKLIFT1', sequenceNum = 1;

    var originX, originY;

    
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.HOOKLIFT);
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        originY = offerManager.getRigOps().getLeftOfChassisRearWidthForTurningCircle(chassisObject) + hooklift.getFromLeftOfVehicleRearWidth();
    } else {
        originY = offerManager.getRigOps().getPortionOfOverallWidthOverhangingLeft() + offerManager.getRigOps().getLeftOfChassisRearWidth(chassisObject) + hooklift.getFromLeftOfVehicleRearWidth();
    }

    
    addHookliftLine(0, 0, 0, hooklift.getWidth(), hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addHookliftLine(0, hooklift.getWidth(), hooklift.getLength(), hooklift.getWidth(), hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addHookliftLine(hooklift.getLength(), hooklift.getWidth(), hooklift.getLength(), 0, hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addHookliftLine(hooklift.getLength(), 0, 0, 0, hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");

    objectNum++;
    sequenceNum = 1;
    
    addHookliftLine(0, 0, 0, hooklift.getWidth(), hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addHookliftLine(0, hooklift.getWidth(), hooklift.getAFrame(), hooklift.getWidth(), hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addHookliftLine(hooklift.getAFrame(), hooklift.getWidth(), hooklift.getAFrame(), 0, hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    
    addHookliftLine(hooklift.getAFrame(), 0, 0, 0, hookliftLayerNum, blockName, objectNum + '_' + sequenceNum++, "TRUE");
    

    if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && vehicleTransformation !== null) {
        applyTransformationsToDrawingObjects(hookliftDrawingObjectArray, vehicleTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 && trailerTransformation !== null) {
        applyTransformationsToDrawingObjects(hookliftDrawingObjectArray, trailerTransformation);
    } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2 && trailer2Transformation !== null) {
        applyTransformationsToDrawingObjects(hookliftDrawingObjectArray, trailer2Transformation);
    }
    hookliftDrawingObjectArray.sort(sortDrawingObjects);
    hookliftDrawingObjectArray = groupDrawingObjects(hookliftDrawingObjectArray);
    addToMainDrawingObjectsArray(hookliftDrawingObjectArray);

    function addHookliftLine(startX, startY, endX, endY, layerNum, blockName, objectNumSequenceNum, cloasedIndicator) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY, layerNum, blockName, objectNumSequenceNum, cloasedIndicator);
        newLine.setDrawingGroupName(drawingGroupName);
        hookliftDrawingObjectArray.push(newLine);
    }
}

function populateDrawingObjectsForTemplateHookliftSideView(hooklift, chassisObject) {
    var hookbeamTaperAngle = 35,
        topHookbeamCutBack = 0,
        aFrame = hooklift.getAFrame(),
        platformHeight = hooklift.getPlatformHeight(),
        hookarmHeight = hooklift.getHeight() - platformHeight,
        hookbeamLength = hooklift.getLength(),
        subframeHeight = platformHeight * 0.56,
        centreOfRoller = hooklift.getCentreOfRoller();


    var originX, originY;
    originX = offerManager.getRigOps().getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.HOOKLIFT, hooklift.getId());
    switch (chassisObject.getChassisObjectName()) {
        case config.CHASSIS_OBJECT_TYPES.VEHICLE:
            originY = chassisObject.getChassisHeightMax() + hooklift.getVerticalGap();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            originY = chassisObject.getChassisHeight() + hooklift.getVerticalGap();
            break;
        case config.CHASSIS_OBJECT_TYPES.TRAILER2:
            originY = chassisObject.getChassisHeight() + hooklift.getVerticalGap();
            break;
    }




    //' Add subframe.
    //AddSubframeForBody(ActualLength)
    addSubframeForHooklift(hookbeamLength);

    //' Add substructure.
    //AddSubStructureForBody(ActualLength)
    addSubstructureForHooklift(hookbeamLength);

    ////draw hookarm and hook
    if (aFrame > 0) {

        var hookHeight = 220, hookProtrusion = 30, hookLength = 115;

        //draw hookarm lower front line 
        addHookliftLine(0, platformHeight, 0, platformHeight + ((hookarmHeight - hookHeight) / 2));
        //draw hookarm upper front line
        addHookliftLine(0, platformHeight + ((hookarmHeight - hookHeight) / 2), aFrame / 2, platformHeight + (hookarmHeight - hookHeight));
        //draw hookarm top line
        addHookliftLine(aFrame / 2, platformHeight + (hookarmHeight - hookHeight), aFrame, platformHeight + (hookarmHeight - hookHeight));
        //draw hookarm back line
        addHookliftLine(aFrame, platformHeight, aFrame, platformHeight + (hookarmHeight - hookHeight));

        //draw hook bottom slant of front protrusion
        addHookliftLine(aFrame - hookLength, platformHeight + (hookarmHeight - hookHeight), (aFrame - hookLength) - hookProtrusion, platformHeight + (hookarmHeight - hookHeight) + hookProtrusion);
        //hook top slant of front protrusion
        addHookliftLine((aFrame - hookLength) - hookProtrusion, platformHeight + (hookarmHeight - hookHeight) + hookProtrusion, aFrame - hookLength, platformHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2));
        //hook bottom arc
        addHookliftArc(aFrame - hookLength, (platformHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + (hookLength / 3), (hookLength / 3), 0, 90);
        //addBodyArc(hookarmLength / 2, (subframeHeight + hookbeamHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + ((hookarmLength / 2) / 3), ((hookarmLength / 2) / 3) + (hookProtrusion * 2), 0, 30);

        ////hook mid section
        var hookMidSectionHeight = hookHeight - (((hookProtrusion * 2) + ((hookLength) / 3)) * 2);
        //hook mid section front
        addHookliftLine((aFrame - hookLength) + (hookLength / 3), platformHeight + (hookarmHeight - hookHeight) + ((hookProtrusion * 2) + (hookLength / 3)),
            (aFrame - hookLength) + (hookLength / 3), platformHeight + (hookarmHeight - hookHeight) + ((hookProtrusion * 2) + (hookLength / 3)) + hookMidSectionHeight);
        ////hook mid section back
        addHookliftLine(aFrame - 9, platformHeight + (hookarmHeight - hookHeight),
            aFrame - 9, platformHeight + (hookarmHeight - hookHeight) + ((hookProtrusion * 2) + (hookLength / 3)) + hookMidSectionHeight);

        //hook top arc
        addHookliftArc(aFrame - hookLength, (platformHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + (hookLength / 3) + hookMidSectionHeight, (hookLength / 3), 225, 135);
        addHookliftArc(aFrame - hookLength, (platformHeight + (hookarmHeight - hookHeight) + (hookProtrusion * 2)) + (hookLength / 3) + hookMidSectionHeight, (hookLength / 3) + (hookProtrusion * 2), 270, 90);
        addHookliftLine((aFrame - hookLength) - hookProtrusion, platformHeight + hookarmHeight - hookProtrusion, aFrame - hookLength, platformHeight + hookarmHeight);
        addHookliftLine((aFrame - hookLength) - hookProtrusion, platformHeight + hookarmHeight - hookProtrusion, (aFrame - hookLength) - hookProtrusion, platformHeight + hookarmHeight - (hookProtrusion * 2));
    }

    ////' Draw bin bottom line.
    //addBodyLine(hookarmLength, subframeHeight + hookbeamHeight, actualLength, subframeHeight + hookbeamHeight, true);

    /////*
    ////' Draw bin rear height line.
    //addBodyLine(actualLength, subframeHeight + hookbeamHeight, actualLength, subframeHeight + hookbeamHeight + binHeight, true);

    /////*
    ////' Draw bin top line.
    //addBodyLine(actualLength, subframeHeight + hookbeamHeight + binHeight, hookarmLength, subframeHeight + hookbeamHeight + binHeight, true);

    /////*
    //// ' Draw bin front height line.
    //addBodyLine(hookarmLength, subframeHeight + hookbeamHeight, hookarmLength, subframeHeight + hookbeamHeight + binHeight, true);

    ////draw beam roller circles
    topHookbeamCutBack = ((Math.tan(hookbeamTaperAngle / (180 / Math.PI)) * (platformHeight - subframeHeight)));
    //draw outer roller circle
    addHookliftCircle(hookbeamLength - centreOfRoller, (platformHeight) - 50, 125, CIRCLE_TYPE.OTHER);
    //draw inner roller circle
    addHookliftCircle(hookbeamLength - centreOfRoller, (platformHeight) - 50, 50, CIRCLE_TYPE.OTHER);

    ////draw bin roller circle
    ////draw outer cricle
    //addHookliftCircle(actualLength - 125, (platformHeight) + 50, 125, CIRCLE_TYPE.OTHER, true);
    ////draw inner circle
    //addHookliftCircle(actualLength - 125, (platformHeight) + 50, 50, CIRCLE_TYPE.OTHER, true);

    

            ///*
            //' Draw outer bottom line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   0,
            //                                          _SubframeHeight + _SubstructureHeight,
            //                                          ActualLength,
            //                                          _SubframeHeight + _SubstructureHeight))

            //*/
            //addBodyLine(0, subframeHeight + substructureHeight, actualLength, subframeHeight + substructureHeight);

            ///*
            //' Draw outer rear height line.
            //  DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   ActualLength,
            //                                          _SubframeHeight + _SubstructureHeight,
            //                                          ActualLength,
            //                                          ActualExternalHeight))

            //*/
            //addBodyLine(actualLength, subframeHeight + substructureHeight, actualLength, actualExternalHeight);


            ///*
            //' Draw outer roof line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                    ActualLength,
            //                            ActualExternalHeight,
            //                            0,
            //                            ActualExternalHeight))    
            //*/
            //addBodyLine(actualLength, actualExternalHeight, 0, actualExternalHeight);

            ///*
            //' Draw outer front height line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   0,
            //                                          ActualExternalHeight,
            //                                          0,
            //                                          _SubframeHeight + SubstructureHeight))

            //*/
            //addBodyLine(0, actualExternalHeight, 0, subframeHeight + substructureHeight);

            ///*

            //' Draw inner bottom line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   _HeadboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness,
            //                                          ActualLength - _TailboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness))

            //*/
            ////addBodyLine(headboardThickness, subframeHeight + substructureHeight + roofThickness, actualLength - tailboardThickness, subframeHeight + substructureHeight + roofThickness);

            ///*
            //' Draw inner rear height line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   ActualLength - _TailboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness,
            //                                          ActualLength - _TailboardThickness,
            //                                          ActualExternalHeight - _RoofThickness))    
            //*/
            //addBodyLine(actualLength - tailboardThickness, subframeHeight + substructureHeight, actualLength - tailboardThickness, actualExternalHeight - roofThickness);

            ///*
            //' Draw inner roof line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   ActualLength - _TailboardThickness,
            //                                          ActualExternalHeight - _RoofThickness,
            //                                          _HeadboardThickness,
            //                                          ActualExternalHeight - _RoofThickness))        
            //*/
            //addBodyLine(actualLength - tailboardThickness, actualExternalHeight - roofThickness, headboardThickness, actualExternalHeight - roofThickness);

            ///*
            // ' Draw inner front height line.
            //DrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
            //                                   _HeadboardThickness,
            //                                          ActualExternalHeight - _RoofThickness,
            //                                          _HeadboardThickness,
            //                                          _SubframeHeight + _SubstructureHeight + _RoofThickness))       
            //*/
            //addBodyLine(headboardThickness, actualExternalHeight - roofThickness, headboardThickness, subframeHeight + substructureHeight);

            ///*

            //   ' Removed until SP1
            //   '' Draw body ribs.
            //   'For i As Integer = 1 To numberOfBodyRibs - 1
            //   */

            ///*
            //   '    DrawingObjects.Add(New FileLoadingDrawingLine(_HeadboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i, _
            //   '                                                  ActualLength - TailboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i))

            //   '    DrawingObjects.Add(New FileLoadingDrawingLine(_HeadboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i + bodyRibHeight, _
            //   '                                                  ActualLength - TailboardThickness, _
            //   '                                                  _SubframeHeight + _SubstructureHeight + bodyRibSpacer * i + bodyRibHeight))

            //   'Next
            //   */



    function addSubframeForHooklift(length) {
        addHookliftLine(0, 0, length, 0);
        
        addHookliftLine(length, 0, length, subframeHeight);
        
        addHookliftLine(length, subframeHeight, 0, subframeHeight);
        addHookliftLine(0, subframeHeight, 0, 0);
    }

    function addSubstructureForHooklift(length) {
        topHookbeamCutBack = ((Math.tan(hookbeamTaperAngle / (180 / Math.PI)) * (platformHeight - subframeHeight)));
        addHookliftLine(length, subframeHeight, length - topHookbeamCutBack, platformHeight);
        addHookliftLine(length - topHookbeamCutBack, platformHeight, 0, platformHeight);
        addHookliftLine(0, platformHeight, 0, subframeHeight);
        addHookliftLine(0, subframeHeight, length, subframeHeight);

    }

    function addHookliftArc(centreX, centreY, radius, startAngle, sweep) {
        var newArc = new Arc(originX + centreX, originY + centreY, radius, startAngle, sweep, true);
        newArc.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, hooklift, originY, originX)) {
                dashItemAndBringForward(newArc);
            }
            newArc.setDrawingOrder(6000);
        }
        if (hooklift.getIsPinned()) {
            newArc.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newArc);
    }

    function addHookliftLine(startX, startY, endX, endY) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY);
        newLine.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, hooklift, originY, originX)) {
                dashItemAndBringForward(newLine);
            }
        }
        if (hooklift.getIsPinned()) {
            newLine.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newLine);
    }

    function addHookliftCircle(centreX, centreY, radius, circleType) {
        var newCircle = new Circle(originX + centreX, originY + centreY, radius, circleType);
        newCircle.setDrawingGroupName(chassisObject.getChassisObjectName() + '.' + config.OBJECT_TYPES.ACCESSORY + '.' + config.ACCESSORY_TYPES.HOOKLIFT);
        if (curVehicle.hasServiceBody()) {
            if (isItemObscured(chassisObject, hooklift, originY, originX)) {
                dashItemAndBringForward(newCircle);
            }
            newCircle.setDrawingOrder(6000);
        }
        if (hooklift.getIsPinned()) {
            newCircle.setColour(config.DRAWING_COLOURS.ORANGE);
        }
        drawingObjectsArray.push(newCircle);
    }
}


function populateDrawingObjectsForTemplateTrailer(trailer) {
    if (curElevation === config.ELEVATION.SIDE && curViewType !== config.VIEW_TYPE.TURNING_CIRCLE) {
        populateDrawingObjectsForTemplateTrailerSideView(trailer);
    } else {
        populateDrawingObjectsForTemplateTrailerTopView(trailer);
    }
}

function populateDrawingObjectsForTemplateTrailerTopView(trailer) {

    var drawingGroupName = trailer.getChassisObjectName();
    if (curViewType === config.VIEW_TYPE.TURNING_CIRCLE) {
        drawingGroupName = config.OBJECT_TYPES.TURNING_CIRCLE;
    }


    drawTrailerTopView(trailer, drawingGroupName);
}

function populateDrawingObjectsForTemplateTrailerSideView(trailer) {
    /*
        Const BottomChassisThickness As Integer = 500
Const BumperHeight As Integer = 1000
Const BumperThickness As Integer = 150
Const SlopOffset As Integer = 350
Const LandingLegsWidth As Integer = 150
Const LandingLegsHeight As Integer = 800

Dim ChassisThickness As Integer = ChassisHeight - KingpinHeight

'clear out any existing objects before drawing the template
SideViewDrawingObjects.Clear(clearScreenOnly)
*/
    var bottomChassisThickness = 400,
        bumperHeight = 1000,
        bumperThickness = 150,
        slopOffset = 350,
        landingLegsWidth = 150,
        landingLegsHeight = 800;

    var chassisHeight = trailer.getChassisHeight(),
        kingpinHeight = trailer.getKingpinHeight(),
        overallLengthExclAccessories = trailer.getOverallLengthExclAccessories(),
        frontOverhang = trailer.getFrontOverhang(),
        kingpinToLandingLegs = trailer.getKingpinToLandingLegs(),
        noOfAxlesRear = trailer.getNumberOfAxlesRear(),
        kingpinToAxle1Rear = trailer.getKingpinToAxle1Rear(),
        axle1RearToAxle2Rear = trailer.getAxle1RearToAxle2Rear(),
        axle2RearToAxle3Rear = trailer.getAxle2RearToAxle3Rear(),
        axle3RearToAxle4Rear = trailer.getAxle3RearToAxle4Rear(),
        tyreRadius = trailer.getTyreRadius(),
        noOfAxlesFront = trailer.getNumberOfAxlesFront(),
        drawbarHeight = trailer.getDrawbarHeight(),
        drawbarLength = trailer.getDrawbarLength(),
        turntableHeight = trailer.getTurntableHeight(),
        turntableLength = trailer.getTurntableLength(),
        axle1FrontToWheelbaseTheoreticalStart = trailer.getAxle1FrontToWheelbaseTheoreticalStart(),
        payloadLength = trailer.getPayloadLength(),
        axle1FrontToAxle2Front = trailer.getAxle1FrontToAxle2Front(),
        axle2FrontToAxle3Front = trailer.getAxle2FrontToAxle3Front(),
        rearMostFrontAxleToAxle1Rear = trailer.getRearMostFrontAxleToAxle1Rear(),
        frontToStartOfTaper = trailer.getFrontToStartOfTaper(),
        wheelbaseTheoretical = trailer.getWheelbaseTheoretical(),
        interLinkFifthWheelOffset = trailer.getFifthWheelOffset(),
        interlinkFifthWheelHeightFromGround = trailer.getFifthWheelHeight();


    var axle1Front = trailer.getAxlesHolder().getAxle1Front();
    var axle2Front = trailer.getAxlesHolder().getAxle2Front();
    var axle3Front = trailer.getAxlesHolder().getAxle3Front();
    var axle1Rear = trailer.getAxlesHolder().getAxle1Rear();
    var axle2Rear = trailer.getAxlesHolder().getAxle2Rear();
    var axle3Rear = trailer.getAxlesHolder().getAxle3Rear();
    var axle4Rear = trailer.getAxlesHolder().getAxle4Rear();

    var chassisThickness = chassisHeight - kingpinHeight;

    var originX = 0, originY = 0;
    
    var fifthWheelOffset, fifthWheelHeight;
    var platformRearThickness, platformFrontThickness, slopeRear, slopeFront;

    var frontAxleGroup = trailer.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
    var rearAxleGroup = trailer.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);

    
    var groupPath = trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.';
    var path = trailer.getChassisObjectName() + '.' + config.OBJECT_TYPES.AXLES + '.';
    var drawingGroupName;

    originX = offerManager.getRigOps().getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(path) - trailer.getDrawbarLength();

    if (trailer.getType() === config.TRAILER_TYPES.SEMI) {


        //if (curVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
        //    fifthWheelOffset = curVehicle.getFifthWheelOffset();
        //} else {
        //    fifthWheelOffset = curVehicle.getAccessoryHolder().getFifthWheel().getOffset();
        //}

        fifthWheelOffset = offerManager.getRigOps().getFifthWheelOffset();

        //originX = ((curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheelOffset)) - trailer.getFrontOverhang();
        originY = 0;
            
        /*
    'front chassis line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            0, _
                                                            ChassisHeight - ChassisThickness, _
                                                            0, _
                                                            ChassisHeight))
                                                            */
        addTrailerLine(0, chassisHeight - chassisThickness, 0, chassisHeight);
        /*
    'top chassis line.
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            0, _
                                                            ChassisHeight, _
                                                            OverallLengthExclAccessories, _
                                                            ChassisHeight))
                                                            */
        addTrailerLine(0, chassisHeight, overallLengthExclAccessories, chassisHeight);
        /*
    'rear bumper rear line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            OverallLengthExclAccessories, _
                                                            ChassisHeight, _
                                                            OverallLengthExclAccessories, _
                                                            ChassisHeight - BumperHeight))
                                                            */
        addTrailerLine(overallLengthExclAccessories, chassisHeight, overallLengthExclAccessories, chassisHeight - bumperHeight);
        /*
    'rear bumper bottom line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            OverallLengthExclAccessories, _
                                                            ChassisHeight - BumperHeight, _
                                                            OverallLengthExclAccessories - BumperThickness, _
                                                            ChassisHeight - BumperHeight))
                                                            */
        addTrailerLine(overallLengthExclAccessories, chassisHeight - bumperHeight, overallLengthExclAccessories - bumperThickness, chassisHeight - bumperHeight);
        /*
    'rear bumper front line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            OverallLengthExclAccessories - BumperThickness, _
                                                            ChassisHeight - BumperHeight, _
                                                            OverallLengthExclAccessories - BumperThickness, _
                                                            ChassisHeight - BottomChassisThickness))
                                                            */
        addTrailerLine(overallLengthExclAccessories - bumperThickness, chassisHeight - bumperHeight, overallLengthExclAccessories - bumperThickness, chassisHeight - bottomChassisThickness);
        /*
    'rear bottom of trailer line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            OverallLengthExclAccessories, _
                                                            ChassisHeight - BottomChassisThickness, _
                                                            FrontOverhang + KingpinToLandingLegs + SlopOffset, _
                                                            ChassisHeight - BottomChassisThickness))
                                                            */
        addTrailerLine(overallLengthExclAccessories, chassisHeight - bottomChassisThickness, frontOverhang + kingpinToLandingLegs + slopOffset, chassisHeight - bottomChassisThickness);
        /*
    'slope line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            FrontOverhang + KingpinToLandingLegs + SlopOffset, _
                                                            ChassisHeight - BottomChassisThickness, _
                                                            FrontOverhang + KingpinToLandingLegs - SlopOffset, _
                                                            ChassisHeight - ChassisThickness))
                                                            */
        addTrailerLine(frontOverhang + kingpinToLandingLegs + slopOffset, chassisHeight - bottomChassisThickness, frontOverhang + kingpinToLandingLegs - slopOffset, chassisHeight - chassisThickness);
        /*
    'front bottom of trailer line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            FrontOverhang + KingpinToLandingLegs - SlopOffset, _
                                                            ChassisHeight - ChassisThickness, _
                                                            0, _
                                                            ChassisHeight - ChassisThickness))
                                                            */
        addTrailerLine(frontOverhang + kingpinToLandingLegs - slopOffset, chassisHeight - chassisThickness, 0, chassisHeight - chassisThickness);
        /*
    'front landing legs line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            FrontOverhang + KingpinToLandingLegs, _
                                                            ChassisHeight - ChassisThickness, _
                                                            FrontOverhang + KingpinToLandingLegs, _
                                                            ChassisHeight - ChassisThickness - LandingLegsHeight))
                                                            */
        addTrailerLine(frontOverhang + kingpinToLandingLegs, chassisHeight - chassisThickness, frontOverhang + kingpinToLandingLegs, chassisHeight - chassisThickness - landingLegsHeight);
        /*
    'top landing legs line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            FrontOverhang + KingpinToLandingLegs, _
                                                            ChassisHeight - ChassisThickness, _
                                                            FrontOverhang + KingpinToLandingLegs + LandingLegsWidth, _
                                                            ChassisHeight - ChassisThickness))
                                                            */
        addTrailerLine(frontOverhang + kingpinToLandingLegs, chassisHeight - chassisThickness, frontOverhang + kingpinToLandingLegs + landingLegsWidth, chassisHeight - chassisThickness);
        /*
    'rear landing legs line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            FrontOverhang + KingpinToLandingLegs + LandingLegsWidth, _
                                                            ChassisHeight - ChassisThickness, _
                                                            FrontOverhang + KingpinToLandingLegs + LandingLegsWidth, _
                                                            ChassisHeight - ChassisThickness - LandingLegsHeight))
                                                            */
        addTrailerLine(frontOverhang + kingpinToLandingLegs + landingLegsWidth, chassisHeight - chassisThickness, frontOverhang + kingpinToLandingLegs + landingLegsWidth, chassisHeight - chassisThickness - landingLegsHeight);
        /*
    'bottom landing legs line
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            FrontOverhang + KingpinToLandingLegs - (LandingLegsWidth / 3), _
                                                            ChassisHeight - ChassisThickness - LandingLegsHeight, _
                                                            FrontOverhang + KingpinToLandingLegs + LandingLegsWidth + (LandingLegsWidth / 3), _
                                                            ChassisHeight - ChassisThickness - LandingLegsHeight))
                                                            */
        addTrailerLine(frontOverhang + kingpinToLandingLegs - (landingLegsWidth / 3), chassisHeight - chassisThickness - landingLegsHeight, frontOverhang + kingpinToLandingLegs + landingLegsWidth + (landingLegsWidth / 3), chassisHeight - chassisThickness - landingLegsHeight);
        /*
    'now add the wheels
    If NoOfAxlesRear > 0 Then

        'draw axle 1 rear
        AddAxle(FrontOverhang + KingpinToAxle1Rear, _
                TyreRadius, _
                TyreRadius, _
                TyreRadius / 2, _
                NoOfPreviousAxles + 1)

    End If
    */

        if (noOfAxlesRear > 0) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' +  axle1Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle1Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle1Rear.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesRear > 1 Then

        'draw axle 2 rear
        AddAxle(FrontOverhang + KingpinToAxle1Rear + Axle1RearToAxle2Rear, _
                TyreRadius, _
                TyreRadius, _
                TyreRadius / 2, _
                NoOfPreviousAxles + 2)

    End If
    */
        if (noOfAxlesRear > 1) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle2Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle2Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle2Rear.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesRear > 2 Then

        'draw axle 3 rear
        AddAxle(FrontOverhang + KingpinToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear, _
                TyreRadius, _
                TyreRadius, _
                TyreRadius / 2, _
                NoOfPreviousAxles + 3)

    End If
    */
        if (noOfAxlesRear > 2) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle3Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle3Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle3Rear.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesRear > 3 Then

        'draw axle 4 rear
        AddAxle(FrontOverhang + KingpinToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear + Axle3RearToAxle4Rear, _
                TyreRadius, _
                TyreRadius, _
                TyreRadius / 2, _
                NoOfPreviousAxles + 4)

    End If
        */

        if (noOfAxlesRear > 3) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle4Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle4Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle4Rear.getRaised(), drawingGroupName);
        }
    } else if (trailer.getType() === config.TRAILER_TYPES.INTERLINK) {


        fifthWheelOffset = offerManager.getRigOps().getFifthWheelOffset();
        

        //originX = ((curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheelOffset)) - trailer.getFrontOverhang();
        originY = 0;

        var rearThickness = 500;
        var rearPlatformHeight = 500;
        var rearPlatformSlopeStart = 1000;
        var rearBumperHeight = 500;
        landingLegsWidth = 150;
        landingLegsHeight = rearThickness + rearBumperHeight;
        /*
        
        Const RearThickness As Integer = 500
Const RearPlatformHeight As Integer = 500
Const RearPlatformSlopeStart As Integer = 1000
Const RearBumperHeight As Integer = 500
Const LandingLegsWidth As Integer = 150
Const LandingLegsHeight As Integer = RearThickness + RearBumperHeight
*/
        
        var frontThickness = chassisHeight - kingpinHeight;
        var bottomSlopeStart = frontToStartOfTaper - (frontToStartOfTaper / 1.8);
        var bottomSlopeEnd = frontToStartOfTaper - (frontToStartOfTaper / 9);
        var interlinkFifthWheelOffsetFrontX = frontOverhang + wheelbaseTheoretical - interLinkFifthWheelOffset - 395; //'395 is front overhang of fifth wheel

        /*
Dim FrontThickness As Integer = ChassisHeight - KingpinHeight
Dim bottomSlopeStart As Integer = _FrontToStartOfTaper - (_FrontToStartOfTaper / 1.8)
Dim bottomSlopeEnd As Integer = _FrontToStartOfTaper - (_FrontToStartOfTaper / 9)
Dim fifthWheelOffset As Integer = _FrontOverhang + WheelbaseTheoretical - _FifthWheelOffset - 395 '395 is front overhang of fifth wheel
*/

        

        /*
'line 1 - front
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        0, _
                                                        ChassisHeight, _
                                                        0, _
                                                        ChassisHeight - FrontThickness))
                                                        */
        addTrailerLine(0, chassisHeight, 0, chassisHeight - frontThickness);
        /*
'line 2 - deck
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        0, _
                                                        ChassisHeight, _
                                                        _FrontToStartOfTaper, _
                                                        ChassisHeight))
                                                        */
        addTrailerLine(0, chassisHeight, frontToStartOfTaper, chassisHeight);
        /*
'line 3 - deck end
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        _FrontToStartOfTaper, _
                                                        ChassisHeight, _
                                                        _FrontToStartOfTaper, _
                                                        ChassisHeight - FrontThickness))
                                                        */
        addTrailerLine(frontToStartOfTaper, chassisHeight, frontToStartOfTaper, chassisHeight - frontThickness);
        /*
'line 4 - slope to rear platform
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        _FrontToStartOfTaper, _
                                                        ChassisHeight - FrontThickness, _
                                                        _FrontToStartOfTaper + RearPlatformHeight - FrontThickness, _
                                                        ChassisHeight - RearPlatformHeight))
                                                        */
        addTrailerLine(frontToStartOfTaper, chassisHeight - frontThickness, frontToStartOfTaper + rearPlatformHeight - frontThickness, chassisHeight - rearPlatformHeight);
        /*
'line 5 - rear platform
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        _FrontToStartOfTaper + RearPlatformHeight - FrontThickness, _
                                                        ChassisHeight - RearPlatformHeight, _
                                                        OverallLengthExclAccessories - RearPlatformSlopeStart, _
                                                        ChassisHeight - RearPlatformHeight))
                                                        */
        addTrailerLine(frontToStartOfTaper + rearPlatformHeight - frontThickness, chassisHeight - rearPlatformHeight, overallLengthExclAccessories - rearPlatformSlopeStart, chassisHeight - rearPlatformHeight);
        /*
'line 6 - slope on rear platform
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        OverallLengthExclAccessories - RearPlatformSlopeStart, _
                                                        ChassisHeight - RearPlatformHeight, _
                                                        OverallLengthExclAccessories, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness / 2))
                                                        */
        addTrailerLine(overallLengthExclAccessories - rearPlatformSlopeStart, chassisHeight - rearPlatformHeight, overallLengthExclAccessories, chassisHeight - rearPlatformHeight - rearThickness / 2);
        /*
'line 7 - rear
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        OverallLengthExclAccessories, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness / 2, _
                                                        OverallLengthExclAccessories, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness))
                                                        */
        addTrailerLine(overallLengthExclAccessories, chassisHeight - rearPlatformHeight - rearThickness/2, overallLengthExclAccessories, chassisHeight - rearPlatformHeight - rearThickness);
        /*
'line 8 - bottom
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        OverallLengthExclAccessories, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness, _
                                                        bottomSlopeEnd, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness))
                                                        */
        addTrailerLine(overallLengthExclAccessories, chassisHeight - rearPlatformHeight - rearThickness, bottomSlopeEnd, chassisHeight - rearPlatformHeight - rearThickness);
        /*
'line 9 - slope on bottom
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        bottomSlopeStart, _
                                                        ChassisHeight - FrontThickness, _
                                                        bottomSlopeEnd, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness))
                                                        */
        addTrailerLine(bottomSlopeStart, chassisHeight - frontThickness, bottomSlopeEnd, chassisHeight - rearPlatformHeight - rearThickness);
        /*
'line 10 - bottom of front platform
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        0, _
                                                        ChassisHeight - FrontThickness, _
                                                        _FrontToStartOfTaper, _
                                                        ChassisHeight - FrontThickness))
                                                        */
        addTrailerLine(0, chassisHeight - frontThickness, frontToStartOfTaper, chassisHeight - frontThickness);
        /*
'line 11 - detail
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        bottomSlopeEnd, _
                                                        ChassisHeight - FrontThickness, _
                                                        bottomSlopeEnd, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness))
                                                        */
        addTrailerLine(bottomSlopeEnd, chassisHeight - frontThickness, bottomSlopeEnd, chassisHeight - rearPlatformHeight - rearThickness);
        /*
'line 12 - detail
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        _FrontToStartOfTaper + RearPlatformHeight - FrontThickness, _
                                                        ChassisHeight - RearPlatformHeight, _
                                                        _FrontToStartOfTaper + RearPlatformHeight - FrontThickness, _
                                                        ChassisHeight - RearPlatformHeight - RearThickness))
                                                        */
        addTrailerLine(frontToStartOfTaper + rearPlatformHeight - frontThickness, chassisHeight - rearPlatformHeight, frontToStartOfTaper + rearPlatformHeight - frontThickness, chassisHeight - rearPlatformHeight - rearThickness);
        /*
'line 13 - landing legs
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        _FrontOverhang + _KingpinToLandingLegs, _
                                                        ChassisHeight - FrontThickness, _
                                                        _FrontOverhang + _KingpinToLandingLegs, _
                                                        ChassisHeight - LandingLegsHeight))
                                                        */
        addTrailerLine(frontOverhang + kingpinToLandingLegs, chassisHeight - frontThickness, frontOverhang + kingpinToLandingLegs, chassisHeight - landingLegsHeight);
        /*
'line 14 - landing legs
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        _FrontOverhang + _KingpinToLandingLegs + LandingLegsWidth, _
                                                        ChassisHeight - FrontThickness, _
                                                        _FrontOverhang + _KingpinToLandingLegs + LandingLegsWidth, _
                                                        ChassisHeight - LandingLegsHeight))
                                                        */
        addTrailerLine(frontOverhang + kingpinToLandingLegs + landingLegsWidth, chassisHeight - frontThickness, frontOverhang + kingpinToLandingLegs + landingLegsWidth, chassisHeight - landingLegsHeight);
        /*
'line 14 - landing legs
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                        _FrontOverhang + _KingpinToLandingLegs - 100, _
                                                        ChassisHeight - LandingLegsHeight, _
                                                        _FrontOverhang + _KingpinToLandingLegs + LandingLegsWidth + 100, _
                                                        ChassisHeight - LandingLegsHeight))
                                                        */
        addTrailerLine(frontOverhang + kingpinToLandingLegs - 100, chassisHeight - landingLegsHeight, frontOverhang + kingpinToLandingLegs + landingLegsWidth + 100, chassisHeight - landingLegsHeight);
        /*
Dim x As UtilDrawingObjectLine

'front of base
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset
x.StartY = ChassisHeight - RearPlatformHeight
x.EndX = fifthWheelOffset + 50
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.47
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX, chassisHeight - rearPlatformHeight, interlinkFifthWheelOffsetFrontX + 50, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.47);
        /*
'top of base
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 50
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.47
x.EndX = fifthWheelOffset + 740
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.47
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 50, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.47, interlinkFifthWheelOffsetFrontX + 740, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.47);
        /*
'rear of base
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 740
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.47
x.EndX = fifthWheelOffset + 790
x.EndY = ChassisHeight - RearPlatformHeight
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 740, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.47, interlinkFifthWheelOffsetFrontX + 790, chassisHeight - rearPlatformHeight);
        /*
'middle front
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 300
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.47
x.EndX = fifthWheelOffset + 300
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.75
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 300, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.47, interlinkFifthWheelOffsetFrontX + 300, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.75);
        /*
'middle middle
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 490
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.47
x.EndX = fifthWheelOffset + 490
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.75
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 490, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.47, interlinkFifthWheelOffsetFrontX + 490, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.75);
        /*
'middle rear
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 510
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.47
x.EndX = fifthWheelOffset + 510
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.75
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 510, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.47, interlinkFifthWheelOffsetFrontX + 510, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.75);
        /*
'front of fw
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 50
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.85
x.EndX = fifthWheelOffset + 50
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight))
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 50, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.85, interlinkFifthWheelOffsetFrontX + 50, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)));
        /*
'top of fw
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 50
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight))
x.EndX = fifthWheelOffset + 740
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight))
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 50, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)), interlinkFifthWheelOffsetFrontX + 740, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)));
        /*
'rear of fw
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 740
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight))
x.EndX = fifthWheelOffset + 900
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.9
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 740, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)), interlinkFifthWheelOffsetFrontX + 900, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.9);
        /*
'bottom of fw
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 900
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.9
x.EndX = fifthWheelOffset + 510
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.75
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 900, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.9, interlinkFifthWheelOffsetFrontX + 510, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.75);
        /*
'bottom of fw
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 510
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.75
x.EndX = fifthWheelOffset + 300
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.75
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 510, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.75, interlinkFifthWheelOffsetFrontX + 300, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.75);
        /*
'bottom of fw
x = New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal)
x.Pen = Colors.Black
x.StartX = fifthWheelOffset + 300
x.StartY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.75
x.EndX = fifthWheelOffset + 50
x.EndY = (ChassisHeight - RearPlatformHeight) + (FifthWheelHeight - (ChassisHeight - RearPlatformHeight)) * 0.85
SideViewDrawingObjects.Add(x)
*/
        addTrailerLine(interlinkFifthWheelOffsetFrontX + 300, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.75, interlinkFifthWheelOffsetFrontX + 50, (chassisHeight - rearPlatformHeight) + (interlinkFifthWheelHeightFromGround - (chassisHeight - rearPlatformHeight)) * 0.85);
        /*
''line 15 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 790, _
'                                                      _FifthWheelHeight, _
'                                                      fifthWheelOffset + 740, _
'                                                      _FifthWheelHeight + 140))
*/
        //addTrailerLine(fifthWheelOffset + 790, fifthWheelHeight, fifthWheelOffset + 740, fifthWheelHeight + 140);
        /*
''line 16 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 740, _
'                                                      _FifthWheelHeight + 140, _
'                                                      fifthWheelOffset + 50, _
'                                                      _FifthWheelHeight + 140))
*/
        //addTrailerLine(fifthWheelOffset + 740, fifthWheelHeight + 140, fifthWheelOffset + 50, fifthWheelHeight + 140);
        /*
''line 17 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 50, _
'                                                      _FifthWheelHeight + 140, _
'                                                      fifthWheelOffset, _
'                                                      _FifthWheelHeight + 0))
*/
        //addTrailerLine(fifthWheelOffset + 50, fifthWheelHeight + 140, fifthWheelOffset, fifthWheelHeight + 0);
        /*
''line 18 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 510, _
'                                                      _FifthWheelHeight + 140, _
'                                                      fifthWheelOffset + 510, _
'                                                      _FifthWheelHeight + 220))
*/
        //addTrailerLine(fifthWheelOffset + 510, fifthWheelHeight + 140, fifthWheelOffset + 510, fifthWheelHeight + 220);
        /*
''line 19 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 300, _
'                                                      _FifthWheelHeight + 140, _
'                                                      fifthWheelOffset + 300, _
'                                                      _FifthWheelHeight + 220))
*/
        //addTrailerLine(fifthWheelOffset + 300, fifthWheelHeight + 140, fifthWheelOffset + 300, fifthWheelHeight + 220);
        /*
''line 20 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 490, _
'                                                      _FifthWheelHeight + 140, _
'                                                      fifthWheelOffset + 490, _
'                                                      _FifthWheelHeight + 220))
*/
        //addTrailerLine(fifthWheelOffset + 490, fifthWheelHeight + 140, fifthWheelOffset + 490, fifthWheelHeight + 220);
        /*
''line 21 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 490, _
'                                                      _FifthWheelHeight + 220, _
'                                                      fifthWheelOffset + 300, _
'                                                      _FifthWheelHeight + 220))
*/
        //addTrailerLine(fifthWheelOffset + 490, fifthWheelHeight + 220, fifthWheelOffset + 300, fifthWheelHeight + 220);
        /*
''line 22 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 300, _
'                                                      _FifthWheelHeight + 220, _
'                                                      fifthWheelOffset + 50, _
'                                                      _FifthWheelHeight + 240))
*/
        //addTrailerLine(fifthWheelOffset + 300, fifthWheelHeight + 220, fifthWheelOffset + 50, fifthWheelHeight + 240);
        /*
''line 23 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 50, _
'                                                      _FifthWheelHeight + 240, _
'                                                      fifthWheelOffset + 50, _
'                                                      _FifthWheelHeight + 300))
*/
        //addTrailerLine(fifthWheelOffset + 50, fifthWheelHeight + 240, fifthWheelOffset + 50, fifthWheelHeight + 300);
        /*
''line 24 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 50, _
'                                                      _FifthWheelHeight + 300, _
'                                                      fifthWheelOffset + 740, _
'                                                      _FifthWheelHeight + 300))
*/
        //addTrailerLine(fifthWheelOffset + 50, fifthWheelHeight + 300, fifthWheelOffset + 740, fifthWheelHeight + 300);
        /*
''line 25 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 740, _
'                                                      _FifthWheelHeight + 300, _
'                                                      fifthWheelOffset + 900, _
'                                                      _FifthWheelHeight + 260))
*/
        //addTrailerLine(fifthWheelOffset + 740, fifthWheelHeight + 300, fifthWheelOffset + 900, fifthWheelHeight + 260);
        /*
''line 25 - fifth wheel
'SideViewDrawingObjects.Add(New UtilDrawingLine(UtilDrawingLine.LineTypes.Normal, _
'                                                      fifthWheelOffset + 900, _
'                                                      _FifthWheelHeight + 260, _
'                                                      fifthWheelOffset + 490, _
'                                                      _FifthWheelHeight + 220))
*/
        //addTrailerLine(fifthWheelOffset + 900, fifthWheelHeight + 260, fifthWheelOffset + 490, fifthWheelHeight + 220);
        /*
'now add the wheels
If NoOfAxlesRear > 0 Then

    'draw axle 1 rear
    AddAxle(FrontOverhang + KingpinToAxle1Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            NoOfPreviousAxles + 1)

End If
*/
        //'now add the wheels
        if (noOfAxlesRear > 0) {

            //'draw axle 1 rear
            //addAxle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius, tyreRadius / 2, noOfPreviousAxles + 1);

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle1Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle1Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle1Rear.getRaised(), drawingGroupName);

        }
        /*
If NoOfAxlesRear > 1 Then

    'draw axle 2 rear
    AddAxle(FrontOverhang + KingpinToAxle1Rear + Axle1RearToAxle2Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            NoOfPreviousAxles + 2)

End If
*/
        if (noOfAxlesRear > 1) {

            //'draw axle 1 rear
            //addAxle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius, tyreRadius / 2, noOfPreviousAxles + 1);

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle2Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle2Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle2Rear.getRaised(), drawingGroupName);

        }
        /*
If NoOfAxlesRear > 2 Then

    'draw axle 3 rear
    AddAxle(FrontOverhang + KingpinToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            NoOfPreviousAxles + 3)

End If
*/
        if (noOfAxlesRear > 2) {

            //'draw axle 1 rear
            //addAxle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius, tyreRadius / 2, noOfPreviousAxles + 1);

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle3Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle3Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle3Rear.getRaised(), drawingGroupName);

        }
        /*
If NoOfAxlesRear > 3 Then

    'draw axle 4 rear
    AddAxle(FrontOverhang + KingpinToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear + Axle3RearToAxle4Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            NoOfPreviousAxles + 4)

End If
        */

        if (noOfAxlesRear > 3) {

            //'draw axle 1 rear
            //addAxle(frontOverhang + kingpinToAxle1Rear, tyreRadius, tyreRadius, tyreRadius / 2, noOfPreviousAxles + 1);

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle4Rear.getNumber();

            //draw outer circle
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle4Rear.getRaised(), drawingGroupName);
            addTrailerCircle(frontOverhang + kingpinToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle4Rear.getRaised(), drawingGroupName);

        }

    } else if (trailer.getType() === config.TRAILER_TYPES.DRAWBAR) {

        //originX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset(); 
        originY = 0;

        /*
        Dim platformRearThickness As Integer = ChassisHeight - TyreRadius * 2
    Dim platformFrontThickness As Integer = platformRearThickness / 2
    Dim slopeRear As Integer = (_FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart) * 3
    Dim slopeFront As Integer = (_FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart) * 2
    Dim swivelLengthConnector As Integer
    */
        platformRearThickness = chassisHeight - tyreRadius * 2;
        platformFrontThickness = platformRearThickness / 2;
        slopeRear = (frontOverhang + axle1FrontToWheelbaseTheoreticalStart) * 3;
        slopeFront = (frontOverhang + axle1FrontToWheelbaseTheoreticalStart) * 2;
        var swivelLengthConnector;

        /*
    'clear out any existing objects before drawing the template
    SideViewDrawingObjects.Clear(clearScreenOnly)
    */

        /*
    If NoOfAxlesFront > 1 Then
        swivelLengthConnector = 800
    Else
        swivelLengthConnector = 600
    End If
        */
        if (noOfAxlesFront > 1) {
            swivelLengthConnector = 800;
        } else {
            swivelLengthConnector = 600;
        }


        /*
    If _DrawbarType = TrailerDrawbarTypes.Conventional Then
        */
        if(trailer.getDrawbarType() === config.DRAWBAR_TYPES.CONVENTIONAL) {
            /*
            'line 1 - drawbar front            
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    0,
                                                                    _DrawbarHeight + 120,
                                                                    0,
                                                                    _DrawbarHeight - 20))
*/ 
            addTrailerLine(0,
                drawbarHeight + 70,
                0,
                drawbarHeight - 70);
            /*
            'line 2 - drawbar top
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    0,
                                                                    _DrawbarHeight + 100,
                                                                    _DrawbarLength,
                                                                    _TurntableHeight - 100))
*/ 
            addTrailerLine(0,
                drawbarHeight + 50,
                drawbarLength,
                turntableHeight - 100);
            /*
            'line 3 - drawbar bottom
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    0,
                                                                    _DrawbarHeight,
                                                                    _DrawbarLength,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(0,
                drawbarHeight - 50,
                drawbarLength,
                turntableHeight - 200);
            /*
            'coupling
            SideViewDrawingObjects.Add(New UtilDrawingObjectCircle(_DrawbarLength,
                                                                _TurntableHeight - 150,
                                                                50,
                                                                UtilDrawingObjectCircle.CircleTypes.Other))
*/
            
            addTrailerCircle(drawbarLength,
                turntableHeight - 150,
                50,
                tyreRadius, CIRCLE_TYPE.OTHER);
            /*
            'line 1 - swivel
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                                                                    ChassisHeight - platformFrontThickness,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                                                                    _TurntableHeight))
*/
            addTrailerLine(drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                chassisHeight - platformFrontThickness,
                drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                turntableHeight);
            /*
            'line 2 - swivel
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                                                                    ChassisHeight - platformFrontThickness,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                                                                    _TurntableHeight))
*/
            addTrailerLine(drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                chassisHeight - platformFrontThickness,
                drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                turntableHeight);
            /*
            'line 1 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + 150,
                                                                    _TurntableHeight,
                                                                    _DrawbarLength + TurnTableLength - 200,
                                                                    _TurntableHeight))
*/
            addTrailerLine(drawbarLength + 150,
                turntableHeight,
                drawbarLength + turntableLength - 200,
                turntableHeight);
            /*
            'line 2 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + 150,
                                                                    _TurntableHeight,
                                                                    _DrawbarLength,
                                                                    _TurntableHeight - 125))
*/
            addTrailerLine(drawbarLength + 150,
                turntableHeight,
                drawbarLength,
                turntableHeight - 125);
            /*
            'line 3 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + TurnTableLength - 200,
                                                                    _TurntableHeight,
                                                                    _DrawbarLength + TurnTableLength,
                                                                    _TurntableHeight - 125))
*/
            addTrailerLine(drawbarLength + turntableLength - 200,
                turntableHeight,
                drawbarLength + turntableLength,
                turntableHeight - 125);
            /*
            'line 4 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + TurnTableLength,
                                                                    _TurntableHeight - 125,
                                                                    _DrawbarLength + TurnTableLength,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(drawbarLength + turntableLength,
                turntableHeight - 125,
                drawbarLength + turntableLength,
                turntableHeight - 200);
            /*
            'line 5 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength,
                                                                    _TurntableHeight - 200,
                                                                    _DrawbarLength + TurnTableLength,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(drawbarLength,
                turntableHeight - 200,
                drawbarLength + turntableLength,
                turntableHeight - 200);
        } else {
            /*  
            'line 1 - drawbar top
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    0,
                                                                    _DrawbarHeight + 120,
                                                                    _DrawbarLength * 0.8,
                                                                    _DrawbarHeight + 120))
*/
            addTrailerLine(0,
                drawbarHeight + 60,
                drawbarLength * 0.8,
                drawbarHeight + 120);
            /*
            'line 2 - top diagonal
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength * 0.8,
                                                                    _DrawbarHeight + 120,
                                                                    _DrawbarLength,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(drawbarLength * 0.8,
                drawbarHeight + 120,
                drawbarLength,
                turntableHeight - 200);
            /*
            'line 3 - front
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    0,
                                                                    _DrawbarHeight + 140,
                                                                    0,
                                                                    _DrawbarHeight - 20))
*/
            addTrailerLine(0,
                drawbarHeight + 80,
                0,
                drawbarHeight - 80);
            /*
            'line 4 - drawbar bottom
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    0,
                                                                    _DrawbarHeight,
                                                                    _DrawbarLength,
                                                                    _DrawbarHeight))
*/
            addTrailerLine(0,
                drawbarHeight - 60,
                drawbarLength,
                drawbarHeight);
            /*
            'line 5 - drawbar bottom diagonal
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength,
                                                                    _DrawbarHeight,
                                                                    _DrawbarLength + TurnTableLength / 2,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(drawbarLength,
                drawbarHeight,
                drawbarLength + turntableLength / 2,
                turntableHeight - 200);
            /*
            'coupling
            SideViewDrawingObjects.Add(New UtilDrawingObjectCircle(_DrawbarLength,
                                                                _DrawbarHeight + 100,
                                                                75,
                                                                UtilDrawingObjectCircle.CircleTypes.Other))
*/
            addTrailerCircle(drawbarLength,
                drawbarHeight + 100,
                75,
                CIRCLE_TYPE.OTHER);
            /*
            'line 1 - swivel
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                                                                    ChassisHeight - platformFrontThickness,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                                                                    _TurntableHeight))
*/
            addTrailerLine(drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                chassisHeight - platformFrontThickness,
                drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart - swivelLengthConnector / 2,
                turntableHeight);
            /*
            'line 2 - swivel
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                                                                    ChassisHeight - platformFrontThickness,
                                                                    _DrawbarLength + _FrontOverhang + _Axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                                                                    _TurntableHeight))
*/
            addTrailerLine(drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                chassisHeight - platformFrontThickness,
                drawbarLength + frontOverhang + axle1FrontToWheelbaseTheoreticalStart + swivelLengthConnector / 2,
                turntableHeight);
            /*
            'line 1 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + 150,
                                                                    _TurntableHeight,
                                                                    _DrawbarLength + TurnTableLength - 150,
                                                                    _TurntableHeight))
*/
            addTrailerLine(drawbarLength + 150,
                turntableHeight,
                drawbarLength + turntableLength - 150,
                turntableHeight);
            /*
            'line 2 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + 150,
                                                                    _TurntableHeight,
                                                                    _DrawbarLength,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(drawbarLength + 150,
                turntableHeight,
                drawbarLength,
                turntableHeight - 200);
            /*
            'line 3 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength + TurnTableLength - 150,
                                                                    _TurntableHeight,
                                                                    _DrawbarLength + TurnTableLength,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(drawbarLength + turntableLength - 150,
                turntableHeight,
                drawbarLength + turntableLength,
                turntableHeight - 200);
            /*
            'line 4 - turn table
            SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                    _DrawbarLength,
                                                                    _TurntableHeight - 200,
                                                                    _DrawbarLength + TurnTableLength,
                                                                    _TurntableHeight - 200))
*/
            addTrailerLine(drawbarLength,
                turntableHeight - 200,
                drawbarLength + turntableLength,
                turntableHeight - 200);
        }
    
    //'common lines
    /*
    'line 1 - front
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength,
                                                            ChassisHeight,
                                                            _DrawbarLength,
                                                            ChassisHeight - platformFrontThickness))
*/
        addTrailerLine(drawbarLength,
                chassisHeight,
                drawbarLength,
                chassisHeight - platformFrontThickness);
        /*
    'line 2 - top
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength,
                                                            ChassisHeight,
                                                            _DrawbarLength + PayloadLength,
                                                            ChassisHeight))
*/
        addTrailerLine(drawbarLength,
                chassisHeight,
                drawbarLength + payloadLength,
                chassisHeight);
        /*
    'line 3 - back
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength + PayloadLength,
                                                            ChassisHeight,
                                                            _DrawbarLength + PayloadLength,
                                                            ChassisHeight - platformRearThickness))
*/
        addTrailerLine(drawbarLength + payloadLength,
                chassisHeight,
                drawbarLength + payloadLength,
                chassisHeight - platformRearThickness);
        /*
    'line 4 - bottom back
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength + PayloadLength,
                                                            ChassisHeight - platformRearThickness,
                                                            _DrawbarLength + slopeRear,
                                                            ChassisHeight - platformRearThickness))
*/
        addTrailerLine(drawbarLength + payloadLength,
                chassisHeight - platformRearThickness,
                drawbarLength + slopeRear,
                chassisHeight - platformRearThickness);
        /*
    'line 5 - slope
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength + slopeFront,
                                                            ChassisHeight - platformFrontThickness,
                                                            _DrawbarLength + slopeRear,
                                                            ChassisHeight - platformRearThickness))
*/
        addTrailerLine(drawbarLength + slopeFront,
                chassisHeight - platformFrontThickness,
                drawbarLength + slopeRear,
                chassisHeight - platformRearThickness);
        /*
    'line 6 - bottom front
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength + slopeFront,
                                                            ChassisHeight - platformFrontThickness,
                                                            _DrawbarLength,
                                                            ChassisHeight - platformFrontThickness))
*/
        addTrailerLine(drawbarLength + slopeFront,
                chassisHeight - platformFrontThickness,
                drawbarLength,
                chassisHeight - platformFrontThickness);
        /*
    'line 7 - front
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength + slopeFront,
                                                            ChassisHeight,
                                                            _DrawbarLength + slopeFront,
                                                            ChassisHeight - platformFrontThickness))
*/
        addTrailerLine(drawbarLength + slopeFront,
                chassisHeight,
                drawbarLength + slopeFront,
                chassisHeight - platformFrontThickness);
        /*
    'line 8 - front
    SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal,
                                                                _DrawbarLength + slopeRear,
                                                            ChassisHeight,
                                                            _DrawbarLength + slopeRear,
                                                            ChassisHeight - platformRearThickness))
*/
        addTrailerLine(drawbarLength + slopeRear,
                chassisHeight,
                drawbarLength + slopeRear,
                chassisHeight - platformRearThickness);
        /*
    Dim previousAxles As Integer = NoOfPreviousAxles
*/
        
        /*
    'now add the wheels
    If NoOfAxlesFront > 0 Then

        previousAxles += 1

        'draw axle 1 front
        AddAxle(_DrawbarLength + _FrontOverhang,
                TyreRadius,
                TyreRadius,
                TyreRadius / 2,
                previousAxles)

    End If
*/
        if (noOfAxlesFront > 0) {

            drawingGroupName = groupPath + frontAxleGroup.getId() + '.' + axle1Front.getNumber();

            addTrailerCircle(drawbarLength + frontOverhang, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle1Front.getRaised(), drawingGroupName);
            addTrailerCircle(drawbarLength + frontOverhang, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle1Front.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesFront > 1 Then

        previousAxles += 1

        'draw axle 2 front
        AddAxle(_DrawbarLength + _FrontOverhang + _Axle1FrontToAxle2Front,
                TyreRadius,
                TyreRadius,
                TyreRadius / 2,
                previousAxles)

    End If
*/
        if (noOfAxlesFront > 1) {

            drawingGroupName = groupPath + frontAxleGroup.getId() + '.' + axle2Front.getNumber();

            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle2Front.getRaised(), drawingGroupName);
            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle2Front.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesFront > 2 Then

        previousAxles += 1

        'draw axle 3 front
        AddAxle(_DrawbarLength + _FrontOverhang + _Axle1FrontToAxle2Front + _Axle2FrontToAxle3Front,
                TyreRadius,
                TyreRadius,
                TyreRadius / 2,
                previousAxles)

    End If
*/
        if (noOfAxlesFront > 2) {

            drawingGroupName = groupPath + frontAxleGroup.getId() + '.' + axle3Front.getNumber(); 

            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle3Front.getRaised(), drawingGroupName);
            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle3Front.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesRear > 0 Then

        previousAxles += 1

        'draw axle 1 rear
        AddAxle(_DrawbarLength + _FrontOverhang + _Axle1FrontToAxle2Front + _Axle2FrontToAxle3Front +
                RearMostFrontAxleToAxle1Rear,
                TyreRadius,
                TyreRadius,
                TyreRadius / 2,
                previousAxles)

    End If
*/
        if (noOfAxlesRear > 0) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle1Rear.getNumber();

            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle1Rear.getRaised(), drawingGroupName);
            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle1Rear.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesRear > 1 Then

        previousAxles += 1

        'draw axle 2 rear
        AddAxle(_DrawbarLength + _FrontOverhang + _Axle1FrontToAxle2Front + _Axle2FrontToAxle3Front +
                RearMostFrontAxleToAxle1Rear + Axle1RearToAxle2Rear,
                TyreRadius,
                TyreRadius,
                TyreRadius / 2,
                previousAxles)

    End If
*/
        if (noOfAxlesRear > 1) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle2Rear.getNumber();

            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle2Rear.getRaised(), drawingGroupName);
            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle2Rear.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesRear > 2 Then

        previousAxles += 1

        'draw axle 3 rear
        AddAxle(_DrawbarLength + _FrontOverhang + _Axle1FrontToAxle2Front + _Axle2FrontToAxle3Front +
                RearMostFrontAxleToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear,
                TyreRadius,
                TyreRadius,
                TyreRadius / 2,
                previousAxles)

    End If
*/
        if (noOfAxlesRear > 2) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle3Rear.getNumber();

            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle3Rear.getRaised(), drawingGroupName);
            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle3Rear.getRaised(), drawingGroupName);
        }
        /*
    If NoOfAxlesRear > 3 Then

        previousAxles += 1

        'draw axle 4 rear
        AddAxle(_DrawbarLength + _FrontOverhang + _Axle1FrontToAxle2Front + _Axle2FrontToAxle3Front +
                RearMostFrontAxleToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear + Axle3RearToAxle4Rear,
                TyreRadius,
                TyreRadius,
                TyreRadius / 2,
                previousAxles)

    End If
        */
        if (noOfAxlesRear > 3) {

            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle4Rear.getNumber();

            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle4Rear.getRaised(), drawingGroupName);
            addTrailerCircle(drawbarLength + frontOverhang + axle1FrontToAxle2Front + axle2FrontToAxle3Front + rearMostFrontAxleToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle4Rear.getRaised(), drawingGroupName);
        }
    } else if (trailer.getType() === config.TRAILER_TYPES.PUP) { 

        //originX = curVehicle.getPortionOfOverallLengthOverhangingFront() + curVehicle.getFrontOverhang() + curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() + curVehicle.getAccessoryHolder().getHitch().getOffset();
        originY = 0;

        /*
        Dim drawbarMiddleY As Integer = TyreRadius * 2 - 95
Dim platformRearThickness As Integer = ChassisHeight - TyreRadius * 2
Dim platformFrontThickness As Integer = platformRearThickness / 2
Dim slopeRear As Integer = _DrawbarLength * 3
Dim slopeFront As Integer = _DrawbarLength * 2
*/
        var drawbarMiddleY = tyreRadius * 2 - 95;
        platformRearThickness = chassisHeight - tyreRadius * 2;
        platformFrontThickness = platformRearThickness / 2;
        slopeRear = drawbarLength * 3;
        slopeFront = drawbarLength * 2;


        var hitchPointToAxle1Rear = trailer.getHitchPointToAxle1Rear();
        /*
'clear out any existing objects before drawing the template
SideViewDrawingObjects.Clear(clearScreenOnly)
*/

        /*
If _DrawbarType = TrailerDrawbarTypes.Conventional Then
*/
        if (trailer.getDrawbarType() === config.DRAWBAR_TYPES.CONVENTIONAL) {
            /*
        drawbarMiddleY = TyreRadius * 2 - 95
        */
            drawbarMiddleY = tyreRadius * 2 - 95;
            /*
        'line 1 - drawbar front            
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                0, _
                                                                _DrawbarHeight + 120, _
                                                                0, _
                                                                _DrawbarHeight - 20))
*/
            addTrailerLine(0,
                drawbarHeight + 70,
                0,
                drawbarHeight - 70);
            /*
        'line 2 - drawbar top
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                0, _
                                                                _DrawbarHeight + 100, _
                                                                _DrawbarLength, _
                                                                drawbarMiddleY + 50))
                                                                */
            addTrailerLine(0,
                drawbarHeight + 50,
                drawbarLength,
                drawbarMiddleY + 50);
            /*
        'line 3 - drawbar bottom
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                0, _
                                                                _DrawbarHeight, _
                                                                _DrawbarLength, _
                                                                drawbarMiddleY - 50))
                                                                */
            addTrailerLine(0,
                drawbarHeight - 50,
                drawbarLength,
                drawbarMiddleY - 50);
            /*
        'line 4 - right side
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength + PayloadLength, _
                                                                ChassisHeight, _
                                                                _DrawbarLength + PayloadLength, _
                                                                drawbarMiddleY - 50))
                                                                */
            addTrailerLine(drawbarLength + payloadLength,
                chassisHeight,
                drawbarLength + payloadLength,
                drawbarMiddleY - 50);
            /*
        'line 5 - left side
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength, _
                                                                ChassisHeight, _
                                                                _DrawbarLength, _
                                                                drawbarMiddleY - 50))
                                                                */
            addTrailerLine(drawbarLength,
                chassisHeight,
                drawbarLength,
                drawbarMiddleY - 50);
            /*
        'line 6 - top
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength, _
                                                                drawbarMiddleY - 50, _
                                                                _DrawbarLength + PayloadLength, _
                                                                drawbarMiddleY - 50))
                                                                */
            addTrailerLine(drawbarLength,
                drawbarMiddleY - 50,
                drawbarLength + payloadLength,
                drawbarMiddleY - 50);
            /*
        'coupling
        SideViewDrawingObjects.Add(New UtilDrawingObjectCircle(_DrawbarLength, _
                                                            drawbarMiddleY, _
                                                            50, _
                                                            UtilDrawingObjectCircle.CircleTypes.Other))
                                                            */
            
            addTrailerCircle(drawbarLength, drawbarMiddleY, 50, CIRCLE_TYPE.OTHER);
        } else {
            /*
    Else
    */

            /*
        drawbarMiddleY = TyreRadius
        */
            drawbarMiddleY = tyreRadius;
            /*
        'line 1 - drawbar top
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                0, _
                                                                _DrawbarHeight + 120, _
                                                                _DrawbarLength * 0.8, _
                                                                _DrawbarHeight + 120))
                                                                */
            addTrailerLine(0,
                drawbarHeight + 70,
                drawbarLength * 0.8,
                drawbarMiddleY + 120);
            /*
        'line 2 - top diagonal
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength * 0.8, _
                                                                _DrawbarHeight + 120, _
                                                                _DrawbarLength, _
                                                                drawbarMiddleY + 250))
                                                                */
            addTrailerLine(drawbarLength * 0.8,
                drawbarMiddleY + 120,
                drawbarLength,
                drawbarMiddleY + 250);
            /*
        'line 3 - drawbar front
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                0, _
                                                                _DrawbarHeight + 140, _
                                                                0, _
                                                                _DrawbarHeight - 20))
                                                                */
            addTrailerLine(0,
                drawbarHeight + 90,
                0,
                drawbarHeight - 70);
            /*
        'line 4 - drawbar bottom
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                0, _
                                                                _DrawbarHeight, _
                                                                _DrawbarLength, _
                                                                _DrawbarHeight))
                                                                */
            addTrailerLine(0,
                drawbarHeight - 50,
                drawbarLength,
                drawbarHeight);
            /*
        'line 5 - drawbar bottom diagonal
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength, _
                                                                _DrawbarHeight, _
                                                                _DrawbarLength * 5 / 3, _
                                                                drawbarMiddleY + 250))
                                                                */
            addTrailerLine(drawbarLength,
                drawbarHeight,
                drawbarLength * 5 / 3,
                drawbarMiddleY + 250);
            /*
        'line 6 - right side
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength + PayloadLength, _
                                                                ChassisHeight, _
                                                                _DrawbarLength + PayloadLength, _
                                                                drawbarMiddleY + 250))
                                                                */
            addTrailerLine(drawbarLength + payloadLength,
                chassisHeight,
                drawbarLength + payloadLength,
                drawbarMiddleY + 250);
            /*
        'line 7 - left side
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength, _
                                                                ChassisHeight, _
                                                                _DrawbarLength, _
                                                                drawbarMiddleY + 250))
                                                                */
            addTrailerLine(drawbarLength,
                chassisHeight,
                drawbarLength,
                drawbarMiddleY + 250);
            /*
        'line 8 - top
        SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                                _DrawbarLength, _
                                                                drawbarMiddleY + 250, _
                                                                _DrawbarLength + PayloadLength, _
                                                                drawbarMiddleY + 250))
                                                                */
            addTrailerLine(drawbarLength,
                drawbarMiddleY + 250,
                drawbarLength + payloadLength,
                drawbarMiddleY + 250);
            /*
        'coupling
        SideViewDrawingObjects.Add(New UtilDrawingObjectCircle(_DrawbarLength, _
                                                            _DrawbarHeight + 100, _
                                                            75, _
                                                            UtilDrawingObjectCircle.CircleTypes.Other))
                                                            */
            addTrailerCircle(drawbarLength, drawbarHeight + 100, 75, CIRCLE_TYPE.OTHER);
        }
        /*
End If
*/

        /*
'common lines

'line 1 - top
SideViewDrawingObjects.Add(New UtilDrawingObjectLine(UtilDrawingObjectLine.LineTypes.Normal, _
                                                            _DrawbarLength, _
                                                        ChassisHeight, _
                                                        _DrawbarLength + PayloadLength, _
                                                        ChassisHeight))
                                                        */
        addTrailerLine(drawbarLength,
                chassisHeight,
                drawbarLength + payloadLength,
                chassisHeight);
        /*
Dim previousAxles As Integer = NoOfPreviousAxles
*/
        
        /*
'now add the wheels
If NoOfAxlesRear > 0 Then


        
    previousAxles += 1
    

        
    'draw axle 1 rear
    AddAxle(HitchPointToAxle1Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            previousAxles)
            

        
End If
*/
        if (noOfAxlesRear > 0) {
            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle1Rear.getNumber();

            addTrailerCircle(hitchPointToAxle1Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle1Rear.getRaised(), drawingGroupName);
            addTrailerCircle(hitchPointToAxle1Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle1Rear.getRaised(), drawingGroupName);
        }
        /*
If NoOfAxlesRear > 1 Then

    previousAxles += 1

    'draw axle 2 rear
    AddAxle(HitchPointToAxle1Rear + Axle1RearToAxle2Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            previousAxles)

End If
*/
        if (noOfAxlesRear > 1) {
            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle2Rear.getNumber();

            addTrailerCircle(hitchPointToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle2Rear.getRaised(), drawingGroupName);
            addTrailerCircle(hitchPointToAxle1Rear + axle1RearToAxle2Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle2Rear.getRaised(), drawingGroupName);
        }
        /*
If NoOfAxlesRear > 2 Then

    previousAxles += 1

    'draw axle 3 rear
    AddAxle(HitchPointToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            previousAxles)

End If
*/
        if (noOfAxlesRear > 2) {
            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle3Rear.getNumber();

            addTrailerCircle(hitchPointToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle3Rear.getRaised(), drawingGroupName);
            addTrailerCircle(hitchPointToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle3Rear.getRaised(), drawingGroupName);
        }
        /*
If NoOfAxlesRear > 3 Then

    previousAxles += 1

    'draw axle 4 rear
    AddAxle(HitchPointToAxle1Rear + Axle1RearToAxle2Rear + Axle2RearToAxle3Rear + Axle3RearToAxle4Rear, _
            TyreRadius, _
            TyreRadius, _
            TyreRadius / 2, _
            previousAxles)

End If
        */

        if (noOfAxlesRear > 3) {
            drawingGroupName = groupPath + rearAxleGroup.getId() + '.' + axle4Rear.getNumber();

            addTrailerCircle(hitchPointToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius, CIRCLE_TYPE.OUTER, !axle4Rear.getRaised(), drawingGroupName);
            addTrailerCircle(hitchPointToAxle1Rear + axle1RearToAxle2Rear + axle2RearToAxle3Rear + axle3RearToAxle4Rear, tyreRadius, tyreRadius / 2, CIRCLE_TYPE.INNER, !axle4Rear.getRaised(), drawingGroupName);
        }
    }

    function addTrailerLine(startX, startY, endX, endY) {
        var newLine = new Line(originX + startX, originY + startY, originX + endX, originY + endY);
        newLine.setDrawingGroupName(trailer.getChassisObjectName());
        newLine.setDrawingOrder(7000);
        drawingObjectsArray.push(newLine);
    }

    function addTrailerCircle(centreX, centreY, radius, circleType, drawGroundline, drawingGroupName) {
        var newCircle = new Circle(originX + centreX, originY + centreY, radius, circleType);
        
        if (drawGroundline === true) {
            newCircle.setDrawGroundline(true);
        }

        if (drawingGroupName !== undefined) {
            newCircle.setDrawingGroupName(drawingGroupName);
        } else {
            newCircle.setDrawingGroupName(trailer.getChassisObjectName());
        }
        newCircle.setDrawingOrder(7000);
        drawingObjectsArray.push(newCircle);
    }
}
