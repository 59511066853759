<template>
    <tr class="menu-li menu-li-value" :class="upDownBoxValue.customCss"
        v-visible="isVisible()">
        <td>
            <div class="menu-label-div menu-label-upodownbox">
                <span>{{ upDownBoxValue.label.value !== undefined ? upDownBoxValue.label.value : upDownBoxValue.label }}</span>
                <div class="menu-tooltip-div" v-if="upDownBoxValue.tooltipMessageBesideLabel === true">
                    <a data-placement="top" v-if="upDownBoxValue.tooltipMessage !== undefined"
                        @click="upDownBoxValue.showPopover" :id="upDownBoxValue.tooltipId"
                        :data-content="tooltipMessageText()"><i class="fa fa-info-circle fa-lg"></i></a>
                </div>
            </div>
        </td>
        <td class="menu-value-td">
            <div class="flex-center menu-flex-end">
                <div class="increment-div info-tooltip-div" :class="{ 'no-content': upDownBoxValue.addNoContentCss() }">
                    <a data-placement="top" v-if="upDownBoxValue.tooltipMessage !== undefined"
                        @click="upDownBoxValue.showPopover" :id="upDownBoxValue.tooltipId"
                        :data-content="tooltipMessageText()">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a>
                </div>
                <div v-if="upDownBoxValue.displayInfoTooltip === true" class="increment-div input-tooltip-div"
                    :class="{ 'increment-div-inactive': isInfoTooltipReadOnly() }">
                    <a href="#" data-placement="top" @click="upDownBoxValue.infoTooltip.showPopover"
                        :id="upDownBoxValue.infoTooltip.id">
                        <i class="fa fa-info-circle fa-lg"></i>
                    </a>
                </div>
                <div v-if="upDownBoxValue.displayInputTooltip() === true" class="increment-div input-tooltip-div"
                    :class="{ 'increment-div-inactive': isInputTooltipReadOnly() }">
                    <a href="#" data-placement="top" @click="upDownBoxValue.inputTooltip.showPopover"
                        :id="upDownBoxValue.inputTooltip.id">
                        <i class="fa fa-book-open fa-lg"></i>
                    </a>
                </div>
                <div class="override-updownbox-div">
                    <div
                        @click="inputDivClick">
                        <input class="form-control login-input menu-input menu-input-number default-width-tel"
                            type="tel" v-model.lazy="upDownBoxValue.value.value"
                            @click="inputClickFunction"
                            @focus="inputFocusFunction"
                            @blur="upDownBoxValue.revertErrorValueOnBlur"
                            @keyup.enter="handleEnterKey"
                            :readonly="upDownBoxValue.override.value === false || isReadOnly()"
                            :id="upDownBoxValue.id"
                            :title="upDownBoxValue.override.value === false && displayOverride() ? upDownBoxValue.overrideTooltipMessage : upDownBoxValue.errorMessageObv.value"
                            :class="{ 'override-false': addOverrideFalseClass(), 'override-cursor': addOverrideCursorClass() }"
                            data-toggle="tooltip" />
                    </div>
                    <div v-if="displayOverride()"
                        class="override-updownbox-checkbox" @click="upDownBoxValue.toggleOverride"
                        :class="{ 'override-false': upDownBoxValue.override.value === false }" :id="upDownBoxValue.id"
                        :title="upDownBoxValue.override.value === false ? upDownBoxValue.overrideTooltipMessage : ''"
                        data-toggle="tooltip">
                        <i class="far fa-lg"
                            :class="{ 'fa-check-square': upDownBoxValue.override.value === true, 'fa-square': upDownBoxValue.override.value === false }"
                            data-bind="css: { 'fa-check-square': override() === true, 'fa-square': override() === false }"></i>
                    </div>
                </div>
                <template v-if="isReadOnlyFalse()">
                    <div class="increment-div decrement-div decrement-value" @click="upDownBoxValue.decrementValue($event)"
                        :class="{ 'increment-div-active': upDownBoxValue.override.value === true, 'increment-div-inactive': upDownBoxValue.override.value === false }">
                        <i class="fa fa-minus-circle fa-lg"></i>
                    </div>
                    <div class="increment-div increment-value" @click="upDownBoxValue.incrementValue($event)"
                        :class="{ 'increment-div-active': upDownBoxValue.override.value === true, 'increment-div-inactive': upDownBoxValue.override.value === false }">
                        <i class="fa fa-plus-circle fa-lg"></i>
                    </div>
                </template>
                <template v-else>
                    <div class="increment-div decrement-div"></div>
                    <div class="increment-div"></div>
                </template>

            </div>
        </td>
    </tr>
</template>

<script>
import { isRef, toRef, nextTick } from 'vue';

const $ = window.$;

export default {
    props: {
        valueFromParent: Object
    },
    setup(props) {
        // const upDownBoxValue = ref(props.valueFromParent);
        const upDownBoxValue = toRef(props, "valueFromParent");

        function inputDivClick() {
            if (upDownBoxValue.value.permissionObv.value.readOnly === false && upDownBoxValue.value.permissionObv.value.override === true && upDownBoxValue.value.override.value === false) { 
                upDownBoxValue.value.toggleOverride();
            }
        }

        function inputClickFunction() {
            if(upDownBoxValue.value.isMac === true) {
                upDownBoxValue.value.selectAllText();
            }
        }

        function inputFocusFunction() {
            if(upDownBoxValue.value.isMac === false) {
                upDownBoxValue.value.selectAllText();
            }
        }

        function tooltipMessageText() {
            $('a').webuiPopover('destroy');
            if(isRef(upDownBoxValue.value.tooltipMessage)) {
                return upDownBoxValue.value.tooltipMessage.value;
            } else {
                return upDownBoxValue.value.tooltipMessage;
            }
        }

        function handleEnterKey() {
            nextTick(function(){
                $('#' + upDownBoxValue.value.id).val(upDownBoxValue.value.value.value);
            });
        }

        function isVisible() {
            if(isRef(upDownBoxValue.value.permissionObv) && upDownBoxValue.value.permissionObv.value) {
                return upDownBoxValue.value.permissionObv.value.visible;
            } else if(upDownBoxValue.value.permissionObv.visible) {
                return upDownBoxValue.value.permissionObv.visible;
            } else  {
                return false;
            }
        }

        function isInfoTooltipReadOnly() {
            if(isRef(upDownBoxValue.value.permissionObv)) {
                return upDownBoxValue.value.infoTooltip.permissionObv.value.readOnly;
            } else {
                return upDownBoxValue.value.infoTooltip.permissionObv.readOnly;
            }
        }

        function isInputTooltipReadOnly() {
            if(isRef(upDownBoxValue.value.permissionObv)) {
                return upDownBoxValue.value.permissionObv.value.readOnly;
            } else {
                return upDownBoxValue.value.permissionObv.readOnly;
            }
        }

        function isReadOnly() {
            if(isRef(upDownBoxValue.value.permissionObv) && upDownBoxValue.value.permissionObv.value) {
                return upDownBoxValue.value.permissionObv.value.readOnly === true;
            } else if(upDownBoxValue.value.permissionObv && upDownBoxValue.value.permissionObv.readOnly){
                return upDownBoxValue.value.permissionObv.readOnly === true;
            } else {
                return false;
            }
        }

        function isReadOnlyFalse() {
            if(isRef(upDownBoxValue.value.permissionObv) && upDownBoxValue.value.permissionObv.value) {
                return upDownBoxValue.value.permissionObv.value.readOnly === false;
            } else if(upDownBoxValue.value.permissionObv.readOnly) {
                return upDownBoxValue.value.permissionObv.readOnly === false;
            } else {
                return false;
            }
        }

        function returnReadOnlyValue() {
            if(isRef(upDownBoxValue.value.permissionObv)) {
                return upDownBoxValue.value.permissionObv.value.readOnly;
            } else {
                return upDownBoxValue.value.permissionObv.readOnly;
            }
        }

        function addOverrideFalseClass() {
            if(isRef(upDownBoxValue.value.permissionObv) && upDownBoxValue.value.permissionObv.value) {
                return upDownBoxValue.value.override.value === false || upDownBoxValue.value.permissionObv.value.readOnly === true;
            } else {
                return upDownBoxValue.value.override.value === false || upDownBoxValue.value.permissionObv.readOnly === true;
            }
        }

        function addOverrideCursorClass() {
            if(isRef(upDownBoxValue.value.permissionObv)) {
                return upDownBoxValue.value.override.value === false && upDownBoxValue.value.permissionObv.value.readOnly === false;
            } else {
                return upDownBoxValue.value.override.value === false && upDownBoxValue.value.permissionObv.readOnly === false;
            }
        }

        function displayOverride() {
            if(isRef(upDownBoxValue.value.permissionObv) && upDownBoxValue.value.permissionObv.value) {
                return upDownBoxValue.value.permissionObv.value.readOnly === false && upDownBoxValue.value.permissionObv.value.override === true;
            } else {
                return upDownBoxValue.value.permissionObv.readOnly === false && upDownBoxValue.value.permissionObv.override === true;
            }
        }

        return {
            upDownBoxValue,
            inputDivClick,
            inputClickFunction,
            inputFocusFunction,
            tooltipMessageText,
            handleEnterKey,
            isVisible,
            isInfoTooltipReadOnly,
            isInputTooltipReadOnly,
            isReadOnly,
            isReadOnlyFalse,
            returnReadOnlyValue,
            addOverrideFalseClass,
            addOverrideCursorClass,
            displayOverride
        }
    }
}
</script>

<style lang="scss" scoped>
</style>