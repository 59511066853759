import utilLegislationAttributeObjectCode from './enum/utilLegislationAttributeObjectCode';
import globals from './globals';
import config from './config';
import logger from './logger';
// import {logger , globals, config } from './';

    

        function Interpreter() {
            var self = this;
            let interpretCounter = 0;
            let loadVariableCounter = 0;
            let indentMultiplier = 0;
            let lookupTable = {};
            let attributeAndVariableEvluationTimes = {};
            let evaluationTree = {};
            let circularReferences = [];
            var booleanResults = {
                Equal: 'EQUAL',
                Less: 'LESS',
                Greater: 'GREATER'
            };

            var _D0;
            var _D1;
            var _Stack = new Array();
            
            //var _Instructions;
            //var _CurrentInstruction = {
            //    Instruction: "",
            //    Parameter: ""
            //};
            //var _CurrentInstructionCounter;
            var _LegislationAttributes;
            var legislationObject;
            var rigOps;
            var vehicle;
            var rig;
            var roundingPrecision = 5;
            let formatter = null;
            

            function setLegislationObject(newValue) {
                legislationObject = newValue;
            }
            function setRigOps(rigOpsObj) {
                rigOps = rigOpsObj;
            }
            
            function setFormatter(_formatter) {
                formatter = _formatter;
            }
           

            

            

            function processLegislationAttributes(objectCode, returnObject) {
                legislationObject = returnObject;
                
                for (var i = 0; i < objectCode.length; i++) {
                    returnObject[objectCode[i].Name()] = interpret(objectCode[i]);
                }

                return returnObject;
            }

            function processLegislationAttribute(fullLegislationObject, legislationToCheck) {
                legislationObject = fullLegislationObject;

                return interpret(legislationToCheck);
            }

            //function compareWithVehicle(legislationObj) {
            //    var innerVehicle = calculation.getRig().getVehicle();
            //    var testObject = new Object();
            //    var testVar = '';

            //    for (var obj in innerVehicle) {
            //        testVar = obj.toUpperCase();
            //        if (legislationObj.hasOwnProperty(testVar)) {
            //            testObject[obj] = innerVehicle[obj]();
            //        }
            //    }

            //    return legislationObj;
            //}

            // function evaluate(attributeName) {
            //     var attributeToCheck = test.filter(function (attribute) {
            //         return attributeName === attribute.name;
            //     });

            //     return interpret(attributeToCheck[0]);
            // }

            function Clear() {
                _D0 = 0;
            }

            function LoadNumberConstant(d) {
                _D0 = parseFloat(d);
            }

            function LoadStringConstant(s) {
                _D0 = s;
            }

            function LoadVariable(name, type, doValueReturn) {
                loadVariableCounter++;
                var tempVehicle, tempVehicleFrontAxleGroup, tempVehicleRearAxleGroup, tempVehiclePusher1, tempVehiclePusher2, tempVehiclePusher3, tempVehicleTag1, tempVehicleTag2, tempVehicleTag3, tempVehicleMinus1, tempVehicleMinus2, tempVehicleMinus3;
                var tempTrailer1, tempTrailer2, trailerType;
                var tempBody, tempHitch;
                if(!globals.debugLegislation) {
                    if(lookupTable[type]) {
                        _D0 = lookupTable[type];
                        // console.log(type + ' retrieved from lookupTable')
                        if (doValueReturn) {
                            return _D0;
                        }
                        return;
                    }
                }else {
                    if(!attributeAndVariableEvluationTimes.variables[indentMultiplier]) {
                        attributeAndVariableEvluationTimes.variables[indentMultiplier] = {};
                    }
                    attributeAndVariableEvluationTimes.variables[indentMultiplier][type] = {};
                    attributeAndVariableEvluationTimes.variables[indentMultiplier][type].name = type;
                    attributeAndVariableEvluationTimes.variables[indentMultiplier][type].startMillis = new Date().getTime();
                }

                switch (type) {
                    case utilLegislationAttributeObjectCode.variableTypes.LoadingAdministrator:
                        if (globals.user.isLoadingAdministrator()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.LoadingTrailerAdministrator:
                        //If WebContext.Current.User.IsInRole(ApplicationGlobals.RoleLoadingTrailer) Then
                        //    _D0 = 1
                        //Else
                        //    _D0 = 0
                        //End If
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.TruckScienceAdministrator:
                        if (globals.user.isTruckScienceAdministrator()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.LegislationDescription:
                        _D0 = legislationObject.description;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.LegislationRoadName:
                        _D0 = legislationObject.roadNames;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppEnvironment:
                        _D0 = config.appEnvironment;
                        break;
                        
                        // LegislationDate

                    case utilLegislationAttributeObjectCode.variableTypes.PropertiesApplication:
                        _D0 = rigOps.getActiveOffer().getApplicationTypeCode();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.PropertiesApplyHigherMassLimits:
                        _D0 = rigOps.getActiveOffer().getApplyHigherMassLimits() === true ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxleLayout:
                        _D0 = rigOps.getActiveOffer().getVehicleAxleLayout();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleNoOfTyresAxle1Front:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1FrontNoOfTyres();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleNoOfTyresAxle2Front:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle2FrontNoOfTyres();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleNoOfTyresAxle1Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1RearNoOfTyres();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleNoOfTyresAxle2Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle2RearNoOfTyres();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleNoOfTyresAxle3Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle3RearNoOfTyres();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleNoOfTyresAxle4Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle4RearNoOfTyres();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTyreWidthAxle1Front:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1FrontTyreWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTyreWidthAxle2Front:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle2FrontTyreWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTyreWidthAxle1Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1RearTyreWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTyreWidthAxle2Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle2RearTyreWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTyreWidthAxle3Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle3RearTyreWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTyreWidthAxle4Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle4RearTyreWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontOverhang:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getFrontOverhang();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontToAxle2Front:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1FrontToAxle2Front();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMostAxleFrontToAxle1Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle2FrontToAxle1Rear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearToAxle2Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1RearToAxle2Rear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearToAxle3Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle2RearToAxle3Rear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearToAxle4Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle3RearToAxle4Rear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontToWheelbaseTheoreticalStart:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1FrontToWheelbaseTheoreticalStart();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleWheelbaseTheoretical:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getWheelbaseTheoretical();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleWheelbaseTheoreticalEndToAxle4Rear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getWheelbaseTheoreticalEndToAxle4Rear();
                        break;
                        
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleWheelbaseTheoreticalManufacturerMin:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getWheelbaseTheoreticalManufacturerMin();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleWheelbaseTheoreticalManufacturerMax:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getWheelbaseTheoreticalManufacturerMax();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearOverhang:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getRearOverhang();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearProtrusion:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getRearProtrusion();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearOverhangTotal:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getRearOverhangTotal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleBumperToBackOfCab:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getBumperToBackOfCab();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleChassisHeight:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getChassisHeightMax();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleOverallHeight:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getOverallHeight();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleHeightExcludingPayload:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getOverallHeightExcludingPayload();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleBodySubframeHeight:
                        tempBody = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBody();
                        if(tempBody !== null) {
                            _D0 = tempBody.getSubframeHeight();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleBodyHeightExternal:
                        tempBody = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBody();
                        if(tempBody !== null) {
                            _D0 = tempBody.getActualExternalHeight();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodySubTypeVehicle:
                        tempBody = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBody();
                        if(tempBody !== null) {
                            _D0 = tempBody.getSubType();
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleSideWallLeftWidth:
                        tempBody = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBody();
                        if(tempBody !== null) {
                            _D0 = tempBody.getSidewallLeft();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleSideWallRightWidth:
                        tempBody = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBody();
                        if(tempBody !== null) {
                            _D0 = tempBody.getSidewallRight();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleHitchOffset:
                        tempHitch = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getHitch();
                        if(tempHitch !== null) {
                            _D0 = tempHitch.getOffset();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearSpreadDistance:
                        _D0 = rigOps.getVehicleExtremeRearGroupBridgeFormulaDistance();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearSpreadDistance:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getRearDrivenAxleSpan();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus1ToFirstMainAxle:
                            _D0 = rigOps.getActiveOffer().getConfiguration().getVehicleRearMinus1ToFirstMainAxle();
                            break;
                    case utilLegislationAttributeObjectCode.variableTypes.CabWidth:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getCabWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.TrackWidthVehicleFront:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getTrackWidthFront();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.TrackWidthVehicleRear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getTrackWidthRear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontOuterTrackWidth:
                        _D0 = rigOps.getVehicleFrontOuterTrackWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMainOuterTrackWidth:
                        _D0 = rigOps.getVehicleRearMainOuterTrackWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus1OuterTrackWidth:
                        _D0 = rigOps.getVehicleRearMinus1OuterTrackWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearPlus1OuterTrackWidth:
                        _D0 = rigOps.getVehicleRearPlus1OuterTrackWidth();
                        break;
                        
                        
                        
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxleFrontGGA:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getGAFront();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxleRearGGA:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getGARear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxleFrontAAU:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getOriginalAUFront();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxleRearAAU:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getOriginalAURear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePermissibleFront:
                        _D0 = rigOps.getVehicleFrontPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePermissibleRear:
                        _D0 = rigOps.getVehicleRearPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleGVM:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getGVM();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleGCM:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getGCM();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleV:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getVRating();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleDT:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getDT();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontRoadFriendlySuspension:
                        if (rigOps.getActiveOffer().getConfiguration().getFrontRoadFriendlySuspension() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRoadFriendlySuspension:
                        if (rigOps.getActiveOffer().getConfiguration().getRearRoadFriendlySuspension() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxlePositionRearNoOfTyres:
                        //as agreed with Martin, return sum of rear tyres
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxle1RearNoOfTyres() + rigOps.getActiveOffer().getConfiguration().getAxle2RearNoOfTyres() + rigOps.getActiveOffer().getConfiguration().getAxle3RearNoOfTyres() + rigOps.getActiveOffer().getConfiguration().getAxle4RearNoOfTyres();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRolloverProtection:
                        if (rigOps.getActiveOffer().getConfiguration().getRolloverProtection() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleSteeringAngle:                        
                        _D0 = rigOps.getActiveOffer().getConfiguration().getSteeringAngleInner();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTurningRadiusWallToWall:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getTurningRadiusWallToWall();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus1SteeringAngle:
                        _D0 = rigOps.getSteeringAngleForMinusAxle(1);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus2SteeringAngle:
                        _D0 = rigOps.getSteeringAngleForMinusAxle(2);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus3SteeringAngle:
                        _D0 = rigOps.getSteeringAngleForMinusAxle(3);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearPlus1SteeringAngle:
                        _D0 = rigOps.getSteeringAngleForPlusAxle(1);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearPlus2SteeringAngle:
                        _D0 = rigOps.getSteeringAngleForPlusAxle(2);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearPlus3SteeringAngle:
                        _D0 = rigOps.getSteeringAngleForPlusAxle(3);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTurningRadiusCurbToCurb:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getTurningRadiusCurbToCurb();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTurningRadiusInner:
                            _D0 = rigOps.getVehicleTurningRadiusInner();
                            break;
                        
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontUnderrunProtection:
                        if (rigOps.getActiveOffer().getConfiguration().getFrontUnderrunProtection() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMaxWheelbaseCut:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getWheelbaseTheoreticalMaxCut();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleWheelbaseChangeIncrement:
                        _D0 = rigOps.getWheelbaseChangeIncrement();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontPosition:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front() !== null) {
                            switch (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front().getType()) {
                                case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                                    _D0 = 'FRONT';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                                    _D0 = 'REAR';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                                    _D0 = 'PUSHER';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                                    _D0 = 'TAG';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                                    _D0 = 'SECONDSTEER';
                                    break;
                                //case config.VEHICLE_AXLE_POSITION_TYPES.NOTAPPLICABLE:
                                //    break;
                                default:
                                    _D0 = '';
                                    break;
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontLifting:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front().getLifting()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontSteering:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front().getSteering()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontRaised:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front().getRaised()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontManufacturerRating:
                        var axle1FrontForManufacturerRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front();
                        if (axle1FrontForManufacturerRating !== null && axle1FrontForManufacturerRating.getRaised() === false) {
                            _D0 = axle1FrontForManufacturerRating.getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontSuspensionRating:
                        var axle1FrontForSuspensionRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front();
                        if (axle1FrontForSuspensionRating !== null && axle1FrontForSuspensionRating.getRaised() === false) {
                            _D0 = axle1FrontForSuspensionRating.getSuspensionMassRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1FrontTyreRating:
                        var axle1FrontForTyreRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Front();
                        if (axle1FrontForTyreRating !== null && axle1FrontForTyreRating.getRaised() === false) {
                            if (axle1FrontForTyreRating.getNoOfTyresOnAxle() === 2) {
                                _D0 = axle1FrontForTyreRating.getTyreMassRatingSingle();
                            } else {
                                _D0 = axle1FrontForTyreRating.getTyreMassRatingDual();
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2FrontPosition:                    
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front() !== null) {
                            switch (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front().getType()) {
                                case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                                    _D0 = 'FRONT';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                                    _D0 = 'REAR';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                                    _D0 = 'PUSHER';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                                    _D0 = 'TAG';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                                    _D0 = 'SECONDSTEER';
                                    break;
                                    //case config.VEHICLE_AXLE_POSITION_TYPES.NOTAPPLICABLE:
                                    //    break;
                                default:
                                    _D0 = '';
                                    break;
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2FrontLifting:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front().getLifting()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2FrontSteering:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front().getSteering()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2FrontRaised:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front().getRaised()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2FrontManufacturerRating:
                        var axle2FrontForManufacturerRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front();
                        if (axle2FrontForManufacturerRating !== null && axle2FrontForManufacturerRating.getRaised() === false) {
                            _D0 = axle2FrontForManufacturerRating.getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2FrontSuspensionRating:
                        var axle2FrontForSuspensionRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front();
                        if (axle2FrontForSuspensionRating !== null && axle2FrontForSuspensionRating.getRaised() === false) {
                            _D0 = axle2FrontForSuspensionRating.getSuspensionMassRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2FrontTyreRating:
                        var axle2FrontForTyreRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Front();
                        if (axle2FrontForTyreRating !== null && axle2FrontForTyreRating.getRaised() === false) {
                            if (axle2FrontForTyreRating.getNoOfTyresOnAxle() === 2) {
                                _D0 = axle2FrontForTyreRating.getTyreMassRatingSingle();
                            } else {
                                _D0 = axle2FrontForTyreRating.getTyreMassRatingDual();
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearPosition:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear() !== null) {
                            switch (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear().getType()) {
                                case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                                    _D0 = 'FRONT';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                                    _D0 = 'REAR';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                                    _D0 = 'PUSHER';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                                    _D0 = 'TAG';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                                    _D0 = 'SECONDSTEER';
                                    break;
                                    //case config.VEHICLE_AXLE_POSITION_TYPES.NOTAPPLICABLE:
                                    //    break;
                                default:
                                    _D0 = '';
                                    break;
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearLifting:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear().getLifting()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearSteering:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear().getSteering()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearRaised:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear().getRaised()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearManufacturerRating:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear().getRaised() === false) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear().getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearSuspensionRating:
                        var axle1RearForSuspensionRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear();
                        if (axle1RearForSuspensionRating !== null && axle1RearForSuspensionRating.getRaised() === false) {
                            _D0 = axle1RearForSuspensionRating.getSuspensionMassRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle1RearTyreRating:
                        var axle1RearForTyreRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle1Rear();
                        if (axle1RearForTyreRating !== null && axle1RearForTyreRating.getRaised() === false) {
                            if (axle1RearForTyreRating.getNoOfTyresOnAxle() === 2) {
                                _D0 = axle1RearForTyreRating.getTyreMassRatingSingle();
                            } else {
                                _D0 = axle1RearForTyreRating.getTyreMassRatingDual();
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearPosition:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear() !== null) {
                            switch (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear().getType()) {
                                case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                                    _D0 = 'FRONT';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                                    _D0 = 'REAR';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                                    _D0 = 'PUSHER';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                                    _D0 = 'TAG';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                                    _D0 = 'SECONDSTEER';
                                    break;
                                    //case config.VEHICLE_AXLE_POSITION_TYPES.NOTAPPLICABLE:
                                    //    break;
                                default:
                                    _D0 = '';
                                    break;
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearLifting:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear().getLifting()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearSteering:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear().getSteering()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearRaised:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear().getRaised()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearManufacturerRating:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear().getRaised() === false) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear().getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearSuspensionRating:
                        var axle2RearForSuspensionRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear();
                        if (axle2RearForSuspensionRating !== null && axle2RearForSuspensionRating.getRaised() === false) {
                            _D0 = axle2RearForSuspensionRating.getSuspensionMassRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle2RearTyreRating:
                        var axle2RearForTyreRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle2Rear();
                        if (axle2RearForTyreRating !== null && axle2RearForTyreRating.getRaised() === false) {
                            if (axle2RearForTyreRating.getNoOfTyresOnAxle() === 2) {
                                _D0 = axle2RearForTyreRating.getTyreMassRatingSingle();
                            } else {
                                _D0 = axle2RearForTyreRating.getTyreMassRatingDual();
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearPosition:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear() !== null) {
                            switch (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear().getType()) {
                                case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                                    _D0 = 'FRONT';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                                    _D0 = 'REAR';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                                    _D0 = 'PUSHER';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                                    _D0 = 'TAG';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                                    _D0 = 'SECONDSTEER';
                                    break;
                                    //case config.VEHICLE_AXLE_POSITION_TYPES.NOTAPPLICABLE:
                                    //    break;
                                default:
                                    _D0 = '';
                                    break;
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearLifting:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear().getLifting()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearSteering:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear().getSteering()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearRaised:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear().getRaised()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearManufacturerRating:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear().getRaised() === false) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear().getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearSuspensionRating:
                        var axle3RearForSuspensionRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear();
                        if (axle3RearForSuspensionRating !== null && axle3RearForSuspensionRating.getRaised() === false) {
                            _D0 = axle3RearForSuspensionRating.getSuspensionMassRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle3RearTyreRating:
                        var axle3RearForTyreRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle3Rear();
                        if (axle3RearForTyreRating !== null && axle3RearForTyreRating.getRaised() === false) {
                            if (axle3RearForTyreRating.getNoOfTyresOnAxle() === 2) {
                                _D0 = axle3RearForTyreRating.getTyreMassRatingSingle();
                            } else {
                                _D0 = axle3RearForTyreRating.getTyreMassRatingDual();
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle4RearPosition:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear() !== null) {
                            switch (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear().getType()) {
                                case config.VEHICLE_AXLE_POSITION_TYPES.FRONT:
                                    _D0 = 'FRONT';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.REAR:
                                    _D0 = 'REAR';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.PUSHER:
                                    _D0 = 'PUSHER';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.TAG:
                                    _D0 = 'TAG';
                                    break;
                                case config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER:
                                    _D0 = 'SECONDSTEER';
                                    break;
                                    //case config.VEHICLE_AXLE_POSITION_TYPES.NOTAPPLICABLE:
                                    //    break;
                                default:
                                    _D0 = '';
                                    break;
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle4RearLifting:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear().getLifting()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle4RearSteering:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear().getSteering()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle4RearRaised:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear().getRaised()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle4RearManufacturerRating:
                        if (rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear() !== null && rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear().getRaised() === false) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear().getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle4RearSuspensionRating:
                        var axle4RearForSuspensionRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear();
                        if (axle4RearForSuspensionRating !== null && axle4RearForSuspensionRating.getRaised() === false) {
                            _D0 = axle4RearForSuspensionRating.getSuspensionMassRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAxle4RearTyreRating:
                        var axle4RearForTyreRating = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getAxle4Rear();
                        if (axle4RearForTyreRating !== null && axle4RearForTyreRating.getRaised() === false) {
                            if (axle4RearForTyreRating.getNoOfTyresOnAxle() === 2) {
                                _D0 = axle4RearForTyreRating.getTyreMassRatingSingle();
                            } else {
                                _D0 = axle4RearForTyreRating.getTyreMassRatingDual();
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleNoOfAxlesRearRear:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleSumOfManufacturerRatingRearRear:
                        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getRearRearAxlesGrossAxleWeightRating();
                        } else {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getAxlesHolder().getSumOfManufacturerRatingRearRear();
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearOverhangManufacturerMin:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getRearOverhangManufacturerMin();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleBreakPointForRearOverhang:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getBreakpointForRearOverhangFromRearMostAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTypeDistributionCode:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getVehicleTypeDistributionCode();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFridgeAdded:
                        tempVehicle = rigOps.getActiveOffer().getRig().getVehicle();
                        if (tempVehicle !== null && tempVehicle.getAccessoryHolder().getFridge() !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.HitchAdded:
                        tempVehicle = rigOps.getActiveOffer().getRig().getVehicle();
                        if (tempVehicle !== null && tempVehicle.getAccessoryHolder().getHitch() !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFifthWheelOffset:
                        _D0 = rigOps.getFifthWheelOffset();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleWheelbaseSpecified:
                        _D0 = rigOps.getVehicleWheelbaseSpecified();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontGross:
                        _D0 = rigOps.getCombinationVehicleFrontGross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearGross:
                        _D0 = rigOps.getCombinationVehicleRearGross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontGrossPercent:
                        _D0 = rigOps.getVehicleFrontGrossPercent();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearGrossPercent:
                        _D0 = rigOps.getVehicleRearGrossPercent();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.UtilisationOfGVWRPercent:
                        _D0 = rigOps.getUtilisationOfGVWRPercent();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.UtilisationOfGCWRPercent:
                        _D0 = rigOps.getUtilisationOfGCWRPercent();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.UtilisationOfVehiclePermissiblePercent:
                        _D0 = rigOps.getUtilisationOfVehiclePermissiblePercent();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.UtilisationOfCombinationPermissiblePercent:
                        _D0 = rigOps.getUtilisationOfCombinationPermissiblePercent();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.SwingClearanceBetweenTruckTractorAndTrailer1:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                                _D0 = rigOps.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.SwingClearanceBetweenInterlinkTrailer1AndTrailer2:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                                _D0 = rigOps.getVehicleAndInterlinkAndSemiSwingClearanceBetweenTrailer1AndTrailer2();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.SwingClearanceBetweenRigidAndDrawbar:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = rigOps.getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.SwingClearanceBetweenRigidAndPup:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                                _D0 = rigOps.getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                        
                        
                    case utilLegislationAttributeObjectCode.variableTypes.TrailerHitchLoadWeight:
                        _D0 = rigOps.getHitchLoad();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.TrailerHitchLoadPercent:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                                _D0 = rigOps.getHitchLoadAsPercentageOfTrailerGross();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleStaticRolloverAngle:
                        _D0 = rigOps.getVehicleStaticRolloverAngle();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMaxUnladenWeight:
                        _D0 = rigOps.getVehicleMaxUnladenWeight();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehicleVerticalCOG:
                        _D0 = rigOps.getOverallVerticalCOG();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehOverallWidth:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getOverallWidth();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehOverallHeight:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getOverallHeight();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehFrontalArea:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getFrontalArea();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehOverallBridgePermissible:
                        _D0 = rigOps.getVehicleGroup1BridgeFormulaPermissible();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehBodyGap:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBodyGap();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehBodyPlusPayloadPercentFront:
                        _D0 = rigOps.getComVehBodyPlusPayloadPercentFront();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehBodyPlusPayloadPercentRear:
                        _D0 = rigOps.getComVehBodyPlusPayloadPercentRear();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhangPercent:
                        _D0 = rigOps.getRearOverhangLegislationPercent();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhang:
                        //_D0 = rigOps.getActiveOffer().getRig().getVehicle().getRearOverhang();
                        _D0 = rigOps.getRearOverhangLegislation();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontAxlesNoOfAxles:
                        //var tempVehicleFrontAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                        //_D0 = tempVehicleFrontAxleGroup.getRelatedAxleIds().length;
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1NoOfAxles:
                        tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        if (tempVehiclePusher1 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2NoOfAxles:
                        tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        if (tempVehiclePusher2 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3NoOfAxles:
                        tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        if (tempVehiclePusher3 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1NoOfAxles:
                        tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                        if (tempVehicleTag1 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2NoOfAxles:
                        tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        if (tempVehicleTag2 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3NoOfAxles:
                        tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        if (tempVehicleTag3 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAllRearAxlesNoOfAxles:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontAxlesNoOfTyresPerAxle:
                        tempVehicleFrontAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                        _D0 = tempVehicleFrontAxleGroup.getNoOfTyresOnAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1NoOfTyresPerAxle:
                        //var tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        //if (tempVehiclePusher1 !== null) {
                        //    _D0 = tempVehiclePusher1.getNoOfTyresOnAxle();
                        //} else {
                        //    _D0 = 0;
                        //}
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2NoOfTyresPerAxle:
                        //var tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        //if (tempVehiclePusher2 !== null) {
                        //    _D0 = tempVehiclePusher2.getNoOfTyresOnAxle();
                        //} else {
                        //    _D0 = 0;
                        //}
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3NoOfTyresPerAxle:
                        //var tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        //if (tempVehiclePusher3 !== null) {
                        //    _D0 = tempVehiclePusher3.getNoOfTyresOnAxle();
                        //} else {
                        //    _D0 = 0;
                        //}
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearOnlyNoOfTyresPerAxle:
                        //var tempVehicleRearAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                        //_D0 = tempVehicleRearAxleGroup.getNoOfTyresOnAxle();
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1NoOfTyresPerAxle:
                        //var tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                        //if (tempVehicleTag1 !== null) {
                        //    _D0 = tempVehicleTag1.getNoOfTyresOnAxle();
                        //} else {
                        //    _D0 = 0;
                        //}
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2NoOfTyresPerAxle:
                        //var tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        //if (tempVehicleTag2 !== null) {
                        //    _D0 = tempVehicleTag2.getNoOfTyresOnAxle();
                        //} else {
                        //    _D0 = 0;
                        //}
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3NoOfTyresPerAxle:
                        //var tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        //if (tempVehicleTag3 !== null) {
                        //    _D0 = tempVehicleTag3.getNoOfTyresOnAxle();
                        //} else {
                        //    _D0 = 0;
                        //}
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAllRearAxlesNoOfTyresPerAxle:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAllRearAxlesNumTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontAxlesWidthPerTyre:
                        tempVehicleFrontAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                        _D0 = tempVehicleFrontAxleGroup.getTyreWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1WidthPerTyre:
                        tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        if (tempVehiclePusher1 !== null) {
                            _D0 = tempVehiclePusher1.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2WidthPerTyre:
                        tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        if (tempVehiclePusher2 !== null) {
                            _D0 = tempVehiclePusher2.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3WidthPerTyre:
                        tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        if (tempVehiclePusher3 !== null) {
                            _D0 = tempVehiclePusher3.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearOnlyWidthPerTyre:
                        //var tempVehicleRearAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                        //_D0 = tempVehicleRearAxleGroup.getTyreWidth();
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1WidthPerTyre:
                        tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                        if (tempVehicleTag1 !== null) {
                            _D0 = tempVehicleTag1.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2WidthPerTyre:
                        tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        if (tempVehicleTag2 !== null) {
                            _D0 = tempVehicleTag2.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3WidthPerTyre:
                        tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        if (tempVehicleTag3 !== null) {
                            _D0 = tempVehicleTag3.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAllRearAxlesWidthPerTyre:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAllRearAxlesWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontAxlesLifting:
                        tempVehicleFrontAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                        _D0 = tempVehicleFrontAxleGroup.getLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1Lifting:
                        tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        if (tempVehiclePusher1 !== null) {
                            _D0 = tempVehiclePusher1.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2Lifting:
                        tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        if (tempVehiclePusher2 !== null) {
                            _D0 = tempVehiclePusher2.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3Lifting:
                        tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        if (tempVehiclePusher3 !== null) {
                            _D0 = tempVehiclePusher3.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearOnlyLifting:
                        //var tempVehicleRearAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                        //_D0 = tempVehicleRearAxleGroup.getLifting();
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaLifting();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1Lifting:
                        tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                        if (tempVehicleTag1 !== null) {
                            _D0 = tempVehicleTag1.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2Lifting:
                        tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        if (tempVehicleTag2 !== null) {
                            _D0 = tempVehicleTag2.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3Lifting:
                        tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        if (tempVehicleTag3 !== null) {
                            _D0 = tempVehicleTag3.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAllRearAxlesLifting:
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontAxlesRaised:
                        tempVehicleFrontAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                        _D0 = tempVehicleFrontAxleGroup.getRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1Raised:
                        tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        if (tempVehiclePusher1 !== null) {
                            _D0 = tempVehiclePusher1.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2Raised:
                        tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        if (tempVehiclePusher2 !== null) {
                            _D0 = tempVehiclePusher2.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3Raised:
                        tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        if (tempVehiclePusher3 !== null) {
                            _D0 = tempVehiclePusher3.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearOnlyRaised:
                        tempVehicleRearAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                        _D0 = tempVehicleRearAxleGroup.getRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1Raised:
                        tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                        if (tempVehicleTag1 !== null) {
                            _D0 = tempVehicleTag1.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2Raised:
                        tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        if (tempVehicleTag2 !== null) {
                            _D0 = tempVehicleTag2.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3Raised:
                        tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        if (tempVehicleTag3 !== null) {
                            _D0 = tempVehicleTag3.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAllRearAxlesRaised:
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontAxlesSteering:
                        tempVehicleFrontAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                        _D0 = tempVehicleFrontAxleGroup.getSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1Steering:
                        tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        if (tempVehiclePusher1 !== null) {
                            _D0 = tempVehiclePusher1.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2Steering:
                        tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        if (tempVehiclePusher2 !== null) {
                            _D0 = tempVehiclePusher2.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3Steering:
                        tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        if (tempVehiclePusher3 !== null) {
                            _D0 = tempVehiclePusher3.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearOnlySteering:
                        tempVehicleRearAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                        _D0 = tempVehicleRearAxleGroup.getSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1Steering:
                        tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                        if (tempVehicleTag1 !== null) {
                            _D0 = tempVehicleTag1.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2Steering:
                        tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        if (tempVehicleTag2 !== null) {
                            _D0 = tempVehicleTag2.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3Steering:
                        tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        if (tempVehicleTag3 !== null) {
                            _D0 = tempVehicleTag3.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAllRearAxlesSteering:
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontAxlesDriven:
                        tempVehicleFrontAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                        _D0 = tempVehicleFrontAxleGroup.getDrivenAxle() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1Driven:
                        tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        if (tempVehiclePusher1 !== null) {
                            _D0 = tempVehiclePusher1.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2Driven:
                        tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        if (tempVehiclePusher2 !== null) {
                            _D0 = tempVehiclePusher2.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3Driven:
                        tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        if (tempVehiclePusher3 !== null) {
                            _D0 = tempVehiclePusher3.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearOnlyDriven:
                        tempVehicleRearAxleGroup = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                        _D0 = tempVehicleRearAxleGroup.getDrivenAxle() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1Driven:
                        tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                        if (tempVehicleTag1 !== null) {
                            _D0 = tempVehicleTag1.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2Driven:
                        tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        if (tempVehicleTag2 !== null) {
                            _D0 = tempVehicleTag2.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3Driven:
                        tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        if (tempVehicleTag3 !== null) {
                            _D0 = tempVehicleTag3.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus1Gross:
                        _D0 = rigOps.getVehicleRearMinus1Gross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus2Gross:
                        _D0 = rigOps.getVehicleRearMinus2Gross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearMinus3Gross:
                        _D0 = rigOps.getVehicleRearMinus3Gross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMainGross:
                        _D0 = rigOps.getVehicleMainGross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearPlus1Gross:
                        _D0 = rigOps.getVehicleRearPlus1Gross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearPlus2Gross:
                        _D0 = rigOps.getVehicleRearPlus2Gross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearPlus3Gross:
                        _D0 = rigOps.getVehicleRearPlus3Gross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleFrontManufacturerRating:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfFrontAxleManufacturerRatings();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher1ManufacturerRating:
                        tempVehiclePusher1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher1();
                        if(tempVehiclePusher1 !== null) {
                            _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfPusherOrTagAxleManufacturerRatings(tempVehiclePusher1.getNumber());
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher2ManufacturerRating:
                        tempVehiclePusher2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher2();
                        if(tempVehiclePusher2 !== null) {
                            _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfPusherOrTagAxleManufacturerRatings(tempVehiclePusher2.getNumber());
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePusher3ManufacturerRating:
                        tempVehiclePusher3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getPusher3();
                        if(tempVehiclePusher3 !== null) {
                            _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfPusherOrTagAxleManufacturerRatings(tempVehiclePusher3.getNumber());
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearRearManufacturerRating:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfRearRearAxleManufacturerRatings();
                        break;  
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag1ManufacturerRating:
                            tempVehicleTag1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag1();
                            if(tempVehicleTag1 !== null) {
                                _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfPusherOrTagAxleManufacturerRatings(tempVehicleTag1.getNumber());
                            } else {
                                _D0 = 0;
                            }
                            break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag2ManufacturerRating:
                        tempVehicleTag2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag2();
                        if(tempVehicleTag2 !== null) {
                            _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfPusherOrTagAxleManufacturerRatings(tempVehicleTag2.getNumber());
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleTag3ManufacturerRating:
                        tempVehicleTag3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getTag3();
                        if(tempVehicleTag3 !== null) {
                            _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfPusherOrTagAxleManufacturerRatings(tempVehicleTag3.getNumber());
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleRearManufacturerRating:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getLesserOfRearAxleManufacturerRatings();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleAllRearAxlesDriven:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getHasPusherOrTagAxleDown() ? 0 : rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getDrivenAxle() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup3NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup3WidthPerTyre:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup3Lifting:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup3Raised:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup3Steering:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup4NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup4WidthPerTyre:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup4Lifting:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup4Raised:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup4Steering:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup5DistanceRounded:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup5NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup5WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup5Lifting:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup5Raised:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup5Steering:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup6DistanceRounded:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup6NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup6WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup6Lifting:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup6Raised:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup6Steering:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup7DistanceRounded:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup7NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup7WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup7Lifting:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup7Raised:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup7Steering:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup8DistanceRounded:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup8NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup8WidthPerTyre:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup8Lifting:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup8Raised:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup8Steering:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup9DistanceRounded:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup9NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup9WidthPerTyre:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup9Lifting:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup9Raised:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup9Steering:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup10DistanceRounded:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup10NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup10WidthPerTyre:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup10Lifting:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup10Raised:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup10Steering:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaSteering() ? 1 : 0;
                        break;



                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus1NoOfAxles:
                        tempVehicleMinus1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus1();
                        if (tempVehicleMinus1 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus2NoOfAxles:
                        tempVehicleMinus2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus2();
                        if (tempVehicleMinus2 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus3NoOfAxles:
                        tempVehicleMinus3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus3();
                        if (tempVehicleMinus3 !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus1NoOfTyresPerAxle:
                        tempVehicleMinus1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus1();
                        if (tempVehicleMinus1 !== null) {
                           _D0 = tempVehicleMinus1.getNoOfTyresOnAxle();
                        } else {
                           _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus2NoOfTyresPerAxle:
                        tempVehicleMinus2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus2();
                        if (tempVehicleMinus2 !== null) {
                            _D0 = tempVehicleMinus2.getNoOfTyresOnAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus3NoOfTyresPerAxle:
                        tempVehicleMinus3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus3();
                        if (tempVehicleMinus3 !== null) {
                           _D0 = tempVehicleMinus3.getNoOfTyresOnAxle();
                        } else {
                           _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus1WidthPerTyre:
                        tempVehicleMinus1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus1();
                        if (tempVehicleMinus1 !== null) {
                            _D0 = tempVehicleMinus1.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus2WidthPerTyre:
                        tempVehicleMinus2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus2();
                        if (tempVehicleMinus2 !== null) {
                            _D0 = tempVehicleMinus2.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus3WidthPerTyre:
                        tempVehicleMinus3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus3();
                        if (tempVehicleMinus3 !== null) {
                            _D0 = tempVehicleMinus3.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus1Lifting:
                        tempVehicleMinus1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus1();
                        if (tempVehicleMinus1 !== null) {
                            _D0 = tempVehicleMinus1.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus2Lifting:
                        tempVehicleMinus2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus2();
                        if (tempVehicleMinus2 !== null) {
                            _D0 = tempVehicleMinus2.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus3Lifting:
                        tempVehicleMinus3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus3();
                        if (tempVehicleMinus3 !== null) {
                            _D0 = tempVehicleMinus3.getLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus1Raised:
                        tempVehicleMinus1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus1();
                        if (tempVehicleMinus1 !== null) {
                            _D0 = tempVehicleMinus1.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus2Raised:
                        tempVehicleMinus2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus2();
                        if (tempVehicleMinus2 !== null) {
                            _D0 = tempVehicleMinus2.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus3Raised:
                        tempVehicleMinus3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus3();
                        if (tempVehicleMinus3 !== null) {
                            _D0 = tempVehicleMinus3.getRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus1Steering:
                        tempVehicleMinus1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus1();
                        if (tempVehicleMinus1 !== null) {
                            _D0 = tempVehicleMinus1.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus2Steering:
                        tempVehicleMinus2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus2();
                        if (tempVehicleMinus2 !== null) {
                            _D0 = tempVehicleMinus2.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus3Steering:
                        tempVehicleMinus3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus3();
                        if (tempVehicleMinus3 !== null) {
                            _D0 = tempVehicleMinus3.getSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus1Driven:
                        tempVehicleMinus1 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus1();
                        if (tempVehicleMinus1 !== null) {
                            _D0 = tempVehicleMinus1.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus2Driven:
                        tempVehicleMinus2 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus2();
                        if (tempVehicleMinus2 !== null) {
                            _D0 = tempVehicleMinus2.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleMinus3Driven:
                        tempVehicleMinus3 = rigOps.getActiveOffer().getRig().getVehicle().getAxlesHolder().getMinus3();
                        if (tempVehicleMinus3 !== null) {
                            _D0 = tempVehicleMinus3.getDrivenAxle() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePayloadCOGMM:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getVehiclePayloadCOGMM();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePayload:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getPayloadTotal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehiclePayloadCOGPercent:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getVehiclePayloadCOGPercent();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleBodyAdded:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getAccessoryHolder().getBody() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.InteraxleVehicleFrontToVehicleRear:
                        _D0 = rigOps.getInteraxleVehicleFrontToVehicleRear();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleEffectiveRearOverhang:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getVehicleEffectiveRearOverhang();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleEnergySource:
                        _D0 = rigOps.getActiveOffer().getRig().getVehicle().getFuelType();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRear:
                        // _D0 = rigOps.getVehicleExtremeRearGroupBridgeFormulaPermissible();
                        _D0 = rigOps.getVehicleRearTotalBridgeOrRegulationVal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRearMain:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRearMinus1:
                        _D0 = rigOps.getVehicleMinus1GroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRearMinus2:
                        _D0 = rigOps.getVehicleMinus2GroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRearMinus3:
                        _D0 = rigOps.getVehicleMinus3GroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRearPlus1:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRearPlus2:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleRearPlus3:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleFront:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicle:
                        _D0 = rigOps.getVehicleTotalBridgeOrRegulationVal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitTrailer1Front:
                        _D0 = rigOps.getTrailer1ExtremeFrontGroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitTrailer1Rear:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitTrailer2Rear:
                        _D0 = rigOps.getTrailer2ExtremeRearGroupBridgeFormulaPermissible();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitCombination:
                        _D0 = rigOps.getCombinationTotalBridgeOrRegulationVal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitCombinationBridge:
                        _D0 = rigOps.getCombinationTotalBridgeVal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AppLimitVehicleBridge:
                        _D0 = rigOps.getVehicleTotalBridgeVal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationBridgeFormulaUsed:
                        _D0 = rigOps.getCurrentCombinationBridgeFormulaUsed();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationOverallWidth:
                        _D0 = rigOps.getCombinationOverallWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleOverallWidth:
                        _D0 = rigOps.getVehicleOverallWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.FrontUnderrunAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getFrontUnderrunProtection() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.DevicesForIndirectVisionAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getDevicesForIndirectVision() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BrakeSystemsAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getCommercialVehicleBrakeSystems() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.AdvancedEmergencyBrakingAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getAdvancedEmergencyBraking() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.LaneDepartureWarningAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getLaneDepartureWarning() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.SideUnderrunProtectionAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getSideUnderrunProtection() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ConspicuityMarkingsRearAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getConspicuityMarkingsToTheRear() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ConspicuityMarkingsSidesAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getConspicuityMarkingsToBothSides() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BlindSpotInfoSystemsAUAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getBlindSpotInformationSystems() === config.YES_NO_OPTIONS.YES) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ReverseGearStartability:
                        _D0 = rigOps.getReverseGearStartability();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CDLRequiredUS:
                        _D0 = rigOps.getCDLRequiredUS();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ParkPAWL:
                        _D0 = rigOps.getParkPAWL();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes['1stGearStartability']:
                        _D0 = rigOps.getFirstGearStartability();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes['1stGearGradeability']:
                        _D0 = rigOps.getFirstGearGradeability();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.EmissionsStandard:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getEmissionsStandard();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.EmissionsStandardCode:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getEmissionsStandardCode();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.FrameCut:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getStartOfBodyToEndOfChassis();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.OriginalWBSpecified:
                        _D0 = rigOps.getBaseWheelbaseSpecified();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.OriginalROH:
                        _D0 = rigOps.getBaseOffer().getConfiguration().getRearOverhang();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.OriginalCE:
                        _D0 = rigOps.getBaseOffer().getConfiguration().getCabToChassisEnd();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.VehicleCE:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getCabToChassisEnd();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.UnladenVehicleWeight:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getUnladenVehicleWeight();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.ManufacturerMaxUnladenVehicleWeight:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getMaxUnladenVehicleWeight();
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.UserMaxUnladenVehicleWeight:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getUnladenVehicleWeight();
                        break;   
                        
                         
                        
                        
                    //#region Trailers Section
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Source:
                        //_D0 = .Trailer1(0).TrailerTypeCode
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getSource();
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1DrawbarType:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.DrawbarTypeCode
                    //ElseIf .Trailer1(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer1(0)
                    //_D0 = x.DrawbarTypeCode
                    //Else
                    //_D0 = String.Empty
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            trailerType = tempTrailer1.getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.PUP) {
                                _D0 = rigOps.getActiveOffer().getTrailer1().getDrawbarType();
                            } else {
                                _D0 = '';
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Type:
                        //_D0 = .Trailer1(0).TrailerTypeCode
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getType();
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1GVM:
                        //_D0 = .Trailer1(0).GVM
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getGVM()
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseTheoretical:
                        //_D0 = .Trailer1(0).WheelbaseTheoretical
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getWheelbaseTheoretical();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle2FrontToAxle3Front:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.Axle2FrontToAxle3Front
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getAxle2FrontToAxle3Front();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle1FrontToAxle2Front:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.Axle1FrontToAxle2Front
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getAxle1FrontToAxle2Front();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1RearMostFrontAxleToAxle1Rear:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.RearMostFrontAxleToAxle1Rear
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getRearMostFrontAxleToAxle1Rear();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle1RearToAxle2Rear:
                        //_D0 = .Trailer1(0).Axle1RearToAxle2Rear
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getAxle1RearToAxle2Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle2RearToAxle3Rear:
                        //_D0 = .Trailer1(0).Axle2RearToAxle3Rear
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getAxle2RearToAxle3Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Axle3RearToAxle4Rear:
                        //_D0 = .Trailer1(0).Axle3RearToAxle4Rear
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getAxle3RearToAxle4Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1RearSpreadDistance:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getRearAxleSpan();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FrontSpreadDistance:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getFrontAxleSpan();
                        } else {
                            _D0 = 0;
                        }
                        break;
                        
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1NoOfTyresAxle1Front:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.Axle1FrontNoOfTyres
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getAxlesHolder().getAxle1Front().getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1NoOfTyresAxle2Front:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.Axle2FrontNoOfTyres
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getAxlesHolder().getAxle2Front().getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1NoOfTyresAxle3Front:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.Axle3FrontNoOfTyres
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getAxlesHolder().getAxle3Front().getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1NoOfTyresAxle1Rear:
                        //_D0 = .Trailer1(0).Axle1RearNoOfTyres
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            var axle1Rear = tempTrailer1.getAxlesHolder().getAxle1Rear();
                            if (axle1Rear !== null) {
                                _D0 = axle1Rear.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1NoOfTyresAxle2Rear:
                        //_D0 = .Trailer1(0).Axle2RearNoOfTyres
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            var axle2Rear = tempTrailer1.getAxlesHolder().getAxle2Rear();
                            if (axle2Rear !== null) {
                                _D0 = axle2Rear.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1NoOfTyresAxle3Rear:
                        //_D0 = .Trailer1(0).Axle3RearNoOfTyres
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            var axle3Rear = tempTrailer1.getAxlesHolder().getAxle3Rear();
                            if (axle1Rear !== null) {
                                _D0 = axle3Rear.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1NoOfTyresAxle4Rear:
                        //_D0 = .Trailer1(0).Axle4RearNoOfTyres
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            var axle4Rear = tempTrailer1.getAxlesHolder().getAxle4Rear();
                            if (axle4Rear !== null) {
                                _D0 = axle4Rear.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1OverallWidth:
                        //_D0 = .Trailer1(0).OverallWidth
                        // tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        // if (tempTrailer1 !== null) {
                        //     _D0 = tempTrailer1.getOverallWidthInclAccessoriesAndPayload()
                        // } else {
                        //     _D0 = 0;
                        // }
                        _D0 = rigOps.getTrailer1OverallWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1DrawbarLength:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.DrawbarLength
                    //ElseIf .Trailer1(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer1(0)
                    //_D0 = x.DrawbarLength
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                                _D0 = tempTrailer1.getDrawbarLength();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1ChassisHeight:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getChassisHeight();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FrontToStartOfTaper:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getFrontToStartOfTaper();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1BodyHeightExternal:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            tempBody = tempTrailer1.getAccessoryHolder().getBody();
                            if(tempBody !== null) {
                                _D0 = tempBody.getActualExternalHeight();
                            } else {
                                _D0 = 0;    
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1SideWallLeftWidth:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            tempBody = tempTrailer1.getAccessoryHolder().getBody();
                            if(tempBody !== null) {
                                _D0 = tempBody.getSidewallLeft();
                            } else {
                                _D0 = 0;    
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1SideWallRightWidth:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            tempBody = tempTrailer1.getAccessoryHolder().getBody();
                            if(tempBody !== null) {
                                _D0 = tempBody.getSidewallRight();
                            } else {
                                _D0 = 0;    
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseTheoreticalEndToAxle4Rear:
                        //_D0 = .Trailer1(0).WheelbaseTheoreticalEndToRearMostAxle
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1ManufacturersRearAxleMassGAGAURear:
                        //_D0 = .Trailer1(0).ManufacturerRearAxleMass
                        //_D0 = rigOps.getActiveOffer().getTrailer1().getManufacturersRearAxlesMass();
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1ManufacturersFrontAxleMassGAGAUFront:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.ManufacturerFrontAxleMass
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                //_D0 = rigOps.getActiveOffer().getTrailer1().getManufacturersFrontAxlesMass();
                                _D0 = tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getManufacturerRating();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FrontRoadFriendlySuspension:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //If x.FrontRoadFriendlySuspension = TrailerRoadFriendlySuspensionTypes.Yes Then
                    //_D0 = 1
                    //Else
                    //_D0 = 0
                    //End If
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (tempTrailer1.getFrontRoadFriendlySuspension() === config.VEHICLE_ROAD_FRIENDLY_SUSPENSION_TYPES.YES) {
                                    _D0 = 1;
                                } else {
                                    _D0 = 0;
                                }
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1RearRoadFriendlySuspension:
                    //If .Trailer1(0).RearRoadFriendlySuspension = TrailerRoadFriendlySuspensionTypes.Yes Then
                    //_D0 = 1
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getRearRoadFriendlySuspension() === config.VEHICLE_ROAD_FRIENDLY_SUSPENSION_TYPES.YES) {
                                _D0 = 1;
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                        
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Length:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getTrailerLengthForCompiler(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FrontOverhang:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.FrontOverhang
                    //ElseIf .Trailer1(0).TrailerTypeCode = "SEMI" Then
                    //Dim x As FileLoadingTrailerSemi = .Trailer1(0)
                    //_D0 = x.FrontOverhang
                    //ElseIf .Trailer1(0).TrailerTypeCode = "INTERLINK" Then
                    //Dim x As FileLoadingTrailerInterlink = .Trailer1(0)
                    //_D0 = x.FrontOverhang
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            trailerType = tempTrailer1.getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK) {
                                _D0 = tempTrailer1.getFrontOverhang();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseTheoreticalStartFromAxle1Front:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.Axle1FrontToWheelbaseTheoreticalStart
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1TyreWidth:
                        //_D0 = .Trailer1(0).TyreWidth
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1RearOverhangTotal:
                        //_D0 = .Trailer1RearOverhangTotal
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getTrailer1RearOverhangTotal();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1MaxDrawbarLengthCut:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.DrawbarProtrusionMaxCut
                    //ElseIf .Trailer1(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer1(0)
                    //_D0 = x.DrawbarProtrusionMaxCut
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            trailerType = tempTrailer1.getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.PUP) {
                                _D0 = tempTrailer1.getDrawbarProtrusionMaxCut();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1MaxFrontOverhangCut:
                    //If .Trailer1(0).TrailerTypeCode = "SEMI" Then
                    //Dim x As FileLoadingTrailerSemi = .Trailer1(0)
                    //_D0 = x.FrontOverhangMaxCut
                    //ElseIf .Trailer1(0).TrailerTypeCode = "INTERLINK" Then
                    //Dim x As FileLoadingTrailerInterlink = .Trailer1(0)
                    //_D0 = x.FrontOverhangMaxCut
                    //ElseIf .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.FrontOverhangMaxCut
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            trailerType = tempTrailer1.getType();
                            if (trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK || trailerType === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getFrontOverhangMaxCut();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1MaxWheelbaseTheoreticalCut:
                        //_D0 = .Trailer1(0).WheelbaseTheoreticalMaxCut
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getWheelbaseTheoreticalMaxCut();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1MaxTrailerLengthCut:
                        //_D0 = .Trailer1(0).TrailerLengthMaxCut
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getTrailerLengthMaxCut();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1MaxOverallHeightCut:
                        //_D0 = .Trailer1(0).OverallHeightMaxCut
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getOverallHeightMaxCut();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1DrawbarLengthChangeIncrement:
                    //If .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.DrawbarProtrusionChangeIncrement
                    //ElseIf .Trailer1(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer1(0)
                    //_D0 = x.DrawbarProtrusionChangeIncrement
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            trailerType = tempTrailer1.getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.PUP) {
                                _D0 = tempTrailer1.getDrawbarProtrusionChangeIncrement();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FrontOverhangChangeIncrement:
                    //If .Trailer1(0).TrailerTypeCode = "SEMI" Then
                    //Dim x As FileLoadingTrailerSemi = .Trailer1(0)
                    //_D0 = x.FrontOverhangChangeIncrement
                    //ElseIf .Trailer1(0).TrailerTypeCode = "INTERLINK" Then
                    //Dim x As FileLoadingTrailerInterlink = .Trailer1(0)
                    //_D0 = x.FrontOverhangChangeIncrement
                    //ElseIf .Trailer1(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer1(0)
                    //_D0 = x.FrontOverhangChangeIncrement
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            trailerType = tempTrailer1.getType();
                            if (trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK || trailerType === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer1.getFrontOverhangChangeIncrement();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1WheelbaseChangeIncrement:
                        //_D0 = .Trailer1(0).WheelbaseTheoreticalChangeIncrement
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getWheelbaseTheoreticalChangeIncrement();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1HeightChangeIncrement:
                        //_D0 = .Trailer1(0).OverallHeightChangeIncrement
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getOverallHeightChangeIncrement();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1LengthChangeIncrement:
                        //_D0 = .Trailer1(0).OverallLengthChangeIncrement
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getLengthChangeIncrement();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1OverallHeight:
                        //_D0 = .Trailer1(0).OverallHeight
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getOverallHeightAtRear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1HeightExcludingPayload:
                        //_D0 = .Trailer1(0).OverallHeight
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getOverallHeightAtRearExcludingPayload();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1AxleLastToChassisEnd:
                        //_D0 = .Trailer1(0).RearOverhang
                        //_D0 = rigOps.getActiveOffer().getTrailer1().getAxleLastToChassisEnd();
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getRearOverhang();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1InternalBodyLength:
                    //If .Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                    //_D0 = .Trailer1(0).Accessories.Body.ActualInternalLength
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            tempBody = tempTrailer1.getAccessoryHolder().getBody();
                            if (tempBody !== null) {
                                _D0 = tempBody.getActualInternalLength();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1TyreRadius:
                        //_D0 = .Trailer1(0).TyreRadius
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getTyreRadius();
                        } else {
                            _D0 = 0;
                        }
                        break; 
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FridgeAdded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getAccessoryHolder().getFridge() !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1ArticulationPointToRearOfBDouble:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getTrailer1ArticulationPointToRearOfBDouble();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1KingpinLoadWeight:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1KingpinLoadPercent:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getKingpinLoadPercent(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.OverallTrailer1VerticalCOG:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getTrailer1OverallVerticalCOG();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1StaticRolloverAngle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getTrailer1StaticRolloverAngle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1TyreWidthFront:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            var tempTrailer1FrontAxleGroup = tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
                            if (tempTrailer1FrontAxleGroup !== null) {
                                _D0 = tempTrailer1FrontAxleGroup.getTyreWidth();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1TyreWidthRear:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            var tempTrailer1RearAxleGroup = tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                            if (tempTrailer1RearAxleGroup !== null) {
                                _D0 = tempTrailer1RearAxleGroup.getTyreWidth();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1PayloadCOGMM:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getTrailerPayloadCOGMM();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1Payload:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getPayloadTotal();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1PayloadCOGPercent:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getTrailerPayloadCOGPercent();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1BodyAdded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = tempTrailer1.getAccessoryHolder().getBody() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FifthWheelOffset:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            trailerType = tempTrailer1.getType();
                            if (trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK) {
                                _D0 = tempTrailer1.getFifthWheelOffset();
                            }else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                        

                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Source:
                        //_D0 = .Trailer1(0).TrailerTypeCode
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getSource();
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2DrawbarType:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.DrawbarTypeCode
                    //ElseIf .Trailer2(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer2(0)
                    //_D0 = x.DrawbarTypeCode
                    //Else
                    //_D0 = String.Empty
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            trailerType = rigOps.getActiveOffer().getTrailer2().getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.PUP) {
                                _D0 = tempTrailer2.getDrawbarType();
                            } else {
                                _D0 = '';
                            }
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Type:
                        //_D0 = .Trailer2(0).TrailerTypeCode
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getType();
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2GVM:
                        //_D0 = .Trailer2(0).GVM
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getGVM();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2WheelbaseTheoretical:
                        //_D0 = .Trailer2(0).WheelbaseTheoretical
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getWheelbaseTheoretical();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Axle2FrontToAxle3Front:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.Axle2FrontToAxle3Front
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getAxle2FrontToAxle3Front();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Axle1FrontToAxle2Front:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.Axle1FrontToAxle2Front
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getAxle1FrontToAxle2Front();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2RearMostFrontAxleToAxle1Rear:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.RearMostFrontAxleToAxle1Rear
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getRearMostFrontAxleToAxle1Rear();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Axle1RearToAxle2Rear:
                        //_D0 = .Trailer2(0).Axle1RearToAxle2Rear
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getAxle1RearToAxle2Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Axle2RearToAxle3Rear:
                        //_D0 = .Trailer2(0).Axle2RearToAxle3Rear
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getAxle2RearToAxle3Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Axle3RearToAxle4Rear:
                        //_D0 = .Trailer2(0).Axle3RearToAxle4Rear
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getAxle3RearToAxle4Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2RearSpreadDistance:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getRearAxleSpan();
                        } else {
                            _D0 = 0;
                        }
                        break;
                        
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2NoOfTyresAxle1Front:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.Axle1FrontNoOfTyres
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getAxlesHolder().getAxle1Front().getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2NoOfTyresAxle2Front:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.Axle2FrontNoOfTyres
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getAxlesHolder().getAxle2Front().getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2NoOfTyresAxle3Front:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.Axle3FrontNoOfTyres
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getAxlesHolder().getAxle3Front().getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2NoOfTyresAxle1Rear:
                        //_D0 = .Trailer2(0).Axle1RearNoOfTyres
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            var axle1RearForNoOfTyres = tempTrailer2.getAxlesHolder().getAxle1Rear();
                            if (axle1RearForNoOfTyres !== null) {
                                _D0 = axle1RearForNoOfTyres.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2NoOfTyresAxle2Rear:
                        //_D0 = .Trailer2(0).Axle2RearNoOfTyres
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            var axle2RearForNoOfTyres = tempTrailer2.getAxlesHolder().getAxle2Rear();
                            if (axle2RearForNoOfTyres !== null) {
                                _D0 = axle2RearForNoOfTyres.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2NoOfTyresAxle3Rear:
                        //_D0 = .Trailer2(0).Axle3RearNoOfTyres
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            var axle3RearForNoOfTyres = tempTrailer2.getAxlesHolder().getAxle3Rear();
                            if (axle3RearForNoOfTyres !== null) {
                                _D0 = axle3RearForNoOfTyres.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2NoOfTyresAxle4Rear:
                        //_D0 = .Trailer2(0).Axle4RearNoOfTyres
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            var axle4RearForNoOfTyres = tempTrailer2.getAxlesHolder().getAxle4Rear();
                            if (axle4RearForNoOfTyres !== null) {
                                _D0 = axle4RearForNoOfTyres.getNoOfTyresOnAxle();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2OverallWidth:
                        //_D0 = .Trailer2(0).OverallWidth
                        // tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        // if (tempTrailer2 !== null) {
                        //     _D0 = tempTrailer2.getOverallWidthInclAccessoriesAndPayload();
                        // } else {
                        //     _D0 = 0;
                        // }
                        _D0 = rigOps.getTrailer2OverallWidth();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2DrawbarLength:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.DrawbarLength
                    //ElseIf .Trailer2(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer2(0)
                    //_D0 = x.DrawbarLength
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer2.getType() === config.TRAILER_TYPES.PUP) {
                                _D0 = rigOps.getActiveOffer().getTrailer2().getDrawbarLength();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2ChassisHeight:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getChassisHeight();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2BodyHeightExternal:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            tempBody = tempTrailer2.getAccessoryHolder().getBody();
                            if(tempBody !== null) {
                                _D0 = tempBody.getActualExternalHeight();
                            } else {
                                _D0 = 0;    
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                        
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2WheelbaseTheoreticalEndToAxle4Rear:
                        //_D0 = .Trailer2(0).WheelbaseTheoreticalEndToRearMostAxle
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getWheelbaseTheoreticalEndToRearMostAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2ManufacturersRearAxleMassGAGAURear:
                        //_D0 = .Trailer2(0).ManufacturerRearAxleMass
                        //_D0 = rigOps.getActiveOffer().getTrailer2().getManufacturersRearAxlesMass();
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getManufacturerRating();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2ManufacturersFrontAxleMassGAGAUFront:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.ManufacturerFrontAxleMass
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                //_D0 = rigOps.getActiveOffer().getTrailer2().getManufacturersFrontAxlesMass();
                                _D0 = tempTrailer2.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getManufacturerRating();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2FrontRoadFriendlySuspension:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //If x.FrontRoadFriendlySuspension = TrailerRoadFriendlySuspensionTypes.Yes Then
                    //_D0 = 1
                    //Else
                    //_D0 = 0
                    //End If
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                if (tempTrailer2.getFrontRoadFriendlySuspension() === config.VEHICLE_ROAD_FRIENDLY_SUSPENSION_TYPES.YES) {
                                    _D0 = 1;
                                } else {
                                    _D0 = 0;
                                }
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2RearRoadFriendlySuspension:
                    //If .Trailer2(0).RearRoadFriendlySuspension = TrailerRoadFriendlySuspensionTypes.Yes Then
                    //_D0 = 1
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getRearRoadFriendlySuspension() === config.VEHICLE_ROAD_FRIENDLY_SUSPENSION_TYPES.YES) {
                                _D0 = 1;
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Length:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = rigOps.getTrailerLengthForCompiler(config.CHASSIS_OBJECT_TYPES.TRAILER2);
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2FrontOverhang:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.FrontOverhang
                    //ElseIf .Trailer2(0).TrailerTypeCode = "SEMI" Then
                    //Dim x As FileLoadingTrailerSemi = .Trailer2(0)
                    //_D0 = x.FrontOverhang
                    //ElseIf .Trailer2(0).TrailerTypeCode = "INTERLINK" Then
                    //Dim x As FileLoadingTrailerInterlink = .Trailer2(0)
                    //_D0 = x.FrontOverhang
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            trailerType = tempTrailer2.getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK) {
                                _D0 = tempTrailer2.getFrontOverhang();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2WheelbaseTheoreticalStartFromAxle1Front:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.Axle1FrontToWheelbaseTheoreticalStart
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getAxle1FrontToWheelbaseTheoreticalStart();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2TyreWidth:
                        //_D0 = .Trailer2(0).TyreWidth
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getTyreWidth();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2RearOverhangTotal:
                        //_D0 = .Trailer2RearOverhangTotal
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = rigOps.getTrailer2RearOverhangTotal();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2MaxDrawbarLengthCut:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.DrawbarProtrusionMaxCut
                    //ElseIf .Trailer2(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer2(0)
                    //_D0 = x.DrawbarProtrusionMaxCut
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            trailerType = tempTrailer2.getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.PUP) {
                                _D0 = tempTrailer2.getDrawbarProtrusionMaxCut();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2MaxFrontOverhangCut:
                    //If .Trailer2(0).TrailerTypeCode = "SEMI" Then
                    //Dim x As FileLoadingTrailerSemi = .Trailer2(0)
                    //_D0 = x.FrontOverhangMaxCut
                    //ElseIf .Trailer2(0).TrailerTypeCode = "INTERLINK" Then
                    //Dim x As FileLoadingTrailerInterlink = .Trailer2(0)
                    //_D0 = x.FrontOverhangMaxCut
                    //ElseIf .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.FrontOverhangMaxCut
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            trailerType = tempTrailer2.getType();
                            if (trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK || trailerType === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getFrontOverhangMaxCut();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2MaxWheelbaseTheoreticalCut:
                        //_D0 = .Trailer2(0).WheelbaseTheoreticalMaxCut
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getWheelbaseTheoreticalMaxCut();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2MaxTrailerLengthCut:
                        //_D0 = .Trailer2(0).TrailerLengthMaxCut
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getTrailerLengthMaxCut();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2MaxOverallHeightCut:
                        //_D0 = .Trailer2(0).OverallHeightMaxCut
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getOverallHeightMaxCut();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2DrawbarLengthChangeIncrement:
                    //If .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.DrawbarProtrusionChangeIncrement
                    //ElseIf .Trailer2(0).TrailerTypeCode = "PUP" Then
                    //Dim x As FileLoadingTrailerPup = .Trailer2(0)
                    //_D0 = x.DrawbarProtrusionChangeIncrement
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            trailerType = rigOps.getActiveOffer().getTrailer2().getType();
                            if (trailerType === config.TRAILER_TYPES.DRAWBAR || trailerType === config.TRAILER_TYPES.PUP) {
                                _D0 = tempTrailer2.getDrawbarProtrusionChangeIncrement();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2FrontOverhangChangeIncrement:
                    //If .Trailer2(0).TrailerTypeCode = "SEMI" Then
                    //Dim x As FileLoadingTrailerSemi = .Trailer2(0)
                    //_D0 = x.FrontOverhangChangeIncrement
                    //ElseIf .Trailer2(0).TrailerTypeCode = "INTERLINK" Then
                    //Dim x As FileLoadingTrailerInterlink = .Trailer2(0)
                    //_D0 = x.FrontOverhangChangeIncrement
                    //ElseIf .Trailer2(0).TrailerTypeCode = "DRAWBAR" Then
                    //Dim x As FileLoadingTrailerDrawbar = .Trailer2(0)
                    //_D0 = x.FrontOverhangChangeIncrement
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            trailerType = tempTrailer2.getType();
                            if (trailerType === config.TRAILER_TYPES.SEMI || trailerType === config.TRAILER_TYPES.INTERLINK || trailerType === config.TRAILER_TYPES.DRAWBAR) {
                                _D0 = tempTrailer2.getFrontOverhangChangeIncrement();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2WheelbaseChangeIncrement:
                        //_D0 = .Trailer2(0).WheelbaseTheoreticalChangeIncrement
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getWheelbaseTheoreticalChangeIncrement();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2HeightChangeIncrement:
                        //_D0 = .Trailer2(0).OverallHeightChangeIncrement
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getOverallHeightChangeIncrement();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2LengthChangeIncrement:
                        //_D0 = .Trailer2(0).OverallLengthChangeIncrement
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getLengthChangeIncrement();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2OverallHeight:
                        //_D0 = .Trailer2(0).OverallHeight
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getOverallHeightAtRear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2HeightExcludingPayload:
                        //_D0 = .Trailer2(0).OverallHeight
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getOverallHeightAtRearExcludingPayload();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2AxleLastToChassisEnd:
                        //_D0 = .Trailer2(0).RearOverhang
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getRearOverhang();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2InternalBodyLength:
                    //If .Trailer2(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                    //_D0 = .Trailer2(0).Accessories.Body.ActualInternalLength
                    //Else
                    //_D0 = 0
                        //End If
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            tempBody = tempTrailer2.getAccessoryHolder().getBody();
                            if (tempBody !== null) {
                                _D0 = tempBody.getActualInternalLength();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2TyreRadius:
                        //_D0 = .Trailer2(0).TyreRadius
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getTyreRadius();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2FridgeAdded:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null && tempTrailer2.getAccessoryHolder().getFridge() !== null) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2KingpinLoadWeight:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = rigOps.getKingpinLoad(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2KingpinLoadPercent:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = rigOps.getKingpinLoadPercent(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2StaticRolloverAngle:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = rigOps.getTrailer2StaticRolloverAngle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.OverallTrailer2VerticalCOG:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = rigOps.getTrailer2OverallVerticalCOG();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2TyreWidthRear:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            var tempTrailer2RearAxleGroup = tempTrailer2.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                            if (tempTrailer2RearAxleGroup !== null) {
                                _D0 = tempTrailer2RearAxleGroup.getTyreWidth();
                            } else {
                                _D0 = 0;
                            }
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2PayloadCOGMM:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getTrailerPayloadCOGMM();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2Payload:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getPayloadTotal();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2PayloadCOGPercent:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getTrailerPayloadCOGPercent();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2BodyAdded:
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = tempTrailer2.getAccessoryHolder().getBody() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;

                    //#endregion Trailers Section

                    case utilLegislationAttributeObjectCode.variableTypes.BullbarProtrusion:
                        //If .Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Bullbar) > 0 Then
                        //_D0 = .Vehicle.Accessories.Bullbar.Protrusion
                        //Else
                        //_D0 = 0
                        //End If
                        if (rigOps.getActiveOffer().getConfiguration().getBullbarAdded()) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getBullbarProtrusion();
                        } else {
                            _D0 = 0;
                        }
                        
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BullbarAdded:
                        //If .Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Bullbar) > 0 Then
                        //_D0 = 1
                        //Else
                        //_D0 = 0
                        //End If
                        if (rigOps.getActiveOffer().getConfiguration().getBullbarAdded()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.SnowploughAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getSnowploughAdded()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehCraneAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getCraneAdded()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.FifthWheelAdded:
                        if (rigOps.getActiveOffer().getConfiguration().getFifthWheelAdded()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;

                    case utilLegislationAttributeObjectCode.variableTypes.RolloverAdded:
                        //If .Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Rollover) > 0 Then
                        //_D0 = 1
                        //Else
                        //_D0 = 0
                        //End If
                        if (rigOps.getActiveOffer().getConfiguration().getRolloverAdded()) {
                            _D0 = 1;
                        } else {
                            _D0 = 0;
                        }
                        break;

                    case utilLegislationAttributeObjectCode.variableTypes.CombinationType:
                        switch (rigOps.getCombinationType()) {
                            case config.COMBINATION_TYPES.VEHICLE:
                                _D0 = 'VEH';
                                break;
                            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                                _D0 = 'VEHDRAW';
                                break;
                            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                                _D0 = 'VEHINTSEMI';
                                break;
                            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                                _D0 = 'VEHPUP';
                                break;
                            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                                _D0 = 'VEHSEMI';
                                break;
                            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                                _D0 = 'VEHSEMIDRAW';
                                break;
                            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                                _D0 = 'VEHSEMIPUP';
                                break;
                            default:
                                _D0 = '';
                                break;
                        }
                        break;

                    case utilLegislationAttributeObjectCode.variableTypes.CombinationOverallLength:
                        _D0 = rigOps.getOverallLengthLegal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationTurningRadiusCurbToCurb:
                        _D0 = rigOps.getCombinationTurningRadiusCurbToCurb();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationTurningRadiusWallToWall:
                        _D0 = rigOps.getCombinationTurningRadiusWallToWall();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationInnerRadius:
                        _D0 = rigOps.getCombinationInnerRadius();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CargoCarryingLength:
                        _D0 = rigOps.getCombinationCargoCarryingLength();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGapBetweenVehicleAndTrailerBody:
                        _D0 = rigOps.getVehicleAndDrawbarGapBetweenVehicleAndTrailer();
                        break;
                    
                        
                        
                        
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1FrontGross:
                        //_D0 = .Trailer1FrontGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getTrailer1FrontGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer1RearGross:
                        //_D0 = .Trailer1RearGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null) {
                            _D0 = rigOps.getTrailer1RearGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2FrontGross:
                        //_D0 = .Trailer2FrontGross
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.Trailer2RearGross:
                        //_D0 = .Trailer2RearGross
                        tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer2 !== null) {
                            _D0 = rigOps.getTrailer2RearGross();
                        } else {
                            _D0 = 0;
                        }
                        break;

                        //Adding Combination Vehicle
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehAxle3RearToRearMostAccessory:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getRearMostAccessoryEndFromRearMostAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehFrontGross:
                        _D0 = rigOps.getVehicleOnlyFrontGross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehRearGross:
                        _D0 = rigOps.getVehicleOnlyRearGross();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehTotalGross:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getGrossTotal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehRearOverhangTotal:
                        _D0 = rigOps.getActiveOffer().getConfiguration().getRearOverhangTotalInclAccessoriesAndPayload();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehOverallLength:
                        //_D0 = .OverallVehicleLengthLegal
                        _D0 = rigOps.getOverallVehicleLengthLegal();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup1DistanceRounded:
                        _D0 = rigOps.getVehicleGroup1BridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup1NoOfAxles:
                        _D0 = rigOps.getVehicleGroup1BridgeFormulaNumberOfAxles();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup2DistanceRounded:
                        _D0 = rigOps.getVehicleGroup2BridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup2NoOfAxles:
                        _D0 = rigOps.getVehicleGroup2BridgeFormulaNumberOfAxles();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup3DistanceRounded:
                        _D0 = rigOps.getVehicleGroup3BridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup3NoOfAxles:
                        _D0 = rigOps.getVehicleGroup3BridgeFormulaNumberOfAxles();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup4DistanceRounded:
                        _D0 = rigOps.getVehicleGroup4BridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehGroup4NoOfAxles:
                        _D0 = rigOps.getVehicleGroup4BridgeFormulaNumberOfAxles();
                        break;

                    //Adding Combination Vehicle + Drawbar
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarTotalGross:
                        //_D0 = .VehicleAndDrawbarTotalGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarTotalGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarVehRearGross:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleOnlyRearGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarVehRearMostAxleToAxle1TrailerFront:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleRearMostAxleToAxle1TrailerFront();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehRearMostAxleToAxle1Trailer1Rear:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() !== config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleRearMostAxleToAxle1Trailer1Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiTrailer1RearMostAxleToAxle1Trailer2:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() !== config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1RearMostAxleToAxle1Trailer2Rear();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    // case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarVehRearMostAxleToAxle1TrailerFront:
                    //     tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //     if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    //         _D0 = rigOps.getVehicleRearMostAxleToAxle1TrailerFront();
                    //     } else {
                    //         _D0 = 0;
                    //     }
                    //     break;
                    // case utilLegislationAttributeObjectCode.variableTypes.ComVehRearMostAxleToAxle1Trailer1Rear:
                    //     tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //     if (tempTrailer1 !== null && tempTrailer1.getType() !== config.TRAILER_TYPES.DRAWBAR) {
                    //         _D0 = rigOps.getVehicleRearMostAxleToAxle1Trailer1Rear();
                    //     } else {
                    //         _D0 = 0;
                    //     }
                    //     break;
                    // case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiTrailer1RearMostAxleToAxle1Trailer2:
                    //     tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //     if (tempTrailer1 !== null && tempTrailer1.getType() !== config.TRAILER_TYPES.DRAWBAR) {
                    //         _D0 = rigOps.getTrailer1RearMostAxleToAxle1Trailer2Rear();
                    //     } else {
                    //         _D0 = 0;
                    //     }
                    //     break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehHookliftRearOverhangTotal:
                        _D0 = rigOps.getHookliftRearOverhangTotal();
                        break;
                        
                        
                        
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup1DistanceRounded:
                        //_D0 = .VehicleAndDrawbarGroup1BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup1BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup1NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup1BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup2DistanceRounded:
                        //_D0 = .VehicleAndDrawbarGroup2BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup2BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup2NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup2BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup3DistanceRounded:
                        //_D0 = .VehicleAndDrawbarGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup3BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup3NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup3BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup4DistanceRounded:
                        //_D0 = .VehicleAndDrawbarGroup4BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup4BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup4NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup4BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup5DistanceRounded:
                        //_D0 = .VehicleAndDrawbarGroup5BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup5BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup5Distance:
                        //_D0 = .VehicleAndDrawbarGroup5BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup5BridgeFormulaDistance();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup5NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup5BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup6DistanceRounded:
                        //_D0 = .VehicleAndDrawbarGroup6BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup6BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup6NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup6BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup7DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup7BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup7NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup7BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup8DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup8BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup8NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup8BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup9DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup9BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup9NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup9BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup10DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup10BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup10NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup10BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup11DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup11BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup11NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleAndDrawbarGroup11BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup8NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup8WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup8Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup8Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup8Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup9NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup9WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup9Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup9Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup9Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup10NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup10WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeFrontGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup10Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeFrontGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup10Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeFrontGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup10Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeFrontGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup11NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup11WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup11Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup11Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup11Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup12DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup12NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup12WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup12Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup12Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup12Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup13DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup13NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup13WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup13Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup13Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup13Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup14DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup14NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup14WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup14Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup14Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup14Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup15DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup15NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup15WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup15Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup15Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup15Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup16DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup16NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup16WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup16Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup16Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup16Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup17DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup17NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup17WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup17Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup17Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarGroup17Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarOverallHeight:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    //        _D0 = rigOps.getOverallHeight();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarFrontalArea:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    //        _D0 = rigOps.getFrontalArea();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarOverallBridgePermissible:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    //        _D0 = rigOps.getCombinationExtremeGroupPermissible();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehDrawbarVehBodyGap:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    //        _D0 = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBodyGap();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                        

                    //Adding Combination Vehicle + Pup
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupTotalGross:
                        //_D0 = .VehicleAndPupTotalGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupTotalGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupVehRearGross:
                        //_D0 = .VehicleAndPupVehicleRearAxleGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupVehicleRearAxleGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup1DistanceRounded:
                        //_D0 = .VehicleAndPupGroup1BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup1BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup1NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup1BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup2DistanceRounded:
                        //_D0 = .VehicleAndPupGroup2BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup2BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup2NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup2BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup3DistanceRounded:
                        //_D0 = .VehicleAndPupGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup3BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup3Distance:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup3BridgeFormulaDistance();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup3NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup3BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup4DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup4BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup4NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup4BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup5DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup5BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup5NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup5BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup6DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup6BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup6NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup6BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup7DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup7BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup7NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupGroup7BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupTrailerRearOverhangTotal:
                        //_D0 = .VehicleAndPupTrailerRearOverhangTotal
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                            _D0 = rigOps.getVehicleAndPupTrailerRearOverhangTotal();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup5NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup5WidthPerTyre:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup5Lifting:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup5Raised:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup5Steering:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup6NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup6WidthPerTyre:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup6Lifting:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup6Raised:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup6Steering:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup7NoOfTyresPerAxle:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup7WidthPerTyre:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup7Lifting:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup7Raised:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup7Steering:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup8DistanceRounded:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup8NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup8WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup8Lifting:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup8Raised:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup8Steering:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup9DistanceRounded:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup9NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup9WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup9Lifting:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup9Raised:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup9Steering:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup10DistanceRounded:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup10NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup10WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup10Lifting:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup10Raised:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup10Steering:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup11DistanceRounded:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup11NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup11WidthPerTyre:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup11Lifting:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup11Raised:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup11Steering:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup12DistanceRounded:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup12NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup12WidthPerTyre:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup12Lifting:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup12Raised:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup12Steering:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup13DistanceRounded:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup13NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup13WidthPerTyre:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup13Lifting:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup13Raised:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehPupGroup13Steering:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehPupOverallHeight:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    //        _D0 = rigOps.getOverallHeight();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehPupFrontalArea:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    //        _D0 = rigOps.getFrontalArea();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehPupOverallBridgePermissible:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    //        _D0 = rigOps.getCombinationExtremeGroupPermissible();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehPupVehBodyGap:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                    //        _D0 = rigOps.getActiveOffer().getConfiguration().getAccessoryHolder().getBodyGap();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;


                    //adding Combination Vehicles + Semi
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiTotalGross:
                        //_D0 = .VehicleAndSemiOrInterlinkTotalGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkTotalGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiVehRearGross:
                        //_D0 = .VehicleAndSemiOrInterlinkVehicleRearAxleGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup1DistanceRounded:
                        //_D0 = .VehicleAndSemiOrInterlinkGroup1BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup1BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup1NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup1BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup2DistanceRounded:
                        //_D0 = .VehicleAndSemiOrInterlinkGroup2BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup2NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup2BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup3DistanceRounded:
                        //_D0 = .VehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup3Distance:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup3NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup3BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup4DistanceRounded:
                        //_D0 = .VehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup4BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup4NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup4BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup5DistanceRounded:
                        //_D0 = .VehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup5BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup5NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup5BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup6DistanceRounded:
                        //_D0 = .VehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup6BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup6NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup6BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup7DistanceRounded:
                        //_D0 = .VehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup7NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                            _D0 = rigOps.getVehicleAndSemiOrInterlinkGroup7BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiFridgeAdded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) && tempTrailer1.getAccessoryHolder().getFridge() !== null) {
                            _D0 = 1;
                        }else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup5NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup5WidthPerTyre:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup5Lifting:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup5Raised:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup5Steering:
                        _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup6NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup6WidthPerTyre:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup6Lifting:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup6Raised:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup6Steering:
                        _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup7NoOfTyresPerAxle:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup7WidthPerTyre:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup7Lifting:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup7Raised:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup7Steering:
                        _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup8DistanceRounded:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup8NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup8WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup8Lifting:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup8Raised:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup8Steering:
                        _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup9DistanceRounded:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup9NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup9WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup9Lifting:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup9Raised:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup9Steering:
                        _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup10DistanceRounded:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup10NoOfTyresPerAxle:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup10WidthPerTyre:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup10Lifting:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup10Raised:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup10Steering:
                        _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup11DistanceRounded:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup11NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup11WidthPerTyre:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup11Lifting:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup11Raised:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup11Steering:
                        _D0 = rigOps.getVehicleTag1GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup12DistanceRounded:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup12NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup12WidthPerTyre:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup12Lifting:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup12Raised:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup12Steering:
                        _D0 = rigOps.getVehicleTag2GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup13DistanceRounded:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaDistanceNearest();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup13NoOfTyresPerAxle:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup13WidthPerTyre:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaWidthPerTyre();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup13Lifting:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaLifting() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup13Raised:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaRaised() ? 1 : 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiGroup13Steering:
                        _D0 = rigOps.getVehicleTag3GroupBridgeFormulaSteering() ? 1 : 0;
                        break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiOverallHeight:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
                    //        _D0 = rigOps.getOverallHeight();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiFrontalArea:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
                    //        _D0 = rigOps.getFrontalArea();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    //case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiOverallBridgePermissible:
                    //    var tempTrailer1 = rigOps.getActiveOffer().getTrailer1();
                    //    if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
                    //        _D0 = rigOps.getCombinationExtremeGroupPermissible();
                    //    } else {
                    //        _D0 = 0;
                    //    }
                    //    break;
                    

                    //Combination Vehicle + Int + Semi
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiTotalGross:
                        //_D0 = .VehicleAndInterlinkAndSemiTotalGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiTotalGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiVehRearGross:
                        //_D0 = .VehicleAndInterlinkAndSemiVehicleRearAxleGross
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiVehicleRearAxleGross();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup1DistanceRounded:
                        //_D0 = .VehicleAndInterlinkAndSemiGroup1BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup1NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup1BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup2DistanceRounded:
                        //_D0 = .VehicleAndInterlinkAndSemiGroup2BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup2NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup2BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup3DistanceRounded:
                        //_D0 = .VehicleAndInterlinkAndSemiGroup3BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup3NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup3BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup4DistanceRounded:
                        //_D0 = .VehicleAndInterlinkAndSemiGroup4BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup4NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup4BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup5DistanceRounded:
                        //_D0 = .VehicleAndInterlinkAndSemiGroup5BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup5Distance:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistance();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup5NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup5BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup6DistanceRounded:
                        //_D0 = .VehicleAndInterlinkAndSemiGroup6BridgeFormulaDistanceNearest
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup6NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup6BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup7DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup7BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup7NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup7BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup8DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup8BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup8NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup8BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup9DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup9BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup9NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup9BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup10DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup10NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup10BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup11DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup11NoOfAxles:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiGroup11BridgeFormulaNumberOfAxles();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiXDimension:
                        //_D0 = .VehicleAndInterlinkAndSemiXDimension
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiXDimension();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiYDimension:
                        //_D0 = .VehicleAndInterlinkAndSemiYDimension
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleAndInterlinkAndSemiYDimension();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiOverallHeight:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getOverallHeight();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiFrontalArea:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getFrontalArea();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiOverallBridgePermissible:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getCombinationExtremeGroupPermissible();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup8NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup8WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup8Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup8Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup8Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeRearDrivenGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup9NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup9WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup9Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup9Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup9Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleExtremeFrontGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup10NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup10WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup10Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup10Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup10Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer1ExtremeRearGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup11NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer2ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup11WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer2ExtremeRearGroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup11Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer2ExtremeRearGroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup11Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer2ExtremeRearGroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup11Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getTrailer2ExtremeRearGroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup12DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup12NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup12WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup12Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup12Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup12Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher1GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup13DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup13NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup13WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup13Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup13Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup13Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher2GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup14DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup14NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup14WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup14Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup14Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup14Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehiclePusher3GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup15DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup15NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup15WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup15Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup15Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup15Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag1GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup16DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup16NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup16WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup16Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup16Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup16Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag2GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup17DistanceRounded:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaDistanceNearest();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup17NoOfTyresPerAxle:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup17WidthPerTyre:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaWidthPerTyre();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup17Lifting:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaLifting() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup17Raised:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaRaised() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehIntSemiGroup17Steering:
                        tempTrailer1 = rigOps.getActiveOffer().getTrailer1(), tempTrailer2 = rigOps.getActiveOffer().getTrailer2();
                        if (tempTrailer1 !== null && tempTrailer2 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                            _D0 = rigOps.getVehicleTag3GroupBridgeFormulaSteering() ? 1 : 0;
                        } else {
                            _D0 = 0;
                        }
                        break;

                    //Combination Vehicle + Semi + Drawbar
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarTotalGross:
                        //_D0 = .VehicleAndSemiAndDrawbarTotalGross
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarVehRearGross:
                        //_D0 = .VehicleAndSemiAndDrawbarVehicleRearAxleGross
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup1DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup1BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup2DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup2BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup3DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup3BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup4DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup4BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup5DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup5BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup6DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup6BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup7DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup7BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup8DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup8BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup9DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup9BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarGroup10DistanceRounded:
                        //_D0 = .VehicleAndSemiAndDrawbarGroup10BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiDrawbarOverallLength:
                        //_D0 = .OverallLengthActual
                        _D0 = 0;
                        break;

                    //Combination Vehicle + Semi + Pup
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupTotalGross:
                        //_D0 = .VehicleAndSemiAndPupTotalGross
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupVehRearGross:
                        //_D0 = .VehicleAndSemiAndPupVehicleRearAxleGross
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupGroup1DistanceRounded:
                        //_D0 = .VehicleAndSemiAndPupGroup1BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupGroup2DistanceRounded:
                        //_D0 = .VehicleAndSemiAndPupGroup2BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupGroup3DistanceRounded:
                        //_D0 = .VehicleAndSemiAndPupGroup3BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupGroup4DistanceRounded:
                        //_D0 = .VehicleAndSemiAndPupGroup4BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupGroup5DistanceRounded:
                        //_D0 = .VehicleAndSemiAndPupGroup5BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupGroup6DistanceRounded:
                        //_D0 = .VehicleAndSemiAndPupGroup6BridgeFormulaDistanceNearest
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupTrailerRearOverhangTotal:
                        //_D0 = .VehicleAndSemiAndPupTrailerRearOverhangTotal
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.ComVehSemiPupOverallLength:
                        //_D0 = .OverallLengthActual
                        _D0 = 0;
                        break;

                    case utilLegislationAttributeObjectCode.variableTypes.BodyTypeVehicle:
                    //If .Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) > 0 Then
                    //_D0 = .Vehicle.Accessories.Body.BodyTypeCode
                    //Else
                    //_D0 = String.Empty
                        //End If
                        if (rigOps.getActiveOffer().getConfiguration().getBody() !== null) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getBody().getType();
                        } else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyGapVehicle:
                    //If .Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) > 0 Then
                    //_D0 = .Vehicle.Accessories.BodyGap
                    //Else
                    //_D0 = 0
                        //End If
                        if (rigOps.getActiveOffer().getConfiguration().getBody() !== null) {
                            _D0 = rigOps.getActiveOffer().getConfiguration().getBody().getHorizontalGap();
                        } else {
                            _D0 = 0;
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyTypeTrailer1:
                        tempTrailer1 = rigOps.getActiveOffer().getRig().getTrailer1();
                        if (tempTrailer1 !== null && tempTrailer1.getAccessoryHolder().getBody() !== null) {
                            _D0 = tempTrailer1.getAccessoryHolder().getBody().getType();
                        } else if (tempTrailer1 !== null && tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                            _D0 = tempTrailer1.getBodyType();
                        }else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyLengthTrailer1:
                    //If .Trailer1.Count > 0 Then
                    //If .Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) > 0 Then
                    //_D0 = .Trailer1(0).Accessories.BodyLength
                    //Else
                    //_D0 = 0
                    //End If
                    //Else
                    //_D0 = 0
                        //End If
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyGapTrailer1:
                    //If .Trailer1.Count > 0 Then
                    //If .Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) > 0 Then
                    //_D0 = .Trailer1(0).Accessories.BodyGap
                    //Else
                    //_D0 = 0
                    //End If
                    //Else
                    //_D0 = 0
                        //End If
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyTypeTrailer2:
                        tempTrailer2 = rigOps.getActiveOffer().getRig().getTrailer2();
                        if (tempTrailer2 !== null && tempTrailer2.getAccessoryHolder().getBody() !== null) {
                            _D0 = tempTrailer2.getAccessoryHolder().getBody().getType();
                        } else if (tempTrailer2 !== null && tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                            _D0 = tempTrailer2.getBodyType();
                        }else {
                            _D0 = '';
                        }
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyLengthTrailer2:
                    //If .Trailer2.Count > 0 Then
                    //If .Trailer2(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) > 0 Then
                    //_D0 = .Trailer2(0).Accessories.BodyLength
                    //Else
                    //_D0 = 0
                    //End If
                    //Else
                    //_D0 = 0
                        //End If
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyGapTrailer2:
                    //If .Trailer2.Count > 0 Then
                    //If .Trailer2(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) > 0 Then
                    //_D0 = .Trailer2(0).Accessories.BodyGap
                    //Else
                    //_D0 = 0
                    //End If
                    //Else
                    //_D0 = 0
                        //End If
                        _D0 = 0;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.BodyWidthManufacturerMax:
                        //_D0 = .Vehicle.BodyWidthManufacturerMax
                        _D0 = rigOps.getActiveOffer().getConfiguration().getBodyWidthManufacturerMax();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationOverallWheelbase:
                        //_D0 = .Vehicle.BodyWidthManufacturerMax
                        _D0 = rigOps.getCombinationExtremeGroupDistance();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationOverallHeight:
                        _D0 = rigOps.getOverallHeight();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationHeightExcludingPayload:
                        _D0 = rigOps.getOverallHeightExcludingPayload();
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CombinationFrontalArea:
                        _D0 = rigOps.getFrontalArea();
                        break;
                    

                    case utilLegislationAttributeObjectCode.variableTypes.CVInchesToMM:
                        _D0 = 25.4;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CVFeetToInches:
                        _D0 = 12;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.CVlbTokg:
                        _D0 = 2.20462262;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.UVDimension:
                        _D0 = 99999;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.UVWeight:
                        _D0 = 999999;
                        break;
                    case utilLegislationAttributeObjectCode.variableTypes.UVPercent:
                        _D0 = 100;
                        break;
                    default:
                         // Check that attribute exists in attributes
                         var test = legislationObject.attributes.filter(function (attribute) {
                            return attribute.name.toUpperCase() === type;
                        });
                        // if it does then run through interpreter
                        if (test.length > 0) {
                            //var tempInterpreter = new Interpreter(legislationObject, rigOps);
                            //_D0 = tempInterpreter.interpret(test[0]);
                            // if(globals.debugLegislation) {
                            //     delete attributeAndVariableEvluationTimes.variables[indentMultiplier][type];
                            // }
                            _D0 = interpret(test[0]);
                            
                            //logger.log('_D0: ' + _D0, null, 'interpreter', false);
                        } else {
                            // else log as unable to be interpreted
                            _D0 = 0;
                            if(globals.debugLegislation) {
                                console.log('Unable to interpret: ' + type);
                            }
                            //logger.log('Variable not yet able to be interpreted: ' + type + ' = undefined, _CurrentInstructionCounter = ' + _CurrentInstructionCounter, null, 'interpreter', false);
                        }
                }
                if (doValueReturn) {
                    return _D0;
                }
                if(!globals.debugLegislation) {
                    lookupTable[type] = _D0;
                }else {
                    if(attributeAndVariableEvluationTimes.variables[indentMultiplier] && attributeAndVariableEvluationTimes.variables[indentMultiplier][type]) {
                        attributeAndVariableEvluationTimes.variables[indentMultiplier][type].endMillis = new Date().getTime();
                        if(attributeAndVariableEvluationTimes.variables[indentMultiplier][type].endMillis - attributeAndVariableEvluationTimes.variables[indentMultiplier][type].startMillis <= 4) {
                            // delete attributeAndVariableEvluationTimes.variables[indentMultiplier][type];
                        }
                    }
                }
                
            }

            function Negate() {
                _D0 = _D0 * -1;
            }

            function PopAdd() {
                _D0 = _D0 + _Stack[_Stack.length - 1];
                _Stack.pop()
            }

            function PopSubtract() {
                _D0 = _Stack[_Stack.length - 1] - _D0;
                _Stack.pop()
            }

            function PopDivide() {
                _D0 = _Stack[_Stack.length - 1] / _D0;
                _Stack.pop();
            }

            function PopMultiply() {
                _D0 = _D0 * _Stack[_Stack.length - 1];
                _Stack.pop();
            }

            function Push() {
                _Stack.push(_D0);
            }

            function NotIt() {
                if (_D0 === 0) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
            }

            function PopAnd() {
                if (_D0 === 1 && _Stack[_Stack.length - 1] === 1) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
                _Stack.pop();
            }

            function PopCompare() {
                var myTop = _Stack[_Stack.length - 1];
                _Stack.pop();

                if (typeof myTop === 'number' && typeof _D0 === 'number') {
                    if (parseFloat(myTop.toFixed(roundingPrecision)) === parseFloat(_D0.toFixed(roundingPrecision))) {
                        _D1 = booleanResults.Equal;
                    }

                    if (parseFloat(myTop.toFixed(roundingPrecision)) > parseFloat(_D0.toFixed(roundingPrecision))) {
                        _D1 = booleanResults.Greater;
                    }

                    if (parseFloat(myTop.toFixed(roundingPrecision)) < parseFloat(_D0.toFixed(roundingPrecision))) {
                        _D1 = booleanResults.Less;
                    }
                } else {
                    if (myTop === _D0) {
                        _D1 = booleanResults.Equal;
                    }

                    if (myTop > _D0) {
                        _D1 = booleanResults.Greater;
                    }

                    if (myTop < _D0) {
                        _D1 = booleanResults.Less;
                    }
                }

            }

            function PopOr() {
                if (_D0 === 1 || _Stack[_Stack.length - 1] === 1) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
                _Stack.pop();
            }

            function PopXor() {
                if (_D0 === 1 ^ _Stack[_Stack.length - 1] === 1) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
                _Stack.pop();
            }

            function SetEqual() {
                if (_D1 === booleanResults.Equal) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
            }

            function SetGreater() {
                if (_D1 === booleanResults.Greater) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
            }

            function SetGreaterOrEqual() {
                if (_D1 === booleanResults.Equal || _D1 === booleanResults.Greater) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
            }

            function SetLess() {
                if (_D1 === booleanResults.Less) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
            }

            function SetLessOrEqual() {
                if (_D1 === booleanResults.Equal || _D1 === booleanResults.Less) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
            }

            function SetNotEqual() {
                if (_D1 === booleanResults.Greater || _D1 === booleanResults.Less) {
                    _D0 = 1;
                } else {
                    _D0 = 0;
                }
            }

            

            function SquareRoot() {
                _D0 = Math.sqrt(_D0);
            }

            function RoundNearest() {

                var roundToNearest = _Stack.pop();
                var valueToBeRounded = _Stack.pop();
                var modValue = valueToBeRounded % roundToNearest;
                var divValue = valueToBeRounded - modValue;
                var addedValue = 0;

                if (_D0 === "TRUE") {
                //this means we are rounding up at the midpoint
                    if (modValue < roundToNearest * 0.5) {
                        addedValue = 0;
                    } else if (modValue >= roundToNearest * 0.5 && modValue < roundToNearest * 1.5) {
                        addedValue = roundToNearest;
                    } else {
                        addedValue = roundToNearest * 2;
                    }
                } else {
                    //this means we are rounding down at the midpoint
                    if (modValue <= roundToNearest * 0.5) {
                        addedValue = 0;
                    } else if (modValue > roundToNearest * 0.5 && modValue <= roundToNearest * 1.5) {
                        addedValue = roundToNearest;
                    } else {
                        addedValue = roundToNearest * 2;
                    }
                }

                _D0 = divValue + addedValue;

            }

            function PrepForCompare() {

                var unitType = _Stack.pop();
                var valueForCompare = _Stack.pop();
                

                switch(unitType) {
                    case config.INCREMENT_TYPE.LENGTH.toUpperCase():
                        _D0 = formatter.getCurMeasurementSysRoundedLength(valueForCompare);
                        break;
                    case config.INCREMENT_TYPE.MASS.toUpperCase():
                        _D0 = formatter.getCurMeasurementSysRoundedMass(valueForCompare);
                        break;
                        default:
                            _D0 = valueForCompare;
                }

                

            }
            
            function RoundUp() {
                if (_D0 < 0) {
                    _D0 = Math.floor(_D0);
                } else {
                    if (_D0 > Math.floor(_D0)) {
                        _D0 = Math.floor(_D0) + 1;
                    }
                }
            }

            function PopGreaterOf() {
                _D0 = Math.max(parseFloat(_Stack[_Stack.length - 1].toFixed(roundingPrecision)), parseFloat(_D0.toFixed(roundingPrecision)));
                _Stack.pop();
            }

            function PopLesserOf() {
                _D0 = Math.min(parseFloat(_Stack[_Stack.length - 1].toFixed(roundingPrecision)), parseFloat(_D0.toFixed(roundingPrecision)));
                _Stack.pop();
            }

            function Truncate() {
                if (_D0 < 0) {
                    _D0 = Math.floor(_D0) + 1;
                } else {
                    _D0 = Math.floor(_D0);
                }
            }

            

            function interpret(legislationAttribute, resetState) {
                //var _D0;
                //var _D1;
                //var _Stack = new Array();
                var _Instructions;
                var _CurrentInstruction = {
                    Instruction: "",
                    Parameter: ""
                };
                var _CurrentInstructionCounter;
                let curAttributeId;
                if(resetState) {
                    interpretCounter = 0;
                    loadVariableCounter = 0;
                    lookupTable = {};
                    evaluationTree = {};
                    circularReferences = [];
                    if(globals.debugLegislation) {
                        attributeAndVariableEvluationTimes = {};
                        attributeAndVariableEvluationTimes.attributes = {};
                        attributeAndVariableEvluationTimes.variables = {};
                    }
                }else if(interpretCounter%100 === 0) {
                    var breakHere = 0;
                }
                if(globals.debugLegislation) {
                    
                    let indentPrefix = '<<<';
                    for(var i = 0; i < indentMultiplier; i++) {
                        indentPrefix += '<<<';
                    }
                    indentPrefix += indentMultiplier+1;
                    console.log(indentPrefix + " Inner Interpret: " + legislationAttribute.name + ' ' + indentPrefix);
                    
                    let numCircularRefs = attributeAlreadyReferenceed(legislationAttribute.name, indentMultiplier);
                    if(numCircularRefs > 0) {
                        // circularReferences.push({ AttributeName: legislationAttribute.name, NumCircularRefs: numCircularRefs });
                        console.log('CirculareReference>>> AttributeName: ' + legislationAttribute.name);
                    }
                    curAttributeId = legislationAttribute.name + indentMultiplier;

                    
                    indentMultiplier++;
                    attributeAndVariableEvluationTimes.attributes[indentMultiplier] = {};
                    attributeAndVariableEvluationTimes.attributes[indentMultiplier][legislationAttribute.name] = {};
                    attributeAndVariableEvluationTimes.attributes[indentMultiplier][legislationAttribute.name].name = legislationAttribute.name;
                    attributeAndVariableEvluationTimes.attributes[indentMultiplier][legislationAttribute.name].startMillis = new Date().getTime();
                    attributeAndVariableEvluationTimes.variables[indentMultiplier] = {};
                    if(!evaluationTree[indentMultiplier]) {
                        evaluationTree[indentMultiplier] = {};
                    }
                    evaluationTree[indentMultiplier][legislationAttribute.name] = true;
                    
                    
                    
                    interpretCounter++;
                }
                
                _Instructions = legislationAttribute.instructions;
                _CurrentInstructionCounter = 0;
                if (_CurrentInstruction === undefined || _CurrentInstruction.Instruction === "" ) {
                    _CurrentInstruction = _Instructions[0];
                }                

                //for (var i = 0; i < legislationAttribute.Instructions.length; i++) {
                //    _CurrentInstruction.Instruction = legislationAttribute.Instructions[i][0];
                //    _CurrentInstruction.Parameter = legislationAttribute.Instructions[i][1];

                while (_CurrentInstruction !== undefined) {
                    _CurrentInstruction.Instruction = _Instructions[_CurrentInstructionCounter].Instruction;
                    _CurrentInstruction.Parameter = _Instructions[_CurrentInstructionCounter].Parameter;

                    switch (_CurrentInstruction.Instruction) {
                        case utilLegislationAttributeObjectCode.commandTypes.Branch:
                            Branch(_CurrentInstruction.Parameter);
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.BranchFalse:
                            BranchFalse(_CurrentInstruction.Parameter);
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.Clear:
                            Clear();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.EndProgram:
                            clearCurrentInstruction();
                            if(globals.debugLegislation) {
                                // if (attributeAndVariableEvluationTimes.attributes[curAttributeId]) {
                                //     attributeAndVariableEvluationTimes.attributes[curAttributeId].endMillis = new Date().getTime();
                                // } else {
                                //     var breakHere2 = 0;
                                // }
                                delete evaluationTree[indentMultiplier];
                                if (attributeAndVariableEvluationTimes.attributes[indentMultiplier] && attributeAndVariableEvluationTimes.attributes[indentMultiplier][legislationAttribute.name]) {
                                    attributeAndVariableEvluationTimes.attributes[indentMultiplier][legislationAttribute.name].endMillis = new Date().getTime();
                                } 
                                indentMultiplier--;
                                
                                
                                if(indentMultiplier === 0) {
                                    let breakHere = 0;
                                    console.log("InterpretCounter: " + interpretCounter + ', LoadVariableCounter: ' + loadVariableCounter);
                                    console.log(...circularReferences);
                                }
                            }
                            
                            return _D0;
                        case utilLegislationAttributeObjectCode.commandTypes.Label:
                            //'do nothing
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.LoadNumberConstant:
                            LoadNumberConstant(_CurrentInstruction.Parameter)
                            if(!globals.debugLegislation) {
                                lookupTable[_CurrentInstruction.Parameter] = _D0;
                            }
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.LoadStringConstant:
                            LoadStringConstant(_CurrentInstruction.Parameter);
                            if(!globals.debugLegislation) {
                                lookupTable[_CurrentInstruction.Parameter] = _D0;
                            }
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.LoadVariable:
                            // top is the original
                            //LoadVariable(_CurrentInstruction.Parameter, _CurrentInstruction.Variable)
                            LoadVariable(_CurrentInstruction.Variable, _CurrentInstruction.Parameter)
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.Negate:
                            Negate();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.NotIt:
                            NotIt();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopAdd:
                            PopAdd();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopAnd:
                            PopAnd();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopCompare:
                            PopCompare();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopDivide:
                            PopDivide();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopMultiply:
                            PopMultiply();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopOr:
                            PopOr();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopSubtract:
                            PopSubtract();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopXor:
                            PopXor();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.Push:
                            Push();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.SetEqual:
                            SetEqual();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.SetGreater:
                            SetGreater();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.SetGreaterOrEqual:
                            SetGreaterOrEqual();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.SetLess:
                            SetLess();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.SetLessOrEqual:
                            SetLessOrEqual();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.SetNotEqual:
                            SetNotEqual();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.SquareRoot:
                            SquareRoot();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.RoundNearest:
                            RoundNearest();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PrepForCompare:
                            PrepForCompare();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.RoundUp:
                            RoundUp();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopGreaterOf:
                            PopGreaterOf();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.PopLesserOf:
                            PopLesserOf();
                            break;
                        case utilLegislationAttributeObjectCode.commandTypes.Truncate:
                            Truncate();
                            break;
                        default:
                            logger.log();
                    }
                    
                    GetNextInstruction();
                }

                

                function GetNextInstruction() {

                    _CurrentInstructionCounter += 1;
                    if (_CurrentInstructionCounter <= _Instructions.length - 1) {
                        _CurrentInstruction = _Instructions[_CurrentInstructionCounter];
                    } else {
                        _CurrentInstruction = undefined;
                    }

                }
                function Branch(label) {
                    while (_CurrentInstruction !== undefined) {
                        if (_CurrentInstruction.Instruction === utilLegislationAttributeObjectCode.commandTypes.Label && _CurrentInstruction.Parameter === label) {
                            break;
                        } else {
                            GetNextInstruction();
                        }
                    }
                }

                function BranchFalse(label) {
                    if (_D0 === 0) {
                        Branch(label);
                    }
                }

                function clearCurrentInstruction() {
                    _CurrentInstruction = {
                        Instruction: "",
                        Parameter: ""
                    };
                }
            }

            function attributeAlreadyReferenceed(attributeName, treeDepth) {
                let circularReferenceFoundCount = 0;
                Object.keys(evaluationTree).forEach(function (key){
                    if(Number(key) <= treeDepth) {
                        if(evaluationTree[key][attributeName]) {
                            circularReferenceFoundCount++;
                        }
                    }
                })
                return circularReferenceFoundCount;
            }

            function getAttributeAndVariableEvluationTimes() {
                return attributeAndVariableEvluationTimes;
            }

            var module = {
                interpret: interpret,
                processLegislationAttributes: processLegislationAttributes,
                processLegislationAttribute: processLegislationAttribute,
                setLegislationObject: setLegislationObject,
                setRigOps: setRigOps,
                loadVariable: LoadVariable,
                setFormatter: setFormatter,
                getAttributeAndVariableEvluationTimes: getAttributeAndVariableEvluationTimes
                //compareWithVehicle: compareWithVehicle
            };

            return module;
          
        }

        export default Interpreter;

    