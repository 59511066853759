import config from './config';
import globals from './globals';
import utils from './utils';
// import { config, utils, globals } from '@/services';
import Axle from '../model/offer/configuration/model.axle';
    
var RigOps = function() {
    var self = this;

    var activeOffer = null;
    var originalOffer = null;
    var baseOffer = null;
    var activeRig = null;
    var originalRig = null;
    var baseRig = null;
    var evaluateLegislation = null;
    var legislationVals = null;
    var isSetup = false;
    var curCombinationPermissibleVals = null;

    function setup(activeOfferRef, originalOfferRef, baseOfferRef, evaluateLegislationRef, legislationValsRef) {
        activeOffer = activeOfferRef;
        originalOffer = originalOfferRef;
        activeRig = activeOfferRef.getRig();
        originalRig = originalOfferRef.getRig();
        if (baseOfferRef !== undefined) {
            baseOffer = baseOfferRef;
            baseRig = baseOfferRef.getRig();
        }
        
        evaluateLegislation = evaluateLegislationRef;
        legislationVals = legislationValsRef;
        
        isSetup = true;
    }

    function cleanUp() {
        isSetup = false;
        activeOffer = null;
        originalOffer = null;
        baseOffer = null;
        activeRig = null;
        originalRig = null;
        evaluateLegislation = null;
        legislationVals = null;
    }

    function setLegislationEvaluationFunction(evaluateLegislationRef) {
        evaluateLegislation = evaluateLegislationRef;
    }

    function evaluationLegislationFunction(attributeName) {
        return evaluateLegislation(attributeName);
    }

    function getActiveOffer() {
        return activeOffer;
    }

    function getOriginalOffer() {
        return originalOffer;
    }

    function getBaseOffer() {
        return baseOffer;
    }

    function getActiveRig() {
        return activeRig;
    }

    function getOriginalRig() {
        return originalRig;
    }

    function getIsSetup() {
        return isSetup;
    }

    function getOverallHeight() {
        /*
        Dim myHeight As Double = _Vehicle.OverallHeight

        If _Trailer1.Count = 1 Then

            myHeight = Math.Max(myHeight, _Trailer1.OverallHeight)

            If _Trailer2.Count = 1 Then
                myHeight = Math.Max(myHeight, _Trailer2.OverallHeight)
            End If

        End If

        Return myHeight
        */
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        var overallHeight = activeRig.getVehicle().getOverallHeight();
        if (tempTrailer1 !== null) {
            overallHeight = Math.max(overallHeight, tempTrailer1.getOverallHeightAtRear());
            if (tempTrailer2 !== null) {
                overallHeight = Math.max(overallHeight, tempTrailer2.getOverallHeightAtRear());
            }
        }
        return overallHeight;
    }

    function getOverallHeightExcludingPayload() {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        var overallHeightExcludingPayload = activeRig.getVehicle().getOverallHeightExcludingPayload();
        if (tempTrailer1 !== null) {
            overallHeightExcludingPayload = Math.max(overallHeightExcludingPayload, tempTrailer1.getOverallHeightAtRearExcludingPayload());
            if (tempTrailer2 !== null) {
                overallHeightExcludingPayload = Math.max(overallHeightExcludingPayload, tempTrailer2.getOverallHeightAtRearExcludingPayload());
            }
        }
        return overallHeightExcludingPayload;
    }

    function getOverallVehicleLengthLegal() {
        /*If LABullbarIncludedInLength = 1 Then
        Return _Vehicle.OverallLengthInclAccessories
        Else
        If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Bullbar) Then
        Return _Vehicle.OverallLengthInclAccessories - _Vehicle.Accessories.Bullbar.Protrusion
        Else
        Return _Vehicle.OverallLengthInclAccessories
        End If
        End If*/
        var tempVehicle = activeRig.getVehicle();
        if (evaluateLegislation(legislationVals.variableTypes.BullbarIncludedInLength) === 1) {
            //return activeRig.getVehicle().getOverallLengthInclAccessories();
            return tempVehicle.getOverallLengthInclAccessoriesAndPayload(true);
        } else {
            if (tempVehicle.getAccessoryHolder().getAccessoryCount(config.ACCESSORY_TYPES.BULLBAR) > 0) {
                //return activeRig.getVehicle().getOverallLengthInclAccessoriesAndPayload() - activeRig.getVehicle().getAccessoryHolder().getBullbar().getProtrusion();
                var bullbarProtrusion = Math.max(-tempVehicle.getAccessoryHolder().getBullbar().getHorizontalGap() - tempVehicle.getBumperToBackOfCab(), 0);
                var forwardMostProtrudingItem = Math.abs(tempVehicle.getAccessoryHolder().getAccessoryLeftMostPoint(tempVehicle.getBumperToBackOfCab(), tempVehicle.getFrontOverhang(), true));
                if(bullbarProtrusion > forwardMostProtrudingItem) {
                    return tempVehicle.getOverallLengthInclAccessoriesAndPayload(false) + bullbarProtrusion;
                } else {
                    return tempVehicle.getOverallLengthInclAccessoriesAndPayload() - bullbarProtrusion;
                }
                
            } else {
                return tempVehicle.getOverallLengthInclAccessoriesAndPayload();
            }
        }

        
    }

    function getOverallChassisObjectLengthLegal(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return getOverallVehicleLengthLegal();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return activeRig.getTrailer1().getOverallLengthInclAccessoriesAndPayload();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return activeRig.getTrailer2().getOverallLengthInclAccessoriesAndPayload();
        } else {
            return 0;
        }
    }

    function getChassisObjectOverallHeight(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return activeRig.getVehicle().getOverallHeight();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return activeRig.getTrailer1().getOverallHeightAtRear();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return activeRig.getTrailer2().getOverallHeightAtRear();
        } else {
            return 0;
        }
    }

    function getOverallLengthLegal() {
        
        if (evaluateLegislation(legislationVals.variableTypes.BullbarIncludedInLength) === 1) {
            return getOverallLengthActual(true);
        } else {
            var tempVehicle = activeRig.getVehicle();
            if (tempVehicle.getAccessoryHolder().getAccessoryCount(config.ACCESSORY_TYPES.BULLBAR) > 0) {
                //return getOverallLengthActual() - activeRig.getVehicle().getAccessoryHolder().getBullbar().getProtrusion();
                var bullbarProtrusion = Math.max(-tempVehicle.getAccessoryHolder().getBullbar().getHorizontalGap() - tempVehicle.getBumperToBackOfCab(), 0);
                var forwardMostProtrudingItem = Math.abs(tempVehicle.getAccessoryHolder().getAccessoryLeftMostPoint(tempVehicle.getBumperToBackOfCab(), tempVehicle.getFrontOverhang(), true));
                if (bullbarProtrusion > forwardMostProtrudingItem) {
                    //return tempVehicle.getOverallLengthInclAccessoriesAndPayload(false) + bullbarProtrusion;
                    return getOverallLengthActual(false) + bullbarProtrusion;
                } else {
                    //return tempVehicle.getOverallLengthInclAccessoriesAndPayload();
                    return getOverallLengthActual() - bullbarProtrusion;
                }
                
            } else {
                return getOverallLengthActual();
            }
        }

        //return activeRig.getVehicle().getOverallLengthInclAccessories();
        /*
        If LABullbarIncludedInLength = 1 Then
            Return OverallLengthActual
        Else
            If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Bullbar) Then
                Return OverallLengthActual - _Vehicle.Accessories.Bullbar.Protrusion
            Else
                Return OverallLengthActual
            End If
        End If

        Return OverallLengthActual
        */
    }

    /*
    Public ReadOnly Property WheelbaseChangeIncrement() As Double
    Get
        Return Axle2FrontToAxle1Rear - OriginalWheelbase
    End Get
End Property
    */
    function getWheelbaseChangeIncrement() {
        return activeRig.getVehicle().getAxle2FrontToAxle1Rear() - originalRig.getVehicle().getAxle2FrontToAxle1Rear();
    }

    function getVehicleWheelbaseSpecified() {
        var tempVehicle = activeRig.getVehicle();
        if (tempVehicle.getWheelbaseOverride() === true && !globals.wheelbaseSpecifiedOptionJustChanged) {
            return tempVehicle.getWheelbaseSpecified();
        } else {
            return getWheelbaseSpecified();
        }
    }

    function getWheelbaseSpecified(optionalSpecifyWheelbaseAs) {
        
        var tempVehicle = activeRig.getVehicle();
        var specifyWheelbaseAs = optionalSpecifyWheelbaseAs || globals.user.getSpecifyWheelbaseAs();

        var calculatedWheelbaseSpecified = 0;

        switch (specifyWheelbaseAs) {
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
                calculatedWheelbaseSpecified = tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToCentreOfRearDrivenAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
                calculatedWheelbaseSpecified = tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToCentreOfRearAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
                calculatedWheelbaseSpecified = tempVehicle.getAxle1FrontToAxle2Front()/2 + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToCentreOfRearAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
                calculatedWheelbaseSpecified = tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToFirstRearDrivenAxle();
                break;
        }

        return calculatedWheelbaseSpecified;
    }

    function getOriginalWheelbaseSpecified() {

        var tempOriginalVehicle = originalRig.getVehicle();
        var specifyWheelbaseAs = globals.user.getSpecifyWheelbaseAs();

        var calculatedWheelbaseSpecified = 0;

        switch (specifyWheelbaseAs) {
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
                calculatedWheelbaseSpecified = tempOriginalVehicle.getAxle1FrontToAxle2Front() + tempOriginalVehicle.getAxle2FrontToAxle1Rear() + tempOriginalVehicle.getAxle1RearToCentreOfRearDrivenAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
                calculatedWheelbaseSpecified = tempOriginalVehicle.getAxle1FrontToAxle2Front() + tempOriginalVehicle.getAxle2FrontToAxle1Rear() + tempOriginalVehicle.getAxle1RearToCentreOfRearAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
                calculatedWheelbaseSpecified = tempOriginalVehicle.getAxle1FrontToAxle2Front() / 2 + tempOriginalVehicle.getAxle2FrontToAxle1Rear() + tempOriginalVehicle.getAxle1RearToCentreOfRearAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
                calculatedWheelbaseSpecified = tempOriginalVehicle.getAxle1FrontToAxle2Front() + tempOriginalVehicle.getAxle2FrontToAxle1Rear() + tempOriginalVehicle.getAxle1RearToFirstRearDrivenAxle();
                break;
        }

        return calculatedWheelbaseSpecified;
    }

    function getBaseWheelbaseSpecified() {

        var tempBaseVehicle = baseRig.getVehicle();
        var specifyWheelbaseAs = globals.user.getSpecifyWheelbaseAs();

        var calculatedWheelbaseSpecified = 0;

        switch (specifyWheelbaseAs) {
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
                calculatedWheelbaseSpecified = tempBaseVehicle.getAxle1FrontToAxle2Front() + tempBaseVehicle.getAxle2FrontToAxle1Rear() + tempBaseVehicle.getAxle1RearToCentreOfRearDrivenAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
                calculatedWheelbaseSpecified = tempBaseVehicle.getAxle1FrontToAxle2Front() + tempBaseVehicle.getAxle2FrontToAxle1Rear() + tempBaseVehicle.getAxle1RearToCentreOfRearAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
                calculatedWheelbaseSpecified = tempBaseVehicle.getAxle1FrontToAxle2Front() / 2 + tempBaseVehicle.getAxle2FrontToAxle1Rear() + tempBaseVehicle.getAxle1RearToCentreOfRearAxles();
                break;
            case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
                calculatedWheelbaseSpecified = tempBaseVehicle.getAxle1FrontToAxle2Front() + tempBaseVehicle.getAxle2FrontToAxle1Rear() + tempBaseVehicle.getAxle1RearToFirstRearDrivenAxle();
                break;
        }

        return calculatedWheelbaseSpecified;
    }

    function adjustRearMostProtrudingItemForWheelbaseSecifiedAsOptionFromDefaultWheelbase() {
        
        var tempVehicle = getActiveOffer().getRig().getVehicle();
        var specifyWheelbaseAs = globals.user.getSpecifyWheelbaseAs();
        var wheelbaseSpecified = getWheelbaseSpecified(specifyWheelbaseAs);
        var rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles();

        //switch (specifyWheelbaseAs) {
        //    case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTER_OF_REAR_DRIVEN_AXLES:
                
        //        if (tempVehicle.getAxle1FrontToAxle2Front() === 0) {
        //            var oldWheelbaseSpecified = getWheelbaseSpecified(config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES);
        //            var newWheelbaseSpecified = getWheelbaseSpecified(specifyWheelbaseAs);

        //            var currentCabToAxles = oldWheelbaseSpecified + getAppropriateAxle1FrontToAxle2FrontAddition() - tempVehicle.getAxle1FrontToBackOfCab();
        //            var newCabToAxles = newWheelbaseSpecified + getAppropriateAxle1FrontToAxle2FrontAddition() - tempVehicle.getAxle1FrontToBackOfCab();

        //            if (currentCabToAxles !== newCabToAxles) {
        //                rearMostProtrudingItemBehindCabToRearAxles = rearMostProtrudingItemBehindCabToRearAxles + (newCabToAxles - currentCabToAxles);
        //            }
        //        } else {
        //            if (tempVehicle.getAxle3RearToAxle4Rear() > 0) {
        //                rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
        //                                                        (wheelbaseSpecified - (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + 0.5 * tempVehicle.getAxle2RearToAxle3Rear()));
        //            } else if (tempVehicle.getAxle2RearToAxle3Rear() > 0) {
        //                rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
        //                                                        (wheelbaseSpecified - (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear()));
        //            } else {
        //                rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
        //                                                        (wheelbaseSpecified - (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + 0.5 * tempVehicle.getAxle1RearToAxle2Rear()));
        //            }
        //        }
        //        break;
        //    case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_FIRST_REAR_DRIVEN_AXLE:
        //    case config.SPECIFY_WHEELBASE_AS_OPTIONS.FRONT_MOST_STEERING_AXLE_TO_CENTRE_OF_REAR_AXLES:
        //        rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
        //                                                (wheelbaseSpecified - (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + (tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear()) / 2));
        //        break;
        //    case config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES:
        //        rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
        //                                                ((wheelbaseSpecified + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart()) -
        //                                                    (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + (tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear()) / 2));
        //        break;
        //}

        if (tempVehicle.getAxle3RearToAxle4Rear() > 0) {
            rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
                                                    (wheelbaseSpecified + evaluateLegislation(legislationVals.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) -
                                                    (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + 0.5 * tempVehicle.getAxle2RearToAxle3Rear()));
        } else if (tempVehicle.getAxle2RearToAxle3Rear() > 0) {
            rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
                                                    (wheelbaseSpecified + evaluateLegislation(legislationVals.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) - 
                                                    (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear()));
        } else {
            rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() +
                                                    (wheelbaseSpecified + evaluateLegislation(legislationVals.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) -
                                                    (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + 0.5 * tempVehicle.getAxle1RearToAxle2Rear()));
        }

        tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(rearMostProtrudingItemBehindCabToRearAxles);
    }

    function adjustRearMostProtrudingItemWhenSwitchingWheelbaseSpecifiedAsOption(oldWheelbaseSpecifiedAsOption, newWheelbaseSpecifiedAsOption) {

        var tempVehicle = activeRig.getVehicle();
        

        var rearMostProtrudingItemBehindCabToRearAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles();

        var oldWheelbaseSpecified = getWheelbaseSpecified(oldWheelbaseSpecifiedAsOption);
        var newWheelbaseSpecified = getWheelbaseSpecified(newWheelbaseSpecifiedAsOption);

        var currentCabToAxles = oldWheelbaseSpecified + getAppropriateAxle1FrontToAxle2FrontAddition() - tempVehicle.getAxle1FrontToBackOfCab();
        var newCabToAxles = newWheelbaseSpecified + getAppropriateAxle1FrontToAxle2FrontAddition() - tempVehicle.getAxle1FrontToBackOfCab();

        if (currentCabToAxles !== newCabToAxles) {
            tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(rearMostProtrudingItemBehindCabToRearAxles + (newCabToAxles - currentCabToAxles) + getAppropriateAxle1FrontToAxle2FrontAddition());
            if (oldWheelbaseSpecifiedAsOption === config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES && newWheelbaseSpecifiedAsOption !== config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES) {
                tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - tempVehicle.getAxle1FrontToAxle2Front() * 0.5);
            }
        }
        
    }

    function getVehicleOnlyFrontTare() {
        //if (activeRig.getVehicle().getTareFrontOverride() === true) {
        //    return activeRig.getVehicle().getTareFront();
        //} else {
        //    return originalRig.getVehicle().getTareFront();
        //}
        
        var tempVehicle = activeRig.getVehicle();
        var hasAddedPusherOrTag = tempVehicle.getAxlesHolder().hasAddedPusherOrTag();

        if (hasAddedPusherOrTag) {
            return getDistributedTareFrontTotal();
        } else {
            return activeRig.getVehicle().getTareFront();
        }
    }

    function getCombinationVehicleFrontTare() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleOnlyFrontTare();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleOnlyFrontTare() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleOnlyFrontTare() + getVehicleAndPupTareTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return getVehicleOnlyFrontTare() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
    }

    function getVehicleOnlyRearTare() {
        //if (activeRig.getVehicle().getTareRearOverride() === true) {
        //    return activeRig.getVehicle().getTareRear();
        //} else {
        //    return originalRig.getVehicle().getTareRear();
        //}
        
        var tempVehicle = activeRig.getVehicle();
        //var hasAddedPusherOrTagDown = tempVehicle.getAxlesHolder().hasAddedPusherOrTagDown();
        var hasAddedPusherOrTag = tempVehicle.getAxlesHolder().hasAddedPusherOrTag();

        if (hasAddedPusherOrTag) {
            return getDistributedTareRearTotal();
        } else {
            if (tempVehicle.getTareRearOverride()) {
                return tempVehicle.getTareRearRear();
            }
            return tempVehicle.getTareRear();
        }
    }

    function getVehicleTareRearRearOnly() {
        var tempVehicle = activeRig.getVehicle();
        if (tempVehicle.getAxlesHolder().getAnyRearAxlesOverridden() || (tempVehicle.getAxlesHolder().hasPreconfiguredPusherOrTag() && tempVehicle.getTareRearOverride())) {
            if (tempVehicle.getTareRearOverride()) {
                return tempVehicle.getAxlesHolder().getPusherAndTagOnlyRearAxleWeight() + tempVehicle.getTareRearRear();
            } else {
                return tempVehicle.getAxlesHolder().getRearAxleWeight();
            }
        } else {
            return getVehicleOnlyRearTare();
        }
    }

    function getCombinationVehicleRearTare() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleOnlyRearTare();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleOnlyRearTare() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleOnlyRearTare() + getVehicleAndPupTareTransferOntoVehicleRearAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                // Return VehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer
                return getVehicleOnlyRearTare() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
    }

    function getVehicleOnlyFrontUnladen() {
        
        var tempVehicle = activeRig.getVehicle();
        var hasAddedPusherOrTagDown = tempVehicle.getAxlesHolder().hasAddedPusherOrTagDown();
        var hasAddedPusherOrTag = tempVehicle.getAxlesHolder().hasAddedPusherOrTag();

        if (hasAddedPusherOrTag) {
            return activeRig.getVehicle().getRevisedUnladenFront(getDistributedTareFrontTotal());
        } else {
            return activeRig.getVehicle().getUnladenFront();
        }
    }

    function getCombinationVehicleFrontUnladen() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleOnlyFrontUnladen();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleOnlyFrontUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleOnlyFrontUnladen() + getVehicleAndPupTareTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return getVehicleOnlyFrontUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
    }

    function getVehicleOnlyRearUnladen() {
        
        var tempVehicle = activeRig.getVehicle();
        var hasAddedPusherOrTag = tempVehicle.getAxlesHolder().hasAddedPusherOrTag();

        if (hasAddedPusherOrTag) {
            return activeRig.getVehicle().getRevisedUnladenRear(getDistributedTareRearTotal());
        } else {
            return activeRig.getVehicle().getUnladenRear();
        }
    }

    function getCombinationVehicleRearUnladen() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleOnlyRearUnladen();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleOnlyRearUnladen() + getVehicleAndPupTareTransferOntoVehicleRearAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                // Return VehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer
                return getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
    }

    //function getVehiclePermissibleTotal() {
    //    if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
    //        return activeOffer.getVehicleGVW();
    //    } else {
    //        return evaluateLegislation('COMVEHPERMISSIBLETOTALSUB');
    //    }
    //}

    function getVehicleHorizontalCOG() {
        var vehHCOG = getVehicleOnlyRearTare() / (getVehicleOnlyFrontTare() + getVehicleOnlyRearTare()) * activeRig.getVehicle().getWheelbaseTheoretical();
        //if (!isNaN(parseFloat(vehHCOG)) && isFinite(vehHCOG)) {
        //    return vehHCOG;
        //} else {
        //    return 0;
        //}
        return utils.returnValueOrZeroIfNanOrInfinity(vehHCOG);
    }

    function getTrailer1HorizontalCOG() {
        var trailer1HCOG = activeRig.getTrailer1().getTareRear() / (activeRig.getTrailer1().getTareFront() + activeRig.getTrailer1().getTareRear()) * activeRig.getTrailer1().getWheelbaseTheoretical();

        return utils.returnValueOrZeroIfNanOrInfinity(trailer1HCOG);
    }

    function getTrailer2HorizontalCOG() {
        var trailer2HCOG = activeRig.getTrailer2().getTareRear() / (activeRig.getTrailer2().getTareFront() + activeRig.getTrailer2().getTareRear()) * activeRig.getTrailer2().getWheelbaseTheoretical();

        return utils.returnValueOrZeroIfNanOrInfinity(trailer2HCOG);
    }

    function getTrailerHorizontalCOG(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1HorizontalCOG();
        } else {
            return getTrailer2HorizontalCOG();
        }
    }

    function getOverallHorizontalCOG() {
        var numerator, denominator;

        var tempVehicle = activeRig.getVehicle();


        //'chassis cab
        numerator = (getCombinationVehicleFrontTare() + getCombinationVehicleRearTare()) * getVehicleHorizontalCOG();
        denominator = getCombinationVehicleFrontTare() + getCombinationVehicleRearTare();

        //'crew first row
        let numberOfCrewToIncludeInFirstRow = tempVehicle.getNoOfCrewInFirstRow();
        let driverIncluded = tempVehicle.getTareDriverIncluded() === config.TARE_DRIVER_INCLUDED_OPTIONS.YES;
        if(driverIncluded) {
            numberOfCrewToIncludeInFirstRow--;
        }
        if(numberOfCrewToIncludeInFirstRow > 0) { 
            numerator += numberOfCrewToIncludeInFirstRow * tempVehicle.getCrewMassPerPerson() * (getVehicleCrewFirstRowHorizontalCOG() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
            denominator += numberOfCrewToIncludeInFirstRow * tempVehicle.getCrewMassPerPerson();
        }

        //'crew second row
        var crewSecondRow = activeRig.getVehicle().getCrewSecondRow();//getAttributeGroup('VEHICLE.' + config.VALUE_TYPE.CREWSECONDROW);
        if (crewSecondRow === true) {
            numerator += tempVehicle.getNoOfCrewInSecondRow() * tempVehicle.getCrewMassPerPerson() * (getVehicleCrewSecondRowHorizontalCOG() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
            denominator += tempVehicle.getNoOfCrewInSecondRow() * tempVehicle.getCrewMassPerPerson();
        }

        activeRig.getVehicle().getFuelTanks().forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true) {
                numerator += (activeRig.getVehicle().getFuelSpecificGravity() * fuelTank.getVolumeIncludedInExtras()) * (fuelTank.getHorizontalCog() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
                denominator += (activeRig.getVehicle().getFuelSpecificGravity() * fuelTank.getVolumeIncludedInExtras());
            }

        });
        //'factory-fitted fuel tanks
        //If FuelTank1Included Then
        //numerator += TotalFuelTank1 * (_FuelTanks(0).HorizontalCOG - Axle1FrontToWheelbaseTheoreticalStart)
        //denominator += TotalFuelTank1
        //End If

        //If FuelTank2Included Then
        //numerator += TotalFuelTank2 * (_FuelTanks(1).HorizontalCOG - Axle1FrontToWheelbaseTheoreticalStart)
        //denominator += TotalFuelTank2
        //End If

        //If FuelTank3Included Then
        //numerator += TotalFuelTank3 * (_FuelTanks(2).HorizontalCOG - Axle1FrontToWheelbaseTheoreticalStart)
        //denominator += TotalFuelTank3
        //End If

        //If FuelTank4Included Then
        //numerator += TotalFuelTank4 * (_FuelTanks(3).HorizontalCOG - Axle1FrontToWheelbaseTheoreticalStart)
        //denominator += TotalFuelTank4
        //End If

        //'crew cab
        //If Accessories.CrewCab IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromWheelbaseTheoreticalStartForVehicle(Accessories.CrewCab,
        //                                                                            FrontOverhang,
        //                                                                            BumperToBackOfCab,
        //                                                                            Axle1FrontToWheelbaseTheoreticalStart,
        //                                                                            WheelbaseTheoretical,
        //                                                                            Nothing) * Accessories.CrewCab.Mass
        //denominator += Accessories.CrewCab.Mass

        //End If

        //'crane
        var tempCrane = tempVehicle.getAccessoryHolder().getCrane();
        if (tempCrane !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempCrane,
                                            tempVehicle.getFrontOverhang(),
                                            tempVehicle.getBumperToBackOfCab(),
                                            tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                                            tempVehicle.getWheelbaseTheoretical()) * tempCrane.getMass();
            denominator += tempCrane.getMass()

        }

        //'fuel tanks
        //For Each fuelTank As FileLoadingAccessoryFuelTank In Accessories.FuelTanks

        //numerator += Accessories.GetDistanceFromWheelbaseTheoreticalStartForVehicle(fuelTank,
        //                                                                            FrontOverhang,
        //                                                                            BumperToBackOfCab,
        //                                                                            Axle1FrontToWheelbaseTheoreticalStart,
        //                                                                            WheelbaseTheoretical,
        //                                                                            Nothing) * fuelTank.Mass
        //denominator += fuelTank.Mass

        //Next

        //'fifth wheel
        var tempTrailer1, kingPinLoad; 
        var tempFifthWheel = tempVehicle.getAccessoryHolder().getFifthWheel();
        if (tempFifthWheel !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempFifthWheel,
                                            tempVehicle.getFrontOverhang(),
                                            tempVehicle.getBumperToBackOfCab(),
                                            tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                                            tempVehicle.getWheelbaseTheoretical()) * tempFifthWheel.getMass();
            denominator += tempFifthWheel.getMass()

            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                numerator += (tempVehicle.getWheelbaseTheoretical() - tempFifthWheel.getOffset()) * kingPinLoad;
                denominator += kingPinLoad;
            }

        } else if (tempVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                numerator += (tempVehicle.getWheelbaseTheoretical() - getFifthWheelOffset()) * kingPinLoad;
                denominator += kingPinLoad;
            }
        }

        var tempHitch = tempVehicle.getAccessoryHolder().getHitch();
        if (tempHitch !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempHitch,
                                            tempVehicle.getFrontOverhang(),
                                            tempVehicle.getBumperToBackOfCab(),
                                            tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                                            tempVehicle.getWheelbaseTheoretical()) * tempHitch.getMass();
            denominator += tempHitch.getMass();

            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                var hitchLoad = getHitchLoad();
                numerator += (tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset()) * hitchLoad;
                denominator += hitchLoad;
            }

        }
        //End If

        //'rollover
        //If Accessories.Rollover IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromWheelbaseTheoreticalStartForVehicle(Accessories.Rollover,
        //                                                                            FrontOverhang,
        //                                                                            BumperToBackOfCab,
        //                                                                            Axle1FrontToWheelbaseTheoreticalStart,
        //                                                                            WheelbaseTheoretical,
        //                                                                            Nothing) * Accessories.Rollover.Mass
        //denominator += Accessories.Rollover.Mass

        //End If

        //'headboard
        //If Accessories.Headboard IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromWheelbaseTheoreticalStartForVehicle(Accessories.Headboard,
        //                                                                            FrontOverhang,
        //                                                                            BumperToBackOfCab,
        //                                                                            Axle1FrontToWheelbaseTheoreticalStart,
        //                                                                            WheelbaseTheoretical,
        //                                                                            Nothing) * Accessories.Headboard.Mass
        //denominator += Accessories.Headboard.Mass

        //End If

        //'bullbar
        //If Accessories.Bullbar IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromWheelbaseTheoreticalStartForVehicle(Accessories.Bullbar,
        //                                                                            FrontOverhang,
        //                                                                            BumperToBackOfCab,
        //                                                                            Axle1FrontToWheelbaseTheoreticalStart,
        //                                                                            WheelbaseTheoretical,
        //                                                                            Nothing) * Accessories.Bullbar.Mass
        //denominator += Accessories.Bullbar.Mass

        //End If

        //'other accessories
        activeRig.getVehicle().getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(accessory,
                                                                                    tempVehicle.getFrontOverhang(),
                                                                                    tempVehicle.getBumperToBackOfCab(),
                                                                                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                                                                                    tempVehicle.getWheelbaseTheoretical()) * accessory.getMass();
                denominator += accessory.getMass()
            }
        });

        //'taillift
        var tempTaillift = activeRig.getVehicle().getAccessoryHolder().getTaillift();
        if (tempTaillift !== null) {
            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempTaillift,
                                                                                    tempVehicle.getFrontOverhang(),
                                                                                    tempVehicle.getBumperToBackOfCab(),
                                                                                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                                                                                    tempVehicle.getWheelbaseTheoretical()) * tempTaillift.getMass();
            denominator += tempTaillift.getMass()
        }

        //'fridge
        var tempFridge = activeRig.getVehicle().getAccessoryHolder().getFridge();
        if (tempFridge !== null) {
            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempFridge,
                                                                                    tempVehicle.getFrontOverhang(),
                                                                                    tempVehicle.getBumperToBackOfCab(),
                                                                                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                                                                                    tempVehicle.getWheelbaseTheoretical()) * tempFridge.getMass();
            denominator += tempFridge.getMass();
        }

        var tempHooklift = tempVehicle.getAccessoryHolder().getHooklift();
        if (tempHooklift !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempHooklift,
                tempVehicle.getFrontOverhang(),
                tempVehicle.getBumperToBackOfCab(),
                tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                tempVehicle.getWheelbaseTheoretical()) * tempHooklift.getMass();
            denominator += tempHooklift.getMass()

        }
        //'fairing
        //If Accessories.Fairing IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromWheelbaseTheoreticalStartForVehicle(Accessories.Fairing,
        //                                                                            FrontOverhang,
        //                                                                            BumperToBackOfCab,
        //                                                                            Axle1FrontToWheelbaseTheoreticalStart,
        //                                                                            WheelbaseTheoretical,
        //                                                                            Nothing) * Accessories.Fairing.Mass
        //denominator += Accessories.Fairing.Mass

        //End If

        //'body
        var tempBody = tempVehicle.getBody();
        if (tempBody !== null) {
            numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempBody,
                    tempVehicle.getFrontOverhang(),
                    tempVehicle.getBumperToBackOfCab(),
                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                    tempVehicle.getWheelbaseTheoretical()) * tempBody.getActualMass();
            denominator += tempBody.getActualMass();

            //    //'body payload
            //tempBody.getPayloadHolder().getPayloadObjects().forEach(function (payloadObject) {
            //    numerator += (tempVehicle.getFrontOfVehicleToPayloadDeckStart() - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart()) +
            //        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
            //    denominator += payloadObject.getMass();
            //});
        }

        if (tempBody !== null || tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //'payload
            if (tempVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                tempVehicle.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadObject) {
                    numerator += (tempVehicle.getFrontOfVehicleToPayloadDeckStart() - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart()) +
                        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
                    denominator += payloadObject.getMass();
                });
            } else {
                var payloadObject = tempVehicle.getPayloadHolder().getSimplePayloadObject();
                numerator += (tempVehicle.getFrontOfVehicleToPayloadDeckStart() - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart()) +
                        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
                denominator += payloadObject.getMass();
            }
            
        }


        if (denominator !== 0) {
            return numerator / denominator;
        } else {
            return 0;
        }
    }

    function getOverallVerticalCOG() {
        var numerator, denominator, subframeHeight;

        var tempVehicle = activeRig.getVehicle();
        var tempBody = tempVehicle.getBody();
        if (tempBody !== null) {
            subframeHeight = tempBody.getSubframeHeight();
        } else {
            subframeHeight = 0;
        }


        //'chassis cab
        numerator = (getCombinationVehicleFrontTare() + getCombinationVehicleRearTare()) * getVehicleVerticalCOG();
        denominator = getCombinationVehicleFrontTare() + getCombinationVehicleRearTare();

        //'crew first row
        let numberOfCrewToIncludeInFirstRow = tempVehicle.getNoOfCrewInFirstRow();
        let driverIncluded = tempVehicle.getTareDriverIncluded() === config.TARE_DRIVER_INCLUDED_OPTIONS.YES;
        if(driverIncluded) {
            numberOfCrewToIncludeInFirstRow--;
        }
        if(numberOfCrewToIncludeInFirstRow > 0) { 
            numerator += numberOfCrewToIncludeInFirstRow * tempVehicle.getCrewMassPerPerson() * getVehicleCrewFirstRowVerticalCOG();
            denominator += numberOfCrewToIncludeInFirstRow * tempVehicle.getCrewMassPerPerson();
        }
        //'crew second row
        var crewSecondRow = activeRig.getVehicle().getCrewSecondRow();//getAttributeGroup('VEHICLE.' + config.VALUE_TYPE.CREWSECONDROW);
        if (crewSecondRow === true) {
            numerator += tempVehicle.getNoOfCrewInSecondRow() * tempVehicle.getCrewMassPerPerson() * getVehicleCrewSecondRowVerticalCOG();
            denominator += tempVehicle.getNoOfCrewInSecondRow() * tempVehicle.getCrewMassPerPerson();
        }

        //'factory-fitted fuel tanks
        activeRig.getVehicle().getFuelTanks().forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true) {
                numerator += ((activeRig.getVehicle().getFuelSpecificGravity() * fuelTank.getVolumeIncludedInExtras()) * fuelTank.getVerticalCog());
                denominator += (activeRig.getVehicle().getFuelSpecificGravity() * fuelTank.getVolumeIncludedInExtras());
            }
        });
        //#unfinished, is there a check for included missing from the fueltank code above?

        //If FuelTank1Included Then
        //numerator += TotalFuelTank1 * _FuelTanks(0).VerticalCOG
        //denominator += TotalFuelTank1
        //End If

        //If FuelTank2Included Then
        //numerator += TotalFuelTank2 * _FuelTanks(1).VerticalCOG
        //denominator += TotalFuelTank2
        //End If

        //If FuelTank3Included Then
        //numerator += TotalFuelTank3 * _FuelTanks(2).VerticalCOG
        //denominator += TotalFuelTank3
        //End If

        //If FuelTank4Included Then
        //numerator += TotalFuelTank4 * _FuelTanks(3).VerticalCOG
        //denominator += TotalFuelTank4
        //End If

        //'crew cab NOTE!!! subframe needs to be added/handled/used in getActualVerticalCOG of crewCab 
        //If Accessories.CrewCab IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.CrewCab,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.CrewCab.Mass
        //denominator += Accessories.CrewCab.Mass

        //End If

        //'crane
        var tempCrane = activeRig.getVehicle().getAccessoryHolder().getCrane();
        if (tempCrane !== null) {

            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempCrane,
                                                                        activeRig.getVehicle().getChassisHeightMax(),
                                                                        subframeHeight) * tempCrane.getMass();
            denominator += tempCrane.getMass();

        }

        //'fuel tanks
        //For Each fuelTank As FileLoadingAccessoryFuelTank In Accessories.FuelTanks

        //numerator += Accessories.GetDistanceFromGroundForVehicle(fuelTank,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * fuelTank.Mass
        //denominator += fuelTank.Mass

        //Next

        //'fifth wheel
        //If Accessories.FifthWheel IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.FifthWheel,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.FifthWheel.Mass
        //denominator += Accessories.FifthWheel.Mass
        var tempTrailer1, kingPinLoad;
        var tempFifthWheel = activeRig.getVehicle().getAccessoryHolder().getFifthWheel();
        if (tempFifthWheel !== null) {

            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempFifthWheel,
                                                                        activeRig.getVehicle().getChassisHeightMax(),
                                                                        subframeHeight) * tempFifthWheel.getMass();
            denominator += tempFifthWheel.getMass();

            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                numerator += (tempVehicle.getChassisHeightMax() + tempFifthWheel.getSubframeHeight() + tempFifthWheel.getHeight()) * kingPinLoad;
                denominator += kingPinLoad;
            }

        } else if (tempVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                numerator += tempVehicle.getFifthWheelHeightFromGround() * kingPinLoad;
                denominator += kingPinLoad;
            }
        }

        var tempHitch = activeRig.getVehicle().getAccessoryHolder().getHitch();
        if (tempHitch !== null) {

            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempHitch,
                                                                        activeRig.getVehicle().getChassisHeightMax(),
                                                                        subframeHeight) * tempHitch.getMass();
            denominator += tempHitch.getMass();

            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                var hitchLoad = getHitchLoad();
                numerator += tempHitch.getHeightFromGround() * hitchLoad;
                denominator += hitchLoad;
            }

        }
        //End If

        //'rollover
        //If Accessories.Rollover IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Rollover,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Rollover.Mass
        //denominator += Accessories.Rollover.Mass

        //End If

        //'headboard
        //If Accessories.Headboard IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Headboard,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Headboard.Mass
        //denominator += Accessories.Headboard.Mass

        //End If

        //'bullbar
        //If Accessories.Bullbar IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Bullbar,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Bullbar.Mass
        //denominator += Accessories.Bullbar.Mass

        //End If

        //'other accessories

        activeRig.getVehicle().getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(accessory,
                                                                                    tempVehicle.getChassisHeightMax(),
                                                                                    subframeHeight) * accessory.getMass();
                denominator += accessory.getMass()
            }
        });

        //'taillift
        var tempTaillift = activeRig.getVehicle().getAccessoryHolder().getTaillift();
        if (tempTaillift !== null) {
            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempTaillift,
                                                                    tempVehicle.getChassisHeightMax(),
                                                                    subframeHeight) * tempTaillift.getMass();
            denominator += tempTaillift.getMass();
        }

        //'fridge
        var tempFridge = activeRig.getVehicle().getAccessoryHolder().getFridge();
        if (tempFridge !== null) {
            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempFridge,
                                                                    tempVehicle.getChassisHeightMax(),
                                                                    subframeHeight) * tempFridge.getMass();
            denominator += tempFridge.getMass();
        }

        var tempHooklift = activeRig.getVehicle().getAccessoryHolder().getHooklift();
        if (tempHooklift !== null) {

            numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempHooklift,
                activeRig.getVehicle().getChassisHeightMax(),
                subframeHeight) * tempHooklift.getMass();
            denominator += tempHooklift.getMass();

        }

        //'fairing
        //If Accessories.Fairing IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Fairing,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Fairing.Mass
        //denominator += Accessories.Fairing.Mass

        //End If

        //'body
        if (tempVehicle.getBody() !== null) {
            numerator += tempVehicle.getAccessoryHolder().getDistanceFromGroundForChassis(tempVehicle.getBody(),
                tempVehicle.getChassisHeightMax(),
                subframeHeight) * tempVehicle.getBody().getActualMass();
            denominator += tempVehicle.getBody().getActualMass();

            ////'body payload
            //tempVehicle.getBody().getPayloadHolder().getPayloadObjects().forEach(function(payloadObject) {
            //    numerator += (tempVehicle.getBottomOfVehicleToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
            //    denominator += payloadObject.getMass();
            //});
        }

        if (tempVehicle.getBody() !== null || tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //' payload
            if (tempVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                tempVehicle.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadObject) {
                    if (tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        numerator += (tempVehicle.getFloorHeight() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
                    } else {
                        numerator += (tempVehicle.getBottomOfVehicleToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
                    }

                    denominator += payloadObject.getMass();
                });
            } else {
                var payloadObject = tempVehicle.getPayloadHolder().getSimplePayloadObject();
                if (tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    numerator += (tempVehicle.getFloorHeight() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
                } else {
                    numerator += (tempVehicle.getBottomOfVehicleToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
                }

                denominator += payloadObject.getMass();
                
            }
            
        }

        if (denominator !== 0) {
            return numerator / denominator;
        } else {
            return 0;
        }

    }


    function getOverallLateralCOG() {
        var numerator, denominator, subframeHeight;

        var tempVehicle = activeRig.getVehicle();

        //var bodyWidth = 0;
        //if(tempVehicle.getAccessoryHolder().getBody() !== null) {
        //    bodyWidth = tempVehicle.getAccessoryHolder().getBody().getWidth();
        //}

        var centreLineOfVehicle = tempVehicle.getOverallWidth() / 2;//tempVehicle.getOverallWidth() / 2;//Math.max(tempVehicle.getOverallWidth(), bodyWidth) / 2;
        var leftOfChassisRearWidth = centreLineOfVehicle - tempVehicle.getRearWidth() / 2;//getLeftOfChassisRearWidth(tempVehicle);

        var tempBody = tempVehicle.getAccessoryHolder().getBody();

        var bodyWidthExternal = 0;
        if (tempVehicle.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            if (tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                bodyWidthExternal = tempVehicle.getRearWidth();
            } else {
                if (tempBody !== null) {
                    bodyWidthExternal = tempBody.getActualWidth();
                }
            }
        }

        //var leftOfBodyWidthExternal = 0;//-(bodyWidthExternal / 2);//getLeftOfBodyWidthExternal(tempVehicle);


        //'chassis cab
        numerator = (getCombinationVehicleFrontTare() + getCombinationVehicleRearTare()) * (getVehicleLateralCOG() - centreLineOfVehicle);
        denominator = getCombinationVehicleFrontTare() + getCombinationVehicleRearTare();

        //'crew first row
        let numberOfCrewToIncludeInFirstRow = tempVehicle.getNoOfCrewInFirstRow();
        let driverIncluded = tempVehicle.getTareDriverIncluded() === config.TARE_DRIVER_INCLUDED_OPTIONS.YES;
        if(driverIncluded) {
            numberOfCrewToIncludeInFirstRow--;
        }
        if(numberOfCrewToIncludeInFirstRow > 0) { 
            numerator += numberOfCrewToIncludeInFirstRow * tempVehicle.getCrewMassPerPerson() * (getVehicleCrewFirstRowLateralCOG() - tempVehicle.getCabWidth() / 2);
            denominator += numberOfCrewToIncludeInFirstRow * tempVehicle.getCrewMassPerPerson();
        }
        //'crew second row
        var crewSecondRow = activeRig.getVehicle().getCrewSecondRow();//getAttributeGroup('VEHICLE.' + config.VALUE_TYPE.CREWSECONDROW);
        if (crewSecondRow === true) {
            numerator += tempVehicle.getNoOfCrewInSecondRow() * tempVehicle.getCrewMassPerPerson() * (getVehicleCrewSecondRowLateralCOG() - tempVehicle.getCabWidth() / 2);
            denominator += tempVehicle.getNoOfCrewInSecondRow() * tempVehicle.getCrewMassPerPerson();
        }

        //'factory-fitted fuel tanks
        activeRig.getVehicle().getFuelTanks().forEach(function (fuelTank) {
            if (fuelTank.getIncludeInCalculation() === true) {
                numerator += ((activeRig.getVehicle().getFuelSpecificGravity() * fuelTank.getVolumeIncludedInExtras()) * (fuelTank.getLateralCog() - tempVehicle.getRearWidth() / 2));
                denominator += (activeRig.getVehicle().getFuelSpecificGravity() * fuelTank.getVolumeIncludedInExtras());
            }
        });
        //#unfinished, is there a check for included missing from the fueltank code above?

        //If FuelTank1Included Then
        //numerator += TotalFuelTank1 * _FuelTanks(0).VerticalCOG
        //denominator += TotalFuelTank1
        //End If

        //If FuelTank2Included Then
        //numerator += TotalFuelTank2 * _FuelTanks(1).VerticalCOG
        //denominator += TotalFuelTank2
        //End If

        //If FuelTank3Included Then
        //numerator += TotalFuelTank3 * _FuelTanks(2).VerticalCOG
        //denominator += TotalFuelTank3
        //End If

        //If FuelTank4Included Then
        //numerator += TotalFuelTank4 * _FuelTanks(3).VerticalCOG
        //denominator += TotalFuelTank4
        //End If

        //'crew cab NOTE!!! subframe needs to be added/handled/used in getActualVerticalCOG of crewCab 
        //If Accessories.CrewCab IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.CrewCab,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.CrewCab.Mass
        //denominator += Accessories.CrewCab.Mass

        //End If

        //'crane
        if (activeRig.getVehicle().getAccessoryHolder().getCrane() !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempVehicle.getAccessoryHolder().getCrane(), leftOfChassisRearWidth, centreLineOfVehicle) * tempVehicle.getAccessoryHolder().getCrane().getMass();
            denominator += tempVehicle.getAccessoryHolder().getCrane().getMass();

        }

        //'fuel tanks
        //For Each fuelTank As FileLoadingAccessoryFuelTank In Accessories.FuelTanks

        //numerator += Accessories.GetDistanceFromGroundForVehicle(fuelTank,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * fuelTank.Mass
        //denominator += fuelTank.Mass

        //Next

        //'fifth wheel
        //If Accessories.FifthWheel IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.FifthWheel,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.FifthWheel.Mass
        //denominator += Accessories.FifthWheel.Mass
        var tempTrailer1, kingPinLoad;
        var tempFifthWheel = tempVehicle.getAccessoryHolder().getFifthWheel();
        if (tempFifthWheel !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempFifthWheel,
                                                                        leftOfChassisRearWidth,
                                                                        centreLineOfVehicle) * tempFifthWheel.getMass();
            denominator += tempFifthWheel.getMass();

            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                numerator += ((leftOfChassisRearWidth + tempFifthWheel.getFromLeftOfVehicleRearWidth()) - centreLineOfVehicle ) * kingPinLoad;
                denominator += kingPinLoad;
            }

            //(offsetToLeft + accessory.getFromLeftOfVehicleRearWidth() + accessory.getActualLateralCOG()) - centreLine - accessory.getWidth() / 2
        } else if (tempVehicle.getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.VEHICLE);
                //numerator += ((leftOfChassisRearWidth +tempFifthWheel.getFromLeftOfVehicleRearWidth()) -centreLineOfVehicle) * kingPinLoad;
                numerator += (0) * kingPinLoad;
                denominator += kingPinLoad;
            }
        }

        var tempHitch = tempVehicle.getAccessoryHolder().getHitch();
        if (tempHitch !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempHitch,
                                                                        leftOfChassisRearWidth,
                                                                        centreLineOfVehicle) * tempHitch.getMass();
            denominator += tempHitch.getMass();

            tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                var hitchLoad = getHitchLoad();
                numerator += ((leftOfChassisRearWidth + tempHitch.getFromLeftOfVehicleRearWidth()) - centreLineOfVehicle) * hitchLoad;
                denominator += hitchLoad;
            }

        }
        //End If

        //'rollover
        //If Accessories.Rollover IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Rollover,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Rollover.Mass
        //denominator += Accessories.Rollover.Mass

        //End If

        //'headboard
        //If Accessories.Headboard IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Headboard,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Headboard.Mass
        //denominator += Accessories.Headboard.Mass

        //End If

        //'bullbar
        //If Accessories.Bullbar IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Bullbar,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Bullbar.Mass
        //denominator += Accessories.Bullbar.Mass

        //End If

        //'other accessories

        tempVehicle.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(accessory,
                                                                                    leftOfChassisRearWidth,
                                                                                    centreLineOfVehicle) * accessory.getMass();
                denominator += accessory.getMass()
            }
        });

        //'taillift
        var tempTaillift = tempVehicle.getAccessoryHolder().getTaillift();
        if (tempTaillift !== null) {
            numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempTaillift,
                                                                                    0,
                                                                                    centreLineOfVehicle) * tempTaillift.getMass();
            denominator += tempTaillift.getMass();
        }

        //'fridge
        var tempFridge = tempVehicle.getAccessoryHolder().getFridge();
        if (tempFridge !== null) {
            numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempFridge,
                                                                                    0,
                                                                                    centreLineOfVehicle) * tempFridge.getMass();
            denominator += tempFridge.getMass();
        }

        if (activeRig.getVehicle().getAccessoryHolder().getHooklift() !== null) {

            numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempVehicle.getAccessoryHolder().getHooklift(), leftOfChassisRearWidth, centreLineOfVehicle) * tempVehicle.getAccessoryHolder().getHooklift().getMass();
            denominator += tempVehicle.getAccessoryHolder().getHooklift().getMass();

        }


        //'fairing
        //If Accessories.Fairing IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Fairing,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Fairing.Mass
        //denominator += Accessories.Fairing.Mass

        //End If

        //'body
        if (tempVehicle.getBody() !== null) {
            numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempVehicle.getBody(),
                0,
                centreLineOfVehicle) * tempVehicle.getBody().getActualMass();
            denominator += tempVehicle.getBody().getActualMass();

            ////'body payload
            //tempVehicle.getBody().getPayloadHolder().getPayloadObjects().forEach(function(payloadObject) {
            //    numerator += (tempVehicle.getBottomOfVehicleToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
            //    denominator += payloadObject.getMass();
            //});
        }

        if (tempVehicle.getBody() !== null || tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            //' payload
            if (tempVehicle.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                tempVehicle.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadObject) {
                    //numerator += ((tempVehicle.getLeftOfVehicleToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) - centreLineOfVehicle) * payloadObject.getMass();
                    if (tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                        numerator += (-tempVehicle.getLoadCompartmentWidth() / 2 + tempVehicle.getLeftOfVehicleToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) * payloadObject.getMass();
                    } else {
                        numerator += (-tempVehicle.getBody().getWidth() / 2 + tempVehicle.getLeftOfVehicleToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) * payloadObject.getMass();
                    }
                    
                    denominator += payloadObject.getMass();

                });
            } else {
                var payloadObject = tempVehicle.getPayloadHolder().getSimplePayloadObject();
                //numerator += ((tempVehicle.getLeftOfVehicleToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) - centreLineOfVehicle) * payloadObject.getMass();
                if (tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    numerator += (-tempVehicle.getLoadCompartmentWidth() / 2 + tempVehicle.getLeftOfVehicleToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) * payloadObject.getMass();
                } else {
                    numerator += (-tempVehicle.getBody().getWidth() / 2 + tempVehicle.getLeftOfVehicleToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) * payloadObject.getMass();
                }

                
                denominator += payloadObject.getMass();

            }

        }

        if (denominator !== 0) {
            return numerator / denominator;
        } else {
            return 0;
        }

    }

    //function getTrailerOverallHorizontalCOG(path) {
    //    if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    //        return getTrailer1OverallHorizontalCOG();
    //    } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
    //        getTrailer2OverallHorizontalCOG()
    //    }
    //    return 0;
    //}

    function getDistanceFromWheelbaseTheoreticalStartForTrailer(accessory, path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getDistanceFromWheelbaseTheoreticalStartForTrailer1(accessory);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return getDistanceFromWheelbaseTheoreticalStartForTrailer2(accessory);
        }
        return 0;
    }

    function getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getDistanceFromWheelbaseTheoreticalEndForTrailer1(accessory);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return getDistanceFromWheelbaseTheoreticalEndForTrailer2(accessory);
        }
        return 0;
    }

    function getDistanceFromWheelbaseTheoreticalStartForTrailer1(accessory) {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempAccessoryHolder = tempTrailer1.getAccessoryHolder();
        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalStartForTrailer(accessory, tempTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStart());
        } else {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalStartForTrailer(accessory, tempTrailer1.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar());
        }
    }

    function getDistanceFromWheelbaseTheoreticalStartForTrailer2(accessory) {
        var tempTrailer2 = activeRig.getTrailer2();
        var tempAccessoryHolder = tempTrailer2.getAccessoryHolder();
        if (tempTrailer2.getType() === config.TRAILER_TYPES.SEMI || tempTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalStartForTrailer(accessory, tempTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStart());
        } else {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalStartForTrailer(accessory, tempTrailer2.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar());
        }
    }

    function getDistanceFromWheelbaseTheoreticalEndForTrailer1(accessory) {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempAccessoryHolder = tempTrailer1.getAccessoryHolder();
        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, tempTrailer1.getFrontOfTrailerToWheelbaseTheoreticalEnd(), tempTrailer1.getFrontOfTrailerToLastAxle());
        } else {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, tempTrailer1.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), tempTrailer1.getFrontOfTrailerToLastAxleExclDrawbar());
        }

    }

    function getDistanceFromWheelbaseTheoreticalEndForTrailer2(accessory) {
        var tempTrailer2 = activeRig.getTrailer2();
        var tempAccessoryHolder = tempTrailer2.getAccessoryHolder();
        if (tempTrailer2.getType() === config.TRAILER_TYPES.SEMI || tempTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, tempTrailer2.getFrontOfTrailerToWheelbaseTheoreticalEnd(), tempTrailer2.getFrontOfTrailerToLastAxle());
        } else {
            return tempAccessoryHolder.getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, tempTrailer2.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), tempTrailer2.getFrontOfTrailerToLastAxleExclDrawbar());
        }

    }

    function getTrailer1OverallHorizontalCOG() {
        return getTrailerOverallHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function getTrailer2OverallHorizontalCOG() {
        return getTrailerOverallHorizontalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }

    function getTrailerOverallHorizontalCOG(path) {
        var numerator, denominator;

        var trailer = getChassisObjectFromPath(path);
        
        //'chassis
        numerator = (getTrailerTareFront(path) + getTrailerTareRear(path)) * getTrailerHorizontalCOG(path);
        denominator = getTrailerTareFront(path) + getTrailerTareRear(path);
        
        //'crane
        var tempCrane = trailer.getAccessoryHolder().getCrane();
        if (tempCrane !== null) {
            addAccessoryToOverall(tempCrane);
            //numerator += getDistanceFromWheelbaseTheoreticalStartForTrailer(tempCrane, path) * tempCrane.getMass();
            //denominator += tempCrane.getMass()

        }
        

        ////'fifth wheel
        //var tempFifthWheel = tempVehicle.getAccessoryHolder().getFifthWheel();
        //if (tempFifthWheel !== null) {

        //    numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempFifthWheel,
        //                                    tempVehicle.getFrontOverhang(),
        //                                    tempVehicle.getBumperToBackOfCab(),
        //                                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
        //                                    tempVehicle.getWheelbaseTheoretical()) * tempFifthWheel.getMass();
        //    denominator += tempFifthWheel.getMass()

        //}

        //var tempHitch = tempVehicle.getAccessoryHolder().getHitch();
        //if (tempHitch !== null) {

        //    numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempHitch,
        //                                    tempVehicle.getFrontOverhang(),
        //                                    tempVehicle.getBumperToBackOfCab(),
        //                                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
        //                                    tempVehicle.getWheelbaseTheoretical()) * tempHitch.getMass();
        //    denominator += tempHitch.getMass()

        //}
        

        //'other accessories
        trailer.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                addAccessoryToOverall(accessory);
                //numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(accessory,
                //                                                                    tempVehicle.getFrontOverhang(),
                //                                                                    tempVehicle.getBumperToBackOfCab(),
                //                                                                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                //                                                                    tempVehicle.getWheelbaseTheoretical()) * accessory.getMass();
                //denominator += accessory.getMass()
            }
        });

        //'taillift
        var tempTaillift = trailer.getAccessoryHolder().getTaillift();
        if (tempTaillift !== null) {
            addAccessoryToOverall(tempTaillift);
            //numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempTaillift,
            //                                                                        tempVehicle.getFrontOverhang(),
            //                                                                        tempVehicle.getBumperToBackOfCab(),
            //                                                                        tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
            //                                                                        tempVehicle.getWheelbaseTheoretical()) * tempTaillift.getMass();
            //denominator += tempTaillift.getMass()
        }

        //'fridge
        var tempFridge = trailer.getAccessoryHolder().getFridge();
        if (tempFridge !== null) {
            addAccessoryToOverall(tempFridge);
            //numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempFridge,
            //                                                                        tempVehicle.getFrontOverhang(),
            //                                                                        tempVehicle.getBumperToBackOfCab(),
            //                                                                        tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
            //                                                                        tempVehicle.getWheelbaseTheoretical()) * tempFridge.getMass();
            //denominator += tempFridge.getMass();
        }

        //'fairing
        //If Accessories.Fairing IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromWheelbaseTheoreticalStartForVehicle(Accessories.Fairing,
        //                                                                            FrontOverhang,
        //                                                                            BumperToBackOfCab,
        //                                                                            Axle1FrontToWheelbaseTheoreticalStart,
        //                                                                            WheelbaseTheoretical,
        //                                                                            Nothing) * Accessories.Fairing.Mass
        //denominator += Accessories.Fairing.Mass

        //End If


        //'hooklift
        var tempHooklift = trailer.getAccessoryHolder().getHooklift();
        if (tempHooklift !== null) {
            addAccessoryToOverall(tempHooklift);
        }

        //'body
        var tempBody = trailer.getAccessoryHolder().getBody();
        if (tempBody !== null) {
            addAccessoryToOverall(tempBody);
            //numerator += tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(tempBody,
            //        tempVehicle.getFrontOverhang(),
            //        tempVehicle.getBumperToBackOfCab(),
            //        tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
            //        tempVehicle.getWheelbaseTheoretical()) * tempBody.getActualMass();
            //denominator += tempBody.getActualMass();

            //    //'body payload
            //tempBody.getPayloadHolder().getPayloadObjects().forEach(function (payloadObject) {
            //    numerator += (tempVehicle.getFrontOfVehicleToPayloadDeckStart() - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart()) +
            //        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
            //    denominator += payloadObject.getMass();
            //});
        }

        if (tempBody !== null || trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            //'payload
            if (trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                trailer.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadObject) {
                    if(trailer.getType() === config.TRAILER_TYPES.PUP) {
                        numerator += (trailer.getFrontOfTrailerToPayloadDeckStart() - (trailer.getFrontOverhang() + trailer.getAxle1FrontToWheelbaseTheoreticalStart()) +
                        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
                    }else {
                        numerator += (trailer.getFrontOfTrailerToPayloadDeckStartExclDrawbar() - (trailer.getFrontOverhang() + trailer.getAxle1FrontToWheelbaseTheoreticalStart()) +
                        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
                    }
                    
                    denominator += payloadObject.getMass();
                });
            } else {
                var payloadObject = trailer.getPayloadHolder().getSimplePayloadObject();
                if(trailer.getType() === config.TRAILER_TYPES.PUP) {
                    numerator += (trailer.getFrontOfTrailerToPayloadDeckStart() - (trailer.getFrontOverhang() + trailer.getAxle1FrontToWheelbaseTheoreticalStart()) +
                        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
                }else {
                    numerator += (trailer.getFrontOfTrailerToPayloadDeckStartExclDrawbar() - (trailer.getFrontOverhang() + trailer.getAxle1FrontToWheelbaseTheoreticalStart()) +
                        payloadObject.getActualHorizontalCOGFromFrontOfDeck()) * payloadObject.getMass();
                }
                
                denominator += payloadObject.getMass();
            }

        }

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            var tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            var tempTrailer2 = getActiveOffer().getRig().getTrailer2();

            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2 !== null) {
                var kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                numerator += (tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset()) * kingPinLoad;
                denominator += kingPinLoad;
            }
        }


        if (denominator !== 0) {
            return numerator / denominator;
        } else {
            return 0;
        }

        function addAccessoryToOverall(accessory) {
            numerator += getDistanceFromWheelbaseTheoreticalStartForTrailer(accessory, path) * accessory.getMass();
            denominator += accessory.getMass()
        }
    }

    function getTrailer1OverallVerticalCOG() {
        return getTrailerOverallVerticalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function getTrailer2OverallVerticalCOG() {
        return getTrailerOverallVerticalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }

    function getTrailerOverallVerticalCOG(path) {
        var numerator, denominator, subframeHeight;

        var trailer = getChassisObjectFromPath(path);

        if (trailer.getAccessoryHolder().getBody() !== null) {
            subframeHeight = trailer.getAccessoryHolder().getBody().getSubframeHeight();
        } else {
            subframeHeight = 0;
        }



        //'chassis
        numerator = (getTrailerTareFront(path) + getTrailerTareRear(path)) * getTrailerVerticalCOG(path);
        denominator = getTrailerTareFront(path) + getTrailerTareRear(path);

        

        //'crane
        var tempCrane = trailer.getAccessoryHolder().getCrane();
        if (tempCrane !== null) {
            addAccessoryToOverall(tempCrane);
            //numerator += trailer.getAccessoryHolder().getDistanceFromGroundForChassis(tempCrane,
            //                                                         trailer.getChassisHeight(),
            //                                                         subframeHeight) * tempCrane.getMass();
            //denominator += tempCrane.getMass();

        }

        
        //var tempFifthWheel = activeRig.getVehicle().getAccessoryHolder().getFifthWheel();
        //if (tempFifthWheel !== null) {

        //    numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempFifthWheel,
        //                                                             activeRig.getVehicle().getChassisHeightMax(),
        //                                                             subframeHeight) * tempFifthWheel.getMass();
        //    denominator += tempFifthWheel.getMass();

        //}

        //var tempHitch = activeRig.getVehicle().getAccessoryHolder().getHitch();
        //if (tempHitch !== null) {

        //    numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempHitch,
        //                                                             activeRig.getVehicle().getChassisHeightMax(),
        //                                                             subframeHeight) * tempHitch.getMass();
        //    denominator += tempHitch.getMass();

        //}
        

        //'other accessories

        trailer.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                addAccessoryToOverall(accessory);
                //numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(accessory,
                //                                                                    tempVehicle.getChassisHeightMax(),
                //                                                                    subframeHeight) * accessory.getMass();
                //denominator += accessory.getMass()
            }
        });

        //'taillift
        var tempTaillift = trailer.getAccessoryHolder().getTaillift();
        if (tempTaillift !== null) {
            addAccessoryToOverall(tempTaillift);
            //numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempTaillift,
            //                                                     tempVehicle.getChassisHeightMax(),
            //                                                     subframeHeight) * tempTaillift.getMass();
            //denominator += tempTaillift.getMass();
        }

        //'fridge
        var tempFridge = trailer.getAccessoryHolder().getFridge();
        if (tempFridge !== null) {
            addAccessoryToOverall(tempFridge);
            //numerator += activeRig.getVehicle().getAccessoryHolder().getDistanceFromGroundForChassis(tempFridge,
            //                                                     tempVehicle.getChassisHeightMax(),
            //                                                     subframeHeight) * tempFridge.getMass();
            //denominator += tempFridge.getMass();
        }


        //'hooklift
        var tempHooklift = trailer.getAccessoryHolder().getHooklift();
        if (tempHooklift !== null) {
            addAccessoryToOverall(tempHooklift);
        }

        //'body
        var tempBody = trailer.getAccessoryHolder().getBody();
        if (tempBody !== null) {
            addAccessoryToOverall(tempBody);

            //numerator += tempVehicle.getAccessoryHolder().getDistanceFromGroundForChassis(tempVehicle.getBody(),
            //    tempVehicle.getChassisHeightMax(),
            //    subframeHeight) * tempVehicle.getBody().getActualMass();
            //denominator += tempVehicle.getBody().getActualMass();

            ////'body payload
            //tempVehicle.getBody().getPayloadHolder().getPayloadObjects().forEach(function(payloadObject) {
            //    numerator += (tempVehicle.getBottomOfVehicleToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
            //    denominator += payloadObject.getMass();
            //});
        }

        if (tempBody !== null || trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            //' payload
            if (trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                trailer.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadObject) {
                    numerator += (trailer.getBottomOfTrailerToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();

                    denominator += payloadObject.getMass();
                });
            } else {
                var payloadObject = trailer.getPayloadHolder().getSimplePayloadObject();
                numerator += (trailer.getBottomOfTrailerToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();

                denominator += payloadObject.getMass();

            }

        }

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            var tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            var tempTrailer2 = getActiveOffer().getRig().getTrailer2();

            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2 !== null) {
                var kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                numerator += (tempTrailer1.getChassisHeight() + tempTrailer1.getFifthWheelHeight()) * kingPinLoad;
                denominator += kingPinLoad;
            }
        }
        if (denominator !== 0) {
            return numerator / denominator;
        } else {
            return 0;
        }

        function addAccessoryToOverall(accessory) {
            numerator += trailer.getAccessoryHolder().getDistanceFromGroundForChassis(accessory,
                                                                        trailer.getChassisHeight(),
                                                                        subframeHeight) * accessory.getMass();
            denominator += accessory.getMass();
        }

    }

    function getTrailer1OverallLateralCOG() {
        return getTrailerOverallLateralCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function getTrailer2OverallLateralCOG() {
        return getTrailerOverallLateralCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }

    function getTrailerOverallLateralCOG(path) {
        var numerator, denominator, subframeHeight;

        var trailer = getChassisObjectFromPath(path);
        var centreLineOfChassis = trailer.getOverallWidth() / 2;
        
        var leftOfChassisRearWidth = 0;//trailer.getOverallWidth() / 2;//getLeftOfChassisRearWidth(trailer);

        var tempBody = trailer.getAccessoryHolder().getBody();

        var bodyWidthExternal = 0;
        if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            bodyWidthExternal = trailer.getOverallWidth();
        } else {
            if (tempBody !== null) {
                bodyWidthExternal = tempBody.getActualWidth();
            }
        }


        var leftOfBodyWidthExternal = 0;//bodyWidthExternal / 2;//getLeftOfBodyWidthExternal(trailer);
        var centreLineOfBody = bodyWidthExternal / 2;
        var centreLineOfTrailer = centreLineOfTrailer = Math.max(centreLineOfBody, centreLineOfChassis);

        //'chassis
        numerator = (getTrailerTareFront(path) + getTrailerTareRear(path)) * (getTrailerLateralCOG(path) - centreLineOfChassis);
        denominator = getTrailerTareFront(path) + getTrailerTareRear(path);

        
        //'crane
        var tempCrane = trailer.getAccessoryHolder().getCrane();
        if (tempCrane !== null) {
            addAccessoryToOverall(tempCrane, leftOfChassisRearWidth, centreLineOfChassis);
            //numerator += trailer.getAccessoryHolder().getDistanceFromCentreOfChassis(tempCrane, leftOfChassisRearWidth, centreLineOfTrailer) * tempCrane.getMass();
            //denominator += tempCrane.getMass();

        }

        //var tempFifthWheel = tempVehicle.getAccessoryHolder().getFifthWheel();
        //if (tempFifthWheel !== null) {

        //    numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempFifthWheel,
        //                                                             leftOfChassisRearWidth,
        //                                                             centreLineOfVehicle) * tempFifthWheel.getMass();
        //    denominator += tempFifthWheel.getMass();

        //}

        //var tempHitch = tempVehicle.getAccessoryHolder().getHitch();
        //if (tempHitch !== null) {

        //    numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempHitch,
        //                                                             leftOfChassisRearWidth,
        //                                                             centreLineOfVehicle) * tempHitch.getMass();
        //    denominator += tempHitch.getMass();

        //}
        
        //'other accessories

        trailer.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER) {
                addAccessoryToOverall(accessory, leftOfChassisRearWidth, centreLineOfChassis);
                //numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(accessory,
                //                                                                    leftOfChassisRearWidth,
                //                                                                    centreLineOfVehicle) * accessory.getMass();
                //denominator += accessory.getMass()
            }
        });

        //'taillift
        var tempTaillift = trailer.getAccessoryHolder().getTaillift();
        if (tempTaillift !== null) {
            addAccessoryToOverall(tempTaillift, leftOfBodyWidthExternal, centreLineOfBody);
            //numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempTaillift,
            //                                                                        leftOfBodyWidthExternal,
            //                                                                        centreLineOfVehicle) * tempTaillift.getMass();
            //denominator += tempTaillift.getMass();
        }

        //'fridge
        var tempFridge = trailer.getAccessoryHolder().getFridge();
        if (tempFridge !== null) {
            addAccessoryToOverall(tempFridge, leftOfBodyWidthExternal, centreLineOfBody);
            //numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempFridge,
            //                                                                        leftOfBodyWidthExternal,
            //                                                                        centreLineOfVehicle) * tempFridge.getMass();
            //denominator += tempFridge.getMass();
        }

        //'hooklift
        var tempHooklift = trailer.getAccessoryHolder().getHooklift();
        if (tempHooklift !== null) {
            addAccessoryToOverall(tempHooklift, leftOfChassisRearWidth, centreLineOfChassis);
        }

        //'fairing
        //If Accessories.Fairing IsNot Nothing Then

        //numerator += Accessories.GetDistanceFromGroundForVehicle(Accessories.Fairing,
        //                                                         ChassisHeightMax,
        //                                                         subFrameHeight) * Accessories.Fairing.Mass
        //denominator += Accessories.Fairing.Mass

        //End If

        //'body
        // var tempBody = trailer.getAccessoryHolder().getBody();
        if (tempBody !== null) {
            addAccessoryToOverall(tempBody, leftOfBodyWidthExternal, centreLineOfBody);

            //numerator += tempVehicle.getAccessoryHolder().getDistanceFromCentreOfChassis(tempVehicle.getBody(),
            //    leftOfBodyWidthExternal,
            //    centreLineOfVehicle) * tempVehicle.getBody().getActualMass();
            //denominator += tempVehicle.getBody().getActualMass();

            ////'body payload
            //tempVehicle.getBody().getPayloadHolder().getPayloadObjects().forEach(function(payloadObject) {
            //    numerator += (tempVehicle.getBottomOfVehicleToPayloadDeckStart() + payloadObject.getActualVerticalCOGFromBottomOfDeck()) * payloadObject.getMass();
            //    denominator += payloadObject.getMass();
            //});
        }

        if (tempBody !== null || trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            //' payload
            if (trailer.getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                trailer.getPayloadHolder().getDetailedPayloadItems().forEach(function (payloadObject) {
                    numerator += ((trailer.getLeftOfTrailerToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) - centreLineOfBody) * payloadObject.getMass();
                    denominator += payloadObject.getMass();
                });
            } else {
                var payloadObject = trailer.getPayloadHolder().getSimplePayloadObject();
                numerator += ((trailer.getLeftOfTrailerToPayloadDeckStart() + payloadObject.getActualLateralCOGFromSideOfDeck()) - centreLineOfBody) * payloadObject.getMass();

                denominator += payloadObject.getMass();

            }

        }

        

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            var tempTrailer1 = getActiveOffer().getRig().getTrailer1();
            var tempTrailer2 = getActiveOffer().getRig().getTrailer2();

            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2 !== null) {
                var kingPinLoad = getKingpinLoad(config.CHASSIS_OBJECT_TYPES.TRAILER1);
                numerator += (0) * kingPinLoad;
                denominator += kingPinLoad;
            }
        }

        if (denominator !== 0) {
            return numerator / denominator;
        } else {
            return 0;
        }

        function addAccessoryToOverall(accessory, leftRefVal, centreForRefVal) {
            numerator += trailer.getAccessoryHolder().getDistanceFromCentreOfChassis(accessory, leftRefVal, centreForRefVal) * accessory.getMass();
            denominator += accessory.getMass();
        }

    }

    function getVehicleVerticalCOG() {
        if (activeRig.getVehicle().getVehicleVerticalCOGOverride() === true) {
            return activeRig.getVehicle().getVehicleVerticalCOG();
        } else {
            return originalRig.getVehicle().getVehicleVerticalCOG();
        }
    }

    function getVehicleLateralCOG() {
        if (activeRig.getVehicle().getVehicleLateralCOGOverride() === true) {
            return activeRig.getVehicle().getVehicleLateralCOG();
        } else {
            return originalRig.getVehicle().getVehicleLateralCOG();
        }
    }

    function getTrailerLengthForCompiler(chassisObjectType) {

        var tempTrailer = getChassisObjectFromPath(chassisObjectType);

        var trailerRearOverhangTotal;
        if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            trailerRearOverhangTotal = getTrailer1RearOverhangTotal();
        } else {
            trailerRearOverhangTotal = getTrailer2RearOverhangTotal();
        }

        return tempTrailer.getFrontOverhang() + tempTrailer.getWheelbaseTheoretical() + tempTrailer.getWheelbaseTheoreticalEndToRearMostAxle() + trailerRearOverhangTotal;
    }

    function getCombinationCargoCarryingLength() {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        if(tempTrailer1 !== null) {
            var tempVehicleBody = tempVehicle.getBody();
            var tempTrailer1Body = tempTrailer1.getAccessoryHolder().getBody();
            
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                        if (tempVehicleBody !== null && tempTrailer1Body !== null) {
                            return tempVehicle.getBody().getActualLength() + tempVehicle.getBackOfBodyToRearOfVehicle() + getVehicleAndDrawbarGapBetweenVehicleAndTrailer() + getBodyGap(tempTrailer1.getChassisObjectName()) + tempTrailer1.getAccessoryHolder().getBody().getActualLength();
                        }
                    } else {
                        if (tempVehicleBody !== null) {
                            return tempVehicle.getBody().getActualLength() + tempVehicle.getBackOfBodyToRearOfVehicle() + getVehicleAndDrawbarGapBetweenVehicleAndTrailer() + tempTrailer1.getLength();
                        } 
                    }
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    var tempTrailer2 = activeRig.getTrailer2();
                    if(tempTrailer2 !== null) {
                        var tempTrailer2Body = tempTrailer2.getAccessoryHolder().getBody();
                        if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE && tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                            if (tempTrailer1Body !== null && tempTrailer2Body !== null) {
                                return tempTrailer1Body.getActualLength() + getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2() + getBodyGap(tempTrailer2.getChassisObjectName()) + tempTrailer2Body.getActualLength();
                            }
                        } else if ((tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) && (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer2)))) {
                            return tempTrailer1.getFrontToStartOfTaper() + getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2() + tempTrailer2.getLength();
                        } else if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE && (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer2)))) {
                            if (tempTrailer1Body !== null) {
                                return tempTrailer1Body.getActualLength() + getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2() + tempTrailer2.getLength();
                            }
                        } else  {
                            if (tempTrailer2Body !== null) {
                                return tempTrailer1.getFrontToStartOfTaper() + getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2() + getBodyGap(tempTrailer2.getChassisObjectName()) + tempTrailer2Body.getActualLength();
                            }
                        }
                    }
                    break;
            }
        }

        return 0;
    }

    
    function getCombinationTurningRadiusCurbToCurb() {
        return getTurningCircleValues(config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST).turningRadiusCurbToCurb;
    }
    function getCombinationTurningRadiusWallToWall() {
        return getTurningCircleValues(config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST).turningRadiusWallToWall;
    }

    function getCombinationInnerRadius() {
        var tempTrailer1 = activeRig.getTrailer1(), tempTrailer2 = activeRig.getTrailer2();
        var tcVals = getTurningCircleValues(config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST);
        if (tempTrailer1 === null) {
            return tcVals.vehicleTurningRadiusInner;
        } else if (tempTrailer1 !== null && tempTrailer2 === null) {
            return tcVals.trailer1TurningRadiusInner;
        } else if (tempTrailer1 !== null && tempTrailer2 !== null) {
            return tcVals.trailer2TurningRadiusInner;
        }
        return 0;
    }

    function getVehicleTurningRadiusInner() {
        var tcVals = getTurningCircleValues(config.VIEW_DETAIL.TURNING_CIRCLE_SMALLEST);
        return tcVals.vehicleTurningRadiusInner;
    }

    function getTrailer1VerticalCOG() {
        return getTrailerVerticalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function getTrailer2VerticalCOG() {
        return getTrailerVerticalCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }

    function getTrailerVerticalCOG(path) {
        var tempTrailer = getChassisObjectFromPath(path);
        var tempOriginalTrailer = getOriginalChassisObjectFromPath(path);

        if (tempTrailer.getVerticalCOGOverride() === true) {
            return tempTrailer.getVerticalCOG();
        } else {
            return tempOriginalTrailer.getVerticalCOG();
        }
    }

    function getTrailer1LateralCOG() {
        return getTrailerLateralCOG(config.CHASSIS_OBJECT_TYPES.TRAILER1);
    }

    function getTrailer2LateralCOG() {
        return getTrailerLateralCOG(config.CHASSIS_OBJECT_TYPES.TRAILER2);
    }

    function getTrailerLateralCOG(path) {
        var tempTrailer = getChassisObjectFromPath(path);
        var tempOriginalTrailer = getOriginalChassisObjectFromPath(path);

        if (tempTrailer.getLateralCOGOverride() === true) {
            return tempTrailer.getLateralCOG();
        } else {
            return tempOriginalTrailer.getLateralCOG();
        }
    }

    //function getManufacturersFrontAxleMass() {

    //    return activeRig.getVehicle().getGAFront();
    //}
    //function getManufacturersRearAxleMass() {
    //    return activeRig.getVehicle().getGARear();
    //}

    function getVehicleFrontGrossPercent() {
        var totalGross = (getCombinationVehicleFrontGross() + getCombinationVehicleRearGross())
        if (totalGross !== 0) {
            return (getCombinationVehicleFrontGross() / totalGross) * 100;
        }
        return 0;
    }

    function getVehicleRearGrossPercent() {
        var totalGross = (getCombinationVehicleFrontGross() + getCombinationVehicleRearGross())
        if (totalGross !== 0) {
            return (getCombinationVehicleRearGross() / totalGross) * 100;
        }
        return 0;
    }

    function getComVehBodyPlusPayloadPercentFront() {

        var tempVehicle = activeRig.getVehicle();
        var tempVehicleBody = tempVehicle.getAccessoryHolder().getBody();
        var bodyAndPayloadTotal = (tempVehicleBody.getTotalLoad() + tempVehicle.getPayloadHolder().getTotalLoad());
        if (tempVehicleBody !== null && bodyAndPayloadTotal !== 0) {
            var bodyDistanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(tempVehicle, tempVehicleBody);
            return ((tempVehicleBody.getFrontAxleLoad(bodyDistanceFromWheelbaseTheoreticalEnd, tempVehicle.getWheelbaseTheoretical()) + tempVehicle.getPayloadHolder().getFrontAxleLoad(tempVehicle.getFrontOfVehicleToWheelbaseTheoreticalEnd(), tempVehicle.getFrontOfVehicleToPayloadDeckStart(), tempVehicle.getWheelbaseTheoretical())) / bodyAndPayloadTotal) * 100;
        }
        return 0;
    }

    function getComVehBodyPlusPayloadPercentRear() {

        var tempVehicle = activeRig.getVehicle();
        var tempVehicleBody = tempVehicle.getAccessoryHolder().getBody();
        var bodyAndPayloadTotal = (tempVehicleBody.getTotalLoad() + tempVehicle.getPayloadHolder().getTotalLoad());
        if (tempVehicleBody !== null && bodyAndPayloadTotal !== 0) {
            var bodyDistanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(tempVehicle, tempVehicleBody);
            return ((tempVehicleBody.getRearAxleLoad(bodyDistanceFromWheelbaseTheoreticalEnd, tempVehicle.getWheelbaseTheoretical()) + tempVehicle.getPayloadHolder().getRearAxleLoad(tempVehicle.getFrontOfVehicleToWheelbaseTheoreticalEnd(), tempVehicle.getFrontOfVehicleToPayloadDeckStart(), tempVehicle.getWheelbaseTheoretical())) / bodyAndPayloadTotal) * 100;
        }
        return 0;
    }

    function getVehicleMaxUnladenWeight() {
        return 0;
    }

    function getUtilisationOfGVWRPercent() {
        //Vehicle Total Gross / GVWR x 100
        return  (((getCombinationVehicleFrontGross() + getCombinationVehicleRearGross()) / getGrossVehicleMass()) * 100);
    }
    function getUtilisationOfGCWRPercent() {
        //(Combination Total Gross / GCWR x 100)
        return  ((getCombinationGrossTotal() / getGrossCombinationMass()) * 100);
    }
    function getUtilisationOfVehiclePermissiblePercent() {
        //(Vehicle Total Gross / Vehicle Permissible x 100)
        let permissibleValToUse = curCombinationPermissibleVals ? curCombinationPermissibleVals.vehicleTotalPermissible : getCombinationPermissibleVals().vehicleTotalPermissible;
        return  ((activeRig.getVehicle().getGrossTotal() / permissibleValToUse) * 100);
    }
    function getUtilisationOfCombinationPermissiblePercent() {
        //(Combination Total Gross / Combination Permissible x 100)	0 %	100 %	Always
        let permissibleValToUse = curCombinationPermissibleVals ? curCombinationPermissibleVals.combinationTotalPermissible : getCombinationPermissibleVals().combinationTotalPermissible;
        return  ((getCombinationGrossTotal() / permissibleValToUse) * 100);
    }
    
    function getGrossVehicleMass() {
        if (activeRig.getVehicle().getManufacturersGrossVehicleMassOverride() === true) {
            return activeRig.getVehicle().getGVM();
        } else {
            //return Math.min(activeRig.getVehicle().getGCM(), originalRig.getVehicle().getGVM());
            return originalRig.getVehicle().getGVM();
        }
    }

    function getTrailerGVMUsingPath(path) {
        if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            return getGrossVehicleMassTrailer1();
        } else if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
            return getGrossVehicleMassTrailer2();
        }
        return 0;
    }

    function getGrossVehicleMassTrailer1() {
        if (activeRig.getTrailer1().getGVMOverride() === true) {
            return activeRig.getTrailer1().getGVM();
        } else {
            return originalRig.getTrailer1().getGVM();
        }
    }

    function getGrossVehicleMassTrailer2() {
        if (activeRig.getTrailer2().getGVMOverride() === true) {
            return activeRig.getTrailer2().getGVM();
        } else {
            return originalRig.getTrailer2().getGVM();
        }
    }

    function getGrossCombinationMass() {
        if (activeRig.getVehicle().getManufacturersGrossCombinationMassOverride() === true) {
            return activeRig.getVehicle().getGCM();
        } else {
            return originalRig.getVehicle().getGCM();
        }
    }

    function getVehicleFrontPermissible() {

        var tempVehicle = activeRig.getVehicle();
        //if (tempVehicle.getPermissibleFrontOverride() === false /*|| activeRig.getVehicle().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE*/) {
        //    setupVehicleFrontPermissibleVal();
        //}
        //return Math.min(tempVehicle.getAUFront(), tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getBridgeFormulaPermissible());
        var vehicleFrontPermissible = Math.min(tempVehicle.getAxlesHolder().getLesserOfFrontAxleManufacturerRatings(), getVehicleExtremeFrontGroupBridgeFormulaPermissible());
        if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
            tempVehicle.setAUFront(tempVehicle.getGAFront());
        } else {
            tempVehicle.setAUFront(vehicleFrontPermissible);
        }
        
        return vehicleFrontPermissible;
        //if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
        //    vehicleFrontPermissible = Math.min(tempVehicle.getAUFront(), getVehicleExtremeFrontGroupBridgeFormulaPermissible()); 
        //} else {
        //    var frontAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        //    return Math.min(frontAxleGroup.getPermissibleSimplified(), getVehicleExtremeFrontGroupBridgeFormulaPermissible());
        //}
        //if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
        //    return tempVehicle.getAUFront();
        //} else {
        //    var frontAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        //    return frontAxleGroup.getPermissibleSimplified();
        //}
        

    }

    function setupVehicleFrontPermissibleVal() {
        if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
            activeRig.getVehicle().setAUFront(activeRig.getVehicle().getGAFront());
        } else {
            var evalResult = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
            activeRig.getVehicle().setAUFront(evalResult);
        }
    }

    function getVehicleRearPermissible(forceOverrideFalseForTemplateVehicleSetup) {
        
        var tempVehicle = activeRig.getVehicle();
        //if (tempVehicle.getPermissibleRearOverride() === false  /*|| activeRig.getVehicle().getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE*/) {
        //    setupVehicleRearPermissibleVal();
        //}
        var vehicleRearPermissible = Math.min(tempVehicle.getAxlesHolder().getLesserOfRearAxleManufacturerRatings(), getVehicleRearTotalBridgeOrRegulationVal());
        if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
            tempVehicle.setAURear(tempVehicle.getGARear());
        } else {
            tempVehicle.setAURear(vehicleRearPermissible);
        }
        
        return vehicleRearPermissible;
        
    }

    

    //function getTrailer1FrontPermissible() {
    //    if (activeRig.getTrailer1().getPermissibleFrontOverride() === false) {
    //        return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFront);
    //    } else {
    //        return activeRig.getTrailer1().getPermissibleFront();
    //    }

    //}

    //function getTrailer1RearPermissible() {
    //    if (activeRig.getTrailer1().getPermissibleRearOverride() === false) {
    //        return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
    //    } else {
    //        return activeRig.getTrailer1().getPermissibleRear();
    //    }
    //}

    function setupVehicleRearPermissibleVal() {
        if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
            activeRig.getVehicle().setAURear(activeRig.getVehicle().getGARear());
        } else {
            var evalResult = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);
            activeRig.getVehicle().setAURear(evalResult);
        }
    }

    function getVehicleTotalPermissible(ignoreOverride) { 
        if (activeRig.getVehicle().getPermissibleTotalOverride() === false) {
            // if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
            //     //commented out for 16137
            //     return getGrossVehicleMass();
            // } else {
                //if (!ignoreOverride) {
                //    var permissibleVal;
                //    if (activeRig.getVehicle().getVehicleBridgeFormulaPermissibleOverride()) {
                //        permissibleVal = activeRig.getVehicle().getVehicleTotalPermissible();
                //    }else {
                //        permissibleVal = evaluateLegislation('COMVEHPERMISSIBLETOTALSUB');

                //    }
                //}
                //if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
                    return Math.min(getGrossVehicleMass(), getVehicleTotalBridgeOrRegulationVal(ignoreOverride));
                //} else {

                //}
                
                //return Math.min(permissibleVal, getGrossVehicleMass());
                //return getVehicleExtremeGroupBridgeFormulaPermissible();
            // }
        } else {
            return activeRig.getVehicle().getPermissibleTotal();
        }
    }

    function getVehicleAndPupTotalPermissible(ignoreOverride) {
        //if (activeRig.getVehicle().getPermissibleTotalOverride() === false) {
        //    if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
        //        return getGrossCombinationMass();
        //    } else {
        //        return Math.min(getGrossCombinationMass(), getCombinationTotalBridgeOrRegulationVal(ignoreOverride));
        //    }
        //} else {
        //    return activeRig.getVehicle().getPermissibleTotal();
        //}
        if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
            return getGrossCombinationMass();
        } else {
            return Math.min(getGrossCombinationMass(), getCombinationTotalBridgeOrRegulationVal(ignoreOverride));
        }
    }

    // function getVehicleAndDrawbarTotalPermissible(ignoreOverride) {
    //     if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
    //         return getGrossCombinationMass();
    //     } else {
    //         return Math.min(getGrossCombinationMass(), getCombinationTotalBridgeOrRegulationVal(ignoreOverride));
    //     }
    // }

    function getExtremeBridgeGroupName(bridgeGroup) {
        switch(getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getExtremeBridgeGroupNameCombinationVehicle(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getExtremeBridgeGroupNameCombinationVehicleAndDrawbar(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getExtremeBridgeGroupNameCombinationVehicleAndSemiOrInterlinkOrPup(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getExtremeBridgeGroupNameCombinationVehicleAndInterlinkAndSemi(bridgeGroup);
        }
    }

    function mapPusherToMinusAxle(rearAxlePosition) {
        let tempVehicle = activeRig.getVehicle();
        let numMinusAxles = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

        switch(rearAxlePosition) {
            case 1:
                switch(numMinusAxles) {
                    case 1:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                    case 2:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                    case 3:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_3;
                }
                break; 
            case 2:
                switch(numMinusAxles) {
                    case 2:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                    case 3:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                }
                break; 
            case 3:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1; 
        }

        return '';
    }

    function mapCorrectVehicleRearExtremeGroupName() {
        let bridgeGroupsVisible = getBridgeGroupsVisible();

        if(bridgeGroupsVisible.showExtremeRearDrivenGroup && bridgeGroupsVisible.showExtremeRearGroup) {
            return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MAIN;
        }
        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR;
    }

    function getExtremeBridgeGroupNameCombinationVehicle(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE;
            case config.BRIDGE_GROUPS.GROUP2:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR;
                // return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP3:
                // return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MAIN;
                return mapCorrectVehicleRearExtremeGroupName();
                // return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP4:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_FRONT;
                // return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP5:
                return mapPusherToMinusAxle(1);
                // return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                // return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP6:
                return mapPusherToMinusAxle(2);
                // return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                // return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP7:
                return mapPusherToMinusAxle(3);
                // return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_3;
                // return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_1;
                // return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_2;
                // return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_3;
                // return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }



    function getExtremeBridgeGroupNameCombinationVehicleAndDrawbar(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE;
                // return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP2:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_FRONT_TRAILER1_FRONT;
            case config.BRIDGE_GROUPS.GROUP3:
                return config.EXTREME_BRIDGE_GROUPS.COMBINATION;
                // return getCombinationExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP4:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_TRAILER1_FRONT;
            case config.BRIDGE_GROUPS.GROUP5:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_TRAILER1_REAR;
            case config.BRIDGE_GROUPS.GROUP6:
                return config.EXTREME_BRIDGE_GROUPS.TRAILER1;
            case config.BRIDGE_GROUPS.GROUP7:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR;
                // return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                // return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MAIN;
                return mapCorrectVehicleRearExtremeGroupName();
                // return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_FRONT;
                // return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return config.EXTREME_BRIDGE_GROUPS.TRAILER1_FRONT;
                // return getTrailer1ExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP11:
                return config.EXTREME_BRIDGE_GROUPS.TRAILER1_REAR;
                // return getTrailer1ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP12:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_3;
                // return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP13:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                // return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP14:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                // return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP15:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_1;
                // return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP16:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_2;
                // return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP17:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_3;
                // return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }

    function getExtremeBridgeGroupNameCombinationVehicleAndSemiOrInterlinkOrPup(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE;
                // return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP2:
                return config.EXTREME_BRIDGE_GROUPS.COMBINATION;
                // return getCombinationExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP3:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_TRAILER1_REAR;
            case config.BRIDGE_GROUPS.GROUP4:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR;
                // return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP5:
                // return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MAIN;
                return mapCorrectVehicleRearExtremeGroupName();
                // return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP6:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_FRONT;
                // return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP7:
                return config.EXTREME_BRIDGE_GROUPS.TRAILER1_REAR;
                // return getTrailer1ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_3;
                // return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                // return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                // return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP11:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_1;
                // return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP12:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_2;
                // return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP13:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_3;
                // return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }

    function getExtremeBridgeGroupNameCombinationVehicleAndInterlinkAndSemi(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE;
                // return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP2:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_FRONT_TRAILER1_REAR;
            case config.BRIDGE_GROUPS.GROUP3:
                return config.EXTREME_BRIDGE_GROUPS.COMBINATION;
                // return getCombinationExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP4:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_TRAILER1_REAR;
            case config.BRIDGE_GROUPS.GROUP5:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_TRAILER2_REAR;
            case config.BRIDGE_GROUPS.GROUP6:
                return config.EXTREME_BRIDGE_GROUPS.TRAILER1_REAR_TRAILER2_REAR;
            case config.BRIDGE_GROUPS.GROUP7:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR;
                // return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                // return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MAIN;
                return mapCorrectVehicleRearExtremeGroupName();
                // return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_FRONT;
                // return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return config.EXTREME_BRIDGE_GROUPS.TRAILER1_REAR;
                // return getTrailer1ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP11:
                return config.EXTREME_BRIDGE_GROUPS.TRAILER2_REAR;
                // return getTrailer2ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP12:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_3;
                // return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP13:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                // return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP14:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                // return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP15:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_1;
                // return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP16:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_2;
                // return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP17:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_PLUS_3;
                // return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }

    function getCurrentCombinationBridgeFormulaUsed() {
        switch(getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return evaluateLegislation(legislationVals.variableTypes.ComVehBridgeFormulaUsed);
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarBridgeFormulaUsed);
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return evaluateLegislation(legislationVals.variableTypes.ComVehSemiBridgeFormulaUsed);
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return evaluateLegislation(legislationVals.variableTypes.ComVehPupBridgeFormulaUsed);
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiBridgeFormulaUsed);
        }
        return 0;
        
    }

    function getCurrentCombinationBridgeFormulaUsedAttributeName() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return legislationVals.variableTypes.ComVehBridgeFormulaUsed;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return legislationVals.variableTypes.ComVehDrawbarBridgeFormulaUsed;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return legislationVals.variableTypes.ComVehSemiBridgeFormulaUsed;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return legislationVals.variableTypes.ComVehPupBridgeFormulaUsed;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return legislationVals.variableTypes.ComVehIntSemiBridgeFormulaUsed;
        }
        return 'unsupported combination'; // User Story 17565

    }

    function getBridgeGroupsVisible() {
        var tempVehicle = activeRig.getVehicle();
        var trailer1 = activeRig.getTrailer1();
        var trailer2 = activeRig.getTrailer2();

        var groupsVisible = {
            showExtremeRearGroup: false,
            showExtremeRearDrivenGroup: false,
            showExtremeFrontGroup: false,
            showPusher1Group: false,
            showPusher2Group: false,
            showPusher3Group: false,
            showTag1Group: false,
            showTag2Group: false,
            showTag3Group: false,
            showTrailer1ExtremeFrontGroup: false,
            showTrailer1ExtremeRearGroup: false,
            showTrailer2ExtremeRearGroup: false
        }
        
        if (evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles) === 1) {
            if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
                groupsVisible.showExtremeRearGroup = true;
            }
            groupsVisible.showExtremeRearDrivenGroup = true;
            groupsVisible.showExtremeFrontGroup = true;
            if (tempVehicle.getAxlesHolder().getHasPusherAxleDown()) {
                var axle1Rear = tempVehicle.getAxlesHolder().getAxle1Rear();
                if (axle1Rear !== null && axle1Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && axle1Rear.getRaised() === false) {
                    groupsVisible.showPusher1Group = true;
                }
                var axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
                if (axle2Rear !== null && axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && axle2Rear.getRaised() === false) {
                    groupsVisible.showPusher2Group = true;
                }
                var axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
                if (axle3Rear !== null && axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER && axle3Rear.getRaised() === false) {
                    groupsVisible.showPusher3Group = true;
                }
            }
            if (tempVehicle.getAxlesHolder().getHasTagAxleDown()) {
                var numRearAxlesBeforeTags = tempVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags() - tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

                var tag1, tag2, tag3;
                if (numRearAxlesBeforeTags === 1) {
                    tag1 = tempVehicle.getAxlesHolder().getAxle2Rear();
                    tag2 = tempVehicle.getAxlesHolder().getAxle3Rear();
                    tag3 = tempVehicle.getAxlesHolder().getAxle4Rear();
                } else if (numRearAxlesBeforeTags === 2) {
                    tag1 = tempVehicle.getAxlesHolder().getAxle3Rear();
                    tag2 = tempVehicle.getAxlesHolder().getAxle4Rear();
                    tag3 = null;
                } else if (numRearAxlesBeforeTags === 3) {
                    tag1 = tempVehicle.getAxlesHolder().getAxle4Rear();
                    tag2 = null;
                    tag3 = null;
                }


                if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && tag1.getRaised() === false) {
                    groupsVisible.showTag1Group = true;
                }
                if (tag2 !== null && tag2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && tag2.getRaised() === false) {
                    groupsVisible.showTag2Group = true;
                }
                if (tag3 !== null && tag3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG && tag3.getRaised() === false) {
                    groupsVisible.showTag3Group = true;
                }
            }
            if (trailer1 !== null) {
                if (trailer1.getType() === config.TRAILER_TYPES.SEMI || trailer1.getType() === config.TRAILER_TYPES.INTERLINK || trailer1.getType() === config.TRAILER_TYPES.PUP) {
                    groupsVisible.showTrailer1ExtremeRearGroup = true;
                } else if (trailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    groupsVisible.showTrailer1ExtremeFrontGroup = true;
                    groupsVisible.showTrailer1ExtremeRearGroup = true;
                }
                if (trailer2 !== null) {
                    if (trailer2.getType() === config.TRAILER_TYPES.SEMI) {
                        groupsVisible.showTrailer2ExtremeRearGroup = true;
                    }
                }
            }
        }
        return groupsVisible;
    }
        
    function getBridgeGroupPermissibleHyperlinkElementId(bridgeGroup) {
        var combinationType = getCombinationType();

        switch (combinationType) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicle(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicleAndDrawbar(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //return getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicleAndSemi(bridgeGroup);
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicleAndPupOrInterlinkOrSemi(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicleAndInterlinkAndSemi(bridgeGroup);
        }
        return '';
    }

    function getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicle(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP2:
                return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP3:
                return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP4:
                return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP5:
                return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP6:
                return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP7:
                return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }

    function getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicleAndDrawbar(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP2:
                return '';
            case config.BRIDGE_GROUPS.GROUP3:
                return getCombinationExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP4:
                return '';
            case config.BRIDGE_GROUPS.GROUP5:
                return ''
            case config.BRIDGE_GROUPS.GROUP6:
                return '';
            case config.BRIDGE_GROUPS.GROUP7:
                return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return getTrailer1ExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP11:
                return getTrailer1ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP12:
                return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP13:
                return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP14:
                return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP15:
                return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP16:
                return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP17:
                return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }

    function getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicleAndInterlinkAndSemi(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP2:
                return '';
            case config.BRIDGE_GROUPS.GROUP3:
                return getCombinationExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP4:
                return '';
            case config.BRIDGE_GROUPS.GROUP5:
                return ''
            case config.BRIDGE_GROUPS.GROUP6:
                return '';
            case config.BRIDGE_GROUPS.GROUP7:
                return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return getTrailer1ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP11:
                return getTrailer2ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP12:
                return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP13:
                return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP14:
                return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP15:
                return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP16:
                return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP17:
                return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }

    function getBridgeGroupPermissibleHyperlinkElementIdCombinationVehicleAndPupOrInterlinkOrSemi(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP2:
                return getCombinationExtremeAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP3:
                return '';
            case config.BRIDGE_GROUPS.GROUP4:
                return getVehicleExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP5:
                return getVehicleExtremeRearDrivenAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP6:
                return getVehicleExtremeFrontAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP7:
                return getTrailer1ExtremeRearAxlesInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP8:
                return getVehiclePusher1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP9:
                return getVehiclePusher2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP10:
                return getVehiclePusher3AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP11:
                return getVehicleTag1AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP12:
                return getVehicleTag2AxleInteractionTargetElementId();
            case config.BRIDGE_GROUPS.GROUP13:
                return getVehicleTag3AxleInteractionTargetElementId();
        }
        return '';
    }

    function getCombinationExtremeAxlesInteractionTargetElementId() {
        var combinationType = getCombinationType();

        switch (combinationType) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleExtremeAxlesInteractionTargetElementId();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.COMBINATIONBRIDGEFORMULAPERMISSIBLE;
        }
        return '';
    }

    function getCombinationExtremeAxlesInteractionTargetElementIdPermissible() {
        var combinationType = getCombinationType();

        switch (combinationType) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleExtremeAxlesInteractionTargetElementIdPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.COMBINATIONTOTALPERMISSIBLE;
        }
        return '';
    }

    
    function getInteractionTargetElementIdValueType() {
        var valueType;
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
            valueType = config.VALUE_TYPE.AXLEPERMISSIBLEDETAILED;
        } else {
            valueType = config.VALUE_TYPE.AXLEPERMISSIBLESIMPLIFIED;
        }
        return valueType;
    }
    
    function getTrailer1ExtremeFrontAxlesInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getTrailer1ExtremeRearAxlesInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.TRAILER1 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getTrailer2ExtremeRearAxlesInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.TRAILER2 + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + activeRig.getTrailer2().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehicleExtremeAxlesInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.VEHICLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehicleExtremeRearAxlesInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_REAR_TOTAL + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehicleExtremeRearDrivenAxlesInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehicleExtremeFrontAxlesInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehiclePusher1AxleInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + activeRig.getVehicle().getAxlesHolder().getAxle1Rear().getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehiclePusher2AxleInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + activeRig.getVehicle().getAxlesHolder().getAxle2Rear().getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehiclePusher3AxleInteractionTargetElementId() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + activeRig.getVehicle().getAxlesHolder().getAxle3Rear().getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
    }

    function getVehicleTag1AxleInteractionTargetElementId() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);
        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + tag1.getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
        }
        return '';
    }

    function getVehicleTag2AxleInteractionTargetElementId() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);

        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag2 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 1);
            if (tag2 !== null && tag2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + tag2.getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
            }

        }
        return '';
    }

    function getVehicleTag3AxleInteractionTargetElementId() {
        var tempVehicle = activeRig.getVehicle();


        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);

        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag3 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 2);


            if (tag3 !== null && tag3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + tag3.getId() + '.' + config.VALUE_TYPE.AXLEBRIDGEFORMULAPERMISSIBLE;
            }
        }
        return '';
    }

    function getVehicleExtremeAxlesInteractionTargetElementIdPermissible() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_TOTAL + '.' + config.VALUE_TYPE.VEHICLETOTALPERMISSIBLE;
    }

    function getVehicleExtremeRearAxlesInteractionTargetElementIdPermissible() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES_REAR_TOTAL + '.' + getInteractionTargetElementIdValueType();
    }

    function getVehicleExtremeRearDrivenAxlesInteractionTargetElementIdPermissible() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getId() + '.' + getInteractionTargetElementIdValueType();
    }

    function getVehicleExtremeFrontAxlesInteractionTargetElementIdPermissible() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLE_GROUPS + '.' + activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getId() + '.' + getInteractionTargetElementIdValueType();
    }

    function getVehiclePusher1AxleInteractionTargetElementIdPermissible() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + activeRig.getVehicle().getAxlesHolder().getAxle1Rear().getId() + '.' + getInteractionTargetElementIdValueType();
    }

    function getVehiclePusher2AxleInteractionTargetElementIdPermissible() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + activeRig.getVehicle().getAxlesHolder().getAxle2Rear().getId() + '.' + getInteractionTargetElementIdValueType();
    }

    function getVehiclePusher3AxleInteractionTargetElementIdPermissible() {
        return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + activeRig.getVehicle().getAxlesHolder().getAxle3Rear().getId() + '.' + getInteractionTargetElementIdValueType();
    }

    function getVehicleTag1AxleInteractionTargetElementIdPermissible() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);
        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + tag1.getId() + '.' + getInteractionTargetElementIdValueType();
        }
        return '';
    }

    function getVehicleTag2AxleInteractionTargetElementIdPermissible() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);

        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag2 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 1);
            if (tag2 !== null && tag2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + tag2.getId() + '.' + getInteractionTargetElementIdValueType();
            }

        }
        return '';
    }

    function getVehicleTag3AxleInteractionTargetElementIdPermissible() {
        var tempVehicle = activeRig.getVehicle();


        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);

        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag3 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 2);


            if (tag3 !== null && tag3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return config.CHASSIS_OBJECT_TYPES.VEHICLE + '.' + config.OBJECT_TYPES.AXLES + '.' + tag3.getId() + '.' + getInteractionTargetElementIdValueType();
            }
        }
        return '';
    }
    
    function getBridgeGroupLabel(bridgeGroup) { 
        var combinationType = getCombinationType();

        switch (combinationType) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getBridgeGroupLabelCombinationVehicle(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getBridgeGroupLabelCombinationVehicleAndDrawbar(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return getBridgeGroupLabelCombinationVehicleAndSemi(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getBridgeGroupLabelCombinationVehicleAndPup(bridgeGroup);
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getBridgeGroupLabelCombinationVehicleAndInterlinkAndSemi(bridgeGroup);
        }
        return '';
    }

    function getBridgeGroupLabelCombinationVehicle(bridgeGroup) {
        
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getBridgeGroup1Label();
            case config.BRIDGE_GROUPS.GROUP2:
                return getBridgeGroup2VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP3:
                return getBridgeGroup3VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP4:
                return getBridgeGroup4VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP5:
                return getBridgeGroup5VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP6:
                return getBridgeGroup6VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP7:
                return getBridgeGroup7VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP8:
                return getBridgeGroup8VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP9:
                return getBridgeGroup9VehicleLabel();
            case config.BRIDGE_GROUPS.GROUP10:
                return getBridgeGroup10VehicleLabel();
        }
        return '';
    }

    function getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearLabel() {
        var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        var vehicleFrontMostRearDownAxleNumber = tempVehicleAxlesHolder.getAxleByPosition(tempVehicleAxlesHolder.getFrontMostDownAxlePositionAtRear()).getNumber();

        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var trailer1RearMostDownAxleNumber = tempTrailer1AxlesHolder.getAxleByPosition(tempTrailer1AxlesHolder.getRearMostDownAxlePositionAtRear()).getNumber();

        return vehicleFrontMostRearDownAxleNumber + ' - ' + (trailer1RearMostDownAxleNumber);
    }

    function getBridgeGroup1Label() {
        var tempVehicle = activeRig.getVehicle();
        
        var rearMostDownAxlePositionAtRear = tempVehicle.getAxlesHolder().getRearMostDownAxlePositionAtRear();
        var lastAxleNumForGroup = 0;


        switch(rearMostDownAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle1Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle2Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle3Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle4Rear().getNumber();
                break;

        }
        return '1 - ' + lastAxleNumForGroup;
    }

    function getBridgeVehicleExtermeRearGroupLabel() {
        var tempVehicle = activeRig.getVehicle();

        var rearMostDownAxlePositionAtRear = tempVehicle.getAxlesHolder().getRearMostDownAxlePositionAtRear();
        var frontMostDownAxlePositionAtRear = tempVehicle.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        var firstAxleNumForGroup = 0, lastAxleNumForGroup = 0;


        switch (frontMostDownAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle1Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle2Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle3Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle4Rear().getNumber();
                break;
        }

        switch (rearMostDownAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle1Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle2Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle3Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle4Rear().getNumber();
                break;

        }


        return firstAxleNumForGroup + ' - ' + lastAxleNumForGroup;
    }

    function getBridgeGroup2VehicleLabel(bridgeGroup) {
        return getBridgeVehicleExtermeRearGroupLabel();
    }

    function getBridgeVehicleExtermeRearDrivenGroupLabel() {
        var tempVehicle = activeRig.getVehicle();

        var firstRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getFirstRearDrivenAxlePosition();
        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();

        var firstAxleNumForGroup = 0, lastAxleNumForGroup = 0;

        switch (firstRearDrivenAxlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle1Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle2Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle3Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                firstAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle4Rear().getNumber();
                break;
        }

        switch (lastRearDrivenAxlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle1Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle2Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle3Rear().getNumber();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                lastAxleNumForGroup = tempVehicle.getAxlesHolder().getAxle4Rear().getNumber();
                break;

        }

        return firstAxleNumForGroup + ' - ' + lastAxleNumForGroup;
    }

    function getBridgeGroup3VehicleLabel(bridgeGroup) {
        return getBridgeVehicleExtermeRearDrivenGroupLabel();
    }

    function getBridgeVehicleExtermeFrontGroupLabel() {
        var lastAxleNumForGroup = 1;
        var axle2Front = activeRig.getVehicle().getAxlesHolder().getAxle2Front();
        if (axle2Front !== null && axle2Front.getRaised() === false) {
            lastAxleNumForGroup = 2;
        }

        return '1 - ' + lastAxleNumForGroup;
    }

    function getBridgeGroup4VehicleLabel(bridgeGroup) {
        return getBridgeVehicleExtermeFrontGroupLabel();
    }

    function getBridgeVehiclePusher1GroupLabel() {
        var numFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        var axlePosNum = numFrontAxles + 1;

        return axlePosNum + ' - ' + axlePosNum;
    }

    function getBridgeGroup5VehicleLabel(bridgeGroup) {
        return getBridgeVehiclePusher1GroupLabel();
    }

    function getBridgeVehiclePusher2GroupLabel() {
        var numFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

        var axlePosNum = numFrontAxles + 2;

        return axlePosNum + ' - ' + axlePosNum;
    }

    function getBridgeGroup6VehicleLabel(bridgeGroup) {
        return getBridgeVehiclePusher2GroupLabel();
    }

    function getBridgeVehiclePusher3GroupLabel() {
        var numFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

        var axlePosNum = numFrontAxles + 3;

        return axlePosNum + ' - ' + axlePosNum;
    }

    function getBridgeGroup7VehicleLabel(bridgeGroup) {
        return getBridgeVehiclePusher3GroupLabel()
    }

    function getBridgeVehicleTag1GroupLabel() {
        var numAxlesBeforeTags = activeRig.getVehicle().getAxlesHolder().getNumAxlesOnVehicleBeforeTags();

        return (numAxlesBeforeTags + 1) + ' - ' + (numAxlesBeforeTags + 1);
    }

    function getBridgeGroup8VehicleLabel(bridgeGroup) {
        return getBridgeVehicleTag1GroupLabel();
    }

    function getBridgeVehicleTag2GroupLabel() {
        var numAxlesBeforeTags = activeRig.getVehicle().getAxlesHolder().getNumAxlesOnVehicleBeforeTags();

        return (numAxlesBeforeTags + 2) + ' - ' + (numAxlesBeforeTags + 2);
    }

    function getBridgeGroup9VehicleLabel(bridgeGroup) {
        return getBridgeVehicleTag2GroupLabel();
    }

    function getBridgeVehicleTag3GroupLabel() {
        var numAxlesBeforeTags = activeRig.getVehicle().getAxlesHolder().getNumAxlesOnVehicleBeforeTags();

        return (numAxlesBeforeTags + 3) + ' - ' + (numAxlesBeforeTags + 3);
    }

    function getBridgeGroup10VehicleLabel(bridgeGroup) {
        return getBridgeVehicleTag3GroupLabel();
    }

    function getBridgeTrailer1ExtremeRearGroupLabel() {
        var tempTrailer1 = activeRig.getTrailer1();
                    

        var rearMostDownAxlePositionAtRear = tempTrailer1.getAxlesHolder().getRearMostDownAxlePositionAtRear();
        var frontMostDownAxlePositionAtRear = tempTrailer1.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        var firstAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(frontMostDownAxlePositionAtRear).getNumber();
        var lastAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(rearMostDownAxlePositionAtRear).getNumber();

        return firstAxleNumForGroup + ' - ' + lastAxleNumForGroup;
    }

    function getBridgeTrailer2ExtremeRearGroupLabel() {
        var tempTrailer2 = activeRig.getTrailer2();
        
        var rearMostDownAxlePositionAtRear = tempTrailer2.getAxlesHolder().getRearMostDownAxlePositionAtRear();
        var frontMostDownAxlePositionAtRear = tempTrailer2.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        var firstAxleNumForGroup = tempTrailer2.getAxlesHolder().getAxleByPosition(frontMostDownAxlePositionAtRear).getNumber();
        var lastAxleNumForGroup = tempTrailer2.getAxlesHolder().getAxleByPosition(rearMostDownAxlePositionAtRear).getNumber();

        return firstAxleNumForGroup + ' - ' + lastAxleNumForGroup;
    }

    function getBridgeTrailer1ExtremeFrontGroupLabel() {
        var tempTrailer1 = activeRig.getTrailer1();


        var rearMostDownAxlePositionAtFront = tempTrailer1.getAxlesHolder().getRearMostDownAxlePositionAtFront();
        var frontMostDownAxlePositionAtFront = tempTrailer1.getAxlesHolder().getFrontMostDownAxlePositionAtFront();
        var firstAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(frontMostDownAxlePositionAtFront).getNumber();
        var lastAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(rearMostDownAxlePositionAtFront).getNumber();

        return firstAxleNumForGroup + ' - ' + lastAxleNumForGroup;
    }

    function getBridgeGroup2VehicleAndSemiLabel() {
        //return 'A - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var trailer1RearMostDownAxleNumber = tempTrailer1AxlesHolder.getAxleByPosition(tempTrailer1AxlesHolder.getRearMostDownAxlePositionAtRear()).getNumber();

        return '1 - ' + (trailer1RearMostDownAxleNumber);
    }

    function getBridgeGroup3VehicleAndSemiLabel() {

        //var numVehicleFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        //numVehicleFrontAxles += activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
        //numVehicleFrontAxles += 1;

        ////return String.fromCharCode(65 + numVehicleFrontAxles) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        //return numVehicleFrontAxles + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearLabel();
        
    }

    function getBridgeGroup4VehicleAndSemiLabel() {
        return getBridgeVehicleExtermeRearGroupLabel();
    }

    function getBridgeGroup5VehicleAndSemiLabel() {
        return getBridgeVehicleExtermeRearDrivenGroupLabel();
    }

    function getBridgeGroup6VehicleAndSemiLabel() {
        return getBridgeVehicleExtermeFrontGroupLabel();
    }

    function getBridgeGroup7VehicleAndSemiLabel() {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        var numAxlesOnVehicle = tempVehicle.getAxlesHolder().getAxles().length;

        var rearMostDownAxlePositionAtRear = tempTrailer1.getAxlesHolder().getRearMostDownAxlePositionAtRear();
        var frontMostDownAxlePositionAtRear = tempTrailer1.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        var firstAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(frontMostDownAxlePositionAtRear).getNumber();
        var lastAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(rearMostDownAxlePositionAtRear).getNumber();

        return firstAxleNumForGroup + ' - ' + lastAxleNumForGroup;
    }

    function getBridgeGroup8VehicleAndSemiLabel() {
        return getBridgeVehiclePusher1GroupLabel();
    }

    function getBridgeGroup9VehicleAndSemiLabel() {
        return getBridgeVehiclePusher2GroupLabel();
    }

    function getBridgeGroup10VehicleAndSemiLabel() {
        return getBridgeVehiclePusher3GroupLabel();
    }

    function getBridgeGroup11VehicleAndSemiLabel() {
        return getBridgeVehicleTag1GroupLabel();
    }

    function getBridgeGroup12VehicleAndSemiLabel() {
        return getBridgeVehicleTag2GroupLabel();
    }

    function getBridgeGroup13VehicleAndSemiLabel() {
        return getBridgeVehicleTag3GroupLabel();
    }

    

    function getBridgeGroup2VehicleAndDrawbarLabel() {
        var numTrailerFrontAxles = activeRig.getTrailer1().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        
        //return 'A - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + numTrailerFrontAxles - 1);
        return '1 - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + numTrailerFrontAxles);
    }

    

    function getBridgeGroup2VehicleAndInterlinkAndSemiLabel() {
        //return 'A - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        return '1 - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
    }

    

    function getBridgeGroup3VehicleAndDrawbarLabel() {
        //return 'A - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        return '1 - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup2VehicleAndPupLabel() {
        //return 'A - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        return '1 - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup3VehicleAndPupLabel() {
        //var numVehicleFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        //numVehicleFrontAxles += activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
        //numVehicleFrontAxles += 1;

        ////return String.fromCharCode(65 + numVehicleFrontAxles) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        //return numVehicleFrontAxles + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearLabel();
    }

    function getBridgeGroup4VehicleAndPupLabel() {
        return getBridgeVehicleExtermeRearGroupLabel();
    }

    function getBridgeGroup5VehicleAndPupLabel() {
        return getBridgeVehicleExtermeRearDrivenGroupLabel();
    }

    function getBridgeGroup6VehicleAndPupLabel() {
        return getBridgeVehicleExtermeFrontGroupLabel();
    }

    function getBridgeGroup7VehicleAndPupLabel() {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        var numAxlesOnVehicle = tempVehicle.getAxlesHolder().getAxles().length;

        var rearMostDownAxlePositionAtRear = tempTrailer1.getAxlesHolder().getRearMostDownAxlePositionAtRear();
        var frontMostDownAxlePositionAtRear = tempTrailer1.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        var firstAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(frontMostDownAxlePositionAtRear).getNumber();
        var lastAxleNumForGroup = tempTrailer1.getAxlesHolder().getAxleByPosition(rearMostDownAxlePositionAtRear).getNumber();

        return firstAxleNumForGroup + ' - ' + lastAxleNumForGroup;
    }

    function getBridgeGroup8VehicleAndPupLabel() {
        return getBridgeVehiclePusher1GroupLabel();
    }

    function getBridgeGroup9VehicleAndPupLabel() {
        return getBridgeVehiclePusher2GroupLabel();
    }

    function getBridgeGroup10VehicleAndPupLabel() {
        return getBridgeVehiclePusher3GroupLabel();
    }

    function getBridgeGroup11VehicleAndPupLabel() {
        return getBridgeVehicleTag1GroupLabel();
    }

    function getBridgeGroup12VehicleAndPupLabel() {
        return getBridgeVehicleTag2GroupLabel();
    }

    function getBridgeGroup13VehicleAndPupLabel() {
        return getBridgeVehicleTag3GroupLabel();
    }

    function getBridgeGroup3VehicleAndInterlinkAndSemiLabel() {
        //return 'A - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length + activeRig.getTrailer2().getAxlesHolder().getAxles().length - 1);
        return '1 - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length + activeRig.getTrailer2().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup4VehicleAndDrawbarLabel() {
        var numVehicleFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        numVehicleFrontAxles += activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
        numVehicleFrontAxles += 1;

        var numTrailerFrontAxles = activeRig.getTrailer1().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);

        //return String.fromCharCode(65 + numVehicleFrontAxles) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + numTrailerFrontAxles - 1);
        return numVehicleFrontAxles + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + numTrailerFrontAxles);
    }

    function getBridgeGroup4VehicleAndInterlinkAndSemiLabel() {
        var numVehicleFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        numVehicleFrontAxles += activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
        numVehicleFrontAxles += 1;

        //return String.fromCharCode(65 + numVehicleFrontAxles) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        return numVehicleFrontAxles + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup5VehicleAndDrawbarLabel() {
        var numVehicleFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        numVehicleFrontAxles += activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
        numVehicleFrontAxles += 1;

        //return String.fromCharCode(65 + numVehicleFrontAxles) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        return numVehicleFrontAxles + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup5VehicleAndInterlinkAndSemiLabel() {
        var numVehicleFrontAxles = activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        numVehicleFrontAxles += activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
        
        //return String.fromCharCode(65 + numVehicleFrontAxles) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length + activeRig.getTrailer2().getAxlesHolder().getAxles().length - 1);
        return numVehicleFrontAxles + 1 + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length + activeRig.getTrailer2().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup6VehicleAndDrawbarLabel() {
        var numVehicleAxles = activeRig.getVehicle().getAxlesHolder().getAxles().length + 1;
        //return String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length - 1);
        return numVehicleAxles + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup6VehicleAndInterlinkAndSemiLabel() {
        var numVehicleAxles = activeRig.getVehicle().getAxlesHolder().getAxles().length + 1;
        //return String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length) + ' - ' + String.fromCharCode(65 + activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length + activeRig.getTrailer2().getAxlesHolder().getAxles().length - 1);
        return numVehicleAxles + ' - ' + (activeRig.getVehicle().getAxlesHolder().getAxles().length + activeRig.getTrailer1().getAxlesHolder().getAxles().length + activeRig.getTrailer2().getAxlesHolder().getAxles().length);
    }

    function getBridgeGroup7VehicleAndDrawbarLabel() {
        return getBridgeVehicleExtermeRearGroupLabel();
    }

    function getBridgeGroup7VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehicleExtermeRearGroupLabel();
    }

    function getBridgeGroup8VehicleAndDrawbarLabel() {
        return getBridgeVehicleExtermeRearDrivenGroupLabel();
    }

    function getBridgeGroup8VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehicleExtermeRearDrivenGroupLabel();
    }

    function getBridgeGroup9VehicleAndDrawbarLabel() {
        return getBridgeVehicleExtermeFrontGroupLabel();
    }

    function getBridgeGroup9VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehicleExtermeFrontGroupLabel();
    }

    function getBridgeGroup10VehicleAndDrawbarLabel() {
        return getBridgeTrailer1ExtremeFrontGroupLabel();
    }

    function getBridgeGroup10VehicleAndInterlinkAndSemiLabel() {
        return getBridgeTrailer1ExtremeRearGroupLabel();
    }

    function getBridgeGroup11VehicleAndDrawbarLabel() {
        return getBridgeTrailer1ExtremeRearGroupLabel();
    }

    function getBridgeGroup11VehicleAndInterlinkAndSemiLabel() {
        return getBridgeTrailer2ExtremeRearGroupLabel();
    }

    function getBridgeGroup12VehicleAndDrawbarLabel() {
        return getBridgeVehiclePusher1GroupLabel();
    }

    function getBridgeGroup12VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehiclePusher1GroupLabel();
    }

    function getBridgeGroup13VehicleAndDrawbarLabel() {
        return getBridgeVehiclePusher2GroupLabel();
    }

    function getBridgeGroup13VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehiclePusher2GroupLabel();
    }

    function getBridgeGroup14VehicleAndDrawbarLabel() {
        return getBridgeVehiclePusher3GroupLabel();
    }

    function getBridgeGroup14VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehiclePusher3GroupLabel();
    }

    function getBridgeGroup15VehicleAndDrawbarLabel() {
        return getBridgeVehicleTag1GroupLabel();
    }

    function getBridgeGroup15VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehicleTag1GroupLabel();
    }

    function getBridgeGroup16VehicleAndDrawbarLabel() {
        return getBridgeVehicleTag2GroupLabel();
    }

    function getBridgeGroup16VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehicleTag2GroupLabel();
    }

    function getBridgeGroup17VehicleAndDrawbarLabel() {
        return getBridgeVehicleTag3GroupLabel();
    }

    function getBridgeGroup17VehicleAndInterlinkAndSemiLabel() {
        return getBridgeVehicleTag3GroupLabel();
    }

    function getBridgeGroupLabelCombinationVehicleAndSemi(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getBridgeGroup1Label();
            case config.BRIDGE_GROUPS.GROUP2:
                return getBridgeGroup2VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP3:
                return getBridgeGroup3VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP4:
                return getBridgeGroup4VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP5:
                return getBridgeGroup5VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP6:
                return getBridgeGroup6VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP7:
                return getBridgeGroup7VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP8:
                return getBridgeGroup8VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP9:
                return getBridgeGroup9VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP10:
                return getBridgeGroup10VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP11:
                return getBridgeGroup11VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP12:
                return getBridgeGroup12VehicleAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP13:
                return getBridgeGroup13VehicleAndSemiLabel();
        }
        return '';
    }

    function getBridgeGroupLabelCombinationVehicleAndDrawbar(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getBridgeGroup1Label();
            case config.BRIDGE_GROUPS.GROUP2:
                return getBridgeGroup2VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP3:
                return getBridgeGroup3VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP4:
                return getBridgeGroup4VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP5:
                return getBridgeGroup5VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP6:
                return getBridgeGroup6VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP7:
                return getBridgeGroup7VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP8:
                return getBridgeGroup8VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP9:
                return getBridgeGroup9VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP10:
                return getBridgeGroup10VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP11:
                return getBridgeGroup11VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP12:
                return getBridgeGroup12VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP13:
                return getBridgeGroup13VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP14:
                return getBridgeGroup14VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP15:
                return getBridgeGroup15VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP16:
                return getBridgeGroup16VehicleAndDrawbarLabel();
            case config.BRIDGE_GROUPS.GROUP17:
                return getBridgeGroup17VehicleAndDrawbarLabel();
        }
        return '';
    }

    function getBridgeGroupLabelCombinationVehicleAndPup(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getBridgeGroup1Label();
            case config.BRIDGE_GROUPS.GROUP2:
                return getBridgeGroup2VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP3:
                return getBridgeGroup3VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP4:
                return getBridgeGroup4VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP5:
                return getBridgeGroup5VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP6:
                return getBridgeGroup6VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP7:
                return getBridgeGroup7VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP8:
                return getBridgeGroup8VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP9:
                return getBridgeGroup9VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP10:
                return getBridgeGroup10VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP11:
                return getBridgeGroup11VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP12:
                return getBridgeGroup12VehicleAndPupLabel();
            case config.BRIDGE_GROUPS.GROUP13:
                return getBridgeGroup13VehicleAndPupLabel();
        }
        return '';
    }

    function getBridgeGroupLabelCombinationVehicleAndInterlinkAndSemi(bridgeGroup) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getBridgeGroup1Label();
            case config.BRIDGE_GROUPS.GROUP2:
                return getBridgeGroup2VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP3:
                return getBridgeGroup3VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP4:
                return getBridgeGroup4VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP5:
                return getBridgeGroup5VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP6:
                return getBridgeGroup6VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP7:
                return getBridgeGroup7VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP8:
                return getBridgeGroup8VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP9:
                return getBridgeGroup9VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP10:
                return getBridgeGroup10VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP11:
                return getBridgeGroup11VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP12:
                return getBridgeGroup12VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP13:
                return getBridgeGroup13VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP14:
                return getBridgeGroup14VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP15:
                return getBridgeGroup15VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP16:
                return getBridgeGroup16VehicleAndInterlinkAndSemiLabel();
            case config.BRIDGE_GROUPS.GROUP17:
                return getBridgeGroup17VehicleAndInterlinkAndSemiLabel();
        }
        return '';
    }

    // function getCombinationTotalBridgeOrRegulationOverload() {
    //     switch (combinationType) {
    //         case config.COMBINATION_TYPES.VEHICLE:
    //             return getBridgeGroupOverloadColourCombinationVehicle(bridgeGroup, percentageRoundingFunc);
    //         case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    //             return getBridgeGroupOverloadColourCombinationVehicleAndDrawbar(bridgeGroup, percentageRoundingFunc);
    //         case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    //             return getBridgeGroupOverloadColourCombinationVehicleAndSemi(bridgeGroup, percentageRoundingFunc);
    //         case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
    //             return getBridgeGroupOverloadColourCombinationVehicleAndPup(bridgeGroup, percentageRoundingFunc);
    //         case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
    //             return getBridgeGroupOverloadColourCombinationVehicleAndInterlinkAndSemi(bridgeGroup, percentageRoundingFunc);
    //     }
    //     return 0;
    //     getBridgeFormulaOverload
    // }

    function getCombinationExtremeGroupOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getGroup1BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getGroup2BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getGroup2BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getGroup3BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getGroup3BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroupOverloadColour(bridgeGroup, percentageRoundingFunc) {
        var combinationType = getCombinationType();

        switch (combinationType) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getBridgeGroupOverloadColourCombinationVehicle(bridgeGroup, percentageRoundingFunc);
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getBridgeGroupOverloadColourCombinationVehicleAndDrawbar(bridgeGroup, percentageRoundingFunc);
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return getBridgeGroupOverloadColourCombinationVehicleAndSemi(bridgeGroup, percentageRoundingFunc);
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getBridgeGroupOverloadColourCombinationVehicleAndPup(bridgeGroup, percentageRoundingFunc);
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getBridgeGroupOverloadColourCombinationVehicleAndInterlinkAndSemi(bridgeGroup, percentageRoundingFunc);
        }
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }

    function getBridgeGroupOverloadColourCombinationVehicle(bridgeGroup, percentageRoundingFunc) {
        
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                //return getOverloadColour(getVehicleGroup1BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup1BridgeFormulaActual(), getVehicleGroup1BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP2:
                //return getOverloadColour(getVehicleGroup2BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup2BridgeFormulaActual(), getVehicleGroup2BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP3:
                //return getOverloadColour(getVehicleGroup3BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup3BridgeFormulaActual(), getVehicleGroup3BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP4:
                //return getOverloadColour(getVehicleGroup4BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup4BridgeFormulaActual(), getVehicleGroup4BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP5:
                //return getOverloadColour(getVehicleGroup5BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup5BridgeFormulaActual(), getVehicleGroup5BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP6:
                //return getOverloadColour(getVehicleGroup6BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup6BridgeFormulaActual(), getVehicleGroup6BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP7:
                //return getOverloadColour(getVehicleGroup7BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup7BridgeFormulaActual(), getVehicleGroup7BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP8:
                //return getOverloadColour(getVehicleGroup8BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup8BridgeFormulaActual(), getVehicleGroup8BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP9:
                //return getOverloadColour(getVehicleGroup9BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup9BridgeFormulaActual(), getVehicleGroup9BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP10:
                //return getOverloadColour(getVehicleGroup10BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleGroup10BridgeFormulaActual(), getVehicleGroup10BridgeFormulaPermissible());
        }
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }

    function getBridgeGroupOverloadColourCombinationVehicleAndSemi(bridgeGroup, percentageRoundingFunc) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                //return getOverloadColour(getVehicleAndSemiOrInterlinkGroup1BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup1BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup1BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP2:
                //return getOverloadColour(getVehicleAndSemiOrInterlinkGroup2BridgeFormulaOverload(), percentageRoundingFunc);
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup2BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup2BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP3:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup3BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup3BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP4:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup4BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup4BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP5:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup5BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup5BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP6:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup6BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup6BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP7:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup7BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup7BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP8:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup8BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup8BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP9:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup9BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup9BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP10:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup10BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup10BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP11:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup11BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup11BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP12:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup12BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup12BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP13:
                return getOverloadColour(getVehicleAndSemiOrInterlinkGroup13BridgeFormulaActual(), getVehicleAndSemiOrInterlinkGroup13BridgeFormulaPermissible());
        }
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }

    function getBridgeGroupOverloadColourCombinationVehicleAndPup(bridgeGroup, percentageRoundingFunc) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getOverloadColour(getVehicleAndPupGroup1BridgeFormulaActual(), getVehicleAndPupGroup1BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP2:
                return getOverloadColour(getVehicleAndPupGroup2BridgeFormulaActual(), getVehicleAndPupGroup2BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP3:
                return getOverloadColour(getVehicleAndPupGroup3BridgeFormulaActual(), getVehicleAndPupGroup3BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP4:
                return getOverloadColour(getVehicleAndPupGroup4BridgeFormulaActual(), getVehicleAndPupGroup4BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP5:
                return getOverloadColour(getVehicleAndPupGroup5BridgeFormulaActual(), getVehicleAndPupGroup5BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP6:
                return getOverloadColour(getVehicleAndPupGroup6BridgeFormulaActual(), getVehicleAndPupGroup6BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP7:
                return getOverloadColour(getVehicleAndPupGroup7BridgeFormulaActual(), getVehicleAndPupGroup7BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP8:
                return getOverloadColour(getVehicleAndPupGroup8BridgeFormulaActual(), getVehicleAndPupGroup8BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP9:
                return getOverloadColour(getVehicleAndPupGroup9BridgeFormulaActual(), getVehicleAndPupGroup9BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP10:
                return getOverloadColour(getVehicleAndPupGroup10BridgeFormulaActual(), getVehicleAndPupGroup10BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP11:
                return getOverloadColour(getVehicleAndPupGroup11BridgeFormulaActual(), getVehicleAndPupGroup11BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP12:
                return getOverloadColour(getVehicleAndPupGroup12BridgeFormulaActual(), getVehicleAndPupGroup12BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP13:
                return getOverloadColour(getVehicleAndPupGroup13BridgeFormulaActual(), getVehicleAndPupGroup13BridgeFormulaPermissible());
        }
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }

    function getBridgeGroupOverloadColourCombinationVehicleAndDrawbar(bridgeGroup, percentageRoundingFunc) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getOverloadColour(getVehicleGroup1BridgeFormulaActual(), getVehicleGroup1BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP2:
                return getOverloadColour(getVehicleAndDrawbarGroup2BridgeFormulaActual(), getVehicleAndDrawbarGroup2BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP3:
                return getOverloadColour(getVehicleAndDrawbarGroup3BridgeFormulaActual(), getVehicleAndDrawbarGroup3BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP4:
                return getOverloadColour(getVehicleAndDrawbarGroup4BridgeFormulaActual(), getVehicleAndDrawbarGroup4BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP5:
                return getOverloadColour(getVehicleAndDrawbarGroup5BridgeFormulaActual(), getVehicleAndDrawbarGroup5BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP6:
                return getOverloadColour(getVehicleAndDrawbarGroup6BridgeFormulaActual(), getVehicleAndDrawbarGroup6BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP7:
                return getOverloadColour(getVehicleAndDrawbarGroup7BridgeFormulaActual(), getVehicleAndDrawbarGroup7BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP8:
                return getOverloadColour(getVehicleAndDrawbarGroup8BridgeFormulaActual(), getVehicleAndDrawbarGroup8BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP9:
                return getOverloadColour(getVehicleAndDrawbarGroup9BridgeFormulaActual(), getVehicleAndDrawbarGroup9BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP10:
                return getOverloadColour(getVehicleAndDrawbarGroup10BridgeFormulaActual(), getVehicleAndDrawbarGroup10BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP11:
                return getOverloadColour(getVehicleAndDrawbarGroup11BridgeFormulaActual(), getVehicleAndDrawbarGroup11BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP12:
                return getOverloadColour(getVehicleAndDrawbarGroup12BridgeFormulaActual(), getVehicleAndDrawbarGroup12BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP13:
                return getOverloadColour(getVehicleAndDrawbarGroup13BridgeFormulaActual(), getVehicleAndDrawbarGroup13BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP14:
                return getOverloadColour(getVehicleAndDrawbarGroup14BridgeFormulaActual(), getVehicleAndDrawbarGroup14BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP15:
                return getOverloadColour(getVehicleAndDrawbarGroup15BridgeFormulaActual(), getVehicleAndDrawbarGroup15BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP16:
                return getOverloadColour(getVehicleAndDrawbarGroup16BridgeFormulaActual(), getVehicleAndDrawbarGroup16BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP17:
                return getOverloadColour(getVehicleAndDrawbarGroup17BridgeFormulaActual(), getVehicleAndDrawbarGroup17BridgeFormulaPermissible());
        }
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }

    function getBridgeGroupOverloadColourCombinationVehicleAndInterlinkAndSemi(bridgeGroup, percentageRoundingFunc) {
        switch (bridgeGroup) {
            case config.BRIDGE_GROUPS.GROUP1:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup1BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup1BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP2:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup2BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup2BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP3:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup3BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup3BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP4:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup4BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup4BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP5:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup5BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup5BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP6:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup6BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup6BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP7:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup7BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup7BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP8:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup8BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup8BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP9:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup9BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup9BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP10:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup10BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup10BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP11:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup11BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup11BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP12:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup12BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup12BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP13:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup13BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup13BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP14:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup14BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup14BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP15:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup15BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup15BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP16:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup16BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup16BridgeFormulaPermissible());
            case config.BRIDGE_GROUPS.GROUP17:
                return getOverloadColour(getVehicleAndInterlinkAndSemiGroup17BridgeFormulaActual(), getVehicleAndInterlinkAndSemiGroup17BridgeFormulaPermissible());
        }
        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }
    
    function getOverloadColour(compareVal, refVal) {

        //var roundValToCheck = percentageRoundingFunc(overloadValToCheck);
        compareVal = compareVal * globals.user.getActiveMeasurementSystem().massConversion;
        refVal = refVal * globals.user.getActiveMeasurementSystem().massConversion;
        var legislationVal = evaluateLegislation(legislationVals.variableTypes.BridgeTolerance);

        //if (roundValToCheck <= 0) {
        //    return config.TABLE_HIGHLIGHT_COLOURS.OK;
        //} else if (roundValToCheck > 0 && roundValToCheck <= bridgeTolerance) {
        //    return config.TABLE_HIGHLIGHT_COLOURS.WARNING;
        //} else if (roundValToCheck > bridgeTolerance) {
        //    return config.TABLE_HIGHLIGHT_COLOURS.FAIL;
        //}



        var roundCompareVal = applyRounding(compareVal);
        var roundedRefVal = applyRounding(refVal);
        var overloaded = roundCompareVal > roundedRefVal;


        if (overloaded) {
            var legislationPercentageAsUnitVal = applyRounding(refVal * (legislationVal/100));
            if (roundCompareVal > 0 && (roundCompareVal - roundedRefVal) <= legislationPercentageAsUnitVal) {
                //if (columName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columName === config.TABLE_COLUMNS.REAR_CONNECTED) {
                //    return config.DRAWING_COLOURS.LIGHT_ORANGE;
                //} else {
                    return config.TABLE_HIGHLIGHT_COLOURS.WARNING;
                //}

            } else if (roundCompareVal - roundedRefVal > legislationPercentageAsUnitVal) {
                //if (columName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columName === config.TABLE_COLUMNS.REAR_CONNECTED) {
                //    return config.DRAWING_COLOURS.LIGHT_RED;
                //} else {
                    return config.TABLE_HIGHLIGHT_COLOURS.FAIL;
                //}

            }

        }
        //if (columName.indexOf(config.TABLE_COLUMNS.PUSHER) !== -1 || columName.indexOf(config.TABLE_COLUMNS.TAG) !== -1 || columName === config.TABLE_COLUMNS.REAR_CONNECTED) {
        //    return config.DRAWING_COLOURS.LIGHT_GREEN;
        //} else {
            return config.TABLE_HIGHLIGHT_COLOURS.OK;
        //}

    
        function applyRounding(valToRound) {
            return parseFloat(valToRound.toFixed(config.roundingPrecisionForTableAndWarnings));
        }

    }

    //function getOverloadColour(overloadValToCheck, percentageRoundingFunc) {

    //    var roundValToCheck = percentageRoundingFunc(overloadValToCheck);

    //    var bridgeTolerance = evaluateLegislation(legislationVals.variableTypes.BridgeTolerance);

    //    if (roundValToCheck <= 0) {
    //        return config.TABLE_HIGHLIGHT_COLOURS.OK;
    //    } else if (roundValToCheck > 0 && roundValToCheck <= bridgeTolerance) {
    //        return config.TABLE_HIGHLIGHT_COLOURS.WARNING;
    //    } else if (roundValToCheck > bridgeTolerance) {
    //        return config.TABLE_HIGHLIGHT_COLOURS.FAIL;
    //    }

    //    /*
    //    If GetPercentageInCurrentMeasurementSystem(VehicleAndDrawbarGroup1BridgeFormulaOverload) <= 0 Then
    //    backgroundColour = UtilDrawingSummaryObject.BackGroundColour.Green
    //ElseIf GetPercentageInCurrentMeasurementSystem(VehicleAndDrawbarGroup1BridgeFormulaOverload) > 0 And
    //       GetPercentageInCurrentMeasurementSystem(VehicleAndDrawbarGroup1BridgeFormulaOverload) <= GetPercentageInCurrentMeasurementSystem(LABridgeTolerance) Then
    //    backgroundColour = UtilDrawingSummaryObject.BackGroundColour.Orange
    //ElseIf GetPercentageInCurrentMeasurementSystem(VehicleAndDrawbarGroup1BridgeFormulaOverload) > GetPercentageInCurrentMeasurementSystem(LABridgeTolerance) Then
    //    backgroundColour = UtilDrawingSummaryObject.BackGroundColour.Red
    //End If
    //    */
    //}

    /*
    function getRearAxlesTitleAppend(objWithAxles, numPreviousAxles) {
            var numFrontAxles = objWithAxles.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
            numFrontAxles += objWithAxles.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER);
            //var numRearAxles = curVehicle.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            var numRearAxles = objWithAxles.getAxlesHolder().getAxles().length - numFrontAxles;

            if (numRearAxles > 1) {
                var firstRearAxleLetter = String.fromCharCode(65 + numPreviousAxles + (numFrontAxles));
                var lastRearAxleLetter = String.fromCharCode(65 + numPreviousAxles + ((numFrontAxles + numRearAxles) - 1));

                return '(' + firstRearAxleLetter + ' - ' + lastRearAxleLetter + ')';
            } else {
                return '(' + String.fromCharCode(65 + numPreviousAxles + ((numFrontAxles + numRearAxles) - 1)) + ')';
            }

        }
    */

    function hasMissingTareWeigthsForBridgeFormula() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return activeRig.getVehicle().getTareFront() === 0 || activeRig.getVehicle().getTareRearRear() === 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return activeRig.getVehicle().getTareFront() === 0 || activeRig.getVehicle().getTareRearRear() === 0 || activeRig.getTrailer1().getTareFront() === 0 || activeRig.getTrailer1().getTareRear() === 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return activeRig.getVehicle().getTareFront() === 0 || activeRig.getVehicle().getTareRearRear() === 0 || activeRig.getTrailer1().getTareFront() === 0 || activeRig.getTrailer1().getTareRear() === 0 || activeRig.getTrailer2().getTareFront() === 0 || activeRig.getTrailer2().getTareRear() === 0;
        }
        return false;
    }

    function hasMissingTareOrPermissibleWeigthsForRegulation() {
        let combinationPermissibleVals = getCombinationPermissibleVals();
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return activeRig.getVehicle().getTareFront() === 0 || activeRig.getVehicle().getTareRearRear() === 0 || 
                isZeroOrNaN(combinationPermissibleVals.vehicleFrontPermissible) || isZeroOrNaN(combinationPermissibleVals.vehicleRearPermissible);
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return activeRig.getVehicle().getTareFront() === 0 || activeRig.getVehicle().getTareRearRear() === 0 || activeRig.getTrailer1().getTareRear() === 0 || 
                isZeroOrNaN(combinationPermissibleVals.vehicleFrontPermissible) || isZeroOrNaN(combinationPermissibleVals.vehicleRearPermissible) || isZeroOrNaN(combinationPermissibleVals.trailer1PermissibleRear);
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return activeRig.getVehicle().getTareFront() === 0 || activeRig.getVehicle().getTareRearRear() === 0 || activeRig.getTrailer1().getTareFront() === 0 || activeRig.getTrailer1().getTareRear() === 0 || 
                isZeroOrNaN(combinationPermissibleVals.vehicleFrontPermissible) || isZeroOrNaN(combinationPermissibleVals.vehicleRearPermissible) || isZeroOrNaN(combinationPermissibleVals.trailer1PermissibleFront) || isZeroOrNaN(combinationPermissibleVals.trailer1PermissibleRear);
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return activeRig.getVehicle().getTareFront() === 0 || activeRig.getVehicle().getTareRearRear() === 0 || activeRig.getTrailer1().getTareRear() === 0 || activeRig.getTrailer2().getTareRear() === 0 || 
                isZeroOrNaN(combinationPermissibleVals.vehicleFrontPermissible) || isZeroOrNaN(combinationPermissibleVals.vehicleRearPermissible) || isZeroOrNaN(combinationPermissibleVals.trailer1PermissibleRear) || isZeroOrNaN(combinationPermissibleVals.trailer2PermissibleRear);
        }
        return false;
    }
    
    function isZeroOrNaN(value) {
        return value === 0 || isNaN(value);
    }

    function getGroup1BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup1BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup1BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup1BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup1BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup1BridgeFormulaNumberOfAxles();
        }
        return 0;
    }

    function getGroup1BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup1BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup1BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup1BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup1BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup1BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup1BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup1BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup1BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup1BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup1BridgeFormulaPermissible()
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup1BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup1BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup1BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup1BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup1BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup1BridgeFormulaActual()
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup1BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup1BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup1BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup1BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup1BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup1BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup1BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup1BridgeFormulaUnusedCapacity() {
        //switch (getCombinationType()) {
        //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
        //        return getVehicleAndSemiOrInterlinkGroup1BridgeFormulaOverload();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        //    case config.COMBINATION_TYPES.VEHICLE:
        //        return getVehicleGroup1BridgeFormulaOverload();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //        return getVehicleAndPupGroup1BridgeFormulaOverload();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        //        return getVehicleAndInterlinkAndSemiGroup1BridgeFormulaOverload();
        //}
        //return 0;
        return getGroup1BridgeFormulaPermissible() - getGroup1BridgeFormulaActual();
    }

    function getGroup2BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup2BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup2BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup2BridgeFormulaNumberOfAxles();
        }
        return 0;
    }

    function getGroup2BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup2BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup2BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup2BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup2BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup2BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup2BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup2BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup2BridgeFormulaPermissible()
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup2BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup2BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup2BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup2BridgeFormulaActual()
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup2BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup2BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup2BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup2BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup2BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup2BridgeFormulaUnusedCapacity() {
        return getGroup2BridgeFormulaPermissible() - getGroup2BridgeFormulaActual();
    }

    function getGroup3BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup3BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup3BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaNumberOfAxles();
        }
        return 0;
    }

    function getGroup3BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup3BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup3BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup3BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup3BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup3BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup3BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup3BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup3BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup3BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup3BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup3BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup3BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup3BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup3BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup3BridgeFormulaUnusedCapacity() {
        return getGroup3BridgeFormulaPermissible() - getGroup3BridgeFormulaActual();
    }

    function getGroup4BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup4BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup4BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup4BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup4BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup4BridgeFormulaNumberOfAxles();
        }
        return 0;
    }

    function getGroup4BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup4BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup4BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup4BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup4BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup4BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup4BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup4BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup4BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup4BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup4BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup4BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup4BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup4BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup4BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup4BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup4BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup4BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup4BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup4BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup4BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup4BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup4BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup4BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup4BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup4BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup4BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup4BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup4BridgeFormulaUnusedCapacity() {
        return getGroup4BridgeFormulaPermissible() - getGroup4BridgeFormulaActual();
    }

    function getGroup5BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup5BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup5BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup5BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup5BridgeFormulaNumberOfAxles();
        }
        return 0;
    }

    function getGroup5BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup5BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup5BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup5BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup5BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup5BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup5BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup5BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup5BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup5BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup5BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup5BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup5BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup5BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup5BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup5BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup5BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup5BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup5BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup5BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup5BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup5BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup5BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup5BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup5BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup5BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup5BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup5BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup5BridgeFormulaUnusedCapacity() {
        return getGroup5BridgeFormulaPermissible() - getGroup5BridgeFormulaActual();
    }

    function getGroup6BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup6BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup6BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup6BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup6BridgeFormulaNumberOfAxles();
        }
        return 0;
    }

    function getGroup6BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup6BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup6BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup6BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup6BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup6BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup6BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup6BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup6BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup6BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup6BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup6BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup6BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup6BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup6BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup6BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup6BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup6BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup6BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup6BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup6BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup6BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup6BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup6BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup6BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup6BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup6BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup6BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup6BridgeFormulaUnusedCapacity() {
        return getGroup6BridgeFormulaPermissible() - getGroup6BridgeFormulaActual();
    }

    function getGroup7BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup7BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup7BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:

        }
        return 0;
    }

    function getGroup7BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup7BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup7BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup7BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup7BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup7BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup7BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup7BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup7BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup7BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup7BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup7BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup7BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup7BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup7BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup7BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup7BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup7BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup7BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup7BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup7BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup7BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup7BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup7BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup7BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup7BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup7BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup7BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup7BridgeFormulaUnusedCapacity() {
        return getGroup7BridgeFormulaPermissible() - getGroup7BridgeFormulaActual();
    }

    function getGroup8BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup8BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup8BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return 0;
        }
        return 0;
    }

    function getGroup8BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup8BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup8BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup8BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup8BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup8BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup8BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup8BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup8BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup8BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup8BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup8BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup8BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup8BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup8BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup8BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup8BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup8BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup8BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup8BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup8BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup8BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup8BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup8BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup8BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup8BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup8BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup8BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup8BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup8BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup8BridgeFormulaUnusedCapacity() {
        return getGroup8BridgeFormulaPermissible() - getGroup8BridgeFormulaActual();
    }

    function getGroup9BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup9BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup9BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return 0;
        }
        return 0;
    }

    function getGroup9BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup9BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup9BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup9BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup9BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup9BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup9BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup9BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup9BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup9BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup9BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup9BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup9BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup9BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup9BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup9BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup9BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup9BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup9BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup9BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup9BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup9BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup9BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup9BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup9BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup9BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup9BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup9BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup9BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup9BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup9BridgeFormulaUnusedCapacity() {
        return getGroup9BridgeFormulaPermissible() - getGroup9BridgeFormulaActual();
    }

    function getGroup10BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //return getVehicleAndPupGroup10BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                break;
        }
        return 0;
    }

    function getGroup10BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup10BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup10BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup10BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup10BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup10BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup10BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup10BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup10BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup10BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup10BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup10BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup10BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup10BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup10BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup10BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup10BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup10BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup10BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup10BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup10BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup10BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup10BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup10BridgeFormulaUnusedCapacity() {
        return getGroup10BridgeFormulaPermissible() - getGroup10BridgeFormulaActual();
    }

    function getGroup11BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:

        }
        return 0;
    }

    function getGroup11BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup11BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup11BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup11BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup11BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup11BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup11BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup11BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup11BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup11BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup11BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup11BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup11BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup11BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup11BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup11BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup11BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup11BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup11BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup11BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup11BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup11BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup11BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup11BridgeFormulaUnusedCapacity() {
        return getGroup11BridgeFormulaPermissible() - getGroup11BridgeFormulaActual();
    }

    function getGroup12BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:

        }
        return 0;
    }

    function getGroup12BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup12BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup12BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup12BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup12BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup12BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup12BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup12BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup12BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup12BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup12BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup12BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup12BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup12BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup12BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup12BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup12BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup12BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup12BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup12BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup12BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup12BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup12BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup12BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup12BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup12BridgeFormulaUnusedCapacity() {
        return getGroup12BridgeFormulaPermissible() - getGroup12BridgeFormulaActual();
    }

    function getGroup13BridgeFormulaNumberOfAxles() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                //return getVehicleGroup4BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //return getVehicleAndSemiOrInterlinkGroup10BridgeFormulaNumberOfAxles();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                break;
        }
        return 0;
    }

    function getGroup13BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup13BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup13BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup13BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup13BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup13BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup13BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup13BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup13BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup13BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup13BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup13BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup13BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup13BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup13BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup13BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup13BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup13BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup13BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup13BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup13BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup13BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup13BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup13BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup13BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup13BridgeFormulaUnusedCapacity() {
        return getGroup13BridgeFormulaPermissible() - getGroup13BridgeFormulaActual();
    }

    function getGroup14BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup14BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup14BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup14BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup14BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup14BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup14BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup14BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup14BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup14BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup14BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup14BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup14BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup14BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup14BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup14BridgeFormulaUnusedCapacity() {
        return getGroup14BridgeFormulaPermissible() - getGroup14BridgeFormulaActual();
    }

    function getGroup15BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup15BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup15BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup15BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup15BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup15BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup15BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup15BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup15BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup15BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup15BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup15BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup15BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup15BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup15BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup15BridgeFormulaUnusedCapacity() {
        return getGroup15BridgeFormulaPermissible() - getGroup15BridgeFormulaActual();
    }

    function getGroup16BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup16BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup16BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup16BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup16BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup16BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup16BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup16BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup16BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup16BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup16BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup16BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup16BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup16BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup16BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup16BridgeFormulaUnusedCapacity() {
        return getGroup16BridgeFormulaPermissible() - getGroup16BridgeFormulaActual();
    }

    function getGroup17BridgeFormulaDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup17BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup17BridgeFormulaDistance();
        }
        return 0;
    }

    function getGroup17BridgeFormulaDistanceNearest() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup17BridgeFormulaDistanceNearest();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup17BridgeFormulaDistanceNearest();
        }
        return 0;
    }

    function getGroup17BridgeFormulaPermissible() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup17BridgeFormulaPermissible();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup17BridgeFormulaPermissible();
        }
        return 0;
    }

    function getGroup17BridgeFormulaActual() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup17BridgeFormulaActual();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup17BridgeFormulaActual();
        }
        return 0;
    }

    function getGroup17BridgeFormulaOverload() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup17BridgeFormulaOverload();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            //not supported
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup17BridgeFormulaOverload();
        }
        return 0;
    }

    function getGroup17BridgeFormulaUnusedCapacity() {
        return getGroup17BridgeFormulaPermissible() - getGroup17BridgeFormulaActual();
    }

    function getTrailer1ExtremeGroupBridgeFormulaNumberOfAxles() {
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var onlyCountDownAxles = true;

        return tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles) + tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaNumberOfAxles() {
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var onlyCountDownAxles = true;

        return tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaNumberOfAxles() {
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var onlyCountDownAxles = true;

        return tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempTrailer1FrontAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if (tempTrailer1FrontAxleGroup !== null) {
            return tempTrailer1FrontAxleGroup.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaWidthPerTyre() {
        var tempTrailer1FrontAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if (tempTrailer1FrontAxleGroup !== null) {
            return tempTrailer1FrontAxleGroup.getTyreWidth();
        }
        return 0;
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaLifting() {
        var tempTrailer1FrontAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if (tempTrailer1FrontAxleGroup !== null) {
            return tempTrailer1FrontAxleGroup.getLifting();
        }
        return false;
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaRaised() {
        var tempTrailer1FrontAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if (tempTrailer1FrontAxleGroup !== null) {
            return tempTrailer1FrontAxleGroup.getRaised();
        }
        return false;
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaSteering() {
        var tempTrailer1FrontAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if (tempTrailer1FrontAxleGroup !== null) {
            return tempTrailer1FrontAxleGroup.getSteering();
        }
        return false;
    }



    function getTrailer1ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempTrailer1RearAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer1RearAxleGroup !== null) {
            return tempTrailer1RearAxleGroup.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaWidthPerTyre() {
        var tempTrailer1RearAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer1RearAxleGroup !== null) {
            return tempTrailer1RearAxleGroup.getTyreWidth();
        }
        return 0;
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaLifting() {
        var tempTrailer1RearAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer1RearAxleGroup !== null) {
            return tempTrailer1RearAxleGroup.getLifting();
        }
        return false;
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaRaised() {
        var tempTrailer1RearAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer1RearAxleGroup !== null) {
            return tempTrailer1RearAxleGroup.getRaised();
        }
        return false;
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaSteering() {
        var tempTrailer1RearAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer1RearAxleGroup !== null) {
            return tempTrailer1RearAxleGroup.getSteering();
        }
        return false;
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaNumberOfAxles() {
        var tempTrailer2AxlesHolder = activeRig.getTrailer2().getAxlesHolder();
        var onlyCountDownAxles = true;

        return tempTrailer2AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempTrailer2RearAxleGroup = activeRig.getTrailer2().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer2RearAxleGroup !== null) {
            return tempTrailer2RearAxleGroup.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaWidthPerTyre() {
        var tempTrailer2RearAxleGroup = activeRig.getTrailer2().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer2RearAxleGroup !== null) {
            return tempTrailer2RearAxleGroup.getTyreWidth();
        }
        return 0;
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaLifting() {
        var tempTrailer2RearAxleGroup = activeRig.getTrailer2().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer2RearAxleGroup !== null) {
            return tempTrailer2RearAxleGroup.getLifting();
        }
        return false;
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaRaised() {
        var tempTrailer2RearAxleGroup = activeRig.getTrailer2().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer2RearAxleGroup !== null) {
            return tempTrailer2RearAxleGroup.getRaised();
        }
        return false;
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaSteering() {
        var tempTrailer2RearAxleGroup = activeRig.getTrailer2().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        if (tempTrailer2RearAxleGroup !== null) {
            return tempTrailer2RearAxleGroup.getSteering();
        }
        return false;
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaDistance() {
        var tempTrailer1 = activeRig.getTrailer1();

        //Note: under current app beahviour frontMostAxleDownPositionAtFront should only ever be AXLE_1_FRONT and rearMostAxleDownPositionAtFront should only be AXLE_1_FRONT or AXLE_2_FRONT but this could change if we start adding axles on trailers for example
        var frontMostAxleDownPositionAtFront = tempTrailer1.getAxlesHolder().getFrontMostDownAxlePositionAtFront();
        var rearMostAxleDownPositionAtFront = tempTrailer1.getAxlesHolder().getRearMostDownAxlePositionAtFront();

        var groupDistance = 0;


        if (frontMostAxleDownPositionAtFront !== rearMostAxleDownPositionAtFront) {
            switch (frontMostAxleDownPositionAtFront) {
                case config.AXLE_POSITIONS.AXLE_1_FRONT:
                    switch (rearMostAxleDownPositionAtFront) {
                        case config.AXLE_POSITIONS.AXLE_1_FRONT:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_FRONT:
                            groupDistance += tempTrailer1.getAxle1FrontToAxle2Front();
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_FRONT:
                            groupDistance += tempTrailer1.getAxle1FrontToAxle2Front() + tempTrailer1.getAxle2FrontToAxle3Front();
                            break;
                    }
                    break;
                case config.AXLE_POSITIONS.AXLE_2_FRONT:
                    switch (rearMostAxleDownPositionAtFront) {
                        case config.AXLE_POSITIONS.AXLE_1_FRONT:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_FRONT:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_FRONT:
                            groupDistance += tempTrailer1.getAxle2FrontToAxle3Front();
                            break;
                    }

                    break;
                case config.AXLE_POSITIONS.AXLE_3_FRONT:
                    switch (rearMostAxleDownPositionAtFront) {
                        case config.AXLE_POSITIONS.AXLE_1_FRONT:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_FRONT:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_FRONT:
                            //do nothing
                            break;
                    }
                    break;
            }
        }


        return groupDistance;
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaDistance() {
        var tempTrailer1 = activeRig.getTrailer1();
        return getTrailerExtremeRearGroupBridgeFormulaDistance(tempTrailer1); 
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaDistance() {
        var tempTrailer2 = activeRig.getTrailer2();
        return getTrailerExtremeRearGroupBridgeFormulaDistance(tempTrailer2);
    }

    function getTrailerExtremeRearGroupBridgeFormulaDistance(trailer) {
        

        var frontMostAxleDownPositionAtRear = trailer.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        var rearMostAxleDownPositionAtRear = trailer.getAxlesHolder().getRearMostDownAxlePositionAtRear();

        var groupDistance = 0;


        if (frontMostAxleDownPositionAtRear !== rearMostAxleDownPositionAtRear) {
            switch (frontMostAxleDownPositionAtRear) {
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                    switch (rearMostAxleDownPositionAtRear) {
                        case config.AXLE_POSITIONS.AXLE_1_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_REAR:
                            groupDistance += trailer.getAxle1RearToAxle2Rear();
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_REAR:
                            groupDistance += trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear();
                            break;
                        case config.AXLE_POSITIONS.AXLE_4_REAR:
                            groupDistance += trailer.getAxle1RearToAxle2Rear() + trailer.getAxle2RearToAxle3Rear() + trailer.getAxle3RearToAxle4Rear();
                            break;
                    }
                    break;
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                    switch (rearMostAxleDownPositionAtRear) {
                        case config.AXLE_POSITIONS.AXLE_1_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_REAR:
                            groupDistance += trailer.getAxle2RearToAxle3Rear();
                            break;
                        case config.AXLE_POSITIONS.AXLE_4_REAR:
                            groupDistance += trailer.getAxle2RearToAxle3Rear() + trailer.getAxle3RearToAxle4Rear();
                            break;
                    }

                    break;
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                    switch (rearMostAxleDownPositionAtRear) {
                        case config.AXLE_POSITIONS.AXLE_1_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_4_REAR:
                            groupDistance += trailer.getAxle3RearToAxle4Rear();
                            break;
                    }
                    break;
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    //do nothing
                    break;
            }
        }


        return groupDistance;
    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride) {
        var tempTrailer1 = activeRig.getTrailer1();

        var groupPermissibleLegislationCode;
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                //no front axle group, defaulting to prevent crash
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup10Permissible;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //no front axle group, defaulting to prevent crash
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                //no front axle group, defaulting to prevent crash
                return 0;
        }
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (ignoreOverride) {
            if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                return evaluateLegislation(groupPermissibleLegislationCode);
            } else {
                return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFront);
            }
        } else {
            var frontAxleGroup = tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
            if (frontAxleGroup && frontAxleGroup.getBridgeFormulaPermissibleOverride()) {
                return frontAxleGroup.getBridgeFormulaPermissible();
            } else {

                if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                    var numFrontAxles = tempTrailer1.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
                    //if (numFrontAxles === 1) {
                    //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
                    //} else {
                        return evaluateLegislation(groupPermissibleLegislationCode);
                    //}
                } else {
                    return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFront);
                }

            }
        }
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride) {
        var tempTrailer1 = activeRig.getTrailer1();
            
        var groupPermissibleLegislationCode;
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup7Permissible;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup11Permissible;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup7Permissible;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup10Permissible;
                break;
        }
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (ignoreOverride) {
            if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                return evaluateLegislation(groupPermissibleLegislationCode);
            } else {
                return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
            }
        } else {
            var rearAxleGroup = tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if (rearAxleGroup.getBridgeFormulaPermissibleOverride()) {
                return rearAxleGroup.getBridgeFormulaPermissible();
            } else {
                
                if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                    var numRearAxles = tempTrailer1.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);
                    //if (numRearAxles === 1) {
                    //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
                    //} else {
                        return evaluateLegislation(groupPermissibleLegislationCode);
                    //}
                } else {
                    return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
                }

            }
        }
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride) {
        var tempTrailer2 = activeRig.getTrailer2();

        var groupPermissibleLegislationCode;
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup11Permissible
                break;
        }
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (ignoreOverride) {
            if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                return evaluateLegislation(groupPermissibleLegislationCode);
            } else {
                return evaluateLegislation(legislationVals.variableTypes.Trailer2PermissibleRear);
            }
        } else {
            var rearAxleGroup = tempTrailer2.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if (rearAxleGroup.getBridgeFormulaPermissibleOverride()) {
                return rearAxleGroup.getBridgeFormulaPermissible();
            } else {

                if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                    var numRearAxles = tempTrailer2.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);
                    //if (numRearAxles === 1) {
                    //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
                    //} else {
                        return evaluateLegislation(groupPermissibleLegislationCode);
                    //}
                } else {
                    return evaluateLegislation(legislationVals.variableTypes.Trailer2PermissibleRear);
                }

            }
        }

    }

    function getTrailer1ExtremeFrontGroupBridgeFormulaActual() {
        var tempTrailer1 = activeRig.getTrailer1();

        return tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
    }

    function getTrailer1ExtremeRearGroupBridgeFormulaActual() {
        var tempTrailer1 = activeRig.getTrailer1();

        return tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
    }

    function getTrailer2ExtremeRearGroupBridgeFormulaActual() {
        var tempTrailer2 = activeRig.getTrailer2();

        return tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear();
    }


    //function getTrailer1ExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride) {
    //    var frontAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        
    //    if (ignoreOverride) {
    //        return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFront);
    //    } else {
    //        if (frontAxleGroup && frontAxleGroup.getBridgeFormulaPermissibleOverride()) {
    //            return frontAxleGroup.getBridgeFormulaPermissible();
    //        } else {
    //            return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFront);
    //        }
    //    }
    //}

    //function getTrailer1ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride) {
    //    switch (getCombinationType()) {
    //        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
    //            //return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
    //            return getVehicleAndSemiOrInterlinkGroup7BridgeFormulaPermissible(ignoreOverride);
    //        case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
    //            if (ignoreOverride) {
    //                return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
    //            } else {
    //                var rearAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);

    //                if (rearAxleGroup.getBridgeFormulaPermissibleOverride()) {
    //                    return rearAxleGroup.getBridgeFormulaPermissible();
    //                } else {
    //                    return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
    //                }
    //            }
    //        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
    //            return getVehicleAndPupGroup7BridgeFormulaPermissible(ignoreOverride);
    //    }
    //    return 0;
    //}

    

    function getVehicleAndTrailer1ExtremeVehicleFrontTrailer1FrontGroupBridgeFormulaNumberOfAxles() {
        var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var onlyCountDownAxles = true;

        return tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles) +
            tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles) +
            tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
    }

    function getVehicleAndTrailer1ExtremeVehicleFrontTrailer1RearGroupBridgeFormulaNumberOfAxles() {
        var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var onlyCountDownAxles = true;

        var vehicleAxles = tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles) +
            tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        var trailer1Axles = tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailer1Axles += tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        }
        return vehicleAxles + trailer1Axles;
    }

    function getVehicleAndTrailer1ExtremeVehicleRearTrailer1FrontGroupBridgeFormulaNumberOfAxles() {
        var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var onlyCountDownAxles = true;

        return tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles) +
            tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
    }

    function getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaNumberOfAxles() {
        var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var onlyCountDownAxles = true;

        var vehicleAxles = tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        var trailer1Axles = tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailer1Axles += tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        }
        return vehicleAxles + trailer1Axles;
    }

    function getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle(),
        tempTrailer1 = activeRig.getTrailer1();

        var axle1RearStartingPoint = (tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear());
        var downSpan = 0;
        if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
            downSpan = axle1RearStartingPoint - getFifthWheelOffset() + tempTrailer1.getKingpinToAxle1Rear() + tempTrailer1.getAxle1RearToAxle2Rear() + tempTrailer1.getAxle2RearToAxle3Rear() + tempTrailer1.getAxle3RearToAxle4Rear();
        } else if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            //return axle1RearStartingPoint - getFifthWheelOffset() + tempTrailer1.getKingpinToAxle1Rear() + tempTrailer1.getAxle1RearToAxle2Rear() + tempTrailer1.getAxle2RearToAxle3Rear() + tempTrailer1.getAxle3RearToAxle4Rear();
            downSpan = (tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() -
                tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear()) +
                tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getWheelbaseTheoretical() +
                tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
            
            
        } else if(tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            downSpan = (tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear()) +
                tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() +
                tempTrailer1.getWheelbaseTheoretical() + tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
        } else {
            return 0;
        }

        var firstDownAxlePositionAtRear = tempVehicle.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        switch (firstDownAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                // no change
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                downSpan -= tempVehicle.getAxle1RearToAxle2Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                downSpan -= tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                downSpan -= tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                break;
        }
        return downSpan;
    }

    function getTrailer1AndTrailer2ExtremeTrailer1RearTrailer2RearBridgeFormulaNumberOfAxles() {
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var tempTrailer2AxlesHolder = activeRig.getTrailer2().getAxlesHolder();
        var onlyCountDownAxles = true;

        var trailer1Axles = tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        
        var trailer2Axles = tempTrailer2AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (activeRig.getTrailer2().getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailer2Axles += tempTrailer2AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        }
        return trailer1Axles + trailer2Axles;
    }

    function getVehicleAndTrailer1AndTrailer2ExtremeVehicleRearTrailer2RearBridgeFormulaNumberOfAxles() {
        var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var tempTrailer2AxlesHolder = activeRig.getTrailer2().getAxlesHolder();
        var onlyCountDownAxles = true;

        var vehicleAxles = tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        var trailer1Axles = tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailer1Axles += tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        }
        var trailer2Axles = tempTrailer2AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (activeRig.getTrailer2().getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailer2Axles += tempTrailer2AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        }
        return vehicleAxles + trailer1Axles + trailer2Axles;
    }

    function getVehicleAndTrailer1AndTrailer2ExtremeVehicleFrontTrailer2RearBridgeFormulaNumberOfAxles() {
        var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        var tempTrailer2AxlesHolder = activeRig.getTrailer2().getAxlesHolder();
        var onlyCountDownAxles = true;

        var vehicleAxles = tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles) + tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);

        var trailer1Axles = tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailer1Axles += tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        }
        var trailer2Axles = tempTrailer2AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        if (activeRig.getTrailer2().getType() === config.TRAILER_TYPES.DRAWBAR) {
            trailer2Axles += tempTrailer2AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
        }
        return vehicleAxles + trailer1Axles + trailer2Axles;
    }


    function getVehicleExtremeGroupBridgeFormulaNumberOfAxles() {
        var tempVehicle = activeRig.getVehicle();
        var onlyCountDownAxles = true;

        return tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles) + tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
    }

    function getVehicleGroup1BridgeFormulaNumberOfAxles() {

        return getVehicleExtremeGroupBridgeFormulaNumberOfAxles();

    }

    function getVehicleExtremeRearGroupBridgeFormulaNumberOfAxles() {
        var onlyCountDownAxles = true;

        return activeRig.getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
    }

    function getVehicleGroup2BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfAxles() {
        return activeRig.getVehicle().getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
    }

    function getVehicleGroup3BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles() {
        var onlyCountDownAxles = true;

        return activeRig.getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles);
    }

    function getVehicleGroup4BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehiclePusher1GroupBridgeFormulaNumberOfAxles() {
        return 1;
    }

    function getVehiclePusher2GroupBridgeFormulaNumberOfAxles() {
        return 1;
    }

    function getVehiclePusher3GroupBridgeFormulaNumberOfAxles() {
        return 1;
    }

    function getVehicleTag1GroupBridgeFormulaNumberOfAxles() {
        return 1;
    }

    function getVehicleTag2GroupBridgeFormulaNumberOfAxles() {
        return 1;
    }

    function getVehicleTag3GroupBridgeFormulaNumberOfAxles() {
        return 1;
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehicleRearAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        return tempVehicleRearAxleGroup.getNoOfTyresOnAxle();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre() {
        var tempVehicleRearAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        return tempVehicleRearAxleGroup.getTyreWidth();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaLifting() {
        var tempVehicleRearAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        return tempVehicleRearAxleGroup.getLifting();
    }
    
    function getVehicleExtremeRearDrivenGroupBridgeFormulaRaised() {
        var tempVehicleRearAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        return tempVehicleRearAxleGroup.getRaised();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaSteering() {
        var tempVehicleRearAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        return tempVehicleRearAxleGroup.getSteering();
    }



    function getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehicleFrontAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        return tempVehicleFrontAxleGroup.getNoOfTyresOnAxle();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre() {
        var tempVehicleFrontAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        return tempVehicleFrontAxleGroup.getTyreWidth();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaLifting() {
        var tempVehicleFrontAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        return tempVehicleFrontAxleGroup.getLifting();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaRaised() {
        var tempVehicleFrontAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        return tempVehicleFrontAxleGroup.getRaised();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaSteering() {
        var tempVehicleFrontAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        return tempVehicleFrontAxleGroup.getSteering();
    }

    function getVehiclePusher1GroupBridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehiclePusher1 = activeRig.getVehicle().getAxlesHolder().getPusher1();
        if (tempVehiclePusher1 !== null) {
            return tempVehiclePusher1.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getVehiclePusher1GroupBridgeFormulaWidthPerTyre() {
        var tempVehiclePusher1 = activeRig.getVehicle().getAxlesHolder().getPusher1();
        if (tempVehiclePusher1 !== null) {
            return tempVehiclePusher1.getTyreWidth();
        }
        return 0;
    }

    function getVehiclePusher1GroupBridgeFormulaLifting() {
        var tempVehiclePusher1 = activeRig.getVehicle().getAxlesHolder().getPusher1();
        if (tempVehiclePusher1 !== null) {
            return tempVehiclePusher1.getLifting();
        }
        return false;
    }

    function getVehiclePusher1GroupBridgeFormulaRaised() {
        var tempVehiclePusher1 = activeRig.getVehicle().getAxlesHolder().getPusher1();
        if (tempVehiclePusher1 !== null) {
            return tempVehiclePusher1.getRaised();
        }
        return false;
    }

    function getVehiclePusher1GroupBridgeFormulaSteering() {
        var tempVehiclePusher1 = activeRig.getVehicle().getAxlesHolder().getPusher1();
        if (tempVehiclePusher1 !== null) {
            return tempVehiclePusher1.getSteering();
        }
        return false;
    }

    
    function getVehiclePusher2GroupBridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehiclePusher2 = activeRig.getVehicle().getAxlesHolder().getPusher2();
        if (tempVehiclePusher2 !== null) {
            return tempVehiclePusher2.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getVehiclePusher2GroupBridgeFormulaWidthPerTyre() {
        var tempVehiclePusher2 = activeRig.getVehicle().getAxlesHolder().getPusher2();
        if (tempVehiclePusher2 !== null) {
            return tempVehiclePusher2.getTyreWidth();
        }
        return 0;
    }

    function getVehiclePusher2GroupBridgeFormulaLifting() {
        var tempVehiclePusher2 = activeRig.getVehicle().getAxlesHolder().getPusher2();
        if (tempVehiclePusher2 !== null) {
            return tempVehiclePusher2.getLifting();
        }
        return false;
    }

    function getVehiclePusher2GroupBridgeFormulaRaised() {
        var tempVehiclePusher2 = activeRig.getVehicle().getAxlesHolder().getPusher2();
        if (tempVehiclePusher2 !== null) {
            return tempVehiclePusher2.getRaised();
        }
        return false;
    }

    function getVehiclePusher2GroupBridgeFormulaSteering() {
        var tempVehiclePusher2 = activeRig.getVehicle().getAxlesHolder().getPusher2();
        if (tempVehiclePusher2 !== null) {
            return tempVehiclePusher2.getSteering();
        }
        return false;
    }



    function getVehiclePusher3GroupBridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehiclePusher3 = activeRig.getVehicle().getAxlesHolder().getPusher3();
        if (tempVehiclePusher3 !== null) {
            return tempVehiclePusher3.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getVehiclePusher3GroupBridgeFormulaWidthPerTyre() {
        var tempVehiclePusher3 = activeRig.getVehicle().getAxlesHolder().getPusher3();
        if (tempVehiclePusher3 !== null) {
            return tempVehiclePusher3.getTyreWidth();
        }
        return 0;
    }

    function getVehiclePusher3GroupBridgeFormulaLifting() {
        var tempVehiclePusher3 = activeRig.getVehicle().getAxlesHolder().getPusher3();
        if (tempVehiclePusher3 !== null) {
            return tempVehiclePusher3.getLifting();
        }
        return false;
    }

    function getVehiclePusher3GroupBridgeFormulaRaised() {
        var tempVehiclePusher3 = activeRig.getVehicle().getAxlesHolder().getPusher3();
        if (tempVehiclePusher3 !== null) {
            return tempVehiclePusher3.getRaised();
        }
        return false;
    }

    function getVehiclePusher3GroupBridgeFormulaSteering() {
        var tempVehiclePusher3 = activeRig.getVehicle().getAxlesHolder().getPusher3();
        if (tempVehiclePusher3 !== null) {
            return tempVehiclePusher3.getSteering();
        }
        return false;
    }


    function getVehicleTag1GroupBridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehicleTag1 = activeRig.getVehicle().getAxlesHolder().getTag1();
        if (tempVehicleTag1 !== null) {
            return tempVehicleTag1.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getVehicleTag1GroupBridgeFormulaWidthPerTyre() {
        var tempVehicleTag1 = activeRig.getVehicle().getAxlesHolder().getTag1();
        if (tempVehicleTag1 !== null) {
            return tempVehicleTag1.getTyreWidth();
        }
        return 0;
    }

    function getVehicleTag1GroupBridgeFormulaLifting() {
        var tempVehicleTag1 = activeRig.getVehicle().getAxlesHolder().getTag1();
        if (tempVehicleTag1 !== null) {
            return tempVehicleTag1.getLifting();
        }
        return false;
    }

    function getVehicleTag1GroupBridgeFormulaRaised() {
        var tempVehicleTag1 = activeRig.getVehicle().getAxlesHolder().getTag1();
        if (tempVehicleTag1 !== null) {
            return tempVehicleTag1.getRaised();
        }
        return false;
    }

    function getVehicleTag1GroupBridgeFormulaSteering() {
        var tempVehicleTag1 = activeRig.getVehicle().getAxlesHolder().getTag1();
        if (tempVehicleTag1 !== null) {
            return tempVehicleTag1.getSteering();
        }
        return false;
    }


    function getVehicleTag2GroupBridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehicleTag2 = activeRig.getVehicle().getAxlesHolder().getTag2();
        if (tempVehicleTag2 !== null) {
            return tempVehicleTag2.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getVehicleTag2GroupBridgeFormulaWidthPerTyre() {
        var tempVehicleTag2 = activeRig.getVehicle().getAxlesHolder().getTag2();
        if (tempVehicleTag2 !== null) {
            return tempVehicleTag2.getTyreWidth();
        }
        return 0;
    }

    function getVehicleTag2GroupBridgeFormulaLifting() {
        var tempVehicleTag2 = activeRig.getVehicle().getAxlesHolder().getTag2();
        if (tempVehicleTag2 !== null) {
            return tempVehicleTag2.getLifting();
        }
        return false;
    }

    function getVehicleTag2GroupBridgeFormulaRaised() {
        var tempVehicleTag2 = activeRig.getVehicle().getAxlesHolder().getTag2();
        if (tempVehicleTag2 !== null) {
            return tempVehicleTag2.getRaised();
        }
        return false;
    }

    function getVehicleTag2GroupBridgeFormulaSteering() {
        var tempVehicleTag2 = activeRig.getVehicle().getAxlesHolder().getTag2();
        if (tempVehicleTag2 !== null) {
            return tempVehicleTag2.getSteering();
        }
        return false;
    }



    function getVehicleTag3GroupBridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle() {
        var tempVehicleTag3 = activeRig.getVehicle().getAxlesHolder().getTag3();
        if (tempVehicleTag3 !== null) {
            return tempVehicleTag3.getNoOfTyresOnAxle();
        }
        return 0;
    }

    function getVehicleTag3GroupBridgeFormulaWidthPerTyre() {
        var tempVehicleTag3 = activeRig.getVehicle().getAxlesHolder().getTag3();
        if (tempVehicleTag3 !== null) {
            return tempVehicleTag3.getTyreWidth();
        }
        return 0;
    }

    function getVehicleTag3GroupBridgeFormulaLifting() {
        var tempVehicleTag3 = activeRig.getVehicle().getAxlesHolder().getTag3();
        if (tempVehicleTag3 !== null) {
            return tempVehicleTag3.getLifting();
        }
        return false;
    }

    function getVehicleTag3GroupBridgeFormulaRaised() {
        var tempVehicleTag3 = activeRig.getVehicle().getAxlesHolder().getTag3();
        if (tempVehicleTag3 !== null) {
            return tempVehicleTag3.getRaised();
        }
        return false;
    }

    function getVehicleTag3GroupBridgeFormulaSteering() {
        var tempVehicleTag3 = activeRig.getVehicle().getAxlesHolder().getTag3();
        if (tempVehicleTag3 !== null) {
            return tempVehicleTag3.getSteering();
        }
        return false;
    }


    function getVehicleExtremeGroupBridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle();

        var rearMostAxleDownPosition = tempVehicle.getAxlesHolder().getRearMostDownAxlePositionAtRear();

        var group1Distance = tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear();

        switch (rearMostAxleDownPosition) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                //do nothing
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                group1Distance += tempVehicle.getAxle1RearToAxle2Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                group1Distance += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                group1Distance += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                break;
        }

        return group1Distance;
    }

    function getVehicleGroup1BridgeFormulaDistance() {
        return getVehicleExtremeGroupBridgeFormulaDistance();
    }

    function getVehicleExtremeRearGroupBridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle();

        var frontMostAxleDownPosition = tempVehicle.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        var rearMostAxleDownPosition = tempVehicle.getAxlesHolder().getRearMostDownAxlePositionAtRear();

        var group2Distance = 0;


        if (frontMostAxleDownPosition !== rearMostAxleDownPosition) {
            switch (frontMostAxleDownPosition) {
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                    switch (rearMostAxleDownPosition) {
                        case config.AXLE_POSITIONS.AXLE_1_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_REAR:
                            group2Distance += tempVehicle.getAxle1RearToAxle2Rear();
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_REAR:
                            group2Distance += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
                            break;
                        case config.AXLE_POSITIONS.AXLE_4_REAR:
                            group2Distance += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                            break;
                    }
                    break;
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                    switch (rearMostAxleDownPosition) {
                        case config.AXLE_POSITIONS.AXLE_1_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_REAR:
                            group2Distance += tempVehicle.getAxle2RearToAxle3Rear();
                            break;
                        case config.AXLE_POSITIONS.AXLE_4_REAR:
                            group2Distance += tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                            break;
                    }

                    break;
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                    switch (rearMostAxleDownPosition) {
                        case config.AXLE_POSITIONS.AXLE_1_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_2_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_3_REAR:
                            //do nothing
                            break;
                        case config.AXLE_POSITIONS.AXLE_4_REAR:
                            group2Distance += tempVehicle.getAxle3RearToAxle4Rear();
                            break;
                    }
                    break;
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    //do nothing
                    break;
            }
        }


        return group2Distance;


        //var tempVehicle = activeRig.getVehicle();

        //return tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
    }

    function getVehicleGroup2BridgeFormulaDistance() {
        return getVehicleExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle();

        return tempVehicle.getRearDrivenAxleSpan();
    }

    function getVehicleGroup3BridgeFormulaDistance() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistance();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle();
        var axle2Front = tempVehicle.getAxlesHolder().getAxle2Front();
        if (axle2Front !== null && axle2Front.getRaised() === true) {
            return 0;
        } else {
            return tempVehicle.getAxle1FrontToAxle2Front();
        }
    }

    function getVehicleGroup4BridgeFormulaDistance() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistance();
    }

    function getVehiclePusherOrTagGroupBridgeFormulaDistance() {
        return 0;
    }

    function getVehicleGroup5BridgeFormulaDistance() {
        return 0;
    }

    function getVehicleGroup6BridgeFormulaDistance() {
        return 0;
    }

    function getVehicleGroup7BridgeFormulaDistance() {
        return 0;
    }

    function getVehicleGroup8BridgeFormulaDistance() {
        return 0;
    }

    function getVehicleGroup9BridgeFormulaDistance() {
        return 0;
    }

    function getVehicleGroup10BridgeFormulaDistance() {
        return 0;
    }

    function getVehicleExtremeGroupBridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleGroup1BridgeFormulaDistance);
    }

    function getVehicleGroup1BridgeFormulaDistanceNearest() {
        return getVehicleExtremeGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleExtremeRearGroupBridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleGroup2BridgeFormulaDistance);
    }

    function getVehicleGroup2BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleGroup3BridgeFormulaDistance);
    }

    function getVehicleGroup3BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleGroup4BridgeFormulaDistance);
    }

    function getVehicleGroup4BridgeFormulaDistanceNearest() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistanceNearest();
    }

    function getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleGroup5BridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleGroup6BridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleGroup7BridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleGroup8BridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleGroup9BridgeFormulaDistanceNearest() {
        return 0;
    }

    function getVehicleGroup10BridgeFormulaDistanceNearest() {
        return 0;
    }


    
    function getChassisExtremeRearDrivenGroupBridgeFormulaPermissible(chassisObjectType, ignoreOverride) {
        switch (chassisObjectType) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible(ignoreOverride);
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                return getTrailer1ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getTrailer2ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
        }
    }
    
    function getChassisExtremeRearGroupBridgeFormulaPermissible(chassisObjectType, ignoreOverride) {
        switch (chassisObjectType) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return getVehicleExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                return getTrailer1ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getTrailer2ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
        }
    }

    function getChassisExtremeFrontGroupBridgeFormulaPermissible(chassisObjectType, ignoreOverride) {
        switch (chassisObjectType) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return getVehicleExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                return getTrailer1ExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                //return getTrailer2ExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
                return 0;
                
        }
    }

    

    function getCombinationExtremeGroupPermissible(ignoreOverride) {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaPermissible(ignoreOverride);
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaPermissible(ignoreOverride);
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaPermissible(ignoreOverride);
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaPermissible(ignoreOverride);
        }
        return 0;
    }

    function getVehicleRearExtremeGroupPermissible() {
        return getChassisExtremeRearGroupBridgeFormulaPermissible(config.CHASSIS_OBJECT_TYPES.VEHICLE);
    }

    function getCombinationExtremeGroupDistance() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleExtremeGroupBridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaDistance();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance();
        }
        return 0;
    }

    

    function getVehicleTotalBridgeOrRegulationVal(ignoreOverride) {

        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
        } else {
            if (ignoreOverride) {
                return evaluateLegislation('COMVEHPERMISSIBLETOTALSUB');
            } else {
                var tempVehicle = activeRig.getVehicle();
                if (tempVehicle.getVehicleBridgeFormulaPermissibleOverride()) {
                    return tempVehicle.getVehicleBridgeFormulaPermissible();
                }
            }
            return evaluateLegislation('COMVEHPERMISSIBLETOTALSUB');
        }
    }


    function getVehicleTotalBridgeVal() {
        let bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        let bridgeComplianceOnly = evaluateLegislation(legislationVals.variableTypes.BridgeComplianceOnly);
        let bridgeFormulaUsed = getCurrentCombinationBridgeFormulaUsed();
        if(bridgeMethodTwoOrMoreConsecutiveAxles === 0 && bridgeComplianceOnly === 0 && bridgeFormulaUsed === 1) {
            var tempVehicle = activeRig.getVehicle();
            if (tempVehicle.getVehicleBridgeFormulaPermissibleOverride()) {
                return tempVehicle.getVehicleBridgeFormulaPermissible();
            }
            let ignoreOverride = true;
            return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
        }else {
            return getVehicleTotalBridgeOrRegulationVal();
        }
    }
    //function getVehicleAndPupTotalBridgeOrRegulationVal(ignoreOverride) {

    //    var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
    //    if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
    //        return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
    //    } else {
    //        if (ignoreOverride) {
    //            return evaluateLegislation('COMVEHPUPPERMISSIBLETOTALSUB');
    //        } else {
    //            var tempVehicle = activeRig.getVehicle();
    //            if (tempVehicle.getVehicleBridgeFormulaPermissibleOverride()) {
    //                return tempVehicle.getVehicleBridgeFormulaPermissible();
    //            }
    //        }
    //        return evaluateLegislation('COMVEHPUPPERMISSIBLETOTALSUB');
    //    }
    //}

    function getVehicleRearTotalBridgeOrRegulationVal(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);

        var overrideValToUse, bridgeFormulaPermissibleValToUse;
        if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
            overrideValToUse = tempVehicle.getBridgeFormulaRearPermissibleOverride();
            bridgeFormulaPermissibleValToUse = tempVehicle.getBridgeFormulaRearPermissible();
        } else {
            var rearAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            overrideValToUse = rearAxleGroup.getBridgeFormulaPermissibleOverride();
            bridgeFormulaPermissibleValToUse = rearAxleGroup.getBridgeFormulaPermissible();
        }

        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
                return getVehicleExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
            } else {
                if (ignoreOverride) {
                    return getPermissibleLegislationVal();
                } else {
                    if (overrideValToUse) {
                        return bridgeFormulaPermissibleValToUse;
                    }
                    return getPermissibleLegislationVal();
                }
                
                
            }
        } else {
            if (ignoreOverride) {
                return evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);
            } else {
                if (overrideValToUse) {
                    return bridgeFormulaPermissibleValToUse;
                }
            }
            return evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);
        }
        function getPermissibleLegislationVal() {
            var groupPermissibleLegislationCode;
            if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
                switch (getCombinationType()) {
                    case config.COMBINATION_TYPES.VEHICLE:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup2Permissible;
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup4Permissible
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup2Permissible;
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup4Permissible
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup2Permissible;
                        break;
                }
            } else {
                switch (getCombinationType()) {
                    case config.COMBINATION_TYPES.VEHICLE:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup3Permissible;
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup5Permissible
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup3Permissible;
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup5Permissible
                        break;
                    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                        groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup3Permissible;
                        break;
                }
            }
            //var onlyCountDownAxles = true;
            //var numRearAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
            //if (numRearAxles === 1) {
            //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
            //} else {
                return evaluateLegislation(groupPermissibleLegislationCode);
            //}
        }
    }

    function getCombinationTotalBridgeOrRegulationVal(ignoreOverride) { 

        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            return getCombinationExtremeGroupPermissible(ignoreOverride);
        } else {
            if (ignoreOverride) {
                return getCombinationPermissibleTotalSub();
            } else {
                var tempVehicle = activeRig.getVehicle();
                var overrideToCheckFunc, permissibleValFunc;
                if (getCombinationType() === config.COMBINATION_TYPES.VEHICLE) {
                    permissibleValFunc = tempVehicle.getVehicleBridgeFormulaPermissible;
                    overrideToCheckFunc = tempVehicle.getVehicleBridgeFormulaPermissibleOverride;
                } else {
                    permissibleValFunc = tempVehicle.getCombinationBridgeFormulaPermissible;
                    overrideToCheckFunc = tempVehicle.getCombinationBridgeFormulaPermissibleOverride;
                }

                if (overrideToCheckFunc()) {
                    return permissibleValFunc();
                }
            }
            return getCombinationPermissibleTotalSub();
        }
    }

    function getCombinationPermissibleTotalSub() {
        var groupPermissibleLegislationCode;
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPermissibleTotalSub;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiPermissibleTotalSub;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarPermissibleTotalSub;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupPermissibleTotalSub;
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiPermissibleTotalSub;
                break;
        }
        return evaluateLegislation(groupPermissibleLegislationCode);
    }


    function getCombinationTotalBridgeVal() {
        let bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        let bridgeComplianceOnly = evaluateLegislation(legislationVals.variableTypes.BridgeComplianceOnly);
        let bridgeFormulaUsed = getCurrentCombinationBridgeFormulaUsed();
        if(bridgeMethodTwoOrMoreConsecutiveAxles === 0 && bridgeComplianceOnly === 0 && bridgeFormulaUsed === 1) {
            var tempVehicle = activeRig.getVehicle();
            var overrideToCheckFunc, permissibleValFunc;
            if (getCombinationType() === config.COMBINATION_TYPES.VEHICLE) {
                permissibleValFunc = tempVehicle.getVehicleBridgeFormulaPermissible;
                overrideToCheckFunc = tempVehicle.getVehicleBridgeFormulaPermissibleOverride;
            } else {
                permissibleValFunc = tempVehicle.getCombinationBridgeFormulaPermissible;
                overrideToCheckFunc = tempVehicle.getCombinationBridgeFormulaPermissibleOverride;
            }

            if (overrideToCheckFunc()) {
                return permissibleValFunc();
            }
            let ignoreOverride = true;
            return getCombinationExtremeGroupPermissible(ignoreOverride);
        }else {
            return getCombinationTotalBridgeOrRegulationVal();
        }
    }

    //function getVehicleRearTotalBridgeOrRegulationVal(ignoreOverride) {

    //    var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
    //    if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
    //        return getVehicleExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
    //    } else {
    //        if (ignoreOverride) {
    //            return getCombinationTotalPermissible(ignoreOverride);
    //        } else {
    //            var tempVehicle = activeRig.getVehicle();
    //            if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
    //                return tempVehicle.getCombinationBridgeFormulaPermissible();
    //            }
    //        }
    //        return getCombinationTotalPermissible(true);
    //    }
    //}

    function getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        

        if (ignoreOverride) {
            return getPermissibleLegislationVal();
        } else {

            if (tempVehicle.getVehicleBridgeFormulaPermissibleOverride()) {
                return tempVehicle.getVehicleBridgeFormulaPermissible();
            } else {
                return getPermissibleLegislationVal();
            }
        }

        function getPermissibleLegislationVal() {

            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup1Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup1Permissible
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup1Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup1Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup1Permissible;
                    break;
            }

            //var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
            //if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                return evaluateLegislation(groupPermissibleLegislationCode);
            //} else {
            //    return evaluateLegislation('COMVEHPERMISSIBLETOTALSUB');
            //}
        }
    }

    //function getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride) {
    //    var tempVehicle = activeRig.getVehicle();

    //    var groupPermissibleLegislationCode;
    //    switch (getCombinationType()) {
    //        case config.COMBINATION_TYPES.VEHICLE:
    //            groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup1Permissible;
    //            break;
    //        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
    //            groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup1Permissible
    //            break;
    //        case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    //            groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup1Permissible;
    //            break;
    //        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
    //            groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup1Permissible;
    //            break;
    //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
    //            groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup1Permissible;
    //            break;
    //    }

    //    if (ignoreOverride) {
    //        return evaluateLegislation(groupPermissibleLegislationCode);
    //    } else {
    //        if (tempVehicle.getVehicleBridgeFormulaPermissibleOverride()) {
    //            return tempVehicle.getVehicleBridgeFormulaPermissible();
    //        } else {
    //            return evaluateLegislation(groupPermissibleLegislationCode);
    //        }
    //    }
    //}

    function getVehicleGroup1BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleExtremeRearGroupBridgeFormulaPermissible(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        if (ignoreOverride) {
            return getPermissibleLegislationVal();
        } else {
            //var overrideFuncToUse, valueFuncToUse;
            //if (tempVehicle.getAxlesHolder().getHasPusherAxleDown()) {
            //    var rearAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                
            //    overrideFuncToUse = rearAxleGroup.getBridgeFormulaPermissibleOverride;
            //    valueFuncToUse = rearAxleGroup.getBridgeFormulaPermissible;
            //}else {
            //    overrideFuncToUse = tempVehicle.getBridgeFormulaRearPermissibleOverride;
            //    valueFuncToUse = tempVehicle.getBridgeFormulaRearPermissible;
            //}
            if (tempVehicle.getBridgeFormulaRearPermissibleOverride()) {
                return tempVehicle.getBridgeFormulaRearPermissible();
            } else {
                return getPermissibleLegislationVal();
            }
        }

        function getPermissibleLegislationVal() {

            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup2Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup4Permissible
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup2Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup4Permissible
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup2Permissible;
                    break;
            }
            //return evaluateLegislation(groupPermissibleLegislationCode);
            var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
            if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                var numRearAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);
                //if (numRearAxles === 1) {
                //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
                //} else {
                    return evaluateLegislation(groupPermissibleLegislationCode);
                //}
            } else {
                return evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);
            }
        }
    }

    function getVehicleGroup2BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.ComVehGroup2Permissible);
        return getVehicleExtremeRearGroupBridgeFormulaPermissible(ignoreOverride)
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible(ignoreOverride) {

        

        var tempVehicle = activeRig.getVehicle();

        if (ignoreOverride) {
            return getPermissibleLegislationVal();
        } else {
            var rearAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if (rearAxleGroup.getBridgeFormulaPermissibleOverride()) {
                return rearAxleGroup.getBridgeFormulaPermissible();
            } else {
                return getPermissibleLegislationVal();
            }
        }

        function getPermissibleLegislationVal() {

            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup3Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup5Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    //groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup5Permissible
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup8Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup5Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup8Permissible;
                    break;
            }

            var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
            if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                var numRearAxles = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                //if (numRearAxles === 1) {
                //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
                //} else {
                    return evaluateLegislation(groupPermissibleLegislationCode);
                //}
            } else {
                if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
                    return evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearRearOnly);
                } else {
                    return evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);
                }
            }
        }
    }

    function getVehicleGroup3BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride) {

        var tempVehicle = activeRig.getVehicle();

        if (ignoreOverride) {
            return getPermissibleLegislationVal();
        } else {
            var frontAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
            if (frontAxleGroup.getBridgeFormulaPermissibleOverride()) {
                return frontAxleGroup.getBridgeFormulaPermissible();
            } else {
                return getPermissibleLegislationVal();
            }
        }

        function getPermissibleLegislationVal() {

            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup4Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup6Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    //groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup5Permissible
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup9Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup6Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup9Permissible;
                    break;
            }

            var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
            if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
                var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
                //if (numFrontAxles === 1) {
                //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
                //} else {
                return evaluateLegislation(groupPermissibleLegislationCode);
                //}
            } else {
                return evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
            }
        }
    }

    function getVehicleGroup4BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function mapMinusAxleToPusherGroupBridgeFormulaPermissible(minusAxlePosition) {
        let tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        let numMinusAxles = tempVehicleAxlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

        switch(minusAxlePosition) {
            case 1:
                switch(numMinusAxles) {
                    case 1:
                        return getVehiclePusher1GroupBridgeFormulaPermissible();
                    case 2:
                        return  getVehiclePusher2GroupBridgeFormulaPermissible();
                    case 3:
                        return  getVehiclePusher3GroupBridgeFormulaPermissible();
                }
                break; 
            case 2:
                switch(numMinusAxles) {
                    case 2:
                        return getVehiclePusher1GroupBridgeFormulaPermissible();
                    case 3:
                        return getVehiclePusher2GroupBridgeFormulaPermissible();
                }
                break; 
            case 3:
                switch(numMinusAxles) {
                    case 3:
                        return getVehiclePusher1GroupBridgeFormulaPermissible();
                }
                break;
                 
        }

        return 0;
    }

    function getVehicleMinus1GroupBridgeFormulaPermissible() {
        return mapMinusAxleToPusherGroupBridgeFormulaPermissible(1);
    }

    function getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverride) {
        if (ignoreOverride) {
            return getVehiclePusher1LegislationPermissibleVal();
        } else {
            var pusherAxle = activeRig.getVehicle().getAxlesHolder().getAxle1Rear();

            if (pusherAxle.getBridgeFormulaPermissibleOverride()) {
                return pusherAxle.getBridgeFormulaPermissible();
            } else {
                return getVehiclePusher1LegislationPermissibleVal();
            }
        }
    }

    function getVehicleGroup5BridgeFormulaPermissible(ignoreOverride) {
        return getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverride);
        
    }

    function getVehicleMinus2GroupBridgeFormulaPermissible() {
        return mapMinusAxleToPusherGroupBridgeFormulaPermissible(2);
    }

    function getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverride) {
        if (ignoreOverride) {
            return getVehiclePusher2LegislationPermissibleVal();
        } else {
            var pusherAxle = activeRig.getVehicle().getAxlesHolder().getAxle2Rear();

            if (pusherAxle.getBridgeFormulaPermissibleOverride()) {
                return pusherAxle.getBridgeFormulaPermissible();
            } else {
                return getVehiclePusher2LegislationPermissibleVal();
            }
        }
    }

    function getVehicleGroup6BridgeFormulaPermissible(ignoreOverride) {
        return getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleMinus3GroupBridgeFormulaPermissible() {
        return mapMinusAxleToPusherGroupBridgeFormulaPermissible(3);
    }

    function getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverride) {
        if (ignoreOverride) {
            return getVehiclePusher3LegislationPermissibleVal();
        } else {
            var pusherAxle = activeRig.getVehicle().getAxlesHolder().getAxle3Rear();

            if (pusherAxle.getBridgeFormulaPermissibleOverride()) {
                return pusherAxle.getBridgeFormulaPermissible();
            } else {
                return getVehiclePusher3LegislationPermissibleVal();
            }
        }
        
    }

    function getVehicleGroup7BridgeFormulaPermissible(ignoreOverride) {
        return getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverride)
    }

    function getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverride) {

        if (ignoreOverride) {
            return getVehicleTag1LegislationPermissibleVal();
        } else {
            var tempVehicle = activeRig.getVehicle();
            //var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
            //var tagAxle = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);

            var tagAxle = null;
            if (tempVehicle.getAxlesHolder().getHasTagAxleDown()) {
                var numAxlesBeforeTags = tempVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags();
                tagAxle = tempVehicle.getAxlesHolder().getAxleByNumber(numAxlesBeforeTags + 1);
            }
            if (tagAxle !== null && tagAxle.getBridgeFormulaPermissibleOverride()) {
                return tagAxle.getBridgeFormulaPermissible();
            } else {
                return getVehicleTag1LegislationPermissibleVal();
            }
        }
    }

    function getVehicleGroup8BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverride) {
        if (ignoreOverride) {
            return getVehicleTag2LegislationPermissibleVal();
        } else {
            var tempVehicle = activeRig.getVehicle();

            var tagAxle = null;
            if (tempVehicle.getAxlesHolder().getHasTagAxleDown()) {
                var numAxlesBeforeTags = tempVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags();
                tagAxle = tempVehicle.getAxlesHolder().getAxleByNumber(numAxlesBeforeTags + 2);
            }
            if (tagAxle !== null && tagAxle.getBridgeFormulaPermissibleOverride()) {
                return tagAxle.getBridgeFormulaPermissible();
            } else {
                return getVehicleTag2LegislationPermissibleVal();
            }
        }
    }

    function getVehicleGroup9BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverride) {
        if (ignoreOverride) {
            return getVehicleTag3LegislationPermissibleVal();
        } else {
            var tempVehicle = activeRig.getVehicle();

            var tagAxle = null;
            if (tempVehicle.getAxlesHolder().getHasTagAxleDown()) {
                var numAxlesBeforeTags = tempVehicle.getAxlesHolder().getNumAxlesOnVehicleBeforeTags();
                tagAxle = tempVehicle.getAxlesHolder().getAxleByNumber(numAxlesBeforeTags + 3);
            }
            if (tagAxle !== null && tagAxle.getBridgeFormulaPermissibleOverride()) {
                return tagAxle.getBridgeFormulaPermissible();
            } else {
                return getVehicleTag3LegislationPermissibleVal();
            }
        }
        
    }

    function getVehiclePusherOrTagPermissibleLegislationVal() {
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        } else {
            return evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);
        }
    }

    function getVehiclePusher1LegislationPermissibleVal() {
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup5Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup8Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup12Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup8Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup12Permissible;
                    break;
            }
            return evaluateLegislation(groupPermissibleLegislationCode);
        } else {
            return evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearPusher1);
        }
    }

    function getVehiclePusher2LegislationPermissibleVal() {
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup6Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup9Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup13Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup9Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup13Permissible;
                    break;
            }
            return evaluateLegislation(groupPermissibleLegislationCode);
        } else {
            return evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearPusher2);
        }
    }

    function getVehiclePusher3LegislationPermissibleVal() {
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            /*return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);*/
            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup7Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup10Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup14Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup10Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup14Permissible;
                    break;
            }
            return evaluateLegislation(groupPermissibleLegislationCode);
        } else {
            return evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearPusher3);
        }
    }

    function getVehicleTag1LegislationPermissibleVal() {
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            /*return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);*/
            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup8Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup11Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup15Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup11Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup15Permissible;
                    break;
            }
            return evaluateLegislation(groupPermissibleLegislationCode);
        } else {
            return evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearTag1);
        }
    }

    function getVehicleTag2LegislationPermissibleVal() {
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup9Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup12Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup16Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup12Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup16Permissible;
                    break;
            }
            return evaluateLegislation(groupPermissibleLegislationCode);
        } else {
            return evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearTag2);
        }
    }

    function getVehicleTag3LegislationPermissibleVal() {
        var bridgeMethodTwoOrMoreConsecutiveAxles = evaluateLegislation(legislationVals.variableTypes.BridgeMethodTwoOrMoreConsecutiveAxles);
        if (bridgeMethodTwoOrMoreConsecutiveAxles === 1) {
            //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
            var groupPermissibleLegislationCode;
            switch (getCombinationType()) {
                case config.COMBINATION_TYPES.VEHICLE:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehGroup10Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehSemiGroup13Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehDrawbarGroup17Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehPupGroup13Permissible;
                    break;
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    groupPermissibleLegislationCode = legislationVals.variableTypes.ComVehIntSemiGroup17Permissible;
                    break;
            }
            return evaluateLegislation(groupPermissibleLegislationCode);
        } else {
            return evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearTag3);
        }
    }

    function getVehicleGroup10BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleExtremeGroupBridgeFormulaActual() {
        return getCombinationVehicleFrontGross() + getCombinationVehicleRearGross();
    }

    function getVehicleGroup1BridgeFormulaActual() {
        return getVehicleExtremeGroupBridgeFormulaActual();
    }

    function getVehicleExtremeRearGroupBridgeFormulaActual() {
        return getCombinationVehicleRearGross();
    }

    function getVehicleGroup2BridgeFormulaActual() {
        return getVehicleExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle();

        return tempVehicle.getAxlesHolder().getRearRearAxlesDistribution(getCombinationVehicleRearGross());
    }

    function getVehicleGroup3BridgeFormulaActual() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaActual();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaActual() {
        return getCombinationVehicleFrontGross();
    }

    function getVehicleGroup4BridgeFormulaActual() {
        return getVehicleExtremeFrontGroupBridgeFormulaActual();
    }

    function getVehiclePusher1GroupBridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle();
        var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

        return tempVehicle.getPusherOrTagRearAxleGross(numFrontAxles + 1, getCombinationVehicleRearGross());
    }

    function getVehicleGroup5BridgeFormulaActual() {
        return getVehiclePusher1GroupBridgeFormulaActual();
    }

    function getVehiclePusher2GroupBridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle();
        var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

        return tempVehicle.getPusherOrTagRearAxleGross(numFrontAxles + 2, getCombinationVehicleRearGross());
    }

    function getVehicleGroup6BridgeFormulaActual() {
        return getVehiclePusher2GroupBridgeFormulaActual();
    }

    function getVehiclePusher3GroupBridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle();
        var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);

        return tempVehicle.getPusherOrTagRearAxleGross(numFrontAxles + 3, getCombinationVehicleRearGross());
    }

    function getVehicleGroup7BridgeFormulaActual() {
        return getVehiclePusher3GroupBridgeFormulaActual();
    }

    function getVehicleTag1GroupBridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle();
        var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        var numRearAxlesBeforeTags = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        numRearAxlesBeforeTags += tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);

        return tempVehicle.getPusherOrTagRearAxleGross(numFrontAxles + numRearAxlesBeforeTags + 1, getCombinationVehicleRearGross());
    }

    function getVehicleGroup8BridgeFormulaActual() {
        return getVehicleTag1GroupBridgeFormulaActual();
    }

    function getVehicleTag2GroupBridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle();
        var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        var numRearAxlesBeforeTags = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        numRearAxlesBeforeTags += tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);

        return tempVehicle.getPusherOrTagRearAxleGross(numFrontAxles + numRearAxlesBeforeTags + 2, getCombinationVehicleRearGross());
    }

    function getVehicleGroup9BridgeFormulaActual() {
        return getVehicleTag2GroupBridgeFormulaActual();
    }

    function getVehicleTag3GroupBridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle();
        var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        var numRearAxlesBeforeTags = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        numRearAxlesBeforeTags += tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);

        return tempVehicle.getPusherOrTagRearAxleGross(numFrontAxles + numRearAxlesBeforeTags + 3, getCombinationVehicleRearGross());
    }

    function getVehicleGroup10BridgeFormulaActual() {
        return getVehicleTag3GroupBridgeFormulaActual();
        
    }

    function getVehicleGroup1BridgeFormulaOverload() {

        var permissible = getVehicleGroup1BridgeFormulaPermissible();

        if (permissible > 0) {
            return (getVehicleGroup1BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }

        /*
            Dim myPermissible As Double = VehicleGroup1BridgeFormulaPermissible

        If myPermissible <> 0 Then
            Return (VehicleGroup1BridgeFormulaActual - myPermissible) / myPermissible * 100
        Else
            Return 0
        End If
        */
    }

    function getVehicleExtremeRearGroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup2BridgeFormulaPermissible, getVehicleGroup2BridgeFormulaActual);
    }

    function getVehicleGroup2BridgeFormulaOverload() {
        return getVehicleExtremeRearGroupBridgeFormulaOverload();
    }

    function getVehicleExtremeRearDrivenGroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup3BridgeFormulaPermissible, getVehicleGroup3BridgeFormulaActual);
    }

    function getVehicleGroup3BridgeFormulaOverload() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaOverload();
    }

    function getVehicleExtremeFrontGroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup4BridgeFormulaPermissible, getVehicleGroup4BridgeFormulaActual);
    }

    function getVehicleGroup4BridgeFormulaOverload() {
        return getVehicleExtremeFrontGroupBridgeFormulaOverload();
    }

    function getVehiclePusher1GroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup5BridgeFormulaPermissible, getVehicleGroup5BridgeFormulaActual);
    }

    function getVehicleGroup5BridgeFormulaOverload() {
        return getVehiclePusher1GroupBridgeFormulaOverload();
    }

    function getVehiclePusher2GroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup6BridgeFormulaPermissible, getVehicleGroup6BridgeFormulaActual);
    }

    function getVehicleGroup6BridgeFormulaOverload() {
        return getVehiclePusher2GroupBridgeFormulaOverload();
    }

    function getVehiclePusher3GroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup7BridgeFormulaPermissible, getVehicleGroup7BridgeFormulaActual);
    }

    function getVehicleGroup7BridgeFormulaOverload() {
        return getVehiclePusher3GroupBridgeFormulaOverload();
    }

    function getVehicleTag1GroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup8BridgeFormulaPermissible, getVehicleGroup8BridgeFormulaActual);
    }

    function getVehicleGroup8BridgeFormulaOverload() {
        return getVehicleTag1GroupBridgeFormulaOverload();
    }

    function getVehicleTag2GroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup9BridgeFormulaPermissible, getVehicleGroup9BridgeFormulaActual);
    }

    function getVehicleGroup9BridgeFormulaOverload() {
        return getVehicleTag2GroupBridgeFormulaOverload();
    }

    function getVehicleTag3GroupBridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleGroup10BridgeFormulaPermissible, getVehicleGroup10BridgeFormulaActual);
    }

    function getVehicleGroup10BridgeFormulaOverload() {
        return getVehicleTag3GroupBridgeFormulaOverload();
    }
    
    function getVehicleAndDrawbarGroup1BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup2BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleFrontTrailer1FrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup3BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleFrontTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup4BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1FrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup5BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup6BridgeFormulaNumberOfAxles() {
        return getTrailer1ExtremeGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup7BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup8BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup9BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup10BridgeFormulaNumberOfAxles() {
        return getTrailer1ExtremeFrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup11BridgeFormulaNumberOfAxles() {
        return getTrailer1ExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup12BridgeFormulaNumberOfAxles() {
        return getVehiclePusher1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup13BridgeFormulaNumberOfAxles() {
        return getVehiclePusher2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup14BridgeFormulaNumberOfAxles() {
        return getVehiclePusher3GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup15BridgeFormulaNumberOfAxles() {
        return getVehicleTag1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup16BridgeFormulaNumberOfAxles() {
        return getVehicleTag2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup17BridgeFormulaNumberOfAxles() {
        return getVehicleTag3GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndDrawbarGroup2BridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() +
                tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength() +
                tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToAxle2Front() + tempTrailer1.getAxle2FrontToAxle3Front();

    }

    function getVehicleAndDrawbarGroup1BridgeFormulaDistanceNearest() {

        return getVehicleGroup1BridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup2BridgeFormulaDistanceNearest() {

        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndDrawbarGroup2BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndDrawbarGroup2BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndDrawbarGroup2BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndDrawbarGroup2BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndDrawbarGroup2BridgeFormulaDistance);
    }

    function getVehicleAndDrawbarGroup1BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGroup1Permissible);
        return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup2BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGroup2Permissible);
    }

    function getVehicleAndDrawbarGroup3BridgeFormulaPermissible(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        if (ignoreOverride) {
            return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGroup3Permissible);
        } else {
            if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
                return tempVehicle.getCombinationBridgeFormulaPermissible();
            } else {
                return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGroup3Permissible);
            }
        }
    }

    function getVehicleAndDrawbarGroup4BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGroup4Permissible);
    }

    function getVehicleAndDrawbarGroup5BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGroup5Permissible);
    }

    function getVehicleAndDrawbarGroup6BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarGroup6Permissible);
    }

    function getVehicleAndDrawbarGroup7BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup8BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup9BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup10BridgeFormulaPermissible(ignoreOverride) {
        return getTrailer1ExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup11BridgeFormulaPermissible(ignoreOverride) {
        return getTrailer1ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup12BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup13BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup14BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup15BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup16BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup17BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndDrawbarGroup2BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
    }

    function getVehicleAndDrawbarGroup3BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
    }

    function getVehicleAndDrawbarGroup4BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        
        return getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
    }

    function getVehicleAndDrawbarGroup5BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
    }

    function getVehicleAndDrawbarGroup6BridgeFormulaActual() {
        var tempTrailer1 = activeRig.getTrailer1();

        return tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
    }

    function getVehicleAndDrawbarGroup7BridgeFormulaActual() {
        return getVehicleExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup8BridgeFormulaActual() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup9BridgeFormulaActual() {
        return getVehicleExtremeFrontGroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup10BridgeFormulaActual() {
        return getTrailer1ExtremeFrontGroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup11BridgeFormulaActual() {
        return getTrailer1ExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup12BridgeFormulaActual() {
        return getVehiclePusher1GroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup13BridgeFormulaActual() {
        return getVehiclePusher2GroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup14BridgeFormulaActual() {
        return getVehiclePusher3GroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup15BridgeFormulaActual() {
        return getVehicleTag1GroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup16BridgeFormulaActual() {
        return getVehicleTag2GroupBridgeFormulaActual();
    }

    function getVehicleAndDrawbarGroup17BridgeFormulaActual() {
        return getVehicleTag3GroupBridgeFormulaActual();
    }


    function getVehicleAndDrawbarGroup2BridgeFormulaOverload() {
        var permissible = getVehicleAndDrawbarGroup2BridgeFormulaPermissible();

        if(permissible !== 0) {
            return (getVehicleAndDrawbarGroup2BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndDrawbarGroup3BridgeFormulaOverload() {
        var permissible = getVehicleAndDrawbarGroup3BridgeFormulaPermissible();

        if(permissible !== 0) {
            return (getVehicleAndDrawbarGroup3BridgeFormulaActual() - permissible) / permissible * 100;
        }else {
            return 0;
        }
    }

    function getVehicleAndDrawbarGroup4BridgeFormulaOverload() {
        var permissible = getVehicleAndDrawbarGroup4BridgeFormulaPermissible();

        if(permissible !== 0) {
            return (getVehicleAndDrawbarGroup4BridgeFormulaActual() - permissible) / permissible * 100;
        }else {
            return 0;
        }
    }

    function getVehicleAndDrawbarGroup5BridgeFormulaOverload() {
        var permissible = getVehicleAndDrawbarGroup5BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndDrawbarGroup5BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndDrawbarGroup6BridgeFormulaOverload() {
        var permissible = getVehicleAndDrawbarGroup6BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndDrawbarGroup6BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndDrawbarGroup7BridgeFormulaOverload() {
        //return getVehicleExtremeRearGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup7BridgeFormulaPermissible, getVehicleAndDrawbarGroup7BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup8BridgeFormulaOverload() {
        //return getVehicleExtremeRearDrivenGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup8BridgeFormulaPermissible, getVehicleAndDrawbarGroup8BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup9BridgeFormulaOverload() {
        //return getVehicleExtremeFrontGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup9BridgeFormulaPermissible, getVehicleAndDrawbarGroup9BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup10BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup10BridgeFormulaPermissible, getVehicleAndDrawbarGroup10BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup11BridgeFormulaOverload() {
        //return getVehiclePusher1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup11BridgeFormulaPermissible, getVehicleAndDrawbarGroup11BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup12BridgeFormulaOverload() {
        //return getVehiclePusher2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup12BridgeFormulaPermissible, getVehicleAndDrawbarGroup12BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup13BridgeFormulaOverload() {
        //return getVehiclePusher3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup13BridgeFormulaPermissible, getVehicleAndDrawbarGroup13BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup14BridgeFormulaOverload() {
        //return getVehicleTag1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup14BridgeFormulaPermissible, getVehicleAndDrawbarGroup14BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup15BridgeFormulaOverload() {
        //return getVehicleTag2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup15BridgeFormulaPermissible, getVehicleAndDrawbarGroup15BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup16BridgeFormulaOverload() {
        //return getVehicleTag3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup16BridgeFormulaPermissible, getVehicleAndDrawbarGroup16BridgeFormulaActual);
    }

    function getVehicleAndDrawbarGroup17BridgeFormulaOverload() {
        //return getVehicleTag3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndDrawbarGroup17BridgeFormulaPermissible, getVehicleAndDrawbarGroup17BridgeFormulaActual);
    }

    function getVehicleAndPupGroup1BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup2BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleFrontTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup3BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup4BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup5BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup6BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup7BridgeFormulaNumberOfAxles() {
        return getTrailer1ExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup8BridgeFormulaNumberOfAxles() {
        return getVehiclePusher1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup9BridgeFormulaNumberOfAxles() {
        return getVehiclePusher2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup10BridgeFormulaNumberOfAxles() {
        return getVehiclePusher3GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup11BridgeFormulaNumberOfAxles() {
        return getVehicleTag1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup12BridgeFormulaNumberOfAxles() {
        return getVehicleTag2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup13BridgeFormulaNumberOfAxles() {
        return getVehicleTag3GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndPupGroup1BridgeFormulaDistance() {
        //var tempVehicle = activeRig.getVehicle();

        //return tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() +
        //       tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
        return getVehicleExtremeGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup1BridgeFormulaDistanceNearest() {
        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndPupGroup1BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndPupGroup1BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndPupGroup1BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndPupGroup1BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndPupGroup1BridgeFormulaDistance);
    }

    function getVehicleAndPupGroup2BridgeFormulaDistance() {

        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() +
                tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getWheelbaseTheoretical() +
                tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();

        /*
        Return _Vehicle.Axle1FrontToWheelbaseTheoreticalStart + _Vehicle.WheelbaseTheoretical +
                _Vehicle.Accessories.Hitch.HorizontalGap + _Trailer1.Item(0).WheelbaseTheoretical +
                _Trailer1.Item(0).WheelbaseTheoreticalEndToRearMostAxle
        */
    }

    function getVehicleAndPupGroup2BridgeFormulaDistanceNearest() {

        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndPupGroup2BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndPupGroup2BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndPupGroup2BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndPupGroup2BridgeFormulaDistance();
        //}

        return getBridgeFormulaDistanceNearest(getVehicleAndPupGroup2BridgeFormulaDistance);
    }

    function getVehicleAndPupGroup3BridgeFormulaDistance() {
        //var tempVehicle = activeRig.getVehicle(),
        //    tempTrailer1 = activeRig.getTrailer1();

        //return (tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() -
        //        tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear()) +
        //        tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getWheelbaseTheoretical() +
        //        tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup3BridgeFormulaDistanceNearest() {
    //    if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
    //        if (getVehicleAndPupGroup3BridgeFormulaDistance() % 100 === 0) {
    //            return getVehicleAndPupGroup3BridgeFormulaDistance();
    //        } else {
    //            return (Math.floor(getVehicleAndPupGroup3BridgeFormulaDistance() / 100) + 1) * 100;
    //}
    //} else {
    //        return getVehicleAndPupGroup3BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndPupGroup3BridgeFormulaDistance);
    }

    function getVehicleAndPupGroup4BridgeFormulaDistance() { 
        return getVehicleExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup4BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup5BridgeFormulaDistance() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup5BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup6BridgeFormulaDistance() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup6BridgeFormulaDistanceNearest() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup7BridgeFormulaDistance() {
        return getTrailer1ExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup7BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndPupGroup7BridgeFormulaDistance);
    }

    function getVehicleAndPupGroup8BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup8BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup9BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup9BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup10BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup10BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup11BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup11BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup12BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup12BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndPupGroup13BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndPupGroup13BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }


    function getVehicleAndPupGroup1BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup1Permissible);
        return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndPupGroup2BridgeFormulaPermissible(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        if (ignoreOverride) {
            return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup2Permissible);
        } else {
            if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
                return tempVehicle.getCombinationBridgeFormulaPermissible();
            } else {
                return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup2Permissible);
            }
        }
    }

    function getVehicleAndPupGroup3BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup3Permissible);
    }

    function getVehicleAndPupGroup4BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup4Permissible);
    }

    function getVehicleAndPupGroup5BridgeFormulaPermissible(ignoreOverride) {
        //var tempVehicle = activeRig.getVehicle();

        //var rearAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        //if (rearAxleGroup.getBridgeFormulaPermissibleOverride()) {
        //    return rearAxleGroup.getBridgeFormulaPermissible();
        //} else {
        //    var numRearAxles = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        //    if (numRearAxles === 1) {
        //        return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        //    } else {
        //        return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup5Permissible);
        //    }
        //}
        return getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible(ignoreOverride)
    }

    function getVehicleAndPupGroup6BridgeFormulaPermissible(ignoreOverride) {
        //var tempVehicle = activeRig.getVehicle();

        //var frontAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        //if (frontAxleGroup.getBridgeFormulaPermissibleOverride()) {
        //    return frontAxleGroup.getBridgeFormulaPermissible();
        //} else {
        //    var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        //    if (numFrontAxles === 1) {
        //        return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        //    } else {
        //        return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup6Permissible);
        //    }
        //}
        return getVehicleExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndPupGroup7BridgeFormulaPermissible() {
        //var tempTrailer1 = activeRig.getTrailer1();

        //var rearAxleGroup = tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        //if (rearAxleGroup.getBridgeFormulaPermissibleOverride()) {
        //    return rearAxleGroup.getBridgeFormulaPermissible();
        //} else {
        //    return evaluateLegislation(legislationVals.variableTypes.ComVehPupGroup7Permissible);
        //}
        return getTrailer1ExtremeRearGroupBridgeFormulaPermissible();
    }

    function getVehicleAndPupGroup8BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndPupGroup9BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndPupGroup10BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndPupGroup11BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndPupGroup12BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndPupGroup13BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverride);
    }


    function getVehicleAndPupGroup1BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();
        
        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
    }

    function getVehicleAndPupGroup2BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();
        
        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
    }

    function getVehicleAndPupGroup3BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();
        
        return getVehicleOnlyRearGross() + getVehicleAndPupTareTransferOntoVehicleRearAxle() + getVehicleAndPupPayloadTransferOntoVehicleRearAxle() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
    }

    function getVehicleAndPupGroup4BridgeFormulaActual() {
        return getVehicleExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup5BridgeFormulaActual() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup6BridgeFormulaActual() {
        return getVehicleExtremeFrontGroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup7BridgeFormulaActual() {
        //var tempTrailer1 = activeRig.getTrailer1();

        //return tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();

        return getTrailer1ExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup8BridgeFormulaActual() {
        return getVehiclePusher1GroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup9BridgeFormulaActual() {
        return getVehiclePusher2GroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup10BridgeFormulaActual() {
        return getVehiclePusher3GroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup11BridgeFormulaActual() {
        return getVehicleTag1GroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup12BridgeFormulaActual() {
        return getVehicleTag2GroupBridgeFormulaActual();
    }

    function getVehicleAndPupGroup13BridgeFormulaActual() {
        return getVehicleTag3GroupBridgeFormulaActual();
    }


    function getVehicleAndPupGroup1BridgeFormulaOverload() {
        var permissible = getVehicleAndPupGroup1BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndPupGroup1BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndPupGroup2BridgeFormulaOverload() {
        var permissible = getVehicleAndPupGroup2BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndPupGroup2BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndPupGroup3BridgeFormulaOverload() {
        var permissible = getVehicleAndPupGroup3BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndPupGroup3BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndPupGroup4BridgeFormulaOverload() {
        //return getVehicleExtremeRearGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup4BridgeFormulaPermissible, getVehicleAndPupGroup4BridgeFormulaActual);
    }

    function getVehicleAndPupGroup5BridgeFormulaOverload() {
        //return getVehicleExtremeRearDrivenGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup5BridgeFormulaPermissible, getVehicleAndPupGroup5BridgeFormulaActual);
    }

    function getVehicleAndPupGroup6BridgeFormulaOverload() {
        //return getVehicleExtremeFrontGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup6BridgeFormulaPermissible, getVehicleAndPupGroup6BridgeFormulaActual);
    }

    function getVehicleAndPupGroup7BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndPupGroup7BridgeFormulaPermissible, getVehicleAndPupGroup7BridgeFormulaActual);
    }

    function getVehicleAndPupGroup8BridgeFormulaOverload() {
        //return getVehiclePusher1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup8BridgeFormulaPermissible, getVehicleAndPupGroup8BridgeFormulaActual);
    }

    function getVehicleAndPupGroup9BridgeFormulaOverload() {
        //return getVehiclePusher2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup9BridgeFormulaPermissible, getVehicleAndPupGroup9BridgeFormulaActual);
    }

    function getVehicleAndPupGroup10BridgeFormulaOverload() {
        //return getVehiclePusher3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup10BridgeFormulaPermissible, getVehicleAndPupGroup10BridgeFormulaActual);
    }

    function getVehicleAndPupGroup11BridgeFormulaOverload() {
        //return getVehicleTag1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup11BridgeFormulaPermissible, getVehicleAndPupGroup11BridgeFormulaActual);
    }

    function getVehicleAndPupGroup12BridgeFormulaOverload() {
        //return getVehicleTag2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup12BridgeFormulaPermissible, getVehicleAndPupGroup12BridgeFormulaActual);
    }

    function getVehicleAndPupGroup13BridgeFormulaOverload() {
        //return getVehicleTag3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndPupGroup13BridgeFormulaPermissible, getVehicleAndPupGroup13BridgeFormulaActual);
    }


    function getVehicleCrewFirstRowHorizontalCOG() {
        if (activeRig.getVehicle().getCrewFirstRowHorizontalCOGOverride() === true) {
            return activeRig.getVehicle().getCrewFirstRowCOG();
        } else {
            return originalRig.getVehicle().getCrewFirstRowCOG();
        }
    }

    function getVehicleCrewFirstRowVerticalCOG() {
        if (activeRig.getVehicle().getCrewFirstRowVerticalCOGOverride() === true) {
            return activeRig.getVehicle().getCrewFirstRowVerticalCOG();
        } else {
            return originalRig.getVehicle().getCrewFirstRowVerticalCOG();
        }
    }

    function getVehicleCrewFirstRowLateralCOG() {
        if (activeRig.getVehicle().getCrewFirstRowLateralCOGOverride() === true) {
            return activeRig.getVehicle().getCrewFirstRowLateralCOG();
        } else {
            return originalRig.getVehicle().getCrewFirstRowLateralCOG();
        }
    }

    function getVehicleCrewSecondRowHorizontalCOG() {
        if (activeRig.getVehicle().getCrewSecondRowHorizontalCOGOverride() === true) {
            return activeRig.getVehicle().getCrewSecondRowCOG();
        } else {
            return originalRig.getVehicle().getCrewSecondRowCOG();
        }
    }

    function getVehicleCrewSecondRowVerticalCOG() {
        if (activeRig.getVehicle().getCrewSecondRowVerticalCOGOverride() === true) {
            return activeRig.getVehicle().getCrewSecondRowVerticalCOG();
        } else {
            return originalRig.getVehicle().getCrewSecondRowVerticalCOG();
        }
    }

    function getVehicleCrewSecondRowLateralCOG() {
        if (activeRig.getVehicle().getCrewSecondRowLateralCOGOverride() === true) {
            return activeRig.getVehicle().getCrewSecondRowLateralCOG();
        } else {
            return originalRig.getVehicle().getCrewSecondRowLateralCOG();
        }
    }

    function getVehicleFrontAxleOverload() {
        return (((getVehicleOnlyFrontGross() - curCombinationPermissibleVals.vehicleFrontPermissible) / curCombinationPermissibleVals.vehicleFrontPermissible) * 100);
    }

    function getVehicleFrontAxleOverloadStatus() {
        return applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleFrontGross()) > applyRoundingForManufacturerWeightsComplianceFailed(activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating())
    }
    
    function getVehicleRearAxleOverload() {
        return (((getVehicleOnlyRearGross() - curCombinationPermissibleVals.vehicleRearPermissible) / curCombinationPermissibleVals.vehicleRearPermissible) * 100);
    }
    function getVehicleTotalOverload() {
        //var vehicleTotalPermissible = getVehicleTotalPermissible();
        return (((activeRig.getVehicle().getGrossTotal() - curCombinationPermissibleVals.vehicleTotalPermissible) / curCombinationPermissibleVals.vehicleTotalPermissible) * 100);
    }
   
    function getPusherAxleOverload(axleNumber, pusherNumber) {
        var axleGross = activeRig.getVehicle().getPusherOrTagRearAxleGross(axleNumber, getCombinationVehicleRearGross());
        var axlePermissibleVal = curCombinationPermissibleVals['vehiclePusher' + pusherNumber];//getPusherOrTagPermissibleVal.call(this, axleNumber, pusherNumber, config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);//evaluateLegislation('VEHICLEPERMISSIBLEREARPUSHER' + pusherNumber);

        return (((axleGross - axlePermissibleVal) / axlePermissibleVal) * 100);
    }
    function getTagAxleOverload(axleNumber, tagNumber) {
        var axleGross = activeRig.getVehicle().getPusherOrTagRearAxleGross(axleNumber, getCombinationVehicleRearGross());
        var axlePermissibleVal = curCombinationPermissibleVals['vehicleTag' + tagNumber];//getPusherOrTagPermissibleVal.call(this, axleNumber, tagNumber, config.VEHICLE_AXLE_POSITION_TYPES.TAG);//evaluateLegislation('VEHICLEPERMISSIBLEREARTAG' + tagNumber);

        return (((axleGross - axlePermissibleVal) / axlePermissibleVal) * 100); 
    }
    function getRearRearAxlesOverload() {
        var axleGross = activeRig.getVehicle().getAxlesHolder().getRearRearAxlesDistribution(getCombinationVehicleRearGross());
        var axlePermissibleVal = curCombinationPermissibleVals.vehicleRearRearOnly;//getRearRearAxlePermissibleVal();//evaluateLegislation('VEHICLEPERMISSIBLEREARREARONLY');

        return (((axleGross - axlePermissibleVal) / axlePermissibleVal) * 100);
    }

    function getVehicleOnlyFrontGross() {
        var tempVehicle = activeRig.getVehicle();
        var hasAddedPusherOrTag = tempVehicle.getAxlesHolder().hasAddedPusherOrTag();

        if (hasAddedPusherOrTag) {
            return tempVehicle.getRevisedGrossFront(getDistributedTareFrontTotal());
        } else {
            return tempVehicle.getGrossFront();
        }

        
    }

    function getCombinationVehicleFrontGross() {

        //#unfinished, only supporting vehicle and single semi trailer, drawbar or pup at the moment

        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleOnlyFrontGross();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleOnlyFrontGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle() +
                        getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleFrontAxleGrossIncludingTrailer1PupTransfer();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //Return _Vehicle.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle +
                //       VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle
                return getVehicleOnlyFrontGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }

        /*
        Select Case CombinationType
            Case CombinationTypes.Vehicle
                Return _Vehicle.FrontAxleGross
            Case CombinationTypes.VehicleAndDrawbar
                Return _Vehicle.FrontAxleGross
            Case CombinationTypes.VehicleAndInterlinkAndSemi
                Return _Vehicle.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle +
                        VehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFront +
                        VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle +
                        VehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFront
            Case CombinationTypes.VehicleAndPup
                Return VehicleFrontAxleGrossIncludingTrailer1PupTransfer
            Case CombinationTypes.VehicleAndSemi
                Return _Vehicle.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle +
                        VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle
            Case CombinationTypes.VehicleAndSemiAndDrawbar
                Return _Vehicle.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle +
                        VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle
            Case CombinationTypes.VehicleAndSemiAndPup
                Return _Vehicle.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle +
                        VehicleAndSemiAndPupTrailer2TareTransferOntoVehicleFront +
                        VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle +
                        VehicleAndSemiAndPupTrailer2PayloadTransferOntoVehicleFront
            Case Else
                Return 0
        End Select
        */
    }

    function getVehicleOnlyRearGross() {
        var tempVehicle = activeRig.getVehicle();
        var hasAddedPusherOrTag = tempVehicle.getAxlesHolder().hasAddedPusherOrTag();

        if (hasAddedPusherOrTag) {
            return tempVehicle.getRevisedGrossRear(getDistributedTareRearTotal());
        } else {
            return tempVehicle.getGrossRear();
        }

    }

    function getCombinationVehicleRearGross() {

        //#unfinished, only supporting vehicle and single semi trailer, drawbar or pup at the moment
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleOnlyRearGross();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleRearAxleGrossIncludingTrailer1PupTransfer();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                // Return VehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer
                return getVehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }

        /*
        Select Case CombinationType
            Case CombinationTypes.Vehicle
                Return _Vehicle.RearAxleGross
            Case CombinationTypes.VehicleAndDrawbar
                Return _Vehicle.RearAxleGross
            Case CombinationTypes.VehicleAndInterlinkAndSemi
                Return VehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer
            Case CombinationTypes.VehicleAndPup
                Return VehicleRearAxleGrossIncludingTrailer1PupTransfer
            Case CombinationTypes.VehicleAndSemi
                Return VehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer
            Case CombinationTypes.VehicleAndSemiAndDrawbar
                Return VehicleRearAxleGrossIncludingTrailer1SemiAndTrailer2DrawbarTransfer
            Case CombinationTypes.VehicleAndSemiAndPup
                Return VehicleRearAxleGrossIncludingTrailer1SemiAndTrailer2PupTransfer
            Case Else
                Return 0
        End Select
        */
    }

    function getCombinationPermissibleVals() {
        var tempVehicle = activeRig.getVehicle();
        var combinationPermissibleVals = {};

        combinationPermissibleVals.vehicleRearPermissible = getVehicleRearPermissible();
        combinationPermissibleVals.vehicleFrontPermissible = getVehicleFrontPermissible();

        if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
            combinationPermissibleVals.vehicleRearRearOnly = getRearRearAxlePermissibleVal();

            if (tempVehicle.getAxlesHolder().hasPusher1()) {
                combinationPermissibleVals.vehiclePusher1 = self.getPusherOrTagPermissibleVal(tempVehicle.getAxlesHolder().getPusher1().getNumber(), 1, config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
            }
            if (tempVehicle.getAxlesHolder().hasPusher2()) {
                combinationPermissibleVals.vehiclePusher2 = self.getPusherOrTagPermissibleVal(tempVehicle.getAxlesHolder().getPusher2().getNumber(), 2, config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
            }
            if (tempVehicle.getAxlesHolder().hasPusher3()) {
                combinationPermissibleVals.vehiclePusher3 = self.getPusherOrTagPermissibleVal(tempVehicle.getAxlesHolder().getPusher3().getNumber(), 3, config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
            }
            if (tempVehicle.getAxlesHolder().hasTag1()) {
                combinationPermissibleVals.vehicleTag1 = self.getPusherOrTagPermissibleVal(tempVehicle.getAxlesHolder().getTag1().getNumber(), 1, config.VEHICLE_AXLE_POSITION_TYPES.TAG);
            }
            if (tempVehicle.getAxlesHolder().hasTag2()) {
                combinationPermissibleVals.vehicleTag2 = self.getPusherOrTagPermissibleVal(tempVehicle.getAxlesHolder().getTag2().getNumber(), 2, config.VEHICLE_AXLE_POSITION_TYPES.TAG);
            }
            if (tempVehicle.getAxlesHolder().hasTag3()) {
                combinationPermissibleVals.vehicleTag3 = self.getPusherOrTagPermissibleVal(tempVehicle.getAxlesHolder().getTag3().getNumber(), 3, config.VEHICLE_AXLE_POSITION_TYPES.TAG);
            }
        }

        if (tempVehicle.getPermissibleTotalOverride() === false) {
            combinationPermissibleVals.vehicleTotalPermissible = getVehicleTotalPermissible();
        } else {
            combinationPermissibleVals.vehicleTotalPermissible = tempVehicle.getPermissibleTotal();
        }



        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:   
                
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                combinationPermissibleVals.trailer1PermissibleRear = getTrailer1PermissibleRear();
                combinationPermissibleVals.trailer1PermissibleFront = getTrailer1PermissibleFront();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                combinationPermissibleVals.trailer1PermissibleRear = getTrailer1PermissibleRear();
                combinationPermissibleVals.trailer2PermissibleRear = getTrailer2PermissibleRear();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                combinationPermissibleVals.trailer1PermissibleRear = getTrailer1PermissibleRear();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                break;
        }
        combinationPermissibleVals.combinationTotalPermissible = getCombinationTotalPermissible();
        return combinationPermissibleVals;
    }

    function setCombinationPermissibleVals(optionalPerissibleVals) {
        if (optionalPerissibleVals) {
            curCombinationPermissibleVals = optionalPerissibleVals;
        } else {
            curCombinationPermissibleVals = getCombinationPermissibleVals();
        }
    }

    function maximisePayloads(combinationPermissibleValues) {
        
        var maximisedPayloads = getMaximisedLoad(combinationPermissibleValues);

        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1(),
            tempTrailer2 = activeRig.getTrailer2();


        if (canMaximisePayloadForCombination()) {
            if (tempVehicle && tempVehicle.getPayloadHolder().getSimplePayloadObject() !== null) {
                tempVehicle.getPayloadHolder().getSimplePayloadObject().setMass(maximisedPayloads.vehiclePayload);
            }
            if (tempTrailer1 && tempTrailer1.getPayloadHolder().getSimplePayloadObject() !== null) {
                tempTrailer1.getPayloadHolder().getSimplePayloadObject().setMass(maximisedPayloads.trailer1Payload);
            }
            if (tempTrailer2 && tempTrailer2.getPayloadHolder().getSimplePayloadObject() !== null) {
                tempTrailer2.getPayloadHolder().getSimplePayloadObject().setMass(maximisedPayloads.trailer2Payload);
            }
        }
    }

    function getMaximisedLoad(combinationPermissibleValues) {

           

        var resultObject = {
            vehiclePayload: 0,
            trailer1Payload: 0,
            trailer2Payload: 0
        };

        var maximisedPayload = 999999;
        var checkTrailerRear = false, checkTrailerFront = false, checkTrailer2Rear = false, checkTrailer2Front = false, checkVehicleRearRearOnly = false, checkVehiclePusher1 = false, checkVehiclePusher2 = false, checkVehiclePusher3 = false, checkVehicleTag1 = false, checkVehicleTag2 = false, checkVehicleTag3 = false;
        var vehicleFront, vehicleRear, vehicleRearRearOnly, vehiclePusher1, vehiclePusher2, vehiclePusher3, vehicleTag1, vehicleTag2, vehicleTag3, trailerFront, trailer1Rear, total;
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();
        var trailer1PermissibleRear, vehicleInternalBodyLength = 0, trailer1InternalBodyLength = 0;
        if (canMaximisePayloadForCombination()) {
            
            if ((tempVehicle.getAccessoryHolder().getBody() !== null || tempVehicle.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) && tempTrailer1 === null && tempTrailer2 === null && tempVehicle.getAccessoryHolder().getFifthWheel() === null) {
                if (!combinationPermissibleValues) {
                    combinationPermissibleValues = getCombinationPermissibleVals();
                }
                if (combinationPermissibleValues.vehicleFrontPermissible > getVehicleOnlyFrontUnladen()) {
                    if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalEnd() === 0) {
                        vehicleFront = combinationPermissibleValues.vehicleRearPermissible - getVehicleOnlyRearUnladen();
                    } else {
                        vehicleFront = ((combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen()) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalEnd();
                    }
                    
                } else {
                    vehicleFront = 0;
                }
                if (combinationPermissibleValues.vehicleRearPermissible > getVehicleOnlyRearUnladen()) {
                    if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                        vehicleRear = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                    } else {
                        vehicleRear = ((combinationPermissibleValues.vehicleRearPermissible - getVehicleOnlyRearUnladen()) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart();
                    }
                    
                } else {
                    vehicleRear = 0;
                }

                if (combinationPermissibleValues.vehicleRearRearOnly) {
                    generatePusherTagAndRearRearValuesForMaximise(getVehicleOnlyRearUnladen());
                }
                
                if (combinationPermissibleValues.combinationTotalPermissible > activeRig.getVehicle().getUnladenTotal()) {
                    total = combinationPermissibleValues.combinationTotalPermissible - activeRig.getVehicle().getUnladenTotal();
                } else {
                    total = 0;
                }

                allocateLesserOfCalculatedValsToMaximisedPayload();

                resultObject.vehiclePayload = maximisedPayload;

            } else if (tempTrailer1 !== null && tempTrailer2 === null) {
                if (!combinationPermissibleValues) {
                    combinationPermissibleValues = getCombinationPermissibleVals();
                }
                if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {

                    var fifthWheelOffset = getFifthWheelOffset();

                    if (combinationPermissibleValues.vehicleFrontPermissible > (getVehicleOnlyFrontUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle())) {
                        vehicleFront = (combinationPermissibleValues.vehicleFrontPermissible - (getVehicleOnlyFrontUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle())) /
                                       ((tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer1.getWheelbaseTheoretical()) *
                                       (fifthWheelOffset / tempVehicle.getWheelbaseTheoretical()));
                    } else {
                        vehicleFront = 0;
                    }

                    if (combinationPermissibleValues.vehicleRearPermissible > (getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle())) {
                        vehicleRear = (combinationPermissibleValues.vehicleRearPermissible - (getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle())) /
                                      ((tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer1.getWheelbaseTheoretical()) *
                                      ((tempVehicle.getWheelbaseTheoretical() - fifthWheelOffset) / tempVehicle.getWheelbaseTheoretical()));
                    } else {
                        vehicleRear = 0;
                    }

                    if (combinationPermissibleValues.vehicleRearRearOnly) {
                        generatePusherTagAndRearRearValuesForMaximise(getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle());
                    }

                    checkTrailerRear = true;
                    trailer1PermissibleRear = combinationPermissibleValues.trailer1PermissibleRear;//getTrailer1ExtremeRearGroupBridgeFormulaPermissible();//evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
                    if (trailer1PermissibleRear > tempTrailer1.getUnladenRear()) {
                        trailer1Rear = (trailer1PermissibleRear - tempTrailer1.getUnladenRear()) /
                                      ((tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd()) / tempTrailer1.getWheelbaseTheoretical())
                    } else {
                        trailer1Rear = 0
                    }


                    if (combinationPermissibleValues.combinationTotalPermissible > (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal())) {
                        total = combinationPermissibleValues.combinationTotalPermissible - (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal());
                    } else {
                        total = 0
                    }

                    allocateLesserOfCalculatedValsToMaximisedPayload();

                    resultObject.trailer1Payload = maximisedPayload;

                } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR && tempVehicle.getAccessoryHolder().getFifthWheel() === null) {
                    if (!combinationPermissibleValues) {
                        combinationPermissibleValues = getCombinationPermissibleVals();
                    }
                    vehicleInternalBodyLength = 0; 
                    trailer1InternalBodyLength = 0;

                    if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) {
                        trailer1InternalBodyLength = tempTrailer1.getPayloadLength();
                    } else if (tempTrailer1.getAccessoryHolder().getBody() !== null) {
                        trailer1InternalBodyLength = tempTrailer1.getAccessoryHolder().getBody().getActualInternalLength();
                    }

                    if (tempVehicle.getAccessoryHolder().getBody() !== null) {

                        vehicleInternalBodyLength = tempVehicle.getAccessoryHolder().getBody().getActualInternalLength();

                        if (vehicleInternalBodyLength + trailer1InternalBodyLength !== 0) {
                            if (combinationPermissibleValues.vehicleFrontPermissible > getVehicleOnlyFrontUnladen()) {
                                vehicleFront = (combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen()) /
                                               ((vehicleInternalBodyLength /
                                               (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                               (tempVehicle.getPayloadCOGToWheelbaseTheoreticalEnd() / tempVehicle.getWheelbaseTheoretical()));
                            } else {
                                vehicleFront = 0;
                            }

                            if (combinationPermissibleValues.vehicleRearPermissible > getVehicleOnlyRearUnladen()) {
                                vehicleRear = (combinationPermissibleValues.vehicleRearPermissible - getVehicleOnlyRearUnladen()) /
                                              ((vehicleInternalBodyLength /
                                              (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              ((tempVehicle.getWheelbaseTheoretical() - tempVehicle.getPayloadCOGToWheelbaseTheoreticalEnd()) / tempVehicle.getWheelbaseTheoretical()))
                            } else {
                                vehicleRear = 0
                            }

                            if (combinationPermissibleValues.vehicleRearRearOnly) {
                                generatePusherTagAndRearRearValuesForMaximise(getVehicleOnlyRearUnladen());
                            }

                        } else {
                            vehicleFront = 0;
                            vehicleRear = 0;
                        }



                    } else {
                        vehicleFront = 0;
                        vehicleRear = 0;
                    }

                    checkTrailerFront = true;
                    checkTrailerRear = true;
                    if (tempTrailer1.getAccessoryHolder().getBody() !== null || tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) {

                        if (vehicleInternalBodyLength + trailer1InternalBodyLength !== 0) {

                            var trailer1PermissibleFront = combinationPermissibleValues.trailer1PermissibleFront;//getTrailer1ExtremeFrontGroupBridgeFormulaPermissible();//evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFront);
                            if (trailer1PermissibleFront > tempTrailer1.getUnladenFront()) {
                                trailerFront = (trailer1PermissibleFront - tempTrailer1.getUnladenFront()) /
                                               ((trailer1InternalBodyLength /
                                               (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                               (tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer1.getWheelbaseTheoretical()));
                            } else {
                                trailerFront = 0;
                            }


                            trailer1PermissibleRear = combinationPermissibleValues.trailer1PermissibleRear;//getTrailer1ExtremeRearGroupBridgeFormulaPermissible();//evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
                            if (trailer1PermissibleRear > tempTrailer1.getUnladenRear()) {
                                trailer1Rear = (trailer1PermissibleRear - tempTrailer1.getUnladenRear()) /
                                              ((trailer1InternalBodyLength /
                                              (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              ((tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd()) / tempTrailer1.getWheelbaseTheoretical()))
                            } else {
                                trailer1Rear = 0
                            }

                        } else {
                            trailerFront = 0;
                            trailer1Rear = 0;
                        }

                    } else {
                        trailerFront = 0;
                        trailer1Rear = 0;
                    }


                    if (combinationPermissibleValues.combinationTotalPermissible > (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal())) {
                        total = combinationPermissibleValues.combinationTotalPermissible - (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal());
                    } else {
                        total = 0;
                    }

                    allocateLesserOfCalculatedValsToMaximisedPayload();
                    if (vehicleInternalBodyLength + trailer1InternalBodyLength !== 0) {
                        resultObject.vehiclePayload = Math.round(maximisedPayload * (vehicleInternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)));
                    }
                    resultObject.trailer1Payload = maximisedPayload - resultObject.vehiclePayload;

                } else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP && tempVehicle.getAccessoryHolder().getFifthWheel() === null) {
                    if (!combinationPermissibleValues) {
                        combinationPermissibleValues = getCombinationPermissibleVals();
                    }
                    vehicleInternalBodyLength = 0; 
                    trailer1InternalBodyLength = 0;

                    if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) {
                        trailer1InternalBodyLength = tempTrailer1.getPayloadLength();
                    } else if (tempTrailer1.getAccessoryHolder().getBody() !== null) {
                        trailer1InternalBodyLength = tempTrailer1.getAccessoryHolder().getBody().getActualInternalLength();
                    }


                    /*
                    Dim vehicleFront, vehicleRear, trailerRear, total As Double
                Dim vehicleInternalBodyLength, trailer1InternalBodyLength As Double
    
                If _Trailer1(0).Source = SourceTypes.Standard Then
                    trailer1InternalBodyLength = _Trailer1(0).PayloadLength
                ElseIf _Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                    trailer1InternalBodyLength = _Trailer1(0).Accessories.Body.ActualInternalLength
                End If
    */

                    if (tempVehicle.getAccessoryHolder().getBody() !== null) {

                        vehicleInternalBodyLength = tempVehicle.getAccessoryHolder().getBody().getActualInternalLength();

                        if (vehicleInternalBodyLength + trailer1InternalBodyLength !== 0) {
                            if (combinationPermissibleValues.vehicleFrontPermissible > getVehicleOnlyFrontUnladen()) {
                                vehicleFront = (combinationPermissibleValues.vehicleFrontPermissible - (getVehicleOnlyFrontUnladen() + getVehicleAndPupTareTransferOntoVehicleFrontAxle())) /
                                               ((vehicleInternalBodyLength /
                                               (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                               (tempVehicle.getPayloadCOGToWheelbaseTheoreticalEnd() / tempVehicle.getWheelbaseTheoretical()) -
                                               (trailer1InternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                               (tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer1.getWheelbaseTheoretical()) *
                                               ((tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset()) / tempVehicle.getWheelbaseTheoretical()));

                            } else {
                                vehicleFront = 0;
                            }

                            if (combinationPermissibleValues.vehicleRearPermissible > getVehicleOnlyRearUnladen() + getVehicleAndPupTareTransferOntoVehicleRearAxle()) {
                                vehicleRear = (combinationPermissibleValues.vehicleRearPermissible - (getVehicleOnlyRearUnladen() + getVehicleAndPupTareTransferOntoVehicleRearAxle())) /
                                              ((vehicleInternalBodyLength /
                                              (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              ((tempVehicle.getWheelbaseTheoretical() - tempVehicle.getPayloadCOGToWheelbaseTheoreticalEnd()) / tempVehicle.getWheelbaseTheoretical()) +
                                              (trailer1InternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              (tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer1.getWheelbaseTheoretical()) *
                                              ((tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset()) / tempVehicle.getWheelbaseTheoretical()));
                                /*
                                vehicleRear = (VehicleRearPermissible - (.RearAxleUnladen + VehicleAndPupTareTransferOntoVehicleRearAxle)) /
                                              ((vehicleInternalBodyLength /
                                              (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical) +
                                              (trailer1InternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                              ((.WheelbaseTheoretical + .Accessories.Hitch.HorizontalGap) / .WheelbaseTheoretical))
                                */
                            } else {
                                vehicleRear = 0
                            }

                            if (combinationPermissibleValues.vehicleRearRearOnly) {
                                generatePusherTagAndRearRearValuesForMaximise(getVehicleOnlyRearUnladen() + getVehicleAndPupTareTransferOntoVehicleRearAxle());
                            }
                        } else {
                            vehicleFront = 0;
                            vehicleRear = 0;
                        }



                    } else {
                        vehicleFront = 0;
                        vehicleRear = 0;
                    }

                    /*
                With _Vehicle
    
                    If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
    
                        vehicleInternalBodyLength = .Accessories.Body.ActualInternalLength
    
                        If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then
    
                            If VehicleFrontPermissible > .FrontAxleUnladen Then
                                vehicleFront = (VehicleFrontPermissible - (.FrontAxleUnladen + VehicleAndPupTareTransferOntoVehicleFrontAxle)) /
                                               ((vehicleInternalBodyLength /
                                               (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                               (.PayloadCOGToWheelbaseTheoreticalEnd / .WheelbaseTheoretical) -
                                               (trailer1InternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                               (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                               (.Accessories.Hitch.HorizontalGap / .WheelbaseTheoretical))
                            Else
                                vehicleFront = 0
                            End If
    
                            If VehicleRearPermissible > .RearAxleUnladen Then
                                vehicleRear = (VehicleRearPermissible - (.RearAxleUnladen + VehicleAndPupTareTransferOntoVehicleRearAxle)) /
                                              ((vehicleInternalBodyLength /
                                              (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical) +
                                              (trailer1InternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                              ((.WheelbaseTheoretical + .Accessories.Hitch.HorizontalGap) / .WheelbaseTheoretical))
                            Else
                                vehicleRear = 0
                            End If
    
                        Else
                            vehicleFront = 0
                            vehicleRear = 0
                        End If
    
                    Else
                        vehicleFront = 0
                        vehicleRear = 0
                    End If
    
                    'vehicleFront = Math.Floor(vehicleFront)
                    'vehicleRear = Math.Floor(vehicleRear)
    
                End With
    */
                    checkTrailerRear = true;
                    if (tempTrailer1.getAccessoryHolder().getBody() !== null || tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) {

                        if (vehicleInternalBodyLength + trailer1InternalBodyLength !== 0) {


                            trailer1PermissibleRear = combinationPermissibleValues.trailer1PermissibleRear;//getTrailer1ExtremeRearGroupBridgeFormulaPermissible();//evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
                            if (trailer1PermissibleRear > tempTrailer1.getUnladenRear()) {
                                trailer1Rear = (trailer1PermissibleRear - tempTrailer1.getUnladenRear()) /
                                              ((trailer1InternalBodyLength /
                                              (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              ((tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd()) / tempTrailer1.getWheelbaseTheoretical()))
                            } else {
                                trailer1Rear = 0
                            }

                        } else {

                            trailer1Rear = 0;
                        }

                    } else {

                        trailer1Rear = 0;
                    }

                    /*
                With _Trailer1(0)
    
                    If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                           .Source = SourceTypes.Standard Then
    
                        If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then
    
                            If LATrailer1PermissibleRear > .RearAxleUnladen Then
                                trailerRear = (LATrailer1PermissibleRear - .RearAxleUnladen) /
                                              ((trailer1InternalBodyLength /
                                              (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                              ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))
                            Else
                                trailerRear = 0
                            End If
    
                        Else
                            trailerRear = 0
                        End If
    
                    Else
                        trailerRear = 0
                    End If
    
                    'trailerRear = Math.Floor(trailerRear)
    
                End With
    */

                    if (combinationPermissibleValues.combinationTotalPermissible > (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal())) {
                        total = combinationPermissibleValues.combinationTotalPermissible - (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal());
                    } else {
                        total = 0;
                    }

                    /*
                If CombinationTotalPermissible > (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen) Then
                    total = CombinationTotalPermissible - (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen)
                Else
                    total = 0
                End If
                */

                    allocateLesserOfCalculatedValsToMaximisedPayload();
                    if (vehicleInternalBodyLength + trailer1InternalBodyLength !== 0) {
                        resultObject.vehiclePayload = Math.round(maximisedPayload * (vehicleInternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)));
                    }
                    resultObject.trailer1Payload = maximisedPayload - resultObject.vehiclePayload;

                    /*
    
                If vehicleFront >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
                End If
                If vehicleRear >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
                End If
                If trailerRear >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, trailerRear)
                End If
                If total >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, total)
                End If
    
                If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then
                    vehiclePayload = Math.Round(maximisedPayload * (vehicleInternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)))
                Else
                    vehiclePayload = 0
                End If
    
                trailer1Payload = maximisedPayload - vehiclePayload
                trailer2Payload = 0
    
            End If
                    */
                }
            } else if (tempTrailer1 !== null && tempTrailer2 !== null) {

                if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                    if (!combinationPermissibleValues) {
                        combinationPermissibleValues = getCombinationPermissibleVals();
                    }
                    var trailer2Rear;
                    trailer1InternalBodyLength = 0;
                    var trailer2InternalBodyLength = 0;


                    var vehicleFifthWheelOffset = getFifthWheelOffset();

                    if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) {
                        trailer1InternalBodyLength = tempTrailer1.getPayloadLength();
                    } else if (tempTrailer1.getAccessoryHolder().getBody() !== null) {
                        trailer1InternalBodyLength = tempTrailer1.getAccessoryHolder().getBody().getActualInternalLength();
                    }

                    if (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer2))) {
                        trailer2InternalBodyLength = tempTrailer2.getPayloadLength();
                    } else if (tempTrailer2.getAccessoryHolder().getBody() !== null) {
                        trailer2InternalBodyLength = tempTrailer2.getAccessoryHolder().getBody().getActualInternalLength();
                    }

                    if (trailer1InternalBodyLength + trailer2InternalBodyLength !== 0) {

                        if (combinationPermissibleValues.vehicleFrontPermissible > getVehicleOnlyFrontUnladen()) {

                            vehicleFront = (combinationPermissibleValues.vehicleFrontPermissible - (getVehicleOnlyFrontUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle())) /
                                                               ((trailer1InternalBodyLength /
                                                               (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                                               (tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer1.getWheelbaseTheoretical()) *
                                                               (vehicleFifthWheelOffset / tempVehicle.getWheelbaseTheoretical()) +
                                                               (trailer2InternalBodyLength /
                                                               (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                                               (tempTrailer2.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer2.getWheelbaseTheoretical()) *
                                                               (tempTrailer1.getFifthWheelOffset() / tempTrailer1.getWheelbaseTheoretical()) *
                                                               (vehicleFifthWheelOffset / tempVehicle.getWheelbaseTheoretical()));

                        } else {
                            vehicleFront = 0;
                        }

                        if (combinationPermissibleValues.vehicleRearPermissible > getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle()) {

                            vehicleRear = (combinationPermissibleValues.vehicleRearPermissible - (getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle())) /
                                          ((trailer1InternalBodyLength /
                                          (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                          (tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer1.getWheelbaseTheoretical()) *
                                          ((tempVehicle.getWheelbaseTheoretical() - vehicleFifthWheelOffset) / tempVehicle.getWheelbaseTheoretical()) +
                                          (trailer2InternalBodyLength /
                                          (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                          (tempTrailer2.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer2.getWheelbaseTheoretical()) *
                                          (tempTrailer1.getFifthWheelOffset() / tempTrailer1.getWheelbaseTheoretical()) *
                                          ((tempVehicle.getWheelbaseTheoretical() - vehicleFifthWheelOffset) / tempVehicle.getWheelbaseTheoretical()));

                        } else {
                            vehicleRear = 0;
                        }

                        if (combinationPermissibleValues.vehicleRearRearOnly) {
                            generatePusherTagAndRearRearValuesForMaximise(getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle());
                        }

                    } else {
                        vehicleFront = 0;
                        vehicleRear = 0;
                    }

                    checkTrailerRear = true;
                    if ((tempTrailer1.getAccessoryHolder().getBody() !== null) || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) {

                        if (trailer1InternalBodyLength + trailer2InternalBodyLength !== 0) {
                            trailer1PermissibleRear = combinationPermissibleValues.trailer1PermissibleRear;//getTrailer1ExtremeRearGroupBridgeFormulaPermissible();//evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
                            if (trailer1PermissibleRear > tempTrailer1.getUnladenRear()) {

                                trailer1Rear = (trailer1PermissibleRear - (tempTrailer1.getUnladenRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle())) /
                                           ((trailer1InternalBodyLength /
                                           (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                           ((tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getPayloadCOGToWheelbaseTheoreticalEnd()) / tempTrailer1.getWheelbaseTheoretical()) +
                                           (trailer2InternalBodyLength /
                                           (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                           (tempTrailer2.getPayloadCOGToWheelbaseTheoreticalEnd() / tempTrailer2.getWheelbaseTheoretical()) *
                                           ((tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset()) / tempTrailer1.getWheelbaseTheoretical()));

                            } else {
                                trailer1Rear = 0;
                            }

                        } else {
                            trailer1Rear = 0;
                        }

                    } else {
                        trailer1Rear = 0;
                    }
                    checkTrailer2Rear = true;
                    if ((tempTrailer2.getAccessoryHolder().getBody() !== null) || (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) || (tempTrailer2.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer2))) {

                        if (trailer1InternalBodyLength + trailer2InternalBodyLength !== 0) {
                            var trailer2PermissibleRear = combinationPermissibleValues.trailer2PermissibleRear;//getTrailer2ExtremeRearGroupBridgeFormulaPermissible();//evaluateLegislation(legislationVals.variableTypes.Trailer2PermissibleRear);
                            if (trailer2PermissibleRear > tempTrailer2.getUnladenRear()) {

                                trailer2Rear = (trailer2PermissibleRear - tempTrailer2.getUnladenRear()) /
                                               ((trailer2InternalBodyLength /
                                               (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                               ((tempTrailer2.getWheelbaseTheoretical() - tempTrailer2.getPayloadCOGToWheelbaseTheoreticalEnd()) / tempTrailer2.getWheelbaseTheoretical()));

                            } else {
                                trailer2Rear = 0;
                            }

                        } else {
                            trailer2Rear = 0;
                        }

                    } else {
                        trailer2Rear = 0;
                    }

                    if (combinationPermissibleValues.combinationTotalPermissible > (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal() + tempTrailer2.getUnladenTotal())) {
                        total = combinationPermissibleValues.combinationTotalPermissible - (tempVehicle.getUnladenTotal() + tempTrailer1.getUnladenTotal() + tempTrailer2.getUnladenTotal());
                    } else {
                        total = 0
                    }
                    allocateLesserOfCalculatedValsToMaximisedPayload();

                    resultObject.vehiclePayload = 0;

                    if (trailer1InternalBodyLength + trailer2InternalBodyLength !== 0) {
                        resultObject.trailer1Payload = Math.round(maximisedPayload * (trailer1InternalBodyLength / (trailer1InternalBodyLength + trailer2InternalBodyLength)));
                    } else {
                        resultObject.trailer1Payload = 0;
                    }

                    resultObject.trailer2Payload = maximisedPayload - resultObject.trailer1Payload;

                    /*
               
                
    
                
    
                
    
                If vehicleFront >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
                End If
                If vehicleRear >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
                End If
                If trailer1Rear >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, trailer1Rear)
                End If
                If trailer2Rear >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, trailer2Rear)
                End If
                If total >= 0 Then
                    maximisedPayload = Math.Min(maximisedPayload, total)
                End If
    
                
                    */
                }


            }
        }

        return resultObject;

        function generatePusherTagAndRearRearValuesForMaximise(vehicleRearMassVal) {
            var rearRearOnlyMassValToUse, pusherAndTagMassValToUse;
            if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                rearRearOnlyMassValToUse = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getPercentageOfWeightCarried();
                pusherAndTagMassValToUse = activeRig.getVehicle().getAxlesHolder().getSumOfDownPusherAndTagAxlesPercentageOfWeightCarried();
            } else {
                rearRearOnlyMassValToUse = activeRig.getVehicle().getAxlesHolder().getRearRearAxlesSuspensionRating();
                pusherAndTagMassValToUse = activeRig.getVehicle().getAxlesHolder().getSumPusherAndTagAxlesSuspensionRating();
            }

            checkVehicleRearRearOnly = true;
            var rearRearOnlyUnladen = activeRig.getVehicle().getAxlesHolder().getRearRearAxlesDistribution(vehicleRearMassVal);
            if (combinationPermissibleValues.vehicleRearRearOnly > rearRearOnlyUnladen) {


                if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                    vehicleRearRearOnly = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                } else {
                    vehicleRearRearOnly = (((combinationPermissibleValues.vehicleRearRearOnly - rearRearOnlyUnladen) / (rearRearOnlyMassValToUse / (rearRearOnlyMassValToUse + pusherAndTagMassValToUse)) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart());
                }
            } else {
                vehicleRearRearOnly = 0;
            }

            if (combinationPermissibleValues.vehiclePusher1) {
                checkVehiclePusher1 = true;
                var pusher1 = activeRig.getVehicle().getAxlesHolder().getPusher1();
                var pusher1Unladen = activeRig.getVehicle().getAxlesHolder().getPusherOrTagRearAxleDistribution(pusher1.getNumber(), vehicleRearMassVal);
                if (combinationPermissibleValues.vehiclePusher1 > pusher1Unladen) {
                    var pusher1ValToUse;
                    if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                        pusher1ValToUse = pusher1.getPercentageOfWeightCarried();
                    } else {
                        pusher1ValToUse = pusher1.getSuspensionMassRating();
                    }
                    if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                        vehiclePusher1 = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                    } else {
                        vehiclePusher1 = (((combinationPermissibleValues.vehiclePusher1 - pusher1Unladen) / (pusher1ValToUse / (rearRearOnlyMassValToUse + pusherAndTagMassValToUse)) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart());
                    }
                } else {
                    vehiclePusher1 = 0;
                }


                if (combinationPermissibleValues.vehiclePusher2) {
                    checkVehiclePusher2 = true;
                    var pusher2 = activeRig.getVehicle().getAxlesHolder().getPusher2();
                    var pusher2Unladen = activeRig.getVehicle().getAxlesHolder().getPusherOrTagRearAxleDistribution(pusher2.getNumber(), vehicleRearMassVal);
                    if (combinationPermissibleValues.vehiclePusher2 > pusher2Unladen) {
                        var pusher2ValToUse;
                        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                            pusher2ValToUse = pusher2.getPercentageOfWeightCarried();
                        } else {
                            pusher2ValToUse = pusher2.getSuspensionMassRating();
                        }
                        if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                            vehiclePusher2 = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                        } else {
                            vehiclePusher2 = (((combinationPermissibleValues.vehiclePusher2 - pusher2Unladen) / (pusher2ValToUse / (rearRearOnlyMassValToUse + pusherAndTagMassValToUse)) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart());
                        }
                    } else {
                        vehiclePusher2 = 0;
                    }


                    if (combinationPermissibleValues.vehiclePusher3) {
                        checkVehiclePusher3 = true;
                        var pusher3 = activeRig.getVehicle().getAxlesHolder().getPusher3();
                        var pusher3Unladen = activeRig.getVehicle().getAxlesHolder().getPusherOrTagRearAxleDistribution(pusher3.getNumber(), vehicleRearMassVal);
                        if (combinationPermissibleValues.vehiclePusher3 > pusher3Unladen) {
                            var pusher3ValToUse;
                            if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                                pusher3ValToUse = pusher3.getPercentageOfWeightCarried();
                            } else {
                                pusher3ValToUse = pusher3.getSuspensionMassRating();
                            }
                            if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                                vehiclePusher3 = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                            } else {
                                vehiclePusher3 = (((combinationPermissibleValues.vehiclePusher3 - pusher3Unladen) / (pusher3ValToUse / (rearRearOnlyMassValToUse + pusherAndTagMassValToUse)) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart());
                            }
                        } else {
                            vehiclePusher3 = 0;
                        }

                    }
                }
            }

            if (combinationPermissibleValues.vehicleTag1) {
                checkVehicleTag1 = true;
                var tag1 = activeRig.getVehicle().getAxlesHolder().getTag1();
                var tag1Unladen = activeRig.getVehicle().getAxlesHolder().getPusherOrTagRearAxleDistribution(tag1.getNumber(), vehicleRearMassVal);
                if (combinationPermissibleValues.vehicleTag1 > tag1Unladen) {
                    var tag1ValToUse;
                    if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                        tag1ValToUse = tag1.getPercentageOfWeightCarried();
                    } else {
                        tag1ValToUse = tag1.getSuspensionMassRating();
                    }
                    if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                        vehicleTag1 = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                    } else {
                        vehicleTag1 = (((combinationPermissibleValues.vehicleTag1 - tag1Unladen) / (tag1ValToUse / (rearRearOnlyMassValToUse + pusherAndTagMassValToUse)) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart());
                    }
                } else {
                    vehicleTag1 = 0;
                }

                if (combinationPermissibleValues.vehicleTag2) {
                    checkVehicleTag2 = true;
                    var tag2 = activeRig.getVehicle().getAxlesHolder().getTag2();
                    var tag2Unladen = activeRig.getVehicle().getAxlesHolder().getPusherOrTagRearAxleDistribution(tag2.getNumber(), vehicleRearMassVal);
                    if (combinationPermissibleValues.vehicleTag2 > tag2Unladen) {
                        var tag2ValToUse;
                        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                            tag2ValToUse = tag2.getPercentageOfWeightCarried();
                        } else {
                            tag2ValToUse = tag2.getSuspensionMassRating();
                        }
                        if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                            vehicleTag2 = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                        } else {
                            vehicleTag2 = (((combinationPermissibleValues.vehicleTag2 - tag2Unladen) / (tag2ValToUse / (rearRearOnlyMassValToUse + pusherAndTagMassValToUse)) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart());
                        }
                    } else {
                        vehicleTag2 = 0;
                    }

                    if (combinationPermissibleValues.vehicleTag3) {
                        checkVehicleTag3 = true;
                        var tag3 = activeRig.getVehicle().getAxlesHolder().getTag3();
                        var tag3Unladen = activeRig.getVehicle().getAxlesHolder().getPusherOrTagRearAxleDistribution(tag3.getNumber(), vehicleRearMassVal);
                        if (combinationPermissibleValues.vehicleTag3 > tag3Unladen) {
                            var tag3ValToUse;
                            if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                                tag3ValToUse = tag3.getPercentageOfWeightCarried();
                            } else {
                                tag3ValToUse = tag3.getSuspensionMassRating();
                            }
                            if (activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart() === 0) {
                                vehicleTag3 = combinationPermissibleValues.vehicleFrontPermissible - getVehicleOnlyFrontUnladen();
                            } else {
                                vehicleTag3 = (((combinationPermissibleValues.vehicleTag3 - tag3Unladen) / (tag3ValToUse / (rearRearOnlyMassValToUse + pusherAndTagMassValToUse)) * activeRig.getVehicle().getWheelbaseTheoretical()) / activeRig.getVehicle().getPayloadCOGToWheelbaseTheoreticalStart());
                            }
                        } else {
                            vehicleTag3 = 0;
                        }
                    }
                }
            }
        }

        function allocateLesserOfCalculatedValsToMaximisedPayload() {
            if (vehicleFront >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehicleFront);
            }

            if (vehicleRear >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehicleRear);
            }

            if (checkVehicleRearRearOnly && vehicleRearRearOnly >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehicleRearRearOnly);
            }

            if (checkVehiclePusher1 && vehiclePusher1 >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehiclePusher1);
            }

            if (checkVehiclePusher2 && vehiclePusher2 >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehiclePusher2);
            }

            if (checkVehiclePusher3 && vehiclePusher3 >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehiclePusher3);
            }

            if (checkVehicleTag1 && vehicleTag1 >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehicleTag1);
            }

            if (checkVehicleTag2 && vehicleTag2 >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehicleTag2);
            }

            if (checkVehicleTag3 && vehicleTag3 >= 0) {
                maximisedPayload = Math.min(maximisedPayload, vehicleTag3);
            }

            if (checkTrailerFront === true && trailerFront >= 0) {
                maximisedPayload = Math.min(maximisedPayload, trailerFront);
            }

            if (checkTrailerRear === true && trailer1Rear >= 0) {
                maximisedPayload = Math.min(maximisedPayload, trailer1Rear);
            }

            if (checkTrailer2Rear && trailer2Rear >= 0) {
                maximisedPayload = Math.min(maximisedPayload, trailer2Rear)
            }

            if (total >= 0) {
                maximisedPayload = Math.min(maximisedPayload, total);
            }

            
        }
        
        //vehiclePayload = maximisedPayload
        //trailer1Payload = 0
        //trailer2Payload = 0


        /*
            Private Sub MaximisePayloads(ByRef vehiclePayload As Double,
                                ByRef trailer1Payload As Double,
                                ByRef trailer2Payload As Double)

    Dim maximisedPayload As Double = 999999

    If (_Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
        _Vehicle.Type = Web.VehicleTypes.CompleteVehicle) And
        _Trailer1.Count = 0 And
        _Trailer2.Count = 0 Then

        Dim vehicleFront, vehicleRear, total As Double
        With _Vehicle

            If VehicleFrontPermissible > .FrontAxleUnladen And .PayloadCOGToWheelbaseTheoreticalEnd <> 0 Then
                vehicleFront = ((VehicleFrontPermissible - .FrontAxleUnladen) * .WheelbaseTheoretical) / .PayloadCOGToWheelbaseTheoreticalEnd
            Else
                vehicleFront = 0
            End If
            'vehicleFront = Math.Floor(vehicleFront)

            If VehicleRearPermissible > .RearAxleUnladen And .PayloadCOGToWheelbaseTheoreticalStart <> 0 Then
                vehicleRear = ((VehicleRearPermissible - .RearAxleUnladen) * .WheelbaseTheoretical) / .PayloadCOGToWheelbaseTheoreticalStart
            Else
                vehicleRear = 0
            End If
            'vehicleRear = Math.Floor(vehicleRear)

            If CombinationTotalPermissible > .TotalUnladen Then
                total = CombinationTotalPermissible - .TotalUnladen
            Else
                total = 0
            End If

            If vehicleFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
            End If
            If vehicleRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
            End If
            If total >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, total)
            End If

            vehiclePayload = maximisedPayload
            trailer1Payload = 0
            trailer2Payload = 0

        End With

    ElseIf _Trailer1.Count = 1 And
            _Trailer2.Count = 0 Then

        If TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi Or
            TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink Then

            Dim vehicleFront, vehicleRear, trailerRear, total As Double
            Dim fifthWheelOffset As Double

            If _Vehicle.FifthWheelIncludedInTare = Web.VehicleInclusionTypes.Yes Then
                fifthWheelOffset = _Vehicle.FifthWheelOffset
            ElseIf _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
                fifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
            End If

            With _Vehicle

                If VehicleFrontPermissible > (.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle) Then
                    vehicleFront = (VehicleFrontPermissible - (.FrontAxleUnladen +
                                    VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle)) /
                                    ((_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                    (fifthWheelOffset / _Vehicle.WheelbaseTheoretical))
                Else
                    vehicleFront = 0
                End If
                'vehicleFront = Math.Floor(vehicleFront)

                If VehicleRearPermissible > (.RearAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle) Then
                    vehicleRear = (VehicleRearPermissible - (.RearAxleUnladen +
                                    VehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle)) /
                                    ((_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                    ((.WheelbaseTheoretical - fifthWheelOffset) / .WheelbaseTheoretical))
                Else
                    vehicleRear = 0
                End If
                'vehicleRear = Math.Floor(vehicleRear)

            End With

            With _Trailer1(0)

                If LATrailer1PermissibleRear > .RearAxleUnladen Then
                    trailerRear = (LATrailer1PermissibleRear - .RearAxleUnladen) /
                                    ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical)
                Else
                    trailerRear = 0
                End If
                'trailerRear = Math.Floor(trailerRear)

            End With

            If CombinationTotalPermissible > (_Vehicle.TotalUnladen + _Trailer1.Item(0).TotalUnladen) Then
                total = CombinationTotalPermissible - (_Vehicle.TotalUnladen + _Trailer1.Item(0).TotalUnladen)
            Else
                total = 0
            End If

            If vehicleFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
            End If
            If vehicleRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
            End If
            If trailerRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailerRear)
            End If
            If total >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, total)
            End If

            vehiclePayload = 0
            trailer1Payload = maximisedPayload
            trailer2Payload = 0

        ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerDrawbar Then

            Dim vehicleFront, vehicleRear, trailerFront, trailerRear, total As Double
            Dim vehicleInternalBodyLength, trailer1InternalBodyLength As Double
            Dim drawbar As FileLoadingTrailerDrawbar = _Trailer1(0)

            If drawbar.Source = SourceTypes.Standard Then
                trailer1InternalBodyLength = drawbar.PayloadLength
            ElseIf drawbar.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer1InternalBodyLength = drawbar.Accessories.Body.ActualInternalLength
            End If

            With _Vehicle

                If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

                    vehicleInternalBodyLength = .Accessories.Body.ActualInternalLength

                    If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then

                        If VehicleFrontPermissible > .FrontAxleUnladen Then
                            vehicleFront = (VehicleFrontPermissible - .FrontAxleUnladen) /
                                            ((vehicleInternalBodyLength /
                                            (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            (.PayloadCOGToWheelbaseTheoreticalEnd / .WheelbaseTheoretical))
                        Else
                            vehicleFront = 0
                        End If

                        If VehicleRearPermissible > .RearAxleUnladen Then
                            vehicleRear = (VehicleRearPermissible - .RearAxleUnladen) /
                                            ((vehicleInternalBodyLength /
                                            (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))
                        Else
                            vehicleRear = 0
                        End If

                    Else
                        vehicleFront = 0
                        vehicleRear = 0
                    End If

                Else
                    vehicleFront = 0
                    vehicleRear = 0
                End If

            End With

            'vehicleFront = Math.Floor(vehicleFront)
            'vehicleRear = Math.Floor(vehicleRear)

            With drawbar

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                    .Source = SourceTypes.Standard Then

                    If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then

                        If LATrailer1PermissibleFront > .FrontAxleUnladen Then
                            trailerFront = (LATrailer1PermissibleFront - .FrontAxleUnladen) /
                                            ((trailer1InternalBodyLength /
                                            (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            (.PayloadCOGToWheelbaseTheoreticalEnd / .WheelbaseTheoretical))
                        Else
                            trailerFront = 0
                        End If

                        If LATrailer1PermissibleRear > .RearAxleUnladen Then
                            trailerRear = (LATrailer1PermissibleRear - .RearAxleUnladen) /
                                            ((trailer1InternalBodyLength /
                                            (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))
                        Else
                            trailerRear = 0
                        End If

                    Else
                        trailerFront = 0
                        trailerRear = 0
                    End If

                Else
                    trailerFront = 0
                    trailerRear = 0
                End If

                'trailerFront = Math.Floor(trailerFront)
                'trailerRear = Math.Floor(trailerRear)

            End With

            If CombinationTotalPermissible > (_Vehicle.TotalUnladen + drawbar.TotalUnladen) Then
                total = CombinationTotalPermissible - (_Vehicle.TotalUnladen + drawbar.TotalUnladen)
            Else
                total = 0
            End If

            If vehicleFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
            End If
            If vehicleRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
            End If
            If trailerFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailerFront)
            End If
            If trailerRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailerRear)
            End If
            If total >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, total)
            End If

            If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then
                vehiclePayload = Math.Round(maximisedPayload * (vehicleInternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)))
            Else
                vehiclePayload = 0
            End If

            trailer1Payload = maximisedPayload - vehiclePayload
            trailer2Payload = 0

        ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then

            Dim vehicleFront, vehicleRear, trailerRear, total As Double
            Dim vehicleInternalBodyLength, trailer1InternalBodyLength As Double

            If _Trailer1(0).Source = SourceTypes.Standard Then
                trailer1InternalBodyLength = _Trailer1(0).PayloadLength
            ElseIf _Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer1InternalBodyLength = _Trailer1(0).Accessories.Body.ActualInternalLength
            End If

            With _Vehicle

                If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then

                    vehicleInternalBodyLength = .Accessories.Body.ActualInternalLength

                    If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then

                        If VehicleFrontPermissible > .FrontAxleUnladen Then
                            vehicleFront = (VehicleFrontPermissible - (.FrontAxleUnladen + VehicleAndPupTareTransferOntoVehicleFrontAxle)) /
                                            ((vehicleInternalBodyLength /
                                            (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            (.PayloadCOGToWheelbaseTheoreticalEnd / .WheelbaseTheoretical) -
                                            (trailer1InternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                            (.Accessories.Hitch.HorizontalGap / .WheelbaseTheoretical))
                        Else
                            vehicleFront = 0
                        End If

                        If VehicleRearPermissible > .RearAxleUnladen Then
                            vehicleRear = (VehicleRearPermissible - (.RearAxleUnladen + VehicleAndPupTareTransferOntoVehicleRearAxle)) /
                                            ((vehicleInternalBodyLength /
                                            (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical) +
                                            (trailer1InternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                            ((.WheelbaseTheoretical + .Accessories.Hitch.HorizontalGap) / .WheelbaseTheoretical))
                        Else
                            vehicleRear = 0
                        End If

                    Else
                        vehicleFront = 0
                        vehicleRear = 0
                    End If

                Else
                    vehicleFront = 0
                    vehicleRear = 0
                End If

                'vehicleFront = Math.Floor(vehicleFront)
                'vehicleRear = Math.Floor(vehicleRear)

            End With

            With _Trailer1(0)

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                        .Source = SourceTypes.Standard Then

                    If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then

                        If LATrailer1PermissibleRear > .RearAxleUnladen Then
                            trailerRear = (LATrailer1PermissibleRear - .RearAxleUnladen) /
                                            ((trailer1InternalBodyLength /
                                            (vehicleInternalBodyLength + trailer1InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))
                        Else
                            trailerRear = 0
                        End If

                    Else
                        trailerRear = 0
                    End If

                Else
                    trailerRear = 0
                End If

                'trailerRear = Math.Floor(trailerRear)

            End With

            If CombinationTotalPermissible > (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen) Then
                total = CombinationTotalPermissible - (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen)
            Else
                total = 0
            End If

            If vehicleFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
            End If
            If vehicleRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
            End If
            If trailerRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailerRear)
            End If
            If total >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, total)
            End If

            If vehicleInternalBodyLength + trailer1InternalBodyLength <> 0 Then
                vehiclePayload = Math.Round(maximisedPayload * (vehicleInternalBodyLength / (vehicleInternalBodyLength + trailer1InternalBodyLength)))
            Else
                vehiclePayload = 0
            End If

            trailer1Payload = maximisedPayload - vehiclePayload
            trailer2Payload = 0

        End If

    ElseIf _Trailer1.Count = 1 And
            _Trailer2.Count = 1 Then

        If TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink And
            TypeOf (_Trailer2(0)) Is FileLoadingTrailerSemi Then

            Dim x As FileLoadingTrailerInterlink = _Trailer1(0)

            Dim vehicleFront, vehicleRear, trailer1Rear, trailer2Rear, total As Double
            Dim trailer1InternalBodyLength, trailer2InternalBodyLength As Double
            Dim vehicleFifthWheelOffset As Double

            If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
                vehicleFifthWheelOffset = _Vehicle.FifthWheelOffset
            Else
                vehicleFifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
            End If

            If _Trailer1(0).Source = SourceTypes.Standard Then
                trailer1InternalBodyLength = _Trailer1(0).PayloadLength
            ElseIf _Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer1InternalBodyLength = _Trailer1(0).Accessories.Body.ActualInternalLength
            End If

            If _Trailer2(0).Source = SourceTypes.Standard Then
                trailer2InternalBodyLength = _Trailer2(0).PayloadLength
            ElseIf _Trailer2(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer2InternalBodyLength = _Trailer2(0).Accessories.Body.ActualInternalLength
            End If

            With _Vehicle

                If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                    If VehicleFrontPermissible > .FrontAxleUnladen Then

                        vehicleFront = (VehicleFrontPermissible - (.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle + VehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFront)) /
                                                            ((trailer1InternalBodyLength /
                                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                                            (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                                            (vehicleFifthWheelOffset / _Vehicle.WheelbaseTheoretical) +
                                                            (trailer2InternalBodyLength /
                                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                                            (Trailer2(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer2(0).WheelbaseTheoretical) *
                                                            (x.FifthWheelOffset / _Trailer1(0).WheelbaseTheoretical) *
                                                            (vehicleFifthWheelOffset / _Vehicle.WheelbaseTheoretical))

                    Else
                        vehicleFront = 0
                    End If

                    If VehicleRearPermissible > .RearAxleUnladen Then

                        vehicleRear = (VehicleRearPermissible - (.RearAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle + VehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRear)) /
                                        ((trailer1InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                        ((_Vehicle.WheelbaseTheoretical - vehicleFifthWheelOffset) / _Vehicle.WheelbaseTheoretical) +
                                        (trailer2InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (Trailer2(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer2(0).WheelbaseTheoretical) *
                                        (x.FifthWheelOffset / _Trailer1(0).WheelbaseTheoretical) *
                                        ((_Vehicle.WheelbaseTheoretical - vehicleFifthWheelOffset) / _Vehicle.WheelbaseTheoretical))

                    Else
                        vehicleRear = 0
                    End If

                Else
                    vehicleFront = 0
                    vehicleRear = 0
                End If

                'vehicleFront = Math.Floor(vehicleFront)
                'vehicleRear = Math.Floor(vehicleRear)

            End With

            With _Trailer1(0)

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                    .Source = SourceTypes.Standard Then

                    If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                        If LATrailer1PermissibleRear > .RearAxleUnladen Then

                            trailer1Rear = (LATrailer1PermissibleRear - (.RearAxleUnladen + VehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1Rear)) /
                                            ((trailer1InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical) +
                                            (trailer2InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            (_Trailer2(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer2(0).WheelbaseTheoretical) *
                                            ((.WheelbaseTheoretical - x.FifthWheelOffset) / .WheelbaseTheoretical))

                        Else
                            trailer1Rear = 0
                        End If

                    Else
                        trailer1Rear = 0
                    End If

                End If

                'trailer1Rear = Math.Floor(trailer1Rear)

            End With

            With _Trailer2(0)

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                    .Source = SourceTypes.Standard Then

                    If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                        If LATrailer2PermissibleRear > .RearAxleUnladen Then

                            trailer2Rear = (LATrailer2PermissibleRear - .RearAxleUnladen) /
                                            ((trailer2InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))

                        Else
                            trailer2Rear = 0
                        End If

                    Else
                        trailer2Rear = 0
                    End If

                End If

                'trailer2Rear = Math.Floor(trailer2Rear)

            End With

            If CombinationTotalPermissible > (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen + _Trailer2(0).TotalUnladen) Then
                total = CombinationTotalPermissible - (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen + _Trailer2(0).TotalUnladen)
            Else
                total = 0
            End If

            If vehicleFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
            End If
            If vehicleRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
            End If
            If trailer1Rear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailer1Rear)
            End If
            If trailer2Rear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailer2Rear)
            End If
            If total >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, total)
            End If

            vehiclePayload = 0

            If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then
                trailer1Payload = Math.Round(maximisedPayload * (trailer1InternalBodyLength / (trailer1InternalBodyLength + trailer2InternalBodyLength)))
            Else
                trailer1Payload = 0
            End If

            trailer2Payload = maximisedPayload - trailer1Payload

        ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi And
                TypeOf (_Trailer2(0)) Is FileLoadingTrailerPup Then

            Dim vehicleFront, vehicleRear, trailer1Rear, trailer2Rear, total As Double
            Dim trailer1InternalBodyLength, trailer2InternalBodyLength As Double
            Dim vehicleFifthWheelOffset As Double
            Dim trailer1hitchOffset As Double

            Dim x As FileLoadingTrailerSemi = _Trailer1(0)
            Dim y As FileLoadingTrailerPup = _Trailer2(0)

            If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
                vehicleFifthWheelOffset = _Vehicle.FifthWheelOffset
            Else
                vehicleFifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
            End If

            If x.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Hitch) = 1 Then
                trailer1hitchOffset = x.Accessories.Hitch.HorizontalGap
            Else
                trailer1hitchOffset = x.HitchOffset
            End If

            If _Trailer1(0).Source = SourceTypes.Standard Then
                trailer1InternalBodyLength = _Trailer1(0).PayloadLength
            ElseIf _Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer1InternalBodyLength = _Trailer1(0).Accessories.Body.ActualInternalLength
            End If

            If _Trailer2(0).Source = SourceTypes.Standard Then
                trailer2InternalBodyLength = _Trailer2(0).PayloadLength
            ElseIf _Trailer2(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer2InternalBodyLength = _Trailer2(0).Accessories.Body.ActualInternalLength
            End If

            With _Vehicle

                If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                    If VehicleFrontPermissible > .FrontAxleUnladen Then

                        vehicleFront = (VehicleFrontPermissible - (.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle + VehicleAndSemiAndPupTrailer2TareTransferOntoVehicleFront)) /
                                        ((trailer1InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                        (vehicleFifthWheelOffset / _Vehicle.WheelbaseTheoretical) -
                                        (trailer2InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (Trailer2(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer2(0).WheelbaseTheoretical) *
                                        (trailer1hitchOffset / _Trailer1(0).WheelbaseTheoretical) *
                                        (vehicleFifthWheelOffset / _Vehicle.WheelbaseTheoretical))

                    Else
                        vehicleFront = 0
                    End If

                    If VehicleRearPermissible > .RearAxleUnladen Then

                        vehicleRear = (VehicleRearPermissible - (.RearAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle + VehicleAndSemiAndPupTrailer2TareTransferOntoVehicleRear)) /
                                        ((trailer1InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                        ((_Vehicle.WheelbaseTheoretical - vehicleFifthWheelOffset) / _Vehicle.WheelbaseTheoretical) -
                                        (trailer2InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (Trailer2(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer2(0).WheelbaseTheoretical) *
                                        (trailer1hitchOffset / _Trailer1(0).WheelbaseTheoretical) *
                                        ((_Vehicle.WheelbaseTheoretical - vehicleFifthWheelOffset) / _Vehicle.WheelbaseTheoretical))

                    Else
                        vehicleRear = 0
                    End If

                Else
                    vehicleFront = 0
                    vehicleRear = 0
                End If

                'vehicleFront = Math.Floor(vehicleFront)
                'vehicleRear = Math.Floor(vehicleRear)

            End With

            With _Trailer1(0)

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                    .Source = SourceTypes.Standard Then

                    If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                        If LATrailer1PermissibleRear > .RearAxleUnladen Then

                            trailer1Rear = (LATrailer1PermissibleRear - (.RearAxleUnladen + VehicleAndSemiAndPupTrailer2TareTransferOntoTrailer1Rear)) /
                                            ((trailer1InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical) +
                                            (trailer2InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            (_Trailer2(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer2(0).WheelbaseTheoretical) *
                                            ((.WheelbaseTheoretical + trailer1hitchOffset) / .WheelbaseTheoretical))


                        Else
                            trailer1Rear = 0
                        End If

                    Else
                        trailer1Rear = 0
                    End If

                End If

                'trailer1Rear = Math.Floor(trailer1Rear)

            End With

            With _Trailer2(0)

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                    .Source = SourceTypes.Standard Then

                    If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                        If LATrailer2PermissibleRear > .RearAxleUnladen Then

                            trailer2Rear = (LATrailer2PermissibleRear - .RearAxleUnladen) /
                                            ((trailer2InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))

                        Else
                            trailer2Rear = 0
                        End If

                    Else
                        trailer2Rear = 0
                    End If

                End If

                'trailer2Rear = Math.Floor(trailer2Rear)

            End With

            If CombinationTotalPermissible > (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen + _Trailer2(0).TotalUnladen) Then
                total = CombinationTotalPermissible - (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen + _Trailer2(0).TotalUnladen)
            Else
                total = 0
            End If

            If vehicleFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
            End If
            If vehicleRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
            End If
            If trailer1Rear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailer1Rear)
            End If
            If trailer2Rear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailer2Rear)
            End If
            If total >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, total)
            End If

            vehiclePayload = 0

            If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then
                trailer1Payload = Math.Round(maximisedPayload * (trailer1InternalBodyLength / (trailer1InternalBodyLength + trailer2InternalBodyLength)))
            Else
                trailer1Payload = 0
            End If

            trailer2Payload = maximisedPayload - trailer1Payload

        ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi And
                TypeOf (_Trailer2(0)) Is FileLoadingTrailerDrawbar Then

            Dim vehicleFront, vehicleRear, trailer1Rear, trailer2Front, trailer2Rear, total As Double
            Dim trailer1InternalBodyLength, trailer2InternalBodyLength As Double
            Dim vehicleFifthWheelOffset As Double

            Dim x As FileLoadingTrailerSemi = _Trailer1(0)
            Dim y As FileLoadingTrailerDrawbar = _Trailer2(0)

            If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
                vehicleFifthWheelOffset = _Vehicle.FifthWheelOffset
            Else
                vehicleFifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
            End If

            If _Trailer1(0).Source = SourceTypes.Standard Then
                trailer1InternalBodyLength = _Trailer1(0).PayloadLength
            ElseIf _Trailer1(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer1InternalBodyLength = _Trailer1(0).Accessories.Body.ActualInternalLength
            End If

            If _Trailer2(0).Source = SourceTypes.Standard Then
                trailer2InternalBodyLength = _Trailer2(0).PayloadLength
            ElseIf _Trailer2(0).Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                trailer2InternalBodyLength = _Trailer2(0).Accessories.Body.ActualInternalLength
            End If

            With _Vehicle

                If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                    If VehicleFrontPermissible > .FrontAxleUnladen Then

                        vehicleFront = (VehicleFrontPermissible - (.FrontAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle)) /
                                        ((trailer1InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                        (vehicleFifthWheelOffset / _Vehicle.WheelbaseTheoretical))

                    Else
                        vehicleFront = 0
                    End If

                    If VehicleRearPermissible > .RearAxleUnladen Then

                        vehicleRear = (VehicleRearPermissible - (.RearAxleUnladen + VehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle)) /
                                        ((trailer1InternalBodyLength /
                                        (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                        (_Trailer1(0).PayloadCOGToWheelbaseTheoreticalEnd / _Trailer1(0).WheelbaseTheoretical) *
                                        ((_Vehicle.WheelbaseTheoretical - vehicleFifthWheelOffset) / _Vehicle.WheelbaseTheoretical))

                    Else
                        vehicleRear = 0
                    End If

                Else
                    vehicleFront = 0
                    vehicleRear = 0
                End If

                'vehicleFront = Math.Floor(vehicleFront)
                'vehicleRear = Math.Floor(vehicleRear)

            End With

            With _Trailer1(0)

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                    .Source = SourceTypes.Standard Then

                    If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                        If LATrailer1PermissibleRear > .RearAxleUnladen Then

                            trailer1Rear = (LATrailer1PermissibleRear - .RearAxleUnladen) /
                                            ((trailer1InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))

                        Else
                            trailer1Rear = 0
                        End If

                    Else
                        trailer1Rear = 0
                    End If

                End If

                'trailer1Rear = Math.Floor(trailer1Rear)

            End With

            With y

                If .Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Or
                    .Source = SourceTypes.Standard Then

                    If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then

                        If LATrailer2PermissibleFront > .FrontAxleUnladen Then
                            trailer2Front = (LATrailer2PermissibleFront - .FrontAxleUnladen) /
                                            ((trailer2InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            (.PayloadCOGToWheelbaseTheoreticalEnd / .WheelbaseTheoretical))

                        Else
                            trailer2Front = 0
                        End If

                        If LATrailer2PermissibleRear > .RearAxleUnladen Then

                            trailer2Rear = (LATrailer2PermissibleRear - .RearAxleUnladen) /
                                            ((trailer2InternalBodyLength /
                                            (trailer1InternalBodyLength + trailer2InternalBodyLength)) *
                                            ((.WheelbaseTheoretical - .PayloadCOGToWheelbaseTheoreticalEnd) / .WheelbaseTheoretical))

                        Else
                            trailer2Rear = 0
                        End If

                    Else
                        trailer2Front = 0
                        trailer2Rear = 0
                    End If

                Else
                    trailer2Front = 0
                    trailer2Rear = 0
                End If

                'trailer2Front = Math.Floor(trailer2Front)
                'trailer2Rear = Math.Floor(trailer2Rear)

            End With

            If CombinationTotalPermissible > (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen + _Trailer2(0).TotalUnladen) Then
                total = CombinationTotalPermissible - (_Vehicle.TotalUnladen + _Trailer1(0).TotalUnladen + _Trailer2(0).TotalUnladen)
            Else
                total = 0
            End If

            If vehicleFront >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleFront)
            End If
            If vehicleRear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, vehicleRear)
            End If
            If trailer1Rear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailer1Rear)
            End If
            If trailer2Front >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailer2Front)
            End If
            If trailer2Rear >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, trailer2Rear)
            End If
            If total >= 0 Then
                maximisedPayload = Math.Min(maximisedPayload, total)
            End If

            vehiclePayload = 0

            If trailer1InternalBodyLength + trailer2InternalBodyLength <> 0 Then
                trailer1Payload = Math.Round(maximisedPayload * (trailer1InternalBodyLength / (trailer1InternalBodyLength + trailer2InternalBodyLength)))
            Else
                trailer1Payload = 0
            End If

            trailer2Payload = maximisedPayload - trailer1Payload

        End If

    End If

End Sub
        
        */
    }

    function getMaximisedPayloadForChassis(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return getMaximisedLoad().vehiclePayload;
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getMaximisedLoad().trailer1Payload;
        } else {
            return getMaximisedLoad().trailer2Payload;
        }
    }

    function canMaximisePayloadForCombination() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return activeRig.getVehicle().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return activeRig.getTrailer1().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return activeRig.getVehicle().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE && activeRig.getTrailer1().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return activeRig.getTrailer1().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE && activeRig.getTrailer2().getPayloadHolder().getPayloadType() === config.PAYLOAD_TYPES.SIMPLE;

        }
        return true;
    }

    function noSimplePayloadObjectOverridden() {
        var noSimplePayloadOverridden = true;

        activeRig.getChassisObjects().forEach(function (chassisObject) {
            var tempPayloadObject = chassisObject.getPayloadHolder().getSimplePayloadObject();
            if (tempPayloadObject && tempPayloadObject.getSpecifyMassOverride() === true) {
                noSimplePayloadOverridden = false;
            }
        });

        return noSimplePayloadOverridden;
    }

    function getTrailerTareFront(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailerInstanceTareFront(activeOffer.getTrailer1(), originalOffer.getTrailer1());
        } else {
            return getTrailerInstanceTareFront(activeOffer.getTrailer2(), originalOffer.getTrailer2());
        }
    }

    function getTrailerTareRear(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailerInstanceTareRear(activeOffer.getTrailer1(), originalOffer.getTrailer1());
        } else {
            return getTrailerInstanceTareRear(activeOffer.getTrailer2(), originalOffer.getTrailer2());
        }
    }

    function getTrailerInstanceTareFront(trailer, originalTrailer) {

        if (trailer.getTareFrontOverride() === true) {
            return trailer.getTareFront();
        } else {
            if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                return originalTrailer.getTareFront();
            } else {
                return trailer.getTareFront();
            }
        }
    }
    
    function getTrailerInstanceTareRear(trailer, originalTrailer) {

        if (trailer.getTareRearOverride() === true) {
            return trailer.getTareRear();
        } else {
            if (trailer.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || trailer.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM) {
                return originalTrailer.getTareRear();
            } else {
                return trailer.getTareRear();
            }

        }
    }

    function getTrailerPermissibleFront(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            //return getTrailer1PermissibleFront();
            return curCombinationPermissibleVals.trailer1PermissibleFront
        } else {
            //return getTrailer2PermissibleFront();
            return curCombinationPermissibleVals.trailer2PermissibleFront;
        }
    }

    function getTrailer1PermissibleFront() {
        //if (activeOffer.getTrailer1().getPermissibleFrontOverride() === true) {
        //    return activeOffer.getTrailer1().getPermissibleFront();
        //} else {
        //    return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFront);
        //}
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
            var lesserOfTrailerFrontManufacturerRatings = activeRig.getTrailer1().getAxlesHolder().getLesserOfFrontAxleManufacturerRatings();

            return Math.min(lesserOfTrailerFrontManufacturerRatings, getTrailer1ExtremeFrontGroupBridgeFormulaPermissible());
        } else {
            var frontAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
            if (frontAxleGroup) {
                return Math.min(frontAxleGroup.getPermissibleSimplified(), getTrailer1ExtremeFrontGroupBridgeFormulaPermissible());
            }
            return 0;
        }
        
    }

    function getTrailer2PermissibleFront() {
        if (activeOffer.getTrailer2().getPermissibleFrontOverride() === true) {
            return activeOffer.getTrailer2().getPermissibleFront();
        } else {
            return evaluateLegislation(legislationVals.variableTypes.Trailer2PermissibleFront);
        }
    }

    function getTrailerPermissibleRear(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            //return getTrailer1PermissibleRear();
            return curCombinationPermissibleVals.trailer1PermissibleRear;
        } else {
            //return getTrailer2PermissibleRear();
            return curCombinationPermissibleVals.trailer2PermissibleRear
        }
    }

    function getTrailer1PermissibleRear() {


        //if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
        //    var lesserOfTrailerRearRearManufacturerRatings = activeRig.getTrailer1().getAxlesHolder().getLesserOfRearRearAxleManufacturerRatings();

        //    return Math.min(lesserOfTrailerRearRearManufacturerRatings, getTrailer1ExtremeRearDrivenGroupBridgeFormulaPermissible());
        //} else {
            var rearAxleGroup = activeRig.getTrailer1().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if (rearAxleGroup) {
                return Math.min(rearAxleGroup.getPermissibleSimplified(), getTrailer1ExtremeRearGroupBridgeFormulaPermissible());
            }
            return 0;
        //}

        
        //if (activeOffer.getTrailer1().getPermissibleRearOverride() === true) {
        //    return activeOffer.getTrailer1().getPermissibleRear();
        //} else {
        //    return evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRear);
        //}
    }

    function getTrailer2PermissibleRear() {
        //if (activeOffer.getTrailer2().getPermissibleRearOverride() === true) {
        //    return activeOffer.getTrailer2().getPermissibleRear();
        //} else {
        //    return evaluateLegislation(legislationVals.variableTypes.Trailer2PermissibleRear);
        //}
        //if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
        //    var lesserOfTrailerRearRearManufacturerRatings = activeRig.getTrailer2().getAxlesHolder().getLesserOfRearRearAxleManufacturerRatings();

        //    return Math.min(lesserOfTrailerRearRearManufacturerRatings, getTrailer2ExtremeRearGroupBridgeFormulaPermissible());
        //} else {
            var rearAxleGroup = activeRig.getTrailer2().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if (rearAxleGroup) {
                return Math.min(rearAxleGroup.getPermissibleSimplified(), getTrailer2ExtremeRearGroupBridgeFormulaPermissible());
            }
            return 0;
        //}
    }

    function getCombinationTotalPermissible(ignoreOverride) {
        

        var trailer1 = activeRig.getTrailer1(), trailer2 = activeRig.getTrailer2();

        if (trailer1 === null) {
            if (activeRig.getVehicle().getPermissibleTotalOverride() === false) {
                return getVehicleTotalPermissible(ignoreOverride);
            } else {
                return activeRig.getVehicle().getPermissibleTotal();
            }
        } else {
            if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
                return getGrossCombinationMass();
            } else {
                return Math.min(getGrossCombinationMass(), getCombinationTotalBridgeOrRegulationVal(ignoreOverride));
            }
        }/* else if (trailer1 !== null && trailer2 === null) {
            if (trailer1.getType() === config.TRAILER_TYPES.SEMI || trailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                return getVehicleAndSemiOrInterlinkTotalPermissible(ignoreOverride);
            } else if (trailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                return getVehicleAndDrawbarTotalPermissible(ignoreOverride);
                
            } else if (trailer1.getType() === config.TRAILER_TYPES.PUP) {
                return getVehicleAndPupTotalPermissible(ignoreOverride);
            }
        } else if (trailer1 !== null && trailer2 !== null) {
            if (trailer1.getType() === config.TRAILER_TYPES.INTERLINK && trailer2.getType() === config.TRAILER_TYPES.SEMI) {
                return getVehicleAndInterlinkAndSemiTotalPermissible(ignoreOverride);
            } else if (trailer1.getType() === config.TRAILER_TYPES.SEMI && trailer2.getType() === config.TRAILER_TYPES.PUP) {
                return 0;//#unfinished, need to implement getVehicleAndSemiAndPupTotalPermissible() when supporting road train;
            } else if (trailer1.getType() === config.TRAILER_TYPES.SEMI && trailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                return 0; //#unfinished, need to implement getVehicleAndSemiAndDrawbarTotalPermissible() when supporting road train;
            }
        }*/

        

        /*
            If Not _CombinationTotalPermissibleOverride Then

                If _Trailer1.Count = 0 Then

                    _CombinationTotalPermissible = VehicleTotalPermissible

                ElseIf _Trailer1.Count = 1 And
                        _Trailer2.Count = 0 Then

                    If TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerSemi Or
                        TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerInterlink Then

                        _CombinationTotalPermissible = VehicleAndSemiOrInterlinkTotalPermissible

                    ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerDrawbar Then

                        _CombinationTotalPermissible = VehicleAndDrawbarTotalPermissible

                    ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then

                        _CombinationTotalPermissible = VehicleAndPupTotalPermissible

                    End If

                ElseIf _Trailer1.Count = 1 And
                        _Trailer2.Count = 1 Then

                    If TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink And
                        TypeOf (_Trailer2(0)) Is FileLoadingTrailerSemi Then

                        _CombinationTotalPermissible = VehicleAndInterlinkAndSemiTotalPermissible

                    ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi And
                            TypeOf (_Trailer2(0)) Is FileLoadingTrailerPup Then

                        _CombinationTotalPermissible = VehicleAndSemiAndPupTotalPermissible

                    ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi And
                            TypeOf (_Trailer2(0)) Is FileLoadingTrailerDrawbar Then

                        _CombinationTotalPermissible = VehicleAndSemiAndDrawbarTotalPermissible

                    End If

                End If

            End If

            Return _CombinationTotalPermissible
        */
    }

    function getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() {
        //'calc turning moments for front
        //If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
        //Return ApplicationGlobals.TurningMomentsFront(_Trailer1.Item(0).FrontAxleUnladen,
        //                                        _Vehicle.FifthWheelOffset,
        //                                        _Vehicle.WheelbaseTheoretical)
        //ElseIf _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
        //Return ApplicationGlobals.TurningMomentsFront(_Trailer1.Item(0).FrontAxleUnladen,
        //                                        _Vehicle.Accessories.FifthWheel.HorizontalGap,
        //                                        _Vehicle.WheelbaseTheoretical)
        //Else
        //Return 0
        //End If
        //var fifthWheelOffset = 0;
        //if (getVehicle().getFifthWheelIncludedInTare() === config.VEHICLE_INCLUSION_TYPES.YES) {
        //    fifthWheelOffset = getVehicle().getFifthWhelOffset();
        //    return getTurningMomentsFront(getTrailer1().getUnladenFront(), fifthWheelOffset);
        //} else if (getTrailer1().getAccessoryHolder().getFifthWheel() !== null) {
        //    fifthWheelOffset = getTrailer1().getAccessoryHolder().getFifthWheel().getOffset();
        //    return getTurningMomentsFront(getTrailer1().getUnladenFront(), fifthWheelOffset);
        //}

        //return 0;

        //function getTurningMomentsFront(massValue, fifthWheelOffset) {
        //    return utils.getTurningMomentsFront(massValue, fifthWheelOffset, getVehicle().getWheelbaseTheoretical());
        //}
        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(activeRig.getTrailer1().getUnladenFront());
    }

    function calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(massValue) {
        var fifthWheelOffset = getFifthWheelOffset();
        if (activeRig.getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            //fifthWheelOffset = activeRig.getVehicle().getFifthWheelOffset();
            return getTurningMomentsFront(massValue, fifthWheelOffset);
        } else if (activeRig.getVehicle().getAccessoryHolder().getFifthWheel() !== null) {
            //fifthWheelOffset = activeRig.getVehicle().getAccessoryHolder().getFifthWheel().getOffset();
            return getTurningMomentsFront(massValue, fifthWheelOffset);
        }

        return 0;

        function getTurningMomentsFront(massValue, fifthWheelOffset) {
            return utils.getTurningMomentsFront(massValue, fifthWheelOffset, activeRig.getVehicle().getWheelbaseTheoretical());
        }
    }

    function getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() {
        return activeRig.getTrailer1().getUnladenFront() - getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() {
        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(activeRig.getTrailer1().getPayloadFront());
        //If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
        //Return ApplicationGlobals.TurningMomentsFront(_Trailer1.Item(0).FrontAxlePayload,
        //                                        _Vehicle.FifthWheelOffset,
        //                                        _Vehicle.WheelbaseTheoretical)
        //ElseIf _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
        //Return ApplicationGlobals.TurningMomentsFront(_Trailer1.Item(0).FrontAxlePayload,
        //                                        _Vehicle.Accessories.FifthWheel.HorizontalGap,
        //                                        _Vehicle.WheelbaseTheoretical)
        //Else
        //Return 0
        //End If
    }

    function getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() {
        return activeRig.getTrailer1().getPayloadFront() - getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndSemiOrInterlinkTotalGross() {
        return activeRig.getVehicle().getUnladenTotal() + activeRig.getTrailer1().getUnladenTotal() + activeRig.getTrailer1().getPayloadTotal();
    }

    function getVehicleAndSemiOrInterlinkVehicleFrontAxleGross() {
        /*
        Return _Vehicle.FrontAxleGross +
                VehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle +
                VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle
        */

        return getVehicleOnlyFrontGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle();


    }

    function getVehicleAndSemiOrInterlinkVehicleRearAxleGross() {
        /*
        Return _Vehicle.RearAxleGross +
                VehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle +
                VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle
        
        */
        return getVehicleOnlyRearGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle();
    }

    function getTrailerFrontGross(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1FrontGross();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return getTrailer2FrontGross();
        }
        return 0;
    }

    function getTrailer1FrontGross() {
            
        var tempTrailer1 = activeRig.getTrailer1();
        //return tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }

        /*
        Select Case CombinationType
            Case CombinationTypes.Vehicle
                Return 0
            Case CombinationTypes.VehicleAndDrawbar
                Dim x As FileLoadingTrailerDrawbar = _Trailer1(0)
                Return x.FrontAxleUnladen + x.FrontAxlePayload
            Case CombinationTypes.VehicleAndInterlinkAndSemi
                Return 0
            Case CombinationTypes.VehicleAndPup
                Return 0
            Case CombinationTypes.VehicleAndSemi
                Return 0
            Case CombinationTypes.VehicleAndSemiAndDrawbar
                Return 0
            Case CombinationTypes.VehicleAndSemiAndPup
                Return 0
            Case Else
                Return 0
        End Select
        */
    }

    function getTrailer2FrontGross() {

        var tempTrailer2 = activeRig.getTrailer2();
        
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;//#unfinished, need to support this case when adding road train support
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
        /*
        Select Case CombinationType
            Case CombinationTypes.Vehicle
                Return 0
            Case CombinationTypes.VehicleAndDrawbar
                Return 0
            Case CombinationTypes.VehicleAndInterlinkAndSemi
                Return 0
            Case CombinationTypes.VehicleAndPup
                Return 0
            Case CombinationTypes.VehicleAndSemi
                Return 0
            Case CombinationTypes.VehicleAndSemiAndDrawbar
                Dim x As FileLoadingTrailerDrawbar = _Trailer2(0)
                Return x.FrontAxleUnladen + x.FrontAxlePayload
            Case CombinationTypes.VehicleAndSemiAndPup
                Return 0
            Case Else
                Return 0
        End Select
        */
        
    }

    function getTrailerRearGross(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1RearGross();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return getTrailer2RearGross();
        }
        return 0;
    }

    function getTrailer1RearGross() {

        //#unfinished, only VEHICLE_AND_SEMI, VEHICLE_AND_DRAWBAR, VEHICLE_AND_PUP supported so far
        var tempTrailer1 = activeRig.getTrailer1();

        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return 0;    
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return tempTrailer1.getUnladenRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() + tempTrailer1.getPayloadRear() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
        /*
        Select Case CombinationType
            Case CombinationTypes.Vehicle
                Return 0
            Case CombinationTypes.VehicleAndDrawbar
                Dim x As FileLoadingTrailerDrawbar = _Trailer1(0)
                Return x.RearAxleUnladen + x.RearAxlePayload
            Case CombinationTypes.VehicleAndInterlinkAndSemi
                Dim x As FileLoadingTrailerInterlink = _Trailer1(0)
                Return x.RearAxleUnladen + VehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1Rear +
                        x.RearAxlePayload + VehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1Rear
            Case CombinationTypes.VehicleAndPup
                Dim x As FileLoadingTrailerPup = _Trailer1(0)
                Return x.RearAxleUnladen + x.RearAxlePayload
            Case CombinationTypes.VehicleAndSemi
                Return _Trailer1.Item(0).RearAxleUnladen + _Trailer1.Item(0).RearAxlePayload
            Case CombinationTypes.VehicleAndSemiAndDrawbar
                Dim x As FileLoadingTrailerSemi = _Trailer1(0)
                Return x.RearAxleUnladen + x.RearAxlePayload
            Case CombinationTypes.VehicleAndSemiAndPup
                Dim x As FileLoadingTrailerSemi = _Trailer1(0)
                Return x.RearAxleUnladen + VehicleAndSemiAndPupTrailer2TareTransferOntoTrailer1Rear +
                        x.RearAxlePayload + VehicleAndSemiAndPupTrailer2PayloadTransferOntoTrailer1Rear
            Case Else
                Return 0
        End Select
        */
    }

    function getTrailer2RearGross() {

        
        var tempTrailer2 = activeRig.getTrailer2();

        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear();
            default:
                return 0;
        }
        /*
        Select Case CombinationType
            Case CombinationTypes.Vehicle
                Return 0
            Case CombinationTypes.VehicleAndDrawbar
                Return 0
            Case CombinationTypes.VehicleAndInterlinkAndSemi
                Dim x As FileLoadingTrailerSemi = _Trailer2(0)
                Return x.RearAxleUnladen + x.RearAxlePayload
            Case CombinationTypes.VehicleAndPup
                Return 0
            Case CombinationTypes.VehicleAndSemi
                Return 0
            Case CombinationTypes.VehicleAndSemiAndDrawbar
                Dim x As FileLoadingTrailerDrawbar = _Trailer2(0)
                Return x.RearAxleUnladen + x.RearAxlePayload
            Case CombinationTypes.VehicleAndSemiAndPup
                Dim x As FileLoadingTrailerPup = _Trailer2(0)
                Return x.RearAxleUnladen + x.RearAxlePayload
            Case Else
                Return 0
        End Select
        */
    }

    function getCombinationType() {
        /*
        Public ReadOnly Property CombinationType() As CombinationTypes
    Get

        If Trailer1.Count = 0 And Trailer2.Count = 0 Then
            Return CombinationTypes.Vehicle
        ElseIf Trailer1.Count > 0 And Trailer2.Count = 0 Then
            If TypeOf (Trailer1(0)) Is FileLoadingTrailerDrawbar Then
                Return CombinationTypes.VehicleAndDrawbar
            ElseIf TypeOf (Trailer1(0)) Is FileLoadingTrailerPup Then
                Return CombinationTypes.VehicleAndPup
            ElseIf TypeOf (Trailer1(0)) Is FileLoadingTrailerInterlink Or TypeOf (Trailer1(0)) Is FileLoadingTrailerSemi Then
                Return CombinationTypes.VehicleAndSemi
            Else
                Return CombinationTypes.Unknown
            End If
        ElseIf Trailer1.Count > 0 And Trailer2.Count > 0 Then
            If TypeOf (Trailer1(0)) Is FileLoadingTrailerInterlink And TypeOf (Trailer2(0)) Is FileLoadingTrailerSemi Then
                Return CombinationTypes.VehicleAndInterlinkAndSemi
            ElseIf TypeOf (Trailer1(0)) Is FileLoadingTrailerSemi And TypeOf (Trailer2(0)) Is FileLoadingTrailerDrawbar Then
                Return CombinationTypes.VehicleAndSemiAndDrawbar
            ElseIf TypeOf (Trailer1(0)) Is FileLoadingTrailerSemi And TypeOf (Trailer2(0)) Is FileLoadingTrailerPup Then
                Return CombinationTypes.VehicleAndSemiAndPup
            Else
                Return CombinationTypes.Unknown
            End If
        Else
            Return CombinationTypes.Unknown
        End If

    End Get
End Property
        */
        var tempTrailer1 = activeRig.getTrailer1(), tempTrailer2 = activeRig.getTrailer2();


        if (tempTrailer1 === null && tempTrailer2 === null) {
            return config.COMBINATION_TYPES.VEHICLE;
        } else if (tempTrailer1 !== null && tempTrailer2 === null) {
            if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
                return config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR;
            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                return config.COMBINATION_TYPES.VEHICLE_AND_PUP;
            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK || tempTrailer1.getType() === config.TRAILER_TYPES.SEMI) {
                return config.COMBINATION_TYPES.VEHICLE_AND_SEMI;
            } else {
                return config.COMBINATION_TYPES.UNKNOWN;
            }


        } else if (tempTrailer1 !== null && tempTrailer2 !== null) {
            if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                return config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI;
            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI && tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR) {
                return config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR;
            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI && tempTrailer2.getType() === config.TRAILER_TYPES.PUP) {
                return config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP;
            } else {
                return config.COMBINATION_TYPES.UNKNOWN;
            }
        } else {
            return config.COMBINATION_TYPES.UNKNOWN;
        }
    }

    function getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload() {
        /*
            If VehicleFrontPermissible <> 0 Then
            Return (VehicleAndSemiOrInterlinkVehicleFrontAxleGross - VehicleFrontPermissible) / VehicleFrontPermissible * 100
        Else
            Return 0
        End If
        */
        if (curCombinationPermissibleVals.vehicleFrontPermissible !== 0) {
            return parseFloat(((getVehicleAndSemiOrInterlinkVehicleFrontAxleGross() - curCombinationPermissibleVals.vehicleFrontPermissible) / curCombinationPermissibleVals.vehicleFrontPermissible * 100).toFixed(8));
        } else {
            return 0;
        }
    }

    function getVehicleAndSemiOrInterlinkVehicleRearAxleOverload() {
        
        if (curCombinationPermissibleVals.vehicleRearPermissible !== 0) {
            return parseFloat(((getVehicleAndSemiOrInterlinkVehicleRearAxleGross() - curCombinationPermissibleVals.vehicleRearPermissible) / curCombinationPermissibleVals.vehicleRearPermissible * 100).toFixed(8));
        } else {
            return 0;
        }
    }

    function getVehicleAndSemiOrInterlinkTrailerRearAxleOverload() { 
        
        if (curCombinationPermissibleVals.trailer1PermissibleRear !== 0) {
            return parseFloat(((getVehicleAndSemiOrInterlinkTrailerRearAxleGross() - curCombinationPermissibleVals.trailer1PermissibleRear) / curCombinationPermissibleVals.trailer1PermissibleRear * 100).toFixed(8));
        } else {
            return 0;
        }
    }

    function getVehicleAndSemiOrInterlinkTrailerRearAxleGross() {
        return activeRig.getTrailer1().getUnladenRear() + activeRig.getTrailer1().getPayloadRear();
    }

    function getVehicleAndSemiOrInterlinkTotalOverload() {
        
        var trailer1 = activeRig.getTrailer1();

        if (activeRig.getVehicle().getGrossTotal() + trailer1.getUnladenRear() + trailer1.getPayloadRear() !== 0) {
            return (activeRig.getVehicle().getGrossTotal() + trailer1.getUnladenTotal() + trailer1.getPayloadTotal() - curCombinationPermissibleVals.combinationTotalPermissible) / curCombinationPermissibleVals.combinationTotalPermissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndTrailer1GrossTotal() {
        var trailer1 = activeRig.getTrailer1();

        return activeRig.getVehicle().getGrossTotal() + trailer1.getUnladenTotal() + trailer1.getPayloadTotal();
    }

    function getVehicleAndSemiOrInterlinkTotalPermissible(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        if (tempVehicle.getPermissibleTotalOverride() === false) {
            if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
                return Math.min(tempVehicle.getGCM(), (curCombinationPermissibleVals.vehicleFrontPermissible + curCombinationPermissibleVals.vehicleRearPermissible + curCombinationPermissibleVals.trailer1PermissibleRear))
            } else {
                if (!ignoreOverride) {
                    if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
                        return tempVehicle.getCombinationBridgeFormulaPermissible();
                    }
                }
                return evaluateLegislation(legislationVals.variableTypes.ComVehSemiPermissibleTotalSub);
            }
        } else {
            return tempVehicle.getPermissibleTotal();
        }

    }

    function getVehicleAndSemiOrInterlinkGroup1BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup2BridgeFormulaNumberOfAxles() {
        //var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        //var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        //var onlyCountDownAxles = true;

        //return tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles) +
        //    tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles) +
        //    tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        return getVehicleAndTrailer1ExtremeVehicleFrontTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup3BridgeFormulaNumberOfAxles() {
        //var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        //var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        //var onlyCountDownAxles = true;

        //return tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles) +
        //    tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup4BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup5BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup6BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup7BridgeFormulaNumberOfAxles() {
        //var tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        //var tempTrailer1AxlesHolder = activeRig.getTrailer1().getAxlesHolder();
        //var onlyCountDownAxles = true;

        //return tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT, onlyCountDownAxles) +
        //    tempVehicleAxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles) +
        //    tempTrailer1AxlesHolder.countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
        return getTrailer1ExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup8BridgeFormulaNumberOfAxles() {
        return getVehiclePusher1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup9BridgeFormulaNumberOfAxles() {
        return getVehiclePusher2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup10BridgeFormulaNumberOfAxles() {
        return getVehiclePusher3GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup11BridgeFormulaNumberOfAxles() {
        return getVehicleTag1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup12BridgeFormulaNumberOfAxles() {
        return getVehicleTag2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndSemiOrInterlinkGroup13BridgeFormulaNumberOfAxles() {
        return getVehicleTag3GroupBridgeFormulaNumberOfAxles();
    }

    

    function getVehicleAndSemiOrInterlinkGroup1BridgeFormulaDistance() {
        return getVehicleGroup1BridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup1BridgeFormulaDistanceNearest() {
        return getVehicleGroup1BridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance() {
        var axle1RearStartingPoint;

        var curVehicle = activeRig.getVehicle();
        var curTrailer1 = activeRig.getTrailer1();

        if (curTrailer1 !== null && (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
            var fifthWheelOffset = getFifthWheelOffset();
            return curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - fifthWheelOffset + curTrailer1.getKingpinToAxle1Rear() + curTrailer1.getAxle1RearToAxle2Rear() + curTrailer1.getAxle2RearToAxle3Rear() + curTrailer1.getAxle3RearToAxle4Rear();
        } else {
            return 0;
        }

    }

    function getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistanceNearest() {

        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistance);
    }

    function getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance() {

        //var tempVehicle = activeRig.getVehicle(),
        //    tempTrailer1 = activeRig.getTrailer1();

        //var axle1RearStartingPoint = (tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear());

        //if (tempTrailer1 !== null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
        //    return axle1RearStartingPoint - getFifthWheelOffset() + tempTrailer1.getKingpinToAxle1Rear() + tempTrailer1.getAxle1RearToAxle2Rear() + tempTrailer1.getAxle2RearToAxle3Rear() + tempTrailer1.getAxle3RearToAxle4Rear();
        //} else {
        //    return 0;
        //}
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest() {
        
        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance);
    }

    function getVehicleAndSemiOrInterlinkGroup4BridgeFormulaDistance() {
        return getVehicleExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup4BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup5BridgeFormulaDistance() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup5BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup6BridgeFormulaDistance() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup6BridgeFormulaDistanceNearest() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistance() {
        //var tempTrailer1 = activeRig.getTrailer1();

        //var frontMostAxleDownPositionAtRear = tempTrailer1.getAxlesHolder().getFrontMostDownAxlePositionAtRear();
        //var rearMostAxleDownPositionAtRear = tempTrailer1.getAxlesHolder().getRearMostDownAxlePositionAtRear();

        //var group13Distance = 0;


        //if (frontMostAxleDownPositionAtRear !== rearMostAxleDownPositionAtRear) {
        //    switch (frontMostAxleDownPositionAtRear) {
        //        case config.AXLE_POSITIONS.AXLE_1_REAR:
        //            switch (rearMostAxleDownPositionAtRear) {
        //                case config.AXLE_POSITIONS.AXLE_1_REAR:
        //                    //do nothing
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_2_REAR:
        //                    group13Distance += tempTrailer1.getAxle1RearToAxle2Rear();
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_3_REAR:
        //                    group13Distance += tempTrailer1.getAxle1RearToAxle2Rear() + tempTrailer1.getAxle2RearToAxle3Rear();
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_4_REAR:
        //                    group13Distance += tempTrailer1.getAxle1RearToAxle2Rear() + tempTrailer1.getAxle2RearToAxle3Rear() + tempTrailer1.getAxle3RearToAxle4Rear();
        //                    break;
        //            }
        //            break;
        //        case config.AXLE_POSITIONS.AXLE_2_REAR:
        //            switch (rearMostAxleDownPositionAtRear) {
        //                case config.AXLE_POSITIONS.AXLE_1_REAR:
        //                    //do nothing
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_2_REAR:
        //                    //do nothing
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_3_REAR:
        //                    group13Distance += tempTrailer1.getAxle2RearToAxle3Rear();
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_4_REAR:
        //                    group13Distance += tempTrailer1.getAxle2RearToAxle3Rear() + tempTrailer1.getAxle3RearToAxle4Rear();
        //                    break;
        //            }

        //            break;
        //        case config.AXLE_POSITIONS.AXLE_3_REAR:
        //            switch (rearMostAxleDownPositionAtRear) {
        //                case config.AXLE_POSITIONS.AXLE_1_REAR:
        //                    //do nothing
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_2_REAR:
        //                    //do nothing
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_3_REAR:
        //                    //do nothing
        //                    break;
        //                case config.AXLE_POSITIONS.AXLE_4_REAR:
        //                    group13Distance += tempTrailer1.getAxle3RearToAxle4Rear();
        //                    break;
        //            }
        //            break;
        //        case config.AXLE_POSITIONS.AXLE_4_REAR:
        //            //do nothing
        //            break;
        //    }
        //}


        //return group13Distance;
        return getTrailer1ExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistance);
    }

    function getVehicleAndSemiOrInterlinkGroup8BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup8BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup9BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup9BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup10BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup10BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup11BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup11BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup12BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup12BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndSemiOrInterlinkGroup13BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndSemiOrInterlinkGroup13BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    


    function getVehicleAndSemiOrInterlinkGroup1BridgeFormulaPermissible(ignoreOverride) {

        //return evaluateLegislation(legislationVals.variableTypes.ComVehSemiGroup1Permissible);
        return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndSemiOrInterlinkGroup2BridgeFormulaPermissible(ignoreOverride) {
        
        var tempVehicle = activeRig.getVehicle();

        if (ignoreOverride) {
            return evaluateLegislation(legislationVals.variableTypes.ComVehSemiGroup2Permissible);
        } else {
            if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
                return tempVehicle.getCombinationBridgeFormulaPermissible();
            } else {
                return evaluateLegislation(legislationVals.variableTypes.ComVehSemiGroup2Permissible);
            }
        }
    }

    function getVehicleAndSemiOrInterlinkGroup3BridgeFormulaPermissible(ignoreOverride) {
        return evaluateLegislation(legislationVals.variableTypes.ComVehSemiGroup3Permissible);
    }

    function getVehicleAndSemiOrInterlinkGroup4BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehSemiGroup4Permissible);
    }

    function getVehicleAndSemiOrInterlinkGroup5BridgeFormulaPermissible(ignoreOverride) {
        //var tempVehicle = activeRig.getVehicle();

        //var rearAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        //if (rearAxleGroup.getBridgeFormulaPermissibleOverride()) {
        //    return rearAxleGroup.getBridgeFormulaPermissible();
        //} else {
        //    var numRearAxles = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        //    if (numRearAxles === 1) {
        //        return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        //    } else {
        //        return evaluateLegislation(legislationVals.variableTypes.ComVehSemiGroup5Permissible);
        //    }
        //}
        return getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndSemiOrInterlinkGroup6BridgeFormulaPermissible() {
        var tempVehicle = activeRig.getVehicle();

        var frontAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        if (frontAxleGroup.getBridgeFormulaPermissibleOverride()) {
            return frontAxleGroup.getBridgeFormulaPermissible();
        } else {
            //var numFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
            //if (numFrontAxles === 1) {
            //    return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
            //} else {
                return evaluateLegislation(legislationVals.variableTypes.ComVehSemiGroup6Permissible);
            //}
        }
    }

    function getVehicleAndSemiOrInterlinkGroup7BridgeFormulaPermissible() { 
        return getTrailer1ExtremeRearGroupBridgeFormulaPermissible();
    }

    function getVehicleAndSemiOrInterlinkGroup8BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndSemiOrInterlinkGroup9BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndSemiOrInterlinkGroup10BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndSemiOrInterlinkGroup11BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndSemiOrInterlinkGroup12BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndSemiOrInterlinkGroup13BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    

    function getVehicleAndSemiOrInterlinkGroup1BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
    }

    function getVehicleAndSemiOrInterlinkGroup2BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenTotal() + tempTrailer1.getPayloadTotal();
    }

    function getVehicleAndSemiOrInterlinkGroup3BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyRearGross() - getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() -
                getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() +
                tempTrailer1.getUnladenTotal() + tempTrailer1.getPayloadTotal();
    }

    function getVehicleAndSemiOrInterlinkGroup4BridgeFormulaActual() {
        return getVehicleExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup5BridgeFormulaActual() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup6BridgeFormulaActual() {
        return getVehicleExtremeFrontGroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup7BridgeFormulaActual() {
        //var tempTrailer1 = activeRig.getTrailer1();

        //return tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
        return getTrailer1ExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup8BridgeFormulaActual() {
        return getVehiclePusher1GroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup9BridgeFormulaActual() {
        return getVehiclePusher2GroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup10BridgeFormulaActual() {
        return getVehiclePusher3GroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup11BridgeFormulaActual() {
        return getVehicleTag1GroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup12BridgeFormulaActual() {
        return getVehicleTag2GroupBridgeFormulaActual();
    }

    function getVehicleAndSemiOrInterlinkGroup13BridgeFormulaActual() {
        return getVehicleTag3GroupBridgeFormulaActual();
    }

    

    function getVehicleAndSemiOrInterlinkGroup1BridgeFormulaOverload() {
        var permissible = getVehicleAndSemiOrInterlinkGroup1BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndSemiOrInterlinkGroup1BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndSemiOrInterlinkGroup2BridgeFormulaOverload() {
        var permissible = getVehicleAndSemiOrInterlinkGroup2BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndSemiOrInterlinkGroup2BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndSemiOrInterlinkGroup3BridgeFormulaOverload() {
        var permissible = getVehicleAndSemiOrInterlinkGroup3BridgeFormulaPermissible();

        if (permissible !== 0) {
            return (getVehicleAndSemiOrInterlinkGroup3BridgeFormulaActual() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndSemiOrInterlinkGroup4BridgeFormulaOverload() {
        //return getVehicleExtremeRearGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup4BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup4BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup5BridgeFormulaOverload() {
        //return getVehicleExtremeRearDrivenGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup5BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup5BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup6BridgeFormulaOverload() {
        //return getVehicleExtremeFrontGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup6BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup6BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup7BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup7BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup7BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup8BridgeFormulaOverload() {
        //return getVehiclePusher1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup8BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup8BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup9BridgeFormulaOverload() {
        //return getVehiclePusher2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup9BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup9BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup10BridgeFormulaOverload() {
        //return getVehiclePusher3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup10BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup10BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup11BridgeFormulaOverload() {
        //return getVehicleTag1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup11BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup11BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup12BridgeFormulaOverload() {
        //return getVehicleTag2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup12BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup12BridgeFormulaActual);
    }

    function getVehicleAndSemiOrInterlinkGroup13BridgeFormulaOverload() {
        //return getVehicleTag3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndSemiOrInterlinkGroup13BridgeFormulaPermissible, getVehicleAndSemiOrInterlinkGroup13BridgeFormulaActual);
    }

    

    

    

    function getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle() {
        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle());
    }

    function getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle() {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();
        
        //return utils.getTurningMomentsFront(tempTrailer2.getUnladenFront(), tempTrailer1.getFifthWheelOffset(), tempTrailer1.getWheelbaseTheoretical());
        return calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(tempTrailer2.getUnladenFront());
    }

    function calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(massValue) {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        return utils.getTurningMomentsFront(massValue, tempTrailer1.getFifthWheelOffset(), tempTrailer1.getWheelbaseTheoretical());
    }

    function getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle() {
        return getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle() - getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() {
        return activeRig.getTrailer2().getUnladenFront() - getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle();
    }

    function getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle() {
        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle());
    }

    function getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle() {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        return utils.getTurningMomentsFront(tempTrailer2.getPayloadFront(), tempTrailer1.getFifthWheelOffset(), tempTrailer1.getWheelbaseTheoretical());
    }

    function getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle() {
        return getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle() - getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle() {
        return activeRig.getTrailer2().getPayloadFront() - getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle();
    }

    function getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload() { 

        var tempTrailer1 = activeRig.getTrailer1();

        if (curCombinationPermissibleVals.trailer1PermissibleRear !== 0) {
            return ((tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle()) - curCombinationPermissibleVals.trailer1PermissibleRear) / curCombinationPermissibleVals.trailer1PermissibleRear * 100;
        } else {
            return 0;
        }

    }

    function getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload() { 
        var tempTrailer2 = activeRig.getTrailer2();

        if (curCombinationPermissibleVals.trailer2PermissibleRear !== 0) {
            return (tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear() - curCombinationPermissibleVals.trailer2PermissibleRear) / curCombinationPermissibleVals.trailer2PermissibleRear * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndInterlinkAndSemiTotalGross() {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        return activeRig.getVehicle().getUnladenTotal() + tempTrailer1.getUnladenTotal() + tempTrailer2.getUnladenTotal() + tempTrailer1.getPayloadTotal() + tempTrailer2.getPayloadTotal();
    }

    function getVehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer() {
        return getVehicleOnlyRearGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle() +
            getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle();
    }

    function getVehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer() {
        /*
        Return _Vehicle.RearAxleGross +
                VehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle +
                VehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle
        */
        return getVehicleOnlyRearGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle();
    }

    function getVehicleAndInterlinkAndSemiTotalPermissible(ignoreOverride) {

        var tempVehicle = activeRig.getVehicle();

        if (tempVehicle.getPermissibleTotalOverride() === false) {
            if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
                return Math.min(tempVehicle.getGCM(), evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiSumOfAxles));
            } else {
                if (!ignoreOverride) {
                    if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
                        return tempVehicle.getCombinationBridgeFormulaPermissible();
                    }
                }
                return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiPermissibleTotalSub);
            }
        } else {
            return tempVehicle.getPermissibleTotal();
        }
    }

    function getVehicleAndInterlinkAndSemiVehicleFrontAxleGross() {
        return getVehicleOnlyFrontUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle() +
                getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndInterlinkAndSemiVehicleRearAxleGross() {
        return getVehicleOnlyRearGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle() +
            getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle();
    }
        
    function getVehicleAndInterlinkAndSemiInterlinkFrontAxleGross() {
        return activeRig.getTrailer1().getUnladenFront() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle() +
                getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload() {
        var vehicleFrontAxleGross = 0;

        vehicleFrontAxleGross = getVehicleOnlyFrontGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() +
                                getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle();

        if (curCombinationPermissibleVals.vehicleFrontPermissible !== 0) {
            return (getCombinationVehicleFrontGross() - curCombinationPermissibleVals.vehicleFrontPermissible) / curCombinationPermissibleVals.vehicleFrontPermissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndInterlinkAndSemiVehicleRearAxleOverload() {
        var vehicleRearAxleGross = 0;

        //vehicleRearAxleGross = getVehicleOnlyRearGross() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() +
        //                       getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle();

        vehicleRearAxleGross = getVehicleAndInterlinkAndSemiVehicleRearAxleGross();

        if (curCombinationPermissibleVals.vehicleRearPermissible !== 0) {
            return (getCombinationVehicleRearGross() - curCombinationPermissibleVals.vehicleRearPermissible) / curCombinationPermissibleVals.vehicleRearPermissible * 100;
        } else {
            return 0
        }
    }

    function getVehicleAndInterlinkAndSemiTotalOverload() {


        if (curCombinationPermissibleVals.combinationTotalPermissible !== 0) {

            var tempTrailer1 = activeRig.getTrailer1(), tempTrailer2 = activeRig.getTrailer2();

            return (getCombinationGrossTotal() -
                curCombinationPermissibleVals.combinationTotalPermissible) /
                curCombinationPermissibleVals.combinationTotalPermissible * 100;
        } else {
            return 0;
        }
    }

    function getCombinationGrossTotal() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return activeRig.getVehicle().getGrossTotal();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return activeRig.getVehicle().getGrossTotal() + activeRig.getTrailer1().getGrossTotal() + activeRig.getTrailer2().getGrossTotal();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return activeRig.getVehicle().getGrossTotal() + activeRig.getTrailer1().getGrossTotal();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
    }

    function getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2() {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        var tempTrailer1Body = tempTrailer1.getAccessoryHolder().getBody();

        if (tempTrailer1Body !== null) {
            return (tempTrailer1.getFrontOverhang() + tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() - tempTrailer2.getFrontOverhang()) -
                (getBodyGap(tempTrailer1.getChassisObjectName()) + tempTrailer1Body.getActualLength());

        } else if (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (tempTrailer1.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && utils.itemHasGraphic(tempTrailer1))) {
            return (tempTrailer1.getFrontOverhang() + tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() - tempTrailer2.getFrontOverhang()) - tempTrailer1.getFrontToStartOfTaper();
        }

        return 0;
    }

    function getVehicleAndInterlinkAndSemiSwingClearanceBetweenVehicleAndTrailer1() {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        if (tempTrailer1.getAccessoryHolder().getBody() !== null) {
            return (tempVehicle.getRearMostAccessoryToAxles() - getFifthWheelOffset()) - Math.sqrt(Math.pow(tempTrailer1.getFrontOverhang(), 2) + Math.pow((tempTrailer1.getAccessoryHolder().getBody().getActualWidth() / 2), 2));
        } else {
            return (tempVehicle.getRearMostAccessoryToAxles() - getFifthWheelOffset()) - Math.sqrt(Math.pow(tempTrailer1.getFrontOverhang(), 2) + Math.pow((tempTrailer1.getPayloadWidth() / 2), 2));
        }

        /*If myTrailer1.Source = SourceTypes.Standard Then
        Return (_Vehicle.RearMostAccessoryToAxles - vehicleFifthWheelOffset) -
                Math.Sqrt((myTrailer1.FrontOverhang ^ 2) + ((myTrailer1.PayloadWidth / 2) ^ 2))
        Else
        If myTrailer1.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
        Return (_Vehicle.RearMostAccessoryToAxles - vehicleFifthWheelOffset) -
                Math.Sqrt((myTrailer1.FrontOverhang ^ 2) + ((myTrailer1.Accessories.Body.ActualWidth / 2) ^ 2))
        Else
        Return (_Vehicle.RearMostAccessoryToAxles - vehicleFifthWheelOffset) -
                Math.Sqrt((myTrailer1.FrontOverhang ^ 2) + ((myTrailer1.PayloadWidth / 2) ^ 2))
        End If
        End If*/
    }

    function getVehicleAndInterlinkAndSemiProtrusionSwingClearanceBetweenVehicleAndTrailer1() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        if (tempTrailer1.getAccessoryHolder().getBody() !== null) {
            return (tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - getFifthWheelOffset()) - Math.sqrt(Math.pow(tempTrailer1.getFrontOverhang(), 2) + Math.pow((tempTrailer1.getAccessoryHolder().getBody().getActualWidth() / 2), 2));
        } else {
            return (tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - getFifthWheelOffset()) - Math.sqrt(Math.pow(tempTrailer1.getFrontOverhang(), 2) + Math.pow((tempTrailer1.getPayloadWidth() / 2), 2));
        }
    }

    function getVehicleAndInterlinkAndSemiSwingClearanceBetweenTrailer1AndTrailer2() {

        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        if (tempTrailer2.getAccessoryHolder().getBody() !== null) {
            return (tempTrailer1.getFrontOverhang() + tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() - tempTrailer1.getFrontOfTrailerToRightMostPointOfPayloadDeck()) -
                Math.sqrt(Math.pow(tempTrailer2.getFrontOverhang(), 2) + Math.pow((tempTrailer2.getAccessoryHolder().getBody().getActualWidth() / 2), 2));
        } else {
            return (tempTrailer1.getFrontOverhang() + tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() - tempTrailer1.getFrontOfTrailerToRightMostPointOfPayloadDeck()) -
                Math.sqrt(Math.pow(tempTrailer2.getFrontOverhang(), 2) + Math.pow((tempTrailer2.getPayloadWidth() / 2), 2));
        }
        /*
        If myTrailer2.Source = SourceTypes.Standard Then
            Return (myTrailer1.FrontOverhang + myTrailer1.WheelbaseTheoretical -
                    myTrailer1.FifthWheelOffset - myTrailer1.FrontOfTrailerToRightMostPointOfPayloadDeck) -
                    Math.Sqrt((myTrailer2.FrontOverhang ^ 2) +
                                ((myTrailer2.PayloadWidth / 2) ^ 2))
        Else
            If myTrailer2.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Body) = 1 Then
                Return (myTrailer1.FrontOverhang + myTrailer1.WheelbaseTheoretical -
                        myTrailer1.FifthWheelOffset - myTrailer1.FrontOfTrailerToRightMostPointOfPayloadDeck) -
                        Math.Sqrt((myTrailer2.FrontOverhang ^ 2) +
                                    ((myTrailer2.Accessories.Body.ActualWidth / 2) ^ 2))
            Else
                Return (myTrailer1.FrontOverhang + myTrailer1.WheelbaseTheoretical -
                        myTrailer1.FifthWheelOffset - myTrailer1.FrontOfTrailerToRightMostPointOfPayloadDeck) -
                        Math.Sqrt((myTrailer2.FrontOverhang ^ 2) +
                                    ((myTrailer2.PayloadWidth / 2) ^ 2))
            End If
        End If
        */
    }

    function getStartingPointVehicleAxle3Rear() {
        var tempVehicle = activeRig.getVehicle();
        return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
    }

    function getStartingPointTrailer1Axle1Rear() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        let combinationType = getCombinationType();
        if(combinationType === config.COMBINATION_TYPES.VEHICLE_AND_SEMI || combinationType === config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI) {
            return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() + tempTrailer1.getKingpinToAxle1Rear();
        } else if(combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP) {
            return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getHitchPointToAxle1Rear();
        }
    }

    function getStartingPointTrailer1Axle4Rear() {
        var tempTrailer1 = activeRig.getTrailer1();
        return getStartingPointTrailer1Axle1Rear() + tempTrailer1.getAxle1RearToAxle2Rear() + tempTrailer1.getAxle2RearToAxle3Rear() + tempTrailer1.getAxle3RearToAxle4Rear();
    }

    function getStartingPointTrailer2Axle1Rear() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();
        return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                            tempVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() - tempTrailer1.getFrontOverhang() +
                            tempTrailer1.getFrontOfTrailerToWheelbaseTheoreticalEnd() - tempTrailer1.getFifthWheelOffset() +
                            tempTrailer2.getKingpinToAxle1Rear();
    }

    function getVehicleAndInterlinkAndSemiXDimension() {
        return getStartingPointTrailer1Axle1Rear() - getStartingPointVehicleAxle3Rear();

    }

    function getVehicleAndInterlinkAndSemiYDimension() {
        return getStartingPointTrailer2Axle1Rear() - getStartingPointTrailer1Axle4Rear();
    }
    
    function getBridgeFormulaDistanceNearest(bridgeDistenceFormulaFunc) {
        var distance = bridgeDistenceFormulaFunc();
        if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
            if (evaluateLegislation(legislationVals.variableTypes['BridgeDistanceRounded0.1M']) === 1) {
                if (distance % 100 === 0) {
                    return distance;
                } else {
                    return (Math.floor(distance / 100) + 1) * 100;
                }
            } else {
                // check if distance greater than 120 inches as values below that are not rounded in US bridge methood 
                if (parseFloat(distance.toFixed(1)) > 3048) {
                    //round to nearest foot
                    if (distance % 304.8 === 0) {
                        return distance;
                    } else {
                        var subFootRemainder = distance % 304.8;
                        if (parseFloat(subFootRemainder.toFixed(1))  < 152.4) {
                            //round down
                            return distance - subFootRemainder;
                        } else {
                            //round up
                            return distance - subFootRemainder + 304.8;
                        }

                    }
                } else {
                    return distance;
                }
            }
        } else {
            return distance;
        }

    }
    
    function getVehicleAndInterlinkAndSemiGroup1BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup2BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleFrontTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup3BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1AndTrailer2ExtremeVehicleFrontTrailer2RearBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup4BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1ExtremeVehicleRearTrailer1RearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup5BridgeFormulaNumberOfAxles() {
        return getVehicleAndTrailer1AndTrailer2ExtremeVehicleRearTrailer2RearBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup6BridgeFormulaNumberOfAxles() {
        return getTrailer1AndTrailer2ExtremeTrailer1RearTrailer2RearBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup7BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup8BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup9BridgeFormulaNumberOfAxles() {
        return getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup10BridgeFormulaNumberOfAxles() {
        return getTrailer1ExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup11BridgeFormulaNumberOfAxles() {
        return getTrailer2ExtremeRearGroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup12BridgeFormulaNumberOfAxles() {
        return getVehiclePusher1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup13BridgeFormulaNumberOfAxles() {
        return getVehiclePusher2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup14BridgeFormulaNumberOfAxles() {
        return getVehiclePusher3GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup15BridgeFormulaNumberOfAxles() {
        return getVehicleTag1GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup16BridgeFormulaNumberOfAxles() {
        return getVehicleTag2GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup17BridgeFormulaNumberOfAxles() {
        return getVehicleTag3GroupBridgeFormulaNumberOfAxles();
    }

    function getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistanceNearest() {

        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistance() {

        var tempVehicle = activeRig.getVehicle();

        return tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() +
                tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
    }

    function getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistance() {
        
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        
        var vehicleFifthWheelOffset = getFifthWheelOffset();

        return tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() -
                vehicleFifthWheelOffset + tempTrailer1.getWheelbaseTheoretical() + tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance() {
        var axle1RearStartingPoint;

        var curVehicle = activeRig.getVehicle();
        var curTrailer1 = activeRig.getTrailer1();
        var curTrailer2 = activeRig.getTrailer2();

        return curVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + curVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() + curTrailer1.getWheelbaseTheoretical() -
                    curTrailer1.getFifthWheelOffset() + curTrailer2.getWheelbaseTheoretical() + curTrailer2.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistance() {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        var vehicleFifthWheelOffset = getFifthWheelOffset();
        
        return getVehicleAxle1RearStartingPoint() - vehicleFifthWheelOffset + tempTrailer1.getWheelbaseTheoretical() + tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        var vehicleFifthWheelOffset = getFifthWheelOffset();

        return getVehicleAxle1RearStartingPoint() - vehicleFifthWheelOffset + tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() + tempTrailer2.getWheelbaseTheoretical() + tempTrailer2.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAxle1RearStartingPoint() {
        var tempVehicle = activeRig.getVehicle();
        
        return tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() -
                                    tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear();
    }

    function getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistance() {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        var axle1RearToWheelbaseEnd = tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getKingpinToAxle1Rear();

        return axle1RearToWheelbaseEnd - tempTrailer1.getFifthWheelOffset() + tempTrailer2.getWheelbaseTheoretical() + tempTrailer2.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAndInterlinkAndSemiGroup7BridgeFormulaDistance() {
        return getVehicleExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup7BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup8BridgeFormulaDistance() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup8BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup9BridgeFormulaDistance() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup9BridgeFormulaDistanceNearest() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistance() {
        return getTrailer1ExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistance() {
        return getTrailer2ExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistance);
    }

    function getVehicleAndInterlinkAndSemiGroup12BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup12BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup13BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup13BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup14BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup14BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup15BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup15BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup16BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup16BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup17BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndInterlinkAndSemiGroup17BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndInterlinkAndSemiGroup1BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiGroup1Permissible);
        return getVehicleExtremeGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup2BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiGroup2Permissible);
    }

    function getVehicleAndInterlinkAndSemiGroup3BridgeFormulaPermissible(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        if (ignoreOverride) {
            return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiGroup3Permissible);
        } else {
            if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
                return tempVehicle.getCombinationBridgeFormulaPermissible();
            } else {
                return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiGroup3Permissible);
            }
        }
    }

    function getVehicleAndInterlinkAndSemiGroup4BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiGroup4Permissible);
    }

    function getVehicleAndInterlinkAndSemiGroup5BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiGroup5Permissible);
    }

    function getVehicleAndInterlinkAndSemiGroup6BridgeFormulaPermissible() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehIntSemiGroup6Permissible);
    }

    function getVehicleAndInterlinkAndSemiGroup7BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup8BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup9BridgeFormulaPermissible(ignoreOverride) {
        return getVehicleExtremeFrontGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup10BridgeFormulaPermissible(ignoreOverride) {
        return getTrailer1ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup11BridgeFormulaPermissible(ignoreOverride) {
        return getTrailer2ExtremeRearGroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup12BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup13BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup14BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup15BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup16BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup17BridgeFormulaPermissible(ignoreOverride) {
        //return evaluateLegislation(legislationVals.variableTypes.PermissibleSingleAxleWeight);
        return getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverride);
    }

    function getVehicleAndInterlinkAndSemiGroup1BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() +
                getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle() +
                getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle();
    }

    function getVehicleAndInterlinkAndSemiGroup2BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle() +
                getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() +
                getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle();
    }

    function getVehicleAndInterlinkAndSemiGroup3BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1(),
            tempTrailer2 = activeRig.getTrailer2();

        return getVehicleOnlyFrontGross() + getVehicleOnlyRearGross() + tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() +
                getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle() +
                tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() +
                getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle() + tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear();
    }

    function getVehicleAndInterlinkAndSemiGroup4BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        return getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() +
                getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle() +
                tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle();
    }

    function getVehicleAndInterlinkAndSemiGroup5BridgeFormulaActual() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1(),
            tempTrailer2 = activeRig.getTrailer2();

        return getVehicleOnlyRearUnladen() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() +
                getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle() +
                tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() +
                getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle() + tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear();
    }

    function getVehicleAndInterlinkAndSemiGroup6BridgeFormulaActual() {
        var tempTrailer1 = activeRig.getTrailer1(),
            tempTrailer2 = activeRig.getTrailer2();

        return tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() +
                getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle() + tempTrailer2.getUnladenRear() + tempTrailer2.getPayloadRear();
    }

    function getVehicleAndInterlinkAndSemiGroup7BridgeFormulaActual() {
        return getVehicleExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup8BridgeFormulaActual() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup9BridgeFormulaActual() {
        return getVehicleExtremeFrontGroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup10BridgeFormulaActual() {
        return getTrailer1ExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup11BridgeFormulaActual() {
        return getTrailer2ExtremeRearGroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup12BridgeFormulaActual() {
        return getVehiclePusher1GroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup13BridgeFormulaActual() {
        return getVehiclePusher2GroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup14BridgeFormulaActual() {
        return getVehiclePusher3GroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup15BridgeFormulaActual() {
        return getVehicleTag1GroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup16BridgeFormulaActual() {
        return getVehicleTag2GroupBridgeFormulaActual();
    }

    function getVehicleAndInterlinkAndSemiGroup17BridgeFormulaActual() {
        return getVehicleTag3GroupBridgeFormulaActual();
    }

    function getBridgeFormulaOverload(bridgeFormulaGroupPermissibleFunc, bridgeFormulaGroupActualFunc) {
        var permissible = bridgeFormulaGroupPermissibleFunc();

        if (permissible !== 0) {
            return (bridgeFormulaGroupActualFunc() - permissible) / permissible * 100;
        } else {
            return 0;
        }
    }

    function getVehicleAndInterlinkAndSemiGroup1BridgeFormulaOverload() {
        //var permissible = getVehicleAndInterlinkAndSemiGroup1BridgeFormulaPermissible();

        //if (permissible !== 0) {
        //    return (getVehicleAndInterlinkAndSemiGroup1BridgeFormulaActual() - permissible) / permissible * 100;
        //} else {
        //    return 0;
        //}
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup1BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup1BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup2BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup2BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup2BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup3BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup3BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup3BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup4BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup4BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup4BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup5BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup5BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup5BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup6BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup6BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup6BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup7BridgeFormulaOverload() {
        //return getVehicleExtremeRearGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup7BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup7BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup8BridgeFormulaOverload() {
        //return getVehicleExtremeRearDrivenGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup8BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup8BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup9BridgeFormulaOverload() {
        //return getVehicleExtremeFrontGroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup9BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup9BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup10BridgeFormulaOverload() {
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup10BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup10BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup11BridgeFormulaOverload() {
        //return getVehiclePusher1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup11BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup11BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup12BridgeFormulaOverload() {
        //return getVehiclePusher2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup12BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup12BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup13BridgeFormulaOverload() {
        //return getVehiclePusher3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup13BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup13BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup14BridgeFormulaOverload() {
        //return getVehicleTag1GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup14BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup14BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup15BridgeFormulaOverload() {
        //return getVehicleTag2GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup15BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup15BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup16BridgeFormulaOverload() {
        //return getVehicleTag3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup16BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup16BridgeFormulaActual);
    }

    function getVehicleAndInterlinkAndSemiGroup17BridgeFormulaOverload() {
        //return getVehicleTag3GroupBridgeFormulaOverload();
        return getBridgeFormulaOverload(getVehicleAndInterlinkAndSemiGroup17BridgeFormulaPermissible, getVehicleAndInterlinkAndSemiGroup17BridgeFormulaActual);
    }

    function getVehicleAndDrawbarTrailerFrontAxleOverload() {

        var tempTrailer1 = activeRig.getTrailer1();

        if (curCombinationPermissibleVals.trailer1PermissibleFront !== 0) {
            return (tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront() - curCombinationPermissibleVals.trailer1PermissibleFront) / curCombinationPermissibleVals.trailer1PermissibleFront * 100;
        } else {
            return 0;
        }

    }

    function getVehicleAndDrawbarTrailerRearAxleOverload() { 

        var tempTrailer1 = activeRig.getTrailer1();

        if (curCombinationPermissibleVals.trailer1PermissibleRear !== 0) {
            return (tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() - curCombinationPermissibleVals.trailer1PermissibleRear) / curCombinationPermissibleVals.trailer1PermissibleRear * 100;
        } else {
            return 0;
        }

    }

    function getVehicleAndDrawbarVehicleFrontAxleGross() {
        return getVehicleOnlyFrontGross();
    }

    function getVehicleAndDrawbarVehicleRearAxleGross() {
        return getVehicleOnlyRearGross();
    }

    function getVehicleAndDrawbarTotalPermissible(ignoreOverride) {
        var tempVehicle = activeRig.getVehicle();

        if (tempVehicle.getPermissibleTotalOverride() === false) {
            if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
                return Math.min(tempVehicle.getGCM(), evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarSumOfAxles));
            } else {
                if (!ignoreOverride) {
                    if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
                        return tempVehicle.getCombinationBridgeFormulaPermissible();
                    }
                }
                return evaluateLegislation(legislationVals.variableTypes.ComVehDrawbarPermissibleTotalSub);
            }
        } else {
            return activeRig.getVehicle().getPermissibleTotal();
        }

        /*
        If _Common.Properties.ApplicationType = VehicleApplicationTypes.OffRoadOnly Or
        _Common.Properties.ApplicationType = VehicleApplicationTypes.AbnormalPermit Then

        Return Math.Min(_Vehicle.GCM, LAComVehDrawbarSumOfAxles)

        Else

        Return LAComVehDrawbarPermissibleTotalSub

        End If
        */
    }

    function getVehicleAndDrawbarTotalGross() {
        /*
        Return _Vehicle.TotalUnladen + _Vehicle.TotalPayload +
                _Trailer1(0).TotalUnladen + _Trailer1(0).TotalPayload
        */
        return activeRig.getVehicle().getUnladenTotal() + activeRig.getVehicle().getPayloadTotal() + activeRig.getTrailer1().getUnladenTotal() + activeRig.getTrailer1().getPayloadTotal();
    }

    function getVehicleAndDrawbarTotalOverload() {

        var tempTrailer1 = activeRig.getTrailer1();

        if (curCombinationPermissibleVals.combinationTotalPermissible !== 0) {
            return (activeRig.getVehicle().getGrossTotal() + tempTrailer1.getUnladenTotal() + tempTrailer1.getPayloadTotal() - curCombinationPermissibleVals.combinationTotalPermissible) / curCombinationPermissibleVals.combinationTotalPermissible * 100;
        } else {
            return 0;
        }
        
    }

    function getVehicleAndDrawbarTrailerFrontAxleGross() {

        var tempTrailer1 = activeRig.getTrailer1();

        return tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadFront();
        /*
        Dim x As FileLoadingTrailerDrawbar = _Trailer1(0)

        Return x.FrontAxleUnladen + x.FrontAxlePayload
        */
    }

    function getVehicleAndDrawbarTrailerRearAxleGross() {

        var tempTrailer1 = activeRig.getTrailer1();

        return tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();
        /*
        Dim x As FileLoadingTrailerDrawbar = _Trailer1(0)

        Return x.RearAxleUnladen + x.RearAxlePayload
        */
    }

    function getVehicleAndDrawbarGroup3BridgeFormulaDistanceNearest() {

        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndDrawbarGroup3BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndDrawbarGroup3BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndDrawbarGroup3BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndDrawbarGroup3BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndDrawbarGroup3BridgeFormulaDistance);
    }

    function getVehicleAndDrawbarGroup4BridgeFormulaDistanceNearest() {

        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndDrawbarGroup4BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndDrawbarGroup4BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndDrawbarGroup4BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndDrawbarGroup4BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndDrawbarGroup4BridgeFormulaDistance);
    }

    function getVehicleAndDrawbarGroup5BridgeFormulaDistanceNearest() {
        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndDrawbarGroup5BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndDrawbarGroup5BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndDrawbarGroup5BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndDrawbarGroup5BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndDrawbarGroup5BridgeFormulaDistance);
    }

    function getVehicleAndDrawbarGroup6BridgeFormulaDistanceNearest() {
        //if (evaluateLegislation(legislationVals.variableTypes.BridgeDistanceRounded) === 1) {
        //    if (getVehicleAndDrawbarGroup6BridgeFormulaDistance() % 100 === 0) {
        //        return getVehicleAndDrawbarGroup6BridgeFormulaDistance();
        //    } else {
        //        return (Math.floor(getVehicleAndDrawbarGroup6BridgeFormulaDistance() / 100) + 1) * 100;
        //    }
        //} else {
        //    return getVehicleAndDrawbarGroup6BridgeFormulaDistance();
        //}
        return getBridgeFormulaDistanceNearest(getVehicleAndDrawbarGroup6BridgeFormulaDistance);
    }
    

    function getVehicleAndDrawbarGroup3BridgeFormulaDistance() {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        return tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength() +
                tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + tempTrailer1.getWheelbaseTheoretical() + tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAndDrawbarGroup4BridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        return (tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear()) +
                tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToAxle2Front() + tempTrailer1.getAxle2FrontToAxle3Front();
    }

    function getVehicleAndDrawbarGroup5BridgeFormulaDistance() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        return (tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear()) +
                tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() +
                tempTrailer1.getWheelbaseTheoretical() + tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAndDrawbarGroup6BridgeFormulaDistance() {
        var tempTrailer1 = activeRig.getTrailer1();

        return tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + tempTrailer1.getWheelbaseTheoretical() + tempTrailer1.getWheelbaseTheoreticalEndToRearMostAxle();
    }

    function getVehicleAndDrawbarGroup7BridgeFormulaDistance() {
        return getVehicleExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup7BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup8BridgeFormulaDistance() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup8BridgeFormulaDistanceNearest() {
        return getVehicleExtremeRearDrivenGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup9BridgeFormulaDistance() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup9BridgeFormulaDistanceNearest() {
        return getVehicleExtremeFrontGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup10BridgeFormulaDistance() {
        return getTrailer1ExtremeFrontGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup10BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndDrawbarGroup10BridgeFormulaDistance);
    }

    function getVehicleAndDrawbarGroup11BridgeFormulaDistance() {
        return getTrailer1ExtremeRearGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup11BridgeFormulaDistanceNearest() {
        return getBridgeFormulaDistanceNearest(getVehicleAndDrawbarGroup11BridgeFormulaDistance);
    }

    function getVehicleAndDrawbarGroup12BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup12BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }
    
    function getVehicleAndDrawbarGroup13BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup13BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup14BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup14BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup15BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup15BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup16BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup16BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGroup17BridgeFormulaDistance() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistance();
    }

    function getVehicleAndDrawbarGroup17BridgeFormulaDistanceNearest() {
        return getVehiclePusherOrTagGroupBridgeFormulaDistanceNearest();
    }

    function getVehicleAndDrawbarGapBetweenVehicleAndTrailer() {
        if (activeRig.getTrailer1() !== null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR || activeRig.getTrailer1().getType() === config.TRAILER_TYPES.PUP)) {
            return getOverallLengthActual() - activeRig.getVehicle().getOverallLengthInclAccessoriesAndPayload() - (activeRig.getTrailer1().getOverallLengthInclAccessoriesAndPayload() - activeRig.getTrailer1().getDrawbarLength());
        } else {
            return 0;
        }
    }

    function getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer() {
        
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        
        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            return tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() - tempVehicle.getRearSwingClearanceRadius() - tempTrailer1.getFrontSwingClearanceRadius();
        }

        return 0;
        /*
        If _Trailer1.Count > 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerDrawbar Then

            Dim myTrailer As FileLoadingTrailerDrawbar = _Trailer1(0)

            Return myTrailer.DrawbarLength + myTrailer.FrontOverhang + myTrailer.Axle1FrontToWheelbaseTheoreticalStart - _Vehicle.RearSwingClearanceRadius - myTrailer.FrontSwingClearanceRadius

        End If

        Return 0
        */
    }

    function getInteraxleVehicleFrontToVehicleRear() {
        let tempVehicle = activeRig.getVehicle();
        let interaxleDistance = 0;

        
        
        let rearMostDownAxlePositionAtFront = tempVehicle.getAxlesHolder().getRearMostDownAxlePositionAtFront();
        let frontMostDownAxlePositionAtRear = tempVehicle.getAxlesHolder().getFrontMostDownAxlePositionAtRear();

        switch (rearMostDownAxlePositionAtFront) {
            case config.AXLE_POSITIONS.AXLE_1_FRONT:
                interaxleDistance += tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_2_FRONT:
                interaxleDistance += tempVehicle.getAxle2FrontToAxle1Rear();
                break;
        }
        
        switch (frontMostDownAxlePositionAtRear) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                //do nothing
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                interaxleDistance += tempVehicle.getAxle1RearToAxle2Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                interaxleDistance += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                interaxleDistance += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                break;

        }

        return interaxleDistance;
    }

    function getVehicleRearMostAxleToAxle1TrailerFront() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        
        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
            return tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang();
        }

        return 0;
    }

    function getVehicleRearMostAxleToAxle1Trailer1Rear() {

        if(activeRig.getTrailer1()) {
            let tempVehicle = activeRig.getVehicle();

            let rearMostDownAxlePositionAtRear = tempVehicle.getAxlesHolder().getRearMostDownAxlePositionAtRear();
            let distanceToLastDownAxleAtRear = tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear();
    
            switch (rearMostDownAxlePositionAtRear) {
                case config.AXLE_POSITIONS.AXLE_1_REAR:
                    //do nothing
                    break;
                case config.AXLE_POSITIONS.AXLE_2_REAR:
                    distanceToLastDownAxleAtRear += tempVehicle.getAxle1RearToAxle2Rear();
                    break;
                case config.AXLE_POSITIONS.AXLE_3_REAR:
                    distanceToLastDownAxleAtRear += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
                    break;
                case config.AXLE_POSITIONS.AXLE_4_REAR:
                    distanceToLastDownAxleAtRear += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                    break;
    
            }


            return getStartingPointTrailer1Axle1Rear() - distanceToLastDownAxleAtRear;
        }
        return 0;
    }

    function getTrailer1RearMostAxleToAxle1Trailer2Rear() {
        if(activeRig.getTrailer1() && activeRig.getTrailer2()) {
            return getStartingPointTrailer2Axle1Rear() - getStartingPointTrailer1Axle4Rear();
        }
        return 0;
    }

    function getTrailer1RearOverhangTotal() {
        
        var trailer1 = activeRig.getTrailer1();
        return getTrailerInstanceRearOverhangTotal(trailer1);
        //if (trailer1 !== null) {
        //    //if (trailer1.getType() !== config.TRAILER_TYPES.PUP) {
        //    return trailer1.getOverallLengthInclAccessoriesAndPayload() - trailer1.getOverallLengthExclAccessories() + trailer1.getRearOverhang() - trailer1.getPortionOfOverallLengthOverhangingFront();
        //    //} else {
        //        //return trailer1.getOverallLengthInclAccessories() - trailer1.getOverallLengthExclAccessories() + trailer1.getRearOverhang() - trailer1.getPortionOfOverallLengthOverhangingFront() + trailer1.getDrawbarLength();
        //    //}
            
        //} else {
        //    return 0;
        //}

    }

    function getTrailer2RearOverhangTotal() {
        var trailer2 = activeRig.getTrailer2();
        return getTrailerInstanceRearOverhangTotal(trailer2);
    }

    function getTrailerInstanceRearOverhangTotal(trailer) {
        if (trailer !== null) {
            return trailer.getOverallLengthInclAccessoriesAndPayload() - trailer.getOverallLengthExclAccessories() + trailer.getRearOverhang() - trailer.getPortionOfOverallLengthOverhangingFront();
        } else {
            return 0;
        }
    }

    function getRearOverhangLegislationPercent() {
        var comVehWheelbaseLegislation = evaluateLegislation(legislationVals.variableTypes.ComVehWheelbaseLegislation);
        var comVehRearOverhangLegislation = evaluateLegislation(legislationVals.variableTypes.ComVehRearOverhangLegislation);
        if (comVehWheelbaseLegislation !== 0) {
            return comVehRearOverhangLegislation / comVehWheelbaseLegislation * 100;
        }
        return 0;
    }

    function getRearOverhangLegislation() {
        return evaluateLegislation(legislationVals.variableTypes.ComVehRearOverhangLegislation);
    }

    function getRearOverhangTotalForChassis(chassisObject) {
        return getRearOverhangTotalByPath(chassisObject.getChassisObjectName());
    }

    function getRearOverhangTotalByPath(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return activeRig.getVehicle().getRearOverhangTotal();
        } else {
            return getTrailerRearOverhangTotal(getChassisObjectFromPath(path));
        }
    }
    

    function getTrailerRearOverhangTotal(attrContext) {
        if (attrContext.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1RearOverhangTotal();
        } else {
            return getTrailer2RearOverhangTotal();
        }
    }

    function getVehicleAndSemiOrInterlinkGapBetweenVehicleAndTrailer() {

        var tempTrailer1 = activeRig.getTrailer1();
        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            var tempVeh = activeRig.getVehicle();
            return tempVeh.getFrontOverhang() + tempVeh.getAxle1FrontToWheelbaseTheoreticalStart() + tempVeh.getWheelbaseTheoretical() - getFifthWheelOffset() - tempTrailer1.getFrontOverhang() - tempVeh.getBumperToBackOfRearMostEquipment();
        } else {
            return 0;
        }

        /*
        If TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerInterlink Then
            Dim x As FileLoadingTrailerInterlink = _Trailer1.Item(0)

            If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
                Return _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - _Vehicle.Accessories.FifthWheel.HorizontalGap -
                        x.FrontOverhang - _Vehicle.BumperToBackOfRearMostAccessory
            Else
                Return _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - _Vehicle.FifthWheelOffset -
                        x.FrontOverhang - _Vehicle.BumperToBackOfRearMostAccessory
            End If
        ElseIf TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerSemi Then
            Dim x As FileLoadingTrailerSemi = _Trailer1.Item(0)

            If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
                Return _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - _Vehicle.Accessories.FifthWheel.HorizontalGap -
                        x.FrontOverhang - _Vehicle.BumperToBackOfRearMostAccessory
            Else
                Return _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - _Vehicle.FifthWheelOffset -
                        x.FrontOverhang - _Vehicle.BumperToBackOfRearMostAccessory
            End If
        Else
            Return 0
        End If
        */
    }

    function getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer() {

        if (activeRig.getTrailer1() !== null) {
            return (activeRig.getVehicle().getRearMostAccessoryToAxles() - getFifthWheelOffset()) - activeRig.getTrailer1().getFrontSwingClearanceRadius();
        }
        return 0;
        /*
        If _Trailer1.Count > 0 Then

            Dim vehicleFifthWheelOffset As Integer

            If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
                vehicleFifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
            Else
                vehicleFifthWheelOffset = _Vehicle.FifthWheelOffset
            End If

            Return (_Vehicle.RearMostAccessoryToAxles - vehicleFifthWheelOffset) - _Trailer1(0).FrontSwingClearanceRadius

        End If

        Return 0
        */
    }

    function getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer() {
        
        if (activeRig.getTrailer1() !== null) {
            return (activeRig.getVehicle().getRearMostProtrudingItemBehindCabToRearAxles() - getFifthWheelOffset()) - activeRig.getTrailer1().getFrontSwingClearanceRadius();
        }
        /*
        Dim vehicleFifthWheelOffset As Integer

        If _Trailer1.Count > 0 Then

            If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
                vehicleFifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
            Else
                vehicleFifthWheelOffset = _Vehicle.FifthWheelOffset
            End If

            Return (_Vehicle.RearMostProtrudingItemBehindCabToAxles - vehicleFifthWheelOffset) - _Trailer1(0).FrontSwingClearanceRadius

        End If

        Return 0
        */
    }

    function getVehicleFrontAxleGrossIncludingTrailer1PupTransfer() {
        return getVehicleOnlyFrontGross() + getVehicleAndPupTareTransferOntoVehicleFrontAxle() + getVehicleAndPupPayloadTransferOntoVehicleFrontAxle();
    }
    
    function getVehicleAndPupTareTransferOntoVehicleFrontAxle() {

        //var tempVehicle = activeRig.getVehicle();

        //var dist = 0;

        //dist = (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical()) - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getAccessoryHolder().getHitch().getOffset());

        //return utils.getTurningMomentsFront(activeRig.getTrailer1().getUnladenFront(), dist, tempVehicle.getWheelbaseTheoretical());
        return calculateTransferOntoVehicleFrontAxleWhenPup(activeRig.getTrailer1().getUnladenFront());
        /*
        Dim dist As Integer

        dist = (_Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart + _Vehicle.WheelbaseTheoretical) -
                (_Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart + _Vehicle.WheelbaseTheoretical + _Vehicle.Accessories.Hitch.HorizontalGap)

        'calc turning moments for front
        Return ApplicationGlobals.TurningMomentsFront(_Trailer1.Item(0).FrontAxleUnladen,
                                                dist,
                                                _Vehicle.WheelbaseTheoretical)
        */
    }

    function calculateTransferOntoVehicleFrontAxleWhenPup(weight) {
        var tempVehicle = activeRig.getVehicle();

        var dist = 0;

        dist = (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical()) - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset());

        return utils.getTurningMomentsFront(weight, dist, tempVehicle.getWheelbaseTheoretical());
    }

    function getVehicleAndPupPayloadTransferOntoVehicleFrontAxle() {

        //var tempVehicle = activeRig.getVehicle();

        //var dist = 0;

        //dist = (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical()) - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getAccessoryHolder().getHitch().getOffset());

        //return utils.getTurningMomentsFront(activeRig.getTrailer1().getPayloadFront(), dist, tempVehicle.getWheelbaseTheoretical());
        return calculateTransferOntoVehicleFrontAxleWhenPup(activeRig.getTrailer1().getPayloadFront());
        /*
        Dim dist As Integer

        dist = (_Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart + _Vehicle.WheelbaseTheoretical) -
                (_Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart + _Vehicle.WheelbaseTheoretical + _Vehicle.Accessories.Hitch.HorizontalGap)

        'calc turning moments for front
        Return ApplicationGlobals.TurningMomentsFront(_Trailer1.Item(0).FrontAxlePayload,
                                                dist,
                                                _Vehicle.WheelbaseTheoretical)
        */
    }

    function getVehicleRearAxleGrossIncludingTrailer1PupTransfer() {
        return getVehicleOnlyRearGross() + getVehicleAndPupTareTransferOntoVehicleRearAxle() + getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
    }

    function getVehicleAndPupTareTransferOntoVehicleRearAxle() {
        return activeRig.getTrailer1().getUnladenFront() - getVehicleAndPupTareTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndPupPayloadTransferOntoVehicleRearAxle() {
        return activeRig.getTrailer1().getPayloadFront() - getVehicleAndPupPayloadTransferOntoVehicleFrontAxle();
    }

    function getVehicleAndPupVehicleFrontAxleOverload() {

        if (curCombinationPermissibleVals.vehicleFrontPermissible !== 0) {
            var vehicleFrontAxleGross = getVehicleOnlyFrontGross() + getVehicleAndPupTareTransferOntoVehicleFrontAxle() + getVehicleAndPupPayloadTransferOntoVehicleFrontAxle();
            return (vehicleFrontAxleGross - curCombinationPermissibleVals.vehicleFrontPermissible) / curCombinationPermissibleVals.vehicleFrontPermissible * 100;
        } else {
            return 0;
        }
        
    }

    function getVehicleAndPupTotalOverload() {
        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();

        if (tempVehicle.getGrossTotal() + tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() !== 0) {
            return (tempVehicle.getGrossTotal() + tempTrailer1.getUnladenTotal() + tempTrailer1.getPayloadTotal() - curCombinationPermissibleVals.combinationTotalPermissible) / curCombinationPermissibleVals.combinationTotalPermissible * 100;
        } else {
            return 0;
        }
        
    }

    function getVehicleAndPupVehicleRearAxleOverload() {

        if (curCombinationPermissibleVals.vehicleRearPermissible !== 0) {
            var vehicleRearAxleGross = getVehicleOnlyRearGross() + getVehicleAndPupTareTransferOntoVehicleRearAxle() + getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
            return (vehicleRearAxleGross - curCombinationPermissibleVals.vehicleRearPermissible) / curCombinationPermissibleVals.vehicleRearPermissible * 100;
        } else {
            return 0;
        }

        /*
    Dim VehicleRearAxleGross As Double

        VehicleRearAxleGross = _Vehicle.RearAxleGross +
                                VehicleAndPupTareTransferOntoVehicleRearAxle +
                                VehicleAndPupPayloadTransferOntoVehicleRearAxle

        If VehicleRearPermissible <> 0 Then
            Return (VehicleRearAxleGross - VehicleRearPermissible) / VehicleRearPermissible * 100
        Else
            Return 0
        End If
    */
    }

    function getVehicleAndPupTrailerRearAxleOverload() {

        var tempTrailer1 = activeRig.getTrailer1();

        if (curCombinationPermissibleVals.trailer1PermissibleRear !== 0) {
            return (tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear() - curCombinationPermissibleVals.trailer1PermissibleRear) / curCombinationPermissibleVals.trailer1PermissibleRear * 100;
        } else {
            return 0;
        }      

    }

    //function getVehicleAndPupTotalPermissible(ignoreOverride) { 

    //    var tempVehicle = activeRig.getVehicle();

    //    if (tempVehicle.getPermissibleTotalOverride() === false) {
    //        if (activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.OFFROADONLY || activeOffer.getApplication().id === config.VEHICLE_APPLICATION_TYPES.ABNORMALPERMIT) {
    //            return Math.min(tempVehicle.getGCM(), evaluateLegislation(legislationVals.variableTypes.ComVehPupSumOfAxles));
    //        } else {
    //            if (!ignoreOverride) {
    //                if (tempVehicle.getCombinationBridgeFormulaPermissibleOverride()) {
    //                    return tempVehicle.getCombinationTotalPermissible();
    //                }
    //            }
    //            return evaluateLegislation(legislationVals.variableTypes.ComVehPupPermissibleTotalSub);
    //        }
    //    } else {
    //        return tempVehicle.getPermissibleTotal();
    //    }

    //    /*If _Common.Properties.ApplicationType = VehicleApplicationTypes.OffRoadOnly Or
    //       _Common.Properties.ApplicationType = VehicleApplicationTypes.AbnormalPermit Then

    //        Return Math.Min(_Vehicle.GCM, LAComVehPupSumOfAxles)

    //    Else

    //        Return LAComVehPupPermissibleTotalSub

    //    End If*/
    //}

    function getVehicleAndPupTotalGross() {
        var tempTrailer1 = activeRig.getTrailer1();

        return activeRig.getVehicle().getGrossTotal() + tempTrailer1.getUnladenTotal() + tempTrailer1.getPayloadTotal();
    }

    function getVehicleAndPupVehicleRearAxleGross() {
        //var tempTrailer1 = activeRig.getTrailer1();

        //return tempTrailer1.getUnladenRear() + tempTrailer1.getPayloadRear();

        var tempVehicle = activeRig.getVehicle();

        return getVehicleOnlyRearGross() + getVehicleAndPupTareTransferOntoVehicleRearAxle() + getVehicleAndPupPayloadTransferOntoVehicleRearAxle();
    /*
    Return _Vehicle.RearAxleGross +
                VehicleAndPupTareTransferOntoVehicleRearAxle +
                VehicleAndPupPayloadTransferOntoVehicleRearAxle
    */
    }

    function getVehicleAndPupTrailerRearOverhangTotal() {
        var tempTrailer1 = activeRig.getTrailer1();

        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            return tempTrailer1.getOverallLengthInclAccessoriesAndPayload() - tempTrailer1.getOverallLengthExclAccessories() +
                        tempTrailer1.getRearOverhang() - tempTrailer1.getPortionOfOverallLengthOverhangingFront();
        } else {
            return 0;
        }

    /*
    If _Trailer1.Count = 1 Then

            If TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then

                Return _Trailer1(0).OverallLengthInclAccessories - _Trailer1(0).OverallLengthExclAccessories +
                        _Trailer1(0).RearOverhang - _Trailer1(0).PortionOfOverallLengthOverhangingFront

            Else
                Return 0
            End If

        Else
            Return 0
        End If
    */
    }

    function getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer() {

        var tempTrailer1 = activeRig.getTrailer1();

        if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            return tempTrailer1.getDrawbarLength() - activeRig.getVehicle().getRearSwingClearanceRadius();
        }

        return 0;

        /*
        If _Trailer1.Count > 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then

            Dim myTrailer As FileLoadingTrailerPup = _Trailer1(0)

            Return myTrailer.DrawbarLength - _Vehicle.RearSwingClearanceRadius

        End If

        Return 0
        */
    }
    
    function getOverallLengthLegalStart() {
        /*
        If LABullbarIncludedInLength = 1 Then
            Return 0
        Else
            Return _Vehicle.StartOfVehicle
        End If
        */
        if (evaluateLegislation(legislationVals.variableTypes.BullbarIncludedInLength) === 1) {
            return 0;
        } else {
            return activeRig.getVehicle().getStartOfVehicle();
        }
    }

    function getOverallLengthActual(includeBullbar) {
        //#unfinished, only supporting vehicle and single semi, drawbar, pup so far

        var tempTrailer1 = activeRig.getTrailer1(),
            tempTrailer2 = activeRig.getTrailer2(),
            tempVehicle = activeRig.getVehicle();

        if (tempTrailer1 === null) {
            //return tempVehicle.getOverallLengthInclAccessories();
            return tempVehicle.getOverallLengthInclAccessoriesAndPayload(includeBullbar);
        } else if (tempTrailer1 !== null && tempTrailer2 === null) {

            if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
                return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                            tempVehicle.getWheelbaseTheoretical() + tempTrailer1.getOverallLengthInclAccessoriesAndPayload() - (getFifthWheelOffset() + tempTrailer1.getFrontOverhang() + tempTrailer1.getPortionOfOverallLengthOverhangingFront());
            }else if(tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                            tempVehicle.getWheelbaseTheoretical() +
                            tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getOverallLengthInclAccessoriesAndPayload();
            }
        } else if (tempTrailer1 !== null && tempTrailer2 !== null) {

            if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
                return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                            tempVehicle.getWheelbaseTheoretical() - (getFifthWheelOffset() + tempTrailer1.getFrontOverhang() + tempTrailer1.getPortionOfOverallLengthOverhangingFront()) + (tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() - tempTrailer2.getFrontOverhang() + tempTrailer2.getOverallLengthInclAccessoriesAndPayload());

                /*
                    (_Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - vehicleFifthWheelOffset) +
                        (x.OverallLengthInclAccessories - x.FrontOverhang -
                        x.PortionOfOverallLengthOverhangingFront - x.FifthWheelOffset -
                        x.WheelbaseTheoreticalEndToRearMostAxle - x.RearOverhang) +
                        (y.OverallLengthInclAccessories - y.FrontOverhang -
                        y.PortionOfOverallLengthOverhangingFront)
                
                */

            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
                return tempVehicle.getStartOfVehicle() + tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                            tempVehicle.getWheelbaseTheoretical() +
                            tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getOverallLengthInclAccessoriesAndPayload();
            }
        }
        /*
        If _Trailer1.Count = 0 Then

            Return _Vehicle.OverallLengthInclAccessories

        ElseIf _Trailer1.Count = 1 And
                _Trailer2.Count = 0 Then

            If TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerSemi Then

                Dim x As FileLoadingTrailerSemi = _Trailer1.Item(0)

                If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then

                    Return _Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                            _Vehicle.WheelbaseTheoretical + x.OverallLengthInclAccessories - (_Vehicle.FifthWheelOffset + x.FrontOverhang + x.PortionOfOverallLengthOverhangingFront)

                Else

                    If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then

                        Return _Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                                _Vehicle.WheelbaseTheoretical + x.OverallLengthInclAccessories -
                                (_Vehicle.Accessories.FifthWheel.HorizontalGap + x.FrontOverhang + x.PortionOfOverallLengthOverhangingFront)

                    Else
                        Return 0
                    End If

                End If

            ElseIf TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerInterlink Then

                Dim x As FileLoadingTrailerInterlink = _Trailer1.Item(0)

                If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then

                    Return _Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                            _Vehicle.WheelbaseTheoretical + x.OverallLengthInclAccessories - (_Vehicle.FifthWheelOffset + x.FrontOverhang + x.PortionOfOverallLengthOverhangingFront)

                Else

                    If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then

                        Return _Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                                _Vehicle.WheelbaseTheoretical + x.OverallLengthInclAccessories -
                                (_Vehicle.Accessories.FifthWheel.HorizontalGap + x.FrontOverhang + x.PortionOfOverallLengthOverhangingFront)

                    Else
                        Return 0
                    End If

                End If

            ElseIf TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerDrawbar Then

                Dim x As FileLoadingTrailerDrawbar = _Trailer1.Item(0)

                If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Hitch) = 1 Then

                    Return _Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                            _Vehicle.WheelbaseTheoretical +
                            _Vehicle.Accessories.Hitch.HorizontalGap + x.OverallLengthInclAccessories

                End If

            ElseIf TypeOf (_Trailer1.Item(0)) Is FileLoadingTrailerPup Then

                Dim x As FileLoadingTrailerPup = _Trailer1.Item(0)

                If _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Hitch) = 1 Then

                    Return _Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                            _Vehicle.WheelbaseTheoretical +
                            _Vehicle.Accessories.Hitch.HorizontalGap + x.OverallLengthInclAccessories

                End If

            End If

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 1 Then

            If TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink And
                TypeOf (_Trailer2(0)) Is FileLoadingTrailerSemi Then

                Dim x As FileLoadingTrailerInterlink = _Trailer1(0)
                Dim y As FileLoadingTrailerSemi = _Trailer2(0)
                Dim vehicleFifthWheelOffset As Long

                If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
                    vehicleFifthWheelOffset = _Vehicle.FifthWheelOffset
                ElseIf _Vehicle.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.FifthWheel) = 1 Then
                    vehicleFifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
                Else
                    Return 0
                End If

                Return (_Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - vehicleFifthWheelOffset) +
                        (x.OverallLengthInclAccessories - x.FrontOverhang -
                        x.PortionOfOverallLengthOverhangingFront - x.FifthWheelOffset -
                        x.WheelbaseTheoreticalEndToRearMostAxle - x.RearOverhang) +
                        (y.OverallLengthInclAccessories - y.FrontOverhang -
                        y.PortionOfOverallLengthOverhangingFront)

            ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi And
                    TypeOf (_Trailer2(0)) Is FileLoadingTrailerPup Then

                Dim x As FileLoadingTrailerSemi = _Trailer1(0)
                Dim y As FileLoadingTrailerPup = _Trailer2(0)
                Dim fifthWheelOffset As Long
                Dim hitchOffset As Long

                If x.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Hitch) = 1 Then
                    hitchOffset = x.Accessories.Hitch.HorizontalGap
                Else
                    hitchOffset = x.HitchOffset
                End If

                If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
                    fifthWheelOffset = _Vehicle.FifthWheelOffset
                Else
                    fifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
                End If

                Return (_Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - fifthWheelOffset) +
                        (x.FrontOfTrailerToWheelbaseTheoreticalEnd - x.FrontOverhang - x.PortionOfOverallLengthOverhangingFront) +
                        hitchOffset + y.OverallLengthInclAccessories

            ElseIf TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi And
                    TypeOf (_Trailer2(0)) Is FileLoadingTrailerDrawbar Then

                Dim x As FileLoadingTrailerSemi = _Trailer1(0)
                Dim y As FileLoadingTrailerDrawbar = _Trailer2(0)
                Dim fifthWheelOffset As Long
                Dim hitchOffset As Long

                If _Vehicle.FifthWheelIncludedInTare = VehicleInclusionTypes.Yes Then
                    fifthWheelOffset = _Vehicle.FifthWheelOffset
                Else
                    fifthWheelOffset = _Vehicle.Accessories.FifthWheel.HorizontalGap
                End If

                If x.Accessories.AccessoryCount(FileLoadingAccessoryCollection.AccessoryTypes.Hitch) = 1 Then
                    hitchOffset = x.Accessories.Hitch.HorizontalGap
                Else
                    hitchOffset = x.HitchOffset
                End If

                Return (_Vehicle.StartOfVehicle + _Vehicle.FrontOverhang + _Vehicle.Axle1FrontToWheelbaseTheoreticalStart +
                        _Vehicle.WheelbaseTheoretical - fifthWheelOffset) +
                        (x.FrontOfTrailerToWheelbaseTheoreticalEnd - x.FrontOverhang - x.PortionOfOverallLengthOverhangingFront) +
                        hitchOffset + y.OverallLengthInclAccessories

            End If

        End If

        Return 0
        */
    }

    function getFifthWheelOffset() {
        var fifthWheelOffset = 0;
        if (activeRig.getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            fifthWheelOffset = activeRig.getVehicle().getFifthWheelOffset();
        } else if (activeRig.getVehicle().getAccessoryHolder().getFifthWheel() !== null) {
            fifthWheelOffset = activeRig.getVehicle().getAccessoryHolder().getFifthWheel().getOffset();
        }
        return fifthWheelOffset;
    }

    function getFifthWheelFrontOverhang() {
        var fifthWheelFontOverhang = 0;
        if (activeRig.getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            // do nothing
        } else if (activeRig.getVehicle().getAccessoryHolder().getFifthWheel() !== null) {
            fifthWheelFontOverhang = activeRig.getVehicle().getAccessoryHolder().getFifthWheel().getFrontOverhang();
        }
        return fifthWheelFontOverhang;
    }

    function getFifthWheelHeightFromGround() {
        var fifthWheelHeightGround = 0;
        var tempFifthWheel = activeRig.getVehicle().getAccessoryHolder().getFifthWheel();
        if (activeRig.getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            fifthWheelHeightGround = activeRig.getVehicle().getFifthWheelHeightFromGround();
        } else if (tempFifthWheel !== null) {
            fifthWheelHeightGround = activeRig.getVehicle().getChassisHeightMax() + tempFifthWheel.getSubframeHeight() + tempFifthWheel.getHeight();
        }
        return fifthWheelHeightGround;
    }

    function getFifthWheelHeight() {
        var fifthWheelHeight = 0;
        var tempFifthWheel = activeRig.getVehicle().getAccessoryHolder().getFifthWheel();
        if (activeRig.getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) {
            fifthWheelHeight = activeRig.getVehicle().getFifthWheelHeightFromGround() - activeRig.getVehicle().getChassisHeightMax();
        } else if (tempFifthWheel !== null) {
            fifthWheelHeight = tempFifthWheel.getSubframeHeight() + tempFifthWheel.getHeight();
        }
        return fifthWheelHeight;
    }

    function getTrailerCouplingPointFromFrontOfRig(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1CouplingPointFromFrontOfRig();
        } else {
            return getTrailer2CouplingPointFromFrontOfRig();
        }
    }

    function getTrailer1CouplingPointFromFrontOfRig() {
        var tempTrailer1 = activeRig.getTrailer1();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            return getTrailer1KingpinPointFromFrontOfRig();
        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            return getTrailer1HitchPointFromFrontOfRig();
        }
        return 0;
    }

    function getTrailer2CouplingPointFromFrontOfRig() {
        var tempTrailer2 = activeRig.getTrailer2();

        if (tempTrailer2.getType() === config.TRAILER_TYPES.SEMI || tempTrailer2.getType() === config.TRAILER_TYPES.INTERLINK) {
            return getTrailer2KingpinPointFromFrontOfRig();
        } else if (tempTrailer2.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer2.getType() === config.TRAILER_TYPES.PUP) {
            return getTrailer2HitchPointFromFrontOfRig();
        }
        return 0;
    }

    function getTrailerKingpinPointFromFrontOfRig(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1KingpinPointFromFrontOfRig();
        } else {
            return getTrailer2KingpinPointFromFrontOfRig();
        }
    }

    function getTrailer1KingpinPointFromFrontOfRig() {
        var tempVehicle = activeRig.getVehicle();

        return tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - getFifthWheelOffset();
    }

    function getTrailer2KingpinPointFromFrontOfRig() {
        var tempTrailer1 = activeRig.getTrailer1();
        return getTrailer1KingpinPointFromFrontOfRig() - tempTrailer1.getFrontOverhang() + tempTrailer1.getFrontOfTrailerToWheelbaseTheoreticalEnd() - tempTrailer1.getFifthWheelOffset();
    }

    function getTrailerHitchPointFromFrontOfRig(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1HitchPointFromFrontOfRig();
        } else {
            return getTrailer2HitchPointFromFrontOfRig();
        }
    }

    function getTrailer1HitchPointFromFrontOfRig() {
        var tempVehicle = activeRig.getVehicle();

        return tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset();
    }

    function getTrailer2HitchPointFromFrontOfRig() {
        var tempTrailer1 = activeRig.getTrailer1();
        return getTrailer1HitchPointFromFrontOfRig() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + tempTrailer1.getWheelbaseTheoretical() + tempTrailer1.getHitchOffset();
    }

    function getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(path) {
        return activeRig.getVehicle().getPortionOfOverallLengthOverhangingFront(path) + getTrailerFrontOverhangStart(path);
    }

    function getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFrontExclDrawbar(path) {
        return activeRig.getVehicle().getPortionOfOverallLengthOverhangingFront(path) + getTrailerFrontOverhangStartExclDrawbar(path);
    }

    function getTrailerFrontOverhangStart(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1FrontOverhangStart();
        } else {
            return getTrailer2FrontOverhangStart();
        }
    }

    function getTrailerFrontOverhangStartExclDrawbar(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1FrontOverhangStartExclDrawbar();
        } else {
            return getTrailer2FrontOverhangStart();
        }
    }

    function getTrailer1FrontOverhangStart() {
        var tempTrailer1 = activeRig.getTrailer1(),
            tempVehicle = activeRig.getVehicle();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            return tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() - tempTrailer1.getFrontOverhang();
        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            return tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength();
        }
        return 0;
        
    }

    function getTrailer1FrontOverhangStartExclDrawbar() {
        var tempTrailer1 = activeRig.getTrailer1(),
            tempVehicle = activeRig.getVehicle();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            return tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() - getFifthWheelOffset() - tempTrailer1.getFrontOverhang();
        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            return tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempVehicle.getAccessoryHolder().getHitch().getOffset();
        }
        return 0;

    }

    function getTrailer2FrontOverhangStart() {
        var tempTrailer1 = activeRig.getTrailer1(),
            tempTrailer2 = activeRig.getTrailer2();

        if (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
            return getTrailer1FrontOverhangStart() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart() + tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() - tempTrailer2.getFrontOverhang();
        } else if (tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR || tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            //return tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical() + tempVehicle.getAccessoryHolder().getHitch().getOffset() + tempTrailer1.getDrawbarLength();
            //#unfinished, need to support drawbar and pup when adding support for road train
        }
        return 0;
    }

    function getFrontOfChassisInclPortionOfVehicleOverhangingFront(path) {
        switch (getChassisTypeUsingPath(path)) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return activeRig.getVehicle().getPortionOfOverallLengthOverhangingFront();
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront(path);
        }
    }

    function getFrontOfChassis(path) {
        switch (getChassisTypeUsingPath(path)) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return 0;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getTrailerFrontOverhangStart(path);
        }
    }

    function getItemStartFromFrontOfRig(path, accessoryType, optionalItemId) {
        return getFrontOfChassis(path) + getItemStartFromFrontOfChassis(path, accessoryType, optionalItemId);
    }

    function getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(path, accessoryType, optionalItemId) {
        return getFrontOfChassisInclPortionOfVehicleOverhangingFront(path) + getItemStartFromFrontOfChassis(path, accessoryType, optionalItemId);
        //switch (accessoryType) {
        //    case config.ACCESSORY_TYPES.CRANE:
        //        switch (chassisObject.getChassisObjectName()) {
        //            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        
        //            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
        //                originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER1) + crane.getHorizontalGap();
        //                break;
        //            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
        //                originX = getTrailerFrontOverhangStart(config.CHASSIS_OBJECT_TYPES.TRAILER2) + crane.getHorizontalGap();
        //                break;
        //        }
        //        return tempAccessoryHolder.getCrane();
        //    case config.ACCESSORY_TYPES.OTHER:
        //        return getOtherFromPathById(path, getIdFromPath(path));
        //    case config.ACCESSORY_TYPES.FRIDGE:
        //        return tempAccessoryHolder.getFridge();
        //    case config.ACCESSORY_TYPES.TAILLIFT:
        //        return tempAccessoryHolder.getTaillift();
        //    case config.ACCESSORY_TYPES.HITCH:
        //        return tempAccessoryHolder.getHitch();
        //    case config.ACCESSORY_TYPES.FIFTH_WHEEL:
        //        return tempAccessoryHolder.getFifthWheel();
        //    case config.ACCESSORY_TYPES.BODY:
        //        return tempAccessoryHolder.getBody();
        //    case config.ACCESSORY_TYPES.PAYLOAD:
        //        return getPayloadFromPathById(path, getIdFromPath(path));
        //}
    }

    function getItemReferencePointFromFrontOfRigInclPortionOfVehicleOverhangingFront(path, accessoryType, optionalItemId) {
        return getFrontOfChassisInclPortionOfVehicleOverhangingFront(path) + getItemStartFromFrontOfChassis(path, accessoryType, optionalItemId) + getItemReferencePointOffsetFromItemStart(path, accessoryType, optionalItemId);
    }

    function getItemReferencePointOffsetFromItemStart(path, accessoryType, optionalItemId) {
        var chassisObject = getChassisObjectFromPath(path);

        switch (accessoryType) {
            case config.ACCESSORY_TYPES.CRANE:
                //no difference
                break;
            case config.ACCESSORY_TYPES.OTHER:
                //no difference
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                //no difference
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                var taillift = getTailliftFromPath(path);
                return taillift.getMechanismLength();
            case config.ACCESSORY_TYPES.HITCH:
                var hitch = getHitchFromPath(path);
                return hitch.getMountingLength() + hitch.getCouplingOffset();
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var fifthWheel = getFifthWheelFromPath(path);
                return fifthWheel.getLength() / 2;
            case config.ACCESSORY_TYPES.BODY:
                //no difference
                break;
            case config.ACCESSORY_TYPES.PAYLOAD:
                //no difference
                break;
        }
        return 0;
    }

    function getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(path, accessoryType, optionalItemId) {
        return getFrontOfChassisInclPortionOfVehicleOverhangingFront(path) + getItemStartFromFrontOfChassis(path, accessoryType, optionalItemId) + getItemDrawingStartOffsetFromItemStart(path, accessoryType, optionalItemId);
    }

    function getItemDrawingStartOffsetFromItemStart(path, accessoryType, optionalItemId) {
        return getItemReferencePointOffsetFromItemStart(path, accessoryType, optionalItemId);
        //var chassisObject = getChassisObjectFromPath(path);

        //switch (accessoryType) {
        //    case config.ACCESSORY_TYPES.CRANE:
        //        //no difference
        //        break;
        //    case config.ACCESSORY_TYPES.OTHER:
        //        //no difference
        //        break;
        //    case config.ACCESSORY_TYPES.FRIDGE:
        //        //no difference
        //        break;
        //    case config.ACCESSORY_TYPES.TAILLIFT:
        //        var taillift = getTailliftFromPath(path);
        //        return taillift.getMechanismLength();
        //    case config.ACCESSORY_TYPES.HITCH:
        //        var hitch = getHitchFromPath(path);
        //        return hitch.getMountingLength() + hitch.getCouplingOffset();
        //        break;
        //    case config.ACCESSORY_TYPES.FIFTH_WHEEL:
        //        var fifthWheel = getFifthWheelFromPath(path);
        //        return fifthWheel.getLength() / 2;
        //        break;
        //    case config.ACCESSORY_TYPES.BODY:
        //        //no difference
        //        break;
        //    case config.ACCESSORY_TYPES.PAYLOAD:
        //        //no difference
        //        break;
        //}
        //return 0;
    }

    function getItemStartFromFrontOfChassis(path, accessoryType, optionalItemId) {

        var chassisObject = getChassisObjectFromPath(path);

        switch (accessoryType) {
            case config.ACCESSORY_TYPES.CRANE:
                var crane = getCraneFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getBumperToBackOfCab() + crane.getHorizontalGap();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return crane.getHorizontalGap();
                }
                break;
            case config.ACCESSORY_TYPES.OTHER:
                var other = getOtherFromPathById(path, optionalItemId);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getBumperToBackOfCab() + other.getHorizontalGap();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return other.getHorizontalGap();
                }
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                var fridge = getFridgeFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return (chassisObject.getBumperToBackOfCab() + getBodyGap(chassisObject.getChassisObjectName())) - fridge.getProtrusion() - fridge.getFromFrontOfBody();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return getBodyGap(chassisObject.getChassisObjectName()) - fridge.getProtrusion() - fridge.getFromFrontOfBody();
                }
                break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                var taillift = getTailliftFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        if (chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                            return chassisObject.getOverallLength() + taillift.getFromBackOfBody() - taillift.getMechanismLength();
                        } else {
                            return chassisObject.getBumperToBackOfCab() + getBodyGap(chassisObject.getChassisObjectName()) + chassisObject.getAccessoryHolder().getBody().getActualLength() + taillift.getFromBackOfBody() - taillift.getMechanismLength();
                        }
                        
                        //vehicle.getBumperToBackOfCab() + tempAccessoryHolder.getBody().getHorizontalGap() + tempAccessoryHolder.getBody().getActualLength() + tempTaillift.getActualHorizontalCOG() + tempTaillift.getFromBackOfBody() - tempTaillift.getMechanismLength()
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return getBodyGap(chassisObject.getChassisObjectName()) + chassisObject.getAccessoryHolder().getBody().getActualLength() + taillift.getFromBackOfBody() - taillift.getMechanismLength();
                }
                break;
            case config.ACCESSORY_TYPES.HITCH:
                var hitch = getHitchFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getFrontOverhang() + chassisObject.getAxle1FrontToWheelbaseTheoreticalStart() + chassisObject.getWheelbaseTheoretical() + chassisObject.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + hitch.getOffset();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return 0;
                }
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var fifthWheel = getFifthWheelFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getFrontOverhang() + chassisObject.getAxle1FrontToWheelbaseTheoreticalStart() + chassisObject.getWheelbaseTheoretical() - getFifthWheelOffset() - getFifthWheelFrontOverhang();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return 0;
                }
                break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var hooklift = getHookliftFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getBumperToBackOfCab() + hooklift.getHorizontalGap();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return hooklift.getHorizontalGap();
                }
                break;
            case config.ACCESSORY_TYPES.BODY:
                var body = getBodyFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        //if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                        //    var tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
                        //    return chassisObject.getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + body.getHorizontalGap();
                        //} else {
                        //    return chassisObject.getBumperToBackOfCab() + body.getHorizontalGap();
                        //}
                        return chassisObject.getBumperToBackOfCab() + getBodyGap(chassisObject.getChassisObjectName());
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        //if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                        //    var tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
                        //    return tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + body.getHorizontalGap();
                        //} else {
                        //    return body.getHorizontalGap();
                        //}
                        return getBodyGap(chassisObject.getChassisObjectName());
                        
                }
                break;
            /*case config.ACCESSORY_TYPES.PAYLOAD:
                var payload = getPayloadFromPathById(path, optionItemId);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getBumperToBackOfCab() + body.getHorizontalGap();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return body.getHorizontalGap();
                }
                break;*/
        }
        return 0;
    }

    function getItemStartFromGround(path, accessoryType, optionalItemId) {
        var chassisObject = getChassisObjectFromPath(path);
        var originalChassisObject = getOriginalChassisObjectFromPath(path);
        var body;
        switch (accessoryType) {
            case config.ACCESSORY_TYPES.CRANE:
                var crane = getCraneFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getChassisHeightMax() + crane.getAboveBelowChassis();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return chassisObject.getChassisHeight() + crane.getAboveBelowChassis();
                }
                break;
            case config.ACCESSORY_TYPES.OTHER:
                var other = getOtherFromPathById(path, optionalItemId);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getChassisHeightMax() + other.getVerticalGap();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return chassisObject.getChassisHeight() + other.getVerticalGap();
                }
                break;
            case config.ACCESSORY_TYPES.FRIDGE:
                var fridge = getFridgeFromPath(path);
                body = getBodyFromPath(path);
                //switch (chassisObject.getChassisObjectName()) {
                //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                //        return chassisObject.getBumperToBackOfCab() + chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - fridge.getProtrusion();
                //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                //        return chassisObject.getAccessoryHolder().getBody().getHorizontalGap() - fridge.getProtrusion();
                //}
                return fridge.getVerticalGap() + body.getAboveBelowChassis() + body.getActualExternalHeight() - fridge.getMountingPosition() - fridge.getHeight();
                //switch (chassisObject.getChassisObjectName()) {
                //    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        
                //        //fridge.getVerticalGap() + tempBody.getAboveBelowChassis() + tempBody.getActualExternalHeight() - mountingPosition - height
                //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                //    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                //        return chassisObject.getChassisHeight() + fridge.getVerticalGap();
                //}
                //break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                var taillift = getTailliftFromPath(path);
                body = getBodyFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        if (chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                            if (taillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                                return chassisObject.getFloorHeight() + taillift.getAboveOrBelowFloor() - (taillift.getMechanismHeight() - (originalRig.getVehicle().getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
                            } else {
                                return chassisObject.getFloorHeight() + taillift.getAboveOrBelowFloor() - taillift.getMechanismHeight();
                            }
                        } else {
                            if (taillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                                return chassisObject.getChassisHeightRear() + body.getAboveBelowChassis() + body.getSubframeHeight() + body.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - (taillift.getMechanismHeight() - (originalRig.getVehicle().getAccessoryHolder().getBody().getSubstructureHeight() + originalRig.getVehicle().getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
                            } else {
                                return chassisObject.getChassisHeightRear() + body.getAboveBelowChassis() + body.getSubframeHeight() + body.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - taillift.getMechanismHeight();
                            }
                        }
                        
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        if (taillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                            return chassisObject.getChassisHeight() + body.getAboveBelowChassis() + body.getSubframeHeight() + body.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - (taillift.getMechanismHeight() - (originalChassisObject.getAccessoryHolder().getBody().getSubstructureHeight() + originalChassisObject.getAccessoryHolder().getTaillift().getAboveOrBelowFloor()));
                        } else {
                            return chassisObject.getChassisHeight() + body.getAboveBelowChassis() + body.getSubframeHeight() + body.getSubstructureHeight() + taillift.getAboveOrBelowFloor() - taillift.getMechanismHeight();
                        }
                }
                break;
            case config.ACCESSORY_TYPES.HITCH:
                var hitch = getHitchFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return hitch.getHeightFromGround() - hitch.getCouplingHeight();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return 0;
                }
                break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var fifthWheel = getFifthWheelFromPath(path);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getChassisHeightMax();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return 0;
                }
                break;
            case config.ACCESSORY_TYPES.BODY:
                body = getBodyFromPath(path);
                var tempHooklift;
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        if (body.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                            return chassisObject.getChassisHeightMax() - body.getBottomOfBodyToTopOfChassis();
                        } else if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                            tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
                            return chassisObject.getChassisHeightMax() + tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight() + body.getAboveBelowChassis();
                        } else {
                            return chassisObject.getChassisHeightMax() + body.getAboveBelowChassis();
                        }
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        if (body.getType() === config.BODY_TYPES.SERVICE /*|| tempTrailer1Body.getType() === config.BODY_TYPES.COMPACTOR || tempTrailer1Body.getType() === config.BODY_TYPES.BEAVERTAIL || tempTrailer1Body.getType() === config.BODY_TYPES.OTHER*/) {
                            return chassisObject.getChassisHeight() /*- body.getBottomOfBodyToTopOfChassis()*/;
                        } else if (body.getType() === config.BODY_TYPES.HOOKLIFT && body.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                            tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
                            return chassisObject.getChassisHeight() + tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight() + body.getAboveBelowChassis();
                        } else {
                            return chassisObject.getChassisHeight() + body.getAboveBelowChassis();
                        }
                }
                break;
            /*case config.ACCESSORY_TYPES.PAYLOAD:
                var payload = getPayloadFromPathById(path, optionItemId);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getBumperToBackOfCab() + body.getHorizontalGap();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return body.getHorizontalGap();
                }
                break;*/
        }
        return 0;
    }
    
    function getItemStartFromLeftOfChassis(path, accessoryType, optionalItemId) {

        var chassisObject = getChassisObjectFromPath(path);

        switch (accessoryType) {
            case config.ACCESSORY_TYPES.CRANE:
                var crane = getCraneFromPath(path);
                return getLeftOfChassisRearWidth(chassisObject) + crane.getFromLeftOfVehicleRearWidth();
            case config.ACCESSORY_TYPES.OTHER:
                var other = getOtherFromPathById(path, optionalItemId);
                return getLeftOfChassisRearWidth(chassisObject) + other.getFromLeftOfVehicleRearWidth();
            case config.ACCESSORY_TYPES.FRIDGE:
                var fridge = getFridgeFromPath(path);
                return getLeftOfBodyWidthExternal(chassisObject) + fridge.getFromLeftOfBodyWidthExternal();
            case config.ACCESSORY_TYPES.TAILLIFT:
                var taillift = getTailliftFromPath(path);
                return getLeftOfBodyWidthExternal(chassisObject) + taillift.getFromLeftOfBodyWidthExternal();
            case config.ACCESSORY_TYPES.HITCH:
                var hitch = getHitchFromPath(path);
                return getLeftOfChassisRearWidth(chassisObject) + hitch.getFromLeftOfVehicleRearWidth();
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var fifthWheel = getFifthWheelFromPath(path);
                return getLeftOfChassisRearWidth(chassisObject) + fifthWheel.getFromLeftOfVehicleRearWidth();
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var hooklift = getHookliftFromPath(path);
                return getLeftOfChassisRearWidth(chassisObject) + hooklift.getFromLeftOfVehicleRearWidth();
            case config.ACCESSORY_TYPES.BODY:
                var body = getBodyFromPath(path);
                return body.getActualWidth() / 2;
            /*case config.ACCESSORY_TYPES.PAYLOAD:
                var payload = getPayloadFromPathById(path, optionItemId);
                switch (chassisObject.getChassisObjectName()) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        return chassisObject.getBumperToBackOfCab() + body.getHorizontalGap();
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        return body.getHorizontalGap();
                }
                break;*/
        }
        return 0;
    }


    function getRighMostChassisPathRootForCurrentCombination() {
        let chassisObjects = activeRig.getChassisObjects();
        return chassisObjects[chassisObjects.length-1].getChassisObjectName();
    }

    function getBodyFromPath(path) {
        var tempBody = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempBody = activeRig.getVehicle().getBody();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempBody = activeRig.getTrailer1().getAccessoryHolder().getBody();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempBody = activeRig.getTrailer2().getAccessoryHolder().getBody();
        }
        return tempBody;
    }

    function getCraneFromPath(path) {
        var tempCrane = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempCrane = activeRig.getVehicle().getAccessoryHolder().getCrane();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempCrane = activeRig.getTrailer1().getAccessoryHolder().getCrane();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempCrane = activeRig.getTrailer2().getAccessoryHolder().getCrane();
        }
        return tempCrane;
    }

    function getOtherFromPathById(path, id) {
        var tempOther = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempOther = activeRig.getVehicle().getAccessoryHolder().getAccessoryById(id);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempOther = activeRig.getTrailer1().getAccessoryHolder().getAccessoryById(id);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempOther = activeRig.getTrailer2().getAccessoryHolder().getAccessoryById(id);
        }
        return tempOther;
    }

    

    /**
     * Get a hooklift accessory item from the vehicle/trailer using the path
     * @param {string} path - Path to the hooklift
     * @returns {object|null} - Returns model.hooklift object if it exists otherwise null
     */
    function getHookliftFromPath(path) {
        var tempHooklift = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempHooklift = activeRig.getVehicle().getAccessoryHolder().getHooklift();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempHooklift = activeRig.getTrailer1().getAccessoryHolder().getHooklift();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempHooklift = activeRig.getTrailer2().getAccessoryHolder().getHooklift();
        }
        return tempHooklift;
    }

    function getPayloadFromPathById(path, id) {
        var tempPayload = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempPayload = activeRig.getVehicle().getPayloadHolder().getPayloadById(id);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempPayload = activeRig.getTrailer1().getPayloadHolder().getPayloadById(id);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempPayload = activeRig.getTrailer2().getPayloadHolder().getPayloadById(id);
        }
        return tempPayload;
    }

    function getOriginalPayloadFromPathById(path, id) {
        var tempPayload = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempPayload = originalRig.getVehicle().getPayloadHolder().getPayloadById(id);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempPayload = originalRig.getTrailer1().getPayloadHolder().getPayloadById(id);
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempPayload = originalRig.getTrailer2().getPayloadHolder().getPayloadById(id);
        }
        return tempPayload;
    }

    function getFridgeFromPath(path) {
        var tempFridge = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempFridge = activeRig.getVehicle().getAccessoryHolder().getFridge();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempFridge = activeRig.getTrailer1().getAccessoryHolder().getFridge();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempFridge = activeRig.getTrailer2().getAccessoryHolder().getFridge();
        }
        return tempFridge;
    }

    function getTailliftFromPath(path) {
        var tempTaillift = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempTaillift = activeRig.getVehicle().getAccessoryHolder().getTaillift();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempTaillift = activeRig.getTrailer1().getAccessoryHolder().getTaillift();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempTaillift = activeRig.getTrailer2().getAccessoryHolder().getTaillift();
        }
        return tempTaillift;
    }

    function getFifthWheelFromPath(path) {
        var tempFifthWheel = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempFifthWheel = activeRig.getVehicle().getAccessoryHolder().getFifthWheel();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempFifthWheel = activeRig.getTrailer1().getAccessoryHolder().getFifthWheel();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempFifthWheel = activeRig.getTrailer2().getAccessoryHolder().getFifthWheel();
        }
        return tempFifthWheel;
    }

    function getHitchFromPath(path) {
        var tempHitch = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempHitch = activeRig.getVehicle().getAccessoryHolder().getHitch();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempHitch = activeRig.getTrailer1().getAccessoryHolder().getHitch();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempHitch = activeRig.getTrailer2().getAccessoryHolder().getHitch();
        }
        return tempHitch;
    }

    // function getHookliftFromPath(path) {
    //     var tempHooklift = null;
    //     if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
    //         tempHooklift = activeRig.getVehicle().getAccessoryHolder().getHooklift();
    //     } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
    //         tempHooklift = activeRig.getTrailer1().getAccessoryHolder().getHooklift();
    //     } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
    //         tempHooklift = activeRig.getTrailer2().getAccessoryHolder().getHooklift();
    //     }
    //     return tempHooklift;
    // }

    function getAxlesFromPath(path) {
        var tempAxles = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempAxles = activeRig.getVehicle().getAxlesHolder().getAxles();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempAxles = activeRig.getTrailer1().getAxlesHolder().getAxles();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempAxles = activeRig.getTrailer2().getAxlesHolder().getAxles();
        }
        return tempAxles;
    }

    function getAxleFromPath(path) {
        var tempAxle = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempAxle = activeRig.getVehicle().getAxlesHolder().getAxleById(getIdFromPath(path));
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempAxle = activeRig.getTrailer1().getAxlesHolder().getAxleById(getIdFromPath(path));
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempAxle = activeRig.getTrailer2().getAxlesHolder().getAxleById(getIdFromPath(path));
        }
        return tempAxle;
    }

    function getAxleFromAxleGroupPath(path) {
        var tempAxle = null;
        var pathParts = path.split('.');
        var axleNumber = pathParts[pathParts.length - 1];
        tempAxle = getAxleHolderFromPath(path).getAxleByNumber(Number(axleNumber));
        return tempAxle;
    }

    function getAxleGroupFromPath(path) {
        var tempAxle = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempAxle = activeRig.getVehicle().getAxlesHolder().getAxleGroupById(getIdFromPath(path));
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempAxle = activeRig.getTrailer1().getAxlesHolder().getAxleGroupById(getIdFromPath(path));
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempAxle = activeRig.getTrailer2().getAxlesHolder().getAxleGroupById(getIdFromPath(path));
        }
        return tempAxle;
    }

    function getFuelTankFromPath(path) {
        return activeRig.getVehicle().getFuelTankById(getIdFromPath(path));
    }

    function getAxlePositionFromPath(path) {
        if (path.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
            return getAxleHolderFromPath(path).getAxlePosition(getAxleFromAxleGroupPath(path));
        } else {
            return getAxleHolderFromPath(path).getAxlePosition(getAxleFromPath(path));
        }
    }

    function getNeighbouringAxleFromAxlePosition(path, axlePosition) {

        var axlesHolderToUse = getAxleHolderFromPath(path);

        if (axlePosition === config.AXLE_POSITIONS.AXLE_2_FRONT) {
            return axlesHolderToUse.getAxle1Front();
        } else if (axlePosition === config.AXLE_POSITIONS.AXLE_1_REAR) {
            if (axlesHolderToUse.countAxleLocation(config.AXLE_LOCATIONS.REAR) > 1) {
                return axlesHolderToUse.getAxle2Rear();
            } else {
                return axlesHolderToUse.getRearMostFrontAxle();
            }
        } else if (axlePosition === config.AXLE_POSITIONS.AXLE_2_REAR) {
            return axlesHolderToUse.getAxle1Rear();
        } else if (axlePosition === config.AXLE_POSITIONS.AXLE_3_REAR) {
            return axlesHolderToUse.getAxle2Rear();
        } else if (axlePosition === config.AXLE_POSITIONS.AXLE_4_REAR) {
            return axlesHolderToUse.getAxle3Rear();
        }

        return null;
    }

    function getIdFromPath(path) {
        var pathParts = path.split('.');

        if (!isNaN(pathParts[pathParts.length - 1])) {
            return Number(pathParts[pathParts.length - 1]);
        } else if (!isNaN(pathParts[pathParts.length - 2])) {
            return Number(pathParts[pathParts.length - 2]);
        }

        return -1;
    }

    function getChassisTypeUsingPath(path) {
        if (path.includes(config.CHASSIS_OBJECT_TYPES.VEHICLE)) {
            return config.CHASSIS_OBJECT_TYPES.VEHICLE;
        }
        if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER1)) {
            return config.CHASSIS_OBJECT_TYPES.TRAILER1;
        }
        if (path.includes(config.CHASSIS_OBJECT_TYPES.TRAILER2)) {
            return config.CHASSIS_OBJECT_TYPES.TRAILER2;
        }
    }

    function getAccessoryHolderFromPath(path) {
        var tempAccessoryHolder = null;

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempAccessoryHolder = activeRig.getVehicle().getAccessoryHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempAccessoryHolder = activeRig.getTrailer1().getAccessoryHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempAccessoryHolder = activeRig.getTrailer2().getAccessoryHolder();
        }

        return tempAccessoryHolder;
    }

    function getOriginalAccessoryHolderFromPath(path) {
        var tempAccessoryHolder = null;

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempAccessoryHolder = originalRig.getVehicle().getAccessoryHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempAccessoryHolder = originalRig.getTrailer1().getAccessoryHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempAccessoryHolder = originalRig.getTrailer2().getAccessoryHolder();
        }

        return tempAccessoryHolder;
    }

    function getAccessoryTypeFromPath(path) {
        var accessoryTypeToReturn = null;
        Object.keys(config.ACCESSORY_TYPES).forEach(function (key) {
            if (path.indexOf(config.ACCESSORY_TYPES[key]) !== -1) {
                accessoryTypeToReturn = config.ACCESSORY_TYPES[key];
            }
        });

        return accessoryTypeToReturn;
    }

    function getAxleHolderFromPath(path) {
        var tempAxles = null;
        try {
            if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
                tempAxles = activeRig.getVehicle().getAxlesHolder();
            } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
                tempAxles = activeRig.getTrailer1().getAxlesHolder();
            } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
                tempAxles = activeRig.getTrailer2().getAxlesHolder();
            }
        } catch (nullEx) {
            var breakHere = 0;
        }
        return tempAxles;
    }

    function getOriginalAxleHolderFromPath(path) {
        var tempAxles = null;
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempAxles = originalRig.getVehicle().getAxlesHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempAxles = originalRig.getTrailer1().getAxlesHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempAxles = originalRig.getTrailer2().getAxlesHolder();
        }
        return tempAxles;
    }

    function getChassisObjectFromPath(path) {
        var tempChassisObject = null;

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempChassisObject = activeRig.getVehicle();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempChassisObject = activeRig.getTrailer1();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempChassisObject = activeRig.getTrailer2();
        }

        return tempChassisObject;
    }

    function getOriginalChassisObjectFromPath(path) {
        var tempOriginalChassisObject = null;

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempOriginalChassisObject = originalRig.getVehicle();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempOriginalChassisObject = originalRig.getTrailer1();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempOriginalChassisObject = originalRig.getTrailer2();
        }

        return tempOriginalChassisObject;
    }

    function getPayloadHolderFromPath(path) {
        var tempPayloadHolder;

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempPayloadHolder = activeRig.getVehicle().getPayloadHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempPayloadHolder = activeRig.getTrailer1().getPayloadHolder();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempPayloadHolder = activeRig.getTrailer2().getPayloadHolder();
        }

        return tempPayloadHolder;
    }

    function getSimplePayloadFromPath(path) {
        var tempSimplePayload = null;

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            tempSimplePayload = activeRig.getVehicle().getPayloadHolder().getSimplePayloadObject();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            tempSimplePayload = activeRig.getTrailer1().getPayloadHolder().getSimplePayloadObject();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            tempSimplePayload = activeRig.getTrailer2().getPayloadHolder().getSimplePayloadObject();
        }

        return tempSimplePayload;
    }

    function getAccessoryFromPath(path) {

        var tempAccessoryHolder = getAccessoryHolderFromPath(path);

        switch (getAccessoryTypeFromPath(path)) {
            case config.ACCESSORY_TYPES.CRANE:
                return tempAccessoryHolder.getCrane();
            case config.ACCESSORY_TYPES.OTHER:
                return getOtherFromPathById(path, getIdFromPath(path));
            case config.ACCESSORY_TYPES.FRIDGE:
                return tempAccessoryHolder.getFridge();
            case config.ACCESSORY_TYPES.TAILLIFT:
                return tempAccessoryHolder.getTaillift();
            case config.ACCESSORY_TYPES.HITCH:
                return tempAccessoryHolder.getHitch();
            case config.ACCESSORY_TYPES.HOOKLIFT:
                return tempAccessoryHolder.getHooklift();
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                return tempAccessoryHolder.getFifthWheel();
            case config.ACCESSORY_TYPES.BODY:
                return tempAccessoryHolder.getBody();
            case config.ACCESSORY_TYPES.PAYLOAD:
                return getPayloadFromPathById(path, getIdFromPath(path));
        }

        return null;
    }

    function getAccessoryForChassisById(chassisType, objectId) {
        var accessoryHolderToUse = getAccessoryHolderFromPath(chassisType);
        return accessoryHolderToUse.getAccessoryById(objectId);
    }

    function getOriginalAccessoryFromPath(path) {

        var tempAccessoryHolder = getOriginalAccessoryHolderFromPath(path);

        switch (getAccessoryTypeFromPath(path)) {
            case config.ACCESSORY_TYPES.CRANE:
                return tempAccessoryHolder.getCrane();
            case config.ACCESSORY_TYPES.OTHER:
                return getOtherFromPathById(path, getIdFromPath(path));
            case config.ACCESSORY_TYPES.FRIDGE:
                return tempAccessoryHolder.getFridge();
            case config.ACCESSORY_TYPES.TAILLIFT:
                return tempAccessoryHolder.getTaillift();
            case config.ACCESSORY_TYPES.HITCH:
                return tempAccessoryHolder.getHitch();
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                return tempAccessoryHolder.getFifthWheel();
            case config.ACCESSORY_TYPES.HOOKLIFT:
                return tempAccessoryHolder.getHooklift();
            case config.ACCESSORY_TYPES.BODY:
                return tempAccessoryHolder.getBody();
            case config.ACCESSORY_TYPES.PAYLOAD:
                return getOriginalPayloadFromPathById(path, getIdFromPath(path));
        }

        return null;
    }

    function getFrontalArea() {
        var bodyWidthExternal;
        var heightToUse, widthToUse;
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return activeRig.getVehicle().getFrontalArea();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                bodyWidthExternal = 0;
                var trailerOverallWidth = 0,
                    trailerWidthToUse = 0;
                if (activeRig.getTrailer1() !== null) {
                    trailerOverallWidth = activeRig.getTrailer1().getOverallWidth();
                }
                if (activeRig.getTrailer1().getAccessoryHolder().getBody() !== null) {
                    bodyWidthExternal = activeRig.getTrailer1().getAccessoryHolder().getBody().getActualWidth();                        
                }
                if (activeRig.getTrailer2() !== null) {
                    trailerOverallWidth = Math.max(activeRig.getTrailer1().getOverallWidth(), activeRig.getTrailer2().getOverallWidth());
                }
                if (activeRig.getTrailer2().getAccessoryHolder().getBody() !== null) {
                    if (activeRig.getTrailer1().getAccessoryHolder().getBody() !== null) {
                        bodyWidthExternal = Math.max(activeRig.getTrailer1().getAccessoryHolder().getBody().getActualWidth(), activeRig.getTrailer2().getAccessoryHolder().getBody().getActualWidth());
                    } else {
                        bodyWidthExternal = activeRig.getTrailer2().getAccessoryHolder().getBody().getActualWidth();
                    }
                    
                }
                trailerWidthToUse = Math.max(bodyWidthExternal, trailerOverallWidth);
                heightToUse = Math.max(getOverallHeight(), activeRig.getVehicle().getCabHeight()) - activeRig.getVehicle().getGroundClearanceRear();
                widthToUse = Math.max(activeRig.getVehicle().getOverallWidth(), trailerWidthToUse);

                return (heightToUse * widthToUse) / 1000000;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                bodyWidthExternal = 0;
                if (activeRig.getTrailer1().getAccessoryHolder().getBody() !== null) {
                    bodyWidthExternal = activeRig.getTrailer1().getAccessoryHolder().getBody().getActualWidth();
                }
                heightToUse = Math.max(getOverallHeight(), activeRig.getVehicle().getCabHeight()) - activeRig.getVehicle().getGroundClearanceRear();
                widthToUse = Math.max(activeRig.getVehicle().getOverallWidth(), bodyWidthExternal);

                return (heightToUse * widthToUse) / 1000000;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
                return 0;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
                return 0;
            default:
                return 0;
        }
        
    }

    function allowTurningCircleCalculation() {
        return activeRig.getVehicle().allowTurningCircleCalculation();
    }

    function getTurningCircleValues(viewDetail, optionalSteeringAttribute, optionalValue) {

        var tempVehicle = activeRig.getVehicle();
        var sourceVals = new TurningCircleValues();
        if (!getVehicleWheelbaseTurningChanged()) {
            if (optionalSteeringAttribute) {
                if (optionalSteeringAttribute === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                    sourceVals.steeringAngleInnerInDegrees = optionalValue;
                } else if (optionalSteeringAttribute === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                    sourceVals.turningRadiusCurbToCurb = optionalValue;
                } else if (optionalSteeringAttribute === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                    sourceVals.turningRadiusWallToWall = optionalValue;
                }
            } else {
                if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                    sourceVals.steeringAngleInnerInDegrees = getVehicleSteeringAngleInner();
                } else if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                    sourceVals.turningRadiusCurbToCurb = getVehicleTurningRadiusCurbToCurb();
                } else if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                    sourceVals.turningRadiusWallToWall = getVehicleTurningRadiusWallToWall();
                }
            }
        } else {
            sourceVals.steeringAngleInnerInDegrees = getVehicleSteeringAngleInner();
        }


        var turningCircleVals = new TurningCircleValues();
        turningCircleVals.turningRadiusCurbToCurbMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
        turningCircleVals.turningRadiusOuterMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusOuterMax);
        turningCircleVals.turningRadiusInnerMin = evaluateLegislation(legislationVals.variableTypes.TurningRadiusInnerMin);
        

        if (viewDetail === config.VIEW_DETAIL.TURNING_CIRCLE_REGULATION) {
            
            calculateRegulationTurningCircle(turningCircleVals, sourceVals/*, bypassWheelbaseChanged*/);
            
        } else {
            
            calculateSmallestTurningCircle(turningCircleVals, sourceVals/*, bypassWheelbaseChanged*/);
            turningCircleVals.turningRadiusCurbToCurbMax = 0;
            turningCircleVals.turningRadiusOuterMax = 0;
            turningCircleVals.turningRadiusInnerMin = 0;
        }

        
        //roundTCValsToFourPlaces(turningCircleVals);
        return turningCircleVals;
    }

    function roundTCValsToFourPlaces(tcValsObject) {
        Object.keys(tcValsObject).forEach(function (key) {
            tcValsObject[key] = parseFloat(tcValsObject[key].toFixed(4));
        });
        
    }

    function calculateRegulationTurningCircle(turningCircleVals, optionalSourceVals/*, bypassWheelbaseChanged*/) {

        //toCentreOfVehicleRearAxle, toCentreOfTrailer1FrontAxle, toCentreOfTrailer1RearAxle, toCentreOfHitch, toCentreOfFifthWheel, vehicleTurningRadiusInner, trailer1TurningRadiusInner,
        //        turningRadiusWallToWall, turningRadiusCurbToCurb, steeringAngleOuterInDegrees, steeringAngleInnerInDegrees, secondSteeringAngleOuterInDegrees, secondSteeringAngleInnerInDegrees

        
        //roundTCValsToFourPlaces(turningCircleVals);
        if (allowTurningCircleCalculation(null)) {

            calculateSmallestTurningCircleVehicle(turningCircleVals, optionalSourceVals/*, bypassWheelbaseChanged*/);

            //#unfinished, only supporting vehicle or one semi/interling trailer and the moment, rest of if statment below needs to ported
            if (activeRig.getTrailer1() === null) {
                //roundTCValsToFourPlaces(turningCircleVals);
                calculateRegulationTurningCircleVehicle(turningCircleVals);

                turningCircleVals.toCentreOfHitch = 0
                turningCircleVals.toCentreOfFifthWheel = 0
                turningCircleVals.toCentreOfTrailer1FrontAxle = 0
                turningCircleVals.toCentreOfTrailer1RearAxle = 0
                turningCircleVals.trailer1TurningRadiusInner = 0
                
            } else if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() === null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.SEMI || activeRig.getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK)) {
                //roundTCValsToFourPlaces(turningCircleVals);
                calculateRegulationTurningCircleVehicleAndSemiOrInterlink(turningCircleVals);
                turningCircleVals.toCentreOfHitch = 0
                turningCircleVals.toCentreOfTrailer1FrontAxle = 0
                
            }else if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() === null && activeRig.getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR){

                calculateRegulationTurningCircleVehicleAndDrawbar(turningCircleVals);

                turningCircleVals.toCentreOfFifthWheel = 0;

                
            } else if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() === null && activeRig.getTrailer1().getType() === config.TRAILER_TYPES.PUP) {

                calculateRegulationTurningCircleVehicleAndPup(turningCircleVals);

                turningCircleVals.toCentreOfFifthWheel = 0;
                turningCircleVals.toCentreOfTrailer1FrontAxle = 0;


            } else if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() !== null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK && activeRig.getTrailer2().getType() === config.TRAILER_TYPES.SEMI)) {
                //roundTCValsToFourPlaces(turningCircleVals);
                calculateRegulationTurningCircleVehicleAndInterlinkAndSemi(turningCircleVals);
                turningCircleVals.toCentreOfHitch = 0;
                turningCircleVals.toCentreOfTrailer1FrontAxle = 0;

            }

            /*
            If _Trailer1.Count = 0 Then

            // already ported

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso (TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink Or TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi) Then

            // already ported

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerDrawbar Then

            // already ported

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then

            CalculateRegulationTurningCircleVehicleAndPup(toCentreOfVehicleRearAxle, toCentreOfTrailer1RearAxle,
                                                            toCentreOfHitch, vehicleTurningRadiusInner,
                                                            trailer1TurningRadiusInner, turningRadiusWallToWall,
                                                            turningRadiusCurbToCurb, steeringAngleOuterInDegrees,
                                                            steeringAngleInnerInDegrees, steeringAngleInnerMaxInDegrees,
                                                            secondSteeringAngleOuterInDegrees, secondSteeringAngleInnerInDegrees)

            toCentreOfFifthWheel = 0
            toCentreOfTrailer1FrontAxle = 0

        End If
        */
            return true
            
        }

        
        turningCircleVals.toCentreOfVehicleRearAxle = 0;
        turningCircleVals.toCentreOfFifthWheel = 0;
        turningCircleVals.toCentreOfHitch = 0;
        turningCircleVals.toCentreOfTrailer1FrontAxle = 0;
        turningCircleVals.toCentreOfTrailer1RearAxle = 0;
        turningCircleVals.turningRadiusInner = 0;
        turningCircleVals.trailer1TurningRadiusInner = 0;
        turningCircleVals.turningRadiusWallToWall = 0;
        turningCircleVals.turningRadiusCurbToCurb = 0;
        turningCircleVals.steeringAngleOuterInDegrees = 0;
        turningCircleVals.steeringAngleInnerInDegrees = 0;
        turningCircleVals.secondSteeringAngleOuterInDegrees = 0;
        turningCircleVals.secondSteeringAngleInnerInDegrees = 0;
    
        return false
        
        
    }

    function calculateSmallestTurningCircle(turningCircleVals, optionalSourceVals/*, bypassWheelbaseChanged*/) {
        //toCentreOfVehicleRearAxle, toCentreOfTrailer1FrontAxle, toCentreOfTrailer1RearAxle, toCentreOfHitch, toCentreOfFifthWheel, vehicleTurningRadiusInner,
        //                                   trailer1TurningRadiusInner, turningRadiusWallToWall, turningRadiusCurbToCurb, steeringAngleOuter,
        //                                   steeringAngleInner, secondSteeringAngleOuter, secondSteeringAngleInner, Nothing

        
        var tempVehicle = activeRig.getVehicle();
        
        if (allowTurningCircleCalculation(null)) {

            calculateSmallestTurningCircleVehicle(turningCircleVals, optionalSourceVals); 
            //#unfinished, not supporting road train combinations yet

            if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() === null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.SEMI || activeRig.getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK)) {
                calculateSmallestTurningCircleVehicleAndSemiOrInterlink(turningCircleVals);
            }else if(activeRig.getTrailer1() !== null && activeRig.getTrailer2() === null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.DRAWBAR)) {
                calculateSmallestTurningCircleVehicleAndDrawbar(turningCircleVals);
            } else if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() === null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.PUP)) {
                calculateSmallestTurningCircleVehicleAndPup(turningCircleVals);
            } else if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() !== null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK && activeRig.getTrailer2().getType() === config.TRAILER_TYPES.SEMI)) {
                calculateSmallestTurningCircleVehicleAndInterlinkAndSemi(turningCircleVals);
            }

            /*
            If _Trailer1.Count = 0 Then

            // already ported

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso (TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink Or TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi) Then

            // already ported

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerDrawbar Then

            // already ported

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then

            // already ported

        End If
        */
            //if (bypassWheelbaseChanged === true) {
            //    if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
            //        tempVehicle.setSteeringAngleInnerCalculated(optionalSourceVals.steeringAngleInnerInDegrees);
            //        tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
            //        tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
            //    } else if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
            //        tempVehicle.setTurningRadiusCurbToCurb(optionalSourceVals.turningRadiusCurbToCurb);
            //        tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
            //        tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
            //    } else if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
            //        tempVehicle.setTurningRadiusWallToWall(optionalSourceVals.turningRadiusWallToWall);
            //        tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
            //        tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
            //    }
            //} else {
            //    tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
            //    tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
            //    tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
            //}
            

            return true;

        }


        turningCircleVals.toCentreOfVehicleRearAxle = 0;
        turningCircleVals.toCentreOfTrailer1RearAxle = 0;
        turningCircleVals.toCentreOfFifthWheel = 0;
        turningCircleVals.turningRadiusInner = 0;
        turningCircleVals.trailer1TurningRadiusInner = 0;
        turningCircleVals.turningRadiusWallToWall = 0;
        turningCircleVals.turningRadiusCurbToCurb = 0;
        turningCircleVals.steeringAngleOuterInDegrees = 0;
        turningCircleVals.steeringAngleInnerInDegrees = 0;
        turningCircleVals.wheelbaseTurningMax = 0;

        return false;

        /*
        If AllowTurningCircleCalculation(Nothing) Then

        If _Trailer1.Count = 0 Then

            CalculateSmallestTurningCircleVehicle(toCentreOfVehicleRearAxle, vehicleTurningRadiusInner, turningRadiusWallToWall, turningRadiusCurbToCurb,
                                                    steeringAngleOuterInDegrees, steeringAngleInnerInDegrees, steeringAngleInnerMaxInDegrees,
                                                    secondSteeringAngleOuterInDegrees, secondSteeringAngleInnerInDegrees, wheelbaseTurningMax)

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso (TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink Or TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi) Then

            CalculateSmallestTurningCircleVehicle(Nothing, Nothing, Nothing, Nothing,
                                                    Nothing, Nothing, steeringAngleInnerMaxInDegrees,
                                                    Nothing, Nothing, Nothing)

            CalculateSmallestTurningCircleVehicleAndSemiOrInterlink(toCentreOfVehicleRearAxle, toCentreOfTrailer1RearAxle, toCentreOfFifthWheel,
                                                                    vehicleTurningRadiusInner, trailer1TurningRadiusInner, turningRadiusWallToWall,
                                                                    turningRadiusCurbToCurb, steeringAngleOuterInDegrees, steeringAngleInnerInDegrees,
                                                                    steeringAngleInnerMaxInDegrees, secondSteeringAngleOuterInDegrees,
                                                                    secondSteeringAngleInnerInDegrees)

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerDrawbar Then

            CalculateSmallestTurningCircleVehicle(Nothing, Nothing, Nothing, Nothing,
                                                    Nothing, Nothing, steeringAngleInnerMaxInDegrees,
                                                    Nothing, Nothing, Nothing)

            CalculateSmallestTurningCircleVehicleAndDrawbar(toCentreOfVehicleRearAxle, toCentreOfTrailer1FrontAxle,
                                                            toCentreOfTrailer1RearAxle, toCentreOfHitch,
                                                            vehicleTurningRadiusInner, trailer1TurningRadiusInner,
                                                            turningRadiusWallToWall, turningRadiusCurbToCurb,
                                                            steeringAngleOuterInDegrees, steeringAngleInnerInDegrees,
                                                            steeringAngleInnerMaxInDegrees, secondSteeringAngleOuterInDegrees,
                                                            secondSteeringAngleInnerInDegrees)

        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then

            CalculateSmallestTurningCircleVehicle(Nothing, Nothing, Nothing, Nothing,
                                                    Nothing, Nothing, steeringAngleInnerMaxInDegrees,
                                                    Nothing, Nothing, Nothing)

            CalculateSmallestTurningCircleVehicleAndPup(toCentreOfVehicleRearAxle,
                                                        toCentreOfTrailer1RearAxle, toCentreOfHitch,
                                                        vehicleTurningRadiusInner, trailer1TurningRadiusInner,
                                                        turningRadiusWallToWall, turningRadiusCurbToCurb,
                                                        steeringAngleOuterInDegrees, steeringAngleInnerInDegrees,
                                                        steeringAngleInnerMaxInDegrees, secondSteeringAngleOuterInDegrees,
                                                        secondSteeringAngleInnerInDegrees)

        End If

        Return True

    Else

        toCentreOfVehicleRearAxle = 0
        toCentreOfTrailer1RearAxle = 0
        toCentreOfFifthWheel = 0
        vehicleTurningRadiusInner = 0
        trailer1TurningRadiusInner = 0
        turningRadiusWallToWall = 0
        turningRadiusCurbToCurb = 0
        steeringAngleOuterInDegrees = 0
        steeringAngleInnerInDegrees = 0
        wheelbaseTurningMax = 0

        Return False

    End If
        
        */
    }

    function TurningCircleValues() {
        this.turningRadiusWallToWall = 0;
        this.turningRadiusCurbToCurb = 0;
        this.steeringAngleOuterInDegrees = 0;
        this.steeringAngleInnerInDegrees = 0;
        this.steeringAngleInnerMaxInDegrees = 0;
        this.secondSteeringAngleOuterInDegrees = 0;
        this.secondSteeringAngleInnerInDegrees = 0;
        this.toCentreOfVehicleRearAxle = 0;
        this.toCentreOfTrailer1FrontAxle = 0;
        this.toCentreOfTrailer1RearAxle = 0;
        this.toCentreOfTrailer2RearAxle = 0;
        this.toCentreOfHitch = 0;
        this.toCentreOfFifthWheel = 0;
        this.toCentreOfTrailer1FifthWheel = 0;
        this.vehicleTurningRadiusInner = 0;
        this.trailer1TurningRadiusInner = 0;
        this.trailer2TurningRadiusInner = 0;
        this.wheelbaseTurningMax = 0;
        this.turningRadiusCurbToCurbMax = 0;
        this.turningRadiusInnerMax = 0;
        this.turningRadiusOuterMax = 0;
            
    }

    function calculateSmallestTurningCircleVehicle(vals, optionalSourceVals/*, bypassWheelbaseChanged*/) {

        var tempVehicle = activeRig.getVehicle();
        var tempOriginalVehicle = originalRig.getVehicle();

        var turningRadiusWallToWall, turningRadiusCurbToCurb, steeringAngleInner, steeringAngleOuter;

        if (optionalSourceVals !== undefined) {
            turningRadiusWallToWall = optionalSourceVals.turningRadiusWallToWall;
            turningRadiusCurbToCurb = optionalSourceVals.turningRadiusCurbToCurb;
            steeringAngleInner = optionalSourceVals.steeringAngleInnerInDegrees;
            steeringAngleOuter = optionalSourceVals.steeringAngleOuterInDegrees;
        } else {
            turningRadiusWallToWall = tempVehicle.getTurningRadiusWallToWall();
            turningRadiusCurbToCurb = tempVehicle.getTurningRadiusCurbToCurb();
            steeringAngleInner = tempVehicle.getSteeringAngleInner();
            steeringAngleOuter = tempVehicle.getSteeringAngleOuter();
        }

        

        //if (tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
            if (turningRadiusCurbToCurb > 0) {
                vals.turningRadiusCurbToCurb = turningRadiusCurbToCurb;
                vals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempOriginalVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
                vals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempOriginalVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                vals.steeringAngleOuterInDegrees = Math.asin(tempOriginalVehicle.getWheelbaseTurning() / (vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                vals.steeringAngleInnerInDegrees = Math.atan(tempOriginalVehicle.getWheelbaseTurning() / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     vals.secondSteeringAngleOuterInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     vals.secondSteeringAngleInnerInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
            } else {

                if (turningRadiusWallToWall > 0) {
                    vals.turningRadiusWallToWall = turningRadiusWallToWall;
                    vals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningRadiusWallToWall, 2) - Math.pow(tempOriginalVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2)) - tempVehicle.getCabWidth() / 2;
                    vals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempOriginalVehicle.getWheelbaseTurning(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    vals.steeringAngleOuterInDegrees = Math.asin(tempOriginalVehicle.getWheelbaseTurning() / (vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    vals.steeringAngleInnerInDegrees = Math.atan(tempOriginalVehicle.getWheelbaseTurning() / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                    vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                    // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     vals.secondSteeringAngleOuterInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    //     vals.secondSteeringAngleInnerInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }
                } else {
                    if (steeringAngleInner > 0) {
                        vals.toCentreOfVehicleRearAxle = tempOriginalVehicle.getWheelbaseTurning() / Math.tan(steeringAngleInner * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
                        vals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempOriginalVehicle.getWheelbaseTurning(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                        vals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempOriginalVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                        vals.steeringAngleOuterInDegrees = Math.asin(tempOriginalVehicle.getWheelbaseTurning() / (vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                        vals.steeringAngleInnerInDegrees = steeringAngleInner;
                        vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                        vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                        //     vals.secondSteeringAngleOuterInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        //     vals.secondSteeringAngleInnerInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        // }
                    } else {
                        vals.turningRadiusCurbToCurb = tempOriginalVehicle.getWheelbaseTurning() / Math.sin(tempVehicle.getSteeringAngleOuter() * Math.PI / 180) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                        vals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
                        vals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempOriginalVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                        vals.steeringAngleOuterInDegrees = tempVehicle.getSteeringAngleOuter();
                        vals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                        vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                        //     vals.secondSteeringAngleOuterInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        //     vals.secondSteeringAngleInnerInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        // }
                    }
                }
            }

            if (getVehicleWheelbaseTurningChanged() === true/* && bypassWheelbaseChanged === false*/) {
                vals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(vals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
                vals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                vals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                vals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                vals.steeringAngleInnerInDegrees = vals.steeringAngleInnerMaxInDegrees;
                vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     vals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     vals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
            }
        //}
        /*else {
            if (getDefaultSteeringAttribute() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {

                vals.turningRadiusCurbToCurb = turningRadiusCurbToCurb;
                vals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
                vals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                vals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                vals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    vals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    vals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                }
            } else {
                if (getDefaultSteeringAttribute() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                    vals.turningRadiusWallToWall = turningRadiusWallToWall;
                    vals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningRadiusWallToWall, 2) - Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2)) - tempVehicle.getCabWidth() / 2;
                    vals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    vals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    vals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                    vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                    if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                        vals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        vals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    }
                } else {
                    if (getDefaultSteeringAttribute() === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                        vals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(steeringAngleInner * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
                        vals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                        vals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                        vals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                        vals.steeringAngleInnerInDegrees = steeringAngleInner;
                        vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                        vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                        if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                            vals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                            vals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        }
                    } else {
                        
                        vals.turningRadiusCurbToCurb = tempVehicle.getWheelbaseTurning() / Math.sin(tempVehicle.getSteeringAngleOuter() * Math.PI / 180) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                        vals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(vals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
                        vals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(vals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                        vals.steeringAngleOuterInDegrees = tempVehicle.getSteeringAngleOuter();
                        vals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        vals.steeringAngleInnerMaxInDegrees = vals.steeringAngleInnerInDegrees;
                        vals.vehicleTurningRadiusInner = vals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                        if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                            vals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                            vals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        }
                        
                    }
                }

                
            }
        }*/

        var tempTurningRadiusCurbToCurb, wheelbaseTurningLow, wheelbaseTurningHigh, tempToCentreOfRearAxle, tempTurningRadiusWallToWall, tempSteeringAngleInner;

        var turningRadiusCurbToCurbMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);


        if (turningRadiusCurbToCurbMax > 0) {
            tempToCentreOfRearAxle = evaluateLegislation(legislationVals.variableTypes.TurningRadiusInnerMin) + tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            wheelbaseTurningHigh = Math.sqrt(Math.pow(turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) -
                                            Math.pow(tempToCentreOfRearAxle + tempVehicle.getTrackWidthFront() / 2, 2));
            tempSteeringAngleInner = Math.atan(wheelbaseTurningHigh / (tempToCentreOfRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

            if (vals.steeringAngleInnerInDegrees >= tempSteeringAngleInner) {
                vals.wheelbaseTurningMax = wheelbaseTurningHigh;
            } else {
                tempTurningRadiusCurbToCurb = 0
                wheelbaseTurningLow = 0
                vals.wheelbaseTurningMax = wheelbaseTurningHigh

                while (turningRadiusCurbToCurbMax - tempTurningRadiusCurbToCurb >= 0.5 ||
                    turningRadiusCurbToCurbMax - tempTurningRadiusCurbToCurb < 0) {

                    vals.wheelbaseTurningMax = wheelbaseTurningLow + (wheelbaseTurningHigh - wheelbaseTurningLow) / 2;
                    tempToCentreOfRearAxle = vals.wheelbaseTurningMax / Math.tan(vals.steeringAngleInnerInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
                    tempTurningRadiusCurbToCurb = Math.sqrt(Math.pow(vals.wheelbaseTurningMax, 2) + Math.pow(tempToCentreOfRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    
                    if (turningRadiusCurbToCurbMax >= tempTurningRadiusCurbToCurb) {
                        wheelbaseTurningLow = vals.wheelbaseTurningMax;
                        // wheelbaseTurningHigh = wheelbaseTurningHigh;
                    } else {   
                        // wheelbaseTurningLow = wheelbaseTurningLow;
                        wheelbaseTurningHigh = vals.wheelbaseTurningMax;
                    }
                }
                
            }
            
        } else if (evaluateLegislation(legislationVals.variableTypes.TurningRadiusOuterMax) > 0) {
            

            tempToCentreOfRearAxle = vals.turningRadiusInnerMin + tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            wheelbaseTurningHigh = Math.sqrt(Math.pow(vals.turningRadiusOuterMax, 2) - Math.pow(tempToCentreOfRearAxle + tempVehicle.getCabWidth() / 2, 2)) - tempVehicle.getFrontOverhang();
            tempSteeringAngleInner = Math.atan(wheelbaseTurningHigh / (tempToCentreOfRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

            if (vals.steeringAngleInnerInDegrees >= tempSteeringAngleInner) {
                vals.wheelbaseTurningMax = wheelbaseTurningHigh;
            } else {

                tempTurningRadiusWallToWall = 0;
                wheelbaseTurningLow = 0;
                vals.wheelbaseTurningMax = wheelbaseTurningHigh;
                while(vals.turningRadiusOuterMax - tempTurningRadiusWallToWall >= 0.5 || vals.turningRadiusOuterMax - tempTurningRadiusWallToWall < 0) {
                    vals.wheelbaseTurningMax = wheelbaseTurningLow + (wheelbaseTurningHigh - wheelbaseTurningLow) / 2
                    tempToCentreOfRearAxle = vals.wheelbaseTurningMax / Math.tan(vals.steeringAngleInnerInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
                    tempTurningRadiusWallToWall = Math.sqrt(Math.pow(vals.wheelbaseTurningMax + tempVehicle.getFrontOverhang(), 2) + Math.pow(tempToCentreOfRearAxle + tempVehicle.getCabWidth() / 2, 2));

                    if (vals.turningRadiusOuterMax >= tempTurningRadiusWallToWall) {
                        wheelbaseTurningLow = vals.wheelbaseTurningMax;
                    } else {
                        wheelbaseTurningHigh = vals.wheelbaseTurningMax;
                    }
                }
                
            }

            
        } else {
            //wheelbase turning max cannot be calculated
        }
        //roundTCValsToFourPlaces(vals);
        //tempVehicle.setSteeringAngleInnerCalculated(vals.steeringAngleInnerInDegrees);
        //tempVehicle.setTurningRadiusCurbToCurbCalculated(vals.turningRadiusCurbToCurb);
        //tempVehicle.setTurningRadiusWallToWallCalculated(vals.turningRadiusWallToWall);
        calculateTurningCircleSteeringAxleAngles(vals);
    }

    function calculateTurningCircleSteeringAxleAngles(vals) {
        let tempVehicle = activeRig.getVehicle();
        var tempOriginalVehicle = originalRig.getVehicle();

        if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            vals.secondSteeringAngleOuterInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            vals.secondSteeringAngleInnerInDegrees = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        }

        let pusher1Rear = tempVehicle.getAxlesHolder().getAxle1Rear();
        if(pusher1Rear.canCalculateSteeringAngleForPusher()) {
            vals.steeringAngleAxle1RearOuter = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthRear()/2)) * 180 / Math.PI;
            vals.steeringAngleAxle1RearInner = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthRear()/2)) * 180 / Math.PI;

        }

        let pusher2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
        if(pusher2Rear && pusher2Rear.canCalculateSteeringAngleForPusher()) {
            vals.steeringAngleAxle2RearOuter = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear() - tempVehicle.getAxle1RearToAxle2Rear()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthRear()/2)) * 180 / Math.PI;
            vals.steeringAngleAxle2RearInner = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear() - tempVehicle.getAxle1RearToAxle2Rear()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthRear()/2)) * 180 / Math.PI;

        }

        let pusher3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
        if(pusher3Rear && pusher3Rear.canCalculateSteeringAngleForPusher()) {
            vals.steeringAngleAxle3RearOuter = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear() - tempVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle2RearToAxle3Rear()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthRear()/2)) * 180 / Math.PI;
            vals.steeringAngleAxle3RearInner = Math.atan((tempOriginalVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle2FrontToAxle1Rear() - tempVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle2RearToAxle3Rear()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthRear()/2)) * 180 / Math.PI;

        }

        let tag1Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
        if(tag1Rear && tag1Rear.canCalculateSteeringAngleForTag()) {
            vals.steeringAngleAxle2RearOuter = (Math.atan((tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() - tempOriginalVehicle.getWheelbaseTurning()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthRear()/2)) *-1) * 180 / Math.PI;
            vals.steeringAngleAxle2RearInner = (Math.atan((tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() - tempOriginalVehicle.getWheelbaseTurning()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthRear()/2)) *-1) * 180 / Math.PI;

        }

        let tag2Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
        if(tag2Rear && tag2Rear.canCalculateSteeringAngleForTag()) {
            vals.steeringAngleAxle3RearOuter = (Math.atan((tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() - tempOriginalVehicle.getWheelbaseTurning()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthRear()/2)) *-1) * 180 / Math.PI;
            vals.steeringAngleAxle3RearInner = (Math.atan((tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() - tempOriginalVehicle.getWheelbaseTurning()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthRear()/2)) *-1) * 180 / Math.PI;

        }

        let tag3Rear = tempVehicle.getAxlesHolder().getAxle4Rear();
        if(tag3Rear && tag3Rear.canCalculateSteeringAngleForTag()) {
            vals.steeringAngleAxle4RearOuter = (Math.atan((tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear() - tempOriginalVehicle.getWheelbaseTurning()) / (vals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthRear()/2)) *-1) * 180 / Math.PI;
            vals.steeringAngleAxle4RearInner = (Math.atan((tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear() - tempOriginalVehicle.getWheelbaseTurning()) / (vals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthRear()/2)) *-1) * 180 / Math.PI;

        }
        
    }

    function calculateSmallestTurningCircleVehicleAndSemiOrInterlink(turningCircleVals) {

        

        var fifthWheelOffset = getFifthWheelOffset();

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        
        turningCircleVals.trailer1TurningRadiusInner = 0;
        turningCircleVals.toCentreOfTrailerRearAxle = tempTrailer1.getOverallWidth() / 2;
        turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailerRearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
        turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(fifthWheelOffset, 2));
        turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
        //steeringAngleOuterInDegrees = Math.Asin(_Vehicle.WheelbaseTurning / (turningRadiusCurbToCurb - _Vehicle.Axles.Axle1FrontTyreWidth / 2)) * 180 / Math.PI
        turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
        //     //secondSteeringAngleOuterInDegrees = Math.Atan((_Vehicle.WheelbaseTurning - _Vehicle.Axle1FrontToAxle2Front) / (toCentreOfVehicleRearAxle + _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
        //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        // }

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {

            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            //steeringAngleOuterInDegrees = Math.Asin(_Vehicle.WheelbaseTurning / (turningRadiusCurbToCurb - _Vehicle.Axles.Axle1FrontTyreWidth / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(fifthWheelOffset, 2));
            turningCircleVals.toCentreOfTrailerRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailerRearAxle - tempTrailer1.getOverallWidth() / 2;
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     //turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }

        } else {
            //do nothing as the previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function calculateSmallestTurningCircleVehicleAndInterlinkAndSemi(turningCircleVals) {



        var fifthWheelOffset = getFifthWheelOffset();

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        turningCircleVals.trailer2TurningRadiusInner = 0;

        turningCircleVals.toCentreOfTrailer2RearAxle = tempTrailer2.getOverallWidth() / 2;
        turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer2RearAxle, 2) + Math.pow(tempTrailer2.getWheelbaseTurning(), 2));
        turningCircleVals.toCentreOfTrailerRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) + Math.pow(tempTrailer1.getFifthWheelOffset(), 2));//Sqrt {To Centre of Trailer 1 Fifth Wheel² - T1FWO²} (check negative FWO!)
        turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailerRearAxle - tempTrailer1.getOverallWidth() / 2;

        turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailerRearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
        turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(fifthWheelOffset, 2));
        turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;

        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
        
        //steeringAngleOuterInDegrees = Math.Asin(_Vehicle.WheelbaseTurning / (turningRadiusCurbToCurb - _Vehicle.Axles.Axle1FrontTyreWidth / 2)) * 180 / Math.PI
        turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        
        
        
        
        

        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
        //     //secondSteeringAngleOuterInDegrees = Math.Atan((_Vehicle.WheelbaseTurning - _Vehicle.Axle1FrontToAxle2Front) / (toCentreOfVehicleRearAxle + _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
        //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        // }

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {

            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;

            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));

            //steeringAngleOuterInDegrees = Math.Asin(_Vehicle.WheelbaseTurning / (turningRadiusCurbToCurb - _Vehicle.Axles.Axle1FrontTyreWidth / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            
            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(fifthWheelOffset, 2));
            turningCircleVals.toCentreOfTrailerRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailerRearAxle - tempTrailer1.getOverallWidth() / 2;
            
            turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getFifthWheelOffset(), 2));
            turningCircleVals.toCentreOfTrailer2RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer2TurningRadiusInner = turningCircleVals.toCentreOfTrailer2RearAxle - tempTrailer1.getOverallWidth() / 2;
            
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     //turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }

        } else {
            //do nothing as the previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }
    

    function calculateSmallestTurningCircleVehicleAndDrawbar(turningCircleVals) {
        var tempVehicle = activeRig.getVehicle(), 
            tempTrailer1 = activeRig.getTrailer1();

        turningCircleVals.trailer1TurningRadiusInner = 0;
        turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
        turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
        turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) + Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));
        turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
        turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
        turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
        turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
        //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        // }

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {
            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
            turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));
            turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
        }else {
            //'do nothing as the previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function calculateSmallestTurningCircleVehicleAndPup(turningCircleVals) {

        var tempVehicle = activeRig.getVehicle(),
            tempTrailer1 = activeRig.getTrailer1();


        turningCircleVals.trailer1TurningRadiusInner = 0;
        turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
        turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
        turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() +  tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
        turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
        turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
        turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
        //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
        // }

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {
            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
            turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
        }else {
            //'do nothing as the previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function calculateRegulationTurningCircleVehicle(turningCircleVals) {

        var tempVehicle = activeRig.getVehicle();

        var turningRadiusCurbToCurbMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);

        //#unfinished, outer part not ported in this if statement, 
        //If LATurningRadiusOuterMax - (_Vehicle.WheelbaseTurning + _Vehicle.FrontOverhang) >= 0 Or
        //LATurningRadiusCurbToCurbMax - _Vehicle.Axles.Axle1FrontTyreWidth / 2 - _Vehicle.WheelbaseTurning >= 0 Then
        if (turningCircleVals.turningRadiusOuterMax - (tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang()) >= 0 || turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {

            if (turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
            } else {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.turningRadiusOuterMax, 2) - Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2)) - tempVehicle.getCabWidth() / 2;
            }

            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;

            if (turningCircleVals.vehicleTurningRadiusInner >= 0) {

                if (turningRadiusCurbToCurbMax > 0) {
                    turningCircleVals.turningRadiusCurbToCurb = turningRadiusCurbToCurbMax;
                    turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                } else {
                    turningCircleVals.turningRadiusWallToWall = turningCircleVals.turningRadiusOuterMax;
                    turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                }
                turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
                
            } else {

                turningCircleVals.vehicleTurningRadiusInner = 0;
                turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
            }

        } else {

            turningCircleVals.vehicleTurningRadiusInner = 0;
            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
            
        }

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {

            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
        } else {
            //do nothing as the previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function calculateRegulationTurningCircleVehicleAndSemiOrInterlink(turningCircleVals) {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();

        var turningRadiusCurbToCurbMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
        var turningRadiusOuterMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusOuterMax);
        
        var fifthWheelOffset = getFifthWheelOffset();

        if (turningRadiusOuterMax - (tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang()) >= 0 || turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {
            if (turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
            }else{
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningRadiusOuterMax, 2) - Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2)) - tempVehicle.getCabWidth() / 2;
            }

            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(fifthWheelOffset, 2));

            if (turningCircleVals.toCentreOfFifthWheel - tempTrailer1.getWheelbaseTurning() >= 0) {

                turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;

                if (turningCircleVals.trailer1TurningRadiusInner >= 0) {

                    if (turningRadiusCurbToCurbMax > 0) {
                        turningCircleVals.turningRadiusCurbToCurb = turningRadiusCurbToCurbMax
                        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                    } else {
                        turningCircleVals.turningRadiusWallToWall = turningRadiusOuterMax
                        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    }
                    turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                        turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning()/ (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                        //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI
                        //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        // }
                    
                } else {

                    turningCircleVals.trailer1TurningRadiusInner = 0;
                    turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
                    turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                    turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(fifthWheelOffset, 2));
                    turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                    turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                    turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }
                }
                
            } else {

                turningCircleVals.trailer1TurningRadiusInner = 0;
                turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
                turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(fifthWheelOffset, 2));
                turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
                /*
                trailer1TurningRadiusInner = 0
            toCentreOfTrailer1RearAxle = _Trailer1(0).OverallWidthInclAccessories / 2
            toCentreOfFifthWheel = Math.Sqrt(Math.Pow(toCentreOfTrailer1RearAxle, 2) + Math.Pow(_Trailer1(0).WheelbaseTurning, 2))
            toCentreOfVehicleRearAxle = Math.Sqrt(Math.Pow(toCentreOfFifthWheel, 2) - Math.Pow(fifthWheelOffset, 2))
            vehicleTurningRadiusInner = toCentreOfVehicleRearAxle - _Vehicle.RearWidthOfVehicleOrBodyForTurningCircleCalculation / 2
            turningRadiusCurbToCurb = Math.Sqrt(Math.Pow(_Vehicle.WheelbaseTurning, 2) + Math.Pow(toCentreOfVehicleRearAxle + _Vehicle.TrackWidthFront / 2, 2)) + _Vehicle.Axles.Axle1FrontTyreWidth / 2
            turningRadiusWallToWall = Math.Sqrt(Math.Pow(_Vehicle.WheelbaseTurning + _Vehicle.FrontOverhang, 2) + Math.Pow(toCentreOfVehicleRearAxle + _Vehicle.CabWidth / 2, 2))
            steeringAngleOuterInDegrees = Math.Asin(_Vehicle.WheelbaseTurning / (turningRadiusCurbToCurb - _Vehicle.Axles.Axle1FrontTyreWidth / 2)) * 180 / Math.PI
            steeringAngleInnerInDegrees = Math.Atan(_Vehicle.WheelbaseTurning / (toCentreOfVehicleRearAxle - _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
            If _Vehicle.Axle1FrontToAxle2Front > 0 Then
                secondSteeringAngleOuterInDegrees = Math.Atan((_Vehicle.WheelbaseTurning - _Vehicle.Axle1FrontToAxle2Front) / (toCentreOfVehicleRearAxle + _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
                secondSteeringAngleInnerInDegrees = Math.Atan((_Vehicle.WheelbaseTurning - _Vehicle.Axle1FrontToAxle2Front) / (toCentreOfVehicleRearAxle - _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
            End If
                */
            }
        } else {
            turningCircleVals.trailer1TurningRadiusInner = 0;
            turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(fifthWheelOffset, 2));
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
            /*
            trailer1TurningRadiusInner = 0
        toCentreOfTrailer1RearAxle = _Trailer1(0).OverallWidthInclAccessories / 2
        toCentreOfFifthWheel = Math.Sqrt(Math.Pow(toCentreOfTrailer1RearAxle, 2) + Math.Pow(_Trailer1(0).WheelbaseTurning, 2))
        toCentreOfVehicleRearAxle = Math.Sqrt(Math.Pow(toCentreOfFifthWheel, 2) - Math.Pow(fifthWheelOffset, 2))
        vehicleTurningRadiusInner = toCentreOfVehicleRearAxle - _Vehicle.RearWidthOfVehicleOrBodyForTurningCircleCalculation / 2
        turningRadiusCurbToCurb = Math.Sqrt(Math.Pow(_Vehicle.WheelbaseTurning, 2) + Math.Pow(toCentreOfVehicleRearAxle + _Vehicle.TrackWidthFront / 2, 2)) + _Vehicle.Axles.Axle1FrontTyreWidth / 2
        turningRadiusWallToWall = Math.Sqrt(Math.Pow(_Vehicle.WheelbaseTurning + _Vehicle.FrontOverhang, 2) + Math.Pow(toCentreOfVehicleRearAxle + _Vehicle.CabWidth / 2, 2))
        steeringAngleOuterInDegrees = Math.Asin(_Vehicle.WheelbaseTurning / (turningRadiusCurbToCurb - _Vehicle.Axles.Axle1FrontTyreWidth / 2)) * 180 / Math.PI
        steeringAngleInnerInDegrees = Math.Atan(_Vehicle.WheelbaseTurning / (toCentreOfVehicleRearAxle - _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
        If _Vehicle.Axle1FrontToAxle2Front > 0 Then
            secondSteeringAngleOuterInDegrees = Math.Atan((_Vehicle.WheelbaseTurning - _Vehicle.Axle1FrontToAxle2Front) / (toCentreOfVehicleRearAxle + _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
            secondSteeringAngleInnerInDegrees = Math.Atan((_Vehicle.WheelbaseTurning - _Vehicle.Axle1FrontToAxle2Front) / (toCentreOfVehicleRearAxle - _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
        End If
            */
        }

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {

            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(fifthWheelOffset, 2));
            turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
        } else {
            //do nothing as the previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function calculateRegulationTurningCircleVehicleAndInterlinkAndSemi(turningCircleVals) {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        var turningRadiusCurbToCurbMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
        var turningRadiusOuterMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusOuterMax);

        var fifthWheelOffset = getFifthWheelOffset();

        if (turningRadiusOuterMax - (tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang()) >= 0 || turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {
            if (turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
            } else {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningRadiusOuterMax, 2) - Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2)) - tempVehicle.getCabWidth() / 2;
            }

            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(Math.abs(fifthWheelOffset), 2));

            if (turningCircleVals.toCentreOfFifthWheel - tempTrailer1.getWheelbaseTurning() >= 0) {

                turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;

                turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(Math.abs(tempTrailer1.getFifthWheelOffset()), 2));
                //turningCircleVals.toCentreOfTrailer2RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) - Math.pow(tempTrailer2.getWheelbaseTurning(), 2));
                if (turningCircleVals.toCentreOfTrailer1FifthWheel - tempTrailer2.getWheelbaseTurning() >= 0) {
                    turningCircleVals.toCentreOfTrailer2RearAxle = Math.sqrt(Math.pow(Math.max(turningCircleVals.toCentreOfTrailer1FifthWheel, tempTrailer2.getWheelbaseTurning()), 2) - Math.pow(Math.min(tempTrailer2.getWheelbaseTurning(), turningCircleVals.toCentreOfTrailer1FifthWheel), 2));
                    turningCircleVals.trailer2TurningRadiusInner = turningCircleVals.toCentreOfTrailer2RearAxle - tempTrailer2.getOverallWidth() / 2;
                } else {
                    turningCircleVals.trailer2TurningRadiusInner = 0;

                    turningCircleVals.toCentreOfTrailer2RearAxle = tempTrailer2.getOverallWidth() / 2;
                    turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer2RearAxle, 2) + Math.pow(tempTrailer2.getWheelbaseTurning(), 2));
                    turningCircleVals.toCentreOfTrailerRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) + Math.pow(Math.abs(tempTrailer1.getFifthWheelOffset()), 2));//Sqrt {To Centre of Trailer 1 Fifth Wheel² - T1FWO²} (check negative FWO!)
                    turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailerRearAxle - tempTrailer1.getOverallWidth() / 2;

                    turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailerRearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                    turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(Math.abs(fifthWheelOffset), 2));
                    turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;

                    turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));

                    //steeringAngleOuterInDegrees = Math.Asin(_Vehicle.WheelbaseTurning / (turningRadiusCurbToCurb - _Vehicle.Axles.Axle1FrontTyreWidth / 2)) * 180 / Math.PI
                    turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;






                    // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     //secondSteeringAngleOuterInDegrees = Math.Atan((_Vehicle.WheelbaseTurning - _Vehicle.Axle1FrontToAxle2Front) / (toCentreOfVehicleRearAxle + _Vehicle.TrackWidthFront / 2)) * 180 / Math.PI
                    //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }
                }
                

                if (turningCircleVals.trailer1TurningRadiusInner >= 0) {

                    if (turningRadiusCurbToCurbMax > 0) {
                        turningCircleVals.turningRadiusCurbToCurb = turningRadiusCurbToCurbMax
                        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                    } else {
                        turningCircleVals.turningRadiusWallToWall = turningRadiusOuterMax
                        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    }


                    turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

                    // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI
                    //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }

                } else {

                    turningCircleVals.trailer2TurningRadiusInner = 0;
                    turningCircleVals.toCentreOfTrailer2RearAxle = tempTrailer2.getOverallWidth() / 2;
                    turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer2RearAxle, 2) + Math.pow(tempTrailer2.getWheelbaseTurning(), 2));
                    turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) - Math.pow(Math.abs(tempTrailer1.getFifthWheelOffset()), 2));
                    turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;

                    turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                    turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(Math.abs(fifthWheelOffset), 2));
                    turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                    turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                    turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }
                    
                }

            } else {
                turningCircleVals.trailer2TurningRadiusInner = 0;
                turningCircleVals.toCentreOfTrailer2RearAxle = tempTrailer2.getOverallWidth() / 2;
                turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer2RearAxle, 2) + Math.pow(tempTrailer2.getWheelbaseTurning(), 2));
                turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) - Math.pow(Math.abs(tempTrailer1.getFifthWheelOffset()), 2));
                turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;

                
                turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(Math.abs(fifthWheelOffset), 2));
                turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
                
            }
        } else {

            turningCircleVals.trailer2TurningRadiusInner = 0;
            turningCircleVals.toCentreOfTrailer2RearAxle = tempTrailer2.getOverallWidth() / 2;
            turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer2RearAxle, 2) + Math.pow(tempTrailer2.getWheelbaseTurning(), 2));
            turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) - Math.pow(Math.abs(tempTrailer1.getFifthWheelOffset()), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;

            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(Math.abs(fifthWheelOffset), 2));
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
        }

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {

            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;

            turningCircleVals.toCentreOfFifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(Math.abs(fifthWheelOffset), 2));
            turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfFifthWheel, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;

            turningCircleVals.toCentreOfTrailer1FifthWheel = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer2RearAxle, 2) + Math.pow(Math.abs(tempTrailer1.getFifthWheelOffset()), 2));
            turningCircleVals.toCentreOfTrailer2RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FifthWheel, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer2TurningRadiusInner = turningCircleVals.toCentreOfTrailer2RearAxle - tempTrailer2.getOverallWidth() / 2;


            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
        } else {
            //do nothing as the previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function calculateRegulationTurningCircleVehicleAndDrawbar(turningCircleVals) {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();


        if(turningCircleVals.turningRadiusOuterMax - (tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang()) >= 0 || 
            turningCircleVals.turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {


            if (turningCircleVals.turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.turningRadiusCurbToCurbMax - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
            } else {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.turningRadiusOuterMax, 2) - Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2)) - tempVehicle.getCabWidth() / 2;
            }

            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));

            if (turningCircleVals.toCentreOfHitch - (tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart()) >= 0) {

                turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));

                if (turningCircleVals.toCentreOfTrailer1FrontAxle - tempTrailer1.getWheelbaseTurning() >= 0) {

                    turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                    turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;
                
                    if (turningCircleVals.trailer1TurningRadiusInner >= 0) {

                        if (turningCircleVals.turningRadiusCurbToCurbMax > 0) {
                            turningCircleVals.turningRadiusCurbToCurb = turningCircleVals.turningRadiusCurbToCurbMax;
                            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                        }else{
                            turningCircleVals.turningRadiusWallToWall = turningCircleVals.turningRadiusOuterMax;
                            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                        }

                        turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                        turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

                        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                        //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        // }

                    }else {

                        turningCircleVals.trailer1TurningRadiusInner = 0;
                        turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
                        turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                        turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) + Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));
                        turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
                        turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                        turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                        turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

                        // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                        //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                        // }

                    }

                } else {

                    
                    turningCircleVals.trailer1TurningRadiusInner = 0;
                    turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
                    turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                    turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) + Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));
                    turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
                    turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                    turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                    turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                    turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

                    // if( tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }
                    
                }
                
            } else {
                
                turningCircleVals.trailer1TurningRadiusInner = 0;
                turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
                turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) + Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
                turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
                turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

                // if( tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
                
            }

        } else {
            
            turningCircleVals.trailer1TurningRadiusInner = 0;
            turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
            turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) + Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));
            turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

            // if( tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
            
        }

        
        

        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {

            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicle.getAxlesHolder().getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
            turningCircleVals.toCentreOfTrailer1FrontAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempTrailer1.getDrawbarLength() + tempTrailer1.getFrontOverhang() + tempTrailer1.getAxle1FrontToWheelbaseTheoreticalStart(), 2));
            turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1FrontAxle, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;

            // if( tempTrailer1.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }

        }else {
            //'do nothing as previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function calculateRegulationTurningCircleVehicleAndPup(turningCircleVals) {

        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        var tempVehicleAxleHolder = tempVehicle.getAxlesHolder();

        if (turningCircleVals.turningRadiusOuterMax - (tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang()) >= 0 || turningCircleVals.turningRadiusCurbToCurbMax - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {

            if (turningCircleVals.turningRadiusCurbToCurbMax - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2 - tempVehicle.getWheelbaseTurning() >= 0) {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.turningRadiusCurbToCurbMax - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2, 2) - Math.pow(tempVehicle.getWheelbaseTurning(), 2)) - tempVehicle.getTrackWidthFront() / 2;
            }else {
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.turningRadiusOuterMax, 2) - Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2)) - tempVehicle.getCabWidth() / 2;
            }

            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));


            if (turningCircleVals.toCentreOfHitch - tempTrailer1.getWheelbaseTurning() >= 0) {

                turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;


                if (turningCircleVals.trailer1TurningRadiusInner >= 0) {

                    if (turningCircleVals.turningRadiusCurbToCurbMax > 0) {
                        turningCircleVals.turningRadiusCurbToCurb = turningCircleVals.turningRadiusCurbToCurbMax;
                        turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                    }else {
                        turningCircleVals.turningRadiusWallToWall = turningCircleVals.turningRadiusOuterMax;
                        turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2;
                    }
                    
                    turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    
                    // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }
                    
                } else {

                    turningCircleVals.trailer1TurningRadiusInner = 0;
                    turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
                    turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                    turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
                    turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                    turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2;
                    turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                    turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                    turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    
                    // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                    //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                    // }
                }
                
            } else {

                turningCircleVals.trailer1TurningRadiusInner = 0;
                turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
                turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
                turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
                turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
                turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2;
                turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
                turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
                turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                
                // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
                //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
                // }
            }

        } else {

            turningCircleVals.trailer1TurningRadiusInner = 0;
            turningCircleVals.toCentreOfTrailer1RearAxle = tempTrailer1.getOverallWidth() / 2;
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfTrailer1RearAxle, 2) + Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.toCentreOfVehicleRearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempTrailer1.getTrackWidthFront() / 2, 2)) + tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = Math.atan(tempVehicle.getWheelbaseTurning() / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;

            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }
            
        }


        if (turningCircleVals.steeringAngleInnerInDegrees > turningCircleVals.steeringAngleInnerMaxInDegrees) {

            turningCircleVals.toCentreOfVehicleRearAxle = tempVehicle.getWheelbaseTurning() / Math.tan(turningCircleVals.steeringAngleInnerMaxInDegrees * Math.PI / 180) + tempVehicle.getTrackWidthFront() / 2;
            turningCircleVals.vehicleTurningRadiusInner = turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getRearWidthOfVehicleOrBodyForTurningCircleCalculation() / 2;
            turningCircleVals.turningRadiusCurbToCurb = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2, 2)) + tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2;
            turningCircleVals.turningRadiusWallToWall = Math.sqrt(Math.pow(tempVehicle.getWheelbaseTurning() + tempVehicle.getFrontOverhang(), 2) + Math.pow(turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getCabWidth() / 2, 2));
            turningCircleVals.steeringAngleOuterInDegrees = Math.asin(tempVehicle.getWheelbaseTurning() / (turningCircleVals.turningRadiusCurbToCurb - tempVehicleAxleHolder.getAxle1Front().getTyreWidth() / 2)) * 180 / Math.PI;
            turningCircleVals.steeringAngleInnerInDegrees = turningCircleVals.steeringAngleInnerMaxInDegrees;
            turningCircleVals.toCentreOfHitch = Math.sqrt(Math.pow(turningCircleVals.toCentreOfVehicleRearAxle, 2) + Math.pow(tempVehicle.getWheelbaseTheoreticalEndToRearMostDrivenAxleForTurning() + tempVehicle.getAccessoryHolder().getHitch().getOffset(), 2));
            turningCircleVals.toCentreOfTrailer1RearAxle = Math.sqrt(Math.pow(turningCircleVals.toCentreOfHitch, 2) - Math.pow(tempTrailer1.getWheelbaseTurning(), 2));
            turningCircleVals.trailer1TurningRadiusInner = turningCircleVals.toCentreOfTrailer1RearAxle - tempTrailer1.getOverallWidth() / 2;

            // if (tempVehicle.getAxle1FrontToAxle2Front() > 0) {
            //     turningCircleVals.secondSteeringAngleOuterInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle + tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            //     turningCircleVals.secondSteeringAngleInnerInDegrees = Math.atan((tempVehicle.getWheelbaseTurning() - tempVehicle.getAxle1FrontToAxle2Front()) / (turningCircleVals.toCentreOfVehicleRearAxle - tempVehicle.getTrackWidthFront() / 2)) * 180 / Math.PI;
            // }

        }else {
            //'do nothing as previous calculations will be valid
        }
        calculateTurningCircleSteeringAxleAngles(turningCircleVals);
    }

    function isSwingClearanceWarning() {

        //#unfinished, need to port rest of this when drawbar and pup trilers are added

        if (activeRig.getTrailer1() !== null && activeRig.getTrailer2() === null && (activeRig.getTrailer1().getType() === config.TRAILER_TYPES.SEMI || activeRig.getTrailer1().getType() === config.TRAILER_TYPES.INTERLINK)) {
            if (getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer() < config.swingClearanceLimit) {
                return true;
            }
        }

        return false;
        /*
        If _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso (TypeOf (_Trailer1(0)) Is FileLoadingTrailerInterlink Or TypeOf (_Trailer1(0)) Is FileLoadingTrailerSemi) Then
            If VehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer < _SwingClearanceLimit Then
                Return True
            End If
        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerDrawbar Then
            If VehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer < _SwingClearanceLimit Then
                Return True
            End If
        ElseIf _Trailer1.Count = 1 And _Trailer2.Count = 0 AndAlso TypeOf (_Trailer1(0)) Is FileLoadingTrailerPup Then
            If VehicleAndPupSwingClearanceBetweenVehicleAndTrailer < _SwingClearanceLimit Then
                Return True
            End If
        Else
        End If

        Return False
        */
    }

    function getVehicleWheelbaseTurningChanged() {
        var forceAddedRaised = true;
        return (activeRig.getVehicle().getWheelbaseTurning() !== originalRig.getVehicle().getWheelbaseTurning(forceAddedRaised)) || getSteeringStateOfPushersOrTagsHaveChanged() /*|| getNumberOfDownRearAxlesHasChanged()*/;
    }

    function getSteeringStateOfPushersOrTagsHaveChanged() {
        return activeRig.getVehicle().getAxlesHolder().getCountSteeringPushersOrTags() !== originalRig.getVehicle().getAxlesHolder().getCountSteeringPushersOrTags();
    }

    function getNumberOfDownRearAxlesHasChanged() {
        let onlyCountDownAxles = true;
        return activeRig.getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles) !== originalRig.getVehicle().getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR, onlyCountDownAxles);
    }

    function getVehicleSteeringAngleInner() {
        
        var tempVehicle = activeRig.getVehicle();
        var tempOriginalVehicle = originalRig.getVehicle();
        if(tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
            if (tempVehicle.getSteeringAngleInnerOverride() === true || tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE || getVehicleWheelbaseTurningChanged() || tempVehicle.getWheelbaseOverride()) {
                return tempVehicle.getSteeringAngleInner();
            } else {
                //if (tempOriginalVehicle.getSteeringAngleInner() > 0) {
                //    return tempOriginalVehicle.getSteeringAngleInner();
                //} else {
                    if (tempVehicle.getSteeringAngleInnerCalculated() === undefined) {
                        if (tempVehicle.getSteeringAngleInner() !== undefined) {
                            return tempVehicle.getSteeringAngleInner();
                        } else if (tempOriginalVehicle.getSteeringAngleInner() > 0){
                            return tempOriginalVehicle.getSteeringAngleInner();
                        }
                    } else {
                        return tempVehicle.getSteeringAngleInnerCalculated();
                    }
                    
                //}
            }
        }else {
            //if (tempOriginalVehicle.getSteeringAngleInner() > 0) {
            //    return tempOriginalVehicle.getSteeringAngleInner();
            //} else {
                return tempVehicle.getSteeringAngleInnerCalculated();
            //}
        }
        return 0;
        
    }

    function getVehicleTurningRadiusCurbToCurb() {

        var tempVehicle = activeRig.getVehicle();
        var tempOriginalVehicle = originalRig.getVehicle();
        if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
            if (tempVehicle.getTurningRadiusCurbToCurbOverride() === true || tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                return tempVehicle.getTurningRadiusCurbToCurb();
            } else {
                if (tempOriginalVehicle.getTurningRadiusCurbToCurb() > 0) {
                    return tempOriginalVehicle.getTurningRadiusCurbToCurb();
                } else {
                    return tempVehicle.getTurningRadiusCurbToCurbCalculated();
                }
            }
        } else {
            //if (tempOriginalVehicle.getTurningRadiusCurbToCurb() > 0) {
            //    return tempOriginalVehicle.getTurningRadiusCurbToCurb();
            //} else {
                return tempVehicle.getTurningRadiusCurbToCurbCalculated();
            //}
        }


    }

    function getVehicleTurningRadiusWallToWall() {

        var tempVehicle = activeRig.getVehicle();
        var tempOriginalVehicle = originalRig.getVehicle();
        if (tempVehicle.getTurningAbilitySpecifiedAs() === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
            if (tempVehicle.getTurningRadiusWallToWallOverride() === true || tempVehicle.getSource() === config.VEHICLE_SOURCE_TYPES.TEMPLATE) {
                return tempVehicle.getTurningRadiusWallToWall();
            } else {
                if (tempOriginalVehicle.getTurningRadiusWallToWall() > 0) {
                    return tempOriginalVehicle.getTurningRadiusWallToWall();
                } else {
                    return tempVehicle.getTurningRadiusWallToWallCalculated();
                }
            }
        } else {
            //if (tempOriginalVehicle.getTurningRadiusWallToWall() > 0) {
            //    return tempOriginalVehicle.getTurningRadiusWallToWall();
            //} else {
                return tempVehicle.getTurningRadiusWallToWallCalculated();
            //}
        }


    }

    function updateTurningCircleCalculatedVals(steeringAttributeType, value/*, bypassWheelbaseChanged*/) {
        var tempVehicle = activeRig.getVehicle();
        var sourceVals = new TurningCircleValues();


        if (steeringAttributeType === undefined) {
            steeringAttributeType = getDefaultSteeringAttribute();
            value = getDefaultSteeringAttributeValue();
        //    bypassWheelbaseChanged = true;
        } else if (value === undefined) {
        //    if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
        //        value = getVehicleSteeringAngleInner();
        //    } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
        //        value = getVehicleTurningRadiusCurbToCurb();
        //    } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
        //        value = getVehicleTurningRadiusWallToWall();
            //    }
            value = getVehicleSteeringAngleInner();
        }



        if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
            sourceVals.steeringAngleInnerInDegrees = value;
        } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
            sourceVals.turningRadiusCurbToCurb = value;
        } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
            sourceVals.turningRadiusWallToWall = value;
        }

        var turningCircleVals = new TurningCircleValues();
        turningCircleVals.turningRadiusCurbToCurbMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
        turningCircleVals.turningRadiusOuterMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusOuterMax);
        turningCircleVals.turningRadiusInnerMin = evaluateLegislation(legislationVals.variableTypes.TurningRadiusInnerMin);


        calculateSmallestTurningCircle(turningCircleVals, sourceVals/*, bypassWheelbaseChanged || false*/);

        //if (bypassWheelbaseChanged === true) {
            if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                tempVehicle.setSteeringAngleInnerCalculated(value);
                tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
                tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
            } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                tempVehicle.setTurningRadiusCurbToCurbCalculated(value);
                tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
                tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
            } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                tempVehicle.setTurningRadiusWallToWallCalculated(value);
                tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
                tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
            }
        //} else {
        //    tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
        //    tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
        //    tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
        //}
        //tempVehicle.setSteeringAngleInnerCalculated(turningCircleVals.steeringAngleInnerInDegrees);
        //tempVehicle.setTurningRadiusCurbToCurbCalculated(turningCircleVals.turningRadiusCurbToCurb);
        //tempVehicle.setTurningRadiusWallToWallCalculated(turningCircleVals.turningRadiusWallToWall);
        tempVehicle.setSteeringAngleAxle1RearInnerCalculated(turningCircleVals.steeringAngleAxle1RearInner);
        tempVehicle.setSteeringAngleAxle2RearInnerCalculated(turningCircleVals.steeringAngleAxle2RearInner);
        tempVehicle.setSteeringAngleAxle3RearInnerCalculated(turningCircleVals.steeringAngleAxle3RearInner);
        tempVehicle.setSteeringAngleAxle4RearInnerCalculated(turningCircleVals.steeringAngleAxle4RearInner);
    }

    function getSteeringAngleForMinusAxle(minusAxlePosition) {
        let steeringAngle = 0;
        var tempVehicle = activeRig.getVehicle();
        let tempVehicleAxlesHolder = tempVehicle.getAxlesHolder();
        let numMinusAxles = tempVehicleAxlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

        switch(minusAxlePosition) {
            case 1:
                switch(numMinusAxles) {
                    case 1:
                        // return tempVehicleAxlesHolder.getAxle1Rear().getNumber();
                        return tempVehicle.getSteeringAngleAxle1RearInnerCalculated();
                    case 2:
                        // return  tempVehicleAxlesHolder.getAxle2Rear().getNumber();
                        return tempVehicle.getSteeringAngleAxle2RearInnerCalculated();
                    case 3:
                        // return  tempVehicleAxlesHolder.getAxle3Rear().getNumber();
                        return tempVehicle.getSteeringAngleAxle3RearInnerCalculated();
                }
                break; 
            case 2:
                switch(numMinusAxles) {
                    case 2:
                        // return tempVehicleAxlesHolder.getAxle1Rear().getNumber();
                        return tempVehicle.getSteeringAngleAxle1RearInnerCalculated();
                    case 3:
                        // return tempVehicleAxlesHolder.getAxle2Rear().getNumber();
                        return tempVehicle.getSteeringAngleAxle2RearInnerCalculated();
                }
                break; 
            case 3:
                switch(numMinusAxles) {
                    case 3:
                        // return tempVehicleAxlesHolder.getAxle1Rear().getNumber();
                        return tempVehicle.getSteeringAngleAxle1RearInnerCalculated();
                }
                break;
                 
        }

        return steeringAngle;
    }

    function getSteeringAngleForPlusAxle(plusAxlePosition) {
        let steeringAngle = 0;
        var tempVehicle = activeRig.getVehicle();
        let tempVehicleAxlesHolder = tempVehicle.getAxlesHolder();
        let numPlusAxles = tempVehicleAxlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.TAG);

        
        var numRearAxlesBeforePlus = tempVehicleAxlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) + tempVehicleAxlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        var numRearAxles = numRearAxlesBeforePlus + numPlusAxles;
        switch(numRearAxlesBeforePlus) {
            case 1:
                switch(plusAxlePosition) {
                    case 1:
                        switch(numRearAxles) {
                            case 2:
                            case 3:
                            case 4:
                                return tempVehicle.getSteeringAngleAxle2RearInnerCalculated();
                        }
                        break; 
                    case 2:
                        switch(numRearAxles) {
                            case 3:
                            case 4:
                                return tempVehicle.getSteeringAngleAxle3RearInnerCalculated();
                        }
                        break; 
                    case 3:
                        switch(numRearAxles) {
                            case 4:
                                return tempVehicle.getSteeringAngleAxle4RearInnerCalculated();
                        }
                        break;
                         
                }
                break; 
            case 2:
                switch(plusAxlePosition) {
                    case 1:
                        switch(numRearAxles) {
                            case 3:
                            case 4:
                                return tempVehicle.getSteeringAngleAxle3RearInnerCalculated();
                        }
                        break; 
                    case 2:
                        switch(numRearAxles) {
                            case 4:
                                return tempVehicle.getSteeringAngleAxle4RearInnerCalculated();
                        }
                        break; 

                }
                break; 
            case 3:
                switch(plusAxlePosition) {
                    case 1:
                        switch(numRearAxles) {
                            case 4:
                                return tempVehicle.getSteeringAngleAxle4RearInnerCalculated();
                        }
                        break; 
                         
                }
                break;
        }
        

        return steeringAngle;
    }

    function getDefaultSteeringAttribute() {
        if (originalRig.getVehicle().getSteeringAngleInner() !== 0) {
            return config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;
        } else /*if(getSteeringAngleOuter() !== 0) {
            return config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_OUTER;
        } else*/ if (originalRig.getVehicle().getTurningRadiusCurbToCurb() !== 0) {
            return config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB;
        } else {
            return config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL;
        }
        /*
        If _Loading.Vehicle.SteeringAngleInner <> 0 Then
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.SteeringAngleInner
                    ElseIf _Loading.Vehicle.SteeringAngleOuter <> 0 Then
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.SteeringAngleOuter
                    ElseIf _Loading.Vehicle.TurningRadiusCurbToCurb <> 0 Then
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.TurningRadiusCurbToCurb
                    Else
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.TurningRadiusWallToWall
                    End If
        */
    }
    function getDefaultSteeringAttributeValue() {
        if (originalRig.getVehicle().getSteeringAngleInner() !== 0) {
            return originalRig.getVehicle().getSteeringAngleInner();
        } else /*if(getSteeringAngleOuter() !== 0) {
            return config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_OUTER;
        } else*/ if (originalRig.getVehicle().getTurningRadiusCurbToCurb() !== 0) {
            return originalRig.getVehicle().getTurningRadiusCurbToCurb();
        } else {
            return originalRig.getVehicle().getTurningRadiusWallToWall();
        }
        /*
        If _Loading.Vehicle.SteeringAngleInner <> 0 Then
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.SteeringAngleInner
                    ElseIf _Loading.Vehicle.SteeringAngleOuter <> 0 Then
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.SteeringAngleOuter
                    ElseIf _Loading.Vehicle.TurningRadiusCurbToCurb <> 0 Then
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.TurningRadiusCurbToCurb
                    Else
                        _Loading.Vehicle.DefaultSteeringAttribute = FileLoadingVehicle.DefaultSteeringAttributeTypes.TurningRadiusWallToWall
                    End If
        */
    }


    function getAvailableAxles(chassisObjectType) {
        var availableAxleOptions = [];
        var chassisObjectToUse = getChassisObjectFromPath(chassisObjectType);
        var axlesHolderToUse = getAxleHolderFromPath(chassisObjectType);


        //if (axlesHolderToUse.canAddSecondSteer()) {
        //    availableAxleOptions.push({ id: config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER, label: config.getTranslationText('1031')})
        //}

        if (axlesHolderToUse.canAddPusher()) {
            availableAxleOptions.push({ id: config.VEHICLE_AXLE_POSITION_TYPES.PUSHER, label: config.getTranslationText('1032') })
        }
        if (axlesHolderToUse.canAddTag()) {
            availableAxleOptions.push({ id: config.VEHICLE_AXLE_POSITION_TYPES.TAG, label: config.getTranslationText('1033') })
        }

        return availableAxleOptions;
    }

    function getAvailableAddFuelTankOptions() {
        let tempVehicle = activeRig.getVehicle();
        let availableTankOptions = [];

        if(tempVehicle.countFuelTankType(config.FUEL_TYPE_OPTIONS.DIESEL.CODE) < 6) {
            availableTankOptions.push({ id: config.FUEL_TYPE_OPTIONS.DIESEL.CODE, label: config.getTranslationText('4649') });
        }
        if(tempVehicle.countFuelTankType(config.FUEL_TYPE_OPTIONS.ADBLUE.CODE) < 2) {
            availableTankOptions.push({ id: config.FUEL_TYPE_OPTIONS.ADBLUE.CODE, label: config.getTranslationText('4650') });
        }

        return availableTankOptions;
    }

    function resetFuelTanks(newFuelType) {
        let tempVehicle = activeRig.getVehicle();
        let tempOrginalVehicle = originalRig.getVehicle();
        
        tempVehicle.clearFuelTanks();
        tempOrginalVehicle.clearFuelTanks();

        let newFuelTank = tempVehicle.addFuelTank(undefined, newFuelType);
        newFuelTank.setOfferedAs(config.OFFERED_AS_OPTIONS.STANDARD);
        newFuelTank.setIncludeInCalculation(true);
        tempOrginalVehicle.addFuelTank(newFuelTank.clone(true), newFuelType);
        
        return newFuelTank;
    }

    function addAxle(axleType, path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return addAxleToVehicle(axleType);
        } else {
            // return addAxleToTrailer(axleType, path); not yet supported
        }
    }

    function addAxleToVehicle(axleType) {
        var tempVehicle = activeRig.getVehicle(),
            tempOriginalVehicle = originalRig.getVehicle();

        var manufacturerRating, steering, lifting, axleWeight, manufacturerRatingOverride = false, axleWeightOverride = false, suspensionMassRating,
            suspensionMassRatingOverride = false, tyreMassRatingDual, tyreMassRatingDualOverride = false, tyreMassRatingSingle,
            tyreMassRatingSingleOverride = false, tyreRadius = config.vehicleAxleDefaultTyreRadius, numberOfTyresOnAxle = 2,
            averageCostOfTyre, desiredWeight, tyreWidth;

        if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            averageCostOfTyre = tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT) !== null ? tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getAverageCostOfTyre() : tempVehicle.getAxlesHolder().getAxle1Front().getAverageCostOfTyre();
            //if (tempVehicle.getTareFrontOverride() === false) {
                axleWeight = config.vehicleAxleDefaultAxleWeight;
            //} else {
            //    axleWeight = 0;
            //}
            if (tempVehicle.getManufacturersFrontAxlesMassOverride() === false) {
                manufacturerRating = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating();
                suspensionMassRating = tempVehicle.getAxlesHolder().getAxle1Front().getSuspensionMassRating();
                tyreMassRatingDual = tempVehicle.getAxlesHolder().getAxle1Front().getTyreMassRatingDual();
                tyreMassRatingSingle = tempVehicle.getAxlesHolder().getAxle1Front().getTyreMassRatingSingle();
                tyreMassRatingSingle = tempVehicle.getAxlesHolder().getAxle1Front().getTyreMassRatingSingle();
                desiredWeight = tempVehicle.getAxlesHolder().getAxle1Front().getDesiredWeight();
            } else {
                manufacturerRating = 0;
                suspensionMassRating = 0;
                tyreMassRatingDual = 0;
                tyreMassRatingSingle = 0;
                desiredWeight = 0;
            }
            //tyreRadius = tempVehicle.getAxlesHolder().getAxle1Front().getTyreRadius();
            steering = true;
        } else {
            averageCostOfTyre = tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) !== null ? tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getAverageCostOfTyre() : tempVehicle.getAxlesHolder().getAxle1Rear().getAverageCostOfTyre();
            // axleWeight = config.vehicleAxleDefaultAxleWeight;
            axleWeight = getVehicleAxleDefaultAxleWeight(axleType);
            tyreRadius = getVehicleAxleDefaultTyreRadius(axleType);
            tyreWidth = getVehicleAxleDefaultTyreWidth(axleType);
            numberOfTyresOnAxle = getVehicleAxleDefaultNumberOfTyresOnAxle(axleType);
            steering = getVehicleAxleDefaultSteering(axleType);
            lifting = getVehicleAxleDefaultLifting(axleType);

            if (tempVehicle.getManufacturersRearAxlesMassOverride() === false) {
                // manufacturerRating = config.vehicleAxleDefaultManufacturerRating;
                // suspensionMassRating = config.vehicleAxleDefaultSuspensionMassRating;
                // tyreMassRatingDual = config.vehicleAxleDefaultTyreMassRating;
                // tyreMassRatingSingle = config.vehicleAxleDefaultTyreMassRating;
                // desiredWeight = config.vehicleAxleDefaultDesiredWeight;
                manufacturerRating = getVehicleAxleDefaultManufacturerRating(axleType);
                suspensionMassRating = getVehicleAxleDefaultSuspensionMassRating(axleType);
                tyreMassRatingDual = getVehicleAxleDefaultTyreMassRatingDual(axleType);
                tyreMassRatingSingle = getVehicleAxleDefaultTyreMassRatingSingle(axleType);
                desiredWeight = getVehicleAxleDefaultDesiredWeight(manufacturerRating, suspensionMassRating, tyreMassRatingDual, tyreMassRatingSingle, numberOfTyresOnAxle);
            } else {
                manufacturerRating = 0;
                suspensionMassRating = 0;
                tyreMassRatingDual = 0;
                tyreMassRatingSingle = 0;
                desiredWeight = 0;
            }
            // if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            //     tyreRadius = tempVehicle.getAxlesHolder().getAxle1Rear().getTyreRadius();
            // } else {
            //     tyreRadius = tempVehicle.getAxlesHolder().getRearMostRearAxle().getTyreRadius();
            // }
            
            if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                if (tempVehicle.getAxle2RearToAxle3RearOverride() === true) {
                    tempVehicle.setAxle3RearToAxle4RearOverride(true);
                    tempVehicle.setAxle2RearToAxle3RearOverride(false);
                }
                if (tempVehicle.getAxle1RearToAxle2RearOverride() === true) {
                    tempVehicle.setAxle2RearToAxle3RearOverride(true);
                    tempVehicle.setAxle1RearToAxle2RearOverride(false);
                }
            } 
        }

        var newAxle = new Axle(globals.nextId(), tempVehicle.getChassisObjectName(), 0, axleType, true, steering, lifting, config.vehicleAxleDefaultLiftingMaxTravel, manufacturerRating, manufacturerRatingOverride, axleWeight, axleWeightOverride,
            suspensionMassRating, suspensionMassRatingOverride, tyreMassRatingDual, tyreMassRatingDualOverride, tyreMassRatingSingle, tyreMassRatingSingleOverride,
            false, tyreRadius, tyreWidth, numberOfTyresOnAxle, false, 0, 0, false, 0, averageCostOfTyre, 0, numberOfTyresOnAxle, false, desiredWeight, false, 0, false, null, false, 0, false, 0);
        
        var currentDifference = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - getCabToAxles();
        var oldCabToAxles = getCabToAxles();
        var oldRearAxleSpan = tempVehicle.getRearAxleSpan();

        //'log all values before we make a change in case we have to rollback
        var oldAxle1RearToAxle2Rear, oldAxle2RearToAxle3Rear, oldAxle3RearAxle4Rear, oldRearOverhang, oldBreakpointForRearOverhangFromAxle4Rear;
        var oldOriginalRearOverhang, oldGARear, oldAxle1FrontToAxle2Front, oldBreakpointForWheelbaseFromAxle2Front, oldAxle2FrontTo1Rear;
        var oldOriginalWheelbase, oldGAFront, oldGVM, oldOriginalGVM, oldRearMostProtrudingItemBehindCabToAxles;
        var oldRearRoadFriendlySuspension, oldFrontRoadFriendlySuspension;
        var oldWheelbaseTheoreticalOverride, oldAxle1FrontToWheelbaseTheoreticalStartOverride;

        oldAxle1FrontToAxle2Front = tempVehicle.getAxle1FrontToAxle2Front();
        oldAxle2FrontTo1Rear = tempVehicle.getAxle2FrontToAxle1Rear();
        oldAxle1RearToAxle2Rear = tempVehicle.getAxle1RearToAxle2Rear();
        oldAxle2RearToAxle3Rear = tempVehicle.getAxle2RearToAxle3Rear();
        oldAxle3RearAxle4Rear = tempVehicle.getAxle3RearToAxle4Rear();
        oldRearOverhang = tempVehicle.getRearOverhang();
        oldBreakpointForWheelbaseFromAxle2Front = tempVehicle.getBreakpointForWheelbaseFromRearMostAxleFront();
        oldBreakpointForRearOverhangFromAxle4Rear = tempVehicle.getBreakpointForRearOverhangFromRearMostAxle();
        oldOriginalWheelbase = tempOriginalVehicle.getAxle2FrontToAxle1Rear();
        oldOriginalRearOverhang = tempOriginalVehicle.getRearOverhang();
        oldGAFront = tempVehicle.getGAFront();
        oldGARear = tempVehicle.getGARear();
        oldFrontRoadFriendlySuspension = tempVehicle.getFrontRoadFriendlySuspension();
        oldRearRoadFriendlySuspension = tempVehicle.getRearRoadFriendlySuspension();
        oldGVM = tempVehicle.getGVM();
        oldOriginalGVM = tempOriginalVehicle.getGVM();
        oldRearMostProtrudingItemBehindCabToAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles();
        oldWheelbaseTheoreticalOverride = tempVehicle.getWheelbaseTheoreticalOverride();
        oldAxle1FrontToWheelbaseTheoreticalStartOverride = tempVehicle.getAxle1FrontToWheelbaseTheoreticalStartOverride();

        var currentAxle1RearToAxle2Rear, currentAxle2RearToAxle3Rear, newAxle1RearToAxle2Rear, newAxle2RearToAxle3Rear, newAxle3RearToAxle4Rear;

        if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            tempVehicle.setAxle1FrontToAxle2Front(config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempOriginalVehicle.setAxle1FrontToAxle2Front(config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempVehicle.setBreakpointForWheelbaseFromRearMostAxleFront(tempVehicle.getBreakpointForWheelbaseFromRearMostAxleFront() - config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempVehicle.setBreakPointForAxle1FrontToAxle2FrontFromAxle1Front(tempOriginalVehicle.getBreakPointForAxle1FrontToAxle2FrontFromAxle1Front() - config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempVehicle.setAxle2FrontToAxle1Rear(tempVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultSecondSteerAxleSpread);
            tempVehicle.setGAFront(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating() + getLesserOfAxleManufacturerRatings(newAxle));
            
            tempVehicle.setFrontRoadFriendlySuspension(config.YES_NO_OPTIONS.NO);
        } else if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            //'this axle needs to become axle 1 rear
            currentAxle1RearToAxle2Rear = tempVehicle.getAxle1RearToAxle2Rear();
            currentAxle2RearToAxle3Rear = tempVehicle.getAxle2RearToAxle3Rear();
            newAxle1RearToAxle2Rear = getAxle1RearToAxle2RearDefaultValue(currentAxle1RearToAxle2Rear);

            // tempVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
            tempVehicle.setAxle1RearToAxle2Rear(newAxle1RearToAxle2Rear);
            tempOriginalVehicle.setAxle3RearToAxle4Rear(tempOriginalVehicle.getAxle2RearToAxle3Rear());
            tempOriginalVehicle.setAxle2RearToAxle3Rear(tempOriginalVehicle.getAxle1RearToAxle2Rear());
            // tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
            tempOriginalVehicle.setAxle1RearToAxle2Rear(newAxle1RearToAxle2Rear);
            tempVehicle.setAxle2RearToAxle3Rear(currentAxle1RearToAxle2Rear);
            tempVehicle.setAxle3RearToAxle4Rear(currentAxle2RearToAxle3Rear);
            // tempVehicle.setAxle2FrontToAxle1Rear(tempVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultAxleSpread);
            // tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultAxleSpread);
            tempVehicle.setAxle2FrontToAxle1Rear(tempVehicle.getAxle2FrontToAxle1Rear() - newAxle1RearToAxle2Rear);
            tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - newAxle1RearToAxle2Rear);
            tempVehicle.setGARear(tempVehicle.getGARear() + getLesserOfAxleManufacturerRatings(newAxle));
            tempVehicle.setRearRoadFriendlySuspension(config.YES_NO_OPTIONS.NO);
        } else {
            //'has to be a tag

            var changeToAddedAxlesSpacingFromDefaultSpread = 0;
            var axle2Rear, newValueForCalculations;
            currentAxle1RearToAxle2Rear = tempVehicle.getAxle1RearToAxle2Rear();
            currentAxle2RearToAxle3Rear = tempVehicle.getAxle2RearToAxle3Rear();
            newAxle1RearToAxle2Rear = getAxle1RearToAxle2RearDefaultValue(currentAxle1RearToAxle2Rear);

            if (tempVehicle.getAxle1RearToAxle2Rear() === 0) {
                // tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                // tempVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
                tempOriginalVehicle.setAxle1RearToAxle2Rear(newAxle1RearToAxle2Rear);
                tempVehicle.setAxle1RearToAxle2Rear(newAxle1RearToAxle2Rear);
                newValueForCalculations = newAxle1RearToAxle2Rear;
            } else if (tempVehicle.getAxle2RearToAxle3Rear() === 0) {
                newAxle2RearToAxle3Rear = getAxle2RearToAxle3RearDefaultValue(currentAxle1RearToAxle2Rear);
                tempOriginalVehicle.setAxle2RearToAxle3Rear(newAxle2RearToAxle3Rear);
                tempVehicle.setAxle2RearToAxle3Rear(newAxle2RearToAxle3Rear);
                newValueForCalculations = newAxle2RearToAxle3Rear;
                
                axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
                if (axle2Rear !== null && axle2Rear.isAdded()) {
                    changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear();
                }
            } else if (tempVehicle.getAxle3RearToAxle4Rear() === 0) {
                newAxle3RearToAxle4Rear = getAxle3RearToAxle4RearDefaultValue(currentAxle2RearToAxle3Rear);
                tempOriginalVehicle.setAxle3RearToAxle4Rear(newAxle3RearToAxle4Rear);
                tempVehicle.setAxle3RearToAxle4Rear(newAxle3RearToAxle4Rear);
                newValueForCalculations = newAxle3RearToAxle4Rear;

                axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
                if (axle2Rear !== null && axle2Rear.isAdded()) {
                    changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear();
                }

                var axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
                if (axle3Rear !== null && axle3Rear.isAdded()) {
                    changeToAddedAxlesSpacingFromDefaultSpread += tempVehicle.getAxle2RearToAxle3Rear();
                }
            }

            // tempVehicle.setRearOverhang(tempVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
            // tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
            tempVehicle.setRearOverhang(tempVehicle.getRearOverhang() - newValueForCalculations);
            tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - newValueForCalculations - changeToAddedAxlesSpacingFromDefaultSpread);

            // tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
            tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - newValueForCalculations);
            tempVehicle.setGARear(tempVehicle.getGARear() + getLesserOfAxleManufacturerRatings(newAxle));
            tempVehicle.setRearRoadFriendlySuspension(config.YES_NO_OPTIONS.NO);
        }

        // commented out in 2020.1 for defect 11920
        //tempOriginalVehicle.setGVM(tempOriginalVehicle.getGVM() + getLesserOfAxleManufacturerRatings(newAxle));
        //if (!tempVehicle.getManufacturersGrossVehicleMassOverride()) {
        //    tempVehicle.setGVM(Math.min(tempVehicle.getGCM(), tempOriginalVehicle.getGVM()));
        //}
        

        //'since we are adding an axle,which is a significant change, we should override the wheelbase theoretical
        tempVehicle.setWheelbaseTheoreticalOverride(false);
        tempVehicle.setAxle1FrontToWheelbaseTheoreticalStartOverride(false);
        tempVehicle.getAxlesHolder().add(newAxle);
        tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(getCabToAxles() + currentDifference);

        if (getActiveOffer().getRig().getVehicle().getWheelbaseOverride() === false) {
            getActiveOffer().getRig().getVehicle().setWheelbaseSpecified(getWheelbaseSpecified());
        }

        var axleLocation = getAxleLocationFromAxle(newAxle);

        
        if (axleLocation === config.AXLE_LOCATIONS.FRONT) {
            if (tempVehicle.getManufacturersFrontAxlesMassOverride() === false) {
                tempVehicle.getAxlesHolder().setAxleGroupManufacturerRatingOverrides(axleLocation, false);
                tempVehicle.getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(axleLocation, false);
                tempVehicle.getAxlesHolder().setAxleGroupTyreMassRatingOverrides(axleLocation, false);
            } else {
                distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getGAFront());
                distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getGAFront);
                distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getGAFront);

                if (activeRig.getVehicle().getAxlesHolder().getAxle1Front().getAxleWeightOverride() === false) {
                    distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getTareFront());
                }
            }
        } else {
            if (tempVehicle.getManufacturersRearAxlesMassOverride() === false) {
                tempVehicle.getAxlesHolder().setAxleGroupManufacturerRatingOverrides(axleLocation, false);
                tempVehicle.getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(axleLocation, false);
                tempVehicle.getAxlesHolder().setAxleGroupTyreMassRatingOverrides(axleLocation, false);
            } else {
                distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getGARear());
                distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getGARear());
                distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getGARear());

                if (activeRig.getVehicle().getAxlesHolder().getAxle1Rear().getAxleWeightOverride() === false) {
                    distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getTareRear());
                }
            }
            tempVehicle.getAxlesHolder().calculatePercentagesOfWeightCarried();

        }        

        return newAxle;        
    }

    /**
     * 
     * @param {number} axleType - The type of axle to use for defaults. Comes from VEHICLE_AXLE_POSITION_TYPES enum on config
     * @param {boolean} [returnAxleGroupIfMoreThanOneAxle] - If true, then if there is more than one axle in the axle group, then the axle group will be returned. If false, then the first/last axle in the axle group will be returned.
     * @returns {object|null} - Returns the axle to use for defaults, or null if there is no axle to use for defaults.
     */
    function getAxleToUseForDefaults(axleType, returnAxleGroupIfMoreThanOneAxle) {
        if(axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return getAxleToUseForPusherAxleDefaults(returnAxleGroupIfMoreThanOneAxle);
        }

        if(axleType === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return getAxleToUseForTagAxleDefaults(returnAxleGroupIfMoreThanOneAxle);
        }

        return null;
    }

    /**
     * 
     * @param {boolean} [returnAxleGroupIfMoreThanOneAxle] - If true, then if there is more than one axle in the axle group, then the axle group will be returned. If false, then the first axle in the axle group will be returned.
     * @returns {object} - Returns the axle to use for defaults
     */
    function getAxleToUseForPusherAxleDefaults(returnAxleGroupIfMoreThanOneAxle) {
        // If there are no pusher axles, then we need to use the rear axle
        var axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE),
            axleToUse,
            firstDrivenAxlePosition,
            pusherAxles = axlesHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

        if(pusherAxles.length === 0) {
            axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if(axleToUse.getIsAxleGroup() === true) {
                // Get the driven axles from the rear axle group
                if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                    if(returnAxleGroupIfMoreThanOneAxle === true) {
                        return axleToUse;
                    } else {
                        firstDrivenAxlePosition = axlesHolderToUse.getFirstRearDrivenAxlePosition();
                        return axlesHolderToUse.getAxleByPosition(firstDrivenAxlePosition);    
                    }
                } else {
                    // if 1 then use that axle
                    // if more than 1 then use the first axle
                    firstDrivenAxlePosition = axlesHolderToUse.getFirstRearDrivenAxlePosition();
                    return axlesHolderToUse.getAxleByPosition(firstDrivenAxlePosition);
                }
            } else {
                return axleToUse;
            }
        } else {
            // Sort the pusher axles by axle number, and return the first one
            pusherAxles.sort(function(a, b) {
                return a.getNumber() - b.getNumber();
            });
            return pusherAxles[0];
        }
    }

    /**
     * 
     * @param {boolean} [returnAxleGroupIfMoreThanOneAxle] - If true, then if there is more than one axle in the axle group, then the axle group will be returned. If false, then the last axle in the axle group will be returned.
     * @returns {object} - Returns the axle to use for defaults
     */
    function getAxleToUseForTagAxleDefaults(returnAxleGroupIfMoreThanOneAxle) {
        // If there are no tag axles, then we need to use the rear axle
        var axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE),
            axleToUse,
            lastDrivenAxlePosition,
            tagAxles = axlesHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.TAG);
        if(tagAxles.length === 0) {
            // Get the driven axles from the rear axle group
            axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                if(returnAxleGroupIfMoreThanOneAxle === true) {
                    return axleToUse;
                } else {
                    lastDrivenAxlePosition = axlesHolderToUse.getLastRearDrivenAxlePosition();
                    return axlesHolderToUse.getAxleByPosition(lastDrivenAxlePosition);    
                }
            } else {
                // if 1 then use that axle
                // if more than 1 then use the first axle
                lastDrivenAxlePosition = axlesHolderToUse.getLastRearDrivenAxlePosition();
                return axlesHolderToUse.getAxleByPosition(lastDrivenAxlePosition);
            }
        } else {
            // Sort the tag axles by axle number, and return the last one
            tagAxles.sort(function(a, b) {
                return a.getNumber() - b.getNumber();
            });
            return tagAxles[tagAxles.length - 1];
        }
    }

    function getClosestPusherOrTagAxleForDefaults(axleType) {
        if(axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return getPusherAxleForDefaults();
        }

        if(axleType === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return getTagAxleForDefaults();
        }

        return null;
    }

    function getPusherAxleForDefaults() {
        var axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE),
            pusherAxles = axlesHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        if(pusherAxles.length === 0) {
            return null;
        } else {
            // Sort the tag axles by axle number, and return the last one
            pusherAxles.sort(function(a, b) {
                return a.getNumber() - b.getNumber();
            });
            return pusherAxles[0];
        }
    }

    function getTagAxleForDefaults() {
        var axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE),
            tagAxles = axlesHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.TAG);
        if(tagAxles.length === 0) {
            return null;
        } else {
            // Sort the tag axles by axle number, and return the last one
            tagAxles.sort(function(a, b) {
                return a.getNumber() - b.getNumber();
            });
            return tagAxles[tagAxles.length - 1];
        }
    }

    /**
     * 
     * @returns {number} - Returns the default axle weight for a given culture code. Returns 680.4 kg for US and CA, use 1000 kg for all others
     */
    function getVehicleAxleDefaultAxleWeight(axleType) {
        var defaultValue, returnValue;
        // vehicleAxleDefaultAxleWeight = 680.4,
        // for AU = 1000 kg
        // for NZ = 1000 kg
        // for ZA = 1000 kg
        // for Row = 1000 kg
        // for CA = 680.4 kg
        // for US = 680.4 kg
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            defaultValue = config.vehicleAxleDefaultAxleWeight;
        } else {
            defaultValue = 1000;
        }

        var axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
        if(axleToUse !== null) {
            returnValue = axleToUse.getAxleWeight();
        } else {
            returnValue = defaultValue;
        }

        return returnValue;
    }

    /**
     * Get the default manufacturer rating for a given culture code. Returns 5896.7 kg for US and CA, use axle to determine default for all others
     * @param {number} axleType - Axle to be used to determine default manufacturer rating
     * @returns {number} - Default manufacturer rating for given culture code and axle
     */
    function getVehicleAxleDefaultManufacturerRating(axleType) {
        // vehicleAxleDefaultManufacturerRating = 5896.7,
        // for AU = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for NZ = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for ZA = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for Row = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for CA = 5896.7 kg
        // for US = 5896.7 kg
        var axleToUse;
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            // return config.vehicleAxleDefaultManufacturerRating;
            axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
            if(axleToUse !== null) {
                return axleToUse.getManufacturerRating();
            } else {
                return config.vehicleAxleDefaultManufacturerRating;
            }
        } else {
            axleToUse = getAxleToUseForDefaults(axleType, true);
            if(axleToUse !== null) {
                if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                    return axleToUse.getManufacturerRating() / axleToUse.getRelatedAxleIds().length;
                } else {
                    return axleToUse.getManufacturerRating();
                }
            }

            return config.vehicleAxleDefaultManufacturerRating;
        }
    }
    /**
     * Get the default suspension rating for a given culture code. Returns 5896.7 kg for US and CA, use axle to determine default for all others
     * @param {number} axleType - Axle type to be used to determine default suspension rating
     * @returns {number} - Default suspension rating for given culture code and axle
     */
    function getVehicleAxleDefaultSuspensionMassRating(axleType) {
        // vehicleAxleDefaultSuspensionMassRating = 5896.7,
        // for AU = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for NZ = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for ZA = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for Row = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for CA = 5896.7 kg
        // for US = 5896.7 kg
        var axleToUse;
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            // return config.vehicleAxleDefaultSuspensionMassRating;
            axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
            if(axleToUse !== null) {
                return axleToUse.getSuspensionMassRating();
            } else {
                return config.vehicleAxleDefaultSuspensionMassRating;
            }
        } else {
            axleToUse = getAxleToUseForDefaults(axleType);
            if(axleToUse !== null) {
                if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                    return axleToUse.getSuspensionMassRating() / axleToUse.getRelatedAxleIds().length;
                } else {
                    return axleToUse.getSuspensionMassRating();
                }
            }
        }
    }

    /**
     * 
     * @param {number} axleType - Axle type to be used to determine default dual tyre mass rating
     * @returns {number} - Default tyre mass rating for given country and axle type
     */
    function getVehicleAxleDefaultTyreMassRatingDual(axleType) {
        // vehicleAxleDefaultTyreMassRating = 5896.7,
        // for AU = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for NZ = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for ZA = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for Row = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for CA = 5896.7 kg
        // for US = 5896.7 kg
        var axleToUse;
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            // return config.vehicleAxleDefaultTyreMassRating;
            axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
            if(axleToUse !== null) {
                return axleToUse.getTyreMassRatingDual();
            } else {
                return config.vehicleAxleDefaultTyreMassRating;
            }
        } else {
            axleToUse = getAxleToUseForDefaults(axleType);
            if(axleToUse !== null) {
                if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                    return axleToUse.getTyreMassRatingDual() / axleToUse.getRelatedAxleIds().length;
                } else {
                    return axleToUse.getTyreMassRatingDual();
                }
            }
        }
    }

    /**
     * 
     * @param {number} axleType - Axle type to be used to determine default single tyre mass rating
     * @returns {number} - Default tyre mass rating for given country and axle type
     */
    function getVehicleAxleDefaultTyreMassRatingSingle(axleType) {
        // vehicleAxleDefaultTyreMassRating = 5896.7,
        // for AU = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for NZ = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for ZA = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for Row = same as closest pusher/tag or single rear driven axle ie for tandem rear goup /2, for tridem rear group /3
        // for CA = 5896.7 kg
        // for US = 5896.7 kg
        var axleToUse;
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            // return config.vehicleAxleDefaultTyreMassRating;
            axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
            if(axleToUse !== null) {
                return axleToUse.getTyreMassRatingSingle();
            } else {
                return config.vehicleAxleDefaultTyreMassRating;
            }
        } else {
            axleToUse = getAxleToUseForDefaults(axleType);
            if(axleToUse !== null) {
                if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                    return axleToUse.getTyreMassRatingSingle() / axleToUse.getRelatedAxleIds().length;
                } else {
                    return axleToUse.getTyreMassRatingSingle();
                }
            }
        }
    }

    /**
     * Function to get the value for GAWR for a given axle type
     * @param {number} manufacturerRating - Manufacturer rating for the given axle type
     * @param {number} suspensionMassRating - Suspension mass rating for the given axle type
     * @param {number} tyreMassRatingDual - Dual tyre mass rating for the given axle type
     * @param {number} tyreMassRatingSingle - Single tyre mass rating for the given axle type
     * @param {number} numberOfTyresOnAxle - Number of tyres on axle
     * @returns {number} - The value for GAWR for the given axle type
     */
    function getVehicleAxleDefaultDesiredWeight(manufacturerRating, suspensionMassRating, tyreMassRatingDual, tyreMassRatingSingle, numberOfTyresOnAxle) {
        return globals.getGrossAxleWeightRatingDefaultValue(manufacturerRating, suspensionMassRating, tyreMassRatingDual, tyreMassRatingSingle, numberOfTyresOnAxle);
        // var returnValue = manufacturerRating,
        //     tyreMassToUse = numberOfTyresOnAxle === 2 ? tyreMassRatingSingle : tyreMassRatingDual;

        // if (returnValue > suspensionMassRating) {
        //     returnValue = suspensionMassRating;
        // }

        // if (returnValue > tyreMassToUse) {
        //     returnValue = tyreMassToUse;
        // }
        // return returnValue;
    }

    /**
     * Get the default number of tyres on axle for a given culture code. Returns 2 for US and CA, use axle to determine default for all others
     * @param {object} axleType 
     * @returns {number} - Default number of tyres on axle for given culture code and axle
     */
    function getVehicleAxleDefaultNumberOfTyresOnAxle(axleType) {
        //     for AU = same as closest pusher/tag or rear driven axle
        //     for NZ = same as closest pusher/tag or rear driven axle
        //     for ZA = same as closest pusher/tag or rear driven axle
        //     for Row = same as closest rear driven axle
        //     for CA = 2
        //     for US = 2
        var axleToUse;
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
            if(axleToUse !== null) {
                return axleToUse.getNoOfTyresOnAxle();
            } else {
                return 2;
            }
        } else {
            axleToUse = getAxleToUseForDefaults(axleType);
            if(axleToUse !== null) {
                return axleToUse.getNoOfTyresOnAxle();
            }
        }
    }

    /**
     * get the default tyre radius for a given culture code.
     * @param {object} axleType 
     * @returns {number}
     */
    function getVehicleAxleDefaultTyreRadius(axleType) {
        // vehicleAxleDefaultTyreRadius = 513,
        // for AU = same as closest pusher/tag or rear driven axle
        // for NZ = same as closest pusher/tag or rear driven axle
        // for ZA = same as closest pusher/tag or rear driven axle
        // for Row = same as closest pusher/tag or rear driven axle
        // for CA = same as closest pusher/tag or (closest rear driven axle x 0.9)
        // for US = same as closest pusher/tag or (closest rear driven axle x 0.9)

        var axleToUse = getAxleToUseForDefaults(axleType);
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            // Get the closest rear driven axle
            if(axleToUse.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axleToUse.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return axleToUse.getTyreRadius();
            } else {
                // return axleToUse.getTyreRadius() * 0.9;
                if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US')) {
                    return 495;
                } else if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
                    return 556;
                }
            }
        } else {
            // Get the closest pusher/tag or rear driven axle
            return axleToUse.getTyreRadius();
        }
    }

    /**
     * Get the default tyre width for the user's country.
     * @param {number} axleType - Axle type to be used to determine default tyre width
     * @returns {number} - Default tyre width for the user's country and axle type
     */
    function getVehicleAxleDefaultTyreWidth(axleType) {
        // vehicleAxleDefaultTyreWidth = 315,
        // If added axle
        //     for AU = same as closest pusher/tag or rear driven axle
        //     for NZ = same as closest pusher/tag or rear driven axle
        //     for ZA = same as closest pusher/tag or rear driven axle
        //     for Row = same as closest pusher/tag or rear driven axle
        //     for CA = 294.65
        //     for US = 294.65
        var axleToUse = getAxleToUseForDefaults(axleType);
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            if(axleToUse.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axleToUse.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return axleToUse.getTyreWidth();
            } else {
                if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US')) {
                    return 294.65;
                } else if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
                    return 425;
                }
                
            }
        } else {
            return axleToUse.getTyreWidth();
        }
    }

    /**
     * Get the default steering for a the user's country. Returns true for US and CA, false for all others
     * @returns {boolean} - Default steering for the user's country
     * */
    function getVehicleAxleDefaultSteering(axleType) {
        // If added axle
        // for AU = False
        // for NZ = False
        // for ZA = False
        // for Row = False
        // for CA = True
        // for US = True
        var axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            if(axleToUse !== null) {
                return axleToUse.getSteering();
            } else {
                return true;
            }
        } else {
            if(axleToUse !== null) {
                return axleToUse.getSteering();
            } else {
                return false;
            }
        }
    }
    /**
     * Get the default lifting for the user's country. Returns true for US and CA, false for all others
     * @returns {boolean} - Default lifting for the user's country
     */
    function getVehicleAxleDefaultLifting(axleType) {
        // If added axle
        //     for AU = False
        //     for NZ = False
        //     for ZA = False
        //     for Row = False
        //     for CA = True
        //     for US = True
        var axleToUse = getClosestPusherOrTagAxleForDefaults(axleType);
        if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
            if(axleToUse !== null) {
                return axleToUse.getLifting();
            } else {
                return true;
            }
        } else {
            if(axleToUse !== null) {
                return axleToUse.getLifting();
            } else {
                return false;
            }
        }
    }

    function getAxle1RearToAxle2RearDefaultValue(currentAxle1RearToAxle2Rear) {
        // for AU = same as closest axle spacing or 1320 mm
        // for NZ = same as closest axle spacing or 1320 mm
        // for ZA = same as closest axle spacing or 1350 mm
        // for Row = same as closest axle spacing or 1350 mm
        // for CA = same as closest pusher/tag axle spacing or 1244.6 mm
        // for US = same as closest pusher/tag axle spacing or 1244.6 mm
        var axlesHolderToUse,
            axleToUse = getClosestPusherOrTagAxleForDefaults(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);
        // if pusher has been added then return axle1RearToAxle2Rear
        if(axleToUse !== null) {
            return currentAxle1RearToAxle2Rear;
        } else {
            if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
                return 1244.6;
            } else if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('AU') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('NZ')) {
                // Check rear axle group - more than 1 axle then return axle 2-3
                axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE)
                axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                if(axleToUse.getIsAxleGroup() === true) {
                    // Get the driven axles from the rear axle group
                    if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                        return currentAxle1RearToAxle2Rear;        
                    } else {
                        return 1320;
                    }
                } else {
                    return 1320;
                }
            } else {
                // Check rear axle group - more than 1 axle then return axle 2-3
                axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE)
                axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                if(axleToUse.getIsAxleGroup() === true) {
                    // Get the driven axles from the rear axle group
                    if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                        return currentAxle1RearToAxle2Rear;        
                    } else {
                        return 1350;
                    }
                } else {
                    return 1350;
                }
            }    
        }
    }

    function getAxle2RearToAxle3RearDefaultValue(currentAxle1RearToAxle2Rear) {
        // for AU = same as closest axle spacing or 1320 mm
        // for NZ = same as closest axle spacing or 1320 mm
        // for ZA = same as closest axle spacing or 1350 mm
        // for Row = same as closest axle spacing or 1350 mm
        // for CA = same as closest tag axle spacing or 1244.6 mm
        // for US = same as closest tag axle spacing or 1244.6 mm
        var axlesHolderToUse,
            axleToUse = getClosestPusherOrTagAxleForDefaults(config.VEHICLE_AXLE_POSITION_TYPES.TAG);
        // if pusher has been added then return axle1RearToAxle2Rear
        if(axleToUse !== null) {
            return currentAxle1RearToAxle2Rear;
        } else {
            if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
                return 1244.6;
            } else if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('AU') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('NZ')) {
                // Check rear axle group - more than 1 axle then return axle 2-3
                axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE)
                axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                if(axleToUse.getIsAxleGroup() === true) {
                    // Get the driven axles from the rear axle group
                    if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                        return currentAxle1RearToAxle2Rear;        
                    } else {
                        return 1320;
                    }
                } else {
                    return 1320;
                }
            } else {
                // Check rear axle group - more than 1 axle then return axle 2-3
                axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE)
                axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                if(axleToUse.getIsAxleGroup() === true) {
                    // Get the driven axles from the rear axle group
                    if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                        return currentAxle1RearToAxle2Rear;        
                    } else {
                        return 1350;
                    }
                } else {
                    return 1350;
                }
            }    
        }
    }

    function getAxle3RearToAxle4RearDefaultValue(currentAxle2RearToAxle3Rear) {
        // for AU = same as closest axle spacing or 1320 mm
        // for NZ = same as closest axle spacing or 1320 mm
        // for ZA = same as closest axle spacing or 1350 mm
        // for Row = same as closest axle spacing or 1350 mm
        // for CA = same as closest tag axle spacing or 1244.6 mm
        // for US = same as closest tag axle spacing or 1244.6 mm
        var axlesHolderToUse,
            axleToUse = getClosestPusherOrTagAxleForDefaults(config.VEHICLE_AXLE_POSITION_TYPES.TAG);
        // if pusher has been added then return axle1RearToAxle2Rear
        if(axleToUse !== null) {
            return currentAxle2RearToAxle3Rear;
        } else {
            if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('US') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('CA')) {
                return 1244.6;
            } else if(globals.user.doesCountryCodeMatchUserCountryAbbreviation('AU') || globals.user.doesCountryCodeMatchUserCountryAbbreviation('NZ')) {
                // Check rear axle group - more than 1 axle then return axle 2-3
                axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE)
                axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                if(axleToUse.getIsAxleGroup() === true) {
                    // Get the driven axles from the rear axle group
                    if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                        return currentAxle2RearToAxle3Rear;        
                    } else {
                        return 1320;
                    }
                } else {
                    return 1320;
                }
            } else {
                // Check rear axle group - more than 1 axle then return axle 2-3
                axlesHolderToUse = getAxleHolderFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE)
                axleToUse = axlesHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
                if(axleToUse.getIsAxleGroup() === true) {
                    // Get the driven axles from the rear axle group
                    if(axleToUse.getIsAxleGroup() === true && axleToUse.doesAxleGroupContainMoreThanOneAxle() === true) {
                        return currentAxle2RearToAxle3Rear;        
                    } else {
                        return 1350;
                    }
                } else {
                    return 1350;
                }
            }    
        }
    }

    // function addAxleToOriginalVehicle(axle) {


    //     var tempVehicle = activeRig.getVehicle(),
    //         tempOriginalVehicle = originalRig.getVehicle();


    //     var axleType = axle.getType();

    //     var manufacturerRating, steering, axleWeight, manufacturerRatingOverride = false, axleWeightOverride = false, suspensionMassRating, suspensionMassRatingOverride = false, tyreMassRatingDual, tyreMassRatingDualOverride = false, tyreMassRatingSingle,
    //         tyreMassRatingSingleOverride = false, tyreRadius = config.vehicleAxleDefaultTyreRadius, numberOfTyresOnAxle = 2, averageCostOfTyre;

    //     if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
    //         averageCostOfTyre = tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT) !== null ? tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getAverageCostOfTyre() : tempVehicle.getAxlesHolder().getAxle1Front().getAverageCostOfTyre();
    //         if (tempVehicle.getTareFrontOverride() === false) {
    //             axleWeight = config.vehicleAxleDefaultAxleWeight;
    //         } else {
    //             axleWeight = 0;
    //         }
    //         if (tempVehicle.getManufacturersFrontAxlesMassOverride() === false) {
    //             manufacturerRating = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating();
    //             suspensionMassRating = tempVehicle.getAxlesHolder().getAxle1Front().getSuspensionMassRating();
    //             tyreMassRatingDual = tempVehicle.getAxlesHolder().getAxle1Front().getTyreMassRatingDual();
    //             tyreMassRatingSingle = tempVehicle.getAxlesHolder().getAxle1Front().getTyreMassRatingSingle();
    //         } else {
    //             manufacturerRating = 0;
    //             suspensionMassRating = 0;
    //             tyreMassRatingDual = 0;
    //             tyreMassRatingSingle = 0;
    //         }
    //         //tyreRadius = tempVehicle.getAxlesHolder().getAxle1Front().getTyreRadius();
    //         steering = true;
    //     } else {
    //         averageCostOfTyre = tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) !== null ? tempVehicle.getAxlesHolder().getAxleUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getAverageCostOfTyre() : tempVehicle.getAxlesHolder().getAxle1Rear().getAverageCostOfTyre();
    //         if (tempVehicle.getTareRearOverride() === false) {
    //             axleWeight = config.vehicleAxleDefaultAxleWeight;
    //         } else {
    //             axleWeight = 0;
    //         }
    //         if (tempVehicle.getManufacturersRearAxlesMassOverride() === false) {
    //             manufacturerRating = config.vehicleAxleDefaultManufacturerRating;
    //             suspensionMassRating = config.vehicleAxleDefaultSuspensionMassRating;
    //             tyreMassRatingDual = config.vehicleAxleDefaultTyreMassRating;
    //             tyreMassRatingSingle = config.vehicleAxleDefaultTyreMassRating;
    //         } else {
    //             manufacturerRating = 0;
    //             suspensionMassRating = 0;
    //             tyreMassRatingDual = 0;
    //             tyreMassRatingSingle = 0;
    //         }
    //         if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
    //             tyreRadius = tempVehicle.getAxlesHolder().getAxle1Rear().getTyreRadius();
    //         } else {
    //             tyreRadius = tempVehicle.getAxlesHolder().getRearMostRearAxle().getTyreRadius();
    //         }
    //         steering = false;
    //         if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
    //             if (tempVehicle.getAxle2RearToAxle3RearOverride() === true) {
    //                 tempVehicle.setAxle3RearToAxle4RearOverride(true);
    //                 tempVehicle.setAxle2RearToAxle3RearOverride(false);
    //             }
    //             if (tempVehicle.getAxle1RearToAxle2RearOverride() === true) {
    //                 tempVehicle.setAxle2RearToAxle3RearOverride(true);
    //                 tempVehicle.setAxle1RearToAxle2RearOverride(false);
    //             }
    //         }
    //     }


    //     var newAxle = new Axle(globals.nextId(), tempVehicle.getChassisObjectName(), 0, axleType, true, steering, true, config.vehicleAxleDefaultLiftingMaxTravel, manufacturerRating, manufacturerRatingOverride, axleWeight, axleWeightOverride,
    //         suspensionMassRating, suspensionMassRatingOverride, tyreMassRatingDual, tyreMassRatingDualOverride, tyreMassRatingSingle, tyreMassRatingSingleOverride,
    //         false, tyreRadius, config.vehicleAxleDefaultTyreWidth, numberOfTyresOnAxle, false, 0, 0, false, 0, averageCostOfTyre, 0, numberOfTyresOnAxle, false, suspensionMassRating, false, 0, false, null, false, 0, false);
    //     //newAxle.setAdded(true);


    //     var currentDifference = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - getCabToAxles();
    //     var oldCabToAxles = getCabToAxles();
    //     var oldRearAxleSpan = tempVehicle.getRearAxleSpan();

    //     //'log all values before we make a change in case we have to rollback
    //     var oldAxle1RearToAxle2Rear, oldAxle2RearToAxle3Rear, oldAxle3RearAxle4Rear, oldRearOverhang, oldBreakpointForRearOverhangFromAxle4Rear;
    //     var oldOriginalRearOverhang, oldGARear, oldAxle1FrontToAxle2Front, oldBreakpointForWheelbaseFromAxle2Front, oldAxle2FrontTo1Rear;
    //     var oldOriginalWheelbase, oldGAFront, oldGVM, oldOriginalGVM, oldRearMostProtrudingItemBehindCabToAxles;
    //     var oldRearRoadFriendlySuspension, oldFrontRoadFriendlySuspension;
    //     var oldWheelbaseTheoreticalOverride, oldAxle1FrontToWheelbaseTheoreticalStartOverride;

    //     oldAxle1FrontToAxle2Front = tempVehicle.getAxle1FrontToAxle2Front();
    //     oldAxle2FrontTo1Rear = tempVehicle.getAxle2FrontToAxle1Rear();
    //     oldAxle1RearToAxle2Rear = tempVehicle.getAxle1RearToAxle2Rear();
    //     oldAxle2RearToAxle3Rear = tempVehicle.getAxle2RearToAxle3Rear();
    //     oldAxle3RearAxle4Rear = tempVehicle.getAxle3RearToAxle4Rear();
    //     oldRearOverhang = tempVehicle.getRearOverhang();
    //     oldBreakpointForWheelbaseFromAxle2Front = tempVehicle.getBreakpointForWheelbaseFromRearMostAxleFront();
    //     oldBreakpointForRearOverhangFromAxle4Rear = tempVehicle.getBreakpointForRearOverhangFromRearMostAxle();
    //     oldOriginalWheelbase = tempOriginalVehicle.getAxle2FrontToAxle1Rear();
    //     oldOriginalRearOverhang = tempOriginalVehicle.getRearOverhang();
    //     oldGAFront = tempVehicle.getGAFront();
    //     oldGARear = tempVehicle.getGARear();
    //     oldFrontRoadFriendlySuspension = tempVehicle.getFrontRoadFriendlySuspension();
    //     oldRearRoadFriendlySuspension = tempVehicle.getRearRoadFriendlySuspension();
    //     oldGVM = tempVehicle.getGVM();
    //     oldOriginalGVM = tempOriginalVehicle.getGVM();
    //     oldRearMostProtrudingItemBehindCabToAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles();
    //     oldWheelbaseTheoreticalOverride = tempVehicle.getWheelbaseTheoreticalOverride();
    //     oldAxle1FrontToWheelbaseTheoreticalStartOverride = tempVehicle.getAxle1FrontToWheelbaseTheoreticalStartOverride();

    //     if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {

    //         tempVehicle.setAxle1FrontToAxle2Front(config.vehicleAxleDefaultSecondSteerAxleSpread);
    //         tempOriginalVehicle.setAxle1FrontToAxle2Front(config.vehicleAxleDefaultSecondSteerAxleSpread);
    //         tempVehicle.setBreakpointForWheelbaseFromRearMostAxleFront(tempVehicle.getBreakpointForWheelbaseFromRearMostAxleFront() - config.vehicleAxleDefaultSecondSteerAxleSpread);

    //         tempVehicle.setBreakPointForAxle1FrontToAxle2FrontFromAxle1Front(tempOriginalVehicle.getBreakPointForAxle1FrontToAxle2FrontFromAxle1Front() - config.vehicleAxleDefaultSecondSteerAxleSpread);

    //         tempVehicle.setAxle2FrontToAxle1Rear(tempVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultSecondSteerAxleSpread);

    //         tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultSecondSteerAxleSpread);
    //         //tempVehicle.setGAFront(tempVehicle.getGAFront() + newAxle.getManufacturerRating()); 
    //         tempVehicle.setGAFront(tempVehicle.getGAFront() + getLesserOfAxleManufacturerRatings(newAxle));

    //         tempVehicle.setFrontRoadFriendlySuspension(config.YES_NO_OPTIONS.NO);

    //         //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getCabToAxles() + currentDifference);

    //     } else if (axleType === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
    //         //'this axle needs to become axle 1 rear

    //         var currentAxle1RearToAxle2Rear, currentAxle2RearToAxle3Rear;

    //         currentAxle1RearToAxle2Rear = tempVehicle.getAxle1RearToAxle2Rear();
    //         currentAxle2RearToAxle3Rear = tempVehicle.getAxle2RearToAxle3Rear();

    //         tempVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
    //         tempOriginalVehicle.setAxle3RearToAxle4Rear(tempOriginalVehicle.getAxle2RearToAxle3Rear());
    //         tempOriginalVehicle.setAxle2RearToAxle3Rear(tempOriginalVehicle.getAxle1RearToAxle2Rear());
    //         tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
    //         tempVehicle.setAxle2RearToAxle3Rear(currentAxle1RearToAxle2Rear);
    //         tempVehicle.setAxle3RearToAxle4Rear(currentAxle2RearToAxle3Rear);
    //         tempVehicle.setAxle2FrontToAxle1Rear(tempVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultAxleSpread);
    //         tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() - config.vehicleAxleDefaultAxleSpread);
    //         //tempVehicle.setGARear(tempVehicle.getGARear() + newAxle.getManufacturerRating());
    //         tempVehicle.setGARear(tempVehicle.getGARear() + getLesserOfAxleManufacturerRatings(newAxle));
    //         tempVehicle.setRearRoadFriendlySuspension(config.YES_NO_OPTIONS.NO);

    //         //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(oldRearMostProtrudingItemBehindCabToAxles + (tempVehicle.getCabToAxles() - oldCabToAxles) / 2);
    //     } else {
    //         //'has to be a tag

    //         var changeToAddedAxlesSpacingFromDefaultSpread = 0;
    //         var axle2Rear;
    //         if (tempVehicle.getAxle1RearToAxle2Rear() === 0) {
    //             tempOriginalVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
    //             tempVehicle.setAxle1RearToAxle2Rear(config.vehicleAxleDefaultAxleSpread);
    //             //tempVehicle.setBreakPointForAxle1RearToAxle2RearFromAxle1Rear(tempOriginalVehicle.getBreakPointForAxle1RearToAxle2RearFromAxle1Rear() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
    //             //tempVehicle.setBreakPointForAxle2RearToAxle3RearFromAxle2Rear(tempOriginalVehicle.getBreakPointForAxle2RearToAxle3RearFromAxle2Rear() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
    //             //tempVehicle.setBreakPointForAxle3RearToAxle4RearFromAxle3Rear(tempOriginalVehicle.getBreakPointForAxle3RearToAxle4RearFromAxle3Rear() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
    //         } else if (tempVehicle.getAxle2RearToAxle3Rear() === 0) {
    //             tempOriginalVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);
    //             tempVehicle.setAxle2RearToAxle3Rear(config.vehicleAxleDefaultAxleSpread);


    //             axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
    //             if (axle2Rear !== null && axle2Rear.isAdded()) {
    //                 //changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear() - tempOriginalVehicle.getAxle1RearToAxle2Rear();
    //                 changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear();
    //             }
    //             //tempVehicle.setBreakPointForAxle2RearToAxle3RearFromAxle2Rear(tempOriginalVehicle.getBreakPointForAxle2RearToAxle3RearFromAxle2Rear() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
    //             //tempVehicle.setBreakPointForAxle3RearToAxle4RearFromAxle3Rear(tempOriginalVehicle.getBreakPointForAxle3RearToAxle4RearFromAxle3Rear() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
    //         } else if (tempVehicle.getAxle3RearToAxle4Rear() === 0) {

    //             tempOriginalVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);
    //             tempVehicle.setAxle3RearToAxle4Rear(config.vehicleAxleDefaultAxleSpread);

    //             axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
    //             if (axle2Rear !== null && axle2Rear.isAdded()) {
    //                 //changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear() - tempOriginalVehicle.getAxle1RearToAxle2Rear();
    //                 changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear();
    //             }

    //             var axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
    //             if (axle3Rear !== null && axle3Rear.isAdded()) {
    //                 //changeToAddedAxlesSpacingFromDefaultSpread += tempVehicle.getAxle2RearToAxle3Rear() - tempOriginalVehicle.getAxle2RearToAxle3Rear();
    //                 changeToAddedAxlesSpacingFromDefaultSpread += tempVehicle.getAxle2RearToAxle3Rear();
    //             }
    //             //tempVehicle.setBreakPointForAxle3RearToAxle4RearFromAxle3Rear(tempOriginalVehicle.getBreakPointForAxle3RearToAxle4RearFromAxle3Rear() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
    //         }

    //         tempVehicle.setRearOverhang(tempVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
    //         tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempOriginalVehicle.getBreakpointForRearOverhangFromRearMostAxle() - config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);






    //         tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() - config.vehicleAxleDefaultAxleSpread);
    //         //tempVehicle.setGARear(tempVehicle.getGARear() + newAxle.getManufacturerRating());
    //         tempVehicle.setGARear(tempVehicle.getGARear() + getLesserOfAxleManufacturerRatings(newAxle));
    //         tempVehicle.setRearRoadFriendlySuspension(config.YES_NO_OPTIONS.NO);

    //         //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(oldRearMostProtrudingItemBehindCabToAxles + (tempVehicle.getCabToAxles() - oldCabToAxles) / 2);

    //     }

    //     // commented out in 2020.1 for defect 11920
    //     //tempOriginalVehicle.setGVM(tempOriginalVehicle.getGVM() + getLesserOfAxleManufacturerRatings(newAxle));
    //     //if (!tempVehicle.getManufacturersGrossVehicleMassOverride()) {
    //     //    tempVehicle.setGVM(Math.min(tempVehicle.getGCM(), tempOriginalVehicle.getGVM()));
    //     //}
        

    //     //'since we are adding an axle,which is a significant change, we should override the wheelbase theoretical
    //     tempVehicle.setWheelbaseTheoreticalOverride(false);
    //     tempVehicle.setAxle1FrontToWheelbaseTheoreticalStartOverride(false);


    //     tempVehicle.getAxlesHolder().add(newAxle);

    //     //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(oldRearMostProtrudingItemBehindCabToAxles + (tempVehicle.getRearAxleSpan() - oldRearAxleSpan) / 2);
    //     tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(getCabToAxles() + currentDifference);

    //     if (getActiveOffer().getRig().getVehicle().getWheelbaseOverride() === false) {
    //         getActiveOffer().getRig().getVehicle().setWheelbaseSpecified(getWheelbaseSpecified());
    //     }

    //     var axleLocation = getAxleLocationFromAxle(newAxle);


    //     if (axleLocation === config.AXLE_LOCATIONS.FRONT) {
    //         //if (tempVehicle.getTareFrontOverride() === false) {
    //         //    tempVehicle.getAxlesHolder().setAxleGroupWeightOverrides(axleLocation, true);
    //         //} else {
    //         //    //distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getTareFront());
    //         //    distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getTareFront());
    //         //}
    //         if (tempVehicle.getManufacturersFrontAxlesMassOverride() === false) {
    //             tempVehicle.getAxlesHolder().setAxleGroupManufacturerRatingOverrides(axleLocation, true);
    //             tempVehicle.getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(axleLocation, true);
    //             tempVehicle.getAxlesHolder().setAxleGroupTyreMassRatingOverrides(axleLocation, true);
    //         } else {
    //             distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getGAFront());
    //             distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getGAFront());
    //             distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getGAFront());

    //             if (activeRig.getVehicle().getAxlesHolder().getAxle1Front().getAxleWeightOverride() === false) {
    //                 distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.FRONT, activeRig.getVehicle().getTareFront());
    //             }
    //         }
    //     } else {
    //         //if (tempVehicle.getTareRearOverride() === false) {
    //         //    tempVehicle.getAxlesHolder().setAxleGroupWeightOverrides(axleLocation, true);
    //         //} else {
    //         //    //distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEWEIGHT, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getTareRear());
    //         //    distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getTareRear());
    //         //}
    //         if (tempVehicle.getManufacturersRearAxlesMassOverride() === false) {
    //             tempVehicle.getAxlesHolder().setAxleGroupManufacturerRatingOverrides(axleLocation, true);
    //             tempVehicle.getAxlesHolder().setAxleGroupSuspensionMassRatingOverrides(axleLocation, true);
    //             tempVehicle.getAxlesHolder().setAxleGroupTyreMassRatingOverrides(axleLocation, true);
    //         } else {
    //             distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.AXLEMANUFACTURERRATING, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getGARear());
    //             distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.SUSPENSIONMASSRATING, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getGARear());
    //             distributeGivenMassOverGivenAttributeOnAxleGroup(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.VALUE_TYPE.TYREMASSRATING, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getGARear());

    //             if (activeRig.getVehicle().getAxlesHolder().getAxle1Rear().getAxleWeightOverride() === false) {
    //                 distributeAxleGroupWeightOverGroupAxles(config.CHASSIS_OBJECT_TYPES.VEHICLE, config.AXLE_LOCATIONS.REAR, activeRig.getVehicle().getTareRear());
    //             }
    //         }
    //     }
        


    //     return newAxle;


        
    // }

    function getAddedAxlesSpread() {
        var tempVehicle = activeRig.getVehicle(),
            tempOriginalVehicle = originalRig.getVehicle();

        //var axle2Front = tempVehicle.getAxlesHolder().getAxle2Front();
        var axle1Rear = tempVehicle.getAxlesHolder().getAxle1Rear();
        var axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
        var axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
        var axle4Rear = tempVehicle.getAxlesHolder().getAxle4Rear();

        var addedAxlesSpread = 0;

        //if (axle2Front !== null && axle2Front.isAdded()) {
        //    addedAxlesSpread += tempVehicle.getAxle1FrontToAxle2Front() - tempOriginalVehicle.getAxle1FrontToAxle2Front();
        //}

        if (axle1Rear !== null && axle1Rear.isAdded()) {
            addedAxlesSpread += tempVehicle.getAxle1RearToAxle2Rear() - tempOriginalVehicle.getAxle1RearToAxle2Rear();
        }

        if (axle2Rear !== null && axle2Rear.isAdded()) {
            if (axle2Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                addedAxlesSpread += tempVehicle.getAxle2RearToAxle3Rear() - tempOriginalVehicle.getAxle2RearToAxle3Rear();
            } else {
                addedAxlesSpread += tempVehicle.getAxle1RearToAxle2Rear();
            }
            
        }

        if (axle3Rear !== null && axle3Rear.isAdded()) {
            if (axle3Rear.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
                addedAxlesSpread += tempVehicle.getAxle3RearToAxle4Rear() - tempOriginalVehicle.getAxle3RearToAxle4Rear();
            } else {
                addedAxlesSpread += tempVehicle.getAxle2RearToAxle3Rear();
            }
            
        }

        if (axle4Rear !== null && axle4Rear.isAdded()) {
            addedAxlesSpread += tempVehicle.getAxle3RearToAxle4Rear();
        }

        return addedAxlesSpread;
    }

    function getIsAddedRearSection(axleSection) {

        var tempVehicle = activeRig.getVehicle();
        var axle2Rear, axle3Rear;
        switch (axleSection) {
            case config.AXLE_SECTIONS.AXLE_1_REAR_TO_AXLE_2_REAR:

                var axle1Rear = tempVehicle.getAxlesHolder().getAxle1Rear();
                axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();

                return axle1Rear.isAdded() || axle2Rear.isAdded();

            case config.AXLE_SECTIONS.AXLE_2_REAR_TO_AXLE_3_REAR:

                axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
                axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();

                return axle2Rear.isAdded() || axle3Rear.isAdded();


            case config.AXLE_SECTIONS.AXLE_3_REAR_TO_AXLE_4_REAR:

                
                axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
                var axle4Rear = tempVehicle.getAxlesHolder().getAxle4Rear();

                return axle3Rear.isAdded() || axle4Rear.isAdded();
        }
        return false;
    }

    function getBreakpointAdjustment(axleSection) {

        var tempVehicle = activeRig.getVehicle(),
            tempOriginalVehicle = originalRig.getVehicle();
        var axle2Rear, axle3Rear, changeToAddedAxlesSpacingFromDefaultSpread;
        switch (axleSection) {
            case config.VALUE_TYPE.AXLE1REARTOAXLE2REAR:

                var axle1Rear = tempVehicle.getAxlesHolder().getAxle1Rear();
                axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();

                if ((axle1Rear !== null && axle1Rear.isAdded()) || (axle2Rear !== null && axle2Rear.isAdded())) {
                    //return (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + 5) - (config.vehicleAxleDefaultAxleSpread);
                    return  5 - (config.vehicleAxleDefaultAxleSpread);
                } else {
                    return 0;
                }
                
            case config.VALUE_TYPE.AXLE2REARTOAXLE3REAR:

                axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
                axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();

                if ((axle3Rear !== null && axle3Rear.isAdded()) || (axle2Rear !== null && axle2Rear.isAdded())) {
                    changeToAddedAxlesSpacingFromDefaultSpread = 0;

                    
                    if (axle2Rear !== null && axle2Rear.isAdded()) {
                        //changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear() - tempOriginalVehicle.getAxle1RearToAxle2Rear();
                        changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle2RearToAxle3Rear() - tempOriginalVehicle.getAxle2RearToAxle3Rear();
                    }
                    //return (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + 5) - (config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
                    return changeToAddedAxlesSpacingFromDefaultSpread;
                } else {
                    return 0;
                }

                
            case config.VALUE_TYPE.AXLE3REARTOAXLE4REAR:

                axle2Rear = tempVehicle.getAxlesHolder().getAxle2Rear();
                axle3Rear = tempVehicle.getAxlesHolder().getAxle3Rear();
                var axle4Rear = tempVehicle.getAxlesHolder().getAxle4Rear();

                if ((axle3Rear !== null && axle3Rear.isAdded()) || (axle4Rear !== null && axle4Rear.isAdded())) {
                    changeToAddedAxlesSpacingFromDefaultSpread = 0;

                    
                    //if (axle2Rear !== null && axle2Rear.isAdded()) {
                    //    //changeToAddedAxlesSpacingFromDefaultSpread = tempVehicle.getAxle1RearToAxle2Rear() - tempOriginalVehicle.getAxle1RearToAxle2Rear();
                    //    changeToAddedAxlesSpacingFromDefaultSpread = tempOriginalVehicle.getAxle1RearToAxle2Rear();
                    //}

                    
                    if (axle3Rear !== null && axle3Rear.isAdded()) {
                        //changeToAddedAxlesSpacingFromDefaultSpread += tempVehicle.getAxle2RearToAxle3Rear() - tempOriginalVehicle.getAxle2RearToAxle3Rear();
                        changeToAddedAxlesSpacingFromDefaultSpread += tempVehicle.getAxle2RearToAxle3Rear()  - tempOriginalVehicle.getAxle2RearToAxle3Rear();
                    }



                    //return (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + 5) - (tempOriginalVehicle.getFrontOverhang() + tempOriginalVehicle.getAxle1FrontToAxle2Front() + tempOriginalVehicle.getAxle2FrontToAxle1Rear() + tempOriginalVehicle.getAxle1RearToAxle2Rear() + tempOriginalVehicle.getAxle2RearToAxle3Rear() + 5);
                    //return (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + 5) - (config.vehicleAxleDefaultAxleSpread - changeToAddedAxlesSpacingFromDefaultSpread);
                    return changeToAddedAxlesSpacingFromDefaultSpread;
                } else {
                    return 0;
                }




                
        }
    }

    function getLesserOfAxleManufacturerRatings(axle) {

        var tyreMassRatingValToUse = axle.getNoOfTyresOnAxle() === 2 ? axle.getTyreMassRatingSingle() : axle.getTyreMassRatingDual();

        return Math.min(axle.getManufacturerRating(), Math.min(axle.getSuspensionMassRating(), tyreMassRatingValToUse));
    }

    function removeAxle(path, id) {

        var axle = getChassisObjectFromPath(path).getAxlesHolder().getAxleById(id);

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return removeAxleFromVehicle(axle);
        } else {
            // return removeAxleFromTrailer(axle, path); not yet supported
        }
        
    }

    function resequenceTrailerAxles() {
        var tempVehicle = activeRig.getVehicle();
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();
        var originalTrailer1 = originalRig.getTrailer1();
        var originalTrailer2 = originalRig.getTrailer2();

        if (tempTrailer1 !== null) {
            tempTrailer1.getAxlesHolder().setStartingNumberForAxles(tempVehicle.getAxlesHolder().getNumberOfAxles());
            tempTrailer1.getAxlesHolder().resequence();
            tempTrailer1.getAxlesHolder().updateValuesOnAllAxleGroups();
            originalTrailer1.getAxlesHolder().setStartingNumberForAxles(tempVehicle.getAxlesHolder().getNumberOfAxles());
            originalTrailer1.getAxlesHolder().resequence();
            originalTrailer1.getAxlesHolder().updateValuesOnAllAxleGroups();

            if (tempTrailer2 !== null) {
                tempTrailer2.getAxlesHolder().setStartingNumberForAxles(tempVehicle.getAxlesHolder().getNumberOfAxles() + tempTrailer1.getAxlesHolder().getNumberOfAxles());
                tempTrailer2.getAxlesHolder().resequence();
                tempTrailer2.getAxlesHolder().updateValuesOnAllAxleGroups();
                originalTrailer2.getAxlesHolder().setStartingNumberForAxles(tempVehicle.getAxlesHolder().getNumberOfAxles() + tempTrailer1.getAxlesHolder().getNumberOfAxles());
                originalTrailer2.getAxlesHolder().resequence();
                originalTrailer2.getAxlesHolder().updateValuesOnAllAxleGroups();
            }
        }
    }

    function removeAxleFromVehicle(axle) {

        var tempVehicle = activeRig.getVehicle(),
            tempOriginalVehicle = originalRig.getVehicle();

        var oldAxle1RearToAxle2Rear, oldAxle2RearToAxle3Rear, oldAxle3RearAxle4Rear, oldRearOverhang, oldBreakpointForRearOverhangFromAxle4Rear;
        var oldOriginalRearOverhang, oldGARear, oldAxle1FrontToAxle2Front, oldBreakpointForWheelbaseFromAxle2Front, oldAxle2FrontTo1Rear;
        var oldOriginalWheelbase, oldGAFront, oldGVM, oldOriginalGVM, oldRearMostProtrudingItemBehindCabToAxles;
        var oldRearRoadFriendlySuspension, oldFrontRoadFriendlySuspension;
        var oldWheelbaseTheoreticalOverride, oldAxle1FrontToWheelbaseTheoreticalStartOverride;

        oldAxle1FrontToAxle2Front = tempVehicle.getAxle1FrontToAxle2Front();
        oldAxle2FrontTo1Rear = tempVehicle.getAxle2FrontToAxle1Rear();
        oldAxle1RearToAxle2Rear = tempVehicle.getAxle1RearToAxle2Rear();
        oldAxle2RearToAxle3Rear = tempVehicle.getAxle2RearToAxle3Rear();
        oldAxle3RearAxle4Rear = tempVehicle.getAxle3RearToAxle4Rear();
        oldRearOverhang = tempVehicle.getRearOverhang();
        oldBreakpointForWheelbaseFromAxle2Front = tempVehicle.getBreakpointForWheelbaseFromRearMostAxleFront();
        oldBreakpointForRearOverhangFromAxle4Rear = tempVehicle.getBreakpointForRearOverhangFromRearMostAxle();
        oldOriginalWheelbase = tempOriginalVehicle.getAxle2FrontToAxle1Rear();
        oldOriginalRearOverhang = tempOriginalVehicle.getRearOverhang();
        oldGAFront = tempVehicle.getGAFront();
        oldGARear = tempVehicle.getGARear();
        oldFrontRoadFriendlySuspension = tempVehicle.getFrontRoadFriendlySuspension();
        oldRearRoadFriendlySuspension = tempVehicle.getRearRoadFriendlySuspension();
        oldGVM = tempVehicle.getGVM();
        oldOriginalGVM = tempOriginalVehicle.getGVM();
        oldRearMostProtrudingItemBehindCabToAxles = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles();
        oldWheelbaseTheoreticalOverride = tempVehicle.getWheelbaseTheoreticalOverride();
        oldAxle1FrontToWheelbaseTheoreticalStartOverride = tempVehicle.getAxle1FrontToWheelbaseTheoreticalStartOverride();


        var currentDifference = tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles() - getCabToAxles();
        var currentAxle1FrontToAxle2Front, currentAxle1RearToAxle2Rear, currentAxle2RearToAxle3Rear, currentAxle3RearToAxle4Rear;
        var noOfFrontAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        var noOfRearAxles = tempVehicle.getAxlesHolder().countAxleLocation(config.AXLE_LOCATIONS.REAR);

        currentAxle1FrontToAxle2Front = tempVehicle.getAxle1FrontToAxle2Front();
        currentAxle1RearToAxle2Rear = tempVehicle.getAxle1RearToAxle2Rear();
        currentAxle2RearToAxle3Rear = tempVehicle.getAxle2RearToAxle3Rear();
        currentAxle3RearToAxle4Rear = tempVehicle.getAxle3RearToAxle4Rear();

        var updateRearMostProtrudingItemBehindCabToRearAxles = false;

        tempVehicle.setAxle3RearToAxle4Rear(0);

        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {

            tempVehicle.setAxle1FrontToAxle2Front(0);
            tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() + tempOriginalVehicle.getAxle1FrontToAxle2Front());
            tempOriginalVehicle.setAxle1FrontToAxle2Front(0);
            tempVehicle.setBreakpointForWheelbaseFromRearMostAxleFront(tempVehicle.getBreakpointForWheelbaseFromRearMostAxleFront() + currentAxle1FrontToAxle2Front);
            tempVehicle.setAxle2FrontToAxle1Rear(tempVehicle.getAxle2FrontToAxle1Rear() + currentAxle1FrontToAxle2Front);
            
            tempVehicle.setGAFront(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating() - axle.getManufacturerRating());

        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {

            
            
            

            if (axle.getNumber() === noOfFrontAxles + 1) {
                //'this is the first pusher axle
                tempVehicle.setAxle2FrontToAxle1Rear(tempVehicle.getAxle2FrontToAxle1Rear() + currentAxle1RearToAxle2Rear);
                tempOriginalVehicle.setAxle2FrontToAxle1Rear(tempOriginalVehicle.getAxle2FrontToAxle1Rear() + tempOriginalVehicle.getAxle1RearToAxle2Rear());
                tempOriginalVehicle.setAxle1RearToAxle2Rear(tempOriginalVehicle.getAxle2RearToAxle3Rear());
                tempOriginalVehicle.setAxle2RearToAxle3Rear(tempOriginalVehicle.getAxle3RearToAxle4Rear());
                tempOriginalVehicle.setAxle3RearToAxle4Rear(0);
                
                tempVehicle.setAxle1RearToAxle2Rear(currentAxle2RearToAxle3Rear);
                tempVehicle.setAxle2RearToAxle3Rear(currentAxle3RearToAxle4Rear);
            } /*else if (axle.getNumber() === noOfFrontAxles + 2) {
                //'there must be another pusher to the left of this axle
                tempVehicle.setAxle1RearToAxle2Rear(currentAxle1RearToAxle2Rear + currentAxle2RearToAxle3Rear);
                tempVehicle.setAxle2RearToAxle3Rear(currentAxle3RearToAxle4Rear);
            } else {
                //'this is the last pusher, so there must be another 2 pusher axles to the left
                tempVehicle.setAxle1RearToAxle2Rear(currentAxle1RearToAxle2Rear);
                tempVehicle.setAxle2RearToAxle3Rear(currentAxle2RearToAxle3Rear + currentAxle3RearToAxle4Rear);
            }*/

            tempVehicle.setGARear(tempVehicle.getGARear() - axle.getManufacturerRating());
            updateRearMostProtrudingItemBehindCabToRearAxles = true;//tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getCabToAxles() + currentDifference);

        } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {

            //'we need to figure out what axle spacing this particular axle maps to
            var rearAxleNumber = tempVehicle.getAxlesHolder().getAxleNumber(axle) - noOfFrontAxles;

            switch (rearAxleNumber) {
                case 2:
                    //if (tempVehicle.getAxle2RearToAxle3Rear() !== 0) {
                    //    //'there are more axles
                    //    tempVehicle.setAxle1RearToAxle2Rear(currentAxle1RearToAxle2Rear + currentAxle2RearToAxle3Rear);
                    //    tempVehicle.setAxle2RearToAxle3Rear(currentAxle3RearToAxle4Rear);
                    //    tempOriginalVehicle.setAxle1RearToAxle2Rear(tempOriginalVehicle.getAxle1RearToAxle2Rear() + tempOriginalVehicle.getAxle2RearToAxle3Rear());
                    //    tempOriginalVehicle.setAxle2RearToAxle3Rear(tempOriginalVehicle.getAxle3RearToAxle4Rear());
                    //} else {
                        //'this is the last axle
                        tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() + tempOriginalVehicle.getAxle1RearToAxle2Rear());
                        tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempVehicle.getBreakpointForRearOverhangFromRearMostAxle() + tempOriginalVehicle.getAxle1RearToAxle2Rear());
                        tempVehicle.setAxle1RearToAxle2Rear(0);
                        //tempVehicle.setAxle2RearToAxle3Rear(0);
                        
                        tempOriginalVehicle.setAxle1RearToAxle2Rear(0);
                        //tempOriginalVehicle.setAxle2RearToAxle3Rear(0);
                        updateRearMostProtrudingItemBehindCabToRearAxles = true; //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getCabToAxles() + currentDifference);
                        tempVehicle.setRearOverhang(tempVehicle.getRearOverhang() + currentAxle1RearToAxle2Rear);
                        
                        
                    //}
                    break;
                case 3:
                    //if (tempVehicle.getAxle3RearToAxle4Rear() !== 0) {
                    //    tempVehicle.setAxle2RearToAxle3Rear(currentAxle2RearToAxle3Rear + currentAxle3RearToAxle4Rear);
                    //    tempOriginalVehicle.setAxle2RearToAxle3Rear(tempOriginalVehicle.getAxle2RearToAxle3Rear() + tempOriginalVehicle.getAxle3RearToAxle4Rear());
                    //} else {

                    tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() + tempOriginalVehicle.getAxle2RearToAxle3Rear());
                    tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempVehicle.getBreakpointForRearOverhangFromRearMostAxle() + tempOriginalVehicle.getAxle2RearToAxle3Rear());
                    tempVehicle.setAxle2RearToAxle3Rear(0);
                        
                        tempOriginalVehicle.setAxle2RearToAxle3Rear(0);
                        updateRearMostProtrudingItemBehindCabToRearAxles = true; //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getCabToAxles() + currentDifference);
                        tempVehicle.setRearOverhang(tempVehicle.getRearOverhang() + currentAxle2RearToAxle3Rear);
                        
                        
                    //}
                    break;
                case 4:
                    tempOriginalVehicle.setRearOverhang(tempOriginalVehicle.getRearOverhang() + tempOriginalVehicle.getAxle3RearToAxle4Rear());
                    tempVehicle.setBreakpointForRearOverhangFromRearMostAxle(tempVehicle.getBreakpointForRearOverhangFromRearMostAxle() + tempOriginalVehicle.getAxle3RearToAxle4Rear());
                    tempVehicle.setAxle3RearToAxle4Rear(0);
                    tempOriginalVehicle.setAxle3RearToAxle4Rear(0);
                    updateRearMostProtrudingItemBehindCabToRearAxles = true; //tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(tempVehicle.getCabToAxles() + currentDifference);
                    tempVehicle.setRearOverhang(tempVehicle.getRearOverhang() + currentAxle3RearToAxle4Rear);
                    
                    
                    break;
            }

            tempVehicle.setGARear(tempVehicle.getGARear() - axle.getManufacturerRating());

        }
        //commented out in 2020.1 for defect 11920
        //tempOriginalVehicle.setGVM(tempOriginalVehicle.getGVM() - axle.getManufacturerRating());

        //if (!tempVehicle.getManufacturersGrossVehicleMassOverride()) {
        //    tempVehicle.setGVM(Math.min(tempVehicle.getGCM(), tempOriginalVehicle.getGVM()));
        //}

        //'since we are adding an axle,which is a significant change, we should override the wheelbase theoretical
        tempVehicle.setWheelbaseTheoreticalOverride(false);
        tempVehicle.setAxle1FrontToWheelbaseTheoreticalStartOverride(false);
        

        tempVehicle.getAxlesHolder().remove(axle);
        tempOriginalVehicle.getAxlesHolder().remove(axle);
        tempVehicle.getAxlesHolder().calculatePercentagesOfWeightCarried();
        tempOriginalVehicle.getAxlesHolder().calculatePercentagesOfWeightCarried();
        
        
        if (updateRearMostProtrudingItemBehindCabToRearAxles === true) {
            tempVehicle.setRearMostProtrudingItemBehindCabToRearAxles(getCabToAxles() + currentDifference);
        }
        if (tempVehicle.getWheelbaseOverride() === false) {
            tempVehicle.setWheelbaseSpecified(getWheelbaseSpecified());
        }
        if (tempOriginalVehicle.getWheelbaseOverride() === false) {
            tempOriginalVehicle.setWheelbaseSpecified(getOriginalWheelbaseSpecified());
        }
        /*
            'disable the validation until the axle is ready to be added
    ApplicationGlobals.CurrentCalculation.IsLoading = True

    'log all values before we make a change in case we have to rollback
    Dim oldAxle1RearToAxle2Rear, oldAxle2RearToAxle3Rear, oldAxle3RearAxle4Rear, oldRearOverhang, oldBreakpointForRearOverhangFromAxle4Rear As Double
    Dim oldOriginalRearOverhang, oldGARear, oldAxle1FrontToAxle2Front, oldBreakpointForWheelbaseFromAxle2Front, oldAxle2FrontTo1Rear As Double
    Dim oldOriginalWheelbase, oldGAFront, oldGVM, oldOriginalGVM, oldRearMostProtrudingItemBehindCabToAxles As Double
    Dim oldRearRoadFriendlySuspension, oldFrontRoadFriendlySuspension As VehicleRoadFriendlySuspensionTypes
    Dim oldWheelbaseTheoreticalOverride, oldAxle1FrontToWheelbaseTheoreticalStartOverride As Boolean

    oldAxle1FrontToAxle2Front = _Axle1FrontToAxle2Front
    oldAxle2FrontTo1Rear = _Axle2FrontToAxle1Rear
    oldAxle1RearToAxle2Rear = _Axle1RearToAxle2Rear
    oldAxle2RearToAxle3Rear = _Axle2RearToAxle3Rear
    oldAxle3RearAxle4Rear = _Axle3RearToAxle4Rear
    oldRearOverhang = RearOverhang
    oldBreakpointForWheelbaseFromAxle2Front = BreakpointForWheelbaseFromAxle2Front
    oldBreakpointForRearOverhangFromAxle4Rear = BreakPointForRearOverhangFromAxle4Rear
    oldOriginalWheelbase = OriginalWheelbase
    oldOriginalRearOverhang = OriginalRearOverhang
    oldGAFront = GAFront
    oldGARear = GARear
    oldFrontRoadFriendlySuspension = FrontRoadFriendlySuspension
    oldRearRoadFriendlySuspension = RearRoadFriendlySuspension
    oldGVM = GVM
    oldOriginalGVM = OriginalGVM
    oldRearMostProtrudingItemBehindCabToAxles = _RearMostProtrudingItemBehindCabToAxles
    oldWheelbaseTheoreticalOverride = WheelbaseTheoreticalOverride
    oldAxle1FrontToWheelbaseTheoreticalStartOverride = Axle1FrontToWheelbaseTheoreticalStartOverride

    Dim currentDifference As Double = RearMostProtrudingItemBehindCabToAxles - CabToAxles
    Dim currentAxle1FrontToAxle2Front, currentAxle1RearToAxle2Rear, currentAxle2RearToAxle3Rear, currentAxle3RearToAxle4Rear As Double
    Dim noOfFrontAxles As Integer = Axles.CountAxleLocation(FileLoadingVehicleAxleCollection.AxleLocations.Front)
    Dim noOfRearAxles As Integer = Axles.CountAxleLocation(FileLoadingVehicleAxleCollection.AxleLocations.Rear)

    currentAxle1FrontToAxle2Front = Axle1FrontToAxle2Front
    currentAxle1RearToAxle2Rear = Axle1RearToAxle2Rear
    currentAxle2RearToAxle3Rear = Axle2RearToAxle3Rear
    currentAxle3RearToAxle4Rear = Axle3RearToAxle4Rear

    _Axle3RearToAxle4Rear = 0

    If axle.Type = VehicleAxlePositionTypes.SecondSteer Then

        _Axle1FrontToAxle2Front = 0
        _BreakpointForWheelbaseFromAxle2Front += currentAxle1FrontToAxle2Front
        _Axle2FrontToAxle1Rear += currentAxle1FrontToAxle2Front
        _OriginalWheelbase += currentAxle1FrontToAxle2Front
        GAFront -= axle.ManufacturerRating

    ElseIf axle.Type = VehicleAxlePositionTypes.Pusher Then

        If axle.Number = noOfFrontAxles + 1 Then
            'this is the first pusher axle
            _Axle2FrontToAxle1Rear += currentAxle1RearToAxle2Rear
            _OriginalWheelbase += currentAxle1RearToAxle2Rear
            _Axle1RearToAxle2Rear = currentAxle2RearToAxle3Rear
            _Axle2RearToAxle3Rear = currentAxle3RearToAxle4Rear
        ElseIf axle.Number = noOfFrontAxles + 2 Then
            'there must be another pusher to the left of this axle
            _Axle1RearToAxle2Rear = currentAxle1RearToAxle2Rear + currentAxle2RearToAxle3Rear
            _Axle2RearToAxle3Rear = currentAxle3RearToAxle4Rear
        Else
            'this is the last pusher, so there must be another 2 pusher axles to the left
            _Axle1RearToAxle2Rear = currentAxle1RearToAxle2Rear
            _Axle2RearToAxle3Rear = currentAxle2RearToAxle3Rear + currentAxle3RearToAxle4Rear
        End If

        GARear -= axle.ManufacturerRating
        _RearMostProtrudingItemBehindCabToAxles = CabToAxles + currentDifference

    ElseIf axle.Type = VehicleAxlePositionTypes.Tag Then

        'we need to figure out what axle spacing this particular axle maps to
        Dim rearAxleNumber As Integer = Axles.GetAxleNumber(axle) - noOfFrontAxles

        Select Case rearAxleNumber
            Case 2
                If Axle2RearToAxle3Rear <> 0 Then
                    'there are more axles
                    _Axle1RearToAxle2Rear = currentAxle1RearToAxle2Rear + currentAxle2RearToAxle3Rear
                    _Axle2RearToAxle3Rear = currentAxle3RearToAxle4Rear
                Else
                    'this is the last axle
                    _Axle1RearToAxle2Rear = 0
                    _Axle2RearToAxle3Rear = 0
                    _RearMostProtrudingItemBehindCabToAxles = CabToAxles + currentDifference
                    RearOverhang += currentAxle1RearToAxle2Rear
                    BreakPointForRearOverhangFromAxle4Rear += currentAxle1RearToAxle2Rear
                    OriginalRearOverhang += currentAxle1RearToAxle2Rear
                End If
            Case 3
                If Axle3RearToAxle4Rear <> 0 Then
                    _Axle2RearToAxle3Rear = currentAxle2RearToAxle3Rear + currentAxle3RearToAxle4Rear
                Else
                    _Axle2RearToAxle3Rear = 0
                    _RearMostProtrudingItemBehindCabToAxles = CabToAxles + currentDifference
                    RearOverhang += currentAxle2RearToAxle3Rear
                    BreakPointForRearOverhangFromAxle4Rear += currentAxle2RearToAxle3Rear
                    OriginalRearOverhang += currentAxle2RearToAxle3Rear
                End If
            Case 4
                _RearMostProtrudingItemBehindCabToAxles = CabToAxles + currentDifference
                RearOverhang += currentAxle3RearToAxle4Rear
                BreakPointForRearOverhangFromAxle4Rear += currentAxle3RearToAxle4Rear
                OriginalRearOverhang += currentAxle3RearToAxle4Rear
        End Select

        GARear -= axle.ManufacturerRating

    End If

    OriginalGVM -= axle.ManufacturerRating
    If Not GVMOverride Then
        GVM = Math.Min(GCM, OriginalGVM)
    End If

    'since we are adding an axle,which is a significant change, we should override the wheelbase theoretical
    WheelbaseTheoreticalOverride = False
    Axle1FrontToWheelbaseTheoreticalStartOverride = False

    'enable the validation before adding the axle
    ApplicationGlobals.CurrentCalculation.IsLoading = False

    Try
        Axles.Remove(axle)
    Catch ex As Exception

        'disable the validation while we reverse the changes that we made above
        ApplicationGlobals.CurrentCalculation.IsLoading = True

        _Axle3RearToAxle4Rear = oldAxle3RearAxle4Rear

        If axle.Type = VehicleAxlePositionTypes.SecondSteer Then

            _Axle1FrontToAxle2Front = oldAxle1FrontToAxle2Front
            _BreakpointForWheelbaseFromAxle2Front = oldBreakpointForWheelbaseFromAxle2Front
            _Axle2FrontToAxle1Rear = oldAxle2FrontTo1Rear
            _OriginalWheelbase = oldOriginalWheelbase
            GAFront = oldGAFront

        ElseIf axle.Type = VehicleAxlePositionTypes.Pusher Then

            _Axle2FrontToAxle1Rear = oldAxle2FrontTo1Rear
            _OriginalWheelbase = oldOriginalWheelbase
            _Axle1RearToAxle2Rear = oldAxle1RearToAxle2Rear
            _Axle2RearToAxle3Rear = oldAxle2RearToAxle3Rear
            GARear = oldGARear
            _RearMostProtrudingItemBehindCabToAxles = oldRearMostProtrudingItemBehindCabToAxles

        ElseIf axle.Type = VehicleAxlePositionTypes.Tag Then

            _Axle1RearToAxle2Rear = oldAxle1RearToAxle2Rear
            _Axle2RearToAxle3Rear = oldAxle2RearToAxle3Rear
            _RearMostProtrudingItemBehindCabToAxles = oldRearMostProtrudingItemBehindCabToAxles
            RearOverhang = oldRearOverhang
            BreakPointForRearOverhangFromAxle4Rear = oldBreakpointForRearOverhangFromAxle4Rear
            OriginalRearOverhang = oldOriginalRearOverhang
            GARear = oldGARear

        End If

        OriginalGVM = oldOriginalGVM
        GVM = oldGVM
        WheelbaseTheoreticalOverride = oldWheelbaseTheoreticalOverride
        Axle1FrontToWheelbaseTheoreticalStartOverride = oldAxle1FrontToWheelbaseTheoreticalStartOverride

        'enable the validation before continuing as normal
        ApplicationGlobals.CurrentCalculation.IsLoading = False

        'add the axle back; this should trigger redraw etc
        Axles.Add(axle)

        'display message to the user
        Return New TruckScienceErrorMessage("MSG7265", ResourceMessages.ltlRemoving, ex.Message)

    End Try

    'the wheelbase or rear overhang have changed, so we need to re-align the related accessories
    Accessories.OnVehicleRearOverhangOrWheelbaseChanged(FrontOverhang,
                                                        Axle1FrontToWheelbaseTheoreticalStart,
                                                        WheelbaseTheoretical,
                                                        WheelbaseTheoreticalEndToRearMostAxle,
                                                        RearOverhang)

    RaisePropertyChanged("WheelbaseTheoretical")
    RaisePropertyChanged("WheelbaseTheoreticalForDisplay")
    RaisePropertyChanged("WheelbaseTheoreticalOverride")
    RaisePropertyChanged("HorizontalCOG")
    RaisePropertyChanged("HorizontalCOGForDisplay")
    RaisePropertyChanged("Axle1FrontToWheelbaseTheoreticalStart")
    RaisePropertyChanged("Axle1FrontToWheelbaseTheoreticalStartForDisplay")
    RaisePropertyChanged("Axle1FrontToWheelbaseTheoreticalStartOverride")
    RaisePropertyChanged("Axle1FrontToAxle2Front")
    RaisePropertyChanged("Axle1FrontToAxle2FrontForDisplay")
    RaisePropertyChanged("Axle1FrontToAxle2FrontText")
    RaisePropertyChanged("Axle2FrontToAxle1Rear")
    RaisePropertyChanged("Axle2FrontToAxle1RearForDisplay")
    RaisePropertyChanged("Axle2FrontToAxle1RearText")
    RaisePropertyChanged("Axle1RearToAxle2Rear")
    RaisePropertyChanged("Axle1RearToAxle2RearForDisplay")
    RaisePropertyChanged("Axle1RearToAxle2RearText")
    RaisePropertyChanged("Axle2RearToAxle3Rear")
    RaisePropertyChanged("Axle2RearToAxle3RearForDisplay")
    RaisePropertyChanged("Axle2RearToAxle3RearText")
    RaisePropertyChanged("Axle3RearToAxle4Rear")
    RaisePropertyChanged("Axle3RearToAxle4RearForDisplay")
    RaisePropertyChanged("Axle3RearToAxle4RearText")
    RaisePropertyChanged("RearOverhang")
    RaisePropertyChanged("RearOverhangForDisplay")
    RaisePropertyChanged("RearOverhangText")
    RaisePropertyChanged("RearMostProtrudingItemBehindCabToAxles")
    RaisePropertyChanged("RearMostProtrudingItemBehindCabToAxlesForDisplay")
    RaisePropertyChanged("GAFront")
    RaisePropertyChanged("GAFrontForDisplay")
    RaisePropertyChanged("GARear")
    RaisePropertyChanged("GARearForDisplay")
    RaisePropertyChanged("GVM")
    RaisePropertyChanged("GVMForDisplay")
    RaisePropertyChanged("GCM")
    RaisePropertyChanged("GCMForDisplay")
    RaisePropertyChanged("AUFront")
    RaisePropertyChanged("AUFrontForDisplay")
    RaisePropertyChanged("AURear")
    RaisePropertyChanged("AURearForDisplay")
    RaisePropertyChanged("CombinationTotalPermissible")
    RaisePropertyChanged("CombinationTotalPermissibleForDisplay")
    RaisePropertyChanged("FrontAxleTare")
    RaisePropertyChanged("FrontAxleTareForDisplay")
    RaisePropertyChanged("FrontAxleTareOverride")
    RaisePropertyChanged("RearAxleTare")
    RaisePropertyChanged("RearAxleTareForDisplay")
    RaisePropertyChanged("RearAxleTareOverride")

    Return Nothing
        */
    }

    function getObjectTypeFromPath(path) {
        if (path.includes(config.OBJECT_TYPES.AXLES)) {
            return config.OBJECT_TYPES.AXLES;
        } else if (path.includes(config.OBJECT_TYPES.AXLE_GROUPS)) {
            return config.OBJECT_TYPES.AXLE_GROUPS;
        } else if (path.includes(config.OBJECT_TYPES.PAYLOAD)) {
            return config.OBJECT_TYPES.PAYLOAD;
        } else if (path.includes(config.OBJECT_TYPES.FUEL_TANKS)) {
            return config.OBJECT_TYPES.FUEL_TANKS;
        } else if (path.includes(config.OBJECT_TYPES.ACCESSORY)) {
            return config.OBJECT_TYPES.ACCESSORY;
        } else if (path.includes(config.OBJECT_TYPES.UPRIGHTS)) {
            return config.OBJECT_TYPES.UPRIGHTS;
        } else if (path.includes(config.OBJECT_TYPES.BODY)) {
            return config.OBJECT_TYPES.BODY;
        } else {
            return '';
        }

        /*
            VEHICLE: 'VEHICLE',
            TRAILERS: 'TRAILERS',
            PAYLOAD: 'PAYLOAD',
            AXLES: 'AXLES',
            FUEL_TANKS: 'FUEL_TANKS',
            ACCESSORY: 'ACCESSORY',
            UPRIGHTS: 'UPRIGHTS',
            BODY: 'BODY',
        */
    }

    function getAxleLocationFromAxle(axle) {
        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            return config.AXLE_LOCATIONS.FRONT;
        } else {
            return config.AXLE_LOCATIONS.REAR;
        }
    }

    function getAxleLocationFromAxlePath(axlePath) {

        var axle = getAxleFromPath(axlePath);

        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) {
            return config.AXLE_LOCATIONS.FRONT;
        } else {
            return config.AXLE_LOCATIONS.REAR;
        }
    }

    function resetAxleValues(path, attributeFlag, location) {

        var chassisObject = getChassisObjectFromPath(path),
            originalChassisObject = getOriginalChassisObjectFromPath(path);

        chassisObject.getAxlesHolder().getAxles().forEach(function (axle) {
            var axleLocation = getAxleLocationFromAxle(axle);
            if (axleLocation === location) {
                if (axle.isAdded()) {
                    if (attributeFlag === config.VALUE_TYPE.AXLEMANUFACTURERRATING) {
                        if (axleLocation === config.AXLE_LOCATIONS.FRONT) {
                            axle.setManufacturerRating(originalChassisObject.getAxlesHolder().getAxle1Front().getManufacturerRating());
                        } else {
                            axle.setManufacturerRating(config.vehicleAxleDefaultManufacturerRating);
                        }
                    } else if (attributeFlag === config.VALUE_TYPE.AXLEWEIGHT) {
                        axle.setAxleWeight(config.vehicleAxleDefaultAxleWeight);
                    } else if (attributeFlag === config.VALUE_TYPE.SUSPENSIONMASSRATING) {
                        if (axleLocation === config.AXLE_LOCATIONS.FRONT) {
                            axle.setSuspensionMassRating(originalChassisObject.getAxlesHolder().getAxle1Front().getSuspensionMassRating());
                        } else {
                            axle.setSuspensionMassRating(config.vehicleAxleDefaultSuspensionMassRating);
                        }
                    } else if (attributeFlag === config.VALUE_TYPE.TYREMASSRATING) {
                        if (axleLocation === config.AXLE_LOCATIONS.FRONT) {
                            axle.setTyreMassRatingSingle(originalChassisObject.getAxlesHolder().getAxle1Front().getTyreMassRatingSingle());
                            axle.setTyreMassRatingDual(originalChassisObject.getAxlesHolder().getAxle1Front().getTyreMassRatingDual());
                        } else {
                            axle.setTyreMassRatingSingle(config.vehicleAxleDefaultTyreMassRating);
                            axle.setTyreMassRatingDual(config.vehicleAxleDefaultTyreMassRating);
                        }
                    }
                } else {
                    var originalAxle = originalChassisObject.getAxlesHolder().getAxleById(axle.getId());

                    if (attributeFlag === config.VALUE_TYPE.AXLEMANUFACTURERRATING) {
                        axle.setManufacturerRating(originalAxle.getManufacturerRating());
                    } else if (attributeFlag === config.VALUE_TYPE.AXLEWEIGHT) {
                        axle.setAxleWeight(originalAxle.getAxleWeight());
                    } else if (attributeFlag === config.VALUE_TYPE.SUSPENSIONMASSRATING) {
                        axle.setSuspensionMassRating(originalAxle.getSuspensionMassRating());
                    } else if (attributeFlag === config.VALUE_TYPE.TYREMASSRATING) {
                        axle.setTyreMassRatingSingle(originalAxle.getTyreMassRatingSingle());
                        axle.setTyreMassRatingDual(originalAxle.getTyreMassRatingDual());
                    }
                }
            }
        });
    }

    function distributeAxleWeightOverRearRearAxles(path, mass) {
        var axlesHolder = getAxleHolderFromPath(path);
        var chassisObject = getChassisObjectFromPath(path);
        var numGroupAxles = axlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);

        var startingRearAxleNumber = axlesHolder.getAxleByPosition(axlesHolder.getFirstRearDrivenAxlePosition()).getNumber() - axlesHolder.countAxleLocation(config.AXLE_LOCATIONS.FRONT);
        var curRearAxleNumber = startingRearAxleNumber;

        axlesHolder.getAxles().forEach(function (axle) {



            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR) {
                if (numGroupAxles === 0) {
                    axle.setAxleWeight(0);
                } else if (numGroupAxles === 1) {
                    axle.setAxleWeight(mass);
                } else if (numGroupAxles === 2) {
                    if (curRearAxleNumber === startingRearAxleNumber) {

                        // var firstRearDrivenAxleWeight = mass * axle.getSuspensionMassRating() / (axle.getSuspensionMassRating() + axlesHolder['getAxle' + (curRearAxleNumber + 1) + 'Rear']().getSuspensionMassRating());

                        var firstRearDrivenAxleWeight;

                        if(axle.getSuspensionMassRating() === 0 && axlesHolder['getAxle' + (curRearAxleNumber + 1) + 'Rear']().getSuspensionMassRating() === 0) {
                            firstRearDrivenAxleWeight = mass / numGroupAxles;
                        } else {
                            firstRearDrivenAxleWeight = mass * axle.getSuspensionMassRating() / (axle.getSuspensionMassRating() + axlesHolder['getAxle' + (curRearAxleNumber + 1) + 'Rear']().getSuspensionMassRating());
                        }

                        axle.setAxleWeight(firstRearDrivenAxleWeight);

                        curRearAxleNumber += 1;
                    } else {
                        axle.setAxleWeight(mass - axlesHolder['getAxle' + startingRearAxleNumber + 'Rear']().getAxleWeight());
                    }
                } else {
                    axle.setAxleWeight(mass / numGroupAxles);
                }
            }
        });
    }

    function distributeAxleGroupWeightOverGroupAxles(path, location, mass) {


        var axlesHolder = getAxleHolderFromPath(path);
        var chassisObject = getChassisObjectFromPath(path);
        var numGroupAxles = axlesHolder.countAxleLocation(location);



        if (location === config.AXLE_LOCATIONS.FRONT) {
            distributeFrontAxleGroupWeightOverFrontAxles(mass);
        } else {
            distributeRearAxleGroupWeightOverRearAxles(mass);
        }




        function distributeFrontAxleGroupWeightOverFrontAxles(mass) {
            if (numGroupAxles === 1) {
                axlesHolder.getAxle1Front().setAxleWeight(mass);
            } else {
                var axle1Front = axlesHolder.getAxle1Front();
                var axle2Front = axlesHolder.getAxle2Front();

                var axle1FrontWeight = mass * axle1Front.getSuspensionMassRating() / (axle1Front.getSuspensionMassRating() + axle2Front.getSuspensionMassRating());
                var axle2FrontWeight = mass - axle1FrontWeight;

                axle1Front.setAxleWeight(axle1FrontWeight);
                axle2Front.setAxleWeight(axle2FrontWeight);
            }
        }


        function distributeRearAxleGroupWeightOverRearAxles(mass) {
            var curRearAxleNumber = 1;

            axlesHolder.getAxles().forEach(function (axle) {

                

                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                    if (numGroupAxles === 0) {
                        axle.setAxleWeight(0);
                    } else if (numGroupAxles === 1) {
                        axle.setAxleWeight(mass);
                    } else if (numGroupAxles === 2) {
                        if (curRearAxleNumber === 1) {

                            // var axle1RearWeight = mass * axle.getSuspensionMassRating() / (axle.getSuspensionMassRating() + axlesHolder.getAxle2Rear().getSuspensionMassRating());
                            var axle1RearWeight;

                            if(axle.getSuspensionMassRating() === 0 && axlesHolder.getAxle2Rear().getSuspensionMassRating() === 0) {
                                axle1RearWeight = mass / numGroupAxles;
                            } else {
                                axle1RearWeight = mass * axle.getSuspensionMassRating() / (axle.getSuspensionMassRating() + axlesHolder.getAxle2Rear().getSuspensionMassRating());
                            }

                            axle.setAxleWeight(axle1RearWeight);
                            
                            curRearAxleNumber += 1;
                        } else {
                            axle.setAxleWeight(mass - axlesHolder.getAxle1Rear().getAxleWeight());
                        }
                    } else {
                        axle.setAxleWeight(mass / numGroupAxles);
                    }
                }
            });
        }


    }

    function distributeGivenMassOverGivenAttributeOnAxleGroup(path, attributeFlag, location, mass) {

        var axlesHolder = getAxleHolderFromPath(path);
        var chassisObject = getChassisObjectFromPath(path);
        var numGroupAxles = axlesHolder.countAxleLocation(location);
        
        if (location === config.AXLE_LOCATIONS.FRONT) {
            distributeGivenMassOverFrontAxleGroup(mass);
        } else {
            distributeGivenMassOverRearAxleGroup(mass);
        }


        function distributeGivenMassOverFrontAxleGroup(mass) {
            
            var axle1FrontMass, curFrontAxleNumber = 1;

            axlesHolder.getAxles().forEach(function (axle) {
                var attributeSetFuntion;
                var attributeGetFunction;
                if (attributeFlag === config.VALUE_TYPE.AXLEMANUFACTURERRATING) {
                    attributeSetFuntion = axle.setManufacturerRating;
                    attributeGetFunction = axle.getManufacturerRating;
                } else if (attributeFlag === config.VALUE_TYPE.AXLEWEIGHT) {
                    attributeSetFuntion = axle.setAxleWeight;
                    attributeGetFunction = axle.getAxleWeight;
                } else if (attributeFlag === config.VALUE_TYPE.SUSPENSIONMASSRATING) {
                    attributeSetFuntion = axle.setSuspensionMassRating;
                    attributeGetFunction = axle.getSuspensionMassRating;
                } else if (attributeFlag === config.VALUE_TYPE.TYREMASSRATING) {
                    if (axle.getNoOfTyresOnAxle() === 2) {
                        attributeSetFuntion = axle.setTyreMassRatingSingle;
                        attributeGetFunction = axle.getTyreMassRatingSingle;
                    } else {
                        attributeSetFuntion = axle.setTyreMassRatingDual;
                        attributeGetFunction = axle.getTyreMassRatingDual;
                    }
                    
                }

                //console.log((chassisObject.getAxle1FrontToAxle2Front() + chassisObject.getAxle2FrontToAxle1Rear() + chassisObject.getAxle1RearToAxle2Rear() - chassisObject.getWheelbaseTheoreticalEndToAxle4Rear() - chassisObject.getWheelbaseTheoretical()) / chassisObject.getAxle1FrontToAxle2Front() * mass);

                //console.log(chassisObject.getAxle1FrontToWheelbaseTheoreticalStart() / chassisObject.getAxle1FrontToAxle2Front() * mass);

                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.FRONT || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER)) {
                    if (numGroupAxles === 0) {
                        attributeSetFuntion(0);
                    } else if (numGroupAxles === 1) {
                        attributeSetFuntion(mass);
                    } else {
                        if (curFrontAxleNumber === 1) {
                            attributeSetFuntion(chassisObject.getAxle1FrontToWheelbaseTheoreticalStart() / chassisObject.getAxle1FrontToAxle2Front() * mass);
                            axle1FrontMass = attributeGetFunction();
                            curFrontAxleNumber += 1;
                        } else {
                            attributeSetFuntion(mass - axle1FrontMass);
                        }
                        //attributeSetFuntion(mass / numGroupAxles);
                        
                    }
                }
            });
            
            
        }

        function distributeGivenMassOverRearAxleGroup(mass) {
            var axle1RearWeight, curRearAxleNumber = 1;

            

            axlesHolder.getAxles().forEach(function (axle) {

                var attributeSetFuntion, attributeGetFuntion;
                if (attributeFlag === config.VALUE_TYPE.AXLEMANUFACTURERRATING) {
                    attributeSetFuntion = axle.setManufacturerRating;
                    attributeGetFuntion = axle.getManufacturerRating;
                } else if (attributeFlag === config.VALUE_TYPE.AXLEWEIGHT) {
                    attributeSetFuntion = axle.setAxleWeight;
                    attributeGetFuntion = axle.getAxleWeight;
                } else if (attributeFlag === config.VALUE_TYPE.SUSPENSIONMASSRATING) {
                    attributeSetFuntion = axle.setSuspensionMassRating;
                    attributeGetFuntion = axle.getSuspensionMassRating;
                } else if (attributeFlag === config.VALUE_TYPE.TYREMASSRATING) {
                    if (axle.getNoOfTyresOnAxle() === 2) {
                        attributeSetFuntion = axle.setTyreMassRatingSingle;
                        attributeGetFuntion = axle.getTyreMassRatingSingle;
                    } else {
                        attributeSetFuntion = axle.setTyreMassRatingDual;
                        attributeGetFuntion = axle.getTyreMassRatingDual;
                    }
                    
                    
                }

                if (axle.getRaised() === false && (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.REAR || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG)) {
                    if (numGroupAxles === 0) {
                        attributeSetFuntion(0);
                    } else if (numGroupAxles === 1) {
                        attributeSetFuntion(mass);
                    } else if (numGroupAxles === 2) {
                        if (curRearAxleNumber === 1) {
                            attributeSetFuntion((chassisObject.getAxle1FrontToAxle2Front() + chassisObject.getAxle2FrontToAxle1Rear() + chassisObject.getAxle1RearToAxle2Rear() - chassisObject.getAxle1FrontToWheelbaseTheoreticalStart() - chassisObject.getWheelbaseTheoretical()) / chassisObject.getAxle1RearToAxle2Rear() * mass);
                            axle1RearWeight = attributeGetFuntion();
                            curRearAxleNumber += 1;
                        }else {
                            attributeSetFuntion(mass - axle1RearWeight);
                        }
                    } else {
                        attributeSetFuntion(mass / numGroupAxles);
                    }
                }
            });
            
        }

        /*
        Dim axle1RearWeight As Double
                    Dim curRearAxleNumber As Integer = 1

                    If .AxleWeight = 0 Then
                        'this is an axle defined before we introduced single axle definitions in TIM, so we need to migrate
                        If .Type = AxlePositionTypes.Front Or .Type = AxlePositionTypes.SecondSteer Then
                            If frontAxles = 0 Then
                                .AxleWeight = 0
                            Else
                                .AxleWeight = offer1.FrontAxleTare / frontAxles
                            End If
                        Else
                            If rearAxles = 0 Then
                                .AxleWeight = 0
                            ElseIf rearAxles = 1 Then
                                .AxleWeight = offer1.RearAxleTare
                            ElseIf rearAxles = 2 Then
                                If curRearAxleNumber = 1 Then
                                    .AxleWeight = (offer1.Axle1FrontToAxle2Front + offer1.Axle2FrontToAxle1Rear + offer1.Axle1RearToAxle2Rear - offer1.Axle1FrontToWheelbaseTheoreticalStart - offer1.WheelbaseTheoretical) / offer1.Axle1RearToAxle2Rear * offer1.RearAxleTare
                                    axle1RearWeight = .AxleWeight
                                    curRearAxleNumber += 1
                                Else
                                    .AxleWeight = offer1.RearAxleTare - axle1RearWeight
                                End If
                            Else
                                .AxleWeight = offer1.RearAxleTare / rearAxles
                            End If
                        End If

                    End If
        */
    }

    function getHitchLoad() {

        var tempTrailer1 = activeRig.getTrailer1();

        if (activeRig.getVehicle().getAccessoryHolder().getHitch() !== null && tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.PUP) {
            return tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadTotal() - tempTrailer1.getPayloadRear();
        }

        return 0;
    }

    function getHitchLoadAsPercentageOfTrailerGross() {

        var tempTrailer1 = activeRig.getTrailer1();
        var trailer1TotalGross = (tempTrailer1.getUnladenTotal() + tempTrailer1.getPayloadTotal());
        if (trailer1TotalGross !== 0) {
            return (getHitchLoad() / trailer1TotalGross) * 100;
        }
        return 0;
    }

    function getKingpinLoad(chassisObjectType) {
        var tempTrailer1, tempTrailer2;
        if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            tempTrailer1 = activeRig.getTrailer1();
            tempTrailer2 = activeRig.getTrailer2();

            if ((activeRig.getVehicle().getAccessoryHolder().getFifthWheel() !== null || activeRig.getVehicle().getTareFifthWheelIncluded() === config.VEHICLE_INCLUSION_TYPES.YES) && tempTrailer1 !== null && tempTrailer2 === null && (tempTrailer1.getType() === config.TRAILER_TYPES.SEMI || tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK)) {
                return tempTrailer1.getUnladenFront() + tempTrailer1.getPayloadTotal() - tempTrailer1.getPayloadRear();
            } else if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2 !== null) {
                return getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle() +
                    getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle() +
                        getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle() + getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle() +
                    getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle();
                        //getVehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer();
            }

        } else if (config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            tempTrailer1 = activeRig.getTrailer1();
            tempTrailer2 = activeRig.getTrailer2();

            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2 !== null) {
                return getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle() + getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle();
            }
        }
        return 0;

        
    }

    function getKingpinLoadPercent(chassisObjectType) {

        var tempTrailer1 = activeRig.getTrailer1();

        if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            var trailer1TotalGross = (tempTrailer1.getUnladenTotal() + tempTrailer1.getPayloadTotal());
            if (trailer1TotalGross !== 0) {
                return (getKingpinLoad(chassisObjectType) / trailer1TotalGross) * 100;
            }
        } else if (config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            // var tempTrailer1 = activeRig.getTrailer1();
            var tempTrailer2 = activeRig.getTrailer2();
            var trailer2TotalGross = (tempTrailer2.getUnladenTotal() + tempTrailer2.getPayloadTotal());
            if (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2 !== null && trailer2TotalGross !== 0) {
                return (getKingpinLoad(chassisObjectType) / trailer2TotalGross) * 100;
            }
        }
        return 0;
    }

    function getIsBodyMissing() {
        if (getCombinationType() === config.COMBINATION_TYPES.VEHICLE) {
            return activeRig.getVehicle().getAccessoryHolder().getBody() === null;
        } else if (getCombinationType() === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
            return activeRig.getTrailer1().getAccessoryHolder().getBody === null;
        }
        return false;
    }

    function getOverallWidth() {
        var combinationType = getCombinationType();

        switch (combinationType) {
            case config.COMBINATION_TYPES.VEHICLE:
                return activeRig.getVehicle().getOverallWidth();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return Math.max(activeRig.getVehicle().getOverallWidth(), activeRig.getTrailer1().getOverallWidth());
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return Math.max(activeRig.getVehicle().getOverallWidth(), activeRig.getTrailer1().getOverallWidth(), activeRig.getTrailer2().getOverallWidth());
        }

        return 0;
    }

    function getChassisRearWidth(chassisObject) {

        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return chassisObject.getRearWidth();
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return chassisObject.getOverallWidth();
        }
        return 0;
    }

    function chassisHasBody(chassisObjectType) {
        if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE || chassisObjectType === undefined) {
            return activeRig.getVehicle().getAccessoryHolder().getBody() !== null;
        } else {
            return getBodyFromPath(chassisObjectType) !== null;
        }
    }

    function getCombinationOverallWidth() {
        let tempVehicle = activeRig.getVehicle();
        let tempTrailer1 = activeRig.getTrailer1();
        let tempTrailer2 = activeRig.getTrailer2();

        let topViewCentreLine = getTopViewCentreLine();
        
        let vehicleAccessoryLeftSideWidth = 0, vehicleAccessoryRightSideWidth = 0, trailer1AccessoryLeftSideWidth = 0, trailer1AccessoryRightSideWidth = 0, trailer2AccessoryLeftSideWidth = 0, trailer2AccessoryRightSideWidth = 0;
        let vehiclePayloadLeftSideWidth = 0, vehiclePayloadRightSideWidth = 0, trailer1PayloadLeftSideWidth = 0, trailer1PayloadRightSideWidth = 0, trailer2PayloadLeftSideWidth = 0, trailer2PayloadRightSideWidth = 0;
        
        let vehicleTempBody = tempVehicle.getAccessoryHolder().getBody();
        let vehicleSideWallLeftThickness = 0;
        if(vehicleTempBody !== null) {
            vehicleSideWallLeftThickness = vehicleTempBody.getSidewallLeft();
        }
        
        
        vehicleAccessoryLeftSideWidth = Math.max(topViewCentreLine, tempVehicle.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempVehicle), getLeftOfBodyWidthExternal(tempVehicle)));
        vehicleAccessoryRightSideWidth = Math.max(topViewCentreLine, tempVehicle.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempVehicle), getLeftOfBodyWidthExternal(tempVehicle)));

        vehiclePayloadLeftSideWidth = Math.max(topViewCentreLine, tempVehicle.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempVehicle) + vehicleSideWallLeftThickness));
        vehiclePayloadRightSideWidth = Math.max(topViewCentreLine, tempVehicle.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempVehicle) + vehicleSideWallLeftThickness));

        if(tempTrailer1) {

            let trailer1TempBody = tempTrailer1.getAccessoryHolder().getBody();
            let trailer1SideWallLeftThickness = 0;
            if(trailer1TempBody !== null) {
                trailer1SideWallLeftThickness = trailer1TempBody.getSidewallLeft();
            }

            trailer1AccessoryLeftSideWidth = Math.max(topViewCentreLine, tempTrailer1.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempTrailer1), getLeftOfBodyWidthExternal(tempTrailer1)));
            trailer1AccessoryRightSideWidth = Math.max(topViewCentreLine, tempTrailer1.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempTrailer1), getLeftOfBodyWidthExternal(tempTrailer1)));

            trailer1PayloadLeftSideWidth = Math.max(topViewCentreLine, tempTrailer1.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempTrailer1) + trailer1SideWallLeftThickness));
            trailer1PayloadRightSideWidth = Math.max(topViewCentreLine, tempTrailer1.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempTrailer1) + trailer1SideWallLeftThickness));

            if(tempTrailer2) {

                let trailer2TempBody = tempTrailer2.getAccessoryHolder().getBody();
                let trailer2SideWallLeftThickness = 0;
                if(trailer2TempBody !== null) {
                    trailer2SideWallLeftThickness = trailer2TempBody.getSidewallLeft();
                }

                trailer2AccessoryLeftSideWidth = Math.max(topViewCentreLine, tempTrailer2.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempTrailer2), getLeftOfBodyWidthExternal(tempTrailer2)));
                trailer2AccessoryRightSideWidth = Math.max(topViewCentreLine, tempTrailer2.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempTrailer2), getLeftOfBodyWidthExternal(tempTrailer2)));

                trailer2PayloadLeftSideWidth = Math.max(topViewCentreLine, tempTrailer2.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempTrailer2) + trailer2SideWallLeftThickness));
                trailer2PayloadRightSideWidth = Math.max(topViewCentreLine, tempTrailer2.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempTrailer2) + trailer2SideWallLeftThickness));
            }   
        }
        return Math.max(vehicleAccessoryLeftSideWidth, vehiclePayloadLeftSideWidth, trailer1AccessoryLeftSideWidth, trailer1PayloadLeftSideWidth, trailer2AccessoryLeftSideWidth, trailer2PayloadLeftSideWidth) + 
            Math.max(vehicleAccessoryRightSideWidth, vehiclePayloadRightSideWidth, trailer1AccessoryRightSideWidth, trailer1PayloadRightSideWidth, trailer2AccessoryRightSideWidth, trailer2PayloadRightSideWidth);
        
        // let vehicleOverallwidthIncludingAccessoriesAndPayload = getChassisOverallWidthIncludingAccessoriesAndPayload(tempVehicle);

        // let trailer1OverallwidthIncludingAccessoriesAndPayload = 0, trailer2OverallwidthIncludingAccessoriesAndPayload = 0;
        // if(tempTrailer1) {
        //     trailer1OverallwidthIncludingAccessoriesAndPayload = getChassisOverallWidthIncludingAccessoriesAndPayload(tempTrailer1);

        //     if(tempTrailer2) {
        //         trailer2OverallwidthIncludingAccessoriesAndPayload = getChassisOverallWidthIncludingAccessoriesAndPayload(tempTrailer2);
        //     }   
        // }

        // return Math.max(vehicleOverallwidthIncludingAccessoriesAndPayload, trailer1OverallwidthIncludingAccessoriesAndPayload, trailer2OverallwidthIncludingAccessoriesAndPayload);
    }

    function getVehicleOverallWidth() {
        let tempVehicle = activeRig.getVehicle();

        // return getChassisOverallWidthIncludingAccessoriesAndPayload(tempVehicle);
        // return tempVehicle.getOverallWidthInclAccessoriesAndPayload(getChassisCentreLine(tempVehicle), getLeftOfChassisRearWidth(tempVehicle), getLeftOfBodyWidthExternal(tempVehicle));   
        return getChassisOverallWidth(tempVehicle, getChassisCentreLine(tempVehicle));
    }

    function getTrailer1OverallWidth() {
        let tempTrailer1 = activeRig.getTrailer1();
        
        if(tempTrailer1) {
            // return getChassisOverallWidthIncludingAccessoriesAndPayload(tempTrailer1);  
            // return tempTrailer1.getOverallWidthInclAccessoriesAndPayload(getChassisCentreLine(tempTrailer1), getLeftOfChassisRearWidth(tempTrailer1), getLeftOfBodyWidthExternal(tempTrailer1));  
            return getChassisOverallWidth(tempTrailer1, getChassisCentreLine(tempTrailer1));  
        }

        return 0;
    }

    function getTrailer2OverallWidth() {
        let tempTrailer2 = activeRig.getTrailer2();
        
        if(tempTrailer2) {
            // return getChassisOverallWidthIncludingAccessoriesAndPayload(tempTrailer2);   
            // return tempTrailer2.getOverallWidthInclAccessoriesAndPayload(getChassisCentreLine(tempTrailer2), getLeftOfChassisRearWidth(tempTrailer2), getLeftOfBodyWidthExternal(tempTrailer2));    
            return getChassisOverallWidth(tempTrailer2, getChassisCentreLine(tempTrailer2));
        }

        return 0;
    }

    function getChassisOverallWidth(chassisObject, chassisCentreLine) {
        
        // let topViewCentreLine = getTopViewCentreLine();
        let forChassisOnly = true;
        let chassisAccessoryLeftSideWidth = 0, chassisAccessoryRightSideWidth = 0;
        let chassisPayloadLeftSideWidth = 0, chassisPayloadRightSideWidth = 0;
        
        let chassisTempBody = chassisObject.getAccessoryHolder().getBody();
        let chassisSideWallLeftThickness = 0;
        if(chassisTempBody !== null) {
            chassisSideWallLeftThickness = chassisTempBody.getSidewallLeft();
        }

        chassisAccessoryLeftSideWidth = Math.max(chassisCentreLine, chassisObject.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(chassisCentreLine, getLeftOfChassisRearWidth(chassisObject, forChassisOnly), getLeftOfBodyWidthExternal(chassisObject, forChassisOnly)));
        chassisAccessoryRightSideWidth = Math.max(chassisCentreLine, chassisObject.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(chassisCentreLine, getLeftOfChassisRearWidth(chassisObject, forChassisOnly), getLeftOfBodyWidthExternal(chassisObject, forChassisOnly)));

        chassisPayloadLeftSideWidth = Math.max(chassisCentreLine, chassisObject.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(chassisCentreLine, getLeftOfBodyWidthExternal(chassisObject, forChassisOnly) + chassisSideWallLeftThickness));
        chassisPayloadRightSideWidth = Math.max(chassisCentreLine, chassisObject.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(chassisCentreLine, getLeftOfBodyWidthExternal(chassisObject, forChassisOnly) + chassisSideWallLeftThickness));
       
        return Math.max(chassisAccessoryLeftSideWidth, chassisPayloadLeftSideWidth) + Math.max(chassisAccessoryRightSideWidth, chassisPayloadRightSideWidth);
        
    }

    // function getTrailer2OverallWidth() {
        
        
    //     let tempTrailer2 = activeRig.getTrailer2();

    //     let topViewCentreLine = getTopViewCentreLine();
        
    //     let trailer2AccessoryLeftSideWidth = 0, trailer2AccessoryRightSideWidth = 0;
    //     let trailer2PayloadLeftSideWidth = 0, trailer2PayloadRightSideWidth = 0;
        
        

    //     if(tempTrailer2) {

    //         let trailer2TempBody = tempTrailer2.getAccessoryHolder().getBody();
    //         let trailer2SideWallLeftThickness = 0;
    //         if(trailer2TempBody !== null) {
    //             trailer2SideWallLeftThickness = trailer2TempBody.getSidewallLeft();
    //         }

    //         trailer2AccessoryLeftSideWidth = Math.max(topViewCentreLine, tempTrailer2.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempTrailer2), getLeftOfBodyWidthExternal(tempTrailer2)));
    //         trailer2AccessoryRightSideWidth = Math.max(topViewCentreLine, tempTrailer2.getAccessoryHolder().getAccessoryTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfChassisRearWidth(tempTrailer2), getLeftOfBodyWidthExternal(tempTrailer2)));

    //         trailer2PayloadLeftSideWidth = Math.max(topViewCentreLine, tempTrailer2.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempTrailer2) + trailer2SideWallLeftThickness));
    //         trailer2PayloadRightSideWidth = Math.max(topViewCentreLine, tempTrailer2.getPayloadHolder().getDetailedPayloadTopMostPointDistanceFromTopViewCentreLine(topViewCentreLine, getLeftOfBodyWidthExternal(tempTrailer2) + trailer2SideWallLeftThickness));
    //     }
    //     return Math.max(trailer2AccessoryLeftSideWidth, trailer2PayloadLeftSideWidth) + Math.max(trailer2AccessoryRightSideWidth, trailer2PayloadRightSideWidth);
        
        
    // }

    function getChassisOverallWidthIncludingAccessoriesAndPayload(chassisObject) {
        let leftOfChassisRearWidth = getLeftOfChassisRearWidth(chassisObject); 
        let leftOfBodyWidthExternal = getLeftOfBodyWidthExternal(chassisObject);
        return chassisObject.getOverallWidthInclAccessoriesAndPayload(getTopViewCentreLine(), leftOfChassisRearWidth, leftOfBodyWidthExternal);   
    }

    function getTopViewCentreLine() {
        return getOverallWidth() / 2;
    }

    function getChassisCentreLine(chassisObject) {
        return chassisObject.getOverallWidth() / 2;
    }

    function getTopViewCentreLineForTurningCircle(chassisObject) {
        var topViewCentreLine = 0;
        if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            var cabWidth = chassisObject.getCabWidth(), rearWidth = chassisObject.getRearWidth(), bodyWidth = chassisObject.getRearWidthOfBodyForTurningCircleCalculation();
            

            //if (rearWidth < bodyWidth) {
            //    //topViewCentreLine = chassisObject.getOverallWidthInclAccessories() / 2 - ((Math.max(chassisObject.getCabWidth(), chassisObject.getRearWidth()/*, chassisObject.getRearWidthOfBodyForTurningCircleCalculation()*/) - chassisObject.getRearWidth()) / 2);
            //    topViewCentreLine = chassisObject.getOverallWidthInclAccessories() / 2;
            //} else if (Math.max(cabWidth, bodyWidth, rearWidth) !== bodyWidth) {
            //    topViewCentreLine = chassisObject.getOverallWidthInclAccessories() / 2 - ((Math.max(chassisObject.getCabWidth(), chassisObject.getRearWidth()/*, chassisObject.getRearWidthOfBodyForTurningCircleCalculation()*/) - chassisObject.getRearWidth()) / 2);
            //} else {
            //    //if (Math.min(cabWidth, bodyWidth, rearWidth) === rearWidth && cabWidth > bodyWidth) {
            //    //    topViewCentreLine = chassisObject.getOverallWidthInclAccessories() / 2 - ((bodyWidth - cabWidth)  / 2);
            //    //} else {
            //        topViewCentreLine = chassisObject.getOverallWidthInclAccessories() / 2;
            //    //}
                
            //}

            if (Math.max(cabWidth, bodyWidth, rearWidth) !== bodyWidth) {
                topViewCentreLine = chassisObject.getOverallWidth() / 2 - ((Math.max(chassisObject.getCabWidth(), chassisObject.getRearWidth()/*, chassisObject.getRearWidthOfBodyForTurningCircleCalculation()*/) - chassisObject.getRearWidth()) / 2);
            } else {
                topViewCentreLine = chassisObject.getOverallWidth() / 2;
            }

        } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            //var trailer1OverallWidth = chassisObject.getOverallWidth(), vehicleRearWidth = activeRig.getVehicle().getRearWidth();
            //if (trailer1OverallWidth >= vehicleRearWidth) {
            //    topViewCentreLine = getOverallWidth() / 2;
            //} else {
            //    topViewCentreLine = getOverallWidth() / 2 - ((vehicleRearWidth - trailer1OverallWidth));
            //}
            topViewCentreLine = chassisObject.getOverallWidth() / 2;
            
        } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
            topViewCentreLine = chassisObject.getOverallWidth() / 2;
        }

        return topViewCentreLine;
    }

    function getPortionOfOverallWidthOverhangingLeft() {
        
        var vehicleBottomMostPoint = 0, trailer1BottomMostPoint = 0, trailer2BottomMostPoint = 0;

        var tempVehicle = activeRig.getVehicle();

        var topViewCentreLine = getTopViewCentreLine();
        var leftOfBodyWidthExternalVehicle = 0;
        let sidewallLeftThickness = 0;
        let tempVehicleBody = tempVehicle.getAccessoryHolder().getBody(); 
        if (tempVehicleBody !== null) {
            leftOfBodyWidthExternalVehicle = getLeftOfBodyWidthExternal(tempVehicle);
            sidewallLeftThickness = tempVehicleBody.getSidewallLeft();
        }
        var leftOfVehicleRearWidth = getLeftOfChassisRearWidth(tempVehicle);
        

        vehicleBottomMostPoint = Math.max(tempVehicle.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternalVehicle), tempVehicle.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalVehicle + sidewallLeftThickness));

        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        if (tempTrailer1 !== null) {
            //var leftOfBodyWidthExternalTrailer1 = 0;
            //if (tempTrailer1.getAccessoryHolder().getBody() !== null) {
            //    leftOfBodyWidthExternalTrailer1 = getLeftOfBodyWidthExternal(tempTrailer1);
            //}

            //var leftOfTrailerRearWidth = getLeftOfChassisRearWidth(tempTrailer1);

            //trailer1BottomMostPoint = Math.max(tempTrailer1.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfTrailerRearWidth, leftOfBodyWidthExternalTrailer1), tempTrailer1.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalTrailer1));
            trailer1BottomMostPoint = getPortionOfTrailerOverhangingLeft(tempTrailer1);

            if (tempTrailer2 !== null) {
                trailer2BottomMostPoint = getPortionOfTrailerOverhangingLeft(tempTrailer2);
            }
        }
        

        //var vehicleBottomMostPointTopView = tempVehicle.getAccessoryHolder().getAccessoryBottomMostPointTopView(topViewCentreLine, leftOfVehicleRearWidth, leftOfBodyWidthExternalVehicle);
        //if (vehicleBottomMostPointTopView < 0) {
        //    return Math.abs(vehicleBottomMostPointTopView);
        //} else {
        //    return 0;
        //}
        ////return 0;

        var leftOverhang = Math.max(vehicleBottomMostPoint, trailer1BottomMostPoint, trailer2BottomMostPoint) - topViewCentreLine;
        if (leftOverhang < 0) {
            return 0;
        }
        return leftOverhang;

        function getPortionOfTrailerOverhangingLeft(trailer) {
            var leftOfBodyWidthExternalTrailer = 0;
            let tempTrailerBody = trailer.getAccessoryHolder().getBody();
            let sidewallLeftThickness = 0;
            if (tempTrailerBody !== null) {
                leftOfBodyWidthExternalTrailer = getLeftOfBodyWidthExternal(trailer);
                sidewallLeftThickness = tempTrailerBody.getSidewallLeft();
            }

            var leftOfTrailerRearWidth = getLeftOfChassisRearWidth(trailer);

            var trailerBottomMostPoint = Math.max(trailer.getAccessoryHolder().getAccessoryBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfTrailerRearWidth, leftOfBodyWidthExternalTrailer), trailer.getPayloadHolder().getDetailedPayloadBottomMostPointDistanceFromTopViewCentreLine(topViewCentreLine, leftOfBodyWidthExternalTrailer + sidewallLeftThickness));

            return trailerBottomMostPoint;
        }
    }


    function getLeftOfChassisRearWidth(chassisObject, forChassisOnly) {


        let maxWidth = 0;
        if(forChassisOnly) {
            maxWidth = chassisObject.getOverallWidth();
        }else {
            maxWidth = getOverallWidth();
        }
        var centreLine = maxWidth / 2;


        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return centreLine - chassisObject.getRearWidth() / 2;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return centreLine - chassisObject.getOverallWidth() / 2;
        }
        return 0;
    }

    function getLeftOfVehicleWidestWidth() {
        var maxWidth = getOverallWidth();
        var centreLine = maxWidth / 2;

        return centreLine - activeRig.getVehicle().getOverallWidth() / 2;
    }

    function getLeftOfChassisRearWidthForTurningCircle(chassisObject) {
        var centreLine, maxWidth;
        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                //var maxWidth = activeRig.getVehicle().getOverallWidthInclAccessories();
                //var centreLine = maxWidth / 2;
                centreLine = getTopViewCentreLineForTurningCircle(chassisObject);
                return centreLine - chassisObject.getRearWidth() / 2;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                maxWidth = activeRig.getTrailer1().getOverallWidth();
                centreLine = maxWidth / 2;
                return centreLine - chassisObject.getOverallWidth() / 2;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                maxWidth = activeRig.getTrailer2().getOverallWidth();
                centreLine = maxWidth / 2;
                return centreLine - chassisObject.getOverallWidth() / 2;
        }
    }

    function getLeftOfVehicleCabWidth() {

        var maxWidth = getOverallWidth();
        var centreLine = maxWidth / 2;

        return centreLine - activeRig.getVehicle().getCabWidth() / 2;
        
    }

    function getLeftOfBodyWidthExternal(chassisObject, forChassisOnly) {


        let maxWidth = 0;
        if(forChassisOnly) {
            maxWidth = chassisObject.getOverallWidth();
        }else {
            maxWidth = getOverallWidth();
        }
        
        var centreLine = maxWidth / 2;

        var tempBody = chassisObject.getAccessoryHolder().getBody();

        var bodyWidthExternal = 0;
        if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            if (chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                bodyWidthExternal = chassisObject.getRearWidth();
            } else {
                if (tempBody !== null) {
                    bodyWidthExternal = tempBody.getActualWidth();
                }
            }
        } else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
            if (chassisObject.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD || (chassisObject.getSource() === config.VEHICLE_SOURCE_TYPES.CUSTOM && chassisObject.getBodyType() !== '' && chassisObject.getBodyType() !== 'NONE')) {
                bodyWidthExternal = chassisObject.getOverallWidth();
            } else {
                if (tempBody !== null) {
                    bodyWidthExternal = tempBody.getActualWidth();
                }
            }
            
        }

        return centreLine - bodyWidthExternal / 2;

    }

    function getLeftOfBodyWidthExternalForTurningCircle(chassisObject) {

        var maxWidth;

        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                maxWidth = activeRig.getVehicle().getOverallWidth();
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                maxWidth = activeRig.getTrailer1().getOverallWidth();
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                maxWidth = activeRig.getTrailer2().getOverallWidth();
                break;
        }

        
        var centreLine = getTopViewCentreLineForTurningCircle(chassisObject);//maxWidth / 2;
        if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return centreLine - chassisObject.getRearWidth() / 2;
        } else {
            return centreLine - chassisObject.getAccessoryHolder().getBody().getActualWidth() / 2;
        }
        

    }

    function getBodySidewallLeft(chassisObject) {
        var leftOfBodyWidthExternal = getLeftOfBodyWidthExternal(chassisObject);

        return leftOfBodyWidthExternal + chassisObject.getAccessoryHolder().getBody().getSidewallLeft();
    }

    function getBodySidewallLeftForTurningCircle(chassisObject) {
        var leftOfBodyWidthExternal = getLeftOfBodyWidthExternalForTurningCircle(chassisObject);

        return leftOfBodyWidthExternal + chassisObject.getAccessoryHolder().getBody().getSidewallLeft();
    }

    

    function getLeftOfChassisToPayloadDeckStart(chassisObject) {
        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return chassisObject.getLeftOfVehicleToPayloadDeckStart();
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return chassisObject.getLeftOfTrailerToPayloadDeckStart();
        }
        return 0;
    }

    function getPayloadDeckStartPositionFromFrontForCompleteVehicleOrStandardTrailerInclPortionOfVehicleOverhangingFront(chassisObject) {
        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return getFrontOfChassisInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName()) + chassisObject.getBumperToStartOfBodyInternal();
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getFrontOfChassisInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName())
        }
        return 0;
    }

    function getPayloadDeckStartPositionFromFrontForCompleteVehicleOrStandardTrailer(chassisObject) {
        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return getFrontOfChassis(chassisObject.getChassisObjectName()) + chassisObject.getBumperToBackOfCab();
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getFrontOfChassis(chassisObject.getChassisObjectName())
        }
        return 0;
    }

    function getPayloadDeckStartPositionFrontFrontInclPortionOfVehicleOverhangingFront(chassisObject) {
        var x = 0;

        var tempBody = chassisObject.getAccessoryHolder().getBody();

        if (tempBody !== null) {
            x = getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.BODY) + tempBody.getHeadboardThickness();
            //if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
            //    var tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
            //    x += tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
            //}
        } else {
            x = getPayloadDeckStartPositionFromFrontForCompleteVehicleOrStandardTrailerInclPortionOfVehicleOverhangingFront(chassisObject);
        }

        
        return x;
    }

    function getPayloadDeckStartPositionFrontFront(chassisObject) {
        var x = 0;

        var tempBody = chassisObject.getAccessoryHolder().getBody();

        if (tempBody !== null) {
            x = getItemStartFromFrontOfRig(chassisObject.getChassisObjectName(), config.ACCESSORY_TYPES.BODY) + tempBody.getHeadboardThickness();
            //if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
            //    var tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
            //    x += tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset();
            //}
        } else {
            x = getPayloadDeckStartPositionFromFrontForCompleteVehicleOrStandardTrailer(chassisObject);
        }


        return x;
    }

    function getPayloadDeckStartPositionFromBottom(chassisObject) {
        //var y = 0;

        //var tempBody = chassisObject.getAccessoryHolder().getBody();

        //if (tempBody !== null) {
        //    if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
        //        y = getChassisHeightFromPath(chassisObject.getChassisObjectName()) - tempBody.getBottomOfBodyToTopOfChassis();
        //    } else {
        //        y = getChassisHeightFromPath(chassisObject.getChassisObjectName()) + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight();
        //    }
        //} else if (chassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObject.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
        //    y = chassisObject.get;
        //} else {
        //    y = getChassisHeightFromPath(chassisObject.getChassisObjectName());
        //}


        //return y;
        var payloadDeckStartPositionFromBottom = chassisObject.getBottomToPayloadDeckStart();
        var tempBody = chassisObject.getAccessoryHolder().getBody();
        //if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
        //    var tempHooklift = chassisObject.getAccessoryHolder().getHooklift();
        //    payloadDeckStartPositionFromBottom += tempHooklift.getVerticalGap() + tempHooklift.getPlatformHeight();
        //}
        return payloadDeckStartPositionFromBottom;
    }

    function getPayloadDeckStartPositionFromLeftInclPortionOfChassisOverhangingLeft(chassisObject) {
        var y = 0;

        var tempBody = chassisObject.getAccessoryHolder().getBody();


        if (tempBody !== null) {

            y = getBodySidewallLeft(chassisObject);
        } else {

            y = getLeftOfChassisRearWidth(chassisObject) + chassisObject.getLeftToPayloadDeckStart();

        }

        
        return y;
    }

    function getPayloadDeckStartPositionFromLeftForTurningCircle(chassisObject) {
        var y = 0;

        var tempBody = chassisObject.getAccessoryHolder().getBody();

        

        switch (chassisObject.getChassisObjectName()) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                if (tempBody !== null) {

                    y = getBodySidewallLeftForTurningCircle(chassisObject);
                } else {

                    y = getLeftOfChassisRearWidthForTurningCircle(chassisObject);
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                if (tempBody !== null) {
                    y = getBodySidewallLeft(chassisObject);
                } else {
                    y = getLeftOfChassisRearWidth(chassisObject);
                }
                break;    
        }
        
        return y;
    }

    function getVehicleStaticRolloverAngle() {
        return (Math.atan((activeRig.getVehicle().getTrackWidthRear() / 2 - Math.abs(getOverallLateralCOG())) / getOverallVerticalCOG())) * 180 / Math.PI;
    }

    function getTrailer1StaticRolloverAngle() {
        return (Math.atan(((activeRig.getTrailer1().getOverallWidth() - activeRig.getTrailer1().getTyreWidth() * 2 - 30) / 2 - Math.abs(getTrailer1OverallLateralCOG())) / getTrailer1OverallVerticalCOG())) * 180 / Math.PI;
        //Overall Width - Tyre Width * 2 - 30mm
    }

    function getTrailer2StaticRolloverAngle() {
        return (Math.atan(((activeRig.getTrailer2().getOverallWidth() - activeRig.getTrailer2().getTyreWidth() * 2 - 30) / 2 - Math.abs(getTrailer2OverallLateralCOG())) / getTrailer2OverallVerticalCOG())) * 180 / Math.PI;
    }

    function getStaticRolloverAngleByPath(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return getVehicleStaticRolloverAngle();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return getTrailer1StaticRolloverAngle();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return getTrailer2StaticRolloverAngle();
        }
        return 0;
    }

    function canCalculateVehicleStaticRolloverAngle() {
        return activeRig.getVehicle().getTrackWidthRear() > 0;
    }

    function canCalculateTrailer1StaticRolloverAngle() {
        return activeRig.getTrailer1().getOverallWidth() > 0;
    }

    function canCalculateTrailer2StaticRolloverAngle() {
        return activeRig.getTrailer2().getOverallWidth() > 0;
    }

    function calculatedOuterTrackWidthForCanadianRegs(numberOfTyresPerAxle, trackWidth, widthPerTyre, tyreGap) {
        
        

        if (trackWidth === 0) {
            let singleTyres = numberOfTyresPerAxle === 2;
            let widthToUse = activeRig.getVehicle().getRearWidth();
            if(widthToUse === 0) {
                widthToUse = activeRig.getVehicle().getCabWidth();
            }
            //'no track width has been supplied, so we need to calculate it to fit in with the overall width
            if (singleTyres === true) {
                trackWidth = widthToUse - widthPerTyre;
            } else {
                trackWidth = widthToUse - (2 * widthPerTyre + tyreGap);
            }
        }


        if(numberOfTyresPerAxle === 4) {
             return trackWidth + (widthPerTyre * 2) + tyreGap;
        } else {
            return trackWidth + widthPerTyre;
        }
        // IF VehicleRearRearOnlyNoOfTyresPerAxle = 4
        //  RETURN TrackWidthVehicleRearMain + (VehicleRearRearOnlyWidthPerTyre * 2) + (Half the width of the space between the two tires * 2)
        // ELSE (2 tires per axle)
        //  RETURN TrackWidthVehicleRearMain + VehicleRearRearOnlyWidthPerTyre
        // ENDIF
    }


    function getVehicleFrontOuterTrackWidth() {
        let tempVehicle = activeRig.getVehicle();
        let frontAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT);
        return calculatedOuterTrackWidthForCanadianRegs(frontAxleGroup.getNoOfTyresOnAxle(), tempVehicle.getTrackWidthFront(), frontAxleGroup.getTyreWidth(), config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM);
    }
   
    function getVehicleRearMainOuterTrackWidth() {
        let tempVehicle = activeRig.getVehicle();
        let rearAxleGroup = tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
        return calculatedOuterTrackWidthForCanadianRegs(rearAxleGroup.getNoOfTyresOnAxle(), tempVehicle.getTrackWidthRear(), rearAxleGroup.getTyreWidth(), config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM);
    }
    
    

    function getVehicleRearMinus1OuterTrackWidth() {
        let tempVehicle = activeRig.getVehicle();
        let minus1Axle = tempVehicle.getAxlesHolder().getMinus1();
        if(minus1Axle) {
            return calculatedOuterTrackWidthForCanadianRegs(minus1Axle.getNoOfTyresOnAxle(), tempVehicle.getTrackWidthRear(), minus1Axle.getTyreWidth(), config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM);
        }
        return 0;
    }

    function getVehicleRearPlus1OuterTrackWidth() {
        let tempVehicle = activeRig.getVehicle();
        let plus1Axle = tempVehicle.getAxlesHolder().getTag1();
        if(plus1Axle) {
            return calculatedOuterTrackWidthForCanadianRegs(plus1Axle.getNoOfTyresOnAxle(), tempVehicle.getTrackWidthRear(), plus1Axle.getTyreWidth(), config.TYRE_GAPS.GAP_BETWEEN_TYRES_IN_MM);
        }
        return 0;
    }

    //function getManufacturerWeightsComplianceFailed() {

        
        
    //    var tempTrailer2 = activeRig.getTrailer2();


    //    switch (getCombinationType()) {
    //        case config.COMBINATION_TYPES.VEHICLE:
    //            return getManufacturerWeightsVehicleComplianceFailed();
    //            break;
    //        case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    //            return getManufacturerWeightsVehicleComplianceFailed() || getManufacturerWeightsTrailer1ComplianceFailed() || getManufacturerWeightsVehicleAndDrawbarComplianceFailed();
    //        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    //            return getManufacturerWeightsVehicleAndSemiOrInterlinkVehicleComplianceFailed() || getManufacturerWeightsTrailer1ComplianceFailed() || getManufacturerWeightsVehicleAndSemiOrInterlinkComplianceFailed();
    //        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
    //            return getManufacturerWeightsVehicleAndPupVehicleComplianceFailed() || getManufacturerWeightsTrailer1ComplianceFailed() || getManufacturerWeightsVehicleAndPupComplianceFailed();
    //    }
    //    return false;

    //}

    function getManufacturerWeightsAxlesOverloaded() {



        var tempTrailer2 = activeRig.getTrailer2();


        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getManufacturerWeightsVehicleComplianceFailed() || getVehicleManufacturerRatingRearRearOnlyAxlesExceeded() || getVehicleManufacturerRatingAnyPusherOrTagAxleExceeded();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getManufacturerWeightsVehicleComplianceFailed() || getManufacturerWeightsTrailer1ComplianceFailed() || getVehicleManufacturerRatingRearRearOnlyAxlesExceeded() || getVehicleManufacturerRatingAnyPusherOrTagAxleExceeded();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return getManufacturerWeightsVehicleAndSemiOrInterlinkVehicleComplianceFailed() || getManufacturerWeightsTrailer1ComplianceFailed() || getVehicleManufacturerRatingRearRearOnlyAxlesExceeded() || getVehicleManufacturerRatingAnyPusherOrTagAxleExceeded();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getManufacturerWeightsVehicleAndPupVehicleComplianceFailed() || getManufacturerWeightsTrailer1ComplianceFailed() || getVehicleManufacturerRatingRearRearOnlyAxlesExceeded() || getVehicleManufacturerRatingAnyPusherOrTagAxleExceeded();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getManufacturerWeightsVehicleAndInterlinkAndSemiVehicleComplianceFailed() || getManufacturerWeightsTrailer1ComplianceFailed() || getManufacturerWeightsTrailer2ComplianceFailed() || getVehicleManufacturerRatingRearRearOnlyAxlesExceeded() || getVehicleManufacturerRatingAnyPusherOrTagAxleExceeded();
        }
        return false;

    }

    function getManufacturerWeightsGrossCombinationOverloaded() {
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getManufacturerWeightsVehicleGVMOverloaded();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getManufacturerWeightsVehicleAndDrawbarComplianceFailed();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return getManufacturerWeightsVehicleAndSemiOrInterlinkComplianceFailed();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getManufacturerWeightsVehicleAndPupComplianceFailed();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getManufacturerWeightsVehicleAndInterlinkAndSemiComplianceFailed();
        }
        return false;
    }

    function getVehicleManufacturerRatingRearRearOnlyAxlesExceeded() {
        
        var tempVehicle = activeRig.getVehicle();
        if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
            var rearRearOnlyMassValToUse;
            if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                rearRearOnlyMassValToUse = activeRig.getVehicle().getAxlesHolder().getRearRearAxlesGrossAxleWeightRating();
            } else {
                rearRearOnlyMassValToUse = activeRig.getVehicle().getAxlesHolder().getRearRearAxlesSuspensionRating();
            }
            return applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearRearAxlesDistribution(getCombinationVehicleRearGross())) > applyRoundingForManufacturerWeightsComplianceFailed(rearRearOnlyMassValToUse);
        }
        return false;
    }

    function getVehicleManufacturerRatingAnyPusherOrTagAxleExceeded() {
        var tempVehicle = activeRig.getVehicle();
        
        var returnVal = false;
        if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
            tempVehicle.getAxlesHolder().getAxles().forEach(function (axle) {
                if((axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) && !axle.getRaised()) {
                    var pusherOrTagMassValToUse;
                    if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.SIMPLIFIED) {
                        pusherOrTagMassValToUse = axle.getGrossAxleWeightRating();
                    } else {
                        pusherOrTagMassValToUse = axle.getSuspensionMassRating();
                    }
                    returnVal = returnVal || (applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getPusherOrTagRearAxleDistribution(axle.getNumber(), getCombinationVehicleRearGross())) > applyRoundingForManufacturerWeightsComplianceFailed(pusherOrTagMassValToUse));
                }
                
            });
        }
        return returnVal;
    }

    function getManufacturerWeightsVehicleComplianceFailed() {
        // var tempVehicle = activeRig.getVehicle();

        // if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
        //     return getVehicleManufacturerRatingFrontAxlesExceeded() || getVehicleManufacturerRatingRearAxlesExceeded() || tempVehicle.getAxlesHolder().getAnyPusherOrTagAxleOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion) || tempVehicle.getAxlesHolder().getRearRearOnlyAxlesOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion);
        // } else {
        //     return getVehicleManufacturerRatingFrontAxlesExceeded() || getVehicleManufacturerRatingRearAxlesExceeded();
        // }
        return getVehicleManufacturerRatingFrontAxlesExceeded() || getVehicleManufacturerRatingRearAxlesExceeded();
    }

    function getVehicleManufacturerRatingFrontAxlesExceeded() {
        var tempVehicle = activeRig.getVehicle();
        return applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleFrontGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating());
    }

    function getVehicleManufacturerRatingRearAxlesExceeded() {
        var tempVehicle = activeRig.getVehicle();
        //return applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleRearGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getGrossAxleWeightRating());
        return applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleRearGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating());
        
    }

    function getManufacturerWeightsVehicleGVMOverloaded() {
        var tempVehicle = activeRig.getVehicle();

        return applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getGrossTotal()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getGVM());
    }

    function getManufacturerWeightsVehicleAndTransferFromTrailerGVMOverloaded() {
        var tempVehicle = activeRig.getVehicle();

        return (applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleFrontGross() + getCombinationVehicleRearGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getGVM()))
    }

    function getManufacturerWeightsVehicleAndPupVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        // if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
        //     return applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleFrontGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
        //         applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndPupVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating()) ||
        //         tempVehicle.getAxlesHolder().getAnyPusherOrTagAxleOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion) || tempVehicle.getAxlesHolder().getRearRearOnlyAxlesOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion);
        // } else {
        //     return applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleFrontGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
        //         applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndPupVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating());
        // }
        return applyRoundingForManufacturerWeightsComplianceFailed(getCombinationVehicleFrontGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
                applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndPupVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating());
        
    }

    function getManufacturerWeightsVehicleAndSemiOrInterlinkVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        // if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
        //     return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleFrontAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
        //         applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating()) ||
        //         tempVehicle.getAxlesHolder().getAnyPusherOrTagAxleOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion) || tempVehicle.getAxlesHolder().getRearRearOnlyAxlesOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion);
        // } else {
        //     return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleFrontAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
        //         applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating());
        // }
        return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleFrontAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
                applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating());
        
    }
    
    function getManufacturerWeightsVehicleAndInterlinkAndSemiVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        // if (tempVehicle.getAxlesHolder().getHasPusherOrTagAxleDown()) {
        //     return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleFrontAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
        //         applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating()) ||
        //         tempVehicle.getAxlesHolder().getAnyPusherOrTagAxleOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion) || tempVehicle.getAxlesHolder().getRearRearOnlyAxlesOverloaded(config.roundingPrecisionForTableAndWarnings, globals.user.getActiveMeasurementSystem().massConversion);
        // } else {
        //     return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleFrontAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
        //         applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating());
        // }
        return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleFrontAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getGrossAxleWeightRating()) ||
                applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleRearAxleGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getAxlesHolder().getRearAxlesGrossAxleWeightRating());
        
    }

    function getManufacturerWeightsTrailer1ComplianceFailed() {
        var tempTrailer1 = activeRig.getTrailer1();

        //switch (tempTrailer1.getType()) {
        //    case config.TRAILER_TYPES.DRAWBAR:
        //    case config.TRAILER_TYPES.PUP:
        //        return getTrailer1FrontGross() > tempTrailer1.getManufacturersFrontAxlesMass() || getTrailer1RearGross() > tempTrailer1.getManufacturersRearAxlesMass();
        //    case config.TRAILER_TYPES.SEMI:
        //        return getTrailer1FrontGross() > tempTrailer1.getManufacturersFrontAxlesMass() || getTrailer1RearGross() > tempTrailer1.getManufacturersRearAxlesMass();

        //}
        return applyRoundingForManufacturerWeightsComplianceFailed(getTrailer1FrontGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getGrossAxleWeightRating()) ||
            getManufacturerWeightsTrailer1RearAxlesExceeded();
    }

    function getManufacturerWeightsTrailer2ComplianceFailed() {
        var tempTrailer2 = activeRig.getTrailer2();

        return applyRoundingForManufacturerWeightsComplianceFailed(getTrailer2FrontGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempTrailer2.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getGrossAxleWeightRating()) ||
            getManufacturerWeightsTrailer2RearAxlesExceeded();
    }

    function getManufacturerWeightsTrailer1RearAxlesExceeded() {
        var tempTrailer1 = activeRig.getTrailer1();
        //return applyRoundingForManufacturerWeightsComplianceFailed(getTrailer1RearGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempTrailer1.getManufacturersRearAxlesMass());
        return applyRoundingForManufacturerWeightsComplianceFailed(getTrailer1RearGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempTrailer1.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getGrossAxleWeightRating());
    }

    function getManufacturerWeightsTrailer2RearAxlesExceeded() {
        var tempTrailer2 = activeRig.getTrailer2();

        return applyRoundingForManufacturerWeightsComplianceFailed(getTrailer2RearGross()) > applyRoundingForManufacturerWeightsComplianceFailed(tempTrailer2.getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getGrossAxleWeightRating());
    }

    //function getManufacturerWeightsDrawbarComplianceFailed() {
    //    var tempTrailer1 = activeRig.getTrailer1();

    //    return getTrailer1FrontGross() > tempTrailer1.getManufacturersFrontAxlesMass() || getTrailer1RearGross() > tempTrailer1.getManufacturersRearAxlesMass();
    //}

    function getManufacturerWeightsVehicleAndDrawbarComplianceFailed() {
        return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndDrawbarTotalGross()) > applyRoundingForManufacturerWeightsComplianceFailed(getGrossCombinationMass());
    }

    //function getManufacturerWeightsPupComplianceFailed() {
    //    var tempTrailer1 = activeRig.getTrailer1();

    //    return getTrailer1FrontGross() > tempTrailer1.getManufacturersFrontAxlesMass() || getTrailer1RearGross() > tempTrailer1.getManufacturersRearAxlesMass();
    //}

    function getManufacturerWeightsVehicleAndPupComplianceFailed() {
        return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndPupTotalGross()) > applyRoundingForManufacturerWeightsComplianceFailed(getGrossCombinationMass());
    }

    function getManufacturerWeightsVehicleAndSemiOrInterlinkComplianceFailed() {
        return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndSemiOrInterlinkTotalGross()) > applyRoundingForManufacturerWeightsComplianceFailed(getGrossCombinationMass());
    }

    function getManufacturerWeightsVehicleAndInterlinkAndSemiComplianceFailed() {
        return applyRoundingForManufacturerWeightsComplianceFailed(getVehicleAndInterlinkAndSemiTotalGross()) > applyRoundingForManufacturerWeightsComplianceFailed(getGrossCombinationMass());
    }

    function getMaxUnladenVehicleWeightExceeded() {
        let tempVehicle = activeRig.getVehicle();
        let maxUVW = tempVehicle.getMaxUnladenVehicleWeight();
        let unladenVehicleWeight = tempVehicle.getUnladenVehicleWeight();

        return applyRoundingForManufacturerWeightsComplianceFailed(unladenVehicleWeight) > applyRoundingForManufacturerWeightsComplianceFailed(maxUVW);
    }

    function getMaxUnladenVehicleWeightChanged() {
        let tempMaxUVW = activeRig.getVehicle().getMaxUnladenVehicleWeight();
        let tempMaxUVWOverride = activeRig.getVehicle().getMaxUnladenVehicleWeightOverride();
        let tempOriginalMaxUVW = originalRig.getVehicle().getMaxUnladenVehicleWeight();

        return applyRoundingForManufacturerWeightsComplianceFailed(tempMaxUVW) !== applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalMaxUVW) && tempMaxUVWOverride && tempOriginalMaxUVW > 0;
    }

    function getAnyOverloadedAxlesPermissibleVehicle() {
        var tempVehicle = activeRig.getVehicle();
        //var tempTrailer1 = activeRig.getTrailer1();
        //var tempTrailer2 = activeRig.getTrailer2();

        var hasOverloadedAxle = false;

        //switch (getCombinationType()) {
        //    case config.COMBINATION_TYPES.VEHICLE:
                
                tempVehicle.getAxlesHolder().getAxles().forEach(function (axle) {
                    if (axle.getSteering() === true) {
                        if (applyRoundingForManufacturerWeightsComplianceFailed(axle.getAxleWeight()) > applyRoundingForManufacturerWeightsComplianceFailed(evaluateLegislation(legislationVals.variableTypes.PermissibleMaximumSingleAxleMassSteeringSingles))) {
                            hasOverloadedAxle = true;
                        }
                    } else if (axle.getNoOfTyresOnAxle() === 2) {
                        if (applyRoundingForManufacturerWeightsComplianceFailed(axle.getAxleWeight()) > applyRoundingForManufacturerWeightsComplianceFailed(evaluateLegislation(legislationVals.variableTypes.PermissibleMaximumSingleAxleMassSingles))) {
                            hasOverloadedAxle = true;
                        }
                    } else if (axle.getNoOfTyresOnAxle() === 4) {
                        if (applyRoundingForManufacturerWeightsComplianceFailed(axle.getAxleWeight()) > applyRoundingForManufacturerWeightsComplianceFailed(evaluateLegislation(legislationVals.variableTypes.PermissibleMaximumSingleAxleMassDuals))) {
                            hasOverloadedAxle = true;
                        }
                    }
                });
        //        break;
        //    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        //        return hasOverloadedAxle;
        //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //        return hasOverloadedAxle;
        //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //        return hasOverloadedAxle;
        //}
        return hasOverloadedAxle;
    }

    function applyRoundingForManufacturerWeightsComplianceFailed(valToRound) {

        valToRound = valToRound * globals.user.getActiveMeasurementSystem().massConversion;

        return parseFloat(valToRound.toFixed(config.roundingPrecisionForTableAndWarnings));
    }

    function applyRoundingForPermissibleWeightsComplianceFailed(valToRound) {
        return parseFloat(valToRound.toFixed(config.roundingPrecisionForTableAndWarnings));
    }

    function getPermissibleWeightsComplianceFailed() {
        
        
        var tempTrailer2 = activeRig.getTrailer2();

        

        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getPermissibleWeightsVehicleComplianceFailed();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getPermissibleWeightsVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() /*|| getPermissibleWeightsVehicleAndDrawbarComplianceFailed()*/;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                return getPermissibleWeightsVehicleAndSemiOrInterlinkVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() /*|| getPermissibleWeightsVehicleAndSemiOrInterlinkComplianceFailed()*/;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getPermissibleWeightsVehicleAndPupVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() /*|| getPermissibleWeightsVehicleAndPupComplianceFailed()*/;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getPermissibleWeightsVehicleAndInterlinkAndSemiVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() || getPermissibleWeightsTrailer2ComplianceFailed() ;
        }
        return false;
    }

    function getPermissibleWeightsVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
        var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

        return applyRoundingForPermissibleWeightsComplianceFailed(getCombinationVehicleFrontGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) || applyRoundingForPermissibleWeightsComplianceFailed(getCombinationVehicleRearGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) || applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) || getAnyOverloadedAxlesPermissibleVehicle();
    }

    function getPermissibleWeightsVehicleAndDrawbarVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
        var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

        return getCombinationVehicleFrontGross() > vehicleFrontSingleOrDual || getCombinationVehicleRearGross() > vehicleRearSingleDualTridem || /*tempVehicle.getGrossTotal() > (vehicleFrontSingleOrDual + vehicleRearSingleDualTridem) ||*/ getAnyOverloadedAxlesPermissibleVehicle();
    }

    function getPermissibleWeightsVehicleAndPupVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
        var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

        return applyRoundingForPermissibleWeightsComplianceFailed(getCombinationVehicleFrontGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) || applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndPupVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) || applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) || getAnyOverloadedAxlesPermissibleVehicle();
    }

    function getPermissibleWeightsVehicleAndSemiOrInterlinkVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
        var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

        return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleFrontAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) || applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) || applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) || getAnyOverloadedAxlesPermissibleVehicle();
    }
    
    function getPermissibleWeightsVehicleAndInterlinkAndSemiVehicleComplianceFailed() {
        var tempVehicle = activeRig.getVehicle();

        var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
        var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

        return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleFrontAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) ||
            applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) ||
            applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) ||
            getAnyOverloadedAxlesPermissibleVehicle();
    }        

    function getPermissibleWeightsTrailer1ComplianceFailed() {
        var tempTrailer1 = activeRig.getTrailer1();

        switch (tempTrailer1.getType()) {
            case config.TRAILER_TYPES.DRAWBAR:
                return getTrailer1FrontGross() > evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleFrontMax) || getTrailer1RearGross() > evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRearMax);
            case config.TRAILER_TYPES.PUP:
            case config.TRAILER_TYPES.SEMI:
            case config.TRAILER_TYPES.INTERLINK:
                return getTrailer1RearGross() > evaluateLegislation(legislationVals.variableTypes.Trailer1PermissibleRearMax);
        }
        return false;
    }

    function getPermissibleWeightsTrailer2ComplianceFailed() {
        var tempTrailer2 = activeRig.getTrailer2();

        switch (tempTrailer2.getType()) {
            case config.TRAILER_TYPES.DRAWBAR:
                return false;
            case config.TRAILER_TYPES.PUP:
                return false;
            case config.TRAILER_TYPES.SEMI:
                return getTrailer2RearGross() > evaluateLegislation(legislationVals.variableTypes.Trailer2PermissibleRearMax);
            case config.TRAILER_TYPES.INTERLINK:
                return false;

        }
        return false;
    }

    function getPermissibleWeightsVehicleAndDrawbarComplianceFailed() {
        return getVehicleAndDrawbarTotalGross() > curCombinationPermissibleVals.combinationTotalPermissible;
    }

    function getPermissibleWeightsVehicleAndPupComplianceFailed() {
        return getVehicleAndPupTotalGross() > curCombinationPermissibleVals.combinationTotalPermissible;
    }

    function getPermissibleWeightsVehicleAndSemiOrInterlinkComplianceFailed() {
        return getVehicleAndSemiOrInterlinkTotalGross() > curCombinationPermissibleVals.combinationTotalPermissible;
    }
    

    // function getAnyVehicleBridgeFormulaPermissibleValueChanged() {
    //     let tempVehicle = activeRig.getVehicle();
    //     let tempOriginalVehicle = originalRig.getVehicle();
    //     let tempVehicleAxlesHolder = tempVehicle.getAxlesHolder();
    //     let tempOriginalVehicleAxlesHolder = tempOriginalVehicle.getAxlesHolder(); 
    //     let frontAxleGroupChanged = false, rearAxleGroupChanged = false, anyPusherAxleChanged = false, anyTagAxleChanged = false;

    //     let frontAxleGroup = tempVehicleAxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT); 
    //     if (frontAxleGroup) {
    //         frontAxleGroupChanged = applyRoundingForManufacturerWeightsComplianceFailed(frontAxleGroup.getBridgeFormulaPermissible()) !== 
    //                                 applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicleAxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getBridgeFormulaPermissible()); 
    //     }

    //     tempVehicleAxlesHolder.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER).forEach(function (pusherAxle, index) {
    //         if(!anyPusherAxleChanged) {
    //             anyPusherAxleChanged = applyRoundingForManufacturerWeightsComplianceFailed(pusherAxle.getBridgeFormulaPermissible()) !== 
    //                                     applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicleAxlesHolder.getAxleById(pusherAxle.getId()).getBridgeFormulaPermissible()); 
    //         }
    //     });

    //     let rearAxleGroup = tempVehicleAxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR); 
    //     if (rearAxleGroup) {
    //         rearAxleGroupChanged = applyRoundingForManufacturerWeightsComplianceFailed(rearAxleGroup.getBridgeFormulaPermissible()) !== 
    //                                 applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicleAxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getBridgeFormulaPermissible()); 
    //     }

    //     tempVehicleAxlesHolder.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.TAG).forEach(function (tagAxle, index) {
    //         if(!anyTagAxleChanged) {
    //             anyTagAxleChanged = applyRoundingForManufacturerWeightsComplianceFailed(tagAxle.getBridgeFormulaPermissible()) !== 
    //                                     applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicleAxlesHolder.getAxleById(tagAxle.getId()).getBridgeFormulaPermissible()); 
    //         }
    //     });

    //     return (applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getVehicleBridgeFormulaPermissible()) !== applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicle.getVehicleBridgeFormulaPermissible())) ||
    //         (applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getCombinationBridgeFormulaPermissible()) !== applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicle.getCombinationBridgeFormulaPermissible())) ||
    //         (applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getBridgeFormulaRearPermissible()) !== applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicle.getBridgeFormulaRearPermissible())) ||
    //         frontAxleGroupChanged || rearAxleGroupChanged || anyPusherAxleChanged || anyTagAxleChanged;

       
    // }

    function getVehicleFrontRegulationValueChanged() {
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getBridgeFormulaPermissible');
    }

    function getVehicleRearRegulationValueChanged() {
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getBridgeFormulaPermissible');
    }

    function getCombinationTotalRegulationValueChanged() {
        let tempVehicle = activeRig.getVehicle();
        let tempOriginalVehicle = originalRig.getVehicle();
        
        return (applyRoundingForManufacturerWeightsComplianceFailed(tempVehicle.getCombinationBridgeFormulaPermissible()) !== applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicle.getCombinationBridgeFormulaPermissible()))
    }

    function getVehiclePusher1RegulationValueChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 1, 'getBridgeFormulaPermissible');
    }

    function getVehiclePusher2RegulationValueChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 2, 'getBridgeFormulaPermissible');
    }

    function getVehiclePusher3RegulationValueChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 3, 'getBridgeFormulaPermissible');
    }

    function getVehicleTag1RegulationValueChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 1, 'getBridgeFormulaPermissible');
    }

    function getVehicleTag2RegulationValueChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 2, 'getBridgeFormulaPermissible');
    }

    function getVehicleTag3RegulationValueChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 3, 'getBridgeFormulaPermissible');
    }

    function getTrailer1FrontRegulationValueChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getBridgeFormulaPermissible');
    }

    function getTrailer1RearRegulationValueChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getBridgeFormulaPermissible');
    }

    function getTrailer2FrontRegulationValueChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getBridgeFormulaPermissible');
    }

    function getTrailer2RearRegulationValueChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getBridgeFormulaPermissible');
    }

    // function getAnyTrailer1BridgeFormulaPermissibleValueChanged() {
    //     let tempTrailer1 = activeRig.getTrailer1();
    //     let tempOriginalTrailer1 = originalRig.getTrailer1();
    //     let tempTrailer1AxlesHolder = tempTrailer1.getAxlesHolder();
    //     let tempOriginalTrailer1AxlesHolder = tempOriginalTrailer1.getAxlesHolder(); 
    //     let frontAxleGroupChanged = false, rearAxleGroupChanged = false;

    //     let frontAxleGroup = tempTrailer1AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT); 
    //     if (frontAxleGroup) {
    //         frontAxleGroupChanged = applyRoundingForManufacturerWeightsComplianceFailed(frontAxleGroup.getBridgeFormulaPermissible()) !== 
    //                                 applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalTrailer1AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getBridgeFormulaPermissible()); 
    //     }

       

    //     let rearAxleGroup = tempTrailer1AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR); 
    //     if (rearAxleGroup) {
    //         rearAxleGroupChanged = applyRoundingForManufacturerWeightsComplianceFailed(rearAxleGroup.getBridgeFormulaPermissible()) !== 
    //                                 applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalTrailer1AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getBridgeFormulaPermissible()); 
    //     }

    //     return frontAxleGroupChanged || rearAxleGroupChanged;
    // }

    // function getAnyTrailer2BridgeFormulaPermissibleValueChanged() {
    //     let tempTrailer2 = activeRig.getTrailer2();
    //     let tempOriginalTrailer2 = originalRig.getTrailer2();
    //     let tempTrailer2AxlesHolder = tempTrailer2.getAxlesHolder();
    //     let tempOriginalTrailer2AxlesHolder = tempOriginalTrailer2.getAxlesHolder(); 
    //     let frontAxleGroupChanged = false, rearAxleGroupChanged = false;

    //     let frontAxleGroup = tempTrailer2AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT); 
    //     if (frontAxleGroup) {
    //         frontAxleGroupChanged = applyRoundingForManufacturerWeightsComplianceFailed(frontAxleGroup.getBridgeFormulaPermissible()) !== 
    //                                 applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalTrailer2AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).getBridgeFormulaPermissible()); 
    //     }

       

    //     let rearAxleGroup = tempTrailer2AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR); 
    //     if (rearAxleGroup) {
    //         rearAxleGroupChanged = applyRoundingForManufacturerWeightsComplianceFailed(rearAxleGroup.getBridgeFormulaPermissible()) !== 
    //                                 applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalTrailer2AxlesHolder.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).getBridgeFormulaPermissible()); 
    //     }

    //     return frontAxleGroupChanged || rearAxleGroupChanged;
    // }

    function getDefaultVehicleAxleValueChanged(axleGroupPosition, axleValueFunctionToCheck) {
        let tempVehicle = activeRig.getVehicle();
        let tempOriginalVehicle = originalRig.getVehicle();
        let tempVehicleAxlesHolder = tempVehicle.getAxlesHolder();
        let tempOriginalVehicleAxlesHolder = tempOriginalVehicle.getAxlesHolder(); 
        let valueChanged = false;

        let axleGroup = tempVehicleAxlesHolder.getAxleGroupUsingType(axleGroupPosition); 
        if (axleGroup) {
            valueChanged = applyRoundingForManufacturerWeightsComplianceFailed(axleGroup[axleValueFunctionToCheck]()) !== 
                                    applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicleAxlesHolder.getAxleGroupUsingType(axleGroupPosition)[axleValueFunctionToCheck]()); 
        }
        return valueChanged;
    }

    function getDefaultVehicleFrontAxleAxleRatingChanged() {        
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getManufacturerRating');
    }

    function getDefaultVehicleFrontAxleSuspensionRatingChanged() {
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getSuspensionMassRating');
    }

    function getDefaultVehicleFrontAxleTyreRatingChanged() {
        let frontAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT); 
        if (frontAxleGroup) {
            if (frontAxleGroup.getNoOfTyresOnAxle() === 2) {
                return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getTyreMassRatingSingle');
            } else {
                return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getTyreMassRatingDual');
            }
            
        }
        return false;
    }

    function getDefaultVehicleFrontAxleGAWRChanged() {
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getGrossAxleWeightRating');
    }

    function getDefaultVehicleRearAxleAxleRatingChanged() {
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getManufacturerRating');
    }

    function getDefaultVehicleRearAxleSuspensionRatingChanged() {
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getSuspensionMassRating');
    }

    function getDefaultVehicleRearAxleTyreRatingChanged() {
        let axleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR); 
        if (axleGroup) {
            if (axleGroup.getNoOfTyresOnAxle() === 2) {
                return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getTyreMassRatingSingle');
            } else {
                return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getTyreMassRatingDual');
            }
            
        }
        return false;
    }

    function getDefaultVehicleRearAxleGAWRChanged() {
        return getDefaultVehicleAxleValueChanged(config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getGrossAxleWeightRating');
    }

    function getDefaultPusherOrTagAxleValueChanged(pusherOrTag, number, axleValueFunctionToCheck) {
        let tempVehicle = activeRig.getVehicle();
        let tempOriginalVehicle = originalRig.getVehicle();
        let tempVehicleAxlesHolder = tempVehicle.getAxlesHolder();
        let tempOriginalVehicleAxlesHolder = tempOriginalVehicle.getAxlesHolder(); 
        let valueChanged = false;

        let axle = tempVehicleAxlesHolder['get' + pusherOrTag + number]();
        if(axle) {
            valueChanged = applyRoundingForManufacturerWeightsComplianceFailed(axle[axleValueFunctionToCheck]()) !== 
            applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalVehicleAxlesHolder.getAxleById(axle.getId())[axleValueFunctionToCheck]()); 
        }

        return valueChanged;
    }

    function getDefaultVehiclePusher1AxleAxleRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 1, 'getManufacturerRating');
    }

    function getDefaultVehiclePusher2AxleAxleRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 2, 'getManufacturerRating');
    }

    function getDefaultVehiclePusher3AxleAxleRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 3, 'getManufacturerRating');
    }

    function getDefaultVehiclePusher1AxleSuspensionRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 1, 'getSuspensionMassRating');
    }

    function getDefaultVehiclePusher2AxleSuspensionRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 2, 'getSuspensionMassRating');
    }

    function getDefaultVehiclePusher3AxleSuspensionRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 3, 'getSuspensionMassRating');
    }

    function getDefaultVehiclePusher1AxleTyreRatingChanged() {
        let axle = activeRig.getVehicle().getAxlesHolder().getPusher1();
        if(axle) {
            if(axle.getNoOfTyresOnAxle() === 2) {
                return getDefaultPusherOrTagAxleValueChanged('Pusher', 1, 'getTyreMassRatingSingle');
            } else {
                return getDefaultPusherOrTagAxleValueChanged('Pusher', 1, 'getTyreMassRatingDual');
            }
        }

        return false;
    }

    function getDefaultVehiclePusher2AxleTyreRatingChanged() {
        let axle = activeRig.getVehicle().getAxlesHolder().getPusher2();
        if(axle) {
            if(axle.getNoOfTyresOnAxle() === 2) {
                return getDefaultPusherOrTagAxleValueChanged('Pusher', 2, 'getTyreMassRatingSingle');
            } else {
                return getDefaultPusherOrTagAxleValueChanged('Pusher', 2, 'getTyreMassRatingDual');
            }
        }

        return false;
    }

    function getDefaultVehiclePusher3AxleTyreRatingChanged() {
        let axle = activeRig.getVehicle().getAxlesHolder().getPusher3();
        if(axle) {
            if(axle.getNoOfTyresOnAxle() === 2) {
                return getDefaultPusherOrTagAxleValueChanged('Pusher', 3, 'getTyreMassRatingSingle');
            } else {
                return getDefaultPusherOrTagAxleValueChanged('Pusher', 3, 'getTyreMassRatingDual');
            }
        }

        return false;
    }

    function getDefaultVehiclePusher1AxleGAWRChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 1, 'getGrossAxleWeightRating');
    }

    function getDefaultVehiclePusher2AxleGAWRChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 2, 'getGrossAxleWeightRating');
    }

    function getDefaultVehiclePusher3AxleGAWRChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Pusher', 3, 'getGrossAxleWeightRating');
    }

    function getDefaultVehicleTag1AxleAxleRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 1, 'getManufacturerRating');
    }

    function getDefaultVehicleTag2AxleAxleRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 2, 'getManufacturerRating');
    }

    function getDefaultVehicleTag3AxleAxleRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 3, 'getManufacturerRating');
    }

    function getDefaultVehicleTag1AxleSuspensionRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 1, 'getSuspensionMassRating');
    }

    function getDefaultVehicleTag2AxleSuspensionRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 2, 'getSuspensionMassRating');
    }

    function getDefaultVehicleTag3AxleSuspensionRatingChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 3, 'getSuspensionMassRating');
    }

    function getDefaultVehicleTag1AxleTyreRatingChanged() {
        let axle = activeRig.getVehicle().getAxlesHolder().getTag1();
        if(axle) {
            if(axle.getNoOfTyresOnAxle() === 2) {
                return getDefaultPusherOrTagAxleValueChanged('Tag', 1, 'getTyreMassRatingSingle');
            } else {
                return getDefaultPusherOrTagAxleValueChanged('Tag', 1, 'getTyreMassRatingDual');
            }
        }

        return false;
    }

    function getDefaultVehicleTag2AxleTyreRatingChanged() {
        let axle = activeRig.getVehicle().getAxlesHolder().getTag2();
        if(axle) {
            if(axle.getNoOfTyresOnAxle() === 2) {
                return getDefaultPusherOrTagAxleValueChanged('Tag', 2, 'getTyreMassRatingSingle');
            } else {
                return getDefaultPusherOrTagAxleValueChanged('Tag', 2, 'getTyreMassRatingDual');
            }
        }

        return false;
    }

    function getDefaultVehicleTag3AxleTyreRatingChanged() {
        let axle = activeRig.getVehicle().getAxlesHolder().getTag3();
        if(axle) {
            if(axle.getNoOfTyresOnAxle() === 2) {
                return getDefaultPusherOrTagAxleValueChanged('Tag', 3, 'getTyreMassRatingSingle');
            } else {
                return getDefaultPusherOrTagAxleValueChanged('Tag', 3, 'getTyreMassRatingDual');
            }
        }

        return false;
    }

    function getDefaultVehicleTag1AxleGAWRChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 1, 'getGrossAxleWeightRating');
    }

    function getDefaultVehicleTag2AxleGAWRChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 2, 'getGrossAxleWeightRating');
    }

    function getDefaultVehicleTag3AxleGAWRChanged() {
        return getDefaultPusherOrTagAxleValueChanged('Tag', 3, 'getGrossAxleWeightRating');
    }


    function getDefaultTrailerAxleValueChanged(trailerChassisObjectType, axleGroupPosition, axleValueFunctionToCheck) {
        let tempTrailer = getChassisObjectFromPath(trailerChassisObjectType);
        let tempOriginalTrailer = getOriginalChassisObjectFromPath(trailerChassisObjectType);
        let tempTrailerAxlesHolder = tempTrailer.getAxlesHolder();
        let tempOriginalTrailerAxlesHolder = tempOriginalTrailer.getAxlesHolder(); 
        let valueChanged = false;

        let axleGroup = tempTrailerAxlesHolder.getAxleGroupUsingType(axleGroupPosition); 
        if (axleGroup) {
            valueChanged = applyRoundingForManufacturerWeightsComplianceFailed(axleGroup[axleValueFunctionToCheck]()) !== 
                            applyRoundingForManufacturerWeightsComplianceFailed(tempOriginalTrailerAxlesHolder.getAxleGroupUsingType(axleGroupPosition)[axleValueFunctionToCheck]()); 
        }
        return valueChanged;
    }

    function getDefaultTrailer1FrontAxleAxleRatingChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getManufacturerRating');
    }

    function getDefaultTrailer1RearAxleAxleRatingChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER1, config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getManufacturerRating');
    }

    function getDefaultTrailer2FrontAxleAxleRatingChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.VEHICLE_AXLE_POSITION_TYPES.FRONT, 'getManufacturerRating');
    }

    function getDefaultTrailer2RearAxleAxleRatingChanged() {
        return getDefaultTrailerAxleValueChanged(config.CHASSIS_OBJECT_TYPES.TRAILER2, config.VEHICLE_AXLE_POSITION_TYPES.REAR, 'getManufacturerRating');
    }
    //function getPermissibleWeightsComplianceFailed() {


    //    var tempTrailer2 = activeRig.getTrailer2();



    //    switch (getCombinationType()) {
    //        case config.COMBINATION_TYPES.VEHICLE:
    //            return getPermissibleWeightsVehicleComplianceFailed();
    //        case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    //            return getPermissibleWeightsVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() || getPermissibleWeightsVehicleAndDrawbarComplianceFailed();
    //        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    //            return getPermissibleWeightsVehicleAndSemiOrInterlinkVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() || getPermissibleWeightsVehicleAndSemiOrInterlinkComplianceFailed();
    //        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
    //            return getPermissibleWeightsVehicleAndPupVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() || getPermissibleWeightsVehicleAndPupComplianceFailed();
    //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
    //            return getPermissibleWeightsVehicleAndInterlinkAndSemiVehicleComplianceFailed() || getPermissibleWeightsTrailer1ComplianceFailed() || getPermissibleWeightsTrailer2ComplianceFailed() || getPermissibleWeightsVehicleAndInterlinkAndSemiComplianceFailed();
    //    }
    //    return false;
    //}

    //function getPermissibleWeightsVehicleComplianceFailed() {
    //    var tempVehicle = activeRig.getVehicle();

    //    var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
    //    var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

    //    return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(getCombinationVehicleRearGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) ||
    //        getAnyOverloadedAxlesPermissibleVehicle() /*|| checkFailOnPusherTagOrRearRear()*/;

    //    //return checkFailOnPusherTagOrRearRear() ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(getCombinationVehicleRearGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleRearPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(getCombinationTotalPermissible()));
    //}

    //function checkFailOnPusherTagOrRearRear() {
    //    var tempVehicle = activeRig.getVehicle();
    //    var tempTrailer1 = activeRig.getTrailer1();
    //    var tempTrailer2 = activeRig.getTrailer2();
    //    var hasFailedAxle = false;

    //    //switch (getCombinationType()) {
    //    //    case config.COMBINATION_TYPES.VEHICLE:
    //    var pusherCounter = 1, tagCounter = 2;
    //    tempVehicle.getAxlesHolder().getAxles().forEach(function (axle) {
    //        var grossFunctionToUse, pusherOrTagNumberToUse;
    //        if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER || axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
    //            var legislationValToUse;
    //            if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
    //                legislationValToUse = legislationVals.variableTypes.VehiclePermissibleRearPusher;
    //                pusherOrTagNumberToUse = pusherCounter;
    //                pusherCounter++;
    //            } else if (axle.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
    //                legislationValToUse = legislationVals.variableTypes.VehiclePermissibleRearTag;
    //                pusherOrTagNumberToUse = tagCounter;
    //                tagCounter++;
    //            }
                
    //            if (tempTrailer1 === null || (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR)) {
    //                grossFunctionToUse = tempVehicle.getPusherOrTagRearAxleGross;
    //                //rearRearAxlesGrossFunctionToUse = tempVehicle.getRearRearAxlesGross;
    //            } else if (tempTrailer1 !== null && tempTrailer2 === null) {
    //                grossFunctionToUse = function (axleNumber) {
    //                    return tempVehicle.getPusherOrTagRearAxleGross(axleNumber, getVehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer());
    //                };
    //                //rearRearAxlesGrossFunctionToUse = function () {
    //                //    return tempVehicle.getRearRearAxlesGross(rigOps.getVehicleAndSemiOrInterlinkVehicleRearAxleGross());
    //                //};
    //            } else if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
    //                grossFunctionToUse = function (axleNumber) {
    //                    return tempVehicle.getPusherOrTagRearAxleGross(axleNumber, getVehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer());
    //                };
    //                //rearRearAxlesGrossFunctionToUse = function () {
    //                //    return tempVehicle.getRearRearAxlesGross(rigOps.getVehicleAndInterlinkAndSemiVehicleRearAxleGross());
    //                //};
    //            }
    //            if (applyRoundingForPermissibleWeightsComplianceFailed(grossFunctionToUse(axle.getNumber())) > applyRoundingForPermissibleWeightsComplianceFailed(evaluateLegislation(legislationValToUse + pusherOrTagNumberToUse))) {
    //                hasFailedAxle = true;
    //            }
    //        } 
    //    });

    //    if (tempTrailer1 === null || (tempTrailer1 !== null && tempTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR)) {
    //        //grossFunctionToUse = tempVehicle.getPusherOrTagRearAxleGross;
    //        rearRearAxlesGrossFunctionToUse = tempVehicle.getRearRearAxlesGross;
    //    } else if (tempTrailer1 !== null && tempTrailer2 === null) {
    //        //grossFunctionToUse = function (axleNumber) {
    //        //    return tempVehicle.getPusherOrTagRearAxleGross(axleNumber, getVehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer());
    //        //};
    //        rearRearAxlesGrossFunctionToUse = function () {
    //            return tempVehicle.getRearRearAxlesGross(getVehicleAndSemiOrInterlinkVehicleRearAxleGross());
    //        };
    //    } else if (tempTrailer1.getType() === config.TRAILER_TYPES.INTERLINK && tempTrailer2.getType() === config.TRAILER_TYPES.SEMI) {
    //        //grossFunctionToUse = function (axleNumber) {
    //        //    return tempVehicle.getPusherOrTagRearAxleGross(axleNumber, getVehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer());
    //        //};
    //        rearRearAxlesGrossFunctionToUse = function () {
    //            return tempVehicle.getRearRearAxlesGross(getVehicleAndInterlinkAndSemiVehicleRearAxleGross());
    //        };
    //    }

    //    if (applyRoundingForPermissibleWeightsComplianceFailed(rearRearAxlesGrossFunctionToUse()) > applyRoundingForPermissibleWeightsComplianceFailed(evaluateLegislation(legislationVals.variableTypes.VehiclePermissibleRearRearOnly))) {
    //        hasFailedAxle = true;
    //    }


    //    //        break;
    //    //    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
    //    //        return hasOverloadedAxle;
    //    //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
    //    //        return hasOverloadedAxle;
    //    //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
    //    //        return hasOverloadedAxle;
    //    //}
    //    return hasFailedAxle;
    //}
    
    //function getPermissibleWeightsVehicleAndPupVehicleComplianceFailed() {
    //    var tempVehicle = activeRig.getVehicle();

    //    var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
    //    var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

    //    return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndPupVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) ||
    //        getAnyOverloadedAxlesPermissibleVehicle() /*|| checkFailOnPusherTagOrRearRear()*/;

    //    //return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndPupVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleRearPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(getCombinationTotalPermissible())) ||
    //    //    checkFailOnPusherTagOrRearRear();
    //}

    //function getPermissibleWeightsVehicleAndSemiOrInterlinkVehicleComplianceFailed() {
    //    var tempVehicle = activeRig.getVehicle();

    //    var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
    //    var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

    //    return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleFrontAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) ||
    //        getAnyOverloadedAxlesPermissibleVehicle() /*|| checkFailOnPusherTagOrRearRear()*/;

    //    //return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleFrontAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndSemiOrInterlinkVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleRearPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(getCombinationTotalPermissible())) ||
    //    //    checkFailOnPusherTagOrRearRear();
    //}

    //function getPermissibleWeightsVehicleAndInterlinkAndSemiVehicleComplianceFailed() {
    //    var tempVehicle = activeRig.getVehicle();

    //    var vehicleFrontSingleOrDual = evaluateLegislation(legislationVals.variableTypes.VehicleFrontSingleOrDual);
    //    var vehicleRearSingleDualTridem = evaluateLegislation(legislationVals.variableTypes.VehicleRearSingleDualTridem);

    //    return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleFrontAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(vehicleRearSingleDualTridem) ||
    //        applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(vehicleFrontSingleOrDual + vehicleRearSingleDualTridem)) ||
    //        getAnyOverloadedAxlesPermissibleVehicle() /*|| checkFailOnPusherTagOrRearRear()*/;

    //    //return applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleFrontAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleFrontPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(getVehicleAndInterlinkAndSemiVehicleRearAxleGross()) > applyRoundingForPermissibleWeightsComplianceFailed(getVehicleRearPermissible()) ||
    //    //    applyRoundingForPermissibleWeightsComplianceFailed(tempVehicle.getGrossTotal()) > (applyRoundingForPermissibleWeightsComplianceFailed(getCombinationTotalPermissible())) ||
    //    //    checkFailOnPusherTagOrRearRear();
    //}        


    //function getPermissibleWeightsTrailer1ComplianceFailed() {
    //    var tempTrailer1 = activeRig.getTrailer1();

    //    switch (tempTrailer1.getType()) {
    //        case config.TRAILER_TYPES.DRAWBAR:
    //            return getTrailer1FrontGross() > getTrailer1FrontPermissible() || getTrailer1RearGross() > getTrailer1PermissibleRear();
    //        case config.TRAILER_TYPES.PUP:
    //        case config.TRAILER_TYPES.SEMI:
    //        case config.TRAILER_TYPES.INTERLINK:
    //            return getTrailer1RearGross() > getTrailer1PermissibleRear();
    //    }
    //    return false;
    //}

    //function getPermissibleWeightsTrailer2ComplianceFailed() {
    //    var tempTrailer2 = activeRig.getTrailer2();

    //    switch (tempTrailer2.getType()) {
    //        case config.TRAILER_TYPES.DRAWBAR:
    //            return false;
    //        case config.TRAILER_TYPES.PUP:
    //            return false;
    //        case config.TRAILER_TYPES.SEMI:
    //            return getTrailer2RearGross() > getTrailer2PermissibleRear();
    //        case config.TRAILER_TYPES.INTERLINK:
    //            return false;
                
    //    }
    //    return false;
    //}


    //function getPermissibleWeightsVehicleAndDrawbarComplianceFailed() {
    //    return getVehicleAndDrawbarTotalGross() > getCombinationTotalPermissible();
    //}

    //function getPermissibleWeightsVehicleAndPupComplianceFailed() {
    //    return getVehicleAndPupTotalGross() > getCombinationTotalPermissible();
    //}

    //function getPermissibleWeightsVehicleAndSemiOrInterlinkComplianceFailed() {
    //    return getVehicleAndSemiOrInterlinkTotalGross() > getCombinationTotalPermissible();
    //}

    function getCOGFrom(cogId, parent, cogAxisType, optionalObjectId) {

        var chassisObjectToUse = getChassisObjectFromPath(parent);
        var tempAccessoryHolder = getAccessoryHolderFromPath(parent);
        var tempBody, wheelbaseTheoreticalStart, wheelbaseTheoreticalEnd;
        switch (cogId) {
            case config.COG_ELEMENTS.VEHICLE_COG:
                //Vehicle COG
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return getVehicleHorizontalCOG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return getVehicleVerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfVehicleWidestWidth(chassisObjectToUse) + getVehicleLateralCOG()));
                        return -((chassisObjectToUse.getOverallWidth() / 2) - (getVehicleLateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.TRAILER_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                            return  getTrailerHorizontalCOG(parent);
                        } else {
                            return  getTrailerHorizontalCOG(parent);
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return getTrailerVerticalCOG(parent);
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfChassisRearWidth(chassisObjectToUse) + getTrailerLateralCOG(parent)));
                        return -((chassisObjectToUse.getOverallWidth() / 2) - (getTrailerLateralCOG(parent)));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK1_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank1COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank1VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfVehicleCabWidth() + chassisObjectToUse.getFuelTank1LateralCOG()));
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank1LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK2_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank2COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank2VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfVehicleCabWidth() + chassisObjectToUse.getFuelTank2LateralCOG()));
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank2LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK3_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank3COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank3VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfVehicleCabWidth() + chassisObjectToUse.getFuelTank3LateralCOG()));
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank3LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK4_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank4COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank4VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfVehicleCabWidth() + chassisObjectToUse.getFuelTank4LateralCOG()));
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank4LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK5_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank5COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank5VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank5LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK6_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank6COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank6VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank6LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK7_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank7COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank7VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank7LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FUELTANK8_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getFuelTank8COG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getFuelTank8VerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        return -((chassisObjectToUse.getRearWidth() / 2) - (chassisObjectToUse.getFuelTank8LateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.CREWFIRSTROW_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getCrewFirstRowCOG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getCrewFirstRowVerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfVehicleCabWidth() + chassisObjectToUse.getCrewFirstRowLateralCOG()))
                        return -((chassisObjectToUse.getCabWidth() / 2) - (chassisObjectToUse.getCrewFirstRowLateralCOG()))
                }
                break;
            case config.COG_ELEMENTS.CREWSECONDROW_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return chassisObjectToUse.getCrewSecondRowCOG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getCrewSecondRowVerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfVehicleCabWidth() + chassisObjectToUse.getCrewSecondRowLateralCOG()));
                        return -((chassisObjectToUse.getCabWidth() / 2) - (chassisObjectToUse.getCrewSecondRowLateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.CRANE_COG:

                var tempCrane = getCraneFromPath(parent);

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getBumperToBackOfCab() + tempCrane.getHorizontalGap() + tempCrane.getActualHorizontalCOG() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                        } else {
                            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                                return tempCrane.getHorizontalGap() + tempCrane.getActualHorizontalCOG() -chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                            } else {
                                return tempCrane.getHorizontalGap() + tempCrane.getActualHorizontalCOG() -chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                            }
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getChassisHeightMax() + tempCrane.getActualVerticalCOG();
                        } else {
                            return chassisObjectToUse.getChassisHeight() + tempCrane.getActualVerticalCOG();
                        }
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfChassisRearWidth(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth() + tempCrane.getActualLateralCOG()));
                        
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return -((chassisObjectToUse.getRearWidth()/2) - (tempCrane.getFromLeftOfVehicleRearWidth() + tempCrane.getActualLateralCOG()));
                        } else {
                            return -((chassisObjectToUse.getOverallWidth() / 2) - (tempCrane.getFromLeftOfVehicleRearWidth() + tempCrane.getActualLateralCOG()));
                        }
                }
                
                break;
            case config.COG_ELEMENTS.TAILLIFT_COG:

                var tempTaillift = getTailliftFromPath(parent);
                tempBody = tempAccessoryHolder.getBody();
                //var tempOriginalBody = originalRig.getVehicle().getAccessoryHolder().getBody();
                //var tempOriginalTaillift = originalRig.getVehicle().getAccessoryHolder().getTaillift();
                var tempOriginalBody, tempOriginalTaillift;
                switch (parent) {
                    case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                        tempOriginalBody = originalRig.getVehicle().getAccessoryHolder().getBody();
                        tempOriginalTaillift = originalRig.getVehicle().getAccessoryHolder().getTaillift();
                        break;
                    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                        tempOriginalBody = originalRig.getTrailer1().getAccessoryHolder().getBody();
                        tempOriginalTaillift = originalRig.getTrailer1().getAccessoryHolder().getTaillift();
                        break;
                    case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                        tempOriginalBody = originalRig.getTrailer2().getAccessoryHolder().getBody();
                        tempOriginalTaillift = originalRig.getTrailer2().getAccessoryHolder().getTaillift();
                        break;
                    default:
                        tempOriginalBody = originalRig.getVehicle().getAccessoryHolder().getBody();
                        tempOriginalTaillift = originalRig.getVehicle().getAccessoryHolder().getTaillift();
                        break;
                }

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                                return chassisObjectToUse.getOverallLength() + tempTaillift.getActualHorizontalCOG() + tempTaillift.getFromBackOfBody() - tempTaillift.getMechanismLength() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                            } else {
                                return chassisObjectToUse.getBumperToBackOfCab() + getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualLength() + tempTaillift.getActualHorizontalCOG() + tempTaillift.getFromBackOfBody() - tempTaillift.getMechanismLength() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                            }
                            
                        } else {
                            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                                return getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualLength() + tempTaillift.getActualHorizontalCOG() + tempTaillift.getFromBackOfBody() - tempTaillift.getMechanismLength() -chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                            } else {
                                return getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualLength() + tempTaillift.getActualHorizontalCOG() + tempTaillift.getFromBackOfBody() - tempTaillift.getMechanismLength() -chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                            }
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                                return chassisObjectToUse.getFloorHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getActualVerticalCOG() - tempTaillift.getMechanismHeight();
                            } else {
                                if (tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                                    return chassisObjectToUse.getChassisHeightRear() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getActualVerticalCOG() - (tempTaillift.getMechanismHeight() - (tempOriginalBody.getSubstructureHeight() + tempOriginalTaillift.getAboveOrBelowFloor()));
                                } else {
                                    return chassisObjectToUse.getChassisHeightRear() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getActualVerticalCOG() - tempTaillift.getMechanismHeight();
                                }
                            }
                        } else {
                            if (tempTaillift.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                                return chassisObjectToUse.getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getActualVerticalCOG() - (tempTaillift.getMechanismHeight() - (tempOriginalBody.getSubstructureHeight() + tempOriginalTaillift.getAboveOrBelowFloor()));
                            } else {
                                return chassisObjectToUse.getChassisHeight() + tempBody.getSubframeHeight() + tempBody.getSubstructureHeight() + tempTaillift.getAboveOrBelowFloor() + tempTaillift.getActualVerticalCOG() - tempTaillift.getMechanismHeight();
                            }
                        }
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfBodyWidthExternal(chassisObjectToUse) + tempTaillift.getFromLeftOfBodyWidthExternal() + tempTaillift.getActualLateralCOG()));
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                            return -((chassisObjectToUse.getRearWidth() / 2) - (tempTaillift.getFromLeftOfBodyWidthExternal() + tempTaillift.getActualLateralCOG()));
                        } else {
                            return -((tempBody.getActualWidth() / 2) - (tempTaillift.getFromLeftOfBodyWidthExternal() + tempTaillift.getActualLateralCOG()));
                        }
                        
                }
                break;
            case config.COG_ELEMENTS.FRIDGE_COG:

                var tempFridge = getFridgeFromPath(parent);
                tempBody = tempAccessoryHolder.getBody();

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getBumperToBackOfCab() + getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempFridge.getActualHorizontalCOG() - tempFridge.getProtrusion() - tempFridge.getFromFrontOfBody() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                        } else {
                            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                                return getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempFridge.getActualHorizontalCOG() - tempFridge.getProtrusion() - tempFridge.getFromFrontOfBody() -chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                            } else {
                                return getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempFridge.getActualHorizontalCOG() - tempFridge.getProtrusion() - tempFridge.getFromFrontOfBody() -chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                            }
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getChassisHeightMax() + tempBody.getActualExternalHeight() - tempFridge.getMountingPosition() - tempFridge.getHeight() + tempFridge.getActualVerticalCOG();
                        } else {
                            return chassisObjectToUse.getChassisHeight() + tempBody.getActualExternalHeight() - tempFridge.getMountingPosition() - tempFridge.getHeight() + tempFridge.getActualVerticalCOG();
                        }
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfBodyWidthExternal(chassisObjectToUse) + tempFridge.getFromLeftOfBodyWidthExternal() + tempFridge.getActualLateralCOG()));
                        return -((tempBody.getActualWidth() / 2) - (tempFridge.getFromLeftOfBodyWidthExternal() + tempFridge.getActualLateralCOG()));
                }
                break;
            case config.COG_ELEMENTS.FIFTH_WHEEL_COG:

                var tempFifthWheel = getFifthWheelFromPath(parent);

                wheelbaseTheoreticalStart = chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart();
                wheelbaseTheoreticalEnd = wheelbaseTheoreticalStart + chassisObjectToUse.getWheelbaseTheoretical();

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return (wheelbaseTheoreticalEnd - (tempFifthWheel.getOffset() + (tempFifthWheel.getLength() / 2))) + tempFifthWheel.getActualHorizontalCOG() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return chassisObjectToUse.getChassisHeightMax() + tempFifthWheel.getActualVerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfChassisRearWidth(chassisObjectToUse) + tempFifthWheel.getFromLeftOfVehicleRearWidth() - tempFifthWheel.getWidth()/2+ tempFifthWheel.getActualLateralCOG()));
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return -((chassisObjectToUse.getRearWidth() / 2) - (tempFifthWheel.getFromLeftOfVehicleRearWidth() - tempFifthWheel.getWidth() / 2 + tempFifthWheel.getActualLateralCOG()));
                        } else {
                            return -((chassisObjectToUse.getOverallWidth() / 2) - (tempFifthWheel.getFromLeftOfVehicleRearWidth() - tempFifthWheel.getWidth() / 2 + tempFifthWheel.getActualLateralCOG()));
                        }
                }
                break;
            case config.COG_ELEMENTS.HITCH_COG:

                var tempHitch = getHitchFromPath(parent);

                wheelbaseTheoreticalStart = chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart();
                wheelbaseTheoreticalEnd = wheelbaseTheoreticalStart + chassisObjectToUse.getWheelbaseTheoretical();

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return wheelbaseTheoreticalEnd + ((chassisObjectToUse.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset()) - tempHitch.getCouplingOffset() - tempHitch.getMountingLength()) + tempHitch.getActualHorizontalCOG() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return tempHitch.getHeightFromGround() - tempHitch.getCouplingHeight() + tempHitch.getActualVerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfChassisRearWidth(chassisObjectToUse) + tempHitch.getFromLeftOfVehicleRearWidth() - tempHitch.getWidth() / 2 + tempHitch.getActualLateralCOG()));
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return -((chassisObjectToUse.getRearWidth() / 2) - (tempHitch.getFromLeftOfVehicleRearWidth() - tempHitch.getWidth() / 2 + tempHitch.getActualLateralCOG()));
                        } else {
                            return -((chassisObjectToUse.getOverallWidth() / 2) - (tempHitch.getFromLeftOfVehicleRearWidth() - tempHitch.getWidth() / 2 + tempHitch.getActualLateralCOG()));
                        }
                }
                break;
            case config.COG_ELEMENTS.OTHER_COG:

                var tempOther = getOtherFromPathById(parent, optionalObjectId);

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getBumperToBackOfCab() + tempOther.getHorizontalGap() + tempOther.getActualHorizontalCOG() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                        } else {
                            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                                return tempOther.getHorizontalGap() + tempOther.getActualHorizontalCOG() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                            } else {
                                return tempOther.getHorizontalGap() + tempOther.getActualHorizontalCOG() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                            }
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getChassisHeightMax() + tempOther.getVerticalGap() + tempOther.getActualVerticalCOG();
                        } else {
                            return chassisObjectToUse.getChassisHeight() + tempOther.getVerticalGap() + tempOther.getActualVerticalCOG();
                        }
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfChassisRearWidth(chassisObjectToUse) + tempOther.getFromLeftOfVehicleRearWidth() + tempOther.getActualLateralCOG()));
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return -((chassisObjectToUse.getRearWidth() / 2) - (tempOther.getFromLeftOfVehicleRearWidth() + tempOther.getActualLateralCOG()));
                        } else {
                            return -((chassisObjectToUse.getOverallWidth() / 2) - (tempOther.getFromLeftOfVehicleRearWidth() + tempOther.getActualLateralCOG()));
                        }
                }
                
                break;
            case config.COG_ELEMENTS.HOOKLIFT_COG:

                var tempHooklift = getHookliftFromPath(parent);

                
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getActualHorizontalCOG() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                        } else {
                            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                                return tempHooklift.getHorizontalGap() + tempHooklift.getActualHorizontalCOG() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                            } else {
                                return tempHooklift.getHorizontalGap() + tempHooklift.getActualHorizontalCOG() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                            }
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getChassisHeightMax() + tempHooklift.getActualVerticalCOG();
                        } else {
                            return chassisObjectToUse.getChassisHeight() + tempHooklift.getActualVerticalCOG();
                        }
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return -((getTopViewCentreLine()) - (getLeftOfChassisRearWidth(chassisObjectToUse) + tempCrane.getFromLeftOfVehicleRearWidth() + tempCrane.getActualLateralCOG()));

                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return -((chassisObjectToUse.getRearWidth() / 2) - (tempHooklift.getFromLeftOfVehicleRearWidth() + tempHooklift.getActualLateralCOG()));
                        } else {
                            return -((chassisObjectToUse.getOverallWidth() / 2) - (tempHooklift.getFromLeftOfVehicleRearWidth() + tempHooklift.getActualLateralCOG()));
                        }
                }
                break;
            case config.COG_ELEMENTS.BODY_COG:

                tempBody = getBodyFromPath(parent);

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getBumperToBackOfCab() + getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualHorizontalCOG() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                        } else {
                            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                                return getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualHorizontalCOG() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                            } else {
                                return getBodyGap(chassisObjectToUse.getChassisObjectName()) + tempBody.getActualHorizontalCOG() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                            }
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        // if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                        //     if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempBody.getType() === config.BODY_TYPES.COMPACTOR || tempBody.getType() === config.BODY_TYPES.BEAVERTAIL || tempBody.getType() === config.BODY_TYPES.OTHER*/) {
                        //         return chassisObjectToUse.getChassisHeightMax() - tempBody.getBottomOfBodyToTopOfChassis() + tempBody.getActualVerticalCOG();
                        //     } else {
                        //         return chassisObjectToUse.getChassisHeightMax() + tempBody.getActualVerticalCOG();
                        //     }
                        // } else {
                        //     if (tempBody.getType() === config.BODY_TYPES.SERVICE /*|| tempTrailer1Body.getType() === config.BODY_TYPES.COMPACTOR || tempTrailer1Body.getType() === config.BODY_TYPES.BEAVERTAIL || tempTrailer1Body.getType() === config.BODY_TYPES.OTHER*/) {
                        //         return chassisObjectToUse.getChassisHeight() - tempBody.getBottomOfBodyToTopOfChassis() + tempBody.getActualVerticalCOG();
                        //     } else {
                        //         return chassisObjectToUse.getChassisHeight() + tempBody.getActualVerticalCOG();
                        //     }
                        // }
                        return getItemStartFromGround(parent, tempBody.getAccessoryType()) + tempBody.getActualVerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        return -(tempBody.getActualWidth() / 2) + tempBody.getActualLateralCOG();
                }
                break;
            case config.COG_ELEMENTS.PAYLOAD_COG:

                var tempPayload = getPayloadFromPathById(parent, optionalObjectId);
                tempBody = getBodyFromPath(parent);

                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getFrontOfVehicleToPayloadDeckStart() + tempPayload.getActualHorizontalCOGFromFrontOfDeck() - (chassisObjectToUse.getFrontOverhang() + chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart());
                        } else {
                            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                                return chassisObjectToUse.getFrontOfChassisToPayloadDeckStart() + tempPayload.getActualHorizontalCOGFromFrontOfDeck() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStartExclDrawbar();
                            } else {
                                return chassisObjectToUse.getFrontOfChassisToPayloadDeckStart() + tempPayload.getActualHorizontalCOGFromFrontOfDeck() - chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalStart();
                            }
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return chassisObjectToUse.getBottomOfVehicleToPayloadDeckStart() + tempPayload.getActualVerticalCOGFromBottomOfDeck();
                        } else {
                            return chassisObjectToUse.getBottomOfTrailerToPayloadDeckStart() + tempPayload.getActualVerticalCOGFromBottomOfDeck();
                        }
                    case config.COG_AXIS_TYPES.LATERAL:
                        var bodyWidthExternal = 0;
                        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            if (chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                                bodyWidthExternal = chassisObjectToUse.getRearWidth();
                            } else {
                                if (tempBody !== null) {
                                    bodyWidthExternal = tempBody.getActualWidth();
                                }
                            }
                        } else if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER1 || chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                            if (chassisObjectToUse.getSource() === config.VEHICLE_SOURCE_TYPES.STANDARD) {
                                bodyWidthExternal = chassisObjectToUse.getOverallWidth();
                            } else {
                                if (tempBody !== null) {
                                    bodyWidthExternal = tempBody.getActualWidth();
                                }
                            }

                        }
                        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            //return -((getTopViewCentreLine()) - (getLeftOfBodyWidthExternal(chassisObjectToUse) + chassisObjectToUse.getLeftOfVehicleToPayloadDeckStart() + tempPayload.getActualLateralCOGFromSideOfDeck()));
                            return -((bodyWidthExternal / 2) - (chassisObjectToUse.getLeftOfVehicleToPayloadDeckStart() + tempPayload.getActualLateralCOGFromSideOfDeck()));
                        } else {
                            //return -((getTopViewCentreLine()) - (getLeftOfBodyWidthExternal(chassisObjectToUse) + chassisObjectToUse.getLeftOfTrailerToPayloadDeckStart() + tempPayload.getActualLateralCOGFromSideOfDeck()));
                            return -((bodyWidthExternal / 2) - (chassisObjectToUse.getLeftOfTrailerToPayloadDeckStart() + tempPayload.getActualLateralCOGFromSideOfDeck()));
                        }
                }
                break;
            case config.COG_ELEMENTS.OVERALL_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return getOverallHorizontalCOG();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return getOverallVerticalCOG();
                    case config.COG_AXIS_TYPES.LATERAL:
                        return getOverallLateralCOG();
                }
                break;
            case config.COG_ELEMENTS.TRAILER_OVERALL_COG:
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                            return  getTrailerOverallHorizontalCOG(parent);
                        } else {
                            return  getTrailerOverallHorizontalCOG(parent);
                        }
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return getTrailerOverallVerticalCOG(parent);
                    case config.COG_AXIS_TYPES.LATERAL:
                        return getTrailerOverallLateralCOG(parent);
                }
                break;
        }
        return 0;

    }

    function getCOGFromForTransferItems(transferToChassisObjectType, transferFromChassisObjectType, cogAxisType) {

        var trailerObjectToUse = getChassisObjectFromPath(transferFromChassisObjectType);
        //var tempAccessoryHolder = getAccessoryHolderFromPath(parent);

        var transferToChassisObject = getChassisObjectFromPath(transferToChassisObjectType);
        //if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
        //    transferToChassisObject = getChassisObjectFromPath(config.CHASSIS_OBJECT_TYPES.VEHICLE);
        //} else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
        //    transferToChassisObject = getChassisObjectFromPath(config.CHASSIS_OBJECT_TYPES.TRAILER1);
        //}
        

        var leftOfChassisRearWidth = getLeftOfChassisRearWidth(transferToChassisObject);

        if (trailerObjectToUse.getType() === config.TRAILER_TYPES.SEMI || trailerObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
            var tempFifthWheel = transferToChassisObject.getAccessoryHolder().getFifthWheel();
            if (tempFifthWheel !== null) {
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return transferToChassisObject.getWheelbaseTheoretical() - tempFifthWheel.getOffset();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        if (transferToChassisObject.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                            return transferToChassisObject.getChassisHeightMax() + tempFifthWheel.getSubframeHeight() + tempFifthWheel.getHeight();
                        } else {
                            return transferToChassisObject.getChassisHeight() + tempFifthWheel.getSubframeHeight() + tempFifthWheel.getHeight();
                        }
                        
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return ((leftOfChassisRearWidth + tempFifthWheel.getFromLeftOfVehicleRearWidth()) - centreLineOfVehicle);
                        return -((transferToChassisObject.getRearWidth() / 2) - (tempFifthWheel.getFromLeftOfVehicleRearWidth()));
                }
            } else {
                switch (cogAxisType) {
                    case config.COG_AXIS_TYPES.HORIZONTAL:
                        return transferToChassisObject.getWheelbaseTheoretical() - transferToChassisObject.getFifthWheelOffset();
                    case config.COG_AXIS_TYPES.VERTICAL:
                        return transferToChassisObject.getFifthWheelHeightFromGround();
                    case config.COG_AXIS_TYPES.LATERAL:
                        //return ((leftOfChassisRearWidth + tempFifthWheel.getFromLeftOfVehicleRearWidth()) - centreLineOfVehicle);
                        //return -((tempVehicle.getOverallWidth() / 2));
                        return 0;
                }
            }
            
        } else if (trailerObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
            var tempHitch = transferToChassisObject.getAccessoryHolder().getHitch();
            switch (cogAxisType) {
                case config.COG_AXIS_TYPES.HORIZONTAL:
                    return transferToChassisObject.getWheelbaseTheoretical() + transferToChassisObject.getWheelbaseTheoreticalEndToRearMostDrivenAxle() + tempHitch.getOffset();
                case config.COG_AXIS_TYPES.VERTICAL:
                    return tempHitch.getHeightFromGround();
                case config.COG_AXIS_TYPES.LATERAL:
                    //return ((leftOfChassisRearWidth + tempHitch.getFromLeftOfVehicleRearWidth()) - centreLineOfVehicle);
                    return -((transferToChassisObject.getRearWidth() / 2) - (tempHitch.getFromLeftOfVehicleRearWidth()));
            }
        }

        return 0;

    }

    function getAxleWeightFront(id, parent, optionalObjectIdOrNumber) {

        var chassisObjectToUse = getChassisObjectFromPath(parent);
        

        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();

        switch (id) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                return getVehicleOnlyFrontTare();
            case config.OBJECT_TYPES.CREW_FIRST:
                return activeRig.getVehicle().getFrontCrewFirst();
            case config.OBJECT_TYPES.CREW_SECOND:
                return activeRig.getVehicle().getFrontCrewSecond();
            case config.OBJECT_TYPES.FUEL_TANKS:
                // if (optionalObjectIdOrNumber === 1) {
                //     return activeRig.getVehicle().getFrontFuelTank1();
                // } else if (optionalObjectIdOrNumber === 2) {
                //     return activeRig.getVehicle().getFrontFuelTank2();
                // } else if (optionalObjectIdOrNumber === 3) {
                //     return activeRig.getVehicle().getFrontFuelTank3();
                // } else if (optionalObjectIdOrNumber === 4) {
                //     return activeRig.getVehicle().getFrontFuelTank4();
                // }
                if (optionalObjectIdOrNumber >= 0) {
                    return activeRig.getVehicle()['getFrontFuelTank' + optionalObjectIdOrNumber]();
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                //return getTrailerTareFront(parent) /*- getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber)*/;
                if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                    return (getAxleWeightTransferFront(id, config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber));
                } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    return getTrailerTareFront(parent);
                } 
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                //return getTrailerTareFront(parent) /*- getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber)*/;
                if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                    return (getAxleWeightTransferFront(id, config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber));
                } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                    return getTrailerTareFront(parent);
                }
                break;
            case config.ACCESSORY_TYPES.BODY:
                var tempBody = getBodyFromPath(parent);
                return getAxleWeightFrontForItem(tempBody, parent, optionalObjectIdOrNumber);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempBody);
                //var bodyFrontAxle = tempBody.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                //if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    return bodyFrontAxle;
                //} else if (parent === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return bodyFrontAxle;
                //    } 
                //} else if (parent === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return bodyFrontAxle;
                //    }
                //}
            case config.ACCESSORY_TYPES.CRANE:
                var tempCrane = getCraneFromPath(parent);
                return getAxleWeightFrontForItem(tempCrane, parent, optionalObjectIdOrNumber);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempCrane);
                //var craneFrontAxle = tempCrane.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                //if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    return craneFrontAxle;
                //} else {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return craneFrontAxle;
                //    }
                //}
                //break;
            case config.ACCESSORY_TYPES.TAILLIFT:
                var tempTaillift = getTailliftFromPath(parent);
                return getAxleWeightFrontForItem(tempTaillift, parent, optionalObjectIdOrNumber);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempTaillift);
                //var tailliftFrontAxle = tempTaillift.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                //if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    return tailliftFrontAxle;
                //} else {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return tailliftFrontAxle;
                //    } 
                //}
                //break;
            case config.ACCESSORY_TYPES.FRIDGE:
                var tempFridge = getFridgeFromPath(parent);
                return getAxleWeightFrontForItem(tempFridge, parent, optionalObjectIdOrNumber);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFridge);
                //var fridgeFrontAxle = tempFridge.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                //if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    return fridgeFrontAxle;
                //} else {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return fridgeFrontAxle;
                //    }
                //}
                //break;
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var tempFifthWheel = getFifthWheelFromPath(parent);
                return getAxleWeightFrontForItem(tempFifthWheel, parent, optionalObjectIdOrNumber);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFifthWheel);
                //var fifthWheelFrontAxle = tempFifthWheel.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                //if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    return fifthWheelFrontAxle;
                //} else {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return fifthWheelFrontAxle;
                //    }
                //}
                //break;
            case config.ACCESSORY_TYPES.HITCH:
                var tempHitch = getHitchFromPath(parent);
                return getAxleWeightFrontForItem(tempHitch, parent, optionalObjectIdOrNumber);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempHitch);
                //var hitchFrontAxle = tempHitch.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                //if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    return hitchFrontAxle;
                //} else {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return hitchFrontAxle;
                //    }
                //}
                //break;
            case config.ACCESSORY_TYPES.OTHER:
                var tempOther = getOtherFromPathById(parent, optionalObjectIdOrNumber);
                return getAxleWeightFrontForItem(tempOther, parent, optionalObjectIdOrNumber);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempOther);
                //var otherFrontAxle = tempOther.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                //if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    return otherFrontAxle;
                //} else {
                //    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                //        return (getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber));
                //    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                //        return otherFrontAxle;
                //    } 
                //}
                //break;
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var tempHooklift = getHookliftFromPath(parent);
                return getAxleWeightFrontForItem(tempHooklift, parent, optionalObjectIdOrNumber);
            case config.ACCESSORY_TYPES.PAYLOAD:
                var tempPayload = getPayloadFromPathById(parent, optionalObjectIdOrNumber);
                //return getAxleWeightFrontForItem(tempPayload, parent, optionalObjectIdOrNumber);
                
                //var distanceFromWheelbaseTheoreticalEnd = getPayloadHolderFromPath(parent).getDistanceFromWheelbaseTheoreticalEndForChassis(tempPayload, chassisObjectToUse.getFrontToWheelbaseTheoreticalEnd(), chassisObjectToUse.getFrontToPayloadDeckStart());
                //return tempPayload.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                var distanceFromWheelbaseTheoreticalEnd = getPayloadHolderFromPath(parent).getDistanceFromWheelbaseTheoreticalEndForChassis(tempPayload, chassisObjectToUse.getFrontToWheelbaseTheoreticalEnd(), chassisObjectToUse.getFrontToPayloadDeckStart());
                var payloadFrontAxle = tempPayload.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return payloadFrontAxle;
                } else if (parent === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        return (getAxleWeightTransferFront(tempPayload.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(tempPayload.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber));
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                        return payloadFrontAxle;
                    }
                } else if (parent === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        return (getAxleWeightTransferFront(tempPayload.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(tempPayload.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber));
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                        return payloadFrontAxle;
                    }
                }

                /*
                else {
                    if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                        return (getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber));
                    } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                        return payloadFrontAxle;
                    } 
                }
                */
                //break;
        }
        return 0;
    }

    function getAxleWeightFrontForItem(item, parent, optionalObjectIdOrNumber) {
        var chassisObjectToUse = getChassisObjectFromPath(parent);
        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, item);
        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();
        var itemFrontAxle = item.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            return itemFrontAxle;
        } else if (parent === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                return (getAxleWeightTransferFront(item.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(item.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.VEHICLE, parent, optionalObjectIdOrNumber));
            } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                return itemFrontAxle;
            }
        } else if (parent === config.CHASSIS_OBJECT_TYPES.TRAILER2) {
            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                return (getAxleWeightTransferFront(item.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(item.getAccessoryType(), config.CHASSIS_OBJECT_TYPES.TRAILER1, parent, optionalObjectIdOrNumber));
            } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR) {
                return itemFrontAxle;
            }
        }
        return 0;
    }

    function getAxleWeightTransferFront(id, transferToChassisObjectType, transferFromChassisObjectType, optionalObjectIdOrNumber) {

        var chassisObjectToUse = getChassisObjectFromPath(transferFromChassisObjectType);


        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();

        var combinationType = getCombinationType();
        
        switch (id) {
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                            return calculateTransferOntoVehicleFrontAxleWhenPup(getTrailerTareFront(transferFromChassisObjectType));
                        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(getTrailerTareFront(transferFromChassisObjectType));
                        default:
                            return 0;
                    }
                } 
                return 0;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle();
                        default:
                            return 0;
                    }
                } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle();
                        default:
                            return 0;
                    }
                }
                return 0;
            case config.ACCESSORY_TYPES.BODY:
                var tempBody = getBodyFromPath(transferFromChassisObjectType);
                return getFrontTransferForItem(tempBody, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempBody);
                //var bodyFrontAxle = tempBody.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    switch (combinationType) {
                //        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //            return calculateTransferOntoVehicleFrontAxleWhenPup(bodyFrontAxle);
                //        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //            return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(bodyFrontAxle);
                //        default:
                //            return 0;
                //    }
                //} else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    switch (combinationType) {
                //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                //            return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(bodyFrontAxle));
                //        default:
                //            return 0;
                //    }
                //} else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                //    switch (combinationType) {
                //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                //            return calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(bodyFrontAxle);
                //        default:
                //            return 0;
                //    }
                //}
                //return 0;

                //calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi
            case config.ACCESSORY_TYPES.CRANE:
                var tempCrane = getCraneFromPath(transferFromChassisObjectType);
                return getFrontTransferForItem(tempCrane, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempCrane);
                //var craneFrontAxle = tempCrane.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return calculateTransferOntoVehicleFrontAxleWhenPup(craneFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(craneFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.TAILLIFT:
                var tempTaillift = getTailliftFromPath(transferFromChassisObjectType);
                return getFrontTransferForItem(tempTaillift, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempTaillift);
                //var tailliftFrontAxle =  tempTaillift.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return calculateTransferOntoVehicleFrontAxleWhenPup(tailliftFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(tailliftFrontAxle);
                //    default:
                //        return 0;

                //}
            case config.ACCESSORY_TYPES.FRIDGE:
                var tempFridge = getFridgeFromPath(transferFromChassisObjectType);
                return getFrontTransferForItem(tempFridge, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFridge);
                //var fridgeFrontAxle = tempFridge.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return calculateTransferOntoVehicleFrontAxleWhenPup(fridgeFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(fridgeFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var tempFifthWheel = getFifthWheelFromPath(transferFromChassisObjectType);
                return getFrontTransferForItem(tempFifthWheel, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFifthWheel);
                //var fifthWheelFrontAxle = tempFifthWheel.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return calculateTransferOntoVehicleFrontAxleWhenPup(fifthWheelFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(fifthWheelFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.HITCH:
                var tempHitch = getHitchFromPath(transferFromChassisObjectType);
                return getFrontTransferForItem(tempHitch, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempHitch);
                //var hitchFrontAxle =  tempHitch.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return calculateTransferOntoVehicleFrontAxleWhenPup(hitchFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(hitchFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.OTHER:
                var tempOther = getOtherFromPathById(transferFromChassisObjectType, optionalObjectIdOrNumber);
                return getFrontTransferForItem(tempOther, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempOther);
                //var otherFrontAxle = tempOther.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return calculateTransferOntoVehicleFrontAxleWhenPup(otherFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(otherFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var tempHooklift = getHookliftFromPath(transferFromChassisObjectType);
                return getFrontTransferForItem(tempHooklift, transferToChassisObjectType, transferFromChassisObjectType);
            case config.ACCESSORY_TYPES.PAYLOAD:
                var tempPayload = getPayloadFromPathById(transferFromChassisObjectType, optionalObjectIdOrNumber);
                //return getFrontTransferForItem(tempPayload, transferToChassisObjectType, transferFromChassisObjectType);
                var distanceFromWheelbaseTheoreticalEnd = getPayloadHolderFromPath(transferFromChassisObjectType).getDistanceFromWheelbaseTheoreticalEndForChassis(tempPayload, chassisObjectToUse.getFrontToWheelbaseTheoreticalEnd(), chassisObjectToUse.getFrontToPayloadDeckStart());
                var payloadFrontAxle = tempPayload.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return calculateTransferOntoVehicleFrontAxleWhenPup(payloadFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(payloadFrontAxle);
                //    default:
                //        return 0;
                //}
                if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                            return calculateTransferOntoVehicleFrontAxleWhenPup(payloadFrontAxle);
                        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(payloadFrontAxle);
                        default:
                            return 0;
                    }
                } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(payloadFrontAxle));
                        default:
                            return 0;
                    }
                } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(payloadFrontAxle);
                        default:
                            return 0;
                    }
                }
                return 0;
        }
        return 0;
    }

    function getFrontTransferForItem(item, transferToChassisObjectType, transferFromChassisObjectType) {

        var chassisObjectToUse = getChassisObjectFromPath(transferFromChassisObjectType);


        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();

        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, item);
        var itemFrontAxle = item.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        var combinationType = getCombinationType();

        if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            switch (combinationType) {
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    return calculateTransferOntoVehicleFrontAxleWhenPup(itemFrontAxle);
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(itemFrontAxle);
                default:
                    return 0;
            }
        } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            switch (combinationType) {
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(itemFrontAxle));
                default:
                    return 0;
            }
        } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            switch (combinationType) {
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    return calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(itemFrontAxle);
                default:
                    return 0;
            }
        }
        return 0;
    }

    function getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, accessory) {
        var distanceFromWheelbaseTheoreticalEnd = 0;
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            distanceFromWheelbaseTheoreticalEnd = chassisObjectToUse.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForVehicle(accessory,
            chassisObjectToUse.getFrontOverhang(), chassisObjectToUse.getAxle1FrontToWheelbaseTheoreticalStart(), chassisObjectToUse.getWheelbaseTheoretical(), chassisObjectToUse.getBumperToBackOfCab(), chassisObjectToUse.getAccessoryHolder().getBodyGap());
        } else {
            if (chassisObjectToUse.getType() === config.TRAILER_TYPES.SEMI || chassisObjectToUse.getType() === config.TRAILER_TYPES.INTERLINK) {
                distanceFromWheelbaseTheoreticalEnd = chassisObjectToUse.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalEnd(), chassisObjectToUse.getFrontOfTrailerToLastAxle());
            } else if (chassisObjectToUse.getType() === config.TRAILER_TYPES.DRAWBAR || chassisObjectToUse.getType() === config.TRAILER_TYPES.PUP) {
                distanceFromWheelbaseTheoreticalEnd = chassisObjectToUse.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalEndForTrailer(accessory, chassisObjectToUse.getFrontOfTrailerToWheelbaseTheoreticalEndExclDrawbar(), chassisObjectToUse.getFrontOfTrailerToLastAxleExclDrawbar());
            }
        }
        return distanceFromWheelbaseTheoreticalEnd;
    }

    function getAxleWeightRear(id, parent, optionalObjectIdOrNumber) {

        var chassisObjectToUse = getChassisObjectFromPath(parent);

        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();
        var distanceFromWheelbaseTheoreticalEnd;
        switch (id) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                // return getVehicleOnlyRearTare();
                return getVehicleTareRearRearOnly();
            case config.OBJECT_TYPES.CREW_FIRST:
                return activeRig.getVehicle().getRearCrewFirst();
            case config.OBJECT_TYPES.CREW_SECOND:
                return activeRig.getVehicle().getRearCrewSecond();
            case config.OBJECT_TYPES.FUEL_TANKS:
                // if (optionalObjectIdOrNumber === 1) {
                //     return activeRig.getVehicle().getRearFuelTank1();
                // } else if (optionalObjectIdOrNumber === 2) {
                //     return activeRig.getVehicle().getRearFuelTank2();
                // } else if (optionalObjectIdOrNumber === 3) {
                //     return activeRig.getVehicle().getRearFuelTank3();
                // } else if (optionalObjectIdOrNumber === 4) {
                //     return activeRig.getVehicle().getRearFuelTank4();
                // }
                if (optionalObjectIdOrNumber >= 0) {
                    return activeRig.getVehicle()['getRearFuelTank' + optionalObjectIdOrNumber]();
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                return getTrailerTareRear(parent)/* - getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber)*/;
            case config.ACCESSORY_TYPES.BODY:
                var tempBody = getBodyFromPath(parent);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempBody);
                return tempBody.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.CRANE:
                var tempCrane = getCraneFromPath(parent);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempCrane);
                return tempCrane.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.TAILLIFT:
                var tempTaillift = getTailliftFromPath(parent);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempTaillift);
                return tempTaillift.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.FRIDGE:
                var tempFridge = getFridgeFromPath(parent);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFridge);
                return tempFridge.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var tempFifthWheel = getFifthWheelFromPath(parent);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFifthWheel);
                return tempFifthWheel.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.HITCH:
                var tempHitch = getHitchFromPath(parent);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempHitch);
                return tempHitch.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.OTHER:
                var tempOther = getOtherFromPathById(parent, optionalObjectIdOrNumber);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempOther);
                return tempOther.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var tempHooklift = getHookliftFromPath(parent);
                distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempHooklift);
                return tempHooklift.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
            case config.ACCESSORY_TYPES.PAYLOAD:
                var tempPayload = getPayloadFromPathById(parent, optionalObjectIdOrNumber);
                distanceFromWheelbaseTheoreticalEnd = getPayloadHolderFromPath(parent).getDistanceFromWheelbaseTheoreticalEndForChassis(tempPayload, chassisObjectToUse.getFrontToWheelbaseTheoreticalEnd(), chassisObjectToUse.getFrontToPayloadDeckStart());
                return tempPayload.getRearAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);
        }
        return 0;
    }

    function getAxleWeightTransferRear(id, transferToChassisObjectType, transferFromChassisObjectType, optionalObjectIdOrNumber) {

        var chassisObjectToUse = getChassisObjectFromPath(transferFromChassisObjectType);


        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();

        var combinationType = getCombinationType();

        switch (id) {
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
                if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                            return getTrailerTareFront(transferFromChassisObjectType) - calculateTransferOntoVehicleFrontAxleWhenPup(getTrailerTareFront(transferFromChassisObjectType));
                        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return getTrailerTareFront(transferFromChassisObjectType) - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(getTrailerTareFront(transferFromChassisObjectType));
                        default:
                            return 0;
                    }
                }
                return 0;
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle();
                        default:
                            return 0;
                    }
                } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle();
                        default:
                            return 0;
                    }
                }
                return 0;
            case config.ACCESSORY_TYPES.BODY:
                var tempBody = getBodyFromPath(transferFromChassisObjectType);
                return getRearTransferForItem(tempBody, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempBody);
                //var bodyFrontAxle = tempBody.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    switch (combinationType) {
                //        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //            return bodyFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(bodyFrontAxle);
                //        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                //            return bodyFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(bodyFrontAxle);
                //        default:
                //            return 0;
                //    }
                //} else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                //    switch (combinationType) {
                //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                //            return calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(bodyFrontAxle) - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(bodyFrontAxle));
                //        default:
                //            return 0;
                //    }
                //} else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                //    switch (combinationType) {
                //        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                //            return bodyFrontAxle - calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(bodyFrontAxle);
                //        default:
                //            return 0;
                //    }
                //}
                //return 0;
            case config.ACCESSORY_TYPES.CRANE:
                var tempCrane = getCraneFromPath(transferFromChassisObjectType);
                return getRearTransferForItem(tempCrane, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempCrane);
                //var craneFrontAxle = tempCrane.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return craneFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(craneFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return craneFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(craneFrontAxle);
                //    default:
                //        return 0;
                //}

            case config.ACCESSORY_TYPES.TAILLIFT:
                var tempTaillift = getTailliftFromPath(transferFromChassisObjectType);
                return getRearTransferForItem(tempTaillift, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempTaillift);
                //var tailliftFrontAxle = tempTaillift.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return tailliftFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(tailliftFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return tailliftFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(tailliftFrontAxle);
                //    default:
                //        return 0;

                //}
            case config.ACCESSORY_TYPES.FRIDGE:
                var tempFridge = getFridgeFromPath(transferFromChassisObjectType);
                return getRearTransferForItem(tempFridge, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFridge);
                //var fridgeFrontAxle = tempFridge.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return fridgeFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(fridgeFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return fridgeFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(fridgeFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var tempFifthWheel = getFifthWheelFromPath(transferFromChassisObjectType);
                return getRearTransferForItem(tempFifthWheel, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFifthWheel);
                //var fifthWheelFrontAxle = tempFifthWheel.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return fifthWheelFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(fifthWheelFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return fifthWheelFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(fifthWheelFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.HITCH:
                var tempHitch = getHitchFromPath(transferFromChassisObjectType);
                return getRearTransferForItem(tempHitch, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempHitch);
                //var hitchFrontAxle = tempHitch.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return hitchFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(hitchFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return hitchFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(hitchFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.OTHER:
                var tempOther = getOtherFromPathById(transferFromChassisObjectType, optionalObjectIdOrNumber);
                return getRearTransferForItem(tempOther, transferToChassisObjectType, transferFromChassisObjectType);
                //var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempOther);
                //var otherFrontAxle = tempOther.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return otherFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(otherFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return otherFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(otherFrontAxle);
                //    default:
                //        return 0;
                //}
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var tempHooklift = getHookliftFromPath(transferFromChassisObjectType);
                return getRearTransferForItem(tempHooklift, transferToChassisObjectType, transferFromChassisObjectType);
            case config.ACCESSORY_TYPES.PAYLOAD:
                var tempPayload = getPayloadFromPathById(transferFromChassisObjectType, optionalObjectIdOrNumber);
                //return getRearTransferForItem(tempPayload, transferToChassisObjectType, transferFromChassisObjectType);
                var distanceFromWheelbaseTheoreticalEnd = getPayloadHolderFromPath(transferFromChassisObjectType).getDistanceFromWheelbaseTheoreticalEndForChassis(tempPayload, chassisObjectToUse.getFrontToWheelbaseTheoreticalEnd(), chassisObjectToUse.getFrontToPayloadDeckStart());
                var payloadFrontAxle = tempPayload.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

                //switch (combinationType) {
                //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                //        return payloadFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(payloadFrontAxle);
                //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                //        return payloadFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(payloadFrontAxle);
                //    default:
                //        return 0;
                //}
                if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                            return payloadFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(payloadFrontAxle);
                        case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return payloadFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(payloadFrontAxle);
                        default:
                            return 0;
                    }
                } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(payloadFrontAxle) - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(payloadFrontAxle));
                        default:
                            return 0;
                    }
                } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
                    switch (combinationType) {
                        case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                            return payloadFrontAxle - calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(payloadFrontAxle);
                        default:
                            return 0;
                    }
                }
                return 0;
        }
        return 0;
    }

    function getRearTransferForItem(item, transferToChassisObjectType, transferFromChassisObjectType) {
        var chassisObjectToUse = getChassisObjectFromPath(transferFromChassisObjectType);

        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();

        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, item);
        var itemFrontAxle = item.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        var combinationType = getCombinationType();

        if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            switch (combinationType) {
                case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                    return itemFrontAxle - calculateTransferOntoVehicleFrontAxleWhenPup(itemFrontAxle);
                case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    return itemFrontAxle - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(itemFrontAxle);
                default:
                    return 0;
            }
        } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            switch (combinationType) {
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    return calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(itemFrontAxle) - calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(itemFrontAxle));
                default:
                    return 0;
            }
        } else if (transferFromChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER2 && transferToChassisObjectType === config.CHASSIS_OBJECT_TYPES.TRAILER1) {
            switch (combinationType) {
                case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                    return itemFrontAxle - calculateTransferOntoTrailer1FrontAxleWhenInterlinkAndSemi(itemFrontAxle);
                default:
                    return 0;
            }
        }
        return 0;
    }

    function getAxleWeightTotal(id, parent, optionalObjectIdOrNumber) {

        var chassisObjectToUse = getChassisObjectFromPath(parent);

        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();

        switch (id) {
            case config.CHASSIS_OBJECT_TYPES.VEHICLE:
                // return getVehicleOnlyFrontTare() + getVehicleOnlyRearTare();
                return getVehicleOnlyFrontTare() + getVehicleTareRearRearOnly();
            case config.OBJECT_TYPES.CREW_FIRST:
                return activeRig.getVehicle().getMassCrewFirst();
            case config.OBJECT_TYPES.CREW_SECOND:
                return activeRig.getVehicle().getMassCrewSecond();
            case config.OBJECT_TYPES.FUEL_TANKS:
                // if (optionalObjectIdOrNumber === 1) {
                //     return activeRig.getVehicle().getMassFuelTank1();
                // } else if (optionalObjectIdOrNumber === 2) {
                //     return activeRig.getVehicle().getMassFuelTank2();
                // } else if (optionalObjectIdOrNumber === 3) {
                //     return activeRig.getVehicle().getMassFuelTank3();
                // } else if (optionalObjectIdOrNumber === 4) {
                //     return activeRig.getVehicle().getMassFuelTank4();
                // }
                if (optionalObjectIdOrNumber >= 0) {
                    return activeRig.getVehicle()['getMassFuelTank' + optionalObjectIdOrNumber]();
                }
                break;
            case config.CHASSIS_OBJECT_TYPES.TRAILER1:
            case config.CHASSIS_OBJECT_TYPES.TRAILER2:
                //return (getTrailerTareFront(parent) + getTrailerTareRear(parent)) /*- getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber)*/;
                return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
            case config.ACCESSORY_TYPES.BODY:
                var tempBody = getBodyFromPath(parent);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempBody.getActualMass();
                } else {
                    //return tempBody.getActualMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.CRANE:
                var tempCrane = getCraneFromPath(parent);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempCrane.getMass();
                } else {
                    //return tempCrane.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.TAILLIFT:
                var tempTaillift = getTailliftFromPath(parent);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempTaillift.getMass();
                } else {
                    //return tempTaillift.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.FRIDGE:
                var tempFridge = getFridgeFromPath(parent);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempFridge.getMass();
                } else {
                    //return tempFridge.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.FIFTH_WHEEL:
                var tempFifthWheel = getFifthWheelFromPath(parent);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempFifthWheel.getMass();
                } else {
                    //return tempFifthWheel.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.HITCH:
                var tempHitch = getHitchFromPath(parent);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempHitch.getMass();
                } else {
                    //return tempHitch.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.OTHER:
                var tempOther = getOtherFromPathById(parent, optionalObjectIdOrNumber);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempOther.getMass();
                } else {
                    //return tempOther.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.HOOKLIFT:
                var tempHooklift = getHookliftFromPath(parent);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempHooklift.getMass();
                } else {
                    //return tempHitch.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
            case config.ACCESSORY_TYPES.PAYLOAD:
                var tempPayload = getPayloadFromPathById(parent, optionalObjectIdOrNumber);
                if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    return tempPayload.getMass();
                } else {
                    //return tempPayload.getMass() - getAxleWeightTransferTotal(id, parent, optionalObjectIdOrNumber);
                    return getAxleWeightFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightRear(id, parent, optionalObjectIdOrNumber);
                }
        }
        return 0;
    }

    function getAxleWeightTransferTotal(id, transferToChassisObjectType, transferFromChassisObjectType, optionalObjectIdOrNumber) {

        var chassisObjectToUse = getChassisObjectFromPath(transferFromChassisObjectType);


        var wheelbaseTheoretical = chassisObjectToUse.getWheelbaseTheoretical();

        var combinationType = getCombinationType();

        switch (combinationType) {
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getAxleWeightTransferFront(id, transferToChassisObjectType, transferFromChassisObjectType, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, transferToChassisObjectType, transferFromChassisObjectType, optionalObjectIdOrNumber);
            default:
                return 0;
        }

        //switch (id) {
        //    case config.CHASSIS_OBJECT_TYPES.TRAILER1:
        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return getAxleWeightTransferFront(id, parent, optionalObjectIdOrNumber) + getAxleWeightTransferRear(id, parent, optionalObjectIdOrNumber);
        //            default:
        //                return 0;
        //        }
        //    case config.ACCESSORY_TYPES.BODY:
        //        var tempBody = getBodyFromPath(parent);
        //        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempBody);
        //        var bodyFrontAxle = tempBody.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(bodyFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(bodyFrontAxle);
        //            default:
        //                return 0;
        //        }
        //    case config.ACCESSORY_TYPES.CRANE:
        //        var tempCrane = getCraneFromPath(parent);
        //        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempCrane);
        //        var craneFrontAxle = tempCrane.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(craneFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(craneFrontAxle);
        //            default:
        //                return 0;
        //        }
        //    case config.ACCESSORY_TYPES.TAILLIFT:
        //        var tempTaillift = getTailliftFromPath(parent);
        //        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempTaillift);
        //        var tailliftFrontAxle = tempTaillift.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(tailliftFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(tailliftFrontAxle);
        //            default:
        //                return 0;

        //        }
        //    case config.ACCESSORY_TYPES.FRIDGE:
        //        var tempFridge = getFridgeFromPath(parent);
        //        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFridge);
        //        var fridgeFrontAxle = tempFridge.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(fridgeFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(fridgeFrontAxle);
        //            default:
        //                return 0;
        //        }
        //    case config.ACCESSORY_TYPES.FIFTH_WHEEL:
        //        var tempFifthWheel = getFifthWheelFromPath(parent);
        //        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempFifthWheel);
        //        var fifthWheelFrontAxle = tempFifthWheel.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(fifthWheelFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(fifthWheelFrontAxle);
        //            default:
        //                return 0;
        //        }
        //    case config.ACCESSORY_TYPES.HITCH:
        //        var tempHitch = getHitchFromPath(parent);
        //        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempHitch);
        //        var hitchFrontAxle = tempHitch.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(hitchFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(hitchFrontAxle);
        //            default:
        //                return 0;
        //        }
        //    case config.ACCESSORY_TYPES.OTHER:
        //        var tempOther = getOtherFromPathById(parent, optionalObjectIdOrNumber);
        //        var distanceFromWheelbaseTheoreticalEnd = getDistanceFromWheelbaseTheoreticalEndForChassis(chassisObjectToUse, tempOther);
        //        var otherFrontAxle = tempOther.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(otherFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(otherFrontAxle);
        //            default:
        //                return 0;
        //        }
        //    case config.ACCESSORY_TYPES.PAYLOAD:
        //        var tempPayload = getPayloadFromPathById(parent, optionalObjectIdOrNumber);
        //        var distanceFromWheelbaseTheoreticalEnd = getPayloadHolderFromPath(parent).getDistanceFromWheelbaseTheoreticalEndForChassis(tempPayload, chassisObjectToUse.getFrontToWheelbaseTheoreticalEnd(), chassisObjectToUse.getFrontToPayloadDeckStart());
        //        var payloadFrontAxle = tempPayload.getFrontAxleLoad(distanceFromWheelbaseTheoreticalEnd, wheelbaseTheoretical);

        //        switch (combinationType) {
        //            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //                return calculateTransferOntoVehicleFrontAxleWhenPup(payloadFrontAxle);
        //            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //                return calculateTransferOntoVehicleFrontAxleWhenSemiOrInterlink(payloadFrontAxle);
        //            default:
        //                return 0;
        //        }
        //}
        //return 0;
    }

    function getGrossAxleWeightFront(parent) {
        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            return getCombinationVehicleFrontGross();
        } else {
            //return getTrailerFrontGross(parent);
            return getChassisObjectFromPath(parent).getUnladenFront() + getChassisObjectFromPath(parent).getPayloadFront();
        }
        
    }

    function getGrossAxleWeightRear(parent) {
        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            return getCombinationVehicleRearGross();
        } else {
            return getTrailerRearGross(parent);
        }
        
    }

    function getGrossAxleWeightTotal(parent) {

        if (parent === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            return getCombinationVehicleFrontGross() + getCombinationVehicleRearGross();
        } else {
            return getChassisObjectFromPath(parent).getUnladenFront() + getChassisObjectFromPath(parent).getPayloadFront() + getTrailerRearGross(parent);
        }

        //if (curTrailer1 === null) {
        //    totalGrossVal = offerManager.getUIFormattedMassValue(vehicle.getGrossTotal(), formattingPrecision);
        //} else {

        //    if (curTrailer1.getType() === config.TRAILER_TYPES.SEMI || curTrailer1.getType() === config.TRAILER_TYPES.INTERLINK) {
        //        totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndSemiOrInterlinkTotalGross(), formattingPrecision);
        //    } else if (curTrailer1.getType() === config.TRAILER_TYPES.DRAWBAR) {
        //        totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndDrawbarTotalGross(), formattingPrecision);
        //        trailerFrontGrossVal = offerManager.getUIFormattedMassValue(rigOps.getTrailer1FrontGross(), formattingPrecision);
        //    } else if (curTrailer1.getType() === config.TRAILER_TYPES.PUP) {
        //        totalGrossVal = offerManager.getUIFormattedMassValue(rigOps.getVehicleAndPupTotalGross(), formattingPrecision);
        //    }


        //    trailerRearGrossVal = offerManager.getUIFormattedMassValue(rigOps.getTrailer1RearGross(), formattingPrecision);
        //}
    }

    function getChassisHeightFromPath(path) {
        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return activeRig.getVehicle().getChassisHeightMax();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER1) !== -1) {
            return activeRig.getTrailer1().getChassisHeight();
        } else if (path.indexOf(config.CHASSIS_OBJECT_TYPES.TRAILER2) !== -1) {
            return activeRig.getTrailer2().getChassisHeight();
        }
        return 0;
    }
    
    function getOffsetToBodyRefPointForChassis(path) {
        var tempVehicle = activeRig.getVehicle();

        if (path.indexOf(config.CHASSIS_OBJECT_TYPES.VEHICLE) !== -1) {
            return tempVehicle.getPortionOfOverallLengthOverhangingFront() + tempVehicle.getBumperToBackOfCab();
        } else {
            return tempVehicle.getPortionOfOverallLengthOverhangingFront() + getTrailerFrontOverhangStart(path);
        }
    }

    function getTrailer1ArticulationPointToRearOfBDouble() {
        var tempTrailer1 = activeRig.getTrailer1();
        var tempTrailer2 = activeRig.getTrailer2();

        if (tempTrailer1 !== null && tempTrailer2 !== null) {
            return tempTrailer1.getWheelbaseTheoretical() - tempTrailer1.getFifthWheelOffset() + tempTrailer2.getWheelbaseTheoretical() + tempTrailer2.getWheelbaseTheoreticalEndToRearMostAxle() + getTrailerRearOverhangTotal(tempTrailer2.getChassisObjectName());
        } else {
            return 0;
        }
    }

    //function getLengthForItem(item) {
    //    switch (item.getAccessoryType()) {
    //        case config.ACCESSORY_TYPES.CRANE:
    //        case config.ACCESSORY_TYPES.OTHER:
    //            return item.getLength();
            
    //            return item.g;
    //        case config.ACCESSORY_TYPES.FRIDGE:
    //            return tempAccessoryHolder.getFridge();
    //        case config.ACCESSORY_TYPES.TAILLIFT:
    //            return tempAccessoryHolder.getTaillift();
    //        case config.ACCESSORY_TYPES.HITCH:
    //            return tempAccessoryHolder.getHitch();
    //        case config.ACCESSORY_TYPES.FIFTH_WHEEL:
    //            return tempAccessoryHolder.getFifthWheel();
    //        case config.ACCESSORY_TYPES.BODY:
    //            return tempAccessoryHolder.getBody();
    //        case config.ACCESSORY_TYPES.PAYLOAD:
    //            return getPayloadFromPathById(path, getIdFromPath(path));
    //    }
    //}

    /**
     * Get the value for Cab to Axles (CA) for the vehicle
     * @returns {number} - Value of Cab to Axles (CA)
     */
    function getCabToAxles(forceTakeWBSCalculatedVal) {
        //var forceRigOpsToUse = typeof forceRigOps === 'boolean' ? forceRigOps : false;
        //if (globals.user.getSpecifyChassisLengthAsOption() === config.SPECIFY_CHASSIS_LENGTH_AS_OPTIONS.CA && forceRigOpsToUse === false) {
        //    return activeRig.getVehicle().getCabToAxles();
        //} else {
        //    var tempVehicle = activeRig.getVehicle();
        //    return tempVehicle.getWheelbaseSpecified() + evaluateLegislation(legislationVals.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) - tempVehicle.getAxle1FrontToBackOfCab();
        //}
        var tempVehicle = activeRig.getVehicle();
        //return tempVehicle.getWheelbaseSpecified() + evaluateLegislation(legislationVals.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) - tempVehicle.getAxle1FrontToBackOfCab();
        if (forceTakeWBSCalculatedVal === true) {
            return getVehicleWheelbaseSpecified() + getAppropriateAxle1FrontToAxle2FrontAddition() - tempVehicle.getAxle1FrontToBackOfCab();
        } else {
            return tempVehicle.getWheelbaseSpecified() + getAppropriateAxle1FrontToAxle2FrontAddition() - tempVehicle.getAxle1FrontToBackOfCab();
            
        }
        
        
    }

  

    function getCabProtrusion() {
        var tempVehicle = activeRig.getVehicle();

        var cabProtrusion = getCabToAxles() - tempVehicle.getRearMostProtrudingItemBehindCabToRearAxles();
        if (cabProtrusion < 0) {
            return 0;
        } else {
            return cabProtrusion;
        }
    }

    function getAxle1FrontToStartOfBody() {

        var tempVehicle = activeRig.getVehicle();
        var tempBody = tempVehicle.getAccessoryHolder().getBody();
        if(tempBody !== null) {
            return tempVehicle.getBumperToBackOfCab() + getBodyGap(tempVehicle.getChassisObjectName()) - tempVehicle.getFrontOverhang();
        }
        return 0;
    }

    function getBodyStartToWheelbaseSpecifiedEnd() {
        var tempVehicle = activeRig.getVehicle();
        //return tempVehicle.getWheelbaseSpecified() + evaluateLegislation(legislationVals.variableTypes.ComVehAxle1FrontToWheelbaseLegislationStart) - getAxle1FrontToStartOfBody();
        return tempVehicle.getWheelbaseSpecified() + getAppropriateAxle1FrontToAxle2FrontAddition() - getAxle1FrontToStartOfBody();
        
    }

    function getAppropriateAxle1FrontToAxle2FrontAddition() {
        var tempVehicle = activeRig.getVehicle();
        if (globals.user.getSpecifyWheelbaseAs() === config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES) {
            return tempVehicle.getAxle1FrontToAxle2Front() * 0.5;
        }
        return 0;
    }
    
    function getVehicleBodyMaximumLength() {
        var tempVehicle = activeRig.getVehicle();
        var tempBody = tempVehicle.getAccessoryHolder().getBody();
        if (tempBody !== null) {
            if (tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                return evaluateLegislation(legislationVals.variableTypes.ComVehBodyLengthMax) - tempBody.getRampLength() - tempBody.getRampPlatesProtrusion();
            } else {
                return evaluateLegislation(legislationVals.variableTypes.ComVehBodyLengthMax) - tempBody.getHeadboardThickness() - tempBody.getTailboardThickness() - tempBody.getRampLength() - tempBody.getRampPlatesProtrusion();
            }
        } else {
            return 0;
        }
    }

    function getVehicleWheelbaseMaximum() { 
            
        if (evaluateLegislation(legislationVals.variableTypes.ShowWheelbaseMaximum) === 1) {
            var wheelbaseTurningMax = 999999;
            var wheelbase = 0;

            var turningCircleVals = new TurningCircleValues();
            turningCircleVals.turningRadiusCurbToCurbMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusCurbToCurbMax);
            turningCircleVals.turningRadiusOuterMax = evaluateLegislation(legislationVals.variableTypes.TurningRadiusOuterMax);
            turningCircleVals.turningRadiusInnerMin = evaluateLegislation(legislationVals.variableTypes.TurningRadiusInnerMin);

            var tempVehicle = activeRig.getVehicle();

            if (allowTurningCircleCalculation(null) && (turningCircleVals.turningRadiusOuterMax > 0 || turningCircleVals.turningRadiusCurbToCurbMax > 0)) {



                var steeringAttributeType = tempVehicle.getTurningAbilitySpecifiedAs();
                var value = tempVehicle.getCurrentTurningAbilityVal();


                var sourceVals = new TurningCircleValues();

                if (getVehicleWheelbaseTurningChanged()) {
                    steeringAttributeType = config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER;
                    value = getVehicleSteeringAngleInner();
                }


                if (steeringAttributeType === undefined) {
                    steeringAttributeType = getDefaultSteeringAttribute();
                    value = getDefaultSteeringAttributeValue();
                    //    bypassWheelbaseChanged = true;
                } else if (value === undefined) {
                    //    if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                    //        value = getVehicleSteeringAngleInner();
                    //    } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                    //        value = getVehicleTurningRadiusCurbToCurb();
                    //    } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                    //        value = getVehicleTurningRadiusWallToWall();
                    //    }
                    value = getVehicleSteeringAngleInner();
                }



                if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.STEERING_ANGLE_INNER) {
                    sourceVals.steeringAngleInnerInDegrees = value;
                } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_CURB_TO_CURB) {
                    sourceVals.turningRadiusCurbToCurb = value;
                } else if (steeringAttributeType === config.STEERING_ATTRIBUTE_TYPES.TURNING_RADIUS_WALL_TO_WALL) {
                    sourceVals.turningRadiusWallToWall = value;
                }




                calculateSmallestTurningCircleVehicle(turningCircleVals, sourceVals);
                wheelbaseTurningMax = turningCircleVals.wheelbaseTurningMax;

            }

            if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
                if (allowTurningCircleCalculation(null) && (turningCircleVals.turningRadiusOuterMax > 0 || turningCircleVals.turningRadiusCurbToCurbMax > 0)) {
                    return Math.min(evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMax), wheelbaseTurningMax - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
                } else {
                    return evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMax);
                }
            } else {

                if (allowTurningCircleCalculation(null) && (turningCircleVals.turningRadiusOuterMax > 0 || turningCircleVals.turningRadiusCurbToCurbMax > 0)) {
                    return Math.min(evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMax), wheelbaseTurningMax - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
                } else {
                    return evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMax);
                }
            }
        } else {
            return 9999;
        }
        

        //if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION.Code) ||
        //    globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
        //    wheelbase = Math.min(evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMax), wheelbaseTurningMax - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
        //} else {
        //    wheelbase = Math.min(evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMax),
        //                             wheelbaseTurningMax - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
        //}

        //return wheelbase + tempVehicle.getWheelbaseTheoreticalEndToAxle4Rear() - tempVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle2RearToAxle3Rear() - tempVehicle.getAxle3RearToAxle4Rear();

        /*
        Dim wheelbaseTurningMax As Double = 999999
        Dim wheelbase As Double

        If AllowTurningCircleCalculation(Nothing) And
            (LATurningRadiusOuterMax > 0 Or
            LATurningRadiusCurbToCurbMax > 0) Then

            CalculateSmallestTurningCircleVehicle(Nothing,
                                                    Nothing,
                                                    Nothing,
                                                    Nothing,
                                                    Nothing,
                                                    Nothing,
                                                    Nothing,
                                                    Nothing,
                                                    Nothing,
                                                    wheelbaseTurningMax)

        End If

        If WebContext.Current.User.IsInRole(ApplicationGlobals.RoleLoading) Or
                WebContext.Current.User.IsInRole(ApplicationGlobals.RoleTruckScienceAdmin) Then
            wheelbase = Math.Min(LAVehicleWheelbaseTheoreticalAdminMax,
                                        wheelbaseTurningMax - _Vehicle.Axle1FrontToWheelbaseTheoreticalStart)
        Else
            wheelbase = Math.Min(LAVehicleWheelbaseTheoreticalMax,
                                        wheelbaseTurningMax - _Vehicle.Axle1FrontToWheelbaseTheoreticalStart)
        End If

        Return wheelbase + _Vehicle.WheelbaseTheoreticalEndToAxle4Rear - _Vehicle.Axle1RearToAxle2Rear - _Vehicle.Axle2RearToAxle3Rear - _Vehicle.Axle3RearToAxle4Rear

    End Get
        */
    }

    function getVehicleWheelbaseMinimum() {

        if(evaluateLegislation(legislationVals.variableTypes.ShowWheelbaseMinimum) === 1) {
            if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
                return evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMin);
            } else {
                return evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMin);
            } 
        }else {
            return 1000;
        }
        //var tempVehicle = activeRig.getVehicle();

        //var wheelbase = 0;

        //if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION.Code) || globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
        //    wheelbase = evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMin);
        //} else {
        //    wheelbase = evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMin);
        //}

        //return wheelbase + tempVehicle.getWheelbaseTheoreticalEndToAxle4Rear() - tempVehicle.getAxle1RearToAxle2Rear() - tempVehicle.getAxle2RearToAxle3Rear() - tempVehicle.getAxle3RearToAxle4Rear();

        ///*
        //Dim wheelbase As Double

        //If WebContext.Current.User.IsInRole(ApplicationGlobals.RoleLoading) Or
        //   WebContext.Current.User.IsInRole(ApplicationGlobals.RoleTruckScienceAdmin) Then
        //    wheelbase = LAVehicleWheelbaseTheoreticalAdminMin
        //Else
        //    wheelbase = LAVehicleWheelbaseTheoreticalMin
        //End If

        //Return wheelbase + _Vehicle.WheelbaseTheoreticalEndToAxle4Rear - _Vehicle.Axle1RearToAxle2Rear - _Vehicle.Axle2RearToAxle3Rear - _Vehicle.Axle3RearToAxle4Rear
        //*/
    }

    function getWheelbaseManufacturerMinimum() {

        var tempOriginalVehicle = originalRig.getVehicle();

        return getOriginalWheelbaseSpecified() - (tempOriginalVehicle.getWheelbaseTheoretical() - tempOriginalVehicle.getWheelbaseTheoreticalManufacturerMin());
        //return getWheelbaseTheoreticalManufacturerMin() + getWheelbaseTheoreticalEndToAxle4Rear() - getAxle1RearToAxle2Rear() - getAxle2RearToAxle3Rear() - getAxle3RearToAxle4Rear();
    }

    function getWheelbaseManufacturerMaximum() {

        var tempOriginalVehicle = originalRig.getVehicle();

        return getOriginalWheelbaseSpecified() + (tempOriginalVehicle.getWheelbaseTheoreticalManufacturerMax() - tempOriginalVehicle.getWheelbaseTheoretical());
        //return getWheelbaseTheoreticalManufacturerMax() + getWheelbaseTheoreticalEndToAxle4Rear() - getAxle1RearToAxle2Rear() - getAxle2RearToAxle3Rear() - getAxle3RearToAxle4Rear();
    }

    function getVehicleWheelbaseOptimumAllowed() {

        var tempVehicle = activeRig.getVehicle();

        var optimum = getVehicleWheelbaseOptimum();

        if (globals.user.hasPermission(config.PERMISSIONS.CONFIGURATION_ADMIN_MASSES_DIMENSIONS.Code)) {
                if (optimum > evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMax) || optimum < evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalAdminMin)) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (optimum > evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMax) || optimum < evaluateLegislation(legislationVals.variableTypes.VehicleWheelbaseTheoreticalMin)) {
                    return false;
                } else {
                    return true;
                }
            }
    }

    function getVehicleWheelbaseOptimum() {

        var tempVehicle = activeRig.getVehicle();

        var crew1 = 0, crew2 = 0, fuel1 = 0, fuel2 = 0, fuel3 = 0, fuel4 = 0, distance = 0, mass = 0;
        var totalAccessories = 0;

        var fuelTanks = tempVehicle.getFuelTanks();

        // Extras
        crew1 = tempVehicle.getNoOfCrewInFirstRow() * tempVehicle.getCrewMassPerPerson() * (tempVehicle.getCrewFirstRowCOG() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());

        crew2 = tempVehicle.getNoOfCrewInSecondRow() * tempVehicle.getCrewMassPerPerson() * (tempVehicle.getCrewSecondRowCOG() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());

        if (fuelTanks.length > 0 && tempVehicle.getFuelTank1().getIncludeInCalculation()) {
            var fuelTank1 = tempVehicle.getFuelTank1();
            fuel1 = fuelTank1.getVolumeIncludedInExtras() * tempVehicle.getFuelSpecificGravity() * (fuelTank1.getHorizontalCog() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
        }

        if (fuelTanks.length > 1 && tempVehicle.getFuelTank2().getIncludeInCalculation()) {
            var fuelTank2 = tempVehicle.getFuelTank2();
            fuel2 = fuelTank2.getVolumeIncludedInExtras() * tempVehicle.getFuelSpecificGravity() * (fuelTank2.getHorizontalCog() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
        }

        if (fuelTanks.length > 2 && tempVehicle.getFuelTank3().getIncludeInCalculation()) {
            var fuelTank3 = tempVehicle.getFuelTank3();
            fuel3 = fuelTank3.getVolumeIncludedInExtras() * tempVehicle.getFuelSpecificGravity() * (fuelTank3.getHorizontalCog() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
        }

        if (fuelTanks.length > 3 && tempVehicle.getFuelTank4().getIncludeInCalculation()) {
            var fuelTank4 = tempVehicle.getFuelTank4();
            fuel4 = fuelTank4.getVolumeIncludedInExtras() * tempVehicle.getFuelSpecificGravity() * (fuelTank4.getHorizontalCog() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart());
        }

        // Accessories
        tempVehicle.getAccessoryHolder().getAccessories().forEach(function (accessory) {
            distance = tempVehicle.getAccessoryHolder().getDistanceFromWheelbaseTheoreticalStartForVehicle(accessory,
                                                                                    tempVehicle.getFrontOverhang(),
                                                                                    tempVehicle.getBumperToBackOfCab(),
                                                                                    tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart(),
                                                                                    tempVehicle.getWheelbaseTheoretical());

            //var massFunc;
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                //massFunc = accessory.getActualMass;
                totalAccessories += (accessory.getActualMass() * distance);
            } else {
                //massFunc = accessory.getMass;
                totalAccessories += (accessory.getMass() * distance);
            }


        });

        return (crew1 + crew2 + fuel1 + fuel2 + fuel3 + fuel4 + totalAccessories +
                (tempVehicle.getAUFront() + tempVehicle.getAURear() - tempVehicle.getUnladenTotal()) * tempVehicle.getPayloadCOGToWheelbaseTheoreticalStart()) /
                (tempVehicle.getAURear() - getCombinationVehicleRearTare());

        //return (crew1 + crew2 + fuel1 + fuel2 + fuel3 + fuel4 + totalAccessories +
        //        (getAUFront() + getAURear() - getUnladenTotal()) * getPayloadCOGToWheelbaseTheoreticalStart()) /
        //       (getAURear() - getTareRear()) + getWheelbaseTheoreticalEndToAxle4Rear() - getAxle1RearToAxle2Rear() - getAxle2RearToAxle3Rear() - getAxle3RearToAxle4Rear() - getAxle1FrontToWheelbaseTheoreticalStart();

    }

    function getBodyOptimumLength() {
        var tempVehicle = activeRig.getVehicle();
        var tempAccessoryHolder = tempVehicle.getAccessoryHolder();
        var tempPayloadHolder = tempVehicle.getPayloadHolder();
        var bodyLength = 0;

        if (tempAccessoryHolder.getBody() !== null && tempAccessoryHolder.getTaillift() === null) {

            if (tempAccessoryHolder.getBody().getCOGType() !== config.COG_TYPES.PERCENTAGE || tempAccessoryHolder.getBody().getHorizontalCOG() !== 50 || tempAccessoryHolder.getBody().getHeadboardThickness() !== tempAccessoryHolder.getBody().getTailboardThickness()) {
                return 0;
            }

            if (tempPayloadHolder.getPayloadType() === config.PAYLOAD_TYPES.DETAILED) {
                return 0;
            } else {
                var simplePayloadObject = tempPayloadHolder.getSimplePayloadObject();
                if (simplePayloadObject !== null && (simplePayloadObject.getCOGType() !== config.COG_TYPES.PERCENTAGE || simplePayloadObject.getHorizontalCOG() !== 50)) {
                    return 0;
                }
            }




            var tempBody = tempAccessoryHolder.getBody();
            var gap = getBodyGap(tempVehicle.getChassisObjectName());

            if (tempPayloadHolder.getAveragePayloadHorizontalCOG() === 0 || tempBody.getActualInternalLength() === 0) {
                bodyLength = 0;
            } else {

                bodyLength = Math.round(((tempVehicle.getAURear() -
                                getCombinationVehicleRearUnladen() +
                                tempBody.getRearAxleLoad((tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical()) -
                                    (tempVehicle.getBumperToBackOfCab() + gap + tempBody.getActualHorizontalCOG()),
                                            tempVehicle.getWheelbaseTheoretical())) *
                                tempVehicle.getWheelbaseTheoretical() /
                                (tempVehicle.getAURear() -
                                getCombinationVehicleRearUnladen() +
                                tempBody.getRearAxleLoad((tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical()) -
                                    (tempVehicle.getBumperToBackOfCab() + gap + tempBody.getActualHorizontalCOG()),
                                            tempVehicle.getWheelbaseTheoretical()) +
                                tempVehicle.getAUFront() -
                                getCombinationVehicleFrontUnladen() +
                                tempBody.getFrontAxleLoad((tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getWheelbaseTheoretical()) -
                                    (tempVehicle.getBumperToBackOfCab() + gap + tempBody.getActualHorizontalCOG()),
                                            tempVehicle.getWheelbaseTheoretical())) +
                                tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() +
                                tempVehicle.getFrontOverhang() -
                                tempVehicle.getBumperToBackOfCab() -
                                gap -
                                tempBody.getHeadboardThickness()) /
                                (tempPayloadHolder.getAveragePayloadHorizontalCOG() / tempBody.getActualInternalLength()));

            }



            if (tempBody.getLengthType() === config.BODY_MEASUREMENT_TYPES.EXTERNAL) {
                return bodyLength +
                        tempBody.getHeadboardThickness() +
                        tempBody.getTailboardThickness();
            } else {
                return bodyLength;
            }
        } else {
            return 0;
        }
    }

    function getBodyGap(chassisObjectTypeOrPath) {
        
        var tempAccessoryHolderToUse = getAccessoryHolderFromPath(chassisObjectTypeOrPath);
        var tempBody = tempAccessoryHolderToUse.getBody();
        var gap = 0;
        if (tempBody !== null) {
            if (tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                var tempHooklift = tempAccessoryHolderToUse.getHooklift();
                gap = tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + tempBody.getHorizontalGap();
            } else {
                gap = tempBody.getHorizontalGap();
            }
        }
        return gap;
    }

    function getHookliftRearOverhangTotal() {
        let tempVehicle = activeRig.getVehicle();
        let tempBody = tempVehicle.getAccessoryHolder().getBody();
        let tempHooklift = tempVehicle.getAccessoryHolder().getHooklift();
        if(tempHooklift) {
            let hookliftRearOverhangTotal = 0, hooklIftRearOverhangTotalEquipment = 0, hookliftRearOverhangTotalEquipmentAndBodyContainer = 0;
            let bBC = tempVehicle.getBumperToBackOfCab();
            let vehicleOverallLengthExclAccessories = tempVehicle.getOverallLengthExclAccessoriesExceptRearBumper();

            hooklIftRearOverhangTotalEquipment = -(vehicleOverallLengthExclAccessories - (bBC + tempHooklift.getHorizontalGap() + tempHooklift.getLength()));
            if (tempBody !== null && tempBody.getType() === config.BODY_TYPES.HOOKLIFT && tempBody.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
                hookliftRearOverhangTotalEquipmentAndBodyContainer = -(vehicleOverallLengthExclAccessories - (bBC + getBodyGap(config.CHASSIS_OBJECT_TYPES.VEHICLE) + tempVehicle.getAccessoryHolder().getBody().getActualLength()));
                hookliftRearOverhangTotal = Math.max(hooklIftRearOverhangTotalEquipment, hookliftRearOverhangTotalEquipmentAndBodyContainer)
            }else {
                hookliftRearOverhangTotal = hooklIftRearOverhangTotalEquipment;
            }
            if(hookliftRearOverhangTotal > 0) {
                return hookliftRearOverhangTotal;
            }
        }
        return 0;
    }

    // function getAfterframe() {
    //     let tempVehicle = activeRig.getVehicle();

    //     return tempVehicle.getOverallLengthExclAccessories() - (tempVehicle.getFrontOverhang() + tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + getVehicleWheelbaseSpecified() + tempVehicle.getRearProtrusion());
    // }

    function getAfterframe() {
        var tempVehicle = activeRig.getVehicle();
        if (globals.user.getSpecifyWheelbaseAs() === config.SPECIFY_WHEELBASE_AS_OPTIONS.CENTRE_OF_FRONT_AXLES_TO_CENTRE_OF_REAR_AXLES) {
            return (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear() + tempVehicle.getRearOverhang()) - (tempVehicle.getWheelbaseSpecified() + 0.5 * tempVehicle.getAxle1FrontToAxle2Front());
        } else {
            return (tempVehicle.getAxle1FrontToAxle2Front() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear() + tempVehicle.getRearOverhang()) - (tempVehicle.getWheelbaseSpecified());
        }
    }

    function getVehicleWheelbaseStandard() {
        return originalRig.getVehicle().getAxle2FrontToAxle1Rear();
    }

    function getWheelbaseTheoreticalBeforeAnyAxlesAdded() {
        var tempVehicle = activeRig.getVehicle();
        var forceAddedRaised = true;
        let raiseSteerablePusherOrTagForWheelbaseTurning = false;
        return tempVehicle.getWheelbaseTheoretical(raiseSteerablePusherOrTagForWheelbaseTurning, forceAddedRaised);
    }

    function getOriginalCOGTotalToOriginalWheelbaseTheoreticalStart() { 
        var tempVehicle = activeRig.getVehicle();
        
        return tempVehicle.getTareRearRear() * getWheelbaseTheoreticalBeforeAnyAxlesAdded() / getTareOriginalTotalAxles();
    }

    function getOriginalCOGTotalToNewWheelbaseTheoreticalStart() {
        var tempVehicle = activeRig.getVehicle();

        if (tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.SECONDSTEER) > 0 && tempVehicle.getAxlesHolder().getAxle2Front().isAdded() === true) {
            return getOriginalCOGTotalToOriginalWheelbaseTheoreticalStart() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart();
        } else {
            return getOriginalCOGTotalToOriginalWheelbaseTheoreticalStart();
        }
    }

    function getTareOriginalFrontAxles() {
        var tempVehicle = activeRig.getVehicle();

        return tempVehicle.getTareFront();
    }

    function getTareRevisedFrontAxles() {
        return getTareOriginalTotalAxles() - getTareRevisedRearAxles();
    }

    function getPusher1FrontAxles() {
        var tempVehicle = activeRig.getVehicle();

        var pusher1 = tempVehicle.getAxlesHolder().getAxle1Rear();
        if (pusher1 !== null && pusher1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return pusher1.getAxleWeight() - getPusher1RearAxles();
        }
        return 0;
    }

    function getPusher2FrontAxles() {
        var tempVehicle = activeRig.getVehicle();

        var pusher2 = tempVehicle.getAxlesHolder().getAxle2Rear();
        if (pusher2 !== null && pusher2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return pusher2.getAxleWeight() - getPusher2RearAxles();
        }
        return 0;
    }

    function getPusher3FrontAxles() {
        var tempVehicle = activeRig.getVehicle();

        var pusher3 = tempVehicle.getAxlesHolder().getAxle3Rear();
        if (pusher3 !== null && pusher3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return pusher3.getAxleWeight() - getPusher3RearAxles();
        }
        return 0;
    }

    function getTag1FrontAxles() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);
        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            return tag1.getAxleWeight() - getTag1RearAxles();
        }
        return 0;
    }

    function getTag2FrontAxles() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);
        
        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag2 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 1);
            if (tag2 !== null && tag2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return tag2.getAxleWeight() - getTag2RearAxles();
            }
            
        }
        return 0;
    }

    function getTag3FrontAxles() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);
        
        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag3 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 2);

            if (tag3 !== null && tag3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                return tag3.getAxleWeight() - getTag3RearAxles();
            }
        }
        return 0;
    }

    function getDistributedTareFrontTotal() {
        return getTareRevisedFrontAxles() + getPusher1FrontAxles() + getPusher2FrontAxles() + getPusher3FrontAxles() + getTag1FrontAxles() + getTag2FrontAxles() + getTag3FrontAxles();
    }

    function getTareOriginalRearAxles() {
        var tempVehicle = activeRig.getVehicle();
        
        return tempVehicle.getTareRearRear();
    }

    function getTareRevisedRearAxles() {
        //return (getTareOriginalTotalAxles() * getOriginalCOGTotalToOriginalWheelbaseTheoreticalStart()) / activeRig.getVehicle().getWheelbaseTheoretical();
        return (getTareOriginalTotalAxles() * getOriginalCOGTotalToNewWheelbaseTheoreticalStart()) / activeRig.getVehicle().getWheelbaseTheoretical();
    }

    function getPusher1RearAxles() {
        var tempVehicle = activeRig.getVehicle();

        var pusher1 = tempVehicle.getAxlesHolder().getAxle1Rear();
        var pusher1ToWheelbaseTheoreticalStart = tempVehicle.getDistanceToAxle1Rear() - tempVehicle.getFrontOverhang() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart();

        if (pusher1 !== null && pusher1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return (pusher1.getAxleWeight() * pusher1ToWheelbaseTheoreticalStart) / tempVehicle.getWheelbaseTheoretical();
        }
        return 0;
    }

    function getPusher2RearAxles() {
        var tempVehicle = activeRig.getVehicle();

        var pusher2 = tempVehicle.getAxlesHolder().getAxle2Rear();
        var pusher2ToWheelbaseTheoreticalStart = tempVehicle.getDistanceToAxle1Rear() - tempVehicle.getFrontOverhang() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getAxle1RearToAxle2Rear();
        if (pusher2 !== null && pusher2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return (pusher2.getAxleWeight() * pusher2ToWheelbaseTheoreticalStart) / tempVehicle.getWheelbaseTheoretical();
        }
        return 0;
    }

    

    function getPusher3RearAxles() {
        var tempVehicle = activeRig.getVehicle();

        var pusher3 = tempVehicle.getAxlesHolder().getAxle3Rear();
        var pusher3ToWheelbaseTheoreticalStart = tempVehicle.getDistanceToAxle1Rear() - tempVehicle.getFrontOverhang() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
        if (pusher3 !== null && pusher3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            return (pusher3.getAxleWeight() * pusher3ToWheelbaseTheoreticalStart) / tempVehicle.getWheelbaseTheoretical();
        }
        return 0;
    }

    function getDistanceFromLastRearDrivenAxleToTagAxle(lastRearDrivenAxlePosition, tagAxlePosition) {
        var tempVehicle = activeRig.getVehicle();

        var distanceFromLastRearDrivenToGivenTag = 0;

        switch (lastRearDrivenAxlePosition) {
            case config.AXLE_POSITIONS.AXLE_1_REAR:
                switch (tagAxlePosition) {
                    case config.AXLE_POSITIONS.AXLE_1_REAR:
                        //not possible
                        break;
                    case config.AXLE_POSITIONS.AXLE_2_REAR:
                        distanceFromLastRearDrivenToGivenTag += tempVehicle.getAxle1RearToAxle2Rear();
                        break;
                    case config.AXLE_POSITIONS.AXLE_3_REAR:
                        distanceFromLastRearDrivenToGivenTag += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
                        break;
                    case config.AXLE_POSITIONS.AXLE_4_REAR:
                        distanceFromLastRearDrivenToGivenTag += tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                        break;
                }
                break;
            case config.AXLE_POSITIONS.AXLE_2_REAR:
                switch (tagAxlePosition) {
                    case config.AXLE_POSITIONS.AXLE_1_REAR:
                        //not possible
                        break;
                    case config.AXLE_POSITIONS.AXLE_2_REAR:
                        //not possible
                        break;
                    case config.AXLE_POSITIONS.AXLE_3_REAR:
                        distanceFromLastRearDrivenToGivenTag += tempVehicle.getAxle2RearToAxle3Rear();
                        break;
                    case config.AXLE_POSITIONS.AXLE_4_REAR:
                        distanceFromLastRearDrivenToGivenTag += tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                        break;
                }

                break;
            case config.AXLE_POSITIONS.AXLE_3_REAR:
                switch (tagAxlePosition) {
                    case config.AXLE_POSITIONS.AXLE_1_REAR:
                        //not possible
                        break;
                    case config.AXLE_POSITIONS.AXLE_2_REAR:
                        //not possible
                        break;
                    case config.AXLE_POSITIONS.AXLE_3_REAR:
                        //not possible
                        break;
                    case config.AXLE_POSITIONS.AXLE_4_REAR:
                        distanceFromLastRearDrivenToGivenTag += tempVehicle.getAxle3RearToAxle4Rear();
                        break;
                }
                break;
            case config.AXLE_POSITIONS.AXLE_4_REAR:
                //not possible
                break;
        }
        

        return distanceFromLastRearDrivenToGivenTag;
    }

    function getDistanceFromWheelbaseTheoreticalStartToTag(lastRearDrivenAxlePosition, tagAxle) {
        var tempVehicle = activeRig.getVehicle();

        var tagAxlePosition = tempVehicle.getAxlesHolder().getAxlePosition(tagAxle);
        return tempVehicle.getAxle1FrontToAxle2Front() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getAxle2FrontToAxle1Rear() + tempVehicle.getAxle1RearToFirstRearDrivenAxle() + tempVehicle.getRearDrivenAxleSpan() + getDistanceFromLastRearDrivenAxleToTagAxle(lastRearDrivenAxlePosition, tagAxlePosition);
    }

    function getTag1RearAxles() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);
        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            //var tag1ToWheelbaseTheoreticalStart = tempVehicle.getDistanceToAxle1Rear() - tempVehicle.getFrontOverhang() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getAxle1RearToAxle2Rear();
            
            var tag1ToWheelbaseTheoreticalStart = getDistanceFromWheelbaseTheoreticalStartToTag(lastRearDrivenAxlePosition, tag1);
            return (tag1.getAxleWeight() * tag1ToWheelbaseTheoreticalStart) / tempVehicle.getWheelbaseTheoretical();
        }
        return 0;
    } 

    function getTag2RearAxles() {
        var tempVehicle = activeRig.getVehicle();

        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);

        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag2 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 1);
            if (tag2 !== null && tag2.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                //var tag2ToWheelbaseTheoreticalStart = tempVehicle.getDistanceToAxle1Rear() - tempVehicle.getFrontOverhang() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear();
                var tag2ToWheelbaseTheoreticalStart = getDistanceFromWheelbaseTheoreticalStartToTag(lastRearDrivenAxlePosition, tag2);
                return (tag2.getAxleWeight() * tag2ToWheelbaseTheoreticalStart) / tempVehicle.getWheelbaseTheoretical();
            }

        }
        return 0;
    }

    function getTag3RearAxles() {
        var tempVehicle = activeRig.getVehicle();

        
        var lastRearDrivenAxlePosition = tempVehicle.getAxlesHolder().getLastRearDrivenAxlePosition();
        var tag1 = tempVehicle.getAxlesHolder().getNextAxleByPosition(lastRearDrivenAxlePosition);

        if (tag1 !== null && tag1.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
            var tag3 = tempVehicle.getAxlesHolder().getAxleByNumber(tag1.getNumber() + 2);

            
            if (tag3 !== null && tag3.getType() === config.VEHICLE_AXLE_POSITION_TYPES.TAG) {
                //var tag3ToWheelbaseTheoreticalStart = tempVehicle.getDistanceToAxle1Rear() - tempVehicle.getFrontOverhang() - tempVehicle.getAxle1FrontToWheelbaseTheoreticalStart() + tempVehicle.getAxle1RearToAxle2Rear() + tempVehicle.getAxle2RearToAxle3Rear() + tempVehicle.getAxle3RearToAxle4Rear();
                var tag3ToWheelbaseTheoreticalStart = getDistanceFromWheelbaseTheoreticalStartToTag(lastRearDrivenAxlePosition, tag3);
                return (tag3.getAxleWeight() * tag3ToWheelbaseTheoreticalStart) / tempVehicle.getWheelbaseTheoretical();
            }
        }
        return 0;

    }

    function getDistributedTareRearTotal() {
        return getTareRevisedRearAxles() + getPusher1RearAxles() + getPusher2RearAxles() + getPusher3RearAxles() + getTag1RearAxles() + getTag2RearAxles() + getTag3RearAxles();
    }

    function getTareOriginalTotalAxles() {
        return getTareOriginalFrontAxles() + getTareOriginalRearAxles();
    }

    function getTareRevisedTotalAxles() {
        return getTareRevisedFrontAxles() + getTareRevisedRearAxles();
    }

    function getPusher1TotalAxles() {
        return getPusher1FrontAxles() + getPusher1RearAxles();
    }

    function getPusher2TotalAxles() {
        return getPusher2FrontAxles() + getPusher2RearAxles();
    }

    function getPusher3TotalAxles() {
        return getPusher3FrontAxles() + getPusher3RearAxles();
    }

    function getTag1TotalAxles() {
        return getTag1FrontAxles() + getTag1RearAxles();
    }

    function getTag2TotalAxles() {
        return getTag2FrontAxles() + getTag2RearAxles();
    }

    function getTag3TotalAxles() {
        return getTag3FrontAxles() + getTag3RearAxles();
    }

    function getDistributedTareTotalTotal() {
        return getTareRevisedTotalAxles() + getPusher1TotalAxles() + getPusher2TotalAxles() + getPusher3TotalAxles() + getTag1TotalAxles() + getTag2TotalAxles() + getTag3TotalAxles();
    }

    function generateBridgeFormulaPermissibleValues(path) {

        var legislationBridgeFormulaVals = {};

        // Update axles
        
        generateBridgeFormulaPermissiblesOnAxles(path, legislationBridgeFormulaVals);
        
        legislationBridgeFormulaVals.vehicleTotalBridgeOrRegulationVal = getVehicleTotalBridgeOrRegulationVal();
        legislationBridgeFormulaVals.combinationTotalBridgeOrRegulationVal = getCombinationTotalBridgeOrRegulationVal();
        legislationBridgeFormulaVals.vehicleRearTotalBridgeOrRegulationVal = getVehicleRearTotalBridgeOrRegulationVal();
        
        var ignoreOverride = true;
        legislationBridgeFormulaVals.originalVehicleTotalBridgeOrRegulationVal = getVehicleTotalBridgeOrRegulationVal(ignoreOverride);
        legislationBridgeFormulaVals.originalCombinationTotalBridgeOrRegulationVal = getCombinationTotalBridgeOrRegulationVal(ignoreOverride);
        
        legislationBridgeFormulaVals.originalVehicleRearTotalBridgeOrRegulationVal = getVehicleRearTotalBridgeOrRegulationVal(ignoreOverride);

        return legislationBridgeFormulaVals;
    }

    function generateBridgeFormulaPermissiblesOnAxles(path, legislationBridgeFormulaVals) {

        var legislationBridgeFormulaValsObjToUse;
        if (!legislationBridgeFormulaVals) {
            legislationBridgeFormulaValsObjToUse = {}
        } else {
            legislationBridgeFormulaValsObjToUse = legislationBridgeFormulaVals;
        }

        var axleHolderToUse = getAxleHolderFromPath(path);
        //var originalAxleHolderToUse = getOriginalAxleHolderFromPath(path);
        var ignoreOverrideForOriginals = true;
        // if front axle group is available
        if (axleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT) !== null) {
            legislationBridgeFormulaVals.frontAxleGroupBridgeFormulaPermissible = getChassisExtremeFrontGroupBridgeFormulaPermissible(path);
            legislationBridgeFormulaVals.originalFrontAxleGroupBridgeFormulaPermissible = getChassisExtremeFrontGroupBridgeFormulaPermissible(path, ignoreOverrideForOriginals);
        }

        axleHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER).forEach(function (pusherAxle, index) {
            var bridgeValue, originalBridgeValue,
                axleIndex = index + 1;
            switch (axleIndex) {
                case 1:
                    bridgeValue = getVehiclePusher1GroupBridgeFormulaPermissible();
                    originalBridgeValue = getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                    break;
                case 2:
                    bridgeValue = getVehiclePusher2GroupBridgeFormulaPermissible();
                    originalBridgeValue = getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                    break;
                case 3:
                    bridgeValue = getVehiclePusher3GroupBridgeFormulaPermissible();
                    originalBridgeValue = getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                    break;
            }
            
            legislationBridgeFormulaVals['pusherAxle' + axleIndex + 'BridgeFormulaPermissible'] = bridgeValue;
            legislationBridgeFormulaVals['originalPusherAxle' + axleIndex + 'BridgeFormulaPermissible'] = originalBridgeValue;
        });

        if (axleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) !== null) {
            legislationBridgeFormulaVals.rearAxleBridgeFormulaPermissible = getChassisExtremeRearDrivenGroupBridgeFormulaPermissible(path);
            legislationBridgeFormulaVals.originalRearAxleBridgeFormulaPermissible = getChassisExtremeRearDrivenGroupBridgeFormulaPermissible(path, ignoreOverrideForOriginals);
        }

        axleHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.TAG).forEach(function (tagAxle, index) {
            var bridgeValue, originalBridgeValue,
                axleIndex = index + 1;
            switch (axleIndex) {
                case 1:
                    bridgeValue = getVehicleTag1GroupBridgeFormulaPermissible();
                    originalBridgeValue = getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                    break;
                case 2:
                    bridgeValue = getVehicleTag2GroupBridgeFormulaPermissible();
                    originalBridgeValue = getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                    break;
                case 3:
                    bridgeValue = getVehicleTag3GroupBridgeFormulaPermissible();
                    originalBridgeValue = getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                    break;
            }
            
            legislationBridgeFormulaVals['tagAxle' + axleIndex + 'BridgeFormulaPermissible'] = bridgeValue;
            legislationBridgeFormulaVals['originalTagAxle' + axleIndex + 'BridgeFormulaPermissible'] = originalBridgeValue;
            
        });
        if (!legislationBridgeFormulaVals) {
            return legislationBridgeFormulaValsObjToUse;
        }
    }

    function updateBridgeFormulaPermissibleValues(path, bridgeFormulaPermissibleValues) {
        
        // Update axles
        updateBridgeFormulaPermissiblesOnAxles(path, bridgeFormulaPermissibleValues);

        activeOffer.getConfiguration().setVehicleBridgeFormulaPermissible(bridgeFormulaPermissibleValues.vehicleTotalBridgeOrRegulationVal);
        activeOffer.getConfiguration().setCombinationBridgeFormulaPermissible(bridgeFormulaPermissibleValues.combinationTotalBridgeOrRegulationVal);
        activeOffer.getConfiguration().setBridgeFormulaRearPermissible(bridgeFormulaPermissibleValues.vehicleRearTotalBridgeOrRegulationVal);
        
        
        originalOffer.getConfiguration().setVehicleBridgeFormulaPermissible(bridgeFormulaPermissibleValues.originalVehicleTotalBridgeOrRegulationVal);
        originalOffer.getConfiguration().setCombinationBridgeFormulaPermissible(bridgeFormulaPermissibleValues.originalCombinationTotalBridgeOrRegulationVal);
        originalOffer.getConfiguration().setBridgeFormulaRearPermissible(bridgeFormulaPermissibleValues.originalVehicleRearTotalBridgeOrRegulationVal);
        
    }

    function updateBridgeFormulaPermissiblesOnAxles(path, bridgeFormulaPermissibleValues) {
        var originalChassisObjectToUse = getOriginalChassisObjectFromPath(path);
        if (originalChassisObjectToUse) {
            var axleHolderToUse = getAxleHolderFromPath(path);
            var originalAxleHolderToUse = getOriginalAxleHolderFromPath(path);

            // if front axle group is available
            if (axleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT) !== null) {
                axleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).setBridgeFormulaPermissible(bridgeFormulaPermissibleValues.frontAxleGroupBridgeFormulaPermissible);
                originalAxleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.FRONT).setBridgeFormulaPermissible(bridgeFormulaPermissibleValues.originalFrontAxleGroupBridgeFormulaPermissible);
            }

            axleHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER).forEach(function (pusherAxle, index) {
                var bridgeValue, originalBridgeValue,
                    axleIndex = index + 1;
                //switch (axleIndex) {
                //    case 1:
                //        bridgeValue = getVehiclePusher1GroupBridgeFormulaPermissible();
                //        originalBridgeValue = getVehiclePusher1GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                //        break;
                //    case 2:
                //        bridgeValue = getVehiclePusher2GroupBridgeFormulaPermissible();
                //        originalBridgeValue = getVehiclePusher2GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                //        break;
                //    case 3:
                //        bridgeValue = getVehiclePusher3GroupBridgeFormulaPermissible();
                //        originalBridgeValue = getVehiclePusher3GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                //        break;
                //}
                pusherAxle.setBridgeFormulaPermissible(bridgeFormulaPermissibleValues['pusherAxle' + axleIndex + 'BridgeFormulaPermissible']);
                originalAxleHolderToUse.getAxleById(pusherAxle.getId()).setBridgeFormulaPermissible(bridgeFormulaPermissibleValues['originalPusherAxle' + axleIndex + 'BridgeFormulaPermissible']);
            });

            if (axleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR) !== null) {
                axleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).setBridgeFormulaPermissible(bridgeFormulaPermissibleValues.rearAxleBridgeFormulaPermissible);
                originalAxleHolderToUse.getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR).setBridgeFormulaPermissible(bridgeFormulaPermissibleValues.originalRearAxleBridgeFormulaPermissible);
            }

            axleHolderToUse.getAllAxlesOfType(config.VEHICLE_AXLE_POSITION_TYPES.TAG).forEach(function (tagAxle, index) {
                var bridgeValue, originalBridgeValue,
                    axleIndex = index + 1;
                //switch (axleIndex) {
                //    case 1:
                //        bridgeValue = getVehicleTag1GroupBridgeFormulaPermissible();
                //        originalBridgeValue = getVehicleTag1GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                //        break;
                //    case 2:
                //        bridgeValue = getVehicleTag2GroupBridgeFormulaPermissible();
                //        originalBridgeValue = getVehicleTag2GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                //        break;
                //    case 3:
                //        bridgeValue = getVehicleTag3GroupBridgeFormulaPermissible();
                //        originalBridgeValue = getVehicleTag3GroupBridgeFormulaPermissible(ignoreOverrideForOriginals);
                //        break;
                //}
                tagAxle.setBridgeFormulaPermissible(bridgeFormulaPermissibleValues['tagAxle' + axleIndex + 'BridgeFormulaPermissible']);
                originalAxleHolderToUse.getAxleById(tagAxle.getId()).setBridgeFormulaPermissible(bridgeFormulaPermissibleValues['originalTagAxle' + axleIndex + 'BridgeFormulaPermissible']);
            });
        }
    }

    function getBridgeFormulaPermissibleCombinationOverload() {
        let permissible = activeOffer.getConfiguration().getCombinationBridgeFormulaPermissible();
        let grossCampareValFunc;
        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                grossCampareValFunc = getCombinationGrossTotal;
                // return getGroup1BridgeFormulaOverload();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                grossCampareValFunc = getVehicleAndTrailer1GrossTotal;
                // return getGroup2BridgeFormulaOverload();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                grossCampareValFunc = getVehicleAndTrailer1GrossTotal;
                // return getGroup2BridgeFormulaOverload();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                grossCampareValFunc = getVehicleAndTrailer1GrossTotal;
                // return getGroup3BridgeFormulaOverload();
                break;
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                grossCampareValFunc = getCombinationGrossTotal;
                // return getGroup3BridgeFormulaOverload();
                break;
        }

        if(grossCampareValFunc) {
            if (permissible !== 0) {
                return (grossCampareValFunc() - permissible) / permissible * 100;
            } else {
                return 0;
            }
        }
        return 0;

    }

    function getPusherOrTagPermissibleVal(axleNumber, pusherOrTagNumber, pusherOrTagFlag) { 
        

        var funcPrefix;
        if (pusherOrTagFlag === config.VEHICLE_AXLE_POSITION_TYPES.PUSHER) {
            funcPrefix = 'getVehiclePusher';
        } else {
            funcPrefix = 'getVehicleTag';
        }

        var bridgeFormulaPermissibleVal = this[funcPrefix + pusherOrTagNumber + 'GroupBridgeFormulaPermissible']();
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
            var lesserOfManuafcturerAxleRatings = activeRig.getVehicle().getAxlesHolder().getLesserOfPusherOrTagAxleManufacturerRatings(axleNumber);
            return Math.min(lesserOfManuafcturerAxleRatings, bridgeFormulaPermissibleVal)
        } else {
            var grossAxleWeightRating = activeRig.getVehicle().getAxlesHolder().getPusherOrTagGrossAxleWeightRating(axleNumber);
            return Math.min(grossAxleWeightRating, bridgeFormulaPermissibleVal)
        }
        
    }

    function getRearRearAxlePermissibleVal() {
        
        if (globals.user.getSpecifyAxleRatingAs() === config.SPECIFY_AXLE_RATING_AS_OPTIONS.DETAILED) {
            return Math.min(activeRig.getVehicle().getAxlesHolder().getLesserOfRearRearAxleManufacturerRatings(), getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible());
        } else {
            var rearAxleGroup = activeRig.getVehicle().getAxlesHolder().getAxleGroupUsingType(config.VEHICLE_AXLE_POSITION_TYPES.REAR);
            return Math.min(rearAxleGroup.getPermissibleSimplified(), getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible());
        }
        
    }

    function hasUserOverriddenRegulationValue() {
        var returnValue = false;

        // Check vehicle axles
        getActiveOffer().getRig().getVehicle().getAxlesHolder().getAxles().forEach(checkAxleBridgeFormulaPermissibleOverride);
        // Check vehicle total card
        if (getActiveOffer().getRig().getVehicle().getVehicleBridgeFormulaPermissibleOverride() === true) {
            returnValue = true;
        }
        if (getActiveOffer().getRig().getVehicle().getCombinationBridgeFormulaPermissibleOverride() === true) {
            returnValue = true;
        }
        if (getActiveOffer().getRig().getVehicle().getBridgeFormulaRearPermissibleOverride() === true) {
            returnValue = true;
        }
        if (returnValue === true) {
            return true;
        }
        // Check trailer 1
        if (getActiveOffer().getRig().getTrailer1() !== null) {
            getActiveOffer().getRig().getTrailer1().getAxlesHolder().getAxles().forEach(checkAxleBridgeFormulaPermissibleOverride);
        }
        
        if (returnValue === true) {
            return true;
        }
        // Check trailer 2
        if (getActiveOffer().getRig().getTrailer2() !== null) {
            getActiveOffer().getRig().getTrailer2().getAxlesHolder().getAxles().forEach(checkAxleBridgeFormulaPermissibleOverride);
        }
        
        function checkAxleBridgeFormulaPermissibleOverride(axle) {
            if (axle.getBridgeFormulaPermissibleOverride() === true) {
                returnValue = true;
            }
        }

        return returnValue;
    }

    /**
     * Get the number of axles to determine vehicle class based on 
     * */
    function getNumberOfAxlesForVehicleClass() {
        //For Combination = Veh
        //Group X = Group 1
        //For Combination = VehDraw
        //Group X = Group 3
        //For Combination = VehPup
        //Group X = Group 2
        //For Combination = VehSemi
        //Group X = Group 2
        //For Combination = VehIntSemi
        //Group X = Group 3

        switch (getCombinationType()) {
            case config.COMBINATION_TYPES.VEHICLE:
                return getVehicleGroup1BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
                return getVehicleAndDrawbarGroup3BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
                return getVehicleAndPupGroup2BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK:
                return getVehicleAndSemiOrInterlinkGroup2BridgeFormulaNumberOfAxles();
            case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
                return getVehicleAndInterlinkAndSemiGroup3BridgeFormulaNumberOfAxles();
        }
        return 0;

        //switch (getCombinationType()) {
        //    case config.COMBINATION_TYPES.VEHICLE:
        //    case config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR:
        //        return getVehicleOnlyFrontTare();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI:
        //        return getVehicleOnlyFrontTare() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle() + getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_PUP:
        //        return getVehicleOnlyFrontTare() + getVehicleAndPupTareTransferOntoVehicleFrontAxle();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI:
        //        return getVehicleOnlyFrontTare() + getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle();
        //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_DRAWBAR:
        //        return 0;
        //    case config.COMBINATION_TYPES.VEHICLE_AND_SEMI_AND_PUP:
        //        return 0;
        //    default:
        //        return 0;
        //}

        //switch (bridgeGroup) {
        //    case config.BRIDGE_GROUPS.GROUP1:
        //        return getOverloadColour(getVehicleAndPupGroup1BridgeFormulaActual(), getVehicleAndPupGroup1BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP2:
        //        return getOverloadColour(getVehicleAndPupGroup2BridgeFormulaActual(), getVehicleAndPupGroup2BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP3:
        //        return getOverloadColour(getVehicleAndPupGroup3BridgeFormulaActual(), getVehicleAndPupGroup3BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP4:
        //        return getOverloadColour(getVehicleAndPupGroup4BridgeFormulaActual(), getVehicleAndPupGroup4BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP5:
        //        return getOverloadColour(getVehicleAndPupGroup5BridgeFormulaActual(), getVehicleAndPupGroup5BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP6:
        //        return getOverloadColour(getVehicleAndPupGroup6BridgeFormulaActual(), getVehicleAndPupGroup6BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP7:
        //        return getOverloadColour(getVehicleAndPupGroup7BridgeFormulaActual(), getVehicleAndPupGroup7BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP8:
        //        return getOverloadColour(getVehicleAndPupGroup8BridgeFormulaActual(), getVehicleAndPupGroup8BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP9:
        //        return getOverloadColour(getVehicleAndPupGroup9BridgeFormulaActual(), getVehicleAndPupGroup9BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP10:
        //        return getOverloadColour(getVehicleAndPupGroup10BridgeFormulaActual(), getVehicleAndPupGroup10BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP11:
        //        return getOverloadColour(getVehicleAndPupGroup11BridgeFormulaActual(), getVehicleAndPupGroup11BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP12:
        //        return getOverloadColour(getVehicleAndPupGroup12BridgeFormulaActual(), getVehicleAndPupGroup12BridgeFormulaPermissible());
        //    case config.BRIDGE_GROUPS.GROUP13:
        //        return getOverloadColour(getVehicleAndPupGroup13BridgeFormulaActual(), getVehicleAndPupGroup13BridgeFormulaPermissible());
        //}
        //return config.TABLE_HIGHLIGHT_COLOURS.OK;
    }

    /**
     * Gets the vehicle class ID based on the configuration of the rig
     * @returns {number} - Vehicle class ID (1-4)
     * */
    function getVehicleClassBasedOnRigConfiguration() {
        if (activeRig === null) {
            return 1;
        }
        if (getCombinationType() === config.COMBINATION_TYPES.VEHICLE && activeRig.getVehicle().getGVM() <= 3500) {
            return 1;
        } else {
            var numberOfAxles = getNumberOfAxlesForVehicleClass();
            if (numberOfAxles === 2) {
                return 2;
            } else if (numberOfAxles === 3 || numberOfAxles === 4) {
                return 3;
            } else {
                return 4;
            }
        }
    }
    //function getOriginalCOGTotalToOriginal

    function getRearmostAxleToCentreOfHookliftRoller(chassisObjectType) {
        var chassisObjectToUse = getChassisObjectFromPath(chassisObjectType);
        var hooklift = chassisObjectToUse.getAccessoryHolder().getHooklift();


        var gapToRefPointStart = 0, rearProtrusion = 0;
        if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            gapToRefPointStart = chassisObjectToUse.getBumperToBackOfCab();
            rearProtrusion = chassisObjectToUse.getRearProtrusion();
        }
        var rollerFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength() - hooklift.getCentreOfRoller();

        var rearmostAxleFromFrontOfChassis = chassisObjectToUse.getOverallLengthExclAccessories() - rearProtrusion - chassisObjectToUse.getRearOverhang();
        
        return Math.max(rearmostAxleFromFrontOfChassis, rollerFromFrontOfChassis) - Math.min(rearmostAxleFromFrontOfChassis, rollerFromFrontOfChassis);
    }

    function getCentreOfHookliftRollerToEnd(chassisObjectType) {
        var chassisObjectToUse = getChassisObjectFromPath(chassisObjectType);
        var hooklift = chassisObjectToUse.getAccessoryHolder().getHooklift();

        
        var gapToRefPointStart = 0;
        var rearProtrusionEndFromFrontOfRig = 0;
        if (chassisObjectType === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            gapToRefPointStart = chassisObjectToUse.getBumperToBackOfCab();
            rearProtrusionEndFromFrontOfRig = chassisObjectToUse.getOverallLengthExclAccessories();
        } else {
            gapToRefPointStart = getTrailerFrontOverhangStart(chassisObjectType);
            rearProtrusionEndFromFrontOfRig = getTrailerFrontOverhangStartExclDrawbar(chassisObjectType) + chassisObjectToUse.getOverallLengthExclAccessories();
        }
        var rollerFromFrontOfChassis = gapToRefPointStart + hooklift.getHorizontalGap() + hooklift.getLength() - hooklift.getCentreOfRoller();
        
        var rearBumperEndFromFrontOfChassis = 0;
        var rearBumper = chassisObjectToUse.getAccessoryHolder().getRearmostRearBumper();
        if (rearBumper !== null) {
            rearBumperEndFromFrontOfChassis = gapToRefPointStart + rearBumper.getHorizontalGap() + rearBumper.getLength();
        }
        return Math.max(rearProtrusionEndFromFrontOfRig, rearBumperEndFromFrontOfChassis) - rollerFromFrontOfChassis;
    }

    function calculateFromBackOfCab(accessory) {
        var tempVehicle = activeRig.getVehicle();
        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY && accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
            var tempHooklift = tempVehicle.getAccessoryHolder().getHooklift();

            return accessory.getFromAxle1Front() + tempVehicle.getFrontOverhang() - (tempVehicle.getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset());
        } else {
            return accessory.getFromAxle1Front() + tempVehicle.getFrontOverhang() - tempVehicle.getBumperToBackOfCab();
        }
        
    }

    function calculateFromAxle1Front(accessory) {
        var tempVehicle = activeRig.getVehicle();
        if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY && accessory.getType() === config.BODY_TYPES.HOOKLIFT && accessory.getSubType() === config.BODY_SUB_TYPES.BODY_ONLY) {
            var tempHooklift = tempVehicle.getAccessoryHolder().getHooklift();

            return tempVehicle.getBumperToBackOfCab() + tempHooklift.getHorizontalGap() + tempHooklift.getHookOffset() + accessory.getHorizontalGap() - tempVehicle.getFrontOverhang();
        } else {
            return tempVehicle.getBumperToBackOfCab() + accessory.getHorizontalGap() - tempVehicle.getFrontOverhang();
        }
        
    }
    function calculateFromAxle1FrontForRelevantAccessories() {
        activeRig.getVehicle().getAccessoryHolder().getAccessories().forEach(function (accessory) {
            if (accessory.getAccessoryType() === config.ACCESSORY_TYPES.CRANE || accessory.getAccessoryType() === config.ACCESSORY_TYPES.HOOKLIFT || accessory.getAccessoryType() === config.ACCESSORY_TYPES.OTHER || accessory.getAccessoryType() === config.ACCESSORY_TYPES.BODY) {
                accessory.setFromAxle1Front(calculateFromAxle1Front(accessory));
            }
        });
    }

    function calculateFromLeftOfChassisRearWidth(item, path) {
        let chassisObjectToUse = getChassisObjectFromPath(path);
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            return ((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle();
        } else {
            return ((chassisObjectToUse.getOverallWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle();
        }
    }
    function calculateFromCentreOfChassisForFromLeftOfVehicleRearWidth(item, path) {
        let chassisObjectToUse = getChassisObjectFromPath(path);
        if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
            return -(((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) - item.getFromLeftOfVehicleRearWidth());
        } else {
            return -(((chassisObjectToUse.getOverallWidth() - item.getWidth()) / 2) - item.getFromLeftOfVehicleRearWidth());
        }
        
    }

    function calculateFromLeftOfBodyWidthExternal(item, path) {
        let chassisObjectToUse = getChassisObjectFromPath(path);
        if(chassisObjectToUse.getType && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return ((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle();
        } else {
            return ((chassisObjectToUse.getAccessoryHolder().getBody().getActualWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle();
        }
        
    }

    function calculateFromCentreOfChassisForFromLeftOfBodyWidthExternal(item, path) {
        let chassisObjectToUse = getChassisObjectFromPath(path);
        if(chassisObjectToUse.getType && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
            return -(((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) - item.getFromLeftOfBodyWidthExternal());
        } else {
            return -(((chassisObjectToUse.getAccessoryHolder().getBody().getActualWidth() - item.getWidth()) / 2) - item.getFromLeftOfBodyWidthExternal());
        }
        
    }
    // function calculateFromCentreOfVehicleForAllChassisItems(delta, path) {
    //     let chassisObjectToUse = getChassisObjectFromPath(path);
    //     chassisObjectToUse.getAccessoryHolder().getAccessories().forEach(function (item) {
    //         if(item.getFromLeftOfVehicleRearWidth) {
    //             if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
    //                 return item.getFromCentreOfVehicle() + delta/2;
    //             } else {
    //                 return ((chassisObjectToUse.getOverallWidth() - item.getWidth()) / 2) - item.getFromCentreOfVehicle();
    //             }
    //         }
    //     });
        
    // }
    function updateFromLeftOfChassisRearWidthForAllChassisItems(delta, path) {
        let chassisObjectToUse = getChassisObjectFromPath(path);
        chassisObjectToUse.getAccessoryHolder().getAccessories().forEach(function (item) {
            if(item.getFromLeftOfVehicleRearWidth && item.getAccessoryType() !== config.ACCESSORY_TYPES.HITCH && item.getAccessoryType() !== config.ACCESSORY_TYPES.FIFTH_WHEEL) {
                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    item.setFromLeftOfVehicleRearWidth(((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle());
                } else {
                    item.setFromLeftOfVehicleRearWidth(((chassisObjectToUse.getOverallWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle());
                }
            }
        });
        
    }

    function updateFromCentreOfChassisForAllChassisItems(path) {
        let chassisObjectToUse = getChassisObjectFromPath(path);
        chassisObjectToUse.getAccessoryHolder().getAccessories().forEach(function (item) {
            if(item.getFromLeftOfVehicleRearWidth  && item.getAccessoryType() !== config.ACCESSORY_TYPES.HITCH && item.getAccessoryType() !== config.ACCESSORY_TYPES.FIFTH_WHEEL) {
                if (chassisObjectToUse.getChassisObjectName() === config.CHASSIS_OBJECT_TYPES.VEHICLE) {
                    item.setFromCentreOfVehicle(-(((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) - item.getFromLeftOfVehicleRearWidth()));
                } else {
                    item.setFromCentreOfVehicle(-(((chassisObjectToUse.getOverallWidth() - item.getWidth()) / 2) - item.getFromLeftOfVehicleRearWidth()));
                }
            }else if(item.getFromLeftOfBodyWidthExternal) {
                if(chassisObjectToUse.getType && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    item.setFromCentreOfVehicle(-(((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) - item.getFromLeftOfBodyWidthExternal()));
                }else {
                    item.setFromCentreOfVehicle(-(((chassisObjectToUse.getAccessoryHolder().getBody().getActualWidth() - item.getWidth()) / 2) - item.getFromLeftOfBodyWidthExternal()));
                }
            }
        });
        
    }

    function updateFromLeftOfBodyWidthExternalForAllChassisItems(delta, path) {
        let chassisObjectToUse = getChassisObjectFromPath(path);
        chassisObjectToUse.getAccessoryHolder().getAccessories().forEach(function (item) {
            if(item.getFromLeftOfBodyWidthExternal) {
                if(chassisObjectToUse.getType && chassisObjectToUse.getType() === config.VEHICLE_TYPES.COMPLETE_VEHICLE) {
                    item.setFromLeftOfBodyWidthExternal(((chassisObjectToUse.getRearWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle());
                } else {
                    item.setFromLeftOfBodyWidthExternal(((chassisObjectToUse.getAccessoryHolder().getBody().getActualWidth() - item.getWidth()) / 2) + item.getFromCentreOfVehicle());
                }
                
            }
        });
        
    }
/** 
 * function mapPusherToMinusAxle(rearAxlePosition) {
        let tempVehicle = activeRig.getVehicle();
        let numMinusAxles = tempVehicle.getAxlesHolder().countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

        switch(rearAxlePosition) {
            case 1:
                switch(numMinusAxles) {
                    case 1:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                    case 2:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                    case 3:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_3;
                }
                break; 
            case 2:
                switch(numMinusAxles) {
                    case 2:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1;
                    case 3:
                        return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_2;
                }
                break; 
            case 3:
                return config.EXTREME_BRIDGE_GROUPS.VEHICLE_REAR_MINUS_1; 
        }

        return '';
    }


    curVehicle.getPusherOrTagRearAxleDistribution({ axleNumber: axleNumber, rearMassVal: rigOps.getCombinationVehicleRearGross() })
    _axlesHolder.getPusherOrTagRearAxleDistribution(params.axleNumber, massValToUse);
 * 
 */

    function mapMinusAxlePositionToRearAxleNumber(minusAxlePosition) {
        let tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        let numMinusAxles = tempVehicleAxlesHolder.countAxleType(config.VEHICLE_AXLE_POSITION_TYPES.PUSHER);

        switch(minusAxlePosition) {
            case 1:
                switch(numMinusAxles) {
                    case 1:
                        return tempVehicleAxlesHolder.getAxle1Rear().getNumber();
                    case 2:
                        return  tempVehicleAxlesHolder.getAxle2Rear().getNumber();
                    case 3:
                        return  tempVehicleAxlesHolder.getAxle3Rear().getNumber();
                }
                break; 
            case 2:
                switch(numMinusAxles) {
                    case 2:
                        return tempVehicleAxlesHolder.getAxle1Rear().getNumber();
                    case 3:
                        return tempVehicleAxlesHolder.getAxle2Rear().getNumber();
                }
                break; 
            case 3:
                switch(numMinusAxles) {
                    case 3:
                        return tempVehicleAxlesHolder.getAxle1Rear().getNumber();
                }
                break;
                 
        }

        return -1;
    }

    function getVehicleRearMinus1Gross() {
        return getMinusAxleGross(1);
    }

    function getVehicleRearMinus2Gross() {
        return getMinusAxleGross(2);
    }

    function getVehicleRearMinus3Gross() {
        return getMinusAxleGross(3);
    }

    function getMinusAxleGross(minusAxlePosition) {
        let tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        let axleNumber = mapMinusAxlePositionToRearAxleNumber(minusAxlePosition);
        if(axleNumber !== -1) {
            return tempVehicleAxlesHolder.getPusherOrTagRearAxleDistribution(axleNumber, getCombinationVehicleRearGross());
        }
        return 0;
    }

    function getVehicleMainGross() {
        let tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        return tempVehicleAxlesHolder.getRearRearAxlesDistribution(getCombinationVehicleRearGross());
    }

    function getVehicleRearPlus1Gross() {
        let tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        let plus1Axle = tempVehicleAxlesHolder.getTag1();
        if(plus1Axle !== null) {
            let axleNumber = plus1Axle.getNumber();
            return tempVehicleAxlesHolder.getPusherOrTagRearAxleDistribution(axleNumber, getCombinationVehicleRearGross());
        }
        return 0;
    }

    function getVehicleRearPlus2Gross() {
        let tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        let plus2Axle = tempVehicleAxlesHolder.getTag2();
        if(plus2Axle !== null) {
            let axleNumber = plus2Axle.getNumber();
            return tempVehicleAxlesHolder.getPusherOrTagRearAxleDistribution(axleNumber, getCombinationVehicleRearGross());
        }
        return 0;
    }

    function getVehicleRearPlus3Gross() {
        let tempVehicleAxlesHolder = activeRig.getVehicle().getAxlesHolder();
        let plus3Axle = tempVehicleAxlesHolder.getTag3();
        if(plus3Axle !== null) {
            let axleNumber = plus3Axle.getNumber();
            return tempVehicleAxlesHolder.getPusherOrTagRearAxleDistribution(axleNumber, getCombinationVehicleRearGross());
        }
        return 0;
    }
    
    function getFirstGearStartability() {
        let engineMaximumTorqueAsLbFt = getEngineMaximumTorqueAsLbFt();
        let tempVehicle = activeRig.getVehicle();
        let transmissionFirstGearRatio = tempVehicle.getTransmissionFirstGearRatio();
        let firstDrivenAxleRatio = tempVehicle.getAxlesHolder().getStandardRatioOfDrivenAxle();
        let firstDrivenAxleTyreStaticLoadedRadius = tempVehicle.getAxlesHolder().getTyreStaticLoadedRadiusOfDrivenAxle();
        let transmissionTorqueConverterMultiplication = tempVehicle.getTransmissionTorqueConverterMultiplication();
        // let combinationTotalGrossImperial = getCombinationTotalGrossImperialRounded();
        let combinationGVMOrGCMImperial = getCombinationGVMOrGCMImperialRounded();
        
        if(canEvaluateFirstGearStartability()) {
            if(transmissionTorqueConverterMultiplication > 0) {
                return (engineMaximumTorqueAsLbFt * 0.5 * 0.85 * 
                        transmissionFirstGearRatio * transmissionTorqueConverterMultiplication * 
                        firstDrivenAxleRatio * 1200) /
                        (firstDrivenAxleTyreStaticLoadedRadius / 25.4 *
                            combinationGVMOrGCMImperial) - 1.2;
            } else {
                return (engineMaximumTorqueAsLbFt * 0.5 * 0.85 * 
                    transmissionFirstGearRatio * firstDrivenAxleRatio * 1200) /
                    (firstDrivenAxleTyreStaticLoadedRadius / 25.4 *
                        combinationGVMOrGCMImperial) - 1.2;
            }
        }
        return 0;
    }

    function canEvaluateFirstGearStartability() {
        let engineMaximumTorqueAsLbFt = getEngineMaximumTorqueAsLbFt();
        let tempVehicle = activeRig.getVehicle();
        let transmissionFirstGearRatio = tempVehicle.getTransmissionFirstGearRatio();
        let firstDrivenAxleRatio = tempVehicle.getAxlesHolder().getStandardRatioOfDrivenAxle();
        let firstDrivenAxleTyreStaticLoadedRadius = tempVehicle.getAxlesHolder().getTyreStaticLoadedRadiusOfDrivenAxle();
        // let combinationTotalGrossImperial = getCombinationTotalGrossImperialRounded();
        let combinationGVMOrGCMImperial = getCombinationGVMOrGCMImperialRounded();
        
        if(engineMaximumTorqueAsLbFt > 0 && transmissionFirstGearRatio > 0 && firstDrivenAxleRatio > 0 && firstDrivenAxleTyreStaticLoadedRadius > 0 && combinationGVMOrGCMImperial > 0) {
            return true;
        }
        return false;
    }

    function getReverseGearStartability() {
        let engineMaximumTorqueAsLbFt = getEngineMaximumTorqueAsLbFt();
        let tempVehicle = activeRig.getVehicle();
        let transmissionReverseGearRatio = tempVehicle.getTransmissionReverseGearRatio();
        let firstDrivenAxleRatio = tempVehicle.getAxlesHolder().getStandardRatioOfDrivenAxle();
        let firstDrivenAxleTyreStaticLoadedRadius = tempVehicle.getAxlesHolder().getTyreStaticLoadedRadiusOfDrivenAxle();
        let transmissionTorqueConverterMultiplication = tempVehicle.getTransmissionTorqueConverterMultiplication();
        // let combinationTotalGrossImperial = getCombinationTotalGrossImperialRounded();
        let combinationGVMOrGCMImperial = getCombinationGVMOrGCMImperialRounded();
        
        if(canEvaluateReverseGearStartability()){
            if(transmissionTorqueConverterMultiplication > 0) {
                return (engineMaximumTorqueAsLbFt * 0.5 * 0.85 * 
                    transmissionReverseGearRatio * transmissionTorqueConverterMultiplication * 
                        firstDrivenAxleRatio * 1200) /
                        (firstDrivenAxleTyreStaticLoadedRadius / 25.4 *
                            combinationGVMOrGCMImperial) - 1.2;
            } else {
                return (engineMaximumTorqueAsLbFt * 0.5 * 0.85 * 
                    transmissionReverseGearRatio * firstDrivenAxleRatio * 1200) /
                    (firstDrivenAxleTyreStaticLoadedRadius / 25.4 *
                        combinationGVMOrGCMImperial) - 1.2;
            }
        }
        return 0;
    }

    function canEvaluateReverseGearStartability() {
        let engineMaximumTorqueAsLbFt = getEngineMaximumTorqueAsLbFt();
        let tempVehicle = activeRig.getVehicle();
        let transmissionReverseGearRatio = tempVehicle.getTransmissionReverseGearRatio();
        let firstDrivenAxleRatio = tempVehicle.getAxlesHolder().getStandardRatioOfDrivenAxle();
        let firstDrivenAxleTyreStaticLoadedRadius = tempVehicle.getAxlesHolder().getTyreStaticLoadedRadiusOfDrivenAxle();
        // let combinationTotalGrossImperial = getCombinationTotalGrossImperialRounded();
        let combinationGVMOrGCMImperial = getCombinationGVMOrGCMImperialRounded();
        
        if(engineMaximumTorqueAsLbFt > 0 && transmissionReverseGearRatio > 0 && firstDrivenAxleRatio > 0 && firstDrivenAxleTyreStaticLoadedRadius > 0 && combinationGVMOrGCMImperial > 0) {
            return true;
        }
        return false;
    }

    function getFirstGearGradeability() {
        let engineMaximumTorqueAsLbFt = getEngineMaximumTorqueAsLbFt();
        let tempVehicle = activeRig.getVehicle();
        let transmissionFirstGearRatio = tempVehicle.getTransmissionFirstGearRatio();
        let firstDrivenAxleRatio = tempVehicle.getAxlesHolder().getStandardRatioOfDrivenAxle();
        let firstDrivenAxleTyreStaticLoadedRadius = tempVehicle.getAxlesHolder().getTyreStaticLoadedRadiusOfDrivenAxle();
        let transmissionTorqueConverterMultiplication = tempVehicle.getTransmissionTorqueConverterMultiplication();

        // let combinationTotalGrossImperial = getCombinationTotalGrossImperialRounded();
        let combinationGVMOrGCMImperial = getCombinationGVMOrGCMImperialRounded();

        if(canEvaluateFirstGearGradeability()) {
            if(transmissionTorqueConverterMultiplication > 0) {
                return (engineMaximumTorqueAsLbFt * 0.85 * 
                        transmissionFirstGearRatio * transmissionTorqueConverterMultiplication * 
                        firstDrivenAxleRatio * 1200) /
                        (firstDrivenAxleTyreStaticLoadedRadius / 25.4 *
                            combinationGVMOrGCMImperial) - 1.2;
            } else {
                return (engineMaximumTorqueAsLbFt * 0.85 * 
                    transmissionFirstGearRatio * firstDrivenAxleRatio * 1200) /
                    (firstDrivenAxleTyreStaticLoadedRadius / 25.4 *
                        combinationGVMOrGCMImperial) - 1.2;
            }
        }
        return 0;
    }

    function canEvaluateFirstGearGradeability() {
        let engineMaximumTorqueAsLbFt = getEngineMaximumTorqueAsLbFt();
        let tempVehicle = activeRig.getVehicle();
        let transmissionFirstGearRatio = tempVehicle.getTransmissionFirstGearRatio();
        let firstDrivenAxleRatio = tempVehicle.getAxlesHolder().getStandardRatioOfDrivenAxle();
        let firstDrivenAxleTyreStaticLoadedRadius = tempVehicle.getAxlesHolder().getTyreStaticLoadedRadiusOfDrivenAxle();

        // let combinationTotalGrossImperial = getCombinationTotalGrossImperialRounded();
        let combinationGVMOrGCMImperial = getCombinationGVMOrGCMImperialRounded();

        if(engineMaximumTorqueAsLbFt > 0 && transmissionFirstGearRatio > 0 && firstDrivenAxleRatio > 0 && firstDrivenAxleTyreStaticLoadedRadius > 0 && combinationGVMOrGCMImperial > 0) {
            return true;
        }
        return false;
    }

    function getCDLRequiredUS() {
        let combinationType = getCombinationType();
        let trailer1GVMImperial = getTrailer1GVMImperialRounded();
        let trailer2GVMImperial = getTrailer2GVMImperialRounded();
        let gvmImperial = getVehicleGVMImperialRounded();
        let gcmImperial = getVehicleCGMImperialRounded();
        let vehicleTotalGrossImperial = getVehicleTotalGrossImperialRounded();
        let trailer1TotalGrossImperial = getTrailer1TotalGrossImperialRounded();
        let combinationTotalGrossImperial = getCombinationTotalGrossImperialRounded();

        if(canEvaluateCDLRequiredUS()) {
            if(combinationType === config.COMBINATION_TYPES.VEHICLE) {
                if(gvmImperial >= 26001 || vehicleTotalGrossImperial >= 26001) {
                    // return config.YES_NO_OPTIONS.YES;
                    return config.getTranslationText('4978');
                }else {
                    // return config.YES_NO_OPTIONS.NO;
                    return config.getTranslationText('4979');
                }
            } else {
                if(gcmImperial >= 26001 || 
                combinationTotalGrossImperial >=26001 || 
                (gvmImperial + trailer1GVMImperial + trailer2GVMImperial >= 26001) || 
                (gvmImperial + trailer1TotalGrossImperial >= 26001) || 
                (vehicleTotalGrossImperial + trailer1GVMImperial >= 26001)){
                    // return config.YES_NO_OPTIONS.YES;
                    return config.getTranslationText('4980');
                } else {
                    // return config.YES_NO_OPTIONS.NO;
                    return config.getTranslationText('4981');
                }
            }
        }
    }

    function canEvaluateCDLRequiredUS() {
        let combinationType = getCombinationType();
        let trailer1GVMImperial = getTrailer1GVMImperialRounded();
        let trailer2GVMImperial = getTrailer2GVMImperialRounded();
        let gvmImperial = getVehicleGVMImperialRounded();
        let gcmImperial = getVehicleCGMImperialRounded();
        let vehicleTotalGrossImperial = getVehicleTotalGrossImperialRounded();
        
        if(combinationType === config.COMBINATION_TYPES.VEHICLE) {
            if(gvmImperial > 0 && vehicleTotalGrossImperial > 0) {
                return true;
            } else {
                return false;
            }
        }else if(combinationType === config.COMBINATION_TYPES.VEHICLE_AND_DRAWBAR ||
            combinationType === config.COMBINATION_TYPES.VEHICLE_AND_PUP ||
            combinationType === config.COMBINATION_TYPES.VEHICLE_AND_SEMI) {
            if(gcmImperial > 0 && (gvmImperial > 0 && trailer1GVMImperial > 0)) {
                return true;
            }else {
                return false;
            }
        }else if(combinationType === config.COMBINATION_TYPES.VEHICLE_AND_INTERLINK_AND_SEMI) {
            if(gcmImperial > 0 && (gvmImperial > 0 && trailer1GVMImperial > 0 && trailer2GVMImperial > 0)) {
                return true;
            }else {
                return false;
            }
        }
    }

    function getParkPAWL() {
        let tempVehicle = activeRig.getVehicle();
        if(tempVehicle.getTransmissionParkPAWL() !== undefined && tempVehicle.getTransmissionParkPAWL() !== null && tempVehicle.getTransmissionParkPAWL() !== '') {
            return tempVehicle.getTransmissionParkPAWL();
        } else {
            return '?';
        }
    }

    function getEngineMaximumTorqueAsLbFt() {
        let engineMaxTorquelbft = activeRig.getVehicle().getEngineMaximumTorqueLbFt();
        let engineMaxTorqueNm = activeRig.getVehicle().getEngineMaximumTorqueNm();
        if(engineMaxTorquelbft > 0) {
            return engineMaxTorquelbft;
        } else if(engineMaxTorquelbft === 0 && engineMaxTorqueNm > 0) {
            return engineMaxTorqueNm * 0.737562;
        }
        
    }

    function getCombinationTotalGrossImperialRounded() {
        let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
        return parseFloat((getCombinationGrossTotal() * imperialUSMeasurementSystem.massConversion).toFixed(imperialUSMeasurementSystem.massPrecision));
    }

    function getCombinationGVMOrGCMImperialRounded() {
        
        if(getCombinationType() === config.COMBINATION_TYPES.VEHICLE) {
            return getVehicleGVMImperialRounded();
        }
        return getVehicleCGMImperialRounded();
    }

    function getVehicleCGMImperialRounded() {
        let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
        return parseFloat((getGrossCombinationMass() * imperialUSMeasurementSystem.massConversion).toFixed(imperialUSMeasurementSystem.massPrecision));
    }

    function getVehicleGVMImperialRounded() {
        let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
        return parseFloat((getGrossVehicleMass() * imperialUSMeasurementSystem.massConversion).toFixed(imperialUSMeasurementSystem.massPrecision));
    }

    function getTrailer1GVMImperialRounded() {
        let tempTrailer1 = activeRig.getTrailer1();
        let tempTrailer1GVM = tempTrailer1 !== null ? getGrossVehicleMassTrailer1() : 0;

        if(tempTrailer1GVM !== 0) {
            let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
            return parseFloat((tempTrailer1GVM * imperialUSMeasurementSystem.massConversion).toFixed(imperialUSMeasurementSystem.massPrecision));
        }
        return 0;
    }

    function getTrailer2GVMImperialRounded() {
        let tempTrailer2 = activeRig.getTrailer2();
        let tempTrailer2GVM = tempTrailer2 !== null ? getGrossVehicleMassTrailer2() : 0;

        if(tempTrailer2GVM !== 0) {
            let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
            return parseFloat((tempTrailer2GVM * imperialUSMeasurementSystem.massConversion).toFixed(imperialUSMeasurementSystem.massPrecision));
        }
        return 0;
    }

    function getVehicleTotalGrossImperialRounded() {
        let tempVehicle = activeRig.getVehicle();

        let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
        return parseFloat((tempVehicle.getGrossTotal() * imperialUSMeasurementSystem.massConversion).toFixed(imperialUSMeasurementSystem.massPrecision));
    }

    function getTrailer1TotalGrossImperialRounded() {
        let tempTrailer1 = activeRig.getTrailer1();
        let tempTrailer1GrossTotal = tempTrailer1 !== null ? tempTrailer1.getGrossTotal() : 0;

        if(tempTrailer1GrossTotal !== 0) {
            let imperialUSMeasurementSystem = globals.getMeasurementSystemUsingType(config.MEASUREMENT_SYSTEM.IMPERIALUS);
            return parseFloat((tempTrailer1GrossTotal * imperialUSMeasurementSystem.massConversion).toFixed(imperialUSMeasurementSystem.massPrecision));
        }
        return 0;
    }

    
    
    self.setup = setup;
    self.cleanUp = cleanUp;

    self.getOverallLengthLegal = getOverallLengthLegal;
    self.getVehicleOnlyFrontTare = getVehicleOnlyFrontTare;
    self.getVehicleOnlyRearTare = getVehicleOnlyRearTare;
    self.getVehicleHorizontalCOG = getVehicleHorizontalCOG;
    self.getVehicleVerticalCOG = getVehicleVerticalCOG;
    self.getOverallHorizontalCOG = getOverallHorizontalCOG;
    self.getOverallVerticalCOG = getOverallVerticalCOG;
    self.getVehicleRearPermissible = getVehicleRearPermissible;
    self.getVehicleFrontPermissible = getVehicleFrontPermissible;
    self.getVehicleTotalPermissible = getVehicleTotalPermissible;
    self.getVehicleGroup1BridgeFormulaDistance = getVehicleGroup1BridgeFormulaDistance;
    self.getVehicleGroup1BridgeFormulaDistanceNearest = getVehicleGroup1BridgeFormulaDistanceNearest;
    self.getVehicleFrontAxleOverload = getVehicleFrontAxleOverload;
    self.getVehicleRearAxleOverload = getVehicleRearAxleOverload;
    self.getVehicleTotalOverload = getVehicleTotalOverload;
    self.getPusherAxleOverload = getPusherAxleOverload;
    self.getTagAxleOverload = getTagAxleOverload;
    self.getRearRearAxlesOverload = getRearRearAxlesOverload;
    self.getCombinationVehicleFrontGross = getCombinationVehicleFrontGross;
    self.getCombinationVehicleRearGross = getCombinationVehicleRearGross;

    self.getVehicleCrewFirstRowHorizontalCOG = getVehicleCrewFirstRowHorizontalCOG;
    self.getVehicleCrewFirstRowVerticalCOG = getVehicleCrewFirstRowVerticalCOG;
    self.getVehicleCrewSecondRowHorizontalCOG = getVehicleCrewSecondRowHorizontalCOG;
    self.getVehicleCrewSecondRowVerticalCOG = getVehicleCrewSecondRowVerticalCOG;
    self.getCombinationTotalPermissible = getCombinationTotalPermissible;
    self.getMaximisedLoad = getMaximisedLoad;
            
    self.getTrailerTareFront = getTrailerTareFront;
    self.getTrailerTareRear = getTrailerTareRear;
    self.getTrailerPermissibleRear = getTrailerPermissibleRear;

    self.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle = getVehicleAndSemiOrInterlinkTareTransferOntoVehicleRearAxle;
    self.getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle = getVehicleAndSemiOrInterlinkTareTransferOntoVehicleFrontAxle;
    self.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle = getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleFrontAxle;
    self.getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle = getVehicleAndSemiOrInterlinkPayloadTransferOntoVehicleRearAxle;
    self.getCombinationType = getCombinationType;
    self.getTrailer1RearGross = getTrailer1RearGross;
    self.getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload = getVehicleAndSemiOrInterlinkVehicleFrontAxleOverload;
    self.getVehicleAndSemiOrInterlinkVehicleRearAxleOverload = getVehicleAndSemiOrInterlinkVehicleRearAxleOverload;
    self.getVehicleAndSemiOrInterlinkTrailerRearAxleOverload = getVehicleAndSemiOrInterlinkTrailerRearAxleOverload;
    self.getVehicleAndSemiOrInterlinkTotalOverload = getVehicleAndSemiOrInterlinkTotalOverload;
    self.getVehicleAndSemiOrInterlinkTotalPermissible = getVehicleAndSemiOrInterlinkTotalPermissible;
    self.getVehicleAndSemiOrInterlinkTotalGross = getVehicleAndSemiOrInterlinkTotalGross;
    self.getVehicleAndSemiOrInterlinkVehicleRearAxleGross = getVehicleAndSemiOrInterlinkVehicleRearAxleGross;
    self.getVehicleAndSemiOrInterlinkTrailerRearAxleGross = getVehicleAndSemiOrInterlinkTrailerRearAxleGross;
    self.getVehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer = getVehicleRearAxleGrossIncludingTrailer1InterlinkSemiTransfer;
    self.getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistanceNearest = getVehicleAndSemiOrInterlinkGroup2BridgeFormulaDistanceNearest;
    self.getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest = getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistanceNearest;
    self.getVehicleAndSemiOrInterlinkGapBetweenVehicleAndTrailer = getVehicleAndSemiOrInterlinkGapBetweenVehicleAndTrailer;
    self.getWheelbaseChangeIncrement = getWheelbaseChangeIncrement;
    self.getTrailer1RearOverhangTotal = getTrailer1RearOverhangTotal;
    self.getTrailerRearOverhangTotal = getTrailerRearOverhangTotal;
    self.getOverallLengthLegalStart = getOverallLengthLegalStart;
    self.getOverallHeight = getOverallHeight;
    self.getOverallVehicleLengthLegal = getOverallVehicleLengthLegal;
    self.getOverallChassisObjectLengthLegal = getOverallChassisObjectLengthLegal;
    self.getChassisObjectOverallHeight = getChassisObjectOverallHeight;
    self.getBodyFromPath = getBodyFromPath;
    self.getChassisObjectFromPath = getChassisObjectFromPath;
    self.getOriginalChassisObjectFromPath = getOriginalChassisObjectFromPath;
    self.getPayloadHolderFromPath = getPayloadHolderFromPath;
    self.getCraneFromPath = getCraneFromPath;
    self.getOtherFromPathById = getOtherFromPathById;
    self.getHookliftFromPath = getHookliftFromPath;
    self.getFridgeFromPath = getFridgeFromPath;
    self.getTailliftFromPath = getTailliftFromPath;
    self.getAxlesFromPath = getAxlesFromPath;
    self.getChassisTypeUsingPath = getChassisTypeUsingPath;
    self.getAccessoryHolderFromPath = getAccessoryHolderFromPath;

    self.getAxleHolderFromPath = getAxleHolderFromPath;
    self.getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer = getVehicleAndSemiOrInterlinkSwingClearanceBetweenVehicleAndTrailer;
    self.getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer = getVehicleAndSemiOrInterlinkProtrusionSwingClearanceBetweenVehicleAndTrailer;

    //self.getTrailer1RearPermissible = getTrailer1RearPermissible;
    self.getTrailer1HorizontalCOG = getTrailer1HorizontalCOG;
    self.getTrailerHorizontalCOG = getTrailerHorizontalCOG;

    self.getFrontalArea = getFrontalArea;
    self.getTrailer1FrontOverhangStart = getTrailer1FrontOverhangStart;
    self.getTrailer2FrontOverhangStart = getTrailer2FrontOverhangStart;
    self.getTrailerFrontOverhangStart = getTrailerFrontOverhangStart;
    self.getFifthWheelOffset = getFifthWheelOffset;

    //2018.1
    self.getTurningCircleValues = getTurningCircleValues;
    self.isSwingClearanceWarning = isSwingClearanceWarning;
    self.getVehicleWheelbaseTurningChanged = getVehicleWheelbaseTurningChanged;
    self.updateTurningCircleCalculatedVals = updateTurningCircleCalculatedVals;
    self.getVehicleSteeringAngleInner = getVehicleSteeringAngleInner;
    self.getVehicleTurningRadiusCurbToCurb = getVehicleTurningRadiusCurbToCurb;
    self.getVehicleTurningRadiusWallToWall = getVehicleTurningRadiusWallToWall;
    self.getDefaultSteeringAttribute = getDefaultSteeringAttribute;
    self.getVehicleWheelbaseTurningChanged = getVehicleWheelbaseTurningChanged;

    //2018.3
    self.getPayloadFromPathById = getPayloadFromPathById;

    //2018.4
    self.getTrailerPermissibleFront = getTrailerPermissibleFront;
    self.getMaximisedPayloadForChassis = getMaximisedPayloadForChassis;
    self.maximisePayloads = maximisePayloads;
    self.getVehicleAndDrawbarTotalPermissible = getVehicleAndDrawbarTotalPermissible;
    self.getVehicleAndDrawbarTotalGross = getVehicleAndDrawbarTotalGross;
    self.getVehicleAndDrawbarGroup3BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup3BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarTrailerRearAxleOverload = getVehicleAndDrawbarTrailerRearAxleOverload;
    self.getVehicleAndDrawbarTrailerFrontAxleOverload = getVehicleAndDrawbarTrailerFrontAxleOverload;
    self.getTrailer1FrontGross = getTrailer1FrontGross;
    self.getTrailer1PermissibleFront = getTrailer1PermissibleFront;
    self.getVehicleAndDrawbarTotalOverload = getVehicleAndDrawbarTotalOverload;
    self.getVehicleAndDrawbarGapBetweenVehicleAndTrailer = getVehicleAndDrawbarGapBetweenVehicleAndTrailer;
    self.getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer = getVehicleAndDrawbarSwingClearanceBetweenVehicleAndTrailer;
    self.getAccessoryTypeFromPath = getAccessoryTypeFromPath;

    //2018.6
    self.getBridgeGroupLabel = getBridgeGroupLabel;
    self.getVehicleGroup1BridgeFormulaPermissible = getVehicleGroup1BridgeFormulaPermissible;
    self.getVehicleGroup1BridgeFormulaActual = getVehicleGroup1BridgeFormulaActual;
    self.getVehicleGroup1BridgeFormulaOverload = getVehicleGroup1BridgeFormulaOverload;
    self.getVehicleAndDrawbarGroup2BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup2BridgeFormulaDistanceNearest;
    self.getGroup1BridgeFormulaPermissible = getGroup1BridgeFormulaPermissible;
    self.getGroup1BridgeFormulaDistanceNearest = getGroup1BridgeFormulaDistanceNearest;
    self.getGroup1BridgeFormulaDistance = getGroup1BridgeFormulaDistance;
    self.getGroup1BridgeFormulaActual = getGroup1BridgeFormulaActual;
    self.getGroup1BridgeFormulaOverload = getGroup1BridgeFormulaOverload;
    self.getGroup2BridgeFormulaPermissible = getGroup2BridgeFormulaPermissible;
    self.getGroup2BridgeFormulaDistanceNearest = getGroup2BridgeFormulaDistanceNearest;
    self.getGroup2BridgeFormulaDistance = getGroup2BridgeFormulaDistance;
    self.getGroup2BridgeFormulaActual = getGroup2BridgeFormulaActual;
    self.getGroup2BridgeFormulaOverload = getGroup2BridgeFormulaOverload;
    self.getGroup3BridgeFormulaPermissible = getGroup3BridgeFormulaPermissible;
    self.getGroup3BridgeFormulaDistanceNearest = getGroup3BridgeFormulaDistanceNearest;
    self.getGroup3BridgeFormulaDistance = getGroup3BridgeFormulaDistance;
    self.getGroup3BridgeFormulaActual = getGroup3BridgeFormulaActual;
    self.getGroup3BridgeFormulaOverload = getGroup3BridgeFormulaOverload;
    self.getGroup4BridgeFormulaPermissible = getGroup4BridgeFormulaPermissible;
    self.getGroup4BridgeFormulaDistanceNearest = getGroup4BridgeFormulaDistanceNearest;
    self.getGroup4BridgeFormulaDistance = getGroup4BridgeFormulaDistance;
    self.getGroup4BridgeFormulaActual = getGroup4BridgeFormulaActual;
    self.getGroup4BridgeFormulaOverload = getGroup4BridgeFormulaOverload;
    self.getGroup5BridgeFormulaPermissible = getGroup5BridgeFormulaPermissible;
    self.getGroup5BridgeFormulaDistanceNearest = getGroup5BridgeFormulaDistanceNearest;
    self.getGroup5BridgeFormulaDistance = getGroup5BridgeFormulaDistance;
    self.getGroup5BridgeFormulaActual = getGroup5BridgeFormulaActual;
    self.getGroup5BridgeFormulaOverload = getGroup5BridgeFormulaOverload;
    self.getGroup6BridgeFormulaPermissible = getGroup6BridgeFormulaPermissible;
    self.getGroup6BridgeFormulaDistanceNearest = getGroup6BridgeFormulaDistanceNearest;
    self.getGroup6BridgeFormulaDistance = getGroup6BridgeFormulaDistance;
    self.getGroup6BridgeFormulaActual = getGroup6BridgeFormulaActual;
    self.getGroup6BridgeFormulaOverload = getGroup6BridgeFormulaOverload;
    self.getGroup7BridgeFormulaPermissible = getGroup7BridgeFormulaPermissible;
    self.getGroup7BridgeFormulaDistanceNearest = getGroup7BridgeFormulaDistanceNearest;
    self.getGroup7BridgeFormulaDistance = getGroup7BridgeFormulaDistance;
    self.getGroup7BridgeFormulaActual = getGroup7BridgeFormulaActual;
    self.getGroup7BridgeFormulaOverload = getGroup7BridgeFormulaOverload;
    self.getGroup8BridgeFormulaPermissible = getGroup8BridgeFormulaPermissible;
    self.getGroup8BridgeFormulaDistanceNearest = getGroup8BridgeFormulaDistanceNearest;
    self.getGroup8BridgeFormulaDistance = getGroup8BridgeFormulaDistance;
    self.getGroup8BridgeFormulaActual = getGroup8BridgeFormulaActual;
    self.getGroup8BridgeFormulaOverload = getGroup8BridgeFormulaOverload;
    self.getGroup9BridgeFormulaPermissible = getGroup9BridgeFormulaPermissible;
    self.getGroup9BridgeFormulaDistanceNearest = getGroup9BridgeFormulaDistanceNearest;
    self.getGroup9BridgeFormulaDistance = getGroup9BridgeFormulaDistance;
    self.getGroup9BridgeFormulaActual = getGroup9BridgeFormulaActual;
    self.getGroup9BridgeFormulaOverload = getGroup9BridgeFormulaOverload;
    self.getGroup10BridgeFormulaPermissible = getGroup10BridgeFormulaPermissible;
    self.getGroup10BridgeFormulaDistanceNearest = getGroup10BridgeFormulaDistanceNearest;
    self.getGroup10BridgeFormulaDistance = getGroup10BridgeFormulaDistance;
    self.getGroup10BridgeFormulaActual = getGroup10BridgeFormulaActual;
    self.getGroup10BridgeFormulaOverload = getGroup10BridgeFormulaOverload;
    self.getGroup11BridgeFormulaPermissible = getGroup11BridgeFormulaPermissible;
    self.getGroup11BridgeFormulaDistanceNearest = getGroup11BridgeFormulaDistanceNearest;
    self.getGroup11BridgeFormulaDistance = getGroup11BridgeFormulaDistance;
    self.getGroup11BridgeFormulaActual = getGroup11BridgeFormulaActual;
    self.getGroup11BridgeFormulaOverload = getGroup11BridgeFormulaOverload;
    self.getGroup12BridgeFormulaPermissible = getGroup12BridgeFormulaPermissible;
    self.getGroup12BridgeFormulaDistanceNearest = getGroup12BridgeFormulaDistanceNearest;
    self.getGroup12BridgeFormulaDistance = getGroup12BridgeFormulaDistance;
    self.getGroup12BridgeFormulaActual = getGroup12BridgeFormulaActual;
    self.getGroup12BridgeFormulaOverload = getGroup12BridgeFormulaOverload;
    self.getGroup13BridgeFormulaPermissible = getGroup13BridgeFormulaPermissible;
    self.getGroup13BridgeFormulaDistanceNearest = getGroup13BridgeFormulaDistanceNearest;
    self.getGroup13BridgeFormulaDistance = getGroup13BridgeFormulaDistance;
    self.getGroup13BridgeFormulaActual = getGroup13BridgeFormulaActual;
    self.getGroup13BridgeFormulaOverload = getGroup13BridgeFormulaOverload;
    self.getVehicleAndSemiOrInterlinkGroup1BridgeFormulaDistanceNearest = getVehicleAndSemiOrInterlinkGroup1BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup1BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup1BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup2BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup2BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup3BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup3BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup4BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup4BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup5BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup5BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup6BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup6BridgeFormulaDistanceNearest;
    self.getGroupOverloadColour = getGroupOverloadColour;
    self.hasMissingTareWeigthsForBridgeFormula = hasMissingTareWeigthsForBridgeFormula;
    self.getCurrentCombinationBridgeFormulaUsed = getCurrentCombinationBridgeFormulaUsed;
    self.getIsSetup = getIsSetup;

    //2018.7
    self.getAvailableAxles = getAvailableAxles;
    self.addAxle = addAxle;
    self.removeAxle = removeAxle;
    self.getObjectTypeFromPath = getObjectTypeFromPath;
    self.getAxleFromPath = getAxleFromPath;
    self.getOriginalAxleHolderFromPath = getOriginalAxleHolderFromPath;
    self.getAxleLocationFromAxle = getAxleLocationFromAxle;
    self.getAxleLocationFromAxlePath = getAxleLocationFromAxlePath;
    self.distributeGivenMassOverGivenAttributeOnAxleGroup = distributeGivenMassOverGivenAttributeOnAxleGroup;
    self.resetAxleValues = resetAxleValues;
    self.distributeAxleGroupWeightOverGroupAxles = distributeAxleGroupWeightOverGroupAxles;
    self.getAxlePositionFromPath = getAxlePositionFromPath;
    self.getNeighbouringAxleFromAxlePosition = getNeighbouringAxleFromAxlePosition;
    self.getBreakpointAdjustment = getBreakpointAdjustment;

    //2018.8
    self.getVehicleAndPupGroup2BridgeFormulaDistanceNearest = getVehicleAndPupGroup2BridgeFormulaDistanceNearest;
    self.getVehicleAndPupGroup2BridgeFormulaDistance = getVehicleAndPupGroup2BridgeFormulaDistance;
    self.getVehicleAndPupTotalGross = getVehicleAndPupTotalGross;
    self.getVehicleAndPupVehicleRearAxleGross = getVehicleAndPupVehicleRearAxleGross;
    self.getVehicleAndPupGroup1BridgeFormulaDistanceNearest = getVehicleAndPupGroup1BridgeFormulaDistanceNearest;
    self.getVehicleAndPupGroup1BridgeFormulaDistance = getVehicleAndPupGroup1BridgeFormulaDistance;
    self.getVehicleAndPupTrailerRearOverhangTotal = getVehicleAndPupTrailerRearOverhangTotal;
    self.getVehicleAndPupGroup3BridgeFormulaDistanceNearest = getVehicleAndPupGroup3BridgeFormulaDistanceNearest;
    self.getVehicleAndPupGroup3BridgeFormulaDistance = getVehicleAndPupGroup3BridgeFormulaDistance;
    self.getVehicleAndPupTareTransferOntoVehicleFrontAxle = getVehicleAndPupTareTransferOntoVehicleFrontAxle;
    self.getVehicleAndPupPayloadTransferOntoVehicleFrontAxle = getVehicleAndPupPayloadTransferOntoVehicleFrontAxle;
    self.getVehicleFrontAxleGrossIncludingTrailer1PupTransfer = getVehicleFrontAxleGrossIncludingTrailer1PupTransfer;
    self.getVehicleRearAxleGrossIncludingTrailer1PupTransfer = getVehicleRearAxleGrossIncludingTrailer1PupTransfer;
    self.getVehicleAndPupTareTransferOntoVehicleRearAxle = getVehicleAndPupTareTransferOntoVehicleRearAxle;
    self.getVehicleAndPupPayloadTransferOntoVehicleRearAxle = getVehicleAndPupPayloadTransferOntoVehicleRearAxle;
    self.getVehicleAndPupTotalPermissible = getVehicleAndPupTotalPermissible;
    self.getVehicleAndPupTotalOverload = getVehicleAndPupTotalOverload;
    self.getVehicleAndPupVehicleFrontAxleOverload = getVehicleAndPupVehicleFrontAxleOverload;
    self.getVehicleAndPupVehicleRearAxleOverload = getVehicleAndPupVehicleRearAxleOverload;
    self.getVehicleAndPupTrailerRearAxleOverload = getVehicleAndPupTrailerRearAxleOverload;
    self.getTrailerHitchPointFromFrontOfRig = getTrailerHitchPointFromFrontOfRig;
    self.getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer = getVehicleAndPupSwingClearanceBetweenVehicleAndTrailer;
    self.getHitchLoad = getHitchLoad;

    //2018.9
    self.getActiveOffer = getActiveOffer;
    self.getOriginalOffer = getOriginalOffer;
    self.getBaseOffer = getBaseOffer;
    self.getActiveRig = getActiveRig;
    self.getOriginalRig = getOriginalRig;
    self.getIsBodyMissing = getIsBodyMissing;

    //2018.11
    self.getVehicleWheelbaseSpecified = getVehicleWheelbaseSpecified;
    self.getWheelbaseSpecified = getWheelbaseSpecified;
    self.getOverallWidth = getOverallWidth;
    self.getVehicleLateralCOG = getVehicleLateralCOG;
    self.getVehicleCrewFirstRowLateralCOG = getVehicleCrewFirstRowLateralCOG;
    self.getVehicleCrewSecondRowLateralCOG = getVehicleCrewSecondRowLateralCOG;
    self.getChassisRearWidth = getChassisRearWidth;
    self.getTopViewCentreLine = getTopViewCentreLine;
    self.chassisHasBody = chassisHasBody;
    self.getLeftOfChassisRearWidth = getLeftOfChassisRearWidth;
    self.getLeftOfBodyWidthExternal = getLeftOfBodyWidthExternal;
    self.getPortionOfOverallWidthOverhangingLeft = getPortionOfOverallWidthOverhangingLeft;
    self.getLeftOfChassisToPayloadDeckStart = getLeftOfChassisToPayloadDeckStart;
    self.getBodySidewallLeft = getBodySidewallLeft;
    self.getLeftOfChassisRearWidthForTurningCircle = getLeftOfChassisRearWidthForTurningCircle;
    self.getLeftOfBodyWidthExternalForTurningCircle = getLeftOfBodyWidthExternalForTurningCircle;
    self.getBodySidewallLeftForTurningCircle = getBodySidewallLeftForTurningCircle;
    self.getTopViewCentreLineForTurningCircle = getTopViewCentreLineForTurningCircle;
    self.getAccessoryFromPath = getAccessoryFromPath;

    //2019.1
    self.getOverallLateralCOG = getOverallLateralCOG;
    self.getLeftOfVehicleCabWidth = getLeftOfVehicleCabWidth;
    self.getDistanceFromWheelbaseTheoreticalStartForTrailer1 = getDistanceFromWheelbaseTheoreticalStartForTrailer1;
    self.getDistanceFromWheelbaseTheoreticalEndForTrailer1 = getDistanceFromWheelbaseTheoreticalEndForTrailer1;
    self.getDistanceFromWheelbaseTheoreticalStartForTrailer = getDistanceFromWheelbaseTheoreticalStartForTrailer;
    self.getDistanceFromWheelbaseTheoreticalEndForTrailer = getDistanceFromWheelbaseTheoreticalEndForTrailer;
    self.getTrailerVerticalCOG = getTrailerVerticalCOG;
    self.getTrailerLateralCOG = getTrailerLateralCOG;
    self.getTrailer1OverallHorizontalCOG = getTrailer1OverallHorizontalCOG;
    self.getTrailer1OverallVerticalCOG = getTrailer1OverallVerticalCOG;
    self.getTrailer1OverallLateralCOG = getTrailer1OverallLateralCOG;
    self.getVehicleStaticRolloverAngle = getVehicleStaticRolloverAngle;
    self.getTrailer1StaticRolloverAngle = getTrailer1StaticRolloverAngle;
    self.getTrailer2StaticRolloverAngle = getTrailer2StaticRolloverAngle;
    self.getStaticRolloverAngleByPath = getStaticRolloverAngleByPath;
    self.canCalculateVehicleStaticRolloverAngle = canCalculateVehicleStaticRolloverAngle;
    self.canCalculateTrailer1StaticRolloverAngle = canCalculateTrailer1StaticRolloverAngle;
    //self.getManufacturerWeightsComplianceFailed = getManufacturerWeightsComplianceFailed;
    self.getAnyOverloadedAxlesPermissibleVehicle = getAnyOverloadedAxlesPermissibleVehicle;
    self.getPermissibleWeightsComplianceFailed = getPermissibleWeightsComplianceFailed;
    self.getLeftOfVehicleWidestWidth = getLeftOfVehicleWidestWidth;

    //2019.2
    self.getAddedAxlesSpread = getAddedAxlesSpread;
    self.getIsAddedRearSection = getIsAddedRearSection;
    self.getCOGFrom = getCOGFrom;
    self.getAxleWeightFront = getAxleWeightFront;
    self.getAxleWeightRear = getAxleWeightRear;
    self.getAxleWeightTotal = getAxleWeightTotal;
    self.getAxleWeightTransferFront = getAxleWeightTransferFront;
    self.getAxleWeightTransferRear = getAxleWeightTransferRear;
    self.getAxleWeightTransferTotal = getAxleWeightTransferTotal;
    self.getGrossAxleWeightFront = getGrossAxleWeightFront;
    self.getGrossAxleWeightRear = getGrossAxleWeightRear;
    self.getGrossAxleWeightTotal = getGrossAxleWeightTotal;
    self.getChassisHeightFromPath = getChassisHeightFromPath;
    self.getKingpinLoad = getKingpinLoad;
    self.getCOGFromForTransferItems = getCOGFromForTransferItems;
    self.getManufacturerWeightsAxlesOverloaded = getManufacturerWeightsAxlesOverloaded;
    self.getManufacturerWeightsGrossCombinationOverloaded = getManufacturerWeightsGrossCombinationOverloaded;
    self.getOffsetToBodyRefPointForChassis = getOffsetToBodyRefPointForChassis;

    //2019.3
    self.getFifthWheelHeight = getFifthWheelHeight;
    self.getFifthWheelHeightFromGround = getFifthWheelHeightFromGround;
    self.getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront = getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFront;
    self.getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront = getItemStartFromFrontOfRigInclPortionOfVehicleOverhangingFront;
    self.getFrontOfChassisInclPortionOfVehicleOverhangingFront = getFrontOfChassisInclPortionOfVehicleOverhangingFront;
    self.getPayloadDeckStartPositionFrontFrontInclPortionOfVehicleOverhangingFront = getPayloadDeckStartPositionFrontFrontInclPortionOfVehicleOverhangingFront;
    self.getPayloadDeckStartPositionFromBottom = getPayloadDeckStartPositionFromBottom;
    self.getPayloadDeckStartPositionFromLeftInclPortionOfChassisOverhangingLeft = getPayloadDeckStartPositionFromLeftInclPortionOfChassisOverhangingLeft;
    self.getPayloadDeckStartPositionFromLeftForTurningCircle = getPayloadDeckStartPositionFromLeftForTurningCircle;
    self.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle = getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleFrontAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle = getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1FrontAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle = getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoVehicleRearAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle = getVehicleAndInterlinkAndSemiTrailer2TareTransferOntoTrailer1RearAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle = getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleFrontAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle = getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1FrontAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle = getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoVehicleRearAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle = getVehicleAndInterlinkAndSemiTrailer2PayloadTransferOntoTrailer1RearAxle;
    self.getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload = getVehicleAndInterlinkAndSemiTrailer2RearAxleOverload;
    self.getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload = getVehicleAndInterlinkAndSemiTrailer1RearAxleOverload;
    self.getVehicleAndInterlinkAndSemiTotalGross = getVehicleAndInterlinkAndSemiTotalGross;
    self.getTrailer2RearGross = getTrailer2RearGross;
    self.getVehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer = getVehicleRearAxleGrossIncludingTrailer1InterlinkAndTrailer2SemiTransfer;
    self.getTrailer1PermissibleRear = getTrailer1PermissibleRear;
    self.getTrailer2PermissibleRear = getTrailer2PermissibleRear;
    self.getVehicleAndInterlinkAndSemiVehicleRearAxleGross = getVehicleAndInterlinkAndSemiVehicleRearAxleGross;
    self.getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup1BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup2BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup4BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup6BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance = getVehicleAndInterlinkAndSemiGroup3BridgeFormulaDistance;
    self.getVehicleAndInterlinkAndSemiVehicleRearAxleOverload = getVehicleAndInterlinkAndSemiVehicleRearAxleOverload;
    self.getVehicleAndInterlinkAndSemiTotalOverload = getVehicleAndInterlinkAndSemiTotalOverload;
    self.getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload = getVehicleAndInterlinkAndSemiVehicleFrontAxleOverload;
    self.getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2 = getVehicleAndInterlinkAndSemiGapBetweenTrailer1BodyEndAndTrailer2;
    self.getTrailer2RearOverhangTotal = getTrailer2RearOverhangTotal;
    self.getVehicleAndInterlinkAndSemiVehicleFrontAxleGross = getVehicleAndInterlinkAndSemiVehicleFrontAxleGross;
    self.getVehicleAndInterlinkAndSemiSwingClearanceBetweenVehicleAndTrailer1 = getVehicleAndInterlinkAndSemiSwingClearanceBetweenVehicleAndTrailer1;
    self.getVehicleAndInterlinkAndSemiProtrusionSwingClearanceBetweenVehicleAndTrailer1 = getVehicleAndInterlinkAndSemiProtrusionSwingClearanceBetweenVehicleAndTrailer1;
    self.getVehicleAndInterlinkAndSemiSwingClearanceBetweenTrailer1AndTrailer2 = getVehicleAndInterlinkAndSemiSwingClearanceBetweenTrailer1AndTrailer2;
    self.canCalculateTrailer2StaticRolloverAngle = canCalculateTrailer2StaticRolloverAngle;
    self.getVehicleManufacturerRatingFrontAxlesExceeded = getVehicleManufacturerRatingFrontAxlesExceeded;
    self.getVehicleManufacturerRatingRearAxlesExceeded = getVehicleManufacturerRatingRearAxlesExceeded;
    self.getManufacturerWeightsTrailer1RearAxlesExceeded = getManufacturerWeightsTrailer1RearAxlesExceeded;
    self.getManufacturerWeightsTrailer2RearAxlesExceeded = getManufacturerWeightsTrailer2RearAxlesExceeded;
    self.getVehicleAndInterlinkAndSemiXDimension = getVehicleAndInterlinkAndSemiXDimension;
    self.getVehicleAndInterlinkAndSemiYDimension = getVehicleAndInterlinkAndSemiYDimension;
    self.getStartingPointVehicleAxle3Rear = getStartingPointVehicleAxle3Rear;
    self.getStartingPointTrailer1Axle4Rear = getStartingPointTrailer1Axle4Rear;
    self.getItemStartFromFrontOfRig = getItemStartFromFrontOfRig;
    self.getItemStartFromGround = getItemStartFromGround;
    self.getItemStartFromLeftOfChassis = getItemStartFromLeftOfChassis;
    self.getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront = getItemDrawingStartFromFrontOfRigInclPortionOfVehicleOverhangingFront;
    self.getPayloadDeckStartPositionFrontFront = getPayloadDeckStartPositionFrontFront;
    self.getTrailer2OverallHorizontalCOG = getTrailer2OverallHorizontalCOG;
    self.getTrailer2OverallVerticalCOG = getTrailer2OverallVerticalCOG;
    self.getTrailer2OverallLateralCOG = getTrailer2OverallLateralCOG;
    self.getTrailer1ArticulationPointToRearOfBDouble = getTrailer1ArticulationPointToRearOfBDouble;
    
    //2019.4
    self.getLesserOfAxleManufacturerRatings = getLesserOfAxleManufacturerRatings;

    //2019.5
    self.getCabToAxles = getCabToAxles;
    self.getCabProtrusion = getCabProtrusion;
    self.getAxle1FrontToStartOfBody = getAxle1FrontToStartOfBody;
    self.getBodyStartToWheelbaseSpecifiedEnd = getBodyStartToWheelbaseSpecifiedEnd;
    self.adjustRearMostProtrudingItemForWheelbaseSecifiedAsOptionFromDefaultWheelbase = adjustRearMostProtrudingItemForWheelbaseSecifiedAsOptionFromDefaultWheelbase;
    self.adjustRearMostProtrudingItemWhenSwitchingWheelbaseSpecifiedAsOption = adjustRearMostProtrudingItemWhenSwitchingWheelbaseSpecifiedAsOption;
    self.getAppropriateAxle1FrontToAxle2FrontAddition = getAppropriateAxle1FrontToAxle2FrontAddition;
    self.getTrailerOverallHorizontalCOG = getTrailerOverallHorizontalCOG;
    self.getTrailerOverallVerticalCOG = getTrailerOverallVerticalCOG;
    self.getTrailerOverallLateralCOG = getTrailerOverallLateralCOG;
    self.getFrontOfChassis = getFrontOfChassis;

    //2019.6
    self.getVehicleBodyMaximumLength = getVehicleBodyMaximumLength;
    self.getVehicleWheelbaseMaximum = getVehicleWheelbaseMaximum;
    self.getVehicleWheelbaseMinimum = getVehicleWheelbaseMinimum;
    self.getVehicleWheelbaseStandard = getVehicleWheelbaseStandard;
    self.getVehicleWheelbaseOptimumAllowed = getVehicleWheelbaseOptimumAllowed;
    self.getWheelbaseManufacturerMinimum = getWheelbaseManufacturerMinimum;
    self.getWheelbaseManufacturerMaximum = getWheelbaseManufacturerMaximum;
    self.getOriginalWheelbaseSpecified = getOriginalWheelbaseSpecified;
    self.getSimplePayloadFromPath = getSimplePayloadFromPath;

    //2020.1
    self.getVehicleGroup1BridgeFormulaNumberOfAxles = getVehicleGroup1BridgeFormulaNumberOfAxles;
    self.getVehicleGroup2BridgeFormulaNumberOfAxles = getVehicleGroup2BridgeFormulaNumberOfAxles;
    self.getVehicleGroup3BridgeFormulaNumberOfAxles = getVehicleGroup3BridgeFormulaNumberOfAxles;
    self.getVehicleGroup4BridgeFormulaNumberOfAxles = getVehicleGroup4BridgeFormulaNumberOfAxles;
    self.getVehicleAndSemiOrInterlinkGroup1BridgeFormulaNumberOfAxles = getVehicleAndSemiOrInterlinkGroup1BridgeFormulaNumberOfAxles;
    self.getVehicleAndSemiOrInterlinkGroup2BridgeFormulaNumberOfAxles = getVehicleAndSemiOrInterlinkGroup2BridgeFormulaNumberOfAxles;
    self.getVehicleAndSemiOrInterlinkGroup3BridgeFormulaNumberOfAxles = getVehicleAndSemiOrInterlinkGroup3BridgeFormulaNumberOfAxles;
    self.getVehicleAndSemiOrInterlinkGroup4BridgeFormulaNumberOfAxles = getVehicleAndSemiOrInterlinkGroup4BridgeFormulaNumberOfAxles;
    self.getVehicleAndSemiOrInterlinkGroup5BridgeFormulaNumberOfAxles = getVehicleAndSemiOrInterlinkGroup5BridgeFormulaNumberOfAxles;
    self.getVehicleAndSemiOrInterlinkGroup6BridgeFormulaNumberOfAxles = getVehicleAndSemiOrInterlinkGroup6BridgeFormulaNumberOfAxles;
    self.getVehicleAndSemiOrInterlinkGroup7BridgeFormulaNumberOfAxles = getVehicleAndSemiOrInterlinkGroup7BridgeFormulaNumberOfAxles;
    self.getVehicleAndPupGroup1BridgeFormulaNumberOfAxles = getVehicleAndPupGroup1BridgeFormulaNumberOfAxles;
    self.getVehicleAndPupGroup2BridgeFormulaNumberOfAxles = getVehicleAndPupGroup2BridgeFormulaNumberOfAxles;
    self.getVehicleAndPupGroup3BridgeFormulaNumberOfAxles = getVehicleAndPupGroup3BridgeFormulaNumberOfAxles;
    self.getVehicleAndPupGroup4BridgeFormulaNumberOfAxles = getVehicleAndPupGroup4BridgeFormulaNumberOfAxles;
    self.getVehicleAndPupGroup5BridgeFormulaNumberOfAxles = getVehicleAndPupGroup5BridgeFormulaNumberOfAxles;
    self.getVehicleAndPupGroup6BridgeFormulaNumberOfAxles = getVehicleAndPupGroup6BridgeFormulaNumberOfAxles;
    self.getVehicleAndPupGroup7BridgeFormulaNumberOfAxles = getVehicleAndPupGroup7BridgeFormulaNumberOfAxles;
    self.getVehicleGroup2BridgeFormulaDistanceNearest = getVehicleGroup2BridgeFormulaDistanceNearest;
    self.getVehicleGroup3BridgeFormulaDistanceNearest = getVehicleGroup3BridgeFormulaDistanceNearest;
    self.getVehicleGroup4BridgeFormulaDistanceNearest = getVehicleGroup4BridgeFormulaDistanceNearest;
    
    self.getVehicleAndSemiOrInterlinkGroup4BridgeFormulaDistanceNearest = getVehicleAndSemiOrInterlinkGroup4BridgeFormulaDistanceNearest;
    self.getVehicleAndSemiOrInterlinkGroup5BridgeFormulaDistanceNearest = getVehicleAndSemiOrInterlinkGroup5BridgeFormulaDistanceNearest;
    self.getVehicleAndSemiOrInterlinkGroup6BridgeFormulaDistanceNearest = getVehicleAndSemiOrInterlinkGroup6BridgeFormulaDistanceNearest;
    self.getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistanceNearest = getVehicleAndSemiOrInterlinkGroup7BridgeFormulaDistanceNearest;

    self.getVehicleAndPupGroup4BridgeFormulaDistanceNearest = getVehicleAndPupGroup4BridgeFormulaDistanceNearest;
    self.getVehicleAndPupGroup5BridgeFormulaDistanceNearest = getVehicleAndPupGroup5BridgeFormulaDistanceNearest;
    self.getVehicleAndPupGroup6BridgeFormulaDistanceNearest = getVehicleAndPupGroup6BridgeFormulaDistanceNearest;
    self.getVehicleAndPupGroup7BridgeFormulaDistanceNearest = getVehicleAndPupGroup7BridgeFormulaDistanceNearest;
    self.getOriginalCOGTotalToOriginalWheelbaseTheoreticalStart = getOriginalCOGTotalToOriginalWheelbaseTheoreticalStart;
    self.getOriginalCOGTotalToNewWheelbaseTheoreticalStart = getOriginalCOGTotalToNewWheelbaseTheoreticalStart;
    self.getTareOriginalFrontAxles = getTareOriginalFrontAxles;
    self.getTareRevisedFrontAxles = getTareRevisedFrontAxles;
    self.getPusher1FrontAxles = getPusher1FrontAxles;
    self.getPusher2FrontAxles = getPusher2FrontAxles;
    self.getPusher3FrontAxles = getPusher3FrontAxles;
    self.getTag1FrontAxles = getTag1FrontAxles;
    self.getTag2FrontAxles = getTag2FrontAxles;
    self.getTag3FrontAxles = getTag3FrontAxles;
    self.getTareOriginalRearAxles = getTareOriginalRearAxles;
    self.getTareRevisedRearAxles = getTareRevisedRearAxles;
    self.getPusher1RearAxles = getPusher1RearAxles;
    self.getPusher2RearAxles = getPusher2RearAxles;
    self.getPusher3RearAxles = getPusher3RearAxles;
    self.getTag1RearAxles = getTag1RearAxles;
    self.getTag2RearAxles = getTag2RearAxles;
    self.getTag3RearAxles = getTag3RearAxles;
    self.getTareOriginalTotalAxles = getTareOriginalTotalAxles;
    self.getTareRevisedTotalAxles = getTareRevisedTotalAxles;
    self.getPusher1TotalAxles = getPusher1TotalAxles;
    self.getPusher2TotalAxles = getPusher2TotalAxles;
    self.getPusher3TotalAxles = getPusher3TotalAxles;
    self.getTag1TotalAxles = getTag1TotalAxles;
    self.getTag2TotalAxles = getTag2TotalAxles;
    self.getTag3TotalAxles = getTag3TotalAxles;
    self.getAxleGroupFromPath = getAxleGroupFromPath;
    self.generateBridgeFormulaPermissiblesOnAxles = generateBridgeFormulaPermissiblesOnAxles;
    self.generateBridgeFormulaPermissibleValues = generateBridgeFormulaPermissibleValues;
    self.getBridgeGroupsVisible = getBridgeGroupsVisible;
    self.distributeAxleWeightOverRearRearAxles = distributeAxleWeightOverRearRearAxles;
    self.getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible = getVehicleExtremeRearDrivenGroupBridgeFormulaPermissible;
    self.getVehiclePusher1GroupBridgeFormulaPermissible = getVehiclePusher1GroupBridgeFormulaPermissible;
    self.getVehiclePusher2GroupBridgeFormulaPermissible = getVehiclePusher2GroupBridgeFormulaPermissible;
    self.getVehiclePusher3GroupBridgeFormulaPermissible = getVehiclePusher3GroupBridgeFormulaPermissible;
    self.getVehicleTag1GroupBridgeFormulaPermissible = getVehicleTag1GroupBridgeFormulaPermissible;
    self.getVehicleTag2GroupBridgeFormulaPermissible = getVehicleTag2GroupBridgeFormulaPermissible;
    self.getVehicleTag3GroupBridgeFormulaPermissible = getVehicleTag3GroupBridgeFormulaPermissible;
    self.getDistributedTareFrontTotal = getDistributedTareFrontTotal;
    self.getDistributedTareRearTotal = getDistributedTareRearTotal;
    self.getDistributedTareTotalTotal = getDistributedTareTotalTotal;
    self.getPusherOrTagPermissibleVal = getPusherOrTagPermissibleVal;
    self.getRearRearAxlePermissibleVal = getRearRearAxlePermissibleVal;
    self.getVehicleAndDrawbarGroup1BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup1BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup2BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup2BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup3BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup3BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup4BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup4BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup5BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup5BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup6BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup6BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup1BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup1BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup2BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup2BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup3BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup3BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup4BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup4BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup5BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup5BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup6BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup6BridgeFormulaNumberOfAxles;
    self.getVehicleTotalBridgeOrRegulationVal = getVehicleTotalBridgeOrRegulationVal;
    self.getCombinationTotalBridgeOrRegulationVal = getCombinationTotalBridgeOrRegulationVal;
    self.getWheelbaseTheoreticalBeforeAnyAxlesAdded = getWheelbaseTheoreticalBeforeAnyAxlesAdded;
    self.getVehicleOnlyRearGross = getVehicleOnlyRearGross;
    self.getVehicleOnlyFrontGross = getVehicleOnlyFrontGross;
    self.getVehicleOnlyRearTare = getVehicleOnlyRearTare;
    self.getVehicleOnlyFrontTare = getVehicleOnlyFrontTare;
    self.getVehicleOnlyRearUnladen = getVehicleOnlyRearUnladen;
    self.getVehicleOnlyFrontUnladen = getVehicleOnlyFrontUnladen;
    self.getVehicleWheelbaseOptimum = getVehicleWheelbaseOptimum;
    self.getBodyOptimumLength = getBodyOptimumLength;
    self.hasUserOverriddenRegulationValue = hasUserOverriddenRegulationValue;
    self.getAxleFromPath = getAxleFromPath;

    //2020.2
    self.getCombinationExtremeAxlesInteractionTargetElementId = getCombinationExtremeAxlesInteractionTargetElementId;
    self.getVehicleExtremeAxlesInteractionTargetElementId = getVehicleExtremeAxlesInteractionTargetElementId;
    self.getVehicleExtremeFrontAxlesInteractionTargetElementId = getVehicleExtremeFrontAxlesInteractionTargetElementId;
    self.getVehicleExtremeRearAxlesInteractionTargetElementId = getVehicleExtremeRearAxlesInteractionTargetElementId;
    self.getVehicleExtremeRearDrivenAxlesInteractionTargetElementId = getVehicleExtremeRearDrivenAxlesInteractionTargetElementId;
    self.getVehiclePusher1AxleInteractionTargetElementId = getVehiclePusher1AxleInteractionTargetElementId;
    self.getVehiclePusher2AxleInteractionTargetElementId = getVehiclePusher2AxleInteractionTargetElementId;
    self.getVehiclePusher3AxleInteractionTargetElementId = getVehiclePusher3AxleInteractionTargetElementId;
    self.getVehicleTag1AxleInteractionTargetElementId = getVehicleTag1AxleInteractionTargetElementId;
    self.getVehicleTag2AxleInteractionTargetElementId = getVehicleTag2AxleInteractionTargetElementId;
    self.getVehicleTag3AxleInteractionTargetElementId = getVehicleTag3AxleInteractionTargetElementId;

    self.getCombinationExtremeAxlesInteractionTargetElementIdPermissible = getCombinationExtremeAxlesInteractionTargetElementIdPermissible;
    self.getVehicleExtremeAxlesInteractionTargetElementIdPermissible = getVehicleExtremeAxlesInteractionTargetElementIdPermissible;
    self.getVehicleExtremeFrontAxlesInteractionTargetElementIdPermissible = getVehicleExtremeFrontAxlesInteractionTargetElementIdPermissible;
    self.getVehicleExtremeRearAxlesInteractionTargetElementIdPermissible = getVehicleExtremeRearAxlesInteractionTargetElementIdPermissible;
    self.getVehicleExtremeRearDrivenAxlesInteractionTargetElementIdPermissible = getVehicleExtremeRearDrivenAxlesInteractionTargetElementIdPermissible;
    self.getVehiclePusher1AxleInteractionTargetElementIdPermissible = getVehiclePusher1AxleInteractionTargetElementIdPermissible;
    self.getVehiclePusher2AxleInteractionTargetElementIdPermissible = getVehiclePusher2AxleInteractionTargetElementIdPermissible;
    self.getVehiclePusher3AxleInteractionTargetElementIdPermissible = getVehiclePusher3AxleInteractionTargetElementIdPermissible;
    self.getVehicleTag1AxleInteractionTargetElementIdPermissible = getVehicleTag1AxleInteractionTargetElementIdPermissible;
    self.getVehicleTag2AxleInteractionTargetElementIdPermissible = getVehicleTag2AxleInteractionTargetElementIdPermissible;
    self.getVehicleTag3AxleInteractionTargetElementIdPermissible = getVehicleTag3AxleInteractionTargetElementIdPermissible;

    self.getBridgeGroupPermissibleHyperlinkElementId = getBridgeGroupPermissibleHyperlinkElementId;

    //2020.3
    self.getVehicleTareRearRearOnly = getVehicleTareRearRearOnly;

    //2020.4
    self.getHitchLoadAsPercentageOfTrailerGross = getHitchLoadAsPercentageOfTrailerGross;
    self.getVehicleExtremeGroupBridgeFormulaDistance = getVehicleExtremeGroupBridgeFormulaDistance;
    self.getCombinationExtremeGroupDistance = getCombinationExtremeGroupDistance;

    //2020.5
    self.getVehicleAndTrailer1GrossTotal = getVehicleAndTrailer1GrossTotal;
    self.getCombinationGrossTotal = getCombinationGrossTotal;
    self.getVehicleAndSemiOrInterlinkVehicleFrontAxleGross = getVehicleAndSemiOrInterlinkVehicleFrontAxleGross;
    self.getVehicleClassBasedOnRigConfiguration = getVehicleClassBasedOnRigConfiguration;
    self.getOriginalAccessoryHolderFromPath = getOriginalAccessoryHolderFromPath;
    self.getOriginalAccessoryFromPath = getOriginalAccessoryFromPath;

    //2021.1
    self.getVehicleAndDrawbarGroup7BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup7BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup8BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup8BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup9BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup9BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup10BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup10BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup11BridgeFormulaNumberOfAxles = getVehicleAndDrawbarGroup11BridgeFormulaNumberOfAxles;
    self.getVehicleAndDrawbarGroup7BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup7BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup8BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup8BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup9BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup9BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup10BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup10BridgeFormulaDistanceNearest;
    self.getVehicleAndDrawbarGroup11BridgeFormulaDistanceNearest = getVehicleAndDrawbarGroup11BridgeFormulaDistanceNearest;

    self.getVehicleAndInterlinkAndSemiGroup7BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup7BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup8BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup8BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup9BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup9BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup10BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup10BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup11BridgeFormulaNumberOfAxles = getVehicleAndInterlinkAndSemiGroup11BridgeFormulaNumberOfAxles;
    self.getVehicleAndInterlinkAndSemiGroup7BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup7BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup8BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup8BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup9BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup9BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup10BridgeFormulaDistanceNearest;
    self.getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistanceNearest = getVehicleAndInterlinkAndSemiGroup11BridgeFormulaDistanceNearest;

    self.getGroup14BridgeFormulaDistance = getGroup14BridgeFormulaDistance;
    self.getGroup15BridgeFormulaDistance = getGroup15BridgeFormulaDistance;
    self.getGroup16BridgeFormulaDistance = getGroup16BridgeFormulaDistance;
    self.getGroup17BridgeFormulaDistance = getGroup17BridgeFormulaDistance;
    self.getGroup14BridgeFormulaDistanceNearest = getGroup14BridgeFormulaDistanceNearest;
    self.getGroup15BridgeFormulaDistanceNearest = getGroup15BridgeFormulaDistanceNearest;
    self.getGroup16BridgeFormulaDistanceNearest = getGroup16BridgeFormulaDistanceNearest;
    self.getGroup17BridgeFormulaDistanceNearest = getGroup17BridgeFormulaDistanceNearest;

    self.getGroup14BridgeFormulaPermissible = getGroup14BridgeFormulaPermissible;
    self.getGroup15BridgeFormulaPermissible = getGroup15BridgeFormulaPermissible;
    self.getGroup16BridgeFormulaPermissible = getGroup16BridgeFormulaPermissible;
    self.getGroup17BridgeFormulaPermissible = getGroup17BridgeFormulaPermissible;

    self.getGroup14BridgeFormulaActual = getGroup14BridgeFormulaActual;
    self.getGroup15BridgeFormulaActual = getGroup15BridgeFormulaActual;
    self.getGroup16BridgeFormulaActual = getGroup16BridgeFormulaActual;
    self.getGroup17BridgeFormulaActual = getGroup17BridgeFormulaActual;

    self.getGroup14BridgeFormulaOverload = getGroup14BridgeFormulaOverload;
    self.getGroup15BridgeFormulaOverload = getGroup15BridgeFormulaOverload;
    self.getGroup16BridgeFormulaOverload = getGroup16BridgeFormulaOverload;
    self.getGroup17BridgeFormulaOverload = getGroup17BridgeFormulaOverload;

    //2021.1
    self.getTrailerLengthForCompiler = getTrailerLengthForCompiler;
    self.getCombinationCargoCarryingLength = getCombinationCargoCarryingLength;

    //2021.2
    self.canMaximisePayloadForCombination = canMaximisePayloadForCombination;
    self.setLegislationEvaluationFunction = setLegislationEvaluationFunction;
    self.evaluationLegislationFunction = evaluationLegislationFunction;
    self.updateBridgeFormulaPermissibleValues = updateBridgeFormulaPermissibleValues;
    self.updateBridgeFormulaPermissiblesOnAxles = updateBridgeFormulaPermissiblesOnAxles;
    self.getVehicleFrontGrossPercent = getVehicleFrontGrossPercent;
    self.getVehicleRearGrossPercent = getVehicleRearGrossPercent;
    self.getVehicleMaxUnladenWeight = getVehicleMaxUnladenWeight;
    self.getKingpinLoadPercent = getKingpinLoadPercent;
    self.getTrailer1VerticalCOG = getTrailer1VerticalCOG;
    self.getTrailer2VerticalCOG = getTrailer2VerticalCOG;
    self.getCombinationTurningRadiusCurbToCurb = getCombinationTurningRadiusCurbToCurb;
    self.getCombinationTurningRadiusWallToWall = getCombinationTurningRadiusWallToWall;
    self.getCombinationInnerRadius = getCombinationInnerRadius;
    self.getCombinationExtremeGroupPermissible = getCombinationExtremeGroupPermissible;
    self.getCombinationPermissibleVals = getCombinationPermissibleVals;
    self.getGroup1BridgeFormulaUnusedCapacity = getGroup1BridgeFormulaUnusedCapacity;
    self.getGroup2BridgeFormulaUnusedCapacity = getGroup2BridgeFormulaUnusedCapacity;
    self.getGroup3BridgeFormulaUnusedCapacity = getGroup3BridgeFormulaUnusedCapacity;
    self.getGroup4BridgeFormulaUnusedCapacity = getGroup4BridgeFormulaUnusedCapacity;
    self.getGroup5BridgeFormulaUnusedCapacity = getGroup5BridgeFormulaUnusedCapacity;
    self.getGroup6BridgeFormulaUnusedCapacity = getGroup6BridgeFormulaUnusedCapacity;
    self.getGroup7BridgeFormulaUnusedCapacity = getGroup7BridgeFormulaUnusedCapacity;
    self.getGroup8BridgeFormulaUnusedCapacity = getGroup8BridgeFormulaUnusedCapacity;
    self.getGroup9BridgeFormulaUnusedCapacity = getGroup9BridgeFormulaUnusedCapacity;
    self.getGroup10BridgeFormulaUnusedCapacity = getGroup10BridgeFormulaUnusedCapacity;
    self.getGroup11BridgeFormulaUnusedCapacity = getGroup11BridgeFormulaUnusedCapacity;
    self.getGroup12BridgeFormulaUnusedCapacity = getGroup12BridgeFormulaUnusedCapacity;
    self.getGroup13BridgeFormulaUnusedCapacity = getGroup13BridgeFormulaUnusedCapacity;
    self.getGroup14BridgeFormulaUnusedCapacity = getGroup14BridgeFormulaUnusedCapacity;
    self.getGroup15BridgeFormulaUnusedCapacity = getGroup15BridgeFormulaUnusedCapacity;
    self.getGroup16BridgeFormulaUnusedCapacity = getGroup16BridgeFormulaUnusedCapacity;
    self.getGroup17BridgeFormulaUnusedCapacity = getGroup17BridgeFormulaUnusedCapacity;
    self.getComVehBodyPlusPayloadPercentFront = getComVehBodyPlusPayloadPercentFront;
    self.getComVehBodyPlusPayloadPercentRear = getComVehBodyPlusPayloadPercentRear;
    self.getRearOverhangLegislationPercent = getRearOverhangLegislationPercent;
    self.getCurrentCombinationBridgeFormulaUsedAttributeName = getCurrentCombinationBridgeFormulaUsedAttributeName;
    self.setCombinationPermissibleVals = setCombinationPermissibleVals;
    self.getRearOverhangLegislation = getRearOverhangLegislation;

    //2021.3
    self.getAccessoryForChassisById = getAccessoryForChassisById;
    self.getRearmostAxleToCentreOfHookliftRoller = getRearmostAxleToCentreOfHookliftRoller;
    self.getCentreOfHookliftRollerToEnd = getCentreOfHookliftRollerToEnd;
    // self.getAfterFrame = getAfterFrame;
    
    self.getBodyGap = getBodyGap;
    self.getTrailerFrontOverhangStartExclDrawbar = getTrailerFrontOverhangStartExclDrawbar;
    self.getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFrontExclDrawbar = getTrailerFrontOverhangStartInclPortionOfVehicleOverhangingFrontExclDrawbar;

    //2022.1
    self.getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle = getVehicleExtremeRearDrivenGroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre = getVehicleExtremeRearDrivenGroupBridgeFormulaWidthPerTyre;
    self.getVehicleExtremeRearDrivenGroupBridgeFormulaLifting = getVehicleExtremeRearDrivenGroupBridgeFormulaLifting;
    self.getVehicleExtremeRearDrivenGroupBridgeFormulaRaised = getVehicleExtremeRearDrivenGroupBridgeFormulaRaised;
    self.getVehicleExtremeRearDrivenGroupBridgeFormulaSteering = getVehicleExtremeRearDrivenGroupBridgeFormulaSteering;

    self.getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle = getVehicleExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre = getVehicleExtremeFrontGroupBridgeFormulaWidthPerTyre;
    self.getVehicleExtremeFrontGroupBridgeFormulaLifting = getVehicleExtremeFrontGroupBridgeFormulaLifting;
    self.getVehicleExtremeFrontGroupBridgeFormulaRaised = getVehicleExtremeFrontGroupBridgeFormulaRaised;
    self.getVehicleExtremeFrontGroupBridgeFormulaSteering = getVehicleExtremeFrontGroupBridgeFormulaSteering;

    self.getVehiclePusher1GroupBridgeFormulaDistanceNearest = getVehiclePusher1GroupBridgeFormulaDistanceNearest;
    self.getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle = getVehiclePusher1GroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehiclePusher1GroupBridgeFormulaWidthPerTyre = getVehiclePusher1GroupBridgeFormulaWidthPerTyre;
    self.getVehiclePusher1GroupBridgeFormulaLifting = getVehiclePusher1GroupBridgeFormulaLifting;
    self.getVehiclePusher1GroupBridgeFormulaRaised = getVehiclePusher1GroupBridgeFormulaRaised;
    self.getVehiclePusher1GroupBridgeFormulaSteering = getVehiclePusher1GroupBridgeFormulaSteering;

    self.getVehiclePusher2GroupBridgeFormulaDistanceNearest = getVehiclePusher2GroupBridgeFormulaDistanceNearest;
    self.getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle = getVehiclePusher2GroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehiclePusher2GroupBridgeFormulaWidthPerTyre = getVehiclePusher2GroupBridgeFormulaWidthPerTyre;
    self.getVehiclePusher2GroupBridgeFormulaLifting = getVehiclePusher2GroupBridgeFormulaLifting;
    self.getVehiclePusher2GroupBridgeFormulaRaised = getVehiclePusher2GroupBridgeFormulaRaised;
    self.getVehiclePusher2GroupBridgeFormulaSteering = getVehiclePusher2GroupBridgeFormulaSteering;

    self.getVehiclePusher3GroupBridgeFormulaDistanceNearest = getVehiclePusher3GroupBridgeFormulaDistanceNearest;
    self.getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle = getVehiclePusher3GroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehiclePusher3GroupBridgeFormulaWidthPerTyre = getVehiclePusher3GroupBridgeFormulaWidthPerTyre;
    self.getVehiclePusher3GroupBridgeFormulaLifting = getVehiclePusher3GroupBridgeFormulaLifting;
    self.getVehiclePusher3GroupBridgeFormulaRaised = getVehiclePusher3GroupBridgeFormulaRaised;
    self.getVehiclePusher3GroupBridgeFormulaSteering = getVehiclePusher3GroupBridgeFormulaSteering;

    self.getVehicleTag1GroupBridgeFormulaDistanceNearest = getVehicleTag1GroupBridgeFormulaDistanceNearest;
    self.getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle = getVehicleTag1GroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehicleTag1GroupBridgeFormulaWidthPerTyre = getVehicleTag1GroupBridgeFormulaWidthPerTyre;
    self.getVehicleTag1GroupBridgeFormulaLifting = getVehicleTag1GroupBridgeFormulaLifting;
    self.getVehicleTag1GroupBridgeFormulaRaised = getVehicleTag1GroupBridgeFormulaRaised;
    self.getVehicleTag1GroupBridgeFormulaSteering = getVehicleTag1GroupBridgeFormulaSteering;

    self.getVehicleTag2GroupBridgeFormulaDistanceNearest = getVehicleTag2GroupBridgeFormulaDistanceNearest;
    self.getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle = getVehicleTag2GroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehicleTag2GroupBridgeFormulaWidthPerTyre = getVehicleTag2GroupBridgeFormulaWidthPerTyre;
    self.getVehicleTag2GroupBridgeFormulaLifting = getVehicleTag2GroupBridgeFormulaLifting;
    self.getVehicleTag2GroupBridgeFormulaRaised = getVehicleTag2GroupBridgeFormulaRaised;
    self.getVehicleTag2GroupBridgeFormulaSteering = getVehicleTag2GroupBridgeFormulaSteering;

    self.getVehicleTag3GroupBridgeFormulaDistanceNearest = getVehicleTag3GroupBridgeFormulaDistanceNearest;
    self.getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle = getVehicleTag3GroupBridgeFormulaNumberOfTyresPerAxle;
    self.getVehicleTag3GroupBridgeFormulaWidthPerTyre = getVehicleTag3GroupBridgeFormulaWidthPerTyre;
    self.getVehicleTag3GroupBridgeFormulaLifting = getVehicleTag3GroupBridgeFormulaLifting;
    self.getVehicleTag3GroupBridgeFormulaRaised = getVehicleTag3GroupBridgeFormulaRaised;
    self.getVehicleTag3GroupBridgeFormulaSteering = getVehicleTag3GroupBridgeFormulaSteering;

    self.getTrailer1ExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle = getTrailer1ExtremeFrontGroupBridgeFormulaNumberOfTyresPerAxle;
    self.getTrailer1ExtremeFrontGroupBridgeFormulaWidthPerTyre = getTrailer1ExtremeFrontGroupBridgeFormulaWidthPerTyre;
    self.getTrailer1ExtremeFrontGroupBridgeFormulaLifting = getTrailer1ExtremeFrontGroupBridgeFormulaLifting;
    self.getTrailer1ExtremeFrontGroupBridgeFormulaRaised = getTrailer1ExtremeFrontGroupBridgeFormulaRaised;
    self.getTrailer1ExtremeFrontGroupBridgeFormulaSteering = getTrailer1ExtremeFrontGroupBridgeFormulaSteering;

    self.getTrailer1ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle = getTrailer1ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle;
    self.getTrailer1ExtremeRearGroupBridgeFormulaWidthPerTyre = getTrailer1ExtremeRearGroupBridgeFormulaWidthPerTyre;
    self.getTrailer1ExtremeRearGroupBridgeFormulaLifting = getTrailer1ExtremeRearGroupBridgeFormulaLifting;
    self.getTrailer1ExtremeRearGroupBridgeFormulaRaised = getTrailer1ExtremeRearGroupBridgeFormulaRaised;
    self.getTrailer1ExtremeRearGroupBridgeFormulaSteering = getTrailer1ExtremeRearGroupBridgeFormulaSteering;

    self.getTrailer2ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle = getTrailer2ExtremeRearGroupBridgeFormulaNumberOfTyresPerAxle;
    self.getTrailer2ExtremeRearGroupBridgeFormulaWidthPerTyre = getTrailer2ExtremeRearGroupBridgeFormulaWidthPerTyre;
    self.getTrailer2ExtremeRearGroupBridgeFormulaLifting = getTrailer2ExtremeRearGroupBridgeFormulaLifting;
    self.getTrailer2ExtremeRearGroupBridgeFormulaRaised = getTrailer2ExtremeRearGroupBridgeFormulaRaised;
    self.getTrailer2ExtremeRearGroupBridgeFormulaSteering = getTrailer2ExtremeRearGroupBridgeFormulaSteering;

    self.calculateFromBackOfCab = calculateFromBackOfCab;
    self.calculateFromAxle1Front = calculateFromAxle1Front;

    self.getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles = getVehicleExtremeFrontGroupBridgeFormulaNumberOfAxles;
    self.resequenceTrailerAxles = resequenceTrailerAxles;
    self.noSimplePayloadObjectOverridden = noSimplePayloadObjectOverridden;

    //2023.2
    self.getCombinationExtremeGroupOverload = getCombinationExtremeGroupOverload;
    self.getBridgeFormulaPermissibleCombinationOverload = getBridgeFormulaPermissibleCombinationOverload;
    self.getExtremeBridgeGroupName = getExtremeBridgeGroupName;

    //2023.3
    self.getRighMostChassisPathRootForCurrentCombination = getRighMostChassisPathRootForCurrentCombination;
    self.getVehicleTurningRadiusInner = getVehicleTurningRadiusInner;
    self.getVehicleRearMinus1Gross = getVehicleRearMinus1Gross;
    self.getVehicleRearMinus2Gross = getVehicleRearMinus2Gross;
    self.getVehicleRearMinus3Gross = getVehicleRearMinus3Gross;
    self.getVehicleMainGross = getVehicleMainGross;
    self.getVehicleRearPlus1Gross = getVehicleRearPlus1Gross;
    self.getVehicleRearPlus2Gross = getVehicleRearPlus2Gross;
    self.getVehicleRearPlus3Gross = getVehicleRearPlus3Gross;
    self.getVehicleExtremeRearGroupBridgeFormulaDistance = getVehicleExtremeRearGroupBridgeFormulaDistance;
    self.getVehicleRearMostAxleToAxle1TrailerFront = getVehicleRearMostAxleToAxle1TrailerFront;
    self.getVehicleRearMostAxleToAxle1Trailer1Rear = getVehicleRearMostAxleToAxle1Trailer1Rear;
    self.getTrailer1RearMostAxleToAxle1Trailer2Rear = getTrailer1RearMostAxleToAxle1Trailer2Rear;
    self.getHookliftRearOverhangTotal = getHookliftRearOverhangTotal;
    self.getVehicleAndDrawbarGroup5BridgeFormulaDistance = getVehicleAndDrawbarGroup5BridgeFormulaDistance;
    self.getVehicleAndPupGroup3BridgeFormulaDistance = getVehicleAndPupGroup3BridgeFormulaDistance;
    self.getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance = getVehicleAndSemiOrInterlinkGroup3BridgeFormulaDistance;
    self.getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistance = getVehicleAndInterlinkAndSemiGroup5BridgeFormulaDistance;
    self.getUtilisationOfGVWRPercent = getUtilisationOfGVWRPercent;
    self.getUtilisationOfGCWRPercent = getUtilisationOfGCWRPercent;
    self.getUtilisationOfVehiclePermissiblePercent = getUtilisationOfVehiclePermissiblePercent;
    self.getUtilisationOfCombinationPermissiblePercent = getUtilisationOfCombinationPermissiblePercent;
    self.getVehicleFrontOuterTrackWidth = getVehicleFrontOuterTrackWidth;
    self.getVehicleRearMinus1OuterTrackWidth = getVehicleRearMinus1OuterTrackWidth;
    self.getVehicleRearPlus1OuterTrackWidth = getVehicleRearPlus1OuterTrackWidth;
    self.getVehicleRearMainOuterTrackWidth = getVehicleRearMainOuterTrackWidth;
    self.hasMissingTareOrPermissibleWeigthsForRegulation = hasMissingTareOrPermissibleWeigthsForRegulation;
    self.getInteraxleVehicleFrontToVehicleRear = getInteraxleVehicleFrontToVehicleRear;
    self.getDefaultSteeringAttributeValue = getDefaultSteeringAttributeValue;
    
    //2023.4
    self.getAvailableAddFuelTankOptions = getAvailableAddFuelTankOptions;
    self.resetFuelTanks = resetFuelTanks;
    self.getFuelTankFromPath = getFuelTankFromPath;
    self.getManufacturerWeightsVehicleAndTransferFromTrailerGVMOverloaded = getManufacturerWeightsVehicleAndTransferFromTrailerGVMOverloaded;
    self.getVehicleMinus1GroupBridgeFormulaPermissible = getVehicleMinus1GroupBridgeFormulaPermissible;
    self.getVehicleMinus2GroupBridgeFormulaPermissible = getVehicleMinus2GroupBridgeFormulaPermissible;
    self.getVehicleMinus3GroupBridgeFormulaPermissible = getVehicleMinus3GroupBridgeFormulaPermissible;
    self.getVehicleExtremeRearGroupBridgeFormulaPermissible = getVehicleExtremeRearGroupBridgeFormulaPermissible;
    self.getVehicleExtremeFrontGroupBridgeFormulaPermissible = getVehicleExtremeFrontGroupBridgeFormulaPermissible;
    self.getVehicleExtremeGroupBridgeFormulaPermissible = getVehicleExtremeGroupBridgeFormulaPermissible;
    self.getTrailer1ExtremeFrontGroupBridgeFormulaPermissible = getTrailer1ExtremeFrontGroupBridgeFormulaPermissible;
    self.getTrailer1ExtremeRearGroupBridgeFormulaPermissible = getTrailer1ExtremeRearGroupBridgeFormulaPermissible;
    self.getTrailer2ExtremeRearGroupBridgeFormulaPermissible = getTrailer2ExtremeRearGroupBridgeFormulaPermissible;
    self.getVehicleRearTotalBridgeOrRegulationVal = getVehicleRearTotalBridgeOrRegulationVal;

    //2023.5
    self.getCombinationOverallWidth = getCombinationOverallWidth;
    self.getVehicleOverallWidth = getVehicleOverallWidth;
    self.getTrailer1OverallWidth = getTrailer1OverallWidth;
    self.getTrailer2OverallWidth = getTrailer2OverallWidth;
    self.getVehicleTotalBridgeVal = getVehicleTotalBridgeVal;
    self.getCombinationTotalBridgeVal = getCombinationTotalBridgeVal;
    self.calculateFromLeftOfChassisRearWidth = calculateFromLeftOfChassisRearWidth;
    self.updateFromLeftOfChassisRearWidthForAllChassisItems = updateFromLeftOfChassisRearWidthForAllChassisItems;
    self.calculateFromLeftOfBodyWidthExternal = calculateFromLeftOfBodyWidthExternal;
    self.updateFromLeftOfBodyWidthExternalForAllChassisItems = updateFromLeftOfBodyWidthExternalForAllChassisItems;
    self.calculateFromCentreOfChassisForFromLeftOfBodyWidthExternal = calculateFromCentreOfChassisForFromLeftOfBodyWidthExternal;
    self.calculateFromCentreOfChassisForFromLeftOfVehicleRearWidth = calculateFromCentreOfChassisForFromLeftOfVehicleRearWidth;
    self.updateFromCentreOfChassisForAllChassisItems = updateFromCentreOfChassisForAllChassisItems;
    // self.calculateFromCentreOfVehicleForAllChassisItems = calculateFromCentreOfVehicleForAllChassisItems;

    //2024.2
    self.getFirstGearStartability = getFirstGearStartability;
    self.getReverseGearStartability = getReverseGearStartability;
    self.getFirstGearGradeability = getFirstGearGradeability;
    self.getCDLRequiredUS = getCDLRequiredUS;
    self.getParkPAWL = getParkPAWL;
    self.canEvaluateCDLRequiredUS = canEvaluateCDLRequiredUS;
    self.canEvaluateFirstGearStartability = canEvaluateFirstGearStartability;
    self.canEvaluateReverseGearStartability = canEvaluateReverseGearStartability;
    self.canEvaluateFirstGearGradeability = canEvaluateFirstGearGradeability;
    self.getTrailerGVMUsingPath = getTrailerGVMUsingPath;
    self.getAfterframe = getAfterframe;

    //2024.3
    self.getSteeringAngleForMinusAxle = getSteeringAngleForMinusAxle;
    self.getSteeringAngleForPlusAxle = getSteeringAngleForPlusAxle;
    self.getOverallHeightExcludingPayload = getOverallHeightExcludingPayload;
    self.calculateFromAxle1FrontForRelevantAccessories = calculateFromAxle1FrontForRelevantAccessories;
    self.getBaseWheelbaseSpecified = getBaseWheelbaseSpecified;
    self.getVehicleFrontRegulationValueChanged = getVehicleFrontRegulationValueChanged;
    self.getVehicleRearRegulationValueChanged = getVehicleRearRegulationValueChanged;
    self.getCombinationTotalRegulationValueChanged = getCombinationTotalRegulationValueChanged;
    self.getVehiclePusher1RegulationValueChanged = getVehiclePusher1RegulationValueChanged;
    self.getVehiclePusher2RegulationValueChanged = getVehiclePusher2RegulationValueChanged;
    self.getVehiclePusher3RegulationValueChanged = getVehiclePusher3RegulationValueChanged;
    self.getVehicleTag1RegulationValueChanged = getVehicleTag1RegulationValueChanged;
    self.getVehicleTag2RegulationValueChanged = getVehicleTag2RegulationValueChanged;
    self.getVehicleTag3RegulationValueChanged = getVehicleTag3RegulationValueChanged;
    self.getTrailer1FrontRegulationValueChanged = getTrailer1FrontRegulationValueChanged;
    self.getTrailer1RearRegulationValueChanged = getTrailer1RearRegulationValueChanged;
    self.getTrailer2FrontRegulationValueChanged = getTrailer2FrontRegulationValueChanged;
    self.getTrailer2RearRegulationValueChanged = getTrailer2RearRegulationValueChanged;

    //2025.1
    self.getDefaultVehicleFrontAxleAxleRatingChanged = getDefaultVehicleFrontAxleAxleRatingChanged;
    self.getDefaultVehicleFrontAxleSuspensionRatingChanged = getDefaultVehicleFrontAxleSuspensionRatingChanged;
    self.getDefaultVehicleFrontAxleTyreRatingChanged = getDefaultVehicleFrontAxleTyreRatingChanged;
    self.getDefaultVehicleFrontAxleGAWRChanged = getDefaultVehicleFrontAxleGAWRChanged;
    self.getDefaultVehicleRearAxleAxleRatingChanged = getDefaultVehicleRearAxleAxleRatingChanged;
    self.getDefaultVehicleRearAxleSuspensionRatingChanged = getDefaultVehicleRearAxleSuspensionRatingChanged;
    self.getDefaultVehicleRearAxleTyreRatingChanged = getDefaultVehicleRearAxleTyreRatingChanged;
    self.getDefaultVehicleRearAxleGAWRChanged = getDefaultVehicleRearAxleGAWRChanged;
    self.getDefaultVehiclePusher1AxleAxleRatingChanged = getDefaultVehiclePusher1AxleAxleRatingChanged;
    self.getDefaultVehiclePusher2AxleAxleRatingChanged = getDefaultVehiclePusher2AxleAxleRatingChanged;
    self.getDefaultVehiclePusher3AxleAxleRatingChanged = getDefaultVehiclePusher3AxleAxleRatingChanged;
    self.getDefaultVehiclePusher1AxleSuspensionRatingChanged = getDefaultVehiclePusher1AxleSuspensionRatingChanged;
    self.getDefaultVehiclePusher2AxleSuspensionRatingChanged = getDefaultVehiclePusher2AxleSuspensionRatingChanged;
    self.getDefaultVehiclePusher3AxleSuspensionRatingChanged = getDefaultVehiclePusher3AxleSuspensionRatingChanged;
    self.getDefaultVehiclePusher1AxleTyreRatingChanged = getDefaultVehiclePusher1AxleTyreRatingChanged;
    self.getDefaultVehiclePusher2AxleTyreRatingChanged = getDefaultVehiclePusher2AxleTyreRatingChanged;
    self.getDefaultVehiclePusher3AxleTyreRatingChanged = getDefaultVehiclePusher3AxleTyreRatingChanged;
    self.getDefaultVehiclePusher1AxleGAWRChanged = getDefaultVehiclePusher1AxleGAWRChanged;
    self.getDefaultVehiclePusher2AxleGAWRChanged = getDefaultVehiclePusher2AxleGAWRChanged;
    self.getDefaultVehiclePusher3AxleGAWRChanged = getDefaultVehiclePusher3AxleGAWRChanged;
    self.getDefaultVehicleTag1AxleAxleRatingChanged = getDefaultVehicleTag1AxleAxleRatingChanged;
    self.getDefaultVehicleTag2AxleAxleRatingChanged = getDefaultVehicleTag2AxleAxleRatingChanged;
    self.getDefaultVehicleTag3AxleAxleRatingChanged = getDefaultVehicleTag3AxleAxleRatingChanged;
    self.getDefaultVehicleTag1AxleSuspensionRatingChanged = getDefaultVehicleTag1AxleSuspensionRatingChanged;
    self.getDefaultVehicleTag2AxleSuspensionRatingChanged = getDefaultVehicleTag2AxleSuspensionRatingChanged;
    self.getDefaultVehicleTag3AxleSuspensionRatingChanged = getDefaultVehicleTag3AxleSuspensionRatingChanged;
    self.getDefaultVehicleTag1AxleTyreRatingChanged = getDefaultVehicleTag1AxleTyreRatingChanged;
    self.getDefaultVehicleTag2AxleTyreRatingChanged = getDefaultVehicleTag2AxleTyreRatingChanged;
    self.getDefaultVehicleTag3AxleTyreRatingChanged = getDefaultVehicleTag3AxleTyreRatingChanged;
    self.getDefaultVehicleTag1AxleGAWRChanged = getDefaultVehicleTag1AxleGAWRChanged;
    self.getDefaultVehicleTag2AxleGAWRChanged = getDefaultVehicleTag2AxleGAWRChanged;
    self.getDefaultVehicleTag3AxleGAWRChanged = getDefaultVehicleTag3AxleGAWRChanged;
    self.getDefaultTrailer1FrontAxleAxleRatingChanged = getDefaultTrailer1FrontAxleAxleRatingChanged;
    self.getDefaultTrailer1RearAxleAxleRatingChanged = getDefaultTrailer1RearAxleAxleRatingChanged;
    self.getDefaultTrailer2FrontAxleAxleRatingChanged = getDefaultTrailer2FrontAxleAxleRatingChanged;
    self.getDefaultTrailer2RearAxleAxleRatingChanged = getDefaultTrailer2RearAxleAxleRatingChanged;
    self.getMaxUnladenVehicleWeightExceeded = getMaxUnladenVehicleWeightExceeded;
    self.getMaxUnladenVehicleWeightChanged = getMaxUnladenVehicleWeightChanged;

}   




export default RigOps;
