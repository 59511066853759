import config from '../config';
import logger from '../logger';

const $ = window.$;
const pdfMake = window.pdfMake;

var pdfCreationPromise;
/**
* Takes in the document definition JSON object and creates PDF using PDFMake
* @method createPdf
* @param {Object} docDef JSON object that contains the structure of the PDF document to be created.
* @returns {Object} returns the PDF document
* 
*/
function createPdf(docDef) {
    pdfCreationPromise = $.Deferred();
    pdfMake.fonts = {
        Roboto: {
            normal: 'Roboto-Regular.ttf',
            bold: 'Roboto-Bold.ttf',
            italics: 'Roboto-Regular.ttf',
            bolditalics: 'Roboto-Regular.ttf'
        }
    };
    try {
        pdfMake.createPdf(docDef).getDataUrl(function (outDoc) {
            pdfCreationPromise.resolve(outDoc);
        });
    } catch (e) {
        logger.log('Failed PDF creation', undefined, 'pdfManager.createPdf', true, undefined, config.LOG_MESSAGE_TYPE.ERROR);
        pdfCreationPromise.resolve(undefined);
    }
    
    return pdfCreationPromise.promise();
}

var pdfManager = {
    createPdf: createPdf
};

  export default pdfManager